import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Image } from 'antd'
import { PhoneOutlined, GlobalOutlined } from '@ant-design/icons'
import { infoBranchLogin } from '../../../store/actions/BranchAction'
import { BNPendingByBranch } from '../../../store/actions/receiveAction'
import { recieveService } from '../../../services/receive/recieveService'
import logoNhi from '../../../assets/images/logo/logoNhi_DanhSachChoKham.png'
import logoTiemChung from '../../../assets/images/logo/logoTiemChung_DanhSachChoKham.png'
import logoSan from '../../../assets/images/logo/logoSan_DanhSachChoKham.png'
import logoLao from '../../../assets/images/logo/logoLao_DanhSachChoKham.png'
import logoMat from '../../../assets/images/logo/logoMat_DanhSachChoKham.png'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const DsBnChoKham = () => {
  const idBranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const statusKham = 1
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { BNPending } = useSelector((state) => state.receiveReducer)
  const [listCnPk, setlistCnPk] = useState([])
  const [infoBsKham, setinfoBsKham] = useState(null)
  const idCnPk =
    listCnPk?.find((item) => item?.tenPhongKham?.includes('1'))?.idcnpk ?? listCnPk[0]?.idcnpk ?? 0 //mặc định lấy tên bs đang khám phòng khám 1
  // const logoNhi = 'https://w.ladicdn.com/5aa6273ea81f66ca2eacc40b/nhi-dong-vien-trang-20240621035543-y9bkc.png'
  // const logoSan = 'https://w.ladicdn.com/5aa6273ea81f66ca2eacc40b/phu-san-vien-trang-20231220072323-0gx00.png'
  // const logoLao = 'https://w.ladicdn.com/5aa6273ea81f66ca2eacc40b/tm-td-vien-trang-20231220072330-niatn.png'
  // const logoMat = 'https://w.ladicdn.com/5aa6273ea81f66ca2eacc40b/mat-vien-trang-20231220072303-zil_t.png'
  const titleNhi = 'HỆ THỐNG Y TẾ NHI ĐỒNG 315'
  const titleSan = 'HỆ THỐNG Y TẾ PHỤ SẢN 315'
  const titleLao = 'HỆ THỐNG Y TẾ TIM MẠCH - TIỂU ĐƯỜNG 315'
  const titleMat = 'HỆ THỐNG Y TẾ MẮT 315'
  const srcLogoTop =
    PkDangNhap?.idChuyenKhoa === 1
      ? logoNhi
      : PkDangNhap?.idChuyenKhoa === 2
        ? logoSan
        : PkDangNhap?.idChuyenKhoa === 3
          ? logoLao
          : PkDangNhap?.idChuyenKhoa === 4
            ? logoMat
            : logoNhi
  const srcLogoBottom = srcLogoTop
  const titleTop =
    PkDangNhap?.idChuyenKhoa === 1
      ? titleNhi
      : PkDangNhap?.idChuyenKhoa === 2
        ? titleSan
        : PkDangNhap?.idChuyenKhoa === 3
          ? titleLao
          : PkDangNhap?.idChuyenKhoa === 4
            ? titleMat
            : titleNhi
  const titleBottom =
    PkDangNhap?.idChuyenKhoa === 1
      ? 'HỆ THỐNG Y TẾ NHI ĐỒNG & TIÊM CHỦNG NHI 315'
      : PkDangNhap?.idChuyenKhoa === 2
        ? titleSan
        : PkDangNhap?.idChuyenKhoa === 3
          ? titleLao
          : PkDangNhap?.idChuyenKhoa === 4
            ? titleMat
            : titleNhi
  const dispatch = useDispatch()
  const getListCnPk = async () => {
    try {
      const { data } = await recieveService.getPhongKham(idBranchLogin)
      setlistCnPk(data)
    } catch (error) {
      console.log('getListCnPk : ', error)
    }
  }
  const getBsDangKhamCuaCnPk = async () => {
    try {
      if (!listCnPk?.length) return
      const { data } = await recieveService.getBsDangKhamCuaCnPk(idCnPk)
      // lấy bs khám hôm nay, bs cũ hôm qua không lấy
      if (data?.ngaycapnhat?.includes(moment().format('YYYY-MM-DD'))) setinfoBsKham(data)
      else setinfoBsKham(null)
    } catch (error) {
      console.log('getListCnPk : ', error)
    }
  }

  useEffect(() => {
    getListCnPk()
    dispatch(infoBranchLogin(idBranchLogin))
    dispatch(BNPendingByBranch(statusKham, idBranchLogin))
    // Thiết lập interval để gọi API mỗi 3 giây
    const intervalId = setInterval(() => {
      dispatch(BNPendingByBranch(statusKham, idBranchLogin))
    }, 3000)
    // Cleanup interval khi component unmount
    return () => clearInterval(intervalId)
  }, [])
  useEffect(() => {
    getBsDangKhamCuaCnPk()
  }, [listCnPk, BNPending?.length])
  return (
    <div
      // className='h-svh bg-sky-500'
      className={
        PkDangNhap?.idChuyenKhoa === 1
          ? 'h-svh bg-sky-500'
          : PkDangNhap?.idChuyenKhoa === 2
            ? 'h-svh bg-pink-400'
            : PkDangNhap?.idChuyenKhoa === 3
              ? 'h-svh bg-orange-400'
              : PkDangNhap?.idChuyenKhoa === 4
                ? 'h-svh bg-cyan-500'
                : 'h-svh bg-sky-500'
      }
    >
      <div className="flex flex-row justify-end  text-white">
        <Image className="m-auto pt-3 pb-3" height={200} preview={false} src={srcLogoTop} />
        <div className="w-10/12 h-auto grid grid-flow-row font-bold font-sans text-center gap-0">
          <div className="leading-[50px] text-[50px] pt-4">{titleTop}</div>
          <div className="leading-[40px] text-[40px]">Chi nhánh - {PkDangNhap?.diaChi}</div>
          <div className="leading-[55px] text-[55px] pt-3 pb-2">
            {infoBsKham?.tenBacSi}
            {/* {`${infoBsKham?.tenPhongKham} - ${infoBsKham?.tenBacSi}`} */}
          </div>
        </div>
      </div>
      <div className="flex flex-row h-3/5 bg-white">
        <div className="grid grid-rows-3 grid-flow-col w-full border-2  gap-2 bg-white">
          <div className="row-span-3 text-center">
            {/* <p className="text-[60px] mt-10 italic">Đang khám</p>
                        <p className="text-[220px] font-bold text-sky-500">13</p>
                        <p className="text-[80px]">David Bành Lưu</p> */}
          </div>
          <div className="row-span-3 col-span-1 text-[75px] overflow-auto w-auto bg-white">
            {/* <p className="text-center italic text-[60px] mt-1 mb-1">Chờ khám</p> */}
            {BNPending?.map((item, index) => {
              return (
                <div key={index} className="pl-3 ">
                  <span className="font-bold text-[85px]">{item?.sttkham}</span> -{' '}
                  {item?.tenbenhnhan}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-row h-44 justify-end pt-2 text-white">
        <Image className="m-auto mt-2" height={200} preview={false} src={srcLogoBottom} />
        <div className="w-10/12 h-auto grid grid-flow-row font-bold font-sans text-center">
          <div className="text-[55px]">{titleBottom}</div>
          <div className="text-[45px]">
            <GlobalOutlined className="mr-3" />
            {PkDangNhap?.idChuyenKhoa === 1 && (
              <>
                nhidong315.com<span className="ml-10 mr-10">-</span>
                <GlobalOutlined className="mr-3" />
                tiemchungnhi315.com
              </>
            )}
            {PkDangNhap?.idChuyenKhoa === 2 && <>phusan315.com</>}
            {PkDangNhap?.idChuyenKhoa === 3 && <>timmachtieuduong315.com</>}
            {PkDangNhap?.idChuyenKhoa === 4 && <>mat315.com</>}
          </div>
          <div className="text-[45px]">
            <PhoneOutlined className="mr-3" />
            0901.315.315
          </div>
        </div>
      </div>
    </div>
  )
}
export default DsBnChoKham
