import { InputNumber, Modal, Select } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updatePriceBranchBuy } from '../../../store/actions/thuocVTAction'

const ModalUpdate = ({ setShow, show, nameVT, idThuoc, onReload }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { chuyenKhoa } = useSelector((state) => state.thuocVTReducer)
  const id_company = Number(localStorage.getItem('id_company'))
  const [form, setForm] = useState({
    giaBan: 0,
    idCT: id_company,
    idChuyenKhoa: '',
  })

  const dispatch = useDispatch()

  const handleCancel = () => {
    setShow(false)
  }
  const handleResetForm = () => {
    setForm({
      giaBan: 0,
      idCT: id_company,
      idChuyenKhoa: '',
    })
    handleCancel()
    onReload()
  }
  const onChange = (value) => {
    setForm((prevState) => ({ ...prevState, giaBan: value }))
  }
  const handleOk = () => {
    dispatch(updatePriceBranchBuy(form, infoUser?.idnv, idThuoc, handleResetForm, nameVT))
  }
  return (
    <Modal
      onOk={handleOk}
      open={show}
      title="Cập nhập giá chi nhánh bán"
      okText="Lưu"
      cancelText="Huỷ bỏ"
      onCancel={handleCancel}
    >
      <div className="flex flex-col gap-2">
        <div className="flex  items-center">
          <label className="font-semibold text-gray-500  w-1/4">Hàng hoá:</label>
          <p className="truncate">{nameVT}</p>
        </div>
        <div className="flex  items-center">
          <label className="font-semibold text-gray-500 w-1/4">Công ty:</label>
          <Select
            showSearch
            className="w-3/4"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={(value) => setForm((prevState) => ({ ...prevState, idCT: value }))}
            value={form?.idCT}
            options={listCompany?.map((items) => ({
              label: items.tenct,
              value: items.idct,
            }))}
          />
        </div>
        <div className="flex  items-center">
          <label className="font-semibold text-gray-500  w-1/4">Chuyên khoa:</label>
          <Select
            value={form?.idChuyenKhoa}
            onChange={(value) => setForm((prevState) => ({ ...prevState, idChuyenKhoa: value }))}
            defaultValue=""
            options={[
              { label: 'Tất cả', value: '' },
              ...(chuyenKhoa
                ? chuyenKhoa.map((items) => ({
                    label: items.tenChuyenKhoa,
                    value: items.idChuyenKhoa,
                  }))
                : []),
            ]}
            className="w-3/4"
          />
        </div>
        <div className="flex  items-center">
          <label className="font-semibold text-gray-500  w-1/4">Giá bán:</label>
          <InputNumber
            suffix="VNĐ"
            className="w-3/4"
            value={form?.giaBan}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            onChange={onChange}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ModalUpdate
