import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { DatePicker, Select, TreeSelect, Button, Space, Input, Table, ConfigProvider } from 'antd'
import IconButton from '@mui/material/IconButton'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { SearchOutlined } from '@ant-design/icons'
import TableDetail from './Table/TableDetail'
import Highlighter from 'react-highlight-words'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { branchService } from '../../../../services/branch/branchService'
import { reportService } from '../../../../services/Report/reportService'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
import { calculateAge } from '../../../../utils/calculateAge'
import { CalculateDuSanh } from '../../../../utils/CalculateDuSanh'
import { getListNameChiNhanh } from '../../../../utils/algorithm'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect

const SayThai = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const now = moment()
  const dateFormat = 'DD-MM-YYYY'
  const momentDate = 'YYYY-MM-DD'
  const { id } = useParams()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState([BranchLogin])
  const [idChiNhanh, setIdChiNhanh] = useState(Number(BranchLogin))
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [data, setData] = useState([])
  const [branch, setBranch] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [searchText, setSearchText] = useState('')
  const [activeRow, setActiveRow] = useState(null)
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const nameChiNhanh = useMemo(() => getListNameChiNhanh(value, branch), [value, branch])
  const nameCompany = useMemo(
    () => listCompany?.find((item) => item?.idct === company)?.tenct,
    [company, listCompany],
  )
  const nameChuyenKhoa = useMemo(
    () =>
      !idChuyenKhoa
        ? 'Tất cả'
        : ChuyenKhoa?.find((item) => item?.idChuyenKhoa === idChuyenKhoa)?.tenChuyenKhoa,
    [idChuyenKhoa, ChuyenKhoa],
  )

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex] ?? '' // Kiểm tra nếu record[dataIndex] là null/undefined, gán chuỗi rỗng
      return recordValue.toString().toLowerCase().includes(value.toLowerCase())
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  //-------- export excel-----------//
  const exportToExcel = async () => {
    try {
      const formattedData = data?.map((item) => ({
        'Ngày khám': item.ngaykham ? moment(item.ngaykham).format('DD/MM/YYYY HH:mm:ss') : '-',
        'Chi nhánh': item.tenchinhanh,
        'Mã bệnh nhân': item.mabenhnhan || '',
        'Tên bệnh nhân': item.tenbenhnhan || '',
        'Giới tính': item.gioitinh || '',
        'Ngày sinh': item.ngaysinh ? moment(item.ngaysinh).format('DD/MM/YYYY') : '-',
        Tuổi: calculateAge(item.ngaysinh),
        SDT: item.dienthoai || '',
        'Lúc thai': CalculateDuSanh(item?.ngaykham, item.dusanh).tuoiThai,
      }))
      const name = 'Phụ sản sảy thai'
      formattedData?.length && exportExcelformat(formattedData, name)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_BAO_CAO_THEO_DOI_PHU_SAN_SAY_THAI,
        true,
        MENU_CONSTANTS.THEO_DOI_PHU_SAN,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          nameChiNhanh,
          nameChuyenKhoa,
          nameCompany,
        },
      )
    } catch (error) {
      console.log(error)
      const detailErrorPayload = `Có lỗi xảy ra khi xuất excel báo cáo theo dõi phụ sản sảy thai\n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.THEO_DOI_PHU_SAN,
        detailErrorPayload,
      )
    }
  }
  //-------------- lấy danh sách chuyên kho ------------//
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  //---------- lấy danh sách chi nhánh theo công ty -----------//
  const fetchBranch = async (id) => {
    try {
      const { data } = await branchService.getListBranch(id)
      setBranch(data)
    } catch (error) {
      console.log('fetchBranch : ', error)
    }
  }
  const fetchData = async (id, dateForm, dateTo) => {
    try {
      let idcn = ''
      if (value[0] !== 'all') {
        idcn = value?.map((id) => `&idcn=${id}`).join('&')
      } else {
        // idChiNhanh = branch?.map((item) => item.idChiNhanh)
        idcn = branch?.map((id) => `&idcn=${id.idChiNhanh}`).join('&')
      }
      const { data } = await reportService.getBenhNhanSayThai(company, idcn, dateForm, dateTo)
      setData(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.BAO_CAO_THEO_DOI_PHU_SAN_SAY_THAI,
        true,
        MENU_CONSTANTS.THEO_DOI_PHU_SAN,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          nameChiNhanh,
          nameChuyenKhoa,
          nameCompany,
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.THEO_DOI_PHU_SAN,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }
  //---------- reload dữ liệu --------//
  const onLoad = () => {
    fetchData(idChiNhanh, dateForm, dateTo)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(company, value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (idct, value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(idct, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    // fetchBranch(id_company)
    fetchBranchByChuyenKhoa(company, null)
    fetchData(id, dateForm, dateTo)
    fetchChuyenKhoa()
  }, [])
  const handleClickRow = (record) => {
    if (activeRow && activeRow.mabenhnhan === record.mabenhnhan) {
      setActiveRow(null)
    } else {
      setActiveRow(record)
    }
  }
  return (
    <>
      <div className="mb-2">
        <div className="flex gap-3 justify-between border-dotted border-b-0">
          <div className="flex gap-3 flex-wrap">
            <div className="w-80">
              <Select
                showSearch
                value={company}
                onChange={(value) => {
                  setCompany(value)
                  fetchBranch(value)
                  setIdChiNhanh(null)
                  setValue(['all'])
                  setIdChuyenKhoa('')
                  fetchBranchByChuyenKhoa(value, '')
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={listCompany?.map(({ idct, tenct }) => ({
                  label: tenct,
                  value: idct,
                }))}
                className="w-full"
                disabled={infoUser && infoUser.idNhom === 3}
              />
            </div>
            <div className="w-56">
              <Select
                onChange={onChangeChuyenKhoa}
                className="w-full"
                value={idChuyenKhoa}
                options={[
                  { label: 'Tất cả chuyên khoa', value: '' },
                  ...ChuyenKhoa.map((items) => ({
                    label: items.tenChuyenKhoa,
                    value: items.idChuyenKhoa,
                  })),
                ]}
                disabled={infoUser && infoUser.idNhom === 3}
              />
            </div>
            <div className="w-80">
              <TreeSelect
                {...tProps}
                style={{ flexWrap: 'unset' }}
                className="w-full h-8 overflow-clip"
                disabled={infoUser && infoUser.idNhom === 3}
              />
            </div>

            <div className="flex items-center gap-3">
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('form')}
                format={dateFormat}
              />
              <span className="font-semibold text-lg">-</span>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('to')}
                format={dateFormat}
              />
            </div>
            <div className="flex gap-3">
              <IconButton onClick={onLoad} color="primary" aria-label="delete" size="small">
                <SyncIcon />
              </IconButton>
            </div>
            <div className="font-semibold ">
              <Button
                disabled={!data?.length}
                onClick={exportToExcel}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          rowKey={(record) => record.mabenhnhan}
          onRow={(record) => ({
            onClick: () => handleClickRow(record),
          })}
          pagination={false}
          loading={isLoading}
          bordered
          dataSource={data}
          scroll={{ x: data?.length > 0 ? 'max-content' : 2800, y: '60vh' }}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 'stt',
              align: 'center',
              width: 40,
              render: (text, record, index) => ++index,
            },
            {
              title: 'Ngày khám',
              dataIndex: 'ngaykham',
              key: 'ngaykham',
              render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
              width: 150,
            },
            {
              title: 'Chi nhánh',
              dataIndex: 'tenchinhanh',
              key: 'tenchinhanh',
              ...getColumnSearchProps('tenchinhanh'),
              width: 200,
            },
            {
              title: 'Mã BN',
              dataIndex: 'mabenhnhan',
              key: 'mabenhnhan',
              ...getColumnSearchProps('mabenhnhan'),
              width: 100,
            },
            {
              title: 'Tên BN',
              dataIndex: 'tenbenhnhan',
              key: 'tenbenhnhan',
              ...getColumnSearchProps('tenbenhnhan'),
            },
            {
              title: 'Giới tính',
              dataIndex: 'gioitinh',
              align: 'center',
              width: 100,
              key: 'gioitinh',
              ...getColumnSearchProps('gioitinh'),
            },
            {
              title: 'Ngày sinh',
              dataIndex: 'ngaysinh',
              key: 'ngaysinh',
              render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
              width: 90,
            },
            {
              title: 'Tuổi',
              dataIndex: 'tenPhuongThucTT',
              key: 'tenPhuongThucTT',
              width: 200,
              render: (text, record) => calculateAge(record.ngaysinh),
            },
            {
              title: 'SĐT',
              dataIndex: 'dienthoai',
              key: 'dienthoai',
              width: 120,
              render: (text, record) => {
                if (record.mabenhnhan === activeRow?.mabenhnhan) {
                  return <p>{text}</p>
                }
                const maskedNumber = text?.replace(/./g, '*')
                return <p>{maskedNumber}</p>
              },
            },
            {
              title: 'Lúc thai',
              dataIndex: 'dusanh',
              key: 'dusanh',
              render: (text, record) => CalculateDuSanh(record?.ngaykham, text).tuoiThai,
              width: 200,
            },
          ]}
          summary={(pageData) => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={10}>
                  <p className="font-semibold">Tổng bệnh nhân: {pageData.length}</p>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </ConfigProvider>
    </>
  )
}

export default SayThai
