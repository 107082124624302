import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { DatePicker, Select, TreeSelect, Button } from 'antd'
import IconButton from '@mui/material/IconButton'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import TableDetail from './Table/TableDetail'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { branchService } from '../../../../services/branch/branchService'
import { reportService } from '../../../../services/Report/reportService'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
import { calculateAge } from '../../../../utils/calculateAge'
import { getListNameChiNhanh } from '../../../../utils/algorithm'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect

const TongHop = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const now = moment()
  const dateFormat = 'DD-MM-YYYY'
  const momentDate = 'YYYY-MM-DD'
  const { id } = useParams()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState([BranchLogin])
  const [idChiNhanh, setIdChiNhanh] = useState(Number(BranchLogin))
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [data, setData] = useState([])
  const [branch, setBranch] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))

  const nameChiNhanh = useMemo(() => getListNameChiNhanh(value, branch), [value, branch])
  const nameCompany = useMemo(
    () => listCompany?.find((item) => item?.idct === company)?.tenct,
    [company, listCompany],
  )
  const nameChuyenKhoa = useMemo(
    () =>
      !idChuyenKhoa
        ? 'Tất cả'
        : ChuyenKhoa?.find((item) => item?.idChuyenKhoa === idChuyenKhoa)?.tenChuyenKhoa,
    [idChuyenKhoa, ChuyenKhoa],
  )

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  //-------- export excel-----------//
  const exportToExcel = async () => {
    try {
      const formattedData = data?.map((item) => ({
        'Mã bệnh nhân': item.mabenhnhan,
        'Tên bệnh nhân': item.tenbenhnhan,
        'Giới tính': item.gioitinh,
        'Ngày sinh': item.ngaysinh ? moment(item.ngaysinh).format('DD/MM/YYYY') : '-',
        Tuổi: calculateAge(item.ngaysinh),
        SDT: item.dienthoai,
      }))
      const name = 'Tổng hợp phụ sản có thai'
      formattedData?.length && exportExcelformat(formattedData, name)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_BAO_CAO_THEO_DOI_PHU_SAN_TONG_HOP,
        true,
        MENU_CONSTANTS.THEO_DOI_PHU_SAN,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          nameChiNhanh,
          nameChuyenKhoa,
          nameCompany,
        },
      )
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const detailErrorPayload = `Có lỗi xảy ra khi xuất excel báo cáo theo dõi phụ sản tổng hợp \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.THEO_DOI_PHU_SAN,
        detailErrorPayload,
      )
    }
  }
  //-------------- lấy danh sách chuyên kho ------------//
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  //---------- lấy danh sách chi nhánh theo công ty -----------//
  const fetchBranch = async (id) => {
    try {
      const { data } = await branchService.getListBranch(id)
      setBranch(data)
    } catch (error) {
      console.log('fetchBranch : ', error)
    }
  }
  const fetchData = async (id, dateForm, dateTo) => {
    try {
      let idcn = ''
      if (value[0] !== 'all') {
        idcn = value?.map((id) => `&idcn=${id}`).join('&')
      } else {
        // idChiNhanh = branch?.map((item) => item.idChiNhanh)
        idcn = branch?.map((id) => `&idcn=${id.idChiNhanh}`).join('&')
      }
      const { data } = await reportService.getBenhNhanCoThai(company, idcn, dateForm, dateTo)
      setData(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.BAO_CAO_THEO_DOI_PHU_SAN_TONG_HOP,
        true,
        MENU_CONSTANTS.THEO_DOI_PHU_SAN,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          nameChiNhanh,
          nameChuyenKhoa,
          nameCompany,
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.THEO_DOI_PHU_SAN,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }
  //---------- reload dữ liệu --------//
  const onLoad = () => {
    fetchData(idChiNhanh, dateForm, dateTo)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(company, value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (idct, value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(idct, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    // fetchBranch(id_company)
    fetchBranchByChuyenKhoa(company, null)
    fetchChuyenKhoa()
  }, [])

  useEffect(() => {
    if (infoUser) {
      fetchData(id, dateForm, dateTo)
    }
  }, [infoUser])
  return (
    <>
      <div className="flex gap-3 flex-wrap">
        <div className="w-80">
          <Select
            showSearch
            value={company}
            onChange={(value) => {
              setCompany(value)
              fetchBranch(value)
              setIdChiNhanh(null)
              setValue(['all'])
              setIdChuyenKhoa('')
              fetchBranchByChuyenKhoa(value, '')
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-full"
            disabled={infoUser && infoUser.idNhom === 3}
          />
        </div>
        <div className="w-56">
          <Select
            onChange={onChangeChuyenKhoa}
            className="w-full"
            value={idChuyenKhoa}
            options={[
              { label: 'Tất cả chuyên khoa', value: '' },
              ...ChuyenKhoa.map((items) => ({
                label: items.tenChuyenKhoa,
                value: items.idChuyenKhoa,
              })),
            ]}
            disabled={infoUser && infoUser.idNhom === 3}
          />
        </div>
        <div className="w-80">
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
            disabled={infoUser && infoUser.idNhom === 3}
          />
        </div>

        <div className="flex items-center gap-3">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-lg">-</span>
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        <div className="flex gap-3">
          <IconButton onClick={onLoad} color="primary" aria-label="delete" size="small">
            <SyncIcon />
          </IconButton>
        </div>
        <div className="font-semibold ">
          <Button
            disabled={!data?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div className="mt-1">
        <TableDetail data={data} isLoading={isLoading} />
      </div>
    </>
  )
}

export default TongHop
