import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import _ from 'lodash'
import * as typeAction from '../../../store/constants/constants'
import CachedIcon from '@mui/icons-material/Cached'
import * as XLSX from 'xlsx'
import {
  Tooltip,
  Select,
  ConfigProvider,
  Table,
  Button,
  Input,
  Tag,
  DatePicker,
  TreeSelect,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ContainerOutlined } from '@ant-design/icons'
import {
  branchTonKhoAction,
  ListGroupsVTByTypes,
  listTonKhoHangHoa,
  listTypesThuocVT_Action,
} from '../../../store/actions/thuocVTAction'
import Modaldetai from './ModalDetail/Modaldetai'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import moment from 'moment'
import { https } from '../../../services/apiService'
import { buildFilterCondition } from '../../../utils/buildFilterCondition'
import dayjs from 'dayjs'
import { buildDateFilterCondition } from '../../../utils/buildDateFilterCondition'
import { formatNumber } from 'devextreme/localization'
import { useSearchParams } from 'react-router-dom'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import 'moment/locale/vi'
import { khoVTservice } from '../../../services/khoVT/khoVTservice'
import { branchService } from '../../../services/branch/branchService'
import { reportService } from '../../../services/Report/reportService'
import { getListNameChiNhanh } from '../../../utils/algorithm'
import { addLog } from '../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../utils/log/logConstant'
moment.locale('vi')
const today = moment()
const formatDate = 'DD/MM/YYYY'
const formatDataDB = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

const TonKho = ({ kiemKe }) => {
  const id_company = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listThuocVT, TonHangHoa, typesVT, groupsVT } = useSelector(
    (state) => state.thuocVTReducer,
  )
  const ROLE_KHO_VIEWS = [41, 107, 108, 68, 61, 69, 225, 125, 136, 147, 255, 187, 197] // chỉ xem được các loại kho này
  const ID_NHOM_LE_TAN = 3
  const ID_NHOM_DUOC = 4
  const ID_NHOM_DIEU_DUONG = 38
  const { listCompany } = useSelector((state) => state.companyReducer)
  const idBranchDefault = Number(localStorage.getItem('BRANH_LOGIN'))
  const [types, setTypes] = useState(null)
  const [valueGroups, SetValueGroups] = useState(null)
  const [idChiNhanh, setIdChiNhanh] = useState([idBranchDefault])
  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState(id_company)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [listKho, setListKho] = useState([])
  const [khoCN, setKhoCN] = useState(null)
  const [searchMa, setSearchMa] = useState('')
  const [searchTenThuoc, setSearchTenThuoc] = useState('')
  const [searchHoatChat, setSearchHoatChat] = useState('')
  const [searchDonVi, setDonVi] = useState('')
  const [searchQuyCach, setSearchQuyCach] = useState('')
  const [searchBranch, setSearchBranch] = useState('')
  const [searchKho, setSearchKho] = useState('')
  const [searchLoai, setSearchLoai] = useState('')
  const [searchNhom, setSearchNhom] = useState('')
  const [filterXuat, setfilterXuat] = useState('=')
  const [searchXuat, setSearchXuat] = useState('')
  const [filterNhap, setfilterNhap] = useState('=')
  const [searchNhap, setSearchNhap] = useState('')
  const [filterSLChan, setfilterSLChan] = useState('=')
  const [searchSLChan, setSearchSLChan] = useState('')
  const [filterTon, setfilterTon] = useState('=')
  const [searchTon, setSearchTon] = useState('')
  const [searchSoLo, setSearchSoLo] = useState('')
  const [searchHD, setSearchHD] = useState([])
  const [searchDate, setSearchDate] = useState('')
  const [filterDate, setFilterDate] = useState('=')
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [dataTo, setDateTo] = useState(today.format(formatDataDB))
  const [searchText, setSearchText] = useState(null)
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [chuyenKhoa, setChuyenKhoa] = useState(null)
  const [branchTonKho, setBranchTonKho] = useState([])
  const dispatch = useDispatch()

  const maQuyenXemDs = kiemKe ? 'QHT231' : 'QHT201'
  const maQuyenXemCt = kiemKe ? 'QHT636' : 'QHT202'
  const maQuyenXuatExcel = kiemKe ? 'QHT232' : 'QHT203'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        let idchiNhanhFormat = ''
        if (idChiNhanh[0] !== 'all') {
          idchiNhanhFormat = idChiNhanh?.map((id) => `&idcn=${id}`).join('&')
        }
        dispatch(
          listTonKhoHangHoa(
            company,
            idchiNhanhFormat,
            khoCN,
            types,
            valueGroups,
            dataTo,
            1,
            searchText,
            chuyenKhoa,
            setLoading,
            {
              nameCompany,
              nameLoaiThuocVT,
              nameNhomThuocVT,
              nameKho,
              keyword: searchText ?? '',
              nameChiNhanh,
            },
          ),
        )
        break
      case maQuyenXemCt:
        showModal()
        break
      case maQuyenXuatExcel:
        exportToExcel()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXemCt:
        handleCancel()
        break
      case maQuyenXuatExcel:
        //
        break
      default:
        return false
    }
  }

  const nameCompany = useMemo(() => {
    return listCompany?.find((item) => item.idct === company)?.tenct
  }, [company, listCompany])

  const nameChiNhanh = useMemo(() => {
    return getListNameChiNhanh(idChiNhanh, branchTonKho).join(', ')
  }, [idChiNhanh, branchTonKho])

  const nameLoaiThuocVT = useMemo(() => {
    return !types ? 'Tất cả' : typesVT?.find((item) => item.idPhanLoai === types)?.phanLoai
  }, [types, typesVT])

  const nameNhomThuocVT = useMemo(() => {
    return !valueGroups ? 'Tất cả' : groupsVT?.find((item) => item.idNhom === valueGroups)?.nhom
  }, [valueGroups, groupsVT])

  const nameKho = useMemo(() => {
    return !khoCN ? 'Tất cả' : listKho?.find((item) => item.idkho === khoCN)?.tenkho
  }, [khoCN, listKho])

  const handleSearch = (name) => (e) => {
    const { value } = e.target
    switch (name) {
      case 'ma':
        setSearchMa(value || '')
        break
      case 'tenBietDuoc':
        setSearchTenThuoc(value || '')
        break
      case 'tenHoatChat':
        setSearchHoatChat(value || '')
        break
      case 'dvt':
        setDonVi(value || '')
        break
      case 'quyCach':
        setSearchQuyCach(value || '')
        break
      case 'tongXuat':
        setSearchXuat(value || '')
        break
      case 'tongNhap':
        setSearchNhap(value || '')
        break
      case 'SLChan':
        setSearchSLChan(value || '')
        break
      case 'tonKho':
        setSearchTon(value || '')
        break
      case 'soLo':
        setSearchSoLo(value || '')
        break
      case 'soHoaDon':
        setSearchHD(value || '')
        break
      case 'tenPhanLoai':
        setSearchLoai(value || '')
        break
      case 'tenNhom':
        setSearchNhom(value || '')
        break
      default:
        break
    }
  }
  const handleSearchHD = (value) => {
    setSearchHD(value || '')
  }
  const handleSelected = (value) => {
    setSearchBranch(value || '')
  }
  const handleSearchKho = (value) => {
    setSearchKho(value || '')
  }
  const searchKhoTon = () => {
    // SetSearchParams({ page: 1 })
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const infoTonKho = (info) => {
    dispatch({
      type: typeAction.DISPATCH_INFO_TON_kHO,
      payload: info,
    })
  }
  const handleCompany = (value) => {
    setCompany(value)
    fetchBranch(value)
    setIdChiNhanh(null)
    getKhoTheoCty(value)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const filteredData = TonHangHoa?.data?.filter(
    ({
      thuocVatTu,
      tenCN,
      tenKhoCn,
      soHoaDon,
      tongXuat,
      tongNhap,
      tonKho,
      soLo,
      hanDung,
      tenPhanLoai,
      tenNhom,
    }) => {
      const soLuongChan = Math.floor(tonKho / thuocVatTu?.quyCachDongGoi) // Calculate full packages
      const soLuongLe = tonKho - soLuongChan * thuocVatTu?.quyCachDongGoi // Calculate remaining loose units
      let displaySoLuong

      // Format the quantity display
      if (soLuongChan > 0 && soLuongLe > 0) {
        displaySoLuong = `${soLuongChan} ${thuocVatTu?.donViChan} ${soLuongLe} ${thuocVatTu?.dvt}`
      } else if (soLuongChan > 0) {
        displaySoLuong = `${soLuongChan} ${thuocVatTu?.donViChan}`
      } else {
        displaySoLuong = `${soLuongLe} ${thuocVatTu?.dvt}`
      }
      const displaySoLuongLower = displaySoLuong.toLowerCase()
      const matches = (field, keyword) => {
        const search = keyword?.toString().trim().toLowerCase() || '' // Chuyển keyword về chuỗi
        return !search || field?.toLowerCase().includes(search)
      }
      return (
        matches(thuocVatTu?.maThuoc, searchMa) &&
        matches(thuocVatTu?.tenBietDuoc, searchTenThuoc) &&
        matches(thuocVatTu?.tenHoatChat, searchHoatChat) &&
        matches(thuocVatTu?.dvt, searchDonVi) &&
        matches(thuocVatTu?.quyCach, searchQuyCach) &&
        matches(tenCN, searchBranch) &&
        matches(soLo, searchSoLo) &&
        matches(soHoaDon, searchHD) &&
        matches(displaySoLuongLower, searchSLChan) &&
        matches(tenPhanLoai, searchLoai) &&
        matches(tenNhom, searchNhom) &&
        matches(tenKhoCn, searchKho) &&
        buildFilterCondition(filterXuat, searchXuat, tongXuat) &&
        buildFilterCondition(filterNhap, searchNhap, tongNhap) &&
        (displaySoLuongLower.includes(searchSLChan.toLowerCase()) || !displaySoLuongLower) &&
        buildFilterCondition(filterTon, searchTon, tonKho) &&
        buildDateFilterCondition(filterDate, searchDate, hanDung)
      )
    },
  )
  const handleXuatExcel = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXuatExcel, null, actionIsTrue, actionIsFalse))
  }
  const exportToExcel = () => {
    try {
      const formattedData = filteredData?.map((item) => {
        const soLuongChan = Math.floor(item.tonKho / item.thuocVatTu?.quyCachDongGoi) // Calculate full packages
        const soLuongLe = item.tonKho - soLuongChan * item.thuocVatTu?.quyCachDongGoi // Calculate remaining loose units
        let displaySoLuong

        // Format the quantity display
        if (soLuongChan > 0 && soLuongLe > 0) {
          displaySoLuong = `${soLuongChan} ${item.thuocVatTu?.donViChan} ${soLuongLe} ${item.thuocVatTu?.dvt}`
        } else if (soLuongChan > 0) {
          displaySoLuong = `${soLuongChan} ${item.thuocVatTu?.donViChan}`
        } else {
          displaySoLuong = `${soLuongLe} ${item.thuocVatTu?.dvt}`
        }
        return {
          'Mã thuốc': item.thuocVatTu?.maThuoc,
          'Tên thuốc': item.thuocVatTu?.tenBietDuoc,
          'Hoạt chất': item.thuocVatTu?.tenHoatChat,
          'Đơn vị tính': item.thuocVatTu?.dvt,
          'Quy cách': item.thuocVatTu?.quyCach,
          'Chi nhánh': item.tenCN,
          'Kho chi nhánh': item.tenKhoCn,
          'Nhập kho': item.tongNhap,
          'Xuất kho': item.tongXuat,
          'Tồn chẵn': displaySoLuong,
          'Tồn kho lẻ': item.tonKho,
          'Số lô': item.soLo,
          'Hạn dùng': item.hanDung && moment(item.hanDung).toDate(),
          'Số hoá đơn': item.soHoaDon,
          'Loại thuốc vật tư': types
            ? typesVT.find((type) => type.idPhanLoai === types)?.phanLoai
            : '',
          'Nhóm thuốc vật tư': valueGroups
            ? groupsVT.find((group) => group.idNhom === valueGroups)?.nhom
            : '',
        }
      })
      const name = 'Danh Sách tồn kho'
      formattedData?.length && exportExcelformat(formattedData, name)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_TON_KHO_CHI_TIET,
        true,
        MENU_CONSTANTS.TON_KHO,
        null,
        {
          nameCompany,
          nameLoaiThuocVT,
          nameNhomThuocVT,
          nameKho,
          keyword: searchText ?? '',
          nameChiNhanh,
          dateTo: dataTo,
        },
      )
    } catch (error) {
      console.log(error)
      const detailErrorPayload = `Có lỗi xảy ra khi xuất file excel tồn kho chi tiết \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.TON_KHO,
        detailErrorPayload,
      )
    }
  }
  const getUniqueFilters = (data, dataIndex) => {
    const uniqueValues = Array.from(new Set(TonHangHoa?.data?.map((item) => item[dataIndex]))) // Lấy các giá trị không trùng
    return uniqueValues?.map((value) => ({
      label: value,
      value: value,
    }))
  }
  const calculateTotals = (data) => {
    return data?.reduce(
      (totals, { tongNhap, tonKho, tongXuat, thuocVatTu }) => {
        totals.tongNhap += tongNhap || 0
        totals.tonKho += tonKho || 0
        totals.tongXuat += tongXuat || 0
        totals.tongSoluongChan += Math.trunc(tonKho / thuocVatTu?.quyCachDongGoi) || 0
        return totals
      },
      { tongNhap: 0, tonKho: 0, tongXuat: 0, tongSoluongChan: 0 },
    )
  }
  const totals = calculateTotals(filteredData)
  const searchDebounce = useCallback(
    _.debounce((e) => {
      let { value } = e.target
      setSearchText(value.trim())
      SetSearchParams({ page: 1 })
    }, 300),
    [],
  )
  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (idct, value) => {
    try {
      const { data } = await branchService.getBranchsByIdCtyAndIdChuyenKhoa(idct, value)
      setBranchTonKho(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranch = async (id) => {
    try {
      const { data } = await branchService.getListBranch(id)
      setBranchTonKho(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    dispatch(branchTonKhoAction(id_company))
    fetchBranchByChuyenKhoa(id_company, '')
  }, [])

  useEffect(() => {
    if (khoCN !== null) {
      searchKhoTon()
    }
  }, [searchText, khoCN])

  const getKhoTheoCty = async (value) => {
    try {
      const { data } = await https.get(`Kho/GetByCongTy?IDCT=${value}`)
      setListKho(data.result)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeDatePicker = (date, dateString) => {
    const dateFormat = moment(dateString, formatDate).format(formatDataDB)
    setDateTo(dateFormat)
  }
  const PhepTinh = [
    { label: '>', value: '>' },
    { label: '<', value: '<' },
    { label: '=', value: '=' },
  ]
  useEffect(() => {
    getKhoTheoCty(company)
  }, [])
  const compare = (a, b, dataIndex) => {
    const valueA = (_.get(a, dataIndex) ?? '').toString().toLowerCase()
    const valueB = (_.get(b, dataIndex) ?? '').toString().toLowerCase()
    if (valueA < valueB) {
      return -1
    }
    if (valueA > valueB) {
      return 1
    }
    return 0
  }
  useEffect(() => {
    if (listKho.length !== 0) {
      setKhoCN(viewsRoleKho(listKho)[0].value)
    }
  }, [listKho])
  const dataSource = filteredData?.map(
    (
      {
        soHoaDon,
        tenPhanLoai,
        tenNhom,
        thuocVatTu,
        linkHoaDon,
        tenCN,
        tenKhoCn,
        tongNhap,
        tongXuat,
        tonKho,
        hanDung,
        soLo,
      },
      index,
    ) => {
      const soLuongChan = Math.floor(tonKho / thuocVatTu?.quyCachDongGoi) // Calculate full packages
      const soLuongLe = tonKho - soLuongChan * thuocVatTu?.quyCachDongGoi // Calculate remaining loose units
      let displaySoLuong

      // Format the quantity display
      if (soLuongChan > 0 && soLuongLe > 0) {
        displaySoLuong = `${soLuongChan} ${thuocVatTu?.donViChan} ${soLuongLe} ${thuocVatTu?.dvt}`
      } else if (soLuongChan > 0) {
        displaySoLuong = `${soLuongChan} ${thuocVatTu?.donViChan}`
      } else {
        displaySoLuong = `${soLuongLe} ${thuocVatTu?.dvt}`
      }
      return {
        STT: ++index,
        maThuoc: thuocVatTu?.maThuoc,
        tenBietDuoc: thuocVatTu?.tenBietDuoc,
        tenHoatChat: thuocVatTu?.tenHoatChat,
        dvt: thuocVatTu?.dvt,
        quyCach: thuocVatTu?.quyCach,
        tenCN,
        tenKhoCn,
        tongNhap,
        tongXuat,
        tonKho,
        soLo,
        tenPhanLoai,
        linkHoaDon,
        tenNhom,
        SLChan: displaySoLuong,
        hanDung: hanDung && moment(hanDung).format('DD/MM/YYYY'),
        soHoaDon,
        action: (
          <ul className="flex justify-around">
            <li
              onClick={() =>
                infoTonKho({ thuocVatTu, tenCN, tenKhoCn, tongNhap, tongXuat, tonKho, hanDung })
              }
            >
              <Tooltip title="Xem chi tiết" color="#108ee9">
                <ContainerOutlined
                  // onClick={showModal}
                  onClick={() =>
                    dispatch(
                      putCheckQuyenThaoTacAction(maQuyenXemCt, null, actionIsTrue, actionIsFalse),
                    )
                  }
                  className="text-xl text-[#108ee9] cursor-pointer"
                />
              </Tooltip>
            </li>
          </ul>
        ),
      }
    },
  )
  const columSummary = () => {
    const NumberColum = 17
    const tonKhoData = {
      8: totals?.tongNhap,
      9: totals?.tongXuat,
      // 12: totals?.tongSoluongChan,
      11: totals?.tonKho,
    }
    return Array.from({ length: NumberColum }, (_, i) => ({
      key: i,
      value: formatNumber(tonKhoData[i]) ?? null,
    }))
  }
  const summaryData = columSummary()
  const handleTableChange = (pagination) => {
    SetSearchParams({ page: pagination.current })
  }
  const checkExpiration = (hanDung) => {
    if (!hanDung) return false
    const expirationDate = moment(hanDung, 'DD/MM/YYYY')
    const currentDatePlusThreeMonths = moment().add(3, 'months').endOf('day')
    return expirationDate.isBefore(currentDatePlusThreeMonths)
  }
  const viewsRoleKho = (data) => {
    // Nếu là lễ tân chỉ lấy kho: VPP, Tài sản, Vận hành
    let newData = []
    if (infoUser?.idNhom !== ID_NHOM_LE_TAN) {
      newData = [
        {
          tenKho: 'Tất cả',
          idKho: '',
        },
        ...data,
      ]
    } else {
      newData = data?.filter((item) => ROLE_KHO_VIEWS.includes(item.idKho))
    }
    return newData?.map((item) => ({
      label: item.tenKho,
      value: item.idKho,
    }))
  }
  const handleTypesThuocVT = (value) => {
    // get list nhóm thuốc vật tư sau khi chọn loại
    setTypes(value)
    SetValueGroups(null)
    value && dispatch(ListGroupsVTByTypes(value))
  }
  const BlokRoleUser = () =>
    [ID_NHOM_DUOC, ID_NHOM_LE_TAN, ID_NHOM_DIEU_DUONG].includes(infoUser?.idNhom)
  const isBlockView = BlokRoleUser()
  const getListChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setListChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchBranch(company)
    getListChuyenKhoa()
    dispatch(listTypesThuocVT_Action())
  }, [])

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branchTonKho?.map((branch) => ({
        label: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]

  const onChange = (newValue) => {
    setIdChiNhanh(newValue)
  }
  const tProps = {
    treeData,
    value: idChiNhanh,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.label && treeNode.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-5">
          {infoUser?.idNhom !== ID_NHOM_LE_TAN && (
            <>
              {' '}
              <div className="font-semibold w-64 flex gap-1">
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  showSearch
                  onChange={handleCompany}
                  value={company}
                  options={listCompany?.map(({ idct, tenct }) => ({
                    label: tenct,
                    value: idct,
                  }))}
                  className="w-full"
                />
              </div>
              <div className="font-semibold w-60 flex gap-1">
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  showSearch
                  onChange={(value) => {
                    setChuyenKhoa(value)
                    fetchBranchByChuyenKhoa(company, value)
                    setIdChiNhanh(['all'])
                  }}
                  value={chuyenKhoa}
                  options={[
                    { label: 'Tất cả chuyên khoa', value: null },
                    ...listChuyenKhoa?.map((item) => ({
                      label: item.tenChuyenKhoa,
                      value: item.idChuyenKhoa,
                    })),
                  ]}
                  className="w-full"
                />
              </div>
              <div className="font-semibold w-60 flex gap-1">
                <TreeSelect
                  {...tProps}
                  style={{ flexWrap: 'unset' }}
                  className="w-full h-8 overflow-clip"
                />
              </div>
            </>
          )}

          <div className="font-semibold w-48 flex gap-1">
            <Select
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              value={khoCN}
              showSearch
              onChange={(value) => setKhoCN(value)}
              options={viewsRoleKho(listKho)}
              className="w-full"
            />
          </div>
          <div className="flex items-center">
            <DatePicker
              format={formatDate}
              allowClear={false}
              value={dayjs(dataTo, formatDataDB)}
              onChange={onChangeDatePicker}
              maxDate={dayjs(today)}
            />
          </div>
          <div className="font-semibold flex gap-5 items-center">
            <div
              className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
              onClick={searchKhoTon}
            >
              <CachedIcon sx={{ fontSize: 25 }} />
            </div>
          </div>
        </div>
        <div className="flex gap-2 ml-5">
          <Input.Search
            placeholder="Nhập từ khoá tìm kiếm "
            onChange={searchDebounce}
            className=" w-64 2xl:block hidden"
          />
          <Button
            disabled={!filteredData?.length}
            onClick={handleXuatExcel}
            type="text"
            size="middle"
            className="text-green-700 hidden xl:flex"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
              />
            </svg>
            Xuất excel
          </Button>
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <Input.Search
          placeholder="Nhập từ khoá tìm kiếm "
          onChange={searchDebounce}
          className="2xl:hidden block mt-2"
        />
        <Button
          disabled={!filteredData?.length}
          onClick={handleXuatExcel}
          type="text"
          size="middle"
          className="text-green-700 xl:hidden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            />
          </svg>
          Xuất excel
        </Button>
      </div>
      <div className="flex gap-2 mt-2">
        <div className="w-60 gap-1 items-center flex">
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            value={types}
            onChange={handleTypesThuocVT}
            placeholder="Loại Thuốc Vật tư"
            className="w-full"
            options={typesVT
              ?.filter((item) => item.loai !== 'THUOC')
              .map((items) => ({
                label: items.phanLoai,
                value: items.idPhanLoai,
                key: items.idPhanLoai,
              }))}
          />
        </div>
        <div className="font-semibold 2xl:w-80 w-60 flex gap-1 items-center">
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={SetValueGroups}
            value={valueGroups}
            options={
              types && groupsVT?.length
                ? groupsVT?.map((items) => ({
                    label: items.nhom,
                    value: items.idNhom,
                    key: items.idNhom,
                  }))
                : []
            }
            placeholder="Nhóm Thuốc Vật tư"
            className="w-full truncate"
          />
        </div>
      </div>

      <div className="mt-2 ">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
              borderRadius: 0,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            rowClassName={(record) => (checkExpiration(record.hanDung) ? 'bg-orange-300' : '')}
            loading={loading}
            pagination={false}
            onChange={handleTableChange}
            bordered
            scroll={{
              x: 1500,
              y: '60vh',
            }}
            columns={[
              {
                title: 'STT',
                dataIndex: 'STT',
                width: 60,
                align: 'center',
                fixed: 'left',
                render: (text, record, index) => {
                  return (useSearch.get('page') - 1) * 100 + index + 1
                },
              },
              {
                title: 'Mã hàng',
                dataIndex: 'maThuoc',
                width: 120,
                fixed: 'left',
                sorter: (a, b) => compare(a, b, 'maThuoc'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input
                          size="small"
                          onChange={(e) => setSearchMa(e.target.value)}
                          value={searchMa}
                        />
                      </>
                    ),
                    width: 120,
                    dataIndex: 'maThuoc',
                    fixed: 'left',
                    key: 1,
                  },
                ],
              },
              {
                title: 'Tên hàng',
                dataIndex: 'tenBietDuoc',
                width: 250,
                fixed: 'left',
                sorter: (a, b) => compare(a, b, 'tenBietDuoc'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input
                          size="small"
                          onChange={handleSearch('tenBietDuoc')}
                          value={searchTenThuoc}
                        />
                      </>
                    ),
                    dataIndex: 'tenBietDuoc',
                    key: 2,
                    width: 250,
                    fixed: 'left',
                  },
                ],
              },
              // {
              //   title: 'Hoạt chất',
              //   dataIndex: 'tenHoatChat',
              //   width: 350,
              //   sorter: (a, b) => compare(a, b, 'tenHoatChat'),
              //   showSorterTooltip: false,
              //   children: [
              //     {
              //       title: (
              //         <>
              //           <Input
              //             size="small"
              //             onChange={handleSearch('tenHoatChat')}
              //             value={searchHoatChat}
              //           />
              //         </>
              //       ),
              //       dataIndex: 'tenHoatChat',
              //       key: 2,
              //       width: 350,
              //     },
              //   ],
              // },
              {
                title: 'Phân loại',
                dataIndex: 'tenPhanLoai',
                width: 150,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'tenPhanLoai'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input
                          size="small"
                          onChange={handleSearch('tenPhanLoai')}
                          value={searchLoai}
                        />
                      </>
                    ),
                    dataIndex: 'tenPhanLoai',
                    key: 2,
                    width: 200,
                  },
                ],
              },
              {
                title: 'Nhóm hàng',
                dataIndex: 'tenNhom',
                width: 100,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'tenNhom'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input size="small" onChange={handleSearch('tenNhom')} value={searchNhom} />
                      </>
                    ),
                    dataIndex: 'tenNhom',
                    key: 2,
                    width: 200,
                  },
                ],
              },
              {
                title: 'Đơn vị tính',
                dataIndex: 'dvt',
                width: 100,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'dvt'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input size="small" onChange={handleSearch('dvt')} value={searchDonVi} />
                      </>
                    ),
                    dataIndex: 'dvt',
                    key: 2,
                    width: 100,
                  },
                ],
              },

              // {
              //   title: 'Quy cách',
              //   dataIndex: 'quyCach',
              //   align: 'center',
              //   width: 120,
              //   sorter: (a, b) => compare(a, b, 'quyCach'),
              //   showSorterTooltip: false,
              //   children: [
              //     {
              //       title: (
              //         <>
              //           <Input
              //             size="small"
              //             onChange={handleSearch('quyCach')}
              //             value={searchQuyCach}
              //           />
              //         </>
              //       ),
              //       dataIndex: 'quyCach',
              //       key: 2,
              //       width: 120,
              //     },
              //   ],
              // },
              {
                title: 'Chi nhánh',
                dataIndex: 'tenCN',
                width: 230,
                align: 'left',
                sorter: (a, b) => compare(a, b, 'tenCN'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Select
                          size="small"
                          className="w-full"
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          showSearch
                          onChange={handleSelected}
                          value={searchBranch}
                          options={[
                            { label: 'Tất cả', value: '' },
                            ...branchTonKho?.map((item) => ({
                              label: item.tenChiNhanh,
                              value: item.tenChiNhanh,
                            })),
                          ]}
                        />
                      </>
                    ),
                    dataIndex: 'tenCN',
                    key: 2,
                    width: 230,
                  },
                ],
              },
              {
                title: 'Kho chi nhánh',
                dataIndex: 'tenKhoCn',
                width: 150,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'tenKhoCn'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Select
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          size="small"
                          value={searchKho}
                          showSearch
                          onChange={handleSearchKho}
                          options={[
                            { label: 'Tất cả', value: '' },
                            ...listKho.map((item) => ({
                              label: item.tenKho,
                              value: item.tenKho,
                              key: item.tenKho, // Thêm key cho mỗi tùy chọn
                            })),
                          ]}
                          className="w-full"
                        />
                      </>
                    ),
                    dataIndex: 'tenKhoCn',
                    key: 2,
                    width: 150,
                  },
                ],
              },
              {
                title: 'Nhập kho',
                dataIndex: 'tongNhap',
                width: 130,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'tongNhap'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <div className="flex gap-1">
                        <Select
                          onChange={(value) => setfilterNhap(value)}
                          value={filterNhap}
                          size="small"
                          className="w-16"
                          options={PhepTinh}
                        />
                        <Input
                          size="small"
                          type="Number"
                          value={searchNhap}
                          onChange={handleSearch('tongNhap')}
                        />
                      </div>
                    ),
                    dataIndex: 'tongNhap',
                    key: 2,
                    width: 130,
                    render: (text) =>
                      text < 0 ? (
                        <Tag color="error" bordered={false}>
                          {text}
                        </Tag>
                      ) : (
                        text
                      ),
                  },
                ],
              },
              {
                title: 'Xuất kho',
                dataIndex: 'tongXuat',
                width: 130,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'tongXuat'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <div className="flex gap-1">
                        <Select
                          onChange={(value) => setfilterXuat(value)}
                          value={filterXuat}
                          size="small"
                          className="w-16"
                          options={PhepTinh}
                        />
                        <Input
                          size="small"
                          type="Number"
                          value={searchXuat}
                          onChange={handleSearch('tongXuat')}
                        />
                      </div>
                    ),
                    dataIndex: 'tongXuat',
                    key: 2,
                    width: 130,
                    render: (text) =>
                      text < 0 ? (
                        <Tag color="error" bordered={false}>
                          {text}
                        </Tag>
                      ) : (
                        text
                      ),
                  },
                ],
              },
              {
                title: 'SL chẵn',
                dataIndex: 'SLChan',
                width: 130,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'SLChan'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input
                          size="small"
                          value={searchSLChan}
                          onChange={handleSearch('SLChan')}
                        />
                      </>
                    ),
                    dataIndex: 'SLChan',
                    key: 2,
                    width: 130,
                    render: (text) =>
                      text < 0 ? (
                        <Tag color="error" bordered={false}>
                          {text}
                        </Tag>
                      ) : (
                        text
                      ),
                  },
                ],
              },
              {
                title: 'Tồn kho SL lẻ',
                dataIndex: 'tonKho',
                width: 130,
                align: 'center',

                sorter: (a, b) => compare(a, b, 'tonKho'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <div className="flex gap-1">
                        <Select
                          onChange={(value) => setfilterTon(value)}
                          value={filterTon}
                          size="small"
                          className="w-16"
                          options={PhepTinh}
                        />
                        <Input
                          size="small"
                          type="Number"
                          value={searchTon}
                          onChange={handleSearch('tonKho')}
                        />
                      </div>
                    ),
                    dataIndex: 'tonKho',
                    key: 2,
                    width: 130,
                    render: (text) =>
                      text < 0 ? (
                        <Tag color="error" bordered={false}>
                          {text}
                        </Tag>
                      ) : (
                        text
                      ),
                  },
                ],
              },
              {
                title: 'Số lô',
                dataIndex: 'soLo',
                width: 90,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'soLo'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Input size="small" onChange={handleSearch('soLo')} value={searchSoLo} />
                      </>
                    ),
                    dataIndex: 'soLo',
                    key: 2,
                    width: 90,
                  },
                ],
              },
              {
                title: 'Hạn dùng',
                dataIndex: 'hanDung',
                width: 160,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'hanDung'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <div className="flex gap-1">
                        <Select
                          onChange={(value) => setFilterDate(value)}
                          value={filterDate}
                          size="small"
                          className="w-16"
                          options={PhepTinh}
                        />
                        <DatePicker
                          value={searchDate !== '' ? dayjs(searchDate, 'YYYY-MM-DD') : ''}
                          size="small"
                          format={'DD/MM/YYYY'}
                          onChange={(date, DateString) => {
                            setSearchDate(
                              DateString
                                ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD')
                                : '',
                            )
                          }}
                        />
                      </div>
                    ),
                    dataIndex: 'hanDung',
                    key: 2,
                    width: 160,
                  },
                ],
              },
              {
                title: 'Số HĐ',
                dataIndex: 'soHoaDon',
                width: 100,
                align: 'center',
                sorter: (a, b) => compare(a, b, 'soHoaDon'),
                showSorterTooltip: false,
                children: [
                  {
                    title: (
                      <>
                        <Select
                          mode="multiple"
                          size="small"
                          value={searchHD}
                          onChange={handleSearchHD}
                          options={getUniqueFilters(TonHangHoa?.data, 'soHoaDon')}
                          style={{
                            width: '100%',
                            height: '24px',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        />
                        {/* <Input size="small" onChange={handleSearch('soHoaDon')} value={searchHD} /> */}
                      </>
                    ),
                    dataIndex: 'soHoaDon',
                    key: 2,
                    width: 150,
                  },
                ],
              },
              {
                title: 'Link đính kèm',
                dataIndex: 'linkHoaDon',
                key: 'linkHoaDon',
                width: 500,
                render: (text, record) => {
                  if (record.tonKho === 0 || !text) return null // Kiểm tra tonKho trước
                  const links = text
                    .split(';')
                    .map((link) => link.trim())
                    .filter((link) => link)
                  return links.map((link, index) => {
                    const fileName = link.split('/').pop() // Lấy phần tên file
                    return (
                      <React.Fragment key={index}>
                        <a href={link} target="_blank" rel="noopener noreferrer">
                          {fileName}
                        </a>
                        {index < links.length - 1 && ', '}
                      </React.Fragment>
                    )
                  })
                },
              },
              {
                title: 'Hành động',
                dataIndex: 'action',
                width: 100,
                align: 'center',
                fixed: 'right',
              },
            ].filter((item) => !(isBlockView && ['linkHoaDon'].includes(item.key)))}
            dataSource={dataSource}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  {summaryData?.map((item) => (
                    <Table.Summary.Cell index={item.key}>
                      {' '}
                      <p className="font-semibold">{item.value}</p>
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
        {/* <div className="flex justify-end">
              <Pagination
                className="mt-5 "
                showSizeChanger={false}
                current={+useSearch.get('page')}
                simple
                defaultCurrent={1}
                pageSize={100}
                total={tonKho?.totalCount}
                onChange={(page, pagesize) => {
                  SetSearchParams({ page })
                }}
              />
            </div> */}
      </div>
      <Modaldetai handleCancel={handleCancel} isModalOpen={isModalOpen} />
    </>
  )
}
export default TonKho
