import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Modal,
  Divider,
  notification,
  Tabs,
  ConfigProvider,
  Table,
  DatePicker,
  Tooltip,
  Steps,
  Button,
  InputNumber,
} from 'antd'
import {
  PlusOutlined,
  CloseOutlined,
  StopOutlined,
  DeleteOutlined,
  QuestionCircleFilled,
  CheckOutlined,
  ArrowUpOutlined,
  ClockCircleOutlined,
  GiftOutlined,
} from '@ant-design/icons'
import { Button as ButtonMui } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import BNEdit from './BNEdit/BNEdit'
import InfoBN from './InfoBN/InfoBN'
import CardEdit from './CardEdit/CardEdit'
import {
  getHistoryMemberCardAction,
  resetFormTTV,
  putCreateMemberCardTTTAction,
  putUpgradeMemberCardAction,
  putExtendMemberCardAction,
  putPauseMemberCardAction,
  putDisableMemberCardAction,
  putRueseMemberCardAction,
} from '../../../../store/actions/cardAction'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import * as typeAction from '../../../../store/constants/constants'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import CardTTV from './TypeCard/CardTTV'
import CardUpgrade from './CardUpgrade/CardUpgrade'
import CardExtend from './CardExtend/CardExtend'
import CardTang from './CardTang/CardTang'
import moment from 'moment'
import dayjs from 'dayjs'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
import CardQuaTangExtend from './CardQuaTangExtend/CardQuaTangExtend'
moment.locale('vi')
const ModalEdit = ({ isModalEdit, setIsModalEdit, formLoad, keyTabs, setKeyTabs }) => {
  const now = moment()
  const dateFormat = 'DD/MM/YYYY'
  const dateFormatAPI = 'YYYY-MM-DD'
  const dispatch = useDispatch()
  const {
    historyMemberCard,
    lichSuMuaTTV,
    detailCard,
    infoBN,
    infoBNthu2,
    tongGiamGia,
    tongThanhToan,
    infoPaymentCard,
    discountArr,
    soLanKham,
    infoVoucherDoiTac,
  } = useSelector((state) => state.cartReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [dateForm, setDateForm] = useState(now.format(dateFormatAPI))
  const [dateTo, setDateTo] = useState(now.format(dateFormatAPI))
  // const [tongThanhTienHistory, setTongThanhTienHistory] = useState(0)
  // const [tongTienGiamHistory, setTongTienGiamHistory] = useState(0)
  // const [tongThanhToanHistory, setTongThanhToanHistory] = useState(0)
  const [isEditTTV, setIsEditTTV] = useState(false)
  const [isUpgradeTTV, setIsUpgradeTTV] = useState(false)
  const [isExtendTTV, setIsExtendTTV] = useState(false)
  const [isDowngradeTTV, setIsDowngradeTTV] = useState(false)
  const [isTangTTV, setIsTangTTV] = useState(false)
  const [hieuLucCheck, setHieuLucCheck] = useState(false)
  const [ngayBaoLuu, setNgayBaoLuu] = useState(null)
  const [formTTV, setFormTTV] = useState({})
  const [isDoiTac, setIsDoiTac] = useState(false)
  const [valueChuongTrinhDoiTac, setValueChuongTrinhDoiTac] = useState('')
  const [valueGiaVoucherDoiTac, setValueGiaVoucherDoiTac] = useState(0)
  const [dateBaoLuu, setDateBaoLuu] = useState(now.format(dateFormatAPI))
  const tienHoanTheRef = useRef(0)
  const [form, setForm] = useState({
    tienmat: 0,
    chuyenkhoan: 0,
    thett: 0,
    momo: 0,
    vnpay: 0,
    zalopay: 0,
    pos: 0,
    tongtien: 0,
    tiengiamgia: tongGiamGia,
    tienthanhtoan: tongThanhToan,
    trangthaithanhtoan: 2,
  })
  const [formQuaTang, setFormQuaTang] = useState({
    idThuoc: 0,
    tenBietDuoc: '',
    soLuong: 0,
    donGia: 0,
    thanhTien: 0,
    tongTien: 0,
    nguoiThucHien: 0,
    idKhoCN: 0,
    soLo: '',
    hanDung: null,
    soHoaDon: '',
  })

  const clearGiftForm = () => {
    setIsQuaTang(false)
    setValueChuongTrinhQuaTang('')
    setValueSoLuongQuaTang(1)
    setFormQuaTang({
      idThuoc: 0,
      tenBietDuoc: '',
      soLuong: 0,
      donGia: 0,
      thanhTien: 0,
      tongTien: 0,
      nguoiThucHien: 0,
      idKhoCN: 0,
      soLo: '',
      hanDung: null,
      soHoaDon: '',
    })
  }

  console.log('formQuaTang', formQuaTang)
  // -------------- quà tặng ---------------
  const [isQuaTang, setIsQuaTang] = useState(false)
  const [valueChuongTrinhQuaTang, setValueChuongTrinhQuaTang] = useState('')
  const [valueSoLuongQuaTang, setValueSoLuongQuaTang] = useState(0)

  const handleChangeQuaTang = (e) => {
    setIsQuaTang(e.target.checked)
    if (!e.target.checked) {
      // Reset form when unchecking
      setFormQuaTang({
        idThuoc: 0,
        tenBietDuoc: '',
        soLuong: 0,
        donGia: 0,
        thanhTien: 0,
        tongTien: 0,
        nguoiThucHien: 0,
        idKhoCN: 0,
        soLo: '',
        hanDung: null,
        soHoaDon: '',
      })
    }
  }

  //------------ quyền người dùng ----------
  const isLeTan = ['Lễ tân '].includes(infoUser?.tenNhom)
  const resetStore = () => {
    dispatch({
      type: typeAction.RESET_STORE_LICHSU,
    })
    // setTongThanhTienHistory(0)
    // setTongTienGiamHistory(0)
    // setTongThanhToanHistory(0)
  }
  const handleCheckQuyenThaoTac = async (maquyen) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, maquyen)
      maquyen === 'QHT048' && setIsExtendTTV(true)
      maquyen === 'QHT047' && setIsUpgradeTTV(true)
      maquyen === 'QHT122' && setIsTangTTV(true)
      maquyen === 'QHT119' && showConfirm('huythe')
      maquyen === 'QHT049' && showConfirm('vohieuluc')
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const handleClose = () => {
    if (discountArr || infoVoucherDoiTac) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng hủy áp dụng voucher trước khi đóng!',
      })
      return
    }
    setDateForm(now.format(dateFormatAPI))
    setDateTo(now.format(dateFormatAPI))
    setIsModalEdit(false)
    setIsEditTTV(false)
    setIsUpgradeTTV(false)
    setIsExtendTTV(false)
    setIsDowngradeTTV(false)
    setIsTangTTV(false)
    setCurrent(0)
    resetStore()
  }
  const handleDatePicker = (date, dateString, name) => {
    if (name === 'from') {
      setDateForm(moment(dateString, dateFormat).format(dateFormatAPI))
    } else if (name === 'to') {
      setDateTo(moment(dateString, dateFormat).format(dateFormatAPI))
    }
  }
  // const loadTongTien = (list) => {
  //   setTongThanhTienHistory(list?.reduce((tong, item) => tong + item.tiendichvu, 0))
  //   setTongTienGiamHistory(list?.reduce((tong, item) => tong + item.tiengiamgia, 0))
  //   setTongThanhToanHistory(list?.reduce((tong, item) => tong + item.tienthanhtoan, 0))
  // }
  const handleReLoad = () => {
    dispatch(getHistoryMemberCardAction(detailCard?.idthe, infoBN?.idbn, dateForm, dateTo))
  }
  const checkValuePrice = (total, name, value) => {
    if (total > form.tienthanhtoan) {
      setForm((prevState) => ({ ...prevState, [name]: 0 }))
    } else {
      setForm((prevState) => ({ ...prevState, [name]: value }))
    }
  }
  const onChangeInput = (name, value) => {
    //const { name, value } = e.target
    const newValue = Number(value)
    // setForm({ ...form, [name]: newValue });
    switch (name) {
      case 'tienmat':
        const total_0 =
          newValue +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          form.vnpay +
          form.zalopay +
          form.thett
        checkValuePrice(total_0, name, newValue)
        break
      case 'chuyenkhoan':
        const total_1 =
          form.tienmat + newValue + form.pos + form.momo + form.vnpay + form.zalopay + form.thett
        checkValuePrice(total_1, name, newValue)
        break
      case 'momo':
        const total_2 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          newValue +
          form.vnpay +
          form.zalopay +
          form.thett
        checkValuePrice(total_2, name, newValue)
        break
      case 'vnpay':
        const total_3 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          newValue +
          form.zalopay +
          form.thett
        checkValuePrice(total_3, name, newValue)
        break
      case 'zalopay':
        const total_4 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          form.vnpay +
          newValue +
          form.thett
        checkValuePrice(total_4, name, newValue)
        break
      case 'pos':
        const total_5 =
          form.tienmat +
          form.chuyenkhoan +
          newValue +
          form.momo +
          form.vnpay +
          form.zalopay +
          form.thett
        checkValuePrice(total_5, name, newValue)
        break
      case 'thett':
        if (newValue > infoPaymentCard?.tienVi) return
        const total_6 =
          form.tienmat +
          form.chuyenkhoan +
          form.pos +
          form.momo +
          form.vnpay +
          form.zalopay +
          newValue
        checkValuePrice(total_6, name, newValue)
        break
      default:
        break
    }
  }
  const handleKeyDown = (e) => {
    const { name } = e.target
    if (e.key === 'P' || e.key === 'p') {
      // Kiểm tra nếu phím nhấn là "P"
      // Cập nhật giá tiền vào ô input
      switch (name) {
        case 'tienmat':
          const priceTM = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceTM }))
          break
        case 'chuyenkhoan':
          const priceCK = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceCK }))
          break
        case 'momo':
          const priceMOMO = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceMOMO }))
          break
        case 'vnpay':
          const priceVNPAY = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceVNPAY }))
          break
        case 'zalopay':
          const priceZALOPAY = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: priceZALOPAY }))
          break
        case 'pos':
          const pricePOS = form.tienthanhtoan - form.tongtien
          setForm((prevState) => ({ ...prevState, [name]: pricePOS }))
          break
        case 'thett':
          const priceTheTT = form.tienthanhtoan - form.tongtien
          if (priceTheTT > infoPaymentCard?.tienVi) {
            setForm((prevState) => ({ ...prevState, [name]: infoPaymentCard?.tienVi }))
            break
          }
          setForm((prevState) => ({ ...prevState, [name]: priceTheTT }))
          break
        default:
          break
      }
    }
  }

  const handleResetForm = () => {
    dispatch(resetFormTTV())
    // //reset form
    setForm({
      tienmat: 0,
      chuyenkhoan: 0,
      thett: 0,
      momo: 0,
      vnpay: 0,
      zalopay: 0,
      pos: 0,
      tongtien: 0,
      tiengiamgia: tongGiamGia,
      tienthanhtoan: tongThanhToan,
      trangthaithanhtoan: 2,
    })
    setFormTTV({
      mathe: null,
      idchuyenkhoa: detailCard?.idchuyenkhoa,
      sothanhvien: 1,
      idnhom: detailCard?.idnhom,
      ngaydangky: now.format(),
      tungay: null,
      denngay: null,
      mavoucher: '',
      listMaVoucher: null, // list mã voucher ['abc','xyz',...]
      mavoucherdoitac: '',
      idcn: PkDangNhap?.idChiNhanh,
      idcalamviec: infoUser?.dangNhap?.idCaLamViec,
      idnguoitao: infoUser?.idnv,
      nguoitao: infoUser?.tenNV,
      idnguoiban: infoUser?.idnv,
      nguoiban: infoUser?.tenNV,
      nguoimua: null,
      sodienthoai: null,
      diachi: null,
      lydo: null,
      idphuongthuc: 1, //thanh toán đủ
      hoadondientu: 0, // không xuất
      ghichuxuathoadon: null,
    })
    setIsModalEdit(false)
    setIsEditTTV(false)
    setIsUpgradeTTV(false)
    setIsExtendTTV(false)
    setIsDowngradeTTV(false)
    setIsTangTTV(false)
    setCurrent(0)
    resetStore()
  }
  const handleSubmit = async () => {
    let formBNthu2 = null
    const formPrint = {
      infoBN: infoBN,
      infoBNthu2: infoBNthu2 ?? null,
      infoTTV: formTTV,
      infoTT: form,
      PkDangNhap: PkDangNhap,
    }
    if (isEditTTV) {
      if (infoBNthu2 && formTTV?.idlt && formTTV?.sothanhvien === 2) {
        formBNthu2 = {
          idbn: infoBNthu2?.idbn,
          nguoitao: infoUser?.tenNV,
        }
      }
      dispatch(
        putCreateMemberCardTTTAction(
          detailCard?.idthe,
          {
            ...formTTV,
            idct:
              formTTV?.mavoucherdoitac && infoVoucherDoiTac ? infoVoucherDoiTac.idctDoiTac : null,
            giatri:
              formTTV?.mavoucherdoitac && infoVoucherDoiTac ? infoVoucherDoiTac.giaVoucher : null,
          },
          formPrint,
          formLoad,
          formBNthu2,
        ),
      )
    }
    if (isUpgradeTTV) {
      if (
        infoBNthu2 &&
        formTTV?.idlt &&
        formTTV?.sothanhvien === 2 &&
        detailCard?.sothanhvien === 1
      ) {
        formBNthu2 = {
          idbn: infoBNthu2?.idbn,
          nguoitao: infoUser?.tenNV,
        }
      }
      dispatch(putUpgradeMemberCardAction(formTTV, formPrint, formLoad, formBNthu2, formQuaTang))
    }
    if (isExtendTTV) dispatch(putExtendMemberCardAction(formTTV, formPrint, formLoad, formQuaTang))
    //await dispatch(cardReceiveAction(formLoad?.idcn, formLoad?.tungay, formLoad?.denngay, formLoad?.idlt, formLoad?.idnt, formLoad?.keyword, formLoad?.idct))
    handleResetForm()
    clearGiftForm()
  }
  const handleHuyThe = () => {
    dispatch(putDisableMemberCardAction(detailCard, infoUser?.idnv, tienHoanTheRef.current))
    handleClose()
  }
  const handleVoHieuLuc = () => {
    dispatch(putPauseMemberCardAction(detailCard, dateBaoLuu, infoUser?.idnv, formLoad))
    handleClose()
  }
  const handleRueseMemberCard = () => {
    const ngayBL = moment(detailCard?.ngaybaoluu, 'YYYY-MM-DDTHH:mm:ss')
    const homNay = moment(now.format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss')
    if (homNay.diff(ngayBL, 'days') >= 300) {
      ToastCus.fire({
        icon: 'error',
        text: 'Thời gian bảo lưu đã quá 10 tháng!',
      })
      return
    }
    dispatch(
      putRueseMemberCardAction(detailCard?.idthe, detailCard?.ngaybaoluu, infoUser?.idnv, formLoad),
    )
    handleClose()
  }
  const checkNangCap = () => {
    const ngayHienTai = moment(moment(now).format(dateFormat), dateFormat)
    const ngayHieuLuc = moment(moment(detailCard?.tungay).format(dateFormat), dateFormat)
    const ngayHetHan = moment(moment(detailCard?.denngay).format(dateFormat), dateFormat)
    return ngayHetHan.diff(ngayHienTai, 'days') <= 364 ||
      ngayHetHan.diff(ngayHieuLuc, 'days') <= 364
      ? true
      : false
  }
  const { confirm } = Modal
  const showConfirm = (name) => {
    switch (name) {
      case 'vohieuluc':
        confirm({
          title: 'Bạn có muốn bảo lưu thẻ thành viên ?',
          icon: <QuestionCircleFilled />,
          content: (
            <div>
              Bạn xác nhận bảo lưu thẻ thành viên:
              <br></br>
              <span className="font-semibold">
                {detailCard?.mathe} - {infoBN?.tenBenhNhan}
              </span>
              <div className="w-full flex justify-start items-center gap-1">
                <label>Ngày bảo lưu: {moment(dateBaoLuu).format(dateFormat)}</label>
                {/* <DatePicker
                  placeholder="Chọn ngày bảo lưu..."
                  allowClear={false}
                  defaultValue={dayjs()}
                  // minDate={dayjs(moment(detailCard?.tungay, 'YYYY-MM-DDTHH:mm:ss'))}
                  // maxDate={dayjs(moment())}
                  // value={dayjs(moment(dateBaoLuu, dateFormatAPI))}
                  minDate={dayjs(detailCard?.tungay).startOf('day')}
                  maxDate={dayjs().endOf('day')}
                  // value={dayjs(dateBaoLuu, dateFormatAPI)}
                  format={dateFormat}
                  onChange={(date, dateString) => {
                    // console.log(moment(dateString, dateFormat).format(dateFormatAPI))
                    setDateBaoLuu((prev) => dayjs(dateString, dateFormat).format(dateFormatAPI))
                    // handleDatePicker(date, dateString, 'baoluu')
                  }}
                  size="small"
                /> */}
              </div>
            </div>
          ),
          okText: 'Xác nhận',
          okType: 'danger',
          cancelText: 'Hủy bỏ',
          onOk() {
            // console.log(dateBaoLuu)
            handleVoHieuLuc()
          },
          onCancel() {
            // console.log('Cancel')
          },
        })
        break
      case 'huythe':
        confirm({
          title: 'Bạn có muốn hủy thẻ thành viên ?',
          icon: <QuestionCircleFilled />,
          content: (
            <p>
              Mã thẻ:
              <span className="font-semibold">
                {detailCard?.mathe} - {infoBN?.tenBenhNhan}
              </span>
              <div className="flex flex-col  py-1">
                <label>Tiền hoàn thẻ:</label>
                <InputNumber
                  className="w-full"
                  defaultValue={detailCard?.tienthanhtoan}
                  onChange={(value) => (tienHoanTheRef.current = value)}
                  name="tienhoanthe"
                  step="0.001"
                  formatter={(value) => {
                    const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                    return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }}
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                  max={detailCard?.tienthanhtoan}
                  onKeyDown={(e) => {
                    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
                    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
                      e.preventDefault() // Chặn ký tự không hợp lệ
                    }
                  }}
                />
              </div>
              <i className="text-red-500"> * Hành động này không thể hoàn tác !</i>
            </p>
          ),
          okText: 'Xác nhận',
          okType: 'danger',
          cancelText: 'Hủy bỏ',
          onOk() {
            handleHuyThe()
          },
          onCancel() {
            // console.log('Cancel')
          },
        })
        break
      default:
        break
    }
  }
  const [current, setCurrent] = useState(0)
  const next = () => {
    setFormTTV({
      ...formTTV,
      mathe: formTTV.mathe === null ? '' : formTTV.mathe,
      // idlt: formTTV.idlt === null ? '' : formTTV.idlt,
      tungay: formTTV.tungay === null ? '' : formTTV.tungay,
      nguoimua: formTTV.nguoimua === null ? '' : formTTV.nguoimua,
      sodienthoai: formTTV.sodienthoai === null ? '' : formTTV.sodienthoai,
      diachi: formTTV.diachi === null ? '' : formTTV.diachi,
      lydo: formTTV.lydo === null ? '' : formTTV.lydo,
      ghichuxuathoadon: formTTV.ghichuxuathoadon === null ? '' : formTTV.ghichuxuathoadon,
    })
    if (!formTTV.mathe || formTTV.mathe.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập mã thẻ!',
      })
      return
    }
    if (!formTTV.idlt) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng chọn loại thẻ!',
      })
      return
    }
    if (!formTTV.tungay || formTTV.tungay.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập ngày hiệu lực!',
      })
      return
    }
    if (!formTTV.sothanhvien) {
      setFormTTV({ ...formTTV, sothanhvien: 1 })
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập số thành viên sử dụng thẻ!',
      })
      return
    }
    if (!formTTV.nguoimua || formTTV.nguoimua.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập tên người nộp tiền!',
      })
      return
    }
    if (!formTTV.sodienthoai || formTTV.sodienthoai.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập số điện thoại!',
      })
      return
    }
    if (!formTTV.diachi || formTTV.diachi.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập địa chỉ!',
      })
      return
    }
    if (!formTTV.lydo || formTTV.lydo.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập lý do!',
      })
      return
    }
    if (
      formTTV.hoadondientu === 1 &&
      (!formTTV.ghichuxuathoadon || formTTV.ghichuxuathoadon.trim().length <= 0)
    ) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập nội dung xuất hóa đơn điện tử!',
      })
      return
    }
    if (isUpgradeTTV && formTTV?.idlt && formTTV?.sothanhvien === 2 && !infoBNthu2) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng chọn bệnh nhân thứ 2!',
      })
      return
    }
    if (
      ((formTTV?.mavoucher && !discountArr) || (formTTV?.mavoucherdoitac && !infoVoucherDoiTac)) &&
      detailCard &&
      infoBN &&
      formTTV?.tiensudung
    ) {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng chọn áp dụng voucher!',
      })
      return
    }
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }
  useEffect(() => {
    if (!isModalEdit) {
      handleResetForm()
    }
  }, [isModalEdit])
  useEffect(() => {
    const newTotal =
      parseFloat(form.chuyenkhoan) +
      parseFloat(form.momo) +
      parseFloat(form.pos) +
      parseFloat(form.vnpay) +
      parseFloat(form.zalopay) +
      parseFloat(form.tienmat) +
      parseFloat(form.thett)
    setForm((prevState) => ({ ...prevState, tongtien: newTotal }))
    setFormTTV({ ...formTTV, ...form })
  }, [form.chuyenkhoan, form.momo, form.pos, form.vnpay, form.zalopay, form.tienmat, form.thett])
  useEffect(() => {
    setFormTTV({
      // mathe: detailCard?.mathe,
      // idlt: detailCard?.idlt,
      // idchuyenkhoa: detailCard?.idchuyenkhoa,
      // sothanhvien: detailCard?.sothanhvien,
      // idnhom: detailCard?.idnhom,
      // tungay: detailCard?.tungay,
      // denngay: detailCard?.denngay,
      ...detailCard,
      idlt: null,
      ngaydangky: now.format(),
      mavoucher: '',
      listMaVoucher: null, // list mã voucher ['abc','xyz',...]
      mavoucherdoitac: '',
      // sothanhvien: 1,
      idcn: PkDangNhap?.idChiNhanh,
      idcalamviec: infoUser?.dangNhap?.idCaLamViec,
      chinhanhnap: PkDangNhap?.idChiNhanh,
      idnguoitao: infoUser?.idnv,
      nguoitao: infoUser?.tenNV,
      idnguoiban: infoUser?.idnv,
      nguoiban: infoUser?.tenNV,
      nguoimua: null,
      sodienthoai: null,
      diachi: null,
      lydo: null,
      idphuongthuc: 1, //thanh toán đủ
      hoadondientu: 0, // không xuất
      ghichuxuathoadon: null,
    })
    setDateBaoLuu(now.format(dateFormatAPI))
  }, [detailCard])
  useEffect(() => {
    setFormTTV({
      ...formTTV,
      tiensudung: null,
      mathe: detailCard?.mathe,
      idlt: null,
      sodienthoai: infoBN?.dienThoai,
      diachi: infoBN?.diaChi,
      idnguoitao: infoUser?.idnv,
      nguoitao: infoUser?.tenNV,
      idnguoiban: infoUser?.idnv,
      nguoiban: infoUser?.tenNV,
    })
  }, [isUpgradeTTV, isExtendTTV])
  useEffect(() => {
    if (isUpgradeTTV && formTTV?.sothanhvien === 2) {
      dispatch({
        type: typeAction.DISPATCH_INFO_BN_THU2_CARD,
        payload: null,
      })
    }
  }, [formTTV?.sothanhvien])
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = () => {
    api.success({
      placement: 'top',
      message: 'Kích hoạt thẻ thanh toán',
      description: 'Đã kích hoạt thẻ thanh toán thành công!',
    })
  }
  const steps = [
    {
      title: 'Thông tin',
      content: (
        <>
          <Divider
            orientation="left"
            style={{
              margin: '10px',
              marginTop: '0px',
            }}
          >
            <span className="text-blue-500">Thông tin bệnh nhân</span>
          </Divider>
          <BNEdit />
          {infoBNthu2 && detailCard?.sothanhvien === 2 ? (
            <>
              <Divider
                orientation="left"
                style={{
                  margin: '10px 0',
                  marginTop: '5px',
                }}
              >
                <span className="text-green-500">Thông tin bệnh nhân thứ 2</span>
              </Divider>
              <BNEdit isBNtang={true} />
            </>
          ) : (
            <></>
          )}

          {(isUpgradeTTV &&
            formTTV?.idlt &&
            formTTV?.sothanhvien === 2 &&
            detailCard?.sothanhvien === 1) ||
          (isEditTTV && formTTV?.idlt && formTTV?.sothanhvien === 2) ? (
            <>
              <Divider
                orientation="left"
                style={{
                  margin: '10px',
                }}
              >
                <span className="text-green-500">Thông tin bệnh nhân thứ 2</span>
                <span className="ml-3"></span>
              </Divider>
              <InfoBN isBNtang={true} />
            </>
          ) : (
            <></>
          )}

          <Divider
            orientation="left"
            style={{
              margin: '10px',
            }}
          >
            <div className="flex justify-start items-center">
              <span className="text-blue-500 text-md" onClick={checkNangCap}>
                Thông tin thẻ
              </span>
              <span className="ml-3">
                {!isExtendTTV ? (
                  detailCard?.idlt !== 43 && //không phải thẻ nhi tặng
                  detailCard?.idnhom !== 5 &&
                  detailCard?.hieuluc === 1 &&
                  detailCard?.sudung === 1 &&
                  !isUpgradeTTV &&
                  !isTangTTV &&
                  !isDowngradeTTV ? ( // gia hạn thẻ khi thẻ còn hiệu lực và không phải thẻ thanh toán
                    <Tooltip title="Gia hạn thẻ" color="green">
                      <ButtonMui
                        onClick={() => handleCheckQuyenThaoTac('QHT048')}
                        variant="contained"
                        color="success"
                        size="small"
                        startIcon={<ClockCircleOutlined />}
                      >
                        Gia hạn
                      </ButtonMui>
                      {/* <ClockCircleOutlined
                      onClick={() => { setIsExtendTTV(true) }}
                      className="text-xl text-green-500  cursor-pointer"
                    /> */}
                    </Tooltip>
                  ) : (
                    <></>
                  )
                ) : (
                  <Tooltip title="Hủy gia hạn thẻ" color="red">
                    <CloseOutlined
                      onClick={() => {
                        setIsExtendTTV(false)
                      }}
                      className="text-xl text-red-500  cursor-pointer"
                    />
                  </Tooltip>
                )}
                {!isUpgradeTTV ? (
                  detailCard?.idlt !== 43 && //không phải thẻ nhi tặng
                  detailCard?.idnhom !== 5 &&
                  detailCard?.hieuluc === 1 &&
                  detailCard?.sudung === 1 &&
                  !isExtendTTV &&
                  !isTangTTV &&
                  !isDowngradeTTV ? (
                    // && checkNangCap() // gia hạn trước rồi nâng cấp
                    // nâng cấp thẻ khi thẻ còn hiệu lực và không phải thẻ thanh toán
                    <Tooltip title="Nâng cấp thẻ" color="blue">
                      <span className="ml-3"></span>
                      <ButtonMui
                        onClick={() => handleCheckQuyenThaoTac('QHT047')}
                        variant="contained"
                        // color="success"
                        size="small"
                        startIcon={<ArrowUpOutlined />}
                      >
                        Nâng cấp
                      </ButtonMui>
                      {/* <ArrowUpOutlined
                      onClick={() => { setIsUpgradeTTV(true) }}
                      className="text-xl text-green-500  cursor-pointer"
                    /> */}
                    </Tooltip>
                  ) : (
                    <></>
                  )
                ) : (
                  <Tooltip title="Hủy nâng cấp thẻ" color="red">
                    <CloseOutlined
                      onClick={() => {
                        setIsUpgradeTTV(false)
                      }}
                      className="text-xl text-red-500  cursor-pointer"
                    />
                  </Tooltip>
                )}

                {/* {!isDowngradeTTV ? (
                  detailCard?.idnhom !== 5 &&
                  detailCard?.hieuluc === 1 &&
                  detailCard?.sudung === 1 &&
                  !isExtendTTV &&
                  !isTangTTV &&
                  !isUpgradeTTV ? ( // hạ cấp thẻ khi thẻ còn hiệu lực và không phải thẻ thanh toán
                    <Tooltip title="Hạ cấp thẻ" color="orange">
                      <span className="ml-3"></span>
                      <ButtonMui
                        onClick={() => {
                          setIsDowngradeTTV(true)
                        }}
                        variant="contained"
                        color="warning"
                        size="small"
                        startIcon={<ArrowDownOutlined />}
                      >
                        Hạ cấp
                      </ButtonMui>
                    </Tooltip>
                  ) : (
                    <></>
                  )
                ) : (
                  <Tooltip title="Hủy hạ cấp thẻ" color="red">
                    <CloseOutlined
                      onClick={() => {
                        setIsDowngradeTTV(false)
                      }}
                      className="text-xl text-red-500  cursor-pointer"
                    />
                  </Tooltip>
                )} */}

                {PkDangNhap?.idChuyenKhoa === 1 &&
                  detailCard?.idchuyenkhoa === 2 &&
                  // detailCard?.caploaithe > 1 &&
                  (!isTangTTV ? (
                    detailCard?.idlt !== 4 && //không phải thẻ phụ khoa
                    detailCard?.idlt !== 5 && //không phải thẻ phụ khoa vàng
                    detailCard?.idlt !== 43 && //không phải thẻ nhi tặng
                    detailCard?.idnhom !== 5 &&
                    detailCard?.hieuluc === 1 &&
                    detailCard?.sudung === 1 &&
                    !lichSuMuaTTV?.find((item) => item?.thaotac?.includes('Tạo thẻ tặng nhi')) &&
                    !isExtendTTV &&
                    !isUpgradeTTV &&
                    !isDowngradeTTV ? ( // tặng thẻ nhi khi thẻ sản còn hiệu lực và không phải thẻ thanh toán
                      <Tooltip title="Tặng thẻ" color="purple">
                        <span className="ml-3"></span>
                        <ButtonMui
                          onClick={() => handleCheckQuyenThaoTac('QHT122')}
                          variant="contained"
                          color="secondary"
                          size="small"
                          startIcon={<GiftOutlined />}
                        >
                          Tặng thẻ
                        </ButtonMui>
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  ) : (
                    <Tooltip title="Hủy tặng thẻ" color="red">
                      <CloseOutlined
                        onClick={() => {
                          setIsTangTTV(false)
                        }}
                        className="text-xl text-red-500  cursor-pointer"
                      />
                    </Tooltip>
                  ))}
                {!isEditTTV ? (
                  detailCard?.idnhom === 5 ? ( // đã có thẻ thanh toán tạo thêm thẻ thành viên xài chung mã thẻ
                    <Tooltip title="Tạo thẻ" color="blue">
                      <ButtonMui
                        onClick={() => {
                          setIsEditTTV(true)
                        }}
                        variant="contained"
                        // color="success"
                        size="small"
                        startIcon={<PlusOutlined />}
                      >
                        Tạo thẻ
                      </ButtonMui>
                      {/* <PlusOutlined
                      onClick={() => { setIsEditTTV(true) }}
                      className="text-xl text-green-500  cursor-pointer"
                    /> */}
                    </Tooltip>
                  ) : (
                    <></>
                  )
                ) : (
                  <Tooltip title="Hủy tạo thẻ" color="red">
                    <CloseOutlined
                      onClick={() => {
                        setIsEditTTV(false)
                      }}
                      className="text-xl text-red-500  cursor-pointer"
                    />
                  </Tooltip>
                )}
              </span>
            </div>
          </Divider>
          {isEditTTV ? (
            <CardTTV
              isEditTTV={isEditTTV}
              formTTV={formTTV}
              setFormTTV={setFormTTV}
              // handleOk={handleOk}
              // handleCancel={handleCancel}
              isDoiTac={isDoiTac}
              setIsDoiTac={setIsDoiTac}
              valueChuongTrinhDoiTac={valueChuongTrinhDoiTac}
              setValueChuongTrinhDoiTac={setValueChuongTrinhDoiTac}
              valueGiaVoucherDoiTac={valueGiaVoucherDoiTac}
              setValueGiaVoucherDoiTac={setValueGiaVoucherDoiTac}
            />
          ) : isUpgradeTTV ? (
            <div>
              <CardUpgrade
                isUpgradeTTV={isUpgradeTTV}
                formTTV={formTTV}
                setFormTTV={setFormTTV}
                // handleOk={handleOk}
                // handleCancel={handleCancel}
              />
              <Divider
                orientation="left"
                style={{
                  margin: '10px',
                }}
              >
                <span className="text-blue-500">Chương trình quà tặng</span>
              </Divider>
              <CardQuaTangExtend
                isQuaTang={isQuaTang}
                handleChangeQuaTang={handleChangeQuaTang}
                valueChuongTrinhQuaTang={valueChuongTrinhQuaTang}
                setValueChuongTrinhQuaTang={setValueChuongTrinhQuaTang}
                valueSoLuongQuaTang={valueSoLuongQuaTang}
                setValueSoLuongQuaTang={setValueSoLuongQuaTang}
                setFormQuaTang={setFormQuaTang}
              />
            </div>
          ) : isExtendTTV ? (
            <div>
              <CardExtend
                isExtendTTV={isExtendTTV}
                formTTV={formTTV}
                setFormTTV={setFormTTV}
                // handleOk={handleOk}
                // handleCancel={handleCancel}
              />
              <Divider
                orientation="left"
                style={{
                  margin: '10px',
                }}
              >
                <span className="text-blue-500">Chương trình quà tặng</span>
              </Divider>
              <CardQuaTangExtend
                isQuaTang={isQuaTang}
                handleChangeQuaTang={handleChangeQuaTang}
                valueChuongTrinhQuaTang={valueChuongTrinhQuaTang}
                setValueChuongTrinhQuaTang={setValueChuongTrinhQuaTang}
                valueSoLuongQuaTang={valueSoLuongQuaTang}
                setValueSoLuongQuaTang={setValueSoLuongQuaTang}
                setFormQuaTang={setFormQuaTang}
              />
            </div>
          ) : (
            <CardEdit
              hieuLucCheck={hieuLucCheck}
              setHieuLucCheck={setHieuLucCheck}
              ngayBaoLuu={ngayBaoLuu}
              setNgayBaoLuu={setNgayBaoLuu}
              isEditTTV={isEditTTV}
              setIsEditTTV={setIsEditTTV}
              openNotificationWithIcon={openNotificationWithIcon}
              isTangTTV={isTangTTV}
            />
          )}
        </>
      ),
    },
    {
      title: 'Thanh toán',
      content: (
        <div className="flex justify-center mt-5">
          <div className="text-start w-4/6">
            <div className="flex flex-col gap-2">
              <div className="flex gap-5">
                <div className="w-1/2">
                  <h2 className="font-semibold">Tiền mặt:</h2>
                  <InputNumber
                    value={form.tienmat}
                    name="tienmat"
                    onKeyUp={handleKeyDown}
                    onChange={(value) => onChangeInput('tienmat', value)} //onChangeInput
                    className="w-full"
                    step={100}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={999999999}
                  />
                </div>
                <div className="w-1/2">
                  <h2 className="font-semibold">Chuyển khoản:</h2>
                  <InputNumber
                    value={form.chuyenkhoan}
                    name="chuyenkhoan"
                    onKeyUp={handleKeyDown}
                    onChange={(value) => onChangeInput('chuyenkhoan', value)} //onChangeInput
                    className="w-full"
                    step={100}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={999999999}
                  />
                </div>
              </div>
              <div className="flex gap-5">
                <div className="w-1/2">
                  <h2 className="font-semibold">Máy POS:</h2>
                  <InputNumber
                    value={form.pos}
                    name="pos"
                    onKeyUp={handleKeyDown}
                    onChange={(value) => onChangeInput('pos', value)} //onChangeInput
                    className="w-full"
                    step={100}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={999999999}
                  />
                </div>
                <div className="w-1/2">
                  <h2 className="font-semibold">Momo:</h2>
                  <InputNumber
                    value={form.momo}
                    name="momo"
                    onKeyUp={handleKeyDown}
                    onChange={(value) => onChangeInput('momo', value)} //onChangeInput
                    className="w-full"
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    step={100}
                    max={999999999}
                  />
                </div>
              </div>
              <div className="flex gap-5">
                <div className="w-1/2">
                  <h2 className="font-semibold">VNPay:</h2>
                  <InputNumber
                    value={form.vnpay}
                    name="vnpay"
                    onKeyUp={handleKeyDown}
                    onChange={(value) => onChangeInput('vnpay', value)} //onChangeInput
                    className="w-full"
                    step={100}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={999999999}
                  />
                </div>
                <div className="w-1/2">
                  <h2 className="font-semibold">Zalo Pay:</h2>
                  <InputNumber
                    value={form.zalopay}
                    name="zalopay"
                    onKeyUp={handleKeyDown}
                    onChange={(value) => onChangeInput('zalopay', value)} //onChangeInput
                    className="w-full"
                    step={100}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={999999999}
                  />
                </div>
              </div>
              <div className="flex gap-5 items-center">
                <div className="w-1/2">
                  <h2 className="font-semibold">Thẻ thanh toán:</h2>
                  <InputNumber
                    disabled={!infoPaymentCard}
                    value={form.thett}
                    name="thett"
                    onKeyUp={handleKeyDown}
                    onChange={(value) => onChangeInput('thett', value)} //onChangeInput
                    className="w-full"
                    step={100}
                    formatter={(value) => {
                      const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                      return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    min={0}
                    max={999999999}
                  />
                </div>
                <div className="w-1/2">
                  {' '}
                  {!infoPaymentCard ? (
                    <h2 className="font-semibold text-red-500 pt-5">* Chưa kích hoạt thẻ</h2>
                  ) : (
                    <h2 className="font-semibold  pt-5">
                      Tối đa : {formatNumberVND(infoPaymentCard?.tienVi ?? 0)} VNĐ
                    </h2>
                  )}
                </div>
              </div>
              <p>
                <i>
                  {' '}
                  * Gợi ý: chọn ô thanh toán và nhấn phím <span className="font-semibold">
                    P
                  </span>{' '}
                  để tính tổng tiền{' '}
                </i>
              </p>
            </div>
            <div className="flex py-2 ">
              <h2 className="w-1/2 text-lg font-semibold text-center">
                Tiền thanh toán: {formatNumberVND(form.tienthanhtoan)} VNĐ
              </h2>
              <h2 className="w-1/2 text-lg font-semibold text-center">
                Tổng tiền: {formatNumberVND(form.tongtien)} VNĐ
              </h2>
            </div>
          </div>
        </div>
      ),
    },
  ]
  useEffect(() => {
    tienHoanTheRef.current = detailCard?.tienthanhtoan
  }, [detailCard])
  return (
    <Modal
      open={isModalEdit}
      title="Thẻ thành viên"
      width={900}
      className="text-center"
      onCancel={handleClose}
      footer={null}
    >
      <Tabs
        activeKey={keyTabs}
        onChange={setKeyTabs}
        // onTabClick={handleReLoad}
        items={[
          {
            key: '1',
            label: 'Thông tin',
            children: (
              <>
                {/* <div className={isModalEdit}></div> */}
                {isEditTTV ? (
                  <div className="w-full flex justify-center">
                    <Steps current={current} className="w-[500px]" items={steps} />
                  </div>
                ) : (
                  <></>
                )}
                <div>{steps[current].content}</div>
                {isTangTTV && (
                  <CardTang
                    isTangTTV={isTangTTV}
                    setIsTangTTV={setIsTangTTV}
                    handleClose={handleClose}
                  />
                )}
                <div
                  className="flex justify-end"
                  style={{
                    marginTop: 24,
                  }}
                >
                  {current > 0 && (
                    <Button
                      style={{
                        margin: '0 8px',
                      }}
                      onClick={() => {
                        setForm({
                          tienmat: 0,
                          chuyenkhoan: 0,
                          thett: 0,
                          momo: 0,
                          vnpay: 0,
                          zalopay: 0,
                          pos: 0,
                          tongtien: 0,
                          tiengiamgia: tongGiamGia,
                          tienthanhtoan: tongThanhToan,
                          trangthaithanhtoan: 2,
                        })
                        prev()
                      }}
                    >
                      Trở lại
                    </Button>
                  )}
                  {current < steps.length - 1 && (isEditTTV || isUpgradeTTV || isExtendTTV) && (
                    <Button
                      disabled={!infoBN || !detailCard}
                      type="primary"
                      onClick={() => {
                        setForm({
                          ...form,
                          // tiengiamgia
                          // tienthanhtoan: tongThanhToan,
                          tiengiamgia: tongGiamGia,
                          tienthanhtoan: tongThanhToan,
                        })
                        next()
                      }}
                    >
                      Tiếp tục
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      type="primary"
                      disabled={form.tongtien !== form.tienthanhtoan}
                      onClick={handleSubmit}
                    >
                      Thanh toán
                    </Button>
                  )}
                  {hieuLucCheck && detailCard?.hieuluc === 0 && detailCard?.sudung === 1 ? ( //&& !isLeTan
                    <Tooltip title="Tái sử dụng thẻ thành viên" color="green">
                      <ButtonMui
                        onClick={handleRueseMemberCard}
                        variant="contained"
                        color="success"
                        size="small"
                        startIcon={<CheckOutlined />}
                      >
                        Tái sử dụng
                      </ButtonMui>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ),
          },
          {
            key: '2',
            label: 'Lịch sử thẻ',
            children: (
              <>
                <ConfigProvider
                  theme={{
                    token: {
                      padding: 5,
                    },
                    components: {
                      Table: {
                        rowHoverBg: '#e3f0fb',
                        headerBg: '#e6e6e6',
                        footerBg: '#e6e6e6',
                        borderColor: '#BABABA',
                      },
                    },
                  }}
                >
                  <Table
                    bordered
                    pagination={false}
                    scroll={{ y: 200 }}
                    dataSource={lichSuMuaTTV}
                    columns={[
                      {
                        title: 'STT',
                        dataIndex: 'stt',
                        align: 'center',
                        width: 40,
                        key: 'stt',
                        render: (text, record, index) => ++index,
                      },
                      {
                        title: 'Ngày thao tác',
                        dataIndex: 'ngaythaotac',
                        key: 'ngaythaotac',
                        align: 'center',
                        width: 140,
                        render: (text, record, index) =>
                          text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-',
                      },
                      {
                        title: 'Thao tác',
                        dataIndex: 'thaotac',
                        key: 'thaotac',
                      },
                      {
                        title: 'Thành tiền',
                        dataIndex: 'tiensudung',
                        key: 'tiensudung',
                        align: 'right',
                        width: 90,
                        render: (text, record, index) => formatNumberVND(text ?? 0),
                      },
                      {
                        title: '% Giảm',
                        dataIndex: 'ptgiamgia',
                        key: 'ptgiamgia',
                        align: 'right',
                        width: 70,
                        render: (text, record, index) =>
                          record?.tienthanhtoan === 0 || record?.tiensudung === 0
                            ? 0
                            : Math.round(
                                100 - Number((record?.tienthanhtoan * 100) / record?.tiensudung),
                                0,
                              ),
                      },
                      {
                        title: 'Tiền giảm',
                        dataIndex: 'tiengiamgia',
                        key: 'tiengiamgia',
                        align: 'right',
                        width: 90,
                        render: (text, record, index) => formatNumberVND(text ?? 0),
                      },
                      {
                        title: 'Thanh toán',
                        dataIndex: 'tienthanhtoan',
                        key: 'tienthanhtoan',
                        align: 'right',
                        width: 90,
                        fixed: 'right',
                        render: (text, record, index) => formatNumberVND(text ?? 0),
                      },
                      {
                        title: 'Tiền hoàn thẻ',
                        dataIndex: 'tienhoanthe',
                        key: 'tienhoanthe',
                        align: 'right',
                        width: 100,
                        fixed: 'right',
                        render: (text, record, index) => formatNumberVND(text ?? 0),
                      },
                    ]}
                    summary={() => (
                      <Table.Summary fixed>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={3}></Table.Summary.Cell>
                          <Table.Summary.Cell className="text-end" index={3}>
                            <span className="font-semibold">
                              {formatNumberVND(
                                lichSuMuaTTV?.reduce((tong, item) => tong + item?.tiensudung, 0) ??
                                  0,
                              )}
                            </span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={4}></Table.Summary.Cell>
                          <Table.Summary.Cell className="text-end" index={5}>
                            <span className="font-semibold">
                              {formatNumberVND(
                                lichSuMuaTTV?.reduce((tong, item) => tong + item?.tiengiamgia, 0) ??
                                  0,
                              )}
                            </span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6} align="right">
                            <span className="font-semibold pe-4">
                              {formatNumberVND(
                                lichSuMuaTTV?.reduce(
                                  (tong, item) => tong + item?.tienthanhtoan,
                                  0,
                                ) ?? 0,
                              )}
                            </span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={7} align="right">
                            <span className="font-semibold pe-4">
                              {formatNumberVND(
                                lichSuMuaTTV?.reduce((tong, item) => tong + item?.tienhoanthe, 0) ??
                                  0,
                              )}
                            </span>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>
                    )}
                  />
                </ConfigProvider>
              </>
            ),
          },
          {
            key: '3',
            label: 'Lịch sử dịch vụ',
            children: (
              <>
                <div className="w-full mb-2 flex justify-start">
                  <div className="w-2/6 flex justify-between mr-3">
                    {/* <label className="text-left">Từ ngày:</label> */}
                    <DatePicker
                      placeholder="Chọn ngày..."
                      allowClear={false}
                      defaultValue={dayjs(now)}
                      maxDate={dayjs(now)}
                      format={dateFormat}
                      value={dayjs(dateForm)}
                      onChange={(date, dateString) => {
                        handleDatePicker(date, dateString, 'from')
                      }}
                      size="small"
                    />
                    <span className="font-semibold text-lg mr-2 ml-2">-</span>
                    <DatePicker
                      placeholder="Chọn ngày..."
                      allowClear={false}
                      defaultValue={dayjs(now)}
                      minDate={dayjs(dateForm)}
                      maxDate={dayjs(now)}
                      value={dayjs(dateTo)}
                      format={dateFormat}
                      onChange={(date, dateString) => {
                        handleDatePicker(date, dateString, 'to')
                      }}
                      size="small"
                    />
                  </div>
                  <IconButton onClick={handleReLoad} color="primary" size="small">
                    <SyncIcon />
                  </IconButton>
                </div>
                <ConfigProvider
                  theme={{
                    token: {
                      padding: 5,
                    },
                    components: {
                      Table: {
                        rowHoverBg: '#e3f0fb',
                        headerBg: '#e6e6e6',
                        footerBg: '#e6e6e6',
                        borderColor: '#BABABA',
                      },
                    },
                  }}
                >
                  <Table
                    bordered
                    pagination={false}
                    scroll={{ y: 200 }}
                    dataSource={historyMemberCard}
                    columns={[
                      {
                        title: 'STT',
                        dataIndex: 'stt',
                        align: 'center',
                        width: 40,
                        key: 'stt',
                        render: (text, record, index) => ++index,
                      },
                      {
                        title: 'Ngày sử dụng',
                        dataIndex: 'ngaysd',
                        key: 'ngaysd',
                        align: 'center',
                        width: 105,
                        render: (text, record, index) => moment(text).format('DD/MM/YYYY'),
                      },
                      {
                        title: 'Tên dịch vụ',
                        dataIndex: 'tendichvu',
                        key: 'tendichvu',
                      },
                      // {
                      //   title: 'Loại khám',
                      //   dataIndex: 'tenloaikham',
                      //   key: 'tenloaikham',
                      //   align: 'center',
                      //   width: 90,
                      //   render: (text, record, index) => 'Tái khám',
                      // },
                      {
                        title: 'Thành tiền',
                        dataIndex: 'tiendichvu',
                        key: 'tiendichvu',
                        align: 'right',
                        width: 90,
                        render: (text, record, index) => formatNumberVND(text),
                      },
                      {
                        title: '% Giảm',
                        dataIndex: 'ptgiamgia',
                        key: 'ptgiamgia',
                        align: 'right',
                        width: 70,
                      },
                      {
                        title: 'Tiền giảm',
                        dataIndex: 'tiengiamgia',
                        key: 'tiengiamgia',
                        align: 'right',
                        width: 90,
                        render: (text, record, index) => formatNumberVND(text),
                      },
                      {
                        title: 'Thanh toán',
                        dataIndex: 'tienthanhtoan',
                        key: 'tienthanhtoan',
                        align: 'right',
                        width: 90,
                        fixed: 'right',
                        render: (text, record, index) => formatNumberVND(text),
                      },
                    ]}
                    summary={() => (
                      <Table.Summary fixed>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0}></Table.Summary.Cell>
                          <Table.Summary.Cell index={1}></Table.Summary.Cell>
                          <Table.Summary.Cell index={2}></Table.Summary.Cell>
                          <Table.Summary.Cell className="text-end" index={3}>
                            <span className="font-semibold">
                              {formatNumberVND(
                                historyMemberCard?.reduce(
                                  (tong, item) => tong + item.tiendichvu,
                                  0,
                                ) ?? 0,
                              )}
                            </span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={4}></Table.Summary.Cell>
                          <Table.Summary.Cell className="text-end" index={5}>
                            <span className="font-semibold">
                              {formatNumberVND(
                                historyMemberCard?.reduce(
                                  (tong, item) => tong + item.tiengiamgia,
                                  0,
                                ) ?? 0,
                              )}
                            </span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="text-end" index={6}>
                            <span className="font-semibold pe-4">
                              {formatNumberVND(
                                historyMemberCard?.reduce(
                                  (tong, item) => tong + item.tienthanhtoan,
                                  0,
                                ) ?? 0,
                              )}
                            </span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={7}></Table.Summary.Cell>
                          <Table.Summary.Cell index={8}></Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>
                    )}
                  />
                </ConfigProvider>
                {soLanKham ? (
                  <>
                    <div className="w-full mt-3 flex justify-start">
                      <div className="w-1/5 text-start">Khám bệnh mới: </div>
                      <p>{soLanKham?.khambenhmoi || 0} Lần</p>
                    </div>
                    <div className="w-full flex justify-start">
                      <div className="w-1/5 text-start">Tái khám: </div>
                      <p>{soLanKham?.taikham || 0} Lần</p>
                    </div>
                    <div className="w-full flex justify-start">
                      <div className="w-1/5 text-start">Tổng tiền dịch vụ thẻ: </div>
                      <p>{formatNumberVND(soLanKham?.tongtien || 0)}</p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {detailCard?.hieuluc === 1 && detailCard?.sudung === 1 ? ( //&& !isLeTan
                  <div className="w-full mt-3 grid grid-flow-row">
                    <div className="flex justify-end mb-2">
                      {detailCard?.idlt !== 43 && ( //không phải thẻ nhi tặng
                        <>
                          <DatePicker
                            placeholder="Chọn ngày bảo lưu..."
                            allowClear={false}
                            defaultValue={dayjs()}
                            minDate={dayjs(detailCard?.tungay).startOf('day')}
                            maxDate={dayjs().endOf('day')}
                            value={dayjs(dateBaoLuu, dateFormatAPI)}
                            format={dateFormat}
                            onChange={(date, dateString) => {
                              setDateBaoLuu((prev) =>
                                dayjs(dateString, dateFormat).format(dateFormatAPI),
                              )
                            }}
                            size="small"
                            className="w-[8.3rem]"
                          />
                        </>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <div>
                        {!isLeTan &&
                          detailCard?.idlt !== 43 && ( //không phải thẻ nhi tặng
                            <Tooltip title="Hủy thẻ thành viên" color="red">
                              <ButtonMui
                                // onClick={() => {
                                //   showConfirm('huythe')
                                // }}
                                onClick={() => handleCheckQuyenThaoTac('QHT119')}
                                variant="contained"
                                color="error"
                                size="small"
                                startIcon={<DeleteOutlined />}
                              >
                                Hủy Thẻ
                              </ButtonMui>
                            </Tooltip>
                          )}
                      </div>
                      <div>
                        {detailCard?.idlt !== 43 && ( //không phải thẻ nhi tặng
                          <>
                            <Tooltip title="Vô hiệu hóa thẻ thành viên" color="orange">
                              <ButtonMui
                                // disabled={true}
                                // onClick={() => {
                                //   showConfirm('vohieuluc')
                                // }}
                                onClick={() => handleCheckQuyenThaoTac('QHT049')}
                                variant="contained"
                                color="warning"
                                size="small"
                                startIcon={<StopOutlined />}
                              >
                                Vô hiệu hóa
                              </ButtonMui>
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ),
          },
        ]}
      />
      {contextHolder}
    </Modal>
  )
}

export default ModalEdit
