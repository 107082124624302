import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { khachhangCRMService } from '../../services/khachhangCRM/khachhangCRMService'
import ToastCus from '../../utils/Toast'
import _ from 'lodash'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'

export function* khachhangCRMSaga() {
  yield takeLatest(typeAction.GET_ALL_KHACHHANG_CRM, function* allKhachHangCRM({ type, payload }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => khachhangCRMService.getAllKhachHangCRM())
      const data = _.orderBy(result.data, 'tennguoitao', 'asc')
      data.sort((a, b) => new Date(b.ngaytao) - new Date(a.ngaytao))
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_DS_KHACH_HANG_CRM,
        true,
        MENU_CONSTANTS.GOI_KHAM_KHACH_HANG,
        null,
        {},
      )

      yield put({
        type: typeAction.DISPATCH_ALL_KHACHHANG_CRM,
        payload: data,
      })
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.GOI_KHAM_KHACH_HANG,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //add
  yield takeLatest(typeAction.ADD_KHACHHANG_CRM, function* addKhachHangCRM({ type, form, onLoad }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => khachhangCRMService.addKhachHangCRM(form))
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.ADD_KHACH_HANG,
        true,
        MENU_CONSTANTS.KHACH_HANG_CRM,
        null,
        {
          form,
        },
      )
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm mới thành công',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thêm mới thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.KHACH_HANG_CRM,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //edit
  yield takeLatest(
    typeAction.EDIT_KHACHHANG_CRM,
    function* editKhachHangCRM({ type, form, onLoad }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.EDIT_KHACH_HANG,
          true,
          MENU_CONSTANTS.KHACH_HANG_CRM,
          null,
          {
            form,
          },
        )
        yield call(() => khachhangCRMService.editKhachHangCRM(form))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Sửa thông tin thành công',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Sửa thông tin thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.KHACH_HANG_CRM,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(typeAction.GET_KHACHHANG_CRM_BY_ID, function* getById({ type, id }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => khachhangCRMService.getKhachHangCRMById(id))
      yield put({
        type: typeAction.DISPATCH_INFO_KHACHHANG_CRM,
        payload: result.data.result,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}
