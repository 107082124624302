import Swal from 'sweetalert2'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { toolService } from '../../services/tool/toolService'
import { addLog } from '../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* toolSaga() {
  yield takeLatest(
    typeAction.UPDATE_PAYMENT_BN,
    function* updatePayBN({ type, idLoai, maPhieu, date, setMaPhieu, option }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
        yield call(() => toolService.updatePay(idLoai, maPhieu, date))
        setMaPhieu(null)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_SUA_THOI_GIAN_THANH_TOAN,
          true,
          MENU_CONSTANTS.CONG_CU,
          null,
          {
            maphieuthu: maPhieu,
            ngaythanhtoan: date,
            option,
          },
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CONG_CU,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(typeAction.GET_ALL_UU_DAI_TAI_KHAM, function* getUuDai({ type, setData }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      const { data } = yield call(() => toolService.getAllUuDaiTK())
      setData(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_DS_CHUONG_TRINH_TAI_KHAM,
        true,
        MENU_CONSTANTS.CONG_CU,
        null,
        null,
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.CONG_CU,
        detailErrorPayload,
      )
    }
  })
  yield takeLatest(
    typeAction.ADD_NEW_UU_DAI_TAI_KHAM,
    function* addNewUuDai({ type, form, fetchData }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => toolService.postUuDaiTK(form))
        fetchData()
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.THEM_CHUONG_TRINH_TAI_KHAM,
          true,
          MENU_CONSTANTS.CONG_CU,
          null,
          {
            tendichvu: form.tendichvu,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.CONG_CU,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(
    typeAction.DELETE_UU_DAI_TAI_KHAM,
    function* deleteUuDai({ type, record, fetchData, openNotificationWithIcon }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield call(() => toolService.deleteUuDai(record.idudtk))
        fetchData()
        openNotificationWithIcon('Xoá ưu đãi tái khám thành công')

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          logAction.XOA_CHUONG_TRINH_TAI_KHAM,
          true,
          MENU_CONSTANTS.CONG_CU,
          null,
          {
            tendichvu: record.tendv,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.CONG_CU,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(
    typeAction.GET_DICH_VU_BY_CONG_TY,
    function* getDichvuByCT({ type, idct, keyword, setData }) {
      try {
        const { data } = yield call(() => toolService.getDichVu(idct, keyword))
        setData(data)
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  yield takeLatest(typeAction.UPDATE_TTV_TOOL, function* updateTTV({ type, form, reset }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield toolService.updateTTV(form)
      reset()
      Toast.fire({
        icon: 'success',
        title: 'Lưu thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.CAP_NHAT_TTV,
        true,
        MENU_CONSTANTS.CONG_CU,
        null,
        form,
      )
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Lưu thất bại!',
      })
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.CONG_CU,
        detailErrorPayload,
      )
    }
  })
  yield takeLatest(
    typeAction.UPDATE_NGAY_TAI_KHAM_TOOL,
    function* updateTaiKham({ type, form, reset }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield toolService.putTaiKham(form)
        reset()
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_NGAY_TAI_KHAM,
          true,
          MENU_CONSTANTS.CONG_CU,
          null,
          {
            maphieuthu: form.maphieuthu,
            ngaytaikham: form.ngaytaikham,
          },
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CONG_CU,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(
    typeAction.TOO_GOP_BENH_AN,
    function* gopBenhAn({ type, reset, idbn, idbndelete, listName }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        reset()
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield toolService.putGopBenhAn(idbn, idbndelete)

        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'success',
          title: 'Gộp thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.GOP_HO_SO_BENH_AN,
          true,
          MENU_CONSTANTS.CONG_CU,
          null,
          {
            nameBN: listName.nameBN,
            nameBNDelete: listName.nameBNDelete,
          },
        )
      } catch (error) {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Gộp thất bại!',
        })
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CONG_CU,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(
    typeAction.PUT_CALAMVIEC_PHIEUTHU_TOOL,
    function* putCaLamViecCuaPhieuThuSaga({ type, form, reset, listName }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() =>
          toolService.putCaLamViecCuaPhieuThu(
            form?.maPhieuThu,
            form?.idCaLamViec,
            form?.idLoaiTT,
            form?.idNguoiSua,
          ),
        )
        yield call(() => reset())
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_SUA_CA_LAM_VIEC,
          true,
          MENU_CONSTANTS.CONG_CU,
          null,
          {
            maphieuthu: form?.maPhieuThu,
            loaithanhtoan: listName.loaithanhtoan,
            calamviec: listName.calamviec,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })

        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CONG_CU,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  yield takeLatest(
    typeAction.PUT_LOAIKHAM_PHIEUTHU_TOOL,
    function* putLoaiKhamCuaPhieuThuSaga({ type, form, reset, tenloaikham }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() =>
          toolService.putLoaiKhamCuaPhieuThu(form?.maPhieuThu, form?.idLoaiKham, form?.idNguoiSua),
        )
        yield call(() => reset())
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_SUA_LOAI_KHAM,
          true,
          MENU_CONSTANTS.CONG_CU,
          null,
          {
            maphieuthu: form?.maPhieuThu,
            tenloaikham,
          },
        )
      } catch (error) {
        yield console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CONG_CU,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  yield takeLatest(
    typeAction.DELETE_PHIEU_KHO_TOOL,
    function* deletePhieuKho({ type, maPhieu, reset }) {
      try {
        yield call(() => toolService.deletePhieuKho(maPhieu))
        reset()
        Toast.fire({
          icon: 'success',
          title: 'Xoá thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
      }
    },
  )
  yield takeLatest(
    typeAction.PUT_NGUOIBAN_TTV_TOOL,
    function* putNguoiBanTTVSaga({ type, form, reset }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() =>
          toolService.putNguoiBanTTV(
            form?.maPhieuThu,
            form?.idNguoiBan ?? null,
            form?.idNguoiTao ?? null,
          ),
        )
        yield call(() => reset())
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_SUA_NGUOI_BAN_TTV,
          true,
          MENU_CONSTANTS.CONG_CU,
          null,
          {
            maphieuthu: form?.maPhieuThu,
            tennguoiban: form?.tenNguoiBan,
            tennguoitao: form?.tenNguoiTao,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CONG_CU,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
