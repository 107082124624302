import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { sieuAmService } from '../../services/SieuAm/sieuAmService'
import { loiDanBsService } from '../../services/loiDanBS/LoiDanBsService'
import { fetchLoiDanBsDanhMuc } from '../actions/loiDanBsAction'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
// Sagas
export function* LoiDanBsSaga() {
  //----------------GET LOI DAN BÁC SĨ ---------------//
  yield takeLatest(typeAction.GET_LOI_DAN_BS_DANH_MUC, function* loiDans({ type, search }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({ type: typeAction.OPEN_LOADING_TABLE })
      console.log(search)

      const { data } = yield call(() => loiDanBsService.getAllLoiDan(search))
      yield put({
        type: typeAction.DISPATCH_LOI_DAN_DANH_MUC,
        payload: data,
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_DS_LOI_DAN,
        true,
        MENU_CONSTANTS.LOI_DAN,
        null,
        {
          keyword: search ?? '',
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.LOI_DAN,
        detailErrorPayload,
      )
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_TABLE })
    }
  })
  //---------------- DELETE LỜI DẶN BÁC SĨ ---------------//
  yield takeLatest(
    typeAction.DELETE_LOI_DAN_BAC_SI,
    function* deleteLoiDan({ type, record, reload }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => loiDanBsService.deleteLoiDanById(record.idld))
        yield reload() // reload lại dữ liệu sau khi xoá
        Toast.fire({
          icon: 'success',
          title: 'Xoá thành công !',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          logAction.XOA_LOI_DAN,
          true,
          MENU_CONSTANTS.LOI_DAN,
          null,
          {
            ...record,
          },
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Xoá thất bại !',
        })
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.LOI_DAN,
          detailErrorPayload,
        )
      }
    },
  )
  //---------------- THÊM MỚI LỜI DẶN BÁC SĨ ---------------//
  yield takeLatest(
    typeAction.ADD_NEW_LOI_DAN_BAC_SI,
    function* addNewLiDan({ type, form, reload }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => loiDanBsService.postNewLoiDan(form))
        yield reload() // reload lại dữ liệu sau thêm
        Toast.fire({
          icon: 'success',
          title: 'Thêm mới thành công !',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.THEM_MOI_LOI_DAN,
          true,
          MENU_CONSTANTS.LOI_DAN,
          null,
          {
            ...form,
          },
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Thêm mới thất bại !',
        })
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.LOI_DAN,
          detailErrorPayload,
        )
      }
    },
  )
  //---------------- THÊM MỚI LỜI DẶN BÁC SĨ ---------------//
  yield takeLatest(typeAction.GET_INFO_LOI_DAN, function* getInfoLoiDan({ type, info }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)

    try {
      const { data } = yield call(() => loiDanBsService.getInfoLoiDan(info.idld))
      yield put({
        type: typeAction.DISPATCH_INFO_LOI_DAN,
        payload: data,
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_CHI_TIET_LOI_DAN,
        true,
        MENU_CONSTANTS.LOI_DAN,
        null,
        {
          ...info,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.LOI_DAN,
        detailErrorPayload,
      )
    }
  })
  //----------------  CẬP NHẬP LỜI DẶN ---------------//
  yield takeLatest(
    typeAction.UPDATE_LOI_DAN_BAC_SI,
    function* updateLoiDan({ type, form, reload }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield call(() => loiDanBsService.putLoiDanById(form))
        yield reload()
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công !',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_SUA_LOI_DAN,
          true,
          MENU_CONSTANTS.LOI_DAN,
          null,
          {
            ...form,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại !',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.LOI_DAN,
          detailErrorPayload,
        )
      }
    },
  )
}
