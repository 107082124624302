import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { NhapKhoService } from '../../services/NhapKho/NhapKhoService'
import Swal from 'sweetalert2'
import {
  filterPTNhapKhoAction,
  getAllPhieuNhapKho,
  getInfoDoitac,
  getInfoPTNhapByIdAction,
} from '../actions/NhapKhoAction'
import { generatePhieuNhapKho } from '../../utils/report/phieuNhapKho'
import { branchService } from '../../services/branch/branchService'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import 'moment/locale/vi'
import { khoVTservice } from '../../services/khoVT/khoVTservice'
import { addLog } from '../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
import { CalulateGPP } from '../../utils/CalulateGPP'
import { reportService } from '../../services/Report/reportService'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* NhapKhoSaga() {
  // get chi nhánh và kho mặc định
  yield takeLatest(typeAction.GET_BRANCH_AND_KHO_VT_DEFAULT, function* branch({ type, payload }) {
    // yield console.log(payload);
    try {
      const idBranch = localStorage.getItem('BRANH_LOGIN')
      const result = yield call(() => NhapKhoService.getBranch(idBranch))
      const res = yield call(() => NhapKhoService.getListKhoNhap(idBranch))
      yield put({
        type: typeAction.DISPATCH_BRANCH_NHAPKHO,
        payload: result.data,
      })
      yield put({
        type: typeAction.DISPATCH_DEFAULT_KHONHAP,
        payload: res.data,
      })
    } catch (err) {
      console.log(err)
    }
  })

  //get kho nhập
  yield takeLatest(typeAction.GET_BRANCH_NHAPKHO, function* listKhoNhap({ type, idChiNhanh }) {
    try {
      const result = yield call(() => NhapKhoService.getListKhoNhap(idChiNhanh))
      yield put({
        type: typeAction.DISPATCH_LIST_KHONHAP,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  //LÂY DANH SÁCH CHI NHÁNH NHẬP KHO THEO CÔNG TY
  yield takeLatest(
    typeAction.GET_BRANCH_NHAP_KHO_BY_COMPANY,
    function* branchByCompany({ type, IDCompany }) {
      try {
        const result = yield call(() => branchService.getListBranch(IDCompany))
        yield put({
          type: typeAction.DISPATCH_BRANCH_BY_COMPANY,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
  //get list đối tác
  yield takeLatest(typeAction.GET_LIST_DOITAC, function* listDoiTac({ type, payload }) {
    // yield console.log(payload);
    try {
      const result = yield call(() => NhapKhoService.getListDoiTac())
      yield put({
        type: typeAction.DISPATCH_LIST_DOITAC,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  //LẤY DANH SÁCH CHI NHÁNH NHẬP KHO
  yield takeLatest(
    typeAction.GET_BRANCH_NHAP_KHO_PT,
    function* ({ type, IDCompany, idChuyenKhoa }) {
      // yield console.log(payload);
      try {
        const result = yield call(() =>
          branchService.getBranchsByIdCtyAndIdChuyenKhoa(IDCompany, idChuyenKhoa),
        )
        yield put({
          type: typeAction.DISPATCH_BRANCH_PT_NHAP_KHO,
          payload: result.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  //get info Đối tác
  yield takeLatest(typeAction.GET_INFO_DOITCA, function* infoDoiTac({ type, payload }) {
    // yield console.log(payload);
    try {
      const result = yield call(() => NhapKhoService.getInfoDoiTac(payload))
      yield put({
        type: typeAction.DISPATCH_INFO_DOITAC,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  // get info thuốc và vật tư
  yield takeLatest(
    typeAction.GET_INFO_THUOCVT,
    function* getInfoVTYT({ type, setSelectedItem, payload }) {
      try {
        const result = yield call(() => NhapKhoService.getInfoThuocVT(payload))
        const data = result.data
        const formKhoChiTiet = yield {
          tienVAT: data.GIAMUA * data.QUYCACHDONGGOI * 0.05,
          ptVAT: 0.05,
          idThuoc: data.IDTHUOC,
          soLo: '',
          hanDung: null,
          soLuong: 1,
          countLe: 0,
          donGiaMua: data.GIAMUA,
          donGiaBan: 0,
          phiVanChuyen: 0,
          phiGiaCong: 0,
          soLuongNhan: 0,
          trangThaiChuyenKho: 5,
          ptckTruocVat: 0,
          ckTruocVat: 0,
          vat5: data.GIAMUA * data.QUYCACHDONGGOI * 0.05,
          vat8: 0,
          vat10: 0,
          thanhTien: data.GIAMUA * data.QUYCACHDONGGOI + data.GIAMUA * data.QUYCACHDONGGOI * 0.05,
          thucTra: data.GIAMUA * data.QUYCACHDONGGOI + data.GIAMUA * data.QUYCACHDONGGOI * 0.05,
          soLuongDongGoi: data.QUYCACHDONGGOI,
          quyCachDongGoi: data.QUYCACH,
          nhietDo: null,
          maSoDangKy: data.MASODANGKY,
          tongTienTruocVAT: data.GIAMUA * data.QUYCACHDONGGOI,
          ghiChu: '',
          giaGPP: CalulateGPP(data.GIAMUA),
        }
        //get msdk by id thuoc
        const optionsMSDK = yield call(() => khoVTservice.getMSDKThuoc(data.IDTHUOC))
        const formatData = [...optionsMSDK.data.map((val) => val.maSoDangKy), data.MASODANGKY]
        const newItems = {
          ...data,
          khoChiTiet: formKhoChiTiet,
          optionsMSDK: formatData.filter((val) => val !== null),
        }
        yield put({
          type: typeAction.DISPATCH_LIST_INFO_THUOCVT,
          payload: { newItems, setSelectedItem },
        })
      } catch (err) {
        console.log(err)
      }
    },
  )

  //ADD Phiếu nhập kho và kho chi tiết
  yield takeLatest(
    typeAction.POST_PHIEU_NHAP_KHO,
    function* postPhieuNhapKho({ type, payload, ListThuocVT, xuatPhieuNhapKho }) {
      // yield console.log(payload);
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const phieuNhap = yield call(() => NhapKhoService.postPhieuNhap(payload))
        const id = yield phieuNhap.data.data.idNhapXuat
        const array = []
        ListThuocVT.forEach((item, index) => {
          const { khoChiTiet, ...others } = item
          const { tienVAT, ptVAT, ...data } = khoChiTiet

          data.idNhapXuat = id
          data.soLuongLe = item.khoChiTiet.soLuong * item.QUYCACHDONGGOI + item.khoChiTiet.countLe
          data.soHoaDon = phieuNhap.data.data.soHoaDon

          // Initialize VAT fields
          data.vat5 = 0
          data.vat8 = 0
          data.vat10 = 0

          // Assign VAT based on ptVAT
          if (ptVAT === 0.05) {
            data.vat5 = tienVAT
          } else if (ptVAT === 0.1) {
            data.vat10 = tienVAT
          } else if (ptVAT === 0.08) {
            data.vat8 = tienVAT
          }

          // Add index field
          data.stt = index + 1

          array.push(data)
        })
        const khoChiTiet = yield call(() => NhapKhoService.postkhoChiTiet(array))
        Toast.fire({
          icon: 'success',
          title: 'Thêm Phiếu nhập thành công',
        })
        if (xuatPhieuNhapKho) {
          //xuat phieu nhap kho
          if (phieuNhap.data.data.tenKhoNhap === 'Kho vắc xin') {
            const resultCN = yield call(() =>
              branchService.getInfoBranch(phieuNhap.data.data.idChiNhanhNhan),
            )
            let clonePK = cloneDeep(PkDangNhap)
            clonePK = {
              ...clonePK,
              idChuyenKhoa: resultCN.data.idChuyenKhoa,
              diaChi: resultCN.data.diaChi,
              dienThoai: resultCN.data.dienThoai,
              sohotline: resultCN.data.sohotline,
            }
            generatePhieuNhapKho(phieuNhap.data.data, khoChiTiet.data, clonePK)
          } else {
            generatePhieuNhapKho(phieuNhap.data.data, khoChiTiet.data, PkDangNhap)
          }
        }

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.TAO_PHIEU_NHAP_KHO,
          true,
          MENU_CONSTANTS.NHAP_KHO,
          null,
          {
            maphieu: phieuNhap.data.data.maPhieu,
            tenphieu: phieuNhap.data.data.tenPhieu,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Thêm Phiếu thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.NHAP_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  // get add list phiếu nhập kho
  yield takeLatest(typeAction.GET_ALL_PHIEU_NHAP, function* getAllListPhieuNhap({ type, payload }) {
    // yield console.log(payload);
    try {
      const result = yield call(() => NhapKhoService.getPhieuNhapKho())
      yield put({
        type: typeAction.DISPATCH_LIST_PHIEU_NHAP,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })

  // DELETE PHIẾU NHẬP KHO THEO ID PHIẾU NHẬP KHO
  yield takeLatest(
    typeAction.DELETE_PHIEU_NHAP_KHO,
    function* deletePhieuNhap({ type, idPhieu, filter, maphieunhap }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield call(() => NhapKhoService.deletePhieu(idPhieu)) // sau khi xoá phiếu thành công tải lại danh sách phiếu nhập kho
        yield put(filterPTNhapKhoAction(filter))
        Toast.fire({
          icon: 'success',
          title: 'Xoá phiếu thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          logAction.XOA_PHIEU_NHAP_KHO,
          true,
          MENU_CONSTANTS.NHAP_KHO,
          null,
          {
            maphieunhap,
          },
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Xoá phiếu thất bại!',
        })
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.NHAP_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  // LẤY THÔNG TIN PHIẾU NHẬP KHO THEO ID
  yield takeLatest(typeAction.GET_INFO_PT_NHAP_KHO, function* infoPTNhapKho({ type, idNhapXuat }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)

    try {
      const result = yield call(() => NhapKhoService.getInfoPTNhapKho(idNhapXuat)) //
      const thuocVT = yield call(() => NhapKhoService.getInfoThuocVTByIdNhapXuat(idNhapXuat))
      const data = thuocVT.data.map((items, index) => {
        let ptvat = 0
        let countLe =
          items.soLuongLe -
          Math.floor(items.soLuongLe / items.soLuongDongGoi) * items.soLuongDongGoi
        if (items.vat5 !== 0) {
          ptvat = 5
        } else if (items.vat8 !== 0) {
          ptvat = 8
        } else if (items.vat10 !== 0) {
          ptvat = 10
        }
        return { ...items, ID: index, ptvat, countLe }
      })
      yield put({
        type: typeAction.DISPATCH_INFO_THUOVT_NHAP_KHO,
        payload: data,
      })
      yield put({
        type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
        payload: result.data,
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.CHI_TIET_PHIEU_NHAP,
        true,
        MENU_CONSTANTS.NHAP_KHO,
        null,
        {
          maphieunhap: result.data.maPhieu,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.NHAP_KHO,
        detailErrorPayload,
      )
    }
  })
  // FILTER DANH SÁCH PHIẾU NHẬP KHO
  yield takeLatest(typeAction.GET_FILTER_PT_NHAP_KHO, function* infoPTNhapKho({ type, filter }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)

    try {
      yield put({
        type: typeAction.OPEN_LOADING_TABLE_NHAP_KHO,
      })
      const result = yield call(() => NhapKhoService.getPTBycondition(filter)) //
      yield put({
        type: typeAction.DISPATCH_LIST_PHIEU_NHAP,
        payload: result.data,
      })
      yield put({
        type: typeAction.CLOSE_LOADING_TABLE_NHAP_KHO,
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_DS_PHIEU_NHAP,
        true,
        MENU_CONSTANTS.NHAP_KHO,
        null,
        {
          dateFrom: filter.since,
          dateTo: filter.toDate,
          nameCompany: filter.nameCompany,
          nameChiNhanh: filter.nameChiNhanh,
        },
      )
    } catch (error) {
      yield put({
        type: typeAction.CLOSE_LOADING_TABLE_NHAP_KHO,
      })
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.NHAP_KHO,
        detailErrorPayload,
      )
    }
  })
  // Lây Danh sách thuốc vật tư theo keyword
  yield takeLatest(
    typeAction.GET_THUOCVT_BY_KEYWORD,
    function* SearchThuocVT({ type, keyword, company }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        const result = yield call(() => NhapKhoService.getThuocVTByKeyword(keyword, company)) //
        yield put({
          type: typeAction.DISPATCH_ALL_THUOCVT,
          payload: result.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.SEARCH_THUOC_VAT_TU,
          true,
          MENU_CONSTANTS.NHAP_KHO,
          null,
          {
            keyword,
          },
        )
      } catch (err) {
        console.log(err)
      }
    },
  )
  // UPDATE PHIẾU THU KHO NHẬP HÀNG HOÁ
  yield takeLatest(
    typeAction.UPDATE_INFO_PT_NHAP_KHO,
    function* UpdateInfoPTNhapKho({ type, form, idNhapXuat }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const jsonData = {}

      for (const [key, value] of form) {
        jsonData[key] = value
      }
      try {
        yield call(() => NhapKhoService.putInfoPTNhapKho(form, idNhapXuat)) //
        const result = yield call(() => NhapKhoService.getInfoPTNhapKho(idNhapXuat)) //
        yield put({
          type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
          payload: result.data,
        })
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công!',
        })

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.SUA_THONG_TIN_PHIEU_NHAP,
          true,
          MENU_CONSTANTS.NHAP_KHO,
          null,
          {
            maphieunhap: jsonData.MaPhieu,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(jsonData)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.NHAP_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  // UPDATE HÀNG HOÁ KHO CHI TIẾT
  yield takeLatest(
    typeAction.UPDATE_KHO_CHI_TIET,
    function* UpdateInfokhoChiTiet({ type, form, idNhapXuat, cancel, nameEdit, maphieunhap }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        let count = form.soLuongLe + form.countLe
        let SL = Math.floor(count / form.soLuongDongGoi)
        const data = { ...form, soLuongLe: count, soLuong: SL }
        // console.log(form)
        yield call(() => NhapKhoService.putKhoChiTiet(data)) // cập  nhập hàng
        const thuocVT = yield call(() => NhapKhoService.getInfoThuocVTByIdNhapXuat(idNhapXuat))
        const infoThuoc = thuocVT.data.map((items, index) => {
          let ptvat = 0
          let countLe =
            items.soLuongLe -
            Math.floor(items.soLuongLe / items.soLuongDongGoi) * items.soLuongDongGoi
          if (items.vat5 !== 0) {
            ptvat = 5
          } else if (items.vat8 !== 0) {
            ptvat = 8
          } else if (items.vat10 !== 0) {
            ptvat = 10
          }
          return { ...items, ID: index, ptvat, countLe }
        })
        cancel()
        yield put({
          type: typeAction.DISPATCH_INFO_THUOVT_NHAP_KHO,
          payload: infoThuoc,
        })
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.SUA_HANG_PHIEU_NHAP,
          true,
          MENU_CONSTANTS.NHAP_KHO,
          null,
          {
            tenhang: nameEdit,
            maphieunhap,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.NHAP_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  //XÁC NHÂN NHẬP KHO
  yield takeLatest(
    typeAction.UPDATE_XAC_NHAN_NHAP_KHO,
    function* UpdateKho({ type, idNhapXuat, filter }) {
      try {
        const STATUS = 3
        yield call(() => NhapKhoService.updateXacNhanKho(idNhapXuat, STATUS))
        yield put(filterPTNhapKhoAction(filter))
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại!',
        })
      }
    },
  )
  // Delete Kho chi tiết
  yield takeLatest(
    typeAction.DELETE_KHO_CHI_TIET,
    function* UpdateInfokhoChiTiet({ type, id, idNhapXuat, nameDelete, maphieunhap }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => NhapKhoService.deleteKhoChiTiet(id)) //
        const thuocVT = yield call(() => NhapKhoService.getInfoThuocVTByIdNhapXuat(idNhapXuat))
        const infoThuoc = thuocVT.data.map((items, index) => {
          let ptvat = 0
          let countLe =
            items.soLuongLe -
            Math.floor(items.soLuongLe / items.soLuongDongGoi) * items.soLuongDongGoi
          if (items.vat5 !== 0) {
            ptvat = 5
          } else if (items.vat8 !== 0) {
            ptvat = 8
          } else if (items.vat10 !== 0) {
            ptvat = 10
          }
          return { ...items, ID: index, ptvat, countLe }
        })
        yield put({
          type: typeAction.DISPATCH_INFO_THUOVT_NHAP_KHO,
          payload: infoThuoc,
        })
        Toast.fire({
          icon: 'success',
          title: 'xoá thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          logAction.XOA_HANG_PHIEU_NHAP,
          true,
          MENU_CONSTANTS.NHAP_KHO,
          null,
          {
            tenhang: nameDelete,
            maphieunhap,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Xoá thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.NHAP_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  //------------- UPLOAD FILE------------
  yield takeLatest(
    typeAction.UPLOAD_FILE_NHAP_KHO,
    function* uploadFile({ type, id, form, maphieunhap }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => NhapKhoService.uploadFile(id, form))
        const result = yield call(() => NhapKhoService.getInfoPTNhapKho(id)) //
        yield put({
          type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
          payload: result.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CAP_NHAT_FILE_PHIEU_NHAP,
          true,
          MENU_CONSTANTS.NHAP_KHO,
          null,
          {
            maphieunhap,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.NHAP_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  //------------- DELTE FILE NHAP KHO------------
  yield takeLatest(
    typeAction.DELETE_FILE_NHAP_KHO,
    function* deleteFile({ type, id, form, maphieunhap }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => NhapKhoService.deleteFile(form))
        const result = yield call(() => NhapKhoService.getInfoPTNhapKho(id)) //
        yield put({
          type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
          payload: result.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          logAction.DELETE_FILE_PHIEU_NHAP,
          true,
          MENU_CONSTANTS.NHAP_KHO,
          null,
          {
            maphieunhap,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.NHAP_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  //------------- ACCPECT NHAP KHO MODAL ------------
  yield takeLatest(
    typeAction.ACCPECT_NHAP_KHO_MODAL,
    function* accecptNhapKho({ type, id, maphieunhap }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const STATUS = 3
        yield call(() => NhapKhoService.updateXacNhanKho(id, STATUS))
        const result = yield call(() => NhapKhoService.getInfoPTNhapKho(id)) //
        yield put({
          type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
          payload: result.data,
        })
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.XAC_NHAN_NHAP_KHO_PHIEU_NHAP,
          true,
          MENU_CONSTANTS.NHAP_KHO,
          null,
          {
            maphieunhap,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.NHAP_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  //------------------UPDATE trạng thái nhập kho ----------
  yield takeLatest(
    typeAction.UPDATE_STATUS_NHAP_KHO_TOOL,
    function* updateStatusKho({ type, maPhieu, status }) {
      try {
        const { data } = yield call(() => NhapKhoService.getPhieuHangByMaHang(maPhieu))
        const result = yield call(() => NhapKhoService.updateStatusPhieu(data.idnhapxuat, status)) //

        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công!',
        })
      } catch (err) {
        console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại!',
        })
      }
    },
  )
  //------------- SAP CHEP MA HANG Phiếu nhập ------------
  yield takeLatest(
    typeAction.COPY_LIST_HANG_HOA,
    function* copyHangHoa({ type, mahang, setMaHang, setSelectedItem, formik }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data } = yield call(() => NhapKhoService.getPhieuHangByMaHang(mahang))
        yield put(getInfoDoitac(data.iddoitac))
        formik.setFieldValue('SoHoaDon', data.sohoadon)
        formik.setFieldValue('IdHinhThuc', Number(data.idhinhthuc))
        formik.setFieldValue('IdPhuongThuc', Number(data.idphuongthuc))
        formik.setFieldValue('IdDoiTac', data.iddoitac)
        if (data.ngayhoadon) {
          formik.setFieldValue('NgayHoaDon', data.ngayhoadon)
        }
        const result = yield call(() => NhapKhoService.getKhoChitietByNhapXuat(data.idnhapxuat))
        setMaHang(null)
        for (let item of result.data) {
          const dataInfo = yield call(() => NhapKhoService.getInfoThuocVT(item.idThuoc))
          const formKhoChiTiet = yield {
            tienVAT: item.vat5 + item.vat8 + item.vat10,
            ptVAT: item.vat5 !== 0 ? 0.05 : item.vat8 !== 0 ? 0.08 : item.vat10 !== 0 ? 0.1 : 0,
            idThuoc: item.idThuoc,
            soLo: item.soLo,
            hanDung: item.hanDung,
            soLuong: item.soLuong,
            countLe: item.soLuongLe - item.soLuong * item.soLuongDongGoi,
            donGiaMua: item.donGiaMua,
            donGiaBan: item.donGiaBan,
            phiVanChuyen: item.phiVanChuyen,
            phiGiaCong: item.phiGiaCong,
            soLuongNhan: item.soLuongNhan,
            trangThaiChuyenKho: 5,
            ptckTruocVat: item.ptckTruocVat,
            ckTruocVat: item.ckTruocVat,
            vat5: item.vat5,
            vat8: item.vat8,
            vat10: item.vat10,
            thanhTien: item.thanhTien,
            thucTra: item.thucTra,
            soLuongDongGoi: item.soLuongDongGoi,
            quyCachDongGoi: item.quyCachDongGoi,
            nhietDo: item.nhietDo,
            maSoDangKy: item.maSoDangKy,
            tongTienTruocVAT: item.tongTienTruocVAT,
            ghiChu: item.ghiChu,
            giaGPP: item.giaGPP,
          }
          const newItems = { ...dataInfo.data, khoChiTiet: formKhoChiTiet }
          yield put({
            type: typeAction.DISPATCH_LIST_INFO_THUOCVT,
            payload: { newItems, setSelectedItem },
          })
        }
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.COPY_HANG_HOA,
          true,
          MENU_CONSTANTS.NHAP_KHO,
          null,
          {
            maphieu: mahang,
          },
        )
      } catch (error) {
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TIEP_NHAN,
          detailErrorPayload,
        )
      }
    },
  )
  //---------- SAO CHÉP HÀNG HOÁ VPP -------------
  yield takeLatest(
    typeAction.COPY_PRODUCTS_VPP,
    function* copyHangVPP({ type, mahang, setMaHang, iddoitac, setSelectedItem, formik }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data } = yield call(() => NhapKhoService.getHangVPPNhaCC(mahang, iddoitac))
        setMaHang(null)
        for (let item of data) {
          const dataInfo = yield call(() => NhapKhoService.getInfoThuocVT(item.idthuoc))
          let vat_5 = 0
          let vat_8 = 0
          let Vat_10 = 0
          if (item.vat === 5) {
            vat_5 = 0.05
          } else if (item.vat === 8) {
            vat_8 = 0.08
          } else if (item.vat === 10) {
            Vat_10 = 0.1
          }
          const formKhoChiTiet = yield {
            tienVAT: item.vat * item.thanhtien,
            ptVAT: item.vat === 5 ? 0.05 : item.vat === 8 ? 0.08 : item.vat === 10 ? 0.1 : 0,
            idThuoc: item.idThuoc,
            soLo: '',
            hanDung: null,
            soLuong: Math.floor(item.soluong / dataInfo.data.QUYCACHDONGGOI),
            countLe:
              item.soluong -
              Math.floor(item.soluong / dataInfo.data.QUYCACHDONGGOI) *
                dataInfo.data.QUYCACHDONGGOI,
            donGiaMua: item.giamua,
            donGiaBan: dataInfo.data.GIABAN,
            phiVanChuyen: 0,
            phiGiaCong: 0,
            soLuongNhan: 0,
            trangThaiChuyenKho: 5,
            ptckTruocVat: 0,
            ckTruocVat: 0,
            vat5: item.giamua * dataInfo.data.QUYCACHDONGGOI * vat_5,
            vat8: item.giamua * dataInfo.data.QUYCACHDONGGOI * vat_8,
            vat10: item.giamua * dataInfo.data.QUYCACHDONGGOI * Vat_10,
            thanhTien:
              item.giamua * dataInfo.data.QUYCACHDONGGOI +
              item.giamua * dataInfo.data.QUYCACHDONGGOI * item.vat,
            thucTra:
              item.giamua * dataInfo.data.QUYCACHDONGGOI +
              item.giamua * dataInfo.data.QUYCACHDONGGOI * item.vat,
            soLuongDongGoi: dataInfo.data.QUYCACHDONGGOI,
            quyCachDongGoi: dataInfo.data.QUYCACH,
            nhietDo: null,
            maSoDangKy: dataInfo.data.MASODANGKY,
            tongTienTruocVAT: item.giamua * dataInfo.data.QUYCACHDONGGOI,
            ghiChu: '',
          }
          const newItems = { ...dataInfo.data, khoChiTiet: formKhoChiTiet }
          yield put({
            type: typeAction.DISPATCH_LIST_INFO_THUOCVT,
            payload: { newItems, setSelectedItem },
          })
        }
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (err) {
        console.log(err)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  //------------- ADD NEW THUOC VAT TU CHI TIET PHIEU ------------
  yield takeLatest(
    typeAction.ADD_NEW_THUOC_DETAIL_NHAP_KHO,
    function* addNewThuocVT({ type, index, id, idNhapXuat, cancel, maphieunhap }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const { infoPTNhap } = yield select((state) => state.NhapKhoReducer)
      try {
        const toDay = moment()
        cancel()
        const result = yield call(() => NhapKhoService.getInfoThuocVT(id))
        const data = result.data
        const Newdata = {
          stt: ++index,
          idNhapXuat: idNhapXuat,
          tienVAT: data.GIAMUA * data.QUYCACHDONGGOI * 0.08,
          ptVAT: 0.08,
          idThuoc: data.IDTHUOC,
          soLo: '0',
          hanDung: toDay.format('YYYY-MM-DD'),
          soLuong: 0,
          donGiaMua: data.GIAMUA,
          donGiaBan: 0,
          phiVanChuyen: 0,
          phiGiaCong: 0,
          soLuongNhan: 0,
          soLuongLe: data.QUYCACHDONGGOI,
          trangThaiChuyenKho: 5,
          ptckTruocVat: 0,
          ckTruocVat: 0,
          vat5: 0,
          vat8: data.GIAMUA * data.QUYCACHDONGGOI * 0.08,
          vat10: 0,
          thanhTien: data.GIAMUA * data.QUYCACHDONGGOI + data.GIAMUA * data.QUYCACHDONGGOI * 0.08,
          thucTra: data.GIAMUA * data.QUYCACHDONGGOI + data.GIAMUA * data.QUYCACHDONGGOI * 0.08,
          soLuongDongGoi: data.QUYCACHDONGGOI,
          quyCachDongGoi: data.QUYCACH,
          maSoDangKy: data.MASODANGKY,
          tongTienTruocVAT: data.GIAMUA * data.QUYCACHDONGGOI,
          giaGPP: CalulateGPP(data.GIAMUA),
          soHoaDon:infoPTNhap?.soHoaDon
        }
        yield call(() => NhapKhoService.postkhoChiTiet([Newdata]))
        const thuocVT = yield call(() => NhapKhoService.getInfoThuocVTByIdNhapXuat(idNhapXuat))
        const infoThuoc = thuocVT.data.map((items, index) => {
          let ptvat = 0
          let countLe =
            items.soLuongLe -
            Math.floor(items.soLuongLe / items.soLuongDongGoi) * items.soLuongDongGoi
          if (items.vat5 !== 0) {
            ptvat = 5
          } else if (items.vat8 !== 0) {
            ptvat = 8
          } else if (items.vat10 !== 0) {
            ptvat = 10
          }
          return { ...items, ID: index, ptvat, countLe }
        })

        yield put({
          type: typeAction.DISPATCH_INFO_THUOVT_NHAP_KHO,
          payload: infoThuoc,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.THEM_HANG_PHIEU_NHAP,
          true,
          MENU_CONSTANTS.NHAP_KHO,
          null,
          {
            tenhang: result.data.TENBIETDUOC,
            maphieunhap,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'thêm thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails({
          index,
          id,
          idNhapXuat,
        })} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.NHAP_KHO,
          detailErrorPayload,
        )
      }
    },
  )

  // get info phiếu nhập vpp và list hàng cần phải nhập
  yield takeLatest(
    typeAction.GET_INFO_FORM_PHIEUNHAP_VPP,
    function* getInfoFormPhieuNhapVPPSaga({ type, infodt, setIsModalNhapKho }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        let listHang = []
        if (infodt?.mathang?.length) {
          // listHang = infodt?.mathang?.map((item)=>{idcn:})
          for (const item of infodt?.mathang) {
            const { data } = yield call(() => NhapKhoService.getInfoThuocVT(item?.idhang))
            const formKhoChiTiet = yield {
              tienVAT: data?.GIAMUA * item?.soluongduyet * 0.05,
              ptVAT: 0.05,
              idThuoc: data?.IDTHUOC,
              soLo: '',
              hanDung: null,
              soLuong: 0,
              countLe: item?.soluongduyet, //
              donGiaMua: data?.GIAMUA,
              donGiaBan: 0,
              phiVanChuyen: 0,
              phiGiaCong: 0,
              soLuongNhan: 0,
              trangThaiChuyenKho: 5,
              ptckTruocVat: 0,
              ckTruocVat: 0,
              vat5: data?.GIAMUA * item?.soluongduyet * 0.05,
              vat8: 0,
              vat10: 0,
              thanhTien:
                data?.GIAMUA * item?.soluongduyet + data?.GIAMUA * item?.soluongduyet * 0.05,
              thucTra: data?.GIAMUA * item?.soluongduyet + data?.GIAMUA * item?.soluongduyet * 0.05,
              soLuongDongGoi: data?.QUYCACHDONGGOI,
              quyCachDongGoi: data?.QUYCACH,
              nhietDo: null,
              maSoDangKy: data?.MASODANGKY,
              tongTienTruocVAT: data?.GIAMUA * item?.soluongduyet,
              ghiChu: '',
            }
            //get msdk by id thuoc
            const optionsMSDK = yield call(() => khoVTservice.getMSDKThuoc(data?.IDTHUOC))
            const formatData = [...optionsMSDK.data?.map((val) => val.maSoDangKy), data?.MASODANGKY]
            const newItems = {
              ...data,
              khoChiTiet: formKhoChiTiet,
              optionsMSDK: formatData.filter((val) => val !== null),
              slVPPmua: item?.soluongduyet,
            }
            // yield put({
            //   type: typeAction.DISPATCH_LIST_INFO_THUOCVT,
            //   payload: { newItems, setSelectedItem },
            // })
            const idx = listHang?.findIndex((itemLH) => itemLH?.idcn === item?.idchinhanhdat)
            if (idx !== -1) {
              //tìm thấy
              listHang[idx]?.list.push({ ...newItems })
              // listHang?.map((itemLH,index)=>index===idx?{...itemLH,list:}:itemLH)
            } else {
              //không tìm thấy
              listHang.push({
                key: item?.idchinhanhdat,
                idcn: item?.idchinhanhdat,
                tencn: item?.tenchinhanh,
                idct: item?.idct,
                idkhocnvpp: item?.idkhocn,
                list: [{ ...newItems }],
              })
            }
            // if (listHang?.length) {
            // } else {
            //   listHang.push({
            //     idcn: item?.idchinhanhdat,
            //     tencn: item?.tenchinhanh,
            //     list: [{ ...newItems }],
            //   })
            // }
          }
        }
        yield put({
          type: typeAction.DISPATCH_LIST_VPP_NHAPKHO,
          payload: listHang?.map((item) => ({
            // sắp xếp thứ tự thuốc
            ...item,
            list: item?.list?.sort((a, b) =>
              (a?.khoChiTiet?.TENBIETDUOC ?? '')
                .toLowerCase()
                .localeCompare((b?.khoChiTiet?.TENBIETDUOC ?? '').toLowerCase()),
            ),
          })),
        })
        yield call(() => setIsModalNhapKho(true))
      } catch (err) {
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  //------------------post list phiếu nhập kho hàng hóa văn phòng phẩm----------
  yield takeLatest(
    typeAction.POST_LIST_PHIEUNHAP_VPP,
    function* postListPhieuNhapVPPSaga({
      type,
      form,
      isPrint,
      resetFormPhieuNhapVpp,
      setIsLoading,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => setIsLoading(true))
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { listVPP } = yield select((state) => state.NhapKhoReducer)

        let dataPrint = []
        for (const cn of listVPP) {
          const formData = new FormData()
          formData.append('TenPhieu', form?.tenPhieu)
          formData.append('IdKhoNhap', cn?.idkhocnvpp)
          formData.append('NhanVienNhan', infoUser?.idnv)
          formData.append('NgayNhan', moment().format('YYYY-MM-DDTHH:mm:ss'))
          formData.append('GhiChu', form?.ghiChu)
          formData.append('TrangThai', 5)
          formData.append('IdDoiTac', form?.infodt?.idncc)
          formData.append('SoHoaDon', form?.soHoaDon)
          formData.append('NgayHoaDon', form?.ngayHoaDon)
          formData.append('LinkHoaDon', form?.linkHoaDon)
          formData.append('IdHinhThuc', form?.hinhThuc)
          formData.append('IdPhuongThuc', form?.phuongThuc)
          formData.append('IDCT', cn?.idct)
          if (form?.listHinh?.length) {
            const dataHoaDonFiles = form?.listHinh?.map((item) => item.file)
            dataHoaDonFiles?.forEach((file, index) => {
              formData.append('HoaDonFiles', file)
            })
          }
          const phieuNhap = yield call(() => NhapKhoService.postPhieuNhap(formData))
          const id = yield phieuNhap.data.data.idNhapXuat
          const khoChiTiet = yield call(() =>
            NhapKhoService.postkhoChiTiet(
              cn?.list?.map((vpp, index) => ({
                stt: ++index,
                idNhapXuat: id,
                idThuoc: vpp?.khoChiTiet?.idThuoc,
                soLo: vpp?.khoChiTiet?.soLo,
                hanDung: vpp?.khoChiTiet?.hanDung,
                soLuong: vpp?.khoChiTiet?.soLuong, // sl chẵn
                donGiaMua: vpp?.khoChiTiet?.donGiaMua,
                donGiaBan: vpp?.khoChiTiet?.donGiaBan,
                phiVanChuyen: vpp?.khoChiTiet?.phiVanChuyen,
                phiGiaCong: vpp?.khoChiTiet?.phiGiaCong,
                // soLuongNhan: 0,
                trangThaiChuyenKho: 5,
                ptckTruocVat: vpp?.khoChiTiet?.ptckTruocVat,
                ckTruocVat: vpp?.khoChiTiet?.ckTruocVat,
                vat5: vpp?.khoChiTiet?.ptVAT === 0.05 ? vpp?.khoChiTiet?.tienVAT : 0,
                vat8: vpp?.khoChiTiet?.ptVAT === 0.08 ? vpp?.khoChiTiet?.tienVAT : 0,
                vat10: vpp?.khoChiTiet?.ptVAT === 0.1 ? vpp?.khoChiTiet?.tienVAT : 0,
                thanhTien: vpp?.khoChiTiet?.thanhTien,
                thucTra: vpp?.khoChiTiet?.thucTra,
                soLuongLe:
                  vpp?.khoChiTiet?.soLuong * vpp?.khoChiTiet?.soLuongDongGoi +
                  vpp?.khoChiTiet?.countLe, // tổng lẻ
                soLuongDongGoi: vpp?.khoChiTiet?.soLuongDongGoi,
                quyCachDongGoi: vpp?.khoChiTiet?.quyCachDongGoi,
                loaiPhieu: 1,
                nhietDo: vpp?.khoChiTiet?.nhietDo,
                maSoDangKy: vpp?.khoChiTiet?.maSoDangKy,
                tongTienTruocVAT: vpp?.khoChiTiet?.tongTienTruocVAT,
                ghiChu: vpp?.khoChiTiet?.ghiChu,
                soHoaDon: form?.soHoaDon,
              })),
            ),
          )
          dataPrint.push({
            idcn: cn?.idcn, //chi nhánh nhập
            dataPhieuNhap: phieuNhap?.data?.data,
            dataKhoChiTiet: khoChiTiet?.data,
          })
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.TAO_PHIEU_NHAP_KHO_VVP,
            true,
            MENU_CONSTANTS.NHAP_KHO,
            null,
            {
              maphieunhap: phieuNhap?.data?.data?.maPhieu,
              tenphieu: phieuNhap?.data?.data?.tenPhieu,
            },
          )
        }
        yield call(() =>
          NhapKhoService.putTTNhapKhoCTDatHangVPP({
            idncc: form?.infodt?.idncc,
            // idhang: 0,
            nguoinhap: infoUser?.idnv,
            idct: form?.formFilter?.idct,
            idcn:
              !form?.formFilter?.idcn || form?.formFilter?.idcn === ''
                ? null
                : form?.formFilter?.idcn,
            tungay: form?.formFilter?.tungay,
            denngay: form?.formFilter?.denngay,
          }),
        )
        yield call(() => resetFormPhieuNhapVpp())
        if (isPrint && dataPrint?.length) {
          for (const print of dataPrint) {
            const { data: resultCN } = yield call(() => branchService.getInfoBranch(print?.idcn))
            let clonePK = cloneDeep(PkDangNhap)
            clonePK = {
              ...clonePK,
              idChuyenKhoa: resultCN?.idChuyenKhoa,
              diaChi: resultCN?.diaChi,
              dienThoai: resultCN?.dienThoai,
              sohotline: resultCN?.sohotline,
            }
            generatePhieuNhapKho(print?.dataPhieuNhap, print?.dataKhoChiTiet, clonePK)
          }
          // Toast.fire({
          //   icon: 'success',
          //   title: 'In phiếu!',
          // })
          //in phieu nhap kho
          // if (phieuNhap.data.data.tenKhoNhap === 'Kho vắc xin') {
          //   const resultCN = yield call(() =>
          //     branchService.getInfoBranch(phieuNhap.data.data.idChiNhanhNhan),
          //   )
          //   let clonePK = cloneDeep(PkDangNhap)
          //   clonePK = {
          //     ...clonePK,
          //     idChuyenKhoa: resultCN.data.idChuyenKhoa,
          //     diaChi: resultCN.data.diaChi,
          //     dienThoai: resultCN.data.dienThoai,
          //     sohotline: resultCN.data.sohotline,
          //   }
          //   generatePhieuNhapKho(phieuNhap.data.data, khoChiTiet.data, clonePK)
          // } else {
          //   generatePhieuNhapKho(phieuNhap.data.data, khoChiTiet.data, PkDangNhap)
          // }
        }
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.NHAP_KHO,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        yield call(() => setIsLoading(false))
      }
    },
  )
}
