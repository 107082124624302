import { call, put, takeLatest, select } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { nguoidungService } from '../../services/nguoidung/nguoidungService'
import { authService } from '../../services/auth/authService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import ToastCus from '../../utils/Toast'
import Cookies from 'js-cookie'
import history from '../../services/navigateService/navigateService'
import { addLog } from '../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
export function* phanQuyenSaga() {
  // POST QUYỀN HỆ THỐNG
  yield takeLatest(
    typeAction.POST_PHANQUYEN_QUYENHETHONG,
    function* postQuyenHeThongSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        yield call(() =>
          phanQuyenService.postQuyenHeThong({
            tenquyen: (form?.tenquyen ?? '')?.trim(),
            ghichu: (form?.ghichu ?? '')?.trim(),
            nguoitao: infoUser?.idnv,
          }),
        )
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield call(() => resetForm())
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT QUYỀN HỆ THỐNG
  yield takeLatest(
    typeAction.PUT_PHANQUYEN_QUYENHETHONG,
    function* putQuyenHeThongSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        yield call(() =>
          phanQuyenService.putQuyenHeThong({
            idqht: form?.idqht,
            tenquyen: (form?.tenquyen ?? '')?.trim(),
            ghichu: (form?.ghichu ?? '')?.trim(),
            nguoisua: infoUser?.idnv,
            sudung: form?.sudung,
          }),
        )
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield call(() => resetForm())
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT SỬ DỤNG QUYỀN HỆ THỐNG
  yield takeLatest(
    typeAction.PUT_PHANQUYEN_QUYENHETHONG_SUDUNG,
    function* putSuDungQuyenHeThongSaga({ type, info, getListQuyen }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => phanQuyenService.putSuDungQuyenHeThong(info?.idqht, info?.sudung ? 0 : 1))
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.UPDATE_SU_DUNG_QUYEN_HE_THONG,
          true,
          MENU_CONSTANTS.PHAN_QUYEN,
          null,
          {
            tenquyen: info.tenquyen,
            tenmenu: info.tenmenu,
            sudung: info?.sudung ? 0 : 1,
          },
        )
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = err?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Params: ${JSON.stringify({ id: info?.idqht, sudung: info?.sudung ? 0 : 1 })} \nAPI request: ${apiUrl} \nRESPONSE: ${err?.response?.data?.message || err?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.PHAN_QUYEN,
          detailErrorPayload,
        )
      } finally {
        yield call(() => getListQuyen())
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // POST NHÓM QUYỀN
  yield takeLatest(
    typeAction.POST_PHANQUYEN_NHOMQUYEN,
    function* postNhomQuyenSaga({ type, form, resetForm }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        yield call(() =>
          phanQuyenService.postNhomQuyen({
            tennhomquyen: (form?.tennhomquyen ?? '')?.trim(),
            ghichu: (form?.ghichu ?? '')?.trim(),
            nguoitao: infoUser?.idnv,
          }),
        )
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.THEM_NHOM_QUUYEN,
          true,
          MENU_CONSTANTS.PHAN_QUYEN,
          null,
          {
            tennhomquyen: (form?.tennhomquyen ?? '')?.trim(),
          },
        )
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = err?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails({
          tennhomquyen: (form?.tennhomquyen ?? '')?.trim(),
          ghichu: (form?.ghichu ?? '')?.trim(),
          nguoitao: infoUser?.idnv,
        })} \n API request: ${apiUrl} \n RESPONSE: ${err?.response?.data?.message || err?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.PHAN_QUYEN,
          detailErrorPayload,
        )
      } finally {
        yield call(() => resetForm())
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT NHÓM QUYỀN
  yield takeLatest(
    typeAction.PUT_PHANQUYEN_NHOMQUYEN,
    function* putNhomQuyenSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        yield call(() =>
          phanQuyenService.putNhomQuyen({
            idnqht: form?.idnqht,
            tennhomquyen: (form?.tennhomquyen ?? '')?.trim(),
            ghichu: (form?.ghichu ?? '')?.trim(),
            nguoisua: infoUser?.idnv,
            sudung: form?.sudung,
          }),
        )
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield call(() => resetForm())
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT SỬ DỤNG NHÓM QUYỀN
  yield takeLatest(
    typeAction.PUT_PHANQUYEN_NHOMQUYEN_SUDUNG,
    function* putSuDungNhomQuyenSaga({ type, info, getListNhom }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => phanQuyenService.putSuDungNhomQuyen(info?.idnqht, info?.sudung ? 0 : 1))
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.UPDATE_SU_DUNG_NHOM_QUYEN,
          true,
          MENU_CONSTANTS.PHAN_QUYEN,
          null,
          {
            tennhomquyen: info.tennhomquyen,
            sudung: info?.sudung ? 0 : 1,
          },
        )
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = err?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails({
          ...info,
          sudung: info?.sudung ? 0 : 1,
        })} \n API request: ${apiUrl} \n RESPONSE: ${err?.response?.data?.message || err?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.PHAN_QUYEN,
          detailErrorPayload,
        )
      } finally {
        yield call(() => getListNhom())
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT SỬ DỤNG LIST CHI TIẾT NHÓM QUYỀN
  yield takeLatest(
    typeAction.PUT_PHANQUYEN_CHITIET_NHOM_QUYEN_SUDUNG,
    function* putSuDungListCTNQSaga({ type, form, getListChiTietNhomQuyen }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() =>
          phanQuyenService.putSuDungListCTNQ(form?.idnqht, form?.sudung, form?.listIdQuyen),
        )
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.UPDATE_SU_DUNG_LIST_PHAN_QUYEN_HE_THONG,
          true,
          MENU_CONSTANTS.PHAN_QUYEN,
          null,
          {
            tennhomquyen: form.tennhomquyen,
            sudung: form.sudung,
          },
        )
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = err?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${err?.response?.data?.message || err?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.PHAN_QUYEN,
          detailErrorPayload,
        )
      } finally {
        yield call(() => getListChiTietNhomQuyen(form?.idnqht))
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT HIỆU LỰC NGƯỜI DÙNG TRONG PHÂN QUYÊN
  yield takeLatest(
    typeAction.PUT_PHANQUYEN_NGUOIDUNG_HIEULUC,
    function* putHieuLucNguoiDungSaga({ type, info, reLoad }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() =>
          nguoidungService.putNgDungIslockUser([
            {
              username: info?.taikhoan,
              islock: info?.hieuluc ? 0 : 1,
            },
          ]),
        )
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.UPDATE_LOCK_NGUOI_DUNG,
          true,
          MENU_CONSTANTS.PHAN_QUYEN,
          null,
          { username: info?.taikhoan, islock: info?.hieuluc ? 0 : 1 },
        )
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const detailErrorPayload = `Params: \n \t${JSON.stringify({ username: info?.taikhoan, islock: info?.hieuluc ? 0 : 1 })}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.PHAN_QUYEN,
          detailErrorPayload,
        )
      } finally {
        yield call(() => reLoad())
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT RESET MẬT KHẨU NGƯỜI DÙNG TRONG PHÂN QUYÊN
  yield takeLatest(
    typeAction.PUT_PHANQUYEN_NGUOIDUNG_RESETMK,
    function* putResetMkNguoiDungSaga({ type, info, reLoad }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => authService.resetPassUser(info?.taikhoan, infoUser?.idnv))
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.RESET_PASSWORD_NGUOI_DUNG,
          true,
          MENU_CONSTANTS.PHAN_QUYEN,
          null,
          {
            username: info?.taikhoan,
            nguoiSua: infoUser?.idnv,
          },
        )
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = err?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails({
          username: info?.taikhoan,
          nguoiSua: infoUser?.idnv,
        })} \n API request: ${apiUrl} \n RESPONSE: ${err?.response?.data?.message || err?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.PHAN_QUYEN,
          detailErrorPayload,
        )
      } finally {
        yield call(() => reLoad())
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT CẤP QUYỀN CHO TÀI KHOẢN
  yield takeLatest(
    typeAction.PUT_PHANQUYEN_NGUOIDUNG_CAPQUYEN,
    function* putCapQuyenSaga({ type, form, resetForm }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() =>
          phanQuyenService.putCapQuyen(
            form?.idnd,
            form?.idnqht,
            form?.listQuyen?.map((item) => ({
              // idpqnd: 0,
              idnd: form?.idnd,
              idqht: item?.idqht,
              nguoitao: infoUser?.idnv,
              // ngaytao: '2024-11-20T07:13:16.278Z',
              sudung: item?.check ? 1 : 0,
            })),
          ),
        )
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.UPDATE_QUYEN_BY_ID_NGUOI_DUNG,
          true,
          MENU_CONSTANTS.CAP_KEY,
          null,
          {
            taikhoan: form?.taikhoan,
            tennhanvien: form?.tennhanvien,
            manhanvien: form?.manhanvien,
          },
        )
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = err?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${err?.response?.data?.message || err?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CAP_KEY,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT CHECK QUYỀN THAO TÁC CỦA TÀI KHOẢN
  yield takeLatest(
    typeAction.PUT_PHANQUYEN_CHECK_QUYEN,
    function* putCheckQuyenThaoTacSaga({ type, maquyen, info, actionIsTrue, actionIsFalse }) {
      try {
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(
          () =>
            `${maquyen}`?.includes('QHT') &&
            phanQuyenService.putCheckQuyenThaoTac(
              infoUser?.taiKhoan ?? userInfo?.taiKhoan,
              maquyen,
            ),
        )
        yield call(() => actionIsTrue({ ...info, maquyen: maquyen }))
      } catch (error) {
        yield call(() => actionIsFalse({ ...info, maquyen: maquyen }))
        console.error('Error : ', error)
        yield put({
          type: typeAction.DISPATCH_IS_CHECK_ROLE,
          payload: false,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      }
    },
  )
  // LOGOUT TÀI KHOẢN BỊ KHÓA
  yield takeLatest(
    typeAction.PHANQUYEN_LOGOUT_TAIKHOAN_BIKHOA,
    function* logoutTaiKhoanBiKhoaSaga({ type, messageError }) {
      try {
        yield localStorage.removeItem('USER_INFO')
        yield Cookies.remove('access-token')
        yield put({
          type: typeAction.CLOSE_MODAL_CHECK_IN,
        })
        yield put({
          type: typeAction.DISPATCH_LOGOUT_USER,
          payload: null,
        })
        yield put({
          type: typeAction.RESET_STORE,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        history.push('/login')
        // window.location.href = '/login'
        ToastCus.fire({
          icon: 'error',
          title: messageError ?? 'Thao tác thất bại!',
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  // CHECK HIỆU LỰC CỦA TÀI KHOẢN
  yield takeLatest(
    typeAction.PHANQUYEN_CHECK_HIEULUC_TAIKHOAN,
    function* checkHieuLucTaiKhoanSaga({ type, infoUser }) {
      try {
        const { data } = yield call(() => phanQuyenService.getHieuLucNguoiDung(infoUser?.taiKhoan))
        if (!data || !data[0]?.IsLock)
          yield put(logoutTaiKhoanBiKhoaAction('Tài khoản đang bị khóa!'))
        // const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        // const tenTaiKhoan = infoUser?.taiKhoan ?? userInfo?.taiKhoan
        // if (tenTaiKhoan) {
        //   const { data } = yield call(() => phanQuyenService.getHieuLucNguoiDung(tenTaiKhoan))
        //   if (!data || !data[0]?.IsLock) {

        //     yield localStorage.removeItem('USER_INFO')
        //     yield Cookies.remove('access-token')
        //     yield put({
        //       type: typeAction.CLOSE_MODAL_CHECK_IN,
        //     })
        //     yield put({
        //       type: typeAction.DISPATCH_LOGOUT_USER,
        //       payload: null,
        //     })
        //     yield put({
        //       type: typeAction.RESET_STORE,
        //     })
        //     yield put({
        //       type: typeAction.CLOSE_LOADING_PAGE,
        //     })
        //     ToastCus.fire({
        //       icon: 'error',
        //       title: 'Tài khoản đang bị khóa!',
        //     })
        //   }
        //   const arr = (window.location.pathname ?? '')?.split('/')?.filter((item) => item?.length)
        //   const newPath = `${arr?.length ? arr[0] : ''}${arr?.length >= 2 ? '/' + arr[1] : ''}`
        //   // console.log(newPath)
        //   if (
        //     !data[0]?.DanhSachMenu?.find((item) =>
        //       item?.links?.includes(`${newPath}`),
        //     )
        //   ) {
        //     window.location.href = '/'
        //   }
        // }
      } catch (err) {
        console.log(err)
      }
    },
  )

  // PUT LIST PHÂN QUYỀN TRA CỨU
  yield takeLatest(
    typeAction.PUT_LIST_PHANQUYEN_TRACUU,
    function* putListPhanQuyenTraCuuSaga({ type, idck, list }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => phanQuyenService.putListPhanQuyenTraCuu(idck, list))
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
