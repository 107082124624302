import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import { nhanvienService } from '../../services/nhanvien/nhanvienService'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'

export function* userSaga() {
  // POST BỘ PHẬN
  yield takeLatest(
    typeAction.POST_DANHMUC_BOPHAN,
    function* postDanhMucBoPhanSaga({ type, form, resetForm }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        yield call(() =>
          nhanvienService.postBoPhan({
            // idbp: 0,
            mabophan: form?.mabp,
            tenbophan: form?.tenbp,
            // sudung: 1,
            nguoitao: infoUser?.idnv,
            ghichu: form?.ghichu,
          }),
        )
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.THEM_MOI_BO_PHAN,
          true,
          MENU_CONSTANTS.BO_PHAN,
          null,
          {
            form: {
              // idbp: 0,
              mabophan: form?.mabp,
              tenbophan: form?.tenbp,
              // sudung: 1,
              nguoitao: infoUser?.idnv,
              ghichu: form?.ghichu,
            },
          },
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails({
          // idbp: 0,
          mabophan: form?.mabp,
          tenbophan: form?.tenbp,
          // sudung: 1,
          nguoitao: infoUser?.idnv,
          ghichu: form?.ghichu,
        })} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.BO_PHAN,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT BỘ PHẬN
  yield takeLatest(
    typeAction.PUT_DANHMUC_BOPHAN,
    function* putDanhMucBoPhanSaga({ type, form, resetForm }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        yield call(() =>
          nhanvienService.putBoPhan({
            idbp: form?.idbp,
            mabophan: form?.mabp,
            tenbophan: form?.tenbp,
            sudung: form?.sudung,
            nguoisua: infoUser?.idnv,
            ghichu: form?.ghichu,
          }),
        )
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_SUA_BO_PHAN,
          true,
          MENU_CONSTANTS.BO_PHAN,
          null,
          {
            form: {
              idbp: form?.idbp,
              mabophan: form?.mabp,
              tenbophan: form?.tenbp,
              sudung: form?.sudung,
              nguoisua: infoUser?.idnv,
              ghichu: form?.ghichu,
            },
          },
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })

        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails({
          idbp: form?.idbp,
          mabophan: form?.mabp,
          tenbophan: form?.tenbp,
          sudung: form?.sudung,
          nguoisua: infoUser?.idnv,
          ghichu: form?.ghichu,
        })} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.BO_PHAN,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // PUT BỘ PHẬN SỬ DỤNG
  yield takeLatest(
    typeAction.PUT_DANHMUC_BOPHAN_SUDUNG,
    function* putDanhMucBoPhanSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        yield call(() =>
          nhanvienService.putSuDungBoPhan({
            idbp: form?.idbp,
            // mabophan: form?.mabp,
            // tenbophan: form?.tenbp,
            sudung: form?.sudung ? 0 : 1, //cập nhật ngược lại sử dụng hiện tại
            nguoisua: infoUser?.idnv,
            // ghichu: form?.ghichu,
          }),
        )
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
