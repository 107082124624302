import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Button, ConfigProvider, Table, Checkbox, Popconfirm, Space, Select } from 'antd'
import { SyncOutlined, RollbackOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons'
import {
  putHieuLucNguoiDungAction,
  putResetMkNguoiDungAction,
  putCapQuyenAction,
} from '../../../../store/actions/phanQuyenAction'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import ToastCus from '../../../../utils/Toast'
import { debounce } from 'lodash'
import moment from 'moment'
import 'moment/locale/vi'
import { addLog } from '../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../utils/log/logConstant'
moment.locale('vi')
const CapQuyen = ({ tabQuyen }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const dispatch = useDispatch()
  const [infoEditNguoiDung, setinfoEditNguoiDung] = useState(null)
  const [listNguoiDung, setlistNguoiDung] = useState([])
  const [isLoadingNguoiDung, setisLoadingNguoiDung] = useState(false)
  const [keywordNguoiDung, setkeywordNguoiDung] = useState(null)
  const [filterNhom, setfilterNhom] = useState('all')
  const [totalPages, settotalPages] = useState(0)
  const [pageIndex, setpageIndex] = useState(1)
  const pageSize = 20 //cố định
  const [listQuyen, setlistQuyen] = useState([])
  const [isLoadingQuyen, setisLoadingQuyen] = useState(false)
  const [idNhom, setidNhom] = useState(0)
  const [listNhom, setlistNhom] = useState([])
  const [isLoadingNhom, setisLoadingNhom] = useState(false)
  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => text,
  })
  const reLoad = () => {
    setkeywordNguoiDung((prev) => (prev?.length ? prev?.trim() : prev))
    getListNguoiDung(
      keywordNguoiDung?.length ? keywordNguoiDung?.trim() : keywordNguoiDung,
      1, // pageIndex
      true, //reset setlistNguoiDung([])
    )
  }
  const resetForm = () => {
    setinfoEditNguoiDung(null)
    setidNhom(0)
    getListQuyen()
    getListNhom()
    reLoad()
  }
  const handleSubmit = () => {
    if (!infoEditNguoiDung?.idnd) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn tài khoản!',
      })
      return
    }
    dispatch(
      putCapQuyenAction(
        {
          idnd: infoEditNguoiDung?.idnd,
          idnqht: idNhom,
          listQuyen: listQuyen,
          taikhoan: infoEditNguoiDung?.taikhoan,
          tennhanvien: infoEditNguoiDung?.tennhanvien,
          manhanvien: infoEditNguoiDung?.manhanvien,
        },
        resetForm,
      ),
    )
  }
  const handleCheckCapQuyen = (idqht) => (e) => {
    if (!infoEditNguoiDung?.idnd) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn tài khoản!',
      })
      return
    }
    setlistQuyen((prev) =>
      prev?.map((item) => (item?.idqht === idqht ? { ...item, check: e.target.checked } : item)),
    )
  }
  const getListCapQuyen = async (record) => {
    try {
      setisLoadingQuyen(true)
      const { data } = await phanQuyenService.getListCapQuyen(record.idnd)
      setlistQuyen((prev) =>
        prev?.map((item) => ({
          ...item,
          check: data?.find((itemCq) => itemCq?.idqht === item?.idqht)?.sudung ?? 0,
        })),
      )
      setisLoadingQuyen(false)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_QUYEN_BY_ID_NGUOI_DUNG,
        true,
        MENU_CONSTANTS.PHAN_QUYEN,
        null,
        {
          taikhoan: record.taikhoan,
          manhanvien: record.manhanvien,
          tennhanvien: record.tennhanvien,
        },
      )
    } catch (error) {
      console.log('getListCapQuyen : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.PHAN_QUYEN,
        detailErrorPayload,
      )
    } finally {
    }
  }
  const getListNguoiDung = async (keyword, pageIndex, reset) => {
    if (reset) {
      setlistNguoiDung([])
    }
    try {
      setisLoadingNguoiDung(true)
      const { data } = await phanQuyenService.getListNguoiDung(
        keyword?.length ? keyword?.trim() : null,
        `${filterNhom ?? ''}`?.includes('all') ? null : filterNhom,
        pageIndex,
      )
      setlistNguoiDung((prev) => [...prev, ...data?.data])
      settotalPages(data?.totalPages)
      const nameNhomQuyen =
        filterNhom === 'all'
          ? 'Tất cả'
          : filterNhom === 0
            ? 'Tuỳ chỉnh'
            : listNhom.find((item) => item?.idnqht === filterNhom)?.tennhomquyen
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_ALL_NHOM_QUYEN,
        true,
        MENU_CONSTANTS.PHAN_QUYEN,
        null,
        {
          tenNhom: nameNhomQuyen,
          keyword: keyword ?? '',
          page: pageIndex,
        },
      )
    } catch (error) {
      console.log('getListNguoiDung : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_ALL_NHOM_QUYEN,
        false,
        MENU_CONSTANTS.PHAN_QUYEN,
        detailErrorPayload,
      )
    } finally {
      setisLoadingNguoiDung(false)
    }
  }
  const getListChiTietNhomQuyen = async (idnqht) => {
    if (!idnqht) {
      // tùy chỉnh thì set bỏ check hết các quyền
      // setlistQuyen((prev) => prev?.map((item) => ({ ...item, check: 0 })))
      return
    }
    try {
      setisLoadingQuyen(true)
      const { data } = await phanQuyenService.getListChiTietNhomQuyen(idnqht)
      setlistQuyen((prev) =>
        prev?.map((item) => ({
          ...item,
          check: data?.find((itemCtnq) => itemCtnq?.idqht === item?.idqht)?.sudung ?? 0,
        })),
      )
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_QUYEN_HE_THONG_BY_ID,
        true,
        MENU_CONSTANTS.PHAN_QUYEN,
        null,
        {
          tenNhom: listNhom.find((item) => item?.idnqht === idnqht)?.tennhomquyen,
        },
      )
    } catch (error) {
      console.log('getListChiTietNhomQuyen : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_QUYEN_HE_THONG_BY_ID,
        false,
        MENU_CONSTANTS.PHAN_QUYEN,
        detailErrorPayload,
      )
    } finally {
      setisLoadingQuyen(false)
    }
  }
  const getListNhom = async () => {
    try {
      setisLoadingNhom(true)
      const { data } = await phanQuyenService.getListNhomQuyen(null) //keyword
      setlistNhom(data?.filter((item) => item?.sudung))
      setisLoadingNhom(false)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XEM_NHOM_QUYEN_HE_THONG,
        true,
        MENU_CONSTANTS.PHAN_QUYEN,
        null,
        {
          keyword: '',
        },
      )
    } catch (error) {
      console.log('getListNhom : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XEM_NHOM_QUYEN_HE_THONG,
        false,
        MENU_CONSTANTS.PHAN_QUYEN,
        detailErrorPayload,
      )
    }
  }
  const getListQuyen = async () => {
    try {
      setisLoadingQuyen(true)
      const { data } = await phanQuyenService.getListQuyenHeThong(null) //keyword
      setlistQuyen(
        data
          ?.filter((item) => item?.sudung)
          ?.map((item) => ({
            ...item,
            tenMenuSort: `${item?.tenmenucha ? `${item?.tenmenucha} / ` : ''}${item?.tenmenu}`,
            check: 0,
          }))
          ?.sort((a, b) => (a?.tenMenuSort ?? '')?.localeCompare(b?.tenMenuSort ?? '')),
      )
      setisLoadingQuyen(false)
    } catch (error) {
      console.log('getListQuyen : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    }
  }
  const debounceKeyword = useCallback(debounce((e) => setkeywordNguoiDung(e.target.value), 300))
  const handleScroll = useCallback(
    debounce(() => {
      const tableBody = document.querySelector('.table-nguoi-dung .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 100 &&
        !isLoadingNguoiDung
      ) {
        if (pageIndex + 1 > totalPages) {
          return
        }
        const nextPage = pageIndex + 1
        setpageIndex(nextPage)
        getListNguoiDung(keywordNguoiDung, nextPage, false)
      }
    }, 300),
    [isLoadingNguoiDung],
  )
  useEffect(() => {
    const tableBody = document.querySelector('.table-nguoi-dung .ant-table-body')
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])
  useEffect(() => {
    if (tabQuyen === 1 && infoUser) {
      resetForm()
    }
  }, [tabQuyen, infoUser])
  return (
    <>
      <div className="grid grid-cols-5 h-[49.4rem]">
        <div className="col-span-3 border-e pr-2">
          <div className="flex justify-start items-center gap-2 mb-2">
            <Select
              placeholder="Tìm nhóm quyền..."
              className="w-60"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes((input ?? '').toLowerCase())
              }
              variant={isLoadingNhom ? 'filled' : 'outlined'}
              readOnly={isLoadingNhom}
              value={isLoadingNhom ? 'Đang lấy dữ liệu...' : filterNhom}
              onChange={setfilterNhom}
              options={
                isLoadingNhom
                  ? []
                  : [
                      {
                        key: 'all',
                        value: 'all',
                        label: 'Tất cả nhóm quyền',
                      },
                      {
                        key: 0,
                        value: 0,
                        label: 'Tùy chỉnh',
                      },
                      ...listNhom?.map((item) => ({
                        key: item?.idnqht,
                        value: item?.idnqht,
                        label: item?.tennhomquyen,
                      })),
                    ]
              }
            />
            <Input
              allowClear
              placeholder="Tìm kiếm tài khoản..."
              className="w-60"
              // value={keywordNguoiDung}
              onChange={debounceKeyword}
            />
            <Button
              loading={isLoadingNguoiDung}
              type="primary"
              shape="circle"
              icon={<SyncOutlined />}
              onClick={reLoad}
            />
            <div className="mx-auto font-semibold">
              {infoEditNguoiDung?.idnd ? (
                `Đang chọn: ${infoEditNguoiDung?.taikhoan} / ${infoEditNguoiDung?.tennhanvien} / ${infoEditNguoiDung?.idnqht && infoEditNguoiDung?.tennhomquyen ? infoEditNguoiDung?.tennhomquyen : 'Tùy chỉnh'}`
              ) : (
                <span className="text-red-500">Chưa chọn tài khoản</span>
              )}
            </div>
          </div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              onRow={(record) => {
                return {
                  onClick: () => {
                    setinfoEditNguoiDung(record)
                    setidNhom(record?.idnqht ? record?.idnqht : 0)
                    getListCapQuyen(record)
                  },
                }
              }}
              rowClassName={
                (record) =>
                  infoEditNguoiDung && record?.idnd === infoEditNguoiDung?.idnd ? 'bg-sky-100' : '' //bg-[#ecf0f1]
              }
              loading={isLoadingNguoiDung}
              scroll={{
                y: 715, //677
              }}
              className="table-nguoi-dung"
              pagination={false}
              bordered
              dataSource={listNguoiDung}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'stt',
                  key: 'stt',
                  width: 50,
                  align: 'center',
                  render: (text, record, index) => ++index,
                },
                {
                  title: 'Tài khoản',
                  dataIndex: 'taikhoan',
                  key: 'taikhoan',
                  width: 175,
                },
                {
                  title: 'Mã nhân viên',
                  dataIndex: 'manhanvien',
                  key: 'manhanvien',
                  width: 100,
                },
                {
                  title: 'Tên nhân viên',
                  dataIndex: 'tennhanvien',
                  key: 'tennhanvien',
                },
                {
                  title: 'Nhóm quyền',
                  dataIndex: 'tennhomquyen',
                  key: 'tennhomquyen',
                  render: (text, record, index) => (record?.idnqht && text ? text : 'Tùy chỉnh'),
                },
                {
                  title: 'Hiệu lực',
                  dataIndex: 'hieuluc',
                  key: 'hieuluc',
                  width: 70,
                  align: 'center',
                  render: (text, record, index) => (
                    <Popconfirm
                      placement="topRight"
                      okText="Xác nhận"
                      cancelText="Hủy bỏ"
                      title={'Thay đổi hiệu lực?'}
                      onConfirm={() => dispatch(putHieuLucNguoiDungAction(record, reLoad))}
                      description={
                        <>
                          <p>
                            Bạn xác nhận muốn&nbsp;
                            <span className="font-semibold">
                              {text ? 'VÔ HIỆU HÓA' : 'KÍCH HOẠT'}
                            </span>
                            &nbsp;tài khoản:
                          </p>
                          <p className="font-semibold">{record?.taikhoan}</p>
                        </>
                      }
                    >
                      <Checkbox checked={text} />
                    </Popconfirm>
                  ),
                },
                {
                  title: 'Reset MK',
                  dataIndex: 'reset',
                  key: 'reset',
                  width: 80,
                  align: 'center',
                  render: (text, record, index) => (
                    <>
                      <Popconfirm
                        placement="topRight"
                        okText="Xác nhận"
                        cancelText="Hủy bỏ"
                        title={'Đặt lại mật khẩu?'}
                        onConfirm={() => dispatch(putResetMkNguoiDungAction(record, reLoad))}
                        description={
                          <>
                            <p>
                              Bạn xác nhận muốn&nbsp;
                              <span className="font-semibold">ĐẶT LẠI MẬT KHẨU MẶC ĐỊNH</span>
                              &nbsp;của tài khoản:
                            </p>
                            <p className="font-semibold">{record?.taikhoan}</p>
                          </>
                        }
                      >
                        <style>{`
                        .btnReset {
                          width: 22.7px !important;
                          padding: 3px !important;
                          font-size: 14px !important;
                          line-height: 14px !important;
                          height: fit-content !important;
                        }
                      `}</style>
                        <Button
                          icon={<RollbackOutlined style={{ fontSize: '14.5px' }} />}
                          className="btnReset"
                        />
                      </Popconfirm>
                    </>
                  ),
                },
              ]}
            />
          </ConfigProvider>
        </div>
        <div className="col-span-2 pl-2">
          <div className="flex justify-start items-center gap-2 mb-2">
            <Select
              placeholder="Tìm nhóm quyền..."
              className="w-60"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes((input ?? '').toLowerCase())
              }
              variant={isLoadingNhom ? 'filled' : 'outlined'}
              readOnly={isLoadingNhom}
              value={isLoadingNhom ? 'Đang lấy dữ liệu...' : idNhom}
              onChange={(value) => {
                setidNhom(value)
                getListChiTietNhomQuyen(value)
              }}
              options={
                isLoadingNhom
                  ? []
                  : [
                      {
                        key: 0,
                        value: 0,
                        label: 'Tùy chỉnh',
                      },
                      ...listNhom?.map((item) => ({
                        key: item?.idnqht,
                        value: item?.idnqht,
                        label: item?.tennhomquyen,
                      })),
                    ]
              }
            />
            <Button
              disabled={isLoadingNhom || isLoadingQuyen}
              className="ml-auto"
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleSubmit}
            >
              Lưu
            </Button>
          </div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <style>
              {`
              .custom-scroll-bar .ant-table-tbody-virtual-scrollbar {                
                background: #f0f0f0 !important;
              }
              .custom-scroll-bar .ant-table-tbody-virtual-scrollbar-vertical {
                width: 0.85rem !important;
                height: 100% !important;
                z-index: 1;
              }           
              .custom-scroll-bar .ant-table-tbody-virtual-scrollbar-horizontal {
                width: 100% !important;
                height: 0.85rem !important;
              }                  
              .custom-scroll-bar .ant-table-tbody-virtual-scrollbar .ant-table-tbody-virtual-scrollbar-thumb{
                background: #b4b4b4 !important;
                border-radius: 0px !important;
              }
            `}
            </style>
            <Table
              virtual
              loading={isLoadingQuyen}
              className="custom-scroll-bar"
              scroll={{ y: 715 }}
              pagination={false}
              bordered
              // dataSource={listQuyen.concat(listQuyen, listQuyen, listQuyen)}
              dataSource={useMemo(() => listQuyen, [listQuyen])}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'stt',
                  key: 'stt',
                  width: 35,
                  align: 'center',
                  render: (text, record, index) => ++index,
                },
                {
                  title: 'Tên quyền',
                  dataIndex: 'tenquyen',
                  key: 'tenquyen',
                  ...getColumnSearchProps('tenquyen'),
                },
                {
                  title: 'Menu',
                  dataIndex: 'tenmenu',
                  key: 'tenmenu',
                  width: 150,
                  filters: [...new Set(listQuyen?.map((item) => item?.tenmenu))]?.map(
                    (item, index) => ({
                      key: index,
                      value: item,
                      text: item,
                    }),
                  ),
                  onFilter: (value, record) => {
                    return record?.tenmenu.indexOf(value) === 0
                  },
                },
                {
                  title: () => {
                    let isIndeterminate =
                      listQuyen?.some((itemQ) => itemQ?.check) &&
                      listQuyen?.some((itemQ) => !itemQ?.check)
                    let isChecked = listQuyen?.every((itemQ) => itemQ?.check)
                    return (
                      <Checkbox
                        indeterminate={isIndeterminate}
                        checked={isChecked}
                        onChange={(e) => {
                          if (!infoEditNguoiDung?.idnd) {
                            ToastCus.fire({
                              icon: 'error',
                              title: 'Vui lòng chọn tài khoản!',
                            })
                            return
                          }
                          setlistQuyen((prev) =>
                            prev?.map((item) => ({ ...item, check: e.target.checked })),
                          )
                        }}
                      />
                    )
                  },
                  dataIndex: 'check',
                  key: 'check',
                  width: 50,
                  align: 'center',
                  render: (text, record, index) => (
                    <Checkbox checked={text} onChange={handleCheckCapQuyen(record?.idqht)} />
                  ),
                  filters: [
                    {
                      key: 1,
                      value: 1,
                      text: 'Được chọn',
                    },
                    {
                      key: 0,
                      value: 0,
                      text: 'Không được chọn',
                    },
                  ],
                  onFilter: (value, record) => {
                    return (value === 1 && record?.check) || (value === 0 && !record?.check)
                  },
                },
              ]}
            />
          </ConfigProvider>
        </div>
      </div>
    </>
  )
}

export default CapQuyen
