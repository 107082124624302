import { Input } from 'antd'
import React, { useState, useRef } from 'react'
import { Avatar, List, Image, notification } from 'antd'
import { CloseOutlined, DeleteOutlined, CloudUploadOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import style from './style.module.css'
import * as pdfjsLib from 'pdfjs-dist/build/pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker
const Attach = ({ formik, selectedFile, setSelectedFile }) => {
  const fileInputRef = useRef(null)
  const dropzoneRef = useRef(null)
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (description) => {
    api.error({
      message: 'Chọn file đính kèm',
      description,
    })
  }
  const handleFileChange = async (file) => {
  //  let file = event.target.files[0]
    // List of allowed file types
    const allowedFileTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'application/pdf',
    ]
    if (!allowedFileTypes.includes(file.type)) {
      openNotificationWithIcon('Chỉ chấp nhân tệp Hình ảnh, PDF')
      fileInputRef.current.value = '' // clear the input
      return
    }
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024) // Chuyển đổi kích thước tệp sang MB
      if (fileSizeInMB > 10) {
        // file không được vược quá 10mb
        openNotificationWithIcon('File đính kèm không được vược quá 10MB')
        return
      }
    }
    if (file.type === 'application/pdf') {
      const pdfImgSrc = await renderPdfAsImage(file)
      fileInputRef.current.value = '' // clear the input
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const localUrl = URL.createObjectURL(file)
        setSelectedFile((preState) => [
          ...preState,
          { base64: pdfImgSrc, URL: localUrl, name: file.name, id: uuidv4(), file },
        ])
      }
    } else {
      // formik.setFieldValue('HoaDonFile', file)
      // console.log(formData);
      fileInputRef.current.value = ''
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const localUrl = URL.createObjectURL(file)
        setSelectedFile((preState) => [
          ...preState,
          { base64: e.target.result, URL: localUrl, name: file.name, id: uuidv4(), file },
        ])
      }
    }
  }

  const renderPdfAsImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = async (e) => {
        const pdf = await pdfjsLib.getDocument({ data: e.target.result }).promise
        const page = await pdf.getPage(1)
        const viewport = page.getViewport({ scale: 1.5 })
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        canvas.height = viewport.height
        canvas.width = viewport.width

        await page.render({ canvasContext: context, viewport: viewport }).promise
        resolve(canvas.toDataURL())
      }
    })
  }
  const deleteFileById = (id) => {
    const updatedData = selectedFile.filter((item) => item.id !== id)
    setSelectedFile(updatedData)
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px solid #000'
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #cccccc'
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #cccccc'
   handleFileChange(e.dataTransfer.files[0])
   // const files = Array.from(e.dataTransfer.files)
    //  setFileList(files);
  }

  const handleFileSelect = (e) => {
   // let file = e.target.files[0]
    handleFileChange(e.target.files[0])
  }
  const dropzoneStyle = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '30px',
    textAlign: 'center',
    cursor: 'pointer',
  }
  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div>
      {contextHolder}
      <div>
        <label className="w-24 font-semibold">Link hóa đơn</label>
        <Input
          className="mt-2"
          name="LinkHoaDon"
          value={formik.values.LinkHoaDon}
          onChange={formik.handleChange}
        />
      </div>
      <div>
        <ul className="flex flex-wrap gap-2 mt-2">
          {selectedFile.map((items) => (
            <li className="w-1/12 2xl:h-36 xl:h-28 h-24  overflow-hidden border rounded-md relative">
              <DeleteOutlined
                onClick={() => deleteFileById(items.id)}
                className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                style={{ fontSize: 18 }}
              />
              <a href={items.URL} target="_blank">
                <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                  <div className="relative "></div>
                  <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                    {items.name}
                  </p>
                </div>
                <img src={items.base64} className="w-full h-full" alt={items.name} />
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div
        style={dropzoneStyle}
        className=" mt-2 border"
        ref={dropzoneRef}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleDivClick}
      >
         <CloudUploadOutlined style={{fontSize:36}} className='text-gray-500' />
         <label  style={{ cursor: 'pointer' }}>
         <p className='font-semibold text-lg text-gray-500'>Thả tập tin vào đây hoặc bấm vào để tải lên.</p>
        </label>
       
        {/* <label for="file-upload" className={style.custom_file_upload}>
          <CloudUploadOutlined />
          Chọn Hình ảnh
        </label> */}
        <input  ref={fileInputRef} id="fileInput" type="file" onChange={handleFileSelect} />
      </div>
      <i className='text-red-500'>(*) Lưu ý: chỉ chấp nhận File PDF và hình ảnh không quá 10MB</i>
    </div>
  )
}

export default Attach
