import * as typeAction from '../constants/constants'
export const phieuThuCashier = (form, to, idChiNhanh, setIsLoading, branch) => ({
  type: typeAction.GET_DANH_SACH_PHIEU_THU,
  form,
  to,
  idChiNhanh,
  setIsLoading,
  branch,
})
export const detailBNCashier = (idbn, idbntt, idbnttc, idChuyenKhoa) => ({
  type: typeAction.GET_INFO_PT_CASHIER,
  idbn,
  idbntt,
  idbnttc,
  idChuyenKhoa,
})
export const getListDiscountCashier = (listDV) => ({
  type: typeAction.GET_LIST_DISCOUNT_CASHIER,
  listDV,
})
export const removeListDiscountCashier = (listDV) => ({
  type: typeAction.REMOVE_LIST_DISCOUNT_CASHIER,
  listDV,
})
export const setChuongTrinhGiamGiaDoiTacCashier = (form, isApply) => ({
  type: typeAction.SET_CHUONG_TRINH_GIAM_GIA_DOI_TAC_CASHIER,
  form,
  isApply
})
export const postApplyMemberCardCashierAction = (form, cd) => ({
  type: typeAction.POST_CASHIER_APPLY_MEMBER_CARD,
  form,
  cd,
})
export const putPhieuThuAction = (form, idcn, formPrint, tenbn, mabn) => ({
  type: typeAction.PUT_PHIEUTHU_CASHIER,
  form,
  idcn,
  formPrint,
  tenbn,
  mabn
})
export const getListPhieuDaThuCashier = (form, to, idChiNhanh, setIsLoadingPhieuDaThu) => ({
  type: typeAction.GET_DANH_SACH_PHIEU_THU_CASHIER,
  form,
  to,
  idChiNhanh,
  setIsLoadingPhieuDaThu,
})
export const updateServiceBN = (
  form,
  idbntt,
  idnv,
  ghichu,
  ngaysua,
  tenNguoiSua,
  resetCheckDelete,
  maphieuthu
) => ({
  type: typeAction.UPDATE_SERVICE_BN,
  form,
  idbntt,
  idnv,
  ghichu,
  ngaysua,
  tenNguoiSua,
  resetCheckDelete,
  maphieuthu
})
export const updatePTBenhNhan = (
  data,
  idbntt,
  resetUpdate,
  tiengiamdoitac,
  idnv,
  idbnttc,
  ghichu,
  ngaysua,
  tenNguoiSua,
  maphieuthu,
) => ({
  type: typeAction.UPDATE_SERVICE_PHIEU_THU,
  data,
  idbntt,
  resetUpdate,
  tiengiamdoitac,
  idnv,
  idbnttc,
  ghichu,
  ngaysua,
  tenNguoiSua,
  maphieuthu,
})
export const updateDetailPayment = (form, setShow, idbntt, benhNhanTTC, tienGiamUpdate) => ({
  type: typeAction.UPDATE_CHI_TIET_THANH_TOAN_PT,
  form,
  setShow,
  idbntt,
  benhNhanTTC,
  tienGiamUpdate,
})
export const updateGhiChuHD = (form) => ({
  type: typeAction.UPDATE_BNTT_GHI_CHU_HD,
  form,
})
export const CalculateServiceCustomer_ByMemberCard = (idthe, idbnttc, mabn, tenbn) => ({
  type: typeAction.CALCULATOR_SERVICE_BY_MEMBERCARD,
  idthe,
  idbnttc,
  mabn,
  tenbn,
})

export const resetInfoBnCuaPt = () => {
  return {
    type: typeAction.RESET_INFO_BN_CUA_PT,
  }
}

export const resetMemberCard = () => {
  return {
    type: typeAction.RESET_MEMBER_CARD,
  }
}
export const resetArrXN = () => ({
  type: typeAction.RESET_ARR_XN,
})

export const resetArrCDHA = () => ({
  type: typeAction.RESET_ARR_CDHA,
})

export const resetArrKB = () => ({
  type: typeAction.RESET_ARR_KB,
})

export const resetTongGiam = () => ({
  type: typeAction.RESET_TONG_GIAM,
})

export const resetTongThanhToan = () => ({
  type: typeAction.RESET_TONG_THANH_TOAN,
})

export const resetBNTTC = () => ({
  type: typeAction.RESET_BNTTC,
})

export const resetInfoBN = () => ({
  type: typeAction.RESET_INFO_BN,
})

export const resetDiscountArr = () => ({
  type: typeAction.RESET_DISCOUNT_ARR,
})

export const resetInfoChuongTrinhGiamGiaDoiTac = () => ({
  type: typeAction.RESET_INFO_CHUONG_TRINH_GIAM_GIA_DOI_TAC,
})

export const resetPhieuDaThu = () => ({
  type: typeAction.RESET_PHIEU_DA_THU,
})

export const resetDetailTT = () => ({
  type: typeAction.RESET_DETAIL_TT,
})

export const postApllyVoucherChoChiDinhDaThuAction = (
  form,
  setisLoading,
  setisApply,
  setinfoListDV,
  setinfoBNTT,
) => ({
  type: typeAction.POST_APPLY_VOUCHER_CHIDINH_DATHU,
  form,
  setisLoading,
  setisApply,
  setinfoListDV,
  setinfoBNTT,
})
export const putPhieuThuAddVoucherAction = (form, resetForm) => ({
  type: typeAction.PUT_PHIEUTHU_ADD_VOUCHER,
  form,
  resetForm,
})
export const putDoiChiDinhCungGiaCungNhomAction = (form, resetDoiCd) => ({
  type: typeAction.PUT_DOICD_CUNGGIA_CUNGNHOM_CASHIER,
  form,
  resetDoiCd,
})
