import * as typeAction from '../constants/constants'

export const postQuyenHeThongAction = (form, resetForm) => ({
  type: typeAction.POST_PHANQUYEN_QUYENHETHONG,
  form,
  resetForm,
})

export const putQuyenHeThongAction = (form, resetForm) => ({
  type: typeAction.PUT_PHANQUYEN_QUYENHETHONG,
  form,
  resetForm,
})

export const putSuDungQuyenHeThongAction = (info, getListQuyen) => ({
  type: typeAction.PUT_PHANQUYEN_QUYENHETHONG_SUDUNG,
  info,
  getListQuyen,
})

export const postNhomQuyenAction = (form, resetForm) => ({
  type: typeAction.POST_PHANQUYEN_NHOMQUYEN,
  form,
  resetForm,
})

export const putNhomQuyenAction = (form, resetForm) => ({
  type: typeAction.PUT_PHANQUYEN_NHOMQUYEN,
  form,
  resetForm,
})

export const putSuDungNhomQuyenAction = (info, getListNhom) => ({
  type: typeAction.PUT_PHANQUYEN_NHOMQUYEN_SUDUNG,
  info,
  getListNhom,
})

export const putSuDungListCTNQAction = (form, getListChiTietNhomQuyen) => ({
  type: typeAction.PUT_PHANQUYEN_CHITIET_NHOM_QUYEN_SUDUNG,
  form,
  getListChiTietNhomQuyen,
})

export const putHieuLucNguoiDungAction = (info, reLoad) => ({
  type: typeAction.PUT_PHANQUYEN_NGUOIDUNG_HIEULUC,
  info,
  reLoad,
})

export const putResetMkNguoiDungAction = (info, reLoad) => ({
  type: typeAction.PUT_PHANQUYEN_NGUOIDUNG_RESETMK,
  info,
  reLoad,
})

export const putCapQuyenAction = (form, resetForm) => ({
  type: typeAction.PUT_PHANQUYEN_NGUOIDUNG_CAPQUYEN,
  form,
  resetForm,
})

export const putCheckQuyenThaoTacAction = (maquyen, info, actionIsTrue, actionIsFalse) => ({
  type: typeAction.PUT_PHANQUYEN_CHECK_QUYEN,
  maquyen,
  info,
  actionIsTrue,
  actionIsFalse,
})

export const logoutTaiKhoanBiKhoaAction = (messageError) => ({
  type: typeAction.PHANQUYEN_LOGOUT_TAIKHOAN_BIKHOA,
  messageError,
})

export const putListPhanQuyenTraCuuAction = (idck, list) => ({
  type: typeAction.PUT_LIST_PHANQUYEN_TRACUU,
  idck,
  list,
})
