import {
  Button as AntButton,
  ConfigProvider,
  DatePicker,
  Select,
  Table,
  Badge,
  Modal,
  Checkbox,
  TreeSelect,
  Input,
  Tooltip,
} from 'antd'
import { FilterOutlined, EyeOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import CachedIcon from '@mui/icons-material/Cached'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import ModalAdd from '../Modal/ModalAdd'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import { listBranchAction } from '../../../../store/actions/BranchAction'
import {
  cardReceiveAction,
  infoCardBN,
  getListTypeCardByCNAction,
} from '../../../../store/actions/cardAction'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { checkHetHanTTV } from '../../../../utils/checkHetHanTTV'
import { buildFilterCondition } from '../../../../utils/buildFilterCondition'
import dayjs from 'dayjs'
import ModalEdit from '../Modal/ModalEdit'
import * as XLSX from 'xlsx'
import 'moment/locale/vi'
import { buildDateFilterCondition } from '../../../../utils/buildDateFilterCondition'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../utils/log/logConstant'
import { getListNameChiNhanh } from '../../../../utils/algorithm'
import { addLog } from '../../../../utils/log/apiLog'
moment.locale('vi')
const now = moment()
const formatMoment = 'YYYY-MM-DD'
const dateFormat = 'DD-MM-YYYY'
const { SHOW_PARENT } = TreeSelect

const TableTTV = () => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const idBranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { cardReceive, lisTypesCard, listGroupCard, detailCard, infoBN, chuyenKhoa } = useSelector(
    (state) => state.cartReducer,
  )
  const { listBranch, PkDangNhap } = useSelector((state) => state.branchReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const ruleUser = ['Lễ tân '].includes(infoUser?.tenNhom)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalEdit, setIsModalEdit] = useState(false)
  const [Branch, setBranch] = useState(idBranchLogin)
  const [company, setCompany] = useState(id_company)
  const [type, setType] = useState(null)
  const [group, setGroup] = useState(null)
  const [filterCK, setFilterCK] = useState(null)
  const [dateFrom, setDateFrom] = useState(now.format(formatMoment))
  const [dateTo, setDateTo] = useState(now.format(formatMoment))
  const [keyword, setKeyword] = useState('')
  const [value, setValue] = useState([idBranchLogin])
  const [searchMaBenhNhan, setSearchMaBenhNhan] = useState('')
  const [searchTenBenhNhan, setSearchTenBenhNhan] = useState('')
  const [searchMaThe, setSearchMaThe] = useState('')
  const [searchTenThe, setSearchTenThe] = useState('')
  const [searchChuyenkhoa, setSearchChuyenkhoa] = useState(null)
  const [filterThanhTien, setFilterThanhTien] = useState('=')
  const [searchThanhTien, setSearchThanhTien] = useState(null)
  const [filterTienGiam, setFilterTienGiam] = useState('=')
  const [searchTienGiam, setSearchTienGiam] = useState(null)
  const [filterThanhToan, setFilterThanhToan] = useState('=')
  const [searchThanhToan, setSearchThanhToan] = useState(null)
  const [searchChiNhanh, setSearchChiNhanh] = useState('')
  const [searchNguoiTao, setSearchNguoiTao] = useState('')
  const [searchNgayTao, setSearchNgayTao] = useState('')
  const [filterTheThanhToan, setFilterTheThanhToan] = useState(true)
  const [searchTheThanhToan, setSearchTheThanhToan] = useState(null)
  const [filterHieuLuc, setFilterHieuLuc] = useState(true)
  const [searchHieuLuc, setSearchHieuLuc] = useState(null)
  const [searchDate, setSearchDate] = useState('')
  const [filterTuNgay, setFilterTuNgay] = useState('=')
  const [filterDenNgay, setFilterDenNgay] = useState('=')
  const [searchDateTuNgay, setSearchDateTuNgay] = useState('')
  const [searchDateDenNgay, setSearchDateDenNgay] = useState('')
  const [keyTabs, setKeyTabs] = useState('1')

  const PhepTinh = [
    { label: '>', value: '>' },
    { label: '<', value: '<' },
    { label: '=', value: '=' },
  ]
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: listBranch
        ?.filter(
          (item) =>
            item?.loaiChiNhanh === 'CHINHANH' &&
            (!company || item?.idct === company) &&
            (!filterCK || item?.idChuyenKhoa === filterCK),
        )
        ?.map((item, index) => ({
          title: item?.tenChiNhanh,
          value: item?.idChiNhanh,
          key: `${item?.idChiNhanh}`,
        })),

      // ?.filter((item) => item?.loaiChiNhanh === 'CHINHANH')
      // ?.filter((item) => (company ? item?.idct === company : item)) // dư
      // ?.filter((item) => (filterCK ? item?.idChuyenKhoa === filterCK : item))
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const handleOnChageCongTy = (value) => {
    setCompany(value)
    setValue(['all'])
    dispatch(listBranchAction(value))
  }
  const showModal = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT046',
      )
      setIsModalOpen(true)
      dispatch(getListTypeCardByCNAction(PkDangNhap?.idChiNhanh))
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const handleEditCard = async (idbn, idbnthu2, idthe) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT121',
      )
      dispatch(infoCardBN(idbn, idbnthu2, idthe, PkDangNhap?.idChiNhanh))
      setIsModalEdit(true)
      setKeyTabs('1')
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const handleReload = () => {
    let arrIdCnChon = value
    // if (!value.length) {
    //   setValue(['all'])
    //   arrIdCnChon = ['all']
    // }
    if (value?.includes('all') || !value.length) {
      setValue(['all'])
      arrIdCnChon = listBranch
        ?.filter(
          (item) =>
            item?.loaiChiNhanh === 'CHINHANH' &&
            (!company || item?.idct === company) &&
            (!filterCK || item?.idChuyenKhoa === filterCK),
        )
        ?.map((item) => item?.idChiNhanh)
      // ?.filter((item) => item?.loaiChiNhanh === 'CHINHANH') // dư
      // ?.filter((item) => (company ? item?.idct === company : item)) // dư
      // ?.filter((item) => (filterCK ? item?.idChuyenKhoa === filterCK : item))
    }
    const idChiNhanh = arrIdCnChon
      ?.map((id) => `idChiNhanh=${id}`)
      ?.join('&')
      ?.toString()
    const nameLoaiThe =
      type === null ? 'Tất cả' : lisTypesCard.find((item) => item.idlt === type)?.loaithe
    const nameNhomThe =
      group === null ? 'Tất cả' : listGroupCard.find((item) => item.idnhom === group)?.tennhom
    const listNameChiNhanh = getListNameChiNhanh(value, listBranch)
    dispatch(
      cardReceiveAction(
        idChiNhanh,
        dateFrom,
        dateTo,
        type,
        group,
        keyword,
        listNameChiNhanh.join('\n'),
        nameLoaiThe,
        nameNhomThe,
      ),
    )
  }
  const handleSelected = (name) => (value) => {
    // kiểm tra name set theo filter nào
    if (name === 'branch') {
      // nếu giá trị chọn tất cả  =>> value = null Types
      value !== 'all' ? setBranch(value) : setBranch(null)
    } else if (name === 'Types') {
      value !== 'all' ? setType(value) : setType(null)
    } else if (name === 'Group') {
      value !== 'all' ? setGroup(value) : setGroup(null)
    } else if (name === 'ChuyenKhoa') {
      value !== 'all' ? setFilterCK(value) : setFilterCK(null)
      setValue(['all'])
      setSearchChuyenkhoa(null)
    }
  }
  const handleDatePicker = (name) => (Date, dateString) => {
    // format date
    const value = moment(dateString, dateFormat).format(formatMoment)
    name === 'from' ? setDateFrom(value) : setDateTo(value)
  }

  const { confirm } = Modal
  const showDisableConfirm = (record, sudung) => {
    //console.log(record)
    // confirm({
    //   title: sudung === 0 ? 'Bạn có muốn ngưng sử dụng thẻ thành viên ?' : 'Bạn có muốn tiếp tục sử dụng thẻ thành viên ?',
    //   icon: <QuestionCircleFilled />,
    //   content: (<p>
    //     Bạn xác nhận {sudung === 0 ? 'ngưng sử dụng' : 'tiếp tục sử dụng'} thẻ thành viên:
    //     <br></br>
    //     {record.maThe} - {record.tenBenhNhan}
    //   </p>),
    //   okText: 'Yes',
    //   okType: sudung === 0 ? 'danger' : 'primary',
    //   cancelText: 'No',
    //   onOk() {
    //     dispatch(putDisableMemberCardAction(record.idThe, sudung))
    //     setIsModalEdit(false)
    //     handleReload()
    //     // resetStore()
    //     handleReload()
    //   },
    //   onCancel() {
    //     // console.log('Cancel')
    //   },
    // })
  }
  const columns = [
    {
      title: 'STT ',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      // render: (text, record, index) => ++index,
      fixed: 'left',
    },
    {
      title: 'Mã bệnh nhân',
      dataIndex: 'maBenhNhan',
      key: 'maBenhNhan',
      width: 90,
      fixed: 'left',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.maBenhNhan ?? '').localeCompare(b?.maBenhNhan ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchMaBenhNhan(e.target.value)}
                  value={searchMaBenhNhan}
                />
              </ConfigProvider>
            </>
          ),
          fixed: 'left',
          dataIndex: 'maBenhNhan',
          key: 'search' + 'maBenhNhan',
          width: 120,
        },
      ],
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'tenBenhNhan',
      key: 'tenBenhNhan',
      width: 250,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenBenhNhan ?? '').localeCompare(b?.tenBenhNhan ?? '')
        },
      },
      fixed: 'left',
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchTenBenhNhan(e.target.value)}
                  value={searchTenBenhNhan}
                />
              </ConfigProvider>
            </>
          ),
          fixed: 'left',
          dataIndex: 'tenBenhNhan',
          key: 'search' + 'tenBenhNhan',
          width: 200,
        },
      ],
    },
    {
      title: 'Mã thẻ ',
      dataIndex: 'maThe',
      key: 'maThe',
      width: 100,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.maThe ?? '').localeCompare(b?.maThe ?? '')
        },
      },
      fixed: 'left',
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchMaThe(e.target.value)}
                  value={searchMaThe}
                />
              </ConfigProvider>
            </>
          ),
          fixed: 'left',
          dataIndex: 'maThe',
          key: 'search' + 'maThe',
          width: 120,
          render: (text, record) => (
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => {
                handleEditCard(record.idBN, record.idBNS, record.idThe)
              }}
            >
              {text}
            </span>
          ),
        },
      ],
    },
    {
      title: 'Loại thẻ',
      dataIndex: 'tenThe',
      key: 'tenThe',
      render: (text, record, index) =>
        record.hieuLuc === -1 && record.suDung === 0
          ? text + ' - Đã hủy'
          : record.hieuLuc === 0 && record.suDung === 0
            ? text + ' - Đã hết hạn'
            : record.hieuLuc === 0 && record.suDung === 1 && record.idNhom !== 5
              ? text + ' - Đang bảo lưu'
              : text,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenThe ?? '').localeCompare(b?.tenThe ?? '')
        },
      },
      fixed: 'left',
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchTenThe(e.target.value)}
                  value={searchTenThe}
                />
              </ConfigProvider>
            </>
          ),
          fixed: 'left',
          dataIndex: 'tenThe',
          key: 'search' + 'tenThe',
          // width: 100,
        },
      ],
    },
    {
      title: 'Chuyên khoa',
      dataIndex: 'tenChuyenKhoa',
      key: 'tenChuyenKhoa',
      align: 'center',
      width: 110,
      showSorterTooltip: false,
      sorter: (a, b) => a.idChuyenKhoa - b.idChuyenKhoa,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.tenChuyenKhoa?.replace('Chuyên khoa', '')?.trim() ?? '')?.localeCompare(
      //       b?.tenChuyenKhoa?.replace('Chuyên khoa', '')?.trim() ?? '',
      //     )
      //   },
      // },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Select
                  size="small"
                  className="w-full"
                  options={chuyenKhoa?.map((item) => ({
                    label: item?.tenChuyenKhoa
                      ?.replace('Chuyên khoa', '')
                      ?.replace('Tim mạch tiểu đường', 'TMTĐ')
                      ?.trim(),
                    value: item?.idChuyenKhoa,
                  }))}
                  onChange={(value) => !filterCK && setSearchChuyenkhoa(value)}
                  value={filterCK ?? searchChuyenkhoa}
                  allowClear
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'tenChuyenKhoa',
          key: 'search' + 'tenChuyenKhoa',
          align: 'center',
          width: 110,
          render: (text, record, index) => text?.replace('Chuyên khoa', '')?.trim(),
        },
      ],
    },
    {
      title: 'Thành tiền',
      dataIndex: 'tienSuDUng',
      key: 'tienSuDUng',
      width: 140,
      align: 'center',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tienSuDUng ?? 0) - (b?.tienSuDUng ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setFilterThanhTien(value)}
                    value={filterThanhTien}
                    options={PhepTinh}
                  />
                  <Input
                    size="small"
                    type="Number"
                    className="text-xs"
                    min={0}
                    step={1}
                    value={searchThanhTien}
                    onChange={(e) => setSearchThanhTien(e.target.value)}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'tienSuDUng',
          key: 'search' + 'tienSuDUng',
          width: 140,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },
    {
      title: 'Tiền giảm',
      dataIndex: 'tienGiamGia',
      key: 'tienGiamGia',
      align: 'center',
      width: 140,
      render: (text, record, index) => formattedNumber(text ?? 0),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tienGiamGia ?? 0) - (b?.tienGiamGia ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setFilterTienGiam(value)}
                    value={filterTienGiam}
                    options={PhepTinh}
                  />
                  <Input
                    size="small"
                    type="Number"
                    className="text-xs"
                    min={0}
                    step={1}
                    value={searchTienGiam}
                    onChange={(e) => setSearchTienGiam(e.target.value)}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'tienGiamGia',
          key: 'search' + 'tienGiamGia',
          width: 140,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },
    {
      title: 'Thanh toán',
      dataIndex: 'tienThanhToan',
      key: 'tienThanhToan',
      align: 'center',
      width: 140,
      render: (text, record, index) => formattedNumber(text ?? 0),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tienThanhToan ?? 0) - (b?.tienThanhToan ?? 0)
        },
      },
      children: [
        {
          title: (
            <>
              <div className="flex gap-1">
                <ConfigProvider
                  theme={{
                    token: {
                      borderRadius: 0,
                    },
                  }}
                >
                  <Select
                    size="small"
                    className="w-[3rem] text-xs"
                    defaultValue={'='}
                    onChange={(value) => setFilterThanhToan(value)}
                    value={filterThanhToan}
                    options={PhepTinh}
                  />
                  <Input
                    size="small"
                    type="Number"
                    className="text-xs"
                    min={0}
                    step={1}
                    value={searchThanhToan}
                    onChange={(e) => setSearchThanhToan(e.target.value)}
                  />
                </ConfigProvider>
              </div>
            </>
          ),
          dataIndex: 'tienThanhToan',
          key: 'search' + 'tienThanhToan',
          width: 140,
          align: 'right',
          render: (text, record, index) => formattedNumber(text ?? 0),
        },
      ],
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenChiNhanh',
      key: 'tenChiNhanh',
      width: 250,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenChiNhanh ?? '').localeCompare(b?.tenChiNhanh ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchChiNhanh(e.target.value)}
                  value={searchChiNhanh}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'tenChiNhanh',
          key: 'search' + 'tenChiNhanh',
          width: 200,
        },
      ],
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoiTao',
      key: 'nguoiTao',
      width: 220,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.nguoiTao ?? '').localeCompare(b?.nguoiTao ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchNguoiTao(e.target.value)}
                  value={searchNguoiTao}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'nguoiTao',
          key: 'search' + 'nguoiTao',
          width: 220,
        },
      ],
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngayDangKy',
      key: 'ngayDangKy',
      align: 'center',
      width: 150,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.ngayDangKy ?? '').localeCompare(b?.ngayDangKy ?? '')
        },
      },
      children: [
        {
          title: (
            <>
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 0,
                  },
                }}
              >
                <Input
                  size="small"
                  onChange={(e) => setSearchNgayTao(e.target.value)}
                  value={searchNgayTao}
                />
              </ConfigProvider>
            </>
          ),
          dataIndex: 'ngayDangKy',
          key: 'search' + 'ngayDangKy',
          width: 150,
          render: (text, record, index) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
        },
      ],
    },

    {
      title: 'Ngày hiệu lực',
      dataIndex: 'tuNgay',
      width: 160,
      align: 'center',
      orter: {
        compare: (a, b) => {
          return (a?.tuNgay ?? 0) - (b?.tuNgay ?? 0)
        },
      },
      showSorterTooltip: false,
      children: [
        {
          title: (
            <div className="flex gap-1">
              <Select
                onChange={(value) => setFilterTuNgay(value)}
                defaultValue={'='}
                value={filterTuNgay}
                size="small"
                className="w-16"
                options={PhepTinh}
              />
              <DatePicker
                value={searchDateTuNgay !== '' ? dayjs(searchDateTuNgay, 'YYYY-MM-DD') : ''}
                size="small"
                format={'DD/MM/YYYY'}
                onChange={(date, DateString) => {
                  setSearchDateTuNgay(
                    DateString ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
                  )
                }}
              />
            </div>
          ),
          dataIndex: 'tuNgay',
          key: 2,
          width: 170,
          render: (text) => moment(text).format('DD/MM/YYYY'),
        },
      ],
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'denNgay',
      width: 160,
      align: 'center',
      orter: {
        compare: (a, b) => {
          return (a?.denNgay ?? 0) - (b?.denNgay ?? 0)
        },
      },
      showSorterTooltip: false,
      children: [
        {
          title: (
            <div className="flex gap-1">
              <Select
                onChange={(value) => setFilterDenNgay(value)}
                defaultValue={'='}
                value={filterDenNgay}
                size="small"
                className="w-16"
                options={PhepTinh}
              />
              <DatePicker
                value={searchDateDenNgay !== '' ? dayjs(searchDateDenNgay, 'YYYY-MM-DD') : ''}
                size="small"
                format={'DD/MM/YYYY'}
                onChange={(date, DateString) => {
                  setSearchDateDenNgay(
                    DateString ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
                  )
                }}
              />
            </div>
          ),
          dataIndex: 'denNgay',
          key: 2,
          width: 170,
          render: (text) => moment(text).format('DD/MM/YYYY'),
        },
      ],
    },
    // {
    //   title: "Sử dụng",
    //   dataIndex: "suDung",
    //   key: "suDung",
    //   width: 70,
    //   align: "center",
    //   render: (text, record, index) =>
    //     text === 1 ? <Checkbox checked /> : <Checkbox disabled />,
    // },
    {
      title: 'Thẻ thanh toán',
      dataIndex: 'theThanhToan',
      key: 'theThanhToan',
      width: 130,
      // fixed: 'right',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.theThanhToan ?? 0) - (b?.theThanhToan ?? 0)
        },
      },
      children: [
        {
          title: (
            <div className="flex items-center justify-center w-full gap-1 p-0">
              <Checkbox
                indeterminate={filterTheThanhToan}
                checked={searchTheThanhToan}
                onChange={(e) => {
                  setSearchTheThanhToan(e.target.checked ? 1 : 0)
                  setFilterTheThanhToan(false)
                }}
              />
              <AntButton
                type="link"
                style={{ padding: 0, margin: 0, lineHeight: 14, fontSize: 14, height: 14 }}
                className="flex items-center"
                onClick={() => {
                  setFilterTheThanhToan(true)
                  setSearchTheThanhToan(null)
                }}
              >
                Tất cả
              </AntButton>
            </div>
          ),
          dataIndex: 'theThanhToan',
          key: 'search' + 'theThanhToan',
          // align: 'center',
          width: 130,
          render: (text, record, index) =>
            text === 0 ? (
              <Badge status="error" text="Chưa kích hoạt" />
            ) : (
              <Badge status="success" text="Đã kích hoạt" />
            ),
        },
      ],
    },
    {
      title: 'Hiệu lực',
      dataIndex: 'hieuLuc',
      key: 'hieuLuc',
      width: 80,
      align: 'center',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.hieuLuc ?? 0) - (b?.hieuLuc ?? 0)
        },
      },
      children: [
        {
          title: (
            <div className="flex items-center justify-center w-full gap-1 p-0">
              <Checkbox
                indeterminate={filterHieuLuc}
                checked={searchHieuLuc}
                onChange={(e) => {
                  setSearchHieuLuc(e.target.checked ? 1 : 0)
                  setFilterHieuLuc(false)
                }}
              />
              <AntButton
                type="link"
                style={{ padding: 0, margin: 0, lineHeight: 14, fontSize: 14, height: 14 }}
                className="flex items-center"
                onClick={() => {
                  setSearchHieuLuc(null)
                  setFilterHieuLuc(true)
                }}
              >
                Tất cả
              </AntButton>
            </div>
          ),
          dataIndex: 'hieuLuc',
          key: 'search' + 'hieuLuc',
          align: 'center',
          fixed: 'right',
          width: 80,
          render: (text, record, index) =>
            text === 1 ? (
              checkHetHanTTV(record?.denNgay) ? (
                <Checkbox disabled />
              ) : (
                <Checkbox checked />
              )
            ) : (
              <Checkbox disabled />
            ),
        },
      ],
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 40,
      align: 'center',
      fixed: 'right',
      showSorterTooltip: false,
      render: (text, record, index) => (
        <>
          <Tooltip title="Xem chi tiết" color="blue" className="cursor-pointer">
            <EyeOutlined
              style={{ fontSize: 20 }}
              className="text-blue-500"
              onClick={() => {
                handleEditCard(record.idBN, record.idBNS, record.idThe)
              }}
            />
          </Tooltip>
        </>
        // <Dropdown
        //   className="cursor-pointer"
        //   arrow
        //   menu={{
        //     items: [
        //       // {
        //       //   label: (record.suDung === 1 ?
        //       //     <p
        //       //       onClick={() => {
        //       //         showDisableConfirm(record, 0)
        //       //       }}
        //       //       className="flex gap-5">
        //       //       <MinusCircleFilled className="text-orange-500" />
        //       //       <span className="font-semibold">Ngừng sử dụng</span>
        //       //     </p>
        //       //     :
        //       //     <p
        //       //       onClick={() => {
        //       //         showDisableConfirm(record, 1)
        //       //       }}
        //       //       className="flex gap-5">
        //       //       <CheckCircleFilled className="text-green-500" />
        //       //       <span className="font-semibold">Tiếp tục sử dụng</span>
        //       //     </p>),
        //       //   key: 2,
        //       // },
        //       {
        //         label: (
        //           <p
        //             className="flex gap-5"
        //             onClick={() => {
        //               handleEditCard(record.idBN, record.idThe)
        //             }}
        //           >
        //             <EyeFilled className="text-blue-500" />{" "}
        //             <span className="font-semibold">Xem chi tiết</span>
        //           </p>
        //         ),
        //         key: 1,
        //       },
        //     ],
        //   }}
        // >
        //   <MoreOutlined />
        // </Dropdown>
      ),
    },
  ]
  useEffect(() => {
    // dispatch(listBranchAction(id_company))
    handleReload()
  }, [])
  // useEffect(() => {
  //   dispatch(cardReceiveAction(Branch, dateFrom, dateTo, type, group, keyword, PkDangNhap?.idCongTy))
  // }, [isModalEdit detailCard, isModalEdit, isModalOpen])

  const exportToExcel = () => {
    try {
      const headers = [
        'Mã bệnh nhân',
        'Tên bệnh nhân',
        'Mã thẻ',
        'Tên thẻ',
        'Chuyên khoa',
        'Thành tiền',
        'Tiền giảm',
        'Thanh toán',
        'Chi nhánh',
        'Người tạo',
        'Ngày tạo',
        'Thẻ thanh toán',
        'Hiệu lực',
      ]
      const formattedData = cardReceive.map((item) => ({
        'Mã bệnh nhân': item.maBenhNhan,
        'Tên bệnh nhân': item.tenBenhNhan,
        'Mã thẻ': item.maThe,
        'Tên thẻ': item.tenThe,
        'Chuyên khoa': item.tenChuyenKhoa,
        'Thành tiền': formattedNumber(item.tienSuDUng),
        'Tiền giảm': formattedNumber(item.tienGiamGia),
        'Thanh toán': formattedNumber(item.tienThanhToan),
        'Chi nhánh': item.tenChiNhanh,
        'Người tạo': item.nguoiTao,
        'Ngày tạo': moment(item.ngayDangKy).format('DD/MM/YYYY'),
        'Thẻ thanh toán': item.theThanhToan === 0 ? 'Chưa kích hoạt' : 'Đã kích hoạt',
        'Hiệu lực': `Từ ${moment(item.tuNgay).format('DD/MM/YYYY')} đến ${moment(
          item.denNgay,
        ).format('DD/MM/YYYY')}`,
      }))
      const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
      XLSX.writeFile(wb, 'Thẻ thành viên.xlsx')
      const nameLoaiThe =
        type === null ? 'Tất cả' : lisTypesCard.find((item) => item.idlt === type)?.loaithe
      const nameNhomThe =
        group === null ? 'Tất cả' : listGroupCard.find((item) => item.idnhom === group)?.tennhom
      const listNameChiNhanh = getListNameChiNhanh(value, listBranch)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_DS_TTV,
        true,
        MENU_CONSTANTS.THE_THANH_VIEN_TIEP_NHAN,
        null,
        {
          dateFrom,
          dateTo,
          listChiNhanh: listNameChiNhanh.join('\n'),
          loaithe: nameLoaiThe,
          nhomthe: nameNhomThe,
        },
      )
    } catch (error) {
      console.log(error)
      const detailErrorPayload = `Có lỗi khi xuất excel \n ${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.THE_THANH_VIEN_TIEP_NHAN,
        detailErrorPayload,
      )
    }
  }

  return (
    <div className="">
      <div className="grid grid-rows-2 items-center w-full">
        <div className="flex justify-between items-center border-b border-dashed pb-4">
          <div className="flex gap-2 ">
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              suffixIcon={<FilterOutlined />}
              defaultValue={id_company}
              options={listCompany?.map((item) => ({
                label: item.tenct,
                value: item.idct,
              }))}
              // options={[
              //   { label: 'Tất cả', value: 'all' },
              //   ...(listBranch
              //     ? listBranch.map((items) => ({
              //         label: items.tenChiNhanh,
              //         value: items.idChiNhanh,
              //       }))
              //     : []),
              // ]}
              size="medium"
              className="xl:w-64 w-52"
              onChange={handleOnChageCongTy}
            />
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-52 xl:w-64 h-8 overflow-clip"
              size="medium"
            />

            <DatePicker
              format={dateFormat}
              allowClear={false}
              // maxDate={dayjs(now)}
              defaultValue={dayjs(dateFrom)}
              onChange={handleDatePicker('from')}
              size="medium"
              className="max-w-32"
            />
            <DatePicker
              onChange={handleDatePicker('to')}
              // maxDate={dayjs(now)}
              format={dateFormat}
              allowClear={false}
              defaultValue={dayjs(dateTo)}
              size="medium"
              className="max-w-32"
            />
          </div>

          <Button onClick={showModal} size="small" variant="contained" startIcon={<AddIcon />}>
            Tạo thẻ
          </Button>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <Select
              onChange={handleSelected('ChuyenKhoa')}
              suffixIcon={<FilterOutlined />}
              defaultValue="all"
              options={[
                { label: 'Tất cả chuyên khoa', value: 'all' },
                ...(chuyenKhoa
                  ? chuyenKhoa?.map((items) => ({
                      label: items?.tenChuyenKhoa,
                      value: items?.idChuyenKhoa,
                    }))
                  : []),
              ]}
              size="medium"
              className="xl:w-64 w-52"
            />
            <Select
              onChange={handleSelected('Types')}
              suffixIcon={<FilterOutlined />}
              defaultValue="all"
              options={[
                { label: 'Tất cả loại thẻ', value: 'all' },
                ...(lisTypesCard
                  ? lisTypesCard.map((items) => ({
                      label: items.loaithe,
                      value: items.idlt,
                    }))
                  : []),
              ]}
              size="medium"
              className="w-52"
            />
            <Select
              onChange={handleSelected('Group')}
              suffixIcon={<FilterOutlined />}
              defaultValue="all"
              options={[
                { label: 'Tất cả nhóm thẻ', value: 'all' },
                ...(listGroupCard
                  ? listGroupCard.map((items) => ({
                      label: items.tennhom,
                      value: items.idnhom,
                    }))
                  : []),
              ]}
              size="medium"
              className="w-48"
            />
            <IconButton onClick={handleReload} color="primary">
              <CachedIcon />
            </IconButton>
            {/* <Input
                placeholder="Nhập từ khóa..."
                prefix={<SearchOutlined className="pr-1" />}
                onChange={(e) => {
                  setKeyword(e.target.value)
                }}
                value={keyword}
                // onChange={handleSelected("Types")}
                // suffixIcon={<FilterOutlined />}
                // defaultValue="all"
                // options={[
                //   { label: "Tất cả", value: "all" },
                //   ...(lisTypesCard
                //     ? lisTypesCard.map((items) => ({
                //       label: items.loaithe,
                //       value: items.idlt,
                //     }))
                //     : []),
                // ]}
                size="medium"
                className="w-52"
              />
       */}
          </div>

          <div>
            <AntButton
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1 mr-4"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </AntButton>
          </div>
        </div>
      </div>
      <div className="min-h-[70vh] mt-1">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{ x: 2600 }}
            locale={{
              triggerDesc: 'Sắp xếp giảm dần',
              triggerAsc: 'Sắp xếp tăng dần',
              cancelSort: 'Hủy sắp xếp',
            }}
            bordered
            dataSource={cardReceive?.filter(
              (item) =>
                (!searchMaBenhNhan ||
                  (item?.maBenhNhan ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchMaBenhNhan ?? '')?.toLocaleUpperCase())) &&
                (!searchTenBenhNhan ||
                  (item?.tenBenhNhan ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchTenBenhNhan ?? '')?.toLocaleUpperCase())) &&
                (!searchMaThe ||
                  (item?.maThe ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchMaThe ?? '')?.toLocaleUpperCase())) &&
                (!searchTenThe ||
                  (item?.tenThe ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchTenThe ?? '')?.toLocaleUpperCase())) &&
                (!filterCK || item?.idChuyenKhoa === filterCK) &&
                (!searchChuyenkhoa || item?.idChuyenKhoa === searchChuyenkhoa) &&
                (!searchThanhTien ||
                  buildFilterCondition(filterThanhTien, searchThanhTien, item?.tienSuDUng)) &&
                (!searchTienGiam ||
                  buildFilterCondition(filterTienGiam, searchTienGiam, item?.tienGiamGia)) &&
                (!searchThanhToan ||
                  buildFilterCondition(filterThanhToan, searchThanhToan, item?.tienThanhToan)) &&
                (!searchChiNhanh ||
                  (item?.tenChiNhanh ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchChiNhanh ?? '')?.toLocaleUpperCase())) &&
                (!searchNguoiTao ||
                  (item?.nguoiTao ?? '')
                    ?.toLocaleUpperCase()
                    ?.includes((searchNguoiTao ?? '')?.toLocaleUpperCase())) &&
                (!searchNgayTao ||
                  moment(item?.ngayDangKy)
                    ?.format('DD/MM/YYYY HH:mm:ss')
                    ?.includes((searchNgayTao ?? '')?.toLocaleUpperCase())) &&
                (searchTheThanhToan === null || item?.theThanhToan === searchTheThanhToan) &&
                (searchHieuLuc === null || item?.hieuLuc === searchHieuLuc) &&
                buildDateFilterCondition(filterTuNgay, searchDateTuNgay, item.tuNgay) &&
                buildDateFilterCondition(filterDenNgay, searchDateDenNgay, item.denNgay),
              // && (searchHieuLuc === null ||
              //   (searchHieuLuc === 0 &&
              //     (item?.hieuLuc === 0 ||
              //       (item?.hieuLuc === 1 && checkHetHanTTV(item?.denNgay))))),
            )}
            pagination={{
              pageSize: 20,
              showSizeChanger: false,
            }}
            columns={columns}
          />
        </ConfigProvider>
      </div>
      <ModalAdd
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        formLoad={{
          idcn: Branch,
          tungay: dateFrom,
          denngay: dateTo,
          idlt: type,
          idnt: group,
          keyword: keyword,
          idct: PkDangNhap?.idCongTy,
        }}
        menu={MENU_CONSTANTS.THE_THANH_VIEN_TIEP_NHAN}
      />
      <ModalEdit
        isModalEdit={isModalEdit}
        setIsModalEdit={setIsModalEdit}
        formLoad={{
          idcn: Branch,
          tungay: dateFrom,
          denngay: dateTo,
          idlt: type,
          idnt: group,
          keyword: keyword,
          idct: PkDangNhap?.idCongTy,
        }}
        keyTabs={keyTabs}
        setKeyTabs={setKeyTabs}
      />
    </div>
  )
}

export default TableTTV
