import moment from 'moment'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import { getAllBNCRM } from '../actions/benhnhanCRMAction'
import * as typeAction from '../constants/constants'
import { benhnhanCRMService } from '../../services/benhnhanCRM/benhnhanCRMService'
import 'moment/locale/vi'
import { notification } from 'antd'
import { formatPayloadDetails, getNumbers } from '../../utils/algorithm'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* benhnhanCRMSaga() {
  yield takeLatest(typeAction.GET_ALL_BN_CRM, function* getAllBNCRM({ type, payload }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => benhnhanCRMService.getAllBNCRM())
      yield put({
        type: typeAction.DISPATCH_LIST_ALL_BN_CRM,
        payload: result.data,
      })
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    } catch (err) {
      console.log(err)
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  // XOÁ BỆNH NHÂN KHÁM ĐOÀN
  yield takeLatest(
    typeAction.DELETE_BN_CRM_BY_ID,
    function* deleteBNKhamDoan({ type, id, setReload }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => benhnhanCRMService.deleteBNCRMById(id))
        Toast.fire({
          icon: 'success',
          title: 'Xoá bệnh nhân thành công',
        })
        setReload(true)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          logAction.DELETE_BENH_NHAN_CRM,
          true,
          MENU_CONSTANTS.BENH_NHAN,
          null,
          {
            id,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Xoá bệnh nhân thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.BENH_NHAN,
          detailErrorPayload,
        )
      }
    },
  )

  yield takeLatest(typeAction.POST_BN_CRM, function* postBn({ type, payload, setReload }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)

    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const arr = []
      yield arr.push(payload)
      // console.log(arr);
      yield call(() => benhnhanCRMService.postBNCRM(arr))

      setReload(true)
      Toast.fire({
        icon: 'success',
        title: 'Thêm bệnh nhân thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.ADD_BENH_NHAN_CRM,
        true,
        MENU_CONSTANTS.BENH_NHAN,
        null,
        {
          form: payload,
        },
      )
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Thêm bệnh nhân thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(payload)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.BENH_NHAN,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  // LẤY DANH SÁCH CÔNG TY CRM
  yield takeLatest(typeAction.GET_ALL_KH_CRM, function* getAllKHCRM({ type, payload }) {
    try {
      const result = yield call(() => benhnhanCRMService.getAllKHCRM())
      yield put({
        type: typeAction.DISPATCH_ALL_KH_BN_CRM,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })

  // INFO BỆNH NHÂN CRM
  yield takeLatest(typeAction.GET_INFO_BN_CRM, function* infoBNKhamDoan({ type, id }) {
    try {
      const result = yield call(() => benhnhanCRMService.getInfoBNCRMById(id))
      yield put({
        type: typeAction.DISPATCH_INFO_BN_CRM,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })

  // SEND LIST SMS
  yield takeLatest(
    typeAction.SEND_SMS_BN_CRM,
    function* sendSMSKhamDoan({ type, payload, setLoading, setSelectedRowsPerPage, setReload }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      setLoading(true)
      // console.log(payload);
      try {
        const { data } = yield call(() => benhnhanCRMService.sendSMS(infoUser.tenNV, payload))
        const { success, failed } = getNumbers(data)
        if (success) {
          notification.success({
            message: 'Gửi tin nhắn thành công',
            description: data[0],
            duration: 5,
          })
        }
        if (failed) {
          notification.error({
            message: 'Gửi tin nhắn thất bại',
            description: data[1],
            duration: 6,
          })
        }

        setReload(true)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.GUI_SMS_DS_BENH_NHAN,
          true,
          MENU_CONSTANTS.BENH_NHAN,
          null,
          {
            name: infoUser.tenNV,
            listID: payload.join('\n'),
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: error?.response?.data?.message || 'Có lỗi xảy ra khi gửi tin nhắn',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails({
          name: infoUser.tenNV,
          listID: payload,
        })} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.BENH_NHAN,
          detailErrorPayload,
        )
      } finally {
        setLoading(false)
        setSelectedRowsPerPage({})
      }
    },
  )

  // SEND  ALL BN SMS BY CONDITION
  yield takeLatest(
    typeAction.SEND_SMS_ALL_BN_CRM,
    function* sendSMSAllBN({ type, params, setReload, setSendAll }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })

      // console.log(payload);
      try {
        const condition = Object.values(params).filter(Boolean).join('')
        const formatParams = `?nguoiGui=${infoUser.tenNV}${condition}`
        const { data } = yield call(() => benhnhanCRMService.sendSMSAllBN(formatParams))
        const { success, failed } = getNumbers(data)
        if (success) {
          notification.success({
            message: 'Gửi tin nhắn thành công',
            description: data[0],
            duration: 5,
          })
        }
        if (failed) {
          notification.error({
            message: 'Gửi tin nhắn thất bại',
            description: data[1],
            duration: 6,
          })
        }
        setReload(true)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.GUI_SMS_ALL_BENH_NHAN,
          true,
          MENU_CONSTANTS.BENH_NHAN,
          null,
          {
            name: infoUser.tenNV,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: error?.response?.data?.message || 'Có lỗi xảy ra khi gửi tin nhắn',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.BENH_NHAN,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        setSendAll(false)
      }
    },
  )

  // UPDATE THÔNG TIN BỆNH NHÂN
  yield takeLatest(
    typeAction.UPDATE_INFO_BN_CRM,
    function* updateInfoBNCRM({ type, id, form, setReload }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      const jsonData = {}

      for (const [key, value] of form) {
        jsonData[key] = value
      }
      try {
        yield call(() => benhnhanCRMService.postInfoBNCRMById(id, form))
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật bệnh nhân thành công',
        })
        setReload(true)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.EDIT_BENH_NHAN_CRM,
          true,
          MENU_CONSTANTS.BENH_NHAN,
          null,
          {
            form: jsonData,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật bệnh nhân thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(jsonData)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.BENH_NHAN,
          detailErrorPayload,
        )
      }
    },
  )

  // import danh sách
  yield takeLatest(typeAction.IMPORT_LIST_BN_CRM, function* importListCRM({ type, payload }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    const newData = yield payload.map((items) => ({
      tenbn: items.TENBN,
      gioitinh: items.GIOITINH,
      ngaysinh: moment(items.NGAYSINH, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      sodienthoai: items.SODIENTHOAI,
      makh: items.MAKH,
      nguoitao: infoUser.idnv,
      idgk: +items.MAGK,
    }))
    const result = newData
      .map((obj) => {
        return Object.entries(obj)
          .map(([key, value]) => `${key}: ${value}`)
          .join(', ')
      })
      .join('\n')
    try {
      yield put({
        type: typeAction.OPEN_IS_LOADING_TABLE_BN_KHAM_DOAN,
      })

      yield call(() => benhnhanCRMService.postBNCRMByExcel(newData))
      yield put({
        type: typeAction.IMPORT_DATA_CRM,
        payload: null,
      })
      // yield put(getAllBNCRM())
      yield put({
        type: typeAction.CLOSE_IS_LOADING_TABLE_BN_KHAM_DOAN,
      })
      Toast.fire({
        icon: 'success',
        title: 'Thêm bệnh nhân thành công',
        text: 'Vui lòng refresh lại danh sách',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.IMPORT_DS_BENH_NHAN_CRM,
        true,
        MENU_CONSTANTS.BENH_NHAN,
        null,
        {
          listBN: result,
        },
      )
    } catch (error) {
      console.log(error)
      yield put({
        type: typeAction.CLOSE_IS_LOADING_TABLE_BN_KHAM_DOAN,
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message} \nDanh sách bệnh nhân import:\n${result}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.IMPORT_DS_BENH_NHAN_CRM,
        detailErrorPayload,
      )

      Toast.fire({
        icon: 'error',
        title: error?.response?.data?.message,
      })
    }
  })

  //search idkh & keyword
  yield takeLatest(typeAction.SEARCH_BN_CRM, function* searchBNCRM({ idkh, keyword }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => benhnhanCRMService.searchBNCRM(idkh, keyword))
      yield put({
        type: typeAction.DISPATCH_LIST_ALL_BN_CRM,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}
