import * as typeAction from '../constants/constants'
export const postKhoDieuChinhAction = (form, list, resetForm) => ({
  type: typeAction.POST_PHIEU_DIEU_CHINH_KHO,
  form,
  list,
  resetForm,
})
export const deletePhieuDieuChinhTon = (idNhapXuat, onLoad) => ({
  type: typeAction.DELETE_PHIEU_DIEU_CHINH_TON,
  idNhapXuat,
  onLoad,
})
export const deleteDieuChinhTonChiTiet = (idChiTietNX, onLoad) => ({
  type: typeAction.DELETE_CHI_TIET_DIEU_CHINH,
  idChiTietNX,
  onLoad,
})