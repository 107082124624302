import { CloseOutlined } from '@ant-design/icons'
import { ConfigProvider, DatePicker, Input, InputNumber, Select, Table } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
import React, { memo, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as typeAction from '../../../../store/constants/constants'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import InputMSDK from './InputMSDK'
moment.locale('vi')
const TableChiTiet = memo(({ selectedItem, infoThuocVT }) => {
  const [isFocused, setIsFocused] = useState(false)
  // const { infoThuocVT } = useSelector((state) => state.NhapKhoReducer)
  const newInputRef = useRef(null)
  useEffect(() => {
    if (selectedItem && newInputRef.current) {
      newInputRef.current.focus()
    }
  }, [selectedItem])
  //------------ tính giá tiền ------------//
  const tongTien = infoThuocVT?.reduce(
    (total, items) =>
      total +
      (items.khoChiTiet.soLuong * items.khoChiTiet.soLuongDongGoi + items.khoChiTiet.countLe) *
        items.khoChiTiet.donGiaMua,
    0,
  )
  const tienCK = infoThuocVT?.reduce((total, items) => total + items.khoChiTiet.ckTruocVat, 0)
  const vat5 = infoThuocVT?.reduce((total, items) => total + items.khoChiTiet.vat5, 0)
  const vat8 = infoThuocVT?.reduce((total, items) => total + items.khoChiTiet.vat8, 0)
  const vat10 = infoThuocVT?.reduce((total, items) => total + items.khoChiTiet.vat10, 0)
  const thucTra = infoThuocVT?.reduce((total, items) => total + items.khoChiTiet.thucTra, 0)
  // const today = moment();
  const dispatch = useDispatch()
  // thay đổi số lượng thuốc
  const onChangSLChan = (e, id) => {
    let { value } = e.target
    value = parseFloat(value.replace(/[^0-9]/g, ''))
    const index = infoThuocVT.findIndex((items) => items.id === id)
    dispatch({
      type: typeAction.EDIT_INFO_SL_THUOCVT_BY_ID,
      payload: {
        index,
        value: value || 0,
      },
    })
  }
  const ChangePrice = (e, id) => {
    let { value } = e.target
    const index = infoThuocVT.findIndex((items) => items.id === id)
    value = parseFloat(value.replace(/[^0-9]/g, ''))

    dispatch({
      type: typeAction.CHANGE_PRICE_THUOC_VAT_TU,
      payload: {
        index,
        value: value || 0,
      },
    })
  }
  const Changemsdk = (e, id) => {
    let { value } = e.target
    const index = infoThuocVT.findIndex((items) => items.id === id)

    dispatch({
      type: typeAction.CHANGE_MSDK_NHAP_KHO,
      payload: {
        index,
        value: value,
      },
    })
  }
  const changeGhichu = (e, id) => {
    let { value } = e.target
    const index = infoThuocVT.findIndex((items) => items.id === id)

    dispatch({
      type: typeAction.DISPATCH_GHI_CHU_NHAP_KHO,
      payload: {
        index,
        value: value,
      },
    })
  }
  const changePriceThucTra = (value, id) => {
    const index = infoThuocVT.findIndex((items) => items.id === id)

    dispatch({
      type: typeAction.DISPATCH_CHANGE_PRICE_THUC_TRA,
      payload: {
        index,
        value: value || 0,
      },
    })
  }
  const changePriceTienCK = (value, id) => {
    const index = infoThuocVT.findIndex((items) => items.id === id)
    console.log(value);
    
    dispatch({
      type: typeAction.DISPATCH_CHANGE_PRICE_CK,
      payload: {
        index,
        value: Number(value) || 0,
      },
    })
  }
  const Changenhietdo = (e, id) => {
    let { value } = e.target
    const index = infoThuocVT.findIndex((items) => items.id === id)

    dispatch({
      type: typeAction.CHANGE_NONG_DO_NHAP_KHO,
      payload: {
        index,
        value: value,
      },
    })
  }
  const handleVAT = (id) => (value) => {
    dispatch({
      type: typeAction.DISPATCH_VAT_THUOCVT,
      payload: {
        id,
        value,
      },
    })
  }
  // thay đổi giá phi phát sing
  const handlePhiVanChuyen = (id) => (e) => {
    const value = e.target.value
    dispatch({
      type: typeAction.EDIT_PHI_VAN_CHUYEN_BY_ID,
      payload: {
        id,
        value: Number(value),
      },
    })
  }
  const handlePhiGiaCong = (id) => (e) => {
    const value = e.target.value
    dispatch({
      type: typeAction.EDIT_PHI_GIA_CONG_BY_ID,
      payload: {
        id,
        value: Number(value),
      },
    })
  }
  // %CK trước VAT
  const ptChiecKhauVAT = (id) => (value) => {
    // Nếu value là null, undefined hoặc chuỗi rỗng thì gán value = 0,
    // nếu không thì giới hạn giá trị trong khoảng từ 0 đến 100
    let newValue =
      value === null || value === undefined || value === ''
        ? 0
        : Math.max(0, Math.min(100, Number(value)))
    dispatch({
      type: typeAction.EDIT_PT_CK_VAT_BY_ID,
      payload: {
        id,
        value: newValue,
      },
    })
  }
  // remote infoThuocByID
  const deleteInfoThuocById = (id) => {
    dispatch({
      type: typeAction.DELETE_INFO_THUOCVT_BY_ID,
      payload: id,
    })
  }
  // handle số lô
  const hanldChangeSoLo = (id) => (e) => {
    const value = e.target.value
    dispatch({
      type: typeAction.DISPATCH_SOLO_THUOCVT,
      payload: {
        id,
        value,
      },
    })
  }
  const handleHanDung = (id, dateString) => {
    const date = moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD')

    dispatch({
      type: typeAction.DISPATCH_HANDUNG_THUOCVT,
      payload: {
        date,
        id,
      },
    })
  }
  const priceTruocVAT = (value, id) => {
    // let { value } = e.target
    const index = infoThuocVT.findIndex((items) => items.id === id)
    // value = Number(value)
    dispatch({
      type: typeAction.DISPATCH_GIA_TRUOC_VAT_NHAP_KHO,
      payload: {
        index,
        value,
      },
    })
  }
  const onChangeCK = (value, id) => {
    const index = infoThuocVT.findIndex((items) => items.id === id)
    dispatch({
      type: typeAction.DISPACTH_TIEN_CHIEC_KHAU_NHAP_KHO,
      payload: {
        index,
        value: value || 0,
      },
    })
  }
  const onChangeCountLe = (value, id) => {
    const index = infoThuocVT.findIndex((items) => items.id === id)
    dispatch({
      type: typeAction.DISPATCH_ONCHANGE_COUNT_LE_NHAP_KHO,
      payload: {
        index,
        value: value || 0,
      },
    })
  }
  const onChangePriceVAT = (value, id) => {
    const index = infoThuocVT.findIndex((items) => items.id === id)
    dispatch({
      type: typeAction.DISPATCH_GIA_VAT_NHAP_KHO,
      payload: {
        index,
        value: value || 0,
      },
    })
  }
  const today = moment().startOf('day') // Set to the start of today

  // console.log(infoThuocVT)

  const handleMaSoDangKyChange = (value, id) => {
    // const index = infoThuocVT.findIndex((items) => items.id === id)
    // dispatch({
    //   type: typeAction.CHANGE_MSDK_NHAP_KHO,
    //   payload: {
    //     index,
    //     value: value,
    //   },
    // })
    // console.log(id)
    // console.log(value)
  }
  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            fontSize: 12,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          bordered
          pagination={false}
          scroll={{
            y: 300,
          }}
          columns={[
            {
              key: 1,
              title: 'Thông tin hàng',

              children: [
                {
                  key: 1.1,
                  title: 'STT',
                  dataIndex: 'STT',
                  width: 40,
                  fixed: true,
                  align: 'center',
                },
                {
                  key: 1.2,
                  title: 'Tên hàng',
                  dataIndex: 'TENHANG',
                  width: 200,
                  fixed: true,
                },
                {
                  key: 1.3,
                  title: 'Mã hàng',
                  dataIndex: 'MAHANG',
                  width: 70,
                  fixed: true,
                },
              ],
            },
            {
              key: 2,
              title: 'Đơn vị chẵn',
              children: [
                {
                  key: 2.1,
                  title: 'SL',
                  dataIndex: 'SLCHAN',
                  width: 60,
                  align: 'center',
                  editable: true,
                },
                {
                  key: 2.2,
                  title: 'Đơn vị',
                  dataIndex: 'DVCHAN',
                  align: 'center',
                  width: 60,
                },
                {
                  key: 2.3,
                  title: 'Quy cách',
                  dataIndex: 'QUYCACH',
                  align: 'center',
                  width: 70,
                },
                {
                  key: 24,
                  title: 'SL quy đổi',
                  dataIndex: 'SLQUYDOI',
                  align: 'center',
                  width: 70,
                },
                {
                  key: 2.5,
                  title: 'Đơn giá ',
                  dataIndex: 'DGCHAN',
                  align: 'right',
                  width: 80,
                },
              ],
            },
            {
              key: 3,
              title: 'Đơn vị lẻ',
              children: [
                {
                  key: 3.1,
                  title: 'SL ',
                  dataIndex: 'countLe',
                  width: 80,
                  align: 'center',
                },
                {
                  key: 3.2,
                  title: 'Đơn vị',
                  dataIndex: 'DVLE',
                  align: 'center',
                  width: 60,
                },
                {
                  key: 3.3,
                  title: (
                    <p>
                      <span className="text-red-500">(1)</span> Đơn giá{' '}
                    </p>
                  ),
                  dataIndex: 'DGLE',
                  align: 'center',
                  width: 70,
                },
                {
                  key: 3.4,
                  title: 'Giá GPP',
                  dataIndex: 'GIAGPP',
                  width: 70,
                  align: 'center',
                },
              ],
            },
            {
              key: 4,
              title: 'Thành tiền',
              children: [
                {
                  key: 4.0,
                  title: (
                    <p>
                      <span className="text-red-500">(2)</span> Tổng Lẻ{' '}
                    </p>
                  ),
                  dataIndex: 'SLLE',
                  width: 80,
                  align: 'center',
                },
                {
                  key: 4.1,
                  title: (
                    <p>
                      <span className="text-red-500">(3)</span>Tổng tiền (1) x (2){' '}
                    </p>
                  ),
                  dataIndex: 'TONGTIEN',
                  align: 'right',
                  width: 124,
                },
                {
                  key: 4.2,
                  title: 'P. Gia công',
                  dataIndex: 'PHIGIACONG',
                  width: 75,
                  align: 'center',
                },
                {
                  key: 4.3,
                  title: 'P. Vận chuyển',
                  dataIndex: 'PHIVANCHUYEN',
                  width: 89,
                  align: 'center',
                },
                {
                  key: 4.4,
                  title: (
                    <p>
                      <span className="text-red-500">(4)</span>% Chiết khấu{' '}
                    </p>
                  ),
                  dataIndex: 'CKTRUOCVAT',
                  width: 100,
                  align: 'center',
                },
                {
                  key: 4.5,
                  title: (
                    <p>
                      <span className="text-red-500">(5)</span>Tiền chiết khấu (3) x (4){' '}
                    </p>
                  ),
                  dataIndex: 'TIENCKTRUOCVAT',
                  width: 160,
                  align: 'right',
                },
                {
                  key: 5.1,
                  title: (
                    <p>
                      <span className="text-red-500">(6)</span>Thành tiền (3) - (5){' '}
                    </p>
                  ),
                  dataIndex: 'THANHTIENTRVAT',
                  width: 130,
                  align: 'right',
                },
                {
                  key: 4.6,
                  title: (
                    <p>
                      <span className="text-red-500">(7)</span>% VAT
                    </p>
                  ),
                  dataIndex: 'VAT',
                  width: 70,
                  align: 'center',
                },
                {
                  key: 4.7,
                  title: (
                    <p>
                      <span className="text-red-500">(8)</span>Tiền %VAT (6) x (7)
                    </p>
                  ),
                  dataIndex: 'TIENVAT',
                  width: 130,
                  align: 'center',
                },
                {
                  key: 4.9,
                  title: 'Thành tiền',
                  dataIndex: 'THANHTIEN',
                  width: 100,
                  align: 'right',
                },
                {
                  key: 5,
                  title: 'Thực trả (6) + (8)',
                  dataIndex: 'THUCTRA',
                  width: 120,
                  align: 'right',
                },
              ],
            },
            {
              key: 5,
              title: 'Chi tiết',
              children: [
                {
                  key: 5.1,
                  title: 'Nhiệt độ',
                  dataIndex: 'NHIETDO',
                  align: 'center',
                  width: 80,
                },
                {
                  key: 5.2,
                  title: 'Mã Số ĐK',
                  dataIndex: 'MASODANGKY',
                  align: 'center',
                  width: 140,
                },
                {
                  key: 5.3,
                  title: 'Số lô',
                  dataIndex: 'SOLO',
                  width: 90,
                  align: 'center',
                },
                {
                  key: 5.4,
                  title: 'Hạn dùng',
                  dataIndex: 'HANDUNG',
                  align: 'center',
                  width: 120,
                },
                {
                  key: 5.5,
                  title: 'Ghi chú',
                  dataIndex: 'GHICHU',
                  align: 'center',
                  width: 150,
                },
              ],
            },
            {
              key: 6,
              title: '',
              dataIndex: 'ACTION',
              width: 40,
              align: 'center',
              fixed: 'right',
            },
          ]}
          dataSource={infoThuocVT?.map((items, index) => ({
            key: items.id,
            STT: ++index,
            TENHANG: items.TENBIETDUOC,
            MAHANG: items.MATHUOC,
            QUYCACH: items.QUYCACHDONGGOI,
            SLQUYDOI: items.QUYCACHDONGGOI * items.khoChiTiet.soLuong,
            SLCHAN: (
              <Input
                ref={selectedItem === items.id ? newInputRef : null}
                min={0}
                required
                defaultValue={1}
                className="p-0 text-center"
                value={items.khoChiTiet.soLuong}
                type="number"
                onChange={(e) => {
                  onChangSLChan(e, items.id)
                }}
              />
            ),
            countLe: (
              <InputNumber
                className="w-full"
                size="small"
                value={items.khoChiTiet.countLe}
                formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                onChange={(value) => onChangeCountLe(value, items.id)}
              />
            ),
            DVCHAN: items.DONVICHAN,
            DGCHAN: formatNumberVND(items.khoChiTiet.donGiaMua * items.QUYCACHDONGGOI),
            SLLE: items.khoChiTiet.soLuong * items.QUYCACHDONGGOI + items.khoChiTiet.countLe,
            DVLE: items.DVT,
            DGLE: formatNumberVND(items.khoChiTiet.donGiaMua),
            TONGTIEN: formatNumberVND(
              (items.khoChiTiet.soLuong * items.QUYCACHDONGGOI + items.khoChiTiet.countLe) *
                items.khoChiTiet.donGiaMua,
            ),
            PHIGIACONG: (
              <Input
                value={items.khoChiTiet.phiGiaCong}
                onChange={handlePhiGiaCong(items.id)}
                type="number"
                defaultValue={0}
                className="p-0 text-center"
              />
            ),
            PHIVANCHUYEN: (
              <Input
                value={items.khoChiTiet.phiVanChuyen}
                onChange={handlePhiVanChuyen(items.id)}
                type="number"
                defaultValue={0}
                className="p-0 text-center"
              />
            ),
            CKTRUOCVAT: (
              <InputNumber
                value={items.khoChiTiet.ptckTruocVat}
                onChange={ptChiecKhauVAT(items.id)}
                defaultValue={0}
                size='small'
                className="p-0 text-center"
                formatter={(value) =>
                  value
                    ? `${Number(value).toFixed(3)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : '0.000'
                }
                step="0.001"
              />
            ),
            // TIENCKTRUOCVAT: formatNumberVND(Math.round(items.khoChiTiet.ckTruocVat)),
            TIENCKTRUOCVAT: (
              <InputNumber
                size="small"
                className="w-full "
                value={Math.round(items.khoChiTiet.ckTruocVat)}
                formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                onChange={(value) => changePriceTienCK(value, items.id)}
              />
            ),
            THANHTIENTRVAT: (
              <InputNumber
                className="w-full"
                size="small"
                value={items.khoChiTiet.tongTienTruocVAT}
                step="0.001"
                formatter={(value) => {
                  const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                  return ` ${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                onChange={(value) => priceTruocVAT(value, items.id)}
              />
              // <Input
              //   type="Number"
              //   size="small"
              //   onChange={(e) => priceTruocVAT(e, items.id)}
              //   value={items.khoChiTiet.tongTienTruocVAT}
              // />
            ),
            VAT: (
              <Select
                onChange={handleVAT(items.id)}
                className="w-full  h-[22px]"
                value={items.khoChiTiet.ptVAT}
                options={[
                  { label: '0%', value: 0 },
                  { label: '5%', value: 0.05 },
                  { label: '8%', value: 0.08 },
                  { label: '10%', value: 0.1 },
                ]}
              />
            ),
            TIENVAT: (
              <InputNumber
                onFocus={() => {
                  setIsFocused(true)
                }}
                onBlur={() => {
                  setIsFocused(false)
                }}
                step="0.001"
                size="small"
                value={!isFocused ? Math.round(items.khoChiTiet.tienVAT) : items.khoChiTiet.tienVAT}
                formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                onChange={(value) => onChangePriceVAT(value, items.id)}
              />
            ),
            THANHTIEN: formatNumberVND(items.khoChiTiet.thanhTien),
            THUCTRA: (
              <InputNumber
                size="small"
                className="w-full "
                value={Math.round(items.khoChiTiet.thucTra)}
                formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                onChange={(value) => changePriceThucTra(value, items.id)}
              />
              // <Input
              //   onChange={(e) => changePriceThucTra(e, items.id)}
              //   size="small"
              //   value={items.khoChiTiet.thucTra}
              // />
            ),
            GIAGPP: formatNumberVND(items.khoChiTiet.giaGPP),
            NHIETDO: (
              <Input
                size="small"
                onChange={(e) => Changenhietdo(e, items.id)}
                value={items.khoChiTiet.nhietDo}
              />
            ),
            MASODANGKY: (
              // <Input
              //   onChange={(e) => Changemsdk(e, items.id)}
              //   size="small"
              //   value={items.khoChiTiet.maSoDangKy}
              // />
              <InputMSDK items={items} infoThuocVT={infoThuocVT} />
            ),
            GHICHU: (
              <Input
                onChange={(e) => changeGhichu(e, items.id)}
                size="small"
                value={items.khoChiTiet.GHICHU}
              />
            ),
            SOLO: (
              <Input
                value={items.khoChiTiet.soLo}
                onChange={hanldChangeSoLo(items.id)}
                className="p-0 text-center"
              />
            ),
            HANDUNG: (
              <DatePicker
                size="small"
                onChange={(date, dateString) => {
                  handleHanDung(items.id, dateString)
                }}
                value={
                  items.khoChiTiet.hanDung !== '' && items.khoChiTiet.hanDung !== null
                    ? dayjs(items.khoChiTiet.hanDung, 'YYYY-MM-DD')
                    : ''
                }
                allowClear={false}
                disabledDate={(current) => current && current < today}
                format={'DD/MM/YYYY'}
              />
            ),
            ACTION: (
              <CloseOutlined
                onClick={() => {
                  deleteInfoThuocById(items.id)
                }}
                className="text-white bg-red-500 p-1 rounded-md cursor-pointer hover:bg-red-400"
              />
            ),
          }))}
          footer={() => (
            <div className="w-full  flex justify-end text-sm">
              <ul className="flex flex-col gap-2 mt-2 mr-5">
                <li className="flex w-60 justify-between border-b">
                  <label>Tổng tiền:</label>
                  <p>
                    {formatNumberVND(Math.round(tongTien))}
                    {/* {tongTien.toLocaleString('vi-VN')} */}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between border-b">
                  <label>Tiền chiết khấu:</label>
                  <p>
                    <i className="text-red-500">-</i>
                    {formatNumberVND(Math.round(tienCK))}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between border-b">
                  <label>VAT 5%:</label>
                  <p>
                    <i className="text-green-500">+</i>
                    {formatNumberVND(Math.round(vat5))}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between border-b">
                  <label>VAT 8%:</label>
                  <p>
                    <i className="text-green-500">+</i>
                    {formatNumberVND(Math.round(vat8))}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between border-b">
                  <label>VAT 10%:</label>
                  <p>
                    <i className="text-green-500">+</i>
                    {formatNumberVND(Math.round(vat10))}
                    <span> VNĐ</span>
                  </p>
                </li>
                <li className="flex w-60 justify-between ">
                  <label>Thực trả:</label>
                  <p>
                    {formatNumberVND(Math.round(thucTra))}
                    <span> VNĐ</span>
                  </p>
                </li>
              </ul>
            </div>
          )}
        />
      </ConfigProvider>
    </div>
  )
})

export default TableChiTiet
