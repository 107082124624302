import { Tabs, ConfigProvider, Table, Divider, Dropdown, Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { MoreOutlined, RetweetOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import { detailBNCashier } from '../../../../store/actions/cashierAction'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import Swal from 'sweetalert2' // Import Swal
import { addLog } from '../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../utils/log/logConstant'

// Define Toast using Swal.mixin
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

const TabPT = ({ resetFormThanhToan, BNChuaThu, setIsDelete, setRefundSuccess }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { BNPending } = useSelector((state) => state.receiveReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [isQuyenXem, setisQuyenXem] = useState(false)
  const STATUS_KHAM = 1

  // Hàm gọi API hoàn phí khám
  const handleRefund = async (record) => {
    try {
      const response = await https.post('/BenhNhanThongTinChung/HoanPhiKham', null, {
        params: { idBNTTC: record.idbnttc },
      })
      // console.log('Hoàn phí thành công:', response.data)
      dispatch(detailBNCashier('', '', '', PkDangNhap?.idChuyenKhoa)) // Cập nhật lại danh sách bệnh nhân chờ khám
      setRefundSuccess(true) // Thông báo cho InfoPhieu cập nhật
      Toast.fire({
        icon: 'success',
        title: 'Hoàn phí thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.HOAN_PHI_KHAM,
        true,
        MENU_CONSTANTS.TIEP_NHAN,
        null,
        {
          tenbn: record.tenBN,
          mabn: record.mabn,
        },
      )
    } catch (error) {
      console.error('Lỗi khi hoàn phí:', error)
      Toast.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Hoàn phí thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.TIEP_NHAN,
        detailErrorPayload,
      )
    }
  }

  // Hàm hiển thị popup xác nhận hoàn phí
  const showConfirm = (record) => {
    Modal.confirm({
      title: 'Xác nhận hoàn phí',
      content: 'Bạn có chắc chắn muốn hoàn phí khám không?',
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk: () => handleRefund(record),
    })
  }

  // -------------- lấy thông tin chi tiết bệnh nhân chưa thu tiền ------------/
  const onClickDetailBN = (record) => {
    setIsDelete({ DV: [], XN: [], CDHA: [] })
    // console.log(record)

    dispatch(detailBNCashier(record.idbn, 368, record.idbnttc, PkDangNhap?.idChuyenKhoa))
    resetFormThanhToan()
  }
  const getQuyenXemListPhieuChuaThu = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT034',
      )
      setisQuyenXem(true)
    } catch (error) {
      setisQuyenXem(false)
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  useEffect(() => {
    getQuyenXemListPhieuChuaThu()
  }, [])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      width: 40,
      key: 'stt',
    },
    {
      title: 'Mã BN',
      dataIndex: 'mabenhnhan',
      width: 120,
      key: 'mabenhnhan',
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'tenbenhnhan',
      key: 'tenbenhnhan',
    },
  ]

  const columnsHP = [
    {
      title: 'STT',
      dataIndex: 'STT',
      align: 'center',
      width: 40,
      key: 'STT',
    },
    {
      title: 'Mã BN',
      dataIndex: 'mabn',
      width: 120,
      key: 'mabn',
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'tenBN',
      key: 'tenBN',
    },
  ]

  // Conditionally add the refund column
  if (infoUser.idNhom === 1 || infoUser.idNhom === 27 || infoUser.idNhom === 28) {
    columnsHP.push({
      title: '',
      dataIndex: 'hoanphi',
      key: 'hoanphi',
      align: 'center',
      width: 90,
      render: (text, record) => (
        <Button
          type="primary"
          onClick={(e) => {
            e.stopPropagation() // Stop event propagation
            showConfirm(record)
          }}
          size="small"
          style={{ backgroundColor: '#f5222d', borderColor: '#f5222d' }}
        >
          Hoàn phí
        </Button>
      ),
    })
  }

  const items = [
    {
      key: '1',
      label: <p className="ml-1">Phiếu chưa thu</p>,
      children: (
        <>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#e3f0fb',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              bordered
              pagination={false}
              scroll={{ y: 289 }}
              dataSource={
                isQuyenXem
                  ? BNChuaThu?.map((item, index) => ({
                      stt: ++index,
                      mabenhnhan: item.mabenhnhan,
                      tenbenhnhan: item.tenbenhnhan,
                      idbn: item.idbn,
                      idbnttc: item.idbnttc,
                    }))
                  : []
              }
              onRow={(record) => ({
                onClick: () => onClickDetailBN(record),
              })}
              columns={columns}
            />
          </ConfigProvider>
        </>
      ),
    },
    {
      key: '2',
      label: 'Chờ khám',
      children: (
        <>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              bordered
              pagination={false}
              scroll={{ y: 289 }}
              dataSource={BNPending?.map((item) => ({
                STT: item.sttkham,
                mabn: item.mabenhnhan,
                tenBN: item.tenbenhnhan,
                idbnttc: item.idbnttc,
                idbn: item.idbn,
              }))}
              onRow={(record) => ({
                onClick: () => onClickDetailBN(record),
              })}
              columns={columnsHP}
            />
          </ConfigProvider>
        </>
      ),
    },
  ]

  return (
    <>
      <Tabs items={items} />
    </>
  )
}

export default TabPT
