import React, { useState } from 'react'
import { Modal, Input, DatePicker, Form } from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'

const ModalTimeTTV = ({ openTimeTTV, setOpenTimeTTV }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { infoUser } = useSelector((state) => state.userReducer)

  const handleCancel = () => {
    setOpenTimeTTV(false)
    form.resetFields()
  }

  // Add date validation
  const validateDates = (ngayBatDau, ngayKetThuc) => {
    const startDate = moment(ngayBatDau)
    const endDate = moment(ngayKetThuc)

    if (!startDate.isValid() || !endDate.isValid()) {
      ToastCus.fire({
        icon: 'error',
        title: 'Ngày không hợp lệ',
      })
      return false
    }

    if (startDate.isAfter(endDate)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Ngày bắt đầu phải nhỏ hơn ngày kết thúc',
      })
      return false
    }
    return true
  }

  const onFinish = async (values) => {
    try {
      const { maThe, ngayBatDau, ngayKetThuc } = values

      // Validate dates
      if (!validateDates(ngayBatDau, ngayKetThuc)) {
        return
      }

      setLoading(true)

      // Format dates using native Date methods
      const formatDate = (date) => {
        const d = new Date(date)
        const day = String(d.getDate()).padStart(2, '0')
        const month = String(d.getMonth() + 1).padStart(2, '0')
        const year = d.getFullYear()
        return `${month}-${day}-${year}`
      }

      const tuNgay = formatDate(ngayBatDau)
      const denNgay = formatDate(ngayKetThuc)

      const res = await https.put(
        `CongCu/UpdateNgayHieuLucThe?mathe=${maThe}&tuNgay=${encodeURIComponent(tuNgay)}&denNgay=${encodeURIComponent(denNgay)}`,
      )

      if (res.data) {
        ToastCus.fire({
          icon: 'success',
          title: 'Cập nhật thời gian thành công',
        })
        handleCancel()
      }
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message || 'Cập nhật thời gian thất bại',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Cập nhật thời gian thẻ thành viên"
      open={openTimeTTV}
      onCancel={handleCancel}
      onOk={form.submit}
      confirmLoading={loading}
      okText="Xác nhận"
      cancelText="Huỷ bỏ"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Mã thẻ"
          name="maThe"
          rules={[{ required: true, message: 'Vui lòng nhập mã thẻ!' }]}
        >
          <Input placeholder="Nhập mã thẻ..." />
        </Form.Item>

        <Form.Item
          label="Ngày bắt đầu"
          name="ngayBatDau"
          rules={[{ required: true, message: 'Vui lòng chọn ngày bắt đầu!' }]}
        >
          <DatePicker
            format="DD/MM/YYYY"
            style={{ width: '100%' }}
            placeholder="Chọn ngày bắt đầu..."
            disabledDate={(current) => current && current < moment().startOf('day')}
          />
        </Form.Item>

        <Form.Item
          label="Ngày kết thúc"
          name="ngayKetThuc"
          rules={[{ required: true, message: 'Vui lòng chọn ngày kết thúc!' }]}
        >
          <DatePicker
            format="DD/MM/YYYY"
            style={{ width: '100%' }}
            placeholder="Chọn ngày kết thúc..."
            disabledDate={(current) => current && current < moment().startOf('day')}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalTimeTTV
