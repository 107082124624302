import { create, update } from 'lodash'
import { https } from '../apiService'
import { getIn } from 'formik'
export const smsService = {
  getTemplate: () => https.get(`SMSTemplate/GetAll`),
  // getBNSms: (numberTP, numberTTV) =>
  //   https.get(`/BenhNhan/GetBenhNhanGuiSMS?numberTP=${numberTP}&numberTTV=${numberTTV}`),
  getBNSms: (idChuyenKhoa, numberTP, numberTTV, ageGroup) =>
    https.get(`/BenhNhan/GetBenhNhanGuiSMS`, {
      params: { idChuyenKhoa, numberTP, numberTTV, ageGroup },
    }),
  getInfoSms:(id) => https.get(`SMSTemplate/GetSMSTemplateByIDTP?idtp=${id}`),
  getAccountsSMS: () => https.get('SMSTaiKhoan/GetAll'),
  postTemPlateSMS: (form) => https.post('SMSTemplate/AddSMSTemplate',form),
  putTemplateSMS: (form) => https.put('SMSTemplate/UpdateSMSTemplate',form),
  deleteTemplateSMS: (id) => https.delete(`SMSTemplate/DeleteSMSTemplate?idtp=${id}`),
  createAccountSMS: (form) => https.post('SMSTaiKhoan/AddSMSTaiKhoan',form),
  deleteAccountSMS: (id) => https.delete(`SMSTaiKhoan/DeleteSMSTaiKhoan?idtaikhoan=${id}`),
  updateAccountSMS: (form) => https.put('SMSTaiKhoan/UpdateSMSTaiKhoan',form),
}
