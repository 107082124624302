import { FilterOutlined } from '@ant-design/icons'
import { Button, Collapse, DatePicker, Input } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import 'moment/locale/vi'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
moment.locale('vi')
const { TextArea } = Input

const listItemClasses =
  'flex items-center border py-2 rounded-md hover:bg-sky-100 hover:cursor-pointer hover:-translate-y-1 duration-200'
const activeListItemClasses =
  'flex items-center border py-2 rounded-md bg-blue-200 cursor-pointer -translate-y-1 duration-200'

function DoKhucXa({}) {
  const { PkDangNhap } = useSelector((state) => state.branchReducer)

  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoBN } = useSelector((state) => state.traCuuReducer)
  const [listDoKhucXa, setListDoKhucXa] = useState([])
  const [listDoKhucXaDefault, setListDoKhucXaDefault] = useState([])

  const [time, setTime] = useState(null)
  const [activeRow, setActiveRow] = useState(null)
  const onClickFilter = () => {
    if (!time) {
      setListDoKhucXa(listDoKhucXaDefault)
      return
    }
    const data = listDoKhucXaDefault.filter(
      (item) => dayjs(item.ngaykham).format('DD/MM/YYYY') === time,
    )
    setListDoKhucXa(data)
  }

  const getListDKX = async (idbn) => {
    //reset state
    setTime(null)
    setActiveRow(null)
    try {
      const { data } = await https.get(
        `BenhNhanMat/GetLichSuBenhNhanKhamMatCheckBacSi?idbn=${idbn}&idChiNhanh=${PkDangNhap?.idChiNhanh}&idNV=${infoUser.idnv}`,
      )
      setListDoKhucXa(data)
      setListDoKhucXaDefault(data)

      const tenBenhNhan = infoBN.benhNhan?.tenBenhNhan

      const detailForLog = {
        tenBenhNhan,
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap?.idChiNhanh,
        actionStatus.GET,
        logAction.XEM_KET_QUA_CAN_LAM_SANG,
        true,
        MENU_CONSTANTS.TRACUU_BENH_NHAN,
        null,
        detailForLog,
      )
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (infoBN?.idbn) {
      getListDKX(infoBN.idbn)
    }
  }, [infoBN, PkDangNhap, infoUser])

  const handleShowDetail = (item) => {
    setActiveRow(item)
  }

  return (
    <div className="flex">
      <div className="w-1/6 pr-2 border-r-2 h-full">
        <div className="flex">
          <DatePicker
            placeholder="Chọn ngày khám"
            onChange={(value, dateString) => {
              setTime(dateString)
            }}
            format={'DD/MM/YYYY'}
            className="w-full"
          />
          <Button
            onClick={onClickFilter}
            type="primary"
            icon={<FilterOutlined />}
            className="ml-2"
          ></Button>
        </div>
        {/* date picker time */}

        <ul className=" flex flex-col gap-2 mt-2 rounded-md h-[680px] overflow-auto p-2">
          {listDoKhucXa.map((item) => (
            <li
              key={item.idbnttc}
              onClick={() => handleShowDetail(item)}
              className={
                item.idbnttc === activeRow?.idbnttc ? activeListItemClasses : listItemClasses
              }
            >
              <p className="w-full text-center font-semibold">
                {moment(item.ngaykham).format('DD/MM/YYYY HH:mm')}
              </p>
            </li>
          ))}
        </ul>
      </div>

      <div className="w-5/6 p-2">
        {listDoKhucXa?.length > 0 && activeRow && (
          <>
            <p>
              Bác sĩ thực hiện: <span className="font-semibold">{activeRow.nguoikham}</span>
            </p>
            <div className="overflow-x-hidden pb-10">
              <Collapse
                className="mt-2"
                size="small"
                defaultActiveKey={1}
                items={[
                  {
                    key: '1',
                    label: 'THỊ LỰC (VISUAL ACUITY)',
                    children: (
                      <>
                        <div className="grid grid-cols-12">
                          <div className="col-span-3 grid grid-rows-6 pr-1 border-r-[1px]">
                            <div className=""></div>
                            <div className="flex justify-between">
                              <span>
                                Không kính <span className="text-[12px] italic">(Without RX)</span>
                              </span>
                              <span>
                                Xa <span className="text-[12px] italic">(Dist)</span>
                              </span>
                            </div>
                            <div className="flex justify-between">
                              <span></span>
                              <span>
                                Gần <span className="text-[12px] italic">(Near)</span>
                              </span>
                            </div>
                            <div className="flex justify-between">
                              <span>
                                Kính lỗ <span className="text-[12px] italic">(PH)</span>
                              </span>
                              <span>
                                Xa <span className="text-[12px] italic">(Dist)</span>
                              </span>
                            </div>
                            <div className="flex justify-between">
                              <span>
                                Kính cũ <span className="text-[12px] italic">(CC)</span>
                              </span>
                              <span>
                                Xa <span className="text-[12px] italic">(Dist)</span>
                              </span>
                            </div>
                            <div className="text-end">
                              Gần <span className="text-[12px] italic">(Near)</span>
                            </div>
                          </div>
                          <div className="col-span-3 grid grid-rows-6 pl-1 pr-1 border-r-[1px]">
                            <div className="text-center">MP (OD)</div>
                            <div className="mb-1">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckhongkinhxA_MP}
                                name="thiluckhongkinhxA_MP"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckhongkinhgaN_MP}
                                name="thiluckhongkinhgaN_MP"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckinhloxA_MP}
                                name="thiluckinhloxA_MP"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckinhcuxA_MP}
                                name="thiluckinhcuxA_MP"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckinhcugaN_MP}
                                name="thiluckinhcugaN_MP"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="col-span-3 grid grid-rows-6 pl-1 pr-1  border-r-[1px]">
                            <div className="text-center">MT (OS)</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckhongkinhxA_MT}
                                name="thiluckhongkinhxA_MT"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckhongkinhgaN_MT}
                                name="thiluckhongkinhgaN_MT"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckinhloxA_MT}
                                name="thiluckinhloxA_MT"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckinhcuxA_MT}
                                name="thiluckinhcuxA_MT"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckinhcugaN_MT}
                                name="thiluckinhcugaN_MT"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="col-span-3 grid grid-rows-6 pl-1">
                            <div className="text-center">2M (OU)</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckhongkinhxA_2M}
                                name="thiluckhongkinhxA_2M"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckhongkinhgaN_2M}
                                name="thiluckhongkinhgaN_2M"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckinhloxA_2M}
                                name="thiluckinhloxA_2M"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckinhcuxA_2M}
                                name="thiluckinhcuxA_2M"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.thiluckinhcugaN_2M}
                                name="thiluckinhcugaN_2M"
                                size="small"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
              <Collapse
                className="mt-2"
                size="small"
                defaultActiveKey={1}
                items={[
                  {
                    key: '1',
                    label: 'NHÃN ÁP (TONOMETRY)',
                    children: (
                      <>
                        <div className="grid grid-cols-2 gap-1">
                          <div className="flex pr-16">
                            <span className="w-36 text-end pr-1">MP (OD)</span>
                            <Input
                              className="bg-gray-100"
                              readOnly
                              value={activeRow?.nhanaP_MP}
                              name="nhanaP_MP"
                              size="small"
                            />
                          </div>
                          <div className="flex pr-16">
                            <span className="w-36 text-end pr-1">MT (OS)</span>
                            <Input
                              className="bg-gray-100"
                              readOnly
                              value={activeRow?.nhanaP_MT}
                              name="nhanaP_MT"
                              size="small"
                            />
                          </div>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
              <Collapse
                className="mt-2"
                size="small"
                defaultActiveKey={1}
                items={[
                  {
                    key: '1',
                    label: 'ĐO KHÚC XẠ (REFRACTION)',
                    children: (
                      <>
                        <div className="grid grid-cols-12 gap-1">
                          <div className="col-span-4 grid grid-rows-4 pr-1 border-r-[1px]">
                            <div className=""></div>
                            <div className="">
                              Khách quan không liệt ĐT{' '}
                              <span className="text-[12px] italic">(Objective w/o Cydoplegic)</span>
                            </div>
                            <div className="">
                              Khách quan liệt ĐT{' '}
                              <span className="text-[12px] italic">(Objective w Cydoplegic)</span>
                            </div>
                            <div className="">
                              Chủ quan <span className="text-[12px] italic">(Subjective)</span>
                            </div>
                          </div>
                          <div className="col-span-4 grid grid-rows-4 pr-1 border-r-[1px]">
                            <div className="text-center">MP (OD)</div>
                            <div className="mb-1">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khachquankhonglietdT_MP}
                                name="khachquankhonglietdT_MP"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khachquanlietdT_MP}
                                name="khachquanlietdT_MP"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.chuquaN_MP}
                                name="chuquaN_MP"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="col-span-4 grid grid-rows-4">
                            <div className="text-center">MT (OS)</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khachquankhonglietdT_MT}
                                name="khachquankhonglietdT_MT"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khachquanlietdT_MT}
                                name="khachquanlietdT_MT"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.chuquaN_MT}
                                name="chuquaN_MT"
                                size="small"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
              <Collapse
                className="mt-2"
                size="small"
                defaultActiveKey={1}
                items={[
                  {
                    key: '1',
                    label: 'KÍNH CŨ (CURRENT GLASSES)',
                    children: (
                      <>
                        <div className="grid grid-cols-11 gap-1">
                          <div className="grid grid-rows-3 border-r-[1px]">
                            <div className=""></div>
                            <div className="">MP (OD)</div>
                            <div className="">MT (OS)</div>
                          </div>
                          <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                            <div className="text-center">Cầu (Sph)</div>
                            <div className="mb-1">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhcU_MP_CAU}
                                name="kinhcU_MP_CAU"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhcU_MT_CAU}
                                name="kinhcU_MT_CAU"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                            <div className="text-center">Loạn (Cyl)</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhcU_MP_LOAN}
                                name="kinhcU_MP_LOAN"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhcU_MT_LOAN}
                                name="kinhcU_MT_LOAN"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                            <div className="text-center">Trục (Axis)</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhcU_MP_TRUC}
                                name="kinhcU_MP_TRUC"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhcU_MT_TRUC}
                                name="kinhcU_MT_TRUC"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                            <div className="text-center">ADD</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhcU_MP_ADD}
                                name="kinhcU_MP_ADD"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhcU_MT_ADD}
                                name="kinhcU_MT_ADD"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="col-span-2 grid grid-rows-3">
                            <div className="text-center">PD</div>
                            <div className="row-span-2">
                              <TextArea
                                readOnly
                                value={activeRow?.kinhcU_PD}
                                name="kinhcU_PD"
                                size="small"
                                rows={2}
                                className="pb-1 bg-gray-100"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
              <Collapse
                className="mt-2"
                size="small"
                defaultActiveKey={1}
                items={[
                  {
                    key: '1',
                    label: 'KÍNH ĐIỀU CHỈNH (SPECTACLE PRESCRIPTION)',
                    children: (
                      <>
                        <div className="grid grid-cols-8 gap-1">
                          <div className="grid grid-rows-3 border-r-[1px]">
                            <div className=""></div>
                            <div className="">MP (OD)</div>
                            <div className="">MT (OS)</div>
                          </div>
                          <div className=" grid grid-rows-3 pr-1 border-r-[1px]">
                            <div className="text-center">Cầu (Sph)</div>
                            <div className="mb-1">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhdieuchinH_MP_CAU}
                                name="kinhdieuchinH_MP_CAU"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhdieuchinH_MT_CAU}
                                name="kinhdieuchinH_MT_CAU"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className=" grid grid-rows-3 pr-1 border-r-[1px]">
                            <div className="text-center">Loạn (Cyl)</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhdieuchinH_MP_LOAN}
                                name="kinhdieuchinH_MP_LOAN"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhdieuchinH_MT_LOAN}
                                name="kinhdieuchinH_MT_LOAN"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className=" grid grid-rows-3 pr-1 border-r-[1px]">
                            <div className="text-center">Trục (Axis)</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhdieuchinH_MP_TRUC}
                                name="kinhdieuchinH_MP_TRUC"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhdieuchinH_MT_TRUC}
                                name="kinhdieuchinH_MT_TRUC"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className=" grid grid-rows-3 pr-1 border-r-[1px]">
                            <div className="text-center">TL (VA)</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhdieuchinH_MP_TL}
                                name="kinhdieuchinH_MP_TL"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhdieuchinH_MT_TL}
                                name="kinhdieuchinH_MT_TL"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className=" grid grid-rows-3 pr-1 border-r-[1px]">
                            <div className="text-center">ADD</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhdieuchinH_MP_ADD}
                                name="kinhdieuchinH_MP_ADD"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhdieuchinH_MT_ADD}
                                name="kinhdieuchinH_MT_ADD"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="col-span-2 grid grid-rows-3">
                            <div className="text-center">PD</div>
                            <div className="row-span-2">
                              <TextArea
                                readOnly
                                value={activeRow?.kinhdieuchinH_PD}
                                name="kinhdieuchinH_PD"
                                size="small"
                                rows={2}
                                className="pb-1 bg-gray-100"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
              <Collapse
                className="mt-2"
                size="small"
                defaultActiveKey={1}
                items={[
                  {
                    key: '1',
                    label: 'KÍNH TIẾP XÚC (CONTACT LENS PRESCRIPTION)',
                    children: (
                      <>
                        <div className="grid grid-cols-9 gap-1">
                          <div className="grid grid-rows-3 border-r-[1px]">
                            <div className=""></div>
                            <div className="">MP (OD)</div>
                            <div className="">MT (OS)</div>
                          </div>
                          <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                            <div className="text-center">
                              Công suất <span className="text-[12px] italic">(Power)</span>
                            </div>
                            <div className="mb-1">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhtiepxuC_MP_CONGSUAT}
                                name="kinhtiepxuC_MP_CONGSUAT"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhtiepxuC_MT_CONGSUAT}
                                name="kinhtiepxuC_MT_CONGSUAT"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                            <div className="text-center">BC</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhtiepxuC_MP_BC}
                                name="kinhtiepxuC_MP_BC"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhtiepxuC_MT_BC}
                                name="kinhtiepxuC_MT_BC"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="col-span-2 grid grid-rows-3 pr-1 border-r-[1px]">
                            <div className="text-center">DIA</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhtiepxuC_MP_DIA}
                                name="kinhtiepxuC_MP_DIA"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhtiepxuC_MT_DIA}
                                name="kinhtiepxuC_MT_DIA"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="col-span-2 grid grid-rows-3">
                            <div className="text-center">
                              Hiệu <span className="text-[12px] italic">(Brand)</span>
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhtiepxuC_MP_HIEUBRAND}
                                name="kinhtiepxuC_MP_HIEUBRAND"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.kinhtiepxuC_MT_HIEUBRAND}
                                name="kinhtiepxuC_MT_HIEUBRAND"
                                size="small"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
              <Collapse
                className="mt-2"
                size="small"
                defaultActiveKey={1}
                items={[
                  {
                    key: '1',
                    label: 'KHÁM TIỀN CHẤN (PRE TEST)',
                    children: (
                      <>
                        <div className="grid grid-cols-12 gap-1">
                          <div className="col-span-3 grid grid-rows-8 pr-1 border-r-[1px]">
                            <div className=""></div>
                            <div className="">
                              Sắc giác <span className="text-[12px] italic">(Color)</span>
                            </div>
                            <div className="">
                              Đồng tử <span className="text-[12px] italic">(Pupil)</span>
                            </div>
                            <div className="">
                              Vận nhãn <span className="text-[12px] italic">(EOM)</span>
                            </div>
                            <div className="">
                              Thị trường <span className="text-[12px] italic">(Visual field)</span>
                            </div>
                            <div className="flex justify-between">
                              <span>
                                Test che mắt{' '}
                                <span className="text-[12px] italic">(Cover test)</span>
                              </span>
                              <span>
                                Xa <span className="text-[12px] italic">(Dist)</span>
                              </span>
                            </div>
                            <div className="flex justify-between">
                              <span></span>
                              <span>
                                Gần <span className="text-[12px] italic">(Near)</span>
                              </span>
                            </div>
                            <div className="">
                              Biên độ điều tiết <span className="text-[12px] italic">(AA)</span>
                            </div>
                          </div>
                          <div className="col-span-3 grid grid-rows-8 pr-1 border-r-[1px]">
                            <div className="text-center">MP (OD)</div>
                            <div className="mb-1">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MP_SACGIAC}
                                name="khamtienchaN_MP_SACGIAC"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MP_DONGTU}
                                name="khamtienchaN_MP_DONGTU"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MP_VANNHAN}
                                name="khamtienchaN_MP_VANNHAN"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MP_THITRUONG}
                                name="khamtienchaN_MP_THITRUONG"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MP_TESTCHEMAT_XA}
                                name="khamtienchaN_MP_TESTCHEMAT_XA"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MP_TESTCHEMAT_GAN}
                                name="khamtienchaN_MP_TESTCHEMAT_GAN"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MP_BODIEUTIETAA}
                                name="khamtienchaN_MP_BODIEUTIETAA"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="col-span-3 grid grid-rows-8 pr-1 border-r-[1px]">
                            <div className="text-center">MT (OS)</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MT_SACGIAC}
                                name="khamtienchaN_MT_SACGIAC"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MT_DONGTU}
                                name="khamtienchaN_MT_DONGTU"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MT_VANNHAN}
                                name="khamtienchaN_MT_VANNHAN"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MT_THITRUONG}
                                name="khamtienchaN_MT_THITRUONG"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MT_TESTCHEMAT_XA}
                                name="khamtienchaN_MT_TESTCHEMAT_XA"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MT_TESTCHEMAT_GAN}
                                name="khamtienchaN_MT_TESTCHEMAT_GAN"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_MT_BODIEUTIETAA}
                                name="khamtienchaN_MT_BODIEUTIETAA"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="col-span-3 grid grid-rows-8">
                            <div className="text-center">2M (OU)</div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_2M_SACGIAC}
                                name="khamtienchaN_2M_SACGIAC"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_2M_DONGTU}
                                name="khamtienchaN_2M_DONGTU"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_2M_VANNHAN}
                                name="khamtienchaN_2M_VANNHAN"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_2M_THITRUONG}
                                name="khamtienchaN_2M_THITRUONG"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_2M_TESTCHEMAT_XA}
                                name="khamtienchaN_2M_TESTCHEMAT_XA"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_2M_TESTCHEMAT_GAN}
                                name="khamtienchaN_2M_TESTCHEMAT_GAN"
                                size="small"
                              />
                            </div>
                            <div className="">
                              <Input
                                className="bg-gray-100"
                                readOnly
                                value={activeRow?.khamtienchaN_2M_BODIEUTIETAA}
                                name="khamtienchaN_2M_BODIEUTIETAA"
                                size="small"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-1">
                          <div className="col-span-3 border-r-[1px]">
                            Cận điểm quy tụ <span className="text-[12px] italic">(NPC)</span>
                          </div>
                          <div className="col-span-9">
                            <Input
                              className="bg-gray-100"
                              readOnly
                              value={activeRow?.khamtienchaN_CANDIEMQUYTUNPC}
                              name="khamtienchaN_CANDIEMQUYTUNPC"
                              size="small"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-1">
                          <div className="col-span-3 border-r-[1px]">
                            Thị giác lập thể <span className="text-[12px] italic">(Stereo)</span>
                          </div>
                          <div className="col-span-9 mt-1">
                            <Input
                              className="bg-gray-100"
                              readOnly
                              value={activeRow?.khamtienchaN_THIGIACLAPTHESTEREO}
                              name="khamtienchaN_THIGIACLAPTHESTEREO"
                              size="small"
                            />
                          </div>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

DoKhucXa.propTypes = {}

export default DoKhucXa
