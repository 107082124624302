import * as typeAction from '../constants/constants'
export const fetchLoiDanBsDanhMuc = (search) => ({
  type: typeAction.GET_LOI_DAN_BS_DANH_MUC,
  search
})
export const deleteLoiDanBs = (record, reload) => ({
  type: typeAction.DELETE_LOI_DAN_BAC_SI,
  record,
  reload,
})
export const addNewLoiDanBs = (form, reload) => ({
  type: typeAction.ADD_NEW_LOI_DAN_BAC_SI,
  form,
  reload,
})
export const getInfoLoiDanById = (info) => ({
  type: typeAction.GET_INFO_LOI_DAN,
  info,
})
export const updateLoiDanById = (form, reload) => ({
  type: typeAction.UPDATE_LOI_DAN_BAC_SI,
  form,
  reload,
})
