import { Button, Col, Input, Row, Select, Form, Checkbox } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTemplateSMS } from '../../../../../store/actions/smsAction'
const styleLabel = {
  fontWeight: 600,
}
const InfoTemplate = () => {
  const { accountSMS, infoTemplate } = useSelector((state) => state.smsReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const ChuyenKhoas = ['NHI', 'SAN', 'MEDICAL']
  const handleSubmit = (values) => {
    dispatch(updateTemplateSMS(values))
  }
  const onChangeSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  const onClickCheckbox = (e) => {
    const { name, checked } = e.target
    formik.setFieldValue(name, checked ? 1 : 0)
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idtp: infoTemplate?.idtp,
      matp: infoTemplate?.matp,
      tieude: infoTemplate?.tieude,
      idtaikhoan: infoTemplate?.idtaikhoan,
      templatE_CODAU: infoTemplate?.templatE_CODAU,
      templatE_KHONGDAU: infoTemplate?.templatE_KHONGDAU,
      noidungmau: infoTemplate?.noidungmau,
      tiengvietcodau: infoTemplate?.tiengvietcodau,
      ghichu: infoTemplate?.ghichu,
      sudung: infoTemplate?.sudung,
      chuyenkhoa: infoTemplate?.chuyenkhoa,
      templateid: infoTemplate?.templateid,
      idloaisms: infoTemplate?.idloaisms,
      nguoisua: infoUser?.idnv,
    },
    onSubmit: (values) => handleSubmit(values),
  })
  return (
    <form className="flex flex-col xl:gap-5 gap-3">
      <Row gutter={16}>
        <Col lg={24} xl={12} className="xl:mb-0 mb-2">
          <label style={styleLabel}>Mã template:</label>
          <Input variant="filled" value={formik.values.matp} />
        </Col>
        <Col lg={24} xl={12}>
          <label style={styleLabel}>Tiêu đề:</label>
          <Input value={formik.values.tieude} name="tieude" onChange={formik.handleChange} />
        </Col>
      </Row>
      <Row
        gutter={{
          lg: 16,
        }}
      >
        <Col lg={24} xl={12} className="xl:mb-0 mb-2">
          <label style={styleLabel}>Chuyên khoa:</label>
          <Select
            onChange={onChangeSelected('chuyenkhoa')}
            value={formik.values.chuyenkhoa}
            className="w-full"
            placeholder="Chọn Chuyên khoa "
            options={ChuyenKhoas?.map((value) => ({
              label: value,
              value: value,
            }))}
          />
        </Col>
        <Col lg={24} xl={12}>
          <label style={styleLabel}>Loại tài khoản:</label>
          <Select
            onChange={onChangeSelected('idtaikhoan')}
            value={formik.values.idtaikhoan}
            className="w-full"
            placeholder="Chọn loại tài khoản"
            options={accountSMS?.map((item) => ({
              label: (
                <p>
                  {' '}
                  {item.brandname}{' '}
                  <span className="text-gray-400">( account: {item.taikhoan} )</span>
                </p>
              ),
              value: item.idtaikhoan,
            }))}
          />
        </Col>
      </Row>
      <Row
        gutter={{
          lg: 16,
        }}
      >
        <Col lg={24} xl={12} className="xl:mb-0 mb-2">
          <label style={styleLabel}>Nội dung không dấu:</label>
          <Input.TextArea
            name="templatE_KHONGDAU"
            onChange={formik.handleChange}
            value={formik.values.templatE_KHONGDAU}
            style={{
              height: 80,
              resize: 'none',
            }}
            showCount
            placeholder="Nội dung không dấu"
          />
        </Col>
        <Col lg={24} xl={12}>
          <label style={styleLabel}>Nội dung có dấu:</label>
          <Input.TextArea
            name="templatE_CODAU"
            onChange={formik.handleChange}
            value={formik.values.templatE_CODAU}
            style={{
              height: 80,
              resize: 'none',
            }}
            showCount
            placeholder="Nội dung không dấu"
          />
        </Col>
      </Row>
      <Col lg={24} className="xl:mb-0 mb-2 flex gap-2 items-center ">
        <label style={styleLabel}>Sử dụng:</label>
        <Checkbox checked={formik.values.sudung === 1} name="sudung" onClick={onClickCheckbox} />
      </Col>
      {/* <label className="text-gray-500">
        Ngày tạo: 2024/01/12 13:55:23 / Người tạo: Nguyễn Quốc Tài
      </label> */}
      <div>
        <Button type="primary" onClick={formik.handleSubmit}>
          Cập nhập
        </Button>
      </div>
    </form>
  )
}

export default InfoTemplate
