import React, { useState, useEffect } from 'react'
import { Button as AntButton, Table, ConfigProvider, Tooltip, Select, Input } from 'antd'
import moment from 'moment'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached'
import { FileSearchOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons'
import {
  filterPT_tranferCK,
  listInfoThuocVTChyenKho_Action,
} from '../../../../../store/actions/chuyenKhoAction'
import { branchService } from '../../../../../services/branch/branchService'
import { chuyenKhoService } from '../../../../../services/ChuyenKho/chuyenKhoService'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { putCheckQuyenThaoTacAction } from '../../../../../store/actions/phanQuyenAction'
import * as XLSX from 'xlsx'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import 'moment/locale/vi'
import { addLog } from '../../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../../utils/log/logConstant'
moment.locale('vi')

const columns = [
  {
    title: 'STT',
    dataIndex: 'STT',
    key: 'STT',
    width: 40,
    align: 'center',
  },
  {
    title: 'Mã phiếu',
    dataIndex: 'MAPHIEU',
    key: 'MAPHIEU',
    width: 120,
  },
  {
    title: 'Tên phiếu',
    dataIndex: 'TENPHIEU',
    key: 'TENPHIEU',
    width: 200,
  },
  {
    title: 'Người chuyển',
    dataIndex: 'NGUOICHUYEN',
    key: 'NGUOICHUYEN',
  },
  {
    title: 'Ngày chuyển',
    dataIndex: 'NGAYNHAN',
    key: 'NGAYNHAN',
    width: 120,
  },
  {
    title: 'Công ty chuyển',
    dataIndex: 'CTYCHUYEN',
    key: 'CTYCHUYEN',
  },
  {
    title: 'Chi nhánh chuyển',
    dataIndex: 'NOICHUYEN',
    key: 'NOICHUYEN',
  },
  {
    title: 'Công ty Nhận',
    dataIndex: 'CTYNHAN',
    key: 'CTYNHAN',
  },
  {
    title: 'Chi nhánh nhận',
    dataIndex: 'NOINHAN',
    key: 'NOINHAN',
  },
  {
    title: 'Kho nhận',
    dataIndex: 'khoNhan',
    key: 'khoNhan',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    width: 90,
    align: 'center',
  },
  {
    title: 'Hành động',
    dataIndex: 'action',
    key: 'action',
    width: 120,
    align: 'center',
  },
]

const Transfer = ({ showModal, setStatusUpdate }) => {
  const BranchLogin = localStorage.getItem('BRANH_LOGIN')
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { PTTransfer } = useSelector((state) => state.chuyenKhoReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { branch } = useSelector((state) => state.NhapKhoReducer) // tên chi nhánh mặc định
  const [searchKho, setSearchKho] = useState('')
  const [data, setData] = useState([])
  const [searchMa, setSearchMa] = useState('')
  const [searchBranch, setSearchBranch] = useState('')
  const [ListBranch, setListBranch] = useState([])
  const [ListKho, setListKho] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const statusChuyenKho = 2 // trạng thái đã rời kho
  const filter = {
    status: statusChuyenKho, //
    idCongTy: ID_COMPANY,
    nameCompany: listCompany.find((item) => item.idct === ID_COMPANY)?.tenct,
    tenStatus: 'Phiếu đã  rời',
  }

  const maQuyenXemDs = 'QHT215'
  const maQuyenXuatExcelDs = 'QHT216'
  const maQuyenXemCt = 'QHT213'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        fetchData()
        break
      case maQuyenXuatExcelDs:
        exportToExcel()
        break
      case maQuyenXemCt:
        handleShowModalDetail(infoNew?.idNhapXuat)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXuatExcelDs:
        //
        break
      case maQuyenXemCt:
        //
        break
      default:
        return false
    }
  }
  // xử lí lọc dưc liệu
  const handleFilter = () => {
    //  dispatch(filterPT_tranferCK(filter, setIsLoading))
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  const handleShowModalDetail = (idNhapXuat) => {
    showModal()
    setStatusUpdate(false)
    dispatch(listInfoThuocVTChyenKho_Action(idNhapXuat))
  }
  const filteredData = data?.filter((items) => {
    const findCty = searchBranch.toLowerCase()
    const findKho = searchKho.toLowerCase()
    const findMa = searchMa.toLowerCase()
    return (
      items?.tenCTNhan?.toLowerCase().includes(findCty) &&
      items?.tenKhoNhap?.toLowerCase().includes(findKho) &&
      items?.maPhieu?.toLowerCase().includes(findMa)
    )
  })
  //----------- lấy danh sách chi nhánh theo công ty -------//
  const fetchBranch = async () => {
    const { data } = await branchService.getListBranch(ID_COMPANY)
    setListBranch(data)
  }
  //---------- lấy danh sách kho chi nhánh-------------//
  const fetchKho = async (id) => {
    const { data } = await chuyenKhoService.getlistKhoNhan(id)
    setListKho(data)
  }
  //------------fetch dữ liệu ------------//
  const fetchData = async () => {
    try {
      setIsLoading(true)
      const { data } = await chuyenKhoService.getChuyenKhoCTYFilter(filter)
      const sortedData = _.orderBy(data, 'ngayXuat', 'desc')
      setData(sortedData)
      setIsLoading(false)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_DS_PHIEU_CHUYEN_KHO_CTY,
        true,
        MENU_CONSTANTS.CHUYEN_KHO_CONG_TY,
        null,
        {
          nameCompany: filter.nameCompany,
          nameStatus: filter.tenStatus,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.CHUYEN_KHO_CONG_TY,
        detailErrorPayload,
      )
    }
  }
  const handleSelected = (value, option) => {
    setSearchBranch(option.label === 'Tất cả' ? '' : option.label)
    if (value !== '') {
      fetchKho(value)
    } else {
      setSearchKho('')
      setListKho([])
    }
  }
  //------------ lấy lên tên công ty tương ứng -------------//
  const selectedStaff = listCompany?.find((item) => item.idct === ID_COMPANY)
  useEffect(() => {
    //dispatch(filterPT_tranferCK(filter, setIsLoading))
    handleFilter()
    fetchBranch()
    dispatch(listAllCompanyAction())
  }, [])
  // useEffect(() => {
  //   const sortedData = _.orderBy(PTTransfer, 'ngayXuat', 'desc')
  //   setData(sortedData)
  // }, [PTTransfer]

  const exportToExcel = () => {
    try {
      const headers = [
        'Mã phiếu',
        'Tên phiếu',
        'Người chuyển',
        'Ngày chuyển',
        'Công ty chuyển',
        'Chi nhánh chuyển',
        'Công ty nhận',
        'Chi nhánh nhận',
        'Kho nhận',
      ]

      const formattedData = filteredData.map((item) => ({
        'Mã phiếu': item.maPhieu,
        'Tên phiếu': item.tenPhieu,
        'Người chuyển': item.tenNVXuat,
        'Ngày chuyển': moment(item.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
        'Công ty chuyển': item.tenCTXuat,
        'Chi nhánh chuyển': item.tenChiNhanhXuat,
        'Công ty nhận': item.tenCTNhan,
        'Chi nhánh nhận': item.tenChiNhanhNhan,
        'Kho nhận': item.tenKhoNhap,
      }))

      const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
      XLSX.writeFile(wb, 'Phiếu chuyển kho công ty - Phiếu đã rời.xlsx')
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_DS_PHIEU_CHUYEN_KHO_CTY,
        true,
        MENU_CONSTANTS.CHUYEN_KHO_CONG_TY,
        null,
        {
          nameCompany: filter.nameCompany,
          nameStatus: filter.tenStatus,
        },
      )
    } catch (error) {
      console.log(error)
      const detailErrorPayload = `Có lỗi xảy ra khi xuất excel phiếu đã rời chuyển kho công ty \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.CHUYEN_KHO_CONG_TY,
        detailErrorPayload,
      )
    }
  }

  return (
    <div className="h-full">
      <div className="flex gap-5 ">
        <div className="flex">
          <label className=" font-semibold w-32">Công ty chuyển:</label>
          <Input value={selectedStaff?.tenct} readOnly size="small" className="w-72" />
        </div>
        <div>
          <label className="font-semibold">Công ty nhận: </label>
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              { label: 'Tất cả', value: '' },
              ...listCompany?.map((item) => ({
                label: item.tenct,
                value: item.idct,
              })),
            ]}
            value={searchBranch}
            onChange={handleSelected}
            size="small"
            className="w-60"
          />
        </div>
        {/* <div>
          <label className="font-semibold">Kho nhận: </label>
          <Select
            size="small"
            options={[
              { label: 'Tất cả', value: '' },
              ...ListKho?.map((item) => ({
                label: item.tenKho,
                value: item.tenKho,
              })),
            ]}
            value={searchKho}
            onChange={(value) => setSearchKho(value)}
            className="w-52"
          />
        </div> */}
        <div>
          <label className="font-semibold">Mã phiếu: </label>
          <Input size="small" className="w-40" onChange={(e) => setSearchMa(e.target.value)} />
        </div>
        <div className="flex gap-3">
          <div
            className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
            onClick={handleFilter}
          >
            <CachedIcon sx={{ fontSize: 25 }} />
          </div>
        </div>
        <div className="flex gap-3 ml-auto">
          <AntButton
            disabled={!filteredData?.length}
            // onClick={exportToExcel}
            onClick={() =>
              dispatch(
                putCheckQuyenThaoTacAction(maQuyenXuatExcelDs, null, actionIsTrue, actionIsFalse),
              )
            }
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </AntButton>
        </div>
      </div>
      <div
        className="  mt-2"
        // style={{
        //   boxShadow:
        //     "0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)",
        // }}
      >
        <ConfigProvider
          theme={{
            token: {
              padding: 4,
              fontSize: 12,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            loading={isLoading}
            bordered
            pagination={{
              pageSize: 20,
              showSizeChanger: false,
            }}
            columns={columns}
            dataSource={filteredData?.map((items, index) => ({
              STT: ++index,
              MAPHIEU: items.maPhieu,
              TENPHIEU: items.tenPhieu,
              NGUOICHUYEN: items.tenNVXuat,
              NGAYNHAN: moment(items.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
              NOICHUYEN: items.tenChiNhanhXuat,
              NOINHAN: items.tenChiNhanhNhan,
              CTYNHAN: items.tenCTNhan,
              CTYCHUYEN: items.tenCTXuat,
              khoNhan: items.tenKhoNhap,
              status: (
                <span className="text-blue-500 border border-blue-500 font-semibold p-[2px] rounded-lg">
                  Chờ xác nhận
                </span>
              ),
              action: (
                <Tooltip title="Xem chi tiết" color="#108ee9">
                  <EyeOutlined
                    onClick={() =>
                      dispatch(
                        putCheckQuyenThaoTacAction(
                          maQuyenXemCt,
                          { idNhapXuat: items?.idNhapXuat },
                          actionIsTrue,
                          actionIsFalse,
                        ),
                      )
                    }
                    className="text-xl text-[#108ee9]  cursor-pointer"
                  />
                </Tooltip>
              ),
            }))}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default Transfer
