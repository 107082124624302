import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  ListXetNghiem: null,
  groupsXetNghiem: null,
  infoXetNghiem: null,
  history: [],
  detailXN: null,
  VTTHXetNghiem: [],
}

const xetNghiemReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM:
        draft.ListXetNghiem = payload
        break
      case typeAction.DISPATCH_GROUPS_XETNGHIEM:
        draft.groupsXetNghiem = payload
        break
      case typeAction.DISPATCH_VTTH_XETNGHIEM:
        draft.VTTHXetNghiem = payload
        break
      case typeAction.DISPATCH_INFO_XET_NGHIEM:
        draft.infoXetNghiem = payload
        break
      case typeAction.DISPATCH_HISTORY_XET_NGHIEM:
        draft.history = payload
        break
      case typeAction.DISPATCH_CHANGE_KET_QUA_XET_NGHIEM:
        draft.detailXN.DanhSachXetNghiem[payload.index].ketqua = payload.value
        break
      case typeAction.DISPATCH_CHANGE_CHI_SO_XET_NGHIEM:
        draft.detailXN.DanhSachXetNghiem[payload.index].chisobinhthuong = payload.value
        break
      case typeAction.DISPATCH_DETAIL_BN_XN:
        draft.detailXN = payload
        break
      default:
        return state
    }
  })
}

export default xetNghiemReducer
