import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import _, { add } from 'lodash'
import { childrenClinicService } from '../../services/childrenClinic/childrenClinicService'
import { drugstoreService } from '../../services/drugstore/drugstoreService'
import { recieveService } from '../../services/receive/recieveService'
import { BNChoKham, thuocKeToa, updateDetailBN } from '../actions/childrenClinicAction'
import { generateKhamBenhXuatVacXin } from '../../utils/report/vacxin/phieuKhamBenhXuatVacxin'
import { generatePhieuToaThuoc } from '../../utils/report/phieuToaThuoc'
import { generatePhieuChiDinh } from '../../utils/report/phieuChiDinh'
import { cashierService } from '../../services/cashier/cashierService'
import { doKhucXaService } from '../../services/doKhucXa/doKhucXaService'
import { cardService } from '../../services/card/cardService'
import { generatePhieuKiemTruocTiemChung } from '../../utils/report/phieuKiemTruocTiemChung'
import { generatePhieuKiemTruocTiemChungLao } from '../../utils/report/phieuKiemTruocTiemChungLao'
import { generatePhieuKiemTruocTiemChungSan } from '../../utils/report/phieuKiemTruocTiemChungSan'
import moment from 'moment'
// import { generatePhieuToaThuocTA } from '../../utils/report/phieuToaThuocTA'
import 'moment/locale/vi'
import { generatePhieuKhamBenh } from '../../utils/report/phieuKhamBenh'
import { generatePhieuToaThuocKoLayThuoc } from '../../utils/report/phieuToaThuocKoLayThuoc'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { addLog } from '../../utils/log/apiLog'

moment.locale('vi')

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* childrenClinicSaga() {
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(typeAction.GET_BN_CHO_KHAM_BY_PK, function* BNPending({ type, idcnPk }) {
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    const { infoUser } = yield select((state) => state.userReducer)
    const { phongKham } = yield select((state) => state.receiveReducer)
    try {
      const tenPhongKham = phongKham?.find((pk) => pk.idcnpk === idcnPk)?.tenPhongKham || ''
      const result = yield call(() => childrenClinicService.getBNCho(idcnPk))
      yield put({
        type: typeAction.DISPATCH_BN_CHO_KHAM,
        payload: result.data,
      })
      yield call(
        // lưu tên bs khám của chi nhánh phòng khám hiện tại để hiện thị lên ds bn chờ khám của màn hình gọi số
        () =>
          infoUser?.tenNhom?.toLowerCase()?.includes('bác sĩ') &&
          childrenClinicService.putBsKhamCnPk({
            idcnpk: idcnPk,
            idbskham: infoUser?.idnv,
          }),
      )
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.XEM_DANH_SACH_BENH_NHAN_CHO_KHAM_THEO_PHONGKHAM,
        true,
        MENU_CONSTANTS.PHONGKHAM,
        null,
        {
          phongkham: tenPhongKham,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.XEM_DANH_SACH_BENH_NHAN_CHO_KHAM_THEO_PHONGKHAM,
        true,
        MENU_CONSTANTS.PHONGKHAM,
        null,
        detailErrorPayload,
      )
    }
  })
  // LẤY THÔNG TIN CHI TIẾT DANH SÁCH BỆNH NHÂN KHÁM BỆNH
  yield takeLatest(
    typeAction.GET_INFO_DETAIL_BN_KHAM,

    function* BNPending({ type, idbn, idbnttc }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const { infoUser } = yield select((state) => state.userReducer)

        const result = yield call(() => recieveService.getInfoBN(idbn))
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: result.data,
        })
        const infoDetail = yield call(() => childrenClinicService.getDetailBNKham(idbnttc))
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: infoDetail.data,
        })
        const infoDetailToaThuoc = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
        yield put({
          type: typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM,
          payload: infoDetailToaThuoc.data,
        })
        const cardBN = yield call(() =>
          cardService.getInfoMemberCard(idbn, PkDangNhap?.idChuyenKhoa),
        )
        yield put({
          type: typeAction.TTV_BENH_NHAN_NHI,
          payload: cardBN.data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        const history = yield call(() => recieveService.getLichSuTongQuat(idbn))
        const sortedData = _.orderBy(history.data, 'ngaykham', 'desc')
        const newDataHistory = sortedData.slice(1)
        yield put({
          type: typeAction.DISPATCH_HISTORY_KHAM_PK,
          payload: newDataHistory,
        })

        //Xem lịch sử khám bệnh của bệnh nhân cụ thể

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DETAIL_BENH_NHAN_KHAM_NHI,
          true,
          MENU_CONSTANTS.PHONGKHAM,
          null,
          {
            tenbenhnhan: result.data.tenBenhNhan,
          },
        )
      } catch (error) {
        console.log(error)
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const { infoUser } = yield select((state) => state.userReducer)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          logAction.XEM_DETAIL_BENH_NHAN_KHAM_NHI,
          false,
          MENU_CONSTANTS.PHONGKHAM,
          detailErrorPayload,
        )
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // LẤY THÔNG TIN GỢI Ý KHÁM BỆNH BÁCH SĨ
  yield takeLatest(typeAction.GET_SUGGEST_DOCTOR, function* suggestDoctor({ type }) {
    try {
      // const chanDoan = yield call(() => childrenClinicService.getChanDoan(''));
      // yield put({
      //   type: typeAction.DISPATCH_CHAN_DOAN,
      //   payload: chanDoan.data,
      // });
      const loiDan = yield call(() => childrenClinicService.getLoiDan())
      yield put({
        type: typeAction.DISPATCH_LOI_DAN,
        payload: loiDan.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // LẤY DANH SÁCH THUỐC KÊ TOA TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_THUOC_KE_TOA_DOCTOR,
    function* thuocKeToaBS({ type, idChiNhanh }) {
      try {
        const result = yield call(() => childrenClinicService.getKetoaThuoc(idChiNhanh))
        let listVT = []
        // result?.data?.filter((item1) => result?.data?.indexOf(item2) !== index)
        const arrTonDuoc = result?.data?.filter(
          (items) =>
            items?.tonKho >= 0 &&
            // items?.thuocVatTu?.idNhom !== 169 &&
            items?.thuocVatTu?.idNhom !== 179 &&
            items?.thuocVatTu?.idNhom !== 205,
        ) //không lấy gọng kính trong kho dược
        arrTonDuoc?.map((item, index) => {
          if (listVT?.find((itemVT) => itemVT?.idThuoc === item?.idThuoc)) {
            listVT?.map(
              (itemVT) => itemVT?.idThuoc === item?.idThuoc && (itemVT.tonKho += item.tonKho),
            )
          } else {
            listVT.push({ ...item, ID: index })
          }
        })
        yield put({
          type: typeAction.DISPATCH_THUOC_KE_TOA_DOCTOR,
          payload: {
            ThuocVT: listVT,
            listTonDuoc: arrTonDuoc,
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
  //---------------- Cập nhật THÔNG TIN KHÁM BỆNH-------------//
  yield takeLatest(
    typeAction.UPDATE_DETAIL_BN_KHAM,
    function* BNPending({ type, infoBN, formBenhNhan, form, idcnPk, newArr, isPrint }) {
      // return
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const infoBNLog = yield call(() => recieveService.getInfoBN(formBenhNhan.idbn))
      try {
        const ID_BRANCH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        const { InfoBNDoKhucXa } = yield select((state) => state.doKhucXaReducer)
        // validate chỉ chuyên khoa mắt - bs mắt lưu trước khi đo khúc xạ
        if (PkDangNhap?.idChuyenKhoa === 4 && isPrint) {
          // const dataDKX =
          const { data: dkx } = yield call(() => doKhucXaService.getInfoBNDoKhucXa(form?.idbnttc))
          if (dkx && dkx?.DichVuBenhNhanDoKhucXa?.length && !dkx?.NGUOIKHAM) {
            Toast.fire({
              icon: 'error',
              title: 'Bệnh nhân chưa có kết quả đo khúc xạ!',
            })
            return
          }
        }
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        if (form?.lydokolaythuoc && !newArr?.find((item) => item?.isGongKinh)) {
          yield call(() => childrenClinicService.deleteBNThuocBSKhongKeToa(form?.idbnttc))
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.DELETE,
            logAction.XOA_TOA_THUOC_BS_KHONG_KE_TOA,
            true,
            MENU_CONSTANTS.KHAMSAN,
            null,
            {
              tenBenhNhan: infoBN.tenBenhNhan,
            },
          )
        }
        const tenBenhNhan = infoBN.tenBenhNhan
        const detailForLogBN = {
          tenBenhNhan: tenBenhNhan,
          benhkemtheo: form?.benhkemtheo,
          benhsu: form?.benhsu,
          bskham: form?.bskham,
          cacbophan: form?.cacbophan,
          cannang: form?.cannang,
          chandoan: form?.chandoan,
          chandoanbandau: form?.chandoanbandau,
          chandoanravien: form?.chandoanravien,
          chieucao: form?.chieucao,
          daxuly: form?.daxuly,
          dienbienlamsang: form?.dienbienlamsang,
          dieutringoaitru: form?.dieutringoaitru,
          diung: form?.diung,
          ghichu: form?.ghichu,
          giorapk: form?.giorapk,
          huongxuly: form?.huongxuly,
          huyetap: form?.huyetap,
          idbnttc: form?.idbnttc,
          idbskham: form?.idbskham,
          idchandoan: form?.idchandoan,
          idicD10BANDAU: form?.idicD10BANDAU,
          idicD10BENHKEMTHEO: form?.idicD10BENHKEMTHEO,
          idicD10RAVIEN: form?.idicD10RAVIEN,
          idloidan: form?.idloidan,
          loidan: form?.loidan,
          lydokham: form?.lydokham,
          lydokolaythuoc: form?.lydokolaythuoc,
          mach: form?.mach,
          ngaytaikham: form?.ngaytaikham,
          nhietdo: form?.nhietdo,
          nhiptho: form?.nhiptho,
          ppdieutri: form?.ppdieutri,
          quatrinhbenhly: form?.quatrinhbenhly,
          spO2: form?.spO2,
          tiensubanthan: form?.tiensubanthan,
          tieusugiadinh: form?.tieusugiadinh,
          toanthan: form?.toanthan,
          trangthaikhambenh: form?.trangthaikhambenh,
          trieuchung: form?.trieuchung,
          ttlucravien: form?.ttlucravien,
        }
        const result = yield call(
          () =>
            childrenClinicService.updateDetailBN({
              ...form,
              trangthaikhambenh: isPrint ? (newArr?.length > 0 ? 2 : 4) : 1,
            }), // ấn lưu & in đổi ttkb nếu có thuốc = 2, không có thuốc = 4, ấn nút khác thì 1
        )

        const detailForLog = {
          tenBenhNhan: tenBenhNhan,
          diUng: formBenhNhan?.diUng,
          dienThoai: formBenhNhan?.dienThoai,
          tienSuBanThan: formBenhNhan?.tienSuBanThan,
        }

        yield call(() => childrenClinicService.putInfoBN(formBenhNhan))
        yield call(() =>
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.CAP_NHAT_BN_KHAM,
            true,
            MENU_CONSTANTS.PHONGKHAM, // khong phan biet phong kham hay kham nhi
            null,
            detailForLog,
          ),
        )
        yield call(() =>
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.CAP_NHAT_BN_KHAM_TTV,
            true,
            MENU_CONSTANTS.PHONGKHAM,
            null,
            detailForLogBN,
          ),
        )
        // //in toa rong o nhi
        // if (newArr.length === 0 && form?.lydokolaythuoc && ) {
        //   generatePhieuToaThuocKoLayThuoc(infoBN, result.data, PkDangNhap)
        // }

        if (
          isPrint &&
          form?.lydokolaythuoc &&
          !newArr?.filter((item) => !item?.isGongKinh)?.length && // không có thuốc bthg và có thể có gọng kính hoặc không
          (PkDangNhap?.idChuyenKhoa === 1 ||
            (PkDangNhap?.idChuyenKhoa === 4 && form?.dataDoKhucXa && form?.dataDoKhucXa?.nguoikham)) // chuyên khoa nhi + mắt không có thuốc nhưng vẫn in phiếu toa thuốc
        ) {
          generatePhieuToaThuoc(
            infoBN,
            {
              ...result.data,
              dataDoKhucXa: form?.dataDoKhucXa?.nguoikham ? form?.dataDoKhucXa : null,
              khongCoToaNhungVanInToa: 1,
            },
            [{ idphanloai: 1 }], //không có thuốc nhưng vẫn in phiếu toa thuốc
            PkDangNhap,
          )
        }

        if (newArr.length > 0 && !form?.lydokolaythuoc) {
          //&& !form?.lydokolaythuoc
          // const formatData = newArr.map((item) => ({
          //   ...item,
          //   soLanNgay: item.soLanNgay + '',
          //   lieuDung: item.lieuDung + '',
          // }))
          const detailForLogToaThuoc = {
            tenBenhNhan: tenBenhNhan,
            danhSachThuoc: newArr.map((item) => ({
              tenThuoc: item.tenBietDuoc,
              soLuong: item.soLuong,
              donViTinh: item.dvt,
            })),
          }

          const toathuoc = yield call(() =>
            childrenClinicService.putToaThuocBsTuLaySoLo(
              form?.idbnttc,
              PkDangNhap?.idChiNhanh,
              newArr,
            ),
          )
          yield call(() =>
            addLog(
              infoUser?.dangNhap.idNguoiDung,
              PkDangNhap.idChiNhanh,
              actionStatus.PUT,
              logAction.CAP_NHAT_TOA_THUOC_BS_TU_LAY_SO_LO,
              true,
              MENU_CONSTANTS.PHONGKHAM,
              null,
              detailForLogToaThuoc,
            ),
          )
          //childrenClinicService.putBNThuocBSKeToaLai(idbnttc, list))
          if (isPrint && toathuoc?.data?.length > 0) {
            yield call(() => {
              let listVT = []
              // gộp các dòng khác số lô hạn dùng lại thành 1 dòng
              toathuoc?.data
                ?.sort((a, b) => (a?.stt ?? '').localeCompare(b?.stt ?? ''))
                ?.filter((item) => item?.idnhomthuoc !== 179 && item?.idnhomthuoc !== 205)
                ?.map((item) => {
                  if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
                    listVT?.map(
                      (itemVT) =>
                        itemVT?.idthuoc === item?.idthuoc && (itemVT.soLuong += item.soLuong),
                    )
                  } else {
                    listVT.push({
                      ...item,
                    })
                  }
                })
              // for (const item of toaPrint) {
              //   console.log(item)

              // }
              generatePhieuToaThuoc(
                infoBN,
                {
                  ...result.data,
                  dataDoKhucXa: form?.dataDoKhucXa?.nguoikham ? form?.dataDoKhucXa : null,
                },
                listVT,
                PkDangNhap,
              )
            })
          }
        } else if (
          form?.lydokolaythuoc &&
          newArr?.find((item) => item?.isGongKinh) &&
          PkDangNhap?.idChuyenKhoa === 4
        ) {
          yield call(() =>
            childrenClinicService.putToaThuocBsTuLaySoLo(
              form?.idbnttc,
              PkDangNhap?.idChiNhanh,
              newArr?.filter((item) => item?.isGongKinh),
            ),
          )
        }

        //phieu kham benh
        // if (isPrint && PkDangNhap?.idChuyenKhoa !== 2) {
        //   yield call(() => generatePhieuKhamBenh(result.data, PkDangNhap))
        // }

        yield put(BNChoKham(idcnPk))
        yield put(thuocKeToa(ID_BRANCH_LOGIN))
        // yield put({
        //   type: typeAction.DISPATCH_DETAIL_BN_KHAM,
        //   payload: null,
        // })
        // yield put({
        //   type: typeAction.DISPATCH_INFO_BN_KHAM,
        //   payload: null,
        // })
        // yield put({
        //   type: typeAction.NGAY_THUOC_NHI,
        //   payload: 2,
        // })
        yield put({
          type: typeAction.RESET_BS_KHAM_CAPNHAT,
        })
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (error) {
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TIEP_NHAN,
          detailErrorPayload,
        )
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      }
    },
  )
  // ------------- thêm chỉ định khám bệnh cho bệnh nhân ------------//
  yield takeLatest(
    typeAction.ADD_NEW_CHI_DINH_KHAM,
    function* thuocKeToaBS({
      type,
      ValuePrinter,
      form,
      listDel,
      idbnttc,
      tenNV,
      handleReset,
      chandoanbandau,
    }) {
      try {
        const data = yield select((state) => state.childrenClinicReducer)
        const dataChiNhanh = yield select((state) => state.branchReducer)
        const dataUser = yield select((state) => state.userReducer)
        const { infoUser } = dataUser
        const { DetailBN } = data
        const { PkDangNhap } = dataChiNhanh
        const tenBenhNhan = DetailBN.benhNhan?.tenBenhNhan
        const today = moment()
        // Lọc form thành ba mảng riêng dựa trên type
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const dvArray = form
          .filter((item) => item.type === 'dv' && !item?.infoCD) //&& !item.old
          .map((item) => ({
            idbnttc: idbnttc,
            iddichvu: item.idchiDinh,
            dongia: item.dongia,
            tenDinhVu: item.name,
            ptgiamgia: item?.ptgiamgia ?? 0,
            tiengiamgia: item?.tiengiamgia ?? 0,
            tienthanhtoan: item?.tienthanhtoan ?? item.dongia,
            ngaysudung: today.format('YYYY-MM-DDTHH:mm:ss'),
            trangthaikb: 1,
            trangthaitt: 3,
            idnguoichidinh: infoUser?.idnv,
          }))
        const xnArray = form
          .filter((item) => item.type === 'xn' && !item?.infoCD) //&& !item.old
          .map((item) => ({
            idbnttc: idbnttc,
            idxn: item.idchiDinh,
            tenDinhVu: item.name,
            ngaychidinh: today.format('YYYY-MM-DDTHH:mm:ss'),
            bschidinh: tenNV,
            trangthaitt: 3,
            dongia: item.dongia,
            ptgiamgia: item?.ptgiamgia ?? 0,
            tiengiamgia: item?.tiengiamgia ?? 0,
            tienthanhtoan: item?.tienthanhtoan ?? item.dongia,
          }))
        const cdhaArray = form
          .filter((item) => item.type === 'cdha' && !item?.infoCD) /// && !item.old)
          .map((item) => ({
            idbnttc: idbnttc,
            idcdha: item.idchiDinh,
            tenDinhVu: item.name,
            ngaychidinh: today.format('YYYY-MM-DDTHH:mm:ss'),
            idtrangthaitt: 3,
            dongia: item.dongia,
            ptgiamgia: item?.ptgiamgia ?? 0,
            tiengiamgia: item?.tiengiamgia ?? 0,
            tienthanhtoan: item?.tienthanhtoan ?? item.dongia,
          }))
        const listDvDel = listDel?.filter(
          (item) => item?.trangthaitt !== 2 && item.type === 'dv' && item?.infoCD,
        )
        const listXnDel = listDel?.filter(
          (item) => item?.trangthaitt !== 2 && item.type === 'xn' && item?.infoCD,
        )
        const listCdhaDel = listDel?.filter(
          (item) => item?.trangthaitt !== 2 && item.type === 'cdha' && item?.infoCD,
        )
        // xóa những chỉ định chưa thu
        if (listDvDel.length > 0)
          for (let dv of listDvDel) {
            yield call(() => recieveService.deleteDichVuBN(dv?.infoCD?.idbndv))
            addLog(
              infoUser?.dangNhap.idNguoiDung,
              PkDangNhap.idChiNhanh,
              actionStatus.DELETE,
              logAction.XOA_CHI_DINH_DICH_VU,
              true,
              MENU_CONSTANTS.PHONGKHAM,
              null,
              {
                tenBenhNhan: tenBenhNhan,
                tenDinhVu: dv?.infoCD?.tendichvu,
              },
            )
          }
        if (listXnDel.length > 0)
          for (let xn of listXnDel) {
            yield call(() => recieveService.deleteXetNghiemBN(xn?.infoCD?.idbnxn))
            addLog(
              infoUser?.dangNhap.idNguoiDung,
              PkDangNhap.idChiNhanh,
              actionStatus.DELETE,
              logAction.XOA_CHI_DINH_DICH_VU_XET_NGHIEM,
              true,
              MENU_CONSTANTS.PHONGKHAM,
              null,
              {
                tenBenhNhan: tenBenhNhan,
                tenDinhVu: xn?.infoCD?.tenxn,
              },
            )
          }
        if (listCdhaDel.length > 0)
          for (let cdhn of listCdhaDel) {
            yield call(() => recieveService.deleteCDHABN(cdhn?.infoCD?.idbncdha))
            addLog(
              infoUser?.dangNhap.idNguoiDung,
              PkDangNhap.idChiNhanh,
              actionStatus.DELETE,
              logAction.XOA_CHI_DINH_DICH_VU_CDHA,
              true,
              MENU_CONSTANTS.PHONGKHAM,
              null,
              {
                tenBenhNhan: tenBenhNhan,
                tenDinhVu: cdhn?.infoCD?.tencdha,
              },
            )
          }

        // thêm những chỉ định mới
        if (dvArray?.length > 0) {
          yield call(() => recieveService.postBNDV(dvArray)) //childrenClinicService.putBenhNhanListDVBacSi(idbnttc, dvArray)) //recieveService.postBNDV(dvArray))

          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.THEM_CHI_DINH_DICH_VU,
            true,
            MENU_CONSTANTS.PHONGKHAM,
            null,
            {
              tenBenhNhan: tenBenhNhan,
              tenDinhVu: dvArray.map((item) => item.tenDinhVu),
            },
          )
        }
        if (xnArray?.length > 0) {
          yield call(() => recieveService.postBNXetNghiem(xnArray)) //childrenClinicService.putBenhNhanListXNBacSi(idbnttc, xnArray)) //recieveService.postBNXetNghiem(xnArray))

          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.THEM_CHI_DINH_DICH_VU_XET_NGHIEM,
            true,
            MENU_CONSTANTS.PHONGKHAM,
            null,
            {
              tenBenhNhan: tenBenhNhan,
              tenDinhVu: xnArray.map((item) => item.tenDinhVu),
            },
          )
        }
        if (cdhaArray?.length > 0) {
          yield call(() => recieveService.postBNCDHA(cdhaArray)) //childrenClinicService.putBenhNhanListCDHABacSi(idbnttc, cdhaArray)) //recieveService.postBNCDHA(cdhaArray))
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.THEM_CHI_DINH_DICH_VU_CDHA,
            true,
            MENU_CONSTANTS.PHONGKHAM,
            null,
            {
              tenBenhNhan: tenBenhNhan,
              tenDinhVu: cdhaArray.map((item) => item.tenDinhVu),
            },
          )
        }

        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        yield call(() =>
          generatePhieuChiDinh(infoUser, DetailBN, ValuePrinter, PkDangNhap, chandoanbandau),
        )
        handleReset()
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: null,
        })

        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
      } catch (error) {
        console.log(error)
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const { infoUser } = yield select((state) => state.userReducer)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.THEM_CHI_DINH_DICH_VU,
          false,
          MENU_CONSTANTS.PHONGKHAM,
          null,
          detailErrorPayload,
        )
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
      }
    },
  )
  // LẤY DANH SÁCH BỆNH NHÂN ĐANG KHÁM VÀ ĐÃ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_BN_DA_KHAM_BY_PK,
    function* BNDaKham({ type, idnv, idcn, tuNgay, denNgay }) {
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const listDangKham = yield call(() =>
          childrenClinicService.getBNDangKham(idnv, idcn, tuNgay, denNgay),
        )
        const listDaKham = yield call(() =>
          childrenClinicService.getBNDaKham(idnv, idcn, tuNgay, denNgay),
        )
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DANH_SACH_BENH_NHAN_DANG_KHAM_VA_DA_KHAM_THEO_PHONGKHAM,
          true,
          MENU_CONSTANTS.PHONGKHAM,
          null,
          {
            tuNgay: tuNgay,
            denNgay: denNgay,
          },
        )
        yield put({
          type: typeAction.DISPATCH_BN_DANG_KHAM,
          payload: listDangKham.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_KHAM,
          payload: listDaKham.data,
        })
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DANH_SACH_BENH_NHAN_DANG_KHAM_VA_DA_KHAM_THEO_PHONGKHAM,
          true,
          MENU_CONSTANTS.PHONGKHAM,
          null,
          detailErrorPayload,
        )
      }
    },
  )

  // bác sĩ sửa toa đã kê, chọn không kê toa hoặc xóa hết thuốc trong toa đã kê
  yield takeLatest(
    typeAction.DELETE_BN_THUOC_BS_KHONG_KE_TOA,
    function* deleteBNThuocBSKhongKeToa({
      type,
      form,
      formBenhNhan,
      idbnttc,
      idlydokhonglaythuoc,
    }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const infoDetailToaThuoc = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
        if (infoDetailToaThuoc?.data?.DANHANTOA === 1) {
          yield put({
            type: typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM,
            payload: infoDetailToaThuoc?.data,
          })
          Toast.fire({
            icon: 'error',
            title: 'Dược sĩ đang thao tác!',
          })
          yield put({
            type: typeAction.CLOSE_LOADING_PAGE,
          })
          return
        }
        yield call(() => childrenClinicService.putInfoBN(formBenhNhan))
        yield call(() => childrenClinicService.deleteBNThuocBSKhongKeToa(idbnttc))
        yield call(() =>
          childrenClinicService.updateDetailBN({
            ...form,
            giorapk: moment().format('YYYY-MM-DDTHH:mm:ss'),
          }),
        )
        yield call(() => childrenClinicService.putLyDoKhongKeToaBNTTC(idbnttc, idlydokhonglaythuoc))
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.NGAY_THUOC_NHI,
          payload: 2,
        })
        yield put({
          type: typeAction.RESET_TOA_THUOC_BN_DOCTOR,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
      } catch (error) {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
        console.log(error)
      }
    },
  )
  // bác sĩ sửa toa đã kê, kê lại toa đã lưu
  yield takeLatest(
    typeAction.PUT_BN_THUOC_BS_KE_TOA_LAI,
    function* updateBNThuocBSKeToaLai({
      type,
      form,
      formBenhNhan,
      idbnttc,
      list,
      infobn,
      bnttc,
      pkdangnhap,
      isPrint,
    }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })

        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const infoDetailToaThuoc = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
        if (infoDetailToaThuoc.data?.DANHANTOA === 1) {
          yield put({
            type: typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM,
            payload: infoDetailToaThuoc.data,
          })
          Toast.fire({
            icon: 'error',
            title: 'Dược sĩ đang thao tác!',
          })
          yield put({
            type: typeAction.CLOSE_LOADING_PAGE,
          })
          return
        }
        //-----------update bệnh nhân thông rtin chung ----------//
        const { data: bnttcNew } = yield call(() =>
          childrenClinicService.updateDetailBN({
            ...form,
            giorapk: moment().format('YYYY-MM-DDTHH:mm:ss'),
          }),
        )
        const BNTTCPrint = { ...bnttc, ...bnttcNew }
        //------------ update  lại info bệnh nhân ---------//
        yield call(() => childrenClinicService.putInfoBN(formBenhNhan))
        let listThuoc = null
        if (bnttc?.xuatvacxin) {
          // kê lại vắc xin
          listThuoc = yield call(() =>
            childrenClinicService.putBNThuocBSKeToaLaiVacXin(idbnttc, list),
          )
          if (isPrint && listThuoc?.data?.length > 0) {
            yield call(() =>
              generateKhamBenhXuatVacXin(
                infobn,
                BNTTCPrint,
                listThuoc?.data?.sort((a, b) => (a?.stt ?? '').localeCompare(b?.stt ?? '')),
                pkdangnhap,
              ),
            )
            if (PkDangNhap.idChuyenKhoa === 3) {
              //lao
              yield call(() => generatePhieuKiemTruocTiemChungLao(BNTTCPrint?.formPrint))
            } else if (PkDangNhap.idChuyenKhoa === 2) {
              // san
              yield call(() => generatePhieuKiemTruocTiemChungSan(BNTTCPrint?.formPrint))
            } else {
              // nhi
              yield call(() => generatePhieuKiemTruocTiemChung(BNTTCPrint?.formPrint))
            }
          }
        } else {
          // kê lại toa thuốc bthg
          listThuoc = yield call(() =>
            childrenClinicService.putToaThuocBsTuLaySoLo(idbnttc, pkdangnhap?.idChiNhanh, list),
          )
          if (isPrint && listThuoc?.data?.length > 0) {
            yield call(() => {
              let listVT = []
              // gộp các dòng khác số lô hạn dùng lại thành 1 dòng
              listThuoc?.data
                ?.sort((a, b) => (a?.stt ?? '').localeCompare(b?.stt ?? ''))
                ?.filter((item) => item?.idnhomthuoc !== 179 && item?.idnhomthuoc !== 205)
                ?.map((item) => {
                  if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
                    listVT?.map(
                      (itemVT) =>
                        itemVT?.idthuoc === item?.idthuoc && (itemVT.soLuong += item.soLuong),
                    )
                  } else {
                    listVT.push({
                      ...item,
                    })
                  }
                })
              generatePhieuToaThuoc(infobn, BNTTCPrint, listVT, pkdangnhap)
            })
          }
        }

        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.NGAY_THUOC_NHI,
          payload: 2,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.RESET_TOA_THUOC_BN_DOCTOR,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu toa thành công!',
        })
      } catch (error) {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Lưu toa thất bại!',
        })
        console.log(error)
      }
    },
  )
}
