import { Tabs } from 'antd'
import React from 'react'
import DichVu from './DichVu/DichVu'
import ChanDoanHA from './ChanDoanHA/ChanDoanHA'
import XetNghiem from './XetNghiem/XetNghiem'

const DinhMucVTTH = () => {
  return (
    <div className='bg-[#EFEFEF] p-2 h-full'>
      <div className='p-2 rounded-lg border bg-white h-full'>
        <Tabs
          items={[
            {
              label: 'Dịch vụ',
              key: 1,
              children:<DichVu/>
            },
            {
              label: 'Xét Nghiệm',
              key: 2,
              children:<XetNghiem/>
            },
            {
              label: 'CĐHA',
              key: 3,
              children:<ChanDoanHA/>
            },
          ]}
        />
      </div>
    </div>
  )
}

export default DinhMucVTTH
