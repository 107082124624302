import { ConfigProvider, Table } from 'antd'
import moment from 'moment'
import React, { memo } from 'react'
import 'moment/locale/vi'
import styles from './style.module.css'
moment.locale('vi')
const HistoryTongQuat = memo(({ historyKham }) => {
  return (
    <div className="py-3 h-[66vh]">
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            borderRadius: 0,
            fontSize: 13,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          tableLayout="auto"
          scroll={{
            x: historyKham.length !== 0 ? 'max-content' : 1600,
            y: '58vh',
          }}
          pagination={false}
          dataSource={historyKham}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 1,
              render: (text, _, index) => ++index,
              width: 40,
              align: 'center',
              fixed:'left',
            },
            {
              title: 'Ngày Khám',
              dataIndex: 'ngaykham',
              key: 2,
              width: 90,
              fixed:'left',
              render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
            },
            {
              title: 'Tái khám',
              dataIndex: 'ngaytaikham',
              key: 3,
              width: 80,
              fixed:'left',
              render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
            },
            {
              title: 'Loại khám',
              dataIndex: 'tenloaikham',
              key: 4,
              fixed:'left',
              render: (text) => <p className="min-w-[5rem]">{text}</p>,
            },
            {
              title: 'Ngày thuốc',
              dataIndex: 'ngaythuoc',
              key: 5,
              align: 'center',
              render: (text) => <p className="min-w-[5rem]">{text}</p>,
              className: styles.minWidthColumn,
            },
            { title: 'Cân nặng', dataIndex: 'cannang', key: 6, width: 80, align: 'center' },
            { title: 'Nhiệt độ', dataIndex: 'nhietdo', key: 7, width: 80, align: 'center' },
            {
              title: 'Lý do khám',
              dataIndex: 'lydokham',
              key: 10,
              render: (text) => <p className="min-w-[5rem]">{text}</p>,
            },
            {
              title: 'Bệnh sử',
              dataIndex: 'benhsu',
              key: 11,
              render: (text) => <p className="min-w-[5rem]">{text}</p>,
            },
            {
              title: 'Triệu chứng',
              dataIndex: 'trieuchung',
              key: 12,
              className: styles.minWidthColumn,
              render: (text) => <p className="min-w-[5rem]">{text}</p>,
            },
            {
              title: 'Chẩn đoán',
              dataIndex: 'chandoan',
              key: 8,
              render: (text) => <p className="min-w-[5rem]">{text}</p>,
            },
            {
              title: 'Toa thuốc',
              dataIndex: 'danhSachThuocDTOs',
              key: 9,
              render: (text) => {
                const toaThuoc = text
                  .filter((item) => item.tenbietduoc)
                  .map((item) => item.tenbietduoc + ` ( ${item.soluong} ${item.dvt} )`)
                  .join(', ')
                return toaThuoc
              },
              onCell: (record, rowIndex) => ({
                style: {
                  width: record?.danhSachThuocDTOs.length > 0 ? 100 : 400,
                },
              }),
            },
            {
              title: 'Cận lâm sàng',
              dataIndex: 'lichSuCLSDTOs',
              key: 10,
              render: (text) => {
                const cls = text
                  .filter((item) => item.tencls) // Lọc các item có "tencls"
                  .map((item, index) => <li key={index}>- {item.tencls}</li>)
                return <ul style={{ margin: 0 }}>{cls}</ul>
              },
              onCell: (record, rowIndex) => ({
                style: {
                  width: record?.lichSuCLSDTOs.length > 0 ? 100 : 400,
                },
              }),
            },
            {
              title: 'Lâm sàng',
              dataIndex: 'lichSuLSDTOs',
              key: 11,
              render: (text) => {
                const ls = text
                  .filter((item) => item.tencls) // Lọc các item có "tencls"
                  .map((item, index) => <li key={index}>- {item.tencls}</li>)
                return <ul style={{ margin: 0 }}>{ls}</ul>
              },
              onCell: (record, rowIndex) => ({
                style: {
                  width: record?.lichSuLSDTOs.length > 0 ? 100 : 400,
                },
              }),
            },
            {
              title: 'Lời dặn',
              dataIndex: 'loidan',
              key: 13,
              onCell: (record, rowIndex) => ({
                style: {
                  width: record?.loidan ? 500 : 90,
                },
              }),
            },
            { title: 'Bác sĩ', dataIndex: 'bskham', key: 14 },
            { title: 'Chi  nhánh', dataIndex: 'tenchinhanh', key: 15 },
            // { title: 'Tiền sử bản thân', dataIndex: 'tiensu', key: 16 },
            // { title: 'Dị ứng', dataIndex: 'tenchinhanh', key: 17 },
            {
              title: 'ghi chú',
              dataIndex: 'ghichu',
              key: 18,
              className: styles.minWidthColumn,
            },
          ]}
          bordered
        />
      </ConfigProvider>
    </div>
  )
})

export default HistoryTongQuat
