import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  NgayNghis: [],
  infoNgayNghi: null,
}

const NgayNghiReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_NGAY_NGHIS:
        draft.NgayNghis = payload
        break
      case typeAction.DISPATCH_NGAY_NGHI_BY_ID:
        draft.infoNgayNghi = payload
        break
      default:
        return state
    }
  })
}

export default NgayNghiReducer
