import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import { dichVuService } from '../../services/dichVu/dichVuService'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
export function* hoaHongSaga() {
  // THÊM GIÁ VẬT TƯ CHO DỊCH VỤ THỦ THUẬT ĐỂ TÍNH HOA HỒNG
  yield takeLatest(
    typeAction.POST_GIAVATTU_DV_THUTHUAT_HOAHONG,
    function* postGiaVatTuDVThuThuatSaga({ type, form, resetForm }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => dichVuService.postGiaVatTuChoDVThuThuat(form))
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.THEM_MOI_HOA_HONG,
          true,
          MENU_CONSTANTS.HOA_HONG,
          null,
          {
            form,
          },
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message
            ? `${error?.response?.data?.message}`
            : 'Thao tác thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.HOA_HONG,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // CHỈNH SỬA GIÁ VẬT TƯ CHO DỊCH VỤ THỦ THUẬT ĐỂ TÍNH HOA HỒNG
  yield takeLatest(
    typeAction.PUT_GIAVATTU_DV_THUTHUAT_HOAHONG,
    function* putGiaVatTuDVThuThuatSaga({ type, form, resetForm }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => dichVuService.putGiaVatTuChoDVThuThuat(form))
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_SUA_HOA_HONG,
          true,
          MENU_CONSTANTS.HOA_HONG,
          null,
          {
            form,
          },
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.HOA_HONG,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // XÓA GIÁ VẬT TƯ CHO DỊCH VỤ THỦ THUẬT ĐỂ TÍNH HOA HỒNG
  yield takeLatest(
    typeAction.DELETE_GIAVATTU_DV_THUTHUAT_HOAHONG,
    function* deleteGiaVatTuChoDVThuThuatSaga({ type, idhh, onLoad }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => dichVuService.deleteGiaVatTuChoDVThuThuat(idhh))
        yield call(() => onLoad())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          logAction.XOA_HOA_HONG,
          true,
          MENU_CONSTANTS.HOA_HONG,
          null,
          {
            ma: idhh,
          },
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.HOA_HONG,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
