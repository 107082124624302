import React, { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import { Input, Select, Button, DatePicker, TreeSelect } from 'antd'
import { SearchOutlined, FilterOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import TableThuoc from './TableThuoc/TableThuoc'
import TableTheKho from './TableTheKho/TableTheKho'
import {
  branchTonKhoAction,
  fetchGroupsTheKho,
  fetchKhoTheKhoByBranch,
  filterTheKho,
  listTypesThuocVT_Action,
} from '../../../store/actions/thuocVTAction'
import moment from 'moment'
import dayjs from 'dayjs'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import 'moment/locale/vi'
import { khoVTservice } from '../../../services/khoVT/khoVTservice'
import { branchService } from '../../../services/branch/branchService'
import { https } from '../../../services/apiService'
import { reportService } from '../../../services/Report/reportService'
import { chuyenKhoService } from '../../../services/ChuyenKho/chuyenKhoService'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import { getListNameChiNhanh } from '../../../utils/algorithm'
import { addLog } from '../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../utils/log/logConstant'

moment.locale('vi')
const today = moment()
const formatDate = 'DD/MM/YYYY'
const NEW_STATE_DATA = {
  data: [],
  totalCount: 0,
  totalPage: 1,
}
const { SHOW_PARENT } = TreeSelect

const TheKho = () => {
  const dispatch = useDispatch()
  const { listCompany } = useSelector((state) => state.companyReducer)
  const id_company = Number(localStorage.getItem('id_company'))
  const idBranchDefault = Number(localStorage.getItem('BRANH_LOGIN'))
  const { historyTheKho } = useSelector((state) => state.thuocVTReducer)
  const ROLE_KHO_VIEWS = [41, 107, 108, 68, 61, 69, 225, 125, 136, 147, 255, 187, 197] // chỉ xem được các loại kho này
  const ID_NHOM_LE_TAN = 3
  const { infoUser } = useSelector((state) => state.userReducer)
  //------------ useHook -------//
  const [branch, setBranch] = useState(idBranchDefault)
  const [infoThuoc, setInfoThuoc] = useState(null)
  const [khoCN, setKhoCN] = useState(null)
  const [isloading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(null)
  const [dateFrom, setDateForm] = useState(dayjs().startOf('month').format('YYYY-MM-DD'))
  const [dateTo, setDateTo] = useState(today.format('YYYY-MM-DD'))
  const [type, setType] = useState(null)
  const [group, setGroup] = useState(null)
  const [page, setPage] = useState(0)
  const [listThuocVT, setListThuocVT] = useState(NEW_STATE_DATA)
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [chuyenKhoa, setChuyenKhoa] = useState(null)
  const [company, setCompany] = useState(id_company)
  const [idChiNhanh, setIdChiNhanh] = useState([idBranchDefault])
  const [branchTonKho, setBranchTonKho] = useState([])
  const [listKho, setListKho] = useState([])
  const [searchValues, setSearchValues] = useState({})
  const [valueExport, setValueExport] = useState([])

  const maQuyenXemDs = 'QHT207'
  const maQuyenXuatExcel = 'QHT208'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        dispatch(filterTheKho(infoNew?.filter, setIsLoading, infoNew?.tenThuoc))
        break
      case maQuyenXuatExcel:
        exportToExcel()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXuatExcel:
        //
        break
      default:
        return false
    }
  }

  //------------ STORE REDUCER -----------//
  const { typesVT, groupsTheKho } = useSelector((state) => state.thuocVTReducer)
  //---------- chọn loại thuốc vật tư -----------//
  const SelectedType = (value) => {
    setType(value)
    dispatch(fetchGroupsTheKho(value))
    setGroup(null)
  }
  //------------- chọn nhón thuốc vật tư --------//
  const selectedGroup = (value) => {
    setGroup(value)
  }
  //------------- search thuốc vật tư ------------//
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      setPage(1)
      loadMoreData(true, keyword, 1)
    }, 300),
    [],
  )
  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)
    debounceDropDown(value)
  }
  //---------- click tìm kiếm ----------- //
  const onClickSearch = () => {
    setPage(1)
    loadMoreData(true, search, 1)
  }
  //----------- lấy dữ liệu data thuốc vật tư--------//
  const fetchData = async (keyword, page) => {
    const { data } = await khoVTservice.SearchByCondition(keyword, id_company, type, group, page)
    return data
  }
  const loadMoreData = async (reset, keyword, page) => {
    if (listThuocVT.totalPage < page) return // NẾU DỮ LIỆU CHỈ CÓ 1 PAGE THÌ DỪNG CALL API
    setLoading(true)
    const newData = await fetchData(keyword, page) // Hàm fetchData sẽ gọi API với trang hiện tại
    setListThuocVT((prevState) => ({
      ...prevState,
      data: reset ? newData.data : [...prevState.data, ...newData.data],
      totalCount: newData.totalCount,
      totalPage: newData.totalPage,
    }))
    setPage((prevPage) => prevPage + 1)
    setLoading(false)
  }
  //------------ chọn chi nhánh tìm kiêm -----------//
  const selectedBranch = (value) => {
    setBranch(value)
    dispatch(fetchKhoTheKhoByBranch(value))
  }

  //------------ hiện thị kho làn đầu -----------//
  useEffect(() => {
    selectedBranch(idBranchDefault)
  }, [])

  //---------------- datePicker ----------//
  const selectedDatefrom = (date, dateString) => {
    setDateForm(moment(dateString, formatDate).format('YYYY-MM-DD'))
  }
  const selectedDateTo = (date, dateString) => {
    setDateTo(moment(dateString, formatDate).format('YYYY-MM-DD'))
  }
  // -------------- filter danh sách kho ------//
  const onClickFilter = (id, tenThuoc) => {
    console.log(tenThuoc)
    let idchiNhanhFormat = ''
    if (idChiNhanh[0] !== 'all') {
      idchiNhanhFormat = idChiNhanh?.map((id) => `&idcn=${id}`).join('&')
    }
    const filter = {
      IDCT: company,
      branch: idchiNhanhFormat,
      kho: idChiNhanh.length > 1 ? '' : khoCN, // chỉ chọn 1 chi nhánh thì mới có kho
      dateFrom,
      dateTo,
      idThuoc: id,
      IDChuyenKhoa: chuyenKhoa,
      nameCompany: listCompany.find((item) => item.idct === company)?.tenct,
      nameChiNhanh: getListNameChiNhanh(idChiNhanh, branchTonKho),
      tenThuoc,
    }
    dispatch(
      putCheckQuyenThaoTacAction(maQuyenXemDs, { filter: filter }, actionIsTrue, actionIsFalse),
    )
  }
  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (idct, value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(idct, value)
      setBranchTonKho(data)
    } catch (error) {
      console.log(error)
    }
  }
  //---------xuất excel -------//
  const exportToExcel = () => {
    try {
      const formatData = valueExport?.map((item) => ({
        Ngày: item.ngay ? moment(item.ngay).format('DD/MM/YYYY') : '-',
        Phiếu: item.maPhieu,
        Loại: item.loai,
        'Chi nhánh chuyển': item.tenChiNhanhXuat,
        'Kho chuyển': item.tenKhoXuat,
        'Chi nhánh nhập': item.tenChiNhanhNhap,
        'Kho nhập': item.tenKhoNhap,
        'SL nhập': item.soLuongNhap || 0,
        'SL xuất': item.soLuongXuat || 0,
        'SL tồn': item.tonKho || 0,
        'Số lô': item.soLo,
        'Số hoá đơn': item.soHoaDon,
        'Hạn dùng': item.hanDung ? moment(item.hanDung).toDate() : '-',
      }))
      const name = 'Thẻ kho'
      formatData?.length && exportExcelformat(formatData, name)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_THE_KHO_BY_THUOC,
        true,
        MENU_CONSTANTS.THE_KHO,
        null,
        {
          dateFrom,
          dateTo,
          nameCompany: listCompany.find((item) => item.idct === company)?.tenct,
          nameChiNhanh: getListNameChiNhanh(idChiNhanh, branchTonKho),
          nameThuoc: infoThuoc?.tenBietDuoc,
        },
      )
    } catch (error) {
      console.log(error)
      const detailErrorPayload = `Có lỗi khi xuất file excel thẻ kho \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.THE_KHO,
        detailErrorPayload,
      )
    }
  }
  // useEffect(() => {
  //   dispatch(
  //     getListThuocVTByPageIndex_Action(search, id_company, type, group, useSearch.get('page')),
  //   )
  // }, [useSearch.get('page')])
  useEffect(() => {
    dispatch(listAllCompanyAction())
    dispatch(branchTonKhoAction(id_company))
    dispatch(listTypesThuocVT_Action())
    loadMoreData(true, search, 1)
  }, [])
  const viewsRoleKho = (data) => {
    // Nếu là lễ tân chỉ lấy kho: VPP, Tài sản, Vận hành
    let newData = []
    if (infoUser?.idNhom !== ID_NHOM_LE_TAN) {
      newData = [
        {
          tenKho: 'Tất cả',
          idKhoCN: null,
        },
        ...data,
      ]
    } else {
      newData = data?.filter((item) => ROLE_KHO_VIEWS.includes(item.idKho))
    }
    return newData.map((item) => ({
      label: item.tenKho,
      value: item.idKhoCN,
    }))
  }

  useEffect(() => {
    if (listKho?.length !== 0 && infoUser) {
      // setKho(viewsRoleKho(listKho)[0].value)
    }
  }, [listKho, infoUser])

  const getListChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setListChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getListChuyenKhoa()
  }, [])

  useEffect(() => {
    dispatch(listAllCompanyAction())
  }, [])

  const fetchBranch = async (id) => {
    try {
      const { data } = await branchService.getListBranch(id)
      setBranchTonKho(data)
    } catch (error) {
      console.log(error)
    }
  }

  const getKhosByIDBranch = async (id) => {
    try {
      const { data } = await chuyenKhoService.getlistKhoNhan(id)
      console.table(data)

      setListKho(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCompany = (value) => {
    setCompany(value)
    fetchBranch(value)
    setIdChiNhanh(['all'])
    setChuyenKhoa(null)
  }
  // xử lý chọn chi nhánh lấy danh sách kho
  useEffect(() => {
    if (idChiNhanh[0] !== 'all' && idChiNhanh.length === 1) {
      getKhosByIDBranch(idChiNhanh[0])
    }
  }, [idChiNhanh])
  useEffect(() => {
    fetchBranch(id_company)
    getListChuyenKhoa()
  }, [])

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branchTonKho?.map((branch) => ({
        label: branch.tenChiNhanh,
        value: branch.idChiNhanh,
      })),
    },
  ]

  const onChange = (newValue) => {
    setIdChiNhanh(newValue)
  }
  const tProps = {
    treeData,
    value: idChiNhanh,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.label && treeNode.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  return (
    <>
      <div className="h-full bg-[#EFEFEF] ">
        <div className="p-3 h-full">
          <div className=" h-full  bg-white border rounded-lg flex">
            <div className="h-full  w-1/3 border-r">
              <div className="p-2 h-full">
                <div className="border h-full rounded">
                  <div className="p-2 border-b">
                    <div className="flex gap-1">
                      <div className="w-1/2">
                        <label className="font-semibold h-5 w-full"> Loại:</label>
                        <Select
                          className="w-full"
                          placeholder="Loại hàng hoá"
                          onChange={SelectedType}
                          value={type}
                          options={[
                            { label: 'Tất cả', value: null },
                            ...(typesVT?.map((items) => ({
                              label: items.phanLoai,
                              value: items.idPhanLoai,
                            })) ?? []),
                          ]}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="font-semibold h-5 w-full">Nhóm :</label>
                        <Select
                          className="w-full"
                          onChange={selectedGroup}
                          value={group}
                          options={[
                            { label: 'Tất cả', value: null },
                            ...(groupsTheKho?.map((items) => ({
                              label: items.nhom,
                              value: items.idNhom,
                            })) ?? []),
                          ]}
                        />
                      </div>
                    </div>
                    <div className="flex gap-1 mt-2">
                      <Input onChange={handleSearch} placeholder="Nhập từ khoá tìm kiếm" />
                      <Button type="primary" onClick={onClickSearch} icon={<SearchOutlined />}>
                        Tìm
                      </Button>
                    </div>
                  </div>
                  <div>
                    <TableThuoc
                      search={search}
                      loadMoreData={loadMoreData}
                      loading={loading}
                      page={page}
                      onClickFilter={onClickFilter}
                      setInfoThuoc={setInfoThuoc}
                      listThuocVT={listThuocVT}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-2/3 h-full">
              <div className="p-2">
                <div className="flex justify-between">
                  <div className="flex gap-2 items-center flex-wrap">
                    <div className="flex gap-2 items-center">
                      <p className="font-semibold">Công ty:</p>
                      <Select
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        showSearch
                        onChange={handleCompany}
                        value={company}
                        options={listCompany?.map(({ idct, tenct }) => ({
                          label: tenct,
                          value: idct,
                        }))}
                        className="w-60"
                      />
                    </div>
                    <div className="flex gap-2 items-center">
                      <p className="font-semibold">Chuyên khoa:</p>
                      <Select
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        showSearch
                        onChange={(value) => {
                          setChuyenKhoa(value)
                          fetchBranchByChuyenKhoa(company, value)
                          setIdChiNhanh(['all'])
                        }}
                        value={chuyenKhoa}
                        options={[
                          { label: 'Tất cả', value: null },
                          ...listChuyenKhoa?.map((item) => ({
                            label: item.tenChuyenKhoa,
                            value: item.idChuyenKhoa,
                          })),
                        ]}
                        className="w-44"
                      />
                    </div>
                    <div className="flex gap-2 items-center">
                      <p className="font-semibold">Chi nhánh:</p>
                      <TreeSelect
                        {...tProps}
                        style={{ flexWrap: 'unset' }}
                        className="w-60 h-8 overflow-clip"
                      />
                    </div>
                    <div className="flex gap-2 items-center">
                      <p className="font-semibold">Kho:</p>
                      <Select
                        disabled={idChiNhanh.length !== 1 || idChiNhanh[0] === 'all'} // chọn nhiều chi nhánh không thể chọn kho
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        value={khoCN}
                        showSearch
                        onChange={(value) => setKhoCN(value)}
                        options={viewsRoleKho(listKho)}
                        className="w-60"
                      />
                    </div>

                    <div className="flex gap-2 items-center">
                      <DatePicker
                        onChange={selectedDatefrom}
                        value={dayjs(dateFrom)}
                        format={formatDate}
                        allowClear={false}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                      </svg>
                      <DatePicker
                        onChange={selectedDateTo}
                        value={dayjs(dateTo)}
                        format={formatDate}
                        allowClear={false}
                      />
                    </div>
                  </div>
                  <Button
                    onClick={() => onClickFilter(infoThuoc.idThuoc, infoThuoc.tenBietDuoc)}
                    disabled={!infoThuoc}
                    type="primary"
                    icon={<FilterOutlined />}
                  >
                    Xem
                  </Button>
                  <Button
                    disabled={!valueExport?.length}
                    // onClick={exportToExcel}
                    onClick={() =>
                      dispatch(
                        putCheckQuyenThaoTacAction(
                          maQuyenXuatExcel,
                          null,
                          actionIsTrue,
                          actionIsFalse,
                        ),
                      )
                    }
                    type="text"
                    size="middle"
                    className="text-green-700 "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                      />
                    </svg>
                    Excel
                  </Button>
                </div>

                <div className="flex gap-1 items-center my-2">
                  <label className="w-[100px] font-semibold">Hàng hoá: </label>
                  <Input
                    variant="filled"
                    style={{ color: 'black' }}
                    value={infoThuoc?.maThuoc}
                    // disabled
                    readOnly
                    className="w-36"
                  />
                  <Input
                    readOnly
                    variant="filled"
                    style={{ color: 'black' }}
                    value={infoThuoc?.tenBietDuoc}
                  />
                  <Input
                    readOnly
                    variant="filled"
                    style={{ color: 'black' }}
                    value={infoThuoc?.quyCach}
                    className="w-60"
                  />
                </div>
                <div>
                  <TableTheKho
                    setValueExport={setValueExport}
                    isloading={isloading}
                    searchValues={searchValues}
                    setSearchValues={setSearchValues}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TheKho
