import axios from 'axios'
import React from 'react'
import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { authService } from '../../services/auth/authService'
import Swal from 'sweetalert2'
import { branchService } from '../../services/branch/branchService'
import { menuService } from '../../services/menu/menuService'
import moment from 'moment'
import { generateUUID } from '../../utils/generateUUID'
import { recieveService } from '../../services/receive/recieveService'
import 'moment/locale/vi'
import { add } from 'lodash'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { addLog } from '../../utils/log/apiLog'
moment.locale('vi')

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* customerSaga() {
  //------------- LẤY DANH SÁCH BỆNH NHÂNH ----------/
  yield takeLatest(
    typeAction.GET_CUSTOMER_BY_KEYWORD,
    function* fetchCustomerByKeyword({ type, keyword, idct, setIsLoading }) {
      try {
        setIsLoading(true)
        const result = yield call(() => recieveService.searchBNByIdctAndKeyword(keyword, idct))
        yield put({
          type: typeAction.DISPATCH_CUSTOMER,
          payload: result.data,
        })
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        console.log(err)
      }
    },
  )
  //----------- TẠO  HỒ SƠ BỆNH NHÂN MỚI----------
  yield takeLatest(typeAction.ADD_NEW_CUSTOMER, function* addNew({ type, form, cancelModal }) {
    const now = moment()
    try {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      form.NgayTao = now.format('YYYY-MM-DDTHH:mm:ss')
      const result = yield call(() => recieveService.postNewBN(form))
      cancelModal()
      const detailForLog = {
        Matcqg: form.Matcqg,
        NgayTao: form.NgayTao,
        cccd: form.cccd,
        diaChi: form.diaChi,
        dienThoai: form.dienThoai,
        gioiTinh: form.gioiTinh,
        idChiNhanh: form.idChiNhanh,
        idDanToc: form.idDanToc,
        idDoiTuong: form.idDoiTuong,
        idNgheNghiep: form.idNgheNghiep,
        idPhuong: form.idPhuong,
        idQuan: form.idQuan,
        idQuocTich: form.idQuocTich,
        idTinh: form.idTinh,
        idTrangThai: form.idTrangThai,
        ngayCap: form.ngayCap,
        ngaySinh: form.ngaySinh,
        tenBenhNhan: form.tenBenhNhan,
        tuoi: form.tuoi,
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap?.idChiNhanh,
        actionStatus.GET,
        logAction.TAO_BENH_NHAN_MOI,
        true,
        MENU_CONSTANTS.TRACUU_BENH_NHAN,
        null,
        detailForLog,
      )
      Toast.fire({
        icon: 'success',
        title: 'Tạo bệnh nhân thành công!',
      })
    } catch (err) {
      yield console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'Tạo bệnh nhân thất bại!',
      })
    }
  })

  yield takeLatest(typeAction.GET_CUSTOMER_BY_IDCT, function* getByIdct({ type, idct }) {
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      const { data } = yield call(() => recieveService.getBNbyIdct(idct))
      yield put({
        type: typeAction.DISPATCH_CUSTOMER,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
}
