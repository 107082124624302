import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import CachedIcon from '@mui/icons-material/Cached'
import _, { debounce } from 'lodash'
import IconButton from '@mui/material/IconButton'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { ConfigProvider, Input, Select, Table, Tooltip, Button, Modal, TreeSelect } from 'antd'
import { ContainerOutlined, SearchOutlined, QuestionCircleFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  ListGroupsVTByTypes,
  deleteThuocVT_Action,
  fetchDuongDung,
  getListCountry_Action,
  getListThuocVTByPageIndexTheoListIdNhom_Action,
  infoThuocVT_Action,
  listTypesThuocVT_Action,
} from '../../../store/actions/thuocVTAction'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import ModalAdd from './Modal/ModalAdd'
import ModalDetail from './Modal/ModalDetail'
import { getlistDoitac } from '../../../store/actions/NhapKhoAction'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import { https } from '../../../services/apiService'
import { columns } from './columns'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../utils/log/logConstant'
import { addLog } from '../../../utils/log/apiLog'
import { getListNameNhomHang } from '../../../utils/algorithm'

const { SHOW_PARENT } = TreeSelect
const ThuaMua = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const id_company = Number(localStorage.getItem('id_company'))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [types, setTypes] = useState(null)
  const [company, setCompany] = useState(id_company)
  const [valueGroups, SetValueGroups] = useState(null)
  const [isModalDetail, setIsModalDetail] = useState(false)
  const [search, setSearch] = useState(null)
  const [searchValues, setSearchValues] = useState({})
  const [page, setPage] = useState(1)
  const { HangHoaThuoc, typesVT, groupsVT } = useSelector((state) => state.thuocVTReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [loadingExport, setLoadingExport] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [value, setValue] = useState([])
  const treeData = [
    {
      title: 'Tất cả nhóm hàng',
      value: 'all',
      key: 'all',
      children: groupsVT
        ?.map((item, index) => ({
          title: item?.nhom,
          value: item?.idNhom,
          key: `${item?.idNhom}`,
        }))
        ?.sort((a, b) => (a?.title ?? '').localeCompare(b?.title ?? '')),
    },
  ]

  const maQuyenXemDs = 'QHT158'
  const maQuyenXemCt = 'QHT162'
  const maQuyenNhapExcel = 'QHT159'
  const maQuyenXuatExcel = 'QHT160'
  const maQuyenThemVt = 'QHT161'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        dispatch(
          getListThuocVTByPageIndexTheoListIdNhom_Action(
            infoNew?.search,
            infoNew?.company,
            infoNew?.types,
            infoNew?.listIdNhom, // valueGroups, 1 id
            infoNew?.page,
            infoNew?.reset,
            setIsLoading,
            setTotalPages,
            {
              nameCompany,
              nameLoaiThuocVT,
              nameNhomThuocVT,
            },
          ),
        )
        break
      case maQuyenXemCt:
        handleInfoKhoVT(infoNew?.idThuoc)
        break
      case maQuyenNhapExcel:
        navigate('/thumuakho/thuocVT/import')
        break
      case maQuyenXuatExcel:
        exportToExcel()
        break
      case maQuyenThemVt:
        showModal()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXemCt:
        //
        break
      case maQuyenNhapExcel:
        //
        break
      case maQuyenXuatExcel:
        //
        break
      case maQuyenThemVt:
        setIsModalOpen(false)
        break
      default:
        return false
    }
  }

  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    // disabled: !types,
    treeData: types ? treeData : [],
    value: types ? value : [],
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Nhóm Thuốc Vật tư',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const CancelModalDetail = () => {
    setIsModalDetail(false)
  }
  const showModalDetail = () => {
    setIsModalDetail(true)
  }
  const handleInfoKhoVT = (id) => {
    dispatch(infoThuocVT_Action(id))
    showModalDetail()
  }
  const { confirm } = Modal
  const showDeleteInfoKhoVT = (idThuoc, maThuoc, tenBietDuoc) => {
    confirm({
      title: 'Bạn có muốn xóa Thuốc-Vật Tư này ?',
      icon: <QuestionCircleFilled />,
      content: (
        <p>
          Bạn xác nhận xóa:
          <br />
          {maThuoc} - {tenBietDuoc}
        </p>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteById(idThuoc)
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
  }
  const debounceDropDown = useCallback(
    _.debounce(async (keyword, company, types, valueGroups, page, reset) => {
      fetchDataSource(reset, keyword, page)
      // let listIdNhom = value
      // if (!value || !value.length || value?.includes('all')) {
      //   setValue(['all'])
      //   listIdNhom = await groupsVT?.map((item) => item?.idNhom)
      // }
      // dispatch(
      //   getListThuocVTByPageIndexTheoListIdNhom_Action(
      //     keyword,
      //     company,
      //     types,
      //     listIdNhom, // valueGroups, 1 id
      //     page,
      //     reset,
      //     setIsLoading,
      //     setTotalPages,
      //   ),
      // )
    }, 300),
    [],
  )
  const handleCompany = (value) => {
    setCompany(value)
    loadMoreData(true, search, 1)
  }
  const handleSearchInput = (e) => {
    const { value } = e.target
    setPage(1)
    debounceDropDown(value, company, types, valueGroups, 1, true)
    setSearch(value)
  }
  const exportToExcel = async () => {
    setLoadingExport(true)
    try {
      const response = await https.get(`ThuocVatTu/downloadAll?idCT=${company}`, {
        responseType: 'blob', // Important
      })

      // Create a Blob from the response
      const blob = new Blob([response.data], { type: 'application/octet-stream' })
      // Create a download link and click it programmatically
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Thuốc vật tư.xlsx')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_DS_THUOC_VAT_TU,
        true,
        MENU_CONSTANTS.THUOC_VAT_TU,
        null,
        {
          nameCompany,
        },
      )
    } catch (error) {
      console.error('Error downloading file:', error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.THUOC_VAT_TU,
        detailErrorPayload,
      )
    } finally {
      setLoadingExport(false)
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false) // close modal detail groups VT
  }
  const handleDeleteById = async (id) => {
    // * Truyền vào idThuoc vật tư muốn xóa
    // *  Lấy lại danh sách thuốc vật tư theo page
    await dispatch(deleteThuocVT_Action(id))
    await getListThuocVT()
  }
  const getListThuocVT = () => {
    setPage(1)
    loadMoreData(true, search, 1)
  }
  const handleTypesThuocVT = (value) => {
    // get list nhóm thuốc vật tư sau khi chọn loại
    setTypes(value)
    setValue(['all'])
    SetValueGroups(null)
    value && dispatch(ListGroupsVTByTypes(value))
  }
  useEffect(() => {
    dispatch(listTypesThuocVT_Action())
    dispatch(getlistDoitac())
    dispatch(getListCountry_Action())
    dispatch(listAllCompanyAction())
    dispatch(fetchDuongDung())
  }, [])

  useEffect(() => {
    if (infoUser) {
      loadMoreData(true, search, page)
    }
  }, [infoUser])

  const fetchDataSource = (reset, search, page) => {
    let listIdNhom = value
    if (!value || !value.length || value?.includes('all')) {
      setValue(['all'])
      listIdNhom = groupsVT?.map((item) => item?.idNhom)
    }
    dispatch(
      putCheckQuyenThaoTacAction(
        maQuyenXemDs,
        {
          search: search,
          company: company,
          types: types,
          listIdNhom: listIdNhom,
          page: page,
          reset: reset,
        },
        actionIsTrue,
        actionIsFalse,
      ),
    )
  }
  const loadMoreData = async (reset, search, page) => {
    if (totalPages < page) return // NẾU DỮ LIỆU CHỈ CÓ 1 PAGE THÌ DỪNG CALL API
    fetchDataSource(reset, search, page)
    setPage((prevPage) => prevPage + 1)
  }
  const handleScroll = useCallback(
    debounce(() => {
      const tableBody = document.querySelector('.table-thumua .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 100 &&
        !isLoading
      ) {
        loadMoreData(false, search, page)
      }
    }, 300),
    [isLoading],
  )
  useEffect(() => {
    const tableBody = document.querySelector('.table-thumua .ant-table-body')
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])
  useEffect(() => {
    const filterData = () => {
      let filtered = HangHoaThuoc?.data
      Object.keys(searchValues).forEach((key) => {
        if (searchValues[key]) {
          filtered = filtered.filter((item) =>
            item[key]?.toString().toLowerCase().includes(searchValues[key].toLowerCase()),
          )
        }
      })
      filtered = _.sortBy(filtered, (item) => (item.suDung !== 1 ? 1 : 0))
      setFilteredData(filtered)
    }
    filterData()
  }, [searchValues, HangHoaThuoc?.data])

  const nameCompany = useMemo(() => {
    return listCompany?.find((item) => item.idct === company)?.tenct
  }, [company, listCompany])

  const nameLoaiThuocVT = useMemo(() => {
    return typesVT?.find((item) => item.idPhanLoai === types)?.phanLoai
  }, [types, typesVT])

  const nameNhomThuocVT = useMemo(() => {
    return getListNameNhomHang(value, groupsVT)?.join(', ')
  }, [value, groupsVT])

  return (
    <>
      <div className="p-3 bg-[#E7E7E7] h-full">
        <div className="p-2 bg-white rounded-xl border">
          <div className="block xl:flex 2xl:gap-5 gap-1 items-center justify-between">
            <div className="flex 2xl:gap-5 gap-1 items-center">
              <div className="w-60 gap-1 items-center ">
                <Select
                  showSearch
                  className="w-full"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={handleCompany}
                  value={company}
                  options={listCompany?.map((items) => ({
                    label: items.tenct,
                    value: items.idct,
                  }))}
                />
              </div>
              <div className="w-60 gap-1 items-center flex">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  value={types}
                  onChange={handleTypesThuocVT}
                  placeholder="Loại Thuốc Vật tư"
                  className="w-full"
                  options={typesVT
                    ?.filter((item) => item.loai !== 'HANGHOA')
                    .map((items) => ({
                      label: items.phanLoai,
                      value: items.idPhanLoai,
                    }))}
                />
              </div>
              <div className="2xl:w-80 w-60 flex gap-1 items-center">
                <TreeSelect
                  {...tProps}
                  style={{ flexWrap: 'unset' }}
                  className="2xl:w-80 w-60 h-8 overflow-clip"
                  size="medium"
                />
              </div>
              <div>
                <Input
                  value={search}
                  placeholder="Tìm kiếm"
                  onChange={handleSearchInput}
                  prefix={<SearchOutlined />}
                />
              </div>
              <IconButton
                onClick={getListThuocVT}
                color="primary"
                aria-label="add to shopping cart"
              >
                <CachedIcon />
              </IconButton>
            </div>

            <div className="flex items-center 2xl:gap-5 gap-1">
              <div>
                <Button
                  onClick={() =>
                    dispatch(
                      putCheckQuyenThaoTacAction(
                        maQuyenNhapExcel,
                        null,
                        actionIsTrue,
                        actionIsFalse,
                      ),
                    )
                  }
                  variant="outlined"
                  type="primary"
                  ghost
                  icon={<FileUploadIcon />}
                >
                  Import DS
                </Button>
              </div>
              <div className="font-semibold ">
                <Button
                  // onClick={exportToExcel}
                  onClick={() =>
                    dispatch(
                      putCheckQuyenThaoTacAction(
                        maQuyenXuatExcel,
                        null,
                        actionIsTrue,
                        actionIsFalse,
                      ),
                    )
                  }
                  type="text"
                  size="middle"
                  className="text-green-700 p-1"
                  icon={<ImportExportIcon />}
                  loading={loadingExport}
                  disabled={loadingExport}
                >
                  Xuất Excel
                </Button>
              </div>
              <Tooltip title="Thêm" color="green">
                <button
                  // onClick={showModal}
                  onClick={() =>
                    dispatch(
                      putCheckQuyenThaoTacAction(maQuyenThemVt, null, actionIsTrue, actionIsFalse),
                    )
                  }
                  class="group cursor-pointer outline-none hover:rotate-90 duration-300"
                >
                  <svg
                    class="stroke-teal-500 fill-none group-hover:fill-teal-800 group-active:stroke-teal-200 group-active:fill-teal-600 group-active:duration-0 duration-300"
                    viewBox="0 0 24 24"
                    height="25px"
                    width="25px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeWidth="1.5"
                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    ></path>
                    <path strokeWidth="1.5" d="M8 12H16"></path>
                    <path strokeWidth="1.5" d="M12 16V8"></path>
                  </svg>
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="mt-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                  fontSize: 14,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                loading={isLoading}
                pagination={false}
                bordered
                rowClassName={(record) => (record.active !== 1 ? 'text-red-500' : '')}
                className="w-full table-thumua"
                scroll={{
                  x: filteredData !== 0 ? 'max-content' : 2500,
                  y: '75vh',
                }}
                columns={columns(searchValues, setSearchValues, HangHoaThuoc?.data)}
                dataSource={filteredData?.map(
                  (
                    {
                      stt,
                      idThuoc,
                      maThuoc,
                      tenBietDuoc,
                      tenHoatChat,
                      dvt,
                      quyCach,
                      quyCachDongGoi,
                      giaBan,
                      giaMua,
                      maSoDangKy,
                      duongDung,
                      giagpp,
                      barcode,
                      tenphanloai,
                      nhom,
                      suDung,
                    },
                    index,
                  ) => ({
                    stt,
                    maThuoc,
                    barcode,
                    tenBietDuoc,
                    tenHoatChat,
                    dvt,
                    quyCach,
                    tenphanloai,
                    nhom,
                    quyCachDongGoi,
                    giagpp: formatNumberVND(giagpp),
                    giaBan: formatNumberVND(giaBan),
                    giaMua: formatNumberVND(giaMua),
                    maSoDangKy,
                    duongDung,
                    active: suDung,
                    action: (
                      <ul className="flex justify-around">
                        <li>
                          <Tooltip title="Xem chi tiết" color="#108ee9">
                            <ContainerOutlined
                              className="text-xl text-[#108ee9]  cursor-pointer"
                              onClick={() =>
                                dispatch(
                                  putCheckQuyenThaoTacAction(
                                    maQuyenXemCt,
                                    { idThuoc: idThuoc },
                                    actionIsTrue,
                                    actionIsFalse,
                                  ),
                                )
                              }
                            />
                          </Tooltip>
                        </li>
                        {/* <li>
                          <Tooltip title="Xóa" color="rgb(239 68 68)">
                            <CloseOutlined
                              onClick={() => {
                                showDeleteInfoKhoVT(idThuoc, maThuoc, tenBietDuoc)
                              }}
                              className="text-xl text-red-500  cursor-pointer"
                            />
                          </Tooltip>
                        </li>  */}
                      </ul>
                    ),
                  }),
                )}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
      <ModalDetail isModalDetail={isModalDetail} CancelModalDetail={CancelModalDetail} />
      <ModalAdd isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </>
  )
}

export default ThuaMua
