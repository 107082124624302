import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { versionService } from '../../services/version/versionService'
import { addLog } from '../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
// Sagas
export function* versionSaga() {
  //-------------- ADD NEW VERSION ------------//
  yield takeLatest(typeAction.ADD_NEW_VERSION, function* postversion({ type, form, onReset }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      const { data } = yield call(() => versionService.postVersion(form))
      yield onReset()
      Toast.fire({
        icon: 'success',
        title: 'Thêm phiên bản thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.THEM_PHIEN_BAN,
        true,
        MENU_CONSTANTS.PHIEN_BAN,
        null,
        data,
      )
    } catch (err) {
      console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'Thêm phiên bản thất bại!',
      })
      const apiUrl = err?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${err?.response?.data?.message || err?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.PHIEN_BAN,
        detailErrorPayload,
      )
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
  //---------------- DELETE VERSION--------------//
  yield takeLatest(typeAction.DELETE_VERSION_BY_ID, function* deleteVersion({ type, id, onLoad }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      yield call(() => versionService.deleteVersion(id))
      yield onLoad()
      Toast.fire({
        icon: 'success',
        title: 'Xoá phiên bản thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.DELETE,
        logAction.XOA_PHIEN_BAN,
        true,
        MENU_CONSTANTS.PHIEN_BAN,
        null,
        { id },
      )
    } catch (err) {
      console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'Xoá phiên bản thất bại!',
      })
      const apiUrl = err?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${err?.response?.data?.message || err?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.DELETE,
        null,
        false,
        MENU_CONSTANTS.PHIEN_BAN,
        detailErrorPayload,
      )
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
  //---------------- UPDATE VERSION--------------//
  yield takeLatest(typeAction.UPDATE_VERSION, function* updateVersion({ type, form, reload }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      const { data } = yield call(() => versionService.updateVersion(form))
      yield reload(form.idpb)
      Toast.fire({
        icon: 'success',
        title: 'Cập nhâp phiên bản thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.SUA_PHIEN_BAN,
        true,
        MENU_CONSTANTS.PHIEN_BAN,
        null,
        data,
      )
    } catch (err) {
      console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'cập nhâp bản thất bại!',
      })
      const apiUrl = err?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${err?.response?.data?.message || err?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.PHIEN_BAN,
        detailErrorPayload,
      )
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
}
