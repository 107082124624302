import React, { useEffect, useState, useRef, useCallback } from 'react'
import _, { debounce } from 'lodash'
import { ConfigProvider, Select, Table, Checkbox, Input, Space } from 'antd'
import { SearchOutlined, EditOutlined } from '@ant-design/icons'
import { Button, IconButton } from '@mui/material'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import {
  listAllChuyeKhoa_Action,
  listBranchByThuocVT,
  listGroupsVTBranchBuy_action,
  listTypesThuocVT_Action,
  updateBranchBuyTV_Action,
} from '../../../store/actions/thuocVTAction'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import ToastCus from '../../../utils/Toast'
import { https } from '../../../services/apiService'
import { formattedNumber } from '../../../utils/formattedNumber'
import ModalUpdate from './ModalUpdate'
import { addLog } from '../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../utils/log/logConstant'

const BranchBuy = () => {
  const dispatch = useDispatch()
  const [types, setTypes] = useState(null)
  const [nameVT, setNameVT] = useState(null)
  const id_company = Number(localStorage.getItem('id_company'))
  const [company, setCompany] = useState(id_company)
  const [valueGroups, SetValueGroups] = useState(null)
  const [idThuoc, setIdThuoc] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [listBranchBuy, setListBranchBuy] = useState(null)
  const [search, setSearch] = useState(null)
  const searchInput = useRef(null)
  const [valueChuyenKhoa, setValueChuyenKhoa] = useState('')
  const [isLoadingBranch, setIsloadingBranch] = useState(false)
  const [ListUpdate, setListUpdate] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [listThuocVT, setListThuocVT] = useState([])
  const [reloadTrigger, setReloadTrigger] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [update, setUpdate] = useState(null)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { typesVT, branchBuy, groupsVTBranchBuy, chuyenKhoa } = useSelector(
    (state) => state.thuocVTReducer,
  )

  const maQuyenXemDs = 'QHT194'
  const maQuyenCapNhatGia = 'QHT195'
  const maQuyenCapNhatSuDung = 'QHT196'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        fetchData(infoNew?.page)
        break
      case maQuyenCapNhatGia:
        setShow(true)
        break
      case maQuyenCapNhatSuDung:
        handleUpdate()
        break
      default:
        return true
    }
  }

  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        break
      case maQuyenCapNhatGia:
        setShow(false)
        break
      case maQuyenCapNhatSuDung:
        break
      default:
        return false
    }
  }

  const handleBranchBuy = (record) => {
    setNameVT(record.tenBietDuoc)
    setListUpdate([])
    setIdThuoc(record.idThuoc)
    dispatch(
      listBranchByThuocVT(
        record.idThuoc,
        company,
        valueChuyenKhoa,
        setIsloadingBranch,
        record.tenBietDuoc,
      ),
    )
  }
  const onReload = () => {
    const nameThuoc = listThuocVT.find((item) => item.idThuoc === idThuoc)
    dispatch(
      listBranchByThuocVT(
        idThuoc,
        company,
        valueChuyenKhoa,
        setIsloadingBranch,
        nameThuoc?.tenBietDuoc,
      ),
    )
  }
  const handleTypes = (idTypes) => {
    setTypes(idTypes)
    dispatch(listGroupsVTBranchBuy_action(idTypes))
    SetValueGroups(null)
  }
  const handleCompany = (value) => {
    dispatch(listBranchByThuocVT(idThuoc, value, valueChuyenKhoa, setIsloadingBranch))
    setCompany(value)
  }
  const handleGroups = (idGroups) => {
    SetValueGroups(idGroups)
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const addListBranchUpdate = (id, status) => {
    const index = ListUpdate.findIndex((items) => items.idCNBan === id)
    // tìm inde trong danh sách update
    if (index === -1) {
      // nếu không có  => phần update mới
      setListUpdate([...ListUpdate, { idCNBan: id, checkBan: status }])
    } else {
      // nếu có  => phần này bỏ update
      const updatedList = [...ListUpdate]
      updatedList.splice(index, 1)
      setListUpdate(updatedList)
    }
  }
  const handleUpdate = () => {
    const nameThuoc = listThuocVT.find((item) => item.idThuoc === idThuoc)
    dispatch(
      updateBranchBuyTV_Action(ListUpdate, idThuoc, setIsloadingBranch, nameThuoc?.tenBietDuoc),
    )
    setListUpdate([])
  }
  const handleSelected = (value) => {
    if (value === '') {
      setListBranchBuy(branchBuy)
      setValueChuyenKhoa('')
      return
    }
    dispatch(listBranchByThuocVT(idThuoc, company, value, setIsloadingBranch))
    // const result = branchBuy?.filter((items) => items.tenChuyenKhoa === value)
    // setListBranchBuy(result)
    setValueChuyenKhoa(value)
  }
  const handleCheckBox = (id, status) => {
    const updatedBranchBuy = listBranchBuy.map((item) => {
      // tìm chi  nhánh bán
      if (item.idcnban === id) {
        const newObj = { ...item, trangThaiBan: status === 1 ? 0 : 1 } // update lại trạng thái
        addListBranchUpdate(newObj.idcnban, newObj.trangThaiBan)
        return newObj
      }
      return item
    })
    // set lại mãng
    setListBranchBuy(updatedBranchBuy)
  }

  useEffect(() => {
    dispatch(listTypesThuocVT_Action())
    dispatch(listAllChuyeKhoa_Action())
    dispatch(listAllCompanyAction())
  }, [])

  useEffect(() => {
    setListBranchBuy(branchBuy)
  }, [branchBuy])

  const columnsVT = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 40,
      align: 'center',
      key: 'stt',
    },
    {
      title: 'Mã thuốc',
      dataIndex: 'maThuoc',
      width: 120,
      align: 'center',
      key: 'maThuoc',
    },
    {
      title: 'Tên thuốc',
      dataIndex: 'tenBietDuoc',
      key: 'tenBietDuoc',
    },
    {
      title: 'Hoạt chất',
      dataIndex: 'tenHoatChat',
      key: 'tenHoatChat',
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'dvt',
      width: 100,
      align: 'center',
      key: 'dvt',
    },
  ]
  const columnsCN = [
    {
      title: 'STT',
      dataIndex: 'STT',
      width: 40,
      align: 'center',
      key: 'STT',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenChiNhanh',
      key: 'tenChiNhanh',
      ...getColumnSearchProps('tenChiNhanh'),
    },
    {
      title: 'Giá bán',
      dataIndex: 'giabanchinhanh',
      key: 'giabanchinhanh',
      width: 100,
      align: 'right',
      render: (text, record) => formattedNumber(text),
    },
    {
      title: 'Trạng thái',
      //   dataIndex: "tenChiNhanh",
      width: 80,
      align: 'center',
      key: 'tenChiNhanh',
      render: (text, record, index) => (
        <Checkbox
          checked={record.trangThaiBan !== 0}
          onChange={() => {
            handleCheckBox(record.idcnban, record.trangThaiBan)
          }}
        />
      ),
    },
  ]

  const fetchData = async (page) => {
    setIsLoading(true)
    try {
      const { data } = await https.get('ThuocVatTu/SearchByCondition', {
        params: {
          keyword: search,
          idCT: company,
          idPhanLoai: types,
          idNhom: valueGroups,
          pageIndex: page,
        },
      })
      setTotalPages(data.totalPage)
      setListThuocVT((prev) => [...prev, ...data.data])
      const nameCompany = listCompany.find((item) => item.idct === company)?.tenct
      const nameLoaiThuoc =
        types === null ? 'Tất cả' : typesVT.find((item) => item.idPhanLoai === types)?.phanLoai
      const nameNhomThuoc =
        valueGroups === null
          ? 'Tất cả'
          : groupsVTBranchBuy.find((item) => item.idNhom === valueGroups)?.nhom
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_DS_CHI_NHANH_BAN,
        true,
        MENU_CONSTANTS.CHI_NHANH_BAN,
        null,
        {
          nameCompany,
          nameLoaiThuoc,
          nameNhomThuoc,
          keyword: search ?? '',
          page: page,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.CHI_NHANH_BAN,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (infoUser) {
      dispatch(
        putCheckQuyenThaoTacAction(maQuyenXemDs, { page: page }, actionIsTrue, actionIsFalse),
      )
    }
  }, [page, reloadTrigger, infoUser])

  const handleScroll = useCallback(
    debounce(() => {
      const tableBody = document.querySelector('.table-chinhanhban .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 100 &&
        !isLoading
      ) {
        if (page + 1 > totalPages) {
          return
        }
        setPage((prevPage) => prevPage + 1)
      }
    }, 300),
    [isLoading],
  )

  useEffect(() => {
    const tableBody = document.querySelector('.table-chinhanhban .ant-table-body')
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  const handleReload = () => {
    setListThuocVT([])
    setPage(1)
    setReloadTrigger((prev) => prev + 1)
  }

  return (
    <>
      <div className="flex gap-2 h-full p-5 bg-[#efefef]">
        <div className="w-2/3 h-full bg-white rounded-xl">
          <div className="p-2 min-h-10 flex items-center ">
            <ul className="flex gap-2 items-center">
              <li className="flex flex-col w-60">
                <Select
                  showSearch
                  className="w-full"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={handleCompany}
                  value={company}
                  options={listCompany?.map((items) => ({
                    label: items.tenct,
                    value: items.idct,
                  }))}
                />
              </li>
              <li className="flex flex-col w-52">
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes((input ?? '').toLowerCase())
                  }
                  onChange={handleTypes}
                  value={types}
                  className="w-full"
                  placeholder="Loại thuốc"
                  options={[
                    { label: 'Tất cả loại thuốc', value: null },
                    ...(typesVT
                      ? typesVT
                          .map((items) => ({
                            label: items.phanLoai,
                            value: items.idPhanLoai,
                          }))
                          ?.sort((a, b) => (a?.label ?? '')?.localeCompare(b?.label ?? ''))
                      : []),
                  ]}
                />
              </li>
              <li className="w-60">
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes((input ?? '').toLowerCase())
                  }
                  value={valueGroups}
                  onChange={handleGroups}
                  className="w-full"
                  placeholder="Nhóm thuốc"
                  options={[
                    { label: 'Tất cả nhóm thuốc', value: null },
                    ...(groupsVTBranchBuy && types
                      ? groupsVTBranchBuy
                          ?.map((items) => ({
                            label: items.nhom,
                            value: items.idNhom,
                          }))
                          ?.sort((a, b) => (a?.label ?? '')?.localeCompare(b?.label ?? ''))
                      : []),
                  ]}
                />
              </li>
              <li className="w-60">
                <Input
                  value={search}
                  placeholder="Tìm kiếm"
                  onChange={(e) => setSearch(e.target.value)}
                  prefix={<SearchOutlined />}
                />
              </li>
              <li>
                <IconButton color="primary" onClick={handleReload}>
                  <AutorenewRoundedIcon />
                </IconButton>
              </li>
            </ul>
          </div>
          <div className=" p-2">
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    rowHoverBg: '#bae0ff',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
                token: {
                  padding: 5,
                },
              }}
            >
              <Table
                onRow={(record) => ({
                  onClick: () => handleBranchBuy(record),
                })}
                columns={columnsVT}
                dataSource={listThuocVT}
                className="w-full table-chinhanhban"
                loading={isLoading}
                scroll={{ y: '77vh' }}
                pagination={false}
                bordered
              />
            </ConfigProvider>
          </div>
        </div>
        <div className="w-1/3 h-full bg-white rounded-xl">
          <div className=" min-h-16 px-2 pt-2 ">
            <div className="flex gap-5 justify-between items-center ">
              <div className=" w-full h-full">
                <Select
                  showSearch
                  className="w-full mb-2"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={handleCompany}
                  value={company}
                  options={listCompany?.map((items) => ({
                    label: items.tenct,
                    value: items.idct,
                  }))}
                />
                <Select
                  value={valueChuyenKhoa}
                  onChange={handleSelected}
                  options={[
                    { label: 'Tất cả chuyên khoa', value: '' },
                    ...(chuyenKhoa
                      ? chuyenKhoa.map((items) => ({
                          label: items.tenChuyenKhoa,
                          value: items.idChuyenKhoa,
                        }))
                      : []),
                  ]}
                  className="w-full"
                />
              </div>
            </div>
            <div className="flex gap-1 items-center my-2">
              <div className="flex w-4/6">
                <span className="font-semibold">Tên:</span>
                <p className="truncate">{nameVT}</p>
              </div>
              <div className="w-2/6 flex gap-2">
                <Button
                  startIcon={<EditOutlined style={{ fontSize: 16 }} />}
                  // onClick={() => setShow(true)}
                  onClick={() =>
                    dispatch(
                      putCheckQuyenThaoTacAction(
                        maQuyenCapNhatGia,
                        null,
                        actionIsTrue,
                        actionIsFalse,
                      ),
                    )
                  }
                  disabled={!nameVT}
                  className="h-7 w-full"
                  variant="outlined"
                  size="small"
                >
                  Giá
                </Button>
                <Button
                  // onClick={handleUpdate}
                  onClick={() =>
                    dispatch(
                      putCheckQuyenThaoTacAction(
                        maQuyenCapNhatSuDung,
                        null,
                        actionIsTrue,
                        actionIsFalse,
                      ),
                    )
                  }
                  disabled={!ListUpdate?.length}
                  className="h-7 w-full"
                  variant="contained"
                  size="small"
                >
                  Lưu
                </Button>
              </div>
            </div>
          </div>

          <div className="px-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                scroll={{ y: '70vh' }}
                loading={isLoadingBranch}
                pagination={false}
                columns={columnsCN}
                dataSource={listBranchBuy}
                bordered
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
      <ModalUpdate
        onReload={onReload}
        nameVT={nameVT}
        idThuoc={idThuoc}
        show={show}
        setShow={setShow}
      />
    </>
  )
}

export default BranchBuy
