import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, DatePicker, Input, Select, Table, TreeSelect, Space } from 'antd'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import { SearchOutlined } from '@ant-design/icons'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { branchService } from '../../../../../services/branch/branchService'
import { khoVTservice } from '../../../../../services/khoVT/khoVTservice'
import { reportService } from '../../../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import { exportExcelformatSumCol } from '../../../../../utils/export/exportExcelFormat'
import ToastCus from '../../../../../utils/Toast'
import _ from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'moment/locale/vi'
import 'dayjs/locale/vi'
import { getListNameChiNhanh } from '../../../../../utils/algorithm'
import { addLog } from '../../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../../utils/log/logConstant'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

function SuDung() {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState(['all'])
  const [branch, setBranch] = useState([])
  const [dataReport, setDataReport] = useState([])
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [chuyenKhoa, setChuyenKhoa] = useState('all')
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateFrom, setDateFrom] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [search, setSearch] = useState('')

  // const tongSTTT = dataReport?.reduce((tong, item) => (tong += item?.tongsotoatuantruoc), 0)
  // const tongST = dataReport?.reduce((tong, item) => (tong += item?.tongsotoa), 0)
  // const tongTLCLTongST = Math.round((((tongST - tongSTTT) * 100) / tongSTTT) * 10) / 10
  // const tongTCLTTT = dataReport?.reduce(
  //   (tong, item) => (tong += item?.tongsotoacolaythuoctuantruoc),
  //   0,
  // )
  // const tongTCLT = dataReport?.reduce((tong, item) => (tong += item?.tongsotoacolaythuoc), 0)
  // const tongTLCLTongTCLT = Math.round((((tongTCLT - tongTCLTTT) * 100) / tongTCLTTT) * 10) / 10

  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const nameChiNhanh = useMemo(() => getListNameChiNhanh(value, branch), [value, branch])
  const nameCompany = useMemo(
    () => listCompany?.find((item) => item?.idct === company)?.tenct,
    [company, listCompany],
  )
  const nameChuyenKhoa = useMemo(
    () =>
      chuyenKhoa === 'all'
        ? 'Tất cả'
        : listChuyenKhoa?.find((item) => item?.idChuyenKhoa === chuyenKhoa)?.tenChuyenKhoa,
    [chuyenKhoa, listChuyenKhoa],
  )
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex, type) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      type === 'date'
        ? moment(record[dataIndex], 'YYYY-MM-DD')
            .format('DD/MM/YYYY')
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => text,
  })
  const tinhThu2CuaNgayBatKy = (date) => {
    const inputDate = moment(date)
    const dayOfWeek = inputDate.day()
    const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1
    return inputDate.subtract(daysToSubtract, 'days').format('YYYY-MM-DD')
  }
  const fetchReport = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT552',
      )

      const { data } = await reportService.getBaoCaoTinhHinhSuDung(
        (value?.includes('all') || !value?.length ? branch : value)
          ?.map(
            (item) => `idcn=${value?.includes('all') || !value?.length ? item?.idChiNhanh : item}`,
          )
          ?.join('&')
          ?.toString(),
        dateFrom,
        dateTo,
      )
      setDataReport(data?.map((item) => ({ ...item, solo: '012345678' })))
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.BAO_CAO_TIEM_CHUNG,
        true,
        MENU_CONSTANTS.BAO_CAO_TIEM_CHUNG,
        null,
        {
          dateFrom: dateFrom,
          dateTo,
          nameCompany,
          nameChuyenKhoa,
          nameChiNhanh,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.BAO_CAO_TIEM_CHUNG,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }
  const getListChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setListChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranch = async (idct, idck) => {
    try {
      setIsLoading(true)
      const { data } = await branchService.getBranchNotVP(idct)
      if (!idck || idck === 'all') setBranch(data)
      else if (idck) setBranch(data?.filter((item) => item?.idChuyenKhoa === idck))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT553',
      )

      const formatData = dataReport?.map((item, index) => ({
        'STT ': ++index,
        'Tên chi nhánh': item?.tenchinhanh,
        'Tên vắc xin': item?.tenbietduoc,
        'Nhóm hàng': item?.loaivacxin,
        'Tên nhà sản xuất': item?.nhasanxuat,
        'Tên nhà cung cấp': item?.tennhacungcap,
        'Số lô': item?.solo,
        'Mã số đăng ký': item?.masodangky,
        'Hạn dùng': item?.handung ? moment(item?.handung).format('DD/MM/YYYY') : '-',
        'Số tồn tháng trước': item?.tonthangtruoc, //formattedNumber(item?.tonthangtruoc ?? 0),
        'Số nhập': item?.sonhap, //formattedNumber(item?.sonhap ?? 0),
        'Số tiêm': item?.sotiem, //formattedNumber(item?.sotiem ?? 0),
        'Số hủy': item?.sohuy, //formattedNumber(item?.sohuy ?? 0),
        'Số sử dụng': item?.sosudung, //formattedNumber(item?.sosudung ?? 0),
        'Số hiện còn': item?.sohiencon, //formattedNumber(item?.sohiencon ?? 0),
      }))
      const name = 'BÁO CÁO TÌNH HÌNH SỬ DỤNG VẮC XIN'
      if (formatData?.length)
        exportExcelformatSumCol(formatData, name, [
          'Số tồn tháng trước',
          'Số nhập',
          'Số tiêm',
          'Số hủy',
          'Số sử dụng',
          'Số hiện còn',
        ])
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_BAO_CAO_TIEM_CHUNG,
        true,
        MENU_CONSTANTS.BAO_CAO_TIEM_CHUNG,
        null,
        {
          dateFrom: dateFrom,
          dateTo,
          nameCompany,
          nameChuyenKhoa,
          nameChiNhanh,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const detailErrorPayload = `Có lỗi xảy ra khi xuất excel báo cáo tiêm chủng \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.BAO_CAO_TIEM_CHUNG,
        detailErrorPayload,
      )
    }
  }
  const onLoad = () => {
    // setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    if (!value || !value?.length) setValue(['all'])
    fetchReport()
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    getListChuyenKhoa()
    fetchBranch(id_company, chuyenKhoa)
  }, [])

  // useEffect(() => {//load lần đầu
  //   if (branch?.length) fetchReport()
  // }, [branch])

  // useEffect(() => {
  //   ;(async () => {
  //     try {
  //       const { data } = await axios.get(
  //         'http://192.168.1.250:4343/api/CaLamViec/Mobile/GetDanhSachDangKy?idNhanVien=4783&deviceID=8491B6C1-8EFF-4D71-8A1C-720589C53D71',
  //       )

  //       console.log(data)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })()
  // }, [])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      fixed: 'left',
      width: !dataReport?.length ? 150 : null,
      ...getColumnSearchProps('tenchinhanh'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenchinhanh ?? '').localeCompare(b?.tenchinhanh ?? '')
        },
      },
      render: (text) => <p className={!text || text?.length < 25 ? 'w-40' : ''}>{text}</p>,
    },
    {
      title: 'Tên vắc xin',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
      fixed: 'left',
      // width: !dataReport?.length ? 140 : null,
      ...getColumnSearchProps('tenbietduoc'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tenbietduoc ?? '').localeCompare(b?.tenbietduoc ?? '')
        },
      },
      render: (text) => <p className={!text || text?.length < 25 ? 'w-40' : ''}>{text}</p>,
    },
    {
      title: 'Nhóm hàng',
      dataIndex: 'loaivacxin',
      key: 'loaivacxin',
      width: !dataReport?.length ? 130 : null,
      ...getColumnSearchProps('loaivacxin'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.loaivacxin ?? '').localeCompare(b?.loaivacxin ?? '')
        },
      },
      render: (text) => <p className={!text || text?.length < 20 ? 'w-36' : ''}>{text}</p>,
    },
    {
      title: 'Tên nhà sản xuất',
      dataIndex: 'nhasanxuat',
      key: 'nhasanxuat',
      width: !dataReport?.length ? 170 : null,
      ...getColumnSearchProps('nhasanxuat'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.nhasanxuat ?? '').localeCompare(b?.nhasanxuat ?? '')
        },
      },
      render: (text) => <p className={!text || text?.length < 25 ? 'w-44' : ''}>{text}</p>,
    },
    {
      title: 'Tên nhà cung cấp',
      dataIndex: 'tennhacungcap',
      key: 'tennhacungcap',
      width: !dataReport?.length ? 170 : null,
      ...getColumnSearchProps('tennhacungcap'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tennhacungcap ?? '').localeCompare(b?.tennhacungcap ?? '')
        },
      },
      render: (text) => <p className={!text || text?.length < 25 ? 'w-44' : ''}>{text}</p>,
    },
    {
      title: 'Số lô',
      dataIndex: 'solo',
      key: 'solo',
      width: !dataReport?.length ? 90 : null,
      ...getColumnSearchProps('solo'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.solo ?? '').localeCompare(b?.solo ?? '')
        },
      },
      render: (text) => <p className={!text || text?.length < 10 ? 'w-20' : ''}>{text}</p>,
    },
    {
      title: 'Mã số đăng ký',
      dataIndex: 'masodangky',
      key: 'masodangky',
      width: !dataReport?.length ? 150 : null,
      ...getColumnSearchProps('masodangky'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.masodangky ?? '').localeCompare(b?.masodangky ?? '')
        },
      },
      render: (text) => <p className={!text || text?.length < 20 ? 'w-36' : ''}>{text}</p>,
    },
    {
      title: 'Hạn dùng',
      dataIndex: 'handung',
      key: 'handung',
      align: 'center',
      width: 120,
      ...getColumnSearchProps('handung', 'date'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.handung ?? '').localeCompare(b?.handung ?? '')
        },
      },
      render: (text, record, index) => (
        <p className="min-w-[2rem]">{text ? moment(text).format('DD/MM/YYYY') : '-'}</p>
      ),
    },
    {
      title: 'Số tồn tháng trước',
      dataIndex: 'tonthangtruoc',
      key: 'tonthangtruoc',
      align: 'center',
      width: 170,
      ...getColumnSearchProps('tonthangtruoc'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.tonthangtruoc ?? 0) - (b?.tonthangtruoc ?? 0)
        },
      },
    },
    {
      title: 'Số nhập',
      dataIndex: 'sonhap',
      key: 'sonhap',
      align: 'center',
      width: 110,
      ...getColumnSearchProps('sonhap'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.sonhap ?? 0) - (b?.sonhap ?? 0)
        },
      },
    },
    {
      title: 'Số tiêm',
      dataIndex: 'sotiem',
      key: 'sotiem',
      align: 'center',
      width: 110,
      ...getColumnSearchProps('sotiem'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.sotiem ?? 0) - (b?.sotiem ?? 0)
        },
      },
    },
    {
      title: 'Số hủy',
      dataIndex: 'sohuy',
      key: 'sohuy',
      align: 'center',
      width: 110,
      ...getColumnSearchProps('sohuy'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.sohuy ?? 0) - (b?.sohuy ?? 0)
        },
      },
    },
    {
      title: 'Số sử dụng',
      dataIndex: 'sosudung',
      key: 'sosudung',
      align: 'center',
      width: 130,
      ...getColumnSearchProps('sosudung'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.sosudung ?? 0) - (b?.sosudung ?? 0)
        },
      },
    },
    {
      title: 'Số hiện còn',
      dataIndex: 'sohiencon',
      key: 'sohiencon',
      align: 'center',
      width: 130,
      ...getColumnSearchProps('sohiencon'),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return (a?.sohiencon ?? 0) - (b?.sohiencon ?? 0)
        },
      },
    },
    // {
    //   title: 'Ghi chú',
    //   dataIndex: 'tennhom',
    //   key: 'tennhom',
    // },
    // {
    //   title: 'Thông tin vắc xin',
    //   dataIndex: 'thongtinvacxin',
    //   key: 'thongtinvacxin',
    //   children: [
    //     {
    //       title: 'Mã hàng',
    //       dataIndex: 'mahang',
    //       key: 'mahang',
    //       // align: 'center',
    //       width: 120,
    //     },
    //     {
    //       title: 'Tên vắc xin',
    //       dataIndex: 'tenvacxin',
    //       key: 'tenvacxin',
    //     },
    //   ],
    // },
  ]

  return (
    <>
      <div className="flex gap-2">
        <div className="w-[17rem] flex justify-between items-start gap-1">
          <DatePicker
            placeholder="Từ ngày..."
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={(date, dateString) =>
              setDateFrom(moment(dateString, dateFormat).format(momentDate))
            }
            format={dateFormat}
            locale={{ ...locale, week: { start: 1 } }}
          />
          <div className="font-semibold text-2xl flex items-start">-</div>
          <DatePicker
            placeholder="Đến ngày..."
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={(date, dateString) =>
              setDateTo(moment(dateString, dateFormat).format(momentDate))
            }
            format={dateFormat}
            locale={{ ...locale, week: { start: 1 } }}
          />
        </div>
        <div className="w-[17rem]">
          <Select
            showSearch
            value={company}
            onChange={(value) => {
              setCompany(value)
              fetchBranch(value, chuyenKhoa)
              setValue(['all'])
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-full"
          />
        </div>
        <div className="w-[17rem]">
          <Select
            showSearch
            value={chuyenKhoa}
            onChange={(value) => {
              setChuyenKhoa(value)
              fetchBranch(company, value)
              setValue(['all'])
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                label: 'Tất cả chuyên khoa',
                value: 'all',
              },
              ...listChuyenKhoa?.map((item) => ({
                label: item?.tenChuyenKhoa,
                value: item?.idChuyenKhoa,
              })),
            ]}
            className="w-full"
          />
        </div>
        <div className="w-[17rem] flex gap-2">
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
          />
        </div>
        {/* <div className="flex w-[17rem]">
          <Input
            value={search}
            placeholder="Nhập mã hàng, tên hàng..."
            onChange={(e) => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </div> */}
        <div className="flex gap-2">
          <IconButton
            disabled={isLoading}
            onClick={onLoad}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        <div className="font-semibold ">
          <Button
            disabled={isLoading || !dataReport?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div className="mt-2">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: dataReport?.length ? 'max-content' : 2000,
              y: '68.7vh',
            }}
            pagination={false}
            loading={isLoading}
            bordered
            // dataSource={dataReport.concat(dataReport)}
            dataSource={dataReport}
            columns={columns}
            summary={(pageData) => {
              const tongtonthangtruoc = pageData?.reduce(
                (tong, item) => (tong += item?.tonthangtruoc),
                0,
              )
              const tongsonhap = pageData?.reduce((tong, item) => (tong += item?.sonhap), 0)
              const tongsotiem = pageData?.reduce((tong, item) => (tong += item?.sotiem), 0)
              const tongsohuy = pageData?.reduce((tong, item) => (tong += item?.sohuy), 0)
              const tongsosudung = pageData?.reduce((tong, item) => (tong += item?.sosudung), 0)
              const tongsohiencon = pageData?.reduce((tong, item) => (tong += item?.sohiencon), 0)
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row className="text-center font-semibold">
                    {columns?.map((item, index) => (
                      <Table.Summary.Cell index={index}>
                        {item?.key === 'tonthangtruoc' && tongtonthangtruoc}
                        {item?.key === 'sonhap' && tongsonhap}
                        {item?.key === 'sotiem' && tongsotiem}
                        {item?.key === 'sohuy' && tongsohuy}
                        {item?.key === 'sosudung' && tongsosudung}
                        {item?.key === 'sohiencon' && <p className="pr-[1rem]">{tongsohiencon}</p>}
                      </Table.Summary.Cell>
                    ))}
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default SuDung
