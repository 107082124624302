import React, { useEffect, useRef, useState } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import { Button, DatePicker, Input, Modal, Skeleton } from 'antd'
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import AddVersion from './AddVersion/AddVersion'
import { updateVersionSchema } from '../../../schemas/updateVersionSchema'
import { versionService } from '../../../services/version/versionService'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteVersion, updateVersion } from '../../../store/actions/versionAction'
import { useFormik } from 'formik'
import dayjs from 'dayjs'
import 'moment/locale/vi'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../utils/log/logConstant'
import { addLog } from '../../../utils/log/apiLog'
moment.locale('vi')
const { confirm } = Modal
const formatDB = 'YYYY-MM-DDTHH:mm:ss'
const dateFormat = 'DD/MM/YYYY'
const Version = () => {
  const [isUpdate, setIsUpdate] = useState(false)
  const [version, setVersion] = useState([])
  const [open, setOpen] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)

  const [infoVersion, setInfoVersion] = useState(null)
  const dispatch = useDispatch()
  //------ show delete vertion ------//
  const showDeleteConfirm = (id, phienban) => {
    confirm({
      title: 'Bạn có chắc muốn xóa phiên bản này ?',
      icon: <ExclamationCircleFilled />,
      content: `Xóa phiên bản ${phienban}`,
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk() {
        dispatch(DeleteVersion(id, fetchVersion))
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
  }
  //-------- cancel modal add new version ----------//
  const cancelModal = () => {
    setOpen(false)
  }
  //-----------  list version --------------//
  const fetchVersion = async () => {
    try {
      const { data } = await versionService.getAllVersion()
      setVersion(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_DS_PHIEN_BAN,
        true,
        MENU_CONSTANTS.PHIEN_BAN,
        null,
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.PHIEN_BAN,
        detailErrorPayload,
      )
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idpb: infoVersion?.idpb,
      phienban: infoVersion?.phienban,
      tenphienban: infoVersion?.tenphienban,
      tinhnangcapnhat: infoVersion?.tinhnangcapnhat,
      ngaynangcap: infoVersion?.ngaynangcap,
      nguoicapnhat: infoUser?.tenNV,
    },
    validationSchema: updateVersionSchema,
    onSubmit: (value) => handleSubmit(value),
  })
  const handleSubmit = (value) => {
    dispatch(updateVersion(value, reloadVersion))
  }
  const reloadVersion = (id) => {
    fetchVersionByID(id)
    setIsUpdate(false)
  }
  const fetchVersionByID = async (id) => {
    try {
      setInfoVersion(null)
      const { data } = await versionService.getInfoVersion(id)
      setInfoVersion(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_DETAIL_PHIEN_BAN,
        true,
        MENU_CONSTANTS.PHIEN_BAN,
        null,
        { id },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.PHIEN_BAN,
        detailErrorPayload,
      )
    }
  }
  const handleItemClick = (id) => {
    setSelectedId(id)
    fetchVersionByID(id)
  }
  const handleDatePicker = (date, dateString) => {
    const value = moment(dateString, dateFormat).format(formatDB)
    formik.setFieldValue('ngaynangcap', value)
  }
  useEffect(() => {
    if (infoUser) {
      fetchVersion()
    }
  }, [infoUser])
  return (
    <>
      <div className="h-full">
        <div className="p-5 h-full bg-[#EFEFEF]">
          <div
            className="border h-full bg-white rounded-lg flex"
            style={{
              boxShadow: 'rgba(145, 158, 171, 0.3) 0px 0px 2px 0px',
            }}
          >
            <div className="w-1/4 p-5 border-r">
              <Input.Search />
              <div className="mt-2">
                <p className="font-semibold text-base">Tất cả phiên bản</p>
                <ul className="mt-2 flex flex-col gap-3">
                  {version?.map((item) => (
                    <li
                      key={item.idpb}
                      onClick={() => handleItemClick(item.idpb)}
                      className={`border rounded-md p-2 cursor-pointer relative ${selectedId === item.idpb ? 'bg-blue-50 scale-105' : ''} `}
                    >
                      <p className="font-semibold">{item.phienban}</p>
                      <p className="text-gray-500">{item.tenphienban}</p>
                      <p className="text-gray-500">
                        {item.ngaynangcap && moment(item.ngaynangcap).format('DD/MM/YYYY HH:mm:ss')}
                      </p>
                      <DeleteOutlined
                        onClick={(e) => {
                          showDeleteConfirm(item.idpb, item.phienban)
                          e.stopPropagation() // Ngăn chặn sự kiện click lan truyền lên thẻ li
                        }}
                        className="absolute bottom-2 right-2 text-red-500 cursor-pointer hover:text-white p-1 rounded-md hover:bg-red-500"
                        style={{ fontSize: 18 }}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="w-3/4">
              <div className="border-b p-5 flex justify-end">
                <Button type="primary" onClick={() => setOpen(true)} className="flex items-center ">
                  Thêm mới{' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                  </svg>
                </Button>
              </div>
              <div className="p-5 flex flex-col gap-2 ">
                <div>
                  <h2 className="font-semibold">Tiêu đề:</h2>
                  {!isUpdate ? (
                    <Input value={infoVersion?.tenphienban} variant="borderless" readOnly />
                  ) : (
                    <Input
                      status={
                        formik.errors.tenphienban && formik.touched.tenphienban ? 'error' : ''
                      }
                      value={formik.values.tenphienban}
                      name="tenphienban"
                      onChange={formik.handleChange}
                    />
                  )}
                </div>
                <div className="flex gap-5">
                  <div className="w-1/2">
                    <h2 className="font-semibold">Phiên bản:</h2>
                    {!isUpdate ? (
                      <Input value={infoVersion?.phienban} variant="borderless" readOnly />
                    ) : (
                      <Input
                        status={formik.errors.phienban && formik.touched.phienban ? 'error' : ''}
                        value={formik.values.phienban}
                        name="phienban"
                        onChange={formik.handleChange}
                      />
                    )}
                  </div>
                  <div className="w-1/2">
                    <h2 className="font-semibold">Ngày nâng cấp:</h2>
                    {!isUpdate ? (
                      <Input
                        value={moment(infoVersion?.ngaynangcap).format('DD/MM/YYYY HH:mm:ss')}
                        variant="borderless"
                        readOnly
                      />
                    ) : (
                      <DatePicker
                        allowClear={false}
                        value={dayjs(formik.values.ngaynangcap)}
                        onChange={handleDatePicker}
                        format={dateFormat}
                        className="w-full"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <h2 className="font-semibold">nội dung:</h2>
                  {!isUpdate ? (
                    <Input.TextArea
                      variant="borderless"
                      readOnly
                      value={infoVersion?.tinhnangcapnhat}
                      style={{ height: 220, resize: 'none' }}
                    />
                  ) : (
                    <Input.TextArea
                      status={
                        formik.errors.tinhnangcapnhat && formik.touched.tinhnangcapnhat
                          ? 'error'
                          : ''
                      }
                      value={formik.values.tinhnangcapnhat}
                      name="tinhnangcapnhat"
                      onChange={formik.handleChange}
                      style={{ height: 220, resize: 'none' }}
                    />
                  )}
                </div>
                <div className="text-gray-500 flex gap-5 justify-end">
                  <i>Người cập nhật: {infoVersion?.nguoicapnhat}</i>
                  <i>
                    Ngày:{' '}
                    {infoVersion?.ngaycapnhat &&
                      moment(infoVersion?.ngaycapnhat).format('DD/MM/YYYY HH:mm:ss')}
                  </i>
                </div>
                <div>
                  {isUpdate ? (
                    <>
                      <Button type="primary" onClick={formik.handleSubmit}>
                        Lưu
                      </Button>{' '}
                      <Button type="primary" ghost onClick={() => setIsUpdate(false)}>
                        Hủy bỏ
                      </Button>{' '}
                    </>
                  ) : (
                    <Button onClick={() => setIsUpdate(true)} type="primary">
                      Cập nhật
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddVersion open={open} cancelModal={cancelModal} fetchVersion={fetchVersion} />
    </>
  )
}

export default Version
