import { Input, InputNumber, Modal } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { detailCardService } from '../../../../../services/card/detailCardService'
import { editGiaChiNhanhLoaiTheSchema } from '../../../../../schemas/editGiaChiNhanhLoaiTheSchema'
// import { https } from '../../../../services/apiService'
import ToastCus from '../../../../../utils/Toast'
import { addLog } from '../../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../../utils/log/logConstant'
const ModalEditPriceCN = ({ showModalEditPriceCN, setShowModalEditPriceCN, infoCNLT, congTy }) => {
  const { stafs, PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoLoaiThe } = useSelector((state) => state.detailCardReducer)
  // const [infoGiaCNLT, setInfoGiaCNLT] = useState(infoCNLT)
  // xử lí form thêm dịch vụ
  const handleSubmit = async (values) => {
    // const nameChiNhanh =
    //   stafs?.find((item) => item.idchinhanh === values.idchinhanh)?.tenchinhanh || ''

    // const nameLoaiThe = Array.isArray(congTy)
    //   ? congTy.find((item) => item.idlt === values.idlt)?.loaithe || ''
    //   : ''
    // const loaiTheChiNhanh = Array.isArray(congTy)
    //   ? congTy.find((item) => item.idlt === values.idlt)?.loaithe || ''
    //   : ''
    const detailForLog = {
      dongia: values.dongia,
      idchinhanh: values.idchinhanh,
      idlt: values.idlt,
      idltcn: values.idltcn,
    }
    try {
      await detailCardService.putInfoGiaChiNhanhLoaiThe(values)

      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật giá thẻ chi nhánh thành công!',
      })

      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.UPDATE_THE_LOAI_CHI_NHANH,
        true,
        MENU_CONSTANTS.THE_THANH_VIEN,
        null,
        detailForLog,
      )
      formik.resetForm()
      setShowModalEditPriceCN(false)
    } catch (error) {
      console.log(error)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.NHAN_VIEN,
        detailForLog,
      )
      ToastCus.fire({
        icon: 'error',
        title: 'Cập nhật giá thẻ chi nhánh thất bại!',
      })
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idltcn: infoCNLT?.idltcn,
      idlt: infoCNLT?.idlt,
      idchinhanh: infoCNLT?.idchinhanh,
      dongia: infoCNLT?.dongia ?? 0,
      ghichu: '',
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: editGiaChiNhanhLoaiTheSchema,
  })
  // useEffect(() => {
  //   if () {
  //   }
  // }, [])
  return (
    <>
      <Modal
        okText="Xác nhận"
        cancelText="Hủy"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Chi tiết giá thẻ chi nhánh</h2>}
        onOk={formik.handleSubmit}
        open={showModalEditPriceCN}
        onCancel={() => setShowModalEditPriceCN(false)}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Tên loại thẻ{' '}
            </label>
            <Input variant="filled" readOnly value={infoLoaiThe?.loaithe} />
          </div>
          <div>
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Tên chi nhánh
            </label>
            <Input variant="filled" readOnly value={infoCNLT?.tenchinhanh} />
          </div>
          <div>
            <label className="font-semibold text-base">
              <span className="text-sx text-red-500">(*)&nbsp;</span>Đơn giá
            </label>
            <InputNumber
              onChange={(value) => {
                formik.setFieldValue('dongia', value)
              }}
              status={formik.errors.dongia ? 'error' : ''}
              name="dongia"
              className="w-full"
              value={formik.values.dongia}
              step={100000}
              formatter={(value) => {
                const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              min={0}
              max={999999999}
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalEditPriceCN
