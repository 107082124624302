import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { loiDanBsService } from '../../services/loiDanBS/LoiDanBsService'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
import { NgayNghiService } from '../../services/NgayNghi/ngayNghiService'
import { identity } from 'lodash'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
// Sagas
export function* ngayNghiSaga() {
  //----------------GET NGAY NGHI  ---------------//
  yield takeLatest(typeAction.GET_DANH_SACH_NGAY_NGHI, function* NgayNghis({ type }) {
    try {
      yield put({ type: typeAction.OPEN_LOADING_TABLE })
      const { data } = yield call(() => NgayNghiService.getNgayNghis())
      yield put({
        type: typeAction.DISPATCH_NGAY_NGHIS,
        payload: data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_TABLE })
    }
  })
  //---------------- DELETE NGÀY NGHĨ ---------------//
  yield takeLatest(typeAction.DELETE_NGAY_NGHI, function* deleteLoiDan({ type, record, reload }) {
    try {
      yield call(() => NgayNghiService.deleteNgayNghi(record.idngaynghi))
      yield reload() // reload lại dữ liệu sau khi xoá
      Toast.fire({
        icon: 'success',
        title: 'Xoá thành công !',
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Xoá thất bại !',
      })
      console.log(error)
    }
  })
  //---------------- THÊM MỚI NGAY NGHI ---------------//
  yield takeLatest(typeAction.POST_NGAY_NGHI, function* createNgayNghi({ type, form, reload }) {
    try {
      yield call(() => NgayNghiService.postNgayNghi(form))
      yield reload() // reload lại dữ liệu sau thêm
      Toast.fire({
        icon: 'success',
        title: 'Thêm mới thành công !',
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Thêm mới thất bại !',
      })
      console.log(error)
    }
  })
  //---------------- LẤY THÔNG TIN NGÀY NGHĨ ---------------//
  yield takeLatest(typeAction.GET_NGAY_NGHI_BY_ID, function* getInfoNgayNghi({ type, id }) {
    try {
      const { data } = yield call(() => NgayNghiService.getInfoNgayNghi(id))
      yield put({
        type: typeAction.DISPATCH_NGAY_NGHI_BY_ID,
        payload: data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  //----------------  CẬP NHẬP NGAY NGHĨ---------------//
  yield takeLatest(typeAction.UPDATE_NGAY_NGHI, function* updateNgayNghi({ type, form, reload }) {
    try {
      yield call(() => NgayNghiService.putNgayNghi(form))
      yield reload()
      Toast.fire({
        icon: 'success',
        title: 'Cập nhật thành công !',
      })
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Cập nhật thất bại !',
      })
    }
  })
}
