import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'
import { DatePicker, Select, ConfigProvider, Table, Button, TreeSelect } from 'antd'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { reportService } from '../../../services/Report/reportService'
import { formattedNumber } from '../../../utils/formattedNumber'
import { khoVTservice } from '../../../services/khoVT/khoVTservice'
import { phanQuyenService } from '../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../store/actions/phanQuyenAction'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import ToastCus from '../../../utils/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
import { getListNameChiNhanh } from '../../../utils/algorithm'
import { addLog } from '../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../utils/log/logConstant'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect
const ReportBranch = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [value, setValue] = useState([BranchLogin])
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [idChiNhanh, setIdChiNhanh] = useState(Number(BranchLogin))
  const [data, setData] = useState([])
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))

  const nameChiNhanh = useMemo(() => getListNameChiNhanh(value, branch), [value, branch])
  const nameCompany = useMemo(
    () => listCompany?.find((item) => item?.idct === company)?.tenct,
    [company, listCompany],
  )
  const nameChuyenKhoa = useMemo(
    () =>
      !idChuyenKhoa
        ? 'Tất cả'
        : ChuyenKhoa?.find((item) => item?.idChuyenKhoa === idChuyenKhoa)?.tenChuyenKhoa,
    [idChuyenKhoa, ChuyenKhoa],
  )

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const onLoad = () => {
    fetchReport()
  }

  //-------- export excel-----------//
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT466',
      )
      const formattedData = data?.map((item) => ({
        'Ngày thu': item.ngay ? moment(item.ngay).format('DD/MM/YYYY') : '-',
        'Chi nhánh': item.tenChiNhanh,
        'Ngày khai trương': item.ngayKhaiTruong
          ? moment(item.ngayKhaiTruong).format('DD/MM/YYYY')
          : '-',
        'Dịch vụ ca A': item.doanhThuCaLamViecs[0].doanhThuDichVu,
        'Dịch vụ ca B': item.doanhThuCaLamViecs[1].doanhThuDichVu,
        'Dịch vụ ca C': item.doanhThuCaLamViecs[2].doanhThuDichVu,
        'Tổng dịch vụ ': item.tongTienDichVu,
        'TTV ca A': item.doanhThuCaLamViecs[0].doanhThuThe,
        'TTV ca B': item.doanhThuCaLamViecs[1].doanhThuThe,
        'TTV ca C': item.doanhThuCaLamViecs[2].doanhThuThe,
        'Tổng TTV ': item.tongTienThe,
        'Toa thuốc ca A': item.doanhThuCaLamViecs[0].doanhThuThuocKeToa,
        'Toa thuốc ca B': item.doanhThuCaLamViecs[1].doanhThuThuocKeToa,
        'Toa thuốc ca C': item.doanhThuCaLamViecs[2].doanhThuThuocKeToa,
        'Tổng Toa thuốc ': item.tongTienThuocKeToa,
        'Bán lẻ ca A': item.doanhThuCaLamViecs[0].doanhThuThuocBanLe,
        'Bán lẻ ca B': item.doanhThuCaLamViecs[1].doanhThuThuocBanLe,
        'Bán lẻ ca C': item.doanhThuCaLamViecs[2].doanhThuThuocBanLe,
        'Tổng Bán lẻ ': item.tongTienThuocBanLe,
        'Vaccine ca A': item.doanhThuCaLamViecs[0].doanhThuThuocVacXin,
        'Vaccine ca B': item.doanhThuCaLamViecs[1].doanhThuThuocVacXin,
        'Vaccine ca C': item.doanhThuCaLamViecs[2].doanhThuThuocVacXin,
        'Tổng Vacxin ': item.doanhThuThuocVacXin,
        'Tổng tiền': item.tongTienNgay,
      }))
      const name = 'Doanh thu tổng hợp'
      formattedData?.length && exportExcelformat(formattedData, name)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_BAO_CAO_DOANH_THU_TONG_HOP,
        true,
        MENU_CONSTANTS.DOANH_THU,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          nameChiNhanh: nameChiNhanh.join('\n'),
          nameChuyenKhoa,
          nameCompany,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const detailErrorPayload = `Có lỗi xảy ra khi xuất excel báo cáo doanh thu tổng hợp. \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DOANH_THU,
        detailErrorPayload,
      )
    }
  }
  //---------- lấy danh sách chi nhánh theo công ty -----------//
  // const fetchBranch = async (id) => {
  //   try {
  //     const result = await branchService.getListBranch(id)
  //     setBranch(result.data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  //-------------- lấy danh sách chuyên kho ------------//
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  // ---------đến trang chi tiết -------------//
  const goToDetailReportBranch = async (id) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT588',
      )
      navigate('/baocao/chinhanh/chitiet/' + id)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  // ------------ lấy danh sách report theo chi nhánh ------------//
  const fetchReport = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT465',
      )

      let idChiNhanhs = null
      // Kiểm tra giá trị của `value` để xử lý các trường hợp rỗng hoặc "all"
      if (value?.length > 0 && value[0] !== 'all') {
        // Giả sử `value` là mảng các ID. Chuyển đổi mảng thành chuỗi truy vấn
        idChiNhanhs = value?.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await reportService.getReportTotalByBranch(
          idChuyenKhoa,
          company,
          idChiNhanhs,
          dateForm,
          dateTo,
        )
        setData(data)
      } else {
        const { data } = await reportService.getReportTotalByBranch(
          idChuyenKhoa,
          company,
          '',
          dateForm,
          dateTo,
        )
        setData(data)
        setValue(['all'])
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.BAO_CAO_DOANH_THU_TONG_HOP,
        true,
        MENU_CONSTANTS.DOANH_THU,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          nameChiNhanh: nameChiNhanh.join('\n'),
          nameChuyenKhoa,
          nameCompany,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DOANH_THU,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }
  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (idct, value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(idct, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(company, value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  const total = data.reduce((total, items) => total + items.tongTienNgay, 0)
  const totalDV = data.reduce((total, items) => total + items.tongTienDichVu, 0)
  const totalTHE = data.reduce((total, items) => total + items.tongTienThe, 0)
  const totalTOA = data.reduce((total, items) => total + items.tongTienThuocKeToa, 0)
  const totalLE = data.reduce((total, items) => total + items.tongTienThuocBanLe, 0)
  const totalVX = data.reduce((total, items) => total + items.tongTienThuocVacXin, 0)
  useEffect(() => {
    dispatch(listAllCompanyAction())
    // fetchBranch(id_company)
    fetchChuyenKhoa()
    fetchBranchByChuyenKhoa(company, null)
  }, [])

  useEffect(() => {
    if (infoUser) {
      fetchReport()
    }
  }, [infoUser])
  const statis = [
    {
      title: 'TỔNG TIỀN DỊCH VỤ',
      number: formattedNumber(totalDV),
    },
    {
      title: 'TỔNG TIỀN TTV',
      number: formattedNumber(totalTHE),
    },
    {
      title: 'TỔNG TIỀN BÁN LẺ',
      number: formattedNumber(totalLE),
    },
    {
      title: 'TỔNG TIỀN TOA THUỐC',
      number: formattedNumber(totalTOA),
    },
    {
      title: 'TỔNG TIỀN VACCINE',
      number: formattedNumber(totalVX),
    },
    {
      title: 'TỔNG DOANH THU',
      number: formattedNumber(total),
    },
  ]
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  return (
    <>
      <div>
        <ul className="flex border rounded-md">
          {statis.map((items) => (
            <li className="flex flex-col gap-2 w-1/6 border-r p-2">
              <div className="flex justify-between">
                <h2 className="font-semibold text-gray-500 xl:text-base text-xs">{items.title}</h2>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="size-5 text-green-700 border-2 border-green-500  rounded-full "
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <span className="text-lg font-semibold">{formattedNumber(items.number)}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex  gap-2 border-dotted border-b-0 p-2 ">
        <div className="w-80">
          <Select
            showSearch
            value={company}
            onChange={(value) => {
              // fetchBranch(value)
              setValue(['all'])
              setCompany(value)
              setIdChuyenKhoa('')
              setIdChiNhanh(null)
              fetchBranchByChuyenKhoa(value, '')
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-full"
            disabled={infoUser && infoUser.idNhom === 3}
          />
        </div>
        <div className="w-56">
          <Select
            onChange={onChangeChuyenKhoa}
            className="w-full"
            value={idChuyenKhoa}
            options={[
              { label: 'Tất cả', value: '' },
              ...ChuyenKhoa.map((items) => ({
                label: items.tenChuyenKhoa,
                value: items.idChuyenKhoa,
              })),
            ]}
            disabled={infoUser && infoUser.idNhom === 3}
          />
        </div>
        <div className="w-80">
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
            disabled={infoUser && infoUser.idNhom === 3}
          />
        </div>
        <div className="flex gap-5">
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex gap-3">
            <IconButton onClick={onLoad} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
          </div>
          <div className="font-semibold ">
            <Button
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              x: data.length > 0 ? 'max-content' : 0,
              y: '61vh',
            }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={data}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                onCell: () => ({
                  style: {
                    minWidth: 40,
                  },
                }),
                align: 'center',
                fixed: 'left',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                onCell: () => ({
                  style: {
                    minWidth: 70,
                  },
                }),
                fixed: 'left',
                render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
              },
              {
                title: 'Chi nhánh',
                dataIndex: 'tenChiNhanh',
                key: 'tenChiNhanh',
                fixed: 'left',
                onCell: () => ({
                  style: {
                    minWidth: 70,
                  },
                }),
              },
              {
                title: 'Ngày khai trương',
                dataIndex: 'ngayKhaiTruong',
                key: 'ngayKhaiTruong',
                onCell: () => ({
                  style: {
                    minWidth: 130,
                  },
                }),
                fixed: 'left',
                render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
              },
              {
                title: 'Dịch vụ',
                dataIndex: 'tongTienDichVu',
                key: 'tongTienDichVu',
                align: 'center',
                children: [
                  {
                    title: 'Ca A',
                    dataIndex: 'DV_A',
                    align: 'right',
                    key: 'DV_A',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[0].doanhThuDichVu) || 0,
                  },
                  {
                    title: 'Ca B',
                    dataIndex: 'DV_B',
                    align: 'right',
                    key: 'DV_A',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[1].doanhThuDichVu) || 0,
                  },
                  {
                    title: 'Ca C',
                    dataIndex: 'DV_C',
                    align: 'right',
                    key: 'DV_C',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[2].doanhThuDichVu) || 0,
                  },
                  {
                    title: 'Tổng DV',
                    dataIndex: 'totalDV',
                    key: 'totalDV',
                    align: 'right',
                    onCell: () => ({
                      style: {
                        minWidth: 110,
                      },
                    }),
                    render: (_, record) => formattedNumber(record.tongTienDichVu) || 0,
                  },
                ],
              },
              {
                title: 'Thẻ Thành viên',
                dataIndex: 'tongTienThe',
                key: 'tongTienThe',
                align: 'center',
                children: [
                  {
                    title: 'Ca A',
                    dataIndex: 'DV_A',
                    key: 'DV_A',
                    align: 'right',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[0].doanhThuThe) || 0,
                  },
                  {
                    title: 'Ca B',
                    dataIndex: 'DV_B',
                    key: 'DV_A',
                    align: 'right',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[1].doanhThuThe) || 0,
                  },
                  {
                    title: 'Ca C',
                    dataIndex: 'DV_C',
                    key: 'DV_C',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    align: 'right',
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[2].doanhThuThe) || 0,
                  },
                  {
                    title: 'Tổng Thẻ',
                    dataIndex: 'totalCard',
                    key: 'totalCard',
                    align: 'right',
                    onCell: () => ({
                      style: {
                        minWidth: 110,
                      },
                    }),
                    render: (_, record) => formattedNumber(record.tongTienThe) || 0,
                  },
                ],
              },
              {
                title: 'Toa thuốc',
                dataIndex: 'tongTienThuocKeToa',
                key: 'tongTienThuocKeToa',
                align: 'center',
                children: [
                  {
                    title: 'Ca A',
                    dataIndex: 'TT_A',
                    key: 'TT_A',
                    align: 'right',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[0].doanhThuThuocKeToa) || 0,
                  },
                  {
                    title: 'Ca B',
                    dataIndex: 'DV_B',
                    key: 'TT_A',
                    align: 'right',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[1].doanhThuThuocKeToa) || 0,
                  },
                  {
                    title: 'Ca C',
                    dataIndex: 'DV_C',
                    key: 'DV_C',
                    align: 'right',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[2].doanhThuThuocKeToa) || 0,
                  },
                  {
                    title: 'Tổng toa thuốc',
                    dataIndex: 'totalTT',
                    key: 'totalTT',
                    onCell: () => ({
                      style: {
                        minWidth: 110,
                      },
                    }),
                    align: 'right',
                    render: (_, record) => formattedNumber(record.tongTienThuocKeToa) || 0,
                  },
                ],
              },
              {
                title: 'Bán lẻ',
                dataIndex: 'tongTienThuocBanLe',
                key: 'tongTienThuocBanLe',
                align: 'center',
                children: [
                  {
                    title: 'Ca A',
                    dataIndex: 'BL_A',
                    key: 'BL_A',
                    align: 'right',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[0].doanhThuThuocBanLe) || 0,
                  },
                  {
                    title: 'Ca B',
                    dataIndex: 'BL_B',
                    key: 'BL_B',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    align: 'right',
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[1].doanhThuThuocBanLe) || 0,
                  },
                  {
                    title: 'Ca C',
                    dataIndex: 'BL_C',
                    key: 'BL_C',
                    align: 'right',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[2].doanhThuThuocBanLe) || 0,
                  },
                  {
                    title: 'Tổng bán lẻ',
                    dataIndex: 'totalCard',
                    key: 'totalCard',
                    align: 'right',
                    onCell: () => ({
                      style: {
                        minWidth: 110,
                      },
                    }),
                    render: (_, record) => formattedNumber(record.tongTienThuocBanLe) || 0,
                  },
                ],
              },
              {
                title: 'vaccine',
                dataIndex: 'tongTienThuocVacXin',
                key: 'tongTienThuocVacXin',
                align: 'center',
                children: [
                  {
                    title: 'Ca A',
                    dataIndex: 'BL_A',
                    key: 'BL_A',
                    align: 'right',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[0].doanhThuThuocVacXin) || 0,
                  },
                  {
                    title: 'Ca B',
                    dataIndex: 'BL_B',
                    key: 'BL_B',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    align: 'right',
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[1].doanhThuThuocVacXin) || 0,
                  },
                  {
                    title: 'Ca C',
                    dataIndex: 'BL_C',
                    key: 'BL_C',
                    align: 'right',
                    onCell: () => ({
                      style: {
                        minWidth: 60,
                      },
                    }),
                    render: (_, record) =>
                      formattedNumber(record.doanhThuCaLamViecs[2].doanhThuThuocVacXin) || 0,
                  },
                  {
                    title: 'Tổng vaccine',
                    dataIndex: 'totalCard',
                    key: 'totalCard',
                    align: 'right',
                    onCell: () => ({
                      style: {
                        minWidth: 110,
                      },
                    }),
                    render: (_, record) => formattedNumber(record.tongTienThuocVacXin) || 0,
                  },
                ],
              },
              {
                title: 'Tổng tiền',
                dataIndex: 'tongTienNgay',
                key: 'tongTienNgay',
                onCell: () => ({
                  style: {
                    minWidth: 90,
                  },
                }),
                align: 'right',
                render: (text) => text && formattedNumber(text),
                fixed: 'right',
              },
            ]}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default ReportBranch
