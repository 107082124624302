import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { branchService } from '../../services/branch/branchService'
import { recieveService } from '../../services/receive/recieveService'
import Swal from 'sweetalert2'
import { DetailBranch } from '../actions/BranchAction'
import { reportService } from '../../services/Report/reportService'
import moment from 'moment'
import 'moment/locale/vi'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* branchSaga() {
  yield takeLatest(typeAction.GET_INFO_BRANCH_LOGIN, function* branchLogin({ type, idChiNhanh }) {
    // yield console.log(payload);
    const result = yield call(() => branchService.getbranchLogin(idChiNhanh))

    yield put({
      type: typeAction.DISPATCH_BRANCH_LOGIN,
      payload: result.data,
    })
  })
  // LÂY THÔNG TIN CHI TIẾT CHI NHÁNH
  yield takeLatest(typeAction.GET_INFO_BRANCH, function* branchLogin({ type, idChiNhanh }) {
    const result = yield call(() => branchService.getInfoBranch(idChiNhanh))

    yield put({
      type: typeAction.DISPATCH_INFO_BRANCH,
      payload: result.data,
    })
  })
  // ---- LẤY DANH SÁCH CHI NHÁNH THEO CHUYÊN KHOA-------//
  yield takeLatest(
    typeAction.GET_BRANCH_BY_CHUYEN_KHOA_AND_COMPANY,
    function* branchByChuyenKhoa({ type, idct, idchuyenKhoa, setData }) {
      const result = yield call(() => reportService.getBranchByChuyenKhoa(idct, idchuyenKhoa))
      setData(result.data)
    },
  )
  yield takeLatest(typeAction.GET_LIST_BRANCH_API, function* ListBranch({ type, id }) {
    // yield console.log(payload);
    try {
      const result = yield call(() => branchService.getListBranch(id))
      yield put({
        type: typeAction.DISPATCH_LIST_BRANCH,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  yield takeLatest(typeAction.GET_DEFAULT_BRANCH, function* getDefaultBranch({ type, payload }) {
    try {
      const result = yield call(() => branchService.getDefaultBranch(payload))
      yield put({
        type: typeAction.DISPATCH_DEFAULT_BRANCH,
        payload: result.data.idChiNhanh,
      })
    } catch (err) {
      yield put({
        type: typeAction.DISPATCH_DEFAULT_BRANCH,
        payload: '',
      })
      yield console.log(err)
    }
  })
  //----------- thống kê doanh thu chi nhánh
  yield takeLatest(typeAction.THONG_KE_DONG_MO_CA, function* thongKeDoanhThu({ type }) {
    const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
    const id_company = Number(localStorage.getItem('id_company'))
    const today = moment().format('YYYY-MM-DD')
    try {
      let idChiNhanhs = `&idChiNhanhs=${BranchLogin}&`
      const result = yield call(() =>
        reportService.getReportTotalByBranch('', id_company, idChiNhanhs, today, today),
      )
      yield put({
        type: typeAction.DISTPATCH_THONG_KE_DONG_MO_CA,
        payload: result.data[0],
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  yield takeLatest(typeAction.GET_LIST_CALAMVIEC, function* fetchListCaLamViec({ type, payload }) {
    try {
      const result = yield call(() => branchService.getCaLamViet())
      yield put({
        type: typeAction.DISPATCH_lIST_CALAMVIEC,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  // LẤY DÁNH SÁCH NHÂN VIÊN VÀ TỈNH DỂ TẠO CHI NHÁNH
  yield takeLatest(
    typeAction.GET_COUNTRY_AND_STAFFS_BRANCH,
    function* listInfoStaffsAndCountry({ type }) {
      try {
        const staffs = yield call(() => branchService.getStaffs())
        yield put({
          type: typeAction.DISPATCH_STAFFS_BRANCH,
          payload: staffs.data,
        })
        const country = yield call(() => recieveService.getTinhTP())
        yield put({
          type: typeAction.DISPATCH_COUNTRY_BRANCH,
          payload: country.data,
        })
        const quan = yield call(() => recieveService.getQuanHuyen(46)) // lấy HCM
        yield put({
          type: typeAction.DISPATCH_QUAN_BRANCH,
          payload: quan.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )

  // LẤY DANH SÁCH QUẬN THEO CHI NHÁNH
  yield takeLatest(typeAction.GET_QUAN_BY_TINH_BRANCH, function* listQuan({ type, IDCOUNTRY }) {
    try {
      const result = yield call(() => recieveService.getQuanHuyen(IDCOUNTRY))
      yield put({
        type: typeAction.DISPATCH_QUAN_BRANCH,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  // LẤY DANH SÁCH HUyện  THEO CHI NHÁNH
  yield takeLatest(typeAction.GET_HUYEN_BY_QUAN_BRANCH, function* listHuyen({ type, IDQUAN }) {
    try {
      const result = yield call(() => recieveService.getPhuongXa(IDQUAN))
      yield put({
        type: typeAction.DISPATCH_PHUONG_BRANCH,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  // THÊM MỚI CHI NHÁNH
  yield takeLatest(
    typeAction.ADD_NEW_BRANCH,
    function* ListDetailBranch({ type, form, resetForm }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        yield call(() => branchService.postBranch(form))
        Toast.fire({
          icon: 'success',
          title: 'Thêm Chi nhánh thành công!',
        })
        yield resetForm()

        const detailForLog = {
          tenChiNhanh: form.tenChiNhanh,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          logAction.THEM_MOI_CHI_NHANH,
          true,
          MENU_CONSTANTS.CHI_NHANH,
          null,
          detailForLog,
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Thêm Chi nhánh thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.CHI_NHANH,
          detailErrorPayload,
        )
      }
    },
  )
  // xoá chi nhánh
  yield takeLatest(typeAction.DELETE_BRANCH, function* deleteBranch({ type, id, onReload }) {
    try {
      yield call(() => branchService.deleteBranchByID(id))
      Toast.fire({
        icon: 'success',
        title: 'Xoá chi nhánh thành công!',
      })
      yield onReload()
    } catch (err) {
      console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'Xoá chi nhánh thất bại!',
      })
    }
  })
  //-----------------UPDATE CHI NHÁNH ----------------//
  // xoá chi nhánh
  yield takeLatest(typeAction.UPDATE_BRANCH, function* updateBranch({ type, form }) {
    const { infoUser } = yield select((state) => state.userReducer)
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => branchService.updateBranch(form))
      yield put(DetailBranch(form.idChiNhanh))
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      Toast.fire({
        icon: 'success',
        title: 'cập nhật chi nhánh thành công!',
      })
      const detailForLog = {
        tenChiNhanh: form.tenChiNhanh,
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        logAction.CAP_NHAT_CHI_NHANH,
        true,
        MENU_CONSTANTS.CHI_NHANH,
        null,
        detailForLog,
      )
    } catch (error) {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'cập nhật chi nhánh thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.CHI_NHANH,
        detailErrorPayload,
      )
    }
  })
  yield takeLatest(
    typeAction.GET_LIST_DETAIL_BRANCH,
    function* ListDetailBranch({ type, keyword, IDCompany, from, to, setIsLoading }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield setIsLoading(true)
        const result = yield call(() =>
          branchService.getListBranchDetail(IDCompany, from, to, keyword),
        )
        yield put({
          type: typeAction.DISTPATCH_LIST_DETAIL_BRANCH,
          payload: result.data,
        })
        yield setIsLoading(false)
        //  lấy tên công ty theo IDCompany để ghi log
        const company = result.data.find((item) => item.idChiNhanh === IDCompany)

        const detailForLog = {
          keyword,
          IDCompany,
          from,
          to,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.GET,
          logAction.XEM_DANH_SACH_CHI_NHANH,
          true,
          MENU_CONSTANTS.CHI_NHANH,
          null,
          detailForLog,
        )
      } catch (error) {
        setIsLoading(false)
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.CHI_NHANH,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(typeAction.GET_BRANCH_NOT_VP, function* getBranchNotVP({ type, id, setData }) {
    try {
      const result = yield call(() => branchService.getBranchNotVP(id))
      setData(result.data)
    } catch (err) {
      console.log(err)
    }
  })
}
