import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DatePicker,
  Select,
  TreeSelect,
  Button,
  Card,
  ConfigProvider,
  Table,
  Input,
  Space,
} from 'antd'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import IconButton from '@mui/material/IconButton'
import { SearchOutlined } from '@ant-design/icons'
import SyncIcon from '@mui/icons-material/Sync'
import { reportService } from '../../../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { khoVTservice } from '../../../../../services/khoVT/khoVTservice'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../../utils/Toast'
import dayjs from 'dayjs'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { exportExcelformat } from '../../../../../utils/export/exportExcelFormat'
import { getListNameChiNhanh } from '../../../../../utils/algorithm'
import { addLog } from '../../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../../utils/log/logConstant'
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const { SHOW_PARENT } = TreeSelect

const TongQuan = () => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)

  const [branchs, setBranchs] = useState([])
  const [chuyenkhoas, setChuyenKhoas] = useState([])
  const [searchText, setSearchText] = useState('')
  const searchInput = useRef(null)
  const [searchedColumn, setSearchedColumn] = useState('')
  const [dataSource, setDataSource] = useState([])
  const [valueExport, setValueExport] = useState([])
  const [form, setForm] = useState({
    idCompany: ID_COMPANY,
    idChuyenKhoa: '',
    idBranch: [ID_BRANCH],
    form: now,
    to: now,
  })

  const nameChiNhanh = useMemo(() => getListNameChiNhanh(form.idBranch, branchs), [form, branchs])
  const nameCompany = useMemo(
    () => listCompany?.find((item) => item?.idct === form.idCompany)?.tenct,
    [form, listCompany],
  )
  const nameChuyenKhoa = useMemo(
    () =>
      !form.idChuyenKhoa
        ? 'Tất cả'
        : chuyenkhoas?.find((item) => item?.idChuyenKhoa === form.idChuyenKhoa)?.tenChuyenKhoa,
    [form, chuyenkhoas],
  )

  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoas(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranchByChuyenKhoa = async (idct, value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(idct, value)
      setBranchs(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangSelected = (name) => (value) => {
    setForm((prevState) => ({ ...prevState, [name]: value, idBranch: ['all'] }))
    fetchBranchByChuyenKhoa(form?.idCompany, value)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branchs?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const onChangeTable = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleDatePicker = (name) => (date, dateString) => {
    const value = moment(dateString, dateFormat).format(momentDate)
    setForm((prevState) => ({ ...prevState, [name]: value }))
  }
  const tProps = {
    treeData,
    value: form?.idBranch,
    onChange: (value) => setForm((prevState) => ({ ...prevState, idBranch: value })),
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const fetchData = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT524',
      )

      let idcn = ''
      if (form.idBranch[0] !== 'all') {
        idcn = form.idBranch?.map((id) => `&idcn=${id}`).join('&')
      } else {
        form.idBranch = branchs?.map((item) => item.idChiNhanh)
        idcn = form.idBranch?.map((id) => `&idcn=${id}`).join('&')
      }
      const { data } = await reportService.getTongQuanTTV(form.idCompany, idcn, form.form, form.to)
      setDataSource(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.BAO_CAO_TONG_QUAN_TTV,
        true,
        MENU_CONSTANTS.BAO_CAO_TTV,
        null,
        {
          dateFrom: form.form,
          dateTo: form.to,
          nameChiNhanh,
          nameCompany,
          nameChuyenKhoa,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.BAO_CAO_TTV,
        detailErrorPayload,
      )
    } finally {
    }
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranchByChuyenKhoa(ID_COMPANY, '')
    fetchChuyenKhoa()
  }, [])
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT525',
      )

      const formattedData = valueExport?.map((item) => ({
        'Chi nhánh': item?.tenchinhanh,
        'Ngày khai trương':
          item?.ngaykhaitruong && moment(item?.ngaykhaitruong).format('DD/MM/YYYY'),
        'Loại hình': item?.loaihinh,
        'Số thẻ còn hoạt đông': item?.sotheconhoatdong,
        'Số thẻ mới': item?.sothemoi,
      }))
      const name = 'Báo cáo tổng quan thẻ thành viên'
      exportExcelformat(formattedData, name)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_BAO_CAO_TONG_QUAN_TTV,
        true,
        MENU_CONSTANTS.BAO_CAO_TTV,
        null,
        {
          dateFrom: form.form,
          dateTo: form.to,
          nameChiNhanh,
          nameCompany,
          nameChuyenKhoa,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const detailErrorPayload = `Có lỗi khi xuất file excel tổng quan báo cáo TTV \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.TIEP_NHAN,
        detailErrorPayload,
      )
    }
  }

  const totalActiveMembers = dataSource.reduce((sum, branch) => sum + branch.sotheconhoatdong, 0)
  const totalNewCards = dataSource.reduce((sum, branch) => sum + branch.sothemoi, 0)
  useEffect(() => {
    setValueExport(dataSource)
  }, [dataSource])
  return (
    <>
      <div className="flex gap-5 justify-between border-dotted border-b-0">
        <div className="flex flex-wrap gap-5 items-center mb-2">
          <div className="w-80">
            <Select
              showSearch
              value={form.idCompany}
              onChange={(value) => {
                fetchBranchByChuyenKhoa(value, '')
                // setIdChiNhanh(null)
                setForm((prevState) => ({
                  ...prevState,
                  idCompany: value,
                  idChuyenKhoa: '',
                  idBranch: [''],
                }))
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className="w-56">
            <Select
              onChange={onChangSelected('idChuyenKhoa')}
              className="w-full"
              value={form.idChuyenKhoa}
              options={[
                { label: 'Tất cả chuyên khoa', value: '' },
                ...chuyenkhoas.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
            />
          </div>
          <div className="w-64 ">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-3">
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('form')}
                format={dateFormat}
              />
              <span className="font-semibold text-lg">-</span>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('to')}
                format={dateFormat}
              />
            </div>
            <div className="flex gap-3">
              <IconButton onClick={fetchData} color="primary" aria-label="delete" size="small">
                <SyncIcon />
              </IconButton>
            </div>
            <div className="font-semibold ">
              <Button
                onClick={exportToExcel}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Card className="mb-4 shadow" title="Tổng cộng">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm font-medium">Tổng thẻ hoạt động:</p>
            <p className="text-2xl font-bold">{totalActiveMembers.toLocaleString()}</p>
          </div>
          <div>
            <p className="text-sm font-medium">Tổng thẻ mới tạo:</p>
            <p className="text-2xl font-bold">{totalNewCards.toLocaleString()}</p>
          </div>
        </div>
      </Card>

      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#EFEFEF',
                footerBg: '#EFEFEF',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            onChange={onChangeTable}
            dataSource={dataSource}
            pagination={false}
            scroll={{ x: 1000, y: '55vh' }}
            bordered
            columns={[
              {
                title: 'STT',
                key: 'stt',
                align: 'center',
                render: (text, record, index) => ++index,
                width: 60,
              },
              {
                title: 'Chi nhánh',
                key: 'tenchinhanh',
                dataIndex: 'tenchinhanh',
              },
              {
                title: 'Ngày khai trương',
                key: 'ngaykhaitruong',
                dataIndex: 'ngaykhaitruong',
                width: 130,
                align: 'center',
                render: (text) => text && moment(text).format('DD/MM/YYYY'),
              },
              {
                title: 'Loại hình',
                key: 'loaihinh',
                width: 200,
                dataIndex: 'loaihinh',
                ...getColumnSearchProps('loaihinh'),
              },
              {
                title: 'Số thẻ còn hoạt động',
                key: 'sotheconhoatdong',
                dataIndex: 'sotheconhoatdong',
                width: 200,
                render: (text) => formattedNumber(text),
              },
              {
                title: 'Số thẻ mới',
                key: 'sothemoi',
                dataIndex: 'sothemoi',
                width: 200,
                render: (text) => formattedNumber(text),
              },
            ]}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default TongQuan
