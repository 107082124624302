import { Input, Modal } from 'antd'
import React, { useState } from 'react'
import ToastCus from '../../../../../utils/Toast'
import { https } from '../../../../../services/apiService'
import { useSelector } from 'react-redux'
import { addLog } from '../../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../../utils/log/logConstant'

const ModalSms = ({ isOpen, cancelModal, templateChoosed, optionDau }) => {
  const [loading, setLoading] = useState(false)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listBN } = useSelector((state) => state.smsReducer)
  const [otp, setOtp] = useState('')
  const onChange = (text) => {
    setOtp(text)
  }
  const sharedProps = {
    onChange,
  }

  const handleSendSms = async () => {
    if (otp === '') {
      ToastCus.fire({
        icon: 'error',
        text: 'Vui lòng nhập mã OTP',
      })
      return
    }
    setLoading(true)
    try {
      const userSended = listBN.filter((item) => item?.guisms).map((value) => value.idbn)
      await https.post(
        `SMSGuiTinNhan/XacThucOTP?nguoiYeuCau=${infoUser?.idnv}&maOTP=${otp}&idTemplate=${templateChoosed?.idtp}&noiDungTinNhan=${optionDau === '0' ? templateChoosed.templatE_CODAU : templateChoosed.templatE_KHONGDAU}`,
        userSended,
      )
      ToastCus.fire({
        icon: 'success',
        text: 'SMS đã được gửi',
      })
      setOtp('')
      cancelModal()
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        logAction.XAC_NHAN_GUI_SMS,
        true,
        MENU_CONSTANTS.GUI_SMS,
        null,
        {
          maOTP: otp,
          nguoiGui: infoUser.tenNV,
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Có lỗi xảy ra khi gửi SMS',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.GUI_SMS,
        detailErrorPayload,
      )
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    cancelModal()
    setOtp('')
    setLoading(false)
  }
  return (
    <Modal
      width={400}
      open={isOpen}
      okText="Gửi Sms"
      cancelText="Huỷ bỏ"
      onCancel={handleCancel}
      onOk={handleSendSms}
      confirmLoading={loading}
    >
      <div className="flex flex-col mt-4">
        <h2 className="font-semibold text-lg mb-2">Vui lòng nhập mã OTP</h2>
        <Input.OTP value={otp} length={4} {...sharedProps} />
      </div>
    </Modal>
  )
}

export default ModalSms
