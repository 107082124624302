import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { tmtdClinicService } from '../../services/tmtdClinic/tmtdClinicService'
import { childrenClinicService } from '../../services/childrenClinic/childrenClinicService'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* tmtdClinicSaga() {
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(typeAction.GET_BN_CHO_KHAM_TMTD, function* BNPendingTMTD({ type, idcnpk }) {
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    const { infoUser } = yield select((state) => state.userReducer)
    const { phongKham } = yield select((state) => state.receiveReducer)
    try {
      const tenPhongKham = phongKham?.find((pk) => pk.idcnpk === idcnpk)?.tenPhongKham || ''
      const result = yield call(() => tmtdClinicService.getBNChoKhamTMTD(idcnpk))
      yield put({
        type: typeAction.DISPATCH_BN_CHO_KHAM_TMTD,
        payload: result.data,
      })
      yield call(
        // lưu tên bs khám của chi nhánh phòng khám hiện tại để hiện thị lên ds bn chờ khám của màn hình gọi số
        () =>
          infoUser?.tenNhom?.toLowerCase()?.includes('bác sĩ') &&
          childrenClinicService.putBsKhamCnPk({
            idcnpk: idcnpk,
            idbskham: infoUser?.idnv,
          }),
      )
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.XEM_DANH_SACH_BENH_NHAN_CHO_KHAM_THEO_PHONGKHAM,
        true,
        MENU_CONSTANTS.KHAMTMTD,
        null,
        {
          phongkham: tenPhongKham,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.XEM_DANH_SACH_BENH_NHAN_CHO_KHAM_THEO_PHONGKHAM,
        true,
        MENU_CONSTANTS.KHAMTMTD,
        null,
        detailErrorPayload,
      )
    }
  })
  // LẤY DANH SÁCH BỆNH NHÂN ĐANG KHÁM VÀ ĐÃ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_BN_DANG_VA_DA_KHAM_TMTD,
    function* BNDangVaDaKhamTMTD({ type, idnv, idcn, tuNgay, denNgay }) {
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const listDangKham = yield call(() =>
          tmtdClinicService.getBNDangKhamTMTD(idnv, idcn, tuNgay, denNgay),
        )
        const listDaKham = yield call(() =>
          tmtdClinicService.getBNDaKhamTMTD(idnv, idcn, tuNgay, denNgay),
        )
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DANH_SACH_BENH_NHAN_DANG_KHAM_VA_DA_KHAM_THEO_PHONGKHAM,
          true,
          MENU_CONSTANTS.KHAMTMTD,
          null,
          {
            tuNgay: tuNgay,
            denNgay: denNgay,
          },
        )
        yield put({
          type: typeAction.DISPATCH_BN_DANG_KHAM_TMTD,
          payload: listDangKham.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_KHAM_TMTD,
          payload: listDaKham.data,
        })
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DANH_SACH_BENH_NHAN_DANG_KHAM_VA_DA_KHAM_THEO_PHONGKHAM,
          true,
          MENU_CONSTANTS.KHAMTMTD,
          null,
          detailErrorPayload,
        )
      }
    },
  )
}
