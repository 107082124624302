import React from 'react'
import { Button, Form, Input, Col, Row, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { createTemplateSMS } from '../../../../../store/actions/smsAction'
import { UserAddOutlined } from '@ant-design/icons'
import AccountSMS from '../AccountSMS/AccountSMS'
const styleLabel = {
  style: {
    fontWeight: 600,
  },
}
const FromCreateTemplate = () => {
  const { accountSMS } = useSelector((state) => state.smsReducer)
  const dispatch = useDispatch()
  const [show, setShow] = React.useState(false)
  const [form] = Form.useForm()
  const onFinish = (values) => {
    dispatch(createTemplateSMS(values, onReset))
  }
  const onReset = () => {
    form.resetFields()
  }
  const ChuyenKhoas = ['NHI', 'SAN', 'MEDICAL']
  return (
    <>
      <div className="border rounded-md p-2 xl:p-5">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold ">Tạo SMS template</h2>
          <Button onClick={() => setShow(true)} icon={<UserAddOutlined />} type="link">
            Tài khoản
          </Button>
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row
            gutter={{
              xl: 16,
            }}
          >
            <Col lg={24} xl={6}>
              {' '}
              <Form.Item
                labelCol={styleLabel}
                name="tieude"
                style={{ marginBottom: 5 }}
                label="Tiêu đề"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Nhập tiêu đề" />
              </Form.Item>
            </Col>
            <Col lg={24} xl={6}>
              {' '}
              <Form.Item
                labelCol={styleLabel}
                name="matp"
                style={{ marginBottom: 5 }}
                label="Mã template"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Nhập mã Template" />
              </Form.Item>
            </Col>
            <Col lg={24} xl={6}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                labelCol={styleLabel}
                name="chuyenkhoa"
                label="Chuyên khoa"
                required
              >
                <Select
                  placeholder="Chọn Chuyên khoa "
                  options={ChuyenKhoas?.map((value) => ({
                    label: value,
                    value: value,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col lg={24} xl={6}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                labelCol={styleLabel}
                name="idtaikhoan"
                label="Loại tài khoản"
                required
              >
                <Select
                  placeholder="Chọn loại tài khoản"
                  options={accountSMS?.map((item) => ({
                    label: (
                      <p>
                        {' '}
                        {item.brandname}{' '}
                        <span className="text-gray-400">( account: {item.taikhoan} )</span>
                      </p>
                    ),
                    value: item.idtaikhoan,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{
              xl: 16,
            }}
          >
            <Col lg={24} xl={12}>
              <Form.Item labelCol={styleLabel} name="templatE_CODAU" label="Nội dung có dấu">
                <Input.TextArea
                  style={{
                    height: 80,
                    resize: 'none',
                  }}
                  showCount
                  placeholder="Nội dung có dấu"
                />
              </Form.Item>
            </Col>
            <Col lg={24} xl={12}>
              <Form.Item labelCol={styleLabel} name="templatE_KHONGDAU" label="Nội dung Không dấu">
                <Input.TextArea
                  style={{
                    height: 80,
                    resize: 'none',
                  }}
                  showCount
                  placeholder="Nội dung không dấu"
                />
              </Form.Item>
            </Col>
          </Row>
          
        </Form>
      </div>
      <AccountSMS show={show} setShow={setShow} />
    </>
  )
}

export default FromCreateTemplate
