import React, { useCallback, useEffect, useState } from 'react'
import { Table, Modal, Input, ConfigProvider, Button, Checkbox } from 'antd'
import CachedIcon from '@mui/icons-material/Cached'
import _ from 'lodash'
import {
  SearchOutlined,
  PlusOutlined,
  FileExcelOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteLoiDanBs,
  fetchLoiDanBsDanhMuc,
  getInfoLoiDanById,
} from '../../../store/actions/loiDanBsAction'
import CreateNew from './CreateNew'
import UpdateLoiDan from './UpdateLoiDan'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import { addLog } from '../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../utils/log/logConstant'
const { confirm } = Modal

const LoiDan = () => {
  const dispatch = useDispatch()
  const { LoiDans } = useSelector((state) => state.LoiDanBsReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isOpen, setIsOpen] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [search, setSearch] = useState('')

  const maQuyenXemDs = 'QHT438'
  const maQuyenXemCt = 'QHT440'
  const maQuyenXuatExcel = 'QHT439'
  const maQuyenThemLd = 'QHT437'
  const maQuyenXoaLd = 'QHT442'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        dispatch(fetchLoiDanBsDanhMuc(search))
        break
      case maQuyenXemCt:
        onClickInfoLoiDan(infoNew)
        break
      case maQuyenXuatExcel:
        exportToExcel()
        break
      case maQuyenThemLd:
        setIsOpen(true)
        break
      case maQuyenXoaLd:
        showDeleteConfirm(infoNew)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXemCt:
        setIsUpdate(false)
        break
      case maQuyenXuatExcel:
        //
        break
      case maQuyenThemLd:
        setIsOpen(false)
        break
      case maQuyenXoaLd:
        //
        break
      default:
        return false
    }
  }

  const reloadData = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  const onClickDelete = (record) => {
    dispatch(deleteLoiDanBs(record, reloadData))
  }
  const debounceSearch = useCallback(
    _.debounce((keyword) => {
      setSearch(keyword)
    }, 300),
    [],
  )
  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Bạn có chắc muốn xoá lời dặn này ?',
      icon: <ExclamationCircleFilled />,
      content: record.loiDan,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        onClickDelete(record)
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  }
  const onClickInfoLoiDan = (info) => {
    setIsUpdate(true)
    dispatch(getInfoLoiDanById(info))
  }
  const exportToExcel = () => {
    try {
      const data = LoiDans?.map((item, index) => {
        return {
          STT: index + 1,
          'Mã lời dặn': item.maLoiDan,
          'Lời Dặn': item.loiDan,
          'Ghi chú': item.ghiChu,
          'Sử dụng': item.suDung === 1 ? 'TRUE' : 'FALSE',
        }
      })
      const name = 'Lời dặn bác sĩ'
      data?.length && exportExcelformat(data, name)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_DS_LOI_DAN,
        true,
        MENU_CONSTANTS.LOI_DAN,
        null,
        {
          keyword: search ?? '',
        },
      )
    } catch (error) {
      console.log(error)
      const detailErrorPayload = `Có lỗi xảy ra khi xuất file excel lời dặn \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.LOI_DAN,
        detailErrorPayload,
      )
    }
  }
  useEffect(() => {
    if (infoUser) {
      reloadData()
    }
  }, [search, infoUser])
  return (
    <>
      <div className="bg-[#EFEFEF] h-full p-3 ">
        <div className="bg-white h-full border rounded-xl  p-2">
          <div className="mb-4 flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
            <div className="flex items-center gap-4">
              <h2 className="font-semibold text-lg text-gray-500">LỜI DẶN BÁC SĨ</h2>
            </div>
            <div className="flex flex-col gap-2 sm:flex-row sm:items-center">
              <Input
                placeholder="Tìm kiếm..."
                prefix={<SearchOutlined className="text-gray-400" />}
                onChange={(e) => debounceSearch(e.target.value)}
                style={{ width: 300 }}
                className="text-sm"
              />
              <Button type="primary" icon={<CachedIcon />} onClick={reloadData}>
                Tải lại
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() =>
                  dispatch(
                    putCheckQuyenThaoTacAction(maQuyenThemLd, null, actionIsTrue, actionIsFalse),
                  )
                }
              >
                Tạo mới
              </Button>
              <Button
                disabled={!LoiDans?.length}
                icon={<FileExcelOutlined />}
                onClick={() =>
                  dispatch(
                    putCheckQuyenThaoTacAction(maQuyenXuatExcel, null, actionIsTrue, actionIsFalse),
                  )
                }
              >
                Xuất Excel
              </Button>
            </div>
          </div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              bordered
              loading={isLoadingTable}
              dataSource={LoiDans}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'STT',
                  key: 1,
                  width: 60,
                  align: 'center',
                  render: (text, _, index) => ++index,
                },
                {
                  title: 'Mã lời dặn',
                  dataIndex: 'maLoiDan',
                  key: 2,
                  width: 200,
                },
                {
                  title: 'Lời dặn',
                  dataIndex: 'loiDan',
                  key: 3,
                  width: '50%',
                },
                {
                  title: 'Ghi chú',
                  dataIndex: 'ghiChu',
                  key: 4,
                },
                {
                  title: 'Sử dụng',
                  dataIndex: 'suDung',
                  key: 5,
                  width: 90,
                  align: 'center',
                  render: (text) => <Checkbox checked={text === 1} />,
                },
                {
                  title: 'Hành động',
                  dataIndex: 'action',
                  key: 6,
                  width: 90,
                  align: 'center',
                  render: (_, record) => (
                    <ul className="flex gap-3 justify-center">
                      <li
                        onClick={() =>
                          dispatch(
                            putCheckQuyenThaoTacAction(
                              maQuyenXoaLd,
                              record,
                              actionIsTrue,
                              actionIsFalse,
                            ),
                          )
                        }
                        className="text-red-500 border bg-red-100 rounded hover:bg-red-500 cursor-pointer hover:text-white duration-200"
                      >
                        <DeleteOutlined className="p-1" style={{ fontSize: 16 }} />
                      </li>
                      <li
                        onClick={() =>
                          dispatch(
                            putCheckQuyenThaoTacAction(
                              maQuyenXemCt,
                              record,
                              actionIsTrue,
                              actionIsFalse,
                            ),
                          )
                        }
                        className="text-green-700 border bg-green-100 rounded hover:bg-green-700 cursor-pointer hover:text-white duration-200"
                      >
                        <EditOutlined className="p-1" style={{ fontSize: 16 }} />
                      </li>
                    </ul>
                  ),
                },
              ]}
              // columns={columns}
              rowKey="id"
              scroll={{ x: 1000, y: '78vh' }}
              pagination={false}
            />
          </ConfigProvider>
        </div>
      </div>
      <CreateNew isOpen={isOpen} setIsOpen={setIsOpen} reloadData={reloadData} />
      <UpdateLoiDan isUpdate={isUpdate} setIsUpdate={setIsUpdate} reloadData={reloadData} />
    </>
  )
}

export default LoiDan
