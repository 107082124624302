import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { phcnClinicService } from '../../services/phcnClinic/phcnClinicService'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* phcnClinicSaga() {
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_BN_CHO_KHAM_PHCN,

    function* BNPendingPHCN({ type, idcn }) {
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const { infoUser } = yield select((state) => state.userReducer)
      const { phongKham } = yield select((state) => state.receiveReducer)
      try {
        const tenPhongKham = phongKham?.find((pk) => pk.idcn === idcn)?.tenPhongKham || ''
        const result = yield call(() => phcnClinicService.getBNChoKhamPHCN(idcn))
        yield put({
          type: typeAction.DISPATCH_BN_CHO_KHAM_PHCN,
          payload: result.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DANH_SACH_BENH_NHAN_CHO_KHAM_THEO_PHONGKHAM,
          true,
          MENU_CONSTANTS.KHAMPHCN,
          null,
          {
            phongkham: tenPhongKham,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DANH_SACH_BENH_NHAN_CHO_KHAM_THEO_PHONGKHAM,
          true,
          MENU_CONSTANTS.KHAMPHCN,
          null,
          detailErrorPayload,
        )
      }
    },
  )
  // LẤY DANH SÁCH BỆNH NHÂN ĐANG KHÁM VÀ ĐÃ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_BN_DANG_VA_DA_KHAM_PHCN,
    function* BNDangVaDaKhamPHCN({ type, idcn, tuNgay, denNgay }) {
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const listDangKham = yield call(() =>
          phcnClinicService.getBNDangKhamPHCN(idcn, tuNgay, denNgay),
        )
        const listDaKham = yield call(() =>
          phcnClinicService.getBNDaKhamPHCN(idcn, tuNgay, denNgay),
        )

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DANH_SACH_BENH_NHAN_DANG_KHAM_VA_DA_KHAM_THEO_PHONGKHAM,
          true,
          MENU_CONSTANTS.KHAMPHCN,
          null,
          {
            tuNgay: tuNgay,
            denNgay: denNgay,
          },
        )
        yield put({
          type: typeAction.DISPATCH_BN_DANG_KHAM_PHCN,
          payload: listDangKham.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_KHAM_PHCN,
          payload: listDaKham.data,
        })
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DANH_SACH_BENH_NHAN_DANG_KHAM_VA_DA_KHAM_THEO_PHONGKHAM,
          true,
          MENU_CONSTANTS.KHAMPHCN,
          null,
          detailErrorPayload,
        )
      }
    },
  )
}
