import { Input, Select } from 'antd';
import React from 'react'
import { SearchOutlined} from '@ant-design/icons'
const CustomInput = ({ label, name, formik, checkUpdate, type = 'input', options = [], onSelectChange, debounceSearch }) => {
    return (
        <div className="flex gap-1 mt-1">
          <label className="font-semibold w-44 text-end">{label}</label>
          {type === 'input' ? (
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              onChange={formik.handleChange}
              value={formik.values[name]}
              name={name}
              size="small"
            />
          ) : type === 'select' ? (
            <Select
              disabled={checkUpdate}
              style={{ color: 'black' }}
              showSearch
              filterOption={false}
              className="w-full"
              size="small"
              onChange={onSelectChange}
              onSearch={debounceSearch}
              suffixIcon={<SearchOutlined />}
              notFoundContent={null}
              value={formik.values[name]}
              options={options}
            />
          ) : null}
        </div>
      );
}

export default CustomInput