import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { dangKyKhamOnlineService } from '../../services/dangKyKhamOnline/dangKyKhamOnlineService'
import ToastCus from '../../utils/Toast'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
export function* doiTacKHSaga() {
  // ADD ĐỐI TÁC KHÁCH HÀNG
  yield takeLatest(
    typeAction.POST_DOI_TAC_KH,
    function* postDoiTacKHSaga({ type, form, resetForm }) {
      try {
        const { infoUser } = yield select((state) => state.userReducer)
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => dangKyKhamOnlineService.postDoiTacKH([form]))

        yield call(() =>
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.THEM_DOI_TAC,
            true,
            MENU_CONSTANTS.DOITAC,
            null,
            form,
          ),
        )
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        const { infoUser } = yield select((state) => state.userReducer)
        const { PkDangNhap } = yield select((state) => state.branchReducer)

        yield call(() =>
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            null,
            false,
            MENU_CONSTANTS.DOITAC,
            form,
          ),
        )
        console.log(err)

        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // UPDATE ĐỐI TÁC KHÁCH HÀNG
  yield takeLatest(typeAction.PUT_DOI_TAC_KH, function* putDoiTacKHSaga({ type, form, resetForm }) {
    try {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => dangKyKhamOnlineService.putDoiTacKH(form))
      yield call(() => resetForm())
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })

      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.SUA_DOI_TAC,
        true,
        MENU_CONSTANTS.DOITAC,
        null,
        form,
      )
    } catch (err) {
      console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}
