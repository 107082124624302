import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik, useFormikContext } from 'formik'
import LayoutApp from '../../../../HOCs/LayoutApp'
import {
  Breadcrumb,
  ConfigProvider,
  Table,
  Input,
  InputNumber,
  notification,
  Space,
  Menu,
  Tooltip,
  Checkbox,
  Select,
  Segmented,
  Button as ButtonAntd,
} from 'antd'
import { Button } from '@mui/material'
import { PlusOutlined, SearchOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { Link, useParams } from 'react-router-dom'
import * as typeAction from '../../../../store/constants/constants'
import { listGroupsDichVuAction } from '../../../../store/actions/dichVuAction'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import {
  getListDichVuTheAction,
  addListDichVuTheAction,
  getListDichVuAction,
  putInfoLoaiTheAction,
  getListXetNghiemAction,
  getListCDHAAction,
} from '../../../../store/actions/detailCardAction'
import { detailCardService } from '../../../../services/card/detailCardService'
import { https } from '../../../../services/apiService'
import ModalEditPriceCN from './Modal/ModalEditPriceCN'
import ModalAdd from './Modal/ModalAdd'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import moment from 'moment'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')
const DetaildCard = () => {
  const ID_CTY = Number(localStorage.getItem('id_company'))
  const XN = 'Xét nghiệm'
  const KB = 'Khám bệnh'
  const CĐHA = 'Chẩn đoán hình ảnh'
  const now = moment()
  const { id } = useParams()
  const dispatch = useDispatch()
  const searchInput = useRef(null)
  const { listGroupCard, chuyenKhoa } = useSelector((state) => state.cartReducer)
  const { ListDichVu, groupsDichVu } = useSelector((state) => state.dichVuReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { listTheDichVu, listDichVuTheoNhom, infoLoaiThe, listXN, listCDHA } = useSelector(
    (state) => state.detailCardReducer,
  )
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [api, contextHolder] = notification.useNotification()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [idNhomDichVu, setIdNhomDichVu] = useState('1')
  const [isEdit, setIsEdit] = useState(false)
  const [valueSegmented, setValueSegmented] = useState(KB)
  const [loadingCN, setLoadingCN] = useState(false)
  const [listBranch, setListBranch] = useState(null)
  const [showModalEditPriceCN, setShowModalEditPriceCN] = useState(false)
  const [isModalAdd, setIsModalAdd] = useState(false)
  const [infoCNLT, setInfoCNLT] = useState(null)
  const [congTy, setCongTy] = useState(PkDangNhap?.idCongTy)

  const handleUpdate = async () => {
    setLoadingCN(true)
    try {
      await https.put(
        'TheLoai/UpdateSuDungTheLoaiChiNhanh',
        listBranch.map((item) => ({
          suDung: item.sudung,
          idCN: item.idchinhanh,
          id: item.idlt, //item.idcnpk, //rowActive.idPK,
        })),
      )
      ToastCus.fire({
        icon: 'success',
        text: 'Cập nhật thành công!',
      })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        text: 'Cập nhật thất bại!',
      })
    } finally {
      setLoadingCN(false)
    }
  }
  const handleCheckBox = (e, record) => {
    const { checked } = e.target
    const updatedListBranch = listBranch.map((item) =>
      item.idchinhanh === record.idchinhanh ? { ...item, sudung: checked ? 1 : 0 } : item,
    )
    setListBranch(updatedListBranch)
  }
  const fetchListChiNhanhByLoaiThe = async () => {
    setLoadingCN(true)
    try {
      const result = await detailCardService.getListChiNhanhByLoaiThe(id, congTy) // id là id loại thẻ
      setListBranch(result.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingCN(false)
    }
  }
  const handleSelect = (e) => {
    setListBranch((prev) => prev.map((item) => ({ ...item, sudung: e.target.checked ? 1 : 0 })))
    // if (e.target.checked) {
    // } else {
    //   setListBranch((prev) => prev.map((item) => ({ ...item, sudung: 0 })))
    // }
  }
  // chọn loại chỉ định
  const handleChangeSegmented = (value) => {
    setValueSegmented(value)
    if (value === KB) {
      // restChiDinh()
      setIdNhomDichVu('1')
      dispatch(getListDichVuAction('1', PkDangNhap?.idCongTy))
    }
    if (value === XN) {
      dispatch(getListXetNghiemAction(ID_CTY))
    }
    if (value === CĐHA) {
      dispatch(getListCDHAAction(ID_CTY))
    }
  }
  const handleSubmit = (value) => {
    dispatch(putInfoLoaiTheAction(value.idlt, value))
    setIsEdit(false)
  }
  const handleChangeSelectFormik = (name) => (value, opt) => {
    formik.setFieldValue(name, value > 0 && value < 999999999 ? value : 0)
  }
  const handleChangeSuDung = (value) => {
    formik.setFieldValue('sudung', value)
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idlt: infoLoaiThe?.idlt,
      loaithe: infoLoaiThe?.loaithe,
      tiensudung: infoLoaiThe?.tiensudung,
      idchuyenkhoa: infoLoaiThe?.idchuyenkhoa,
      idnhom: infoLoaiThe?.idnhom,
      sothang: infoLoaiThe?.sothang,
      nguoiupdate: infoUser?.tenNV,
      sudung: infoLoaiThe?.sudung,
      // ngaythai: DetailBN?.ngaythai,
      //ghichu: infoLoaiThe?.loaithe,
    },
    onSubmit: (values) => handleSubmit(values),
    // validationSchema: maternityClinicSchema,
  })
  const handleClickLuuDichVu = () => {
    dispatch(addListDichVuTheAction(listTheDichVu, infoLoaiThe?.idlt))
  }

  console.log('listTheDichVu', listTheDichVu)
  const handleChangeSoLanSuDung = (slsd, idDV, idLoaiDV) => {
    let Arr = []
    listTheDichVu?.map((item) => {
      if (item.iddv === idDV && item.loaithedichvu === idLoaiDV) {
        Arr.push({
          ...item,
          soLanSuDung: slsd === null ? 0 : slsd,
        })
      } else {
        Arr.push(item)
      }
    })
    load(Arr)
  }
  const handleChangePhanTramGiam = (ptg, idDV, idLoaiDV) => {
    let Arr = []
    listTheDichVu?.map((item) => {
      if (item.iddv === idDV && item.loaithedichvu === idLoaiDV) {
        Arr.push({
          ...item,
          ptGiamGia: ptg === null ? 0 : ptg,
        })
      } else {
        Arr.push(item)
      }
    })
    load(Arr)
  }
  const handleClickChonDichVu = (infoDV, idLoaiDV) => {
    // Loại thẻ dịch vụ (idLoaiDV === loaithedichvu) : (1 - dv khám bệnh, 2 - dv xét nghiệm, 3 - dv cdha)
    let Arr = [...listTheDichVu]

    const index = listTheDichVu.findIndex(
      (dvBN) =>
        (idLoaiDV === 1 && dvBN.iddv === infoDV?.iddv) ||
        (idLoaiDV === 2 && dvBN.iddv === infoDV?.idxn) ||
        (idLoaiDV === 3 && dvBN.iddv === infoDV?.idChiDinh),
    )
    if (index !== -1) {
      openNotification()
      return
    }

    if (idLoaiDV === 1) {
      //(1 - dv khám bệnh)
      Arr.push({
        soLanSuDung: 1,
        ptGiamGia: 0,
        idlt: infoLoaiThe?.idlt,
        loaithedichvu: idLoaiDV,

        iddv: infoDV?.iddv,
        maDichVu: infoDV?.maDichVu,
        tenDichVu: infoDV?.tenDichVu,
        dvt: infoDV?.donVi,
        donGia: infoDV?.donGia,
        tienthanhtoan: infoDV?.donGia,

        ghichu: null,
      })
    } else if (idLoaiDV === 2) {
      //(2 - dv xét nghiệm)
      Arr.push({
        soLanSuDung: 1,
        ptGiamGia: 0,
        idlt: infoLoaiThe?.idlt,
        loaithedichvu: idLoaiDV,

        iddv: infoDV?.idxn,
        maDichVu: infoDV?.maxn,
        tenDichVu: infoDV?.tenxn,
        dvt: infoDV?.dvt,
        donGia: infoDV?.dongia,
        tienthanhtoan: infoDV?.dongia,

        ghichu: null,
      })
    } else if (idLoaiDV === 3) {
      //(3 - dv cdha)
      Arr.push({
        soLanSuDung: 1,
        ptGiamGia: 0,
        idlt: infoLoaiThe?.idlt,
        loaithedichvu: idLoaiDV,

        iddv: infoDV?.idChiDinh,
        maDichVu: infoDV?.macdha,
        tenDichVu: infoDV?.tenChiDinh,
        dvt: infoDV?.dvt,
        donGia: infoDV?.donGia,
        tienthanhtoan: infoDV?.donGia,

        ghichu: null,
      })
    }

    load(Arr)
    // listTheDichVu?.map((item) => {
    //   if (item.iddv === infoDV.iddv) {
    //     dichVuNayDaChon = true
    //     openNotification()
    //   }
    //   Arr.push(item)
    // })
    // if (dichVuNayDaChon === false) {
    //   Arr.push({
    //     ...infoDV,
    //     soLanSuDung: 1,
    //     ptGiamGia: 0,
    //     idlt: infoLoaiThe?.idlt,
    //     loaithedichvu: idLoaiDV,
    //     "iddv": 0,
    //     "maDichVu": "string",
    //     "tenDichVu": "string",
    //     "dvt": "string",
    //     "donGia": 0,
    //     "ptGiamGia": 0,
    //     "tienthanhtoan": 0,
    //     "ghichu": "string",
    //     "soLanSuDung": 0,
    //     "loaithedichvu": 0
    //   })
    // }
    // load(Arr)
  }
  const handleClickXoaDichVu = (idDVCanXoa, idLoaiDV) => {
    const Arr = listTheDichVu.filter(
      (item) => item.loaithedichvu !== idLoaiDV || item.iddv !== idDVCanXoa,
    )

    // let Arr = []
    // listTheDichVu?.map((item) => {
    //   if (item.iddv !== idDVCanXoa) {
    //     Arr.push(item)
    //   }
    // })

    load(Arr)
  }
  const handleClickNhomDichVu = (e) => {
    setIdNhomDichVu(e.key)
    dispatch(getListDichVuAction(e.key, PkDangNhap?.idCongTy))
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const openNotification = () => {
    api.warning({
      message: `Chọn dịch vụ`,
      description: 'Dịch vụ đã được chọn!',
      placement: 'topRight',
    })
  }
  const reLoad = () => {
    dispatch(getListDichVuTheAction(id))
  }
  const load = (list) => {
    dispatch({
      type: typeAction.DISPATCH_LIST_THE_DICH_VU,
      payload: list,
    })
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <ButtonAntd
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </ButtonAntd>
          <ButtonAntd
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </ButtonAntd>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //     close,
  //   }) => (
  //     <div
  //       style={{
  //         padding: 8,
  //       }}
  //       onKeyDown={(e) => e.stopPropagation()}
  //     >
  //       <Input
  //         ref={searchInput}
  //         placeholder={"Nhập tìm kiếm"}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{
  //           marginBottom: 8,
  //           display: "block",
  //         }}
  //       />
  //       <Space>
  //         <ButtonAntd
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Tìm
  //         </ButtonAntd>
  //         <ButtonAntd
  //           onClick={() => clearFilters && handleReset(clearFilters)}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Xoá
  //         </ButtonAntd>
  //         <ButtonAntd
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             close()
  //           }}
  //         >
  //           Đóng
  //         </ButtonAntd>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined
  //       style={{
  //         color: filtered ? "#1677ff" : undefined,
  //       }}
  //     />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownOpenChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.current?.select(), 100)
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{
  //           backgroundColor: "#ffc069",
  //           padding: 0,
  //         }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ""}
  //       />
  //     ) : (
  //       text
  //     ),
  // })
  const columnsKB = [
    {
      title: 'Mã dịch vụ',
      dataIndex: 'madv',
      key: 'madv',
      width: 200,
      ...getColumnSearchProps('madv'),
      sorter: {
        compare: (a, b) => {
          return (a?.madv ?? '').localeCompare(b?.madv ?? '')
        },
      },
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tendv',
      key: 'tendv',
      ...getColumnSearchProps('tendv'),
      sorter: {
        compare: (a, b) => {
          return (a?.tendv ?? '').localeCompare(b?.tendv ?? '')
        },
      },
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      width: 90,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (Number(a?.dongia) ?? 0) - (Number(b?.dongia) ?? 0)
        },
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
    },
  ]
  const columns = [
    {
      title: 'Mã dịch vụ',
      dataIndex: 'madv',
      key: 'madv',
      width: 200,
      ...getColumnSearchProps('madv'),
      sorter: {
        compare: (a, b) => {
          return (a?.madv ?? '').localeCompare(b?.madv ?? '')
        },
      },
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tendv',
      key: 'tendv',
      ...getColumnSearchProps('tendv'),
      sorter: {
        compare: (a, b) => {
          return (a?.tendv ?? '').localeCompare(b?.tendv ?? '')
        },
      },
    },
    {
      title: 'Nhóm',
      dataIndex: 'tennhom',
      key: 'tennhom',
      width: 170,
      align: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tennhom ?? '').localeCompare(b?.tennhom ?? '')
        },
      },
    },
    {
      // title: "Giá",
      // dataIndex: "price",
      // key: "price",
      // width: 100,
      // align: "center",
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      width: 90,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (Number(a?.dongia) ?? 0) - (Number(b?.dongia) ?? 0)
        },
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
    },
  ]
  const columnsXN = [
    {
      title: 'Mã xét nghiêm',
      dataIndex: 'madv',
      key: 'madv',
      width: 200,
      ...getColumnSearchProps('madv'),
      sorter: {
        compare: (a, b) => {
          return (a?.madv ?? '').localeCompare(b?.madv ?? '')
        },
      },
    },
    {
      title: 'Tên xét nghiệm',
      dataIndex: 'tendv',
      key: 'tendv',
      ...getColumnSearchProps('tendv'),
      sorter: {
        compare: (a, b) => {
          return (a?.tendv ?? '').localeCompare(b?.tendv ?? '')
        },
      },
    },
    {
      title: 'Đối tác',
      dataIndex: 'tennhom',
      key: 'tennhom',
      width: 170,
      align: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tennhom ?? '').localeCompare(b?.tennhom ?? '')
        },
      },
    },
    {
      // title: "Giá",
      // dataIndex: "price",
      // key: "price",
      // width: 100,
      // align: "center",
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      width: 90,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (Number(a?.dongia) ?? 0) - (Number(b?.dongia) ?? 0)
        },
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
    },
  ]
  const columnsDichVuThe = [
    {
      key: 'STT',
      dataIndex: 'STT',
      title: 'STT',
      width: 40,
      align: 'center',
    },
    {
      key: 'madichvu',
      dataIndex: 'madichvu',
      title: 'Mã dịch vụ',
      width: 200,
    },
    {
      key: 'tendichvu',
      dataIndex: 'tendichvu',
      title: 'Tên dịch vụ',
    },
    {
      key: 'solansudung',
      dataIndex: 'solansudung',
      title: 'Số lần sử dụng',
      width: 120,
      align: 'center',
    },
    {
      key: 'tiendichvu',
      dataIndex: 'tiendichvu',
      title: 'Tiền dịch vụ',
      width: 100,
      align: 'center',
    },
    {
      key: 'phantramgiam',
      dataIndex: 'phantramgiam',
      title: '% Giảm',
      width: 80,
      align: 'center',
    },
    {
      key: 'tienthanhtoan',
      dataIndex: 'tienthanhtoan',
      title: 'Thanh toán',
      width: 100,
      align: 'center',
    },
    {
      key: 'action',
      dataIndex: 'action',
      title: '',
      width: 50,
      align: 'center',
    },
  ]

  const columnsCN = [
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      ...getColumnSearchProps('tenchinhanh'),
    },
    // {
    //   title: (
    //     <div className="flex justify-center flex-col items-center">
    //       <p>Sử dụng</p>
    //       <Checkbox
    //         checked={listBranch?.every((item) => item.sudung === 1)}
    //         onChange={handleSelect}
    //         disabled={loadingCN}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'sudung',
    //   width: 70,
    //   align: 'center',
    //   key: 'sudung',
    //   render: (text, record, index) => (
    //     <Checkbox
    //       // disabled={!rowActive}
    //       checked={record.sudung === 1}
    //       onChange={(e) => {
    //         handleCheckBox(e, record)
    //       }}
    //     />
    //   ),
    // },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 35,
      align: 'center',
      render: (text, record, index) => (
        <>
          <Tooltip title="Chỉnh sửa" color="green">
            <EditOutlined
              onClick={() => {
                setInfoCNLT(record)
                setShowModalEditPriceCN(true)
              }}
              className="text-xl text-green-500  cursor-pointer"
            />
          </Tooltip>
        </>
      ),
    },
  ]
  useEffect(() => {
    if (PkDangNhap?.idCongTy) {
      dispatch(getListDichVuTheAction(id))
      dispatch(listGroupsDichVuAction())
      dispatch(getListDichVuAction(idNhomDichVu, PkDangNhap?.idCongTy))
      fetchListChiNhanhByLoaiThe()
    }
  }, [PkDangNhap?.idCongTy])
  useEffect(() => {
    dispatch(listAllCompanyAction())
    // getAllChiNhanhChuaCoLT()
  }, [])
  useEffect(() => {
    if (!isModalAdd) {
      fetchListChiNhanhByLoaiThe()
    }
  }, [isModalAdd])
  useEffect(() => {
    if (!showModalEditPriceCN) {
      fetchListChiNhanhByLoaiThe()
    }
  }, [showModalEditPriceCN])
  useEffect(() => {
    fetchListChiNhanhByLoaiThe()
  }, [congTy])
  return (
    <>
      {contextHolder}
      <div className="p-3 bg-[#EFEFEF] h-full">
        <div className="flex justify-between">
          <h2 className="font-semibold text-2xl text-gray-500">CHI TIẾT LOẠI THẺ</h2>
          <div>
            <Breadcrumb
              separator={<span className="text-lg">/</span>}
              items={[
                {
                  title: (
                    <Link to={'/khachhang/thetvtt'}>
                      <h2 className="font-semibold text-lg ">Loại thẻ</h2>
                    </Link>
                  ),
                },
                {
                  title: <h2 className="font-semibold text-lg">Chi tiết</h2>,
                },
              ]}
            />
          </div>
        </div>
        <div className="p-3 flex gap-3 border bg-white rounded-xl h-[95%] mt-2">
          <div className="w-1/4">
            <div className="border rounded-md ">
              <div className="p-2 pb-3">
                <div className="flex justify-between h-[2.25rem] items-center">
                  <h2 className="text-base font-semibold text-gray-500">Thông tin</h2>
                  <div className="flex items-center">
                    {isEdit ? (
                      <>
                        <Tooltip title="Hủy chỉnh sửa" color="red" className="mr-2">
                          <CloseOutlined
                            onClick={() => {
                              setIsEdit(false)
                            }}
                            className="text-xl text-red-500  cursor-pointer"
                          />
                        </Tooltip>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          type="button"
                          onClick={formik.handleSubmit}
                        >
                          LƯU
                        </Button>
                      </>
                    ) : (
                      <Tooltip title="Chỉnh sửa" color="green">
                        <EditOutlined
                          onClick={() => {
                            setIsEdit(true)
                          }}
                          className="text-xl text-green-500  cursor-pointer"
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="mt-2">
                  <ul className="flex flex-col gap-3">
                    <li className="flex">
                      <label className="w-1/3 text-gray-600 font-semibold">Loại thẻ:</label>
                      {/* <p className="w-2/3">{infoLoaiThe?.loaithe}</p> */}
                      <div className="w-2/3">
                        {isEdit ? (
                          <Input
                            className="w-4/5 "
                            size="small"
                            // status={formik.errors.benhsu ? 'error' : ''}
                            // onChange={formik.handleChange}
                            value={formik.values.loaithe}
                            name="loaithe"
                            readOnly
                            variant="filled"
                          />
                        ) : (
                          <Input
                            className="w-4/5 "
                            value={infoLoaiThe?.loaithe}
                            size="small"
                            readOnly
                            variant="filled"
                          />
                        )}
                      </div>
                    </li>
                    <li className="flex">
                      <label className="w-1/3 text-gray-600 font-semibold">Giá trị:</label>
                      <div className="w-2/3">
                        {isEdit ? (
                          <InputNumber
                            className="w-4/5 "
                            size="small"
                            onChange={handleChangeSelectFormik('tiensudung')}
                            value={formik.values.tiensudung}
                            name="tiensudung"
                            step={100}
                            formatter={(value) => {
                              const roundedValue = Math.round(Number(value)) // Làm tròn đến số nguyên gần nhất
                              return `${roundedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            min={0}
                            max={999999999}
                          />
                        ) : (
                          <Input
                            className="w-4/5 "
                            value={
                              infoLoaiThe?.tiensudung &&
                              formatNumberVND(infoLoaiThe?.tiensudung) + ' VNĐ'
                            }
                            size="small"
                            readOnly
                            variant="filled"
                          />
                        )}
                      </div>
                    </li>
                    <li className="flex">
                      <label className="w-1/3 text-gray-600 font-semibold">Chuyên khoa:</label>
                      <div className="w-2/3">
                        {isEdit ? (
                          // <Input

                          //   value={infoLoaiThe?.tenchuyenkhoa}
                          //   size="small"
                          // />
                          <Select
                            //onChange={handleChangeSelectFormik('idchuyenkhoa')}
                            options={chuyenKhoa?.map((item) => ({
                              key: item.idChuyenKhoa,
                              value: item.idChuyenKhoa,
                              label: item.tenChuyenKhoa,
                            }))}
                            value={formik.values.idchuyenkhoa}
                            className="w-4/5"
                            size="small"
                            name="idchuyenkhoa"
                            readOnly
                            variant="filled"
                          />
                        ) : (
                          <Input
                            className="w-4/5"
                            value={infoLoaiThe?.tenchuyenkhoa}
                            size="small"
                            readOnly
                            variant="filled"
                          />
                        )}
                      </div>
                    </li>
                    <li className="flex">
                      <label className="w-1/3 text-gray-600 font-semibold">Nhóm thẻ:</label>
                      <div className="w-2/3">
                        {isEdit ? (
                          // <Input
                          //   className="w-4/5 "
                          //   value={infoLoaiThe?.tennhom}
                          //   size="small"
                          // />
                          <Select
                            // onChange={isEditTTV ? handleTypeCardEdit : handleTypeCard}
                            //onChange={handleChangeSelectFormik('idnhom')}
                            options={listGroupCard?.map((item) => ({
                              key: item.idnhom,
                              value: item.idnhom,
                              label: item.tennhom,
                            }))}
                            value={formik.values.idnhom}
                            className="w-4/5"
                            size="small"
                            name="idnhom"
                            readOnly
                            variant="filled"
                          />
                        ) : (
                          <Input
                            className="w-4/5 "
                            value={infoLoaiThe?.tennhom}
                            size="small"
                            readOnly
                            variant="filled"
                          />
                        )}
                      </div>
                    </li>
                    <li className="flex">
                      <label className="w-1/3 text-gray-600 font-semibold">Số tháng:</label>
                      <div className="w-2/3">
                        {isEdit ? (
                          <InputNumber
                            min={0}
                            step={1}
                            max={100}
                            className="w-4/5 "
                            onChange={handleChangeSelectFormik('sothang')}
                            value={formik.values.sothang}
                            size="small"
                            name="sothang"
                            // readOnly
                            // variant="filled"
                          />
                        ) : (
                          // <Input
                          //   className="w-4/5 "
                          //   value={infoLoaiThe?.sothang}
                          //   size="small"
                          // />
                          <Input
                            className="w-4/5 "
                            value={infoLoaiThe?.sothang}
                            size="small"
                            readOnly
                            variant="filled"
                          />
                        )}
                      </div>
                    </li>
                    <li className="flex">
                      <label className="w-1/3 text-gray-600 font-semibold">Người tạo:</label>
                      <div className="w-2/3">
                        <Input
                          className="w-4/5 "
                          value={infoLoaiThe?.nguoitao}
                          size="small"
                          readOnly
                          variant="filled"
                        />
                      </div>
                    </li>
                    {/* <li className="flex">
                      <label className="w-1/3 text-gray-500">Ngày tạo:</label>
                      <p className="w-2/3"></p>
                    </li> */}
                    <li className="flex">
                      <label className="w-1/3 text-gray-600 font-semibold">Người sửa:</label>
                      <div className="w-2/3">
                        {isEdit ? (
                          <Input
                            className="w-4/5 "
                            value={infoUser?.tenNV}
                            size="small"
                            readOnly
                            variant="filled"
                          />
                        ) : (
                          <Input
                            className="w-4/5 "
                            value={infoLoaiThe?.nguoiupdate}
                            size="small"
                            readOnly
                            variant="filled"
                          />
                        )}
                      </div>
                    </li>
                    <li className="flex">
                      <label className="w-1/3 text-gray-600 font-semibold">Ngày sửa:</label>
                      <div className="w-2/3">
                        {isEdit ? (
                          <Input
                            className="w-4/5 "
                            value={moment().format('DD/MM/YYYY')}
                            size="small"
                            readOnly
                            variant="filled"
                          />
                        ) : (
                          <Input
                            className="w-4/5 "
                            value={
                              infoLoaiThe?.ngayupdate &&
                              moment(infoLoaiThe?.ngayupdate).format('DD/MM/YYYY')
                            }
                            size="small"
                            readOnly
                            variant="filled"
                          />
                        )}
                      </div>
                    </li>
                    <li className="flex">
                      <label className="w-1/3 text-gray-600 font-semibold">Sử dụng:</label>
                      <div className="w-2/3">
                        <Checkbox
                          onChange={(e) => {
                            if (isEdit) handleChangeSuDung(e.target.checked ? 1 : 0)
                          }}
                          checked={isEdit ? formik.values.sudung === 1 : infoLoaiThe?.sudung === 1}
                          name="sudung"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="border rounded-md mt-3 h-[25.7rem]">
              <div className="p-2 pb-3">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex justify-between items-center">
                    <h2
                      className="text-base font-semibold text-gray-500"
                      onClick={fetchListChiNhanhByLoaiThe}
                    >
                      Giá CN
                    </h2>
                    <Select
                      placeholder="Chọn công ty..."
                      className="w-[16.5rem] ml-2"
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      options={listCompany?.map((items) => ({
                        label: items.tenct,
                        value: items.idct,
                      }))}
                      defaultValue={PkDangNhap?.idCongTy}
                      onChange={(value) => {
                        setCongTy(value)
                      }}
                      value={congTy}
                    />
                  </div>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    type="button"
                    disabled={loadingCN}
                    onClick={() => setIsModalAdd(true)}
                    // onClick={() => {
                    //   // formik.handleSubmit()
                    //   api.warning({
                    //     message: `Giá chi nhánh`,
                    //     description: 'Tính năng chưa hoàn thiện!',
                    //     placement: 'topRight',
                    //   })
                    // }}
                  >
                    Thêm
                  </Button>
                </div>
                <ConfigProvider
                  theme={{
                    token: {
                      padding: 5,
                    },
                    components: {
                      Table: {
                        rowHoverBg: '#ecf0f1',
                        headerBg: '#e6e6e6',
                        footerBg: '#e6e6e6',
                        borderColor: '#BABABA',
                      },
                    },
                  }}
                >
                  <Table
                    loading={loadingCN}
                    scroll={{ y: 295 }}
                    // loading={isLoadingBranch}
                    pagination={false}
                    columns={columnsCN}
                    dataSource={listBranch} //listBranch
                    // dataSource={listTheDichVu?.map((item) => ({
                    //   ...item,
                    //   action: (
                    //     <>
                    //       <Tooltip title="Chỉnh sửa" color="green">
                    //         <EditOutlined
                    //           // onClick={() => {
                    //           //   setShowEditPriceCN({ show: true, data: item })
                    //           // }}
                    //           className="text-xl text-green-500  cursor-pointer"
                    //         />
                    //       </Tooltip>
                    //     </>
                    //   ),
                    // }))}
                    bordered
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
          <div className="w-3/4 flex flex-col gap-2">
            <div className="border rounded-md flex h-[23.5rem]">
              <div className="">
                <h2 className="p-2 text-base font-semibold text-gray-500 border-b">Dịch vụ thẻ</h2>
                <ConfigProvider
                  theme={{
                    token: {
                      padding: 5,
                      borderRadius: 0,
                    },
                    components: {
                      Table: {
                        rowHoverBg: '#ecf0f1',
                        headerBg: '#e6e6e6',
                        footerBg: '#e6e6e6',
                        borderColor: '#BABABA',
                      },
                    },
                  }}
                >
                  <Table
                    className="w-full"
                    columns={columnsDichVuThe}
                    scroll={{
                      y: 240,
                    }}
                    pagination={false}
                    dataSource={listTheDichVu
                      ?.map((item, index) => ({
                        STT: ++index,
                        madichvu: item?.maDichVu,
                        tendichvu: item?.tenDichVu,
                        solansudung: (
                          <InputNumber
                            min={0}
                            max={100}
                            step={1}
                            size="small"
                            className="p-0 text-center w-12 rounded-md"
                            value={item?.soLanSuDung}
                            onChange={(value) => {
                              handleChangeSoLanSuDung(value, item?.iddv, item?.loaithedichvu)
                            }}
                          />
                        ),
                        tiendichvu: formatNumberVND(item?.donGia),
                        phantramgiam: (
                          <InputNumber
                            min={0}
                            max={100}
                            step={1}
                            size="small"
                            className="p-0 text-center w-12 rounded-md"
                            value={item?.ptGiamGia}
                            onChange={(value) => {
                              handleChangePhanTramGiam(value, item?.iddv, item?.loaithedichvu)
                            }}
                          />
                        ),
                        tienthanhtoan: formatNumberVND(
                          (item?.donGia * (100 - item?.ptGiamGia)) / 100,
                        ),
                        action: (
                          <span
                            className="text-red-500 cursor-pointer"
                            onClick={() => {
                              handleClickXoaDichVu(item?.iddv, item?.loaithedichvu)
                            }}
                          >
                            <CloseOutlined />
                          </span>
                        ),
                        loaithedichvu: item?.loaithedichvu ?? 0,
                      }))
                      ?.sort((a, b) =>
                        `${a?.tendichvu ?? ''}`?.localeCompare(`${b?.tendichvu ?? ''}`),
                      )
                      ?.sort((a, b) => (+a?.loaithedichvu ?? 0) - (+b?.loaithedichvu ?? 0))}
                    bordered
                  />
                </ConfigProvider>
                <div className="flex justify-end m-3">
                  <div className="mr-3">
                    <Button
                      onClick={() => {
                        reLoad()
                      }}
                      variant="outlined"
                      color="info"
                      size="small"
                    >
                      Làm mới
                    </Button>
                  </div>
                  <div className="">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      type="button"
                      onClick={() => {
                        handleClickLuuDichVu()
                      }}
                    >
                      Lưu
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="border rounded-md mt-1">
              <Segmented
                onChange={handleChangeSegmented}
                // onClick={reLoadXetNghiem}
                options={[KB, XN, CĐHA]}
                block
              />
              <div className="flex mt-3">
                {valueSegmented === KB && (
                  <>
                    <div className="w-1/4 max-h-[22.8rem] overflow-auto">
                      <Menu
                        onClick={handleClickNhomDichVu}
                        defaultSelectedKeys={[idNhomDichVu]}
                        mode="inline"
                        items={groupsDichVu?.map((item) => ({
                          label: item?.tenNhom,
                          key: item?.idNhom,
                        }))}
                      />
                    </div>
                  </>
                )}
                <div
                  className={
                    valueSegmented === KB ? 'w-3/4 pl-2 pr-2' : 'w-full p-2 pt-0 h-[22.8rem]'
                  }
                >
                  <h2 className="p-2 text-base font-semibold text-gray-500">
                    Chọn {valueSegmented === KB ? 'dịch vụ' : valueSegmented?.toLowerCase()}
                  </h2>
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                        borderRadius: 0,
                      },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                          headerBg: '#e6e6e6',
                          footerBg: '#e6e6e6',
                          borderColor: '#BABABA',
                        },
                      },
                    }}
                  >
                    <Table
                      scroll={{
                        y: 275,
                      }}
                      pagination={false}
                      bordered
                      columns={
                        valueSegmented === KB
                          ? columnsKB
                          : valueSegmented === XN
                            ? columnsXN
                            : columns
                      }
                      dataSource={
                        valueSegmented === KB
                          ? listDichVuTheoNhom?.map((item) => ({
                              key: item?.iddv,
                              madv: item?.maDichVu,
                              tendv: item?.tenDichVu,
                              price: formatNumberVND(item?.donGia ?? 0),
                              dongia: item?.donGia ?? 0,
                              itemCD: item,
                              action: (
                                <PlusOutlined
                                  onClick={() => {
                                    handleClickChonDichVu(item, 1) // Loại thẻ dịch vụ : (1 - dv khám bệnh, 2 - dv xét nghiệm, 3 - dv cdha)
                                  }}
                                  className="text-blue-500 cursor-pointer"
                                />
                              ),
                            }))
                          : valueSegmented === XN
                            ? listXN?.map((item) => ({
                                key: item?.idxn,
                                madv: item?.maxn,
                                tendv: item?.tenxn,
                                tennhom: (item?.tendoitac ?? '')
                                  ?.toLowerCase()
                                  ?.replace('xét nghiệm', '')
                                  ?.trim()
                                  .toUpperCase(),
                                price: formatNumberVND(item?.dongia ?? 0),
                                dongia: item?.dongia ?? 0,
                                itemCD: item,
                                action: (
                                  <PlusOutlined
                                    onClick={() => {
                                      handleClickChonDichVu(item, 2) // Loại thẻ dịch vụ : (1 - dv khám bệnh, 2 - dv xét nghiệm, 3 - dv cdha)
                                    }}
                                    className="text-blue-500 cursor-pointer"
                                  />
                                ),
                              }))
                            : valueSegmented === CĐHA &&
                              listCDHA?.map((item) => ({
                                key: item?.idChiDinh,
                                madv: item?.macdha,
                                tendv: item?.tenChiDinh,
                                tennhom: (item?.tennhom ?? '')
                                  ?.toLowerCase()
                                  ?.replace('xét nghiệm', '')
                                  ?.trim()
                                  .toUpperCase(),
                                price: formatNumberVND(item?.donGia ?? 0),
                                dongia: item?.donGia ?? 0,
                                itemCD: item,
                                action: (
                                  <PlusOutlined
                                    onClick={() => {
                                      handleClickChonDichVu(item, 3) // Loại thẻ dịch vụ : (1 - dv khám bệnh, 2 - dv xét nghiệm, 3 - dv cdha)
                                    }}
                                    className="text-blue-500 cursor-pointer"
                                  />
                                ),
                              }))
                      }
                      className="w-full"
                    />
                  </ConfigProvider>
                </div>
              </div>

              {/* {valueSegmented === KB ? (<>
                <div className="flex mt-3">
                  <div className="w-1/4 max-h-80 overflow-auto">
                    <Menu
                      onClick={handleClickNhomDichVu}
                      defaultSelectedKeys={[idNhomDichVu]}
                      mode="inline"
                      items={groupsDichVu?.map((item) => ({
                        label: item?.tenNhom,
                        key: item?.idNhom,
                      }))
                      }
                    />
                  </div>
                  <div className="w-3/4 pl-2 pr-2">
                    <h2 className="p-2 text-base font-semibold text-gray-500">
                      Chọn dịch vụ
                    </h2>
                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 5,
                          borderRadius: 0,
                        },
                        components: {
                          Table: {
                            rowHoverBg: '#ecf0f1'
                          }
                        }
                      }}
                    >
                      <Table
                        scroll={{
                          y: 245,
                        }}
                        pagination={false}
                        bordered
                        columns={columnsKB}
                        dataSource={listDichVuTheoNhom?.map((item) => ({
                          key: item?.iddv,
                          madv: item?.maDichVu,
                          tendv: item?.tenDichVu,
                          price: item?.donGia && formatNumberVND(item?.donGia),
                          dongia: item?.donGia ?? 0,
                          action: (
                            <PlusOutlined
                              onClick={() => {
                                handleClickChonDichVu(item)
                              }}
                              className="text-blue-500 cursor-pointer"
                            />
                          ),
                        }))}
                        className="w-full"
                      />
                    </ConfigProvider>
                  </div>
                </div>
              </>) : (<>
                <div className="flex mt-3">
                  <div className="w-full p-2 pt-0">
                    <h2 className="p-2 text-base font-semibold text-gray-500">
                      Chọn {valueSegmented?.toLowerCase()}
                    </h2>
                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 5,
                          borderRadius: 0,
                        },
                        components: {
                          Table: {
                            rowHoverBg: '#ecf0f1'
                          }
                        }
                      }}
                    >
                      <Table
                        scroll={{
                          y: 245,
                        }}
                        pagination={false}
                        bordered
                        columns={columns}
                        dataSource={listDichVuTheoNhom?.map((item) => ({
                          madv: item?.maDichVu,
                          tendv: item?.tenDichVu,
                          price: item?.donGia && formatNumberVND(item?.donGia),
                          action: (
                            <PlusOutlined
                              onClick={() => {
                                handleClickChonDichVu(item)
                              }}
                              className="text-blue-500 cursor-pointer"
                            />
                          ),
                        }))}
                        className="w-full"
                      />
                    </ConfigProvider>
                  </div>
                </div>
              </>)} */}
            </div>
          </div>
        </div>
      </div>
      {showModalEditPriceCN && (
        <ModalEditPriceCN
          infoCNLT={infoCNLT}
          showModalEditPriceCN={showModalEditPriceCN}
          setShowModalEditPriceCN={setShowModalEditPriceCN}
          congTy={congTy}
        />
      )}
      {isModalAdd && (
        <ModalAdd
          isModalAdd={isModalAdd}
          setIsModalAdd={setIsModalAdd}
          // congTy={congTy}
          // showModalEditPriceCN={showModalEditPriceCN}
          // setShowModalEditPriceCN={setShowModalEditPriceCN}
        />
      )}
    </>
  )
}

export default DetaildCard
