import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { cardService } from '../../services/card/cardService'
import { NhapKhoService } from '../../services/NhapKho/NhapKhoService'
import { partnerServices } from '../../services/partner/partnerServices'
import _ from 'lodash'
import ToastCus from '../../utils/Toast'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'

// Common function to fetch partner list and update store
function* fetchAndUpdatePartnerList() {
  try {
    yield put({ type: typeAction.OPEN_LOADING_PAGE })
    const result = yield call(() => NhapKhoService.getListDoiTac())
    const newData = _.orderBy(result.data, 'maDoiTac', 'desc')
    yield put({
      type: typeAction.DISTPATCH_LIST_PARTNER,
      payload: newData,
    })
  } catch (error) {
    console.log(error)
    ToastCus.fire({
      icon: 'error',
      title: 'Lấy dữ liệu thất bại',
    })
  } finally {
    yield put({ type: typeAction.CLOSE_LOADING_PAGE })
  }
}

// Sagas
export function* partnerSaga() {
  yield takeLatest(typeAction.GET_LIST_PARTNER, function* listCardMemberShips({ type }) {
    yield fetchAndUpdatePartnerList()
  })

  yield takeLatest(
    typeAction.ADD_PARTNER,
    function* addNewPartner({ type, form, handleCloseModal, onLoad }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        yield call(() => partnerServices.postPartner(form))
        yield call(() => handleCloseModal())
        yield call(() => onLoad())
        // yield fetchAndUpdatePartnerList()
        ToastCus.fire({
          icon: 'success',
          title: 'Thêm đối tác thành công',
        })
        const detailForLog = {
          maDoiTac: form.maDoiTac,
          tenDoiTac: form.tenDoiTac,
          soDienThoai: form.soDienThoai,
          email: form.email,
          diaChi: form.diaChi,
          ghiChu: form.ghiChu,
        }

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          logAction.THEM_DOI_TACC,
          true,
          MENU_CONSTANTS.DOI_TAC,
          null,
          detailForLog,
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thêm đối tác thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.DOI_TAC,
          detailErrorPayload,
        )
      }
    },
  )

  yield takeLatest(
    typeAction.EDIT_PARTNER,
    function* editPartner({ type, form, handleCloseModal, onLoad }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        yield call(() => partnerServices.editPartner(form))
        yield call(() => handleCloseModal())
        yield call(() => onLoad())
        // yield fetchAndUpdatePartnerList()
        ToastCus.fire({
          icon: 'success',
          title: 'Sửa thông tin đối tác thành công',
        })

        const detailForLog = {
          maDoiTac: form.maDoiTac,
          tenDoiTac: form.tenDoiTac,
          soDienThoai: form.soDienThoai,
          email: form.email,
          diaChi: form.diaChi,
          ghiChu: form.ghiChu,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          logAction.CAP_NHAT_DOI_TAC,
          true,
          MENU_CONSTANTS.DOI_TAC,
          null,
          detailForLog,
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Sửa thông tin đối tác thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.DOI_TAC,
          detailErrorPayload,
        )
      }
    },
  )

  yield takeLatest(typeAction.DELETE_PARTNER, function* deletePartner({ type, id, onLoad }) {
    const { infoUser } = yield select((state) => state.userReducer)
    try {
      yield call(() => partnerServices.deletePartner(id))
      yield call(() => onLoad())
      // yield fetchAndUpdatePartnerList()
      ToastCus.fire({
        icon: 'success',
        title: 'Xóa đối tác thành công',
      })
      const detailForLog = {
        id: id,
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.DELETE,
        logAction.XOA_DOI_TAC,
        true,
        MENU_CONSTANTS.DOI_TAC,
        null,
        detailForLog,
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Xóa đối tác thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.DELETE,
        null,
        false,
        MENU_CONSTANTS.DOI_TAC,
        detailErrorPayload,
      )
    }
  })

  yield takeLatest(typeAction.SEARCH_PARTNER, function* searchKeyword({ type, keyword }) {
    const { infoUser } = yield select((state) => state.userReducer)
    yield put({ type: typeAction.OPEN_LOADING_PAGE })

    try {
      const result = yield call(() => partnerServices.searchPartnerByKeyword(keyword))
      yield put({
        type: typeAction.DISTPATCH_LIST_PARTNER,
        payload: result.data,
      })
      const detailForLog = {
        keyword: keyword,
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.TIM_KIEM_DOI_TAC,
        true,
        MENU_CONSTANTS.DOI_TAC,
        null,
        detailForLog,
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DOI_TAC,
        detailErrorPayload,
      )
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
}
