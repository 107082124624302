import { call, put, select, takeLatest } from 'redux-saga/effects'
import { phanconglienheService } from '../../services/phanconglienhe/phanconglienheService'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
export function* phanconglienheCRMSaga() {
  yield takeLatest(
    typeAction.GET_ALL_PHANCONG_LIENHE_CRM,
    function* allPhanCongLienHe({ type, payload }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => phanconglienheService.getAllPhanCongLienHe())
        result.data.sort((a, b) => new Date(b.ngayphancong) - new Date(a.ngayphancong))
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_KHACH_HANG_CRM,
          true,
          MENU_CONSTANTS.KHACH_HANG_CRM,
          null,
          {},
        )
        yield put({
          type: typeAction.DISPATCH_LIST_PHANCONG_CRM,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.KHACH_HANG_CRM,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.GET_PHANCONG_LIENHE_IDNV_CRM,
    function* phanCongLienHeByIdnv({ type, idnv }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => phanconglienheService.getAllPhanCongLienHeByIdnv(idnv))
        yield put({
          type: typeAction.DISPATCH_LIST_PHANCONG_CRM,
          payload: result.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_KH_BY_IDNV,
          true,
          MENU_CONSTANTS.KHACH_HANG_CRM,
          null,
          { idnv },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.KHACH_HANG_CRM,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.GET_DETAIL_PHANCONG_LIENHE_CRM,
    function* allPhanCongLienHe({ type, idpclh }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data } = yield call(() => phanconglienheService.getPhanCongLienHeById(idpclh))
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DETAIL_PHAN_CONG,
          true,
          MENU_CONSTANTS.PHAN_CONG,
          null,
          {
            id: idpclh,
          },
        )
        yield put({
          type: typeAction.DISPATCH_INFO_PHANCONG_CRM,
          payload: data.result,
        })
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.PHAN_CONG,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.GET_TRANGTHAI_PHANCONG_LIENHE_CRM,
    function* allTrangThaiPhanCongLienHe({ type }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data } = yield call(() => phanconglienheService.getListTrangThaiPhanCongLienHe())
        yield put({
          type: typeAction.DISPATCH_TRANGTHAI_PHANCONG_CRM,
          payload: data,
        })
      } catch (err) {
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  //add list

  yield takeLatest(
    typeAction.ADD_LIST_PHANCONG_LIENHE_CRM,
    function* addPhanCongLienHe({ type, form, onLoad, setLoading }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => phanconglienheService.addListPhanCongLienHe(form))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Thêm thành công',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.ADD_PHAN_CONG,
          true,
          MENU_CONSTANTS.PHAN_CONG,
          null,
          {
            form,
          },
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message || 'Thêm thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.PHAN_CONG,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        setLoading(false)
      }
    },
  )

  //edit
  yield takeLatest(
    typeAction.EDIT_PHANCONG_LIENHE_CRM,
    function* editPhanCongLienHe({ type, form, onLoad }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => phanconglienheService.editListPhanCongLienHe(form))
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.EDIT_PHAN_CONG,
          true,
          MENU_CONSTANTS.PHAN_CONG,
          null,
          {
            form,
          },
        )
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Sửa thành công',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Sửa thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.PHAN_CONG,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
