import { call, put, select, takeLatest } from 'redux-saga/effects'
import { lienheCRMService } from '../../services/lienhe/lienheService'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'

export function* lienheCRMSaga() {
  yield takeLatest(typeAction.GET_ALL_LH_CRM, function* allLienHeCRM({ type, id }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const { data } = yield call(() => lienheCRMService.getCompanyByIDCompany(id))
      data.result.sort((a, b) => new Date(b.ngaytao) - new Date(a.ngaytao))
      yield put({
        type: typeAction.DISPATCH_ALL_LH_CRM,
        payload: data.result,
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_THONG_TIN_LIEN_HE,
        true,
        MENU_CONSTANTS.CONG_TY,
        null,
        {
          tenct: data.result.lnegth ? data.result[0].tencongty : '',
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.CONG_TY,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  yield takeLatest(
    typeAction.GET_ALL_LH_CRM_BY_ID_NV,
    function* allLienHeCRMByID({ type, idnv, idct }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data } = yield call(() => lienheCRMService.getLienHeByIdNv(idnv, idct))
        yield put({
          type: typeAction.DISPATCH_ALL_LH_CRM,
          payload: data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_CHI_TIET_LIEN_HE,
          true,
          MENU_CONSTANTS.CHI_TIET_LIEN_HE,
          null,
          {
            idnv,
            idct,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.CHI_TIET_LIEN_HE,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // yield takeLatest(typeAction.GET_CTY_CRM_BY_ID, function* getById({ type, id }) {
  //   try {
  //     yield put({
  //       type: typeAction.OPEN_LOADING_PAGE,
  //     })
  //     const result = yield call(() => congtyCRMService.getCtyCRMById(id))
  //     yield put({
  //       type: typeAction.DISPATCH_INFO_CTY_CRM,
  //       payload: result.data.result,
  //     })
  //   } catch (err) {
  //     console.log(err)
  //   } finally {
  //     yield put({
  //       type: typeAction.CLOSE_LOADING_PAGE,
  //     })
  //   }
  // })

  // //add
  yield takeLatest(typeAction.ADD_LH_CRM, function* addLienHeCRM({ type, form, onload }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => lienheCRMService.addThongTinLH(form))
      yield call(onload)
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm mới thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.ADD_THONG_TIN_LIEN_HE,
        true,
        MENU_CONSTANTS.CONG_TY,
        null,
        {
          ...form,
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message || 'Thêm mới thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.CONG_TY,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //add chi tiet
  yield takeLatest(
    typeAction.ADD_CHITIET_LH_CRM,
    function* addChiTietLienHeCRM({ type, form, onLoad }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const jsonData = {}

      for (const [key, value] of form) {
        jsonData[key] = value
      }
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => lienheCRMService.addChiTietLH(form))
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.ADD_CHI_TIET_LIEN_HE,
          true,
          MENU_CONSTANTS.CHI_TIET_LIEN_HE,
          null,
          {
            form: jsonData,
          },
        )
        ToastCus.fire({
          icon: 'success',
          title: 'Thêm mới thành công',
        })
        onLoad()
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thêm mới thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(jsonData)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.CHI_TIET_LIEN_HE,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(typeAction.GET_THONGTIN_LH_CRM, function* getThongTinLienHe({ type, idttlh }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => lienheCRMService.getThongTinLienHe(idttlh))
      yield put({
        type: typeAction.DISPATCH_INFO_LH_CRM,
        payload: result.data.result,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //edit
  yield takeLatest(typeAction.EDIT_LH_CRM, function* editLienHeCRM({ type, form, onLoad }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => lienheCRMService.editThongTinLH(form))
      yield call(onLoad)
      ToastCus.fire({
        icon: 'success',
        title: 'Sửa thông tin thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.EDIT_THONG_TIN_LIEN_HE,
        true,
        MENU_CONSTANTS.CONG_TY,
        null,
        {
          ...form,
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message || 'Sửa thông tin thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.CONG_TY,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}
