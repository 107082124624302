import dayjs from 'dayjs'
import moment from 'moment/moment'
import { generateCheckbox } from '../algorithm'
import { calculateAgeForReport } from '../calculateAge'
import { jsPDFCus } from '../jsPdfAddFont'
import { splitLongWord, splitLongWordFitWidth } from '../splitWordForReport'

export const generatePhieuKiemTruocTiemChungSan = ({
  infoBN,
  infoBNTTC,
  infoKSL,
  tenVacXin,
  PkDangNhap,
}) => {
  // const doc = jsPDFCus('p', 'mm', 'a5')
  // let yPos = 10
  // let xPos = 8
  // let ruleWidth = 55 // for text of price is longer
  // const pageWidth = doc.internal.pageSize.width
  // const pageHeight = doc.internal.pageSize.height
  // doc.setFont('times', 'bold')
  // doc.setFontSize(10)
  // doc.text('HỆ THỐNG Y TẾ PHỤ SẢN 315', xPos + 4, yPos)
  // doc.setFontSize(9)
  // doc.text('CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM', pageWidth - 75, yPos)
  // yPos += 4
  // doc.setFont('times', 'regular')
  // doc.setFontSize(8)
  // doc.text(PkDangNhap.diaChi ?? '', xPos, yPos)
  // doc.text('Độc lập - Tự do - Hạnh phúc', pageWidth - 60, yPos)
  // yPos += 4

  // doc.text(
  //   `Điện thoại: ${PkDangNhap.dienThoai ?? '0901.315.315'} - Hotline: ${PkDangNhap.sohotline ?? '0901.315.315'}`,
  //   xPos + 2,
  //   yPos,
  // )
  // yPos += 4
  // doc.setFont('times', 'bold')
  // doc.setFontSize(9)
  // doc.text('BẢNG KIỂM TRƯỚC TIÊM CHỦNG ĐỐI VỚI NGƯỜI LỚN', doc.internal.pageSize.width / 2, yPos, {
  //   align: 'center',
  // })
  // yPos += 4
  // doc.setFont('times', 'regular')
  // doc.text(`Họ và tên: ${infoBN?.tenBenhNhan ?? ''}`, xPos, yPos)
  // doc.text('Nam', pageWidth - 40, yPos)
  // if (infoBN?.gioiTinh?.includes('Nam')) {
  //   generateCheckbox(doc, pageWidth - 45, yPos - 3.5, 4, 4, true)
  // } else {
  //   generateCheckbox(doc, pageWidth - 45, yPos - 3.5, 4, 4, false)
  // }
  // doc.setFontSize(9)
  // doc.text('Nữ', pageWidth - 25, yPos)
  // if (infoBN?.gioiTinh?.includes('Nữ')) {
  //   generateCheckbox(doc, pageWidth - 30, yPos - 3.5, 4, 4, true)
  // } else {
  //   generateCheckbox(doc, pageWidth - 30, yPos - 3.5, 4, 4, false)
  // }
  // doc.setFontSize(9)
  // yPos += 4
  // doc.text(
  //   `Tuổi: ${infoBN?.ngaySinh && calculateAgeForReport(infoBN?.ngaySinh)}, sinh ngày ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('DD')} tháng ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('MM')} năm ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('YYYY')}`,
  //   xPos,
  //   yPos,
  // )
  // yPos += 4
  // //   const diaChiBN = `Địa chỉ: ${detailBN.benhNhan.diaChi ?? ''}${detailBN.benhNhan.tenPhuongXa ? ', ' + detailBN.benhNhan.tenPhuongXa : ''}${detailBN.benhNhan.tenQuanHuyen ? ', ' + detailBN.benhNhan.tenQuanHuyen : ''}${detailBN.benhNhan.tenTinhTP ? ', ' + detailBN.benhNhan.tenTinhTP : ''}`
  // const diaChiBN = `Địa chỉ: ${infoBN?.diaChi + ', ' ?? ''}${infoBN?.tenPhuongXa + ', ' ?? ''}${infoBN?.tenQuanHuyen + ', ' ?? ''}${infoBN?.tenTinhTP + ', ' ?? ''}`
  // if (diaChiBN.length > 80) {
  //   const wordsArray = splitLongWordFitWidth(doc, diaChiBN, pageWidth - 20)
  //   for (let index = 0; index < wordsArray.length; index++) {
  //     doc.text(wordsArray[index], xPos, yPos)
  //     yPos += 5
  //   }
  // } else {
  //   doc.text(diaChiBN, xPos, yPos)
  //   yPos += 4
  // }
  // doc.text(`Cân nặng: ${infoBNTTC?.cannang ?? '...'} kg`, xPos, yPos)
  // doc.text(`Mạch: ${infoBNTTC?.mach ?? '...'} `, xPos + 27, yPos)
  // doc.text(`Huyết áp: ${infoBNTTC?.huyetap ?? '...'} mmHg`, xPos + 50, yPos)
  // doc.text(`Mã tiêm chủng quốc gia: ${infoBN?.maTCQG ?? ''}`, pageWidth - 60, yPos)
  // yPos += 2

  // let startTable = yPos
  // doc.line(xPos - 2, yPos, pageWidth - 8, yPos)
  // doc.setFontSize(8)
  // doc.text(
  //   'Dành cho người đi tiêm chủng: Những câu hỏi sau đây có thể giúp cân nhắc bạn có thể được tiêm những vaccin gì ',
  //   xPos,
  //   yPos + 3,
  // )
  // doc.text(
  //   'trong ngày hôm nay. Nếu bạn trả lời “ CÓ’’ cho bất kỳ câu hỏi nào không có nghĩa là bạn không nên tiêm chủng. ',
  //   xPos,
  //   yPos + 6,
  // )
  // doc.text(
  //   'Điều này chỉ có nghĩa là phải hỏi thêm nhiều câu khác. Nếu có câu hỏi nào không rõ ràng, vui lòng hỏi lại nhân viên ',
  //   xPos,
  //   yPos + 9,
  // )
  // doc.text('y tế tại nơi tiêm chủng để được giải thích rõ hơn.', xPos, yPos + 12)
  // doc.line(xPos - 2, yPos + 13, pageWidth - 8, yPos + 13)
  // yPos += 13
  // doc.setFontSize(9)
  // doc.text('BẢNG KIỂM TRƯỚC TIÊM CHỦNG ĐỐI VỚI NGƯỜI LỚN', xPos + 8, yPos + 4)
  // doc.setFontSize(8)
  // doc.text('Có', xPos + 102, yPos + 4)
  // doc.text('Không', xPos + 110, yPos + 4)
  // doc.text('Không rõ', xPos + 120.5, yPos + 4)
  // doc.setFontSize(9)

  // doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  // yPos += 5
  // doc.text('1. Hiện tại bạn có đang mắc bệnh lý gì không ?', xPos, yPos + 3.5)
  // generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.benhly === 1 ? true : false)
  // generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.benhly === 0 ? true : false)
  // generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.benhly === 2 ? true : false)

  // doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  // yPos += 5
  // doc.text('2. Bạn có dị ứng với thuốc, thức ăn hay vắc xin không ?', xPos, yPos + 3.5)
  // generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.diung === 1 ? true : false)
  // generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.diung === 0 ? true : false)
  // generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.diung === 2 ? true : false)

  // doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  // yPos += 5
  // doc.text(
  //   '3. Bạn có bị phản ứng nặng sau những lần tiêm chủng vắc xin trước đây ?',
  //   xPos,
  //   yPos + 3.5,
  // )
  // generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.phanungnang === 1 ? true : false)
  // generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.phanungnang === 0 ? true : false)
  // generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.phanungnang === 2 ? true : false)

  // doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  // yPos += 5
  // doc.text('4. Bạn có bị động kinh, hoặc có bệnh lý về não, thần kinh không ?', xPos, yPos + 3.5)
  // generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.thankinh === 1 ? true : false)
  // generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.thankinh === 0 ? true : false)
  // generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.thankinh === 2 ? true : false)

  // doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  // yPos += 5
  // doc.text(
  //   '5. Bạn có bị ưng thư, bệnh bạch cầu, AIDS hay bất cứ rối loạn hệ thống',
  //   xPos,
  //   yPos + 3.5,
  // )
  // doc.text('miễn dịch nào không ?', xPos, yPos + 7)
  // generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.ungthu === 1 ? true : false)
  // generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.ungthu === 0 ? true : false)
  // generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.ungthu === 2 ? true : false)

  // doc.line(xPos - 2, yPos + 8, pageWidth - 8, yPos + 8)
  // yPos += 8
  // doc.text(
  //   '6. Trong vòng 3 tháng qua, bạn có điều trị cortisone,prednisone,các thuốc',
  //   xPos,
  //   yPos + 3.5,
  // )
  // doc.text(
  //   'steroide khác hay thuốc điều trị ung thư, hoặc điều trị bằng tia X không ?',
  //   xPos,
  //   yPos + 7,
  // )
  // generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.corsiton === 1 ? true : false)
  // generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.corsiton === 0 ? true : false)
  // generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.corsiton === 2 ? true : false)

  // doc.line(xPos - 2, yPos + 8, pageWidth - 8, yPos + 8)
  // yPos += 8
  // doc.text(
  //   '7. Trong vòng một năm qua, bạn có được truyền máu hay các chế phẩm từ',
  //   xPos,
  //   yPos + 3.5,
  // )
  // doc.text('máu hoặc globulin miễn dịch không ?', xPos, yPos + 7)
  // generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.truyenmau === 1 ? true : false)
  // generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.truyenmau === 0 ? true : false)
  // generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.truyenmau === 2 ? true : false)

  // doc.line(xPos - 2, yPos + 8, pageWidth - 8, yPos + 8)
  // yPos += 8
  // doc.text(
  //   '8. Đối với phụ nữ, bạn có đang mang thai hay có dự định mang thai trong',
  //   xPos,
  //   yPos + 3.5,
  // )
  // doc.text('tháng tới không ?', xPos, yPos + 7)
  // generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.mangthai === 1 ? true : false)
  // generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.mangthai === 0 ? true : false)
  // generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.mangthai === 2 ? true : false)

  // doc.line(xPos - 2, yPos + 8, pageWidth - 8, yPos + 8)
  // yPos += 8
  // doc.text('9. Bạn có được tiêm chủng vắc xin trong vòng 4 tuần vừa qua không ?', xPos, yPos + 3.5)
  // generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.tiemchunG4TUAN === 1 ? true : false)
  // generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.tiemchunG4TUAN === 0 ? true : false)
  // generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.tiemchunG4TUAN === 2 ? true : false)

  // doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  // yPos += 5

  // let endTable = yPos
  // //line y
  // doc.line(xPos - 2, startTable, xPos - 2, endTable)
  // doc.line(xPos + 99, startTable + 13, xPos + 99, endTable)
  // doc.line(xPos + 108, startTable + 13, xPos + 108, endTable)
  // doc.line(xPos + 120, startTable + 13, xPos + 120, endTable)
  // doc.line(pageWidth - 8, startTable, pageWidth - 8, endTable)

  // yPos += 3.5
  // doc.setFont('times', 'italic')
  // doc.setFontSize(8)
  // const formattedDateTime = `Hồi ${moment(infoBNTTC.ngayKham ?? new Date()).format('HH')} giờ ${moment(infoBNTTC.ngayKham ?? new Date()).format('mm')} , ngày ${moment(infoBNTTC.ngayKham ?? new Date()).format('DD')} tháng ${moment(infoBNTTC.ngayKham ?? new Date()).format('MM')} năm ${moment(infoBNTTC.ngayKham ?? new Date()).format('YYYY')}`
  // doc.text(xPos, yPos, formattedDateTime)
  // doc.text(pageWidth - 50, yPos, 'Người điền thông tin (ký tên)')

  // doc.setFontSize(9)
  // doc.setFont('times', 'regular')
  // yPos += 10
  // let startTableBs = yPos
  // doc.line(xPos - 2, yPos, pageWidth - 8, yPos)
  // doc.text('DÀNH CHO BÁC SĨ TƯ VẤN VÀ THĂM KHÁM LÂM SÀNG', xPos + 8, yPos + 4)
  // doc.setFontSize(8)
  // doc.text('Có', xPos + 102, yPos + 4)
  // doc.text('Không', xPos + 110, yPos + 4)
  // doc.text('Không rõ', xPos + 120, yPos + 4)
  // doc.setFontSize(9)
  // doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  // yPos += 5
  // doc.text('1. Thân nhiệt ≥ 37.5°C hoặc ≤ 35.5°C', xPos, yPos + 3.5)
  // generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.sothathannhiet === 1 ? true : false)
  // generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.sothathannhiet === 0 ? true : false)
  // generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.sothathannhiet === 2 ? true : false)

  // doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  // yPos += 5
  // doc.text('2. Tri giác bất thường', xPos, yPos + 3.5)
  // generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.trigiacbatthuong === 1 ? true : false)
  // generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.trigiacbatthuong === 0 ? true : false)
  // generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.trigiacbatthuong === 2 ? true : false)

  // doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  // yPos += 5
  // doc.text('3. Tiếng tim bất thường', xPos, yPos + 3.5)
  // generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.timbatthuong === 1 ? true : false)
  // generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.timbatthuong === 0 ? true : false)
  // generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.timbatthuong === 2 ? true : false)

  // doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  // yPos += 5
  // doc.text('4. Nhịp thở, nghe phổi bất thường', xPos, yPos + 3.5)
  // generateCheckbox(doc, xPos + 102.5, yPos + 1, 3, 3, infoKSL?.nhipthophoibatthuong === 1 ? true : false)
  // generateCheckbox(doc, xPos + 113, yPos + 1, 3, 3, infoKSL?.nhipthophoibatthuong === 0 ? true : false)
  // generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.nhipthophoibatthuong === 2 ? true : false)

  // doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  // yPos += 5
  // //infoKSL?.khac
  // const chidinhkhac = `5. Các chống chỉ định khác: ${infoKSL.ghichu ?? ''}`
  // let tempYposChiDinh = yPos + 3
  // if (doc.getTextWidth(chidinhkhac) > 140) {
  //   doc.setFontSize(7)
  //   const wordsArray = splitLongWordFitWidth(doc, chidinhkhac, 125)
  //   for (let index = 0; index < wordsArray.length; index++) {
  //     doc.text(wordsArray[index], xPos, tempYposChiDinh)
  //     tempYposChiDinh += 2.5
  //   }
  //   doc.setFontSize(8)
  // } else {
  //   tempYposChiDinh = tempYposChiDinh + 2
  //   doc.text(chidinhkhac, xPos, tempYposChiDinh)
  // }

  // doc.line(xPos - 2, yPos + 8, pageWidth - 8, yPos + 8)
  // yPos += 8
  // let tempYpos = yPos + 3
  // doc.text('Kết luận : Đủ điều kiện tiêm chủng lần này :', xPos, yPos + 3.5)
  // const loaiVacXin = `Chỉ định vắc xin: ${tenVacXin ?? ''}`
  // if (doc.getTextWidth(loaiVacXin) > 80) {
  //   doc.setFontSize(6)
  //   const wordsArray = splitLongWordFitWidth(doc, loaiVacXin, 45)
  //   for (let index = 0; index < wordsArray.length; index++) {
  //     doc.text(wordsArray[index], xPos + 85, tempYpos)
  //     tempYpos += 2.5
  //   }
  //   doc.setFontSize(8)
  // } else {
  //   tempYpos = tempYpos + 2
  //   doc.text(loaiVacXin, xPos + 85, tempYpos)
  // }

  // doc.text('Có', xPos + 10, yPos + 6.5)
  // generateCheckbox(doc, xPos + 16, yPos + 4, 3, 3, infoKSL?.tiemchung === 1 ? true : false)
  // doc.text('Không', xPos + 40, yPos + 6.5)
  // generateCheckbox(doc, xPos + 50, yPos + 4, 3, 3, infoKSL?.tiemchung === 0 ? true : false)
  // //infoKSL?.lydo
  // const lydotrihoan = `Lý do trì hoãn: ${infoKSL.lydo ?? ''}`
  // let tempYposTriHoan = yPos + 9
  // if (doc.getTextWidth(lydotrihoan) > 120) {
  //   doc.setFontSize(7)
  //   const wordsArray = splitLongWordFitWidth(doc, lydotrihoan, 80)
  //   for (let index = 0; index < wordsArray.length; index++) {
  //     doc.text(wordsArray[index], xPos, tempYposTriHoan)
  //     tempYposTriHoan += 3
  //   }
  //   doc.setFontSize(8)
  // } else {
  //   tempYposTriHoan = tempYposTriHoan + 2
  //   doc.text(lydotrihoan, xPos, tempYposTriHoan)
  // }

  // doc.line(xPos - 2, yPos + 16, pageWidth - 8, yPos + 16)
  // yPos += 16
  // doc.text('Người đi tiêm chủng : Đã được tư vấn và đồng ý tiêm chủng', xPos, yPos + 3.5)
  // doc.text('Bác sỹ tư vấn', xPos + 98, yPos + 3.5)
  // yPos += 20
  // doc.text('Ký tên, ghi rõ họ tên:', xPos, yPos)
  // const leftLineX = xPos + 84;
  // const rightLineX = pageWidth - 8;
  // const textToCenter = infoKSL.bskham ?? '';

  // // Calculate the width of the text
  // const textWidth = doc.getTextWidth(textToCenter);

  // // Calculate the center point between the two lines
  // const centerX = (leftLineX + rightLineX) / 2;

  // // Calculate the starting x-coordinate for the text to be centered
  // const textX = centerX - (textWidth / 2);

  // // Draw the text
  // doc.text(textToCenter, textX, yPos);

  // doc.line(xPos - 2, yPos + 2, pageWidth - 8, yPos + 2)
  // let endTableBs = yPos + 2

  // //line y
  // doc.line(xPos - 2, startTableBs, xPos - 2, endTableBs)

  // doc.line(xPos + 99, startTableBs, xPos + 99, startTableBs + 25)
  // doc.line(xPos + 108, startTableBs, xPos + 108, startTableBs + 25)
  // doc.line(xPos + 120, startTableBs, xPos + 120, startTableBs + 25)

  // doc.line(xPos + 84, startTableBs + 33, xPos + 84, endTableBs)

  // doc.line(pageWidth - 8, startTableBs, pageWidth - 8, endTableBs)
  // // doc.save('Phieu-thu.pdf')
  // // auto printer
  // const blob = doc.output('blob')
  // const url = URL.createObjectURL(blob)
  // const printWindow = window.open(url)
  // if (printWindow) {
  //   printWindow.addEventListener(
  //     'load',
  //     function () {
  //       printWindow.print()
  //     },
  //     true,
  //   )
  // }
  //-----------MẪU MỚI 02/01/2025----------------
  const doc = jsPDFCus('p', 'mm', 'a5')
  let yPos = 10
  let xPos = 8
  let ruleWidth = 55 // for text of price is longer
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height
  doc.setFont('times', 'bold')
  doc.setFontSize(10)
  doc.text('HỆ THỐNG Y TẾ PHỤ SẢN 315', xPos + 4, yPos)
  doc.setFontSize(9)
  doc.text('CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM', pageWidth - 75, yPos)
  yPos += 4
  // doc.setFont('times', 'regular')
  doc.setFont('times', 'bold')
  doc.setFontSize(8)
  doc.text(PkDangNhap?.diaChi ?? '', xPos, yPos)
  doc.text('Độc lập - Tự do - Hạnh phúc', pageWidth - 60, yPos)
  // Vẽ đường gạch ngang dưới tiêu đề
  doc.setLineWidth(0.2)
  const startX = pageWidth - 50 // Bắt đầu đường gạch ngang chính giữa
  const endX = startX + 15 // Kết thúc đường gạch ngang
  const y = yPos + 3 // Vị trí y dưới dòng chữ

  const gapLength = 0.2 // Độ dài của khoảng trống giữa các đoạn gạch
  const dashLength = 1 // Độ dài của mỗi đoạn gạch

  // Tạo đường gạch đứt
  for (let x = startX; x < endX; x += dashLength + gapLength) {
    doc.line(x, y, Math.min(x + dashLength, endX), y)
  }
  yPos += 4

  doc.text(
    `Điện thoại: ${PkDangNhap.dienThoai ?? '0901.315.315'} - Hotline: ${PkDangNhap.sohotline ?? '0901.315.315'}`,
    xPos + 2,
    yPos,
  )
  yPos += 6
  doc.setFont('times', 'bold')
  doc.setFontSize(9)
  doc.text('BẢNG KIỂM TRƯỚC TIÊM CHỦNG ĐỐI VỚI NGƯỜI LỚN', doc.internal.pageSize.width / 2, yPos, {
    align: 'center',
  })
  yPos += 4
  doc.text('TẠI CÁC CƠ SỞ TIÊM CHỦNG NGOÀI BỆNH VIỆN', doc.internal.pageSize.width / 2, yPos, {
    align: 'center',
  })
  yPos += 6
  doc.setFont('times', 'regular')
  doc.text(`Họ và tên: ${infoBN?.tenBenhNhan ?? ''}`, xPos, yPos)
  doc.text('Nam', xPos + 70, yPos)
  if (infoBN?.gioiTinh?.includes('Nam')) {
    generateCheckbox(doc, xPos + 77, yPos - 3, 3, 3, true)
  } else {
    generateCheckbox(doc, xPos + 77, yPos - 3, 3, 3, false)
  }
  doc.setFontSize(9)
  doc.text('Nữ', xPos + 82, yPos)
  if (infoBN?.gioiTinh?.includes('Nữ')) {
    generateCheckbox(doc, xPos + 87, yPos - 3, 3, 3, true)
  } else {
    generateCheckbox(doc, xPos + 87, yPos - 3, 3, 3, false)
  }
  doc.setFontSize(9)

  doc.text(
    `Ngày sinh: ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('DD/MM/YYYY')}`,
    pageWidth - 47,
    yPos,
  )
  yPos += 4
  doc.text(`Điện thoại: ${infoBN?.dienThoai || ''}`, xPos, yPos)
  doc.text(`Mã tiêm chủng quốc gia: ${infoBN?.maTCQG ?? ''}`, xPos + 43, yPos)
  doc.text(
    `Tuổi: ${infoBN?.ngaySinh && calculateAgeForReport(infoBN?.ngaySinh)} `,
    // `Tuổi: ${infoBN?.ngaySinh && calculateAgeForReport(infoBN?.ngaySinh)}, sinh ngày ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('DD')} tháng ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('MM')} năm ${infoBN?.ngaySinh && dayjs(infoBN?.ngaySinh).format('YYYY')}`,
    xPos + 100,
    yPos,
  )

  yPos += 4
  //   const diaChiBN = `Địa chỉ: ${detailBN.benhNhan.diaChi ?? ''}${detailBN.benhNhan.tenPhuongXa ? ', ' + detailBN.benhNhan.tenPhuongXa : ''}${detailBN.benhNhan.tenQuanHuyen ? ', ' + detailBN.benhNhan.tenQuanHuyen : ''}${detailBN.benhNhan.tenTinhTP ? ', ' + detailBN.benhNhan.tenTinhTP : ''}`
  const diaChiBN = `Địa chỉ: ${infoBN?.diaChi + ', ' ?? ''}${infoBN?.tenPhuongXa + ', ' ?? ''}${infoBN?.tenQuanHuyen + ', ' ?? ''}${infoBN?.tenTinhTP + ', ' ?? ''}`
  if (diaChiBN.length > 80) {
    const wordsArray = splitLongWordFitWidth(doc, diaChiBN, pageWidth - 20)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 5
    }
  } else {
    doc.text(diaChiBN, xPos, yPos)
    yPos += 4
  }
  // doc.text(`Họ tên bố/mẹ: ${infoBNTTC?.tennguoinha ?? ''}`, xPos, yPos)
  // doc.text(`Điện thoại: ${infoBNTTC?.dienthoainguoinha ?? ''}`, pageWidth - 60, yPos)
  // yPos += 4
  doc.text(`Nhiệt độ: ${infoBNTTC?.nhietdo ?? '...'} °C`, xPos, yPos)
  doc.text(`Cân nặng: ${infoBNTTC?.cannang ?? '...'} kg`, xPos + 32, yPos)
  doc.text(`Mạch: ${infoBNTTC?.mach ?? '...'} lần/phút`, xPos + 65, yPos)
  doc.text(`Huyết áp: ${infoBNTTC?.huyetap ?? '...'} mmHg`, xPos + 100, yPos)
  // doc.text(`Mã tiêm chủng quốc gia: ${infoBN?.maTCQG ?? ''}`, pageWidth - 70, yPos)
  yPos += 2
  doc.setFont('times', 'bold')
  doc.line(xPos - 2, yPos, pageWidth - 8, yPos)
  doc.text('I. Khám sàng lọc', xPos, yPos + 4)
  doc.setFont('times', 'regular')
  doc.setFontSize(9)
  doc.text('Không', xPos + 108, yPos + 4)
  doc.text('Có', xPos + 124, yPos + 4)
  yPos += 5
  //table
  doc.line(xPos - 2, yPos, pageWidth - 8, yPos)
  doc.text(
    '1. Phản vệ độ III trở lên sau lần tiêm chủng trước (vắc xin có cùng thành phần)',
    xPos,
    yPos + 4,
  )
  generateCheckbox(doc, xPos + 111, yPos + 1, 3, 3, infoKSL?.phanvedoiii === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 1, 3, 3, infoKSL?.phanvedoiii === 1 ? true : false)

  doc.line(xPos - 2, yPos + 5, pageWidth - 8, yPos + 5)
  doc.text('2. Phản vệ độ II sau lần tiêm chủng trước (vắc xin có cùng thành phần)', xPos, yPos + 9)
  generateCheckbox(doc, xPos + 111, yPos + 6, 3, 3, infoKSL?.phanvedoii === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 6, 3, 3, infoKSL?.phanvedoii === 1 ? true : false)

  doc.line(xPos - 2, yPos + 10, pageWidth - 8, yPos + 10)
  doc.text(
    '3. Phản ứng tăng dần sau các lần tiêm chủng trước (vắc xin có cùng thành phần).',
    xPos,
    yPos + 14,
  )
  // doc.text('3. Đang mắc bệnh cấp tính hoặc bệnh mạn tính tiến triển*', xPos, yPos + 14)
  generateCheckbox(doc, xPos + 111, yPos + 11, 3, 3, infoKSL?.phanungtang === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 11, 3, 3, infoKSL?.phanungtang === 1 ? true : false)

  doc.line(xPos - 2, yPos + 15, pageWidth - 8, yPos + 15)
  doc.text('4. Đang mắc bệnh cấp tính hoặc bệnh mãn tính tiến triển*', xPos, yPos + 19)
  generateCheckbox(
    doc,
    xPos + 111,
    yPos + 16,
    3,
    3,
    infoKSL?.benhcaptinhmantinh === 0 ? true : false,
  )
  generateCheckbox(
    doc,
    xPos + 124.5,
    yPos + 16,
    3,
    3,
    infoKSL?.benhcaptinhmantinh === 1 ? true : false,
  )

  doc.line(xPos - 2, yPos + 20, pageWidth - 8, yPos + 20)
  doc.text(
    '5. Suy giảm miễn dịch chưa xác định mức độ hoặc mức độ nặng, có biểu hiện lâm',
    xPos,
    yPos + 24,
  )
  doc.text(' sàng nghi nhiễm HIV.', xPos, yPos + 28)

  generateCheckbox(doc, xPos + 111, yPos + 22, 3, 3, infoKSL?.suygiammiendich === 0 ? true : false)
  generateCheckbox(
    doc,
    xPos + 124.5,
    yPos + 22,
    3,
    3,
    infoKSL?.suygiammiendich === 1 ? true : false,
  )

  doc.line(xPos - 2, yPos + 30, pageWidth - 8, yPos + 30)
  doc.text(
    '6. Đang hoặc mới kết thúc đợt điều trị corticoid liều cao (tương đương ',
    xPos,
    yPos + 34,
  )
  doc.text('prednison >2mg/kg/ngày), hóa trị, xạ trị, gammaglobulin**', xPos, yPos + 38)

  generateCheckbox(doc, xPos + 111, yPos + 33, 3, 3, infoKSL?.corticoid === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 33, 3, 3, infoKSL?.corticoid === 1 ? true : false)

  doc.line(xPos - 2, yPos + 40, pageWidth - 8, yPos + 40)
  doc.text('7. Sốt ≥ 37,5°C hoặc hạ thân nhiệt ≤ 35,5°C.', xPos, yPos + 44)
  generateCheckbox(doc, xPos + 111, yPos + 41, 3, 3, infoKSL?.sothathannhiet === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 41, 3, 3, infoKSL?.sothathannhiet === 1 ? true : false)

  doc.line(xPos - 2, yPos + 45, pageWidth - 8, yPos + 45)
  doc.text('8. Nghe tim bất thường', xPos, yPos + 49)
  generateCheckbox(doc, xPos + 111, yPos + 46, 3, 3, infoKSL?.timbatthuong === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 46, 3, 3, infoKSL?.timbatthuong === 1 ? true : false)

  doc.line(xPos - 2, yPos + 50, pageWidth - 8, yPos + 50)
  doc.text('9. Nhịp thở, nghe phổi bất thường', xPos, yPos + 54)
  generateCheckbox(
    doc,
    xPos + 111,
    yPos + 51,
    3,
    3,
    infoKSL?.nhipthophoibatthuong === 0 ? true : false,
  )
  generateCheckbox(
    doc,
    xPos + 124.5,
    yPos + 51,
    3,
    3,
    infoKSL?.nhipthophoibatthuong === 1 ? true : false,
  )

  doc.line(xPos - 2, yPos + 55, pageWidth - 8, yPos + 55)
  doc.text('10. Tri giác bất thường (li bì hoặc kích thích)', xPos, yPos + 59)
  generateCheckbox(doc, xPos + 111, yPos + 56, 3, 3, infoKSL?.trigiacbatthuong === 0 ? true : false)
  generateCheckbox(
    doc,
    xPos + 124.5,
    yPos + 56,
    3,
    3,
    infoKSL?.trigiacbatthuong === 1 ? true : false,
  )

  doc.line(xPos - 2, yPos + 60, pageWidth - 8, yPos + 60)
  doc.text(
    '11. Với khách nữ: đang có thai hoặc dự định có thai vào tháng tới không ? ***',
    xPos,
    yPos + 64,
  )
  generateCheckbox(doc, xPos + 111, yPos + 61, 3, 3, infoKSL?.mangthai === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 61, 3, 3, infoKSL?.mangthai === 1 ? true : false)

  doc.line(xPos - 2, yPos + 65, pageWidth - 8, yPos + 65)
  doc.text(
    '12. Các chống chỉ định khác / tạm hoãn tiêm khác, nếu có ghi rõ: ..........................',
    xPos,
    yPos + 69,
  )
  doc.text(
    '.................................................................................................................................',
    xPos,
    yPos + 73,
  )
  doc.text(infoKSL?.ghichu && infoKSL?.khac === 1 ? infoKSL?.ghichu : '', xPos, yPos + 73)
  generateCheckbox(doc, xPos + 111, yPos + 68, 3, 3, infoKSL?.khac === 0 ? true : false)
  generateCheckbox(doc, xPos + 124.5, yPos + 68, 3, 3, infoKSL?.khac === 1 ? true : false)

  doc.line(xPos - 2, yPos + 75, pageWidth - 8, yPos + 75)

  doc.line(xPos - 2, yPos - 5, xPos - 2, yPos + 75)
  doc.line(xPos + 105, yPos - 5, xPos + 105, yPos + 75)
  doc.line(xPos + 120, yPos - 5, xPos + 120, yPos + 75)
  doc.line(pageWidth - 8, yPos - 5, pageWidth - 8, yPos + 75)
  yPos += 78
  doc.setFontSize(8)
  doc.setFont('times', 'italic')
  doc.text(
    '*Không hoãn tiêm nếu bệnh nhẹ (ho, sổ mũi, tiêu chảy nhẹ... và không sốt), ăn uống tốt',
    xPos,
    yPos,
  )
  yPos += 3
  doc.text(
    '** Ngoại trừ kháng huyết thanh viêm gan B. Tiêu chuẩn này chỉ áp dụng với vắc xin sống giảm độc lực',
    xPos,
    yPos,
  )
  yPos += 3
  doc.text(
    '*** Không hoãn tiêm vắc xin uốn ván hấp thụ và 1 số vắc xin khác (ký cam kết)',
    xPos,
    yPos,
  )
  yPos += 3
  doc.setFont('times', 'bold')
  doc.text('II. Kết luận', xPos, yPos)
  yPos -= 7
  doc.line(xPos - 2, yPos + 8, pageWidth - 8, yPos + 8)
  doc.setFont('times', 'regular')
  doc.text('- ', xPos, yPos + 11.5)

  // Thiết lập font chữ đậm và in "Đủ điều kiện tiêm chủng ngay"
  doc.setFont('times', 'bold')
  doc.text('Đủ điều kiện tiêm chủng ngay', xPos + 2, yPos + 11.5)

  // Thiết lập lại font chữ bình thường
  doc.setFont('times', 'regular')
  doc.text(' (Tất cả đều ', xPos + 38, yPos + 11.5)

  // Thiết lập font chữ đậm và in "KHÔNG"
  doc.setFont('times', 'bold')
  doc.text('KHÔNG', xPos + 52, yPos + 11.5)

  // Thiết lập lại font chữ bình thường
  doc.setFont('times', 'regular')
  doc.text(' có điểm bất thường)', xPos + 63, yPos + 11.5)
  // doc.text('- Đủ điều kiện tiêm chủng ngay (Tất cả đều KHÔNG có điểm bất thường)', xPos, yPos + 12)
  doc.setFont('times', 'regular')
  generateCheckbox(doc, xPos + 125, yPos + 9, 3, 3, infoKSL?.tiemchung === 1 ? true : false)

  doc.line(xPos - 2, yPos + 13, pageWidth - 8, yPos + 13)
  doc.setFont('times', 'regular')

  const loaiVacXin = `Vắc xin chủng ngừa lần này: ${tenVacXin ?? ''}`
  let tempYpos = yPos + 16
  if (doc.getTextWidth(loaiVacXin) > 120) {
    doc.setFontSize(7)
    const wordsArray = splitLongWordFitWidth(doc, loaiVacXin, pageWidth - 20)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, tempYpos)
      tempYpos += 2.5
    }
    doc.setFontSize(8)
  } else {
    tempYpos = tempYpos + 2
    doc.text(loaiVacXin, xPos, tempYpos)
  }

  doc.line(xPos - 2, yPos + 22, pageWidth - 8, yPos + 22)

  doc.text('- Chống chỉ định tiêm chủng (Khi CÓ điểm bất thường tại mục 1, 12)', xPos, yPos + 26)

  generateCheckbox(
    doc,
    xPos + 125,
    yPos + 23,
    3,
    3,
    infoKSL?.chongchidinhtiemchung === 1 ? true : false,
  )

  doc.line(xPos - 2, yPos + 27, pageWidth - 8, yPos + 27)
  doc.text(
    '- Tạm hoãn tiêm chủng (Khi CÓ bất kỳ một điểm bất thường tại các mục 2,3,4,5,6,7,8,9,10,11)',
    xPos,
    yPos + 31,
  )
  generateCheckbox(doc, xPos + 125, yPos + 28, 3, 3, infoKSL?.tamhoantiemchung === 1 ? true : false)

  doc.line(xPos - 2, yPos + 32, pageWidth - 8, yPos + 32)
  doc.line(pageWidth - 8, yPos + 8, pageWidth - 8, yPos + 32)
  doc.line(xPos - 2, yPos + 8, xPos - 2, yPos + 32)

  doc.line(xPos + 122, yPos + 8, xPos + 122, yPos + 13)
  doc.line(xPos + 122, yPos + 22, xPos + 122, yPos + 32)

  yPos += 32
  doc.text('Đề nghị khám sàng lọc tại bệnh viện: ', xPos, yPos + 4)
  doc.text('Không', xPos + 58, yPos + 4)
  generateCheckbox(doc, xPos + 70, yPos + 1.5, 3, 3, infoKSL?.benhvien === 0 ? true : false)
  doc.text('Có', xPos + 85, yPos + 4)
  generateCheckbox(doc, xPos + 90.1, yPos + 1.5, 3, 3, infoKSL?.benhvien === 1 ? true : false)
  yPos += 7
  const lydo = `+ Lý do: ${infoKSL?.lydo ?? '.........................................................................................................................................................................'}`
  if (doc.getTextWidth(lydo) > 120) {
    const wordsArray = splitLongWordFitWidth(doc, lydo, pageWidth - 15)
    for (let index = 0; index < wordsArray.length; index++) {
      doc.text(wordsArray[index], xPos, yPos)
      yPos += 4
    }
  } else {
    doc.text(lydo, xPos, yPos)
  }
  if (infoKSL?.lydo) {
    yPos += 4
  }
  doc.setFont('times', 'italic')
  const formattedDateTime = `Lúc ${moment(infoBNTTC.ngaykham ?? new Date()).format('HH')} giờ ${moment(infoBNTTC.ngaykham ?? new Date()).format('mm')} phút, ngày ${moment(infoBNTTC.ngaykham ?? new Date()).format('DD')} tháng ${moment(infoBNTTC.ngaykham ?? new Date()).format('MM')} năm ${moment(infoBNTTC.ngaykham ?? new Date()).format('YYYY')}`
  doc.text(pageWidth - 65, yPos, formattedDateTime)
  doc.text(xPos + 2, yPos, formattedDateTime)
  yPos += 3
  doc.setFont('times', 'bold')
  doc.text('Tôi đã được tư vấn và đồng ý tiêm chủng', xPos + 5, yPos)
  doc.setFont('times', 'regular')
  doc.text('(Ký, ghi rõ họ tên)', xPos + 20, yPos + 3)
  doc.text(pageWidth - 50, yPos + 3, '(Ký, ghi rõ họ tên)')
  doc.setFont('times', 'bold')
  doc.text(
    pageWidth - 5 - 'Bác sĩ thực hiện sàng lọc'.length * 2,
    yPos,
    'Bác sĩ thực hiện sàng lọc',
  )
  const doctorText = infoKSL?.bskham ?? ''
  const dateTextWidth = doc.getTextWidth(formattedDateTime)
  const doctorTextWidth = doc.getTextWidth(doctorText)
  const dateTextX = pageWidth - dateTextWidth - 10
  const dateCenterX = dateTextX + dateTextWidth / 2
  const doctorTextX = dateCenterX - doctorTextWidth / 2
  doc.text(doctorText, doctorTextX, yPos + 15)
  // for (let i = 0; i < pageWidth; i += 5) {
  //   doc.line(i, 0, i, pageHeight, 'S')
  // }
  // for (let j = 0; j < pageHeight; j += 5) {
  //   doc.line(0, j, pageWidth, j, 'S')
  // }
  // doc.save('Phieu-thu.pdf')
  // auto printer
  const blob = doc.output('blob')
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url)

  if (printWindow) {
    printWindow.addEventListener(
      'load',
      function () {
        printWindow.print()
      },
      true,
    )
  }
}
