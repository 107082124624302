import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  listBN: [],
  listTemplate: [],
  infoTemplate: null,
  accountSMS: [],
  infoAccount: null,
}

const smsReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_LIST_BN_SMS:
        draft.listBN = payload
        break
      case typeAction.DISPATCH_LIST_TEMPLATE_SMS:
        draft.listTemplate = payload
        break
      case typeAction.DISPATCH_INFO_TEMPLATE_SMS:
        draft.infoTemplate = payload
        break
      case typeAction.DISPATCH_ACCOUNT_SMS:
        draft.accountSMS = payload
        break
      case typeAction.DISPATCH_INFO_ACCOUNT_SMS:
        draft.infoAccount = payload
        break
      default:
        return state
    }
  })
}

export default smsReducer
