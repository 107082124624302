import { DatePicker, Input, Modal, Select } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { putCaLamViecCuaPhieuThuAction } from '../../../../store/actions/toolAction'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
moment.locale('vi')
const optionsLoaiThanhToan = [
  { key: 1, value: 1, label: 'Tiền dịch vụ' },
  { key: 2, value: 2, label: 'Tiền xét nghiệm' },
  { key: 3, value: 3, label: 'Tiền chẩn đoán hình ảnh' },
  { key: 4, value: 4, label: 'Bán thuốc theo toa' },
  { key: 5, value: 5, label: 'Bán lẻ' },
  { key: 6, value: 6, label: 'Xuất bán vắc xin' },
]

const ModalUpdateCaLamViecPhieuThu = ({
  openCapNhatCaLamViecPhieuThu,
  setOpenCapNhatCaLamViecPhieuThu,
}) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const [form, setForm] = useState(null)
  const hanldeSubmit = () => {
    if (!form?.maPhieuThu || !form?.idLoaiTT || !form?.idCaLamViec) {
      setForm((prev) => ({
        maPhieuThu: prev?.maPhieuThu ?? null,
        idLoaiTT: prev?.idLoaiTT ?? null,
        idCaLamViec: prev?.idCaLamViec ?? null,
      }))
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    handleCancel()
    dispatch(
      putCaLamViecCuaPhieuThuAction({ ...form, idNguoiSua: infoUser?.idnv }, reset, {
        loaithanhtoan: optionsLoaiThanhToan.find((item) => item?.value === form?.idLoaiTT)?.label,
        calamviec: form?.idCaLamViec === 1 ? 'Ca A' : form?.idCaLamViec === 2 ? 'Ca B' : 'Ca C',
      }),
    )
  }
  const handleCancel = () => {
    setOpenCapNhatCaLamViecPhieuThu(false)
  }
  const reset = () => {
    setForm(null)
    handleCancel()
  }
  return (
    <Modal
      onOk={hanldeSubmit}
      okText="Cập nhật"
      cancelText="Huỷ bỏ"
      title="Cập nhật ca làm việc"
      open={openCapNhatCaLamViecPhieuThu}
      onCancel={handleCancel}
    >
      <div className="grid grid-flow-row items-center gap-2 pt-2 pb-2">
        <div>
          <label className="font-semibold text-base">Mã phiếu thu</label>
          <Input
            status={form && !form?.maPhieuThu && 'error'}
            placeholder="Nhập mã phiếu thu PT..."
            name="maphieuthu"
            value={form?.maPhieuThu}
            onChange={(e) => setForm((prev) => ({ ...prev, maPhieuThu: e.target.value?.trim() }))}
          />
        </div>
        <div className="flex flex-col">
          <label className="font-semibold text-base">Loại thanh toán</label>
          <Select
            status={form && !form?.idLoaiTT && 'error'}
            placeholder="Chọn loại thanh toán..."
            value={form?.idLoaiTT}
            onChange={(value) => setForm((prev) => ({ ...prev, idLoaiTT: value }))}
            options={optionsLoaiThanhToan}
          />
        </div>
        <div className="flex flex-col">
          <label className="font-semibold text-base ">Ca làm việc</label>
          <Select
            status={form && !form?.idCaLamViec && 'error'}
            placeholder="Chọn ca làm việc..."
            value={form?.idCaLamViec}
            onChange={(value) => setForm((prev) => ({ ...prev, idCaLamViec: value }))}
            options={[
              { key: 1, value: 1, label: 'Ca A' },
              { key: 2, value: 2, label: 'Ca B' },
              { key: 3, value: 3, label: 'Ca C' },
            ]}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ModalUpdateCaLamViecPhieuThu
