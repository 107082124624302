import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { DonViTinhService } from '../../services/DonViTinh/DonViTinhService'

import Swal from 'sweetalert2'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export function* donViTinhSaga() {
  // ADD DON VI TINH
  yield takeLatest(typeAction.ADD_DON_VI_TINH, function* addDonViTinh({ type, form, resetForm }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)

    try {
      yield call(() => DonViTinhService.AddDonViTinh(form))
      yield call(() => resetForm())
      yield Toast.fire({
        icon: 'success',
        title: 'Thêm đơn vị tính thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.THEM_MOI_DVT,
        true,
        MENU_CONSTANTS.DON_VI_TINH,
        null,
        {
          form,
        },
      )
    } catch (error) {
      // yield Toast.fire({
      //     icon: 'error',
      //     title: 'Thêm đơn vị tính thất bại',
      // })
      yield Toast.fire({
        icon: 'error',
        title: 'Thêm đơn vị tính thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.DON_VI_TINH,
        detailErrorPayload,
      )
    }
  })

  // UPDATE DON VI TINH
  yield takeLatest(
    typeAction.UPDATE_DON_VI_TINH,
    function* updateDonViTinh({ type, payload, resetForm }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield call(() => DonViTinhService.UpdateDonViTinh(payload.ma, payload.data))
        yield call(() => resetForm())
        yield Toast.fire({
          icon: 'success',
          title: 'Cập nhật đơn vị tính thành công',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_SUA_DVT,
          true,
          MENU_CONSTANTS.DON_VI_TINH,
          null,
          {
            form: payload.data,
          },
        )
      } catch (error) {
        yield Toast.fire({
          icon: 'error',
          title: 'Cập nhật đơn vị tính thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(payload.data)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.DON_VI_TINH,
          detailErrorPayload,
        )
      }
    },
  )

  // GET BY MA DON VI TINH
  yield takeLatest(typeAction.GET_BY_MA_DON_VI_TINH, function* getByMaDonViTinh({ type, ma }) {
    try {
      const result = yield call(() => DonViTinhService.GetByMaDonViTinh(ma))
      yield put({
        type: typeAction.DISPATCH_BY_MA_DON_VI_TINH,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })

  // DELETE DON VI TINH
  yield takeLatest(typeAction.DELETE_DON_VI_TINH, function* deleteDonViTinh({ type, ma }) {
    try {
      yield call(() => DonViTinhService.DeleteDonViTinh(ma))
      yield Toast.fire({
        icon: 'success',
        title: 'Xóa đơn vị tính thành công',
      })
    } catch (err) {
      yield Toast.fire({
        icon: 'error',
        title: 'Xóa đơn vị tính thất bại',
      })
    }
  })
}
