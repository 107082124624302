import { Input, Modal, Table, Popconfirm } from 'antd'
import { UserAddOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CreateAccount from './CreateAccount'
import { deleteAccountSMS } from '../../../../../store/actions/smsAction'
import EditAccount from './EditAccount'
import * as TypeAction from '../../../../../store/constants/constants'
const AccountSMS = ({ show, setShow }) => {
  const { accountSMS } = useSelector((state) => state.smsReducer)
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [editing, setEditing] = React.useState(false)
  return (
    <>
      <Modal
        width={'50%'}
        open={show}
        onCancel={() => setShow(false)}
        okText="Thêm tài khoản"
        cancelText="Đóng"
        onOk={() => setOpen(true)}
      >
        <div className="text-lg">
          Tài khoản SMS <UserAddOutlined style={{ fontSize: 18 }} />
        </div>
        <Table
          pagination={false}
          scroll={{ y: 400 }}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              render: (text, _, index) => ++index,
              width: 60,
              align: 'center',
            },
            {
              title: 'Tài khoản',
              dataIndex: 'taikhoan',
              width: 150,
            },
            {
              title: 'Mật khẩu',
              dataIndex: 'matkhau',
              render: (text) => (
                <Input.Password
                  size="small"
                  variant="borderless"
                  readOnly
                  value={text}
                  placeholder="input password"
                />
              ),
            },
            {
              title: 'BrandName',
              dataIndex: 'brandname',
            },
            {
              title: 'Ghi chú',
              dataIndex: 'ghichu',
            },
            {
              title: '',
              dataIndex: 'action',
              width: 80,
              render: (_, record) => (
                <ul className="flex justify-around gap-5">
                  <li className="w-1/2 text-red-500">
                    <Popconfirm
                      title="xoá tài khoản này?"
                      description="Bạn có chắc muốn xoá tài này?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => {
                        dispatch(deleteAccountSMS(record.idtaikhoan))
                      }}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </li>
                  <li
                    className="w-1/2 text-green-700 cursor-pointer"
                    onClick={() => {
                      setEditing(true)
                      dispatch({ type: TypeAction.DISPATCH_INFO_ACCOUNT_SMS, payload: record })
                    }}
                  >
                    <EditOutlined />
                  </li>
                </ul>
              ),
            },
          ]}
          dataSource={accountSMS}
        />
      </Modal>
      <CreateAccount open={open} setOpen={setOpen} />
      <EditAccount open={editing} setOpen={setEditing} />
    </>
  )
}

export default AccountSMS
