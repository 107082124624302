// src/components/layout/IDC10/ModalEdit.jsx
import React, { useMemo, useState } from 'react'
import { Modal, Form, Input, Select, notification } from 'antd'
import { https } from '../../../services/apiService'
import { useSelector, useDispatch } from 'react-redux'
import { getICD10ByChuongs } from '../../../store/actions/ICD10Action'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import { addLog } from '../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../utils/log/logConstant'
import { formatPayloadDetails } from '../../../utils/algorithm'

const ModalEdit = ({ isEditModalVisible, handleOk, handleEditCancel, editRecord }) => {
  const [form] = Form.useForm()
  const [IdChuong, setIdChuong] = useState('')
  const { Chuongs } = useSelector((state) => state.IDC10Reducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()

  const maQuyenSuaIcd = 'QHT435'

  const nameChuong = useMemo(() => {
    return !IdChuong ? 'Tất cả' : Chuongs?.find((item) => item.idChuong === IdChuong)?.chuongTV
  }, [IdChuong, Chuongs])

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaIcd:
        onFinish(infoNew)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaIcd:
        //
        break
      default:
        return false
    }
  }

  React.useEffect(() => {
    if (editRecord) {
      form.setFieldsValue({
        mabenh: editRecord.mabenh,
        icD10TV: editRecord.icD10TV,
        icD10TA: editRecord.icD10TA,
        ghichu: editRecord.ghichu,
        idChuong: editRecord.idchuong,
        mabenhkodau: editRecord.mabenhkodau,
      })
      setIdChuong(editRecord.idchuong)
    }
  }, [editRecord, form])

  const onFinish = async (values) => {
    const payload = {
      idicD10: editRecord.idicD10,
      icD10TV: values.icD10TV,
      icD10TA: values.icD10TA,
      idchuong: values.idChuong,
      ghichu: values.ghichu,
      mabenh: values.mabenh,
      mabenhkodau: values.mabenhkodau,
    }
    try {
      const response = await https.put(
        `ChanDoan/UpdateChanDoanICD10?idicd10=${editRecord.idicD10}`,
        payload,
      )

      if (response.status === 200) {
        notification.success({
          message: 'Cập nhật thành công',
          description: 'ICD-10 đã được cập nhật thành công.',
        })
        form.resetFields()
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          logAction.CHINH_SUA_ICD10,
          true,
          MENU_CONSTANTS.ICD_10,
          null,
          {
            ...payload,
            nameChuong,
          },
        )
        handleOk()
        handleEditCancel()
        // dispatch(getICD10ByChuongs('', IdChuong, 1, 50, true, () => {})) // Reload IDC10s
      }
    } catch (error) {
      notification.error({
        message: 'Cập nhật thất bại',
        description: 'Có lỗi xảy ra khi cập nhật ICD-10.',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(payload)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.ICD_10,
        detailErrorPayload,
      )
    }
  }

  return (
    <Modal
      title={`Cập nhật ICD-10: ${editRecord?.icD10TV} `}
      open={isEditModalVisible}
      onOk={form.submit}
      onCancel={handleEditCancel}
      okText="Cập nhật"
      cancelText="Huỷ"
    >
      <Form
        form={form}
        layout="vertical"
        // onFinish={onFinish}
        onFinish={(values) =>
          dispatch(putCheckQuyenThaoTacAction(maQuyenSuaIcd, values, actionIsTrue, actionIsFalse))
        }
      >
        <Form.Item
          label="Chương"
          name="idChuong"
          rules={[{ required: true, message: 'Vui lòng chọn chương' }]}
        >
          <Select
            onChange={setIdChuong}
            value={IdChuong}
            options={[
              ...Chuongs?.map((chuong) => ({
                label: chuong.chuongTV,
                value: chuong.idChuong,
              })),
            ]}
            className="text-sm"
          />
        </Form.Item>

        <Form.Item
          name="mabenh"
          label="Mã ICD-10"
          rules={[{ required: true, message: 'Vui lòng nhập mã ICD-10!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Mã bệnh không dấu"
          name="mabenhkodau"
          rules={[{ required: true, message: 'Vui lòng nhập mã bệnh không dấu' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="icD10TV"
          label="Tên tiếng Việt"
          rules={[{ required: true, message: 'Vui lòng nhập tên tiếng Việt!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="icD10TA"
          label="Tên tiếng Anh"
          rules={[{ required: true, message: 'Vui lòng nhập tên tiếng Anh!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="ghichu" label="Ghi chú">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalEdit
