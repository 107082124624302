import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import IconButton from '@mui/material/IconButton'
import CachedIcon from '@mui/icons-material/Cached'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import _, { debounce } from 'lodash'
import * as XLSX from 'xlsx'
import Highlighter from 'react-highlight-words'
import { ConfigProvider, Input, Select, Table, DatePicker, Button, Space } from 'antd'
import { SearchOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  ListGroupsVTByTypes,
  getListCountry_Action,
  getListThuocVTByPageIndexGiaBan_Action,
  listTypesThuocVT_Action,
} from '../../../store/actions/thuocVTAction'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import { getlistDoitac } from '../../../store/actions/NhapKhoAction'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import dayjs from 'dayjs'
import moment, { months } from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'moment/locale/vi'
import 'dayjs/locale/vi'
import { addLog } from '../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../utils/log/logConstant'
moment.locale('vi')
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'

const GiaBan = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState(null)
  const [types, setTypes] = useState(null)
  const [page, setPage] = useState(1)
  const id_company = Number(localStorage.getItem('id_company'))
  const [company, setCompany] = useState(id_company)
  const [valueGroups, SetValueGroups] = useState(null)
  const [pageIndex, setPageIndex] = useState(1)
  const { listThuocVT, typesVT, groupsVT } = useSelector((state) => state.thuocVTReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [totalPages, setTotalPages] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [dateFrom, setDateFrom] = useState(moment().format(momentDate))
  const [dateTo, setDateTo] = useState(moment().format(momentDate))
  const [columns, setColumns] = useState([])
  const [searchText, setSearchText] = useState('')
  const searchInput = useRef(null)
  const [valueExport, setValueExport] = useState([])
  const [searchedColumn, setSearchedColumn] = useState('')
  const [dataSource, setDataSource] = useState([])
  const maQuyenXemDs = 'QHT630'
  const maQuyenXemCt = 'QHT193'
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const onChangeTable = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        dispatch(
          getListThuocVTByPageIndexGiaBan_Action(
            infoNew?.keyword,
            infoNew?.company,
            infoNew?.types,
            infoNew?.valueGroups,
            infoNew?.page,
            infoNew?.reset,
            setIsLoading,
            setTotalPages,
            dateFrom,
            dateTo,
            {
              nameCompany,
              nameLoaiThuocVT,
              nameNhomThuocVT,
            },
          ),
        )
        break
      case maQuyenXemCt:
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXemCt:
        //
        break
      default:
        return false
    }
  }

  const debounceDropDown = useCallback(
    _.debounce((keyword, company, types, valueGroups, page, reset) => {
      dispatch(
        putCheckQuyenThaoTacAction(
          maQuyenXemDs,
          {
            keyword: keyword,
            company: company,
            types: types,
            valueGroups: valueGroups,
            page: page,
            reset: reset,
          },
          actionIsTrue,
          actionIsFalse,
        ),
      )
    }, 300),
    [],
  )
  const handleCompany = (value) => {
    setCompany(value)
    loadMoreData(true, search, 1)
  }
  const handleSearchInput = (e) => {
    const { value } = e.target
    setSearch(value)
    setPage(1)
    debounceDropDown(value, company, types, valueGroups, 1, true)
  }
  const getListThuocVT = () => {
    setPage(1)
    loadMoreData(true, search, 1)
  }
  const handleGroupsThuocVT = (value) => {
    SetValueGroups(value)
  }
  const handleTypesThuocVT = (value) => {
    // get list nhóm thuốc vật tư sau khi chọn loại
    setTypes(value)
    SetValueGroups(null)
    dispatch(ListGroupsVTByTypes(value))
  }
  useEffect(() => {
    dispatch(listTypesThuocVT_Action())
    dispatch(getlistDoitac())
    dispatch(getListCountry_Action())
    dispatch(listAllCompanyAction())
    loadMoreData(true, search, page)
  }, [])
  const fetchDataSource = (reset, search, page) => {
    dispatch(
      putCheckQuyenThaoTacAction(
        maQuyenXemDs,
        {
          keyword: search,
          company: company,
          types: types,
          valueGroups: valueGroups,
          page: page,
          reset: reset,
        },
        actionIsTrue,
        actionIsFalse,
      ),
    )
  }
  const loadMoreData = async (reset, search, page) => {
    if (totalPages < page) return // NẾU DỮ LIỆU CHỈ CÓ 1 PAGE THÌ DỪNG CALL API
    fetchDataSource(reset, search, page)
    setPage((prevPage) => prevPage + 1)
  }
  const handleScroll = useCallback(
    debounce(() => {
      const tableBody = document.querySelector('.table-thumua .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 100 &&
        !isLoading
      ) {
        loadMoreData(false, search, page)
      }
    }, 300),
    [isLoading],
  )
  useEffect(() => {
    const tableBody = document.querySelector('.table-thumua .ant-table-body')
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])
  const exportToExcel = async () => {
    try {
      let rows = []
      let stt = 1
      valueExport.forEach((product) => {
        Object.keys(product.giabanthuoc).forEach((date) => {
          let item = product.giabanthuoc[date]
          rows.push([
            stt++, // STT
            product.maThuoc, // Mã Hàng
            product.tenBietDuoc, // Tên Hàng
            product.tenphanloai, // Loại Hàng
            product.nhom, // Nhóm Hàng
            product.dvt, // Đơn vị lẻ
            item.OldPrice, // Giá bán lẻ cũ
            item.NewPrice, // Giá bán lẻ mới
            item.PersonChanged, // Người thay đổi
            moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'), // Ngày thay đổi
          ])
        })
      })
      const ws = XLSX.utils.aoa_to_sheet([
        [
          'STT',
          'Mã Hàng',
          'Tên Hàng',
          'Loại Hàng',
          'Nhóm Hàng',
          'Đơn vị Lẻ',
          'Giá bán lẻ cũ',
          'Giá bán lẻ mới',
          'Người thay đổi',
          'Ngày thay đổi',
        ],
        ...rows,
      ])
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Thay đổi giá bán')

      // Xuất ra file Excel
      XLSX.writeFile(wb, 'Thay đổi giá bán.xlsx')
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_DS_THUOC_VAT_TU_GIA_BAN,
        true,
        MENU_CONSTANTS.GIA_BAN,
        null,
        {
          dateFrom: dateFrom,
          dateTo,
          keyword: search,
          nameCompany,
          nameLoaiThuocVT,
          nameNhomThuocVT,
          page: pageIndex,
        },
      )
    } catch (error) {
      console.log(error)
      const detailErrorPayload = `Có lỗi xảy ra khi xuất file excel giá bán: ${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.GIA_BAN,
        detailErrorPayload,
      )
    }
  }
  const handleShowHistory = async (record) => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemCt, record, actionIsTrue, actionIsFalse))
  }
  useEffect(() => {
    const allDates = Array.from(
      new Set(
        Object.values(listThuocVT?.data || [])?.flatMap((item) =>
          item?.giabanthuoc ? Object.keys(item.giabanthuoc) : [],
        ),
      ),
    ).sort()
    const formattedData = listThuocVT?.data?.map((thuoc, index) => {
      const giaBanTheoNgay = thuoc.giabanthuoc
      // Đảm bảo tất cả các ngày đều có mặt trong dữ liệu
      const row = {
        key: index,
        STT: ++index,
        tenBietDuoc: thuoc.tenBietDuoc,
        maThuoc: thuoc.maThuoc,
        tenphanloai: thuoc.tenphanloai,
        nhom: thuoc.nhom,
        quyCach: thuoc.quyCach,
        giaMua: thuoc.giaMua,
        giagpp: thuoc.giagpp,
        dvt: thuoc.dvt,
        giaBan: thuoc.giaBan,
        giabanthuoc: thuoc.giabanthuoc,
        ...allDates.reduce((acc, date) => {
          // Extract OldPrice, NewPrice and PersonChanged for each date
          const priceDetails = giaBanTheoNgay[date] || {}
          acc[date] = {
            OldPrice: priceDetails.OldPrice || '',
            NewPrice: priceDetails.NewPrice || '',
            PersonChanged: priceDetails.PersonChanged || '',
          }
          return acc
        }, {}),
      }

      return row
    })
    const dynamicColumns = [
      {
        title: 'STT',
        dataIndex: 'STT',
        key: 'STT',
        width: 40,
        align: 'center',
        fixed: 'left',
      },
      {
        title: 'Mã thuốc',
        dataIndex: 'maThuoc',
        key: 'maThuoc',
        width: 80,
        fixed: 'left',
        ...getColumnSearchProps('maThuoc'),
      },
      {
        title: 'Tên thuốc',
        dataIndex: 'tenBietDuoc',
        key: 'tenBietDuoc',
        width: 300,
        fixed: 'left',
        ...getColumnSearchProps('tenBietDuoc'),
      },
      {
        title: 'Loại hàng',
        dataIndex: 'tenphanloai',
        key: 'tenphanloai',
        width: 180,
        ...getColumnSearchProps('tenphanloai'),
      },
      {
        title: 'Nhóm hàng',
        dataIndex: 'nhom',
        key: 'nhom',
        width: 220,
        ...getColumnSearchProps('nhom'),
      },
      {
        title: 'Quy cách',
        dataIndex: 'quyCach',
        key: 'quyCach',
        width: 110,
        align: 'right',
        ...getColumnSearchProps('quyCach'),
      },
      {
        title: 'Giá bán',
        dataIndex: 'giaBan',
        key: 'giaBan',
        width: 90,
        align: 'right',
        render: (text) => formatNumberVND(text),
      },
      ...allDates.map((date) => ({
        title: moment(date).format('DD/MM/YYYY'),
        dataIndex: date,
        key: date,
        width: 90,
        align: 'center',
        children: [
          {
            title: 'Giá cũ',
            dataIndex: `${date}_OldPrice`,
            key: `${date}_OldPrice`,
            render: (value, row) =>
              row[date].OldPrice !== '' ? formatNumberVND(row[date].OldPrice) : '',
            width: 80,
            align: 'center',
          },
          {
            title: 'Giá mới',
            dataIndex: `${date}_NewPrice`,
            key: `${date}_NewPrice`,
            render: (value, row) =>
              row[date].NewPrice !== '' ? formatNumberVND(row[date].NewPrice) : '',
            width: 80,
            align: 'center',
          },
          {
            title: 'Người thay đổi',
            dataIndex: `${date}_PersonChanged`,
            key: `${date}_PersonChanged`,
            render: (value, row) => (row[date] ? row[date].PersonChanged : ''),
            width: 200,
          },
        ],
        render: (value) => (value !== '' ? formatNumberVND(value) : ''), // Hiển thị giá trị hoặc thông báo nếu không có
      })),
    ]
    setDataSource(formattedData)
    setColumns(dynamicColumns)
    setValueExport(listThuocVT?.data)
  }, [listThuocVT?.data])

  const nameCompany = useMemo(() => {
    return listCompany?.find((item) => item.idct === company)?.tenct
  }, [company, listCompany])

  const nameLoaiThuocVT = useMemo(() => {
    return !types ? 'Tất cả' : typesVT?.find((item) => item.idPhanLoai === types)?.phanLoai
  }, [types, typesVT])

  const nameNhomThuocVT = useMemo(() => {
    return !valueGroups ? 'Tất cả' : groupsVT?.find((item) => item.idNhom === valueGroups)?.nhom
  }, [valueGroups, groupsVT])

  return (
    <>
      <div className=" p-2 h-full bg-[#efefef]">
        <div className="h-full bg-white rounded-xl border">
          <div className="p-2 pt-1">
            <div className="flex items-center justify-between">
              <div className="flex gap-2 items-center">
                <div className="w-60 flex gap-1">
                  <Select
                    showSearch
                    className="w-full"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={handleCompany}
                    value={company}
                    options={listCompany?.map((items) => ({
                      label: items.tenct,
                      value: items.idct,
                    }))}
                  />
                </div>
                <div className="w-[13rem] flex gap-1">
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    value={types}
                    onChange={handleTypesThuocVT}
                    placeholder="loại Thuốc vật tư"
                    className="w-full"
                    options={[
                      { label: 'Tất cả loại hàng', value: null },
                      ...(typesVT?.length
                        ? typesVT
                            ?.map((items) => ({
                              label: items?.phanLoai,
                              value: items?.idPhanLoai,
                            }))
                            ?.sort((a, b) =>
                              `${a?.label ?? ''}`
                                .toLowerCase()
                                .localeCompare(`${b?.label ?? ''}`.toLowerCase()),
                            )
                        : []),
                    ]}
                  />
                </div>
                <div className="font-semibold w-[17rem] flex gap-1">
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={handleGroupsThuocVT}
                    value={valueGroups}
                    options={[
                      { label: 'Tất cả nhóm hàng', value: null },
                      ...(groupsVT?.length && types
                        ? groupsVT
                            ?.map((items) => ({
                              label: items?.nhom,
                              value: items?.idNhom,
                            }))
                            ?.sort((a, b) =>
                              `${a?.label ?? ''}`
                                .toLowerCase()
                                .localeCompare(`${b?.label ?? ''}`.toLowerCase()),
                            )
                        : []),
                    ]}
                    placeholder="Nhóm thuốc vật tư"
                    className="w-full truncate"
                  />
                </div>
                <div>
                  <Input
                    value={search}
                    placeholder="Tìm kiếm..."
                    onChange={handleSearchInput}
                    prefix={<SearchOutlined />}
                  />
                </div>
                <div className="flex justify-between items-center gap-1">
                  <DatePicker
                    allowClear={false}
                    placeholder=" "
                    className="w-[7.5rem]"
                    defaultValue={dayjs(moment())}
                    maxDate={dayjs(moment())}
                    onChange={(date, dateString) =>
                      setDateFrom(moment(dateString, dateFormat).format(momentDate))
                    }
                    format={dateFormat}
                    locale={{ ...locale, week: { start: 1 } }}
                  />
                  <span className="font-semibold text-2xl">-</span>
                  <DatePicker
                    allowClear={false}
                    placeholder=" "
                    className="w-[7.5rem]"
                    defaultValue={dayjs(moment())}
                    maxDate={dayjs(moment())}
                    onChange={(date, dateString) =>
                      setDateTo(moment(dateString, dateFormat).format(momentDate))
                    }
                    format={dateFormat}
                    locale={{ ...locale, week: { start: 1 } }}
                  />
                </div>
                <IconButton
                  onClick={getListThuocVT}
                  color="primary"
                  aria-label="add to shopping cart"
                >
                  <CachedIcon />
                </IconButton>
                <Button
                  onClick={exportToExcel}
                  type="text"
                  size="middle"
                  className="text-green-700 p-1"
                  icon={<ImportExportIcon />}
                >
                  Xuất Excel
                </Button>
              </div>
            </div>
            <div className="mt-1">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 3,
                    fontSize: 13,
                  },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                      headerBg: '#e6e6e6',
                      footerBg: '#e6e6e6',
                      borderColor: '#BABABA',
                    },
                  },
                }}
              >
                <Table
                  onChange={onChangeTable}
                  className="w-full table-thumua"
                  pagination={false}
                  scroll={{
                    x: 1000,
                    y: '82vh',
                  }}
                  onRow={(record) => ({
                    onClick: () => handleShowHistory(record),
                  })}
                  bordered
                  columns={columns}
                  dataSource={dataSource}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GiaBan
