import React, { useState } from 'react'
import FromCreateTemplate from './FormCreateTemplate/FromCreateTemplate'
import { List } from 'antd'
import InfoTemplate from './InfoTemplate/InfoTemplate'
const TemplateZalo = () => {
  const [activeIndex, setActiveIndex] = useState(null)
  const item = [
    {
      idtp: 1,
      matp: 'NHIDONG1',
      idtaikhoan: 1,
      templatE_CODAU: null,
      templatE_KHONGDAU: 'Ma OTP NHIDONG315 cua ban la .{otp}',
      noidungmau: 'Ma OTP NHIDONG315 cua ban la 6730',
      tiengvietcodau: null,
      ghichu: null,
      sudung: 1,
      chuyenkhoa: 'NHI',
      idloaisms: null,
    },
    {
      idtp: 2,
      matp: 'NHIDONG1',
      idtaikhoan: 1,
      templatE_CODAU: null,
      templatE_KHONGDAU: 'Ma OTP NHIDONG315 cua ban la .{otp}',
      noidungmau: 'Ma OTP NHIDONG315 cua ban la 6730',
      tiengvietcodau: null,
      ghichu: null,
      sudung: 1,
      chuyenkhoa: 'NHI',
      idloaisms: null,
    },
    {
      idtp: 3,
      matp: 'NHIDONG1',
      idtaikhoan: 1,
      templatE_CODAU: null,
      templatE_KHONGDAU: 'Ma OTP NHIDONG315 cua ban la .{otp}',
      noidungmau: 'Ma OTP NHIDONG315 cua ban la 6730',
      tiengvietcodau: null,
      ghichu: null,
      sudung: 1,
      chuyenkhoa: 'NHI',
      idloaisms: null,
    },
    {
      idtp: 4,
      matp: 'NHIDONG1',
      idtaikhoan: 1,
      templatE_CODAU: null,
      templatE_KHONGDAU: 'Ma OTP NHIDONG315 cua ban la .{otp}',
      noidungmau: 'Ma OTP NHIDONG315 cua ban la 6730',
      tiengvietcodau: null,
      ghichu: null,
      sudung: 1,
      chuyenkhoa: 'NHI',
      idloaisms: null,
    },
    {
      idtp: 5,
      matp: 'NHIDONG1',
      idtaikhoan: 1,
      templatE_CODAU: null,
      templatE_KHONGDAU: 'Ma OTP NHIDONG315 cua ban la .{otp}',
      noidungmau: 'Ma OTP NHIDONG315 cua ban la 6730',
      tiengvietcodau: null,
      ghichu: null,
      sudung: 1,
      chuyenkhoa: 'NHI',
      idloaisms: null,
    },
    {
      idtp: 6,
      matp: 'NHIDONG1',
      idtaikhoan: 1,
      templatE_CODAU: null,
      templatE_KHONGDAU: 'Ma OTP NHIDONG315 cua ban la .{otp}',
      noidungmau: 'Ma OTP NHIDONG315 cua ban la 6730',
      tiengvietcodau: null,
      ghichu: null,
      sudung: 1,
      chuyenkhoa: 'NHI',
      idloaisms: null,
    },
    {
      idtp: 7,
      matp: 'NHIDONG1',
      idtaikhoan: 1,
      templatE_CODAU: null,
      templatE_KHONGDAU: 'Ma OTP NHIDONG315 cua ban la .{otp}',
      noidungmau: 'Ma OTP NHIDONG315 cua ban la 6730',
      tiengvietcodau: null,
      ghichu: null,
      sudung: 1,
      chuyenkhoa: 'NHI',
      idloaisms: null,
    },
    {
      idtp: 8,
      matp: 'NHIDONG1',
      idtaikhoan: 1,
      templatE_CODAU: null,
      templatE_KHONGDAU: 'Ma OTP NHIDONG315 cua ban la .{otp}',
      noidungmau: 'Ma OTP NHIDONG315 cua ban la 6730',
      tiengvietcodau: null,
      ghichu: null,
      sudung: 1,
      chuyenkhoa: 'NHI',
      idloaisms: null,
    },
    {
      idtp: 9,
      matp: 'NHIDONG1',
      idtaikhoan: 1,
      templatE_CODAU: null,
      templatE_KHONGDAU: 'Ma OTP NHIDONG315 cua ban la .{otp}',
      noidungmau: 'Ma OTP NHIDONG315 cua ban la 6730',
      tiengvietcodau: null,
      ghichu: null,
      sudung: 1,
      chuyenkhoa: 'NHI',
      idloaisms: null,
    },
    {
      idtp: 10,
      matp: 'NHIDONG1',
      idtaikhoan: 1,
      templatE_CODAU: null,
      templatE_KHONGDAU: 'Ma OTP NHIDONG315 cua ban la .{otp}',
      noidungmau: 'Ma OTP NHIDONG315 cua ban la 6730',
      tiengvietcodau: null,
      ghichu: null,
      sudung: 1,
      chuyenkhoa: 'NHI',
      idloaisms: null,
    },
    {
      idtp: 11,
      matp: 'NHIDONG1',
      idtaikhoan: 1,
      templatE_CODAU: null,
      templatE_KHONGDAU: 'Ma OTP NHIDONG315 cua ban la .{otp}',
      noidungmau: 'Ma OTP NHIDONG315 cua ban la 6730',
      tiengvietcodau: null,
      ghichu: null,
      sudung: 1,
      chuyenkhoa: 'NHI',
      idloaisms: null,
    },
    {
      idtp: 12,
      matp: 'NHIDONG1',
      idtaikhoan: 1,
      templatE_CODAU: null,
      templatE_KHONGDAU: 'Ma OTP NHIDONG315 cua ban la .{otp}',
      noidungmau: 'Ma OTP NHIDONG315 cua ban la 6730',
      tiengvietcodau: null,
      ghichu: null,
      sudung: 1,
      chuyenkhoa: 'NHI',
      idloaisms: null,
    },
  ]
  return (
    <div className="p-2 bg-[#EFEFEF] h-full">
      <div className="p-2 border rounded-lg h-full bg-white">
        <FromCreateTemplate />
        <div className="border rounded-lg mt-2 xl:h-[485px] h-[590px] flex">
          <div className="xl:w-1/3 w-1/2 border-r h-full p-2  overflow-auto">
            <List
              className="demo-loadmore-list"
              itemLayout="horizontal"
              dataSource={item}
              renderItem={(item) => (
                <List.Item
                  onClick={() => setActiveIndex(item.idtp)}
                  className={`${item.idtp === activeIndex && 'bg-[#e6f7ff] border-l-4 border-[#1890ff] border-solid'} cursor-pointer `}
                  actions={[
                    <a key="list-loadmore-more " className="text-red-500">
                      Xoá
                    </a>,
                  ]}
                >
                  <List.Item.Meta
                    title={<a href="https://ant.design">{item.templatE_KHONGDAU}</a>}
                    description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                  />
                </List.Item>
              )}
            />
          </div>
          <div className='p-5 xl:w-2/3 w-1/2'>
            <InfoTemplate/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateZalo
