import React, { useCallback, useEffect, useState } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import {
  Button,
  ConfigProvider,
  Input,
  Pagination,
  Table,
  Tooltip,
  Modal,
  Select,
  DatePicker,
} from 'antd'
import { PlusOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons'
import * as typeAction from '../../../store/constants/constants'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { customerByKeyword, getCustomerByIdct } from '../../../store/actions/customerAction'
import { dangKyKhamOnlineService } from '../../../services/dangKyKhamOnline/dangKyKhamOnlineService'
import { recieveService } from '../../../services/receive/recieveService'
import moment from 'moment'
import dayjs from 'dayjs'
import CreateCustomer from '../../common/CreateCustomers/CreateCustomer'
import ImportKhachHang from './ImportKhachHang/ImportKhachHang'
import { https } from '../../../services/apiService'
import { useSearchParams } from 'react-router-dom'
import ToastCus from '../../../utils/Toast'
import { putKhachHangDTAction } from '../../../store/actions/khachHangDTAction'
import 'moment/locale/vi'
import { addLog } from '../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../utils/log/logConstant'
moment.locale('vi')

const Customer = () => {
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const idct = +localStorage.getItem('id_company')
  const dispatch = useDispatch()
  const now = moment()
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isloading, setIsLoading] = useState(false)
  const [allCustomer, setAllCustomer] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const [search, setSearch] = useState('')
  const [typeCustomer, setTypeCustomer] = useState(2)
  const [isModalImport, setIsModalImport] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [listDoiTac, setListDoiTac] = useState([])
  const [listDoiTuong, setListDoiTuong] = useState([])
  const [listNgheNghiep, setListNgheNghiep] = useState([])
  const [listTinhTp, setListTinhTp] = useState([])
  const [listQuanHuyen, setListQuanHuyen] = useState([])
  const [listPhuongXa, setListPhuongXa] = useState([])
  const [doiTac, setDoiTac] = useState(0)
  const [rowActive, setRowActive] = useState(null)
  const [form, setForm] = useState(null)
  const [isLoadingEdit, setIsLoadingEdit] = useState(false)
  const resetForm = () => {
    setForm(null)
    setIsModal(false)
    getAllCustomer('', null, 1)
    setRowActive(null)
  }
  const handleSubmit = () => {
    if (!form || !form?.tenKH) {
      setForm((prev) => ({
        ...prev,
        tenKH: prev?.tenKH ?? null,
      }))
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    // setIsModal(false)
    dispatch(putKhachHangDTAction({ ...form, nguoiSua: infoUser?.idnv }, resetForm))
  }
  /// -----STORE------//
  const debounceSearch = useCallback(
    _.debounce((keyword) => {
      setCurrentPage(1)
      SetSearchParams({ page: 1 })
      getAllCustomer(keyword, doiTac, 1)
    }, 350),
    [],
  )
  const handleSearch = (e) => {
    const { value } = e.target
    setSearch(value)
    debounceSearch(value)
  }
  const onClickCreateCustomer = () => {
    if (typeCustomer === 1) {
      dispatch({
        type: typeAction.OPEN_MODAL_CREATE_CUSTOMER,
      })
    } else if (typeCustomer === 2) setIsModalImport(true)
  }

  const getAllCustomer = async (keyword, idDoiTac, pageIndex) => {
    setIsLoading(true)
    const nameDoiTac = listDoiTac.find((item) => item.idDoiTac === idDoiTac)?.tenDoiTac
    const detailForLog = {
      keyword: keyword ?? null,
      nameDoiTac: idDoiTac === null ? 'Tất cả đối tác' : nameDoiTac?.tenDoiTac,
      page: pageIndex,
    }

    try {
      const { data } = await dangKyKhamOnlineService.getListKhachHang(
        keyword?.length > 0 ? keyword?.trim() : null,
        idDoiTac ? idDoiTac : null,
        pageIndex,
      )
      setSearch(keyword?.length > 0 ? keyword?.trim() : null)
      setAllCustomer(data.data)
      setTotalPage(data.totalPage)

      // Add success log
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap?.idChiNhanh,
        actionStatus.GET,
        logAction.GET_DS_KHACH_HANG,
        true,
        MENU_CONSTANTS.KHACH_HANG,
        null,
        detailForLog,
      )
    } catch (error) {
      console.log(error)

      // Add error log
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_DS_KHACH_HANG,
        false,
        MENU_CONSTANTS.KHACH_HANG,
        detailForLog,
      )
    } finally {
      setIsLoading(false)
    }
  }
  const handleChangePage = (search, doitac, page) => {
    setCurrentPage(page)
    SetSearchParams({ page })
    getAllCustomer(search, doitac, page)
  }
  const getListQuanHuyenPhuongXa = async (idtinh, idquan) => {
    try {
      setIsLoadingEdit(true)
      const { data: arrquanhuyen } = await recieveService.getQuanHuyen(idtinh ?? 0)
      const { data: arrphuongxa } = await recieveService.getPhuongXa(idquan ?? 0)
      setListQuanHuyen(idtinh ? arrquanhuyen : [])
      setListPhuongXa(idquan ? arrphuongxa : [])
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingEdit(false)
    }
  }
  const getCacListDanhMuc = async () => {
    try {
      const { data: arrdoitac } = await dangKyKhamOnlineService.getListDoiTac('')
      const { data: arrdoituong } = await recieveService.getDoiTuong()
      const { data: arrnghenghiep } = await recieveService.getNgheNghiep()
      const { data: arrtinhtp } = await recieveService.getTinhTP()
      setListDoiTac(arrdoitac)
      setListDoiTuong(arrdoituong)
      setListNgheNghiep(arrnghenghiep)
      setListTinhTp(arrtinhtp)
      // console.log(listTinhTp)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getCacListDanhMuc()
    getAllCustomer('', null, 1)
  }, [])
  return (
    <>
      <Modal
        width={700}
        open={isModal}
        onCancel={() => setIsModal(false)}
        okText={'Lưu'}
        onOk={handleSubmit}
        cancelText="Huỷ bỏ"
        title={<p className="text-center">Chỉnh sửa khách hàng</p>}
      >
        <div className="grid grid-flow-row gap-1 pb-3 items-center">
          <div className="grid grid-cols-3 gap-3 items-center">
            <div>
              <label className="font-semibold">
                Tên khách hàng<span className="text-red-500">&nbsp;(*)</span>
              </label>
              <Input
                size="small"
                className="w-full"
                status={form && !form?.tenKH && 'error'}
                value={form?.tenKH}
                onChange={(e) => setForm((prev) => ({ ...prev, tenKH: e.target.value }))}
              />
            </div>
            <div>
              <label className="font-semibold">
                Mã khách hàng<span className="text-red-500">&nbsp;(*)</span>
              </label>
              <Input size="small" className="w-full" value={form?.maKH} variant="filled" readOnly />
            </div>
            <div>
              <label className="font-semibold">
                Đối tác<span className="text-red-500">&nbsp;(*)</span>
              </label>
              <Input
                size="small"
                className="w-full"
                value={form?.tenDoiTac}
                variant="filled"
                readOnly
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3 items-center">
            <div>
              <label className="font-semibold">Ngày sinh</label>
              <DatePicker
                size="small"
                className="w-full"
                allowClear={false}
                onChange={(date, dateString) =>
                  setForm((prev) => ({
                    ...prev,
                    ngaySinh: moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                  }))
                }
                value={form?.ngaySinh ? dayjs(form?.ngaySinh, 'YYYY-MM-DD') : ''}
                maxDate={dayjs(now)}
                format={'DD/MM/YYYY'}
              />
            </div>
            <div>
              <label className="font-semibold">Giới tính</label>
              <Select
                size="small"
                className="w-full"
                value={
                  (form?.gioiTinh?.includes('Nam') && 1) ||
                  (form?.gioiTinh?.includes('Nữ') && 2) ||
                  (form?.gioiTinh?.includes('Khác') && 3) ||
                  form?.gioiTinh
                }
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value, opt) => {
                  setForm((prev) => ({ ...prev, gioiTinh: opt?.label }))
                }}
                options={[
                  {
                    key: 'gioitinh1',
                    value: 1,
                    label: 'Nam',
                  },
                  {
                    key: 'gioitinh2',
                    value: 2,
                    label: 'Nữ',
                  },
                  {
                    key: 'gioitinh3',
                    value: 3,
                    label: 'Khác',
                  },
                ]}
              />
            </div>
            <div>
              <label className="font-semibold">SĐT</label>
              <Input
                size="small"
                className="w-full"
                value={form?.dienThoai}
                onChange={(e) => setForm((prev) => ({ ...prev, dienThoai: e.target.value }))}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3 items-center">
            <div>
              <label className="font-semibold">Đối tượng</label>
              <Select
                size="small"
                className="w-full"
                value={form?.idDoiTuong}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value) => {
                  setForm((prev) => ({ ...prev, idDoiTuong: value }))
                }}
                options={listDoiTuong?.map((item) => ({
                  key: `doituong${item?.idDoiTuong}`,
                  value: item?.idDoiTuong,
                  label: item?.tenDoiTuong,
                }))}
              />
            </div>
            <div>
              <label className="font-semibold">Nghề nghiệp</label>
              <Select
                size="small"
                className="w-full"
                value={form?.idNgheNghiep}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value) => {
                  setForm((prev) => ({ ...prev, idNgheNghiep: value }))
                }}
                options={listNgheNghiep?.map((item) => ({
                  key: `nghenghiep${item?.idNN}`,
                  value: item?.idNN,
                  label: item?.tenNN,
                }))}
              />
            </div>
            <div>
              <label className="font-semibold">CCCD</label>
              <Input
                size="small"
                className="w-full"
                value={form?.cccd}
                onChange={(e) => setForm((prev) => ({ ...prev, cccd: e.target.value }))}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3 items-center">
            <div>
              <label className="font-semibold">Tỉnh / Tp</label>
              <Select
                disabled={isLoadingEdit}
                size="small"
                className="w-full"
                value={form?.idTinh}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={(value) => {
                  setForm((prev) => ({ ...prev, idTinh: value, idQuan: null, idPhuong: null }))
                  getListQuanHuyenPhuongXa(value, null)
                }}
                options={listTinhTp?.map((item) => ({
                  key: `tinhtp${item?.idTinh}`,
                  value: item?.idTinh,
                  label: item?.tenTinh,
                }))}
              />
            </div>
            <div>
              <label className="font-semibold">Quận / Huyện</label>
              <Select
                disabled={isLoadingEdit}
                size="small"
                className="w-full"
                value={form?.idQuan}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={(value) => {
                  setForm((prev) => ({ ...prev, idQuan: value, idPhuong: null }))
                  getListQuanHuyenPhuongXa(form?.idTinh, value)
                }}
                options={listQuanHuyen?.map((item) => ({
                  key: `tinhtp${item?.idQuan}`,
                  value: item?.idQuan,
                  label: item?.tenQuan,
                }))}
              />
            </div>
            <div>
              <label className="font-semibold">Phường / Xã</label>
              <Select
                disabled={isLoadingEdit}
                size="small"
                className="w-full"
                value={form?.idPhuong}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={(value) => {
                  setForm((prev) => ({ ...prev, idPhuong: value }))
                }}
                options={listPhuongXa?.map((item) => ({
                  key: `tinhtp${item?.idPhuong}`,
                  value: item?.idPhuong,
                  label: item?.tenPhuong,
                }))}
              />
            </div>
          </div>
          <div>
            <label className="font-semibold">Địa chỉ</label>
            <Input
              size="small"
              className="w-full"
              value={form?.diaChi}
              onChange={(e) => setForm((prev) => ({ ...prev, diaChi: e.target.value }))}
            />
          </div>
          <div>
            <label className="font-semibold">Ghi chú</label>
            <Input
              size="small"
              className="w-full"
              value={form?.ghiChu}
              onChange={(e) => setForm((prev) => ({ ...prev, ghiChu: e.target.value }))}
            />
          </div>
        </div>
      </Modal>
      <div className="p-3 h-full bg-[#EFEFEF]">
        <div className="h-full p-2 bg-white border rounded-xl">
          <>
            <div className="w-[40rem] flex gap-2">
              {/* <Select
                disabled={isloading}
                value={typeCustomer}
                options={[
                  {
                    key: 1,
                    value: 1,
                    label: 'Khách hàng là bệnh nhân',
                  },
                  {
                    key: 2,
                    value: 2,
                    label: 'Khách hàng của đối tác',
                  },
                ]}
                onChange={setTypeCustomer}
                className="w-60"
              /> */}
              <Select
                disabled={isloading}
                placeholder="Chọn đối tác..."
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                // filterSort={(optionA, optionB) =>
                //   (optionA?.label ?? '')
                //     .toLowerCase()
                //     .localeCompare((optionB?.label ?? '').toLowerCase())
                // }
                options={[
                  {
                    key: `doitacTatCa0`,
                    value: 0,
                    label: 'Tất cả đối tác',
                  },
                  ...listDoiTac?.map((item) => ({
                    key: `doitac${item?.maDoiTac}${item?.idDoiTac}`,
                    value: item?.idDoiTac,
                    label: item?.tenDoiTac,
                  })),
                ]}
                value={doiTac}
                onChange={(value) => {
                  setDoiTac(value)
                  handleChangePage(search, value, +useSearch.get('page'))
                }}
                className="w-60"
              />
              <Input.Search
                disabled={isloading}
                value={search}
                onChange={handleSearch}
                placeholder={
                  'Tìm Mã KH, Tên KH, SĐT...'
                  // (typeCustomer === 1 && 'Tìm kiếm Mã BN, Tên BN...') ||
                  // (typeCustomer === 2 && 'Tìm kiếm Mã KH, Tên KH...')
                }
                className="w-60"
              />
              <Tooltip title="Tạo hồ sơ">
                <Button
                  onClick={onClickCreateCustomer}
                  icon={<PlusOutlined />}
                  type="primary"
                  ghost
                  shape="circle"
                ></Button>
              </Tooltip>
            </div>
            <div className="mt-2">
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                  },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                      headerBg: '#e6e6e6',
                      footerBg: '#e6e6e6',
                      borderColor: '#BABABA',
                    },
                  },
                }}
              >
                <Table
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        setRowActive(record)
                      }, // click row
                    }
                  }}
                  loading={isloading}
                  bordered
                  scroll={{ x: 2500 }}
                  pagination={false}
                  dataSource={allCustomer}
                  // dataSource={allCustomer.map((item, index) => ({
                  //   ...item,
                  //   stt: (currentPage - 1) * 20 + (index + 1),
                  // }))}
                  columns={[
                    {
                      title: 'STT',
                      dataIndex: 'stt',
                      key: 'stt',
                      width: 50,
                      align: 'center',
                      fixed: 'left',
                      render: (text, record, index) => (currentPage - 1) * 20 + (index + 1),
                    },
                    {
                      title: 'Mã KH',
                      dataIndex: 'maKH',
                      key: 'maKH',
                      width: 120,
                      align: 'center',
                      fixed: 'left',
                    },
                    {
                      title: 'Tên KH',
                      dataIndex: 'tenKH',
                      key: 'tenKH',
                      fixed: 'left',
                    },
                    {
                      title: 'Đối tác',
                      dataIndex: 'tenDoiTac',
                      key: 'tenDoiTac',
                      width: 250,
                    },
                    {
                      title: 'Ngày sinh',
                      dataIndex: 'ngaySinh',
                      key: 'ngaySinh',
                      width: 90,
                      align: 'center',
                      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
                    },
                    {
                      title: 'Giới tính',
                      dataIndex: 'gioiTinh',
                      key: 'gioiTinh',
                      width: 75,
                      align: 'center',
                    },
                    {
                      title: 'SĐT',
                      dataIndex: 'dienThoai',
                      key: 'dienThoai',
                      width: 100,
                      align: 'center',
                      render: (text, record) =>
                        record?.idKH === rowActive?.idKH ? <p>{text}</p> : '',
                    },
                    {
                      title: 'CCCD',
                      dataIndex: 'cccd',
                      key: 'cccd',
                      width: 120,
                      align: 'center',
                    },
                    { title: 'Địa chỉ', dataIndex: 'diaChi', key: 'diaChi', width: 380 },
                    {
                      title: 'Người tạo',
                      dataIndex: 'tenNguoiTao',
                      key: 'tenNguoiTao',
                      width: 220,
                    },
                    {
                      title: 'Ngày tạo',
                      dataIndex: 'ngayTao',
                      key: 'ngayTao',
                      align: 'center',
                      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
                      width: 140,
                    },
                    {
                      title: 'Người sửa',
                      dataIndex: 'tenNguoiSua',
                      key: 'tenNguoiSua',
                      width: 220,
                    },
                    {
                      title: 'Ngày sửa',
                      dataIndex: 'ngaySua',
                      key: 'ngaySua',
                      align: 'center',
                      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
                      width: 140,
                    },
                    { title: 'Ghi chú', dataIndex: 'diaChi', key: 'diaChi', width: 250 },
                    {
                      title: '',
                      dataIndex: 'action',
                      key: 'action',
                      align: 'center',
                      fixed: 'right',
                      width: 50,
                      render: (text, record) => (
                        <Tooltip title="Chỉnh sửa" color="green">
                          <EditOutlined
                            className="text-xl text-green-500  cursor-pointer"
                            onClick={() => {
                              setIsModal(true)
                              setForm(record)
                              getListQuanHuyenPhuongXa(record?.idTinh, record?.idQuan)
                            }}
                          />
                        </Tooltip>
                      ),
                    },
                  ]}
                />
              </ConfigProvider>
              <div className="flex justify-end">
                <Pagination
                  className="mt-5"
                  showSizeChanger={false}
                  current={+useSearch.get('page')}
                  simple
                  defaultCurrent={1}
                  pageSize={20}
                  total={20 * totalPage}
                  onChange={(page, pagesize) => {
                    handleChangePage(search, doiTac, page)
                  }}
                />
              </div>
            </div>
          </>
        </div>
      </div>
      {/* <CreateCustomer /> */}
      <ImportKhachHang
        isModalImport={isModalImport}
        setIsModalImport={setIsModalImport}
        listDoiTac={listDoiTac}
      />
    </>
  )
}

export default Customer
