import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  SaveOutlined,
  CloseOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import CachedIcon from '@mui/icons-material/Cached'
import {
  Typography,
  ConfigProvider,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Table,
  Tooltip,
  Button,
} from 'antd'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import ShowNotification from '../../../common/ShowNotification/ShowNotification'
import {
  ListDichVuAction,
  listGroupsDichVuAction,
} from '../../../../store/actions/dichVuAction'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { searchThuocVT } from '../../../../store/actions/NhapKhoAction'
import { https } from '../../../../services/apiService'
import { addVTTHchanDoan, deleteVTHHByCDHA, getVTHHByCDHA, searchDanhMucCĐHA_Action, updateVTHHByCDHA } from '../../../../store/actions/CĐHAAction'
const { confirm } = Modal
const ChanDoanHA = () => {
  const dispatch = useDispatch()
  const { ListCĐHA,VTTHChanDoan } = useSelector((state) => state.CĐHAReducer)
  const [searchText, setSearchText] = useState('')
  const [searchAll, setSearchAll] = useState('')
  const [allChuyenKhoa, setAllChuyenKhoa] = useState([])
  const [chuyenKhoa, setChuyenKhoa] = useState('')
  const searchInput = useRef(null)
  const [searchedColumn, setSearchedColumn] = useState('')
  const { contextHolder, openNotificationWithIcon } = ShowNotification()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { thuocVT } = useSelector((state) => state.NhapKhoReducer)
  const [loadingTable, setLoadingTable] = useState(false)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [searchGroup, setSearchGroup] = useState(1)
  const [rowActive, setRowActive] = useState(null)
  const [allNhomCDHA, setAllNhomCDHA] = useState([])
  const [loadingCN, setLoadingCN] = useState(false)
   const [nhomCDHA, setNhomCDHA] = useState('')
  const [data, setData] = useState(VTTHChanDoan)
  const [editingKey, setEditingKey] = useState('')
  const [editTable, setEditTable] = useState(false)
  const isEditing = (record) => record.key === editingKey
  const edit = (record) => {
    setEditTable(true)
    setEditingKey(record.key)
  }
   const handleReloadData = () => {
    dispatch(
      searchDanhMucCĐHA_Action(
        searchAll?.length ? searchAll?.trim() : searchAll,
        company,
        nhomCDHA,
        chuyenKhoa,
      ),
    )
    }


  // handle delete danh mục xét nghiệm
  const showDeleteConfirm = (item) => {
    confirm({
      title: 'Xoá hàng vật tư tiêu hao ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <p>
          Bạn có chắc muốn xóa hàng này không:{' '}
          <span className="font-semibold">
            {item.mathuoc} - {item.tenbietduoc}
          </span>{' '}
        </p>
      ),
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ bỏ',
      onOk() {
        dispatch(deleteVTHHByCDHA(item))
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  }
  useEffect(() => {
    dispatch(ListDichVuAction(searchGroup, company))
    dispatch(listGroupsDichVuAction())
    dispatch(listAllCompanyAction())
  }, [])

  const handleCompany = (value) => {
    setRowActive(null)
    setCompany(value)
    dispatch(ListDichVuAction(searchGroup, value))
  }

  const debounceDropDown = useCallback(
    _.debounce((keyword, company) => {
      dispatch(searchThuocVT(keyword, company))
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const handleSearchVT = (keyword) => {
    debounceDropDown(keyword, company)
  }
  const handleNhomCDHA = (value) => {
    setNhomCDHA(value)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const columns = useMemo(
    () => [
      {
        title: 'STT',
        dataIndex: 'STT',
        width: 80,
        align: 'center',
      },
      {
        title: 'Mã CĐHA',
        dataIndex: 'macdha',
        width: 150,
        align: 'left',
      },
      {
        title: 'Tên chẩn đoán hình ảnh',
        dataIndex: 'tenChiDinh',
        ...getColumnSearchProps('tenCDHA'),
      },
      {
        title: 'Đơn giá',
        dataIndex: 'donGia',
        width: 100,
        align: 'right',
        render: (text) => formatNumberVND(text),
      },
      {
        title: 'Đơn vị tính',
        dataIndex: 'dvt',
        width: 120,
        align: 'center',
      },
    ],
    [],
  )
  const save = (key) => {
    const index = data.findIndex((item) => key === item.idcdhavtth)
    dispatch(updateVTHHByCDHA(data[index]))
  }
  const onChange = (value) => {
    setSearchGroup(value)
    // setIdChuyenKhoa(groupsDichVu.find((item) => item.idNhom === value).idChuyenKhoa)s
    dispatch(ListDichVuAction(value, company))
  }
  const reloadVTTHByCDHA = (id) => {
    dispatch(getVTHHByCDHA(id))
  }
  const handleClickRow = (row) => {
    setRowActive(row)
    reloadVTTHByCDHA(row.idChiDinh)
  }
  const handleSelected = (value) => {
    const form = {
      idcdha: rowActive?.idChiDinh,
      idthuoc: value,
      nguoitao: infoUser?.idnv,
      soluong: 1,
    }
    if (!rowActive)
      return openNotificationWithIcon('warning', 'Thêm vật tư tiêu hao', 'Vui lòng chọn loại chẩn đoán')
    dispatch(addVTTHchanDoan(form))
  }
  const onChangeInput = (key, name, value) => {
    const index = data.findIndex((item) => key === item.idcdhavtth)
    if (index !== -1) {
      const item = { ...data[index], [name]: Number(value) }
      const newData = [...data]
      newData[index] = item
      setData(newData)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('ChanDoanHinhAnhNhom/GetAllNhomChanDoanHinhAnh')
        setAllNhomCDHA(data)
      } catch (error) {
        console.log(error)
      }
    })()
    handleReloadData()
  }, [])
  const columnsCN = [
    {
      title: 'STT',
      dataIndex: 'stt',
      render: (text, _, index) => ++index,
      width: 60,
      key: 1,
    },
    {
      title: 'Mã hàng',
      dataIndex: 'mathuoc',
      key: 2,
      width: 100,
    },
    {
      title: 'Tên Hàng',
      dataIndex: 'tenbietduoc',
      key: 3,
    },
    {
      title: 'SL',
      dataIndex: 'soluong',
      key: 4,
      width: 80,
      align: 'center',
      render: (text, record, index) => (
        <>
          <InputNumber
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(value) => onChangeInput(record.key, 'soluong', value)}
            value={text}
            min={0}
            formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            size="small"
            className="w-16"
          />
        </>
      ),
    },
    {
      title: 'Đơn vị',
      dataIndex: 'dvt',
      key: 5,
      width: 80,
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      width: 90,
      key: 6,
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span className="flex justify-around">
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              <Tooltip title="Lưu" color="blue">
                <SaveOutlined style={{ fontSize: 18 }} />
              </Tooltip>
            </Typography.Link>
            <Typography.Link
              onClick={() => {
                setEditingKey('')
                setEditTable(false)
                setData(VTTHChanDoan)
              }}
            >
              <Tooltip title="Huỷ">
                <CloseOutlined />
              </Tooltip>
            </Typography.Link>
          </span>
        ) : (
          <ul className="flex justify-around">
            <li>
              <Tooltip title="Chỉnh sửa" color="green">
                <EditOutlined
                  onClick={() => {
                    if (editingKey !== '') return
                    edit(record)
                  }}
                  className={`text-xl ${editingKey !== '' ? 'text-gray-500' : 'text-green-500'}  cursor-pointer`}
                />
              </Tooltip>
            </li>
            <li>
              <Tooltip title="Xoá" color="red">
                <DeleteOutlined
                  onClick={(e) => {
                    if (editingKey !== '') return
                    e.stopPropagation()
                    showDeleteConfirm(record)
                  }}
                  className={`text-xl ${editingKey !== '' ? 'text-gray-500' : 'text-red-500'}  cursor-pointer`}
                />
              </Tooltip>
            </li>
          </ul>
        )
      },
    },
  ]

  useEffect(() => {
    setData(VTTHChanDoan)
    setEditingKey('')
  }, [VTTHChanDoan])
  const handleSearchAll = useCallback(
    debounce((keyword) => {
      setSearchAll(keyword)
    }, 300),
    [],
  )
  return (
    <>
      <div className="flex w-full items-center gap-5  bg-white rounded-md">
        <div className="flex gap-3">
          <Select
            showSearch
            className="w-72"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={handleCompany}
            value={company}
            options={[
              ...(listCompany
                ? listCompany.map((items) => ({
                    label: items.tenct,
                    value: items.idct,
                  }))
                : []),
            ]}
          />
          {/* Chuyên khoa */}
          <Select
            showSearch
            className="w-60"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            // filterSort={(optionA, optionB) =>
            //   (optionA?.label ?? '')
            //     .toLowerCase()
            //     .localeCompare((optionB?.label ?? '').toLowerCase())
            // }
            onChange={onChange}
            value={chuyenKhoa}
            options={[
              {
                label: 'Tất cả chuyên khoa',
                value: '',
              },
              ...allChuyenKhoa.map((items) => ({
                label: items.tenChuyenKhoa,
                value: items.idChuyenKhoa,
              })),
            ]}
          />
          <Select
            showSearch
            className="w-60"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            // filterSort={(optionA, optionB) =>
            //   (optionA?.label ?? '')
            //     .toLowerCase()
            //     .localeCompare((optionB?.label ?? '').toLowerCase())
            // }
            onChange={handleNhomCDHA}
            value={nhomCDHA}
            options={[
              {
                label: 'Tất cả các nhóm',
                value: '',
              },
              ...allNhomCDHA
                ?.map((items) => ({
                  label: items.tennhom,
                  value: items.idnhomcdha,
                }))
                ?.sort((a, b) => (a?.label ?? '').localeCompare(b?.label ?? '')),
            ]}
          />
          <Input.Search
            // value={searchAll}
            allowClear
            className="w-60"
            placeholder="Nhập từ khoá tìm kiếm"
            onChange={(e) => {
              handleSearchAll(e.target.value)
            }}
          />
          <Button
            className="flex items-center"
            type="primary"
            onClick={handleReloadData}
            ghost
            icon={<CachedIcon />}
          >
            Tải lại
          </Button>
        </div>
      </div>
      <div className="flex pr-2 h-full border mt-2 rounded">
        <div className="w-1/2 h-full bg-white  border-r">
          <div className="p-2 h-full">
            <div>
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                    fontSize: 14,
                  },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                      headerBg: '#e6e6e6',
                      footerBg: '#e6e6e6',
                      borderColor: '#BABABA',
                    },
                  },
                }}
              >
                <Table
                  loading={loadingTable}
                  pagination={false}
                  bordered
                  scroll={{
                    x: 900,
                    y: '72vh',
                  }}
                  onRow={(row) => {
                    return {
                      onClick: () => {
                        handleClickRow(row)
                      }, // click row
                    }
                  }}
                  columns={columns}
                  dataSource={ListCĐHA?.map((item, index) => ({
                    STT: ++index,
                    ...item,
                  }))}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
        <div className="w-1/2 h-full  p-2">
          <div className="flex items-center gap-2 mb-1">
            <span className="font-semibold w-24">Tên dịch vụ:</span>
            <Input value={rowActive?.tenChiDinh} readOnly variant="filled" />
          </div>
          <Select
            className="w-full mb-1"
            // size="small"
            showSearch
            allowClear
            onChange={handleSelected}
            placeholder="Nhập tên vật tư hàng hoá "
            value={null}
            defaultActiveFirstOption={false}
            prefix={null}
            filterOption={false}
            onSearch={handleSearchVT}
            notFoundContent={null}
            options={(thuocVT || []).map((d, index) => ({
              key: index,
              value: d.idThuoc,
              label: (
                <u className="flex no-underline">
                  <li className="flex w-[20%]">
                    <p>{d.maThuoc}</p>
                  </li>
                  <li className="flex w-[80%]">
                    <p className=" w-full ">{d.tenBietDuoc}</p>
                  </li>
                </u>
              ),
            }))}
          />
          <div>
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                rowKey="key"
                loading={loadingCN}
                scroll={{ y: 700 }}
                // loading={isLoadingBranch}
                pagination={false}
                columns={columnsCN}
                dataSource={data?.map((item) => ({
                  ...item,
                  key: item.idcdhavtth,
                }))}
                bordered
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
      {contextHolder}
    </>
  )
}

export default ChanDoanHA
