import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'

import ToastCus from '../../utils/Toast'
import { goikhamCRMService } from '../../services/goikhamCRM/goikhamCRMService'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'

export function* goikhamCRMSaga() {
  yield takeLatest(typeAction.GET_ALL_GOIKHAM_CRM, function* getAllGoikhamCRM({ type, payload }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => goikhamCRMService.getAllGoikhamCRM())
      result.data.sort((a, b) => new Date(a.ngaytao) - new Date(b.ngaytao))
      yield put({
        type: typeAction.DISPATCH_ALL_GOIKHAM_CRM,
        payload: result.data,
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_DS_GOI_KHAM,
        true,
        MENU_CONSTANTS.GOI_KHAM,
        null,
        {},
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.GOI_KHAM,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //add
  yield takeLatest(typeAction.ADD_GOIKHAM_CRM, function* addGoikhamCRM({ type, form, onLoad }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)

    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => goikhamCRMService.addGoikhamCRM(form))
      console.log(form)

      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm mới thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.ADD_GOI_KHAM,
        true,
        MENU_CONSTANTS.GOI_KHAM,
        null,
        {
          tengoikham: form.goiKham.tengoikham,
          tungay: form.goiKham.khamtungay,
          denngay: form.goiKham.khamdenngay,
          ghichu: form.goiKham.ghichu,
          listdv: form.dichVuKhams.map((item) => `${item.maDichVu} - ${item.tenDichVu}`).join('\n'),
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thêm mới thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.GOI_KHAM,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //edit
  yield takeLatest(typeAction.EDIT_GOIKHAM_CRM, function* editGoikhamCRM({ type, form, onLoad }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)

    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => goikhamCRMService.editGoikhamCRM(form))
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Sửa thông tin thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.EDIT_GOI_KHAM,
        true,
        MENU_CONSTANTS.GOI_KHAM,
        null,
        {
          tengoikham: form.goiKham.tengoikham,
          tungay: form.goiKham.khamtungay,
          denngay: form.goiKham.khamdenngay,
          ghichu: form.goiKham.ghichu,
          listdv: form.dichVuKhams.map((item) => `${item.maDichVu} - ${item.tenDichVu}`).join('\n'),
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Sửa thông tin thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.GOI_KHAM,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  yield takeLatest(typeAction.GET_GOIKHAM_CRM_BY_ID, function* getById({ type, id }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)

    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => goikhamCRMService.getGoikhamCRMById(id))
      const resultListDV = yield call(() => goikhamCRMService.getListDVByIdGK(id))

      yield put({
        type: typeAction.DISPATCH_INFO_GOIKHAM_CRM,
        payload: { ...result.data.result, listDV: [...resultListDV.data.result] },
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_DETAIL_GOI_KHAM,
        true,
        MENU_CONSTANTS.GOI_KHAM,
        null,
        {
          tengoikham: result.data.result.tengoikham,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.GOI_KHAM,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  yield takeLatest(typeAction.GET_GOIKHAM_CRM_BY_ID_KH, function* getByIdKH({ type, id }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => goikhamCRMService.getListGoiKhamByIdKH(id))
      result.data.result.sort((a, b) =>
        b.crmGoiKham.tengoikham.localeCompare(a.crmGoiKham.tengoikham),
      )
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_DS_GOI_KHAM_KHACH_HANG,
        true,
        MENU_CONSTANTS.GOI_KHAM_KHACH_HANG,
        null,
        {
          id,
        },
      )
      yield put({
        type: typeAction.DISPATCH_ALL_GOIKHAM_KHACHHANG_CRM,
        payload: result.data.result,
      })
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.GOI_KHAM_KHACH_HANG,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}
