import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { detailCardService } from '../../services/card/detailCardService'
import { khoVTservice } from '../../services/khoVT/khoVTservice'
import { cardService } from '../../services/card/cardService'
import { dichVuService } from '../../services/dichVu/dichVuService'
import { recieveService } from '../../services/receive/recieveService'
import Swal from 'sweetalert2'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import moment from 'moment'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* detailCardSaga() {
  yield takeLatest(typeAction.GET_LIST_THE_DICH_VU, function* getListDichVuThe({ type, idlt }) {
    try {
      const info = yield call(() => detailCardService.getInfoLoaiThe(idlt))
      const listDV = yield call(() => detailCardService.getListTheDichVuByIdLT(idlt))
      const listCK = yield call(() => khoVTservice.getAllChuyenKhoa())
      const GroupCard = yield call(() => cardService.listGroupCard())
      yield put({
        type: typeAction.DISPATCH_INFO_LOAITHE,
        payload: info.data,
      })
      yield put({
        type: typeAction.DISPATCH_LIST_THE_DICH_VU,
        payload: listDV.data,
      })
      yield put({
        type: typeAction.DISPATCH_CHUYEN_KHOA_CARD,
        payload: listCK.data,
      })
      yield put({
        type: typeAction.DISPATCH_LIST_GROUP_CARD,
        payload: GroupCard.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  yield takeLatest(
    typeAction.GET_LIST_DICH_VU_LOAITHE,
    function* getListDichVuTheSaga({ type, idnhom, idct }) {
      try {
        const list = yield call(() => dichVuService.getListDichVu(idnhom, idct)) // detailCardService.getDichVuByIdNhomDV(idnhom))
        yield put({
          type: typeAction.DISPATCH_LIST_DICH_VU_LOAITHE,
          payload: list.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  yield takeLatest(
    typeAction.GET_LIST_XET_NGHIEM_LOAITHE, //////////////////
    function* getListXNGiaTongSaga({ type, idct }) {
      try {
        const list = yield call(() => detailCardService.getXetNghiemByCTy(idct))
        yield put({
          type: typeAction.DISPATCH_LIST_XET_NGHIEM_LOAITHE,
          payload: list.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  yield takeLatest(
    typeAction.GET_LIST_CDHA_LOAITHE, //////////
    function* getListCDHATiepNhanSaga({ type, idct }) {
      try {
        const list = yield call(() => recieveService.getListCDHATiepNhan(idct)) // detailCardService.getDichVuByIdNhomDV(idnhom))
        yield put({
          type: typeAction.DISPATCH_LIST_CDHA_LOAITHE,
          payload: list.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  yield takeLatest(
    typeAction.POST_THE_DICH_VU,
    function* addListDichVuThe({ type, listDichVuThe, idlt }) {
      const detailForLog = {
        tenDichVu: listDichVuThe.map((item) => item.tenDichVu).join(', '),
        soLanSuDung: listDichVuThe.map((item) => item.soLanSuDung).join(', '),
        ptGiamGia: listDichVuThe.map((item) => item.ptGiamGia).join(', '),
      }

      try {
        const { infoUser } = yield select((state) => state.userReducer)
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        // const result = yield call(() => detailCardService.postListTheDichVu(listDichVuThe))
        const newList = yield call(() => detailCardService.putListTheDichVu(listDichVuThe, idlt))
        // const oldList = yield call(() => detailCardService.postListTheDichVu(listDichVuThe))
        // const list = yield call(() => detailCardService.getListTheDichVuByIdLT(idlt))
        // yield put({
        //     type: typeAction.DISPATCH_LIST_THE_DICH_VU,
        //     payload: list.data,
        // })
        Toast.fire({
          icon: 'success',
          title: 'Lưu dịch vụ thẻ thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.SUA_THE_DICH_VU,
          true,
          MENU_CONSTANTS.THE_THANH_VIEN,
          null,
          detailForLog,
        )
      } catch (err) {
        console.log(err)
        const { infoUser } = yield select((state) => state.userReducer)
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        addLog(
          infoUser,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.THE_THANH_VIEN,
          detailForLog,
        )
        Toast.fire({
          icon: 'error',
          title: 'Lưu dịch vụ thẻ thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  yield takeLatest(typeAction.PUT_INFO_LOAITHE, function* putInfoLoaiTheSaga({ type, idlt, form }) {
    const detailCardString = `Sửa thông tin loại thẻ:
    - Tên loại thẻ : ${form.loaithe}
    - Tiền sử dụng : ${form.tiensudung}
    - idchuyenkhoa : ${form.idchuyenkhoa}
    - idnhom : ${form.idnhom}
    - Số tháng sử dụng : ${form.sothang}
`

    try {
      const result = yield call(() => detailCardService.putInfoLoaiThe(idlt, form))
      const info = yield call(() => detailCardService.getInfoLoaiThe(idlt))
      yield put({
        type: typeAction.DISPATCH_INFO_LOAITHE,
        payload: info.data,
      })
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      yield call(() =>
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.TAO_THE_DICH_VU,
          true,
          MENU_CONSTANTS.THE_THANH_VIEN,
          null,
          detailCardString,
        ),
      )
      // const newList = yield call(() => detailCardService.putListTheDichVu(listDichVuThe, idlt))
      // const oldList = yield call(() => detailCardService.postListTheDichVu(listDichVuThe))
      // const list = yield call(() => detailCardService.getListTheDichVuByIdLT(idlt))
      // yield put({
      //     type: typeAction.DISPATCH_LIST_THE_DICH_VU,
      //     payload: list.data,
      // })
      Toast.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (err) {
      console.log(err)
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      yield call(() =>
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.THE_THANH_VIEN,
          detailCardString,
        ),
      )

      Toast.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    }
  })
}
