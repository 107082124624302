import React, { useEffect, useState } from 'react'
import { Button, ConfigProvider, Table, Input, Modal, Form, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Flag from 'react-world-flags'
import { https } from '../../../../services/apiService'
import {
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
import { formatPayloadDetails } from '../../../../utils/algorithm'

const { confirm } = Modal

const countryCodeMap = {
  'Việt Nam': 'VN',
  Anh: 'GB',
  Brunei: 'BN',
  Campuchia: 'KH',
  Canada: 'CA',
  'Cu ba': 'CU',
  'Đài Loan': 'TW',
  Đức: 'DE',
  'Hà Lan': 'NL',
  'Hàn Quốc': 'KR',
  'Hoa Kỳ (USA)': 'US',
  Nga: 'RU',
  Nhật: 'JP',
  Pháp: 'FR',
  'Trung Quốc': 'CN',
  Úc: 'AU',
  'Thuỵ Sĩ': 'CH',
  Bỉ: 'BE',
  Singapore: 'SG',
  'Thái Lan': 'TH',
  Indonesia: 'ID',
  Lào: 'LA',
  Malaysia: 'MY',
  'Ấn Độ': 'IN',
  Israel: 'IL',
  'Tây Ban Nha': 'ES',
  'Nam Phi': 'ZA',
  Myanmar: 'MM',
  Philippines: 'PH',
  Mỹ: 'US',
  'New Zealand': 'NZ',
  'Đông ti mo ': 'TL',
  Khác: 'UN', // UN - trường hợp không xác định
}

const QuocGia = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [newCountry, setNewCountry] = useState({ tenQuocTich: '' })
  const { infoUser } = useSelector((state) => state.userReducer)
  const maQuyenXemDs = 'QHT443'
  const maQuyenThem = 'QHT444'
  const maQuyenXoa = 'QHT445'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        fetchData()
        break
      case maQuyenThem:
        setIsModalVisible(true)
        break
      case maQuyenXoa:
        showDeleteConfirm(infoNew)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenThem:
        setIsModalVisible(false)
        break
      case maQuyenXoa:
        //
        break
      default:
        return false
    }
  }
  const fetchData = async () => {
    try {
      const response = await https.get('/QuocTich')
      setData(response?.data)
      setFilteredData(
        response?.data?.filter((item) => item?.tenQuocTich?.toLowerCase().includes(searchText)),
      )
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_DS_QUOC_GIA,
        true,
        MENU_CONSTANTS.DIA_CHI,
        null,
        {},
      )
    } catch (error) {
      console.error('Error fetching data:', error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DIA_CHI,
        detailErrorPayload,
      )
    }
  }
  const onLoad = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  useEffect(() => {
    if (infoUser) {
      onLoad()
    }
  }, [infoUser])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchText(value)
    const filtered = data.filter((item) => item.tenQuocTich?.toLowerCase().includes(value))
    setFilteredData(filtered)
  }

  const handleAddCountry = async () => {
    try {
      const response = await https.post('/QuocTich/CreateQuocTich', newCountry)
      onLoad()
      // Refresh data after adding
      // const newResponse = await https.get('/QuocTich')
      // setData(newResponse.data)
      // setFilteredData(newResponse.data)
      setIsModalVisible(false)
      setNewCountry({ tenQuocTich: '' })
      message.success('Thêm quốc gia thành công')
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        logAction.THEM_MOI_QUOC_GIA,
        true,
        MENU_CONSTANTS.DIA_CHI,
        null,
        {
          ten: newCountry.tenQuocTich,
        },
      )
    } catch (error) {
      console.error('Error adding country:', error)
      message.error('Thêm quốc gia thất bại')
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(newCountry)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.DIA_CHI,
        detailErrorPayload,
      )
    }
  }

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Bạn có chắc chắn muốn xóa quốc gia này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk() {
        handleDeleteCountry(record)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const handleDeleteCountry = async (record) => {
    try {
      await https.delete(`/QuocTich/DeleteQuocTich?idQuocTich=${record.idQuocTich}`)
      onLoad()
      // const updatedData = data.filter((item) => item.idQuocTich !== idQuocTich)
      // setData(updatedData)
      // setFilteredData(updatedData)
      message.success('Đã xóa thành công')
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.DELETE,
        logAction.XOA_QUOC_GIA,
        true,
        MENU_CONSTANTS.DIA_CHI,
        null,
        {
          ten: record.tenQuocTich,
        },
      )
    } catch (error) {
      console.error('Error deleting country:', error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.DELETE,
        null,
        false,
        MENU_CONSTANTS.DIA_CHI,
        detailErrorPayload,
      )
    }
  }
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // or whatever default page size you want
  })
  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination)
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      render: (text, record, index) => {
        return (
          // Calculate STT based on pagination
          (pagination.current - 1) * pagination.pageSize + index + 1
        )
      },
    },
    // {
    //   title: 'Cờ',
    //   dataIndex: 'tenQuocTich',
    //   key: 'flag',
    //   render: (name) => {
    //     const countryCode = countryCodeMap[name] || 'UN' // 'UN' cho trường hợp quốc gia không xác định
    //     return <Flag code={countryCode} style={{ width: '30px', height: '20px' }} />
    //   },
    //   width: 350,
    // },
    {
      title: 'Tên Quốc Tịch',
      dataIndex: 'tenQuocTich',
      key: 'tenQuocTich',
      sorter: (a, b) => (a.tenQuocTich || '').localeCompare(b.tenQuocTich || ''),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Hành động',
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          {/* <Button type="primary" size="small" icon={<EditOutlined />}>
            Sửa
          </Button> */}
          <Button
            type="primary"
            danger
            size="small"
            icon={<DeleteOutlined />}
            onClick={() =>
              dispatch(putCheckQuyenThaoTacAction(maQuyenXoa, record, actionIsTrue, actionIsFalse))
            }
          >
            Xóa
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="flex justify-between mb-4">
        <Input
          placeholder="Tìm kiếm quốc tịch"
          value={searchText}
          onChange={handleSearch}
          style={{ width: 250 }}
          prefix={<SearchOutlined />}
        />
        <Button
          onClick={onLoad}
          className="ml-4 mr-auto"
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() =>
            dispatch(putCheckQuyenThaoTacAction(maQuyenThem, null, actionIsTrue, actionIsFalse))
          }
        >
          Thêm quốc gia
        </Button>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="idQuocTich"
          pagination={pagination}
          onChange={handleTableChange}
        />
      </ConfigProvider>

      <Modal
        title="Thêm quốc gia"
        visible={isModalVisible}
        onOk={handleAddCountry}
        onCancel={() => setIsModalVisible(false)}
        okText="Lưu"
        cancelText="Hủy"
      >
        <Form layout="vertical">
          <Form.Item label="Tên Quốc Tịch">
            <Input
              value={newCountry.tenQuocTich}
              onChange={(e) => setNewCountry({ ...newCountry, tenQuocTich: e.target.value })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default QuocGia
