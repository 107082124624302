import { Input, Divider, Radio, DatePicker } from 'antd'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import 'moment/locale/vi'
import dayjs from 'dayjs'
moment.locale('vi')
const today = moment()
const InfoPhieu = ({ formik, isStatus, setIsStatus }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoBNSieuAm, history } = useSelector((state) => state.sieuAmReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  return (
    <div>
      <Divider orientation={'left'} plain style={{ marginTop: 15, marginBottom: 3, fontSize: 15 }}>
        <p className="font-semibold text-blue-500">Thông tin phiếu siêu âm</p>
      </Divider>
      <div className="flex flex-col gap-1 px-2 pb-2">
        <div className="flex">
          <label className="w-24 font-semibold">Ngày SA:</label>
          <Input
            value={
              infoBNSieuAm
                ? infoBNSieuAm?.ngaysieuam
                  ? moment(infoBNSieuAm?.ngaysieuam).format('DD/MM/YYYY HH:mm:ss')
                  : today.format('DD/MM/YYYY HH:mm:ss')
                : ''
            }
            size="small"
            variant="filled"
            readOnly
          />
        </div>
        <div className="flex">
          <label className="w-24 font-semibold text-[13.5px]">BS Chỉ định:</label>
          <Input
            size="small"
            value={formik.values.BSCHIDINH?.replace('null', '')}
            variant="filled"
            readOnly
          />
        </div>
        <div className="flex">
          <label className="w-24 font-semibold">BS Siêu âm:</label>
          <Input
            value={
              infoBNSieuAm ? infoBNSieuAm?.bssieuam ?? infoUser?.tenNV : ''
              // infoBNSieuAm ? (history?.length > 0 ? history[0]?.bssieuam : infoUser?.tenNV) : ''
            }
            size="small"
            variant="filled"
            readOnly
          />
        </div>
        <div className="flex">
          <label className="w-24 font-semibold">CĐ sơ bộ:</label>
          <Input
            size="small"
            value={formik.values.CHANDOANSOBO?.replace('null', '')}
            variant="filled"
            readOnly
          />
        </div>
        <div className="flex">
          <label className="w-24 font-semibold">Loại SA:</label>
          <Input size="small" value={formik.values.LOAISIEUAM} variant="filled" readOnly />
        </div>
        <div className="flex">
          <label className="w-24 font-semibold text-[13.5px]">Lý do khám:</label>
          <Input size="small" variant="filled" readOnly value={infoBNSieuAm?.lydokham} />
        </div>
        {PkDangNhap?.idChuyenKhoa === 2 && (
          <div className="flex">
            <label className="w-24 font-semibold">Ghi chú:</label>
            <Input size="small" variant="filled" readOnly value={infoBNSieuAm?.ghichu} />
          </div>
        )}
        <div className="flex">
          <label className="w-24 font-semibold">Para:</label>
          <Input
            // status={formik.errors.para ? 'error' : ''}
            onChange={formik.handleChange}
            value={formik.values.PARA}
            name="PARA"
            // type="Number"
            size="small"
          />
        </div>
        <div className="flex">
          <label className="w-24 font-semibold">Tình trạng:</label>
          <Radio.Group value={isStatus} onChange={(e) => setIsStatus(e.target.value)}>
            <Radio value={true}>Có thai</Radio>
            <Radio value={false}>Không có thai</Radio>
          </Radio.Group>
        </div>
        <div className="flex">
          <label className="w-24 font-semibold">
            Dự sanh:{isStatus && <span className="text-red-500">*</span>}
          </label>
          <DatePicker
            disabled={!isStatus}
            className="w-full"
            format={'DD/MM/YYYY'}
            onChange={(date, dateString) => {
              formik.setFieldValue('dusanh', dateString ? moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '')
            }}
            value={isStatus && (formik.values.dusanh ? dayjs(formik.values.dusanh) : '')}
            name="dusanh"
            size="small"
          />
        </div>
        <div className="flex">
          <label className="w-24 font-semibold">
            {' '}
            Lúc thai:{isStatus && <span className="text-red-500">*</span>}
          </label>
          <div className="flex gap-2">
            <Input
              disabled={!isStatus}
              type="Number"
              // status={formik.errors.para ? 'error' : ''}
              onChange={formik.handleChange}
              value={isStatus && formik.values.tuanthai}
              name="tuanthai"
              // type="Number"
              size="small"
            />
            <label htmlFor="">Tuần</label>
            <Input
              disabled={!isStatus}
              type="Number"
              // status={formik.errors.para ? 'error' : ''}
              onChange={formik.handleChange}
              value={isStatus && formik.values.ngaythai}
              name="ngaythai"
              // type="Number"
              size="small"
            />
            <label htmlFor="">Ngày</label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoPhieu
