import { Modal, Input, Select, Checkbox, message } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { nhanvienService } from '../../../../services/nhanvien/nhanvienService'
import { branchService } from '../../../../services/branch/branchService'
import { updateUser } from '../../../../store/actions/userAction'
import { https } from '../../../../services/apiService'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
import { addLog } from '../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../utils/log/logConstant'
moment.locale('vi')

const EditUser = ({ branch, setBranch, cancelEdit, isEdit, info, typeUser }) => {
  // console.log("Info in EditUser:", info); // Kiểm tra dữ liệu được truyền vào từ User.js
  const dispatch = useDispatch()
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { listCompany } = useSelector((state) => state.companyReducer)
  const id_company = Number(localStorage.getItem('id_company'))
  const [staff, setStaff] = useState([])
  const [role, setRole] = useState()
  const [tempIsLock, setTempIsLock] = useState(info?.isLock || 0)
  const [listNhomQuyen, setlistNhomQuyen] = useState([])

  const handleSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  //------ lấy dánh sách nhân viên ------------//
  const fetcchStaffs = async () => {
    try {
      const result = await nhanvienService.getStaffs()
      setStaff(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  //--------- lấy danh sách nhóm  ----------//
  const getListNhomQuyen = async () => {
    try {
      const { data } = await phanQuyenService.getListNhomQuyen(null) //keyword
      setlistNhomQuyen(data?.filter((item) => item?.sudung))
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.XEM_NHOM_QUYEN_HE_THONG,
        true,
        MENU_CONSTANTS.NGUOI_DUNG,
        null,
        {
          keyword: '',
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.NGUOI_DUNG,
        detailErrorPayload,
      )
    }
  }
  //--------- lấy danh  sach vai trò  ------------//
  const fetchRule = async () => {
    try {
      const result = await nhanvienService.getRulerUser()
      setRole(result.data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_ALL_NHOM_NGUOI_DUNG,
        true,
        MENU_CONSTANTS.NGUOI_DUNG,
        null,
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.NGUOI_DUNG,
        detailErrorPayload,
      )
    }
  }
  //---------- lấy danh sách chi nhánh theo công ty -----------//
  const fetchBranch = async (id) => {
    try {
      const result = await branchService.getListBranch(id)
      setBranch(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async (value) => {
    const now = moment()
    value.nguoiSua = infoUser?.idnv
    value.ngaySua = now.format('YYYY-MM-DDTHH:mm:ss')
    value.isLock = tempIsLock // Cập nhật lại isLock từ tempIsLock
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT007',
      )
      const payload = [{ username: value.taiKhoan, islock: value.isLock }]

      const result = await https.put('NguoiDung/IsLock-User', payload)
      if (result.status === 200) {
        //add log for is lock user
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.UPDATE_LOCK_NGUOI_DUNG,
          true,
          MENU_CONSTANTS.NGUOI_DUNG,
          null,
          { username: value.taiKhoan, islock: value.isLock },
        )
        dispatch(updateUser(value, restStore))
        info.isLock = value.isLock // Cập nhật lại trạng thái isLock của info
      } else {
        message.error('Failed to update status')
        const detailErrorPayload = `Params: \n \t${JSON.stringify({ username: value.taiKhoan, islock: value.isLock })}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.NGUOI_DUNG,
          detailErrorPayload,
        )
      }
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  const restStore = () => {
    cancelEdit()
    formik.resetForm()
    // onReload()
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetcchStaffs()
    fetchRule()
    getListNhomQuyen()
  }, [])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idNguoiDung: info?.idNguoiDung || '',
      taiKhoan: info?.taiKhoan || '',
      // matKhau: null,
      // passwordConfirmation: null,
      idnv: info?.nhanVien.id || '',
      idChiNhanh: info?.idChiNhanh || '',
      // nguoiTao: user?.taiKhoan,
      // ngayTao: '',
      idNhom: info?.nhomNguoiDung.idNhom || '',
      IDCT: id_company, // set trạng thái mặc đình là 1 >> CTy y tế chấn văn
      isLock: info?.isLock,
      ipmac: info?.ipmac || '',
      tenNguoiSua: info?.tenNguoiSua || '',
      idndloai: info?.idndloai,
      idnqht: info?.idnqht ?? 0,
    },
    initialErrors: {
      taiKhoan: '',
      ipmac: '',
    },
    validate: (values) => {
      const errors = {}
      if (!values.taiKhoan) {
        errors.taiKhoan = 'Tài khoản không được để trống !'
      }
      if (!values.idChiNhanh) {
        errors.idChiNhanh = 'Vui lòng chọn chi nhánh !'
      }
      return errors
    },
    onSubmit: (value) => handleSubmit(value),
  })

  // Cập nhật tempIsLock khi info thay đổi
  useEffect(() => {
    setTempIsLock(info?.isLock || 0)
  }, [info])
  // Xử lý khi thay đổi trạng thái Api của checkbox "Hiệu lực"
  const handleLockChange = (e) => {
    const isLock = e.target.checked ? 1 : 0 // Đảo ngược giá trị isLock khi checkbox thay đổi
    setTempIsLock(isLock) // Cập nhật tempIsLock
    formik.setFieldValue('isLock', isLock) // Cập nhật formik.values.isLock
  }

  return (
    <Modal
      open={isEdit}
      onCancel={restStore}
      onOk={formik.handleSubmit}
      okText={'Cập nhật'}
      cancelText="Hủy"
      title="Cập nhật người dùng"
    >
      <div className="mt-8">
        <form className="flex flex-col gap-2">
          <div>
            <label className="font-semibold">
              <span className="text-red-500">(*)</span> Tài khoản:
            </label>
            <Input
              name="taiKhoan"
              // readOnly
              // variant="filled"
              status={formik.errors.taiKhoan ? 'error' : ''}
              value={formik.values.taiKhoan}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.taiKhoan && <div className="text-red-500">{formik.errors.taiKhoan}</div>}
          </div>
          <div>
            <label className="font-semibold">Công ty:</label>
            <Select
              showSearch
              value={formik.values.IDCT}
              onChange={(value) => {
                fetchBranch(value)
                formik.setFieldValue('idChiNhanh', null)
                formik.setFieldValue('IDCT', value)
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div>
            <label className="font-semibold">
              <span className="text-red-500">(*)</span> Chi nhánh:
            </label>
            <Select
              status={formik.errors.idChiNhanh ? 'error' : ''}
              value={formik.values.idChiNhanh}
              showSearch
              onChange={handleSelected('idChiNhanh')}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={branch?.map((items) => ({
                label: items.tenChiNhanh,
                value: items.idChiNhanh,
              }))}
              className="w-full"
            />
            {formik.errors.idChiNhanh && (
              <div className="text-red-500">{formik.errors.idChiNhanh}</div>
            )}
          </div>
          <div>
            <label className="font-semibold"> Vai trò:</label>
            <Select
              status={formik.errors.idNhom ? 'error' : ''}
              value={formik.values.idNhom}
              onChange={handleSelected('idNhom')}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={role?.map((items) => ({
                label: items.tenNhom,
                value: items.idNhom,
              }))}
              className="w-full"
            />
          </div>
          <div>
            <label className="font-semibold">Nhóm quyền:</label>
            <Select
              showSearch
              className="w-full"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              status={formik.errors.idnqht ? 'error' : ''}
              value={formik.values.idnqht}
              onChange={handleSelected('idnqht')}
              options={[
                {
                  key: 0,
                  value: 0,
                  label: 'Tùy chỉnh',
                },
                ...listNhomQuyen?.map((item) => ({
                  key: item?.idnqht,
                  value: item?.idnqht,
                  label: item?.tennhomquyen,
                })),
              ]}
            />
          </div>
          <div>
            <label className="font-semibold"> Loại:</label>
            <Select
              value={formik.values.idndloai}
              onChange={handleSelected('idndloai')}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={typeUser?.map((items) => ({
                label: items.tenLoai,
                value: items.idNguoiDungLoai,
              }))}
              className="w-full"
            />
          </div>
          <div className="flex items-center gap-4">
            <label className="font-semibold">Hiệu lực:</label>
            <Checkbox
              checked={formik.values.isLock === 1} // Đảo ngược giá trị để checkbox phản ánh đúng trạng thái
              onChange={handleLockChange}
              className="mr-2"
            >
              {tempIsLock === 1 ? 'Đang hoạt động' : 'Đã vô hiệu hóa'}
            </Checkbox>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default EditUser
