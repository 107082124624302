import { call, select, put, takeLatest } from 'redux-saga/effects'
import { xetNghiemService } from '../../services/xetNghiem/xetNghiemService'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import Swal from 'sweetalert2'
import { addLog } from '../../utils/log/apiLog'
import { useSelector } from 'react-redux'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
import { getVTHHByXetNghiem } from '../actions/xetNghiemAction'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* xetNghiemSaga() {
  yield takeLatest(
    typeAction.GET_LIST_DANH_MUC_XET_NGHIEM,
    function* listDanhMucXetNghiem({ type }) {
      try {
        const result = yield call(() => xetNghiemService.listDanhMucXetNghiem())
        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )

  yield takeLatest(
    typeAction.GET_LIST_DANH_MUC_XET_NGHIEM_THEO_CT,
    function* listDanhMucXetNghiem_CT({ type, idct }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const result = yield call(() => xetNghiemService.listDanhMucXetNghiem_CT(idct))
        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  yield takeLatest(
    typeAction.GET_LIST_DANH_MUC_XET_NGHIEM_THEO_CT_NHOMXN,
    function* listDanhMucXetNghiem_CT_NhomXn({ type, idnhomxn, idct }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const result = yield call(() =>
          xetNghiemService.listDanhMucXetNghiemTheoCT_NHOMXN(idnhomxn, idct),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // GET LIST GROUPS XET NGHIỆM
  yield takeLatest(typeAction.GET_LIST_GROUPS_XETNGHIEM, function* listGroupsXetNghiem({ type }) {
    try {
      const result = yield call(() => xetNghiemService.listGroupsXetNghiem())
      yield put({
        type: typeAction.DISPATCH_GROUPS_XETNGHIEM,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // SEARCH XET NGHIEM BY GROUPS KEYWORD
  yield takeLatest(
    typeAction.SEARCH_XET_NGHIEM_BY_GROUPS_KEYWORD,
    function* listXetNghiemByGroupsKeyword({ type, keyword, idnhomxn }) {
      try {
        const result = yield call(() => xetNghiemService.searchByGroupsKeyword(keyword, idnhomxn))
        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )

  yield takeLatest(
    typeAction.SEARCH_XET_NGHIEM_BY_GROUPS_AND_CTY_KEYWORD,
    function* listXetNghiemByGroupsKeyword({ type, keyword, idnhomxn, idct }) {
      try {
        const result = yield call(() =>
          xetNghiemService.searchByGroupsAndCtyKeyword(keyword, idnhomxn, idct),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )

  yield takeLatest(
    typeAction.SEARCH_XET_NGHIEM_BY_CTY_KEYWORD,
    function* listXetNghiemByGroupsKeyword({ type, keyword, idct }) {
      try {
        const result = yield call(() => xetNghiemService.searchByCtyKeyword(keyword, idct))
        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )

  // // SEARCH XET NGHIEM BY GROUPS
  // yield takeLatest(
  //   typeAction.SEARCH_XET_NGHIEM_BY_GRUOPS,
  //   function* listCetNghiemByGroups({ type, idGroups }) {
  //     try {
  //       const result = yield call(() => xetNghiemService.searchByGroups(idGroups))
  //       yield put({
  //         type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
  //         payload: result.data,
  //       })
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   },
  // )

  //CHI TIET XET NGHIEM
  yield takeLatest(typeAction.GET_INFO_XET_NGHIEM, function* infoXetNgiem({ type, idxn }) {
    try {
      const result = yield call(() => xetNghiemService.fetchInfoXetNghiem(idxn))
      yield put({
        type: typeAction.DISPATCH_INFO_XET_NGHIEM,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // ADD NEW DANH MỤC XÉT NGHIỆM
  yield takeLatest(
    typeAction.POST_NEW_DANH_MUC_XET_NGHIEM,
    function* createDMXetNghiem({ type, form, onLoad }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield call(() => xetNghiemService.postDMXetNghiem(form))
        yield onLoad()
        Toast.fire({
          icon: 'success',
          title: 'Tạo thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.THEM_MOI_DICH_VU_XET_NGHIEM,
          true,
          MENU_CONSTANTS.DANH_MUC_XN,
          null,
          {
            form,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Tạo thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.DANH_MUC_XN,
          detailErrorPayload,
        )
      }
    },
  )
  // DELETE DANH MỤC XÉT NGHIÊM
  yield takeLatest(
    typeAction.DELETE_DANH_MUC_XET_NGHIEM,
    function* deleteDMXetNghiem({ type, idxn, onLoad }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => xetNghiemService.deleteDMXetNghiem(idxn))
        yield onLoad()
        Toast.fire({
          icon: 'success',
          title: 'Xóa thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.XOA_DICH_VU_XET_NGHIEM,
          true,
          MENU_CONSTANTS.DANH_MUC_XN,
          null,
          {
            id: idxn,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Xóa thất bại!',
        })
      }
    },
  )
  // UPDATE DANH MỤC XÉT NGHIÊM
  yield takeLatest(
    typeAction.UPDATE_DANH_MUC_XET_NGHIEM,
    function* updateDMXetNghiem({ type, form, onLoad }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => xetNghiemService.putDMXetNghiem(form))
        yield onLoad()
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_SUA_DICH_VU_XET_NGHIEM,
          true,
          MENU_CONSTANTS.DANH_MUC_XN,
          null,
          {
            form,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.DANH_MUC_XN,
          detailErrorPayload,
        )
      }
    },
  )
  //---------- update bệnh nhân xét nghiêm -------------//
  yield takeLatest(
    typeAction.UPDATE_BN_XET_NGHIEM,
    function* updateBNXetNghiem({ type, form, onLoad }) {
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })

        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT313'),
        )

        yield call(() => xetNghiemService.updateBNXN(form))
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_XN,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_HISTORY_XET_NGHIEM,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        yield call(() => onLoad())
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        const detailForLog = {}
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CAP_NHAT_KET_QUA_XET_NGHIEM,
          true,
          MENU_CONSTANTS.XET_NGHIEM,
          null,
          detailForLog,
        )
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }

        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.XET_NGHIEM,
          detailErrorPayload,
        )
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      }
    },
  )
  //------------- LẤY TẤT CẢ THÔNG TINH CỦA BỆNH NHÂN XÉT NGHIỆM ----------
  yield takeLatest(
    typeAction.GET_INFO_BN_XET_NGHIEM,
    function* infoBNXetNghiem({ type, idbn, idbnttc, tenBenhNhan }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => xetNghiemService.getHistory(idbn))
        const infoDetail = yield call(() => xetNghiemService.getDetailXN(idbnttc))
        const listVtthBn = infoDetail?.data?.DanhSachVatTuTieuHao?.filter(
          (item) => item?.idXetNghiem,
        )

        const detailForLog = {
          tenBenhNhan: tenBenhNhan,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_LICH_SU_XET_NGHIEM,
          true,
          MENU_CONSTANTS.XET_NGHIEM,
          null,
          detailForLog,
        )
        yield put({
          type: typeAction.DISPATCH_HISTORY_XET_NGHIEM,
          payload: result.data,
        })
        // const { DanhSachXetNghiem, ...other } = infoDetail.data;
        // const newData = DanhSachXetNghiem.map(items => ({ ...items, }));
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_XN,
          payload: { ...infoDetail.data, DanhSachVatTuTieuHao: listVtthBn },
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(idbnttc)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.XET_NGHIEM,
          detailErrorPayload,
        )
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  //get by condition
  yield takeLatest(
    typeAction.GET_XET_NGHIEM_BY_CONDITION,
    function* getXNByCondition({ type, filter }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const condition = Object.values(filter).filter(Boolean).join('')
        const { data } = yield call(() => xetNghiemService.getXetNghiemByCondition(condition))
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_DINH_MUC_TIEU_HAO_XET_NGHIEM,
          true,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          null,
          {},
        )
        yield put({
          type: typeAction.DISPATCH_LIST_DANH_MUC_XET_NGHIEM,
          payload: data,
        })
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  //------------- ADD NEW VTTH BY XET NGHIEM--------
  yield takeLatest(typeAction.POST_XETNGHIEM_VTTH, function* postVTTHByXetNghiem({ type, form }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)

    try {
      yield call(() => xetNghiemService.postVTTH(form))
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm VTTH Thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.THEM_DINH_MUC_TIEU_HAO_XET_NGHIEM,
        true,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        null,
        {
          form,
        },
      )

      yield put(getVTHHByXetNghiem(form.idxn))
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: 'Thêm VTTH thất bại!',
      })
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        detailErrorPayload,
      )
    }
  })
  //------------- DELETE VTTH BY XET NGHIEM--------
  yield takeLatest(
    typeAction.DELETE_VTTH_BY_XETNGHIEM,
    function* DeleteVTTHByService({ type, form }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => xetNghiemService.deleteVTTHByXetNghiem(form.idxnvtth))
        ToastCus.fire({
          icon: 'success',
          title: 'Xoá VTTH Thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.XOA_DINH_MUC_TIEU_HAO_XET_NGHIEM,
          true,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          null,
          {
            id: form.idxnvtth,
          },
        )
        yield put(getVTHHByXetNghiem(form.idxn))
      } catch (error) {
        ToastCus.fire({
          icon: 'error',
          title: 'Xoá VTTH thất bại!',
        })
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          detailErrorPayload,
        )
      }
    },
  )
  //------------- UPDATE VTTH BY XET NGHIEM--------
  yield takeLatest(
    typeAction.UPDATE_VTTH_BY_XETNGHIEM,
    function* updateVTTHByXetNghiem({ type, form }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => xetNghiemService.putVTTHByXetNghiem(form))
        ToastCus.fire({
          icon: 'success',
          title: 'Cập VTTH Thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_SUA_DINH_MUC_TIEU_HAO_XET_NGHIEM,
          true,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          null,
          {
            form,
          },
        )
        yield put(getVTHHByXetNghiem(form.idxn))
      } catch (error) {
        ToastCus.fire({
          icon: 'error',
          title: 'Cập VTTH thất bại!',
        })
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          detailErrorPayload,
        )
      }
    },
  )
  //------------- GET VTTH BY XET NGHIEM--------
  yield takeLatest(typeAction.GET_VTTH_BY_ID_XETNGHIEM, function* getVTHHByXetNghiem({ type, id }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      const result = yield call(() => xetNghiemService.getVTTHByXetNghiem(id))
      console.log(result.data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_DETAIL_DINH_MUC_TIEU_HAO_XET_NGHIEM,
        true,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        null,
        {
          id,
        },
      )
      yield put({
        type: typeAction.DISPATCH_VTTH_XETNGHIEM,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        detailErrorPayload,
      )
    }
  })
}
