import React, { useEffect, useState, useRef } from 'react'
import { Tabs, Table, ConfigProvider, Input, Select, notification, Modal, Alert } from 'antd'
import { CloseOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import AttachmentIcon from '@mui/icons-material/Attachment'
import Button from '@mui/material/Button'
import * as typeAction from '../../../../store/constants/constants'
import { xetNghiemService } from '../../../../services/xetNghiem/xetNghiemService'
import { infoBNXetNghiem } from '../../../../store/actions/xetNghiemAction'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import UpdateThemFile from './UpdateThemFile'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const { confirm } = Modal
const TableChiDinh = ({
  keyTab,
  setkeyTab,
  khoVatTu,
  setKhoVatTu,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { listVT, listVTBN } = useSelector((state) => state.thuThuatReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { detailXN, history } = useSelector((state) => state.xetNghiemReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [listQuyenTab, setlistQuyenTab] = useState([])
  // const [keyTab, setkeyTab] = useState(1)
  const fileInputRef = useRef(null)
  const isChuaGuiMau =
    !detailXN?.DanhSachXetNghiem[0]?.trangthaikb || !detailXN?.DanhSachVatTuTieuHao ? true : false
  const isDaLuuKQVaQuaNgay =
    detailXN?.DanhSachXetNghiem[0]?.ktvxn &&
    moment().diff(moment(detailXN?.DanhSachXetNghiem[0]?.ngayketqua), 'days') > 0
      ? true
      : false
  const isKhongDcXoaTep = detailXN?.DanhSachXetNghiem?.find((item) => item?.ktvxn)
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (message, description) => {
    api.warning({
      message,
      description,
    })
  }
  const onChangeKetQua = (e, id) => {
    const { value } = e.target
    const index = detailXN?.DanhSachXetNghiem.findIndex((items) => items.idchidinh === id)
    dispatch({
      type: typeAction.DISPATCH_CHANGE_KET_QUA_XET_NGHIEM,
      payload: { index, value },
    })
  }
  const onChangeChiDinh = (e, id) => {
    const { value } = e.target
    const index = detailXN?.DanhSachXetNghiem.findIndex((items) => items.idchidinh === id)
    dispatch({
      type: typeAction.DISPATCH_CHANGE_CHI_SO_XET_NGHIEM,
      payload: { index, value },
    })
  }
  const handleChangeSoLuong = (vt, soLuongNew) => {
    if (isDaLuuKQVaQuaNgay) {
      //infoBNDVThuThuat?.DanhSachDichVu?.length > 0 && infoBNDVThuThuat?.DanhSachDichVu[0]?.trangthaikb === 4
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Bệnh nhân đã thực hiện, không thể chỉnh sửa!')
      return
    }
    const newValue = Number(soLuongNew)
    const index = listVTBN.findIndex((items) => items.iD === vt.iD)
    if (newValue > listVTBN[index].tonKho) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Số lượng tồn kho không đủ!')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_EDIT_VT_SL_BN_THUTHUAT,
      payload: {
        index: index,
        value: newValue,
      },
    })
  }

  const handleChangeGhiChu = (vt, ghiChuNew) => {
    if (isDaLuuKQVaQuaNgay) {
      //infoBNDVThuThuat?.DanhSachDichVu?.length > 0 && infoBNDVThuThuat?.DanhSachDichVu[0]?.trangthaikb === 4
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Bệnh nhân đã thực hiện, không thể chỉnh sửa!')
      return
    }
    const index = listVTBN.findIndex((items) => items.iD === vt.iD)
    dispatch({
      type: typeAction.DISPATCH_EDIT_VT_GHICHU_BN_THUTHUAT,
      payload: {
        index: index,
        value: ghiChuNew,
      },
    })
  }
  const handleVTTieuHao = (value) => {
    if (isDaLuuKQVaQuaNgay) {
      //infoBNDVThuThuat?.DanhSachDichVu?.length > 0 && infoBNDVThuThuat?.DanhSachDichVu[0]?.trangthaikb === 4
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Bệnh nhân đã thực hiện, không thể chỉnh sửa!')
      return
    }
    if (!detailXN?.IDBNTTC) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Vui lòng chọn bệnh nhận cần thực hiện!')
      return
    }
    const index = listVTBN?.findIndex((itemVTBN) => itemVTBN.iD === value)
    if (index !== -1) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Vật tư đã được chọn!')
      return
    }
    const indexVT = listVT?.findIndex((itemVT) => itemVT.iD === value)
    if (listVT[indexVT]?.tonKho <= 0) {
      openNotificationWithIcon('Kê vật tư tiêu hao', 'Số lượng tồn kho không đủ!')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_ADD_VT_BN_THUTHUAT,
      payload: {
        iD: listVT[indexVT]?.iD,
        idbnttc: detailXN?.IDBNTTC,
        idthuoc: listVT[indexVT]?.idThuoc,
        donGia: listVT[indexVT]?.thuocVatTu?.giaBan,
        soLuong: 1,
        thanhTien: listVT[indexVT]?.thuocVatTu?.giaBan,
        tongTien: listVT[indexVT]?.thuocVatTu?.giaBan,
        bsKeDon: detailXN?.BSCHIDINH,
        nguoiThucHien: infoUser?.tenNV,
        ghiChu: '',
        idkhocn: listVT[indexVT]?.idKhoCN,
        soLo: listVT[indexVT]?.soLo,
        hanDung: listVT[indexVT]?.hanDung,
        idXetNghiem: detailXN?.DanhSachXetNghiem[0]?.idxn, ////////////////idXetNghiem-idDichVu
        tenBietDuoc: listVT[indexVT]?.thuocVatTu?.tenBietDuoc,
        dvt: listVT[indexVT]?.thuocVatTu?.dvt,
        tonKho: listVT[indexVT]?.tonKho,
        soHoaDon: listVT[indexVT]?.soHoaDon,
        maSoDangKy: listVT[indexVT]?.maSoDangKy,
      },
    })
  }
  const onLoadInfoBnXn = () => {
    dispatch(infoBNXetNghiem(detailXN?.IDBN, detailXN?.IDBNTTC))
  }
  //----- UPLOAD FILE-------//
  const handleFileChange = async (event) => {
    if (isChuaGuiMau || event.target.files?.length === 0) {
      //isDaLuuKQVaQuaNgay || thêm lúc nào cũng dc kh qua ngày
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
      return
    }
    const allowedFileTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'application/pdf',
    ]
    if (!allowedFileTypes.includes(event.target.files[0].type)) {
      openNotificationWithIcon('Upload file kết quả xét nghiệm','Chỉ chấp nhân tệp Hình ảnh, PDF')
      fileInputRef.current.value = '' // clear the input
      return
    }
    try {
      let file = await event.target.files[0]
      const formData = new FormData()
      formData.append('IDBNTTC', detailXN?.IDBNTTC)
      formData.append('FILEHINHANHS', file)
      fileInputRef.current.value = '' // clear the input

      await xetNghiemService.uploadFileXN(formData)
      onLoadInfoBnXn()
    } catch (error) {
      console.log(error)
      fileInputRef.current.value = '' // clear the input

      ToastCus.fire({
        icon: 'error',
        title: 'Lưu tệp đính kèm thất bại!',
      })
    }
  }
  const handleDeleteFile = (url) => {
    if (isKhongDcXoaTep || isDaLuuKQVaQuaNgay || isChuaGuiMau) {
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
      return
    }
    const isImage =
      url.endsWith('.jpg') ||
      url.endsWith('.jpeg') ||
      url.endsWith('.png') ||
      url.endsWith('.gif') ||
      url.endsWith('.bmp')
    confirm({
      title: 'Xóa tệp đính kèm',
      icon: <ExclamationCircleFilled />,
      content: (
        <>
          <p>
            Bạn có muốn xóa:
            {/* <span className="font-semibold ml-1">
              {formik.values.MAPAKH} - {formik.values.TENPAKH}
            </span> */}
          </p>
          <div className="w-full pl-[4.5rem] pb-3">
            <div className="w-[10rem] h-[10rem] mt-1 border">
              {isImage ? (
                <>
                  <img src={url} alt={url} className="w-full" />
                </>
              ) : (
                <>
                  <img
                    src="https://www.svgrepo.com/download/144578/pdf.svg"
                    alt={url}
                    className="w-full"
                  />
                </>
              )}
            </div>
          </div>
        </>
      ),
      okText: 'Đồng ý', // Thay đổi văn bản nút "OK"
      cancelText: 'Hủy bỏ', // Thay đổi văn bản nút "Cancel"
      onOk() {
        deleteFile(url)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  //-------delete file nhập kho ---------/
  const deleteFile = async (url) => {
    // console.log(url)
    // const form = {
    //   idNhapXuat: detailXN?.IDBNTTC,
    //   hoaDonFiles: url,
    // }
    try {
      await xetNghiemService.deleteFileXN(detailXN?.IDBNTTC, url)
      onLoadInfoBnXn()
      // ToastCus.fire({
      //   icon: 'success',
      //   title: 'Lưu tệp đính kèm thành công!',
      // })
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    }
    // if (!checkValidate) {
    //   const form = {
    //     idNhapXuat: infoPTNhap?.idNhapXuat,
    //     hoaDonFiles: url,
    //   }
    //   // dispatch(deleteFileNhap(infoPTNhap?.idNhapXuat, form))
    // }
  }
  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const checkQuyenCacTabs = async (maquyen) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, maquyen)
      setlistQuyenTab((prev) =>
        prev?.find((item) => item === maquyen) ? prev : [...prev, maquyen],
      )
    } catch (error) {
      setlistQuyenTab((prev) => prev?.filter((item) => item !== maquyen))
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const maQuyenKeVTTH = 'QHT317'
  const maQuyenLichSu = 'QHT587'
  const maQuyenTepDinhKem = 'QHT586'
  // useEffect(() => {
  //   checkQuyenCacTabs()
  // }, [])
  useEffect(() => {
    keyTab === 1 && checkQuyenCacTabs(maQuyenKeVTTH)
    keyTab === 2 && checkQuyenCacTabs(maQuyenLichSu)
    keyTab === 3 && checkQuyenCacTabs(maQuyenTepDinhKem)
  }, [keyTab])
  useEffect(() => {
    dispatch({
      type: typeAction.DISPATCH_LIST_VT_BN_THUTHUAT,
      payload: detailXN?.DanhSachVatTuTieuHao?.map((item) => ({
        ...item,
        iD: listVT?.find(
          (itemVT) =>
            itemVT?.idThuoc === item?.idthuoc &&
            itemVT?.soLo === item?.soLo &&
            itemVT?.hanDung === item?.hanDung &&
            itemVT?.soHoaDon === item?.soHoaDon,
        )?.iD,
        tonKho:
          (listVT?.find(
            (itemVT) =>
              itemVT?.idThuoc === item?.idthuoc &&
              itemVT?.soLo === item?.soLo &&
              itemVT?.hanDung === item?.hanDung &&
              itemVT?.soHoaDon === item?.soHoaDon,
          )?.tonKho ?? 0) + item?.soLuong,
      })),
    })
  }, [detailXN?.DanhSachVatTuTieuHao, detailXN?.DanhSachVatTuTieuHao?.length])

  const items = [
    {
      key: 1,
      label: 'Chỉ định',
      children: (
        <>
          {/* {isChuaGuiMau && (
          <>
          </>
        )} */}
          <div className="p-2 pt-0 pb-0">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                bordered
                rowSelection={rowSelection}
                // pagination={{ pageSize: 20 }}
                pagination={false}
                scroll={{ y: 175 }}
                dataSource={detailXN?.DanhSachXetNghiem.map((item) => ({
                  ...item,
                  key: item.idchidinh,
                }))}
                columns={[
                  {
                    title: 'STT',
                    dataIndex: 'stt',
                    key: 'stt',
                    width: 40,
                    align: 'center',
                    render: (text, record, index) => ++index,
                  },
                  {
                    title: 'Ngày chỉ định',
                    dataIndex: 'ngaychidinh',
                    key: 'ngaychidinh',
                    width: 145,
                    align: 'center',
                    render: (text, record, index) =>
                      text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-',
                  },
                  { title: 'Mã XN', dataIndex: 'machidinh', key: 'machidinh', width: 180 },
                  { title: 'Tên xét nghiệm', dataIndex: 'tenchidinh', key: 'tenchidinh' },
                  { title: 'ĐVT', dataIndex: 'dvt', key: 'dvt', width: 60, align: 'center' },
                  {
                    title: 'Kết quả ',
                    dataIndex: 'ketqua',
                    key: 'ketqua',
                    render: (text, record, index) =>
                      record?.trangthaikb && !record?.ktvxn ? (
                        <Input
                          onChange={(e) => onChangeKetQua(e, record.idchidinh)}
                          size="small"
                          variant="borderless"
                          value={text}
                          placeholder="Nhập kết quả"
                        />
                      ) : (
                        text
                      ),
                  },
                  // {
                  //   title: 'Đơn giá',
                  //   dataIndex: 'dongia',
                  //   key: 'dongia',
                  //   render: (text) => formatNumber(text),
                  // },
                  {
                    title: 'Chỉ số',
                    dataIndex: 'chisobinhthuong',
                    key: 'chisobinhthuong',
                    width: 150,
                    align: 'center',
                    render: (text, record, index) =>
                      record?.trangthaikb && !record?.ktvxn ? (
                        <Input
                          value={text}
                          size="small"
                          onChange={(e) => onChangeChiDinh(e, record.idchidinh)}
                          variant="borderless"
                          placeholder=" Nhập chỉ số"
                        />
                      ) : (
                        text
                      ),
                  },
                ]}
              />
            </ConfigProvider>
          </div>

          <div className="">
            {/* <Divider orientation="left">Vật tư tiêu hao</Divider> */}
            <h2 className="font-semibold  text-lg ml-2 mt-3 text-gray-500">Vật tư tiêu hao</h2>
            <div className="p-2">
              {!listQuyenTab?.includes(maQuyenKeVTTH) ? (
                <>
                  <Alert message="Tài khoản không có quyền thao tác!" type="error" showIcon />
                </>
              ) : (
                <>
                  <div className="flex mb-2">
                    <div className="w-[12%]">
                      <Select
                        value={khoVatTu}
                        options={[
                          {
                            value: 1,
                            label: 'Kho điều dưỡng',
                          },
                          {
                            value: 2,
                            label: 'Kho cấp cứu',
                          },
                        ]}
                        className="w-full"
                        onChange={setKhoVatTu}
                      />
                    </div>
                    <div className="w-[88%] pl-1">
                      <Select
                        placeholder="Nhập tên vật tư..."
                        className="w-full"
                        showSearch
                        onChange={handleVTTieuHao}
                        filterOption={(input, option) => {
                          const label = option?.label ?? ''
                          const tenBietDuoc = option?.tenBietDuoc ?? ''
                          const searchText = input.toLowerCase()
                          return (
                            label.toLowerCase().includes(searchText) ||
                            tenBietDuoc.toLowerCase().includes(searchText)
                          )
                        }}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        value={null}
                        options={listVT?.map((items) => ({
                          key: items.iD,
                          value: items.iD,
                          label: items.thuocVatTu.tenHoatChat,
                          tenBietDuoc: items.thuocVatTu.tenBietDuoc,
                          dvt: items.thuocVatTu.dvt,
                          soLo: items.soLo,
                          hanDung: items.hanDung,
                          soHoaDon: items.soHoaDon,
                          maSoDangKy: items.maSoDangKy,
                          ton: items.tonKho,
                        }))}
                        dropdownRender={(menu) => (
                          <div>
                            <div
                              className="flex bg-gray-100"
                              style={{ padding: '8px', fontWeight: 'bold' }}
                            >
                              <span className="w-[50%] text-center">Tên hàng</span>
                              <span className="w-[12.5%] text-center">Số lô</span>
                              <span className="w-[12.5%] text-center">Hạn dùng</span>
                              <span className="w-[12.5%] text-center">Đơn vị</span>
                              <span className="w-[12.5%] text-center">Tồn</span>
                            </div>
                            <div style={{ marginBottom: '8px' }}>{menu}</div>
                          </div>
                        )}
                        optionLabelProp="label"
                        optionRender={(options) => (
                          <ul className="flex">
                            <li className="w-[50%] ">
                              <p className="font-semibold w-full text-pretty">
                                {options.data.label}
                              </p>
                              <span className="text-xs w-full text-gray-500">
                                {' '}
                                ({options.data.tenBietDuoc})
                              </span>
                            </li>
                            <li className="w-[12.5%] border-x flex items-center justify-center text-center">
                              {options.data.soLo}
                            </li>
                            <li className="w-[12.5%] text-center flex items-center justify-center">
                              {moment(options.data.hanDung).format('DD/MM/YYYY')}
                            </li>
                            <li className="w-[12.5%]  text-center flex items-center justify-center">
                              {options.data.dvt}
                            </li>
                            <li className="w-[12.5%] border-x text-center flex items-center justify-center">
                              {options.data.ton}
                            </li>
                          </ul>
                        )}
                        // filterOption={(input, option) =>
                        //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        // }
                        // options={listVT?.map((items) => ({
                        //   label: items.thuocVatTu.tenBietDuoc,
                        //   value: items.idThuoc
                        // }))}
                      />
                    </div>
                  </div>
                  <ConfigProvider
                    theme={{
                      token: {
                        padding: 5,
                      },
                      components: {
                        Table: {
                          rowHoverBg: '#ecf0f1',
                          headerBg: '#e6e6e6',
                          footerBg: '#e6e6e6',
                          borderColor: '#BABABA',
                        },
                      },
                    }}
                  >
                    <Table
                      pagination={false}
                      dataSource={listVTBN ?? []}
                      columns={[
                        {
                          title: 'STT',
                          dataIndex: 'stt',
                          key: 'stt',
                          align: 'center',
                          width: 40,
                          render: (text, record, index) => ++index,
                        },
                        {
                          title: 'Tên vật tư',
                          dataIndex: 'tenBietDuoc',
                          key: 'tenBietDuoc',
                        },
                        {
                          title: 'Số lô',
                          dataIndex: 'soLo',
                          key: 'soLo',
                          align: 'center',
                          width: 120,
                        },
                        {
                          title: 'Hạn dùng',
                          dataIndex: 'hanDung',
                          key: 'hanDung',
                          align: 'center',
                          width: 90,
                          render: (text, record, index) =>
                            text && moment(text).format('DD/MM/YYYY'),
                        },
                        {
                          title: 'Đơn vị',
                          dataIndex: 'dvt',
                          key: 'dvt',
                          align: 'center',
                          width: 70,
                        },
                        {
                          title: 'SL',
                          dataIndex: 'soLuong',
                          key: 'soLuong',
                          align: 'center',
                          width: 65,
                          render: (text, record, index) => (
                            <Input
                              onChange={(e) => {
                                handleChangeSoLuong(record, e.target.value)
                              }}
                              value={text}
                              min={0}
                              type="number"
                              size="small"
                            />
                          ),
                        },
                        {
                          title: 'Tồn',
                          dataIndex: 'tonKho',
                          key: 'tonKho',
                          align: 'center',
                          width: 65,
                        },
                        {
                          title: 'Ghi chú',
                          dataIndex: 'ghiChu',
                          key: 'ghiChu',
                          align: 'center',
                          width: 200,
                          render: (text, record, index) => (
                            <Input
                              onChange={(e) => {
                                handleChangeGhiChu(record, e.target.value)
                              }}
                              value={text ?? ''}
                              size="small"
                            />
                          ),
                        },
                        {
                          title: '',
                          dataIndex: 'action',
                          key: 'action',
                          align: 'center',
                          width: 40,
                          render: (text, record, index) => (
                            <>
                              {!isDaLuuKQVaQuaNgay && (
                                <CloseOutlined
                                  onClick={() => {
                                    dispatch({
                                      type: typeAction.DISPATCH_DELETE_VT_BN_THUTHUAT,
                                      payload: record.iD,
                                    })
                                    if (!isChuaGuiMau) {
                                      dispatch({
                                        type: typeAction.DISPATCH_LIST_VT_THUTHUAT,
                                        payload: listVT?.map((item) =>
                                          item?.iD === record.iD
                                            ? { ...item, tonKho: record?.tonKho }
                                            : item,
                                        ),
                                      })
                                    }
                                  }}
                                  className="text-red-500 cursor-pointer"
                                />
                              )}
                            </>
                          ),
                        },
                      ]}
                      bordered
                      scroll={{ y: 175 }}
                    />
                  </ConfigProvider>
                </>
              )}
            </div>
          </div>
        </>
      ),
    },
    {
      key: 2,
      label: 'Lịch sử',
      children: !listQuyenTab?.includes(maQuyenLichSu) ? (
        <Alert message="Tài khoản không có quyền thao tác!" type="error" showIcon />
      ) : (
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            dataSource={history}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 40,
                align: 'center',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                width: 150,
                render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
              },
              { title: 'Phiếu', dataIndex: 'phieu', key: 'phieu' },
              { title: 'Tên xét nghiệm', dataIndex: 'tenxetnghiem', key: 'tenxetnghiem' },
              {
                title: 'Kết quả',
                dataIndex: 'ketqua',
                key: 'ketqua',
                render: (text) => (text ? text : '-'),
              },
              {
                title: 'Chỉ số bình thường',
                dataIndex: 'chisobinhthuong',
                key: 'chisobinhthuong',
                align: 'center',
                render: (text) => (text ? text : '-'),
              },
              {
                title: 'Đơn vị gửi',
                dataIndex: 'donvigui',
                key: 'donvigui',
                width: 100,
                align: 'center',
              },
            ]}
          />
        </ConfigProvider>
      ),
    },
    {
      key: 3,
      label: 'Tệp đính kèm',
      children: !listQuyenTab?.includes(maQuyenTepDinhKem) ? (
        <Alert message="Tài khoản không có quyền thao tác!" type="error" showIcon />
      ) : (
        <>
          <div className="mb-2 flex justify-between mr-2">
            <div className="pl-2">
              <ul className="flex gap-2 mb-2 flex-wrap">
                {detailXN?.FILEKETQUA?.split(';')?.map((url, index) => {
                  const isImage =
                    url.endsWith('.jpg') ||
                    url.endsWith('.jpeg') ||
                    url.endsWith('.png') ||
                    url.endsWith('.gif') ||
                    url.endsWith('.bmp')
                  return (
                    url?.length > 0 && (
                      <li key={index} className="relative group">
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          {isImage ? (
                            <>
                              <img
                                src={url}
                                //src='https://scontent.fhan4-3.fna.fbcdn.net/v/t39.30808-6/449400910_876421197839291_9001151934001860500_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=imsuSugvjU8Q7kNvgFrtJMR&_nc_ht=scontent.fhan4-3.fna&oh=00_AYDAISvej-WBPaTDI7onOR9ypQXSXp24MbxE9NRrQc0EVg&oe=66A63746'
                                alt={`Image ${index + 1}`}
                                className="w-20 transition duration-300 transform group-hover:scale-110 group-hover:z-10"
                              />
                            </>
                          ) : (
                            <img
                              src="https://www.svgrepo.com/download/144578/pdf.svg"
                              alt={`PDF ${index + 1}`}
                              className="w-20 transition duration-300 transform group-hover:scale-110 group-hover:z-10"
                            />
                          )}
                        </a>
                        <div
                          onClick={() => handleDeleteFile(url)}
                          className={`absolute top-0 left-0 bg-white hover:bg-slate-200 p-1 transition duration-300 group-hover:scale-125 rounded ${isKhongDcXoaTep || isDaLuuKQVaQuaNgay || isChuaGuiMau ? 'hidden' : 'text-red-500'} cursor-pointer`}
                        >
                          <DeleteOutlined style={{ fontSize: 18 }} />
                        </div>
                      </li>
                    )
                  )
                })}
              </ul>
            </div>
            <div className={`${isKhongDcXoaTep ? 'hidden' : ''}`}>
              <input ref={fileInputRef} id="file-upload" type="file" onChange={handleFileChange} />
              <Button
                disabled={!detailXN || isChuaGuiMau} // || isDaLuuKQVaQuaNgay thêm lúc nào cũng dc kh qua ngày
                onClick={handleButtonClick}
                size="small"
                variant="outlined"
                startIcon={<AttachmentIcon />}
              >
                Đính kèm
              </Button>
            </div>
          </div>
          {isKhongDcXoaTep && <UpdateThemFile />}
        </>
      ),
    },
  ]
  return (
    <div className="p-2 h-[560px] border rounded-md mt-2 pb-0">
      {contextHolder}
      <Tabs value={keyTab} onChange={setkeyTab} items={items} />
    </div>
  )
}

export default TableChiDinh
