import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConfigProvider, DatePicker, Table, TreeSelect, Select, Button } from 'antd'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import Static from './Static/Static'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { getBranchNotVP } from '../../../../store/actions/BranchAction'
import { reportService } from '../../../../services/Report/reportService'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { formattedNumber } from '../../../../utils/formattedNumber'
import * as typeAction from '../../../../store/constants/constants'
import ToastCus from '../../../../utils/Toast'
import { columns } from './Columns'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
import { getListNameChiNhanh } from '../../../../utils/algorithm'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)

const TheThanhVien = () => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const [company, setCompany] = useState(id_company)
  const [date, setDate] = useState({ form: now, to: now })
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState([BranchLogin])
  const [data, setData] = useState([])

  const nameChiNhanh = useMemo(() => getListNameChiNhanh(value, branch), [value, branch])
  const nameCompany = useMemo(
    () => listCompany?.find((item) => item?.idct === company)?.tenct,
    [company, listCompany],
  )

  const fetchReport = async () => {
    try {
      openLoading()
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT520',
      )
      let idChiNhanhs = null
      // Kiểm tra giá trị của `value` để xử lý các trường hợp rỗng hoặc "all"
      if (value?.length > 0 && value[0] !== 'all') {
        // Giả sử `value` là mảng các ID. Chuyển đổi mảng thành chuỗi truy vấn
        idChiNhanhs = value.map((id) => `&idcn=${id}`).join('&')
        const { data } = await reportService.getTTVPhieu(company, idChiNhanhs, date.form, date.to)
        setData(data)
      } else {
        // Xử lý trường hợp "all"
        const { data } = await reportService.getTTVPhieu(company, '', date.form, date.to)
        setData(data)
        setValue(['all'])
      }
      closeLoading()
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.BAO_CAO_TTV,
        true,
        MENU_CONSTANTS.BAO_CAO_TTV,
        null,
        {
          dateFrom: date.form,
          dateTo: date.to,
          nameChiNhanh,
          nameCompany,
        },
      )
    } catch (error) {
      closeLoading()
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })

      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.BAO_CAO_TTV,
        detailErrorPayload,
      )
    } finally {
      closeLoading()
    }
  }

  const openLoading = () => {
    dispatch({
      type: typeAction.OPEN_LOADING_TABLE,
    })
  }
  const closeLoading = () => {
    dispatch({
      type: typeAction.CLOSE_LOADING_TABLE,
    })
  }
  const handleDatePicker = (name) => (date, dateString) => {
    const formatDate = moment(dateString, dateFormat).format(momentDate)
    setDate((prevSate) => ({ ...prevSate, [name]: formatDate }))
  }
  const onChangeCompany = (value) => {
    setCompany(value)
    setValue(['all'])
  }
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT521',
      )

      const formatData = data?.map((item) => ({
        'Ngày chứng từ': moment(item.ngaythanhtoan).format('DD/MM/YYYY'),
        'Số chứng từ': item.machungtu,
        'Mã công cụ': item.mathe,
        'Mã bệnh nhân': item.mabenhnhan,
        'Tên công cụ': item.tenbenhnhan,
        'Ngày tăng': moment(item.ngayhieuluc).format('DD/MM/YYYY'),
        'Ngày tính phân bổ': moment(item.ngayhieuluc).format('DD/MM/YYYY'),
        'Ngày kết thúc pb': moment(item.ngayketthuc).format('DD/MM/YYYY'),
        'Số kỳ phân bổ': item.sothang,
        'ĐVT ': 'Tháng',
        'Mã bộ phận': item.machinhanh,
        'Tên bộ phận': item.tenchinhanh,
        'Nguyên giá': item.tienthanhtoan,
        'Gt phân bổ kỳ': item.giaphanbo,
      }))
      const name = 'Báo cáo thẻ thành viên'
      formatData?.length && exportExcelformat(formatData, name)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_BAO_CAO_TTV,
        true,
        MENU_CONSTANTS.BAO_CAO_TTV,
        null,
        {
          dateFrom: date.form,
          dateTo: date.to,
          nameChiNhanh,
          nameCompany,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })

      const detailErrorPayload = `Có lỗi xảy ra khi xuất file excel bao cáo thẻ thành viên \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.BAO_CAO_TTV,
        detailErrorPayload,
      )
    }
  }
  useEffect(() => {
    dispatch(getBranchNotVP(company, setBranch))
  }, [company])

  useEffect(() => {
    dispatch(listAllCompanyAction())
  }, [])

  useEffect(() => {
    if (infoUser && branch.length) {
      fetchReport()
    }
  }, [infoUser, branch])
  // validate
  return (
    <div>
      <Static data={data} />
      <div className="flex gap-3 border-dotted border-b-0  p-2 ">
        <Select
          showSearch
          value={company}
          onChange={onChangeCompany}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={listCompany?.map(({ idct, tenct }) => ({
            label: tenct,
            value: idct,
          }))}
          className={`w-80`}
        />
        <div className={`w-80 `}>
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
          />
        </div>
        <div className="flex items-center gap-3">
          <DatePicker
            allowClear={false}
            value={dayjs(date.form)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-lg">-</span>
          <DatePicker
            allowClear={false}
            value={dayjs(date.to)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>

        <div className="flex gap-3">
          <IconButton color="primary" onClick={fetchReport} aria-label="delete" size="small">
            <SyncIcon />
          </IconButton>
        </div>
        <div className="flex gap-3 ml-auto">
          <Button
            disabled={!data?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            borderRadius: 0,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          loading={isLoadingTable}
          bordered
          scroll={{
            x: 1500,
            y: '65vh',
          }}
          dataSource={data}
          columns={columns}
          pagination={false} // Disable pagination
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                {/* Tổng thanh toán */}
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={6} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={7} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={8} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={9} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={10} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={11} align="center"></Table.Summary.Cell>
                <Table.Summary.Cell index={12}></Table.Summary.Cell>
                <Table.Summary.Cell index={13} align="right">
                  {' '}
                  <p className="font-semibold">
                    {formattedNumber(data.reduce((total, item) => total + item.tienthanhtoan, 0))}
                  </p>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={14} align="right">
                  {' '}
                  <p className="font-semibold">
                    {formattedNumber(data.reduce((total, item) => total + item.giaphanbo, 0))}
                  </p>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </ConfigProvider>
    </div>
  )
}

export default TheThanhVien
