import React, { useEffect, useState, useRef } from 'react'
import * as typeAction from '../../../../store/constants/constants'
import {
  Tabs,
  Segmented,
  Menu,
  Table,
  ConfigProvider,
  Button,
  Space,
  Input,
  notification,
} from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import Highlighter from 'react-highlight-words'
import {
  fetchListChiDinhCĐHA_Action,
  fetchListChiDinhKham_Action,
  fetchListChiDinhXetNghiem_Action,
  getListGroupCĐHA_Action,
  groupXetNghiem_Action,
  groupsChiDinhKham_Action,
  getListXetNghiemTiepNhanAction,
  getListCDHATiepNhanAction,
} from '../../../../store/actions/receiveAction'
import { postApplyMemberCardCashierAction } from '../../../../store/actions/cashierAction'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import moment from 'moment'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
import { addLog } from '../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../utils/log/logConstant'
moment.locale('vi')
const idnhomDVKham = [1, 2, 3, 6, 21, 22, 24, 25, 42, 46] //đi khám không tính đo khúc xạ, không có 4
const ServiceKham = ({ checkDelete }) => {
  const XN = 'Xét nghiệm'
  const KB = 'Khám bệnh'
  const CĐHA = 'Chẩn đoán hình ảnh'
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { groupsChiDinh, ChiDinh } = useSelector((state) => state.receiveReducer)
  const { BNTTC, ArrKB, ArrXN, ArrCĐHA, memberCard, discountArr, infoChuongTrinhGiamGiaDoiTac } =
    useSelector((state) => state.cashierReducer)
  const [valueSegmented, setValueSegmented] = useState(KB)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const branch = JSON.parse(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const priceChiDinh = {
    ptGiam: 0,
    tienGiam: 0,
    thanhToan: 0,
    type: '', // Mục đích sét type để khi xoá danh sách chỉ đỉnh khám dựa vào type xoá đúng mảng chỉ định
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const [api, contextHolder] = notification.useNotification()
  const openNotification = () => {
    api.warning({
      message: `Chọn dịch vụ`,
      description: 'Dịch vụ đã được chọn!',
      placement: 'topRight',
    })
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const handleMenu = ({ key }) => {
    switch (valueSegmented) {
      case KB:
        dispatch(fetchListChiDinhKham_Action(key, branch))
        break
      case XN:
        // dispatch(fetchListChiDinhXetNghiem_Action(key, branch))
        break
      case CĐHA:
        // dispatch(fetchListChiDinhCĐHA_Action(key))
        break
      default:
        break
    }
  }
  const onClickChonChiDinh = async (cd) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        (valueSegmented === KB && 'QHT031') ||
          (valueSegmented === XN && 'QHT032') ||
          (valueSegmented === CĐHA && 'QHT033'),
      )

      if (discountArr.length > 0 || infoChuongTrinhGiamGiaDoiTac) {
        ToastCus.fire({
          icon: 'error',
          title: 'Vui lòng bỏ voucher hoặc đối tác trước khi thêm chỉ định!',
        })
        return
      }
      const now = moment(),
        Dateformat = 'YYYY-MM-DDTHH:mm:ss'
      let chiTietCD, index
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.APPLY_TTV_CHO_CHI_DINH_KHAM_BENH,
        true,
        MENU_CONSTANTS.TIEP_NHAN,
        null,
        {
          tenChiDinh: cd.tenChiDinh,
        },
      )
      switch (valueSegmented) {
        case KB:
          if (BNTTC?.trangthaikhambenh === 4) {
            // nếu bệnh nhân không có dịch vụ khám bệnh và hoàn thành ca khám thì không thực hiện nữa
            ToastCus.fire({
              icon: 'error',
              title: 'Bệnh nhân này đã hoàn thành lượt khám!',
            })
            return
          }
          if (idnhomDVKham?.includes(cd?.dichVu?.idNhomDV)) {
            let cdKham = ArrKB?.find(
              (item) =>
                idnhomDVKham?.includes(item?.idnhomdv) && item?.idnhomdv !== cd?.dichVu?.idNhomDV,
            )
            if (cdKham) {
              api.warning({
                message: `Chọn dịch vụ`,
                description: 'Dịch vụ khám loại khác đã được chọn!',
                placement: 'topRight',
              })
              return
            }
          }
          index = ArrKB?.findIndex(
            (item) => item.iddichvu === cd.idChiDinh && item?.idnhomdv !== 41,
          )
          if (index !== -1) {
            openNotification()
            return
          }
          chiTietCD = {
            idbnttc: BNTTC?.idbnttc,
            iddichvu: cd.dichVu.iddv,
            madichvu: cd.dichVu.maDichVu,
            tendichvu: cd.dichVu.tenDichVu,
            donvitinh: cd.dichVu.donVi,
            dongia: cd.donGia,
            ptgiamgia: 0,
            tiengiamgia: 0,
            tienthanhtoan: cd.donGia,
            loaichidinh: cd.dichVu.loaiChiDinh,
            tienguimau: cd.dichVu.tienGuiMau,
            tienchietkhau: cd.dichVu.tienChietKhau,
            ngaysudung: now.format(Dateformat),
            trangthaikb: 1, // tiếp nhận
            trangthaitt: 3, // chưa thu
            tiencong: cd.dichVu.tienGiaCong,
            idnhomdv: cd.dichVu.idNhomDV,
            letan: 1, // phân biệt chỉ định lễ tân thêm, và chỉ định bs thêm
          }
          if (memberCard?.idthe && memberCard?.idbn === BNTTC?.idbn) {
            dispatch(
              postApplyMemberCardCashierAction(
                {
                  idthe: memberCard.idthe,
                  iddv: cd.dichVu.iddv,
                  tiendichvu: cd.donGia,
                  loaithedichvu: 1,
                }, // Loại thẻ dịch vụ (idLoaiDV === loaithedichvu) : (1 - dv khám bệnh, 2 - dv xét nghiệm, 3 - dv cdha)
                chiTietCD,
              ),
            )
            return
          }
          dispatch({
            type: typeAction.DISTPATCH_LIST_KB_CASHIER,
            payload: chiTietCD,
          })
          break
        case XN:
          index = ArrXN.findIndex((item) => item.idxn === cd.idChiDinh)
          if (index !== -1) {
            openNotification()
            return
          }
          chiTietCD = {
            idbnttc: BNTTC?.idbnttc,
            idxn: cd.idChiDinh,
            maxn: cd.xetNghiem.maxn,
            tenxn: cd.tenChiDinh,
            mamic: cd.xetNghiem.mamic,
            madiag: cd.xetNghiem.madiag,
            matvd: cd.xetNghiem.matvd,
            dvt: cd.xetNghiem.dvt,
            trangthaitt: 3,
            dongia: cd.donGia,
            ptgiamgia: 0,
            tiengiamgia: 0,
            tienthanhtoan: cd.donGia,
            ngaychidinh: now.format(Dateformat),
            letan: 1, // phân biệt chỉ định lễ tân thêm, và chỉ định bs thêm
          }
          if (memberCard?.idthe && memberCard?.idbn === BNTTC?.idbn) {
            dispatch(
              postApplyMemberCardCashierAction(
                {
                  idthe: memberCard.idthe,
                  iddv: cd.idChiDinh,
                  tiendichvu: cd.donGia,
                  loaithedichvu: 2,
                }, // Loại thẻ dịch vụ (idLoaiDV === loaithedichvu) : (1 - dv khám bệnh, 2 - dv xét nghiệm, 3 - dv cdha)
                chiTietCD,
              ),
            )
            return
          }
          dispatch({
            type: typeAction.DISTPATCH_LIST_XN_CASHIER,
            payload: chiTietCD,
          })
          break
        case CĐHA:
          index = ArrCĐHA.findIndex((item) => item.idcdha === cd.idChiDinh)
          if (index !== -1) {
            openNotification()
            return
          }
          chiTietCD = {
            idbnttc: BNTTC?.idbnttc,
            idcdha: cd.idChiDinh,
            macdha: cd.macdha,
            tencdha: cd.tenChiDinh,
            idnhomcdha: cd.idnhomcdha,
            dongia: cd.donGia,
            ptgiamgia: 0,
            tiengiamgia: 0,
            tienthanhtoan: cd.donGia,
            idtrangthaitt: 3,
            ngaychidinh: now.format(Dateformat),
            dvt: cd.dvt,
            letan: 1, // phân biệt chỉ định lễ tân thêm, và chỉ định bs thêm
          }
          if (memberCard?.idthe && memberCard?.idbn === BNTTC?.idbn) {
            dispatch(
              postApplyMemberCardCashierAction(
                {
                  idthe: memberCard.idthe,
                  iddv: cd.idChiDinh,
                  tiendichvu: cd.donGia,
                  loaithedichvu: 3,
                }, // Loại thẻ dịch vụ (idLoaiDV === loaithedichvu) : (1 - dv khám bệnh, 2 - dv xét nghiệm, 3 - dv cdha)
                chiTietCD,
              ),
            )
            return
          }
          dispatch({
            type: typeAction.DISTPATCH_LIST_CDHA_CASHIER,
            payload: chiTietCD,
          })
          break
        default:
          break
      }
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.TIEP_NHAN,
        detailErrorPayload,
      )
    }
  }
  const restChiDinh = () => {
    dispatch({
      type: typeAction.DISPATCH_CHI_DINH,
      payload: null,
    })
  }
  // chọn loại chỉ định
  const handleChangeSegmented = (value) => {
    setValueSegmented(value)
    if (value === KB) {
      dispatch(groupsChiDinhKham_Action(branch))
      restChiDinh()
    }
    if (value === XN) {
      dispatch(getListXetNghiemTiepNhanAction(branch))
      // dispatch(groupXetNghiem_Action(branch))
      // restChiDinh()
    }
    if (value === CĐHA) {
      dispatch(getListCDHATiepNhanAction(ID_COMPANY))
      // dispatch(getListGroupCĐHA_Action())
      // restChiDinh()
    }
  }
  useEffect(() => {
    dispatch(groupsChiDinhKham_Action(branch))
  }, [])
  // set giá thanh toán theo từng chỉ định
  //   useEffect(() => {
  //     handleSetTotalPay("tienCDHA", totalPayCDHA)
  //   }, [ArrCĐHA])
  //   useEffect(() => {
  //     handleSetTotalPay("tienDichVu", totalPayKB)
  //   }, [ArrKB])
  //   useEffect(() => {
  //     handleSetTotalPay("tienXetNghiem", totalPayXN)
  //   }, [ArrXN])
  useEffect(() => {
    // lây danh sách dịch vụ khám của nhóm chỉ định đầu tiên
    if (groupsChiDinh && groupsChiDinh.length !== 0) {
      if (valueSegmented === KB) {
        dispatch(fetchListChiDinhKham_Action(groupsChiDinh[0].idNhom, branch))
      }
      if (valueSegmented === XN) {
        // dispatch(fetchListChiDinhXetNghiem_Action(groupsChiDinh[0].idnhomxn, branch))
      }
      if (valueSegmented === CĐHA) {
        // dispatch(fetchListChiDinhCĐHA_Action(groupsChiDinh[0].idnhomcdha))
      }
    }
  }, [groupsChiDinh])

  const columnsKB = [
    {
      title: 'Dịch vụ',
      dataIndex: 'dv',
      key: 'dv',
      ...getColumnSearchProps('dv'),
      sorter: {
        compare: (a, b) => {
          return (a?.dv ?? '').localeCompare(b?.dv ?? '')
        },
      },
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      width: 150,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (Number(a?.dongia) ?? 0) - (Number(b?.dongia) ?? 0)
        },
      },
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
    },
  ]
  const columns = [
    {
      title: 'Dịch vụ',
      dataIndex: 'dv',
      key: 'dv',
      sorter: {
        compare: (a, b) => {
          return (a?.dv ?? '').localeCompare(b?.dv ?? '')
        },
      },
      ...getColumnSearchProps('dv'),
    },
    {
      title: 'Nhóm',
      dataIndex: 'tennhom',
      key: 'tennhom',
      width: 250,
      align: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tennhom ?? '').localeCompare(b?.tennhom ?? '')
        },
      },
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      width: 150,
      align: 'right',
      sorter: {
        compare: (a, b) => {
          return (Number(a?.dongia) ?? 0) - (Number(b?.dongia) ?? 0)
        },
      },
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
    },
  ]
  // const columns = [
  //   {
  //     title: 'Dịch vụ',
  //     dataIndex: 'dv',
  //     key: 'dv',
  //     ...getColumnSearchProps('dv'),
  //   },
  //   {
  //     title: 'Giá',
  //     dataIndex: 'price',
  //     key: 'price',
  //     width: 90,
  //     align: 'right',
  //   },
  //   {
  //     dataIndex: 'action',
  //     key: 'action',
  //     width: 50,
  //     align: 'center',
  //   },
  // ]
  return (
    <div className="flex flex-col gap-5">
      {contextHolder}
      <Segmented onChange={handleChangeSegmented} options={[KB, XN, CĐHA]} block />
      <div className="flex">
        {valueSegmented === KB && (
          <div className="w-1/4 max-h-60 overflow-auto">
            <Menu
              onClick={handleMenu}
              mode="inline"
              defaultSelectedKeys={['1']}
              items={groupsChiDinh?.map((items) => ({
                label: items?.tenNhom,
                key: items?.idNhom,
              }))}
            />
            {/* <Menu
          onClick={handleMenu}
          mode="inline"
          defaultSelectedKeys={['1']}
          items={
            valueSegmented === KB
              ? groupsChiDinh?.map((items) => ({
                label: items?.tenNhom,
                key: items?.idNhom,
              }))
              : valueSegmented === XN
                ? groupsChiDinh?.map((items) => ({
                  label: items?.tennhom,
                  key: items?.idnhomxn,
                }))
                : groupsChiDinh?.map((items) => ({
                  label: items?.tennhom,
                  key: items?.idnhomcdha,
                }))
          }
        /> */}
          </div>
        )}
        {/* <div className="w-1/4 max-h-60 overflow-auto">
          <Menu
            className=""
            onClick={handleMenu}
            mode="inline"
            items={
              valueSegmented === KB
                ? groupsChiDinh?.map((items) => ({
                  label: items?.tenNhom,
                  key: items?.idNhom,
                }))
                : valueSegmented === XN
                  ? groupsChiDinh?.map((items) => ({
                    label: items?.tennhom,
                    key: items?.idnhomxn,
                  }))
                  : groupsChiDinh?.map((items) => ({
                    label: items?.tennhom,
                    key: items?.idnhomcdha,
                  }))
            }
          />
        </div> */}
        <div className={valueSegmented === KB ? 'w-3/4' : 'w-full'}>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              scroll={{
                y: 205,
              }}
              pagination={false}
              bordered
              columns={valueSegmented === KB ? columnsKB : columns}
              dataSource={ChiDinh?.map((items) => ({
                dv: items.tenChiDinh,
                price: formatNumberVND(items.donGia ?? items.dongia ?? 0),
                dongia: items.donGia ?? items.dongia ?? 0,
                tennhom: (items?.tennhom ?? items?.tenNhom)
                  ?.toLowerCase()
                  ?.replace('xét nghiệm', '')
                  ?.trim()
                  .toUpperCase(),
                action: !checkDelete && (
                  <PlusOutlined
                    disabled
                    // onClick={() => {
                    //   onClickChiDinh(items.idChiDinh, items.donGia ?? items.dongia)
                    // }}
                    onClick={() => {
                      onClickChonChiDinh(items)
                    }}
                    className="text-blue-500 cursor-pointer"
                  />
                ),
              }))}
              className="w-full"
            />
            {/* <Table
              scroll={{
                y: 205,
              }}
              pagination={false}
              bordered
              columns={columns}
              dataSource={ChiDinh?.map((items) => ({
                dv: items.tenChiDinh,
                price: formatNumberVND(items.donGia ?? items.dongia ?? 0),
                action: (
                  <PlusOutlined
                    onClick={() => {
                      onClickChonChiDinh(items)
                    }}
                    className="text-blue-500 cursor-pointer"
                  />
                ),
              }))}
              className="w-full"
            /> */}
          </ConfigProvider>
        </div>
      </div>
    </div>
  )
}

export default ServiceKham
