export const countTamCaNguyet = (data) => {
  if (!Array.isArray(data)) {
    throw new Error('Dữ liệu không hợp lệ. Vui lòng cung cấp một mảng.')
  }

  // Hàm phụ để xác định tam cá nguyệt
  const getTamCaNguyet = (tuanthai, ngaythai) => {
    if (tuanthai < 0 || ngaythai < 0 ) {
      return null // Bỏ qua các giá trị không hợp lệ
    }
    tuanthai = tuanthai ?? 0
    ngaythai = ngaythai ?? 0
    let adjustedTuanThai = tuanthai + (ngaythai > 1 ? 1 : 0)

    if (adjustedTuanThai >= 0 && adjustedTuanThai <= 13) {
      return 'TamCaNguyet1'
    } else if (adjustedTuanThai >= 14 && adjustedTuanThai <= 27) {
      return 'TamCaNguyet2'
    } else if (adjustedTuanThai >= 28) {
      return 'TamCaNguyet3'
    } else {
      return null
    }
  }

  // Đếm số lượng
  const counts = {
    TamCaNguyet1: 0,
    TamCaNguyet2: 0,
    TamCaNguyet3: 0,
  }

  data.forEach(({ tuanthai, ngaythai }) => {
    const tamCaNguyet = getTamCaNguyet(tuanthai, ngaythai)
    if (tamCaNguyet && counts[tamCaNguyet] !== undefined) {
      counts[tamCaNguyet] += 1
    }
  })

  // Trả về đối tượng với các phương thức
  return {
    TamCaNguyet1: () => counts.TamCaNguyet1,
    TamCaNguyet2: () => counts.TamCaNguyet2,
    TamCaNguyet3: () => counts.TamCaNguyet3,
  }
}
