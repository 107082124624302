import { Button, Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateAccountSMS } from '../../../../../store/actions/smsAction'
import * as Yup from 'yup'

const EditAccount = ({ open, setOpen }) => {
  const { infoAccount } = useSelector((state) => state.smsReducer)
  const dispatch = useDispatch()
  const handleSubmit = (values) => {
    dispatch(updateAccountSMS(values))
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idtaikhoan: infoAccount?.idtaikhoan,
      taikhoan: infoAccount?.taikhoan,
      matkhau: infoAccount?.matkhau,
      brandname: infoAccount?.brandname,
      ghichu: infoAccount?.ghichu,
    },
    onSubmit: (values) => handleSubmit(values),
    validationSchema: Yup.object().shape({
      taikhoan: Yup.string().required('Vui lòng nhập tài khoản'),
      matkhau: Yup.string().required('Vui lòng nhập mật khẩu'),
      brandname: Yup.string().required('Vui lòng nhập BrandName'),
    }),
  })
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title="Chỉnh sửa tài khoản SMS"
      footer={null}
    >
      <form action="" className="flex flex-col gap-4">
        <div>
          <label htmlFor="">
            <span className="text-red-500">*</span> Tài khoản:
          </label>
          <Input
            status={formik.errors.taikhoan ? 'error' : 'success'}
            type="text"
            value={formik.values.taikhoan}
            onChange={formik.handleChange}
            name="taikhoan"
          />
        </div>
        <div>
          <label htmlFor="">
            <span className="text-red-500">*</span> Mật khẩu:
          </label>
          <Input
            status={formik.errors.matkhau ? 'error' : 'success'}
            type="text"
            value={formik.values.matkhau}
            onChange={formik.handleChange}
            name="matkhau"
          />
        </div>
        <div>
          <label htmlFor="">
            <span className="text-red-500">*</span> BrandName:
          </label>
          <Input
            status={formik.errors.brandname ? 'error' : 'success'}
            type="text"
            value={formik.values.brandname}
            onChange={formik.handleChange}
            name="brandname"
          />
        </div>
        <div>
          <label htmlFor="">Ghi chú:</label>
          <Input
            type="text"
            value={formik.values.ghichu}
            onChange={formik.handleChange}
            name="ghichu"
          />
        </div>
        <div className="flex justify-end">
          <Button type="primary" htmlType="submit" onClick={formik.handleSubmit}>
            Cập nhật
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default EditAccount
