import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Table, ConfigProvider, Button, DatePicker, Select } from 'antd'
import { FileExcelOutlined, FileTextOutlined, SyncOutlined } from '@ant-design/icons'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
import { khoDieuChinhService } from '../../../../../services/khodieuchinh/khoDieuChinhService'
import { branchService } from '../../../../../services/branch/branchService'
import { companyService } from '../../../../../services/Company/companyService'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { putCheckQuyenThaoTacAction } from '../../../../../store/actions/phanQuyenAction'
import { addLog } from '../../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../../utils/log/logConstant'
import { exportExcelformat } from '../../../../../utils/export/exportExcelFormat'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'

function ListHangHoa({ keyTabs }) {
  const dispatch = useDispatch()
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [isLoading, setIsLoading] = useState(false)
  const [idKhoCn, setIdKhoCn] = useState(0)
  const [keyword, setKeyword] = useState(null)
  const [listVTHH, setListVTHH] = useState([])
  const [listCongTy, setListCongTy] = useState([])
  const [idCongTy, setIdCongTy] = useState(ID_COMPANY)
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [idChiNhanh, setIdChiNhanh] = useState(ID_BRANCH)

  const maQuyenXemDs = 'QHT228'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        getListVTHHDaXuat()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      default:
        return false
    }
  }
  const getListChiNhanhByIdCty = async (idct) => {
    try {
      setIsLoading(true)
      const { data: arrChiNhanh } = await branchService.getListBranch(idct)
      setListChiNhanh(arrChiNhanh)
    } catch (error) {
      console.log(error)
      setListChiNhanh([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        const { data: arrCongTy } = await companyService.getAllListCompany()
        setListCongTy(arrCongTy)
        getListChiNhanhByIdCty(idCongTy)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  // show delete phiếu thu

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const onLoad = () => {
    setKeyword((prev) => (prev?.length ? prev?.trim() : prev))
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  const getListVTHHDaXuat = async () => {
    try {
      setIsLoading(true)
      const { data } = await khoDieuChinhService.getListVTHHDaXuat(
        keyword?.trim()?.length ? keyword?.trim() : null,
        idCongTy,
        idChiNhanh === 0 ? null : idChiNhanh,
        dateForm,
        dateTo,
      )
      setListVTHH(data)
      const nameCompany = listCongTy?.find((item) => item?.idct === idCongTy)?.tenct
      const nameChiNhanh = !idChiNhanh
        ? 'Tất cả'
        : listChiNhanh?.find((item) => item?.idChiNhanh === idChiNhanh)?.tenChiNhanh
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_DS_HANG_HOA_DIEU_CHINH_TON_KHO,
        true,
        MENU_CONSTANTS.DIEU_CHINH_TON_KHO,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          keyword: keyword ?? '',
          nameCompany,
          nameChiNhanh,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.XUAT_SU_DUNG,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (listCongTy.length && listChiNhanh.length) {
      onLoad()
    }
  }, [idKhoCn, keyword, idCongTy, idChiNhanh, dateForm, dateTo, listCongTy, listChiNhanh])

  useEffect(() => {
    if (keyTabs === 3 && listCongTy.length && listChiNhanh.length) {
      onLoad()
    }
  }, [keyTabs, listCongTy, listChiNhanh])

  console.log('listVTHH', listVTHH)

  const handleExportExcel = () => {
    // Transform listVTHH data into export format
    const exportData = listVTHH.map((item, index) => ({
      STT: index + 1,
      'Ngày điều chỉnh': moment(item.ngaytao).format('DD/MM/YYYY HH:ss:mm'),
      'Tên Chi Nhánh': item.tenchinhanh || '',
      'Mã Phiếu điều chỉnh': item.maphieu || '',
      'Kho hàng': item.tenkho || '',
      'Mã thuốc': item.mathuoc || '',
      'Tên biệt dược': item.tenbietduoc || '',
      Tồn: formattedNumber(item.soluong) || 0,
      'Chênh lệch': formattedNumber(item.chenhlech) || 0,
      'Thực tế': formattedNumber(item.soluong) || 0,
      'Đơn vị': item.dvt || '',
      'Số lô': item.solo || '',
      'Hạn dùng': moment(item.handung).format('DD/MM/YYYY'),
      'Số hóa đơn': item.sohoadon || '',
    }))

    // Call export utility with number formatting columns
    exportExcelformat(
      exportData,
      'Danh_sach_dieu_chinh_ton_kho',
      ['Tồn', 'Chênh lệch', 'Thực tế'], // Columns that need number formatting
    )
  }
  return (
    <div>
      <div className="flex justify-start items-center mb-2 gap-2">
        <div className="flex justify-start items-center w-[17rem] gap-1">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-2xl flex items-center">-</span>
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        {/* <Select
          className="w-60"
          options={[
            {
              key: 0,
              value: 0,
              label: 'Tất cả các kho',
            },
            ...listKho?.map((item) => ({
              key: item?.idKhoCN,
              value: item?.idKhoCN,
              label: item?.tenKho,
            })),
          ]}
          value={idKhoCn}
          onChange={(value) => setIdKhoCn(value)}
        /> */}
        {infoUser?.idNhom !== 3 && (
          <>
            <Select
              disabled={isLoading}
              className="w-[17rem]"
              options={listCongTy?.map((item) => ({
                key: `${item?.mact}${item?.idct}`,
                value: item?.idct,
                label: item?.tenct,
              }))}
              value={idCongTy}
              onChange={(value) => {
                setIdCongTy(value)
                setIdChiNhanh(0)
                getListChiNhanhByIdCty(value)
              }}
            />
            <Select
              disabled={isLoading}
              className="w-[17rem]"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  key: `chinhanh0`,
                  value: 0,
                  label: 'Tất cả chi nhánh',
                },
                ...listChiNhanh?.map((item) => ({
                  key: `chinhanh${item?.idChiNhanh}`,
                  value: item?.idChiNhanh,
                  label: item?.tenChiNhanh,
                })),
              ]}
              value={idChiNhanh}
              onChange={(value) => {
                setIdChiNhanh(value)
              }}
            />
          </>
        )}
        <Input
          placeholder="Nhập từ khóa"
          className="w-[19rem]"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Button
          onClick={onLoad}
          loading={isLoading}
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
        ></Button>

        <Button
          onClick={handleExportExcel}
          loading={isLoading}
          type="primary"
          icon={<FileExcelOutlined />}
        >
          Xuất Excel
        </Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 3,
            fontSize: 13,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          scroll={{ x: 2000, y: 695 }}
          pagination={false}
          loading={isLoading}
          bordered
          dataSource={listVTHH}
          columns={[
            {
              key: 1,
              title: 'STT',
              dataIndex: 'STT',
              width: 50,
              align: 'center',
              fixed: 'left',
              render: (text, record, index) => index + 1,
            },
            {
              key: 4,
              title: 'Ngày điều chỉnh',
              dataIndex: 'ngaytao',
              width: 130,
              fixed: 'left',
              align: 'center',
              render: (text, record, index) => moment(text).format('DD/MM/YYYY HH:ss:mm'),
            },
            {
              key: 2,
              title: 'Tên Chi Nhánh',
              dataIndex: 'tenchinhanh',
              width: 200,
              fixed: 'left',
            },
            {
              key: 3,
              title: 'Mã phiếu điều chỉnh',
              dataIndex: 'maphieu',
              width: 130,
              fixed: 'left',
              render: (text, record, index) => (
                <div className="flex justify-center items-center gap-1">{text}</div>
              ),
            },
            {
              key: 5,
              title: 'Kho hàng',
              dataIndex: 'tenkho',
              width: 200,
            },
            {
              key: 6,
              title: 'Mã thuốc',
              dataIndex: 'mathuoc',
              width: 100,
            },
            {
              key: 7,
              title: 'Tên biệt dược',
              dataIndex: 'tenbietduoc',
            },
            {
              key: 13,
              title: 'Tồn',
              dataIndex: 'soluong',
              width: 100,
              align: 'center',
            },
            {
              key: 8,
              title: 'Chênh lệch',
              dataIndex: 'chenhlech',
              width: 100,
              align: 'center',
              render: (text, record, index) =>
                text < 0 ? (
                  <p className="text-red-500 font-semibold">{formattedNumber(text)}</p>
                ) : (
                  <p className="text-blue-700 font-semibold">{formattedNumber(text)}</p>
                ),
            },
            {
              key: 13,
              title: 'Thực tế',
              dataIndex: 'dvt',
              width: 100,
              align: 'center',
              render: (text, record, index) => record.soluong + record.chenhlech,
            },
            {
              key: 9,
              title: 'Đơn vị',
              dataIndex: 'dvt',
              width: 100,
              align: 'center',
            },

            {
              key: 10,
              title: 'Số lô',
              dataIndex: 'solo',
              width: 100,
              align: 'center',
            },
            {
              key: 11,
              title: 'Hạn dùng',
              dataIndex: 'handung',
              width: 100,
              align: 'center',
              render: (text, record, index) => moment(text).format('DD/MM/YYYY'),
            },
            {
              key: 12,
              title: 'Số hóa đơn',
              dataIndex: 'sohoadon',
              width: 100,
              align: 'center',
            },
          ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default ListHangHoa
