import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import {
  Button,
  ConfigProvider,
  Input,
  notification,
  Table,
  Tooltip,
  Modal,
  Select,
  Space,
  Checkbox,
  Spin,
  Popconfirm,
} from 'antd'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { tiemChungService } from '../../../../services/tiemChung/tiemChungService'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import * as typeAction from '../../../../store/constants/constants'
import { SearchOutlined, PlusOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import _, { cloneDeep } from 'lodash'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import moment from 'moment'
import ToastCus from '../../../../utils/Toast'
import 'moment/locale/vi'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
import { formatPayloadDetails } from '../../../../utils/algorithm'
moment.locale('vi')
const { confirm } = Modal
function KhoangCach() {
  const dispatch = useDispatch()
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isLoading, setIsLoading] = useState(false)
  const [listNhomVacXin, setListNhomVacXin] = useState([])
  const [listVacXin, setListVacXin] = useState([])
  const [listVacXinTuongTac, setListVacXinTuongTac] = useState([])
  const [listVacXinKhoangCach, setListVacXinKhoangCach] = useState([])
  const [listCanhBaoKhoangCach, setListCanhBaoKhoangCach] = useState([])
  const [nhomVX, setNhomVX] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [isModal, setIsModal] = useState(false)
  const [vacXinEdit, setVacXinEdit] = useState(null)
  const [api, contextHolder] = notification.useNotification()
  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isLoadingAction, setisLoadingAction] = useState(false)

  //############# FORM ############
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // idcbkc: 0,
      idthuoc: null,
      mathuoc: '',
      tenthuoc: '',
      tenhoatchat: '',
      dvt: '',
      ngaycachnhau: 0,
      nhasanxuat: '',
      masodangky: '',
      ghichu: '',
      tennhomthuoc: '', //////////////////////
      // nguoitao: 0,
      // nguoisua: 0,
    },
    onSubmit: (value) => handleSubmit(value),
    // validationSchema: receiveSchema,
  })
  const maQuyenXemDs = 'QHT582'
  const maQuyenXemCt = 'QHT583'
  const maQuyenCapNhat = 'QHT584'
  const maQuyenThem = 'QHT581'
  const maQuyenXoa = 'QHT651'
  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        getListCanhBaoKhoangCach()
        break
      case maQuyenXemCt:
        setIsModal(true)
        setVacXinEdit(infoNew)
        getListVacXinKhoangCach(infoNew?.idthuoc)
        getInfoCanhBaoKhoangCach(infoNew?.idcbkc)
        break
      case maQuyenCapNhat:
        handleSubmitEdit()
        break
      case maQuyenThem:
        formik.handleSubmit()
        break
      case maQuyenXoa:
        handleDelete(infoNew)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        setListCanhBaoKhoangCach([])
        break
      case maQuyenXemCt:
        setIsModal(false)
        setVacXinEdit(null)
        break
      case maQuyenCapNhat:
        break
      case maQuyenThem:
        break
      case maQuyenXoa:
        break
      default:
        return false
    }
  }

  const resetForm = () => {
    setListVacXinTuongTac([])
    setListVacXinKhoangCach([])
    formik.resetForm()
    setVacXinEdit(null)
  }
  const handleSubmit = async (value) => {
    if (!value?.idthuoc) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn vắc xin cần cảnh báo!',
      })
      return
    }
    console.log(listVacXinTuongTac)

    try {
      setisLoadingAction(true)
      await tiemChungService.postInfoCanhBaoKhoangCach({
        ...value,
        nguoitao: infoUser?.idnv,
        canhBaoKhoangCachChiTiets: listVacXinTuongTac?.map((item) => ({
          idthuocchinh: value?.idthuoc,
          idthuocphu: item?.idThuoc,

          ngaycachnhau: item?.ngaycachnhau,
          tutuoi: item?.tutuoi,
          dentuoi: item?.dentuoi,
          duoctiemchung: item?.duoctiemchung,

          nguoitao: infoUser?.idnv,
        })),
      })
      cancelModal()
      resetForm()
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        logAction.ADD_CANH_BAO_KHOANG_CACH,
        true,
        MENU_CONSTANTS.CANH_BAO,
        null,
        {
          mathuoc: value?.mathuoc,
          nhomthuoc: value?.tennhomthuoc,
          tenthuoc: value?.tenthuoc,
          vacxintuongtac: listVacXinTuongTac
            .map(
              (item) =>
                `${item.maThuoc} - ${item.tenBietDuoc} - cách: ${item.ngaycachnhau} ngày - từ tuổi ${item.tutuoi} tháng đến tuổi ${item.dentuoi} tháng -  ${item.duoctiemchung ? 'Được tiêm chủng' : 'Không được tiêm chủng'}`,
            )
            .join('\n'),
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      setisLoadingAction(false)
      cancelModal()
    }
  }
  const handleDelete = async (info) => {
    try {
      setIsLoading(true)
      await tiemChungService.deleteInfoCanhBaoKhoangCachByIdThuoc(info?.idcbkc)
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (error) {
      console.log('handleDelete : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }
  const handleSubmitEdit = async () => {
    if (!formik.values || !formik.values?.idcbkc || !formik.values?.idthuoc) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn lại vắc xin cần cảnh báo!',
      })
      return
    }
    try {
      setisLoadingAction(true)
      await tiemChungService.putInfoCanhBaoKhoangCach({
        ...formik.values,
        nguoisua: infoUser?.idnv,
        canhBaoKhoangCachChiTiets: listVacXinTuongTac?.map((item) => ({
          idcbkc: formik.values.idcbkc,
          idthuocchinh: formik.values?.idthuoc,
          idthuocphu: item?.idThuoc,
          ngaycachnhau: item?.ngaycachnhau,
          tutuoi: item?.tutuoi,
          dentuoi: item?.dentuoi,
          duoctiemchung: item?.duoctiemchung,
          nguoitao: item?.nguoitao ?? null,
          nguoisua: infoUser?.idnv,
        })),
      })
      cancelModal()
      resetForm()
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        logAction.UPDATE_CANH_BAO_KHOANG_CACH,
        true,
        MENU_CONSTANTS.CANH_BAO,
        null,
        {
          mathuoc: formik.values?.mathuoc,
          nhomthuoc: formik.values?.tennhomthuoc,
          tenthuoc: formik.values?.tenthuoc,
          vacxintuongtac: listVacXinTuongTac
            .map(
              (item) =>
                `${item.maThuoc} - ${item.tenBietDuoc} - cách: ${item.ngaycachnhau} ngày - từ tuổi ${item.tutuoi} tháng đến tuổi ${item.dentuoi} tháng -  ${item.duoctiemchung ? 'Được tiêm chủng' : 'Không được tiêm chủng'}`,
            )
            .join('\n'),
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails({
        ...formik.values,
        nguoisua: infoUser?.idnv,
        canhBaoKhoangCachChiTiets: listVacXinTuongTac?.map((item) => ({
          idcbkc: formik.values.idcbkc,
          idthuocchinh: formik.values?.idthuoc,
          idthuocphu: item?.idThuoc,
          ngaycachnhau: item?.ngaycachnhau,
          tutuoi: item?.tutuoi,
          dentuoi: item?.dentuoi,
          duoctiemchung: item?.duoctiemchung,
          nguoitao: item?.nguoitao ?? null,
          nguoisua: infoUser?.idnv,
        })),
      })} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.CANH_BAO,
        detailErrorPayload,
      )
    } finally {
      setisLoadingAction(false)
      cancelModal()
    }
  }
  const handleAddVacXinTuongTac = (infoThuoc) => {
    const index = listVacXinTuongTac?.findIndex((item) => item?.idThuoc === infoThuoc?.idThuoc)
    if (index !== -1) {
      openNotification()
      return
    }
    setListVacXinTuongTac((prev) => [
      ...prev,
      { ...infoThuoc, ngaycachnhau: 0, tutuoi: 0, dentuoi: 0, duoctiemchung: 0 },
    ])
  }
  const handleDeleteVacXinTuongTac = (infoThuoc) => {
    setListVacXinTuongTac((prev) => prev?.filter((item) => item?.idThuoc !== infoThuoc?.idThuoc))
  }
  const handleChangeVacXinTuongTac = (name, value, infoThuoc) => {
    setListVacXinTuongTac((prev) =>
      prev?.map((item) =>
        item?.idThuoc === infoThuoc?.idThuoc ? { ...item, [name]: value } : { ...item },
      ),
    )
  }
  const handleVacXin = async (value, opt) => {
    try {
      const { data } = await tiemChungService.getInfoCanhBaoKhoangCachByIdThuoc(
        opt?.itemVX?.idThuoc,
      )
      if (data?.idcbkc) {
        ToastCus.fire({
          icon: 'error',
          title: 'Vắc xin này đã có cảnh báo!',
        })
        return
      }
      formik.setValues((prev) => ({
        ...prev,
        idthuoc: opt?.itemVX?.idThuoc,
        mathuoc: opt?.maThuoc,
        tenthuoc: opt?.tenBietDuoc,
        tenhoatchat: opt?.label,
        dvt: opt?.itemVX?.dvt,
        nhasanxuat: opt?.itemVX?.nhaSanXuat,
        masodangky: opt?.soDangKy,
        tennhomthuoc: opt?.itemVX?.nhom,
      }))
      getListVacXinKhoangCach(opt?.itemVX?.idThuoc) //vacXinEdit?.idthuoc
      setListVacXinTuongTac([])
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
      console.log(error)
    }
  }
  const cancelModal = () => {
    if (!isLoadingAction) {
      setIsModal(false)
      setVacXinEdit(null)
    }
  }
  const getListCanhBaoKhoangCach = async () => {
    try {
      setIsLoading(true)
      const { data } = await tiemChungService.getListCanhBaoKhoangCach(
        keyword?.trim()?.length > 0 ? keyword?.trim() : null,
        nhomVX,
      ) //loại vắc xin = 8
      setListCanhBaoKhoangCach(data)
      const nameNhomVX = !nhomVX
        ? 'Tất cả'
        : listNhomVacXin?.find((item) => item?.idNhom === nhomVX)?.nhom
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_DS_CANH_BAO_KHOANG_CACH,
        true,
        MENU_CONSTANTS.CANH_BAO,
        null,
        {
          keyword: keyword ?? '',
          nameNhomVX,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.CANH_BAO,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }
  const getListNhomVacXin = async () => {
    try {
      setIsLoading(true)
      const { data } = await khoVTservice.getListGroupsThuocVT(8) //loại vắc xin = 8
      setListNhomVacXin(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getListVacXin = async (kw) => {
    try {
      const { data } = await khoVTservice.SearchThuocCanhBao(kw, ID_COMPANY, 8, null)
      setListVacXin(data)
      // keyword,
      // idCT,
      // idPhanLoai,
      // idNhom,
      // pageIndex, //loại vắc xin = 8
    } catch (error) {
      console.log(error)
    }
  }
  const getListVacXinKhoangCach = async (idthuoc) => {
    try {
      setIsLoading(true)
      const { data } = await khoVTservice.SearchThuocCanhBao('', ID_COMPANY, 8, null) //get all
      setListVacXinKhoangCach(data?.filter((item) => item?.idThuoc !== idthuoc))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const getInfoCanhBaoKhoangCach = async (idcbkc) => {
    try {
      setIsLoading(true)
      const { data } = await tiemChungService.getInfoCanhBaoKhoangCach(idcbkc)
      setListVacXinTuongTac((prev) =>
        data?.length
          ? data[0]?.DanhSachThuoc?.map((item) => ({
              ...item,
              idThuoc: item?.idthuoc,
              maThuoc: item?.mathuoc,
              tenBietDuoc: item?.tenbietduoc,
            }))
          : [],
      )
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        getListVacXin(keyword?.trim()?.replaceAll('  ', ' '))
      }
    }, 300),
    [],
  )
  const onLoad = () => {
    // getListCanhBaoKhoangCach()
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const openNotification = () => {
    api.warning({
      message: `Chọn vắc xin`,
      description: 'Vắc xin đã được chọn!',
      placement: 'topRight',
    })
  }
  useEffect(() => {
    if (vacXinEdit) {
      formik.setValues((prev) => ({
        ...prev,
        idcbkc: vacXinEdit?.idcbkc,
        idthuoc: vacXinEdit?.idthuoc,
        mathuoc: vacXinEdit?.mathuoc,
        tenthuoc: vacXinEdit?.tenthuoc,
        tenhoatchat: vacXinEdit?.tenhoatchat,
        dvt: vacXinEdit?.dvt,

        ngaycachnhau: vacXinEdit?.ngaycachnhau,

        nhasanxuat: vacXinEdit?.nhasanxuat,
        masodangky: vacXinEdit?.masodangky,
        ghichu: vacXinEdit?.ghichu,
        tennhomthuoc: vacXinEdit?.nhom,
      }))
    } else formik.resetForm()
  }, [vacXinEdit])
  useEffect(() => {
    getListNhomVacXin()
    onLoad()
  }, [])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 45,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã thuốc',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      width: 120,
      fixed: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.mathuoc ?? '').localeCompare(b?.mathuoc ?? '')
        },
      },
    },
    {
      title: 'Tên thuốc',
      dataIndex: 'tenthuoc',
      key: 'tenthuoc',
      width: 350,
      fixed: 'left',
      sorter: {
        compare: (a, b) => {
          return (a?.tenthuoc ?? '').localeCompare(b?.tenthuoc ?? '')
        },
      },
    },
    {
      title: 'Tên hoạt chất',
      dataIndex: 'tenhoatchat',
      key: 'tenhoatchat',
      width: 450,
      sorter: {
        compare: (a, b) => {
          return (a?.tenhoatchat ?? '').localeCompare(b?.tenhoatchat ?? '')
        },
      },
    },
    {
      title: 'ĐVT',
      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      width: 60,
      sorter: {
        compare: (a, b) => {
          return (a?.dvt ?? '').localeCompare(b?.dvt ?? '')
        },
      },
    },
    // {
    //   title: 'Nhóm',
    //   dataIndex: 'tieude',
    //   key: 'tieude',
    //   width: 150,
    // },
    {
      title: 'Nhà sản xuất',
      dataIndex: 'nhasanxuat',
      key: 'nhasanxuat',
      width: 270,
      sorter: {
        compare: (a, b) => {
          return (a?.nhasanxuat ?? '').localeCompare(b?.nhasanxuat ?? '')
        },
      },
    },
    {
      title: 'Số đăng ký',
      dataIndex: 'masodangky',
      key: 'masodangky',
      width: 170,
      sorter: {
        compare: (a, b) => {
          return (a?.masodangky ?? '').localeCompare(b?.masodangky ?? '')
        },
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoitao',
      key: 'nguoitao',
      width: 220,
      sorter: {
        compare: (a, b) => {
          return (a?.nguoitao ?? '').localeCompare(b?.nguoitao ?? '')
        },
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaytao ?? '').localeCompare(b?.ngaytao ?? '')
        },
      },
    },
    {
      title: 'Người sửa',
      dataIndex: 'nguoisua',
      key: 'nguoisua',
      width: 220,
      sorter: {
        compare: (a, b) => {
          return (a?.nguoisua ?? '').localeCompare(b?.nguoisua ?? '')
        },
      },
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaysua',
      key: 'ngaysua',
      width: 140,
      align: 'center',
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaysua ?? '').localeCompare(b?.ngaysua ?? '')
        },
      },
    },
    // {
    //   title: 'Ngày cách nhau',
    //   dataIndex: 'ngaycachnhau',
    //   key: 'ngaycachnhau',
    //   width: 130,
    //   align: 'center',
    //   fixed: 'right',
    //   sorter: {
    //     compare: (a, b) => {
    //       return (a?.ngaycachnhau ?? 0) - (b?.ngaycachnhau ?? 0)
    //     },
    //   },
    // },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 70,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => (
        <ul className="flex justify-center items-center gap-2">
          <li className="flex justify-center items-center">
            <Tooltip title="Xóa" color="red">
              <Popconfirm
                placement="topRight"
                okText="Xác nhận"
                cancelText="Hủy bỏ"
                title={'Xóa cảnh báo?'}
                onConfirm={() => {
                  dispatch(
                    putCheckQuyenThaoTacAction(maQuyenXoa, record, actionIsTrue, actionIsFalse),
                  )
                }}
                description={
                  <>
                    <p>
                      Bạn xác nhận muốn&nbsp;
                      <span className="font-semibold">XÓA</span>
                      &nbsp;cảnh báo của vắc xin:
                    </p>
                    <p className="font-semibold">
                      {record?.mathuoc} - {record?.tenthuoc}
                    </p>
                  </>
                }
              >
                <CloseOutlined className="text-xl text-red-500  cursor-pointer" />
              </Popconfirm>
            </Tooltip>
          </li>
          <li className="flex justify-center items-center">
            <Tooltip title="Chỉnh sửa" color="green">
              <EditOutlined
                onClick={() => {
                  dispatch(
                    putCheckQuyenThaoTacAction(maQuyenXemCt, record, actionIsTrue, actionIsFalse),
                  )
                }}
                className="text-xl text-green-500  cursor-pointer"
              />
            </Tooltip>
          </li>
        </ul>
      ),
    },
  ]
  const columnsVX = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 45,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã thuốc',
      dataIndex: 'maThuoc',
      key: 'maThuoc',
      width: 120,
      ...getColumnSearchProps('maThuoc'),
      render: (text, record, index) => text && text?.toUpperCase(),
      sorter: {
        compare: (a, b) => {
          return (a?.maThuoc ?? '').localeCompare(b?.maThuoc ?? '')
        },
      },
    },
    {
      title: 'Tên thuốc',
      dataIndex: 'tenBietDuoc',
      key: 'tenBietDuoc',
      ...getColumnSearchProps('tenBietDuoc'),
      sorter: {
        compare: (a, b) => {
          return (a?.tenBietDuoc ?? '').localeCompare(b?.tenBietDuoc ?? '')
        },
      },
    },
  ]
  return (
    <div>
      {contextHolder}
      <Modal
        width={950}
        open={isModal}
        onCancel={cancelModal}
        // okText={'Lưu'}
        // onOk={vacXinEdit ? handleSubmitEdit : formik.handleSubmit}
        // cancelText="Huỷ bỏ"
        title={
          <p className="text-center">{vacXinEdit ? 'Chỉnh sửa' : 'Thêm'} cảnh báo khoảng cách</p>
        }
        className="flex flex-col gap-2"
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button onClick={cancelModal} loading={isLoadingAction}>
              Hủy bỏ
            </Button>
            <Button
              type="primary"
              // onClick={vacXinEdit ? handleSubmitEdit : formik.handleSubmit}
              onClick={() =>
                dispatch(
                  putCheckQuyenThaoTacAction(
                    vacXinEdit ? maQuyenCapNhat : maQuyenThem,
                    null,
                    actionIsTrue,
                    actionIsFalse,
                  ),
                )
              }
              loading={isLoadingAction}
            >
              Lưu
            </Button>
          </>
        )}
      >
        <Spin spinning={isLoadingAction}>
          <div className="grid grid-flow-row">
            {!vacXinEdit && (
              <Select
                placeholder="Nhập tên vắc xin..."
                className="w-full"
                showSearch
                allowClear
                onSearch={debounceDropDown}
                value={null}
                filterOption={false}
                notFoundContent={null}
                suffixIcon={<SearchOutlined className=" " />}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={handleVacXin}
                options={listVacXin?.map((item) => ({
                  key: `` + item?.idThuoc + item?.maSoDangKy,
                  value: item?.idThuoc,
                  label: item?.tenHoatChat,
                  tenBietDuoc: item?.tenBietDuoc,
                  maThuoc: item?.maThuoc?.toUpperCase(),
                  soDangKy: item?.maSoDangKy?.toUpperCase(),
                  itemVX: item,
                }))}
                optionRender={(options) => (
                  <ul className="flex">
                    <li className="w-[60%] border-e">
                      <p className="font-semibold w-full text-pretty">{options?.data?.label}</p>
                      <span className="text-xs w-full text-gray-500">
                        ({options?.data?.tenBietDuoc})
                      </span>
                    </li>
                    <li className="w-[20%] border-e text-center flex items-center justify-center">
                      {options?.data?.maThuoc}
                    </li>
                    <li className="w-[20%] border-e text-center flex items-center justify-center">
                      {options?.data?.soDangKy}
                    </li>
                  </ul>
                )}
              />
            )}
            <div className="grid grid-flow-row gap-1 mt-3">
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label className="font-semibold">Tên thuốc:</label>
                  <Input
                    size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.tenthuoc}
                    // onChange={formik.handleChange}
                    // name="tennguoinha"
                  />
                </div>
                <div>
                  <label className="font-semibold">Mã thuốc:</label>
                  <Input
                    size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.mathuoc}
                    // onChange={formik.handleChange}
                    // name="tennguoinha"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label className="font-semibold">Tên hoạt chất:</label>
                  <Input
                    size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.tenhoatchat}
                    // onChange={formik.handleChange}
                    // name="tennguoinha"
                  />
                </div>
                <div>
                  <label className="font-semibold">Nhóm thuốc:</label>
                  <Input
                    size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.tennhomthuoc}
                    // onChange={formik.handleChange}
                    // name="tennguoinha"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label className="font-semibold">Đơn vị tính:</label>
                  <Input
                    size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.dvt}
                    // onChange={formik.handleChange}
                    // name="tennguoinha"
                  />
                </div>
                <div>
                  <label className="font-semibold">Số đăng ký:</label>
                  <Input
                    size="small"
                    readOnly
                    variant="filled"
                    value={formik.values.masodangky}
                    // onChange={formik.handleChange}
                    // name="tennguoinha"
                  />
                </div>
              </div>
              <div>
                <label className="font-semibold">Nhà sản xuất:</label>
                <Input
                  size="small"
                  readOnly
                  variant="filled"
                  value={formik.values.nhasanxuat}
                  // onChange={formik.handleChange}
                  // name="tennguoinha"
                />
              </div>
              <div className="w-[100%] h-[11.5rem] mb-[3.5rem]">
                <label className="font-semibold">Vắc xin tương tác:</label>
                <ConfigProvider
                  theme={{
                    token: {
                      padding: '3px 5px',
                    },
                    components: {
                      Table: {
                        rowHoverBg: '#ecf0f1',
                        headerBg: '#e6e6e6',
                        footerBg: '#e6e6e6',
                        borderColor: '#BABABA',
                      },
                    },
                  }}
                >
                  <Table
                    //   scroll={{ x: 1000 }}
                    //   pagination={{
                    //     pageSize: 20,
                    //     showSizeChanger: false,
                    //   }}
                    scroll={{ y: 130 }}
                    pagination={false}
                    columns={[
                      ...columnsVX,
                      {
                        title: 'Cách nhau (ngày)',
                        dataIndex: 'ngaycachnhau',
                        key: 'ngaycachnhau',
                        width: 90,
                        align: 'center',
                        render: (text, record, index) => (
                          <Input
                            size="small"
                            type="number"
                            className="w-full text-center pl-5"
                            value={record?.ngaycachnhau}
                            min={0}
                            step={1}
                            onChange={(e) => {
                              handleChangeVacXinTuongTac(
                                'ngaycachnhau',
                                e.target.value > 0 ? e.target.value : 0,
                                record,
                              )
                            }}
                          />
                        ),
                      },
                      {
                        title: 'Từ tuổi (tháng)',
                        dataIndex: 'tutuoi',
                        key: 'tutuoi',
                        width: 90,
                        align: 'center',
                        render: (text, record, index) => (
                          <Input
                            size="small"
                            type="number"
                            className="w-full text-center pl-5"
                            value={record?.tutuoi}
                            min={0}
                            step={1}
                            onChange={(e) => {
                              handleChangeVacXinTuongTac(
                                'tutuoi',
                                e.target.value > 0 ? e.target.value : 0,
                                record,
                              )
                            }}
                          />
                        ),
                      },
                      {
                        title: 'Đến tuổi (tháng)',
                        dataIndex: 'dentuoi',
                        key: 'dentuoi',
                        width: 90,
                        align: 'center',
                        render: (text, record, index) => (
                          <Input
                            size="small"
                            type="number"
                            className="w-full text-center pl-5"
                            value={record?.dentuoi}
                            min={0}
                            step={1}
                            onChange={(e) => {
                              handleChangeVacXinTuongTac(
                                'dentuoi',
                                e.target.value > 0 ? e.target.value : 0,
                                record,
                              )
                            }}
                          />
                        ),
                      },
                      {
                        title: 'Được tiêm chủng',
                        dataIndex: 'duoctiemchung',
                        key: 'duoctiemchung',
                        width: 90,
                        align: 'center',
                        render: (text, record, index) => (
                          <Checkbox
                            checked={record?.duoctiemchung}
                            onChange={(e) => {
                              handleChangeVacXinTuongTac(
                                'duoctiemchung',
                                e.target.checked ? 1 : 0,
                                record,
                              )
                            }}
                          />
                        ),
                      },
                      {
                        title: '',
                        dataIndex: 'action',
                        key: 'action',
                        width: 45,
                        render: (text, record, index) => (
                          <ul className="flex justify-center items-center gap-2">
                            <li className="flex justify-center items-center">
                              <Tooltip title="Xóa" color="red">
                                <CloseOutlined
                                  onClick={() => {
                                    handleDeleteVacXinTuongTac(record)
                                  }}
                                  className="text-xl text-red-500  cursor-pointer"
                                />
                              </Tooltip>
                            </li>
                          </ul>
                        ),
                      },
                    ]}
                    loading={isLoading}
                    dataSource={listVacXinTuongTac}
                    bordered
                  />
                </ConfigProvider>
              </div>
              <div className="w-[100%] h-[10rem]">
                <ConfigProvider
                  theme={{
                    token: {
                      padding: '3px 5px',
                    },
                    components: {
                      Table: {
                        rowHoverBg: '#ecf0f1',
                        headerBg: '#e6e6e6',
                        footerBg: '#e6e6e6',
                        borderColor: '#BABABA',
                      },
                    },
                  }}
                >
                  <Table
                    scroll={{ y: 130 }}
                    pagination={false}
                    columns={[
                      ...columnsVX,
                      {
                        title: '',
                        dataIndex: 'action',
                        key: 'action',
                        width: 45,
                        render: (text, record, index) => (
                          <ul className="flex justify-center items-center gap-2">
                            <li className="flex justify-center items-center">
                              <Tooltip title="Thêm" color="blue">
                                <PlusOutlined
                                  onClick={() => {
                                    handleAddVacXinTuongTac(record)
                                  }}
                                  className="text-xl text-blue-500  cursor-pointer"
                                />
                              </Tooltip>
                            </li>
                          </ul>
                        ),
                      },
                    ]}
                    loading={isLoading}
                    dataSource={listVacXinKhoangCach}
                    bordered
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <div className="flex justify-between items-center mb-2">
        <div className="flex justify-start items-center gap-2">
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            placeholder="Nhóm vắc xin..."
            className="w-60"
            options={listNhomVacXin?.map((item) => ({
              key: `` + item?.idNhom + item?.idPhanLoai,
              value: item?.idNhom,
              label: item?.nhom,
            }))}
            onChange={setNhomVX}
            disabled={isLoading}
            value={nhomVX}
          />
          <Input
            placeholder="Nhập từ khóa..."
            prefix={<SearchOutlined className="pr-1" />}
            onChange={(e) => {
              setKeyword(e.target.value)
            }}
            disabled={isLoading}
            value={keyword}
            className="w-60"
          />
          <IconButton
            onClick={() => {
              setKeyword((prev) => (prev?.length > 0 ? prev?.trim() : prev))
              onLoad()
            }}
            disabled={isLoading}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        <Button
          type="primary"
          className="flex justify-center items-center"
          onClick={() => {
            resetForm()
            setIsModal(true)
          }}
        >
          <PlusOutlined />
          &nbsp;Thêm
        </Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          //   scroll={{ x: 1000 }}
          //   pagination={{
          //     pageSize: 20,
          //     showSizeChanger: false,
          //   }}
          scroll={{ y: 715 }}
          pagination={false}
          loading={isLoading}
          columns={columns}
          dataSource={listCanhBaoKhoangCach}
          bordered
        />
      </ConfigProvider>
    </div>
  )
}

export default KhoangCach
