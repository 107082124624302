import moment from 'moment'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import { cardService } from '../../services/card/cardService'
import { traCuuService } from '../../services/traCuu/traCuuService'
import * as typeAction from '../constants/constants'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import ToastCus from '../../utils/Toast'
import 'moment/locale/vi'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { https } from '../../services/apiService'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* traCuuSaga() {
  // TÌM KIẾM BỆNH NHÂN
  yield takeLatest(typeAction.SEARCH_TRACUU_BN, function* searchBN({ type, keyWord, idct }) {
    try {
      const result = yield call(() => traCuuService.searchBN(keyWord, idct))
      yield put({
        type: typeAction.DISPATCH_TRACUU_LIST_BN,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })

  yield takeLatest(
    typeAction.UPDATE_BN_TRACUU,
    function* updateBNInfo({ type, form, onLoad, idChuyenKhoa, setLoading }) {
      try {
        setLoading(true)
        const { infoUser } = yield select((state) => state.userReducer)
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT012'),
        )

        yield call(() => traCuuService.updateBN(form))
        const result = yield call(() => traCuuService.getInfoBN_TT(form.idbn))
        const paymentCard = yield call(() => cardService.getInfoPaymentCard(form.idbn))
        const memberCard = yield call(() => cardService.getInfoMemberCard(form.idbn, idChuyenKhoa))
        yield put({
          type: typeAction.DISPATCH_TRACUU_INFO_BENH_NHAN,
          payload: {
            ...result.data,
            paymentCard: paymentCard.data[0],
            memberCard: memberCard.data,
          },
        })
        const detailForLog = {
          tenBenhNhan: form.tenBenhNhan,
          cccd: form.cccd,
          diaChi: form.diaChi,
          dienThoai: form.dienThoai,
          ghiChuHoaDon: form.ghiChuHoaDon,
          gioiTinh: form.gioiTinh,
          hoaDon: form.hoaDon,
          idDanToc: form.idDanToc,
          idDoiTuong: form.idDoiTuong,
          idNgheNghiep: form.idNgheNghiep,
          idPhuong: form.idPhuong,
          idQuan: form.idQuan,
          idQuocTich: form.idQuocTich,
          idTinh: form.idTinh,
          idbn: form.idbn,
          maTCQG: form.maTCQG,
          ngayCap: form.ngayCap,
          ngaySinh: form.ngaySinh,
          nguoiSua: form.nguoiSua,
          tuoi: form.tuoi,
        }
        yield addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap?.idChiNhanh,
          actionStatus.PUT,
          logAction.CAP_NHAT_THONG_TIN_BENH_NHAN,
          true,
          MENU_CONSTANTS.TRACUU_BENH_NHAN,
          null,
          detailForLog,
        )
        onLoad()
        Toast.fire({
          icon: 'success',
          title: 'Sửa thông tin bệnh nhân thành công!',
        })
      } catch (error) {
        // yield console.log(err)
        // Toast.fire({
        //   icon: 'error',
        //   title: 'Sửa thông tin bệnh nhân thất bại!',
        // })
        setLoading(false)
        console.error('Error : ', error)
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      } finally {
        setLoading(false)
      }
    },
  )

  // GET INFO BỆNH NHÂN
  yield takeLatest(
    typeAction.GET_INFO_TRACUU_BENH_NHAN,
    function* searchBN({ type, idBN, idChuyenKhoa }) {
      try {
        const result = yield call(() => traCuuService.getInfoBN_TT(idBN))
        const paymentCard = yield call(() => cardService.getInfoPaymentCard(idBN))
        const memberCard = yield call(() => cardService.getInfoMemberCard(idBN, idChuyenKhoa))
        yield put({
          type: typeAction.DISPATCH_TRACUU_INFO_BENH_NHAN,
          payload: {
            ...result.data,
            paymentCard: paymentCard.data[0],
            memberCard: memberCard.data,
          },
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )

  yield takeLatest(
    typeAction.GET_INFO_TRACUU_BENH_NHAN_TT,
    function* searchBN_TT({ type, idBN, idChuyenKhoa }) {
      try {
        const { infoUser } = yield select((state) => state.userReducer)
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const result = yield call(() => traCuuService.getInfoBN_TT(idBN))
        const paymentCard = yield call(() => cardService.getInfoPaymentCard(idBN))
        const memberCard = yield call(() => cardService.getInfoMemberCard(idBN, idChuyenKhoa))

        const nameBenhNhan = result.data?.benhNhan?.tenBenhNhan

        const detailLog = {
          nameBenhNhan: nameBenhNhan,
        }

        yield put({
          type: typeAction.DISPATCH_TRACUU_INFO_BENH_NHAN,
          payload: {
            ...result.data,
            paymentCard: paymentCard.data[0],
            memberCard: memberCard.data,
          },
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap?.idChiNhanh,
          actionStatus.GET,
          logAction.TRA_CUU_BENH_NHAN,
          true,
          MENU_CONSTANTS.TRACUU_BENH_NHAN,
          null,
          detailLog,
        )
      } catch (err) {
        yield console.log(err)
      }
    },
  )

  yield takeLatest(
    typeAction.XEM_THONG_TIN_BN_TAI_KHAM,
    function* searchBN_TT({ type, idBN, idChuyenKhoa }) {
      try {
        const { infoUser } = yield select((state) => state.userReducer)
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const result = yield call(() => traCuuService.getInfoBN_TT(idBN))
        const paymentCard = yield call(() => cardService.getInfoPaymentCard(idBN))
        const memberCard = yield call(() => cardService.getInfoMemberCard(idBN, idChuyenKhoa))

        const nameBenhNhan = result.data?.benhNhan?.tenBenhNhan

        const detailLog = {
          nameBenhNhan: nameBenhNhan,
        }

        yield put({
          type: typeAction.DISPATCH_TRACUU_INFO_BENH_NHAN,
          payload: {
            ...result.data,
            paymentCard: paymentCard.data[0],
            memberCard: memberCard.data,
          },
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap?.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_BENH_NHAN_TAI_KHAM,
          true,
          MENU_CONSTANTS.TAIKHAM,
          null,
          detailLog,
        )
      } catch (err) {
        yield console.log(err)
      }
    },
  )

  //GET ALL
  yield takeLatest(typeAction.GET_ALL_BN, function* getAll_BN({ type }) {
    try {
      const { data } = yield call(() => traCuuService.getAllBN())
      // console.log(data)
      yield put({
        type: typeAction.DISPATCH_TRACUU_LIST_BN,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })

  //GET BY ID CHINHANH ID CTY
  yield takeLatest(
    typeAction.GET_BN_CHINHANH_CTY,
    function* getBNByIdChiNhanhIdCty({
      type,
      idct,
      idChinhanh,
      keyword,
      pageIndex,
      setIsLoading,
      idNhomUser,
    }) {
      try {
        setIsLoading(true)
        const { infoUser } = yield select((state) => state.userReducer)
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT011'),
        )
        const { data: listQuyen } = yield call(() =>
          phanQuyenService.getListCapQuyen(infoUser?.dangNhap?.idNguoiDung),
        )
        const strChuyenKhoa = yield call(() => {
          let listIdCkDcXem = []
          listQuyen
            ?.filter((item) => item?.sudung)
            ?.map((item) => {
              switch (item?.maquyen) {
                case 'QHT644': //nhi
                  listIdCkDcXem.push(1)
                  break
                case 'QHT645': //sản
                  listIdCkDcXem.push(2)
                  break
                case 'QHT646': //lão
                  listIdCkDcXem.push(3)
                  break
                case 'QHT647': //mắt
                  listIdCkDcXem.push(4)
                  break
              }
            })
          return (
            listIdCkDcXem?.reduce(
              (str, item, index) =>
                (str += `idchuyenkhoa=${item}${index < listIdCkDcXem?.length - 1 ? '&' : ''}`),
              '',
            ) ?? ''
          )
        })
        // return console.log(listChuyenKhoaDcXem)
        const { data } = yield call(() =>
          traCuuService.getByIdChinhanhIdctCheckPhanQuyen(
            idct,
            idChinhanh,
            strChuyenKhoa,
            keyword,
            pageIndex,
          ),
        )
        yield put({
          type: typeAction.DISPATCH_TRACUU_LIST_BN,
          payload: data,
        })
        if (idChinhanh) {
          const data_cn = yield call(() => traCuuService.getChiNhanhById(idChinhanh))
          if (data_cn) {
            const detailForLog = {
              branchName: data_cn.data.tenChiNhanh,
              keyword: keyword ?? '',
              page: pageIndex,
            }
            addLog(
              infoUser?.dangNhap.idNguoiDung,
              PkDangNhap?.idChiNhanh,
              actionStatus.GET,
              logAction.XEM_TRA_CUU_BENH_NHAN,
              true,
              MENU_CONSTANTS.TRACUU_BENH_NHAN,
              null,
              detailForLog,
            )
          } else {
            console.error('data_cn is null or undefined')
          }
        } else {
          if (idct) {
            const detailForLog = {
              idCongTY: idct,
              keyword: keyword ?? '',
              page: pageIndex,
            }
            addLog(
              infoUser?.dangNhap.idNguoiDung,
              PkDangNhap?.idChiNhanh,
              actionStatus.GET,
              logAction.XEM_TRA_CUU_BENH_NHAN_ALL_CHINHANH,
              true,
              MENU_CONSTANTS.TRACUU_BENH_NHAN,
              null,
              detailForLog,
            )
          } else {
            const detailForLog = {
              keyword: keyword ?? '',
              page: pageIndex,
            }
            addLog(
              infoUser?.dangNhap.idNguoiDung,
              PkDangNhap?.idChiNhanh,
              actionStatus.GET,
              logAction.XEM_TRA_CUU_BENH_NHAN_ALL_CTY,
              true,
              MENU_CONSTANTS.TRACUU_BENH_NHAN,
              null,
              detailForLog,
            )
          }
        }
      } catch (error) {
        // yield console.log(error)
        const { infoUser } = yield select((state) => state.userReducer)
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap?.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TRACUU_BENH_NHAN,
          detailErrorPayload,
        )
        setIsLoading(false)
        yield put({
          type: typeAction.DISPATCH_TRACUU_LIST_BN,
          payload: [],
        })
        console.error('Error : ', error)
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      } finally {
        setIsLoading(false)
      }
    },
  )

  //only mat
  yield takeLatest(
    typeAction.GET_BN_CHINHANH_CTY_MAT,
    function* getBNMatByIdct({ type, idct, keyword, pageIndex, setIsLoading }) {
      try {
        setIsLoading(true)
        const { data } = yield call(() => traCuuService.getBNMatByIdct(idct, keyword, pageIndex))
        yield put({
          type: typeAction.DISPATCH_TRACUU_LIST_BN,
          payload: data,
        })
      } catch (err) {
        yield console.log(err)
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    },
  )
}
