import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { congtyCRMService } from '../../services/congtyCRM/congtyCRMService'
import ToastCus from '../../utils/Toast'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
export function* congtyCRMSaga() {
  yield takeLatest(typeAction.GET_ALL_CTY_CRM, function* allCtyCRM({ type, payload }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => congtyCRMService.getAllCtyCRM())
      result.data.sort((a, b) => new Date(b.ngaytao) - new Date(a.ngaytao))
      yield put({
        type: typeAction.DISPATCH_ALL_CTY_CRM,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  yield takeLatest(typeAction.GET_CTY_CRM_BY_ID, function* getById({ type, id }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => congtyCRMService.getCtyCRMById(id))
      yield put({
        type: typeAction.DISPATCH_INFO_CTY_CRM,
        payload: result.data.result,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  //-----------GET DETAIL ----------//
  yield takeLatest(typeAction.GET_INFO_DETAIL_COMPANY_CRM, function* getDetail({ type, idct }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => congtyCRMService.getCtyCRMById(idct))
      yield put({
        type: typeAction.DISPATCH_INFO_CTY_CRM,
        payload: result.data.result,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  //add
  yield takeLatest(typeAction.ADD_CTY_CRM, function* addCtyCRM({ type, form, onLoad }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)

    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => congtyCRMService.addCtyCRM(form))
      yield call(onLoad)
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm mới thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.THEM_MOI_CONG_TY_CRM,
        true,
        MENU_CONSTANTS.CONG_TY,
        null,
        {
          ...form,
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thêm mới thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.CONG_TY,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //edit
  yield takeLatest(typeAction.EDIT_CTY_CRM, function* editCtyCRM({ type, form, onLoad }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => congtyCRMService.editCtyCRM(form))
      // console.log(result)
      yield call(onLoad)
      ToastCus.fire({
        icon: 'success',
        title: 'Sửa thông tin thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.CAP_NHAT_CONG_TY_CRM,
        true,
        MENU_CONSTANTS.CONG_TY,
        null,
        {
          ...form,
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Sửa thông tin thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.CONG_TY,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}
