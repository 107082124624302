import { Input, Select, DatePicker, Button, Table, ConfigProvider, Space, message } from 'antd'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import {
  ListDichVuAction,
  listGroupsDichVuAction,
  searchDanhMucDVAction,
} from '../../../store/actions/dichVuAction'
import { IconButton } from '@mui/material'
import CachedIcon from '@mui/icons-material/Cached'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import dayjs from 'dayjs'
import locale from 'antd/es/date-picker/locale/vi_VN'
import moment from 'moment'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import { https } from '../../../services/apiService'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import * as XLSX from 'xlsx'

const LichSuGiaDichVu = () => {
  const dispatch = useDispatch()
  const [keyword, setKeyword] = useState('')
  const [rowActive, setRowActive] = useState(null)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [listBranch, setListBranch] = useState(null)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { ListDichVu, groupsDichVu } = useSelector((state) => state.dichVuReducer)
  const [searchGroup, setSearchGroup] = useState(1)
  const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'))
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'))
  const dateFormat = 'DD-MM-YYYY'
  const momentDate = 'YYYY-MM-DD'
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [valueExport, setValueExport] = useState([])
  const [excelWorkbook, setExcelWorkbook] = useState(null)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Nhập tìm kiếm"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text?.toString() || ''}
        />
      ) : (
        text
      ),
  })

  const [columns, setColumns] = useState([
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 60,
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'Mã dịch vụ',
      dataIndex: 'maDichVu',
      key: 'maDichVu',
      width: 120,
      fixed: 'left',
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tenDichVu',
      key: 'tenDichVu',
      width: 200,
      fixed: 'left',
    },
    {
      title: 'Nhóm dịch vụ',
      dataIndex: 'nhomDichVu',
      key: 'nhomDichVu',
      width: 150,
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'donViTinh',
      key: 'donViTinh',
      width: 100,
      align: 'center',
    },
  ])

  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    dispatch(listAllCompanyAction())
    dispatch(listGroupsDichVuAction(company))
  }, [dispatch, company])

  useEffect(() => {
    if (company) {
      dispatch(ListDichVuAction(searchGroup, company))
    }
  }, [company, searchGroup, dispatch])

  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await https.get('DichVu/GetGiaDichVuByDichVu', {
        params: {
          keyword: keyword,
          idct: company,
          idnhomdv: searchGroup,
          tuNgay: dateFrom,
          denNgay: dateTo,
          pageIndex: 1,
        },
      })

      // Get all unique dates from the response
      const allDates = [
        ...new Set(
          res.data.data?.flatMap((item) => (item?.giadichvu ? Object.keys(item.giadichvu) : [])),
        ),
      ].sort()

      // Format data with dates
      const formattedData = res.data.data?.map((item, index) => {
        const giaDichVuTheoNgay = item.giadichvu

        return {
          key: index,
          stt: item.stt,
          maDichVu: item.maDichVu,
          tenDichVu: item.tenDichVu,
          nhomDichVu: item.tennhomdichvu,
          donViTinh: item.donVi,
          ...allDates.reduce((acc, date) => {
            const priceDetails = giaDichVuTheoNgay[date] || {}
            acc[date] = {
              OldPrice: priceDetails.OldPrice || 0,
              NewPrice: priceDetails.NewPrice || 0,
              PersonChanged: priceDetails.PersonChanged || '',
            }
            return acc
          }, {}),
        }
      })

      // Create dynamic columns
      const dynamicColumns = [
        {
          title: 'STT',
          dataIndex: 'stt',
          key: 'stt',
          width: 60,
          align: 'center',
          fixed: 'left',
        },
        {
          title: 'Mã dịch vụ',
          dataIndex: 'maDichVu',
          key: 'maDichVu',
          width: 120,
          fixed: 'left',
          ...getColumnSearchProps('maDichVu'),
        },
        {
          title: 'Tên dịch vụ',
          dataIndex: 'tenDichVu',
          key: 'tenDichVu',
          width: 200,
          fixed: 'left',
          ...getColumnSearchProps('tenDichVu'),
        },
        {
          title: 'Nhóm dịch vụ',
          dataIndex: 'nhomDichVu',
          key: 'nhomDichVu',
          width: 150,
          ...getColumnSearchProps('nhomDichVu'),
        },
        {
          title: 'Đơn vị tính',
          dataIndex: 'donViTinh',
          key: 'donViTinh',
          width: 100,
          align: 'center',
          ...getColumnSearchProps('donViTinh'),
        },
        ...allDates.map((date) => ({
          title: moment(date).format('DD/MM/YYYY'),
          dataIndex: date,
          key: date,
          width: 360,
          align: 'center',
          children: [
            {
              title: 'Giá cũ',
              dataIndex: `${date}_giaCu`,
              key: `${date}_giaCu`,
              width: 120,
              align: 'center',
              render: (_, record) => formatNumberVND(record[date]?.OldPrice),
            },
            {
              title: 'Giá mới',
              dataIndex: `${date}_giaMoi`,
              key: `${date}_giaMoi`,
              width: 120,
              align: 'center',
              render: (_, record) => formatNumberVND(record[date]?.NewPrice),
            },
            {
              title: 'Người thay đổi',
              dataIndex: `${date}_nguoiThayDoi`,
              key: `${date}_nguoiThayDoi`,
              width: 120,
              render: (_, record) => record[date]?.PersonChanged || '',
            },
          ],
        })),
      ]

      setColumns(dynamicColumns)
      setDataSource(formattedData)

      // Prepare Excel export data
      const headers = [
        'STT',
        'Mã dịch vụ',
        'Tên dịch vụ',
        'Tên nhóm dịch vụ',
        'Ngày',
        'Giá cũ',
        'Giá mới',
        'Người thay đổi',
      ]

      const rows = formattedData.flatMap((item) => {
        return allDates.map((date) => [
          item.stt,
          item.maDichVu,
          item.tenDichVu,
          item.nhomDichVu,
          date,
          item[date]?.OldPrice?.toLocaleString('vi-VN') || '',
          item[date]?.NewPrice?.toLocaleString('vi-VN') || '',
          item[date]?.PersonChanged || '',
        ])
      })

      const excelData = [headers, ...rows]

      // Create workbook
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.aoa_to_sheet(excelData)

      // Set column widths
      const colWidths = [
        { wch: 5 }, // STT
        { wch: 12 }, // Mã DV
        { wch: 30 }, // Tên DV
        { wch: 20 }, // Nhóm DV
        { wch: 12 }, // Ngày
        { wch: 15 }, // Giá cũ
        { wch: 15 }, // Giá mới
        { wch: 20 }, // Người thay đổi
      ]
      ws['!cols'] = colWidths

      // Add worksheet
      XLSX.utils.book_append_sheet(wb, ws, 'Lịch sử giá dịch vụ')

      // Store workbook for export
      setExcelWorkbook(wb)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchData()
  }, [company, searchGroup, dateFrom, dateTo])

  const handleCompany = (value) => {
    setRowActive(null)
    setCompany(value)
    setListBranch([])
    setSearchGroup(-1)
    dispatch(listGroupsDichVuAction(value))
  }

  const onChange = (value) => {
    setSearchGroup(value)
    setListBranch([])
    dispatch(ListDichVuAction(value, company))
  }

  const handleRefresh = () => {
    fetchData()
  }

  const onChangeTable = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }

  const exportToExcel = () => {
    try {
      if (excelWorkbook) {
        // Lấy worksheet đầu tiên
        const ws = excelWorkbook.Sheets[excelWorkbook.SheetNames[0]]

        // Lấy range của dữ liệu
        const range = XLSX.utils.decode_range(ws['!ref'])

        // Thêm cột STT vào đầu
        const newWs = XLSX.utils.sheet_add_aoa(
          ws,
          [['STT']], // Header cho cột STT
          { origin: 'A1' },
        )

        // Thêm số thứ tự cho từng dòng
        for (let i = range.s.r + 1; i <= range.e.r; i++) {
          XLSX.utils.sheet_add_aoa(
            newWs,
            [[i]], // Số thứ tự
            { origin: `A${i + 1}` },
          )
        }

        // Điều chỉnh độ rộng cột STT
        if (!ws['!cols']) ws['!cols'] = []
        ws['!cols'][0] = { wch: 5 } // Độ rộng cột STT

        // Tạo workbook mới và thêm worksheet đã sửa
        const newWb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(newWb, newWs, 'Sheet1')

        // Xuất file
        XLSX.writeFile(newWb, `Lich_su_gia_dich_vu_${moment().format('DD-MM-YYYY')}.xlsx`)
      }
    } catch (error) {
      console.error('Export error:', error)
      message.error('Có lỗi xảy ra khi xuất file!')
    }
  }

  return (
    <>
      <div className=" p-2 h-full bg-[#efefef]">
        <div className="h-full bg-white rounded-xl border">
          <div className="p-2 pt-1">
            <div className="flex items-center justify-between">
              <div className="flex gap-2 items-center">
                <div className="flex w-full gap-3">
                  <Select
                    showSearch
                    className="w-80"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={handleCompany}
                    value={company}
                    options={listCompany?.map((items) => ({
                      label: items.tenct,
                      value: items.idct,
                    }))}
                  />
                  <Select
                    onChange={onChange}
                    value={searchGroup}
                    className="w-80"
                    options={[
                      ...(groupsDichVu
                        ? groupsDichVu.map((items) => ({
                            label: items.tenNhom,
                            value: items.idNhom,
                          }))
                        : []),
                    ]}
                  />
                  <Input.Search
                    className="w-80"
                    placeholder="Nhập từ khoá tìm kiếm"
                    onSearch={(value) => {
                      fetchData(value)
                    }}
                    onChange={(e) => {
                      setKeyword(e.target.value)
                    }}
                    allowClear
                  />
                  <DatePicker
                    allowClear={false}
                    placeholder=" "
                    className="w-[7.5rem]"
                    defaultValue={dayjs(moment())}
                    maxDate={dayjs(moment())}
                    onChange={(date, dateString) =>
                      setDateFrom(moment(dateString, dateFormat).format(momentDate))
                    }
                    format={dateFormat}
                    locale={{ ...locale, week: { start: 1 } }}
                  />
                  <span className="font-semibold text-2xl">-</span>
                  <DatePicker
                    allowClear={false}
                    placeholder=" "
                    className="w-[7.5rem]"
                    defaultValue={dayjs(moment())}
                    maxDate={dayjs(moment())}
                    onChange={(date, dateString) =>
                      setDateTo(moment(dateString, dateFormat).format(momentDate))
                    }
                    format={dateFormat}
                    locale={{ ...locale, week: { start: 1 } }}
                  />
                </div>

                <IconButton color="primary" onClick={handleRefresh}>
                  <CachedIcon />
                </IconButton>

                <Button
                  onClick={exportToExcel}
                  type="text"
                  size="middle"
                  className="text-green-700 p-1"
                  icon={<ImportExportIcon />}
                >
                  Xuất Excel
                </Button>
              </div>
            </div>
          </div>

          <div className="mt-1 bg-white rounded-md">
            <ConfigProvider
              theme={{
                token: {
                  padding: 3,
                  fontSize: 13,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    // headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                loading={loading}
                onChange={onChangeTable}
                className="w-full table-thumua"
                pagination={false}
                scroll={{
                  x: 1000,
                  y: '82vh',
                }}
                bordered
                columns={columns}
                dataSource={dataSource}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </>
  )
}

export default LichSuGiaDichVu
