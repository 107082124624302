import { Tabs } from 'antd'
import React from 'react'
import TongHop from './TongHop'
import ChiTiet from './ChiTiet'
import SayThai from './SayThai'

const SanPhu = () => {
  return (
    <div className="bg-[#EFEFEF] h-full p-2">
      <div className="p-2 bg-white border h-full rounded-lg">
        <Tabs
          items={[
            {
              key: 1,
              label: 'Tổng hợp',
              children: <TongHop />,
            },
            {
              key: 2,
              label: 'Chi tiết',
              children: <ChiTiet />,
            },
            {
              key: 3,
              label: 'Sảy thai',
              children: <SayThai />,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default SanPhu
