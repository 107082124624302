import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { cashierService } from '../../services/cashier/cashierService'
import { childrenClinicService } from '../../services/childrenClinic/childrenClinicService'
import { recieveService } from '../../services/receive/recieveService'
import { cardService } from '../../services/card/cardService'
import { generatePhieuThuTiepNhan } from '../../utils/report/phieuThuTiepNhan'
import { detailBNCashier } from '../../store/actions/cashierAction'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import ToastCus from '../../utils/Toast'
import { cloneDeep } from 'lodash'
import Swal from 'sweetalert2'
import moment from 'moment'
import 'moment/locale/vi'
import { formatPayloadDetails, getListNameChiNhanh } from '../../utils/algorithm'
import { addLog } from '../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../utils/log/logConstant'
moment.locale('vi')
const today = moment()
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* cashierSaga() {
  yield takeLatest(
    typeAction.GET_DANH_SACH_PHIEU_THU,
    function* branchLogin({ type, form, to, idChiNhanh, setIsLoading, branch }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        setIsLoading(true)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT037'),
        )

        const idChiNhanhs = (idChiNhanh?.includes('all') ? branch : idChiNhanh)
          ?.map((item) => `idChiNhanhs=${idChiNhanh?.includes('all') ? item?.idChiNhanh : item}`)
          ?.join('&')
          ?.toString()
        const result = yield call(() => cashierService.getPhieuThu(form, to, idChiNhanhs))
        yield put({
          type: typeAction.DISPATCH_LIST_PHIEU_THU,
          payload: result.data,
        })
        setIsLoading(false)
        const listNameChiNhanh = getListNameChiNhanh(idChiNhanh, branch)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_PHIEU_THU,
          true,
          MENU_CONSTANTS.TIEP_NHAN,
          null,
          {
            dateFrom: form,
            dateTo: to,
            listChiNhanh: listNameChiNhanh.join('\n'),
          },
        )
      } catch (error) {
        setIsLoading(false)
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        // ToastCus.fire({
        //   icon: 'error',
        //   title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        // })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TIEP_NHAN,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(typeAction.UPDATE_BNTT_GHI_CHU_HD, function* updateBNTTPhieu({ type, form }) {
    try {
      const formPayment = {
        idbntt: form.idbntt,
        hoadondientu: form.hoadondientu,
        ghichuxuathoadon: form.ghichuxuathoadon,
      }
      yield call(() => cardService.updateBNTT(formPayment))
      const infoPay = yield call(() => cashierService.getInfoPayment(form.idbntt))
      yield put({
        type: typeAction.DISPATCH_DETAIL_PAYMENT_CASHIER,
        payload: infoPay.data[0],
      })
    } catch (error) {
      console.log(error)
    }
  })
  // UPDATE CHI TIẾT THANH TOAN //
  yield takeLatest(
    typeAction.UPDATE_CHI_TIET_THANH_TOAN_PT,
    function* updateDetailPayment({ type, form, setShow, idbntt, benhNhanTTC, tienGiamUpdate }) {
      const today = moment().format('YYYY-MM-DDTHH:mm:ss')
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        const total =
          form.chuyenkhoan +
          form.tienmat +
          form.thett +
          form.momo +
          form.vnpay +
          form.zalopay +
          form.pos
        yield call(() =>
          cardService.updateChiTietTT({
            idcttt: form.idcttt,
            idbntt: idbntt,
            tienmat: form.tienmat,
            chuyenkhoan: form.chuyenkhoan,
            thett: form.thett,
            momo: form.momo,
            vnpay: form.vnpay,
            zalopay: form.zalopay,
            pos: form.pos,
            tienthanhtoan: total,
            tiengiamgia: tienGiamUpdate + form.tiengiamdoitac,
            tongtien: total + tienGiamUpdate + form.tiengiamdoitac,
          }),
        )
        yield call(() =>
          cashierService.putBenhNhanThanhToan({
            idbntt: form.idbntt,
            idbnttc: form.idbnttc,
            idPhuongThuc: form.idphuongthuc,
          }),
        )

        const infoPay = yield call(() => cashierService.getInfoPayment(idbntt))
        yield put({
          type: typeAction.DISPATCH_DETAIL_PAYMENT_CASHIER,
          payload: infoPay.data[0],
        })
        yield call(() =>
          childrenClinicService.updateDetailBN({
            idbnttc: benhNhanTTC.idbnttc,
            idnguon: benhNhanTTC.idnguon,
          }),
        )
        const infoBNcuaPT = yield call(() =>
          childrenClinicService.getDetailPhieuThu(benhNhanTTC.idbnttc),
        )
        yield call(() =>
          cardService.updateBNTT({
            idbntt: idbntt,
            nguoisua: infoUser?.idnv,
            ngaysua: today,
          }),
        )
        yield put({
          type: typeAction.DISPATCH_CASHIER_BN_TTC_PT,
          payload: infoBNcuaPT.data,
        })

        setShow(false)
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CAP_NHAP_CHI_TIET_THANH_TOAN_PHIEU_THU,
          true,
          MENU_CONSTANTS.TIEP_NHAN,
          null,
          {
            maphieuthu: infoBNcuaPT.data.phieuthu,
          },
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại!',
        })
        console.log(error)

        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.TIEP_NHAN,
          detailErrorPayload,
        )
      }
    },
  )
  // UPDATE DỊCH VỤ  PHIẾU THU //
  yield takeLatest(
    typeAction.UPDATE_SERVICE_PHIEU_THU,
    function* updateServicePT({
      type,
      data,
      idbntt,
      resetUpdate,
      tiengiamdoitac,
      idnv,
      idbnttc,
      ghichu,
      ngaysua,
      tenNguoiSua,
      maphieuthu,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const { DV, CDHA, XN } = data
        const newData = [...DV, ...CDHA, ...XN]
        const tienGiam = newData?.reduce((total, items) => total + items.tiengiamgia, 0) ?? 0
        let priceDV = 0
        let priceCDHA = 0
        let PriceXN = 0
        if (DV.length !== 0) {
          const newDataDV = DV?.map((service) => ({ ...service, nguoisua: idnv }))
          priceDV = DV?.reduce((total, items) => total + items.tienthanhtoan, 0) ?? 0
          yield call(() => cashierService.updateDV(newDataDV))
        }
        if (CDHA.length !== 0) {
          const newDataCDHA = CDHA?.map((service) => ({ ...service, nguoisua: idnv }))
          priceCDHA = CDHA?.reduce((total, items) => total + items.tienthanhtoan, 0) ?? 0
          yield call(() => cashierService.updateCDHA(newDataCDHA))
        }
        if (data.XN.length !== 0) {
          const newDataXN = XN?.map((service) => ({ ...service, nguoisua: idnv }))
          PriceXN = XN?.reduce((total, items) => total + items.tienthanhtoan, 0) ?? 0
          yield call(() => cashierService.updateXN(newDataXN))
        }
        const formPayment = {
          idbntt: idbntt,
          tienDichVu: priceDV,
          tienXetNghiem: PriceXN,
          tienCDHA: priceCDHA,
          nguoisua: idnv,
          tienGiamGia: tiengiamdoitac + tienGiam,
          tienThanhToan: priceDV + priceCDHA + PriceXN,
          ghichu: ghichu,
          ngaysua: ngaysua,
          tenNguoiSua: tenNguoiSua,
        }

        yield call(() => cardService.updateBNTT(formPayment))
        const dv = yield call(() => cashierService.getDicVuByBNTTC(idbnttc))
        yield put({
          type: typeAction.DISPATCH_SET_ARR_KB_CASHIER,
          payload: dv.data,
        })
        const xn = yield call(() => cashierService.getXNByBNTTC(idbnttc))
        yield put({
          type: typeAction.DISPATCH_SET_ARR_XN_CASHIER,
          payload: xn.data,
        })
        const cdha = yield call(() => cashierService.getCDHAByBNTTC(idbnttc))
        yield put({
          type: typeAction.DISPATCH_SET_ARR_CDHA_CASHIER,
          payload: cdha.data,
        })
        const infoPay = yield call(() => cashierService.getInfoPayment(idbntt))
        yield put({
          type: typeAction.DISPATCH_DETAIL_PAYMENT_CASHIER,
          payload: infoPay.data[0],
        })
        resetUpdate()
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CAP_NHAP_CHI_TIET_DICH_VU_PHIEU_THU,
          true,
          MENU_CONSTANTS.TIEP_NHAN,
          null,
          {
            maphieuthu,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.TIEP_NHAN,
          detailErrorPayload,
        )
      }
    },
  )
  /// LÂY TẤT CẢ THÔNG  TIN 1 PHIẾU THU
  yield takeLatest(
    typeAction.GET_INFO_PT_CASHIER,
    function* getInfoBN({ type, idbn, idbntt, idbnttc, idChuyenKhoa }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const infoBNcuaPT = yield call(() => childrenClinicService.getDetailPhieuThu(idbnttc))
        const infoBNTTC = yield call(() => childrenClinicService.getDetailBNKham(idbnttc))
        const infoBN = yield call(() => recieveService.getInfoBN(idbn))
        const dv = yield call(() => cashierService.getDicVuByBNTTC(idbnttc))
        const xn = yield call(() => cashierService.getXNByBNTTC(idbnttc))
        const cdha = yield call(() => cashierService.getCDHAByBNTTC(idbnttc))
        const infoPay = yield call(() => cashierService.getInfoPayment(idbntt))
        const paymentCard = yield call(() => cardService.getInfoPaymentCard(idbn))
        const memberCard = yield call(() => cardService.getInfoMemberCard(idbn, idChuyenKhoa))
        let tongGiam = 0,
          tongThanhToan = 0
        if (memberCard?.data?.idthe && memberCard?.data?.idbn === idbn) {
          let listCD = [...dv.data, ...xn.data, ...cdha.data],
            cdDaGiam = null
          const { data: listCDGiam } = yield call(() =>
            recieveService.postListCDApplyMemberCard(
              listCD?.map((item) => ({
                iddv: item?.iddichvu ?? item?.idxn ?? item?.idcdha,
                loaithedichvu: (item?.iddichvu && 1) || (item?.idxn && 2) || (item?.idcdha && 3),
                idthe: memberCard.data?.idthe,
                tiendichvu: item?.dongia ?? 0,
                ptgiamgia: item?.ptgiamgia ?? 0,
                tiengiamgia: item?.tiengiamgia ?? 0,
                tienthanhtoan: item?.tienthanhtoan ?? 0,
                trangthaitt: item?.idtrangthaitt ?? item?.trangthaitt ?? 2,
              })),
            ),
          )
          const formatData = (item, idDV, idloaiDV) => {
            cdDaGiam = listCDGiam?.find(
              (itemCD) => itemCD?.iddv === idDV && itemCD?.loaithedichvu === idloaiDV,
            )

            item.dongia = cdDaGiam?.tiendichvu ?? item.dongia
            item.ptgiamgia = cdDaGiam?.ptgiamgia ?? 0
            item.tiengiamgia = cdDaGiam?.tiengiamgia ?? 0
            item.tienthanhtoan = cdDaGiam?.tienthanhtoan ?? item.dongia

            tongThanhToan += cdDaGiam?.tienthanhtoan ?? item?.tienthanhtoan ?? item?.dongia
            tongGiam += cdDaGiam?.tiengiamgia ?? item?.tiengiamgia ?? 0
          }
          dv.data?.map((item) => {
            if ((item?.idtrangthaitt ?? item?.trangthaitt) !== 2) {
              formatData(item, item?.iddichvu, 1)
            }
          })
          xn.data?.map((item) => {
            if ((item?.idtrangthaitt ?? item?.trangthaitt) !== 2) {
              formatData(item, item?.idxn, 2)
              // cdDaGiam = listCDGiam?.find(
              //   (itemCD) => itemCD?.iddv === item?.idxn && itemCD?.loaithedichvu === 2,
              // )
              // item.dongia = cdDaGiam?.tiendichvu ?? item.dongia
              // item.ptgiamgia = cdDaGiam?.ptgiamgia ?? 0
              // item.tiengiamgia = cdDaGiam?.tiengiamgia ?? 0
              // item.tienthanhtoan = cdDaGiam?.tienthanhtoan ?? item.dongia
              // tongThanhToan += cdDaGiam?.tienthanhtoan ?? item?.tienthanhtoan ?? item?.dongia
              // tongGiam += cdDaGiam?.tiengiamgia ?? item?.tiengiamgia ?? 0
            }
          })
          cdha.data?.map((item) => {
            if ((item?.idtrangthaitt ?? item?.trangthaitt) !== 2) {
              formatData(item, item?.idcdha, 3)
              // cdDaGiam = listCDGiam?.find(
              //   (itemCD) => itemCD?.iddv === item?.idcdha && itemCD?.loaithedichvu === 3,
              // )
              // item.dongia = cdDaGiam?.tiendichvu ?? item.dongia
              // item.ptgiamgia = cdDaGiam?.ptgiamgia ?? 0
              // item.tiengiamgia = cdDaGiam?.tiengiamgia ?? 0
              // item.tienthanhtoan = cdDaGiam?.tienthanhtoan ?? item.dongia
              // tongThanhToan += cdDaGiam?.tienthanhtoan ?? item?.tienthanhtoan ?? item?.dongia
              // tongGiam += cdDaGiam?.tiengiamgia ?? item?.tiengiamgia ?? 0
            }
          })
        } else {
          dv.data?.map((item) => {
            if ((item?.idtrangthaitt ?? item?.trangthaitt) !== 2) {
              // const form = { idthe: memberCard.idthe, iddv: item.iddichvu }
              // const result = call(() => recieveService.postApplyMemberCard(form))
              // let chiTietCD = {
              //   ...cd,
              //   ptgiamgia: result.data.ptgiamgia,
              //   tiengiamgia: result.data.tiengiamgia,
              //   tienthanhtoan: result.data.tienthanhtoan,
              // }
              tongThanhToan += item?.tienthanhtoan ?? item?.dongia
              tongGiam += item?.tiengiamgia ?? 0
            }
          })
          xn.data?.map((item) => {
            if ((item?.idtrangthaitt ?? item?.trangthaitt) !== 2) {
              tongThanhToan += item?.tienthanhtoan ?? item?.dongia
              tongGiam += item?.tiengiamgia ?? 0
            }
          })
          cdha.data?.map((item) => {
            if ((item?.idtrangthaitt ?? item?.trangthaitt) !== 2) {
              tongThanhToan += item?.tienthanhtoan ?? item?.dongia
              tongGiam += item?.tiengiamgia ?? 0
            }
          })
        }
        yield put({
          type: typeAction.DISPATCH_SET_ARR_KB_CASHIER,
          payload: dv.data,
        })
        yield put({
          type: typeAction.DISPATCH_SET_ARR_XN_CASHIER,
          payload: xn.data,
        })
        yield put({
          type: typeAction.DISPATCH_SET_ARR_CDHA_CASHIER,
          payload: cdha.data,
        })
        yield put({
          type: typeAction.DISPATCH_CASHIER_BN_TTC_PT,
          payload: infoBNcuaPT.data,
        })
        yield put({
          type: typeAction.DISPATCH_CASHIER_BN_TTC,
          payload: infoBNTTC.data,
        })
        yield put({
          type: typeAction.DISPATCH_CASHIER_INFO_BN,
          payload: infoBN.data,
        })
        yield put({
          type: typeAction.DISPATCH_LOAD_TONG_GIAM_CASHIER,
          payload: tongGiam,
        })
        yield put({
          type: typeAction.DISPATCH_LOAD_TONG_THANH_TOAN_CASHIER,
          payload: tongThanhToan,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_PAYMENT_CASHIER,
          payload: infoPay.data[0],
        })
        yield put({
          type: typeAction.DISPATCH_PAYMENT_CARD_CASHIER,
          payload: paymentCard.data[0],
        })
        yield put({
          type: typeAction.DISPATCH_MEMBER_CARD_CASHIER,
          payload: memberCard?.data?.idbn === idbn ? memberCard?.data : null,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_CHI_TIET_PHIEU_THU,
          true,
          MENU_CONSTANTS.TIEP_NHAN,
          null,
          {
            maphieuthu: infoBNcuaPT.data.phieuthu,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TIEP_NHAN,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // Apply CHỈ ĐỊNH GIẢM GIÁ
  yield takeLatest(
    typeAction.GET_LIST_DISCOUNT_CASHIER,
    function* updateListChiDinhGiamGiaCashier({ type, listDV }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const data = yield select((state) => state.cashierReducer)

        let tongTienGiam = 0,
          tongThanhToan = 0
        const { ArrKB, ArrXN, ArrCĐHA } = data
        const cloneArrKB = cloneDeep(ArrKB)
        const cloneArrXN = cloneDeep(ArrXN)
        const cloneArrCĐHA = cloneDeep(ArrCĐHA)
        cloneArrKB?.forEach((item) => {
          listDV.forEach((element) => {
            if (item.iddichvu === element.iddv && element.loaivoucherdichvu === 1) {
              item.tiengiamgia = element.tiengiam
              item.giaTienSauKhiGiam = element.thanhtien
              item.ptgiamgia = element.ptgiam
              item.tienthanhtoan = element.thanhtien

              if ((item.trangthaitt ?? item.idtrangthaitt) !== 2) {
                tongTienGiam += item.tiengiamgia
                tongThanhToan += item.tienthanhtoan
              }
            }
          })
        })

        cloneArrXN?.forEach((item) => {
          listDV.forEach((element) => {
            if (item.idxn === element.iddv && element.loaivoucherdichvu === 2) {
              item.tiengiamgia = element.tiengiam
              item.giaTienSauKhiGiam = element.thanhtien
              item.ptgiamgia = element.ptgiam
              item.tienthanhtoan = element.thanhtien

              if ((item.trangthaitt ?? item.idtrangthaitt) !== 2) {
                tongTienGiam += item.tiengiamgia
                tongThanhToan += item.tienthanhtoan
              }
            }
          })
        })

        cloneArrCĐHA?.forEach((item) => {
          listDV.forEach((element) => {
            if (item.idcdha === element.iddv && element.loaivoucherdichvu === 3) {
              item.tiengiamgia = element.tiengiam
              item.giaTienSauKhiGiam = element.thanhtien
              item.ptgiamgia = element.ptgiam
              item.tienthanhtoan = element.thanhtien

              if ((item.trangthaitt ?? item.idtrangthaitt) !== 2) {
                tongTienGiam += item.tiengiamgia
                tongThanhToan += item.tienthanhtoan
              }
            }
          })
        })
        // cloneArrXN.forEach((item) => {
        //   listDV.forEach((element) => {
        //     if (item.idxn === element.iddv) {
        //       item.tiengiamgia = element.giatrigiam
        //       item.giaTienSauKhiGiam = element.giatri
        //       item.ptgiamgia = element.phantramgiam
        //       item.tienthanhtoan = element.giatri
        //       if (item.trangthaitt !== 2) {
        //         tongTienGiam += item.tiengiamgia
        //         tongThanhToan += item.tienthanhtoan
        //       }
        //     }
        //   })
        // })
        // cloneArrCĐHA.forEach((item) => {
        //   listDV.forEach((element) => {
        //     if (item.idcdha === element.iddv) {
        //       item.tiengiamgia = element.giatrigiam
        //       item.giaTienSauKhiGiam = element.giatri
        //       item.ptgiamgia = element.phantramgiam
        //       item.tienthanhtoan = element.giatri
        //       if (item.trangthaitt !== 2) {
        //         tongTienGiam += item.tiengiamgia
        //         tongThanhToan += item.tienthanhtoan
        //       }
        //     }
        //   })
        // })
        yield put({
          type: typeAction.DISPATCH_LOAD_TONG_GIAM_CASHIER,
          payload: tongTienGiam,
        })
        yield put({
          type: typeAction.DISPATCH_LOAD_TONG_THANH_TOAN_CASHIER,
          payload: tongThanhToan,
        })
        yield put({
          type: typeAction.UPDATE_NEW_PRICE_CHI_DINH_CASHIER,
          payload: listDV,
        })

        //update lai list chi dinh sau khi chinh sua gia moi nhat
        yield put({
          type: typeAction.DISPATCH_SET_ARR_KB_CASHIER,
          payload: cloneArrKB,
        })
        yield put({
          type: typeAction.DISPATCH_SET_ARR_XN_CASHIER,
          payload: cloneArrXN,
        })
        yield put({
          type: typeAction.DISPATCH_SET_ARR_CDHA_CASHIER,
          payload: cloneArrCĐHA,
        })
        ToastCus.fire({
          icon: 'success',
          title: 'Áp dụng voucher thành công',
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  yield takeLatest(
    typeAction.UPDATE_SERVICE_BN,
    function* updateServiceBN({
      type,
      form,
      idbntt,
      idnv,
      ghichu,
      ngaysua,
      tenNguoiSua,
      resetCheckDelete,
      maphieuthu,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })

        // Calculate totals for items with status === 3
        const TotalDV = form.DV.filter((item) => item.status === 2).reduce(
          (total, item) => total + item.donGia,
          0,
        )
        const TotalXN = form.XN.filter((item) => item.status === 2).reduce(
          (total, item) => total + item.donGia,
          0,
        )
        const TotalCDHA = form.CDHA.filter((item) => item.status === 2).reduce(
          (total, item) => total + item.donGia,
          0,
        )
        // Calculate total discounts for items with status === 2
        const DiscountDV = form.DV.filter((item) => item.status === 2).reduce(
          (total, item) => total + item.discount,
          0,
        )
        const DiscountXN = form.XN.filter((item) => item.status === 2).reduce(
          (total, item) => total + item.discount,
          0,
        )
        const DiscountCDHA = form.CDHA.filter((item) => item.status === 2).reduce(
          (total, item) => total + item.discount,
          0,
        )
        if (form.DV.length > 0)
          for (let dv of form.DV) {
            yield call(() => recieveService.deleteDichVuBN(dv.id))
          }
        if (form.CDHA.length > 0)
          for (let cdhn of form.CDHA) {
            yield call(() => recieveService.deleteCDHABN(cdhn.id))
          }
        if (form.XN.length > 0)
          for (let xn of form.XN) {
            yield call(() => recieveService.deleteXetNghiemBN(xn.id))
          }
        const TotalDiscount = DiscountDV + DiscountXN + DiscountCDHA
        const resultPay = yield call(() => cashierService.getInfoPayment(idbntt))
        const data = resultPay.data[0]
        const formBNTT = {
          idbntt: idbntt,
          tienThanhToan: data.tienthanhtoan - (TotalDV + TotalXN + TotalCDHA),
          tienDichVu: TotalDV !== 0 ? data.tiendichvu - TotalDV : data.tiendichvu,
          tienXetNghiem: TotalXN !== 0 ? data.tienxetnghiem - TotalXN : data.tienxetnghiem,
          tienCDHA: TotalCDHA !== 0 ? data.tiencdha - TotalCDHA : data.tiencdha,
          tienGiamGia: data.tiengiamgia - TotalDiscount,
          nguoisua: idnv,
          ghichu: ghichu,
          ngaysua: ngaysua,
          tenNguoiSua: tenNguoiSua,
        }

        let priceDV = TotalDV + TotalXN + TotalCDHA
        const formDetailPay = {
          idbntt: idbntt,
          tienmat: data.tienmat,
          chuyenkhoan: data.chuyenkhoan,
          momo: data.momo,
          vnpay: data.vnpay,
          zalopay: data.zalopay,
          pos: data.tienmposat,
          tongtien: data.tongtien - priceDV,
          tiengiamgia: data.tiengiamgia - TotalDiscount,
          tienthanhtoan: data.tienthanhtoan - priceDV,
        }
        // Adjust formDeTotalDVtailPay based on the data values
        if (priceDV > 0) {
          if (data.tienmat > 0) {
            const remainingTienMat = data.tienmat - priceDV
            if (remainingTienMat < 0) {
              formDetailPay.tienmat = 0
              priceDV = -remainingTienMat
            } else {
              formDetailPay.tienmat = remainingTienMat
              priceDV = 0
            }
          }
        }
        // chuyển khoản
        if (priceDV > 0) {
          if (data.chuyenkhoan > 0) {
            const remainingChuyenKhoan = data.chuyenkhoan - priceDV
            if (remainingChuyenKhoan < 0) {
              formDetailPay.chuyenkhoan = 0
              priceDV = -remainingChuyenKhoan
            } else {
              formDetailPay.chuyenkhoan = remainingChuyenKhoan
              priceDV = 0
            }
          }
        }
        ///momo
        if (priceDV > 0) {
          if (data.momo > 0) {
            const remainingmomo = data.momo - priceDV
            if (remainingmomo < 0) {
              formDetailPay.momo = 0
              priceDV = -remainingmomo
            } else {
              formDetailPay.momo = remainingmomo
              priceDV = 0
            }
          }
        }
        // vnpay
        if (priceDV > 0) {
          if (data.vnpay > 0) {
            const remainingvnpay = data.vnpay - priceDV
            if (remainingvnpay < 0) {
              formDetailPay.vnpay = 0
              priceDV = -remainingvnpay
            } else {
              formDetailPay.vnpay = remainingvnpay
              priceDV = 0
            }
          }
        }
        //zalopay
        if (priceDV > 0) {
          if (data.zalopay > 0) {
            const remainingzalopay = data.zalopay - priceDV
            if (remainingzalopay < 0) {
              formDetailPay.zalopay = 0
              priceDV = -remainingzalopay
            } else {
              formDetailPay.zalopay = remainingzalopay
              priceDV = 0
            }
          }
        }
        //pos
        if (priceDV > 0) {
          if (data.pos > 0) {
            const remainingpos = data.pos - priceDV
            if (remainingpos < 0) {
              formDetailPay.pos = 0
              priceDV = -remainingpos
            } else {
              formDetailPay.pos = remainingpos
              priceDV = 0
            }
          }
        }
        yield call(() => cardService.updateBNTT(formBNTT))
        yield call(() => cardService.updateChiTietTT(formDetailPay))
        resetCheckDelete()
        yield put({
          type: typeAction.RESET_PHIEUTHU_CASHIER,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        ToastCus.fire({
          icon: 'success',
          title: 'Cập nhật thành công',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CAP_NHAT_PHIEU_THU_XOA_CHI_DINH,
          true,
          MENU_CONSTANTS.TIEP_NHAN,
          null,
          {
            maphieuthu,
          },
        )
      } catch (error) {
        yield console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Cập nhật thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TIEP_NHAN,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // Apply VOUCHER cua doi tac
  yield takeLatest(
    typeAction.SET_CHUONG_TRINH_GIAM_GIA_DOI_TAC_CASHIER,
    function* updateGiamGiaByVoucherDoiTacCashier({ type, form, isApply }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })

        yield put({
          type: typeAction.DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC_CASHIER,
          payload: form,
        })
        ToastCus.fire({
          icon: 'success',
          title: 'Áp dụng voucher thành công',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          isApply ? logAction.APPLY_VOUCHER_DOI_TAC : logAction.UNAPPLY_VOUCHER_DOI_TAC,
          true,
          MENU_CONSTANTS.TIEP_NHAN,
          null,
          {
            maVoucher: form.maVoucher,
            giaVoucher: form.giaVoucher,
          },
        )
      } catch (error) {
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TIEP_NHAN,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  // Remove Giá giảm của chỉ định
  yield takeLatest(
    typeAction.REMOVE_LIST_DISCOUNT_CASHIER,
    function* unApplyListChiDinhGiamGiaCashier({ type, listDV }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        // const data = yield select((state) => state.cashierReducer)

        // const { ArrKB, ArrXN, ArrCĐHA, discountArr } = data
        // const cloneArrKB = cloneDeep(ArrKB)
        // const cloneArrXN = cloneDeep(ArrXN)
        // const cloneArrCĐHA = cloneDeep(ArrCĐHA)
        // const cloneDiscountArr = cloneDeep(discountArr)
        // cloneArrKB.forEach((item) => {
        //   cloneDiscountArr.forEach((element) => {
        //     if (item.iddichvu === element.iddv) {
        //       item.tiengiamgia = element.tiengiam
        //       item.giaTienSauKhiGiam = element.giatri
        //       item.ptgiamgia = element.ptgiam
        //       item.tienthanhtoan = element.thanhtien
        //     }
        //   })
        // })
        // cloneArrXN.forEach((item) => {
        //   cloneDiscountArr.forEach((element) => {
        //     if (item.idxn === element.iddv) {
        //       item.tiengiamgia = element.tiengiam
        //       item.giaTienSauKhiGiam = element.giatri
        //       item.ptgiamgia = element.ptgiam
        //       item.tienthanhtoan = element.thanhtien
        //     }
        //   })
        // })
        // cloneArrCĐHA.forEach((item) => {
        //   cloneDiscountArr.forEach((element) => {
        //     if (item.idcdha === element.iddv) {
        //       item.tiengiamgia = element.tiengiam
        //       item.giaTienSauKhiGiam = element.giatri
        //       item.ptgiamgia = element.ptgiam
        //       item.tienthanhtoan = element.thanhtien
        //     }
        //   })
        // })

        let tongTienGiam = 0,
          tongThanhToan = 0
        listDV
          ?.filter((item) => (item.trangthaitt ?? item.idtrangthaitt) !== 2)
          ?.map((item) => {
            tongTienGiam += item.tiengiamgia
            tongThanhToan += item.tienthanhtoan
          })

        // item.tiengiamgia = element.tiengiam
        //       item.giaTienSauKhiGiam = element.thanhtien
        //       item.ptgiamgia = element.ptgiam
        //       item.tienthanhtoan = element.thanhtien

        //       if (item.trangthaitt !== 2) {
        //         tongTienGiam += item.tiengiamgia
        //         tongThanhToan += item.tienthanhtoan
        //       }
        yield put({
          type: typeAction.DISPATCH_LOAD_TONG_GIAM_CASHIER,
          payload: tongTienGiam,
        })
        yield put({
          type: typeAction.DISPATCH_LOAD_TONG_THANH_TOAN_CASHIER,
          payload: tongThanhToan,
        })
        // update lai list chi dinh sau khi chinh sua gia moi nhat

        yield put({
          type: typeAction.DISPATCH_SET_ARR_KB_CASHIER,
          payload: listDV?.filter((item) => item.iddichvu),
        })
        yield put({
          type: typeAction.DISPATCH_SET_ARR_XN_CASHIER,
          payload: listDV?.filter((item) => item.idxn),
        })
        yield put({
          type: typeAction.DISPATCH_SET_ARR_CDHA_CASHIER,
          payload: listDV?.filter((item) => item.idcdha),
        })

        yield put({
          type: typeAction.UPDATE_NEW_PRICE_CHI_DINH_CASHIER,
          payload: [],
        })
        ToastCus.fire({
          icon: 'success',
          title: 'Đã hủy áp dụng voucher',
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // Apply thẻ thành viên khi chọn dịch vụ
  yield takeLatest(
    typeAction.POST_CASHIER_APPLY_MEMBER_CARD,
    function* applyMemberCard({ type, form, cd }) {
      try {
        const result = yield call(() => recieveService.postApplyMemberCard(form))
        let chiTietCD = {
          ...cd,
          ptgiamgia: result.data.ptgiamgia,
          tiengiamgia: result.data.tiengiamgia,
          tienthanhtoan: result.data.tienthanhtoan,
          loaithedichvu: result.data.loaithedichvu,
        }
        yield put({
          type: typeAction.DISTPATCH_LIST_KB_CASHIER_APPLY_MEMBER_CARD,
          payload: chiTietCD,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  // Thanh toán phiếu thu
  yield takeLatest(
    typeAction.PUT_PHIEUTHU_CASHIER,
    function* updatePhieuThu({ type, form, idcn, formPrint, tenbn, mabn }) {
      const dataChiNhanh = yield select((state) => state.branchReducer)
      const { PkDangNhap } = dataChiNhanh
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        yield call(() => cashierService.putBenhNhanThanhToan(form.BNTT))
        yield call(() => cashierService.putBenhNhanhChiTietTT(form.BNCTTT, form.idbntt))

        if (PkDangNhap?.idChuyenKhoa === 4 && form.ArrKB?.length > 0) {
          const formatData = form.ArrKB?.map((item) => ({ ...item, idbndv: item?.idbndv ?? 0 }))
          yield call(() => cashierService.putBenhNhanListDVMat(formatData))
        } else if (form.ArrKB?.length > 0) {
          yield call(() => cashierService.putBenhNhanListDV(form.ArrKB))
        }

        form.ArrXN?.length > 0 && (yield call(() => cashierService.putBenhNhanListXN(form.ArrXN)))
        form.ArrCĐHA?.length > 0 &&
          (yield call(() => cashierService.putBenhNhanListCDHA(form.ArrCĐHA)))
        form?.ArrDvSDThe?.length > 0 &&
          form.BNCTTT?.idthe &&
          (yield call(() => recieveService.postListDVApplyTTV(form?.ArrDvSDThe)))

        //tiếp nhận chỉ có siêu âm, siêu âm xog, ra lễ tân tiếp nhận đi khám lại, update trangthaikhambenh = 1 để vào phòng khám bs
        form?.checkDiKhamLai &&
          (yield call(() =>
            childrenClinicService.updateDetailBN({
              idbnttc: form?.checkDiKhamLai?.idbnttc,
              trangthaikhambenh: 1,
            }),
          ))
        if (form.TTT) {
          //yield call(() => cashierService.postTruTienTheThanhToan(form.TTT))
        }
        //add benh nhan voucher
        if (form.BNTT.mavoucher?.trim().length > 0 && form.BNTT?.listMaVC?.length > 0) {
          const formatData = [...form.ArrCĐHA, ...form.ArrXN, ...form.ArrKB].map((item) => ({
            mavoucher: form.BNTT?.listMaVC[0], //form.BNTT.mavoucher?.trim(),
            idbn: form.idbn,
            iddv: item?.idChiDinh ?? item?.idbnxn ?? item?.iddichvu ?? item?.idcdha,
          }))
          yield call(() => recieveService.addBenhNhanVoucher(formatData))
          yield call(() => cardService.putSuDungVoucher(form.BNTT?.listMaVC, form.BNTT.thuNgan))
          yield put({
            type: typeAction.UPDATE_NEW_PRICE_CHI_DINH_CASHIER,
            payload: [],
          })
        }

        //add voucher doi tac
        if (form.BNTT.voucherDoiTac && form.BNTT.voucherDoiTac?.maVoucher?.trim().length > 0) {
          const formatData = {
            mavoucher: form.BNTT.voucherDoiTac?.maVoucher?.trim(),
            idct: form.BNTT.voucherDoiTac?.idctDoiTac,
            idcn: form.BNTT.idcn,
            nguoitao: form.BNTT.thuNgan,
            giatri: form.BNTT.voucherDoiTac?.giaVoucher,
          }
          // formPay.maVoucher = form.BNTT.voucherDoiTac?.maVoucher?.trim()
          yield call(() => recieveService.addVoucherDoiTac(formatData))
          yield call(() =>
            cardService.putSuDungVoucher(
              [form.BNTT.voucherDoiTac?.maVoucher?.trim()],
              form.BNTT.thuNgan,
            ),
          )
          yield put({
            type: typeAction.DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC_CASHIER,
            payload: null,
          })
        }

        if (formPrint.isPrint)
          yield call(() => {
            // formPrint.BNTTC = { ...formPrint.BNTTC, sttKham: formPrint.BNTTC.sttkham }
            // formPrint.BNTT = { ...formPrint.BNTT, maPhieuThu: '' + formPrint.BNTT.idbntt }

            // const dataFormat = formPrint.ArrCD.map((item) => ({ // in tất cả các chỉ định gồm đã thu và chưa thu
            //   dvt: item?.donvitinh ?? item?.dvt,
            //   dongia: item?.dongia,
            //   tenChiDinh: item?.tendichvu ?? item?.tenxn ?? item?.tencdha,
            //   tienThanhToan: item?.tienthanhtoan,
            // }))
            let dataFormat = []
            formPrint.ArrCD.map((item) => {
              // chỉ in những chỉ định chưa thu
              if (item.trangthaitt !== 2) {
                dataFormat.push({
                  dvt: item?.donvitinh ?? item?.dvt,
                  dongia: item?.dongia,
                  tenChiDinh: item?.tendichvu ?? item?.tenxn ?? item?.tencdha,
                  tienThanhToan: item?.tienthanhtoan,
                })
              }
            })
            // change total price
            const tienThanhToanNew = dataFormat.reduce((a, b) => a + b.tienThanhToan, 0)
            generatePhieuThuTiepNhan(
              formPrint.BNTTC,
              dataFormat,
              { ...formPrint.BNTT, tienThanhToan: tienThanhToanNew },
              {},
              formPrint.PkDangNhap,
            )
          })
        yield put({
          type: typeAction.RESET_PHIEUTHU_CASHIER,
        })
        const result = yield call(
          () => cashierService.getPhieuDaThuTabThuNgan(idcn),
          // cashierService.getPhieuThu(
          //   today.format('YYYY-MM-DD'),
          //   today.format('YYYY-MM-DD'),
          //   `idChiNhanhs=${idcn}`,
          // ),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_PHIEU_DA_THU,
          payload: result.data,
        })
        yield put({
          type: typeAction.DISPATCH_SET_ARR_KB_CASHIER,
          payload: [],
        })
        yield put({
          type: typeAction.DISPATCH_SET_ARR_XN_CASHIER,
          payload: [],
        })
        yield put({
          type: typeAction.DISPATCH_SET_ARR_CDHA_CASHIER,
          payload: [],
        })
        Toast.fire({
          icon: 'success',
          title: 'Thanh toán thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.THANH_TOAN_TAB_THU_NGAN,
          true,
          MENU_CONSTANTS.TIEP_NHAN,
          null,
          {
            tenbn,
            mabn,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Thanh toán thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TIEP_NHAN,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(
    typeAction.GET_DANH_SACH_PHIEU_THU_CASHIER,
    function* branchLogin({ type, form, to, idChiNhanh, setIsLoadingPhieuDaThu }) {
      try {
        yield call(() => setIsLoadingPhieuDaThu(true))
        const result = yield call(
          () => cashierService.getPhieuDaThuTabThuNgan(idChiNhanh),
          // cashierService.getPhieuThu(form, to, `idChiNhanhs=${idChiNhanh}`),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_PHIEU_DA_THU,
          payload: result.data,
        })
      } catch (error) {
        console.log(error)
      } finally {
        yield call(() => setIsLoadingPhieuDaThu(false))
      }
    },
  )
  // tính lại giá dịch vụ khi khách có thẻ thành viên
  yield takeLatest(
    typeAction.CALCULATOR_SERVICE_BY_MEMBERCARD,
    function* branchLogin({ type, idthe, idbnttc, mabn, tenbn }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT060'),
        )

        yield call(() => cashierService.putServiceByTTV(idthe, idbnttc))
        const dv = yield call(() => cashierService.getDicVuByBNTTC(idbnttc))
        yield put({
          type: typeAction.DISPATCH_SET_ARR_KB_CASHIER,
          payload: dv.data,
        })
        const xn = yield call(() => cashierService.getXNByBNTTC(idbnttc))
        yield put({
          type: typeAction.DISPATCH_SET_ARR_XN_CASHIER,
          payload: xn.data,
        })
        const cdha = yield call(() => cashierService.getCDHAByBNTTC(idbnttc))
        yield put({
          type: typeAction.DISPATCH_SET_ARR_CDHA_CASHIER,
          payload: cdha.data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'success',
          title: 'Tính lại thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.TINH_LAI_TIEN_THU_NGAN,
          true,
          MENU_CONSTANTS.TIEP_NHAN,
          null,
          {
            mabn,
            tenbn,
          },
        )
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails({
          idthe,
          idbnttc,
          mabn,
          tenbn,
        })} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.TIEP_NHAN,
          detailErrorPayload,
        )
      }
    },
  )

  // Apply VOUCHER add thêm cho phieu đã thu
  yield takeLatest(
    typeAction.POST_APPLY_VOUCHER_CHIDINH_DATHU,
    function* postApllyVoucherChoChiDinhDaThuSaga({
      type,
      form,
      setisLoading,
      setisApply,
      setinfoListDV,
      setinfoBNTT,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => setisLoading(true))
        const { DetailTT, infoBNcuaPT, BNTTC } = yield select((state) => state.cashierReducer)
        const { data } = yield call(() =>
          cashierService.postApllyVoucherChoChiDinhDaThu([
            {
              mavoucher: form?.voucer,
              idbnttc: DetailTT?.idbnttc,
              iddt: BNTTC?.benhNhan?.idDoiTuong ?? infoBNcuaPT?.idcn, //BNTTC?.benhNhan?.idDoiTuong,
              idcn: PkDangNhap?.idChiNhanh ?? infoBNcuaPT?.idcn,
              idgt:
                ((infoBNcuaPT?.gioitinh === `Nam` && 1) ||
                  (infoBNcuaPT?.gioitinh === `Nữ` && 2) ||
                  (infoBNcuaPT?.gioitinh === `Khác` && 3)) ??
                -1,
            },
          ]),
        )
        const listCdGiamNew = data?.filter((item) => item?.giatrigiam)
        if (!listCdGiamNew?.length) {
          yield put({
            type: typeAction.CLOSE_LOADING_PAGE,
          })
          yield call(() => setisLoading(false))
          ToastCus.fire({
            icon: 'error',
            title: 'Không có dịch vụ nào được giảm!',
          })
          return
        }
        let listCdNew = []
        form?.listCD?.map((item) => {
          const infoNew = listCdGiamNew?.find(
            (itemNew) =>
              itemNew?.idchidinh === item?.idcd && itemNew?.loaivoucherdichvu === item?.loaicd,
          )
          listCdNew.push({
            ...item,
            ptgiam: infoNew?.phantramgiam ?? item?.ptgiam,
            tiengiam: infoNew?.giatrigiam ?? item?.tiengiam,
            thanhtoan: infoNew?.giatri ?? item?.thanhtoan,
          })
        })
        setinfoListDV(listCdNew)
        setinfoBNTT((prev) => {
          const tongDonGia = listCdNew?.reduce((tong, item) => (tong += item?.dongia ?? 0), 0)
          const tongTienGiam = listCdNew?.reduce((tong, item) => (tong += item?.tiengiam ?? 0), 0)
          let tongTienGiamNew = listCdGiamNew?.reduce(
            (tong, item) => (tong += item?.giatrigiam ?? 0),
            0,
          )
          let info = {
            ...prev,
            tiengiam: tongTienGiam,
            tiengiamdoitac: prev?.tiengiamdoitac,
            thanhtoan: tongDonGia - tongTienGiam - prev?.tiengiamdoitac,
          }
          const listHinhThuc = [
            'tienmat',
            'chuyenkhoan',
            'thett',
            'momo',
            'vnpay',
            'zalopay',
            'maypos',
          ]
          listHinhThuc?.map((item) => {
            if (tongTienGiamNew) {
              if (prev[item] >= tongTienGiamNew) {
                info[item] = prev[item] - tongTienGiamNew
                tongTienGiamNew = 0
              } else {
                info[item] = 0
                tongTienGiamNew -= prev[item]
              }
            } else {
              return
            }
          })
          return info
        })
        yield call(() => setisApply(true))
        ToastCus.fire({
          icon: 'success',
          title: 'Áp dụng voucher thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.APPLY_VOUCHER_CHI_TIET_PHIEU_THU,
          true,
          MENU_CONSTANTS.TIEP_NHAN,
          null,
          {
            maphieuthu: form.maphieuthu,
            mavoucher: form?.voucer,
          },
        )
      } catch (err) {
        console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: err?.response?.data?.message ?? 'Áp dụng voucher thất bại!',
        })
        const apiUrl = err?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Mã voucher: ${form.voucer} \nAPI request: ${apiUrl} \n RESPONSE: ${err?.response?.data?.message || err?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.TIEP_NHAN,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        yield call(() => setisLoading(false))
      }
    },
  )
  yield takeLatest(
    typeAction.PUT_PHIEUTHU_ADD_VOUCHER,
    function* putPhieuThuAddVoucherSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const { DetailTT, infoBNcuaPT, BNTTC } = yield select((state) => state.cashierReducer)
        const listVc = [...(infoBNcuaPT?.mavoucher ?? '')?.split(','), ...[form?.voucer]]?.filter(
          (item) => item?.length,
        )
        let maVoucherNew = ''
        listVc?.map(
          (item) => (maVoucherNew = `${maVoucherNew?.length ? maVoucherNew + ',' : ''}${item}`),
        )
        yield call(() =>
          cashierService.putPhieuThuAddVoucher({
            idbnttc: BNTTC?.idbnttc,
            tiengiamgia: form?.infoTT?.tiengiam,
            tienthanhtoan: form?.infoTT?.thanhtoan,
            tienmat: form?.infoTT?.tienmat,
            chuyenkhoan: form?.infoTT?.chuyenkhoan,
            momo: form?.infoTT?.momo,
            vnpay: form?.infoTT?.vnpay,
            zalopay: form?.infoTT?.zalopay,
            pos: form?.infoTT?.maypos,
            thett: form?.infoTT?.thett,
            mavoucher: maVoucherNew,
            updateGiamChiDinhVoucherDTOs: form?.listCD?.map((item) => ({
              idchidinh: item?.idcd,
              loaichidinh: item?.loaicd,
              ptgiamgia: item?.ptgiam,
              tiengiamgia: item?.tiengiam,
              tienthanhtoan: item?.thanhtoan,
            })),
          }),
        )
        yield call(() => cardService.putSuDungVoucher([form?.voucer], infoUser?.tenNV))
        yield put(
          detailBNCashier(
            BNTTC?.idbn,
            infoBNcuaPT?.idbntt,
            BNTTC?.idbnttc,
            PkDangNhap?.idChuyenKhoa,
          ),
        )
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  yield takeLatest(
    typeAction.PUT_DOICD_CUNGGIA_CUNGNHOM_CASHIER,
    function* putDoiChiDinhCungGiaCungNhomSaga({ type, form, resetDoiCd }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })

        const { BNTTC, infoBNcuaPT } = yield select((state) => state.cashierReducer)
        form?.infoCd?.loaiCd === 1 &&
          (yield call(() =>
            cashierService.putDoiDVCungGia({
              idbndv: form?.infoCd?.idbnCd,
              iddichvu: form?.idCdNew?.idcd,
              madichvu: form?.idCdNew?.macd,
              tendichvu: form?.idCdNew?.tencd,
              nguoidoi: infoUser?.idnv,
              idnhomdv: form?.idCdNew?.info?.idNhomDV, // để sản hoặc phụ khoa đổi qua ngược lại
              loaichidinh: form?.idCdNew?.info?.dichVu?.loaiChiDinh,
              donvitinh: form?.idCdNew?.info?.dichVu?.donVi,
              // chuyenkhoa:,
            }),
          ))
        form?.infoCd?.loaiCd === 2 &&
          (yield call(() =>
            cashierService.putDoiXNCungGia({
              idbnxn: form?.infoCd?.idbnCd,
              idxn: form?.idCdNew?.idcd,
              maxn: form?.idCdNew?.macd,
              tenxn: form?.idCdNew?.tencd,
              nguoidoi: infoUser?.idnv,
            }),
          ))
        form?.infoCd?.loaiCd === 3 &&
          (yield call(() =>
            cashierService.putDoiCDHACungGia({
              idbncdha: form?.infoCd?.idbnCd,
              idcdha: form?.idCdNew?.idcd,
              macdha: form?.idCdNew?.macd,
              tencdha: form?.idCdNew?.tencd,
              nguoidoi: infoUser?.idnv,
            }),
          ))
        yield call(() => resetDoiCd())
        yield put(
          detailBNCashier(
            BNTTC?.idbn,
            infoBNcuaPT?.idbntt,
            BNTTC?.idbnttc,
            PkDangNhap?.idChuyenKhoa,
          ),
        )
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        console.log(form)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.DOI_CHI_DINH_THU_NGAN,
          true,
          MENU_CONSTANTS.TIEP_NHAN,
          null,
          {
            tencd: form?.idCdNew?.tencd,
          },
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.TIEP_NHAN,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
