import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, DatePicker, Input, Select, Table, TreeSelect } from 'antd'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import { SearchOutlined } from '@ant-design/icons'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { branchService } from '../../../../services/branch/branchService'
import { reportService } from '../../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import { exportExcelformatSumCol } from '../../../../utils/export/exportExcelFormat'
import { formattedNumber } from '../../../../utils/formattedNumber'
import ToastCus from '../../../../utils/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
import { getListNameChiNhanh } from '../../../../utils/algorithm'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

function GongKinh() {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState(['all'])
  const [dataReport, setDataReport] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [search, setSearch] = useState('')

  const nameChiNhanh = useMemo(() => getListNameChiNhanh(value, branch), [value, branch])
  const nameCompany = useMemo(
    () => listCompany?.find((item) => item?.idct === company)?.tenct,
    [company, listCompany],
  )

  const fetchReport = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT532',
      )
      const { data } = await reportService.getListGongKinhDaBan(
        dateForm,
        dateTo,
        (value?.includes('all') || !value?.length ? branch : value)
          ?.map(
            (item) =>
              `idChiNhanhs=${value?.includes('all') || !value?.length ? item?.idChiNhanh : item}`,
          )
          ?.join('&')
          ?.toString(),
        search?.length ? search?.trim() : null,

        //value?.includes('all') ? branch?.map((item) => item?.idChiNhanh) : value,
      )
      setDataReport(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.BAO_CAO_GONG_KINH,
        true,
        MENU_CONSTANTS.GONG_KINH,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          keyword: search ?? '',
          nameCompany,
          nameChiNhanh,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.GONG_KINH,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }

  const fetchBranch = async (id) => {
    try {
      setIsLoading(true)
      const result = await branchService.getBranchNotVP(id)
      setBranch(result.data?.filter((item) => item?.idChuyenKhoa === 4))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT533',
      )

      const formatData = dataReport?.map((item, index) => ({
        'STT ': ++index,
        'Chi nhánh': item?.chinhanh,
        'Mã hàng': item?.mahang,
        'Tên gọng': item?.tengong,
        'Mã bệnh nhân': item?.mabenhnhan,
        'Tên bệnh nhân': item?.tenbenhnhan,
        'Ngày bán': item?.ngayban ? moment(item?.ngayban).format('DD/MM/YYYY') : '-',
        'ĐVT ': item?.dvt,
        'Số lượng': item?.soluongban,
        'Giá bán': formattedNumber(item?.giaban ?? 0), //item?.,
        'Thành tiền': formattedNumber(item?.thanhtien ?? 0), //item?.,
        // '% Giảm':
        //   item?.tiengiam >= 0 && item?.thanhtien > 0
        //     ? Math.round(((Number(item?.tiengiam) * 100) / Number(item?.thanhtien)) * 100) / 100
        //     : 0,
        // 'Tiền giảm': formattedNumber(item?.tiengiam ?? 0), //item?.,
        'Thanh toán': formattedNumber(item?.thanhtoan ?? 0), //item?.thanhtoan,
      }))
      const name = 'BÁO CÁO GỌNG KÍNH ĐÃ BÁN'
      if (formatData?.length)
        exportExcelformatSumCol(formatData, name, [
          'Số lượng',
          'Thành tiền',
          // 'Tiền giảm',
          'Thanh toán',
        ])
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_BAO_CAO_GONG_KINH,
        true,
        MENU_CONSTANTS.GONG_KINH,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          keyword: search ?? '',
          nameCompany,
          nameChiNhanh,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const detailErrorPayload = `Có lỗi xảy ra khi xuất file excel báo cao gọng kính \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.GONG_KINH,
        detailErrorPayload,
      )
    }
  }
  const onLoad = () => {
    setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    if (!value || !value?.length) setValue(['all'])
    fetchReport()
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranch(id_company)
  }, [])
  useEffect(() => {
    if (branch?.length && infoUser) fetchReport()
  }, [branch, infoUser])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'chinhanh',
      key: 'chinhanh',
      fixed: 'left',
      width: 250,
    },
    {
      title: 'Mã hàng',
      dataIndex: 'mahang',
      key: 'mahang',
      width: 140,
    },
    {
      title: 'Tên gọng',
      dataIndex: 'tengong',
      key: 'tengong',
    },
    {
      title: 'Mã bệnh nhân',
      dataIndex: 'mabenhnhan',
      key: 'mabenhnhan',
      width: 150,
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'tenbenhnhan',
      key: 'tenbenhnhan',
      width: 250,
    },
    {
      title: 'Ngày bán',
      dataIndex: 'ngayban',
      key: 'ngayban',
      width: 100,
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
    },
    {
      title: 'ĐVT',
      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      width: 60,
    },
    {
      title: 'Số lượng',
      dataIndex: 'soluongban',
      key: 'soluongban',
      align: 'center',
      width: 70,
    },
    {
      title: 'Giá bán',
      dataIndex: 'giaban',
      key: 'giaban',
      align: 'right',
      width: 100,
      render: (text) => formattedNumber(text ?? 0),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'thanhtien',
      key: 'thanhtien',
      align: 'right',
      width: 100,
      render: (text) => formattedNumber(text ?? 0),
    },
    // {
    //   title: '% Giảm',
    //   dataIndex: 'ptgiam',
    //   key: 'ptgiam',
    //   align: 'center',
    //   width: 70,
    //   render: (text, record) =>
    //     record?.tiengiam >= 0 && record?.thanhtien > 0
    //       ? Math.round(((Number(record?.tiengiam) * 100) / Number(record?.thanhtien)) * 100) / 100
    //       : 0,
    // },
    // {
    //   title: 'Tiền giảm',
    //   dataIndex: 'tiengiam',
    //   key: 'tiengiam',
    //   align: 'right',
    //   width: 100,
    //   render: (text) => formattedNumber(text ?? 0),
    // },
    {
      title: 'Thanh toán',
      dataIndex: 'thanhtoan',
      key: 'thanhtoan',
      align: 'right',
      fixed: 'right',
      width: 100,
      render: (text) => formattedNumber(text ?? 0),
    },
  ]

  return (
    <>
      <div className="flex gap-2">
        <div className="w-[17rem] flex items-center gap-2">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-lg">-</span>
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        <div className="w-[17rem]">
          <Select
            showSearch
            value={company}
            onChange={(value) => {
              setCompany(value)
              fetchBranch(value)
              setValue(['all'])
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-full"
          />
        </div>
        <div className="w-[17rem] flex gap-2">
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
          />
        </div>
        <div className="flex w-64">
          <Input
            value={search}
            placeholder="Nhập mã hàng, tên hàng..."
            onChange={(e) => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </div>
        <div className="flex gap-2">
          <IconButton
            disabled={isLoading}
            onClick={onLoad}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        <div className="font-semibold ">
          <Button
            disabled={isLoading || !dataReport?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div className="mt-2">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              // x: 1900,
              y: 682,
            }}
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={dataReport}
            columns={columns}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  {columns?.map((item, index) => (
                    <>
                      <Table.Summary.Cell
                        className={
                          ['thanhtien', 'thanhtoan'].includes(item?.key)
                            ? 'text-end'
                            : 'text-center'
                        }
                        index={index}
                      >
                        <p className={item?.key === 'thanhtoan' ? 'pr-[1.1rem]' : ''}>
                          {['soluongban', 'thanhtien', 'thanhtoan'].includes(item?.key)
                            ? formattedNumber(
                                //thành tiền
                                dataReport?.reduce((tong, row) => (tong += row[item?.key]), 0),
                              )
                            : ''}
                        </p>
                      </Table.Summary.Cell>
                    </>
                  ))}
                  {/* <Table.Summary.Cell className="text-right" index={11}>
                    <span className="font-semibold">
                      {formattedNumber(
                        //thành tiền
                        dataReport?.reduce((tong, item) => (tong += item?.tiengiacong), 0),
                      )}
                    </span>
                  </Table.Summary.Cell> */}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default GongKinh
