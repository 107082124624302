import CachedIcon from '@mui/icons-material/Cached'
import { Button, DatePicker, Input, Select, TreeSelect } from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listBranchAction } from '../../../../store/actions/BranchAction'
import { branchNhapKhoPT } from '../../../../store/actions/NhapKhoAction'
import { SearchOutlined } from '@ant-design/icons'
import { NhapKhoService } from '../../../../services/NhapKho/NhapKhoService'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import TableHang from './TableHang'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import 'moment/locale/vi'
import { addLog } from '../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../utils/log/logConstant'
import { branchService } from '../../../../services/branch/branchService'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
const { SHOW_PARENT } = TreeSelect
moment.locale('vi')
const ChiTietHangHoa = () => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const today = moment()
  const dateFormat = 'DD-MM-YYYY'
  const formatDateDB = 'YYYY-MM-DD'
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const dispatch = useDispatch()
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [branch, setBranch] = useState([])
  const [since, setSince] = useState(today.format(formatDateDB))
  const [toDate, SetToDate] = useState(today.format(formatDateDB))
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [idChiNhanh, setIdChiNhanh] = useState([+BranchLogin])
  const [valueExport, setValueExport] = useState([])
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState(-1)
  const [data, setData] = useState([])
  const [loadingChiTiet, setLoadingChiTiet] = useState(false)
  const [search, setSearch] = useState(null)

  const maQuyenXemDs = 'QHT167'
  const maQuyenXuatExcel = 'QHT168'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        handleFilter(search, company, idChiNhanh, since, toDate)
        break
      case maQuyenXuatExcel:
        exportToExcel()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXuatExcel:
        //
        break
      default:
        return false
    }
  }

  const exportToExcel = () => {
    try {
      const formattedData = valueExport.map((item) => ({
        'Mã Phiếu': item.maphieu,
        'Tên phiếu': item.tenphieu,
        'Số hoá đơn': item.sohoadon,
        'Ngày hoá đơn': item.ngayhoadon && moment(item.ngayhoadon).toDate(),
        'Chi nhánh': item.tenchinhanh,
        'Ngày nhập': item.ngaynhan && moment(item.ngaynhan).toDate(),
        'kho nhập': item.tenkho,
        'Mã đối tác': item.madoitac,
        'Tên đối tác': item.tendoitac,
        'Mã hàng': item.mathuoc,
        'Tên biệt dược': item.tenbietduoc,
        'SL chẵn': Math.floor(item?.soluongle / item?.soluongdonggoi) ?? 0,
        'Đơn vị chẵn': item?.donvichan,
        'Đơn giá chẵn': item?.dongiamua * item?.soluongdonggoi,
        'Quy cách': item.quycach,
        'Số lượng lẻ': item.soluongle,
        'Đơn vị tính': item.dvt,
        'Đơn giá lẻ': item.dongiamua,
        'Tổng tiền': item?.dongiamua * item?.soluongle,
        'P. Gia công': item?.phigiacong,
        'P. Vận chuyển': item?.phivanchuyen,
        '% CK Trước VAT': item?.ptcktruocvat,
        'Tiền CK trước VAT': item?.cktruocvat,
        'Tổng trước VAT': item?.tongtientruocvat,
        VAT: item?.vaT5 !== 0 ? '5%' : item?.vaT8 !== 0 ? '8%' : item?.vaT10 !== 0 ? '10%' : '0%',
        'Tiền % VAT':
          item?.vaT5 !== 0
            ? item?.vaT5
            : item?.vaT8 !== 0
              ? item?.vaT8
              : item?.vaT10 !== 0
                ? item?.vaT10
                : 0,
        'Thành tiền': item?.thanhtien,
        'Thực trả': item?.thuctra,
        'Nhiệt độ': item?.nhietdo,
        'Mã số đăng kí': item?.masodangky,
        'Số lô': item.solo,
        'Hạn dùng': item.handung && moment(item.handung).format('DD/MM/YYYY'),
        'Ghi chú': item.ghichu,
      }))
      const name = 'Danh Sách chi tiết hàng'
      const currencyColumns = [
        'SL chẵn',
        'Số lượng lẻ',
        'Đơn giá chẵn',
        'Đơn giá lẻ',
        'Tổng tiền',
        'P. Gia công',
        'P. Vận chuyển',
        'Tiền % VAT',
        'Tiền CK trước VAT',
        'Tổng trước VAT',
        'Thành tiền',
        'Thực trả',
      ];
      exportExcelformat(formattedData, name,currencyColumns)
      const nameCty = listCompany.find((item) => item.idct === company)?.tenct
      const nameChiNhanh =
        idChiNhanh === ''
          ? 'Tất cả'
          : branch.find((item) => item.idChiNhanh === idChiNhanh)?.tenChiNhanh
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_LIST_CHI_TIET_HANG_NHAP_KHO,
        true,
        MENU_CONSTANTS.NHAP_KHO,
        null,
        {
          dateFrom: since,
          dateTo: toDate,
          keyword: search ?? '',
          nameCty,
          nameChiNhanh,
        },
      )
    } catch (error) {
      console.log(error)
      const detailErrorPayload = `Có lỗi xảy ra khi xuất excel \n ${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.NHAP_KHO,
        detailErrorPayload,
      )
    }
  }
  const handleDatePicker = (data, dateString) => {
    setSince(moment(dateString[0], dateFormat).format(formatDateDB))
    SetToDate(moment(dateString[1], dateFormat).format(formatDateDB))
  }

  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (idct, value) => {
    try {
      const { data } = await branchService.getBranchsByIdCtyAndIdChuyenKhoa(idct, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleCompany = (value) => {
    setCompany(value)
    fetchBranchByChuyenKhoa(value, '')
    setIdChuyenKhoa(-1)
    setIdChiNhanh(['all'])
  }
  // xử lí chọn chi nhánh
  const handleSelected = (value) => {
    setIdChiNhanh(value)
  }
  const getListChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setListChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  // xử lí lọc dưc liệu
  const handleFilter = async (keyword, idct, idChinhanh, tuNgay, denNgay) => {
    try {
      setLoadingChiTiet(true)
      let idcn = ''
      if (idChinhanh[0] !== 'all') {
        idcn = idChinhanh?.map((id) => `idcn=${id}`).join('&')
      } else {
        idChinhanh = branch?.map((item) => item.idChiNhanh)
        idcn = idChinhanh?.map((id) => `idcn=${id}`).join('&')
      }

      const { data } = await NhapKhoService.getDetailKhoNhap(keyword, idct, idcn, tuNgay, denNgay)
      const newData = data?.map((item) => ({
        ...item,
        soLuongChan: Math.floor(item?.soluongle / item?.soluongdonggoi) ?? 0,
        donGiaChan: formatNumberVND(item?.dongiamua * item?.soluongdonggoi) || 0,
        tongTien: formatNumberVND(item?.dongiamua * item?.soluongle) || 0,
        vat: item.vaT5 !== 0 ? '5%' : item.vaT8 !== 0 ? '8%' : item.vaT10 !== 0 ? '10%' : '0%',
        phanTramVat:
          item?.vaT5 !== 0
            ? Math.round(item?.vaT5)
            : item?.vaT8 !== 0
              ? Math.round(item?.vaT8)
              : Math.round(item?.vaT10),
      }))
      setData(newData)
      const nameCty = listCompany.find((item) => item.idct === idct)?.tenct
      const nameChiNhanh =
        idcn === '' ? 'Tất cả' : branch.find((item) => item.idChiNhanh === idcn)?.tenChiNhanh
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_LIST_CHI_TIET_HANG_NHAP_KHO,
        true,
        MENU_CONSTANTS.NHAP_KHO,
        null,
        {
          dateFrom: tuNgay,
          dateTo: denNgay,
          keyword: keyword ?? '',
          nameCty,
          nameChiNhanh,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.NHAP_KHO,
        detailErrorPayload,
      )
    } finally {
      setLoadingChiTiet(false)
    }
  }
  const onSearch = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  // search
  const debounceDropDown = useCallback(
    _.debounce((e) => {
      setSearch(e.target.value.trim())
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
      })),
    },
  ]
  const tProps = {
    treeData,
    value: idChiNhanh,
    onChange: setIdChiNhanh,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  useEffect(() => {
    if (branch.length > 0) {
      handleFilter(search, company, idChiNhanh, since, toDate)
    }
  }, [search, branch])
  useEffect(() => {
    dispatch(branchNhapKhoPT(company))
    dispatch(listBranchAction())
    getListChuyenKhoa()
    fetchBranchByChuyenKhoa(company, '')
  }, [])
  useEffect(() => {
    setValueExport(data)
  }, [data])
  return (
    <>
      <div className="flex justify-between mb-5">
        <div className="flex gap-3 items-center w-full">
          <Input
            onChange={debounceDropDown}
            placeholder="Tìm mã phiếu, tên phiếu nhập, số hóa đơn"
            allowClear
            prefix={
              <SearchOutlined
                style={{
                  color: 'rgba(0,0,0,.25)',
                }}
              />
            }
          />
          <Select
            onChange={handleCompany}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            value={company}
            className="min-w-64"
          />
          <Select
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={idChuyenKhoa}
            showSearch
            onChange={(value) => {
              fetchBranchByChuyenKhoa(company, value)
              setIdChiNhanh(['all'])
              setIdChuyenKhoa(value)
            }}
            defaultValue={-1}
            options={[
              { label: 'Tất cả', value: -1 },
              ...listChuyenKhoa?.map((item) => ({
                label: item.tenChuyenKhoa,
                value: item.idChuyenKhoa,
              })),
            ]}
            className="min-w-64"
          />
          <div className="min-w-64">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <DatePicker.RangePicker
            className="min-w-60"
            allowClear={false}
            value={[dayjs(since, formatDateDB), dayjs(toDate, formatDateDB)]}
            format={dateFormat}
            onChange={handleDatePicker}
          />
          <Button type="primary" onClick={onSearch} icon={<CachedIcon sx={{ fontSize: 25 }} />}>
            Tìm
          </Button>
          <Button
            disabled={!valueExport?.length}
            // onClick={exportToExcel}
            onClick={() =>
              dispatch(
                putCheckQuyenThaoTacAction(maQuyenXuatExcel, null, actionIsTrue, actionIsFalse),
              )
            }
            icon={<FileDownloadIcon sx={{ fontSize: 25 }} />}
          >
            Xuất excel
          </Button>
        </div>
      </div>
      <div className=" h-full">
        <TableHang data={data} loadingChiTiet={loadingChiTiet} setValueExport={setValueExport} />
      </div>
    </>
  )
}

export default ChiTietHangHoa
