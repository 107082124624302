export const actionStatus = {
  GET: 'Xem',
  POST: 'Lưu',
  PUT: 'Chỉnh sửa',
  DELETE: 'Xoá',
  Error: 'Lỗi',
  EXPORT: 'Xuất',
}

export const MENU_CONSTANTS = {
  DANG_NHAP: 'Đăng nhập',
  NHAN_VIEN: 'Hệ thống/Nhân viên',
  // KHACH_HANG
  KHACH_HANG: 'Khách hàng/danh sách khách hàng',
  THE_THANH_VIEN: 'Khách hàng/Thẻ thành viên',
  VOUCHER: 'Khách hàng/Voucher',
  TAIKHAM: 'Khách hàng/Tái khám',
  SINHNHAT: 'Khách hàng/Sinh nhật',
  DOITAC: 'Khách hàng/Đối tác',
  DANGKYKHAMONLINE: 'Khách hàng/Đăng ký khám online',
  VAI_TRO_NGUOI_DUNG: 'Hệ thống/Vai trò người dùng',
  NGUOI_DUNG: 'Hệ thống/Người dùng',
  DOI_MAT_KHAU: 'Hệ thống/Đổi mật khẩu',
  LICH_SU_NGUOI_DUNG: 'Hệ thống/Lịch sử người dùng',
  PHIEN_BAN: 'Hệ thống/Phiên bản',
  CAP_KEY: 'Hệ thống/Cấp key',
  PHAN_QUYEN: 'Hệ thống/Phân quyền',
  TIEP_NHAN: 'Tiếp nhận/Thu ngân',

  //PHÒNG KHÁM
  PHONGKHAM: 'Phòng khám',
  KHAMNHI: 'Phòng khám/Khám nhi',
  KHAMSAN: 'Phòng khám/Khám sản',
  KHAMTMTD: 'Phòng khám/Khám TMTD',
  KHAMPHCN: 'Phòng khám/Khám PHCN',
  DOKHUCXA: 'Phòng khám/Đo khúc xạ',
  KHAMSUCKHOE: 'Phòng khám/Khám sức khỏe',
  //TRA CỨU
  TRACUU_BENH_NHAN: 'Tra cứu/Bệnh nhân',
  THE_THANH_VIEN_TIEP_NHAN: 'Tiếp nhận/Thẻ thành viên',
  TRACUU_SO_KHAM_BENH: 'Tra cứu/Sổ khám bệnh',
  TRACUU_BENH_AN_MAT: 'Tra cứu Bệnh án mắt',

  //THU MUA - KHO
  NHAP_KHO: 'Thu mua - Kho/Nhập kho',
  CHUYEN_KHO: 'Thu mua - Kho/Chuyển kho',

  //CAN LAM SANG
  XET_NGHIEM: 'Cận lâm sàng/Xét nghiệm',
  TRONG_KINH: 'Cận lâm sàng/Tròng kính',
  SIEU_AM: 'Cận lâm sàng/Siêu âm',
  MORNITOR: 'Cận lâm sàng/Monitor',
  VLTL: 'Cận lâm sàng/Vật lý trị liệu',

  THUOC_VAT_TU: 'Thu mua - Kho/Thuốc-vật tư',
  GIA_BAN: 'Thu mua - Kho/Giá bán',
  CHI_NHANH_BAN: 'Thu mua - Kho/Chi nhánh bán',
  TON_KHO: 'Thu mua - Kho/Tồn kho',
  THE_KHO: 'Thu mua - Kho/Thẻ kho',
  MA_SO_DANG_KY: 'Thu mua - Kho/Mã số đăng ký',
  GPP: 'Thu mua - Kho/GPP',
  GIA_NHAP: 'Thu mua - Kho/Giá nhập',
  CHUYEN_KHO_CONG_TY: 'Thu mua - Kho/Chuyển kho CTY',
  XUAT_SU_DUNG: 'Thu mua - Kho/Xuất sử dụng',
  DIEU_CHINH_TON_KHO: 'Thu mua - Kho/Điều chỉnh tồn kho',
  KHO_KIEM_KE: 'Thu mua - Kho/Kho kiểm kê',
  TON_HANH_HOA: 'Hàng hoá - /Tồn chi tiết hàng hoá',

  //Tiêm chủng
  TIEM_CHUNG: 'Tiêm chủng/Tiêm chủng',
  CANH_BAO: 'Tiêm chủng/Cảnh báo',
  THU_THUAT: 'Tiêm chủng/Thủ thuật',

  //Báo cáo
  DOANH_THU: 'Báo cáo/Doanh thu',
  LOI_NHUAN: 'Báo cáo/Lợi nhuận',
  VAT_TU_TIEU_HAO: 'Báo cáo/Vật tư tiêu hao',
  PHIEU_THU: 'Báo cáo/Phiếu thu',
  LUOT_KHAM: 'Báo cáo/Lượt khám',
  THONG_KE: 'Báo cáo/Thống kê',
  BAO_CAO_DICH_VU: 'Báo cáo/Báo cáo dịch vụ',
  HOA_HONG_THU_THUAT: 'Báo cáo/Hoa hồng thủ thuật',
  BAO_CAO_TTV: 'Báo cáo/Báo cáo TTV',
  BAO_CAO_XET_NGHIEM: 'Báo cáo/Xét nghiệm',
  XUAT_VACXIN: 'Báo cáo/Xuất vắc xin',
  XUAT_NHA_THUOC: 'Báo cáo/Xuất nhà thuốc',
  GONG_KINH: 'Báo cáo/Gọng kính',
  TI_LE_TOA: 'Báo cáo/Tỉ lệ toa',
  DOANH_THU_THUOC: 'Báo cáo/Doanh thu thuốc',
  THOI_GIAN_KHAM: 'Báo cáo/Thời gian khám',
  BAO_CAO_TIEM_CHUNG: 'Báo cáo/Báo cáo tiêm chủng',
  DOANH_THU_DUOC: 'Báo cáo/Doanh thu dược',
  BAO_CAO_HANG_HOA: 'Báo cáo/Báo cáo hàng hoá',
  PHIEU_XUAT: 'Báo cáo/Phiếu xuất',

  //SMS
  GUI_SMS: 'SMS/Gửi SMS',
  BAO_CAO_SMS: 'SMS/Báo cáo SMS',

  //TOOL
  CONG_CU: 'Tiện ích/Công cụ',

  // NHÀ THUỐC
  BANTOA: 'Nhà thuốc/Bán toa',
  BANLE: 'Nhà thuốc/Bán lẻ',
  XUATVACXIN: 'Nhà thuốc/Xuất vắc xin',
  BANNOIBO: 'Nhà thuốc/Bán nội bộ',
  NHAPTRA: 'Nhà thuốc/Nhập trả',
  XUATTRANCC: 'Nhà thuốc/Xuất trả NCC',
  BANGGIA: 'Nhà thuốc/Bảng giá',
  KHONGMUATHUOC: `Nhà thuốc/Không mua thuốc`,
  THUOCKHONGLAY: `Nhà thuốc/Thuốc không lấy`,
  THUOCHETHAN: `Nhà thuốc/Thuốc hết hạn`,
  //DANH MỤC
  CHI_NHANH: 'Danh mục/Chi nhánh',
  DOI_TAC: 'Danh mục/Đối tác',
  DIA_CHI: 'Danh mục/Địa chỉ',
  LOI_DAN: 'Danh mục/Lời dặn',
  ICD_10: 'Danh mục/ICD-10',
  DON_VI_TINH: 'Danh mục/Đơn vị tính',
  KHO: 'Danh mục/Kho',
  HOA_HONG: 'Danh mục/Hoa hồng',
  BO_PHAN: 'Danh mục/Bộ phận',
  LOAI_HANG_HOA: 'Danh mục/Loại hàng hóa',
  DINH_MUC_TIEU_HAO: 'Danh mục/Định mức tiêu hao',
  DANH_MUC_XN: 'Danh mục/Danh mục xét nghiệm',
  DANH_MUC_CDHA: 'Danh mục/Danh mục CĐHA',
  DANH_MUC_DV: 'Danh mục/Danh mục dịch vụ',
  BIEU_MAU: 'Danh mục/Biểu mẫu',
  PHONG_KHAM: 'Danh mục/Phòng khám',

  //Tài chính
  THEO_DOI_PHU_SAN: 'Tài chính/Theo dõi phụ sản',

  //CRM
  CONG_TY: 'CRM/Công ty',
  CHI_TIET_LIEN_HE: 'CRM/Chi tiết liên hệ',
  GOI_KHAM: 'CRM/Gói khám',
  BENH_NHAN: 'CRM/Bệnh nhân',
  GOI_KHAM_KHACH_HANG: 'CRM/Gọi khám khách hàng',
  KHACH_HANG_CRM: 'CRM/Khách hàng',
  PHAN_CONG: 'CRM/Phân công',

  // SU_CO
  SU_CO: 'Báo cáo sự cố/Tạo sự cố',
  KHU_VUC_QUAN_LY: 'Báo cáo sự cố/Khu vực quản lý',
  //VPP
  VAN_PHONG_PHAM: 'vanphongpham/dathang',
}

export const typeActionStatus = {
  SUCCESS: 'Thành công',
  FAIL: 'Thất bại',
}

export const logAction = {
  //Auth
  DANG_NHAP: 'Đăng nhập',
  DOI_MAT_KHAU: 'Đổi mật khẩu',
  DANG_KY_KHAM_ONLINE: 'Đăng ký khám online',

  //bệnh nhân
  CREATE_BENH_NHAN: 'Tạo bệnh nhân',
  TIEP_NHAN_BENH_NHAN: 'Tiếp nhận bệnh nhân',
  UPDATE_BENH_NHAN: 'Cập nhật thông tin bệnh nhân',
  UPDATE_BENH_AN: 'Cập nhật thông tin bệnh án',
  XEM_BENH_AN: 'Xem thông tin bệnh án',
  UU_DAI_TAI_KHAM_BN: 'Ưu đãi tái khám',
  LICH_SU_KHAM_BENH: 'Lịch sử khám bệnh',
  THONG_TIN_TTV_BN: 'Thông tin thẻ thành viên của bệnh nhân',
  THONG_TIN_TTT_BN: 'Thông tin thẻ thanh toán của bệnh nhân',
  APPLY_TTV_CHO_CHI_DINH_KHAM_BENH: 'Apply thẻ thành viên cho chỉ định khám bệnh',
  UPDATE_XHD: 'Cập nhật XHĐ',
  THANH_TOAN_TIEP_NHAN: 'Thanh toán tiếp nhận',

  SUA_MA_BN: 'Sửa mã bệnh nhân',
  TIM_KIEM_BENH_NHAN: 'Tìm kiếm bệnh nhân',

  //phiếu thu
  TAO_PHIEU_THU: 'Lập phiếu thu',
  SUA_PHIEU_THU: 'Sửa phiếu thu',
  XOA_PHIEU_THU: 'Xoá phiếu thu',
  GET_DS_PHIEU_THU: 'Lấy danh sách phiếu thu',
  XUAT_EXCEL_DS_PHIEU_THU: 'Xuất excel danh sách phiếu thu',
  XEM_CHI_TIET_PHIEU_THU: 'Xem chi tiết phiếu thu',
  APPLY_VOUCHER_CHI_TIET_PHIEU_THU: 'Apply voucher cho chi tiết phiếu thu',
  UNAPPLY_VOUCHER_CHI_TIET_PHIEU_THU: 'Huỷ apply voucher cho chi tiết phiếu thu',
  CAP_NHAP_CHI_TIET_THANH_TOAN_PHIEU_THU: 'Cập nhật chi tiết thanh toán phiếu thu',
  CAP_NHAP_CHI_TIET_DICH_VU_PHIEU_THU: 'Cập nhật chi tiết dịch vụ phiếu thu',
  HOAN_PHI_KHAM: 'Hoàn phí khám',
  UPDATE_SINH_HIEU: 'Cập nhật sinh hiệu',
  TINH_LAI_TIEN_THU_NGAN: 'Tính lại tiền thu ngân',
  THANH_TOAN_TAB_THU_NGAN: 'Thanh toán tab thu ngân',
  DOI_CHI_DINH_THU_NGAN: 'Đổi chi định thu ngân',
  CAP_NHAT_PHIEU_THU_XOA_CHI_DINH: 'Cập nhật phiếu thu xóa chi định',

  //tool
  GOP_BENH_AN: 'Gộp bệnh án',

  //ca làm việc
  MO_CA_LAM_VIEC: 'Mở ca làm việc',
  DONG_CA_LAM_VIEC: 'Đóng ca làm việc',

  //email
  BAO_CAO_EMAIL: 'Báo cáo email',

  //tiếp nhận
  HOAN_PHI_KHAM: 'Hoàn phí khám',
  LUU_PHIEU_TIEP_NHAN: 'Lưu phiếu tiếp nhận',
  SUA_STT: 'Sửa số thứ tự',
  //khach hang
  GET_DS_KHACH_HANG: 'Tải danh sách khách hàng',
  THEM_KHACH_HANG: 'Thêm khách hàng',
  SUA_KHACH_HANG: 'Sửa khách hàng',
  XOA_KHACH_HANG: 'Xoá khách hàng',

  // thẻ thành viên
  TAO_THE_THANH_VIEN: 'Tạo thẻ thành viên',
  HUY_KICH_HOAT_THE_THANH_VIEN: 'Hủy kích hoạt thẻ thành viên',
  SUA_THE_THANH_VIEN: 'Sửa thẻ thành viên',
  APPLY_VOUCHER: 'Kích hoạt mã voucher',
  UNAPPLY_VOUCHER: 'Bỏ kích hoạt mã voucher',
  APPLY_VOUCHER_DOI_TAC: 'Kích hoạt mã voucher đối tác',
  UNAPPLY_VOUCHER_DOI_TAC: 'Bỏ kích hoạt mã voucher đối tác',
  XEM_DS_THE_THANH_VIEN: 'Xem danh sách thẻ thành viên',
  XOA_THE_THANH_VIEN: 'Xoá thẻ thành viên',
  TIM_KIEM_VOUCHER: 'Tìm kiếm voucher',
  PHAT_HANH_MA_VOUCHER: 'Phát hành mã voucher',
  GET_LIST_TTV: 'Lấy danh sách phiếu thu thẻ thành viên',
  XUAT_EXCEL_LIST_TTV: 'Xuất excel danh sách phiếu thu thẻ thành viên',
  GET_CHI_TIET_PHIEU_THU_TTV: 'Xem chi tiết phiếu thu thẻ thành viên',
  GET_DS_TTV: 'Lấy danh sách thẻ thành viên',
  XUAT_EXCEL_DS_TTV: 'Xuất excel danh sách thẻ thành viên',
  CHI_TIET_TTV: 'Xem chi  tiết thẻ thành viên',
  UPDATE_MA_TTV: 'Cập nhật mã thẻ thành viên',
  TANG_THE_THANH_VIEN: 'Tăng thẻ thành viên',
  GIA_HAN_TTV: 'Gia hạn thẻ thành viên',
  NANG_CAP_TTV: 'Nâng cấp thẻ thành viên',
  HUY_TTV: 'Huỷ thẻ thành viên',
  VO_HIEU_HOA_TTV: 'Vô hiệu hoá thẻ thành viên',
  GET_LIST_HUY_TTV: 'Lấy danh sách huỷ thẻ thành viên',
  XUAT_EXCEL_LIST_HUY_TTV: 'Xuất excel danh sách huỷ thẻ thành viên',

  // thẻ dịch vụ
  TAO_THE_DICH_VU: 'Tạo thẻ dịch vụ',
  SUA_THE_DICH_VU: 'Sửa thẻ dịch vụ',

  //Kho
  TAO_PHIEU_CHUYEN_KHO: 'Lập phiếu chuyển kho',
  DIEU_CHINH_KHO: 'Lập phiếu điều chỉnh',
  XOA_PHIEU_DIEU_CHINH: 'Xoá phiếu điều chỉnh',
  SUA_PHIEU_DIEU_CHINH: 'Sửa phiếu điều chỉnh',
  XOA_PHIEU_NHAP_KHO: 'Xoá phiếu nhập kho',
  TAO_PHIEU_NHAP_KHO: 'Lập phiếu nhập kho',
  TAO_PHIEU_NHAP_KHO_VVP: 'Lập phiếu nhập kho văn phòng phẩm',
  THEM_HANG_HOA: 'Thêm hàng hóa',
  SUA_HANG_HOA: 'Sửa hàng hóa',
  SUA_HANG_HOA_EXCEL: 'Sửa hàng hóa từ excel',
  THEM_HANG_HOA_EXCEL: 'Thêm hàng hóa từ excel',
  SUA_PHIEU_NHAP_KHO: 'Sửa phiếu nhập kho',
  XAC_NHAN_PHIEU_CHUYEN_KHO: 'Xác nhận phiếu chuyển kho',
  XOA_PHIEU_CHUYEN_KHO: 'Xoá phiếu chuyển kho',
  SEARCH_THUOC_VAT_TU: 'Tìm kiếm thuốc vật tư',
  COPY_HANG_HOA: 'Copy hàng hóa bằng mã phiếu',
  GET_LIST_CHI_TIET_HANG_NHAP_KHO: 'Lấy danh sách chi tiết hàng nhập kho',
  XUAT_EXCEL_LIST_CHI_TIET_HANG_NHAP_KHO: 'Xuất excel danh sách chi tiết hàng nhập kho',
  GET_LIST_CHI_TIET_HANG_NHAP_KHO_VPP: 'Lấy danh sách chi tiết hàng nhập kho văn vòng phẩm',
  XUAT_EXCEL_LIST_CHI_TIET_HANG_NHAP_KHO_VPP:
    'Xuất excel danh sách chi tiết hàng nhập kho văn phòng phẩm',
  GET_DS_PHIEU_NHAP: 'Lấy danh sách phiếu nhập kho',
  XUAT_EXCEL_DS_PHIEU_NHAP: 'Xuất excel danh sách phiếu nhập kho',
  CHI_TIET_PHIEU_NHAP: 'Xem chi tiết phiếu nhập kho',
  XUAT_EXCEL_CHI_TIET_PHIEU_NHAP: 'Xuất excel chi tiết phiếu nhập kho',
  XOA_HANG_PHIEU_NHAP: 'Xoá hàng trong phiếu nhập kho',
  SUA_HANG_PHIEU_NHAP: 'Sửa hàng trong phiếu nhập kho',
  THEM_HANG_PHIEU_NHAP: 'Thêm hàng trong phiếu nhập kho',
  SUA_THONG_TIN_PHIEU_NHAP: 'Sửa thông tin phiếu nhập kho',
  CAP_NHAT_FILE_PHIEU_NHAP: 'Cập nhật file phiếu nhập kho',
  DELETE_FILE_PHIEU_NHAP: 'Xoá file phiếu nhập kho',
  XAC_NHAN_NHAP_KHO_PHIEU_NHAP: 'Xác nhận nhập kho',
  GET_LIST_HANG_CHO_NHAP_KHO_VPP: 'Lấy danh sách hàng chờ nhập kho văn phòng phẩm',
  XUAT_EXCEL_LIST_HANG_CHO_NHAP_KHO_VPP: 'Xuất excel danh sách hàng chờ nhập kho văn phòng phẩm',
  COPY_HANG_HOA_CHUYEN_KHO: 'Copy hàng hóa bằng mã phiếu chuyển kho',
  GET_LIST_PHIEU_CHUYEN: 'Lấy danh sách phiếu chuyển kho',
  XUAT_EXCEL_LIST_PHIEU_CHUYEN: 'Xuất excel danh sách phiếu chuyển kho',
  CHI_TIET_PHIEU_CHUYEN_KHO: 'Xem chi tiết phiếu chuyển kho',
  XUAT_EXCEL_CHI_TIET_PHIEU_CHUYEN_KHO: 'Xuất excel chi tiết phiếu chuyển kho',
  CAP_NHAT_THONG_TIN_PHIEU_CHUYEN: 'Cập nhật thông tin phiếu chuyển kho',
  CAP_NHAT_FILE_PHIEU_CHUYEN: 'Cập nhật file phiếu nhập kho',
  DELETE_FILE_PHIEU_CHUYEN: 'Xoá file phiếu nhập kho',
  GET_LIST_CHI_TIET_HANG_CHUYEN_KHO: 'Lấy danh sách chi tiết hàng chuyển kho',
  XUAT_EXCEL_LIST_CHI_TIET_HANG_CHUYEN_KHO: 'Xuất excel danh sách chi tiết hàng chuyển kho',
  GET_LIST_SO_PHIEU_CHUYEN_KHO: 'Lấy danh sách số phiếu chuyển kho',
  XUAT_EXCEL_LIST_SO_PHIEU_CHUYEN_KHO: 'Xuất excel danh sách số phiếu chuyển kho',
  THEM_HANG_PHIEU_CHUYEN: 'Thêm hàng trong phiếu chuyển kho',
  XOA_HANG_PHIEU_CHUYEN: 'Xoá hàng trong phiếu chuyển kho',
  GET_DS_THUOC_VAT_TU: 'Lấy danh sách thuốc vật tư',
  XUAT_EXCEL_DS_THUOC_VAT_TU: 'Xuất excel danh sách thuốc vật tư',
  THEM_THUOC_VAT_TU: 'Thêm thuốc vật tư',
  EDIT_THUOC_VAT_TU: 'Chỉnh sửa thuốc vật tư',
  GET_DS_THUOC_VAT_TU_GIA_BAN: 'Lấy danh sách thuốc vật tư giá bán',
  XUAT_EXCEL_DS_THUOC_VAT_TU_GIA_BAN: 'Xuất excel danh sách thuốc vật tư giá bán',
  GET_DS_CHI_NHANH_BAN: 'Lấy danh sách chi nhánh bán',
  GET_DS_CHI_NHANH_BAN_BY_THUOC: 'Lấy danh sách chi nhánh bán theo thuốc vật tư',
  SUA_GIA_CHI_NHANH_BAN: 'Sửa giá chi nhánh bán',
  CAP_NHAT_TRANG_THAI_BAN: 'Cập nhật trạng thái bán',
  GET_TON_KHO_TONG_HOP: 'Lấy danh sách tồn kho tổng hợp',
  GET_TON_KHO_CHI_TIET: 'Lấy danh sách tồn kho chi tiết',
  GET_TON_KHO_NGAY: 'Lấy danh sách tồn kho tồn ngày',
  XUAT_EXCEL_TON_KHO_TONG_HOP: 'Xuất excel danh sách tồn kho tổng hợp',
  XUAT_EXCEL_TON_KHO_CHI_TIET: 'Xuất excel danh sách tồn kho chi tiết',
  XUAT_EXCEL_TON_KHO_NGAY: 'Xuất excel danh sách tồn kho tồn ngày',
  GET_THE_KHO_BY_THUOC: 'Lấy thông tin thẻ kho theo thuốc vật tư',
  XUAT_EXCEL_THE_KHO_BY_THUOC: 'Xuất excel thông tin thẻ kho theo thuốc vật tư',
  GET_MSDK_BY_THUOC: 'Lấy thông tin mã số đăng ký theo thuốc vật tư',
  GET_GPP_BY_THUOC: 'Lấy thông tin GPP theo thuốc vật tư',
  GET_DS_GPP: 'Xem danh sách lịch sử thay đổi giá GPP',
  XUAT_EXCEL_DS_GPP: 'Xuất excel lịch sử thay đổi giá danh sách GPP',
  GET_DS_GIA_NHAP_TRUNG_BINH: 'Lấy danh sách giá nhập trung bình',
  XUAT_EXCEL_DS_GIA_NHAP_TRUNG_BINH: 'Xuất excel danh sách giá nhập trung bình',
  GET_DS_GIA_NHAP_TUNG_LAN: 'Lấy danh sách giá nhập từng lần',
  XUAT_EXCEL_DS_GIA_NHAP_TUNG_LAN: 'Xuất excel danh sách giá nhập từng lần',
  TAO_PHIEU_CHUYEN_KHO_CTY: 'Tạo phiếu chuyển kho công ty',
  GET_DS_PHIEU_CHUYEN_KHO_CTY: 'Lấy danh sách phiếu chuyển kho công ty',
  XUAT_EXCEL_DS_PHIEU_CHUYEN_KHO_CTY: 'Xuất excel danh sách phiếu chuyển kho công ty',
  GET_DS_PHIEU_CHUYEN_KHO_CTY_DA_NHAN: 'Lấy danh sách phiếu chuyển kho công ty phiếu đã nhận',
  XUAT_EXCEL_DS_PHIEU_CHUYEN_KHO_CTY_DA_NHAN:
    'Xuất excel danh sách phiếu chuyển kho công ty phiếu đã nhận',
  TAO_PHIEU_XUAT_SU_DUNG: 'Tạo phiếu xuất sử dụng',
  GET_DS_PHIEU_XUAT_SU_DUNG: 'Lấy danh sách phiếu xuất sử dụng',
  GET_CHI_TIET_PHIEU_XUAT_SU_DUNG: 'Lấy chi tiết phiếu xuất sử dụng',
  GET_DS_HANG_HOA_XUAT_SU_DUNG: 'Lấy danh sách hàng hóa xuất sử dụng',
  XUAT_EXCEL_DS_HANG_HOA_XUAT_SU_DUNG: 'Xuất excel danh sách hàng hoá xuất sử dụng',

  TAO_PHIEU_DIEU_CHINH_TON_KHO: 'Tạo phiếu điều chỉnh tồn kho',
  GET_DS_PHIEU_DIEU_CHINH_TON_KHO: 'Lấy danh sách phiếu điều chỉnh tồn kho',
  GET_CHI_TIET_PHIEU_DIEU_CHINH_TON_KHO: 'Lấy chi tiết phiếu điều chỉnh tồn kho',
  GET_DS_HANG_HOA_DIEU_CHINH_TON_KHO: 'Lấy danh sách hàng hóa điều chỉnh tồn kho',

  GET_HANG_HOA_KHO_KIEM_KE: 'Lấy danh sách hàng hóa kho kiểm kê',
  XUAT_EXCEL_HANG_HOA_KHO_KIEM_KE: 'Xuất excel danh sách hàng hóa kho kiểm kê',
  CHOT_TON_HANG_KHO_KIEM_KE: 'Chốt tồn hàng kho kiểm kê',
  GET_DS_HANG_HOA_DIEU_CHINH_KHO_KIEM_KE: 'Lấy danh sách hàng hóa điều chỉnh kho kiểm kê',
  XUAT_EXCEL_HANG_HOA_DIEU_CHINH_KHO_KIEM_KE:
    'Xuất excel danh sách hàng hóa điều chỉnh kho kiểm kê',
  CHOT_DIEU_CHINH_KHO_KIEM_KE: 'Chốt điều chỉnh kho kiểm kê',

  //voucher
  TAO_MA_VOUCHER: 'Tạo mã voucher',
  THEM_MA_VOUCHER: 'Thêm mã voucher',
  UPDATE_VOUCHER: 'Cập nhật voucher',

  //khám bệnh
  LUU_CHI_DINH: 'Lưu chỉ định khám bệnh',
  LUU_BENH_AN_NGOAI_TRU: 'Lưu bệnh án ngoại trú',
  LUU_TOA_PHIEU_KHAM_BENH: 'Lưu toa phiếu khám bệnh',
  LUU_TOA_PHIEU_KHAM_PHU_SAN: 'Lưu toa phiếu khám phụ sản',

  //bán toa
  LUU_PHIEU_BAN_TOA: 'Lưu phiếu bán toa',
  TRA_TOA: 'Trả toa',

  //bán lẻ
  THEM_PHIEU_BAN_LE: 'Thêm phiếu bán lẻ',
  SUA_PHIEU_BAN_LE: 'Sửa phiếu bán lẻ',
  XOA_PHIEU_BAN_LE: 'Xoá phiếu bán lẻ',

  //thủ thuật
  LUU_PHIEU_THU_THUAT: 'Lưu phiếu thủ thuật',

  //vắc xin
  LUU_PHIEU_XUAT_VACXIN: 'Lưu phiếu xuất vắc xin',
  SUA_TUONG_TAC_VACXIN_DO_TUOI: 'Sửa tương tác độ tuổi',
  SUA_TUONG_TAC_VACXIN_HOAT_CHAT: 'Sửa tương tác hoạt chất',
  SUA_TUONG_TAC_VACXIN_KHOANG_CACH: 'Sửa tương tác khoảng cách',

  //tiêm chủng
  LUU_THEO_DOI_SAU_TIEM_CHUNG: 'Lưu theo dõi sau tiêm',
  XEM_BANG_KE_PHAN_UNG_NANG_VACXIN: 'Xem bảng kê phản ứng nặng vắc xin',
  XEM_BANG_KE_PHAN_UNG_THONG_TIN_VACXIN: 'Xem bảng kê phản ứng thông tin vắc xin',
  XEM_BANG_KE_TINH_HINH_SU_DUNG_VACXIN: 'Xem bảng kê tình hình sử dụng vắc xin',

  //khám sàng lọc
  LUU_PHIEU_KHAM_SANG_LOC: 'Lưu toa phiếu khám sàng lọc',
  LUU_PHIEU_DO_KHUC_XA: 'Lưu toa phiếu khúc xạ',

  //khám tổng quát
  LUU_PHIEU_KHAM_TONG_QUAT: 'Lưu phiếu khám tổng quát',

  //Biểu mẫu
  SUA_BIEU_MAU: 'Sửa biểu mẫu',

  //dịch vụ
  THEM_DICH_VU: 'Thêm dịch vụ',
  SUA_DICH_VU: 'Sửa dịch vụ',
  XOA_DICH_VU: 'Xoá dịch vụ',
  SUA_DINH_MUC_TIEU_HAO: 'Sửa định mức tiêu hao',

  //phân quyền
  XEM_PHAN_QUYEN: 'Xem phân quyền',
  SUA_TAI_KHOAN: 'Sửa tài khoản',
  THEM_TAI_KHOAN: 'Thêm tài khoản',
  THEM_VAI_TRO: 'Thêm vai trò người dùng',
  SUA_VAI_TRO: 'Sửa vai trò người dùng',
  XOA_VAI_TRO: 'Xoá vai trò người dùng',
  CAP_NHAT_QUYEN_MENU: 'Cập nhật quyền menu người dùng',
  GET_VAI_TRO_BY_NHOM_NGUOI_DUNG: 'Lấy vai trò theo nhóm người dùng',
  THEM_MENU: 'Thêm menu',
  SUA_MENU: 'Sửa menu',
  XOA_MENU: 'Xoá menu',
  GET_ALL_MENU: 'Lấy danh sách menu',
  GET_ALL_NHOM_NGUOI_DUNG: 'Lấy danh sách nhóm người dùng',
  GET_ALL_DS_NGUOI_DUNG: 'Xem danh sách người dùng',
  GET_DS_NV_CHUA_TAO_TK: 'Lấy danh sách nhân viên chưa tạo tài khoản',
  THEM_NGUOI_DUNG: 'Thêm người dùng',
  GET_INFO_NGUOI_DUNG: 'Xem thông tin người dùng',
  UPDATE_LOCK_NGUOI_DUNG: 'Cập nhật lock người dùng',
  UPDATE_INFO_NGUOI_DUNG: 'Cập nhật thông tin người dùng',
  RESET_PASSWORD_NGUOI_DUNG: 'Reset mật khẩu người dùng',
  GET_ALL_NHOM_QUYEN: 'Lấy danh sách nhóm quyền',
  GET_QUYEN_BY_ID_NGUOI_DUNG: 'Lấy quyền theo id người dùng',
  UPDATE_QUYEN_BY_ID_NGUOI_DUNG: 'Cập nhật quyền theo id người dùng',
  THEM_NHOM_QUUYEN: 'Thêm nhóm quyền',
  GET_QUYEN_HE_THONG_BY_ID: 'Lấy quyền hệ thống theo id nqht',
  UPDATE_SU_DUNG_NHOM_QUYEN: 'Cập nhật sử dụng nhóm quyền',
  UPDATE_SU_DUNG_QUYEN_HE_THONG: 'Cập nhật sử dụng quyền hệ thống',
  UPDATE_SU_DUNG_LIST_PHAN_QUYEN_HE_THONG: 'Cập nhật list sử dụng phân quyền hệ thống',
  XEM_NHOM_QUYEN_HE_THONG: 'Xem nhóm quyền hệ thống',
  XEM_QUYEN_THEO_MENU_KEYWORD: 'Xem quyền hệ thống theo menu và keyword',

  //bệnh án
  GET_DS_BENH_AN: 'Xem danh sách bệnh án',
  XEM_SDT_BN: 'Xem sđt bệnh nhân',
  CAP_NHAT_BENH_AN: 'Cập nhật thông tin hành chính',

  //đối tác
  THEM_DOI_TAC: 'Thêm đối tác',
  SUA_DOI_TAC: 'Sửa đối tác',
  THEM_DOI_TUONG: 'Thêm đối tượng',
  XOA_DOI_TUONG: 'Thêm đối tượng',

  //Nhân viên
  GET_DS_NHAN_VIEN: 'Xem danh sách nhân viên',
  XUAT_EXCEL_DS_NHAN_VIEN: 'Xuất excel danh sách nhân viên',
  THEM_NHAN_VIEN: 'Thêm nhân viên',
  XOA_NHAN_VIEN: 'Xoá nhân viên',
  SUA_NHAN_VIEN: 'Sửa thông tin nhân viên',

  //xuất sử dụng
  THEM_PHIEU_XUAT_SU_DUNG: 'Thêm phiếu xuất sử dụng',
  XAC_NHAN_PHIEU_XUAT_SU_DUNG: 'Xác nhận phiếu xuất sử dụng',
  XOA_PHIEU_XUAT_SU_DUNG: 'Xoá phiếu xuất sử dụng',

  //báo cáo
  BAO_CAO_BANG_KE_PHIEU_THU: 'Báo cáo bảng kê phiếu thu',
  BAO_CAO_BIEU_MAU_PHONG_KHAM: 'Báo cáo biểu mẫu phòng khám',
  BAO_CAO_CHI_TIET_XUAT: 'Báo cáo chi tiết xuất',
  BAO_CAO_CHUNG_TU_NHAP_KHO: 'Báo cáo chứng từ nhập kho',
  BAO_CAO_DICH_VU: 'Báo cáo dịch vụ',
  BAO_CAO_GUI_SMS: 'Báo cáo gửi SMS',
  BAO_CAO_HANG_HOA_NHAP_KHO: 'Báo cáo hàng hoá nhập kho',
  BAO_CAO_NHAN_VIEN: 'Báo cáo nhân viên',
  BAO_CAO_PHIEU_BAN_HANG: 'Báo cáo phiếu bán hàng',
  BAO_CAO_PHIEU_BAN_THEO_TOA: 'Báo cáo phiếu bán theo toa',
  BAO_CAO_PHIEU_XUAT: 'Báo cáo phiếu xuất',
  BAO_CAO_THEO_DOI_CHUYEN_KHO: 'Báo cáo theo dõi chuyển kho',
  BAO_CAO_THEO_DOI_DOANH_SO: 'Báo cáo theo dõi doanh số',
  BAO_CAO_THEO_DOI_DOI_TUONG: 'Báo cáo theo dõi đối tượng',
  BAO_CAO_THEO_DOI_KHACH_MOI: 'Báo cáo theo dõi khách mới',
  BAO_CAO_THEO_DOI_LUOT_KHAM: 'Báo cáo theo dõi lượt khám',
  BAO_CAO_THEO_DOI_NGUON_KHACH: 'Báo cáo theo dõi nguồn khách',
  BAO_CAO_TONG_HOP: 'Báo cáo tổng hợp',
  BIEU_MAU_MISA_PHIEU_BAN_HANG: 'Biểu mẫu MISA - phiếu bán hàng',
  BAO_CAO_DOANH_THU_TONG_HOP: 'Báo cáo doanh thu tổng hợp',
  XUAT_EXCEL_BAO_CAO_DOANH_THU_TONG_HOP: 'Xuất excel báo cáo doanh thu tổng hợp',
  BAO_CAO_DOANH_THU_CHI_TIET: 'Báo cáo doanh thu chi tiết',
  XUAT_EXCEL_BAO_CAO_DOANH_THU_CHI_TIET: 'Xuất excel báo cáo doanh thu chi tiết',
  BAO_CAO_DOANH_THU_CHI_TIET_THANG: 'Báo cáo doanh thu chi tiết tháng',
  XUAT_EXCEL_BAO_CAO_DOANH_THU_CHI_TIET_THANG: 'Xuất excel báo cáo doanh thu chi tiết tháng',
  BAO_CAO_LOI_NHUAN_TONG_HOP: 'Báo cáo lợi nhuận tổng hợp',
  XUAT_EXCEL_BAO_CAO_LOI_NHUAN_TONG_HOP: 'Xuất excel báo cáo lợi nhuận tổng hợp',
  BAO_CAO_LOI_NHUAN_THUOC: 'Báo cáo lợi nhuận thuốc',
  XUAT_EXCEL_BAO_CAO_LOI_NHUAN_THUOC: 'Xuất excel báo cáo lợi nhuận thuốc',
  BAO_CAO_LOI_NHUAN_VACXIN: 'Báo cáo lợi nhuận vắc xin',
  XUAT_EXCEL_BAO_CAO_LOI_NHUAN_VACXIN: 'Xuất excel báo cáo lợi nhuận vắc xin',
  BAO_CAO_VTTH_TONG_HOP: 'Báo cáo vật tư tiêu hao tổng hợp',
  XUAT_EXCEL_BAO_CAO_VTTH_TONG_HOP: 'Xuất excel báo cáo vật tư tiêu hao tổng hợp',
  BAO_CAO_VTTH_CHI_TIET: 'Báo cáo vật tư tiêu hao chi tiết',
  XUAT_EXCEL_BAO_CAO_VTTH_CHI_TIET: 'Xuất excel báo cáo vật tư tiêu hao chi tiết',
  BAO_CAO_PHIEU_THU: 'Báo cáo phiếu thu',
  XUAT_EXCEL_BAO_CAO_PHIEU_THU: 'Xuất excel báo cáo phiếu thu',

  BAO_CAO_LUOT_KHAM_TONG: 'Báo cáo lượt khám tổng hợp',
  XUAT_EXCEL_BAO_CAO_LUOT_KHAM_TONG: 'Xuất excel báo cáo lượt khám tổng hợp',
  BAO_CAO_LUOT_KHAM_DOANH_THU: 'Báo cáo lượt khám doanh thu',
  XUAT_EXCEL_BAO_CAO_LUOT_KHAM_DOANH_THU: 'Xuất excel báo cáo lượt khám doanh thu',
  BAO_CAO_LUOT_KHAM_CHI_TIET: 'Báo cáo lượt khám chi tiết',
  XUAT_EXCEL_BAO_CAO_LUOT_KHAM_CHI_TIET: 'Xuất excel báo cáo lượt khám chi tiết',
  BAO_CAO_LUOT_KHAM_THEO_DOI_CA_KHAM: 'Báo cáo lượt khám theo dõi ca khám',
  XUAT_EXCEL_BAO_CAO_LUOT_KHAM_THEO_DOI_CA_KHAM: 'Xuất excel báo cáo lượt khám theo dõi ca khám',
  BAO_CAO_LUOT_KHAM_THEO_DOI_NGUON_KHACH: 'Báo cáo lượt khám theo dõi nguồn khách',
  XUAT_EXCEL_BAO_CAO_LUOT_KHAM_THEO_DOI_NGUON_KHACH:
    'Xuất excel báo cáo lượt khám theo dõi nguồn khách',
  BAO_CAO_LUOT_KHAM_LOAI_KHAM: 'Báo cáo lượt khám loại khám',
  XUAT_EXCEL_BAO_CAO_LUOT_KHAM_LOAI_KHAM: 'Xuất excel báo cáo lượt khám loại khám',
  BAO_CAO_LUOT_KHAM_THEO_DOI_KHACH_MOI: 'Báo cáo lượt khám theo dõi khách mới',
  XUAT_EXCEL_BAO_CAO_LUOT_KHAM_THEO_DOI_KHACH_MOI:
    'Xuất excel báo cáo lượt khám theo dõi khách mới',
  BAO_CAO_LUOT_KHAM_THEO_DOI_DOI_TUONG: 'Báo cáo lượt khám theo dõi đối tượng',
  XUAT_EXCEL_BAO_CAO_LUOT_KHAM_THEO_DOI_DOI_TUONG:
    'Xuất excel báo cáo lượt khám theo dõi đối tượng',

  BAO_CAO_THONG_KE_CHUYEN_KHOA_NHI: 'Báo cáo thống kê chuyên khoa nhi',
  XUAT_EXCEL_BAO_CAO_THONG_KE_CHUYEN_KHOA_NHI: 'Xuất excel báo cáo thống kê chuyên khoa nhi',
  BAO_CAO_THONG_KE_CHUYEN_KHOA_SAN: 'Báo cáo thống kê chuyên khoa sản',
  XUAT_EXCEL_BAO_CAO_THONG_KE_CHUYEN_KHOA_SAN: 'Xuất excel báo cáo thống kê chuyên khoa sản',
  BAO_CAO_THONG_KE_CHUYEN_KHOA_TMTD: 'Báo cáo thống kê chuyên khoa TMTD',
  XUAT_EXCEL_BAO_CAO_THONG_KE_CHUYEN_KHOA_TMTD: 'Xuất excel báo cáo thống kê chuyên khoa TMTD',
  BAO_CAO_THONG_KE_CHUYEN_KHOA_MAT: 'Báo cáo thống kê chuyên khoa mắt',
  XUAT_EXCEL_BAO_CAO_THONG_KE_CHUYEN_KHOA_MAT: 'Xuất excel báo cáo thống kê chuyên khoa mắt',
  BAO_CAO_THONG_KE_CHUYEN_TONG_HOP: 'Báo cáo thống kê tổng hợp',
  XUAT_EXCEL_BAO_CAO_THONG_KE_CHUYEN_TONG_HOP: 'Xuất excel báo cáo thống kê tổng hợp',

  BAO_CAO_DICH_VU_KHOA_PHONG: 'Báo cáo dịch vụ khoa phòng',
  XUAT_EXCEL_BAO_CAO_DICH_VU_KHOA_PHONG: 'Xuất excel báo cáo dịch vụ khoa phòng',
  BAO_CAO_DICH_VU_NHOM_DICH_VU: 'Báo cáo dịch vụ nhóm dịch vụ',
  XUAT_EXCEL_BAO_CAO_DICH_VU_NHOM_DICH_VU: 'Xuất excel báo cáo dịch vụ nhóm dịch vụ',
  BAO_CAO_DICH_VU_CHI_TIET_DICH_VU: 'Báo cáo dịch vụ chi tiết dịch vụ',
  XUAT_EXCEL_BAO_CAO_DICH_VU_CHI_TIET_DICH_VU: 'Xuất excel báo cáo dịch vụ chi tiết dịch vụ',

  BAO_CAO_HOA_HONG_THU_THUAT_SAN: 'Báo cáo hoa hồng thủ thuật sản',
  XUAT_EXCEL_BAO_CAO_HOA_HONG_THU_THUAT_SAN: 'Xuất excel báo cáo hoa hồng thủ thuật sản',
  BAO_CAO_HOA_HONG_THU_THUAT_MAT: 'Báo cáo hoa hồng thủ thuật mắt',
  XUAT_EXCEL_BAO_CAO_HOA_HONG_THU_THUAT_MAT: 'Xuất excel báo cáo hoa hồng thủ thuật mắt',

  BAO_CAO_TTV: 'Báo cáo thẻ thành viên',
  XUAT_EXCEL_BAO_CAO_TTV: 'Xuất excel báo cáo thẻ thành viên',

  BAO_CAO_DOANH_SO_TTV_NHI: 'Báo cáo doanh số TTV nhi',
  XUAT_EXCEL_BAO_CAO_DOANH_SO_TTV_NHI: 'Xuất excel báo cáo doanh số TTV nhi',
  BAO_CAO_DOANH_SO_TTV_SAN: 'Báo cáo doanh số TTV sản',
  XUAT_EXCEL_BAO_CAO_DOANH_SO_TTV_SAN: 'Xuất excel báo cáo doanh số TTV sản',
  BAO_CAO_DOANH_SO_TTV_LAO: 'Báo cáo doanh số TTV lão',
  XUAT_EXCEL_BAO_CAO_DOANH_SO_TTV_LAO: 'Xuất excel báo cáo doanh số TTV lão',
  BAO_CAO_DOANH_SO_TTV_MAT: 'Báo cáo doanh số TTV mắt',
  XUAT_EXCEL_BAO_CAO_DOANH_SO_TTV_MAT: 'Xuất excel báo cáo doanh số TTV mắt',

  BAO_CAO_TONG_QUAN_TTV: 'Báo cáo tổng quan TTV',
  XUAT_EXCEL_BAO_CAO_TONG_QUAN_TTV: 'Xuất excel báo cáo tổng quan TTV',

  BAO_CAO_XET_NGHIEM: 'Báo cáo xét nghiệm',
  XUAT_EXCEL_BAO_CAO_XET_NGHIEM: 'Xuất excel báo cáo xét nghiệm',

  BAO_CAO_XUAT_VACXIN: 'Báo cáo xuất vắc xin',
  XUAT_EXCEL_BAO_CAO_XUAT_VACXIN: 'Xuất excel báo cáo xuất vắc xin',

  BAO_CAO_XUAT_NHA_THUOC_TONG_HOP: 'Báo cáo xuất nhà thuốc tổng hợp',
  XUAT_EXCEL_BAO_CAO_XUAT_NHA_THUOC_TONG_HOP: 'Xuất excel báo cáo xuất nhà thuốc tổng hợp',
  BAO_CAO_XUAT_NHA_THUOC_CHI_TIET: 'Báo cáo xuất nhà thuốc chi tiết',
  XUAT_EXCEL_BAO_CAO_XUAT_NHA_THUOC_CHI_TIET: 'Xuất excel báo cáo xuất nhà thuốc chi tiết',

  BAO_CAO_GONG_KINH: 'Báo cáo gọng kính',
  XUAT_EXCEL_BAO_CAO_GONG_KINH: 'Xuất excel báo cáo gọng kính',
  BAO_CAO_CONG_MAI: 'Báo cáo công mài',
  XUAT_EXCEL_BAO_CAO_CONG_MAI: 'Xuất excel báo cáo công mài',

  BAO_CAO_TI_LE_TOA: 'Báo cáo tỉ lệ toa',
  XUAT_EXCEL_BAO_CAO_TI_LE_TOA: 'Xuất excel báo cáo tỉ lệ toa',
  BAO_CAO_DO_LECH: 'Báo cáo độ lệch',
  XUAT_EXCEL_BAO_CAO_DO_LECH: 'Xuất excel báo cáo độ lệch',

  BAO_CAO_DOANH_THU_THUOC_THEO_TUAN: 'Báo cáo doanh thu thuốc theo tuần',
  XUAT_EXCEL_BAO_CAO_DOANH_THU_THUOC_THEO_TUAN: 'Xuất excel báo cáo doanh thu thuốc theo tuần',
  BAO_CAO_DOANH_THU_THUOC_TUY_CHON: 'Báo cáo doanh thu thuốc tuỳ chọn',
  XUAT_EXCEL_BAO_CAO_DOANH_THU_THUOC_TUY_CHON: 'Xuất excel báo cáo doanh thu thuốc tuỳ chọn',

  BAO_CAO_THOI_GIAN_KHAM_THEO_CHI_NHANH: 'Báo cáo thời gian khám theo chi nhánh',
  XUAT_EXCEL_BAO_CAO_THOI_GIAN_KHAM_THEO_CHI_NHANH:
    'Xuất excel báo cáo thời gian khám theo chi nhánh',
  BAO_CAO_THOI_GIAN_KHAM_THEO_BAC_SI: 'Báo cáo thời gian khám theo bác sĩ',
  XUAT_EXCEL_BAO_CAO_THOI_GIAN_KHAM_THEO_BAC_SI: 'Xuất excel báo cáo thời gian khám theo bác sĩ',
  BAO_CAO_THOI_GIAN_KHAM_CHI_TIET_PHIEU: 'Báo cáo thời gian khám theo chi tiết phiếu',
  XUAT_EXCEL_BAO_CAO_THOI_GIAN_KHAM_CHI_TIET_PHIEU:
    'Xuất excel báo cáo thời gian khám theo chi tiết phiếu',

  BAO_CAO_TIEM_CHUNG: 'Báo cáo tiêm chủng',
  XUAT_EXCEL_BAO_CAO_TIEM_CHUNG: 'Xuất excel báo cáo tiêm chủng',

  BAO_CAO_HANG_HOA: 'Báo cáo hàng hoá',
  XUAT_EXCEL_BAO_CAO_HANG_HOA: 'Xuất excel báo cáo hàng hoá',

  BAO_CAO_PHIEU_XUAT_DOANH_THU_TONG_HOP: 'Báo cáo phiếu xuất doanh thu chi tiết',
  XUAT_EXCEL_BAO_CAO_PHIEU_XUAT_DOANH_THU_TONG_HOP:
    'Xuất excel báo cáo phiếu xuất doanh thu chi tiết',
  BAO_CAO_PHIEU_XUAT_DOANH_THU_CHI_TIET: 'Báo cáo doanh thu chi tiết',
  XUAT_EXCEL_BAO_CAO_PHIEU_XUAT_DOANH_THU_CHI_TIET: 'Xuất excel báo cáo doanh thu chi tiết',

  BAO_CAO_THEO_DOI_PHU_SAN_TONG_HOP: 'Báo cáo theo dõi phụ sản tổng hợp',
  XUAT_EXCEL_BAO_CAO_THEO_DOI_PHU_SAN_TONG_HOP: 'Xuất excel báo cáo theo dõi phụ sản tổng hợp',
  BAO_CAO_THEO_DOI_PHU_SAN_CHI_TIET: 'Báo cáo theo dõi phụ sản chi tiết',
  XUAT_EXCEL_BAO_CAO_THEO_DOI_PHU_SAN_CHI_TIET: 'Xuất excel báo cáo theo dõi phụ sản chi tiết',
  BAO_CAO_THEO_DOI_PHU_SAN_SAY_THAI: 'Báo cáo theo dõi phụ sản sảy thai',
  XUAT_EXCEL_BAO_CAO_THEO_DOI_PHU_SAN_SAY_THAI: 'Xuất excel báo cáo theo dõi phụ sản sảy thai',

  // tái khám
  XEM_TAI_KHAM: 'Xem ngày tái khám',
  XUAT_EXCEL_TAI_KHAM: 'Xuất excel ngày tái khám',
  XEM_BENH_NHAN_TAI_KHAM: 'Xem bệnh nhân tái khám',
  XEM_NGAY_KHAM: 'Xem ngày khám',
  UPDATE_LIEN_HE_TAI_KHAM: 'Cập nhật liên hệ tái khám',

  // tái khám tiêm chủng
  XEM_TAI_KHAM_TIEM_CHUNG: 'Xem ngày tái khám tiêm chủng',
  XUAT_EXCEL_TAI_KHAM_TIEM_CHUNG: 'Xuất excel ngày tái khám tiêm chủng',
  UPDATE_LIEN_HE_TAI_KHAM_TIEM_CHUNG: 'Cập nhật liên hệ tái khám tiêm chủng',

  // hẹn tiêm vắc xin
  XEM_HEN_TIEM_VX: 'Xem hẹn tiêm vắc xin',
  XUAT_EXCEL_HEN_TIEM_VX: 'Xuất excel hẹn tiêm vắc xin',
  UPDATE_LIEN_HE_HEN_TIEM_VX: 'Cập nhật liên hệ hẹn tiêm vắc xin',

  //Nhật ký (lịch sử người dùng)
  XEM_LICH_SU_NGUOI_DUNG: 'Xem lịch sử người dùng',

  //Siêu âm
  SUA_PHIEU_SIEU_AM: 'Sửa phiếu siêu âm',

  //Phiên bản
  GET_DS_PHIEN_BAN: 'Xem danh sách phiên bản',
  THEM_PHIEN_BAN: 'Thêm phiên bản',
  GET_DETAIL_PHIEN_BAN: 'Xem chi tiết phiên bản',
  SUA_PHIEN_BAN: 'Sửa phiên bản',
  XOA_PHIEN_BAN: 'Xoá phiên bản',

  //MAC
  XEM_LIST_MAC: 'Xem danh sách MAC',
  UPDATE_SU_DUNG_MAC: 'Update sử dụng MAC',

  //
  THEM_THE_LOAI_CHI_NHANH: 'Thêm thẻ chi nhánh',
  UPDATE_THE_LOAI_CHI_NHANH: 'Cập nhật giá thẻ chi nhánh',

  //PHÒNG KHÁM
  //KHÁM NHI
  XEM_DETAIL_BENH_NHAN_KHAM_NHI: 'Xem chi tiết bệnh nhân khám nhi',
  XEM_DANH_SACH_BENH_NHAN_CHO_KHAM_THEO_PHONGKHAM:
    'Xem danh sách bệnh nhân chờ khám theo phòng khám',
  XEM_DANH_SACH_BENH_NHAN_DANG_KHAM_VA_DA_KHAM_THEO_PHONGKHAM:
    'Xem danh sách bệnh nhân đang khám và đã khám theo phòng khám',
  CAP_NHAT_BN_KHAM: 'Cập nhật thông tin cá nhân bệnh nhân',
  CAP_NHAT_BN_KHAM_TTV: 'Cập nhật thông tin thẻ thành viên bệnh nhân',
  CAP_NHAT_BN_KHAM_TTC: 'Cập nhật thông tin bênh nhân thông tin chung',
  CAP_NHAT_TOA_THUOC_BS_TU_LAY_SO_LO: 'Cập nhật toa thuốc bác sĩ tự lấy số lô',
  THEM_CHI_DINH_DICH_VU: 'Thêm chỉ định dịch vụ',
  XOA_CHI_DINH_DICH_VU: 'Xoá chỉ định dịch vụ',

  THEM_CHI_DINH_DICH_VU_XET_NGHIEM: 'Thêm chỉ định dịch vụ xét nghiệm',
  XOA_CHI_DINH_DICH_VU_XET_NGHIEM: 'Xoá chỉ định dịch vụ xét nghiệm',

  THEM_CHI_DINH_DICH_VU_CDHA: 'Thêm chỉ định dịch vụ chuẩn đoán hình ảnh',
  XOA_CHI_DINH_DICH_VU_CDHA: 'Xoá chỉ định dịch vụ chuẩn đoán hình ảnh',

  // KHÁM SẢN
  XOA_TOA_THUOC_BS_KHONG_KE_TOA: 'Xoá toa thuốc bác sĩ không kê toa',
  XEM_DETAIL_BENH_NHAN_KHAM_SAN: 'Xem chi tiết bệnh nhân khám sản',
  CAP_NHAT_BENH_NHAN_KHAM_SAN: 'Cập nhật thông tin bệnh nhân khám sản',

  // ĐO KHÚC XẠ
  CHINH_SUA_BENH_NHAN_DO_KHUC_XA: 'Chỉnh sửa thông tin bệnh nhân đo khúc xạ',

  //TRA CỨU
  XEM_TRA_CUU_BENH_NHAN: 'Xem tra cứu bệnh nhân',
  XEM_TRA_CUU_BENH_NHAN_ALL_CTY: 'Xem tra cứu bệnh nhân tất cả công ty',
  XEM_TRA_CUU_BENH_NHAN_ALL_CHINHANH: 'Xem tra cứu bệnh nhân tất cả chi nhánh',
  TAO_BENH_NHAN_MOI: 'Tạo bệnh nhân mới',
  XEM_THONG_TIN_BENH_NHAN_TRA_CUU: 'Xem thông tin bệnh nhân tra cứu',
  CAP_NHAT_THONG_TIN_BENH_NHAN: 'Cập nhật thông tin bệnh nhân',
  TRA_CUU_LICH_SU_KHAM_BENH: 'Tra cứu lịch sử khám bệnh',
  XEM_KET_QUA_CAN_LAM_SANG: 'Xem kết quả cận lâm sàng',
  XEM_LICH_SU_BENH_NHAN_XET_NGHIEM: 'Xem lịch sử bệnh nhân xét nghiệm',
  TRA_CUU_KET_QUA_SIEU_AM: `Tra cứu kết quả siêu âm`,
  CAP_NHAT_THONG_TIN_BENH_NHAN_THONG_TIN_CHUNG: 'Cập nhật thông tin bệnh nhân thông tin chung',

  //SỔ KHÁM BỆNH
  XEM_SO_KHAM_BENH: 'XEM SỔ KHÁM BỆNH',
  XEM_SO_KHAM_THAI: 'Xem sổ khám thai',
  XEM_BENH_AN_MAT: 'Xem bệnh án mắt',

  //CẬN LÂM SÀN
  XEM_LICH_SU_XET_NGHIEM: 'Xem lịch sử xét nghiệm',
  IN_PHIEU_CHI_DINH_XET_NGHIEM: 'In phiếu chỉ định xét nghiệm',
  GUI_MAU_VA_LUU_VTTH: 'Gửi mẫu và lưu VTTH',
  XEM_CHI_TIET_XET_NGHIEM: 'Xem chi tiết xét nghiệm',
  CAP_NHAT_BENH_NHAN_VAT_TU_TIEU_HAO_BINH_THUONG: 'Cập nhật bệnh nhân vật tư tiêu hao bình thường',
  XEM_LICH_SU_BENH_NHAN_TRONG_KINH: 'Xem lịch sử bệnh nhân tròng kính',

  //SIÊU ÂM
  XEM_LICH_SU_BENH_NHAN_SIEU_AM: 'Xem lịch sử bệnh nhân siêu âm',
  THEM_MOI_BENH_NHAN_SIEU_AM: 'Thêm bệnh nhân siêu âm',
  CAP_NHAT_BENH_NHAN_SIEU_AM: 'Cập nhật bệnh nhân siêu âm',

  //MONITOR
  XEM_LICH_SU_BENH_NHAN_MONITOR: `Xem lịch sử bệnh nhân monitor`,
  //SMS
  GET_DS_BN_GUI_SMS: 'Xem danh sách bệnh nhân gửi SMS',
  XAC_NHAN_GUI_SMS: 'Xác nhận gửi sms',
  GET_OTP_SMS: 'Lấy mã OTP SMS',

  //Tool
  CHINH_SUA_TRANG_THAI_KHAM_BENH: 'Chỉnh sửa trạng thái khám bệnh',
  CHINH_SUA_THOI_GIAN_THANH_TOAN: 'Chỉnh sửa thời gian thanh toán',
  CHINH_NGAY_TAI_KHAM: 'Chỉnh ngày tái khám',
  CAP_NHAT_TTV: 'Cập nhật thẻ thành viên',
  THEM_CHUONG_TRINH_TAI_KHAM: 'Thêm chương trình tái khám',
  GET_DS_CHUONG_TRINH_TAI_KHAM: 'Xem danh sách chương trình tái khám',
  CAP_NHAT_CHUONG_TRINH_TAI_KHAM: 'Cập nhật chương trình tái khám',
  XOA_CHUONG_TRINH_TAI_KHAM: 'Xoá chương trình tái khám',
  GOP_HO_SO_BENH_AN: 'Gộp hồ sơ bệnh án',
  CHINH_SUA_CA_LAM_VIEC: 'Chỉnh sửa ca làm việc',
  CHINH_SUA_LOAI_KHAM: 'Chỉnh sửa loại khám',
  CHINH_SUA_NGUOI_BAN_TTV: 'Chỉnh sửa người bán thẻ thành viên',

  //Tiêm chủng
  GET_DS_BN_DA_TIEM_CHUNG: 'Xem danh sách bệnh nhân đã tiêm chủng',
  GET_DETAIL_BN_TIEM_CHUNG: 'Xem chi tiết bệnh nhân đã tiêm chủng',
  ADD_VTTH_BN_TIEM_CHUNG: 'Thêm vật tư tiêu hao cho bệnh nhân tiêm chủng',
  LUU_BN_THEO_DOI_SAU_TIEM: 'Lưu bệnh nhân theo dõi sau tiêm',

  GET_DS_CANH_BAO_DO_TUOI: 'Xem danh sách cảnh báo độ tuổi',
  ADD_CANH_BAO_DO_TUOI: 'Thêm cảnh báo độ tuổi',
  UPDATE_CANH_BAO_DO_TUOI: 'Cập nhật cảnh báo độ tuổi',
  GET_DS_CANH_BAO_MUI_TIEM: 'Xem danh sách cảnh báo mũi tiêm',
  ADD_CANH_BAO_MUI_TIEM: 'Thêm cảnh báo mũi tiêm',
  UPDATE_CANH_BAO_MUI_TIEM: 'Cập nhật cảnh báo mũi tiêm',
  GET_DS_CANH_BAO_HOAT_CHAT: 'Xem danh sách cảnh báo hoạt chất',
  ADD_CANH_BAO_HOAT_CHAT: 'Thêm cảnh báo hoạt chất',
  UPDATE_CANH_BAO_HOAT_CHAT: 'Cập nhật cảnh báo hoạt chất',
  GET_DS_CANH_BAO_KHOANG_CACH: 'Xem danh sách cảnh báo khoảng cách',
  ADD_CANH_BAO_KHOANG_CACH: 'Thêm cảnh báo khoảng cách',
  UPDATE_CANH_BAO_KHOANG_CACH: 'Cập nhật cảnh báo khoảng cách',

  //Thủ thuật
  GET_DS_BN_DA_THUC_HIEN_THU_THUAT: 'Xem danh sách bệnh nhân đã thực hiện thủ thuật',
  GET_DETAIL_BN_THUC_HIEN_THU_THUAT: 'Xem chi tiết bệnh nhân đã thực hiện thủ thuật',
  UPDATE_VTTH_BN_THUC_HIEN_THU_THUAT: 'Cập nhật vật tư tiêu hao cho bệnh nhân thực hiện thủ thuật',

  // VLTL
  XEM_LICH_SU_BENH_NHAN_VAT_LY_TRI_LIEU: 'Xem lịch sử bệnh nhân vật lý trị liệu',
  //Nội SOI
  XEM_LICH_SU_BENH_NHAN_NOI_SOI: 'Xem lịch sử bệnh nhân nội soi',
  THEM_MOI_BENH_NHAN_NOI_SOI: 'Thêm mới bệnh nhân nội soi',

  //Điện tim
  XEM_LICH_SU_BENH_NHAN_DIEN_TIM: 'Xem lịch sử bệnh nhân điện tim',
  THEM_MOI_BENH_NHAN_DIEN_TIM: 'Thêm mới bệnh nhân điện tim',

  // Nhà thuốc
  XEM_THONG_TIN_BENH_NHAN_NHA_THUOC: 'Xem thông tin bệnh nhân nhà thuốc',
  THANH_TOAN_THUOC: 'Thanh toán thuốc',
  TRA_TOA_CHO_BS_KE_LAI: 'Trả toa cho bác sĩ kê lại',
  XEM_CHI_TIET_PHIEU_THU_TOA_THUOC: 'Xem chi tiết phiếu thu toa thuốc',
  XOA_PHIEU_THU_TOA_THUOC: 'Xoá phiếu thu toa thuốc',
  CHINH_SUA_CHI_TIET_THANH_TOAN_TOA_THUOC: 'Chỉnh sửa chi tiết thanh toán toa thuốc',

  THANH_TOAN_PHIEU_BAN_LE: 'Thanh toán phiếu bán lẻ',

  BAO_CAO_THUOC_HET_HAN: 'Báo cáo thuốc hết hạn',
  BAO_CAO_THUOC_KHONG_LAY: 'Báo cáo thuốc không lấy',
  BAO_CAO_BANG_GIA: 'Báo cáo bảng giá',
  TAO_PHIEU_BAN_NOI_BO: 'Tạo phiếu bán nội bộ',
  GET_DS_PHIEU_BAN_NOI_BO: 'Xem danh sách phiếu bán nội bộ',
  GET_CHI_TIET_PHIEU_BAN_NOI_BO: 'Xem chi tiết phiếu bán nội bộ',
  GET_DS_HANG_HOA_BAN_NOI_BO: 'Xem danh sách hàng hóa bán nội bộ',
  TAO_PHIEU_XUAT_TRA_NCC: 'Tạo phiếu xuất trả nhà cung cấp',
  GET_DS_PHIEU_XUAT_TRA_NCC: 'Xem danh sách phiếu xuất trả nhà cung cấp',
  GET_CHI_TIET_PHIEU_XUAT_TRA_NCC: 'Xem chi tiết phiếu xuất trả nhà cung cấp',
  GET_DS_HANG_HOA_XUAT_TRA_NCC: 'Xem danh sách hàng hóa xuất trả nhà cung cấp',
  XEM_DS_PHIEU_XUAT_VACXIN: 'Xem danh sách phiếu xuất vắc xin',
  XEM_CHI_TIET_PHIEU_XUAT_VACXIN: 'Xem chi tiết phiếu xuất vắc xin',
  GET_DS_HANG_HOA_XUAT_VACXIN: 'Xem danh sách hàng hóa xuất vắc xin',

  //Danh Mục
  XEM_DANH_SACH_CHI_NHANH: 'Xem danh sách chi nhánh',
  THEM_MOI_CHI_NHANH: 'Thêm mới chi nhánh',
  CAP_NHAT_CHI_NHANH: 'Cập nhật chi nhánh',
  TIM_KIEM_DOI_TAC: 'Tìm kiếm đối tác',
  THEM_DOI_TACC: 'Thêm đối tác',
  CAP_NHAT_DOI_TAC: 'Cập nhật đối tác',
  XOA_DOI_TAC: 'Xoá đối tác',

  GET_DS_QUOC_GIA: 'Xem danh sách quốc gia',
  THEM_MOI_QUOC_GIA: 'Thêm mới quốc gia',
  XOA_QUOC_GIA: 'Xoá quốc gia',
  GET_DS_TINH_TP: 'Xem danh sách tỉnh thành phố',
  THEM_MOI_TINH_TP: 'Thêm mới tỉnh thành phố',
  XOA_TINH_TP: 'Xoá tỉnh thành phố',
  GET_DS_QUAN_HUYEN: 'Xem danh sách quận huyện',
  THEM_MOI_QUAN_HUYEN: 'Thêm mới quận huyện',
  XOA_QUAN_HUYEN: 'Xoá quận huyện',
  GET_DS_PHUONG_XA: 'Xem danh sách phường xã',
  THEM_MOI_PHUONG_XA: 'Thêm mới phường xã',
  XOA_PHUONG_XA: 'Xoá phường xã',

  GET_DS_LOI_DAN: 'Xem danh sách lời dặn',
  XUAT_EXCEL_DS_LOI_DAN: 'Xuất excel sách lời dặn',
  THEM_MOI_LOI_DAN: 'Thêm mới lời dặn',
  CHINH_SUA_LOI_DAN: 'Chỉnh sửa lời dặn',
  XOA_LOI_DAN: 'Xoá lời dặn',
  GET_CHI_TIET_LOI_DAN: 'Xem chi tiết lời dặn',

  GET_DS_ICD10: 'Xem danh sách ICD10',
  XUAT_EXCEL_DS_ICD10: 'Xuất excel sách ICD10',
  THEM_MOI_ICD10: 'Thêm mới ICD10',
  CHINH_SUA_ICD10: 'Chỉnh sửa ICD10',
  XOA_ICD10: 'Xoá ICD10',
  GET_CHI_TIET_ICD10: 'Xem chi tiết ICD10',

  GET_DS_DVT: 'Xem danh sách DVT',
  XUAT_EXCEL_DS_DVT: 'Xuất excel sách DVT',
  THEM_MOI_DVT: 'Thêm mới DVT',
  CHINH_SUA_DVT: 'Chỉnh sửa DVT',
  XOA_DVT: 'Xoá DVT',
  GET_CHI_TIET_DVT: 'Xem chi tiết DVT',

  GET_DS_KHO: 'Xem danh sách kho',
  GET_DS_KHO_CHI_NHANH: 'Xem danh sách kho chi nhánh',
  XUAT_EXCEL_DS_KHO: 'Xuất excel sách kho',
  THEM_MOI_KHO: 'Thêm mới kho',
  CHINH_SUA_KHO: 'Chỉnh sửa kho',
  XOA_KHO: 'Xoá kho',
  GET_CHI_TIET_KHO: 'Xem chi tiết kho',
  CAP_NHAT_KHO_CHI_NHANH: 'Cập nhật kho chi nhánh',

  GET_DS_HOA_HONG: 'Xem danh sách hoa hồng',
  XUAT_EXCEL_DS_HOA_HONG: 'Xuất excel sách hoa hồng',
  THEM_MOI_HOA_HONG: 'Thêm mới hoa hồng',
  CHINH_SUA_HOA_HONG: 'Chỉnh sửa hoa hồng',
  XOA_HOA_HONG: 'Xoá hoa hồng',
  GET_CHI_TIET_HOA_HONG: 'Xem chi tiết hoa hồng',

  GET_DS_BO_PHAN: 'Xem danh sách bộ phận',
  THEM_MOI_BO_PHAN: 'Thêm mới bộ phận',
  CHINH_SUA_BO_PHAN: 'Chỉnh sửa bộ phận',

  GET_DS_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI: 'Xem danh sách loại hàng hóa thuốc vật tư phân loại',
  XUAT_EXCEL_DS_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI:
    'Xuất excel danh sách loại hàng hóa thuốc vật tư phân loại',
  GET_DETAIL_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI:
    'Xem chi tiết loại hàng hóa thuốc vật tư phân loại',
  THEM_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI: 'Thêm mới loại hàng hóa thuốc vật tư phân loại',
  CHINH_SUA_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI: 'Chỉnh sửa loại hàng hóa thuốc vật tư phân loại',
  XOA_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI: 'Xoá loại hàng hóa thuốc vật tư phân loại',

  XUAT_EXCEL_DS_LOAI_HANG_HOA_THUOC_VAT_TU_NHOM_TAT_CA:
    'Xuất excel danh sách loại hàng hóa thuốc vật tư nhóm tất cả',
  XUAT_EXCEL_DS_LOAI_HANG_HOA_THUOC_VAT_TU_NHOM_THEO_LOAI:
    'Xuất excel danh sách loại hàng hóa thuốc vật tư nhóm theo loại',
  THEM_LOAI_HANG_HOA_THUOC_VAT_TU_NHOM: 'Thêm mới loại hàng hóa thuốc vật tư nhóm',
  CHINH_SUA_LOAI_HANG_HOA_THUOC_VAT_TU_NHOM: 'Chỉnh sửa loại hàng hóa thuốc vật tư nhóm',
  UPDATE_TPCN_LOAI_HANG_HOA_THUOC_VAT_TU_NHOM:
    'Cập nhật thực phẩm chức năng loại hàng hóa thuốc vật tư nhóm',

  GET_DS_DINH_MUC_TIEU_HAO_DICH_VU: 'Xem danh sách dịch vụ định mức tiêu hao',
  GET_DETAIL_DINH_MUC_TIEU_HAO_DICH_VU: 'Xem chi tiết dịch vụ định mức tiêu hao',
  THEM_DINH_MUC_TIEU_HAO_DICH_VU: 'Thêm mới dịch vụ định mức tiêu hao',
  CHINH_SUA_DINH_MUC_TIEU_HAO_DICH_VU: 'Chỉnh sửa dịch vụ định mức tiêu hao',
  XOA_DINH_MUC_TIEU_HAO_DICH_VU: 'Xoá dịch vụ định mức tiêu hao',

  GET_DS_DINH_MUC_TIEU_HAO_XET_NGHIEM: 'Xem danh sách xét nghiệm định mức tiêu hao',
  GET_DETAIL_DINH_MUC_TIEU_HAO_XET_NGHIEM: 'Xem chi tiết xét nghiệm định mức tiêu hao',
  THEM_DINH_MUC_TIEU_HAO_XET_NGHIEM: 'Thêm mới xét nghiệm định mức tiêu hao',
  CHINH_SUA_DINH_MUC_TIEU_HAO_XET_NGHIEM: 'Chỉnh sửa xét nghiệm định mức tiêu hao',
  XOA_DINH_MUC_TIEU_HAO_XET_NGHIEM: 'Xoá xét nghiệm định mức tiêu hao',

  GET_DS_DINH_MUC_TIEU_HAO_CDHA: 'Xem danh sách CDHA định mức tiêu hao',
  GET_DETAIL_DINH_MUC_TIEU_HAO_CDHA: 'Xem chi tiết CDHA định mức tiêu hao',
  THEM_DINH_MUC_TIEU_HAO_CDHA: 'Thêm mới CDHA định mức tiêu hao',
  CHINH_SUA_DINH_MUC_TIEU_HAO_CDHA: 'Chỉnh sửa CDHA định mức tiêu hao',
  XOA_DINH_MUC_TIEU_HAO_CDHA: 'Xoá CDHA định mức tiêu hao',

  GET_CHI_NHANH_DICH_VU_XET_NGHIEM: 'Xem danh sách dịch vụ xét nghiệm',
  XUAT_EXCEL_CHI_NHANH_DICH_VU_XET_NGHIEM: 'Xuất excel danh sách dịch vụ xét nghiệm',
  THEM_MOI_DICH_VU_XET_NGHIEM: 'Thêm mới dịch vụ xét nghiệm',
  CHINH_SUA_DICH_VU_XET_NGHIEM: 'Thêm mới dịch vụ xét nghiệm',
  XOA_DICH_VU_XET_NGHIEM: 'Xoá dịch vụ xét nghiệm',
  CAP_NHAT_CHI_NHANH_DICH_VU_XET_NGHIEM: 'Cập nhật chi nhánh dịch vụ xét nghiệm',
  CAP_NHAT_CHI_TIET_DICH_VU_XET_NGHIEM_CHI_NHANH: 'Cập nhật chi tiết dịch vụ xét nghiệm chi nhánh',

  THEM_MOI_CDHA: 'Thêm mới chẩn đoán hình ảnh',
  CHINH_SUA_CDHA: 'Chỉnh sửa chẩn đoán hình ảnh',
  XOA_CDHA: 'Xoá chẩn đoán hình ảnh',

  GET_CHI_NHANH_DICH_VU_DANH_MUC: 'Xem danh sách chi nhánh dịch vụ',
  XUAT_EXCEL_DANH_MUC_DICH_VU_DANH_MUC: 'Xuất excel danh mục dịch vụ',
  THEM_MOI_DICH_VU_DANH_MUC: 'Thêm mới dịch vụ danh mục',
  CHINH_SUA_DICH_VU_DANH_MUC: 'Chỉnh sửa dịch vụ danh mục',
  XOA_DICH_VU_DANH_MUC: 'Xoá dịch vụ danh mục',
  CAP_NHAT_CHI_NHANH_DICH_VU: 'Cập nhật chi nhánh dịch vụ',
  CAP_NHAT_CHI_TIET_DICH_VU_CHI_NHANH: 'Cập nhật chi tiết dịch vụ chi nhánh',

  GET_DANH_SACH_BIEU_MAU: 'Xem danh sách biểu mẫu',
  THEM_MOI_BIEU_MAU: 'Thêm mới biểu mẫu',
  CHINH_SUA_BIEU_MAU: 'Chỉnh sửa biểu mẫu',
  XOA_BIEU_MAU: 'Xoá biểu mẫu',

  GET_DANH_SACH_PHONG_KHAM: 'Xem danh sách phòng khám',
  GET_DANH_SACH_CHI_NHANH_PHONG_KHAM: 'Xem danh sách chi nhánh phòng khám',
  THEM_MOI_PHONG_KHAM: 'Thêm mới phòng khám',
  CHINH_SUA_PHONG_KHAM: 'Chỉnh sửa phòng khám',
  THEM_MOI_CHI_NHANH_PHONG_KHAM: 'Thêm mới chi nhánh phòng khám',
  XOA_CHI_NHANH_PHONG_KHAM: 'Xoá chi nhánh phòng khám',

  //CRM
  GET_DS_CONG_TY_CRM: 'Xem danh sách công ty CRM',
  THEM_MOI_CONG_TY_CRM: 'Thêm mới công ty CRM',
  CAP_NHAT_CONG_TY_CRM: 'Cập nhật công ty CRM',
  GET_THONG_TIN_LIEN_HE: 'Xem thông tin liên hệ',
  ADD_THONG_TIN_LIEN_HE: 'Xem thông tin liên hệ',
  EDIT_THONG_TIN_LIEN_HE: 'Xem thông tin liên hệ',

  GET_DS_GOI_KHAM: 'Xem danh sách gói khám',
  GET_DETAIL_GOI_KHAM: 'Xem chi tiết gói khám',
  ADD_GOI_KHAM: 'Thêm gói khám',
  EDIT_GOI_KHAM: 'Cập nhật gói khám',
  DELETE_GOI_KHAM: 'Xoá gói khám',

  GET_DS_KHACH_HANG_CRM: 'Xem danh sách khách hàng CRM',
  GET_DS_KH_BY_IDNV: 'Xem danh sách khách hàng CRM by id nhân viên',
  ADD_KHACH_HANG: 'Thêm khách hàng',
  EDIT_KHACH_HANG: 'Cập nhật khách hàng',

  GET_DS_GOI_KHAM_KHACH_HANG: 'Xem danh sách gói khám khách hàng',
  ADD_GOI_KHAM_KHACH_HANG: 'Thêm gói khám khách hàng',
  EDIT_GOI_KHAM_KHACH_HANG: 'Cập nhật gói khám khách hàng',

  GET_DETAIL_PHAN_CONG: 'Xem chi tiết phân công',
  ADD_PHAN_CONG: 'Thêm phân công',
  EDIT_PHAN_CONG: 'Cập nhật phân công',

  GET_DS_CHI_TIET_LIEN_HE: 'Xem danh sách chi tiết liên hệ',
  GET_DETAIL_CHI_TIET_LIEN_HE: 'Xem chi tiết liên hệ',
  ADD_CHI_TIET_LIEN_HE: 'Thêm chi tiết liên hệ',
  DOWNLOAD_FILE_CHI_TIET_LIEN_HE: 'Download file chi tiết liên hệ',

  GET_DS_BENH_NHAN_CRM: 'Xem danh sách bệnh nhân CRM',
  GUI_SMS_DS_BENH_NHAN: 'Gửi SMS theo danh sách bệnh nhân đã chọn',
  GUI_SMS_ALL_BENH_NHAN: 'Gửi SMS theo tất cả bệnh nhân theo điều kiện',
  XUAT_EXCEL_ALL_BENH_NHAN_CRM: 'Gửi SMS theo tất cả bệnh nhân theo điều kiện',
  IMPORT_DS_BENH_NHAN_CRM: 'Import excel danh sách bệnh nhân CRM',
  ADD_BENH_NHAN_CRM: 'Thêm bệnh nhân CRM',
  EDIT_BENH_NHAN_CRM: 'Cập nhật bệnh nhân CRM',
  DELETE_BENH_NHAN_CRM: 'Xoá bệnh nhân CRM',

  //Sự cố
  TAO_BAO_HONG: 'Tạo báo hỏng',
  CAP_NHAT_SU_CO: 'Cập nhật sự cố',
  XOA_SU_CO: 'Xoá sự cố',
  THEM_NHAN_VIEN_SU_CO: 'Thêm nhân viên sự cố',
  THEM_NHAN_SU_CO: 'Thêm nhãn sự cố',
  CAP_NHAT_NHAN_SU_CO: 'Cập nhật nhãn sự cố',
  XOA_NHAN_SU_CO: 'Xoá nhãn sự cố',
  THEM_DANH_MUC_SU_CO: 'Thêm danh mục sự cố',
  CAP_NHAT_DANH_MUC_SU_CO: 'Cập nhật danh mục sự cố',
  DELETE_DANH_MUC_SU_CO: 'Xoá danh mục sự cố',
  TAO_VUNG_SU_CO: 'Tạo vùng sự cố',
  THEM_NHAN_VIEN_VUNG_SU_CO: 'Thêm nhân viên vùng sự cố',
  XOA_NHAN_VIEN_VUNG_SU_CO: 'Xoá nhân viên vùng sự cố',
  THEM_CHI_NHANH_VUNG_SU_CO: 'Thêm chi nhánh vùng sự cố',
  XOA_CHI_NHANH_VUNG_SU_CO: 'Xoá chi nhánh vùng sự cố',
  XOA_NHAN_VIEN_SU_CO: 'Xoá nhân viên sự cố',

  //VPP
  THEM_DON_DAT_HANG: 'Thêm đơn đặt hàng',
  CAP_NHAT_TRANG_THAI_DON_DAT_HANG: 'Cập nhật trạng thái đơn đặt hàng',
  XOA_DON_DAT_HANG: 'Xoá đơn đặt hàng',
  SUA_DON_DAT_HANG: 'Sửa đơn đặt hàng',
  DUYET_DON_DAT_HANG: 'Duyệt đơn đặt hàng',
  CAP_NHAT_DON_HANG_THANH_CONG: 'Cập nhật đơn hàng thành công',
  THEM_VVP_DAT_HANG_TONG_HOP: 'Thêm VVP đặt hàng tổng hợp',

  // ORTHER
  TRA_CUU_BENH_NHAN: 'Tra cứu bệnh nhân',
}
