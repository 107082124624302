import React, { useEffect, useRef, useState, useCallback } from 'react'
import * as TypeAction from '../../../store/constants/constants'
import _ from 'lodash'
import { Input, Select, Divider, Tooltip, Checkbox, Avatar } from 'antd'
import SyncIcon from '@mui/icons-material/Sync'
import {
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
  SolutionOutlined,
  PrinterOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  addNewBNTTC_Action,
  getAllSelectClinicAction,
  getInfoBN_Action,
  getListDiscount,
  listPKByChiNhanh_Action,
  removeListDiscount,
  searchBN_Action,
  setChuongTrinhGiamGiaDoiTac,
  getInfoNguoiThan,
} from '../../../store/actions/receiveAction'
import { phanQuyenService } from '../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../store/actions/phanQuyenAction'
import TableHistory from './Table/TableHistory'
import { Button } from '@mui/material'
import TabsChiDinh from './TabsChiDinh/TabsChiDinh'
import TablePay from './Table/TablePay'
import ModalCreateCustomer from './ModalCreateCustomer/ModalCreateCustomer'
import moment from 'moment'
import { useFormik } from 'formik'
import ModalPay from './ModalPay/ModalPay'
import { formatNumberVND } from '../../../utils/formatNumberVND'
import { https } from '../../../services/apiService'
import { receiveSchema } from '../../../schemas/receiveSchema'
import ToastCus from '../../../utils/Toast'
import { recieveService } from '../../../services/receive/recieveService'
import { calculateAge } from '../../../utils/calculateAge'
import 'moment/locale/vi'
import PrinterTTQG from '../../../utils/PrinterTTQG'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../utils/log/logConstant'
import { addLog } from '../../../utils/log/apiLog'
import { formatPayloadDetails } from '../../../utils/algorithm'
import TabsChiDinhNew from './TabsChiDinh/TabsChiDinhNew'
// import '../../../App.css'
moment.locale('vi')
const idnhomDV = [1, 2, 3, 4, 6, 21, 22, 24, 25, 42, 46]

const Receive = ({ BnDkkOnline }) => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const barcodeRef = useRef(null)
  const componentRef = useRef()
  const {
    nguonKH,
    phongKham,
    hinhThucTT,
    nguoiThan,
    listBN,
    infoBN,
    discountArr,
    infoChuongTrinhGiamGiaDoiTac,
    ArrXN,
    ArrCĐHA,
    ArrKB,
    paymentCard,
    memberCard,
    listCombo,
    listCTGiamGia,
  } = useSelector((state) => state.receiveReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  // const { ArrXN, ArrCĐHA, ArrKB, paymentCard, memberCard } = useSelector(
  //   (state) => state.receiveReducer,
  // )
  const newArr = ArrKB.concat(ArrXN, ArrCĐHA) // nối các mãng lại với nhau
  const tongThanhToan = newArr.reduce((tong, item) => tong + item.thanhToan, 0)
  const tongGia = newArr.reduce((tong, item) => tong + item.donGia, 0)
  const tongGiam = newArr.reduce((tong, item) => tong + item.tienGiam, 0)
  const [openModaCreateCustomer, setOpenModaCreateCustomer] = useState(false)
  const idBranchLogin = Number(JSON.parse(localStorage.getItem('BRANH_LOGIN')))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [history, setHistory] = useState([])
  const [isModalPay, setIsModalPay] = useState(false)
  const [isPrint, setIsPrint] = useState(false)
  const [listVoucher, setListVoucher] = useState([]) // list chương trình đối tác gotit, bảo hiểm
  const [isDoiTac, setIsDoiTac] = useState(false)
  const [isQuaTang, setIsQuaTang] = useState(false)
  const [valueQuaTang, setValueQuaTang] = useState([])
  const [valueChuongTrinhQuaTang, setValueChuongTrinhQuaTang] = useState('')
  const [valueSoLuongQuaTang, setValueSoLuongQuaTang] = useState(0)
  const [maVoucher, setMaVoucher] = useState('')
  const [maVoucherDoiTac, setMaVoucherDoiTac] = useState('')
  const [valueGiaVoucherDoiTac, setValueGiaVoucherDoiTac] = useState(0)
  const [valueChuongTrinhDoiTac, setValueChuongTrinhDoiTac] = useState(null)
  const [hoaDon, setHoaDon] = useState(false)
  const [CTComboApply, setCTComboApply] = useState(null)
  const [CTGiamGiaApply, setCTGiamGiaApply] = useState(null)
  const [listMaVoucher, setListMaVoucher] = useState([]) // list mã voucher ['abc','xyz',...]
  const [listDVTruocApplyVC, setListDVTruocApplyVC] = useState([])
  const [formPay, setFormPay] = useState({
    // idbntt: 0,
    // idbnttc: 0,
    idPhuongThuc: 1,
    // idHinhThuc: 0,
    hoaDonDienTu: 0,
    // maVoucher: 0,
    tienThanhToan: 0,
    idloaitt: 1,
    // ngayThanhToan: "2024-05-08T02:09:46.790Z",
    thuNgan: infoUser?.tenNV,
    tienDichVu: 0,
    tienXetNghiem: 0,
    tienCDHA: 0,
    tienGiamGia: 0,
    // idCaLamViec: infoUser?.dangNhap.idCaLamViec,
    ghiChuXuatHoaDon: '',
  })

  useEffect(() => {
    const fetchQuaTang = async () => {
      try {
        const response = await https.get(
          `/TonKho/GetKhoQuaTangByChiNhanh?IDChiNhanh=${idBranchLogin}`,
        )
        setValueQuaTang(response.data)
      } catch (error) {
        console.error('Error fetching quà tặng:', error)
      }
    }

    fetchQuaTang()
  }, [ArrKB, ArrXN, ArrCĐHA, discountArr, infoChuongTrinhGiamGiaDoiTac, listCombo, listCTGiamGia])
  const [form, setForm] = useState({
    // idbntt: 0,
    // idbnttchung: 0,
    idthe: null,
    tienmat: 0,
    chuyenkhoan: 0,
    thett: 0,
    momo: 0,
    vnpay: 0,
    zalopay: 0,
    pos: 0,
    tongtien: 0,
    tiengiamgia: 0,
    tienthanhtoan: 0,
    trangthaithanhtoan: 2,
  })
  const handleChangeCheckbox = (e) => {
    const { checked } = e.target
    formik.setFieldValue('hoaDon', checked ? 1 : 0)
    setFormPay((preState) => ({ ...preState, hoaDonDienTu: checked ? 1 : 0 }))
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      if (keyword?.trim()?.length > 0) {
        dispatch(searchBN_Action(keyword?.trim(), ID_COMPANY))
      }
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  // show modal create new customer
  const showCreateCustomer = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT027',
      )
      setOpenModaCreateCustomer(true)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  // cacel show modal create new customer
  const cancelCreateCustomer = () => {
    setOpenModaCreateCustomer(false)
  }
  // handleChange Selected
  const handleChangeSeleted = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  // handle Change info bệnh nhân

  // ---------- lấy danh sach lịch sử khám bệnh  --------//
  const fetchHistory = async (id) => {
    try {
      const { data } = await recieveService.getHitoryBN(id)
      setHistory(data)
    } catch (error) {
      console.log(error)
    }
  }

  // //---------- in mã TCQG----------------
  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=350,width=900')
    printWindow.document.write('<html><head><title>Print</title>')
    printWindow.document.write(`
     <style>
      @media print {
        @page {
          margin: 0; /* Loại bỏ khoảng trắng ngoài */
        }
        body, html {
          // width: 50mm;
          // height: 30mm;
          margin: 0;
          // border: 1px solid black;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: rotate(0deg);
        }
      }
    </style>
    `)
    printWindow.document.write('</head><body>')
    printWindow.document.write(barcodeRef.current.innerHTML) // Sử dụng useRef để lấy nội dung
    printWindow.document.write('</body></html>')
    printWindow.document.close()
    printWindow.print()

    // Đợi một khoảng thời gian ngắn và sau đó tự động đóng cửa sổ in
    setTimeout(() => {
      printWindow.close() // Đóng cửa sổ in
    }, 300) // Thay đổi thời gian nếu cần
    // TTQGPDF(infoBN?.tenBenhNhan,infoBN?.maTCQG || '00000000')
  }
  // hanldle Sumit
  const handleSubmit = (value) => {
    let Arr = [],
      checkKhongDiKham = true
    ArrKB?.map((item) => {
      if (
        idnhomDV.includes(item.dichVu.idNhomDV)
        // item.dichVu.idNhomDV === 1 ||
        // item.dichVu.idNhomDV === 2 ||
        // item.dichVu.idNhomDV === 3 ||
        // item.dichVu.idNhomDV === 4 ||
        // item.dichVu.idNhomDV === 21 ||
        // item.dichVu.idNhomDV === 22 ||
        // item.dichVu.idNhomDV === 24 ||
        // item.dichVu.idNhomDV === 25 ||
        // item.dichVu.idNhomDV === 42 ||
        // item.dichVu.idNhomDV === 46
      )
        checkKhongDiKham = false // là có đi khám

      // Arr.push({
      //   idthe: memberCard?.idbn === infoBN?.idbn ? memberCard?.idthe : null,
      //   idbn: infoBN?.idbn,
      //   tiensd: item.thanhToan,
      //   trangthaisd: 0,
      //   idlt: memberCard?.idlt,
      //   iddv: item.idChiDinh,
      //   idcn: PkDangNhap?.idChiNhanh,
      //   nguoinhan: infoUser?.tenNV,
      //   nguoitt: infoBN?.tenBenhNhan,
      //   ghichu: '',
      // })
    })
    if (
      (ArrKB?.length > 0 && checkKhongDiKham) ||
      (ArrKB?.length === 0 && (ArrXN?.length > 0 || ArrCĐHA?.length > 0))
    )
      value.trangthaikhambenh = 4
    else value.trangthaikhambenh = 1
    value.tenbennhan = infoBN?.tenBenhNhan ?? ''
    dispatch(
      addNewBNTTC_Action(
        value,
        ArrXN,
        ArrCĐHA,
        ArrKB,
        {
          //form api BenhNhanThanhToan
          ...formPay,
          idcn: PkDangNhap?.idChiNhanh,
          idct: PkDangNhap?.idCongTy,
          idchuyenkhoa: PkDangNhap?.idChuyenKhoa,
          idCaLamViec: infoUser?.dangNhap.idCaLamViec,
          thuNgan: infoUser?.tenNV,
          idThuNgan: infoUser?.idnv,
          tienthanhtoan: form.tienthanhtoan,
          tiengiamgia: tongGiam,
          tienGiamGia: tongGiam,
          maVoucher: maVoucher?.trim()?.length > 0 ? maVoucher?.trim() : null,
          mavoucherdoitac: maVoucherDoiTac?.trim()?.length > 0 ? maVoucherDoiTac?.trim() : null,
          listMaVC: listMaVoucher?.length > 0 ? listMaVoucher : null,
          chuongtrinhcombo: CTComboApply?.trim()?.length > 0 ? CTComboApply : null,
          chuongtrinhgiamgia: CTGiamGiaApply?.trim()?.length > 0 ? CTGiamGiaApply : null,
          idctdoitac: infoChuongTrinhGiamGiaDoiTac?.idctDoiTac ?? null,
          tiengiamdoitac: infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0,
        },
        {
          //form api BenhNhanChiTietTT
          ...form,
          idthe: memberCard?.idbn === infoBN?.idbn ? memberCard?.idthe : null,
          tiengiamgia: tongGiam,
          tiengiamdoitac: infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0,
        },
        paymentCard,
        isPrint,
        maVoucher,
        maVoucherDoiTac,
        // Add gift form if conditions are met
        isQuaTang && formQuaTang?.idThuoc
          ? {
              idThuoc: formQuaTang.idThuoc,
              tenBietDuoc: formQuaTang.tenBietDuoc,
              soLuong: formQuaTang.soLuong,
              donGia: formQuaTang.donGia,
              thanhTien: formQuaTang.thanhTien,
              tongTien: formQuaTang.tongTien,
              nguoiThucHien: formQuaTang.nguoiThucHien,
              ngayThucHien: moment().format(),
              idKhoCN: formQuaTang.idKhoCN,
              soLo: formQuaTang.soLo,
              hanDung: formQuaTang.hanDung,
              soHoaDon: formQuaTang.soHoaDon || '',
              ghiChu: '',
            }
          : null,
      ),
    )
    // dispatch(postListDVApplyTTV_Action(Arr))
    formik.resetForm()
    dispatch({
      type: TypeAction.DISPATCH_INFO_BENH_NHAN,
      payload: null,
    })
    dispatch({
      type: TypeAction.DISPATCH_MEMBER_CARD_RECEIVE,
      payload: null,
    })
    dispatch({
      type: TypeAction.UPDATE_NEW_PRICE_CHI_DINH,
      payload: [],
    })
    dispatch({
      type: TypeAction.DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC,
      payload: null,
    })
    setHistory([])
    setFormPay({
      idPhuongThuc: 1,
      hoaDonDienTu: 0,
      tienThanhToan: 0,
      thuNgan: infoUser?.tenNV,
      tienDichVu: 0,
      tienXetNghiem: 0,
      tienCDHA: 0,
      tienGiamGia: 0,
      idloaitt: 1,
      // idCaLamViec: infoUser?.dangNhap.idCaLamViec,
      ghiChuXuatHoaDon: '',
    })
    setForm({
      // idbntt: 0,
      // idbnttchung: 0,
      idthe: null,
      tienmat: 0,
      chuyenkhoan: 0,
      thett: 0,
      momo: 0,
      vnpay: 0,
      zalopay: 0,
      pos: 0,
      tongtien: 0,
      tiengiamgia: 0,
      tienthanhtoan: 0,
      trangthaithanhtoan: 2,
    })
    setIsDoiTac(false)
    setMaVoucherDoiTac('')
    setValueGiaVoucherDoiTac(0)
    setValueChuongTrinhDoiTac(null)
  }
  //############# FORM ############
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbn: infoBN?.idbn,
      chieucao: null,
      cannang: null,
      mach: '',
      huyetap: '',
      nhietdo: '',
      nhiptho: '',
      spO2: '',
      idcnpk: phongKham?.length > 0 ? phongKham[0]?.idcnpk : null,
      // idlankham: 1, // Lần khám set là khám lần đầu
      idnguon: 1, // mặc định nguồn là người nhà
      ghichu: '',
      nguoitao: infoUser?.tenNV,
      ngaykham: '',
      trangthaikhambenh: 1, // trạng thái tiếp nhận khám bệnh
      // idvoucher: 0,
      lydokham: null,
      idcalamviec: infoUser?.dangNhap.idCaLamViec,
      idcn: PkDangNhap?.idChiNhanh,
      idct: PkDangNhap?.idCongTy,
      idchuyenkhoa: PkDangNhap?.idChuyenKhoa,
      nguoinha: '',
      tennguoinha: '',
      dienthoainguoinha: '',
      ghiChuHoaDon: infoBN?.ghiChuHoaDon,
      hoaDon: infoBN?.hoaDon,
      // sottv: '',
      // loaittv: '',
      // tungayttv: null,
      // denngayttv: null,
      // idsudungthe: 0,
      // idloaikham: 1,
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: receiveSchema,
  })
  useEffect(() => {
    dispatch(getAllSelectClinicAction())
    dispatch(listPKByChiNhanh_Action(idBranchLogin))
  }, [])

  useEffect(() => {
    setIsQuaTang(false)
    setValueChuongTrinhQuaTang('')
    setValueSoLuongQuaTang(1)
  }, [infoBN])
  // useEffect(() => {
  //   setForm((prevState) => ({
  //     ...prevState,
  //     tienthanhtoan: infoChuongTrinhGiamGiaDoiTac ? tongThanhToan - 0 : tongThanhToan,
  //     tiengiamgia: infoChuongTrinhGiamGiaDoiTac ? tongGiam + 0 : tongGiam,
  //   }))
  // }, [tongThanhToan, tongGiam, infoChuongTrinhGiamGiaDoiTac])

  //auto check xuat hoa don
  // useEffect(() => {
  //   setFormPay({
  //     ...formPay,
  //     thuNgan: infoUser?.tenNV,
  //     ghiChuXuatHoaDon: formik.values.ghiChuHoaDon,
  //     hoaDonDienTu: formik.values.hoaDon,
  //   })
  // }, [infoUser, formik.values.ghiChuHoaDon, formik.values.hoaDon])

  useEffect(() => {
    setFormPay({
      ...formPay,
      thuNgan: infoUser?.tenNV,
    })
  }, [infoUser])

  //set tổng thanh toán
  // useEffect(() => {
  //   const total = infoChuongTrinhGiamGiaDoiTac
  //     ? formPay.tienDichVu + formPay.tienCDHA + formPay.tienXetNghiem - 0
  //     : formPay.tienDichVu + formPay.tienCDHA + formPay.tienXetNghiem
  //   setFormPay((prevState) => ({ ...prevState, tienThanhToan: total }))
  // }, [formPay.tienDichVu, formPay.tienCDHA, formPay.tienXetNghiem, infoChuongTrinhGiamGiaDoiTac])
  // set tổng thanh toán khám bệnh

  const handlePay = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT041',
      )
      if (
        infoBN &&
        newArr.length &&
        ((maVoucher && discountArr.length === 0) ||
          (valueGiaVoucherDoiTac && !infoChuongTrinhGiamGiaDoiTac))
      ) {
        ToastCus.fire({
          icon: 'error',
          title: 'Vui lòng chọn áp dụng voucher hoặc đối tác!',
        })
        return
      }

      if (isQuaTang && ![1, 3].includes(infoBN?.idLoaiKham)) {
        ToastCus.fire({
          icon: 'error',
          title: 'Quà tặng "Bao lì xì" chỉ áp dụng cho loại khám bệnh mới hoặc khám lần đầu"',
        })
        return
      }

      setForm({
        ...form,
        tiengiamgia: Number(tongGiam), //+ Number(infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0),
        tienthanhtoan:
          Number(tongThanhToan) - Number(infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0),
      })
      setIsModalPay(true)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const handleApplyVoucher = async () => {
    if (infoChuongTrinhGiamGiaDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập voucher trước khi nhập đối tác!',
      })
      return
    }
    if (tongThanhToan <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Giá trị hóa đơn thanh toán nhỏ hơn giá trị voucher!',
      })
      return
    }
    if (!maVoucher || maVoucher?.trim().length <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Voucher không hợp lệ!',
      })
      return
    }
    // dispatch(applyVoucherAction(ArrKB, temp, {
    //   nguoikichhoat: infoUser.tenNV,
    //   iddt: infoBN?.idDoiTuong ?? -1,
    //   idcn: PkDangNhap?.idChiNhanh,
    // }))

    // call api get list discount || return
    // if (!maVoucher || maVoucher?.trim().length <= 0) return
    try {
      let temp = maVoucher
        ?.trim()
        ?.replaceAll(' ', '')
        ?.replaceAll(';', ',')
        ?.replaceAll('-', ',')
        ?.replaceAll('/', ',')
      setMaVoucher(temp)
      // let dataApply = [...newArr]
      let formatData = newArr?.map((item) => ({
        iddv: item.idChiDinh,
        dongia: item.donGia,
        mavoucher: null, ///////////////
        ptgiam: Math.round(item.ptGiam),
        tiengiam: Math.round(item.tienGiam),
        thanhtien: Math.round(item.thanhToan),
        trangthaitt: 1,
        nguoikichhoat: infoUser.tenNV,
        iddt: infoBN?.idDoiTuong ?? -1,
        idgt:
          ((infoBN?.gioiTinh === `Nam` && 1) ||
            (infoBN?.gioiTinh === `Nữ` && 2) ||
            (infoBN?.gioiTinh === `Khác` && 3)) ??
          -1,
        idcn: PkDangNhap?.idChiNhanh,
        loaivoucherdichvu:
          (item.type === 'KB' && 1) || (item.type === 'XN' && 2) || (item.type === 'CDHA' && 3), /////////
      }))
      setListDVTruocApplyVC(newArr)
      let listMaVC = []
      const vouchers = temp?.split(',')?.filter((vc) => _.isString(vc) && vc?.length > 0)
      if (vouchers?.filter((item, index) => vouchers?.indexOf(item) !== index)?.length > 0) {
        ToastCus.fire({
          icon: 'error',
          title: `Voucher bị trùng!`,
        })
        return
      }
      for (let i = 0; i < vouchers.length; i++) {
        const vc = vouchers[i]
        listMaVC.push(vc)
        formatData = formatData?.map((item) => ({
          ...item,
          mavoucher: vc, // gán mã voucher hiện tại
        }))
        const { data } = await recieveService.postApplyVoucherTiepNhan(formatData) // https.post(`VoucherDanhSach/ApplyVoucher`, formatData);
        formatData = [...data] // cập nhật formatData với kết quả từ API
        await addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.APPLY_VOUCHER,
          true,
          MENU_CONSTANTS.TIEP_NHAN,
          null,
          {
            mavoucher: vc,
          },
        )
      }
      setListMaVoucher(listMaVC)
      // dispatch action apply voucher
      dispatch(getListDiscount(formatData))
    } catch (error) {
      console.log(error)
      const message = error?.response?.data?.message
      ToastCus.fire({
        icon: 'error',
        title: (message ? `${message}!\n` : '') + 'Áp dụng voucher thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message} \nList voucher: ${maVoucher}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.TIEP_NHAN,
        detailErrorPayload,
      )
    }

    // const voucherCodes = await temp?.split(',') || [];
    // for (const vc of voucherCodes) {
    //   if (_.isString(vc) && vc?.length > 0) {
    //     formatData = await formatData?.map((item) => ({
    //       ...item,
    //       mavoucher: vc,///////////////
    //     }))
    //     const { data } = await https.post(`VoucherDanhSach/ApplyVoucher`, formatData)//////// formatData
    //     formatData = [...data]
    //     console.log(formatData)
    //   }
    // }
    // console.log('>>>>>>>>>>>>>>>>', formatData)
    // const formatData = newArr?.map((item) => ({
    //   iddv: item.idChiDinh,
    //   dongia: item.donGia,
    //   mavoucher: vc,///////////////
    //   ptgiam: item.ptGiam,
    //   tiengiam: item.tienGiam,
    //   thanhtien: item.thanhToan,
    //   trangthaitt: 1,
    //   nguoikichhoat: infoUser.tenNV,
    //   iddt: infoBN?.idDoiTuong ?? -1,
    //   idcn: PkDangNhap?.idChiNhanh,
    // }))
    // let cloneArrKB = [...ArrKB]
    // cloneArrKB.forEach((item) => {
    //   data.forEach((element) => {
    //     if (item.idChiDinh === element.iddv) {
    //       item.tienGiam = element.giatrigiam
    //       item.giaTienSauKhiGiam = element.giatri
    //       item.ptGiam = element.phantramgiam
    //       item.thanhToan = element.giatri
    //     }
    //   })
    // })
    // formatData = [...cloneArrKB, ...ArrXN, ...ArrCĐHA]
    // // yield put({
    // //   type: typeAction.UPDATE_NEW_PRICE_CHI_DINH,
    // //   payload: listDV,
    // // })
    // dispatch({
    //   type: TypeAction.DISPATCH_SET_ARR_KB,
    //   payload: cloneArrKB,
    // })

    // dispatch action apply voucher
    // dispatch(getListDiscount(formatData))

    // const formatData = newArr.map((item) => ({
    //   iddv: item.idChiDinh,
    //   dongia: item.donGia,
    //   mavoucher: maVoucher?.trim(),
    //   ptgiam: item.ptGiam,
    //   tiengiam: item.tienGiam,
    //   thanhtien: item.thanhToan,
    //   trangthaitt: 1,
    //   nguoikichhoat: infoUser.tenNV,
    //   iddt: infoBN?.idDoiTuong,
    //   idcn: PkDangNhap?.idChiNhanh,
    // }))
    // const { data } = await https.post(`VoucherDanhSach/ApplyVoucher`, dataApply)//////// formatData
    // console.log(data[data.length - 1])
    // if (data?.length > 0 && data[0]?.giatrigiam === 0) {
    //   handleUnApplyVoucher()
    //   ToastCus.fire({
    //     icon: 'error',
    //     title: 'Voucher không hợp lệ!',
    //   })
    //   return
    // }
  }
  const handleNguoiNha = (value) => {
    formik.setFieldValue('nguoinha', value)
    dispatch(getInfoNguoiThan(infoBN?.idbn, value, formik))
  }
  const handleUnApplyVoucher = async () => {
    //call api unuse voucher
    try {
      //https.put(`VoucherDanhSach/UpdateCancelVoucher?mavoucher=${maVoucher}`)
      //dispatch action apply voucher
      dispatch(removeListDiscount(listDVTruocApplyVC))
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.UNAPPLY_VOUCHER,
        true,
        MENU_CONSTANTS.TIEP_NHAN,
        null,
        {
          listMa: listMaVoucher.join(','),
        },
      )
      // dispatch({
      //   type: TypeAction.DISPATCH_SET_ARR_KB,
      //   payload: listDVTruocApplyVC,
      // })
      // dispatch({
      //   type: TypeAction.UPDATE_NEW_PRICE_CHI_DINH,
      //   payload: [],
      // })
      setListMaVoucher([])
      setMaVoucher('')
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message,
      })
      console.log(error)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.TIEP_NHAN,
        'Có lỗi xảy ra khi huỷ áp dụng voucher \n' + JSON.stringify(error),
      )
    }
  }
  const handleInfoBN = (value) => {
    dispatch(getInfoBN_Action(value, PkDangNhap?.idChuyenKhoa, formik))
    fetchHistory(value)
  }
  const handleChangeDoiTac = (e) => {
    if (!infoBN) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn bệnh nhân!',
      })
      return
    }
    if (!newArr.length) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng thêm chỉ định!',
      })
      return
    }
    if (infoChuongTrinhGiamGiaDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng bỏ sử dụng voucher đối tác',
      })
      return
    }
    setIsDoiTac(e.target.checked)
    if (!e.target.checked) {
      setMaVoucherDoiTac('')
      setValueGiaVoucherDoiTac(0)
      setValueChuongTrinhDoiTac('')
    }
  }

  const handleApplyVoucherDoiTac = () => {
    if (!valueChuongTrinhDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn chương trình đối tác',
      })
      return
    }
    if (!valueGiaVoucherDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng nhập giá trị voucher của đối tác',
      })
      return
    }
    if (tongThanhToan < valueGiaVoucherDoiTac) {
      ToastCus.fire({
        icon: 'error',
        title: 'Giá trị hóa đơn thanh toán nhỏ hơn giá trị voucher',
      })
      setMaVoucherDoiTac('')
      setValueGiaVoucherDoiTac(0)
      return
    }
    dispatch(
      setChuongTrinhGiamGiaDoiTac(
        {
          idctDoiTac: valueChuongTrinhDoiTac,
          maVoucher: maVoucherDoiTac,
          giaVoucher: valueGiaVoucherDoiTac,
        },
        true,
      ),
    )
  }

  const handleUnApplyVoucherDoiTac = () => {
    dispatch(
      setChuongTrinhGiamGiaDoiTac(
        {
          idctDoiTac: valueChuongTrinhDoiTac,
          maVoucher: maVoucherDoiTac,
          giaVoucher: valueGiaVoucherDoiTac,
        },
        false,
      ),
    )
    setIsDoiTac(false)
    setMaVoucherDoiTac('')
    setValueGiaVoucherDoiTac(0)
    setValueChuongTrinhDoiTac('')
  }
  useEffect(() => {
    setListMaVoucher([])
    setMaVoucher('')
    setMaVoucherDoiTac('')
    setIsDoiTac(false)
    setValueGiaVoucherDoiTac(0)
    setValueChuongTrinhDoiTac('')
    dispatch({
      type: TypeAction.UPDATE_NEW_PRICE_CHI_DINH,
      payload: [],
    })
    dispatch({
      type: TypeAction.DISPATCH_SET_CHUONGTRINH_GIAMGIA_DOITAC,
      payload: null,
    })
  }, [infoBN])
  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(`Voucher/GetChuongTrinhDoiTac`)
        setListVoucher(data)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  // useEffect(() => {
  //   setForm({ ...form, idthe: memberCard?.idthe ?? null })
  // }, [memberCard])///////////////////////////////////////////////////////////////////////

  const setMaCTComboApply = (mact) => {
    setCTComboApply((prev) => {
      if (!prev || prev?.trim()?.length === 0) return mact
      else if (!prev.includes(mact)) return `${prev},${mact}`
      return prev
    })
  }
  const setMaCTGGApply = (mact) => {
    setCTGiamGiaApply((prev) => {
      if (!prev || prev?.trim()?.length === 0) return mact
      else if (!prev.includes(mact)) return `${prev},${mact}`
      return prev
    })
  }
  const applyCTGiamGia = async (arrkb, arrxn, arrcdha) => {
    if (listCTGiamGia?.length > 0) {
      let listDVGiam = [],
        listDVKhongGiam = [],
        dvgiam = null,
        ptgiamNew = 0,
        daGiam = false
      //////////////////////////////////////////////////////
      if (arrkb?.length > 0) {
        listDVGiam = []
        listDVKhongGiam = []
        for (const itemKB of arrkb) {
          daGiam = false
          if (!itemKB?.loaithedichvu && !itemKB?.taikham && itemKB?.ptGiam === 0) {
            for (const itemCT of listCTGiamGia) {
              if (
                (itemCT?.IDTTDT === 0 ||
                  (itemCT?.IDTTDT === 1 &&
                    infoBN &&
                    itemCT?.DanhSachDoiTuong?.find(
                      (itemDT) => itemDT?.iddt === infoBN?.idDoiTuong,
                    ))) &&
                itemCT?.DanhSachChiDinh?.find(
                  (itemCD) => itemCD?.loaivoucherdichvu === 1 && itemCD?.iddv === itemKB?.idChiDinh,
                )
              ) {
                daGiam = true
                //ptgiamNew = (itemKB?.ptGiam ?? 0) + (itemCT?.GIATRI ?? 0)
                listDVGiam.push({
                  ...itemKB,
                  ptGiam: itemCT?.GIATRI,
                  tienGiam: (itemKB?.donGia * itemCT?.GIATRI) / 100,
                  thanhToan: (itemKB?.donGia * (100 - itemCT?.GIATRI)) / 100,
                  ctgg: itemCT,
                })
                setMaCTGGApply(itemCT?.MACT)
                // setCTGiamGiaApply((prev) => {
                //   if (!prev || prev?.trim().length === 0) return itemCT?.MACT
                //   else return `${prev}, ${itemCT?.MACT}`
                // })
                break
              }
            }
          }
          if (!daGiam) listDVKhongGiam.push(itemKB)
        }
        if (listDVGiam?.length > 0) {
          arrkb = listDVGiam.concat(listDVKhongGiam)
        }
      }
      //////////////////////////////////////////////////////
      if (arrxn?.length > 0) {
        listDVGiam = []
        listDVKhongGiam = []
        for (const itemXB of arrxn) {
          daGiam = false
          if (!itemXB?.loaithedichvu && !itemXB?.taikham && itemXB?.ptGiam === 0) {
            for (const itemCT of listCTGiamGia) {
              if (
                (itemCT?.IDTTDT === 0 ||
                  (infoBN &&
                    itemCT?.IDTTDT === 1 &&
                    itemCT?.DanhSachDoiTuong?.find(
                      (itemDT) => itemDT?.iddt === infoBN?.idDoiTuong,
                    ))) &&
                itemCT?.DanhSachChiDinh?.find(
                  (itemCD) => itemCD?.loaivoucherdichvu === 2 && itemCD?.iddv === itemXB?.idChiDinh,
                )
              ) {
                daGiam = true
                //ptgiamNew = (itemXB?.ptGiam ?? 0) + (itemCT?.GIATRI ?? 0)
                listDVGiam.push({
                  ...itemXB,
                  ptGiam: itemCT?.GIATRI,
                  tienGiam: (itemXB?.donGia * itemCT?.GIATRI) / 100,
                  thanhToan: (itemXB?.donGia * (100 - itemCT?.GIATRI)) / 100,
                  ctgg: itemCT,
                })
                setMaCTGGApply(itemCT?.MACT)
                break
              }
            }
          }
          if (!daGiam) listDVKhongGiam.push(itemXB)
        }
        if (listDVGiam?.length > 0) {
          arrxn = listDVGiam.concat(listDVKhongGiam)
        }
      }
      //////////////////////////////////////////////////////
      if (arrcdha?.length > 0) {
        listDVGiam = []
        listDVKhongGiam = []
        for (const itemCDHA of arrcdha) {
          daGiam = false
          if (!itemCDHA?.loaithedichvu && !itemCDHA?.taikham && itemCDHA?.ptGiam === 0) {
            for (const itemCT of listCTGiamGia) {
              if (
                (itemCT?.IDTTDT === 0 ||
                  (infoBN &&
                    itemCT?.IDTTDT === 1 &&
                    itemCT?.DanhSachDoiTuong?.find(
                      (itemDT) => itemDT?.iddt === infoBN?.idDoiTuong,
                    ))) &&
                itemCT?.DanhSachChiDinh?.find(
                  (itemCD) =>
                    itemCD?.loaivoucherdichvu === 3 && itemCD?.iddv === itemCDHA?.idChiDinh,
                )
              ) {
                daGiam = true
                //ptgiamNew = (itemCDHA?.ptGiam ?? 0) + (itemCT?.GIATRI ?? 0)
                listDVGiam.push({
                  ...itemCDHA,
                  ptGiam: itemCT?.GIATRI,
                  tienGiam: (itemCDHA?.donGia * itemCT?.GIATRI) / 100,
                  thanhToan: (itemCDHA?.donGia * (100 - itemCT?.GIATRI)) / 100,
                  ctgg: itemCT,
                })
                setMaCTGGApply(itemCT?.MACT)
                break
              }
            }
          }
          if (!daGiam) listDVKhongGiam.push(itemCDHA)
        }
        if (listDVGiam?.length > 0) {
          arrcdha = listDVGiam.concat(listDVKhongGiam)
        }
      }
    }
    dispatch({
      type: TypeAction.DISPATCH_SET_ARR_KB,
      payload: arrkb,
    })
    dispatch({
      type: TypeAction.DISPATCH_SET_ARR_XN,
      payload: arrxn,
    })
    dispatch({
      type: TypeAction.DISPATCH_SET_ARR_CDHA,
      payload: arrcdha,
    })
  }
  const handleApplyChuongTrinh = async () => {
    setCTComboApply(null)
    setCTGiamGiaApply(null)
    let arrCD = await ArrKB.concat(ArrXN, ArrCĐHA)?.map(
      (item) =>
        !item?.loaithedichvu && !item?.taikham
          ? {
              ...item,
              ptGiam: 0,
              tienGiam: 0,
              thanhToan: item.donGia,
              combo: null,
              ctgg: null,
            }
          : { ...item },
      //||((item?.loaithedichvu || item?.taikham) && { ...item })
    )
    if (listCombo?.length > 0) {
      let listDVGiam = [],
        listDVTruocGiam = [],
        listDVKhongGiam = []
      for (const itemCB of listCombo) {
        if (
          itemCB?.DanhSachDichVu?.length > 0 &&
          arrCD?.length >= itemCB?.DanhSachDichVu?.length &&
          (itemCB?.IDTTDT === 0 ||
            (itemCB?.IDTTDT === 1 &&
              infoBN &&
              itemCB?.DanhSachDoiTuong?.find((itemDT) => itemDT?.iddt === infoBN?.idDoiTuong)))
        ) {
          for (const itemCD of arrCD) {
            let loaiDv =
              (itemCD?.type === 'KB' && 1) ||
              (itemCD?.type === 'XN' && 2) ||
              (itemCD?.type === 'CDHA' && 3)
            const index = itemCB?.DanhSachDichVu?.findIndex(
              (dvCB) => dvCB?.iddv === itemCD?.idChiDinh && dvCB?.loaivoucherdichvu === loaiDv,
            )
            if (index === -1) listDVKhongGiam.push(itemCD)
            else {
              //listDVTruocGiam.push(itemCD)
              if (!itemCD?.loaithedichvu && !itemCD?.taikham) {
                listDVGiam.push({
                  ...itemCD,
                  ptGiam: itemCB.GIATRI,
                  tienGiam: (itemCD.donGia * itemCB.GIATRI) / 100,
                  thanhToan: (itemCD.donGia * (100 - itemCB.GIATRI)) / 100,
                  combo: itemCB,
                })
              } else listDVGiam.push({ ...itemCD, combo: itemCB })
            }
          }
          // if (itemCB?.DanhSachDichVu?.length !== listDVGiam?.length) {
          //   listDVTruocGiam = []
          // } else
          if (itemCB?.DanhSachDichVu?.length === listDVGiam?.length) {
            setMaCTComboApply(itemCB?.MACT)
            arrCD = listDVGiam.concat(listDVKhongGiam)
          }
        }
        listDVGiam = []
        listDVKhongGiam = []
      }
      // dispatch({
      //   type: TypeAction.DISPATCH_SET_ARR_KB,
      //   payload: arrCD?.filter((item) => item?.type === 'KB'),
      // })
      // dispatch({
      //   type: TypeAction.DISPATCH_SET_ARR_XN,
      //   payload: arrCD?.filter((item) => item?.type === 'XN'),
      // })
      // dispatch({
      //   type: TypeAction.DISPATCH_SET_ARR_CDHA,
      //   payload: arrCD?.filter((item) => item?.type === 'CDHA'),
      // })
    }
    applyCTGiamGia(
      arrCD?.filter((item) => item?.type === 'KB'),
      arrCD?.filter((item) => item?.type === 'XN'),
      arrCD?.filter((item) => item?.type === 'CDHA'),
    )
    // else if (comboDangApDung && comboDangApDung?.DVTruocGiam?.length > 0) await unApplyCombo()
  }
  useEffect(() => {
    handleApplyChuongTrinh()
  }, [ArrKB?.length, ArrXN?.length, ArrCĐHA?.length]) // ArrKB?.length, ArrXN?.length, ArrCĐHA?.length

  const handleClickUpdateXHD = async () => {
    try {
      await https.put('BenhNhan', {
        idbn: infoBN?.idbn,
        hoaDon: formik.values.hoaDon,
        ghiChuHoaDon: formik.values.ghiChuHoaDon,
      })
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật thông tin xuất hóa đơn thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.UPDATE_XHD,
        true,
        MENU_CONSTANTS.TIEP_NHAN,
        null,
        {
          tenBN: infoBN?.tenBenhNhan,
          hoaDon: formik.values.hoaDon,
          ghiChuHoaDon: formik.values.ghiChuHoaDon,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails({
        tenBN: infoBN?.tenBenhNhan,
        hoaDon: formik.values.hoaDon,
        ghiChuHoaDon: formik.values.ghiChuHoaDon,
      })} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.TIEP_NHAN,
        detailErrorPayload,
      )
    }
  }
  useEffect(() => {
    if (BnDkkOnline) handleInfoBN(BnDkkOnline)
  }, [BnDkkOnline]) // ArrKB?.length, ArrXN?.length, ArrCĐHA?.length
  const selectedGift = valueQuaTang.find((item) => item.idThuoc === valueChuongTrinhQuaTang)

  const [formQuaTang, setFormQuaTang] = useState({})

  const handleChangeQuaTang = (e) => {
    const { checked } = e.target
    setIsQuaTang(checked)
    if (!checked) {
      setFormQuaTang({})
    }
  }

  const handleQuantityChange = (e) => {
    if (valueChuongTrinhQuaTang) {
      const [idThuoc, soLo, hanDung, soHoaDon] = valueChuongTrinhQuaTang.split('_')

      // Find the selected gift using all criteria
      const selectedGift = valueQuaTang.find(
        (item) =>
          item.idThuoc === Number(idThuoc) &&
          item.soLo === soLo &&
          item.hanDung === hanDung &&
          item.soHoaDon === soHoaDon,
      )

      if (selectedGift) {
        // Limit input value to available stock
        const inputValue = Number(e.target.value ?? 0)
        const newValue = Math.min(inputValue, selectedGift.tonKho || 0)

        // Ensure minimum value is 0
        const finalValue = Math.max(newValue, 0)

        setValueSoLuongQuaTang(finalValue)

        setFormQuaTang({
          idThuoc: selectedGift.idThuoc,
          tenBietDuoc: selectedGift?.thuocVatTu?.tenBietDuoc,
          soLuong: finalValue,
          donGia: selectedGift.dongia,
          thanhTien: finalValue * selectedGift.dongia,
          tongTien: finalValue * selectedGift.dongia,
          nguoiThucHien: infoUser?.idnv,
          idKhoCN: selectedGift.idKhoCN,
          soLo: selectedGift.soLo,
          hanDung: selectedGift.hanDung,
          soHoaDon: selectedGift.soHoaDon || '',
        })
      }
    }
  }
  const stringName = (name) => {
    if (name) {
      const nameString = name.split('')[0][0] + name.split('')[1][0]
      return nameString.toUpperCase()
    } else {
      return ''
    }
  }

  return (
    <>
      <div className="h-full">
        <form className="flex gap-2 h-full" action="">
          <div className=" flex flex-col h-full w-2/3 gap-2 ">
            <div className="border rounded-lg">
              <div className="border-b p-2 flex justify-between gap-4">
                <Select
                  onSearch={debounceDropDown}
                  value={null}
                  onChange={handleInfoBN}
                  filterOption={false}
                  notFoundContent={null}
                  placeholder="Tìm kiếm Tên BN, Mã BN, SĐT, CCCD, Mã TTV..."
                  suffixIcon={<SearchOutlined className=" " />}
                  options={listBN?.map((items) => ({
                    label: items?.tenbenhnhan,
                    value: items?.idbn,
                    MaBN: items?.mabenhnhan,
                    ngaySinh: items?.ngaysinh ? moment(items?.ngaysinh).format('DD/MM/YYYY') : '',
                    diaChi: items?.diachi,
                    tinh: items?.tentinhtp,
                    quan: items?.tenquanhuyen,
                    xa: items?.tenphuongxa,
                    gioiTinh: items?.gioitinh,
                    // mathe: items?.mathe,
                  }))}
                  optionRender={(options) => (
                    <ul className="flex">
                      <li className="w-5/6">
                        <p className="font-semibold">
                          {options?.data?.label} - {options?.data?.MaBN}
                          {/* {options?.data?.mathe ? ` - TTV: ${options?.data?.mathe}` : ''} */}
                        </p>
                        <i className="text-gray-500">
                          Địa chỉ: {options?.data?.diaChi}
                          {options?.data?.xa && `, ${options?.data?.xa}`}
                          {options?.data?.quan && `, ${options?.data?.quan}`}
                          {options?.data?.tinh && `, ${options?.data?.tinh}`}
                        </i>
                      </li>
                      <li className="w-1/6 flex flex-col">
                        <p>Ngày sinh: {options?.data?.ngaySinh} </p>
                        <i className="text-gray-500">Giới tính: {options?.data?.gioiTinh}</i>
                      </li>
                    </ul>
                  )}
                  showSearch
                  allowClear
                  className="w-full"
                />
                <Button
                  variant="contained"
                  className="w-28"
                  size="small"
                  onClick={showCreateCustomer}
                >
                  <PlusOutlined />
                  Thêm mới
                </Button>
              </div>
              <div className="p-2 ">
                <h2 className="font-semibold text-base text-gray-500">
                  Thông tin <UserOutlined />
                </h2>
                <div className="flex gap-2 items-center justify-between border-b border-dashed pb-1 h-10">
                  <div className="flex gap-2 items-center">
                    <Avatar children={stringName(infoBN?.tenBenhNhan)} />
                    <div>
                      <h2 className="font-semibold">{infoBN?.tenBenhNhan}</h2>
                      <p className="text-xs text-gray-500">Mã BN: {infoBN?.maBenhNhan}</p>
                    </div>
                    {infoBN?.idbn && (
                      <SyncIcon
                        sx={{ fontSize: 22 }}
                        onClick={() => {
                          handleInfoBN(infoBN?.idbn)
                        }}
                        className=" text-blue-500 cursor-pointer"
                      />
                    )}
                  </div>
                  <h2
                    className={`font-semibold text-white p-1 rounded-lg shadow-md ${infoBN?.idLoaiKham === 2 ? 'bg-green-700' : 'bg-blue-500'}`}
                  >
                    {infoBN?.idLoaiKham === 2
                      ? 'Tái Khám'
                      : infoBN?.idLoaiKham === 4
                        ? 'Khám bệnh quá ngày Tái khám'
                        : infoBN?.idLoaiKham === 1
                          ? 'Khám lần đầu'
                          : 'Bệnh mới'}
                  </h2>
                </div>
                <div className="flex flex-col gap-2 ">
                  <ul className="flex gap-5">
                    <li className="flex gap-2 w-1/4">
                      <p className="font-semibold w-20">Ngày sinh:</p>
                      <span>
                        {infoBN?.ngaySinh && moment(infoBN?.ngaySinh).format('DD/MM/YYYY')}
                      </span>
                    </li>
                    <li className="flex gap-2 w-1/4 ">
                      <p className="font-semibold w-20 ">Tuổi:</p>
                      <span>{infoBN?.ngaySinh && calculateAge(infoBN?.ngaySinh)}</span>
                    </li>
                    <li className="flex gap-2 w-1/4 ">
                      <p className="font-semibold w-20">Giới tính:</p>
                      <span>{infoBN?.gioiTinh}</span>
                    </li>
                    <li className="flex gap-2 w-1/4">
                      <p className="font-semibold w-20">Dân tộc:</p>
                      <span>{infoBN?.tenDanToc}</span>
                    </li>
                  </ul>
                  <ul className="flex gap-5">
                    <li className="flex gap-2 w-1/4 ">
                      <div
                        className="barcode-container"
                        ref={barcodeRef}
                        style={{ display: 'none' }}
                      >
                        <PrinterTTQG
                          name={infoBN?.tenBenhNhan}
                          barcode={infoBN?.maTCQG || '00000000'}
                        />
                      </div>
                      <p className="font-semibold w-20 ">Mã TCQG:</p>
                      <span>
                        {infoBN?.maTCQG}{' '}
                        {infoBN && (
                          <PrinterOutlined
                            onClick={handlePrint}
                            className="text-lg cursor-pointer  hover:text-blue-500 text-gray-500"
                          />
                        )}
                      </span>
                    </li>
                    <li className="flex gap-2 w-1/4 ">
                      <p className="font-semibold w-20  ">SĐT:</p>
                      <span>{infoBN?.dienThoai}</span>
                    </li>
                    <li className="flex gap-2 w-1/4 ">
                      <p className="font-semibold w-20">Đối tượng:</p>
                      <span>{infoBN?.tenDoiTuong}</span>
                    </li>
                    <li className="flex gap-2 w-1/4">
                      <p className="font-semibold w-20">Quốc tịch:</p>
                      <span>{infoBN?.tenQuocTich}</span>
                    </li>
                  </ul>
                  <ul className="flex gap-5">
                    <li className="flex gap-2 w-1/4 ">
                      <p className="font-semibold w-20">Số TTV:</p>
                      <span>{memberCard?.mathe}</span>
                    </li>
                    <li className="flex gap-2 w-1/4 ">
                      <p className="font-semibold w-20 ">Tỉnh/TP:</p>
                      <span>{infoBN?.tenTinhTP}</span>
                    </li>
                    <li className="flex gap-2 w-1/4 ">
                      <p className="font-semibold w-20">Q.Huyện:</p>
                      <span>{infoBN?.tenQuanHuyen}</span>
                    </li>
                    <li className="flex gap-2 w-1/4 ">
                      <p className="font-semibold w-20">X.Phường:</p>
                      <span>{infoBN?.tenPhuongXa}</span>
                    </li>
                  </ul>
                  <ul className="flex gap-5">
                    <li className="flex gap-2 w-2/4 ">
                      <p className="font-semibold w-20">Loại thẻ:</p>
                      <span>{memberCard?.tenloaithe}</span>
                    </li>
                    <li className="flex gap-5 w-2/4 ">
                      <div className="w-1/2 flex gap-5 ">
                        <p className="font-semibold w-20">Từ ngày:</p>
                        <span>
                          {memberCard?.tungay
                            ? moment(memberCard?.tungay).format('DD/MM/YYYY')
                            : ''}
                        </span>
                      </div>
                      <div className="w-1/2 flex gap-5 ">
                        <p className="font-semibold w-20">Đến ngày:</p>
                        <span>
                          {memberCard?.denngay
                            ? moment(memberCard?.denngay).format('DD/MM/YYYY')
                            : ''}
                        </span>
                      </div>
                    </li>
                  </ul>
                  <ul className="flex gap-5">
                    <li className="flex gap-2 w-2/4 ">
                      <p className="font-semibold w-20">Địa chỉ:</p>
                      <span>{infoBN?.diaChi}</span>
                    </li>
                    <li className="flex gap-2 w-2/4 ">
                      <p className="font-semibold w-20">Ghi chú:</p>
                      <span>{infoBN?.ghiChu}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex gap-2">
              <TableHistory history={history} />
              <TabsChiDinhNew
                formPay={formPay}
                ArrXN={ArrXN}
                ArrCĐHA={ArrCĐHA}
                ArrKB={ArrKB}
                newArr={newArr}
                setFormPay={setFormPay}
              />
            </div>
          </div>
          <div className="w-1/3 h-full bg-white p-1 rounded-lg border">
            <div className=" h-[275px] overflow-auto border-b">
              {newArr?.map((item, index) => (
                <TablePay item={item} stt={++index} />
              ))}
            </div>
            <div className="flex flex-col gap-1 border-b pb-1">
              <div className="flex gap-1">
                <div className="w-1/3">
                  <label htmlFor="">Người nhà:</label>
                  <Select
                    onChange={handleNguoiNha}
                    value={formik.values.nguoinha}
                    options={nguoiThan?.map((items) => ({
                      label: items.ten,
                      value: items.ten,
                    }))}
                    className="w-full"
                    size="small"
                  />
                </div>
                <div className="w-1/3">
                  <label className=" text-end">Tên người nhà:</label>
                  <Input
                    onChange={formik.handleChange}
                    value={formik.values.tennguoinha}
                    name="tennguoinha"
                    className="w-full"
                    size="small"
                  />
                </div>
                <div className="w-1/3">
                  <label className=" text-end">SĐT người nhà:</label>
                  <Input
                    onChange={formik.handleChange}
                    value={formik.values.dienthoainguoinha}
                    name="dienthoainguoinha"
                    size="small"
                  />
                </div>
              </div>
              <div className="flex gap-1">
                <div className="w-1/3">
                  <label htmlFor="">
                    {' '}
                    <span className="text-red-500">*</span> Lý do khám:
                  </label>
                  <Input
                    status={formik.errors.lydokham ? 'error' : ''}
                    onChange={formik.handleChange}
                    value={formik.values.lydokham}
                    name="lydokham"
                    placeholder="Lý do khám bệnh"
                    size="small"
                  />
                </div>
                <div className="w-1/3">
                  <label className=" text-end">Nguồn:</label>
                  <Select
                    onChange={handleChangeSeleted('idnguon')}
                    value={formik.values.idnguon}
                    options={nguonKH?.map((items) => ({
                      value: items.idNguonKH,
                      label: items.nguon,
                    }))}
                    className="w-full"
                    size="small"
                  />
                </div>
                <div className="w-1/3">
                  <label className=" text-end">Phòng khám:</label>
                  <Select
                    status={formik.errors.idcnpk ? 'error' : ''}
                    // defaultValue={}
                    value={formik.values.idcnpk}
                    onChange={handleChangeSeleted('idcnpk')}
                    className="w-full"
                    options={phongKham?.map((items) => ({
                      value: items.idcnpk,
                      label: items.tenPhongKham,
                    }))}
                    size="small"
                  />
                </div>
              </div>
              <div className="flex gap-1">
                <div className="w-1/3">
                  <label htmlFor="">Spo2 (%):</label>
                  <Input
                    onChange={formik.handleChange}
                    value={formik.values.spO2}
                    name="spO2"
                    className="w-full"
                    size="small"
                  />
                </div>
                <div className="w-1/3">
                  <label className=" text-end">Huyết áp (mmHg):</label>
                  <Input
                    // type="Number"
                    onChange={formik.handleChange}
                    value={formik.values.huyetap}
                    name="huyetap"
                    size="small"
                  />
                </div>
                <div className="w-1/3">
                  <label className=" text-end">Nhịp thở (Lần/p):</label>
                  <Input
                    // type="Number"
                    onChange={formik.handleChange}
                    value={formik.values.nhiptho}
                    name="nhiptho"
                    size="small"
                  />
                </div>
              </div>
              <div className="flex gap-1">
                <div className="w-1/3">
                  <label htmlFor="">Mạch (Lần/p):</label>
                  <Input
                    // type="Number"
                    onChange={formik.handleChange}
                    value={formik.values.mach}
                    name="mach"
                    size="small"
                  />
                </div>
                <div className="w-1/3">
                  <label className=" text-end">Cân nặng (Kg):</label>
                  <Input
                    type="Number"
                    value={formik.values.cannang}
                    name="cannang"
                    onChange={(e) => {
                      const value = e.target.value === '' ? null : Number(e.target.value)
                      formik.setFieldValue('cannang', value)
                    }}
                    size="small"
                  />
                </div>
                <div className="w-1/3">
                  <label className=" text-end">Chiều cao (Cm):</label>
                  <Input
                    type="Number"
                    value={formik.values.chieucao}
                    name="chieucao"
                    onChange={(e) => {
                      const value = e.target.value === '' ? null : Number(e.target.value)
                      formik.setFieldValue('chieucao', value)
                    }}
                    size="small"
                  />
                </div>
              </div>
              <div className="flex gap-1">
                <div className="w-1/3">
                  <label htmlFor="">Nhiệt độ ({<>&#8451;</>}):</label>
                  <Input
                    // type="Number"
                    value={formik.values.nhietdo}
                    onChange={formik.handleChange}
                    name="nhietdo"
                    size="small"
                  />
                </div>
                <div className="w-2/3">
                  <label className=" text-end">Ghi chú:</label>
                  <Input
                    value={formik.values.ghichu}
                    onChange={formik.handleChange}
                    name="ghichu"
                    size="small"
                  />
                </div>
              </div>
            </div>
            <div
              className="h-[130px] overflow-auto py-1"
              style={{
                scrollbarWidth: '8px',
                scrollbarColor: '#B4B4B4 #f5f5f5', // Firefox: thanh cuộn màu xanh, nền màu xám nhạt
              }}
            >
              <div className="flex items-center gap-1">
                <label className="text-sm font-semibold w-20 text-end">Xuất HĐ:</label>
                <Checkbox
                  checked={formPay.hoaDonDienTu && formPay.hoaDonDienTu === 1}
                  onChange={handleChangeCheckbox}
                />

                <Input
                  name="ghiChuHoaDon"
                  size="small"
                  value={formik.values.ghiChuHoaDon}
                  onChange={(e) => {
                    setFormPay((preState) => ({ ...preState, ghiChuXuatHoaDon: e.target.value }))
                    formik.setFieldValue('ghiChuHoaDon', e.target.value)
                  }}
                  disabled={!infoBN}
                />
                <button
                  type="button"
                  disabled={!infoBN}
                  onClick={handleClickUpdateXHD}
                  className="bg-green-200 text-green-700 hover:bg-green-700 cursor-pointer hover:text-white text-base text-center rounded-md w-6 h-6"
                >
                  <CheckOutlined />
                </button>
              </div>
              <div className="flex flex-col w-full gap-2 ">
                <div>
                  <div className="flex gap-1">
                    <div className="w-1/4">
                      <label htmlFor="" className="font-semibold">
                        Phương thức
                      </label>
                      <Select
                        defaultValue={1}
                        className="w-full"
                        size="small"
                        value={formPay.idPhuongThuc}
                        onChange={(value) => {
                          setFormPay({ ...formPay, idPhuongThuc: value })
                        }}
                        options={[
                          { label: 'Thanh toán', value: 1 },
                          { label: 'Công nợ', value: 2 },
                        ]}
                      />
                    </div>
                    <div className="w-1/4">
                      <label htmlFor="" className="font-semibold">
                        Voucher
                      </label>
                      <Input
                        disabled={!newArr.length || discountArr.length}
                        size="small"
                        value={maVoucher}
                        onChange={(e) => setMaVoucher(e.target.value)}
                        suffix={
                          <ul className="flex justify-around">
                            {maVoucher && infoBN && newArr.length && !discountArr.length ? (
                              <li
                                onClick={handleApplyVoucher}
                                className="bg-green-200 text-green-700 hover:bg-green-700 cursor-pointer hover:text-white text-base text-center rounded-md size-5"
                              >
                                <CheckOutlined style={{ fontSize: 14 }} />
                              </li>
                            ) : null}
                            {discountArr.length ? (
                              <li
                                onClick={handleUnApplyVoucher}
                                className="bg-red-200 text-red-700 hover:bg-red-700 cursor-pointer hover:text-white text-base text-center rounded-md size-5"
                              >
                                <CloseOutlined style={{ fontSize: 14 }} />
                              </li>
                            ) : null}
                          </ul>
                        }
                      />
                    </div>
                    <div className="w-1/4">
                      <label htmlFor="" className="font-semibold">
                        Combo giảm giá
                      </label>
                      <Input readOnly variant="filled" size="small" value={CTComboApply} />
                    </div>
                    <div className="w-1/4">
                      <label htmlFor="" className="font-semibold">
                        CT giảm giá
                      </label>
                      <Input readOnly variant="filled" size="small" value={CTGiamGiaApply} />
                    </div>
                  </div>
                </div>
                {/* voucher đối tác */}
                <div>
                  {/* <label htmlFor="" className="font-semibold">
                    Voucher đối tác: <Checkbox checked={isDoiTac} onChange={handleChangeDoiTac} />
                  </label> */}
                  <div className="flex gap-1">
                    <div className="w-1/3">
                      <label htmlFor="" className="font-semibold">
                        Voucher đối tác:{' '}
                        <Checkbox checked={isDoiTac} onChange={handleChangeDoiTac} />
                      </label>
                      <Select
                        disabled={!newArr.length || infoChuongTrinhGiamGiaDoiTac || !isDoiTac}
                        // style={{ color: 'black' }}
                        className="w-full"
                        size="small"
                        value={valueChuongTrinhDoiTac}
                        options={listVoucher?.map((items) => ({
                          label: items.tenct,
                          value: items.idct,
                        }))}
                        onChange={(value) => setValueChuongTrinhDoiTac(value)}
                      />
                    </div>
                    <div className="w-1/3">
                      <label htmlFor="" className="font-semibold">
                        Mã đối tác
                      </label>
                      <Input
                        disabled={!newArr.length || infoChuongTrinhGiamGiaDoiTac || !isDoiTac}
                        style={{ color: 'black' }}
                        size="small"
                        value={maVoucherDoiTac}
                        onChange={(e) => setMaVoucherDoiTac(e.target.value)}
                      />
                    </div>
                    <div className="w-1/3">
                      <label htmlFor="" className="font-semibold">
                        Đơn giá
                      </label>
                      <Input
                        disabled={!newArr.length || infoChuongTrinhGiamGiaDoiTac || !isDoiTac}
                        style={{ color: 'black' }}
                        size="small"
                        type="number"
                        step={1}
                        min={0}
                        value={valueGiaVoucherDoiTac}
                        onChange={(e) =>
                          setValueGiaVoucherDoiTac(
                            Number(e.target.value ?? 0) > Number(tongThanhToan)
                              ? Number(tongThanhToan)
                              : Number(e.target.value ?? 0),
                          )
                        }
                        className="text-right"
                        // value={maVoucher}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-1">
                  <div className="w-3/4">
                    <label htmlFor="" className="font-semibold">
                      Quà tặng: <Checkbox checked={isQuaTang} onChange={handleChangeQuaTang} />
                    </label>
                    <Select
                      disabled={!isQuaTang}
                      style={{ color: 'black' }}
                      className="w-full"
                      size="small"
                      value={valueChuongTrinhQuaTang}
                      options={valueQuaTang?.map((items) => ({
                        label: (
                          <span>
                            <strong>{items?.thuocVatTu?.tenBietDuoc}</strong>{' '}
                            <span style={{ color: 'red' }}>({items?.tonKho})</span>
                          </span>
                        ),
                        value: `${items.idThuoc}_${items.soLo}_${items.hanDung}_${items.soHoaDon}`,
                      }))}
                      onChange={(value) => {
                        setValueChuongTrinhQuaTang(value)
                        setValueSoLuongQuaTang(1) // Reset quantity to 0 when changing gift
                        const [idThuoc] = value.split('_')
                      }}
                    />
                  </div>
                  <div className="w-1/4">
                    <label htmlFor="" className="font-semibold">
                      Số lượng:
                    </label>
                    <Input
                      disabled={!isQuaTang}
                      style={{ color: 'black' }}
                      size="small"
                      type="number"
                      step={1}
                      min={0}
                      value={valueSoLuongQuaTang}
                      onChange={handleQuantityChange}
                      className=" text-right"
                    />
                  </div>
                </div>

                {/* voucher */}
              </div>
            </div>
            <div className="border-y p-1 border-dashed text-gray-500">
              <p className="flex justify-between">
                Tổng tiền{' '}
                <span>
                  <span className="text-black font-semibold text-base">
                    {formatNumberVND(Number(tongGia))}đ
                  </span>
                </span>
              </p>
              <p className="flex justify-between">
                Tổng giảm{' '}
                <span className="text-red-500 font-semibold text-base">
                  {' '}
                  -{' '}
                  {formatNumberVND(
                    Number(tongGiam) + Number(infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0),
                  )}
                  đ
                </span>
              </p>
              <p className="flex justify-between">
                Tổng thanh toán{' '}
                <span className="text-green-700 font-semibold text-base">
                  {formatNumberVND(
                    Number(tongThanhToan) - Number(infoChuongTrinhGiamGiaDoiTac?.giaVoucher ?? 0),
                  )}
                </span>
              </p>
            </div>
            <Button
              disabled={newArr.length === 0 || !infoBN}
              className="w-full"
              onClick={() => {
                handlePay()
                // formik.handleSubmit();
              }}
              variant="contained"
              color="success"
            >
              Thanh toán
            </Button>
          </div>
        </form>
      </div>
      <ModalCreateCustomer
        openModaCreateCustomer={openModaCreateCustomer}
        cancelCreateCustomer={cancelCreateCustomer}
      />
      <ModalPay
        onSubmit={formik.handleSubmit}
        form={form}
        setForm={setForm}
        isModalPay={isModalPay}
        tongThanhToan={tongThanhToan}
        setIsModalPay={setIsModalPay}
        setIsPrint={setIsPrint}
      />
      {/* <ModalHD hoaDon={hoaDon} setHoaDon={setHoaDon} setFormPay={setFormPay} /> */}
    </>
  )
}

export default Receive
