import { DatePicker, Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import moment from 'moment'
import dayjs from 'dayjs'
import { addNewNgayNghi } from '../../../store/actions/ngayNghiAction'
const today = moment().format('YYYY-MM-DD')
const CreateNew = ({ isOpen, setIsOpen, reloadData }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const handleCancel = () => {
    setIsOpen(false)
  }
  const reload = () => {
    reloadData()
    formik.resetForm()
    handleCancel()
  }
  const handleSubmit = (values) => {
    dispatch(addNewNgayNghi(values, reload))
  }
  const handleDatePikcer = (name) => (date, dateString) => {
    const formatDate = moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD')
    formik.setFieldValue(name, formatDate)
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mann: '',
      tennn: '',
      tungay: today,
      denngay: today,
      ghichu: '',
      sudung: 1,
      idnv: infoUser?.idnv,
    },
    validationSchema: Yup.object().shape({
      mann: Yup.string().required('Vui lòng nhập mã ngày nghỉ !'),
      tennn: Yup.string().required('Vui lòng nhập tên ngày nghỉ !'),
      tungay: Yup.string().required('Vui lòng nhập ngày bắt đầu !'),
      denngay: Yup.string().required('Vui lòng nhập ngày kết thúc !'),
    }),
    onSubmit: (values) => handleSubmit(values),
  })

  return (
    <Modal
      onCancel={handleCancel}
      title="THÊM MỚI NGÀY NGHỈ"
      open={isOpen}
      okText="Lưu"
      cancelText="Huỷ bỏ"
      onOk={formik.handleSubmit}
    >
      <div className="flex flex-col gap-2">
        <div>
          <label>
            <span className="text-red-500">(*)</span> Mã ngày nghỉ:
          </label>
          <Input
            status={formik.errors.mann ? 'error' : ''}
            value={formik.values.mann}
            name="mann"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.mann && formik.errors.mann && (
            <span className="text-red-500 ">{formik.errors.mann}</span>
          )}
        </div>
        <div>
          <label>
            <span className="text-red-500">(*)</span> Tên ngày nghỉ:
          </label>
          <Input
            status={formik.errors.tennn ? 'error' : ''}
            value={formik.values.tennn}
            name="tennn"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.tennn && formik.errors.tennn && (
            <span className="text-red-500 ">{formik.errors.tennn}</span>
          )}
        </div>
        <div className="flex gap-4 justify-between">
          <div className="flex flex-col w-1/2">
            <label htmlFor="">
              {' '}
              <span className="text-red-500">(*)</span>Ngày bắt đầu
            </label>
            <DatePicker
              onChange={handleDatePikcer('tungay')}
              allowClear={false}
              value={formik.values.tungay ? dayjs(formik.values.tungay) : ''}
              status={formik.errors.tungay ? 'error' : ''}
              format={'DD/MM/YYYY'}
              maxDate={formik.values.denngay ? dayjs(formik.values.denngay) : ''}
            />
            {formik.touched.tungay && formik.errors.tungay && (
              <span className="text-red-500 ">{formik.errors.tungay}</span>
            )}
          </div>
          <div className="flex flex-col w-1/2">
            <label htmlFor="">
              {' '}
              <span className="text-red-500">(*)</span>Ngày kết thúc
            </label>
            <DatePicker
              minDate={formik.values.tungay ? dayjs(formik.values.tungay) : ''}
              onChange={handleDatePikcer('denngay')}
              allowClear={false}
              value={formik.values.denngay ? dayjs(formik.values.denngay) : ''}
              status={formik.errors.denngay ? 'error' : ''}
              format={'DD/MM/YYYY'}
            />
            {formik.touched.denngay && formik.errors.denngay && (
              <span className="text-red-500 ">{formik.errors.denngay}</span>
            )}
          </div>
        </div>
        <div>
          <label>Ghi chú:</label>
          <Input.TextArea
            onBlur={formik.handleBlur}
            value={formik.values.ghichu}
            name="ghichu"
            onChange={formik.handleChange}
            style={{
              height: 120,
              resize: 'none',
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default CreateNew
