import { Input, InputNumber, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addNewDMXetNghiemAction } from '../../../../store/actions/xetNghiemAction'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'
import { khoSchema } from '../../../../schemas/khoSchema'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'

const loaiKho = [
  {
    label: 'Kho thường',
    value: 0,
  },
  {
    label: 'Kho tổng',
    value: 1,
  },
  {
    label: 'Kho vắc xin',
    value: 2,
  },
]
const ModalEdit = ({ isEdit, setIsEdit, onLoad }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)

  const maQuyenSuaKho = 'QHT405'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaKho:
        formik.handleSubmit()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaKho:
        //
        break
      default:
        return false
    }
  }

  const editKho = async (values) => {
    try {
      await https.put('Kho/UpdateKho', values)
      ToastCus.fire({
        icon: 'success',
        title: 'Sửa kho thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        logAction.CHINH_SUA_KHO,
        true,
        MENU_CONSTANTS.KHO,
        null,
        {
          form: values,
        },
      )
      onLoad()
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Có lỗi xảy ra khi sửa kho',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.KHO,
        detailErrorPayload,
      )
    }
  }
  const handleSubmit = (value) => {
    editKho(value)
    formik.resetForm()
    setIsEdit(false)
  }

  const formik = useFormik({
    initialValues: {
      idKho: isEdit.data?.idKho,
      maKho: isEdit.data?.maKho,
      tenKho: isEdit.data?.tenKho,
      dienThoai: isEdit.data?.dienThoai,
      nguoiTao: infoUser.idnv,
      ghiChu: isEdit.data?.ghiChu,
      idCT: isEdit.data?.idCT,
      thuocKhoTong: isEdit.data?.thuocKhoTong,
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: khoSchema,
  })

  return (
    <>
      <Modal
        okText="Sửa"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Thông tin kho</h2>}
        onOk={() =>
          dispatch(putCheckQuyenThaoTacAction(maQuyenSuaKho, null, actionIsTrue, actionIsFalse))
        }
        open={isEdit.show}
        onCancel={() =>
          setIsEdit({
            show: false,
            data: null,
          })
        }

        // cancelButtonProps={{ style: { display: 'none' } }}
        // okButtonProps={{ style: { display: 'none' } }}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)</span>
              Mã kho{' '}
            </label>
            <Input
              value={formik.values.maKho}
              name="maKho"
              onChange={formik.handleChange}
              placeholder="Nhập mã kho"
              status={formik.errors.maKho ? 'error' : ''}
              //   readOnly
            />
          </div>
          <div>
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)</span>
              Tên kho{' '}
            </label>
            <Input
              value={formik.values.tenKho}
              name="tenKho"
              onChange={formik.handleChange}
              placeholder="Nhập tên kho"
              status={formik.errors.tenKho ? 'error' : ''}
              //   readOnly
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Số điện thoại</label>
            <Input
              value={formik.values.dienThoai}
              name="dienThoai"
              onChange={formik.handleChange}
              //   readOnly
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)</span>
              Công ty
            </label>
            <Select
              value={formik.values.idCT}
              options={listCompany?.map((items) => ({
                label: items.tenct,
                value: items.idct,
              }))}
              onChange={(value) => formik.setFieldValue('idCT', value)}
              status={formik.errors.idCT ? 'error' : ''}
              //   disabled
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)</span>
              Loại kho
            </label>
            <Select
              value={formik.values.thuocKhoTong}
              options={loaiKho?.map(({ label, value }) => ({
                label,
                value,
              }))}
              onChange={(value) => formik.setFieldValue('thuocKhoTong', value)}
              status={formik.errors.thuocKhoTong ? 'error' : ''}
              //   disabled
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Ghi chú</label>
            <Input value={formik.values.ghiChu} name="ghiChu" onChange={formik.handleChange} />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalEdit
