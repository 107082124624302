import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, Select, TreeSelect, Button, ConfigProvider, Table, Input, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import IconButton from '@mui/material/IconButton'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import { reportService } from '../../../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { khoVTservice } from '../../../../../services/khoVT/khoVTservice'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import Highlighter from 'react-highlight-words'
import ToastCus from '../../../../../utils/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
import { formattedNumber } from '../../../../../utils/formattedNumber'
import { calculateSum } from '../../../../../utils/calculateSum'
import { exportExcelformat } from '../../../../../utils/export/exportExcelFormat'
import { getListNameChiNhanh } from '../../../../../utils/algorithm'
import { addLog } from '../../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../../utils/log/logConstant'
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const now = moment().format(momentDate)
const { SHOW_PARENT } = TreeSelect

const DoiTuong = ({ isBlockView }) => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [isLoading, setisLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const searchInput = useRef(null)
  const [searchedColumn, setSearchedColumn] = useState('')
  const [branchs, setBranchs] = useState([])
  const [chuyenkhoas, setChuyenKhoas] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [valueExport, setValueExport] = useState([])
  const [form, setForm] = useState({
    idCompany: ID_COMPANY,
    idChuyenKhoa: '',
    idBranch: [ID_BRANCH],
    form: now,
    to: now,
  })

  const nameChiNhanh = useMemo(() => getListNameChiNhanh(form.idBranch, branchs), [form, branchs])
  const nameCompany = useMemo(
    () => listCompany?.find((item) => item?.idct === form.idCompany)?.tenct,
    [form, listCompany],
  )
  const nameChuyenKhoa = useMemo(
    () =>
      !form.idChuyenKhoa
        ? 'Tất cả'
        : chuyenkhoas?.find((item) => item?.idChuyenKhoa === form.idChuyenKhoa)?.tenChuyenKhoa,
    [form, chuyenkhoas],
  )
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const onChangeTable = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoas(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranchByChuyenKhoa = async (idct, value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(idct, value)
      setBranchs(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangSelected = (name) => (value) => {
    setForm((prevState) => ({ ...prevState, [name]: value, idBranch: ['all'] }))
    fetchBranchByChuyenKhoa(form?.idCompany, value)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branchs?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const handleDatePicker = (name) => (date, dateString) => {
    const value = moment(dateString, dateFormat).format(momentDate)
    setForm((prevState) => ({ ...prevState, [name]: value }))
  }
  const tProps = {
    treeData,
    value: form?.idBranch,
    onChange: (value) => setForm((prevState) => ({ ...prevState, idBranch: value })),
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const fetchData = async () => {
    try {
      setisLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT498',
      )
      let idcn = ''
      if (form.idBranch[0] !== 'all') {
        idcn = form.idBranch?.map((id) => `&idcn=${id}`).join('&')
      } else {
        form.idBranch = branchs?.map((item) => item.idChiNhanh)
        idcn = form.idBranch?.map((id) => `&idcn=${id}`).join('&')
      }
      const { data } = await reportService.getTheoDoiDoiTuong(
        form.idCompany,
        idcn,
        form.form,
        form.to,
      )
      setDataSource(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.BAO_CAO_LUOT_KHAM_THEO_DOI_DOI_TUONG,
        true,
        MENU_CONSTANTS.LUOT_KHAM,
        null,
        {
          nameCompany,
          nameChiNhanh,
          dateFrom: form.form,
          dateTo: form.to,
          nameChuyenKhoa,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.LUOT_KHAM,
        detailErrorPayload,
      )
    } finally {
      setisLoading(false)
    }
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranchByChuyenKhoa(ID_COMPANY, '')
    fetchChuyenKhoa()
    fetchData()
  }, [])
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT499',
      )
      const formattedData = valueExport?.map((item) => ({
        Ngày: item.ngaykham && moment(item.ngaykham).format('DD/MM/YYYY HH:mm:ss'),
        'Chi nhánh': item?.tenchinhanh,
        'Đối tượng': item?.doituong,
        'Loại thẻ': item?.tenthe,
        'Lần đầu nhi': item?.landaunhi,
        'Tái khám nhi': item?.taikhamnhi,
        'Bệnh mới nhi': item?.benhmoinhi,
        'Lần đầu sản': item?.landausan,
        'Tái khám sản': item?.taikhamsan,
        'Bệnh mới sản': item?.benhmoisan,
        'Lần đầu phụ khoa': item?.landauphukhoa,
        'Tái khám phụ khoa': item?.taikhamphukhoa,
        'Bệnh mới phụ khoa': item?.benhmoiphukhoa,
        'Lần đầu lão khoa': item?.landaulao,
        'Tái khám lão khoa': item?.taikhamlao,
        'Bệnh mới lão khoa': item?.benhmoilao,
        'Lần đầu PHCN': item?.landauphcn,
        'Tái khám PHCN': item?.taikhamphcn,
        'Bệnh mới PHCN': item?.benhmoiphcn,
        'Lần đầu nhãn khoa': item?.landaumat,
        'Tái khám nhãn khoa': item?.taikhammat,
        'Bệnh mới nhãn khoa': item?.benhmoimat,
        'Tiêm chủng': item?.tiemchung,
        Tổng: item?.tongcong,
      }))
      const name = 'Báo cáo theo dõi đối tượng'
      exportExcelformat(formattedData, name)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_BAO_CAO_LUOT_KHAM_THEO_DOI_DOI_TUONG,
        true,
        MENU_CONSTANTS.LUOT_KHAM,
        null,
        {
          nameCompany,
          nameChiNhanh,
          dateFrom: form.form,
          dateTo: form.to,
          nameChuyenKhoa,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const detailErrorPayload = `Có lỗi xảy ra khi xuất file báo cáo lượt khám theo dõi đối tượng \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.LUOT_KHAM,
        detailErrorPayload,
      )
    }
  }
  useEffect(() => {
    setValueExport(dataSource)
  }, [dataSource])
  return (
    <>
      <div className="flex gap-5 justify-between border-dotted border-b-0">
        <div className="flex flex-wrap gap-5 items-center mb-2">
          <div className={`w-80  ${isBlockView ? 'hidden' : ''}`}>
            <Select
              showSearch
              value={form.idCompany}
              onChange={(value) => {
                fetchBranchByChuyenKhoa(value, '')
                // setIdChiNhanh(null)
                setForm((prevState) => ({
                  ...prevState,
                  idCompany: value,
                  idChuyenKhoa: '',
                  idBranch: [''],
                }))
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className={`w-64 flex gap-1 ${isBlockView ? 'hidden' : ''}`}>
            <Select
              onChange={onChangSelected('idChuyenKhoa')}
              className="w-full"
              value={form.idChuyenKhoa}
              options={[
                { label: 'Tất cả chuyên khoa', value: '' },
                ...chuyenkhoas.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
            />
          </div>
          <div className={`w-64 flex gap-1 ${isBlockView ? 'hidden' : ''}`}>
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-3">
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('form')}
                format={dateFormat}
              />
              <span className="font-semibold text-lg">-</span>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('to')}
                format={dateFormat}
              />
            </div>
            <div className="flex gap-3">
              <IconButton onClick={fetchData} color="primary" aria-label="delete" size="small">
                <SyncIcon />
              </IconButton>
            </div>
            <div className="font-semibold ">
              <Button
                onClick={exportToExcel}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#EFEFEF',
                footerBg: '#EFEFEF',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            onChange={onChangeTable}
            loading={isLoading}
            scroll={{ x: 2600, y: '65vh' }}
            bordered
            pagination={false}
            dataSource={dataSource}
            columns={[
              {
                align: 'center',
                title: 'STT',
                key: 'stt',
                width: 60,
                render: (text, record, index) => ++index,
              },
              {
                title: 'Ngày',
                key: 'ngay',
                align: 'center',
                dataIndex: 'ngaykham',
                width: 150,
                render: (text) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
              },
              {
                title: 'Chi nhánh',
                key: 'tenchinhanh',
                dataIndex: 'tenchinhanh',
                width: 200,
                ...getColumnSearchProps('tenchinhanh'),
              },
              {
                title: 'Đối tượng',
                key: 'doituong',
                dataIndex: 'doituong',
                width: 150,
                ...getColumnSearchProps('doituong'),
              },
              {
                title: 'Loại thẻ',
                key: 'tenthe',
                dataIndex: 'tenthe',
                width: 300,
                ...getColumnSearchProps('tenthe'),
              },
              {
                title: 'Nhi khoa',
                key: 'nhi',
                children: [
                  {
                    title: 'Lần đầu',
                    key: 'landaunhi',
                    dataIndex: 'landaunhi',
                    align: 'center',
                  },
                  {
                    title: 'Tái khám',
                    key: 'taikhamnhi',
                    dataIndex: 'taikhamnhi',
                    align: 'center',
                  },
                  {
                    title: 'Bệnh mới',
                    key: 'benhmoinhi',
                    dataIndex: 'benhmoinhi',
                    align: 'center',
                  },
                ],
              },
              {
                title: 'Sản khoa',
                key: 'san',
                children: [
                  {
                    title: 'Lần đầu',
                    key: 'landausan',
                    dataIndex: 'landausan',
                    align: 'center',
                  },
                  {
                    title: 'Tái khám',
                    key: 'taikhamsan',
                    dataIndex: 'taikhamsan',
                    align: 'center',
                  },
                  {
                    title: 'Bệnh mới',
                    key: 'benhmoisan',
                    dataIndex: 'benhmoisan',
                    align: 'center',
                  },
                ],
              },
              {
                title: 'Phụ khoa',
                key: 'phukhoa',
                children: [
                  {
                    title: 'Lần đầu',
                    key: 'landauphukhoa',
                    dataIndex: 'landauphukhoa',
                    align: 'center',
                  },
                  {
                    title: 'Tái khám',
                    key: 'taikhamphukhoa',
                    dataIndex: 'taikhamphukhoa',
                    align: 'center',
                  },
                  {
                    title: 'Bệnh mới',
                    key: 'benhmoiphukhoa',
                    dataIndex: 'benhmoiphukhoa',
                    align: 'center',
                  },
                ],
              },

              {
                title: 'Lão khoa',
                key: 'laokhoa',
                children: [
                  {
                    title: 'Lần đầu',
                    key: 'ngay',
                    dataIndex: 'landaulao',
                    align: 'center',
                  },
                  {
                    title: 'Tái khám',
                    key: 'ngay',
                    dataIndex: 'taikhamlao',
                    align: 'center',
                  },
                  {
                    title: 'Bệnh mới',
                    key: 'ngay',
                    dataIndex: 'benhmoilao',
                    align: 'center',
                  },
                ],
              },
              {
                title: 'PHCN',
                key: 'phcn',
                children: [
                  {
                    title: 'Lần đầu',
                    key: 'landauphcn',
                    dataIndex: 'landauphcn',
                    align: 'center',
                  },
                  {
                    title: 'Tái khám',
                    key: 'taikhamphcn',
                    dataIndex: 'taikhamphcn',
                    align: 'center',
                  },
                  {
                    title: 'Bệnh mới',
                    key: 'benhmoiphcn',
                    dataIndex: 'benhmoiphcn',
                    align: 'center',
                  },
                ],
              },
              {
                title: 'Nhãn khoa',
                key: 'nhankhoa',
                children: [
                  {
                    title: 'Lần đầu',
                    key: 'landaumat',
                    dataIndex: 'landaumat',
                    align: 'center',
                  },
                  {
                    title: 'Tái khám',
                    key: 'taikhammat',
                    dataIndex: 'taikhammat',
                    align: 'center',
                  },
                  {
                    title: 'Bệnh mới',
                    key: 'benhmoimat',
                    dataIndex: 'benhmoimat',
                    align: 'center',
                  },
                ],
              },
              {
                title: 'Tiêm chủng',
                key: 'tiemchung',
                dataIndex: 'tiemchung',
                align: 'center',
              },
              {
                title: 'Tổng cộng',
                key: 'tongcong',
                dataIndex: 'tongcong',
                align: 'center',
                className: 'font-semibold text-red-500',
                fixed: 'right',
              },
            ]}
            summary={(pageData) => (
              <Table.Summary fixed>
                <Table.Summary.Row className="font-semibold">
                  <Table.Summary.Cell index={0} colSpan={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="center">
                    {formattedNumber(calculateSum(pageData, 'landaunhi'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="center">
                    {formattedNumber(calculateSum(pageData, 'taikhamnhi'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="center">
                    {formattedNumber(calculateSum(pageData, 'benhmoinhi'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align="center">
                    {formattedNumber(calculateSum(pageData, 'landausan'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align="center">
                    {formattedNumber(calculateSum(pageData, 'taikhamsan'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} align="center">
                    {formattedNumber(calculateSum(pageData, 'benhmoisan'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11} align="center">
                    {formattedNumber(calculateSum(pageData, 'landauphukhoa'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12} align="center">
                    {formattedNumber(calculateSum(pageData, 'taikhamphukhoa'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={13} align="center">
                    {formattedNumber(calculateSum(pageData, 'benhmoiphukhoa'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={14} align="center">
                    {formattedNumber(calculateSum(pageData, 'landaulao'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={15} align="center">
                    {formattedNumber(calculateSum(pageData, 'taikhamlao'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={16} align="center">
                    {formattedNumber(calculateSum(pageData, 'benhmoilao'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={17} align="center">
                    {formattedNumber(calculateSum(pageData, 'landauphcn'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={18} align="center">
                    {formattedNumber(calculateSum(pageData, 'taikhamphcn'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={19} align="center">
                    {formattedNumber(calculateSum(pageData, 'benhmoiphcn'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={20} align="center">
                    {formattedNumber(calculateSum(pageData, 'landaumat'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={21} align="center">
                    {formattedNumber(calculateSum(pageData, 'taikhammat'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={22} align="center">
                    {formattedNumber(calculateSum(pageData, 'benhmoimat'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={23} align="center">
                    {formattedNumber(calculateSum(pageData, 'tiemchung'))}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={24} align="center">
                    {formattedNumber(calculateSum(pageData, 'tongcong'))}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default DoiTuong
