import { call, put, takeLatest, select } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { khoVTservice } from '../../services/khoVT/khoVTservice'
import Swal from 'sweetalert2'
import moment from 'moment'
import { listBranchByThuocVT } from '../actions/thuocVTAction'
import { branchService } from '../../services/branch/branchService'
import { NhapKhoService } from '../../services/NhapKho/NhapKhoService'
import 'moment/locale/vi'
import { addLog } from '../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
const now = moment()
export function* ThuocVTSaga() {
  //lấy danh sách thuốc vật tư
  yield takeLatest(
    typeAction.GET_ALL_THUOC_VT,
    function* listThuocVT({
      type,
      keyword,
      idCT,
      idPhanLoai,
      idNhom,
      pageIndex,
      reset,
      setIsLoading,
      setTotalPages,
    }) {
      const { listThuocVT } = yield select((state) => state.thuocVTReducer)
      try {
        setIsLoading(true)
        const { data } = yield call(() =>
          khoVTservice.SearchByCondition(keyword, idCT, idPhanLoai, idNhom, pageIndex),
        )
        setTotalPages(data.totalPage === 0 ? 1 : data.totalPage)
        yield put({
          type: typeAction.DISPATCH_LIST_THUOCVT,
          payload: reset ? data : { ...data, data: [...listThuocVT?.data, ...data.data] },
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        setIsLoading(false)
      }
    },
  )
  // lấy danh sách hàng hoá mã số đăng ký
  yield takeLatest(
    typeAction.GET_PRODUCTION_MA_SO_DANG_KY,
    function* listThuocVTMaSoDangKy({
      type,
      keyword,
      idCT,
      idPhanLoai,
      idNhom,
      pageIndex,
      reset,
      setIsLoading,
      setTotalPages,
    }) {
      const { listThuocVT } = yield select((state) => state.thuocVTReducer)
      try {
        setIsLoading(true)
        const { data } = yield call(() =>
          khoVTservice.SearchByConditionMsdk(keyword, idCT, idPhanLoai, idNhom, pageIndex),
        )
        setTotalPages(data.totalPage === 0 ? 1 : data.totalPage)
        yield put({
          type: typeAction.DISPATCH_LIST_THUOCVT,
          payload: reset ? data : { ...data, data: [...listThuocVT?.data, ...data.data] },
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        setIsLoading(false)
      }
    },
  )
  //lấy danh sách thuốc vật tư xài cho menu giá bán vì có truyền thêm từ ngày đến ngày
  yield takeLatest(
    typeAction.GET_HISTORY_PRICE_GPP,
    function* listThuocVTGPP({
      type,
      keyword,
      idCT,
      idPhanLoai,
      idNhom,
      pageIndex,
      reset,
      setIsLoading,
      setTotalPages,
      tuNgay,
      denNgay,
      filterName,
    }) {
      const { listThuocVT } = yield select((state) => state.thuocVTReducer)
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        setIsLoading(true)
        const { data } = yield call(() =>
          khoVTservice.SearchByConditionHistoryGPP(
            keyword,
            idCT,
            idPhanLoai,
            idNhom,
            tuNgay,
            denNgay,
            pageIndex,
          ),
        )
        setTotalPages(data.totalPage === 0 ? 1 : data.totalPage)
        yield put({
          type: typeAction.DISPATCH_LIST_THUOCVT,
          payload: reset ? data : { ...data, data: [...listThuocVT?.data, ...data.data] },
        })
        addLog(
          infoUser.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_GPP,
          true,
          MENU_CONSTANTS.GPP,
          null,
          {
            dateFrom: tuNgay,
            dateTo: denNgay,
            keyword: keyword ?? '',
            idPhanLoai: idPhanLoai ?? '',
            idNhom: idNhom ?? '',
            idct: idCT,
            page: pageIndex,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.GPP,
          detailErrorPayload,
        )
      } finally {
        setIsLoading(false)
      }
    },
  )
  yield takeLatest(
    typeAction.GET_ALL_THUOC_VT_GIABAN,
    function* listThuocVT({
      type,
      keyword,
      idCT,
      idPhanLoai,
      idNhom,
      pageIndex,
      reset,
      setIsLoading,
      setTotalPages,
      tuNgay,
      denNgay,
      filterName,
    }) {
      const { listThuocVT } = yield select((state) => state.thuocVTReducer)
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        setIsLoading(true)
        const { data } = yield call(() =>
          khoVTservice.SearchByConditionChoGiaBan(
            keyword,
            idCT,
            idPhanLoai,
            idNhom,
            tuNgay,
            denNgay,
            pageIndex,
          ),
        )
        setTotalPages(data.totalPage === 0 ? 1 : data.totalPage)
        yield put({
          type: typeAction.DISPATCH_LIST_THUOCVT,
          payload: reset ? data : { ...data, data: [...listThuocVT?.data, ...data.data] },
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_THUOC_VAT_TU_GIA_BAN,
          true,
          MENU_CONSTANTS.GIA_BAN,
          null,
          {
            dateFrom: tuNgay,
            dateTo: denNgay,
            keyword,
            nameCompany: filterName.nameCompany,
            nameLoaiThuocVT: filterName.nameLoaiThuocVT || '',
            nameNhomThuocVT: filterName.nameNhomThuocVT || '',
            page: pageIndex,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.GIA_BAN,
          detailErrorPayload,
        )
      } finally {
        setIsLoading(false)
      }
    },
  )
  //lấy danh sách thuốc vật tư chọn nhiều nhóm
  yield takeLatest(
    typeAction.GET_ALL_THUOC_VT_THEOLISTIDNHOM,
    function* listThuocVTtheoListIdNhom({
      type,
      keyword,
      idCT,
      idPhanLoai,
      listIdNhom,
      pageIndex,
      reset,
      setIsLoading,
      setTotalPages,
      listName,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const { HangHoaThuoc } = yield select((state) => state.thuocVTReducer)

      try {
        setIsLoading(true)
        const strListIdNhom = idPhanLoai
          ? listIdNhom?.reduce((str, item) => (str = `${str}&idNhom=${item}`), '')
          : ''
        // return
        const { data } = yield call(() =>
          khoVTservice.SearchByConditionHangThuoc(
            keyword,
            idCT,
            idPhanLoai,
            strListIdNhom,
            pageIndex,
          ),
        )

        setTotalPages(data?.totalPage === 0 ? 1 : data.totalPage)
        yield put({
          type: typeAction.DISPATCH_HANG_HOA_THUOC,
          payload: reset ? data : { ...data, data: [...HangHoaThuoc?.data, ...data.data] },
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_THUOC_VAT_TU,
          true,
          MENU_CONSTANTS.THUOC_VAT_TU,
          null,
          {
            nameCompany: listName.nameCompany,
            nameLoaiThuocVT: listName.nameLoaiThuocVT || '',
            nameNhomThuocVT: listName.nameNhomThuocVT || '',
            page: pageIndex,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.THUOC_VAT_TU,
          detailErrorPayload,
        )
      } finally {
        setIsLoading(false)
      }
    },
  )
  yield takeLatest(
    typeAction.GET_HANG_HOA_VAT_TU,
    function* listThuocVTtheoListIdNhom({
      type,
      keyword,
      idCT,
      idPhanLoai,
      listIdNhom,
      pageIndex,
      reset,
      setIsLoading,
      setTotalPages,
      listName,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const { HangHoa } = yield select((state) => state.thuocVTReducer)

      try {
        setIsLoading(true)
        const strListIdNhom = idPhanLoai
          ? listIdNhom?.reduce((str, item) => (str = `${str}&idNhom=${item}`), '')
          : ''
        // return
        const { data } = yield call(() =>
          khoVTservice.SearchByConditionHangvt(keyword, idCT, idPhanLoai, strListIdNhom, pageIndex),
        )

        setTotalPages(data?.totalPage === 0 ? 1 : data.totalPage)
        yield put({
          type: typeAction.DISPATCH_HANG_HOA,
          payload: reset ? data : { ...data, data: [...HangHoa?.data, ...data.data] },
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_THUOC_VAT_TU,
          true,
          MENU_CONSTANTS.THUOC_VAT_TU,
          null,
          {
            nameCompany: listName.nameCompany,
            nameLoaiThuocVT: listName.nameLoaiThuocVT || '',
            nameNhomThuocVT: listName.nameNhomThuocVT || '',
            page: pageIndex,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.THUOC_VAT_TU,
          detailErrorPayload,
        )
      } finally {
        setIsLoading(false)
      }
    },
  )
  //ADD THUỐC VẬT TƯ
  yield takeLatest(typeAction.POST_THUOC_VT, function* postThuocVT({ type, form, ArrCompany }) {
    console.log(form)
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      form.ngayTao = now.format()
      for (let id of ArrCompany) {
        yield call(() => khoVTservice.addThuocVT({ ...form, idct: id }))
      }
      Toast.fire({
        icon: 'success',
        title: 'Thêm thuốc VT thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.THEM_THUOC_VAT_TU,
        true,
        MENU_CONSTANTS.THUOC_VAT_TU,
        null,
        {
          mathuoc: form.maThuoc,
          tenthuoc: form.tenBietDuoc,
        },
      )
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Thêm thuốc vật tư thất bại! Mã hàng không được trùng!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.THUOC_VAT_TU,
        detailErrorPayload,
      )
    }
  })
  //DELETE THUỐC VẬT TƯ
  yield takeLatest(typeAction.DELETE_THUOC_VT, function* deleteThuocVT({ type, id }) {
    try {
      yield call(() => khoVTservice.deleteThuocVT(id))
      // yield put(getListThuocVTByPageIndex_Action(form))
      Toast.fire({
        icon: 'success',
        title: 'Xoá Thuốc-Vật tư thành công!',
      })
    } catch (err) {
      yield console.log(err)
      Toast.fire({
        icon: 'error',
        title: 'Xoá Thuốc-Vật tư thất bại!',
      })
    }
  })
  //GET INFO THUOC VT
  yield takeLatest(typeAction.GET_INFO_KHO_VT, function* infoThuocVT({ type, id }) {
    try {
      const result = yield call(() => khoVTservice.getInfoVT(id))
      yield put({
        type: typeAction.DISPATCH_INFO_KHO_VT,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //GET INFO Nước xuất sứ
  yield takeLatest(typeAction.GET_INFO_COUNTRY, function* infoThuocVT({ type }) {
    try {
      const result = yield call(() => khoVTservice.getListCountry())
      yield put({
        type: typeAction.DISPATCH_INFO_COUNTRY,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //lấy danh sách thuốc vâtj tư kho tồn
  yield takeLatest(
    typeAction.GET_LIST_TON_KHO_VT,
    function* getListTonKho({
      type,
      idct,
      idChiNhanh,
      idkhocn,
      toDate,
      types,
      valueGroups,
      pageNumber,
      keyword,
      chuyenKhoa,
      setLoading,
      filterName,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        setLoading(true)
        const result = yield call(() =>
          khoVTservice.getTonKhoByConditionForData(
            idct,
            idChiNhanh,
            idkhocn,
            types,
            valueGroups,
            toDate,
            keyword,
            chuyenKhoa,
          ),
        )
        //  const result = yield call(() => khoVTservice.getTonKhoCondition(idct, idChiNhanh, idkhocn,))
        // const data =  result.data.filter((item) => item.tonKho > 0)
        yield put({
          type: typeAction.DISPATCH_LIST_TON_KHO,
          payload: result.data,
        })
        setLoading(false)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_TON_KHO_CHI_TIET,
          true,
          MENU_CONSTANTS.TON_KHO,
          null,
          {
            nameCompany: filterName.nameCompany,
            nameLoaiThuocVT: filterName.nameLoaiThuocVT,
            nameNhomThuocVT: filterName.nameNhomThuocVT,
            nameKho: filterName.nameKho,
            keyword: filterName.keyword,
            nameChiNhanh: filterName.nameChiNhanh,
            dateTo: toDate,
          },
        )
      } catch (error) {
        console.log(error)
        setLoading(false)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TON_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(
    typeAction.GET_LIST_TON_KHO_VT_HANG_HOA,
    function* getListTonKhoHangHoa({
      type,
      idct,
      idChiNhanh,
      idkhocn,
      toDate,
      types,
      valueGroups,
      pageNumber,
      keyword,
      chuyenKhoa,
      setLoading,
      filterName,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        setLoading(true)
        const result = yield call(() =>
          khoVTservice.getTonKhoByConditionForDataHangHoa(
            idct,
            idChiNhanh,
            idkhocn,
            types,
            valueGroups,
            toDate,
            keyword,
            chuyenKhoa,
          ),
        )
        //  const result = yield call(() => khoVTservice.getTonKhoCondition(idct, idChiNhanh, idkhocn,))
        // const data =  result.data.filter((item) => item.tonKho > 0)
        yield put({
          type: typeAction.DISPATCH_TON_HANG_HOA,
          payload: result.data,
        })
        setLoading(false)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_TON_KHO_CHI_TIET,
          true,
          MENU_CONSTANTS.TON_HANH_HOA,
          null,
          {
            nameCompany: filterName.nameCompany,
            nameLoaiThuocVT: filterName.nameLoaiThuocVT,
            nameNhomThuocVT: filterName.nameNhomThuocVT,
            nameKho: filterName.nameKho,
            keyword: filterName.keyword,
            nameChiNhanh: filterName.nameChiNhanh,
            dateTo: toDate,
          },
        )
      } catch (error) {
        console.log(error)
        setLoading(false)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TON_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  //------------------- GET TON BY FROM TO --------------//
  yield takeLatest(
    typeAction.GET_TON_BY_FROM_TO,
    function* getTonFromTo({
      type,
      idct,
      idChiNhanh,
      idkhocn,
      toDate,
      fromDate,
      keyword,
      chuyenKhoa,
      setLoading,
      filterName,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        setLoading(true)
        const result = yield call(() =>
          khoVTservice.getTonFromToDay(
            idct,
            idChiNhanh,
            idkhocn,
            toDate,
            fromDate,
            keyword,
            chuyenKhoa,
          ),
        )
        //  const result = yield call(() => khoVTservice.getTonKhoCondition(idct, idChiNhanh, idkhocn,))
        // const data =  result.data.filter((item) => item.tonKho > 0)
        yield put({
          type: typeAction.DISPATCH_TON_KHO_FROM_TO,
          payload: result.data,
        })
        setLoading(false)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_TON_KHO_NGAY,
          true,
          MENU_CONSTANTS.TON_KHO,
          null,
          {
            nameCompany: filterName.nameCompany,
            nameChuyenKhoa: filterName.nameChuyenKhoa,
            nameKho: filterName.nameKho,
            keyword: filterName.keyword,
            nameChiNhanh: filterName.nameChiNhanh,
            dateTo: toDate,
            dateFrom: fromDate,
          },
        )
      } catch (error) {
        console.log(error)
        setLoading(false)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TON_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(
    typeAction.GET_TON_BY_FROM_TO_HANG_HOA,
    function* getTonFromTo({
      type,
      idct,
      idChiNhanh,
      idkhocn,
      toDate,
      fromDate,
      keyword,
      chuyenKhoa,
      setLoading,
      filterName,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        setLoading(true)
        const result = yield call(() =>
          khoVTservice.getTonFromToDayHangHoa(
            idct,
            idChiNhanh,
            idkhocn,
            toDate,
            fromDate,
            keyword,
            chuyenKhoa,
          ),
        )
        //  const result = yield call(() => khoVTservice.getTonKhoCondition(idct, idChiNhanh, idkhocn,))
        // const data =  result.data.filter((item) => item.tonKho > 0)
        yield put({
          type: typeAction.DISPATCH_TON_FROM_DAY_HANG_HOA,
          payload: result.data,
        })
        setLoading(false)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_TON_KHO_NGAY,
          true,
          MENU_CONSTANTS.TON_KHO,
          null,
          {
            nameCompany: filterName.nameCompany,
            nameChuyenKhoa: filterName.nameChuyenKhoa,
            nameKho: filterName.nameKho,
            keyword: filterName.keyword,
            nameChiNhanh: filterName.nameChiNhanh,
            dateTo: toDate,
            dateFrom: fromDate,
          },
        )
      } catch (error) {
        console.log(error)
        setLoading(false)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TON_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  //------------------- TỒN KHO TỔNG HỢP -----------------//
  yield takeLatest(
    typeAction.GET_TON_KHO_TONG_HOP,
    function* getListTonKhoTongHop({
      type,
      IDCongTy,
      IDChiNhanh,
      IDKhoCN,
      types,
      valueGroups,
      // toDate,
      keyword,
      chuyenKhoa,
      setLoading,
      filterName,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        setLoading(true)
        const result = yield call(() =>
          khoVTservice.getTonKhoTongHop(
            IDCongTy,
            IDChiNhanh,
            IDKhoCN,
            types,
            valueGroups,
            keyword,
            chuyenKhoa,
          ),
        )
        yield put({
          type: typeAction.DISPATCH_TONKHO_TON_HOP,
          payload: result.data,
        })
        setLoading(false)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_TON_KHO_TONG_HOP,
          true,
          MENU_CONSTANTS.TON_KHO,
          null,
          {
            nameCompany: filterName.nameCompany,
            nameLoaiThuocVT: filterName.nameLoaiThuocVT,
            nameNhomThuocVT: filterName.nameNhomThuocVT,
            nameKho: filterName.nameKho,
            keyword: filterName.keyword,
            nameChiNhanh: filterName.nameChiNhanh,
          },
        )
      } catch (error) {
        console.log(error)
        setLoading(false)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TON_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(
    typeAction.GET_TON_KHO_TONG_HOP_HANG_HOA,
    function* getListTonKhoTongHop({
      type,
      IDCongTy,
      IDChiNhanh,
      IDKhoCN,
      types,
      valueGroups,
      // toDate,
      keyword,
      chuyenKhoa,
      setLoading,
      filterName,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        setLoading(true)
        const result = yield call(() =>
          khoVTservice.getTonKhoTongHopHangHoa(
            IDCongTy,
            IDChiNhanh,
            IDKhoCN,
            types,
            valueGroups,
            keyword,
            chuyenKhoa,
          ),
        )
        yield put({
          type: typeAction.DISPATCH_TON_TONG_HOP_HANG_HOA,
          payload: result.data,
        })
        setLoading(false)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_TON_KHO_TONG_HOP,
          true,
          MENU_CONSTANTS.TON_KHO,
          null,
          {
            nameCompany: filterName.nameCompany,
            nameLoaiThuocVT: filterName.nameLoaiThuocVT,
            nameNhomThuocVT: filterName.nameNhomThuocVT,
            nameKho: filterName.nameKho,
            keyword: filterName.keyword,
            nameChiNhanh: filterName.nameChiNhanh,
          },
        )
      } catch (error) {
        console.log(error)
        setLoading(false)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TON_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  //lấy danh sách thuốc vâtj tư kho tồn theo kho chi nhanh
  yield takeLatest(
    typeAction.GET_LIST_TON_KHO_CN_VT,
    function* getListTonKho({ type, idKhoCN, setLoading }) {
      try {
        setLoading(true)
        const result = yield call(() => khoVTservice.getListTonkhoByKhoCN(idKhoCN))
        yield put({
          type: typeAction.DISPATCH_LIST_TON_KHO,
          payload: result.data.result,
        })
        setLoading(false)
      } catch (err) {
        yield console.log(err)
        setLoading(false)
      }
    },
  )

  //LẤY LIST INFO LOẠI THUỐC VẬT TƯ
  yield takeLatest(
    typeAction.GET_LIST_TYPES_THUOC_VT,
    function* listTypesThuocVT({ type, idChiNhanh, setLoading }) {
      try {
        const result = yield call(() => khoVTservice.getListTypesThuocVT())
        yield put({
          type: typeAction.DISPATCH_TYPES_THUOC_VT,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //LẤY LIST INFO LOẠI THUỐC VẬT TƯ
  yield takeLatest(
    typeAction.GET_ALL_LIST_GROUPS_THUOC_VT,
    function* listAllGroupsThuocVT({ type, idType }) {
      try {
        const result = yield call(() => khoVTservice.getListGroupsThuocVT(idType))
        yield put({
          type: typeAction.DISPATCH_GET_ALL_GROUPS_VT,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // LẤY TẤT CẢ DANH SÁCH LOẠI THUỐC VẬT TƯ
  yield takeLatest(typeAction.GET_ALL_TYPES_THUOC_VT, function* listAllTypesThuocVT({ type }) {
    try {
      const result = yield call(() => khoVTservice.getListTypesThuocVT())
      yield put({
        type: typeAction.DISPATCH_ALL_TYPE_VT,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // GET LIST GROUPS BY TYPE
  yield takeLatest(
    typeAction.GET_LIST_GROUPS_THUOC_VT_BY_TYPE,
    function* ListGroups({ type, idTypes }) {
      try {
        const result = yield call(() => khoVTservice.getListGroupsThuocVT(idTypes))
        yield put({
          type: typeAction.DISPATCH_GROUPS_THUOC_VT,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // GET LIST BRANCH BUY BY THUOC VT
  yield takeLatest(
    typeAction.GET_LIST_BRACNH_BUY,
    function* listBranchBuy({ type, idThuoc, idct, idChuyenKhoa, setIsloadingBranch, tenThuoc }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        setIsloadingBranch(true)
        const result = yield call(() => khoVTservice.getListBranchBuy(idThuoc, idct, idChuyenKhoa))
        yield put({
          type: typeAction.DISPATCH_BRANCH_BUY,
          payload: result.data,
        })
        setIsloadingBranch(false)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_CHI_NHANH_BAN_BY_THUOC,
          true,
          MENU_CONSTANTS.CHI_NHANH_BAN,
          null,
          {
            tenThuoc,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.CHI_NHANH_BAN,
          detailErrorPayload,
        )
      }
    },
  )
  // GET LIST GROUPS VT BRANCH BUY
  yield takeLatest(typeAction.GET_GROUPS_VT_BRANCH_BUY, function* groupsVTBuy({ type, idTypes }) {
    try {
      const result = yield call(() => khoVTservice.getListGroupsThuocVT(idTypes))
      yield put({
        type: typeAction.DISPATCH_GROUPS_VT_BRANCH_BUY,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // GET ALL CHUYEN KHOA
  yield takeLatest(typeAction.GET_ALL_CHUYEN_KHOA, function* groupsVTBuy({ type }) {
    try {
      const result = yield call(() => khoVTservice.getAllChuyenKhoa())
      yield put({
        type: typeAction.DISPATCH_ALL_CHUYEN_KHOA,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // LẤY DANH SÁCH CHI NHÁNH CỦA TỒN KHO
  yield takeLatest(typeAction.GET_BRANCH_TON_KHO, function* branchTonKho({ type, IDCompany }) {
    try {
      const result = yield call(() => branchService.getListBranch(IDCompany))
      yield put({
        type: typeAction.DISPATCH_BRANCH_TON_KHO,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // UPDATE CHI NHANH BAN THEO THUOC VT
  yield takeLatest(
    typeAction.UPDATE_STATUS_BRANCH_BUY,
    function* updateBranchBuy({ type, form, idThuoc, setIsloadingBranch, tenThuoc }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield call(() => khoVTservice.putBranchBuy(form))
        yield put(listBranchByThuocVT(idThuoc, setIsloadingBranch))
        Toast.fire({
          icon: 'success',
          title: 'cập nhật thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CAP_NHAT_TRANG_THAI_BAN,
          true,
          MENU_CONSTANTS.CHI_NHANH_BAN,
          null,
          {
            tenThuoc,
          },
        )
      } catch (error) {
        yield console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'cập nhật thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CHI_NHANH_BAN,
          detailErrorPayload,
        )
      }
    },
  )
  //LẤY DANH SÁCH THUÔC VẬT TƯ THEO TỪ KHOÁ
  yield takeLatest(
    typeAction.GET_THUOC_VT_BY_KEYWORD,
    function* searchKeyword({ type, keyword, pageIndex }) {
      try {
        const result = yield call(() => khoVTservice.searchVTKeyword(keyword, pageIndex))
        yield put({
          type: typeAction.DISPATCH_LIST_THUOCVT,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //LẤY LỊCH SỮ MÃ SỐ ĐĂNG KÝ
  yield takeLatest(typeAction.GET_HISTORY_MSDK, function* historyMSDK({ type, idThuoc, tenThuoc }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)

    try {
      const result = yield call(() => khoVTservice.getMSDKThuoc(idThuoc))
      yield put({
        type: typeAction.DISPATCH_MSDK_THU_MUA,
        payload: result.data,
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_MSDK_BY_THUOC,
        true,
        MENU_CONSTANTS.MA_SO_DANG_KY,
        null,
        {
          tenThuoc,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.MA_SO_DANG_KY,
        detailErrorPayload,
      )
    }
  })
  //------------ LẤY DANH SÁCH NHÓM THUỐC VẬT TƯ THẺ KHO -----------------//
  yield takeLatest(typeAction.GET_GROUPS_THE_KHO, function* groupsTheKho({ type, idType }) {
    try {
      const result = yield call(() => khoVTservice.getListGroupsThuocVT(idType))
      yield put({
        type: typeAction.DISPATCH_GROUPS_THE_KHO,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  //---------------- LẤY DANH SÁCH THẺ KHO THEO CHI NHÁNH --------------- //
  yield takeLatest(typeAction.GET_KHO_THE_KHO, function* khoTheKho({ type, idChinhanh }) {
    try {
      const result = yield call(() => NhapKhoService.getListKhoNhap(idChinhanh))
      yield put({
        type: typeAction.DISPATCH_KHO_THE_KHO,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  //------------- lấy danh sách đường dùng thuốc vật tư ---------//
  yield takeLatest(typeAction.GET_DUONG_DUNG_THUOC, function* fetchDuongDung({ type }) {
    try {
      const result = yield call(() => khoVTservice.fetchDuongDung())
      yield put({
        type: typeAction.DISPATCH_DUONG_DUNG,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  //UPDATE THUỐC VẬT TƯ
  yield takeLatest(typeAction.UPDATE_THUOC_VAT_TU, function* updateThuocVT({ type, form }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      const result = yield call(() => khoVTservice.updateThuocVTById(form))
      yield put({
        type: typeAction.DISPATCH_INFO_KHO_VT, // update lại store
        payload: result.data,
      })
      Toast.fire({
        icon: 'success',
        title: 'cập nhật thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.THEM_THUOC_VAT_TU,
        true,
        MENU_CONSTANTS.THUOC_VAT_TU,
        null,
        {
          mathuoc: form.maThuoc,
          tenthuoc: form.tenBietDuoc,
        },
      )
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Thêm thuốc vật tư thất bại! Mã hàng không được trùng!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.THUOC_VAT_TU,
        detailErrorPayload,
      )
    }
  })
  ////------------ update danh sách hoàng hóa hàng loặt -----------//
  yield takeLatest(
    typeAction.IMPORT_THU_MUA_HANG_LOAT,
    function* importThuMua({ type, form, setIsLoading }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const totalItems = form.length
        setIsLoading(true)
        for (let index = 0; index < totalItems; index++) {
          const item = form[index]
          yield call(() => khoVTservice.addThuocVT(item))
          // Cập nhật tiến trình
          const progress = Math.round(((index + 1) / totalItems) * 100)
          yield put({
            type: typeAction.UPDATE_PROGRESS_THU_MUA,
            payload: progress,
          })
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.THEM_THUOC_VAT_TU,
            true,
            MENU_CONSTANTS.THUOC_VAT_TU,
            null,
            {
              mathuoc: form[index].maThuoc,
              tenthuoc: form[index].tenBietDuoc,
            },
          )
        }
        yield put({
          type: typeAction.DISPATCH_FILE_IMPORT_THU_MUA, // update lại store
          payload: [],
        })
        setIsLoading(false)
        Toast.fire({
          icon: 'success',
          title: 'cập nhật thành công!',
        })
      } catch (error) {
        console.log(error)
        setIsLoading(false)
        Toast.fire({
          icon: 'error',
          title: error.response.data,
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.THUOC_VAT_TU,
          detailErrorPayload,
        )
      }
    },
  )
  //-------------------- LẤY DANH SÁCH LỊCH SỬ THẺ KHO ----------//
  yield takeLatest(
    typeAction.FILTER_HISTORY_THE_KHO,
    function* FilterTheKho({ type, filter, setIsLoading }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        setIsLoading(true)
        const { IDCT, branch, kho, dateFrom, dateTo, idThuoc, IDChuyenKhoa } = filter
        const result = yield call(() =>
          khoVTservice.getTheKho(IDCT, branch, kho, dateFrom, dateTo, idThuoc, IDChuyenKhoa),
        )

        yield put({
          type: typeAction.DISPATCH_HISTORY_THE_KHO, // update lại store
          payload: result.data,
        })
        setIsLoading(false)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_THE_KHO_BY_THUOC,
          true,
          MENU_CONSTANTS.THE_KHO,
          null,
          {
            dateFrom: filter.dateFrom,
            dateTo: filter.dateTo,
            nameChiNhanh: filter.nameChiNhanh.join('\n'),
            nameCompany: filter.nameCompany,
            nameThuoc: filter.tenThuoc,
          },
        )
      } catch (error) {
        yield console.log(error)
        setIsLoading(false)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.THE_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  //-------------- CHECK MA HANG DANH SACH THUOC VAT TU IMPORT -------------//
  yield takeLatest(
    typeAction.CHECK_IMPORT_DANH_SACH_VAT_TU,
    function* checkMaHang({ type, data, idct, openNotificationWithIcon }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const maHangArray = data.map((item) => item.maThuoc)
        yield call(() => khoVTservice.checkMaHangHoa(idct, maHangArray))
        yield put({
          type: typeAction.DISPATCH_FILE_IMPORT_THU_MUA,
          payload: data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (error) {
        if (error.response.status === 400) {
          openNotificationWithIcon('error', `Trùng mã hàng ${error.response.data.duplicates}`)
        }
        console.log(error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  //--------------- UPDATE GIÁ THUỐC VẬT TƯ CHI NHÁNH BÁN -----------------
  yield takeLatest(
    typeAction.UPDATE_PRICE_BRANCH_BUY,
    function* updatePrice({ type, form, idnv, idThuoc, handleResetForm, nameVT }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const { giaBan, idCT, idChuyenKhoa } = form
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() =>
          khoVTservice.updateGiaChiNhanhBan(giaBan, idnv, idThuoc, idCT, idChuyenKhoa),
        )
        handleResetForm()
        Toast.fire({
          icon: 'success',
          title: 'cập nhật thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.SUA_GIA_CHI_NHANH_BAN,
          true,
          MENU_CONSTANTS.CHI_NHANH_BAN,
          null,
          {
            giaBan,
            nameVT,
          },
        )
      } catch (error) {
        console.log(error)

        Toast.fire({
          icon: 'error',
          title: 'cập nhật thất bại !',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CHI_NHANH_BAN,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
