import { call, put, select, takeLatest } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import { loaiHangHoaService } from '../../services/loaiHangHoa/loaiHangHoa'
import { getAllLoaiHangHoa } from '../actions/loaiHangHoaAction'
import { on } from 'devextreme/events'
import { take } from 'lodash'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* loaiHangHoaSaga() {
  //get ThuocVatTuPhanLoai
  yield takeLatest(
    typeAction.GET_THUOC_VAT_TU_PHAN_LOAI,
    function* getThuocVatTuPhanLoai({ idnhom, keyword }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const { data } = yield call(() => loaiHangHoaService.getAllLoaiHangHoa(idnhom, keyword))
        addLog(
          infoUser?.dangNhap?.idNguoiDung,
          PkDangNhap?.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI,
          true,
          MENU_CONSTANTS.LOAI_HANG_HOA,
          null,
          {
            keyword,
            idnhom,
          },
        )
        yield put({
          type: typeAction.DISPATCH_THUOC_VAT_TU_PHAN_LOAI,
          payload: data,
        })
      } catch (error) {
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap?.idNguoiDung,
          PkDangNhap?.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.LOAI_HANG_HOA,
          detailErrorPayload,
        )
      }
    },
  )

  //post ThuocVatTuPhanLoai
  yield takeLatest(
    typeAction.ADD_NEW_THUOC_VAT_TU_PHAN_LOAI,
    function* postThuocVatTuPhanLoai({ form, onReset }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const { data } = yield call(() => loaiHangHoaService.postLoaiHangHoa(form))
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.THEM_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI,
          true,
          MENU_CONSTANTS.LOAI_HANG_HOA,
          null,
          {
            form,
          },
        )
        yield put(getAllLoaiHangHoa())
        onReset()
        Toast.fire({
          icon: 'success',
          title: 'Tạo nhãn thành công!',
        })
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Tạo nhãn thất bại!',
        })
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.LOAI_HANG_HOA,
          detailErrorPayload,
        )
      }
    },
  )
  //update ThuocVatTuPhanLoai
  yield takeLatest(
    typeAction.UPDATE_THUOC_VAT_TU_PHAN_LOAI,
    function* updateThuocVatTuPhanLoai({ form }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const { data } = yield call(() => loaiHangHoaService.updateLoaiHangHoa(form))
        yield put(getAllLoaiHangHoa())
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật hàng hóa thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_SUA_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI,
          true,
          MENU_CONSTANTS.LOAI_HANG_HOA,
          null,
          {
            form,
          },
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật hàng hóa thất bại!',
        })
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.LOAI_HANG_HOA,
          detailErrorPayload,
        )
      }
    },
  )
  //get ThuocVatTuPhanLoai by id
  yield takeLatest(typeAction.GET_INFO_THUOC_VAT_TU, function* ({ id }) {
    try {
      const { data } = yield call(() => loaiHangHoaService.getDetailThuocVatTu(id))
      yield put({
        type: typeAction.DISPATCH_INFO_THUOC_VAT_TU,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })

  //delete ThuocVatTuPhanLoai
  yield takeLatest(typeAction.DELETE_THUOC_VAT_TU_PHAN_LOAI, function* ({ id, handleCancel }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      const { data } = yield call(() => loaiHangHoaService.deleteLoaiHangHoa(id))
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.DELETE,
        logAction.XOA_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI,
        true,
        MENU_CONSTANTS.LOAI_HANG_HOA,
        null,
        {
          id,
        },
      )
      yield put(getAllLoaiHangHoa())
      handleCancel()
      Toast.fire({
        icon: 'success',
        title: 'Xóa hàng hóa thành công!',
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Không thể xoá loại hàng vì có nhóm hàng liên quan',
      })
      yield console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.DELETE,
        null,
        false,
        MENU_CONSTANTS.LOAI_HANG_HOA,
        detailErrorPayload,
      )
    }
  })

  //get NhomHangHoaByLoai
  yield takeLatest(
    typeAction.GET_NHOM_HANG_HOA_BY_LOAI,
    function* getNhomHangHoaByLoai({ id, keyword }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const { data } = yield call(() => loaiHangHoaService.getNhomHangHoaByLoai(id, keyword))
        yield put({
          type: typeAction.DISPATCH_NHOM_HANG_HOA_BY_LOAI,
          payload: data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DETAIL_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI,
          true,
          MENU_CONSTANTS.LOAI_HANG_HOA,
          null,
          {
            id,
            keyword,
          },
        )
      } catch (error) {
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.LOAI_HANG_HOA,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(typeAction.POST_NEW_NHOM_HANG_HOA, function* postNhom({ form, onReset }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      const { data } = yield call(() => loaiHangHoaService.postNhomHangHoa(form))
      onReset()
      Toast.fire({
        icon: 'success',
        title: 'Tạo nhóm hàng hóa thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.THEM_LOAI_HANG_HOA_THUOC_VAT_TU_NHOM,
        true,
        MENU_CONSTANTS.LOAI_HANG_HOA,
        null,
        {
          form,
        },
      )
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Tạo nhóm hàng hóa thất bại!',
      })
      yield console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.LOAI_HANG_HOA,
        detailErrorPayload,
      )
    }
  })

  //get all Loai

  yield takeLatest(
    typeAction.GET_ALL_LOAI_NHOM_HANG_HOA,
    function* getAllLoaiNhomHangHoa({ setLoai }) {
      try {
        const { data } = yield call(() => loaiHangHoaService.getAllLoaiHangHoa('', ''))
        setLoai(data)
      } catch (err) {
        yield console.log(err)
      }
    },
  )

  yield takeLatest(
    typeAction.UPDATE_NHOM_HANG_HOA,
    function* updateNhomHangHoa({ form, handleCancel, fetchDanhMucNhan }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const { data } = yield call(() => loaiHangHoaService.updateNhomHangHoa(form))
        handleCancel()
        fetchDanhMucNhan()
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật nhóm hàng hóa thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_SUA_LOAI_HANG_HOA_THUOC_VAT_TU_NHOM,
          true,
          MENU_CONSTANTS.LOAI_HANG_HOA,
          null,
          {
            form,
          },
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật nhóm hàng hóa thất bại!',
        })
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.LOAI_HANG_HOA,
          detailErrorPayload,
        )
      }
    },
  )

  yield takeLatest(
    typeAction.DELETE_NHOM_HANG_HOA,
    function* deleteNhomHangHoa({ id, fetchDanhMucNhan }) {
      try {
        const { data } = yield call(() => loaiHangHoaService.deleteNhomHangHoa(id))
        fetchDanhMucNhan()
        Toast.fire({
          icon: 'success',
          title: 'Xóa nhóm hàng hóa thành công!',
        })
      } catch (err) {
        console.log('err', err)
        Toast.fire({
          icon: 'error',
          title: 'Xóa nhóm hàng hóa thất bại!',
        })
        yield console.log(err)
      }
    },
  )

  yield takeLatest(
    typeAction.UPDATE_TPCH_NHOM_HANG_HOA,
    function* updateThucPhamChucNang({ active, listIdNhom, fetchDanhMucNhan }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => loaiHangHoaService.updateThucPhamChucNang(active, listIdNhom))
        fetchDanhMucNhan()
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.UPDATE_TPCN_LOAI_HANG_HOA_THUOC_VAT_TU_NHOM,
          true,
          MENU_CONSTANTS.LOAI_HANG_HOA,
          null,
          {
            active,
            listIdNhom: listIdNhom.join(', '),
          },
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại!',
        })
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.LOAI_HANG_HOA,
          detailErrorPayload,
        )
      }
    },
  )
}
