import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { https } from '../../../../services/apiService'
import { addLienHeCRM, getLienHeCRM } from '../../../../store/actions/lienheCRMAction'
import { createLienHeSchema } from '../../../../schemas/createLienHeSchema'
const dateFormat = 'DD-MM-YYYY'

const ModalAdd = ({ openAddLienHe, setOpenAddLienHe, rowActive }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const [allCongTy, setAllCongTy] = useState([])
  // xử lí form thêm dịch vụ
  const onLoad = () => {
    if (rowActive) {
      dispatch(getLienHeCRM(rowActive?.idct))
    }
  }
  const handleSubmit = (value) => {
    dispatch(addLienHeCRM(value, onLoad))
    formik.resetForm()
    setOpenAddLienHe(false)
  }
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idct: null,
      nguoilienhe: '',
      dienthoailienhe: '',
      ghichu: '',
      emaillienhe: '',
      nguoitao: infoUser.idnv,
      tencongty: rowActive?.tenct
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: createLienHeSchema,
  })

  const ChangeSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  useEffect(() => {
    const getAllCongTy = async () => {
      try {
        const { data } = await https.get('CRMCongTy/GetAllCRMCongTyForSelectOption')
        setAllCongTy(data.map((item) => ({ value: item.idct, label: item.tenct })))
      } catch (error) {
        console.log(error)
      }
    }
    getAllCongTy()
  }, [])

  return (
    <>
      <Modal
        okText="Thêm"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Tạo liên hệ</h2>}
        onOk={formik.handleSubmit}
        open={openAddLienHe}
        onCancel={() => setOpenAddLienHe(false)}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">Công ty</label>
            <Select
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              defaultValue={formik.values.idct}
              options={allCongTy?.map(({ value, label }) => ({
                label: label,
                value: value,
              }))}
              className="w-full"
              onChange={ChangeSelected('idct')}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">
              {' '}
              <span className="text-red-500">(*)</span>Người liên hệ
            </label>
            <Input
              value={formik.values.nguoilienhe}
              name="nguoilienhe"
              onChangeCapture={formik.handleChange}
              status={formik.errors.nguoilienhe && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Điện thoại liên hệ</label>
            <Input
              value={formik.values.dienthoailienhe}
              name="dienthoailienhe"
              onChangeCapture={formik.handleChange}
              status={formik.errors.dienthoailienhe && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Email</label>
            <Input
              value={formik.values.emaillienhe}
              name="emaillienhe"
              onChangeCapture={formik.handleChange}
              status={formik.errors.emaillienhe && 'error'}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Ghi chú</label>
            <Input
              value={formik.values.ghichu}
              name="ghichu"
              onChangeCapture={formik.handleChange}
              status={formik.errors.ghichu && 'error'}
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalAdd
