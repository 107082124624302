import { https } from '../apiService'

export const sieuAmService = {
  getBNPendingByBranch: (idcn) => https.get(`BenhNhanCDHA/GetBenhNhanChoCDHA?idcn=${idcn}`),
  getBNDoneBtBranch: (idcn, tuNgay, denNgay) =>
    https.get(`BenhNhanCDHA/GetBenhNhanDaCDHA?idcn=${idcn}&tuNgay=${tuNgay}&denNgay=${denNgay}`),
  postBieuMau: (form) => https.post('BieuMau/AddBieuMau', form),
  getALLBieuMau: (idloai, keyword) =>
    https.get('BieuMau/GetAllBieuMau', { params: { idloai, keyword } }),
  getInfoBn: (id, idcdha) =>
    https.get(`BenhNhanSieuAm/GetThongTinBenhNhanSieuAm?idbnttc=${id}&idbncdha=${idcdha}`),
  postBnSieuAm: (form, idcn) => https.post(`BenhNhanSieuAm/AddBenhNhanSieuAm?idcn=${idcn}`, form),
  getInfoBieuMau: (id) => https.get(`BieuMau/GetBieuMauByIDBM?idbm=${id}`),
  getHistory: (id) => https.get(`BenhNhanSieuAm/GetTLichSuBenhNhanSieuAm?idbnttc=${id}`),
  getHistoryByIdBs: (idbn, idChiNhanh, idNV) =>
    https.get(`BenhNhanSieuAm/GetBenhNhanSieuAmByIDBNCheckBacSi`, {
      params: { idbn, idChiNhanh, idNV },
    }),
  getInfoBnPending: (id, idcdha) =>
    https.get(`/BenhNhanCDHA/GetThongTinBenhNhanCDHA/${id}?idbncdha=${idcdha}`),
}
