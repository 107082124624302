import React, { useCallback, useEffect, useState } from 'react'
import { Table, Modal, Input, ConfigProvider, Button, Checkbox } from 'antd'
import CachedIcon from '@mui/icons-material/Cached'
import _ from 'lodash'
import {
  SearchOutlined,
  PlusOutlined,
  FileExcelOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteLoiDanBs,
  fetchLoiDanBsDanhMuc,
  getInfoLoiDanById,
} from '../../../store/actions/loiDanBsAction'
// import CreateNew from './CreateNew'
// import UpdateLoiDan from './UpdateLoiDan'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import { addLog } from '../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../utils/log/logConstant'
import {
  deleteNgayNghi,
  fetchNgayNghi,
  getInfoNgayNghiById,
} from '../../../store/actions/ngayNghiAction'
import { format } from 'date-fns'
import moment from 'moment'
import CreateNew from './CreateNew'
import UpdateNgayNghi from './UpdateNgayNghi'
import { getIn } from 'formik'
const { confirm } = Modal

const NgayNghi = () => {
  const dispatch = useDispatch()
  const { NgayNghis } = useSelector((state) => state.NgayNghiReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isOpen, setIsOpen] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [search, setSearch] = useState('')

  const reloadData = () => {
    dispatch(fetchNgayNghi())
  }
  const onClickDelete = (record) => {
    dispatch(deleteNgayNghi(record, reloadData))
  }
  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Bạn có chắc muốn xoá lời dặn này ?',
      icon: <ExclamationCircleFilled />,
      content: record.tennn,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        onClickDelete(record)
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  }
  const onClickInfoLoiDan = (info) => {
    setIsUpdate(true)
    dispatch(getInfoLoiDanById(info))
  }
  const exportToExcel = () => {
    try {
      const data = NgayNghis?.map((item, index) => {
        return {
          STT: index + 1,
          'Mã lời dặn': item.maLoiDan,
          'Lời Dặn': item.loiDan,
          'Ghi chú': item.ghiChu,
          'Sử dụng': item.suDung === 1 ? 'TRUE' : 'FALSE',
        }
      })
      const name = 'Lời dặn bác sĩ'
      data?.length && exportExcelformat(data, name)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (infoUser) {
      reloadData()
    }
  }, [search, infoUser])
  return (
    <>
      <div className="bg-[#EFEFEF] h-full p-3 ">
        <div className="bg-white h-full border rounded-xl  p-2">
          <div className="mb-4 flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col gap-2 sm:flex-row sm:items-center">
              <Button type="primary" icon={<CachedIcon />} onClick={reloadData}>
                Tải lại
              </Button>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsOpen(true)}>
                Tạo mới
              </Button>
            </div>
          </div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
            }}
          >
            <Table
              bordered
              loading={isLoadingTable}
              dataSource={NgayNghis}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'STT',
                  key: 1,
                  width: 60,
                  align: 'center',
                  render: (text, _, index) => ++index,
                },
                {
                  title: 'Mã ngày',
                  dataIndex: 'mann',
                  key: 2,
                  width: 100,
                },
                {
                  title: 'Tên ngày',
                  dataIndex: 'tennn',
                  key: 2,
                  width: 300,
                },
                {
                  title: 'Từ ngày',
                  dataIndex: 'tungay',
                  key: 2,
                  width: 100,
                  render: (text) => text && moment(text).format('DD/MM/YYYY'),
                },
                {
                  title: 'Đến ngày',
                  dataIndex: 'denngay',
                  key: 2,
                  width: 100,
                  render: (text) => text && moment(text).format('DD/MM/YYYY'),
                },
                {
                  title: 'Ghi chú',
                  dataIndex: 'ghichu',
                  key: 2,
                  width: 200,
                },
                {
                  title: 'Số ngày',
                  dataIndex: 'songaynghi',
                  key: 3,
                  width: 80,
                  align: 'center',
                },
                {
                  title: 'Người tạo',
                  dataIndex: 'nguoitao',
                  key: 4,
                },
                {
                  title: 'Ngày tạo',
                  dataIndex: 'ngaytao',
                  key: 7,
                  render: (text) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
                },
                {
                  title: 'Người sửa',
                  dataIndex: 'tennguoisua',
                  key: 'tennguoisua',
                },
                {
                  title: 'Ngày sửa',
                  dataIndex: 'ngaysua',
                  key: 'ngaysua',
                  render: (text) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
                },
                {
                  title: 'Sử dụng',
                  dataIndex: 'sudung',
                  key: 5,
                  width: 90,
                  align: 'center',
                  fixed: 'right',
                  render: (text) => <Checkbox checked={text === 1} />,
                },
                {
                  title: 'Hành động',
                  dataIndex: 'action',
                  key: 6,
                  width: 90,
                  fixed: 'right',
                  align: 'center',
                  render: (_, record) => (
                    <ul className="flex gap-3 justify-center">
                      <li
                        onClick={() => showDeleteConfirm(record)}
                        className="text-red-500 border bg-red-100 rounded hover:bg-red-500 cursor-pointer hover:text-white duration-200"
                      >
                        <DeleteOutlined className="p-1" style={{ fontSize: 16 }} />
                      </li>
                      <li
                        onClick={() => {
                          dispatch(getInfoNgayNghiById(record.idngaynghi))
                          setIsUpdate(true)
                        }}
                        className="text-green-700 border bg-green-100 rounded hover:bg-green-700 cursor-pointer hover:text-white duration-200"
                      >
                        <EditOutlined className="p-1" style={{ fontSize: 16 }} />
                      </li>
                    </ul>
                  ),
                },
              ]}
              // columns={columns}
              rowKey="id"
              scroll={{ x: 1800, y: '78vh' }}
              pagination={false}
            />
          </ConfigProvider>
        </div>
      </div>
      <CreateNew isOpen={isOpen} setIsOpen={setIsOpen} reloadData={reloadData} />
      <UpdateNgayNghi isUpdate={isUpdate} setIsUpdate={setIsUpdate} reloadData={reloadData} />
    </>
  )
}

export default NgayNghi
