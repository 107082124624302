import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  infoUser: null,
  username: null,
  idDefaultChiNhanh: null,
  menu: null,
  listUser: null, // []
  
}

const userReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_INFO_LOGIN:
        draft.infoUser = payload
        break
      case typeAction.DISPATCH_LIST_USER:
        draft.listUser = payload
        break
      case typeAction.DISPATCH_USERNAME:
        draft.username = payload
        break
      case typeAction.DISPATCH_DEFAULT_BRANCH:
        draft.idDefaultChiNhanh = payload
        break
      case typeAction.DISPATCH_MENU:
        draft.menu = payload
        break
      default:
        return state
    }
  })
}

export default userReducer
