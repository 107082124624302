import React, { useEffect } from 'react'
import { Modal, Button, Input, Form, Switch } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { DonViTinhService } from '../../../../services/DonViTinh/DonViTinhService'
import { updateDonViTinh } from '../../../../store/actions/donvitinhAction'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import TextArea from 'antd/lib/input/TextArea' // Ensure correct import
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'

const ModalUpdateDonvitinh = ({ isOpen, onRequestClose, ma, onUpdateSuccess }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)

  const maQuyenSuaDvt = 'QHT400'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaDvt:
        formik.handleSubmit()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenSuaDvt:
        //
        break
      default:
        return false
    }
  }
  const resetForm = () => {
    onRequestClose()
    onUpdateSuccess() // Call the callback to refresh data
  }
  const formik = useFormik({
    initialValues: {
      donViTinh: '',
      ghichu: '',
      suDung: 0, // Add initial value for suDung
    },
    validationSchema: Yup.object({
      donViTinh: Yup.string().required('Đơn vị tính không được bỏ trống'),
      suDung: Yup.number()
        .min(0, 'Sử dụng phải là số dương lớn hơn hoặc bằng 0')
        .required('Sử dụng không được bỏ trống')
        .integer('Sử dụng phải là số nguyên'), // Add integer validation for suDung
    }),
    onSubmit: (values) => {
      dispatch(updateDonViTinh(ma, values, resetForm))
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      if (ma) {
        try {
          const response = await DonViTinhService.GetByMaDonViTinh(ma)
          formik.setValues({
            donViTinh: response.data.donViTinh,
            ghichu: response.data.ghichu,
            suDung: response.data.suDung, // Set value for suDung
          })
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            +localStorage.getItem('BRANH_LOGIN'),
            actionStatus.GET,
            logAction.GET_CHI_TIET_DVT,
            true,
            MENU_CONSTANTS.DON_VI_TINH,
            null,
            {
              maDVT: ma,
            },
          )
        } catch (error) {
          console.error('Failed to fetch data:', error)
          const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
          const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            +localStorage.getItem('BRANH_LOGIN'),
            actionStatus.GET,
            null,
            false,
            MENU_CONSTANTS.DON_VI_TINH,
            detailErrorPayload,
          )
        }
      }
    }
    if (infoUser) {
      fetchData()
    }
  }, [ma, infoUser])

  return (
    <Modal
      title="Cập nhật đơn vị tính"
      visible={isOpen}
      onCancel={onRequestClose}
      footer={[
        <Button key="back" onClick={onRequestClose}>
          Quay lại
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() =>
            dispatch(putCheckQuyenThaoTacAction(maQuyenSuaDvt, null, actionIsTrue, actionIsFalse))
          }
        >
          Cập nhật
        </Button>,
      ]}
    >
      <Form layout="vertical" onFinish={formik.handleSubmit}>
        <Form.Item
          required
          label="Đơn vị tính"
          validateStatus={formik.errors.donViTinh && formik.touched.donViTinh ? 'error' : ''}
          help={formik.errors.donViTinh && formik.touched.donViTinh ? formik.errors.donViTinh : ''}
        >
          <Input
            type="text"
            id="donViTinh"
            name="donViTinh"
            value={formik.values.donViTinh}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <Form.Item
          required
          label="Sử dụng"
          validateStatus={formik.errors.suDung && formik.touched.suDung ? 'error' : ''}
          help={formik.errors.suDung && formik.touched.suDung ? formik.errors.suDung : ''}
        >
          <Switch
            checked={formik.values.suDung === 1}
            onChange={(checked) => formik.setFieldValue('suDung', checked ? 1 : 0)}
            style={{ backgroundColor: formik.values.suDung === 1 ? '#4caf50' : '#C0C0C0' }} // Inline style
          />
        </Form.Item>
        <Form.Item label="Ghi chú">
          <TextArea
            id="ghichu"
            name="ghichu"
            value={formik.values.ghichu}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalUpdateDonvitinh
