import { Modal, Form, Input, Button } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { createAccountSMS } from '../../../../../store/actions/smsAction'

const CreateAccount = ({ open, setOpen }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const onFinish = (values) => {
    dispatch(createAccountSMS(values, onReset))
  }
  const onReset = () => {
    setOpen(false)
    form.resetFields()
  }
  return (
    <Modal open={open} onCancel={() => setOpen(false)} title="Tạo tài khoản SMS" footer={null}>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          name={'taikhoan'}
          required
          label="Tài khoản"
          rules={[{ required: true, message: 'Vui lòng nhập tài khoản!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={'matkhau'}
          required
          label="Mật khẩu"
          rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={'brandname'}
          required
          label="BrandName"
          rules={[{ required: true, message: 'Vui lòng nhập BrandName!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Ghi chú" name={'ghichu'}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Tạo tài khoản
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateAccount
