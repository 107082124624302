import moment from 'moment'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import * as typeAction from '../constants/constants'
import { smsService } from '../../services/sms/smsService'
import 'moment/locale/vi'
import { addLog } from '../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../utils/log/logConstant'
import { fetchAccountSMS, getList_Template_SMS } from '../actions/smsAction'
import ToastCus from '../../utils/Toast'
moment.locale('vi')

export function* smsSaga() {
  //GET template
  yield takeLatest(typeAction.GET_LIST_TEMPLATE_SMS, function* getBNByIdChiNhanhIdCty({ type }) {
    yield put({
      type: typeAction.OPEN_LOADING_PAGE,
    })
    try {
      const { data } = yield call(() => smsService.getTemplate())
      yield put({
        type: typeAction.DISPATCH_LIST_TEMPLATE_SMS,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  //---------------- GET INFO SMS TEMPLATE ---------------
  yield takeLatest(typeAction.GET_INFO_TEMPLATE_SMS_BY_ID, function* getInfoSmsByID({ type, id }) {
    try {
      const { data } = yield call(() => smsService.getInfoSms(id))
      yield put({
        type: typeAction.DISPATCH_INFO_TEMPLATE_SMS,
        payload: data.result,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //----------------- GET ACCOUNTS SEND SMS -----------
  yield takeLatest(typeAction.GET_ACCOUNTS_SMS, function* getAccountsSMS({ type }) {
    try {
      const { data } = yield call(() => smsService.getAccountsSMS())
      yield put({
        type: typeAction.DISPATCH_ACCOUNT_SMS,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //----------------- UPDATE TEMPLATE SMS ---------------
  yield takeLatest(typeAction.UPDATE_TEMPLATE_SMS, function* putTemplateSMS({ type, form }) {
    try {
      yield call(() => smsService.putTemplateSMS(form))
      yield put(getList_Template_SMS()) // callback API template after post template
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật template thành công!',
      })
    } catch (err) {
      yield console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: 'Cập nhật template thất bại!',
      })
    }
  })
  //---------------- DELETE TEMPLATE SMS ---------------
  yield takeLatest(typeAction.DELETE_TEMPLATE_SMS, function* deleteTemplateSMS({ type, id }) {
    try {
      yield call(() => smsService.deleteTemplateSMS(id))
      yield put(getList_Template_SMS()) // callback API template after post template
      ToastCus.fire({
        icon: 'success',
        title: 'Xóa template thành công!',
      })
    } catch (err) {
      yield console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: 'Xóa template thất bại!',
      })
    }
  })
  //---------------- ADD NEW TEMPLATE SMS ---------------
  yield takeLatest(
    typeAction.POST_TEMPLATE_SMS,
    function* postTemplateSMS({ type, form, onReset }) {
      try {
        yield call(() => smsService.postTemPlateSMS(form))
        onReset() // reset form create template
        yield put(getList_Template_SMS()) // callback API template after post template
        ToastCus.fire({
          icon: 'success',
          title: 'Tạo template thành công!',
        })
      } catch (err) {
        yield console.log(err)
        ToastCus.fire({
          icon: 'error',
          title: 'Tạo template thất bại!',
        })
      }
    },
  )
  yield takeLatest(
    typeAction.GET_BN_SMS,
    function* getBNByIdChiNhanhIdCty({
      type,
      chuyenKhoa,
      numberTP,
      numberTTV,
      ageGroup,
      setLoadingBN,
      filterName,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        setLoadingBN(true)
        const { data } = yield call(() =>
          smsService.getBNSms(chuyenKhoa, numberTP, numberTTV, ageGroup),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_BN_SMS,
          payload: data?.map((item, index) => ({ ...item, stt: ++index, guisms: 0 })),
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_BN_GUI_SMS,
          true,
          MENU_CONSTANTS.GUI_SMS,
          null,
          {
            nameChuyenKhoa: filterName.nameChuyenKhoa,
            nameTinhThanh: filterName.nameTinhThanh,
            nameTTV: filterName.nameTTV,
            nameDoTuoi: filterName.nameDoTuoi,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.GUI_SMS,
          detailErrorPayload,
        )
      } finally {
        setLoadingBN(false)
      }
    },
  )
  //----------------- GET ACCOUNTS SEND SMS -----------
  yield takeLatest(typeAction.CREATE_ACCOUNT_SMS, function* createSMS({ type, form, onReset }) {
    try {
      yield call(() => smsService.createAccountSMS(form))
      onReset() // reset form create account
      yield put(fetchAccountSMS())
      ToastCus.fire({
        icon: 'success',
        title: 'Tạo tài khoản sms thành công!',
      })
    } catch (err) {
      yield console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: 'Tạo tài khoản sms thất bại!',
      })
    }
  })
  //----------------- DELETE ACCOUNT SMS ---------------
  yield takeLatest(typeAction.DELETE_ACCOUNT_SMS, function* deleteAccountSMS({ type, id }) {
    try {
      yield call(() => smsService.deleteAccountSMS(id))
      yield put(fetchAccountSMS())
      ToastCus.fire({
        icon: 'success',
        title: 'Xóa tài khoản thành công!',
      })
    } catch (err) {
      yield console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: 'Xóa tài khoản thất bại!',
      })
    }
  })
  //----------------- UPDATE ACCOUNT SMS ---------------
  yield takeLatest(typeAction.UPDATE_ACCOUNT_SMS, function* updateAccountSMS({ type, form }) {
    try {
      yield call(() => smsService.updateAccountSMS(form))
      yield put(fetchAccountSMS())
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật tài khoản thành công!',
      })
    } catch (err) {
      yield console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: 'Cập nhật tài khoản thất bại!',
      })
    }
  })
}
