import { Button, Input, Select, Tag, Result, Modal, Popconfirm } from 'antd'
import React, { useEffect, useState, useCallback } from 'react'
import CreateNhan from './CreateNhan'
import { ContainerOutlined } from '@ant-design/icons'
import { suCoService } from '../../../services/suCoService/suCoServvice'
import { useDispatch, useSelector } from 'react-redux'
import * as typeAction from '../../../store/constants/constants'
import { ExclamationCircleFilled } from '@ant-design/icons'
import {
  deleteDanhMucNhan,
  deleteNhanSuCo,
  getAllNhanSuCo,
  getDanhMucByNhan,
  getInfoNhanSuCo,
} from '../../../store/actions/suCoAction'
import moment from 'moment'
import CreateDanhMuc from './CreateDanhMuc'
import EditNhan from './EditNhan'
import _, { values } from 'lodash'
import EditDanhMuc from './EditDanhMuc'
const { confirm } = Modal
const NhanSuCo = () => {
  const [openAdd, setOpenAdd] = useState(false)
  const [openAdDM, setOpenAddDM] = useState(false)
  const [isEditNhan, setIsEditNhan] = useState(false)
  const [isEditDanhMuc, setIsEditDanhMuc] = useState(false)
  const [idDanhMuc, setIDDanhMuc] = useState(null)
  const [active, setActive] = useState(null)
  const [keywordNhan, setKeywordNhan] = useState('')
  const [keywordDanhMuc, setkeywordDanhMuc] = useState('')
  const dispatch = useDispatch()
  const [group, setGroups] = useState('')
  const { groupNhan, Nhans, danhMucNhan } = useSelector((state) => state.suCoReducer)
  const debounceDropDownNhan = useCallback(
    _.debounce((id, e) => {
      dispatch(getAllNhanSuCo(id, e.target.value))
    }, 300),
    [],
  )
  const debounceDropDownDanhMuc = useCallback(
    _.debounce((id, e) => {
      dispatch(getDanhMucByNhan(id, e.target.value))
    }, 300),
    [],
  )
  //---------- lấy danh sách nhóm nhãn sự cố  ---------//
  const fetchDanhMucNhan = () => {
    dispatch(getDanhMucByNhan(active, keywordDanhMuc))
  }
  const fetchGroupsNhanSuCo = async () => {
    try {
      const { data } = await suCoService.getAllGroupsNhan()
      dispatch({
        type: typeAction.DISPATCH_GROUPS_NHAN_SU_CO,
        payload: data,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const showDeleteConfirm = (name, id) => {
    confirm({
      title: 'Xoá danh mục nhãn ?',
      icon: <ExclamationCircleFilled />,
      content: `Bạn có muốn xoá danh mục ${name}`,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ ',
      onOk() {
        dispatch(deleteDanhMucNhan(name, id, fetchDanhMucNhan))
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  const showEditNhan = (id) => {
    setIsEditNhan(true)
    dispatch(getInfoNhanSuCo(id))
  }
  useEffect(() => {
    fetchGroupsNhanSuCo()
    dispatch(getAllNhanSuCo(group, keywordNhan))
  }, [])
  useEffect(() => {
    if (Nhans.length !== 0) {
      dispatch(getDanhMucByNhan(Nhans[0].idnhan, keywordDanhMuc))
      setActive(Nhans[0].idnhan)
    }
  }, [Nhans])

  const handleCancel = () => {
    setIsEditNhan(false)
    dispatch(getAllNhanSuCo(group, keywordNhan))
  }
  const confirmDelete = (id, tennhan) => {
    dispatch(deleteNhanSuCo(id, tennhan, handleCancel))
  }

  return (
    <>
      <div className="p-3 bg-[#EFEFEF] h-full">
        <div className="p-3 bg-white h-full rounded-xl border">
          <div className="border h-full rounded-lg flex ">
            <div className="w-1/3 border-r p-2 h-full ">
              <div className="flex gap-2">
                <div className="flex gap-2 w-full">
                  <Input.Search
                    placeholder="Nhập từ khoá"
                    onChange={(e) => {
                      debounceDropDownNhan(group, e)
                      setKeywordNhan(e.target.value)
                    }}
                  />
                  <Select
                    variant="filled"
                    className="w-32"
                    value={group}
                    onChange={(value) => {
                      setGroups(value)
                      dispatch(getAllNhanSuCo(value, keywordNhan))
                    }}
                    options={[
                      { label: 'Tất cả', value: '' },
                      ...groupNhan?.map((item) => ({
                        label: item.tennhom,
                        value: item.idnhom,
                      })),
                    ]}
                  />
                </div>

                <Button onClick={setOpenAdd} type="primary">
                  Tạo nhãn
                </Button>
              </div>
              <div className="bg-[#EFEFEF] p-2 mt-2 rounded-md h-[95%] overflow-auto">
                <ul className="flex flex-col gap-3">
                  {Nhans.map((item) => (
                    <li
                      onClick={() => {
                        dispatch(getDanhMucByNhan(item.idnhan, keywordDanhMuc))
                        setActive(item.idnhan)
                      }}
                      key={item.idnhan}
                      className={`h-20 ${active === item.idnhan ? 'bg-blue-50 border-blue-400' : 'bg-white'} border rounded-md cursor-pointer hover:-translate-y-1 duration-150 p-2`}
                    >
                      <div className="flex justify-between ">
                        <h2 className="font-semibold  flex gap-1 items-center">
                          {item.tennhan}{' '}
                          <span
                            onClick={() => showEditNhan(item.idnhan)}
                            className="bg-green-200 text-green-700 rounded hover:bg-green-700 hover:text-white duration-200 cursor-pointer "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </span>{' '}
                          <Popconfirm
                            title="Xoá nhãn"
                            description="Bạn có chắc muốn xoá nhãn ?"
                            onConfirm={() => confirmDelete(item.idnhan, item.tennhan)}
                            okText="Xoá"
                            cancelText="bỏ"
                            placement="bottomRight"
                          >
                            <span className="flex items-center text-gray-400 font-normal bg-slate-100 p-1 rounded-md hover:text-red-500 cursor-pointer duration-150">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            </span>
                          </Popconfirm>
                        </h2>
                        <span className="text-gray-400 font-normal">
                          Ngày tạo: {moment(item.ngaytao).format('DD/MM/YYYY')}
                        </span>
                      </div>
                      <div className="flex justify-between mt-1">
                        <p className="text-gray-400">Thời gian xử lý: {item.thoigianxuly}</p>
                        <Tag
                          className="font-semibold"
                          color={
                            item.idnhom === 27 ? 'blue' : item.idnhom === 270 ? 'green' : 'cyan'
                          }
                        >
                          {item.nhom}
                        </Tag>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="w-2/3 p-2">
              <div className="flex justify-between items-center">
                <h2 className="text-green-700 font-semibold"> Số mục: {danhMucNhan.length}</h2>
                <div className="flex gap-2 justify-end">
                  <Input.Search
                    className="w-72"
                    placeholder="Nhập từ khoá"
                    onChange={(e) => {
                      debounceDropDownDanhMuc(active, e)
                      setkeywordDanhMuc(e.target, values)
                    }}
                  />
                  <Button onClick={() => setOpenAddDM(true)} type="primary">
                    Tạo mục
                  </Button>
                </div>
              </div>
              <div className="bg-[#EFEFEF] p-2 mt-2 rounded-md h-[95%] overflow-auto">
                {danhMucNhan.length !== 0 ? (
                  <ul className="flex flex-col gap-3">
                    {danhMucNhan.map((item) => (
                      <li
                        key={item.key}
                        className="bg-white rounded-md cursor-pointer hover:-translate-y-1 duration-150 p-2 flex flex-col"
                      >
                        <div className="flex flex-col sm:flex-row justify-between">
                          <h2 className="font-semibold break-words">{item.tendanhmuc}</h2>
                        </div>
                        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-2">
                          <p className="text-gray-400 text-sm">
                            Người tạo: <span className="font-semibold">{item.nguoitao}</span> - Ngày
                            tạo: {moment(item.ngaytao).format('DD/MM/YYYY')}
                          </p>
                          <div className="flex gap-2 items-center mt-2 sm:mt-0">
                            <button
                              onClick={() => {
                                setIsEditDanhMuc(true)
                                setIDDanhMuc(item)
                              }}
                              className="text-green-700 bg-green-200 hover:text-white hover:bg-green-700 duration-200 p-1 flex items-center justify-center rounded-md w-8 h-8"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                />
                              </svg>
                            </button>
                            <button
                              onClick={() => showDeleteConfirm(item.tendanhmuc, item.idnhandm)}
                              className="text-red-700 bg-red-200 hover:text-white hover:bg-red-700 duration-200 p-1 flex items-center justify-center rounded-md w-8 h-8"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Result
                    icon={<ContainerOutlined />}
                    title="Chưa có danh mục nào trong nhãn!"
                    extra={
                      <Button onClick={() => setOpenAddDM(true)} type="primary">
                        Thêm danh mục
                      </Button>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateNhan
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
        group={group}
        keywordNhan={keywordNhan}
      />
      <CreateDanhMuc
        openAdDM={openAdDM}
        setOpenAddDM={setOpenAddDM}
        active={active}
        keywordDanhMuc={keywordDanhMuc}
      />
      <EditNhan isEditNhan={isEditNhan} setIsEditNhan={setIsEditNhan} />
      <EditDanhMuc
        isEditDanhMuc={isEditDanhMuc}
        setIsEditDanhMuc={setIsEditDanhMuc}
        idDanhMuc={idDanhMuc}
        group={group}
        keywordNhan={keywordNhan}
      />
    </>
  )
}

export default NhanSuCo
