import React, { useEffect, useState } from 'react'
import { Table, Button, Input, Space, Modal, notification, Tag } from 'antd'
import {
  ContainerOutlined,
  DeleteOutlined,
  SearchOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import { https } from '../../../services/apiService'
import ModalAddDonvitinh from './Modal/ModalAddDonvitinh'
import ModalUpdateDonvitinh from './Modal/ModalUpdateDonvitinh'
import { DonViTinhService } from '../../../services/DonViTinh/DonViTinhService'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import { useDispatch, useSelector } from 'react-redux' // Added import statement
import { addLog } from '../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../utils/log/logConstant'

function DonViTinh(props) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [selectedMa, setSelectedMa] = useState(null)

  const maQuyenXemDs = 'QHT398'
  const maQuyenXemCt = 'QHT399'
  const maQuyenThemDvt = 'QHT397'
  const maQuyenXoaDvt = 'QHT401'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        fetchData()
        break
      case maQuyenXemCt:
        openUpdateModal(infoNew?.ma)
        break
      case maQuyenThemDvt:
        openModal()
        break
      case maQuyenXoaDvt:
        confirmDelete(infoNew?.ma, infoNew?.donViTinh)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXemCt:
        closeUpdateModal()
        break
      case maQuyenThemDvt:
        closeModal()
        break
      case maQuyenXoaDvt:
        //
        break
      default:
        return false
    }
  }

  const fetchData = async () => {
    try {
      const response = await https.get('/DonViTinh/getAll')
      setData(response.data)
      setFilteredData(
        response?.data?.filter(
          (item) => item?.donViTinh && item?.donViTinh?.toLowerCase()?.includes(searchText),
        ),
      )
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_DS_DVT,
        true,
        MENU_CONSTANTS.DON_VI_TINH,
        null,
        {},
      )
    } catch (error) {
      console.error('Failed to fetch data:', error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DON_VI_TINH,
        detailErrorPayload,
      )
    }
  }
  const onLoad = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  useEffect(() => {
    if (infoUser) {
      onLoad()
    }
  }, [infoUser])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchText(value)
    const filtered = data?.filter(
      (item) => item?.donViTinh && item?.donViTinh?.toLowerCase()?.includes(value),
    )
    setFilteredData(filtered)
  }

  const handleReset = () => {
    setSearchText('')
    setFilteredData(data)
  }

  const openUpdateModal = (ma) => {
    setSelectedMa(ma)
    setIsUpdateModalOpen(true)
  }

  const handleDelete = async (ma, donViTinh) => {
    try {
      await DonViTinhService.DeleteDonViTinh(ma)
      // Refresh the data after delete
      onLoad()
      notification.success({
        message: 'Xóa thành công',
        description: `Đơn vị tính ${donViTinh} đã được xóa thành công.`,
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.DELETE,
        logAction.XOA_DVT,
        true,
        MENU_CONSTANTS.DON_VI_TINH,
        null,
        {
          maDVT: ma,
        },
      )
    } catch (error) {
      console.error('Failed to delete data:', error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.DELETE,
        null,
        false,
        MENU_CONSTANTS.DON_VI_TINH,
        detailErrorPayload,
      )
    }
  }

  const confirmDelete = (ma, donViTinh) => {
    Modal.confirm({
      title: `Bạn chắc chắn muốn xóa đơn vị tính ${donViTinh}?`,
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk: () => handleDelete(ma, donViTinh),
    })
  }

  const handleSwitchChange = async (ma, checked) => {
    try {
      await DonViTinhService.UpdateDonViTinh(ma, { suDung: checked ? 1 : 0 })
      onLoad()
      notification.success({
        message: 'Cập nhật thành công',
        description: `Đơn vị tính với mã ${ma} đã được cập nhật.`,
      })
    } catch (error) {
      console.error('Failed to update data:', error)
    }
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 10,
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã đơn vị tính',
      dataIndex: 'ma',
      key: 'ma',
      width: 20,
    },
    {
      title: 'Tên đơn vị tính',
      dataIndex: 'donViTinh',
      key: 'donViTinh',
      width: 20,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'suDung',
      key: 'suDung',
      width: 20,
      render: (text, record) => (
        <Tag color={record.suDung === 0 ? 'red' : 'green'}>
          {record.suDung === 0 ? 'Không sử dụng' : 'Sử dụng'}
        </Tag>
      ),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghichu',
      key: 'ghichu',
      width: 20,
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      width: 10,
      render: (_, record) => (
        <Space size="middle">
          <ContainerOutlined
            style={{ color: 'blue', fontSize: 18 }}
            onClick={() =>
              dispatch(
                putCheckQuyenThaoTacAction(maQuyenXemCt, record, actionIsTrue, actionIsFalse),
              )
            }
          />
          <DeleteOutlined
            style={{ color: 'red', fontSize: 18 }}
            onClick={() =>
              dispatch(
                putCheckQuyenThaoTacAction(maQuyenXoaDvt, record, actionIsTrue, actionIsFalse),
              )
            }
          />
        </Space>
      ),
    },
  ]

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false)
  }

  return (
    <div className="h-full w-full bg-[#EBEBEB] p-2 border rounded-sm">
      <div className="flex flex-col gap-5 bg-white border-dotted border-b-0 p-4">
        <div className="flex justify-between mb-4">
          <Space>
            <Input
              placeholder="Tìm kiếm tên đơn vị tính"
              value={searchText}
              onChange={handleSearch}
              style={{ width: 200 }}
            />
            <Button type="primary" icon={<ReloadOutlined />} onClick={onLoad} />
          </Space>
          <Button
            type="primary"
            // onClick={openModal}
            onClick={() =>
              dispatch(
                putCheckQuyenThaoTacAction(maQuyenThemDvt, null, actionIsTrue, actionIsFalse),
              )
            }
          >
            Thêm đơn vị tính
          </Button>
        </div>
        <Table
          bordered
          // scroll={{
          //   x: '100%',
          //   y: 'calc(100vh - 300px)',
          // }}
          columns={columns}
          dataSource={filteredData}
          pagination={false} // Disable pagination
        />
      </div>
      <ModalAddDonvitinh isOpen={isModalOpen} onRequestClose={closeModal} onAddSuccess={onLoad} />
      <ModalUpdateDonvitinh
        isOpen={isUpdateModalOpen}
        onRequestClose={closeUpdateModal}
        ma={selectedMa}
        onUpdateSuccess={onLoad}
      />
    </div>
  )
}

DonViTinh.propTypes = {}

export default DonViTinh
