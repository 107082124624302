import React, { useState, useMemo } from 'react'
import { Tabs } from 'antd'
import CapQuyen from './CapQuyen/CapQuyen'
import NhomQuyen from './NhomQuyen/NhomQuyen'
import QuyenTraCuu from './QuyenTraCuu/QuyenTraCuu'

const PhanQuyen = () => {
  const [tabQuyen, settabQuyen] = useState(1)
  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      <div
        className="h-full p-2 bg-white border rounded-xl"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <Tabs
          value={tabQuyen}
          onChange={(value) => settabQuyen(value)}
          items={useMemo(
            () => [
              {
                key: 1,
                value: 1,
                label: 'Cấp quyền',
                children: <CapQuyen tabQuyen={tabQuyen} />,
              },
              {
                key: 2,
                value: 2,
                label: 'Nhóm quyền',
                children: <NhomQuyen tabQuyen={tabQuyen} />,
              },
              // {
              //   key: 3,
              //   value: 3,
              //   label: 'Quyền tra cứu',
              //   children: <QuyenTraCuu tabQuyen={tabQuyen} />,
              // },
            ],
            [tabQuyen],
          )}
        />
      </div>
    </div>
  )
}

export default PhanQuyen
