import 'ckeditor5/ckeditor5.css'
import 'devextreme/dist/css/dx.light.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import { routesCRM } from './CRM/routes/routes'
import RouteComponent from './HOCs/AppRoute'
import NotFound from './pages/NotFound/NotFound'
import { routes } from './routes/router'
import LayoutApp from './HOCs/LayoutApp'
import Login from './pages/Login/Login'
import DsBnChoKham from './components/layout/Receive/DsBnChoKham'
import CreateQR from './components/common/CreateQR/CreateQR'
import vi_VN from 'antd/lib/locale/vi_VN'
import dayjs from 'dayjs'
import 'dayjs/locale/vi'
import { ConfigProvider } from 'antd'
dayjs.locale('vi')

function App() {
  return (
    <ConfigProvider locale={vi_VN}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LayoutApp />}>
            {[...routes, ...routesCRM]?.map(
              ({ index, path, Component, isAuth, isCheckIn, redirectPath }) => (
                <Route
                  index={index}
                  key={path}
                  path={path}
                  element={
                    <RouteComponent
                      isAuth={isAuth}
                      isCheckIn={isCheckIn}
                      Component={Component}
                      redirectPath={redirectPath}
                    />
                  }
                />
              ),
            )}
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/tiepnhan/dsbnchokham" element={<DsBnChoKham />} />
          <Route path="/QR" element={<CreateQR />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default App

// export default withApiInterceptor(App);