import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, DatePicker, Select, Table, TreeSelect } from 'antd'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { branchService } from '../../../services/branch/branchService'
import { khoVTservice } from '../../../services/khoVT/khoVTservice'
import { reportService } from '../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import { formattedNumber } from '../../../utils/formattedNumber'
import { exportExcelformatSumCol } from '../../../utils/export/exportExcelFormat'
import { phanQuyenService } from '../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../store/actions/phanQuyenAction'
import ToastCus from '../../../utils/Toast'
import _ from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'moment/locale/vi'
import 'dayjs/locale/vi'
import { addLog } from '../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../utils/log/logConstant'
moment.locale('vi')
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

function ThuocHetHan() {
  const dispatch = useDispatch()
  const now = moment()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState(['all'])
  const [dataReport, setDataReport] = useState([])
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [listKho, setListKho] = useState([])
  const [idkho, setidkho] = useState('all')
  const [chuyenKhoa, setChuyenKhoa] = useState('all')
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateFrom, setDateFrom] = useState(moment().format(momentDate))
  const [dateTo, setDateTo] = useState(moment().add(6, 'months').format(momentDate))
  // const [search, setSearch] = useState('')

  // const tongSTTT = dataReport?.reduce((tong, item) => (tong += item?.tongsotoatuantruoc), 0)
  // const tongST = dataReport?.reduce((tong, item) => (tong += item?.tongsotoa), 0)
  // const tongTLCLTongST = Math.round((((tongST - tongSTTT) * 100) / tongSTTT) * 10) / 10
  // const tongTCLTTT = dataReport?.reduce(
  //   (tong, item) => (tong += item?.tongsotoacolaythuoctuantruoc),
  //   0,
  // )
  // const tongTCLT = dataReport?.reduce((tong, item) => (tong += item?.tongsotoacolaythuoc), 0)
  // const tongTLCLTongTCLT = Math.round((((tongTCLT - tongTCLTTT) * 100) / tongTCLTTT) * 10) / 10

  const tinhSoLuongChan = (slLe, quyCach, dvLe, dvChan) => {
    const arrNum = `${slLe / quyCach}`?.split('.')?.filter((item) => item?.length),
      slchan = arrNum?.length ? +arrNum[0] : 0,
      slle = slLe - slchan * quyCach
    return `${slchan ? `${slchan} ${dvChan ?? ''}` : ''}${slle ? ` ${slle} ${dvLe ?? ''}` : ''}`
  }
  const fetchReport = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT305',
      )
      const { data } = await reportService.getListThuocHetHan(
        (value?.includes('all') || !value?.length ? branch : value)
          ?.map(
            (item) => `idcn=${value?.includes('all') || !value?.length ? item?.idChiNhanh : item}`,
          )
          ?.join('&')
          ?.toString(),
        (`${idkho}` ?? '')?.includes('all') ? null : idkho,
        dateFrom,
        dateTo,
      )
      setDataReport(data?.filter((item) => item?.soluongle > 0)) //?.map((item) => ({ ...item, soluongle: 123 })))

      const detailForLog = {
        tuNgay: dateFrom,
        denNgay: dateTo,
        tenChiNhanh:
          value[0] === 'all'
            ? 'Tất cả chi nhánh'
            : value.map((id) => branch.find((item) => item.idChiNhanh === id)?.tenChiNhanh),
        tenKho: (`${idkho}` ?? '')?.includes('all')
          ? 'Tất cả kho'
          : listKho?.find((item) => item?.idKho === idkho)?.tenKho,
      }

      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.BAO_CAO_THUOC_HET_HAN,
        true,
        MENU_CONSTANTS.THUOCHETHAN,
        null,
        detailForLog,
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.THUOCHETHAN,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }
  const getListChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setListChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const getListKho = async (idct) => {
    try {
      const { data } = await khoVTservice.getListKhoByIdCty(idct)
      setListKho(data?.result)
      setidkho('all')
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranch = async (idct, idck) => {
    try {
      setIsLoading(true)
      const { data } = await branchService.getBranchNotVP(idct)
      if (!idck || idck === 'all') setBranch(data)
      else if (idck) setBranch(data?.filter((item) => item?.idChuyenKhoa === idck))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT306',
      )

      const formatData = dataReport?.map((item, index) => ({
        'STT ': ++index,
        'Mã hàng': item?.mathuoc,
        'Tên hàng': item?.tenbietduoc,
        'Tên hoạt chất': item?.tenhoatchat,
        'Nhóm hàng': item?.tennhomthuoc,
        'Loại hàng': item?.tenphanloaithuoc,
        'Quy cách': item?.quycach,
        'Tên kho': item?.tenkho,
        'Tên chi nhánh': item?.tenchinhanh,
        'SL lẻ': formattedNumber(item?.soluongle ?? 0),
        'Đơn vị lẻ': item?.donvile,
        'SL chẵn': tinhSoLuongChan(
          item?.soluongle ?? 0,
          item?.quycachdonggoi ?? 0,
          item?.donvile,
          item?.donvichan,
        ),
        'Số lô': item?.solo,
        'Hạn dùng': item?.handung ? moment(item?.handung).format('DD/MM/YYYY') : '-',
        'Giá nhập': formattedNumber(item?.gianhap ?? 0),
        'Thành tiền': formattedNumber(item?.thanhtien ?? 0),
        'Đối tác': item?.tendoitac,
      }))
      const name = 'BÁO CÁO THUỐC HẾT HẠN'
      if (formatData?.length) exportExcelformatSumCol(formatData, name, ['SL lẻ', 'Thành tiền'])
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const onLoad = () => {
    // setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    if (!value?.length) setValue(['all'])
    fetchReport()
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    getListChuyenKhoa()
    getListKho(id_company)
    fetchBranch(id_company, chuyenKhoa)
  }, [])

  useEffect(() => {
    if (branch?.length) fetchReport()
  }, [branch])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã hàng',
      dataIndex: 'mathuoc',
      key: 'mathuoc',
      fixed: 'left',
      width: 110,
      className: 'min-w-[3rem]',
    },
    {
      title: 'Tên hàng',
      dataIndex: 'tenbietduoc',
      key: 'tenbietduoc',
      fixed: 'left',
      className: 'min-w-[3rem]',
    },
    {
      title: 'Tên hoạt chất',
      dataIndex: 'tenhoatchat',
      key: 'tenhoatchat',
      className: 'min-w-[3rem]',
    },
    {
      title: 'Nhóm hàng',
      dataIndex: 'tennhomthuoc',
      key: 'tennhomthuoc',
      className: 'min-w-[6rem]',
    },
    {
      title: 'Loại hàng',
      dataIndex: 'tenphanloaithuoc',
      key: 'tenphanloaithuoc',
      className: 'min-w-[6rem]',
    },
    {
      title: 'Quy cách',
      dataIndex: 'quycach',
      key: 'quycach',
      width: 110,
      className: 'min-w-[3rem]',
    },
    // {
    //   title: 'Tồn tối thiểu',
    //   dataIndex: 'tenviettat',
    //   key: 'tenviettat',
    // },
    {
      title: 'Tên kho',
      dataIndex: 'tenkho',
      key: 'tenkho',
      className: 'min-w-[3rem]',
    },
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      className: 'min-w-[3rem]',
    },
    // {
    //   title: 'Vị trí',
    //   dataIndex: 'tenviettat',
    //   key: 'tenviettat',
    // },
    {
      title: 'SL lẻ',
      dataIndex: 'soluongle',
      key: 'soluongle',
      align: 'center',
      width: 80,
      className: 'min-w-[3rem]',
    },
    {
      title: 'Đơn vị lẻ',
      dataIndex: 'donvile',
      key: 'donvile',
      align: 'center',
      width: 85,
      className: 'min-w-[3rem]',
    },
    {
      title: 'SL chẵn',
      dataIndex: 'slchan',
      key: 'slchan',
      className: 'min-w-[5.5rem]',
      render: (text, record, index) =>
        tinhSoLuongChan(
          record?.soluongle ?? 0,
          record?.quycachdonggoi ?? 0,
          record?.donvile,
          record?.donvichan,
        ),
      //   {
      //   const arrNum = `${record?.soluongle / record?.quycachdonggoi}`
      //       ?.split('.')
      //       ?.filter((item) => item?.length),
      //     slchan = arrNum?.length ? +arrNum[0] * record?.quycachdonggoi : 0,
      //     slle = arrNum?.length > 1 ? +arrNum[1] : 0
      //   return `${slchan ? `${slchan} ${record?.donvichan}` : ''}${slle ? ` ${slle} ${record?.donvile}` : ''}`
      // },
    },
    {
      title: 'Số lô',
      dataIndex: 'solo',
      key: 'solo',
      // width: 150,
      className: 'min-w-[5rem]',
    },
    {
      title: 'Hạn dùng',
      dataIndex: 'handung',
      key: 'handung',
      align: 'center',
      width: 100,
      className: 'min-w-[3rem]',
      render: (text, record, index) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
    },
    {
      title: 'Giá nhập',
      dataIndex: 'gianhap',
      key: 'gianhap',
      align: 'right',
      width: 100,
      className: 'min-w-[3rem]',
      render: (text, record, index) => formattedNumber(text ?? 0),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'thanhtien',
      key: 'thanhtien',
      align: 'right',
      width: 100,
      className: 'min-w-[3rem]',
      render: (text, record, index) => formattedNumber(text ?? 0),
    },
    {
      title: 'Đối tác',
      dataIndex: 'tendoitac',
      key: 'tendoitac',
      className: 'min-w-[3rem]',
    },
  ]

  return (
    <>
      <div className="p-3 h-full bg-[#EFEFEF]">
        <div
          className="h-full p-2 bg-white border rounded-xl"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="flex gap-2">
            <div className="w-[17rem] flex justify-between items-start gap-1">
              <DatePicker
                placeholder="Từ ngày..."
                allowClear={false}
                defaultValue={dayjs(moment())}
                // maxDate={dayjs(now)}
                onChange={(date, dateString) =>
                  setDateFrom(moment(dateString, dateFormat).format(momentDate))
                }
                format={dateFormat}
                locale={{ ...locale, week: { start: 1 } }}
              />
              <div className="font-semibold text-2xl flex items-start">-</div>
              <DatePicker
                placeholder="Đến ngày..."
                allowClear={false}
                defaultValue={dayjs(moment().add(6, 'months'))}
                // maxDate={dayjs(now)}
                onChange={(date, dateString) =>
                  setDateTo(moment(dateString, dateFormat).format(momentDate))
                }
                format={dateFormat}
                locale={{ ...locale, week: { start: 1 } }}
              />
            </div>
            <div className="w-[17rem]">
              <Select
                showSearch
                value={company}
                onChange={(value) => {
                  setCompany(value)
                  fetchBranch(value, chuyenKhoa)
                  getListKho(value)
                  setValue(['all'])
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={listCompany?.map(({ idct, tenct }) => ({
                  label: tenct,
                  value: idct,
                }))}
                className="w-full"
              />
            </div>
            <div className="w-[17rem]">
              <Select
                showSearch
                value={chuyenKhoa}
                onChange={(value) => {
                  setChuyenKhoa(value)
                  fetchBranch(company, value)
                  setValue(['all'])
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={[
                  {
                    label: 'Tất cả chuyên khoa',
                    value: 'all',
                  },
                  ...listChuyenKhoa?.map((item) => ({
                    label: item?.tenChuyenKhoa,
                    value: item?.idChuyenKhoa,
                  })),
                ]}
                className="w-full"
              />
            </div>
            <div className="w-[17rem] flex gap-2">
              <TreeSelect
                {...tProps}
                style={{ flexWrap: 'unset' }}
                className="w-full h-8 overflow-clip"
              />
            </div>
            <div className="w-[17rem]">
              <Select
                showSearch
                className="w-full"
                value={idkho}
                onChange={(value) => setidkho(value)}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={[
                  {
                    label: 'Tất cả các kho',
                    value: 'all',
                  },
                  ...listKho?.map((item) => ({
                    label: item?.tenKho,
                    value: item?.idKho,
                  })),
                ]}
              />
            </div>
            {/* <div className="flex w-64">
          <Input
            value={search}
            placeholder="Nhập mã hàng, tên hàng..."
            onChange={(e) => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </div> */}
            <div className="flex gap-2">
              <IconButton
                disabled={isLoading}
                onClick={onLoad}
                color="primary"
                aria-label="delete"
                size="small"
              >
                <SyncIcon />
              </IconButton>
            </div>
            <div className="font-semibold">
              <Button
                disabled={isLoading || !dataReport?.length}
                onClick={exportToExcel}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </Button>
            </div>
          </div>
          <div className="mt-2">
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                  // fontSize: 12,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                scroll={{
                  x: dataReport?.length ? 'max-content' : 2000,
                  y: '77.6vh',
                }}
                pagination={false}
                loading={isLoading}
                bordered
                dataSource={dataReport}
                columns={columns}
                summary={() => (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      {columns?.map((item, index) => (
                        <Table.Summary.Cell index={index}>
                          {item?.key === 'soluongle' && (
                            <p className="text-center">
                              {dataReport?.reduce(
                                (tong, itemTong) => (tong += itemTong[item?.key] ?? 0),
                                0,
                              )}
                            </p>
                          )}
                          {item?.key === 'thanhtien' && (
                            <p className="text-end">
                              {formattedNumber(
                                dataReport?.reduce(
                                  (tong, itemTong) => (tong += itemTong[item?.key] ?? 0),
                                  0,
                                ) ?? 0,
                              )}
                            </p>
                          )}
                        </Table.Summary.Cell>
                      ))}
                      {/* <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell> */}
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThuocHetHan
