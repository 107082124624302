import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  SaveOutlined,
  CloseOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import CachedIcon from '@mui/icons-material/Cached'
import {
  Typography,
  ConfigProvider,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Table,
  Tooltip,
  Button,
  Pagination,
} from 'antd'
import { useSearchParams } from 'react-router-dom'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {
    addVTTHXetNghiem,
    deleteVTHHByXetNghiem,
  getVTHHByXetNghiem,
  getXetNghiemByCondition,
  listGroupsXetNghiemAction,
  updateVTHHByXetNghiem,
} from '../../../../store/actions/xetNghiemAction'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import ShowNotification from '../../../common/ShowNotification/ShowNotification'
import { ListDichVuAction, listGroupsDichVuAction } from '../../../../store/actions/dichVuAction'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { searchThuocVT } from '../../../../store/actions/NhapKhoAction'
import { https } from '../../../../services/apiService'
import {
  addVTTHchanDoan,
  deleteVTHHByCDHA,
  getVTHHByCDHA,
  searchDanhMucCĐHA_Action,
  updateVTHHByCDHA,
} from '../../../../store/actions/CĐHAAction'
const { confirm } = Modal
const XetNghiem = () => {
  const dispatch = useDispatch()
  const { ListXetNghiem, groupsXetNghiem, VTTHXetNghiem } = useSelector(
    (state) => state.xetNghiemReducer,
  )
  const [useSearch, SetSearchParams] = useSearchParams({ page: 1 })
  const { contextHolder, openNotificationWithIcon } = ShowNotification()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { thuocVT } = useSelector((state) => state.NhapKhoReducer)
  const [loadingTable, setLoadingTable] = useState(false)
  const [totalPage, setTotalPage] = useState(1)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [searchGroup, setSearchGroup] = useState(1)
  const [rowActive, setRowActive] = useState(null)
  const [allDoiTacXN, setAllDoiTacXN] = useState([])
  const [keyword, setKeyword] = useState('')
  const [doitac, setDoitac] = useState('')
  const [loadingCN, setLoadingCN] = useState(false)
  const [data, setData] = useState(VTTHXetNghiem)
  const [currentPage, setCurrentPage] = useState(1)
  const [editingKey, setEditingKey] = useState('')
  const isEditing = (record) => record.key === editingKey
  const edit = (record) => {
    setEditingKey(record.key)
  }
  // handle delete danh mục xét nghiệm
  const showDeleteConfirm = (item) => {
    confirm({
      title: 'Xoá hàng vật tư tiêu hao ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <p>
          Bạn có chắc muốn xóa hàng này không:{' '}
          <span className="font-semibold">
            {item.mathuoc} - {item.tenbietduoc}
          </span>{' '}
        </p>
      ),
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ bỏ',
      onOk() {
        dispatch(deleteVTHHByXetNghiem(item))
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  }
  const onLoad = (filter) => {
    dispatch(getXetNghiemByCondition(filter))
  }
  useEffect(() => {
    dispatch(ListDichVuAction(searchGroup, company))
    dispatch(listGroupsDichVuAction())
    dispatch(listAllCompanyAction())
  }, [])

  const handleCompany = (value) => {
    setRowActive(null)
    setCompany(value)
    dispatch(ListDichVuAction(searchGroup, value))
  }

  const debounceDropDown = useCallback(
    _.debounce((keyword, company) => {
      dispatch(searchThuocVT(keyword, company))
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const handleSearchVT = (keyword) => {
    debounceDropDown(keyword, company)
  }

  const getDoiTacXN = async () => {
    try {
      const { data } = await https.get('DoiTacXetNghiem/GetAllDoiTacXetNghiem')
      setAllDoiTacXN(data)
    } catch (error) {}
  }
  const columns = useMemo(
    () => [
      {
        title: 'STT',
        dataIndex: 'STT',
        width: 40,
        align: 'center',
      },
      {
        title: 'Mã xét nghiệm',
        dataIndex: 'maxn',
        width: 150,
      },
      {
        title: 'Tên xét nghiệm',
        dataIndex: 'tenxn',
      },
      {
        title: 'Đơn giá',
        dataIndex: 'dongia',
        width: 100,
        align: 'right',
      },
      {
        title: 'Tiền gửi mẫu',
        dataIndex: 'tienguimau',
        width: 100,
        align: 'right',
      },
      {
        title: 'ĐVT',
        dataIndex: 'dvt',
        align: 'center',
        width: 80,
      },
      {
        title: 'Đối tác XN',
        dataIndex: 'tendoitac',
        align: 'left',
        width: 200,
      },
    ],
    [],
  )
  const handleChangePage = (filter, page) => {
    setCurrentPage(page)
    SetSearchParams({ page })
    onLoad({ ...filter, pageNumber: `&pageNumber=${page}` })
  }

  const handleSearch = (filter) => {
    setCurrentPage(1)
    SetSearchParams({ page: 1 })
    onLoad({ ...filter, pageNumber: `&pageNumber=1` })
  }

  const save = (key) => {
    const index = data.findIndex((item) => key === item.idxnvtth)
    dispatch(updateVTHHByXetNghiem(data[index]))
  }
  const reloadVTTHByCDHA = (id) => {
    dispatch(getVTHHByXetNghiem(id))
  }
  const handleClickRow = (row) => {
    setRowActive(row)
    reloadVTTHByCDHA(row.idxn)
  }
  const handleSelected = (value) => {
    const form = {
      idxn: rowActive?.idxn,
      idthuoc: value,
      nguoitao: infoUser?.idnv,
      soluong: 1,
    }
    if (!rowActive)
      return openNotificationWithIcon(
        'warning',
        'Thêm vật tư tiêu hao',
        'Vui lòng chọn loại chẩn đoán',
      )
    dispatch(addVTTHXetNghiem(form))
  }
  const onChangeInput = (key, name, value) => {
    const index = data.findIndex((item) => key === item.idxnvtth)
    if (index !== -1) {
      const item = { ...data[index], [name]: Number(value) }
      const newData = [...data]
      newData[index] = item
      setData(newData)
    }
  }
  const filter = useMemo(() => {
    return {
      idct: `?idct=${company}`,
      idnhomxn: searchGroup ? `&idnhomxn=${searchGroup}` : '',
      iddoitacxn: doitac ? `&idDoiTacXN=${doitac}` : '',
      keyword: keyword ? `&keyword=${keyword}` : '',
      pageNumber: `&pageNumber=${useSearch ? useSearch.get('page') : 1}`,
    }
  }, [keyword, searchGroup, company, doitac, useSearch])
  useEffect(() => {
    setTotalPage(ListXetNghiem?.totalPage ?? 1)
  }, [ListXetNghiem?.totalPage])
  const columnsCN = [
    {
      title: 'STT',
      dataIndex: 'stt',
      render: (text, _, index) => ++index,
      width: 60,
      key: 1,
    },
    {
      title: 'Mã hàng',
      dataIndex: 'mathuoc',
      key: 2,
      width: 100,
    },
    {
      title: 'Tên Hàng',
      dataIndex: 'tenbietduoc',
      key: 3,
    },
    {
      title: 'SL',
      dataIndex: 'soluong',
      key: 4,
      width: 80,
      align: 'center',
      render: (text, record, index) => (
        <>
          <InputNumber
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(value) => onChangeInput(record.key, 'soluong', value)}
            value={text}
            min={0}
            formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            size="small"
            className="w-16"
          />
        </>
      ),
    },
    {
      title: 'Đơn vị',
      dataIndex: 'dvt',
      key: 5,
      width: 80,
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      width: 90,
      key: 6,
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span className="flex justify-around">
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              <Tooltip title="Lưu" color="blue">
                <SaveOutlined style={{ fontSize: 18 }} />
              </Tooltip>
            </Typography.Link>
            <Typography.Link
              onClick={() => {
                setEditingKey('')
                setData(VTTHXetNghiem)
              }}
            >
              <Tooltip title="Huỷ">
                <CloseOutlined />
              </Tooltip>
            </Typography.Link>
          </span>
        ) : (
          <ul className="flex justify-around">
            <li>
              <Tooltip title="Chỉnh sửa" color="green">
                <EditOutlined
                  onClick={() => {
                    if (editingKey !== '') return
                    edit(record)
                  }}
                  className={`text-xl ${editingKey !== '' ? 'text-gray-500' : 'text-green-500'}  cursor-pointer`}
                />
              </Tooltip>
            </li>
            <li>
              <Tooltip title="Xoá" color="red">
                <DeleteOutlined
                  onClick={(e) => {
                    if (editingKey !== '') return
                    e.stopPropagation()
                    showDeleteConfirm(record)
                  }}
                  className={`text-xl ${editingKey !== '' ? 'text-gray-500' : 'text-red-500'}  cursor-pointer`}
                />
              </Tooltip>
            </li>
          </ul>
        )
      },
    },
  ]

  useEffect(() => {
    setData(VTTHXetNghiem)
    setEditingKey('')
  }, [VTTHXetNghiem])
  useEffect(() => {
    getDoiTacXN()
    onLoad(filter)
    dispatch(listGroupsXetNghiemAction())
    dispatch(listAllCompanyAction())
  }, [])
  return (
    <>
      <div className="flex w-full items-center gap-5  bg-white rounded-md">
        <div className="flex w-full gap-3">
          <Select
            showSearch
            className="w-64"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={handleCompany}
            value={company}
            options={[
              ...(listCompany
                ? listCompany.map((items) => ({
                    label: items.tenct,
                    value: items.idct,
                  }))
                : []),
            ]}
          />
          <Select
            onChange={(value) => {
              setSearchGroup(value)
            }}
            value={searchGroup}
            className="w-64"
            options={[
              { label: 'Tất cả các nhóm', value: '' },
              ...(groupsXetNghiem
                ? groupsXetNghiem
                    ?.map((items) => ({
                      label: items.tennhom,
                      value: items.idnhomxn,
                    }))
                    ?.sort((a, b) => (a?.label ?? '')?.localeCompare(b?.label ?? ''))
                : []),
            ]}
          />
          <Select
            value={doitac}
            options={[
              { label: 'Tất cả đối tác', value: '' },
              ...allDoiTacXN?.map((items) => ({
                label: items.tendoitac,
                value: items.iddtxn,
              })),
            ]}
            onChange={(value) => setDoitac(value)}
            className="w-44"
            placeholder="Đối tác XN"
          />
          <Input.Search
            onChange={(e) => {
              setKeyword(e.target.value)
            }}
            className="w-[17rem]"
            placeholder="Tìm kiếm Mã XN, Tên XN"
          />
          <Button
            onClick={() => handleSearch(filter)}
            className="flex items-center"
            type="primary"
            ghost
            icon={<CachedIcon />}
          >
            Tải lại
          </Button>
        </div>
      </div>
      <div className="flex pr-2 h-full border mt-2 rounded">
        <div className="w-1/2 h-full bg-white  border-r">
          <div className="p-2 h-full">
            <div>
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                    fontSize: 14,
                  },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                      headerBg: '#e6e6e6',
                      footerBg: '#e6e6e6',
                      borderColor: '#BABABA',
                    },
                  },
                }}
              >
                <Table
                  loading={loadingTable}
                  pagination={false}
                  bordered
                  scroll={{
                    x: 1200,
                    y: '68vh',
                  }}
                  onRow={(row) => {
                    return {
                      onClick: () => {
                        handleClickRow(row)
                      }, // click row
                    }
                  }}
                  columns={columns}
                  dataSource={ListXetNghiem?.data.map((item, index) => ({
                    STT: (currentPage - 1) * 20 + (index + 1),
                    dongia: formatNumberVND(item.dongia),
                    tienguimau: formatNumberVND(item.tienguimau),
                    ...item,
                  }))}
                />
              </ConfigProvider>
              <div className="flex justify-end">
                <Pagination
                  className="mt-5"
                  showSizeChanger={false}
                  current={+useSearch.get('page')}
                  simple
                  defaultCurrent={1}
                  pageSize={20}
                  total={20 * totalPage}
                  onChange={(page, pagesize) => {
                    handleChangePage(filter, page)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 h-full  p-2">
          <div className="flex items-center gap-2 mb-1">
            <span className="font-semibold w-24">Tên dịch vụ:</span>
            <Input value={rowActive?.tenxn} readOnly variant="filled" />
          </div>
          <Select
            className="w-full mb-1"
            // size="small"
            showSearch
            allowClear
            onChange={handleSelected}
            placeholder="Nhập tên vật tư hàng hoá "
            value={null}
            defaultActiveFirstOption={false}
            prefix={null}
            filterOption={false}
            onSearch={handleSearchVT}
            notFoundContent={null}
            options={(thuocVT || []).map((d, index) => ({
              key: index,
              value: d.idThuoc,
              label: (
                <u className="flex no-underline">
                  <li className="flex w-[20%]">
                    <p>{d.maThuoc}</p>
                  </li>
                  <li className="flex w-[80%]">
                    <p className=" w-full ">{d.tenBietDuoc}</p>
                  </li>
                </u>
              ),
            }))}
          />
          <div>
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                rowKey="key"
                loading={loadingCN}
                scroll={{ y: 700 }}
                // loading={isLoadingBranch}
                pagination={false}
                columns={columnsCN}
                dataSource={data?.map((item) => ({
                  ...item,
                  key: item.idxnvtth,
                }))}
                bordered
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
      {contextHolder}
    </>
  )
}

export default XetNghiem
