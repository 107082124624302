import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Button, ConfigProvider, Table, Checkbox, Input, Space } from 'antd'
import { SaveOutlined, SearchOutlined } from '@ant-design/icons'
import { nhanvienService } from '../../../../services/nhanvien/nhanvienService'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { putListPhanQuyenTraCuuAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'

const listQuyenTraCuu = [
  { idck: 1, tenquyen: 'Tra cứu bệnh nhân Nhi' },
  { idck: 2, tenquyen: 'Tra cứu bệnh nhân Sản' },
  { idck: 3, tenquyen: 'Tra cứu bệnh nhân Lão' },
  { idck: 4, tenquyen: 'Tra cứu bệnh nhân Mắt' },
]
const QuyenTraCuu = ({ tabQuyen }) => {
  const dispatch = useDispatch()
  const [infoQuyen, setinfoQuyen] = useState(null)
  const [isLoading, setisLoading] = useState(false)
  const [listNhomNd, setlistNhomNd] = useState([])
  const isChuaChonQuyen = isLoading || !infoQuyen?.idck // true = lỗi

  const searchInput = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
          className="rounded-md"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#1677ff',
            }}
            className="rounded-md"
          >
            Tìm
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch('', confirm, dataIndex)
            }}
            size="small"
            style={{
              width: 90,
            }}
            className="rounded-md"
          >
            Xoá
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        className="px-1"
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => text,
    // searchedColumn === dataIndex ? (
    //   <Highlighter
    //     highlightStyle={{
    //       backgroundColor: '#ffc069',
    //       padding: 0,
    //     }}
    //     searchWords={[searchText]}
    //     autoEscape
    //     textToHighlight={text ? text.toString() : ''}
    //   />
    // ) : (
    //   text
    // ),
  })
  const handleSubmit = () => {
    if (isChuaChonQuyen) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn quyền tra cứu!',
      })
      return
    }
    const formatData = listNhomNd?.map((item) => ({
      idnnd: item?.idNhom,
      idchuyenkhoa: infoQuyen?.idck,
      sudung: item?.checked ? 1 : 0,
    }))
    dispatch(putListPhanQuyenTraCuuAction(infoQuyen?.idck, formatData))
  }
  const handleCheckedQuyen = (idNhomNd, checked) => {
    if (isChuaChonQuyen) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn quyền tra cứu!',
      })
      return
    }
    setlistNhomNd((prev) =>
      prev?.map((item) =>
        item?.idNhom === idNhomNd ? { ...item, checked: checked ? 1 : 0 } : { ...item },
      ),
    )
  }
  const handleCheckedListQuyen = (checked) => {
    if (isChuaChonQuyen) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn quyền tra cứu!',
      })
      return
    }
    setlistNhomNd((prev) => prev?.map((item) => ({ ...item, checked: checked ? 1 : 0 })))
  }
  const getListNhomNguoiDung = async () => {
    try {
      setisLoading(true)
      const { data } = await nhanvienService.getRulerUser()
      setlistNhomNd(data)
    } catch (error) {
      console.log('getListNhomNguoiDung : ', error)
    } finally {
      setisLoading(false)
    }
  }
  const getListPhanQuyenTraCuu = async (idCk) => {
    try {
      setisLoading(true)
      const { data } = await phanQuyenService.getListPhanQuyenTraCuu(idCk)
      setlistNhomNd((prev) =>
        prev?.map((item) => ({
          ...item,
          checked: data?.find((itemQ) => itemQ?.idnnd === item?.idNhom)?.sudung ?? 0,
        })),
      )
    } catch (error) {
      console.log('getListPhanQuyenTraCuu : ', error)
    } finally {
      setisLoading(false)
    }
  }
  useEffect(() => {
    if (tabQuyen === 3) {
      getListNhomNguoiDung()
    }
  }, [tabQuyen])
  return (
    <div className="grid grid-cols-5 h-[49.4rem]">
      <div className="col-span-2 border-e pr-2">
        <div className="font-semibold py-[0.55rem]">
          {infoQuyen?.tenquyen ? (
            <div>Đang chọn: {infoQuyen?.tenquyen}</div>
          ) : (
            <div className="text-red-500">Chưa chọn quyền</div>
          )}
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            onRow={(record) => {
              return {
                onClick: () => {
                  setinfoQuyen(record)
                  getListPhanQuyenTraCuu(record?.idck)
                },
              }
            }}
            rowClassName={
              (record) => (infoQuyen && record?.idck === infoQuyen?.idck ? 'bg-sky-100' : '') //bg-[#ecf0f1]
            }
            loading={isLoading}
            scroll={{ y: 715 }}
            pagination={false}
            bordered
            dataSource={listQuyenTraCuu}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 35,
                align: 'center',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Tên quyền tra cứu',
                dataIndex: 'tenquyen',
                key: 'tenquyen',
              },
            ]}
          />
        </ConfigProvider>
      </div>
      <div className="col-span-3 pl-2">
        <div className="flex justify-end items-center mb-2">
          <Button loading={isLoading} type="primary" icon={<SaveOutlined />} onClick={handleSubmit}>
            Lưu
          </Button>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            loading={isLoading}
            scroll={{ y: 715 }}
            pagination={false}
            bordered
            dataSource={listNhomNd}
            columns={[
              {
                title: (
                  <Checkbox
                    indeterminate={
                      listNhomNd?.some((item) => item?.checked) &&
                      listNhomNd?.some((item) => !item?.checked)
                    }
                    checked={listNhomNd?.every((item) => item?.checked)}
                    onChange={(e) => handleCheckedListQuyen(e.target.checked)}
                  />
                ),
                dataIndex: 'checked',
                key: 'checked',
                width: 35,
                align: 'center',
                render: (text, record, index) => (
                  <Checkbox
                    checked={text}
                    onChange={(e) => handleCheckedQuyen(record?.idNhom, e.target.checked)}
                  />
                ),
              },
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 35,
                align: 'center',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Tên nhóm người dùng',
                dataIndex: 'tenNhom',
                key: 'tenNhom',
                ...getColumnSearchProps('tenNhom'),
              },
            ]}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default QuyenTraCuu
