import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Select } from 'antd'
import { ContentState, EditorState, Modifier, convertFromRaw, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { notification } from 'antd'
import { sieuAmService } from '../../../../services/SieuAm/sieuAmService'
import { https } from '../../../../services/apiService'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import { infoBNSieuAm as getInfoBNSieuAm } from '../../../../store/actions/sieuAmAction'
import { generatePhieuSieuAmMultiImage } from '../../../../utils/report/san/phieuSieuAmForMultiImageOnly'
import { generatePhieuSieuAm } from '../../../../utils/report/san/phieuSieuAm'
import { formatPayloadDetails, isWithin24Hours } from '../../../../utils/algorithm'
import ToastCus from '../../../../utils/Toast'
import BieuMau from './BieuMau'
import style from './style.module.css'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
import { addLog } from '../../../../utils/log/apiLog'
import { stateToHTML } from 'draft-js-export-html'
import { maternityClinicService } from '../../../../services/maternityClinic/maternityClinicService'

const Template = ({ isStatus, formik, editorState, setEditorState, listImagePrint }) => {
  const dispatch = useDispatch()
  const [api, contextHolder] = notification.useNotification()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { AllBieuMau } = useSelector((state) => state.sieuAmReducer)
  const { infoBNSieuAm } = useSelector((state) => state.sieuAmReducer)
  const [open, setOpen] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const onEditorStateChange = (newState) => {
    setEditorState(newState)

    const contentState = newState.getCurrentContent()
    const htmlContent = stateToHTML(contentState)
    const row = convertToRaw(contentState)
    console.log(htmlContent)
  }
  //--------- INFO LÂY BIỂU ------------//
  const onClickBieuMau = async (value) => {
    const { data } = await sieuAmService.getInfoBieuMau(value)
    const convertJson = JSON.parse(data.mota)
    formik.setFieldValue('KETLUAN', data.ketluan)
    formik.setFieldValue('tieude', data.tieude)
    const contentState = convertFromRaw(convertJson)
    setEditorState(EditorState.createWithContent(contentState))
  }
  const cancelModal = () => {
    setOpen(false)
  }

  const handlePrint = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT562',
      )
      listImagePrint?.length >= 3
        ? generatePhieuSieuAmMultiImage(infoBNSieuAm, listImagePrint, PkDangNhap)
        : generatePhieuSieuAm(infoBNSieuAm, listImagePrint, PkDangNhap)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    })
  }
  const handleUpdate = async () => {
    const contentState = editorState.getCurrentContent()
    const row = convertToRaw(contentState)
    const data = JSON.stringify(row)
    console.log('infoBNSieuAm----', infoBNSieuAm)
    const dataUpdate = {
      idbnttc: infoBNSieuAm?.idbnttc,
      idbncdha: infoBNSieuAm?.idbncdha,
      bschidinh: infoBNSieuAm?.bschidinh,
      bssieuam: infoBNSieuAm?.bssieuam,
      chandoansobo: infoBNSieuAm?.chandoansobo,
      loaisieuam: infoBNSieuAm?.loaisieuam,
      para: formik.values.PARA,
      ketluan: formik.values.KETLUAN,
      bieumau: data,
      tieude: formik.values.tieude,
      nguoisua: infoUser?.idnv,
      dusanh: formik.values.dusanh,
      tuanthai: formik.values.tuanthai,
      ngaythai: formik.values.ngaythai,
    }
    try {
      setisLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT561',
      )
      if (isStatus && checkRequiredFields()) {
        openNotificationWithIcon(
          'warning',
          'Lưu kết quả siêu âm',
          'Vui lòng nhập dự sanh và lúc thai',
        )
        return
      }
      if (!isStatus) {
        dataUpdate.dusanh = null
        dataUpdate.tuanthai = null
        dataUpdate.ngaythai = null
      }
      const formBNTTC = {
        ngaythai: dataUpdate.ngaythai,
        tuanthai: dataUpdate.tuanthai,
        dusanh: dataUpdate.dusanh,
        idbnttc: dataUpdate.idbnttc,
      }

      await https.put('BenhNhanSieuAm/UpdateBenhNhanSieuAm', dataUpdate)
      await maternityClinicService.updateDetailBN(formBNTTC)
      dispatch(getInfoBNSieuAm(infoBNSieuAm.idbnttc, infoBNSieuAm.idbncdha))
      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công',
      })
      const detailErrorPayload = {
        tenbenhnhan: infoBNSieuAm?.tenbenhnhan,
      }

      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.CAP_NHAT_BENH_NHAN_SIEU_AM,
        true,
        MENU_CONSTANTS.SIEU_AM,
        null,
        detailErrorPayload,
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(dataUpdate)}\n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setisLoading(false)
    }
  }
  const checkRequiredFields = () => {
    const requiredFields = [formik.values.dusanh, formik.values.tuanthai, formik.values.ngaythai]
    return requiredFields.some((field) => field === null)
  }
  //handle case shift + enter (disable event)
  const handleReturn = (event, editorState) => {
    const contentState = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    const newContentState = Modifier.splitBlock(contentState, selection)
    const newEditorState = EditorState.push(editorState, newContentState, 'split-block')

    setEditorState(newEditorState)
    return 'handled'
  }

  const handlePastedText = (text, html, editorState) => {
    // Convert pasted text to plain text blocks
    const blocksFromHTML = ContentState.createFromText(text)
    const newContentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      blocksFromHTML.getBlockMap(),
    )

    const newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment')

    setEditorState(newEditorState)
    return true // Returning true prevents default paste behavior
  }

  return (
    <>
      {contextHolder}
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="p-2 border-b flex justify-between items-center">
            <div>
              <Button
                type="primary"
                disabled={infoBNSieuAm?.idttsieuam === 1 || !infoBNSieuAm}
                onClick={formik.handleSubmit}
              >
                Lưu kết quả
              </Button>
              {/* <Button type="link" icon={<PrinterOutlined style={{ fontSize: 18 }} />}>
              Phiếu
            </Button> */}
              {infoBNSieuAm &&
                infoBNSieuAm?.idttsieuam === 1 &&
                infoBNSieuAm?.nguoisua === infoUser?.idnv && // người tạo lần đầu tiên (có id người sửa, không có ngày sửa) để cập nhật dựa trên đúng idnv mới dc cập nhật
                isWithin24Hours(infoBNSieuAm?.ngaysieuam) && (
                  <Button
                    loading={isLoading}
                    type="primary"
                    className="ml-4"
                    onClick={handleUpdate}
                  >
                    Cập nhật
                  </Button>
                )}
              {infoBNSieuAm?.idttsieuam === 1 && infoBNSieuAm && (
                <Button type="primary" className="ml-4" onClick={handlePrint}>
                  In
                </Button>
              )}
            </div>
            <div className="flex items-center gap-1">
              <label className="font-semibold">Biểu mẫu: </label>
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={onClickBieuMau}
                className="w-72"
                options={AllBieuMau?.map((item) => ({
                  label: item.tenbieumau,
                  value: item.idbm,
                }))}
              />
              {/* {infoUser?.tenNhom === 'Quản trị hệ thống' && (
              <PlusCircleOutlined
                onClick={() => setOpen(true)}
                className="cursor-pointer text-blue-500"
                style={{ fontSize: 20 }}
              />
            )} */}
            </div>
          </div>
          <div>
            <Editor
              toolbar={{
                options: ['inline', 'blockType', 'fontFamily', 'textAlign', 'colorPicker'],
                inline: {
                  options: ['bold', 'italic'],
                },
                blockType: {
                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                },
                fontFamily: {
                  options: ['Times New Roman'],
                },
              }}
              editorState={editorState}
              toolbarClassName={style.toolbarClassName}
              wrapperClassName={style.wrapperClassName}
              editorClassName={style.editorClassName}
              onEditorStateChange={onEditorStateChange}
              handleReturn={handleReturn}
              handlePastedText={handlePastedText}
            />
          </div>
        </div>

        <div className="p-2 border-t">
          <h2 className="font-semibold">KẾT LUẬN</h2>
          <Input.TextArea
            style={{
              height: 120,
              resize: 'none',
              fontFamily: 'Time New Roman',
            }}
            className="font-bold text-[1rem]"
            name="KETLUAN"
            onChange={formik.handleChange}
            value={formik.values.KETLUAN}
          />
        </div>
        {/* <BieuMau cancelModal={cancelModal} open={open} /> */}
      </div>
    </>
  )
}

export default Template
