import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { doKhucXaService } from '../../services/doKhucXa/doKhucXaService'
import { childrenClinicService } from '../../services/childrenClinic/childrenClinicService'
// import { drugstoreService } from '../../services/drugstore/drugstoreService'
// import { recieveService } from '../../services/receive/recieveService'
// import { BNChoKham, thuocKeToa } from '../actions/childrenClinicAction'
// import { generatePhieuToaThuoc } from '../../utils/report/phieuToaThuoc'
import moment from 'moment'
import { generatePhieuKhamKhucXa } from '../../utils/report/mat/phieuKhamKhucXa'
// import { generatePhieuToaThuocTA } from '../../utils/report/phieuToaThuocTA'
import 'moment/locale/vi'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { recieveService } from '../../services/receive/recieveService'
moment.locale('vi')

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* doKhucXaSaga() {
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ KHÁM MẮT TẠI CHI NHÁNH
  yield takeLatest(typeAction.GET_BN_CHO_KHAM_MAT, function* getBNChoKhamMatSaga({ type, idcnpk }) {
    try {
      const result = yield call(() => doKhucXaService.getBNChoKhamMat(idcnpk))
      yield put({
        type: typeAction.DISPATCH_BN_CHO_KHAM,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ ĐO KHÚC XẠ
  yield takeLatest(typeAction.GET_BN_CHO_DKX, function* getBNChoDoKhucXaSaga({ type, idcn }) {
    try {
      const result = yield call(() => doKhucXaService.getBNChoDoKhucXa(idcn))
      yield put({
        type: typeAction.DISPATCH_BN_CHO_DKX,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // LẤY DANH SÁCH BỆNH NHÂN ĐANG KHÁM VÀ ĐÃ ĐO KHÚC XẠ
  yield takeLatest(
    typeAction.GET_BN_DA_DKX,
    function* getBNDaDoKhucXaSaga({ type, idcn, tuNgay, denNgay }) {
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const { infoUser } = yield select((state) => state.userReducer)
      const { phongKham } = yield select((state) => state.receiveReducer)
      try {
        const tenPhongKham = phongKham?.find((pk) => pk.idcn === idcn)?.tenPhongKham || ''
        const listDaKham = yield call(() => doKhucXaService.getBNDaDoKhucXa(idcn, tuNgay, denNgay))
        yield put({
          type: typeAction.DISPATCH_BN_DA_DKX,
          payload: listDaKham.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DANH_SACH_BENH_NHAN_DANG_KHAM_VA_DA_KHAM_THEO_PHONGKHAM,
          true,
          MENU_CONSTANTS.KHAMTMTD,
          null,
          {
            tuNgay: tuNgay,
            denNgay: denNgay,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DANH_SACH_BENH_NHAN_DANG_KHAM_VA_DA_KHAM_THEO_PHONGKHAM,
          true,
          MENU_CONSTANTS.KHAMTMTD,
          null,
          detailErrorPayload,
        )
      }
    },
  )
  // LẤY THÔNG TIN CHI TIẾT BỆNH NHÂN KHÁM BỆNH
  yield takeLatest(
    typeAction.GET_INFO_BN_DKX,
    function* getInfoBNDoKhucXaSaga({ type, idbnttc, tenbenhnhan }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => doKhucXaService.getInfoBNDoKhucXa(idbnttc))
        const resultTKGK = yield call(() => doKhucXaService.getInfoBNTrongVaGongKinh(idbnttc))
        const resultBNDV = yield call(() => doKhucXaService.getListBNDichVu(idbnttc))
        const newDataTK = resultTKGK?.data?.DanhSachTrongKinhBenhNhan.map((item, index) => ({
          ...item,
          // doitacthuchien: null,
          iddttkcp: item?.tengong,
          // tenct: null,
          // tengong: null,
        }))
        const newDataGK = resultTKGK?.data?.DanhSachGongKinhBenhNhan.map((item, index) => ({
          ...item,
          ID: index,
          //ton: item?.tonkho,
          ton: null,
          tenBietDuoc: item?.tenbietduoc,
          duongDung: item?.duongdung,
          hamLuong: item?.hamluong,
          soLuong: item?.soluong,
          // bsKeDon: infoUser?.tenNV,
          //   ngayKeDon: "2024-05-23T16:39:02.286Z",
          ghiChu: item?.ghichu,
          lieuDung: item?.lieudung,
          soLanNgay: item?.solanngay,
          soLo: item?.solo,
          hanDung: item?.handung,
          soHoaDon: item?.sohoadon,
          maSoDangKy: item?.masodangky,
          giaBan: item?.dongia,
          thanhToan: item?.thanhtien,
        }))
        yield put({
          type: typeAction.DISPATCH_INFO_BN_DKX,
          payload: result.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_TRONG_KINH_BN_DKX,
          payload: newDataTK ?? [],
        })
        yield put({
          type: typeAction.DISPATCH_LIST_GONG_KINH_BN_DKX,
          payload: newDataGK ?? [],
        })
        yield put({
          type: typeAction.DISPATCH_INFO_TRONG_VA_GONG_KINH_BN_DKX,
          payload: resultTKGK.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DV_DKX,
          payload: resultBNDV.data,
        })
        const detailForLog = {
          tenBenhNhan: tenbenhnhan,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_LICH_SU_BENH_NHAN_TRONG_KINH,
          true,
          MENU_CONSTANTS.TRONG_KINH,
          null,
          detailForLog,
        )
      } catch (error) {
        console.log(error)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // LƯU THÔNG TIN ĐO KHÚC XẠ CỦA BỆNH NHÂN
  yield takeLatest(
    typeAction.POST_INFO_BN_DKX,
    function* postInfoBNDoKhucXaSaga({ type, form, listdv, idcn, tungay, denngay }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        const result = yield call(() => doKhucXaService.postInfoBNDoKhucXa(form))
        form?.nguoikham && (yield call(() => doKhucXaService.putListDVBN(listdv)))
        const listCho = yield call(() => doKhucXaService.getBNChoDoKhucXa(idcn))
        const listDa = yield call(() => doKhucXaService.getBNDaDoKhucXa(idcn, tungay, denngay))
        const resultBN = yield call(() => doKhucXaService.getInfoBN(form.idbnttc))
        yield put({
          type: typeAction.DISPATCH_BN_CHO_DKX,
          payload: listCho.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_DKX,
          payload: listDa.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_DKX,
          payload: null,
        })
        yield call(() => generatePhieuKhamKhucXa(resultBN.data, result.data, PkDangNhap))
        Toast.fire({
          icon: 'success',
          title: 'Lưu thông tin thành công!',
        })
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Lưu thông tin thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // CẬP NHẬT THÔNG TIN ĐO KHÚC XẠ CỦA BỆNH NHÂN
  yield takeLatest(
    typeAction.PUT_INFO_BN_DKX,
    function* putInfoBNDoKhucXaSaga({ type, form, listdv, idcn, tungay, denngay, resetForm }) {
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        const { action, ...other } = form
        // const detailForLog = {
        //   // tenBenhNhan: tenBenhNhan,
        //   thiluckhongkinhxA_MP: other?.thiluckhongkinhxA_MP,
        //   thiluckhongkinhxA_MT: other?.thiluckhongkinhxA_MT,
        //   thiluckhongkinhxA_2MP: other?.thiluckhongkinhxA_2MP,
        //   thiluckhongkinhgaN_MP: other?.thiluckhongkinhgaN_MP,
        //   thiluckhongkinhgaN_MT: other?.thiluckhongkinhgaN_MT,
        //   thiluckhongkinhgaN_2MP: other?.thiluckhongkinhgaN_2MP,
        //   thiluckinhloxA_MP: other?.thiluckinhloxA_MP,
        //   thiluckinhloxA_MT: other?.thiluckinhloxA_MT,
        //   thiluckinhloxA_2M: other?.thiluckinhloxA_2M,
        //   thiluckinhcuxA_MP: other?.thiluckinhcuxA_MP,
        //   thiluckinhcuxA_MT: other?.thiluckinhcuxA_MT,
        //   thiluckinhcuxA_2M: other?.thiluckinhcuxA_2M,
        //   thiluckinhcugaN_MP: other?.thiluckinhcugaN_MP,
        //   thiluckinhcugaN_MT: other?.thiluckinhcugaN_MT,
        //   thiluckinhcugaN_2M: other?.thiluckinhcugaN_2M,
        //   nhanaP_MP: other?.nhanaP_MP,
        //   nhanaP_MT: other?.nhanaP_MT,
        //   khachquankhonglietdT_MP: other?.khachquankhonglietdT_MP,
        //   khachquankhonglietdT_MT: other?.khachquankhonglietdT_MT,
        //   khachquanlietdT_MP: other?.khachquanlietdT_MP,
        //   khachquanlietdT_MT: other?.khachquanlietdT_MT,
        //   chuquaN_MP: other?.chuquaN_MP,
        //   chuquaN_MT: other?.chuquaN_MT,
        //   kinhcU_MP_CAU: other?.kinhcU_MP_CAU,
        //   kinhcU_MP_LOAN: other?.kinhcU_MP_LOAN,
        //   kinhcU_MP_TRUC: other?.kinhcU_MP_TRUC,
        //   kinhcU_MP_ADD: other?.kinhcU_MP_ADD,
        //   kinhcU_MT_CAU: other?.kinhcU_MT_CAU,
        //   kinhcU_MT_LOAN: other?.kinhcU_MT_LOAN,
        //   kinhcU_MT_TRUC: other?.kinhcU_MT_TRUC,
        //   kinhcU_MT_ADD: other?.kinhcU_MT_ADD,
        //   kinhcU_PD: other?.kinhcU_PD,
        //   kinhdieuchinH_MP_CAU: other?.kinhdieuchinH_MP_CAU,
        //   kinhdieuchinH_MP_LOAN: other?.kinhdieuchinH_MP_LOAN,
        //   kinhdieuchinH_MP_TRUC: other?.kinhdieuchinH_MP_TRUC,
        //   kinhdieuchinH_MP_TL: other?.kinhdieuchinH_MP_TL,
        //   kinhdieuchinH_MP_ADD: other?.kinhdieuchinH_MP_ADD,
        //   kinhdieuchinH_MT_CAU: other?.kinhdieuchinH_MT_CAU,
        //   kinhdieuchinH_MT_LOAN: other?.kinhdieuchinH_MT_LOAN,
        //   kinhdieuchinH_MT_TRUC: other?.kinhdieuchinH_MT_TRUC,
        //   kinhdieuchinH_MT_TL: other?.kinhdieuchinH_MT_TL,
        //   kinhdieuchinH_MT_ADD: other?.kinhdieuchinH_MT_ADD,
        //   kinhdieuchinH_PD: other?.kinhdieuchinH_PD,
        //   kinhtiepxuC_MP_CONGSUAT: other?.kinhtiepxuC_MP_CONGSUAT,
        //   kinhtiepxuC_MP_BC: other?.kinhtiepxuC_MP_BC,
        //   kinhtiepxuC_MP_DIA: other?.kinhtiepxuC_MP_DIA,
        //   kinhtiepxuC_MP_HIEUBRAND: other?.kinhtiepxuC_MP_HIEUBRAND,
        //   kinhtiepxuC_MT_CONGSUAT: other?.kinhtiepxuC_MT_CONGSUAT,
        //   kinhtiepxuC_MT_BC: other?.kinhtiepxuC_MT_BC,
        //   kinhtiepxuC_MT_DIA: other?.kinhtiepxuC_MT_DIA,
        //   kinhtiepxuC_MT_HIEUBRAND: other?.kinhtiepxuC_MT_HIEUBRAND,
        //   khamtienchaN_MP_SACGIAC: other?.khamtienchaN_MP_SACGIAC,
        //   khamtienchaN_MT_SACGIAC: other?.khamtienchaN_MT_SACGIAC,
        //   khamtienchaN_2M_SACGIAC: other?.khamtienchaN_2M_SACGIAC,
        //   khamtienchaN_MP_DONGTU: other?.khamtienchaN_MP_DONGTU,
        //   khamtienchaN_MT_DONGTU: other?.khamtienchaN_MT_DONGTU,
        //   khamtienchaN_2M_DONGTU: other?.khamtienchaN_2M_DONGTU,
        //   khamtienchaN_MP_VANNHAN: other?.khamtienchaN_MP_VANNHAN,
        //   khamtienchaN_MT_VANNHAN: other?.khamtienchaN_MT_VANNHAN,
        //   khamtienchaN_2M_VANNHAN: other?.khamtienchaN_2M_VANNHAN,
        //   khamtienchaN_MP_THITRUONG: other?.khamtienchaN_MP_THITRUONG,
        //   khamtienchaN_MT_THITRUONG: other?.khamtienchaN_MT_THITRUONG,
        //   khamtienchaN_2M_THITRUONG: other?.khamtienchaN_2M_THITRUONG,
        //   khamtienchaN_MP_TESTCHEMAT_XA: other?.khamtienchaN_MP_TESTCHEMAT_XA,
        //   khamtienchaN_MT_TESTCHEMAT_XA: other?.khamtienchaN_MT_TESTCHEMAT_XA,
        //   khamtienchaN_2M_TESTCHEMAT_XA: other?.khamtienchaN_2M_TESTCHEMAT_XA,
        //   khamtienchaN_MP_TESTCHEMAT_GAN: other?.khamtienchaN_MP_TESTCHEMAT_GAN,
        //   khamtienchaN_MT_TESTCHEMAT_GAN: other?.khamtienchaN_MT_TESTCHEMAT_GAN,
        //   khamtienchaN_2M_TESTCHEMAT_GAN: other?.khamtienchaN_2M_TESTCHEMAT_GAN,
        //   khamtienchaN_MP_BODIEUTIETAA: other?.khamtienchaN_MP_BODIEUTIETAA,
        //   khamtienchaN_MT_BODIEUTIETAA: other?.khamtienchaN_MT_BODIEUTIETAA,
        //   khamtienchaN_2M_BODIEUTIETA: other?.khamtienchaN_2M_BODIEUTIETA,
        //   khamtienchaN_CANDIEMQUYTUNPC: other?.khamtienchaN_CANDIEMQUYTUNPC,
        //   khamtienchaN_THIGIACLAPTHESTEREO: other?.khamtienchaN_THIGIACLAPTHESTEREO,
        //   nguoikham: other?.nguoikham,
        //   ngaykham: other?.ngaykham,
        //   nguoisua: other?.ngaykham,
        //   ngaysua: other?.ngaykham,
        //   tiensutaimat: other?.tiensutaimat,
        //   chandoanravieN_MT: other?.chandoanravieN_MT,
        //   chandoanravieN_MP: other?.chandoanravieN_MP,
        //   cacxetnghiemcanlam: other?.cacxetnghiemcanlam,
        //   tomtatbenhan: other?.tomtatbenhan,
        //   tienluonggan: other?.tienluonggan,
        //   tienluongxa: other?.tienluongxa,
        //   tuvangiaoducsuckhoe: other?.tuvangiaoducsuckhoe,
        //   noikhoa: other?.noikhoa,
        //   phauthuat: other?.phauthuat,
        //   thuthuatthuchien: other?.thuthuatthuchien,
        //   tinhtrangravieN_TOANTRANG: other?.tinhtrangravieN_TOANTRANG,
        //   tinhtrangravieN_MP: other?.tinhtrangravieN_MP,
        //   tinhtrangravieN_MT: other?.tinhtrangravieN_MT,
        //   khongkinH_MT: other?.khongkinH_MT,
        //   khongkinH_MP: other?.khongkinH_MP,
        //   cokinH_MT: other?.cokinH_MT,
        //   cokinH_MP: other?.cokinH_MP,
        //   hocmaT_MT: other?.hocmaT_MT,
        //   hocmaT_MP: other?.hocmaT_MP,
        //   tinhtrangnhancaU_MT: other?.tinhtrangnhancaU_MT,
        //   tinhtrangnhancaU_MP: other?.tinhtrangnhancaU_MP,
        //   vannhaN_MT: other?.vannhaN_MT,
        //   vannhaN_MP: other?.vannhaN_MP,
        //   ledaO_MT: other?.ledaO_MT,
        //   ledaO_MP: other?.ledaO_MP,
        //   mimaT_MT: other?.mimaT_MT,
        //   mimaT_MP: other?.mimaT_MP,
        //   ketmaC_MT: other?.ketmaC_MT,
        //   ketmaC_MP: other?.ketmaC_MP,
        //   giacmaC_MT: other?.giacmaC_MT,
        //   giacmaC_MP: other?.giacmaC_MP,
        //   cungmaC_MT: other?.cungmaC_MT,
        //   cungmaC_MP: other?.cungmaC_MP,
        //   hinhvemotA_MT: other?.hinhvemotA_MT,
        //   hinhvemotA_MP: other?.hinhvemotA_MP,
        //   tienphonG_MT: other?.tienphonG_MT,
        //   tienphonG_MP: other?.tienphonG_MP,
        //   mongmaT_MT: other?.mongmaT_MT,
        //   mongmaT_MP: other?.mongmaT_MP,
        //   dongtU_MT: other?.dongtU_MT,
        //   dongtU_MP: other?.dongtU_MP,
        //   thethuytinH_MT: other?.thethuytinH_MT,
        //   thethuytinH_MP: other?.thethuytinH_MP,
        //   dichkinH_MT: other?.dichkinH_MT,
        //   dichkinH_MP: other?.dichkinH_MP,
        //   daymaT_MT: other?.daymaT_MT,
        //   daymaT_MP: other?.daymaT_MP,
        //   thilucravieN_MT: other?.thilucravieN_MT,
        //   thilucravieN_MP: other?.thilucravieN_MP,
        //   thilucchinhkinH_MT: other?.thilucchinhkinH_MT,
        //   thilucchinhkinH_MP: other?.thilucchinhkinH_MP,
        //   nhanapravieN_MT: other?.nhanapravieN_MT,
        //   nhanapravieN_MP: other?.nhanapravieN_MP,
        // }

        const result = yield call(() => doKhucXaService.putInfoBNDoKhucXa(other))

        // addLog(
        //    infoUser?.dangNhap.idNguoiDung,
        //   PkDangNhap.idChiNhanh,
        //   actionStatus.PUT,
        //   logAction.CHINH_SUA_BENH_NHAN_DO_KHUC_XA,
        //   true,
        //   MENU_CONSTANTS.DOKHUCXA,
        //   null,
        //   detailForLog,
        // )
        if (form?.nguoikham) {
          yield call(() => doKhucXaService.putListDVBN(listdv))
        }
        const listCho = yield call(() => doKhucXaService.getBNChoDoKhucXa(idcn))
        const listDa = yield call(() => doKhucXaService.getBNDaDoKhucXa(idcn, tungay, denngay))
        yield put({
          type: typeAction.DISPATCH_BN_CHO_DKX,
          payload: listCho.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_DKX,
          payload: listDa.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_DKX,
          payload: null,
        })
        if (form?.nguoikham) {
          const resultBN = yield call(() => doKhucXaService.getInfoBN(form.idbnttc))
          yield call(() => generatePhieuKhamKhucXa(resultBN.data, result.data, PkDangNhap))
        }
        yield call(() => resetForm())
        Toast.fire({
          icon: 'success',
          title: 'Lưu thông tin thành công!',
        })
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Lưu thông tin thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // LẤY DANH SÁCH TRÒNG KÍNH VÀ TỒN GỌNG KÍNH
  yield takeLatest(
    typeAction.GET_LIST_TRONG_VA_GONG_KINH_DKX,
    function* getListTrongVaGongKinhSaga({ type, idnhomdv, idcn }) {
      try {
        const ID_COMPANY = Number(localStorage.getItem('id_company'))
        //const resultTK = yield call(() => doKhucXaService.getListDVTrongKinh(idnhomdv))
        const resultTK = yield call(() =>
          doKhucXaService.getListDVTrongKinh(idnhomdv, idcn, ID_COMPANY),
        )
        const resultGK = yield call(() => doKhucXaService.getListTonGongKinh(idcn))
        yield put({
          type: typeAction.DISPATCH_LIST_TRONG_KINH_DKX,
          payload: resultTK.data,
        })
        const newData = resultGK?.data
          .filter((item) => item.tonKho > 0)
          .map((item, index) => ({ ...item, ID: index }))
        yield put({
          type: typeAction.DISPATCH_LIST_GONG_KINH_DKX,
          payload: newData,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
  // //---------------- LƯU THÔNG TIN TRÒNG VÀ GỌNG KÍNH BỆNH NHÂN -------------//
  yield takeLatest(
    typeAction.POST_LIST_TRONG_VA_GONG_KINH_DKX,
    function* postInfoBNTrongVaGongKinhSaga({ type, idbnttc, listTK, listGK, isKhamMat }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        if (listTK?.length > 0) yield call(() => doKhucXaService.postInfoBNTrongKinh(listTK))
        if (listGK?.length > 0)
          yield call(() => childrenClinicService.putBNThuocKTVKeGong(idbnttc, listGK)) // có gọng mới cập nhật thêm
        if (!isKhamMat && listGK?.length > 0)
          yield call(() => doKhucXaService.putDoKhucXaKhongKhamMat(2, idbnttc)) // nếu mà không khám mắt thì cập nhật trangthaikb = 2 của bnttc để ra dược lấy toa gọng
        else if (!isKhamMat && listGK?.length <= 0)
          yield call(() => doKhucXaService.putDoKhucXaKhongKhamMat(4, idbnttc)) // không khám mắt + không lấy gọng thì hoàn thành để vào phòng tròng kính
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_TRONG_KINH_BN_DKX,
          payload: [],
        })
        yield put({
          type: typeAction.DISPATCH_LIST_GONG_KINH_BN_DKX,
          payload: [],
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu thông tin thành công!',
        })
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Lưu thông tin thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
