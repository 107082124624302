import { call, put, select, takeLatest } from 'redux-saga/effects'
import moment from 'moment'
import Swal from 'sweetalert2'
import { vltlService } from '../../services/vltl/vltlService'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import 'moment/locale/vi'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export function* vltlSaga() {
  //LẤY THÔNG TIN BỆNH NHÂN VLTL
  yield takeLatest(
    typeAction.GET_INFO_BN_TAP_VLTL,
    function* getInfoBNTapVLTLSaga({ type, idbnttc, tenBenhNhan }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const result = yield call(() => vltlService.getInfoBNTapVLTL(idbnttc))

        yield put({
          type: typeAction.DISPATCH_INFO_BN_TAP_VLTL,
          payload: result.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_SO_LAN_BN_TAP_VLTL,
          payload: null,
        })

        const detailForLog = {
          tenBenhNhan: tenBenhNhan,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_LICH_SU_BENH_NHAN_VAT_LY_TRI_LIEU,
          true,
          MENU_CONSTANTS.VLTL,
          null,
          detailForLog,
        )
      } catch (error) {
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_LICH_SU_BENH_NHAN_VAT_LY_TRI_LIEU,
          false,
          MENU_CONSTANTS.VLTL,
          detailErrorPayload,
        )
      }
    },
  )
  //LẤY DANH SÁCH BỆNH NHÂN CHỜ THỰC HIỆN VLTL
  yield takeLatest(typeAction.GET_LIST_BN_CHO_VLTL, function* getListBNChoVLTLSaga({ type, idcn }) {
    try {
      const { infoUser } = yield select((state) => state.userReducer)
      const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
      yield call(() =>
        phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT330'),
      )
      const result = yield call(() => vltlService.getListBNChoVLTL(idcn))
      yield put({
        type: typeAction.DISPATCH_LIST_BN_CHO_VLTL,
        payload: result.data,
      })
    } catch (error) {
      console.error('Error : ', error)
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  })
  //LẤY DANH SÁCH BỆNH NHÂN ĐÃ THỰC HIỆN VLTL
  yield takeLatest(
    typeAction.GET_LIST_BN_DA_VLTL,
    function* getListBNDaVLTLSaga({ type, idcn, tungay, denngay }) {
      try {
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT331'),
        )
        const result = yield call(() => vltlService.getListBNDaVLTL(idcn, tungay, denngay))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DA_VLTL,
          payload: result.data,
        })
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      }
    },
  )
  //LƯU BỆNH NHÂN TẬP VLTL
  yield takeLatest(
    typeAction.POST_BN_TAP_VLTL,
    function* postBNTapVLTLSaga({ type, form, idcnCho, idcnDa, tungay, denngay }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT332'),
        )

        yield call(() => vltlService.postBNTapVLTL(form))
        if (form.lancuoi) yield call(() => vltlService.putBNTapVLTL(form.idbnttc, form.iddv))
        const listChoVLTL = yield call(() => vltlService.getListBNChoVLTL(idcnCho))
        const listDaVLTL = yield call(() => vltlService.getListBNDaVLTL(idcnDa, tungay, denngay))
        const infoBNTapVLTL = yield call(() => vltlService.getInfoBNTapVLTL(form.idbnttc))
        yield put({
          type: typeAction.DISPATCH_INFO_BN_TAP_VLTL,
          payload: infoBNTapVLTL.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_BN_CHO_VLTL,
          payload: listChoVLTL.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DA_VLTL,
          payload: listDaVLTL.data,
        })
        // yield put({
        //     type: typeAction.DISPATCH_INFO_BN_TAP_VLTL,
        //     payload: null,
        // })
        yield put({
          type: typeAction.DISPATCH_INFO_SO_LAN_BN_TAP_VLTL,
          payload: null,
        })
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  //LẤY DANH SÁCH SỐ LẦN BỆNH NHÂN TẬP VLTL
  yield takeLatest(
    typeAction.GET_INFO_SO_LAN_BN_TAP_VLTL,
    function* getInfoSoLanTapVLTLSaga({ type, idbnttc, iddv }) {
      try {
        const result = yield call(() => vltlService.getInfoSoLanTapVLTL(idbnttc, iddv))
        yield put({
          type: typeAction.DISPATCH_INFO_SO_LAN_BN_TAP_VLTL,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
}
