import Button from '@mui/material/Button'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { DatePicker, Empty, Input, Modal, Radio, Select, Tabs, notification } from 'antd'
import React, { useEffect, useState } from 'react'

import '@react-pdf-viewer/core/lib/styles/index.css'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
// Import styles
import { ExperimentOutlined, FileDoneOutlined, UserOutlined } from '@ant-design/icons'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import axios from 'axios'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import moment from 'moment'
import 'moment/locale/vi'
import { useDispatch, useSelector } from 'react-redux'
import { benhnhanCRMSchema } from '../../../../schemas/benhnhanCRMSchema'
import { https } from '../../../../services/apiService'
import { UpdateBNCRMAction } from '../../../../store/actions/benhnhanCRMAction'
import * as typeAction from '../../../../store/constants/constants'
import ToastCus from '../../../../utils/Toast'
moment.locale('vi')

const ModalEditBenhNham = ({ setReload }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const dispatch = useDispatch()
  const [api, contextHolder] = notification.useNotification()
  const { modalEditBNKhamDoan } = useSelector((state) => state.modalReducer)
  const { infoBNCRM } = useSelector((state) => state.benhnhanCRMReducer)
  const { infoUser } = useSelector((state) => state.userReducer)

  const fileType = ['application/pdf']
  const [filePDFkham, setFilePDFkham] = useState(null)
  const [filePDFxn, setFilePDFxn] = useState(null)
  const [filePDFcls, setFilePDFcls] = useState(null)
  const [listKH, setListKh] = useState([])
  const [listGK, setListGK] = useState([])

  // xử lí file khám bệnh
  const handleChangeFileKham = (e) => {
    let File = e.target.files[0]
    if (File) {
      if (File && fileType.includes(File.type)) {
        const fileSizeInMB = File.size / (1024 * 1024) // Chuyển đổi kích thước tệp sang MB
        if (fileSizeInMB > 10) {
          // file không được vược quá 10mb
          openNotificationWithIcon('warning')
          return
        }
        let render = new FileReader()
        let formData = new FormData()
        formData.append('file', File)
        render.readAsDataURL(File)
        render.onload = (e) => {
          const blob = new Blob([File], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          setFilePDFkham(url)
          formik.setFieldValue('kqkhamFile', File)
          // console.log(formData);
          setKq()
        }
      }
    } else {
      formik.setFieldValue('kqkhamFile', null)
    }
  }

  const handleChangeFileCLS = (e) => {
    let File = e.target.files[0]
    if (File) {
      if (File && fileType.includes(File.type)) {
        const fileSizeInMB = File.size / (1024 * 1024) // Chuyển đổi kích thước tệp sang MB
        if (fileSizeInMB > 10) {
          // file không được vược quá 10mb
          openNotificationWithIcon('warning')
          return
        }
        let render = new FileReader()
        let formData = new FormData()
        formData.append('file', File)
        render.readAsDataURL(File)
        render.onload = (e) => {
          const blob = new Blob([File], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          setFilePDFcls(url)
          formik.setFieldValue('kqcdhaFile', File)
          // console.log(formData);
          setKq()
        }
      }
    } else {
      formik.setFieldValue('kqcdhaFile', null)
    }
  }

  // cảnh cáo dung lượng upload file
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Upload File',
      description: 'File Không được vượt quá 10MB!',
      placement: 'top',
    })
  }
  const handleRadio = (name) => (e) => {
    const value = e.target.value
    formik.setFieldValue(name, value)
  }
  const handleSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }

  const handleDate = (name) => (date, dateString) => {
    const newDate = moment(dateString, 'DD/MM/YYYY').format()
    formik.setFieldValue(name, newDate)
  }
  const handleSubmit = (value) => {
    console.log(value)
    if (!value.idkh) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn khách hàng',
      })
    }
    if (!value.idkhgk) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn gói khám',
      })
    }
    let formData = new FormData()
    for (let key in value) {
      if (value[key] !== null) {
        formData.append(key, value[key])
      }
    }
    dispatch(UpdateBNCRMAction(infoBNCRM?.idbn, formData, setReload))
    handleCancel()
  }
  const setKq = () => {
    const now = moment()
    formik.setFieldValue('nguoitao', infoUser.idnv)
    formik.setFieldValue('ngaykq', now.format('YYYY-MM-DDTHH:mm:ss'))
  }
  // xử lí file khám xét nghiệm
  const handleChangeFileXetNghiem = (e) => {
    let File = e.target.files[0]
    if (File) {
      if (File && fileType.includes(File.type)) {
        const fileSizeInMB = File.size / (1024 * 1024) // Chuyển đổi kích thước tệp sang MB
        if (fileSizeInMB > 10) {
          // file không được vược quá 10mb
          openNotificationWithIcon('warning')
          return
        }
        let render = new FileReader()
        render.readAsDataURL(File)
        render.onload = (e) => {
          const blob = new Blob([File], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          setFilePDFxn(url)
          formik.setFieldValue('kqxnFile', File)
          setKq()
          // setPdfXetNghiem(e.target.result);
        }
      }
    } else {
      formik.setFieldValue('kqxnFile', e.target.result)
    }
  }
  // FORRMIK
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idkh: infoBNCRM?.idkh,
      idkhgk: infoBNCRM?.idkhgk,
      idbn: infoBNCRM?.idbn,
      mabn: infoBNCRM?.mabn,
      tenbn: infoBNCRM?.tenbn,
      gioitinh: infoBNCRM?.gioitinh,
      ngaysinh: infoBNCRM?.ngaysinh,
      sodienthoai: infoBNCRM?.sodienthoai,
      ghichu: infoBNCRM?.ghichu,
      idkhgk: infoBNCRM?.idkhgk,
      kqcdhaFile: null,
      kqkhamFile: null,
      kqxnFile: null,
      ngaykq: infoBNCRM?.ngaykq,
      nguoikq: infoUser?.idnv,
      trangthaisms: infoBNCRM?.trangthaisms,
      trangthaikham: infoBNCRM?.trangthaikham,
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: benhnhanCRMSchema,
  })
  const handleCancel = () => {
    setFilePDFkham(null)
    setFilePDFxn(null)
    setFilePDFcls(null)
    dispatch({
      type: typeAction.CLOSE_MODAL_EDIT_BN_KHAM_DOAN,
    })
  }

  useEffect(() => {
    ;(async () => {
      const { data } = await https.get('CRMKhachHang/GetAll')
      setListKh(data)
    })()
  }, [])

  const getGoiKhamByKhachHang = async (value) => {
    try {
      const { data } = await https.get(`CRMKhachHangGoiKham/GetByIDKH?IDKH=${value}`)
      setListGK(data.result)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    async function fetchFileKQK() {
      if (infoBNCRM?.linK_KQKHAM) {
        try {
          const result = await axios.get(infoBNCRM?.linK_KQKHAM, {
            responseType: 'arraybuffer',
          })
          const blob = new Blob([result.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          setFilePDFkham(url)
        } catch (err) {
          console.log(err)
        }
      }
    }
    async function fetchFileKQXN() {
      if (infoBNCRM?.linK_KQXN) {
        try {
          const result = await axios.get(infoBNCRM?.linK_KQXN, {
            responseType: 'arraybuffer',
          })
          const blob = new Blob([result.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          setFilePDFxn(url)
        } catch (err) {
          console.log(err)
        }
      }
    }
    async function fetchFileCLS() {
      if (infoBNCRM?.linK_KQCDHA) {
        try {
          const result = await axios.get(infoBNCRM?.linK_KQCDHA, {
            responseType: 'arraybuffer',
          })
          const blob = new Blob([result.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          setFilePDFcls(url)
        } catch (err) {
          console.log(err)
        }
      }
    }

    fetchFileKQK()
    fetchFileKQXN()
    fetchFileCLS()
  }, [infoBNCRM])

  const handleChangeKhachHang = (value) => {
    if (listGK.length > 0) {
      setListGK([])
      formik.setFieldValue('idkhgk', '')
    }
    formik.setFieldValue('idkh', value)
  }

  useEffect(() => {
    getGoiKhamByKhachHang(formik.values.idkh)
  }, [formik.values.idkh])

  return (
    <>
      {contextHolder}
      <Modal
        width={800}
        className="text-center"
        footer={null}
        title="Sửa thông tin bệnh nhân"
        open={modalEditBNKhamDoan}
        onCancel={handleCancel}
      >
        <form className="text-start">
          <p>
            <span className="font-semibold">Tên BN:</span> {infoBNCRM?.tenbn}
          </p>
          <p>
            <span className="font-semibold">Mã BN:</span> {infoBNCRM?.mabn}
          </p>
          <div className=" shadow-gray-500">
            <Tabs
              type="card"
              defaultActiveKey="1"
              items={[
                {
                  key: 1,
                  label: 'Thông tin',
                  icon: <UserOutlined />,
                  children: (
                    <>
                      <div>
                        <div className="flex gap-5">
                          <div className="w-1/2">
                            <label className="font-semibold">
                              <span className="text-red-500">*</span>
                              Tên BN:
                            </label>
                            <Input
                              status={formik.errors.tenbn ? 'error' : ''}
                              name="tenbn"
                              onChange={formik.handleChange}
                              value={formik.values.tenbn}
                            />
                          </div>
                          <div className="w-1/2 flex">
                            <div className="w-1/2 flex flex-col">
                              <label className="font-semibold">
                                <span className="text-red-500">*</span>Ngày sinh:
                              </label>
                              <DatePicker
                                allowClear={false}
                                onChange={handleDate('ngaysinh')}
                                value={dayjs(formik.values.ngaysinh, 'YYYY-MM-DD')}
                                format={'DD/MM/YYYY'}
                              />
                            </div>
                            <div class="flex flex-col w-1/2 ml-4">
                              <span class="font-semibold">
                                <span className="text-red-500">*</span>Giới tính:
                              </span>
                              <Radio.Group
                                className="flex"
                                onChange={handleRadio('gioitinh')}
                                value={formik.values.gioitinh}
                                name="gioitinh"
                                options={[
                                  {
                                    label: 'Nam',
                                    value: 'Nam',
                                  },
                                  {
                                    label: 'Nữ',
                                    value: 'Nữ',
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-5">
                          <div className="w-1/2">
                            <label className="font-semibold">Số điện thoại:</label>
                            <Input
                              onChange={formik.handleChange}
                              name="sodienthoai"
                              value={formik.values.sodienthoai}
                            />
                          </div>
                          <div className="w-1/2">
                            <label className="font-semibold">
                              <span className="text-red-500">*</span>Tên khách hàng:
                            </label>
                            <Select
                              value={formik.values.idkh}
                              className=" w-full "
                              required=""
                              showSearch
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              type="password"
                              name="idkh"
                              options={listKH?.map((item) => ({
                                label: item.tenkh,
                                value: item.idkh,
                              }))}
                              onChange={handleChangeKhachHang}
                            />
                            {formik.errors.idkh && (
                              <span className="text-red-500">* Vui lòng chọn khách hàng</span>
                            )}
                          </div>
                        </div>
                        <div className="flex gap-5">
                          <div className="w-1/2">
                            <label className="font-semibold">Ghi chú:</label>
                            <Input
                              onChange={formik.handleChange}
                              name="ghichu"
                              value={formik.values.ghichu}
                            />
                          </div>

                          <div class="flex flex-col w-1/2">
                            <span class="font-semibold">
                              <span className="text-red-500">*</span>Gói khám:
                            </span>
                            <Select
                              value={formik.values.idkhgk}
                              className=" w-full "
                              required=""
                              showSearch
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              type="password"
                              name="idkhgk"
                              options={listGK?.map((item) => ({
                                label: item?.crmGoiKham?.tengoikham,
                                value: item?.idkhgk,
                              }))}
                              onChange={(value) => formik.setFieldValue('idkhgk', value)}
                            />
                            {formik.errors.idkhgk && (
                              <span className="text-red-500">* Vui lòng chọn gói khám</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                },
                {
                  key: 2,
                  label: 'KQ khám',
                  icon: <FileDoneOutlined />,
                  children: (
                    <div className="h-[450px]">
                      <div className="flex gap-5">
                        <Button type="button" variant="contained" size="small">
                          <label className="cursor-pointer " for="file-upload-kham">
                            Chọn file
                          </label>
                          <input
                            id="file-upload-kham"
                            type="file"
                            accept="application/pdf"
                            onChange={handleChangeFileKham}
                          />
                        </Button>
                        {/* {filePDFkham ? (
                          <Button
                            size="small"
                            color="error"
                            onClick={() => {
                              cancelPDFKham("KQKhamFile");
                            }}
                            variant="outlined"
                          >
                            Xoá file
                          </Button>
                        ) : (
                          ""
                        )} */}
                      </div>
                      <div>
                        {filePDFkham ? (
                          <div
                            className="mt-2"
                            style={{
                              border: '1px solid rgba(0, 0, 0, 0.3)',
                              height: '400px',
                            }}
                          >
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                              <div style={{ height: '400px' }}>
                                <Viewer
                                  fileUrl={filePDFkham}
                                  plugins={[defaultLayoutPluginInstance]}
                                />
                              </div>
                            </Worker>
                            {/* <embed
                              className="w-full h-full"
                              src={filePDFkham}
                              type={fileType}
                            /> */}
                          </div>
                        ) : (
                          <Empty description={<span>Chưa có file kết quả khám</span>} />
                        )}
                        <div className="pdf-viewer"></div>
                      </div>
                    </div>
                  ),
                },
                {
                  key: 3,
                  label: 'KQ xét nghiệm',
                  icon: <ExperimentOutlined />,
                  children: (
                    <div className="h-[450px]">
                      <div className="flex gap-5">
                        <Button type="button" variant="contained" size="small">
                          <label className="cursor-pointer " for="file-upload-xet-nghiem">
                            Chọn file
                          </label>
                          <input
                            id="file-upload-xet-nghiem"
                            type="file"
                            accept="application/pdf"
                            onChange={handleChangeFileXetNghiem}
                          />
                        </Button>
                      </div>

                      <div>
                        {filePDFxn ? (
                          <div
                            className="mt-2"
                            style={{
                              border: '1px solid rgba(0, 0, 0, 0.3)',
                              height: '400px',
                            }}
                          >
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                              <div style={{ height: '400px' }}>
                                <Viewer
                                  fileUrl={filePDFxn}
                                  plugins={[defaultLayoutPluginInstance]}
                                />
                              </div>
                            </Worker>
                            {/* <embed
                              className="w-full h-full"
                              src={filePDFxn}
                              type={fileType}
                            /> */}
                          </div>
                        ) : (
                          <Empty description={<span>Chưa có file kết quả xét nghiệm</span>} />
                        )}
                      </div>
                    </div>
                  ),
                },
                {
                  key: 4,
                  label: 'KQ chẩn đoán hình ảnh',
                  icon: <ExperimentOutlined />,
                  children: (
                    <div className="h-[450px]">
                      <div className="flex gap-5">
                        <Button type="button" variant="contained" size="small">
                          <label className="cursor-pointer " for="file-upload-cls">
                            Chọn file
                          </label>
                          <input
                            id="file-upload-cls"
                            type="file"
                            accept="application/pdf"
                            onChange={handleChangeFileCLS}
                          />
                        </Button>
                      </div>

                      <div>
                        {filePDFcls ? (
                          <div
                            className="mt-2"
                            style={{
                              border: '1px solid rgba(0, 0, 0, 0.3)',
                              height: '400px',
                            }}
                          >
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                              <div style={{ height: '400px' }}>
                                <Viewer
                                  fileUrl={filePDFcls}
                                  plugins={[defaultLayoutPluginInstance]}
                                />
                              </div>
                            </Worker>
                          </div>
                        ) : (
                          <Empty
                            description={<span>Chưa có file kết quả chẩn đoán hình ảnh</span>}
                          />
                        )}
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </div>
          <div className="flex justify-end gap-5 pt-2">
            <Button onClick={formik.handleSubmit} variant="contained" size="small">
              Cập nhật
            </Button>
            <Button onClick={handleCancel} variant="outlined">
              Huỷ bỏ
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalEditBenhNham
