import * as typeAction from '../constants/constants'
export const getList_BN_SMS = (
  chuyenKhoa,
  numberTP,
  numberTTV,
  ageGroup,
  setLoadingBN,
  filterName,
) => ({
  type: typeAction.GET_BN_SMS,
  chuyenKhoa,
  numberTP,
  numberTTV,
  ageGroup,
  setLoadingBN,
  filterName,
})

export const getList_Template_SMS = () => ({
  type: typeAction.GET_LIST_TEMPLATE_SMS,
})
export const fetchAccountSMS = () => ({
  type: typeAction.GET_ACCOUNTS_SMS,
})
export const createTemplateSMS = (form, onReset) => ({
  type: typeAction.POST_TEMPLATE_SMS,
  form,
  onReset,
})
export const getInfoTemplateSMS = (id) => ({
  type: typeAction.GET_INFO_TEMPLATE_SMS_BY_ID,
  id,
})
export const updateTemplateSMS = (form) => ({
  type: typeAction.UPDATE_TEMPLATE_SMS,
  form,
})
export const deleteTemplateSMS = (id) => ({
  type: typeAction.DELETE_TEMPLATE_SMS,
  id,
})
export const createAccountSMS = (form, onReset) => ({
  type: typeAction.CREATE_ACCOUNT_SMS,
  form,
  onReset,
})
export const deleteAccountSMS = (id) => ({
  type: typeAction.DELETE_ACCOUNT_SMS,
  id,
})
export const updateAccountSMS = (form) => ({
  type: typeAction.UPDATE_ACCOUNT_SMS,
  form,
})
