import {
  CloseOutlined,
  GoldOutlined,
  SaveOutlined,
  DeleteOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons'
import { Button } from '@mui/material'
import { Input, Select, Tabs, notification, Table, ConfigProvider, InputNumber } from 'antd'
import { useFormik } from 'formik'
import 'jspdf-autotable'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import { postPhieuXuatSuDungAction } from '../../../../store/actions/xuatSuDungAction'
import { nhanvienService } from '../../../../services/nhanvien/nhanvienService'
import { chuyenKhoService } from '../../../../services/ChuyenKho/chuyenKhoService'
import * as typeAction from '../../../../store/constants/constants'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'
import { v4 as uuidv4 } from 'uuid'
import 'moment/locale/vi'
moment.locale('vi')

const validKhoIdsDieuDuong = [104, 65, 4] // chỉ lấy ID kho điều dưỡng
const validKhoIdsDuoc = [102, 63, 2] // chỉ lấy ID kho dược
const validKhoIdsVPP = [225] // chỉ lấy ID kho văn phòng phẩm
const ID_NHOM_LE_TAN = 3
const ID_NHOM_DIEU_DUONG = 38
const ID_NHOM_DUOC = 4
function LapPhieuXuat() {
  const [totalPrice, setTotalPrice] = useState(0)
  // ------dùng để focus vào items mới -------------//
  const id_company = Number(localStorage.getItem('id_company'))
  const id_branch = Number(localStorage.getItem('BRANH_LOGIN'))
  // lấy thông tin người dùng >> tạm thời
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [branch, setBranch] = useState(id_branch)
  const [listBranch, setListBranch] = useState([])
  const now = useMemo(() => moment(), [])
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFile] = useState([])
  const [listKho, setListKho] = useState([])
  const [khoXuat, setKhoXuat] = useState(null) //thông tin kho (idkho,idkhocn)
  const [listBoPhan, setListBoPhan] = useState([])
  const [boPhanSD, setBoPhanSD] = useState(infoUser?.idNhom) //id nhom nguoi dung
  const [listTonVTHH, setListTonVTHH] = useState([])
  const [listXuatVTHH, setListXuatVTHH] = useState([])
  const [isLoadingTon, setIsLoadingTon] = useState(false)
  const fileInputRef = useRef(null)
  const dropzoneRef = useRef(null)
  const chonKhoVPP = khoXuat?.makho?.toUpperCase()?.includes('VPP')

  const maQuyenThemPhieu = 'QHT221'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenThemPhieu:
        formik.handleSubmit()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenThemPhieu:
        //
        break
      default:
        return false
    }
  }

  const deleteFileById = (id) => {
    const updatedData = selectedFile.filter((item) => item.id !== id)
    setSelectedFile(updatedData)
  }
  const handleDivClick = () => {
    fileInputRef.current.click()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px solid #000'
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    dropzoneRef.current.style.border = '2px dashed #ccc'
    handleFileChange(e.dataTransfer.files[0])
  }
  const handleFileSelect = (e) => {
    handleFileChange(e.target.files[0])
  }
  const handleFileChange = async (file) => {
    //  let file = event.target.files[0]
    // List of allowed file types
    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp']
    if (!allowedFileTypes.includes(file.type)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Chỉ chấp nhận tệp là hình ảnh!',
      })
      fileInputRef.current.value = '' // clear the input
      return
    }
    // formik.setFieldValue('HoaDonFile', file)
    // console.log(formData);
    fileInputRef.current.value = ''
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      const localUrl = URL.createObjectURL(file)
      setSelectedFile((preState) => [
        ...preState,
        { base64: e.target.result, URL: localUrl, name: file.name, id: uuidv4(), file },
      ])
    }
  }
  // xoá hình ảnh
  const initialValues = useMemo(
    () => ({
      TenPhieu: '',
      NoiDung: '',
      GhiChu: '',
      NguoiXuat: infoUser?.idnv,
      HoaDonFiles: null,
      clickedButton: '',
    }),
    [now, id_company],
  )
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: initialValues,
    // validationSchema: KhoNhapSchema,
    onSubmit: (value, action) => handleSubmit(value, action),
  })
  const closeImg = () => {
    setSelectedFile([])
    formik.setFieldValue('HoaDonFile', null)
  }

  const resetForm = () => {
    formik.resetForm()
    setKhoXuat(null)
    setBoPhanSD(null)
    setListXuatVTHH([])
    setSelectedFile([])
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Clear the input
    }
  }
  // xử lí button submit
  const handleSubmit = (values, action) => {
    if (!values?.TenPhieu || !khoXuat?.idkhocn || (chonKhoVPP && !boPhanSD)) {
      formik.setFieldError('TenPhieu', !values?.TenPhieu ? 'error' : null)
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
      return
    }
    if (!listXuatVTHH?.length) {
      ToastCus.fire({
        icon: 'error',
        title: 'Không có vật tư hàng hóa cần xuất!',
      })
      return
    }
    if (
      listXuatVTHH?.find(
        (item) =>
          !item?.soluong ||
          !item?.tonkho ||
          item?.soluong <= 0 ||
          item?.tonkho <= 0 ||
          item?.soluong > item?.tonkho,
      )
    ) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại số lượng!',
      })
      return
    }
    const formData = new FormData()
    formData.append('TENPHIEU', values.TenPhieu)
    formData.append('IDKHOXUAT', khoXuat?.idkhocn)
    formData.append('NOIDUNG', values.NoiDung)
    formData.append('GHICHU', values.GhiChu)
    formData.append('NGUOIXUAT', infoUser?.idnv)
    if (chonKhoVPP) formData.append('BOPHANSUDUNG', boPhanSD)
    if (selectedFile?.length !== 0) {
      const dataHoaDonFiles = selectedFile?.map((item) => item.file)
      dataHoaDonFiles?.forEach((file, index) => {
        formData.append('hoaDonFiles', file)
      })
    }
    dispatch(postPhieuXuatSuDungAction(formData, listXuatVTHH, resetForm))
    //////////////////////
  }

  const handleOnChangeSoLuongVTHH = (soluong, ton, key) => {
    if (soluong <= 0 || soluong > ton) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại số lượng!',
      })
      return
    }
    setListXuatVTHH((prev) =>
      prev?.map((item) => (item?.key === key ? { ...item, soluong: soluong } : item)),
    )
  }
  const handleAddVTHH = (value, opt) => {
    if (listXuatVTHH?.find((item) => item?.key === opt?.key)) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vật tư hàng hóa này đã được chọn!',
      })
      return
    }
    if (!opt?.infoVTHH?.tonKho || opt?.infoVTHH?.tonKho <= 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vật tư hàng hóa này không đủ tồn!',
      })
      return
    }
    setListXuatVTHH((prev) => [
      ...prev,
      {
        key: opt?.key,
        idthuoc: opt?.infoVTHH?.idThuoc,
        tenbietduoc: opt?.infoVTHH?.thuocVatTu?.tenBietDuoc,
        tenhoatchat: opt?.infoVTHH?.thuocVatTu?.tenHoatChat,
        solo: opt?.infoVTHH?.soLo,
        handung: opt?.infoVTHH?.hanDung,
        sohoadon: opt?.infoVTHH?.soHoaDon,
        soluong: 1,
        masodangky: opt?.infoVTHH?.thuocVatTu?.maSoDangKy,
        ghichu: null,
        stt: (prev?.length ?? 0) + 1,
        mathuoc: opt?.infoVTHH?.thuocVatTu?.maThuoc,
        dvt: opt?.infoVTHH?.thuocVatTu?.dvt,
        tonkho: opt?.infoVTHH?.tonKho,
      },
    ])
  }
  const getListVTHHbyIdKhoCN = async (idkhocn) => {
    try {
      setIsLoadingTon(true)
      const { data } = await https.get(`TonKho/${idkhocn ?? 0}`)
      setListTonVTHH(data?.result?.filter((item) => item?.tonKho > 0))
      setListXuatVTHH([])
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingTon(false)
    }
  }

  // lưu
  const handleSave = () => {
    formik.setFieldValue('clickedButton', 'save')
    dispatch(putCheckQuyenThaoTacAction(maQuyenThemPhieu, null, actionIsTrue, actionIsFalse))
  }
  const viewsRoleKho = (data) => {
    const { idNhom } = infoUser || {}
    let VPP = ['101KVPP', '81KVPP','KVPP'] // - VPP =>  Văn phòng phẩm
    let KD = ['81KD','101KD','1KD','81KHHPD','81KHDPD'] // - KD => Kho dược
    let KDD = ['81KDD','101KDD','1KDD','1KHDDD','81KHDDD','1KHHDD','81KHHDD'] // - KDD => kho điều dưỡng
    const groupKhoMapping = {
      [ID_NHOM_LE_TAN]: [...VPP], // Nhóm lễ tân
      [ID_NHOM_DUOC]: [...VPP, ...KD], // Nhóm dược
      [ID_NHOM_DIEU_DUONG]: [...VPP, ...KDD], // Nhóm điều dưỡng
    }
    // Nếu không thuộc nhóm đặc biệt, trả về tất cả
    if (!(idNhom in groupKhoMapping)) {
      return data
    }
    // Lọc kho theo nhóm
    const allowedKho = groupKhoMapping[idNhom] || []
    return data?.filter((kho) => allowedKho.includes(kho?.maKho))
  }
  // ------------- lấy mã hàng --------//
  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await chuyenKhoService.getlistKhoNhan(id_branch)
        let listKhoCN = viewsRoleKho(data)
        setKhoXuat({ idkhocn: listKhoCN[0]?.idKhoCN, makho: listKhoCN[0]?.maKho })
        getListVTHHbyIdKhoCN(listKhoCN[0]?.idKhoCN)
        setListKho(listKhoCN)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [infoUser])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get(`ChiNhanh/GetAllByIdCTNotVanPhong?idCT=${id_company}`)
        const { data: listBP } = await nhanvienService.getListBoPhan(null) //keyword = null để get all
        setListBranch(data)
        setListBoPhan(listBP?.filter((item) => item?.sudung))
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  return (
    <div>
      <form>
        <div>
          <div className="grid grid-cols-4 items-center gap-2 mt-2 w-full ">
            <div className="col-span-2 grid grid-cols-6 items-center gap-1">
              <label className="text-end font-semibold">
                <span className="text-red-500 text-xs">(*)</span>Tên phiếu xuất:
              </label>
              <Input
                className="col-span-5"
                size="small"
                name="TenPhieu"
                status={formik.errors.TenPhieu}
                value={formik.values.TenPhieu}
                onChange={formik.handleChange}
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-1">
              <label className="text-end font-semibold">Người xuất:</label>
              <Input
                className="col-span-2"
                readOnly
                variant="filled"
                value={infoUser?.tenNV}
                // name="nhanVienNhan"
                size="small"
              />
            </div>
            <div className="grid grid-cols-3 items-center gap-1">
              <label className="text-end font-semibold">Ngày xuất:</label>
              <Input
                className="col-span-2"
                readOnly
                variant="filled"
                value={moment().format('DD/MM/YYYY HH:mm:ss')}
                // name="nhanVienNhan"
                size="small"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 mt-2 items-center w-full ">
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="text-end font-semibold">Nội dung:</label>
              <Input
                className="col-span-5"
                size="small"
                name="NoiDung"
                value={formik.values.NoiDung}
                onChange={formik.handleChange}
              />
            </div>
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">Chi nhánh xuất:</label>
                <Input
                  value={PkDangNhap?.tenChiNhanh}
                  className="col-span-2 w-full"
                  size="small"
                  readOnly
                  variant="filled"
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="text-end font-semibold">
                  <span className="text-red-500 text-xs">(*)</span>Kho xuất:
                </label>
                <Select
                  status={
                    !khoXuat?.idkhocn &&
                    (formik.values.TenPhieu || formik.errors.TenPhieu) &&
                    'error'
                  }
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value, opt) => {
                    setKhoXuat({ idkhocn: value, makho: opt?.infoKho?.maKho })
                    setBoPhanSD(null)
                    getListVTHHbyIdKhoCN(value)
                  }}
                  className="w-full col-span-2"
                  size="small"
                  value={khoXuat?.idkhocn}
                  options={listKho?.map((item) => ({
                    label: item?.tenKho,
                    value: item?.idKhoCN,
                    infoKho: item,
                  }))}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 mt-2 items-center w-full ">
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="text-end font-semibold">Ghi chú:</label>
              <Input
                className="col-span-5"
                size="small"
                name="GhiChu"
                value={formik.values.GhiChu}
                onChange={formik.handleChange}
              />
            </div>
            <div className="grid grid-cols-2 items-center gap-1">
              <div></div>
              <div className="grid grid-cols-3 items-center gap-1">
                {chonKhoVPP && (
                  <>
                    <label className="text-end font-semibold">
                      <span className="text-red-500 text-xs">(*)</span>Bộ phận sử dụng:
                    </label>
                    <Select
                      status={
                        !boPhanSD && (formik.values.TenPhieu || formik.errors.TenPhieu) && 'error'
                      }
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        setBoPhanSD(value)
                      }}
                      className="w-full col-span-2"
                      size="small"
                      value={boPhanSD}
                      options={listBoPhan?.map(({ idbp, tenbophan }) => ({
                        label: tenbophan,
                        value: idbp,
                      }))}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <Tabs
            items={[
              {
                key: 1,
                label: (
                  <div className="flex items-center gap-2">
                    {' '}
                    <GoldOutlined style={{ fontSize: 18 }} /> Hàng hóa
                  </div>
                ),
                children: (
                  <>
                    <div className="flex items-center mb-2 gap-2">
                      <Select
                        disabled={isLoadingTon}
                        className="w-full"
                        placeholder="Nhập tên vật tư hàng hoá..."
                        showSearch
                        onChange={handleAddVTHH}
                        filterOption={(value, option) => {
                          const tenBietDuoc = option?.infoVTHH?.thuocVatTu?.tenBietDuoc ?? ''
                          const maThuoc = option?.infoVTHH?.thuocVatTu?.maThuoc ?? ''
                          const searchText = value?.toLowerCase()
                          return (
                            tenBietDuoc?.toLowerCase()?.includes(searchText) ||
                            maThuoc?.toLowerCase()?.includes(searchText)
                          )
                        }}
                        dropdownRender={(menu) => (
                          <div>
                            <div
                              className="flex bg-gray-100"
                              style={{ padding: '8px', fontWeight: 'bold' }}
                            >
                              <span className="w-[5%] text-center ">Mã hàng</span>
                              <span className="w-[60%] text-center">Tên thuốc</span>
                              <span className="w-[10%] text-center">Số lô</span>
                              <span className="w-[5%] text-center">Hạn dùng</span>
                              <span className="w-[10%] text-center">Số hoá đơn</span>
                              <span className="w-[5%] text-center">Đơn vị </span>
                              <span className="w-[5%] text-center">Tồn</span>
                            </div>
                            <div style={{ marginBottom: '8px' }}>{menu}</div>
                          </div>
                        )}
                        optionLabelProp="label"
                        value={null}
                        options={
                          !khoXuat?.idkhocn
                            ? []
                            : listTonVTHH?.map((d, index) => ({
                                infoVTHH: d,
                                key: `${d?.idThuoc}${d?.soLo}${d?.hanDung}${d?.soHoaDon}`,
                                value: `${d?.idThuoc}${d?.soLo}${d?.hanDung}${d?.soHoaDon}`,
                                label: (
                                  <ul className="flex no-underline">
                                    <li className="flex w-[5%] border-r-2">
                                      <p className="w-full truncate text-center">
                                        {d?.thuocVatTu?.maThuoc}
                                      </p>
                                    </li>
                                    <li className="flex w-[60%] border-r-2">
                                      <p className="px-2 w-full truncate">
                                        {d?.thuocVatTu?.tenBietDuoc}
                                      </p>
                                    </li>
                                    <li className="flex w-[10%] border-r-2 pl-2">
                                      <p className="w-full truncate text-center">{d?.soLo}</p>
                                    </li>
                                    <li className="flex w-[5%] border-r-2 items-end justify-center">
                                      <p className="w-full truncate text-center">
                                        {d?.hanDung && moment(d?.hanDung).format('DD/MM/YYYY')}
                                      </p>
                                    </li>
                                    <li className="flex w-[10%] border-r-2 pl-2">
                                      <p className="w-full truncate text-center">{d?.soHoaDon}</p>
                                    </li>
                                    <li className="flex w-[5%] border-r-2 pl-2 items-end justify-center">
                                      <p className="w-full truncate text-center">
                                        {d?.thuocVatTu?.dvt}
                                      </p>
                                    </li>
                                    {/* <li className="flex w-[10%] border-r-2 pl-2">
                                              <p>{d?.maSoDangKy}</p>
                                            </li> */}
                                    <li className="flex w-[5%] items-end justify-center">
                                      <p className="w-full truncate text-center">{d?.tonKho}</p>
                                    </li>
                                  </ul>
                                ),
                              }))
                        }
                      />
                    </div>
                    <ConfigProvider
                      theme={{
                        token: {
                          padding: 5,
                        },
                        components: {
                          Table: {
                            rowHoverBg: '#ecf0f1',
                            headerBg: '#e6e6e6',
                            footerBg: '#e6e6e6',
                            borderColor: '#BABABA',
                          },
                        },
                      }}
                    >
                      <Table
                        //   scroll={{ x: 1000 }}
                        //   pagination={{
                        //     pageSize: 20,
                        //     showSizeChanger: false,
                        //   }}
                        scroll={{ y: 540 }}
                        pagination={false}
                        loading={isLoadingTon}
                        columns={[
                          {
                            title: 'STT',
                            dataIndex: 'stt',
                            key: 'stt',
                            width: 45,
                            align: 'center',
                          },
                          {
                            title: 'Mã hàng',
                            dataIndex: 'mathuoc',
                            key: 'mathuoc',
                            width: 120,
                          },
                          {
                            title: 'Tên hàng',
                            dataIndex: 'tenbietduoc',
                            key: 'tenbietduoc',
                          },
                          {
                            title: 'Số lô',
                            dataIndex: 'solo',
                            key: 'solo',
                            width: 120,
                          },
                          {
                            title: 'Hạn Dùng',
                            dataIndex: 'handung',
                            key: 'handung',
                            align: 'center',
                            width: 120,
                            render: (text, record, index) =>
                              text ? moment(text).format('DD/MM/YYYY') : '-',
                          },
                          {
                            title: 'Số hóa đơn',
                            dataIndex: 'sohoadon',
                            key: 'sohoadon',
                            width: 120,
                          },
                          {
                            title: 'ĐVT',
                            dataIndex: 'dvt',
                            key: 'dvt',
                            width: 60,
                            align: 'center',
                          },
                          {
                            title: 'Số lượng',
                            dataIndex: 'soluong',
                            key: 'soluong',
                            width: 75,
                            align: 'center',
                            render: (text, record, index) => (
                              <InputNumber
                                className="w-full"
                                size="small"
                                value={text}
                                min={1}
                                step={1}
                                max={record?.tonkho}
                                status={
                                  !text ||
                                  !record?.tonkho ||
                                  text <= 0 ||
                                  record?.tonkho <= 0 ||
                                  text > record?.tonkho
                                    ? 'error'
                                    : ''
                                }
                                onChange={(value) =>
                                  handleOnChangeSoLuongVTHH(value, record?.tonkho, record?.key)
                                }
                              />
                            ),
                          },
                          {
                            title: 'Tồn',
                            dataIndex: 'tonkho',
                            key: 'tonkho',
                            width: 75,
                            align: 'center',
                          },
                          {
                            title: 'Ghi chú',
                            dataIndex: 'ghichu',
                            key: 'ghichu',
                            width: 300,
                            render: (text, record, index) => (
                              <Input
                                className="w-full"
                                size="small"
                                value={text}
                                onChange={(e) => {
                                  setListXuatVTHH((prev) =>
                                    prev?.map((item) =>
                                      item?.key === record?.key
                                        ? { ...item, ghichu: e.target.value }
                                        : item,
                                    ),
                                  )
                                }}
                              />
                            ),
                          },
                          {
                            title: '',
                            dataIndex: 'action',
                            key: 'action',
                            width: 40,
                            align: 'center',
                            render: (text, record, index) => (
                              <CloseOutlined
                                className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
                                onClick={() =>
                                  setListXuatVTHH((prev) =>
                                    prev?.filter((item) => item?.key !== record?.key),
                                  )
                                }
                              />
                            ),
                          },
                        ]}
                        dataSource={listXuatVTHH}
                        bordered
                      />
                    </ConfigProvider>
                  </>
                ),
              },
              {
                key: 2,
                label: (
                  <div className="flex items-center  gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                      />
                    </svg>
                    <div className="flex gap-1 items-start">
                      Đính kèm
                      {selectedFile?.length > 0 && (
                        <span className="font-semibold text-white bg-red-500 text-[10px] pl-[0.35rem] pr-[0.35rem] border border-red-500 rounded-full">
                          {selectedFile?.length}
                        </span>
                      )}
                    </div>
                  </div>
                ),

                children: (
                  <>
                    <ul className="flex flex-wrap gap-2 mb-2">
                      {selectedFile?.map((items, index) => (
                        <li
                          key={`upHinh${index}`}
                          className="w-[10rem] h-[10rem] overflow-hidden border rounded-md relative"
                        >
                          <DeleteOutlined
                            onClick={() => deleteFileById(items.id)}
                            className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                            style={{ fontSize: 18 }}
                          />
                          <a href={items?.URL} target="_blank">
                            <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                              <div className="relative "></div>
                              <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                                {items.name}
                              </p>
                            </div>
                            <img src={items.base64} className="w-full" alt={items.name} />
                          </a>
                        </li>
                      ))}
                    </ul>
                    <div
                      style={{
                        border: '2px dashed #cccccc',
                        borderRadius: '4px',
                        padding: '30px',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      className="border"
                      ref={dropzoneRef}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      onClick={handleDivClick}
                    >
                      <CloudUploadOutlined style={{ fontSize: 36 }} className="text-gray-500" />
                      <label style={{ cursor: 'pointer' }}>
                        <p className="font-semibold text-lg text-gray-500">
                          Thả tập tin vào đây hoặc bấm vào để tải lên.
                        </p>
                      </label>
                      <input
                        ref={fileInputRef}
                        id="fileInput"
                        type="file"
                        onChange={handleFileSelect}
                      />
                    </div>
                  </>
                ),
              },
            ]}
          />
        </div>
        <div className="mt-5 flex gap-5 justify-end">
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="button"
            onClick={handleSave}
            startIcon={<SaveOutlined />}
          >
            Lưu
          </Button>
        </div>
      </form>
    </div>
  )
}

export default LapPhieuXuat
