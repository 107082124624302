import React, { useEffect, useState, useRef, useCallback } from 'react'
import LayoutApp from '../../../HOCs/LayoutApp'
import {
  ConfigProvider,
  Pagination,
  Table,
  Button,
  Space,
  Input,
  Select,
  Tooltip,
  Checkbox,
  message,
} from 'antd'
import {
  SearchOutlined,
  PlusCircleOutlined,
  SyncOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import GetAppIcon from '@mui/icons-material/GetApp'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../services/apiService'
import { nhanvienService } from '../../../services/nhanvien/nhanvienService'
import { phanQuyenService } from '../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../store/actions/phanQuyenAction'
import moment from 'moment'
import AddStaff from './modal/AddStaff'
import EditStaff from './modal/EditStaff'
import SeeEraStaff from './modal/modal2/SeeEraStaff'
import ToastCus from '../../../utils/Toast'
import * as XLSX from 'xlsx'
import _, { debounce } from 'lodash'
import 'moment/locale/vi'
import { addLog } from '../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../utils/log/logConstant'
moment.locale('vi')
const { Search } = Input
const Staff = () => {
  const dispatch = useDispatch()
  const { stafs, PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const [listNV, setListNV] = useState([])
  // State tìm kiếm, phân trang
  const [searchKeyword, setSearchKeyWord] = useState('') // lưu trữ từ khóa tìm kiếm.
  const [currentPage, setCurrentPage] = useState(1) // lưu trữ trang all DSNV.
  const [pageSize, setPageSize] = useState(20) // Số lượng hiển thị trên mỗi trang.
  const [nextPage, setNextPage] = useState(1) // lưu trữ DSNV by CN
  // ============
  const [companies, setCompanies] = useState([]) // danh sách côngty
  const [branch, setBranch] = useState([]) // chi nhánh thuộc côngty
  const [idCT, setIdCT] = useState('all') // ID của công ty được chọn.
  const [idCN, setIdCN] = useState(null) // ID chi nhánh
  const [positionData, setPositionData] = useState()
  // State modal
  const [isModalOpen, setIsOpen] = useState(false) // modal thêm
  const [isEdit, setItEdit] = useState(false) // modal sửa
  const [isViewOpen, setViewOpen] = useState(false) // modal xem
  const [isErase, setIsErase] = useState(false) // modal xoá
  const [editingRecord, setTingRecord] = useState(null) // lưu thông tin thêm, sửa
  const [viewingRecord, setViewingRecord] = useState(null) // lưu thông tin xem
  const [deleteRecordId, setDeleteId] = useState(null) // xoá idnv
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const { Option } = Select

  const getListNhanVien = async (currentPage, searchKeyword, idCN, idCT) => {
    setLoading(true)
    const nameCompany = companies.find((c) => c.id === idCT)
    const nameChiNhanh = branch.find((c) => c.idChiNhanh === idCN)
    const detailForLog = {
      companyName: idCT === 'all' ? 'Tất cả' : nameCompany?.name,
      branchName: idCN === null ? 'Tất cả' : nameChiNhanh?.tenChiNhanh,
      keyword: searchKeyword ?? '',
      page: currentPage,
    }
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT005',
      )
      let idct = ''
      if (idCT !== 'all') {
        idct = idCT
      }
      const { data } = await https.get(`NhanVien/SearchNhanVien`, {
        params: { keyword: searchKeyword, idcn: idCN, idct, pageIndex: currentPage },
      })
      setPage(page)
      setTotalPages(data.totalPages)
      setListNV((prev) => [...prev, ...data.data])

      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_DS_NHAN_VIEN,
        true,
        MENU_CONSTANTS.NHAN_VIEN,
        null,
        detailForLog,
      )
    } catch (error) {
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.NHAN_VIEN,
        error?.message,
        detailForLog,
      )
    } finally {
      setLoading(false)
    }
  }

  // Lấy dữ liệu từ API
  const fetchData = async (url, params) => {
    try {
      setLoading(true)
      const result = await https.get(url, { params })
      setLoading(false)
      return result.data
    } catch (err) {
      setLoading(false)
      console.error(err)
      message.error('Không thể lấy dữ liệu từ API')
      return null
    }
  }
  const fetchCompanies = async () => {
    try {
      const data = await fetchData('CongTy')
      if (data) {
        const mappedCompanies = data.map((company) => ({
          id: company.idct,
          name: company.tenct,
        }))
        setCompanies(mappedCompanies)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // Hàm lấy danh sách công ty
  useEffect(() => {
    fetchCompanies()
  }, [])
  // Danh sách chi nhánh theo công ty
  async function Branch(idCT) {
    try {
      const result = await https.get(`ChiNhanh`, {
        params: { idCT },
      })
      setBranch(result.data)
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    if (idCT !== 'all') {
      Branch(idCT)
    }
  }, [idCT])

  // Xử lý khi chọn công ty
  const handleCompany = (value) => {
    setIdCT(value)
    setIdCN(null) // Reset chi nhánh khi thay đổi cty
  }
  // Xử lý khi chọn chi nhánh
  const handleBranch = (value) => {
    if (value === 'all') {
      setIdCN(null)
      // fetchListAndSearch(currentPage, searchKeyword, null)
    } else {
      setIdCN(value)
      // fetchListAndSearch(currentPage, searchKeyword, value)
    }
    setCurrentPage(1)
    setNextPage(1)
  }

  // Hàm lấy chức danh
  const fetchPosition = async () => {
    try {
      const result = await nhanvienService.getTitle()
      setPositionData(result.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchPosition()
  }, [])
  // Button thêm nhân viên, đóng-mở form sau khi thêm
  const handleAddStaff = () => {
    setIsOpen(true)
  }
  const cancelAddModal = () => {
    setIsOpen(false)
  }
  // Button sửa nhân viên, đóng-mở form sau khi sửa
  const handleEditRecord = async (record) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT004',
      )
      setTingRecord(record)
      setItEdit(true)
    } catch (error) {
      setTingRecord(null)
      setItEdit(false)
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }

    // setTingRecord(record)
    // setItEdit(true)
  }
  const cancelEditModal = () => {
    setItEdit(false)
  }

  const cancelSeeModal = () => {
    setViewOpen(false)
    setViewingRecord(null)
  }
  // Hàm mở modal xóa và thiết lập deleteRecordId, đóng-mở form sau khi xóa
  const handleEraseRecord = (item) => {
    setDeleteId(item) // Thiết lập deleteRecordId với idnv cần xóa
    setIsErase(true)
  }
  const cancelEraseModal = () => {
    setIsErase(false)
    setDeleteId(null)
  }
  // gọi lại DSIDNV
  const onSuccess = () => {
    handleSearch(searchKeyword, idCN, idCT)
  }

  const exportToExcel = async (searchKeyword, idCN, idCT) => {
    const nameCompany = companies.find((c) => c.id === idCT)
    const nameChiNhanh = branch.find((c) => c.idChiNhanh === idCN)
    const detailForLog = {
      companyName: idCT === 'all' ? 'Tất cả' : nameCompany?.name,
      branchName: idCN === null ? 'Tất cả' : nameChiNhanh?.tenChiNhanh,
      keyword: searchKeyword ?? '',
    }
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT000',
      )
      const headers = [
        'Số thứ tự',
        'Mã nhân viên',
        'Tên tắc chức danh',
        'Tên nhân viên',
        'Giới tính',
        'Ngày sinh',
        // 'Số điện thoại',
        'Bằng cấp',
        'Chức danh',
        'Địa chỉ',
        'Phường(xã)',
        'Quận(huyện)',
        'Tỉnh(TP)',
        'Tên chi nhánh',
        'Tên công ty',
        'Người tạo',
        'Ngày tạo',
        'Người sửa',
        'Ngày sửa',
        'Đã nghỉ việc',
        'Tên máy chấm công',
        'Ghi chú',
      ]
      let idct = ''
      if (idCT !== 'all') {
        idct = idCT
      }
      const { data } = await https.get(`NhanVien/SearchNhanVienXuatExcel`, {
        params: {
          keyword: searchKeyword,
          idcn: idCN,
          idct,
        },
      })
      const dataToExport = data?.map((item, index) => ({
        'Số thứ tự': index + 1 || '',
        'Mã nhân viên': item.manv || '',
        'Tên tắc chức danh': item?.chucdanhviettat || '',
        'Tên nhân viên': item.tennv || '',
        'Ngày sinh': item.ngaysinh ? moment(item.ngaysinh).format('DD/MM/YYYY') : '',
        'Giới tính': item.gioitinh || '',
        // 'Số điện thoại': item.sodienthoai || '',
        'Bằng cấp': item.bangcap || '',
        'Chức danh': item.tenchucdanh || '',
        'Địa chỉ': item.diachi || '',
        'Phường(xã)': item.tenphuong || '',
        'Quận(huyện)': item.tenquan || '',
        'Tỉnh(TP)': item.tentinh || '',
        'Tên chi nhánh': item.tenchinhanh || '',
        'Tên công ty': item.tencongty || '',
        'Người tạo': item.nguoitao || '',
        'Ngày tạo': item.ngaytao ? moment(item.ngaytao).format('DD/MM/YYYY HH:mm') : '',
        'Người sửa': item.nguoisua || '',
        'Ngày sửa': item.ngaysua ? moment(item.ngaysua).format('DD/MM/YYYY HH:mm') : '',
        'Đã nghỉ việc': item?.thoiviec === 1 ? 'Đã nghỉ việc' : '',
        'Tên máy chấm công': item.tenmaychamcong || '',
        'Ghi chú': item.ghichu || '',
      }))
      const worksheet = XLSX.utils.json_to_sheet(dataToExport, { header: headers })
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, worksheet, 'Staff List')
      XLSX.writeFile(wb, `Danh Sách Nhân Viên - ${moment(new Date()).format('DD-MM-YYYY')}.xlsx`)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.XUAT_EXCEL_DS_NHAN_VIEN,
        true,
        MENU_CONSTANTS.NHAN_VIEN,
        null,
        detailForLog,
      )
    } catch (error) {
      console.error('Lỗi khi xuất dữ liệu : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.NHAN_VIEN,
        error?.message,
        detailForLog,
      )
    }
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      align: 'center',
      width: 45,
      fixed: 'left',
    },
    {
      title: 'Mã NV',
      dataIndex: 'manv',
      key: 'manv',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Tên tắt chức danh',
      dataIndex: 'chucdanhviettat',
      key: 'chucdanhviettat',
      width: 140,
      fixed: 'left',
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'tennv',
      key: 'tennv',
      width: 250,
      fixed: 'left',
    },
    {
      title: 'Giới tính',
      dataIndex: 'gioitinh',
      key: 'gioitinh',
      align: 'center',
      width: 70,
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaysinh',
      key: 'ngaysinh',
      align: 'center',
      width: 90,
      // Kiểm tra nếu giá trị ngày sinh hợp lệ
      render: (ngaysinh) => (ngaysinh ? moment(ngaysinh).format('DD/MM/YYYY') : null),
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diachi',
      key: 'diachi',
      render: (text, record) => {
        const { tentinh, tenquan, tenphuong } = record
        const addressParts = [text, tenphuong, tenquan, tentinh].filter((part) => part)
        const fullAddress = addressParts.join(', ')
        return <span>{fullAddress}</span>
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'nguoitao',
      key: 'nguoitao',
      width: 200,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'ngaytao',
      key: 'ngaytao',
      align: 'center',
      width: 140,
      render: (ngaytao) => (ngaytao ? moment(ngaytao).format('DD/MM/YYYY HH:mm:ss') : null),
    },
    {
      title: 'Người sửa',
      dataIndex: 'nguoisua',
      key: 'nguoisua',
      width: 200,
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaysua',
      key: 'ngaysua',
      align: 'center',
      width: 140,
      render: (ngaysua) => (ngaysua ? moment(ngaysua).format('DD/MM/YYYY HH:mm:ss') : null),
    },
    {
      title: 'Chức danh',
      dataIndex: 'idchucdanh',
      key: 'idchucdanh',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (id) => positionData?.find((item) => item.idcd === id)?.tenchucdanh || id,
    },
    {
      title: 'Đã nghỉ việc',
      dataIndex: 'thoiviec',
      key: 'thoiviec',
      align: 'center',
      fixed: 'right',
      width: 90,
      render: (thoiviec) => <Checkbox checked={thoiviec === 1} />,
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      width: 100,
      fixed: 'right',
      render: (text, record) => (
        <div className="flex justify-around items-center">
          {/* <Tooltip
            title="Xem thông tin nhân viên"
            color="#1890ff"
            overlayInnerStyle={{ fontSize: '13px' }}
          >
            <EyeOutlined
              style={{ color: '#1890ff', fontSize: '18px', cursor: 'pointer' }}
              onClick={() => handleViewRecord(record)}
            />
          </Tooltip> */}
          <Tooltip
            title="Sửa thông tin nhân viên"
            color="#52c41a"
            overlayInnerStyle={{ fontSize: '13px' }}
          >
            <EditOutlined
              style={{ color: '#52c41a', fontSize: '18px', cursor: 'pointer' }}
              onClick={() => handleEditRecord(record)}
            />
          </Tooltip>
          <Tooltip
            title="Xoá thông tin nhân viên"
            color="#ff4d4f"
            overlayInnerStyle={{ fontSize: '13px' }}
          >
            <DeleteOutlined
              style={{ color: '#ff4d4f', fontSize: '18px', cursor: 'pointer' }}
              onClick={() => handleEraseRecord(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  const handleScroll = useCallback(
    debounce(() => {
      //api search return all data not pagination
      // disable scroll when search filtering
      const tableBody = document.querySelector('.table-nhanvien .ant-table-body')
      if (
        tableBody.scrollTop + tableBody.clientHeight >= tableBody.scrollHeight - 100 &&
        !loading
      ) {
        if (page + 1 > totalPages) {
          return
        }
        setPage((prevPage) => prevPage + 1)
      }
    }, 300),
    [loading, page, totalPages],
  )

  useEffect(() => {
    const tableBody = document.querySelector('.table-nhanvien .ant-table-body')
    tableBody.addEventListener('scroll', handleScroll)
    return () => tableBody.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  useEffect(() => {
    if (infoUser) {
      getListNhanVien(page, searchKeyword, idCN, idCT)
    }
  }, [page, infoUser])

  const handleSearch = (search, idCN, idCT) => {
    setListNV([])
    getListNhanVien(1, search, idCN, idCT)
  }

  return (
    <>
      <div className="h-full p-3 bg-[#EFEFEF]">
        <div className="bg-white rounded-xl p-2">
          <div className="flex flex-col md:flex-row justify-between mb-4 gap-4">
            <div className="flex flex-col md:flex-row gap-2" style={{ flex: 2 }}>
              <Select
                style={{ width: '100%', maxWidth: '260px' }}
                name="tenct"
                placeholder="Chọn công ty"
                onChange={handleCompany}
                value={idCT}
                defaultValue={'all'}
              >
                {[{ name: 'Tất cả', id: 'all' }, ...companies].map((company) => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
              </Select>
              <Select
                disabled={idCT === 'all'}
                style={{ width: '100%', maxWidth: '250px' }}
                name="tenchinhanh"
                placeholder="Chọn chi nhánh"
                showSearch
                onChange={handleBranch}
                value={idCN || 'all'}
                options={[
                  { label: 'Tất cả', value: 'all' },
                  ...(branch?.map((branch) => ({
                    label: branch.tenChiNhanh,
                    value: branch.idChiNhanh,
                  })) || []),
                ]}
                filterOption={(input, option) =>
                  option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
              <Input
                placeholder="Tìm kiếm nhân viên..."
                className="w-[250px]"
                onChange={(e) => setSearchKeyWord(e.target.value)}
              />
              <Button type="primary" onClick={() => handleSearch(searchKeyword, idCN, idCT)}>
                <SearchOutlined />
              </Button>
            </div>
            <div className="flex flex-col md:flex-row gap-2">
              <Button
                onClick={() => exportToExcel(searchKeyword, idCN, idCT)}
                type="default"
                className="rounded-lg border border-gray-300 hover:border-gray-400"
                icon={<GetAppIcon />}
              >
                <span>Xuất Excel</span>
              </Button>
              <Button
                type="primary"
                className="rounded-lg bg-blue-500 hover:bg-blue-600"
                icon={<PlusCircleOutlined />}
                onClick={handleAddStaff}
              >
                <span>Thêm nhân viên</span>
              </Button>
              {/* <Button type="primary" icon={<SyncOutlined />} onClick={onReload}>
                Tải lại
              </Button> */}
            </div>
          </div>

          <ConfigProvider
            renderEmpty={() => <div>Không tìm thấy nhân viên</div>}
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              rowKey="id"
              scroll={{
                x: 2000,
                y: 700,
              }}
              className="table-nhanvien"
              bordered
              pagination={false}
              columns={columns}
              loading={loading}
              dataSource={listNV?.map((items, index) => ({
                STT: ++index,
                key: index,
                id: items?.id,
                manv: items?.manv,
                tennv: items?.tennv,
                diachi: items?.diachi,
                dienthoai: items?.dienthoai,
                idtinh: items?.idtinh,
                idquan: items?.idquan,
                idphuong: items?.idphuong,
                idchinhanh: items?.idchinhanh,
                nguoitao: items?.nguoitao,
                ngaytao: items.ngaytao,
                idchucdanh: items?.idchucdanh,
                ghichu: items?.ghichu,
                idbangcap: items?.idbangcap,
                tenmaychamcong: items?.tenmaychamcong,
                ngaysinh: items?.ngaysinh,
                gioitinh: items?.gioitinh,
                thoiviec: items?.thoiviec,
                nguoisua: items?.nguoisua,
                ngaysua: items?.ngaysua,
                idct: items?.idct,
                tencongty: items?.tencongty,
                tenchinhanh: items?.tenchinhanh,
                tentinh: items?.tentinh,
                tenquan: items?.tenquan,
                tenphuong: items?.tenphuong,
                tenchucdanh: items?.tenchucdanh,
                bangcap: items?.bangcap,
                chucdanhviettat: items?.chucdanhviettat,
              }))}
            />
          </ConfigProvider>
        </div>
      </div>
      {isModalOpen && (
        <AddStaff
          stafs={stafs}
          visible={isModalOpen}
          cancelAddModal={cancelAddModal}
          setModalVisibility={setIsOpen}
          setPage={setCurrentPage}
          pageSize={pageSize}
          modifyTing={setTingRecord} // lưu thông tin thêm vào.
          onAddSuccess={onSuccess}
        />
      )}
      {isEdit && (
        <EditStaff
          editMode={isEdit}
          cancelEditModal={cancelEditModal}
          updateEditState={setItEdit}
          record={editingRecord} // Lưu thông tin chỉnh sửa.
          onEditSuccess={onSuccess}
        />
      )}
      {isViewOpen && (
        <SeeEraStaff
          togglePanel={isViewOpen}
          cancelSeeModal={cancelSeeModal}
          view={viewingRecord} // lưu trữ thôg tin được xem.
          professional={positionData} // Truyền positionData như một prop
          branch={branch}
        />
      )}
      {isErase && (
        <SeeEraStaff
          isErase={isErase}
          cancelEraseModal={cancelEraseModal}
          setIsErase={setIsErase}
          deleteIdnv={deleteRecordId} // Truyền deleteRecordId
          setDeleteIdnv={setDeleteId} // Truyền setDeleteId
          onEraseSuccess={onSuccess}
        />
      )}
    </>
  )
}

export default Staff
