import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Modal } from 'antd'
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import ToastCus from '../../../../utils/Toast'
import { https } from '../../../../services/apiService'
import * as typeAction from '../../../../store/constants/constants'

function ModalUploadMultiFile({
  openUploadMultiFile,
  setUploadMultiFile,
  setReload,
  listFile,
  setListFile,
}) {
  const { infoUser } = useSelector((state) => state.userReducer)
  const [filesShow, setFilesShow] = useState(listFile || [])
  const [loading, setLoading] = useState(false)

  const handleCancel = () => {
    setUploadMultiFile(false)
    setFilesShow([])
    setListFile([])
  }

  const debounceDropDown = useCallback(
    debounce((keyword, listFile) => {
      const listFileSearch = listFile.filter((file) =>
        file.name.toLowerCase().includes(keyword.toLowerCase()),
      )
      setFilesShow(listFileSearch)
    }, 300),
    [],
  )

  const handleSearch = (e, listFile) => {
    const { value } = e.target
    debounceDropDown(value, listFile)
  }

  const handleFileRemove = (fileToRemove) => {
    setListFile((prevFiles) => prevFiles.filter((file) => file !== fileToRemove))
    setFilesShow((prevFiles) => prevFiles.filter((file) => file !== fileToRemove))
  }

  const handleSubmit = async () => {
    setLoading(true)
    const formData = new FormData()
    for (let i = 0; i < listFile.length; i++) {
      formData.append('files', listFile[i])
    }
    try {
      const res = await https.post(
        `CRMBenhNhan/UpdateFilesForPatients?nguoiKQ=${infoUser.idnv}`,
        formData,
      )
      const { existCount, notFoundCount, successCount } = res.data
      ToastCus.fire({
        icon: 'info',
        html: `
          <div class="flex flex-col gap-2">
      <div class="flex items-center text-green-600">
          <svg class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
          </svg>
          Upload thành công ${successCount} file
        </div>
      <div class="flex items-center text-red-600">
          <svg class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"/>
          </svg>
          Upload thất bại ${notFoundCount} file
        </div>
      <div class="flex items-center text-yellow-600">
          <svg class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"/>
          </svg>
          Đã tồn tại ${existCount} file
        </div>
    </div>
        `,
        timer: 5000,
      })
      handleCancel()
      setReload(true)
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data || 'Thao tác thất bại!',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Upload file kết quả khám hàng loạt"
      open={openUploadMultiFile}
      onOk={handleSubmit}
      onCancel={handleCancel}
      okText="Upload"
      cancelText="Huỷ bỏ"
      okButtonProps={{
        disabled: listFile.length === 0,
      }}
      loading={loading}
    >
      <h2 className="text-left font-semibold">Danh sách file kết quả:</h2>
      <Input
        placeholder="Nhập từ khoá tìm kiếm"
        suffix={<SearchOutlined />}
        onChange={(e) => handleSearch(e, listFile)}
      />
      <div className="max-h-80 overflow-y-scroll mt-2">
        {filesShow.length > 0 && (
          <div className="mt-4">
            <ul>
              {filesShow.map((file, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center mt-4 gap-4 border-b border-gray-200 pb-2"
                >
                  <span>{file.name}</span>

                  <DeleteOutlined
                    className="p-1 rounded-sm bg-red-500 text-white cursor-pointer"
                    onClick={() => handleFileRemove(file)}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Modal>
  )
}

ModalUploadMultiFile.propTypes = {}

export default ModalUploadMultiFile
