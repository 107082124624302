import { ConfigProvider, Pagination, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import FilterButton from './Filter/FilterButton'
import { https } from '../../../services/apiService'
import moment from 'moment'
import { addLog } from '../../../utils/log/apiLog'
import { useSelector } from 'react-redux'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../utils/log/logConstant'

const columns = [
  {
    title: 'STT',
    width: 80,
    align: 'center',
    fixed: 'left',
    dataIndex: 'stt',
    key: 'stt',
  },
  {
    title: 'Tài khoản',
    dataIndex: 'tentaikhoan',
    key: 'tentaikhoan',
    width: 100,
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Người dùng',
    dataIndex: 'tennguoidung',
    key: 'tennguoidung',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Chi nhánh',
    dataIndex: 'tenchinhanh',
    key: 'tenchinhanh',
    width: 200,
  },
  {
    title: 'Địa chỉ MAC',
    dataIndex: 'ipmac',
    key: 'ipmac',
    width: 150,
    align: 'center',
  },
  {
    title: 'Địa chỉ IP',
    dataIndex: 'ip',
    key: 'ip',
    width: 200,
    align: 'center',
  },
  {
    title: 'Thời gian',
    dataIndex: 'thoigian',
    key: 'thoigian',
    width: 150,
    align: 'center',
    render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
  },
  {
    title: 'Hành động',
    dataIndex: 'hanhdong',
    key: 'hanhdong',
    width: 100,
    align: 'center',
  },
  {
    title: 'Đối tượng',
    dataIndex: 'doituong',
    key: 'doituong',
    width: 200,
    align: 'center',
  },
  {
    title: 'Mô tả',
    dataIndex: 'mota',
    key: 'mota',
    render: (text) => (
      <div
        style={{
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap', // Preserve white spaces and line breaks
        }}
        dangerouslySetInnerHTML={{
          __html: text
            ? text.replace(/\n/g, '<br />').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
            : '',
        }}
      />
    ),
  },
  {
    title: 'Trạng thái',
    dataIndex: 'trangthai',
    key: 'trangthai',
    align: 'center',
    fixed: 'right',
    width: 100,
    render: (text, record) =>
      record.trangthai === 1 ? (
        <span style={{ color: 'green' }}>Thành công</span>
      ) : (
        <span style={{ color: 'red' }}>Thất bại</span>
      ),
  },
]

function LichSuNguoiDung(props) {
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])


  const getAllLichSuNguoiDung = async (idchinhanh, keyword, datefrom, dateto, page) => {
    setLoading(true)
    const { data } = await https.get(`ChiNhanh/GetChiNhanh/${idchinhanh}`)
    const detailForLog = {
      branchName: data.tenChiNhanh,
      keyword: keyword ?? '',
      page: page,
      dateFrom: moment(datefrom).format('DD-MM-YYYY'),
      dateTo: moment(datefrom).format('DD-MM-YYYY'),
    }
    try {
      const { data } = await https.get('LogLichSu/GetLogLichSuByKeywordDate', {
        params: {
          keyword,
          idchinhanh,
          page,
          datefrom,
          dateto,
          pageSize: 100, //set page size = 100
        },
      })
      setData(data.data)
      setTotalPages(data.totalPage)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XEM_LICH_SU_NGUOI_DUNG,
        true,
        MENU_CONSTANTS.LICH_SU_NGUOI_DUNG,
        null,
        detailForLog,
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.LICH_SU_NGUOI_DUNG,
        detailErrorPayload,
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="p-2 bg-[#E8E8E8] h-full ">
      <div
        className="bg-white p-2 h-full rounded-md"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <div className="w-full">
          <div className="p-2">
            <FilterButton
              setPage={setPage}
              page={page}
              fetchLichSuNguoiDung={getAllLichSuNguoiDung}
            />
          </div>

          {/* <Button
            loading={isLoadingExport}
            onClick={exportToExcel}
            className="text-green-700 font-semibold"
            type="default"
          >
            Excel <ImportExportIcon />
          </Button> */}
        </div>
        <div className="mt-5">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
                fontSize: 13,
              },
            }}
          >
            <Table
              // virtual

              loading={loading}
              pagination={false}
              scroll={{
                y: 700,
                x: 2500,
              }}
              bordered
              className="mb-5n"
              dataSource={data?.map((item, index) => ({
                ...item,
                key: index,
                stt: (page - 1) * 100 + (index + 1),
              }))}
              columns={columns}
              rowClassName={(record) => (record.trangthai === 0 ? 'text-red-500' : '')}
            />
          </ConfigProvider>
          <div className="flex justify-end">
            <Pagination
              className="mt-5"
              showSizeChanger={false}
              current={page}
              simple
              defaultCurrent={1}
              pageSize={100}
              total={100 * totalPages}
              onChange={(page, pagesize) => {
                setPage(page)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

LichSuNguoiDung.propTypes = {}

export default LichSuNguoiDung
