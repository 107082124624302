export function CalulateGPP(giaMua) {
    let thangSoBanLe;

    // Xác định thặng số bán lẻ (%) dựa trên giá mua
    if (giaMua <= 1000) {
        thangSoBanLe = 0.15; // 15%
    } else if (giaMua > 1000 && giaMua <= 5000) {
        thangSoBanLe = 0.10; // 10%
    } else if (giaMua > 5000 && giaMua <= 100000) {
        thangSoBanLe = 0.07; // 7%
    } else if (giaMua > 100000 && giaMua <= 1000000) {
        thangSoBanLe = 0.05; // 5%
    } else {
        thangSoBanLe = 0.02; // 2%
    }

    // Tính GPP theo công thức
    const gpp = giaMua * (1 + thangSoBanLe);

    return +Math.round(gpp); // Làm tròn đến 2 chữ số thập phân trả về dạng số 
}
