import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { xuatNoiBoService } from '../../services/xuatNoiBo/xuatNoiBoService'
import ToastCus from '../../utils/Toast'
import { generatePhieuXuatBanKhachHang } from '../../utils/report/phieuThuTienXuatBanKhachHang'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'

export function* xuatNoiBoSaga() {
  // POST PHIẾU XUẤT NỘI BỘ
  yield takeLatest(
    typeAction.POST_PHIEU_XUAT_NOI_BO,
    function* postPhieuXuatNoiBoSaga({ type, form, list, infoBN, resetForm }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data: infoPhieu } = yield call(() => xuatNoiBoService.postPhieuXuatNoiBo(form))
        const formatData = list?.map((item) => ({
          idthuoc: item?.idthuoc,
          tenthuoc: item?.tenbietduoc,
          // tenhoatchat: item?.tenhoatchat,
          solo: item?.solo,
          handung: item?.handung,
          sohoadon: item?.sohoadon,
          soluong: item?.soluong,
          dongia: item?.dongia,
          thanhtien: item?.dongia * item?.soluong,
          tiengiamgia: 0,
          tienthanhtoan: item?.dongia * item?.soluong,
          // masodangky: item?.masodangky,
          ghichu: item?.ghichu,
          stt: item?.stt,
          mathuoc: item?.mathuoc,
          dvt: item?.dvt,
          idxnb: infoPhieu?.idxnb,
        }))
        const result = yield call(() => xuatNoiBoService.postListVTHH(formatData))
        yield call(() => resetForm())
        //////////////////////////////////////////////
        yield call(() =>
          generatePhieuXuatBanKhachHang(
            { ...infoPhieu, tenbenhnhan: infoBN?.tenBenhNhan, mabenhnhan: infoBN?.maBenhNhan },
            result.data,
            PkDangNhap,
          ),
        )

        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.TAO_PHIEU_BAN_NOI_BO,
          true,
          MENU_CONSTANTS.BANNOIBO,
          null,
          {
            tenBenhNhan: infoBN?.tenBenhNhan,
            maBenhNhan: infoBN?.maBenhNhan,
          },
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.BANNOIBO,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
