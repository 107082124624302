import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import * as typeAction from '../../../../store/constants/constants'
import { Select, DatePicker, Button, TreeSelect } from 'antd'
import { useSelector } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import TableList from './TableList'
import { useDispatch } from 'react-redux'
import ModalDetail from '../Modal/ModalDetail'
import { branchNhapKhoPT, filterPTNhapKhoAction } from '../../../../store/actions/NhapKhoAction'
import { listBranchAction } from '../../../../store/actions/BranchAction'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
import { addLog } from '../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../utils/log/logConstant'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const ListKho = ({ disablePrice }) => {
  const now = new Date()
  const dateFormat = 'DD-MM-YYYY'
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const dispatch = useDispatch()
  const [searchValues, setSearchValues] = useState({})
  const [chuyenkhoas, setChuyenKhoas] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { BranchPT } = useSelector((state) => state.NhapKhoReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [valueExport, setValueExport] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [since, setSince] = useState(now)
  const [toDate, SetToDate] = useState(now)
  const [company, setCompany] = useState(id_company)
  const [idChiNhanh, setIdChiNhanh] = useState([Number(BranchLogin)])
  const [show, setShow] = useState(false)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  // disablePrice ? menu chuyển kho : menu nhập kho
  const maQuyenXemDs = disablePrice ? 'QHT191' : 'QHT165'
  const maQuyenXuatExcelDs = disablePrice ? 'QHT192' : 'QHT608'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        let idcn = ''
        if (idChiNhanh[0] !== 'all') {
          idcn = idChiNhanh?.map((id) => `&idChiNhanh=${id}`).join('&')
        } else {
          idcn = BranchPT?.map((id) => `&idChiNhanh=${id.idChiNhanh}`).join('&')
        }
        dispatch(filterPTNhapKhoAction({ ...filter, idChiNhanh: idcn }))
        break
      case maQuyenXuatExcelDs:
        exportToExcel()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXuatExcelDs:
        //
        break
      default:
        return false
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setShow(false)
    setIsModalOpen(false)
    // tra về kho chi iết ban đầu khi cancel
    dispatch({
      type: typeAction.DISPATCH_INFO_THUOVT_NHAP_KHO,
      payload: [],
    })
    dispatch({
      type: typeAction.DISPATCH_INFO_PT_NHAP_KHO,
      payload: null,
    })
  }
  // set ngày lại từ ngày
  const handleSince = (name) => (date, dateString) => {
    if (name === 'since') {
      setSince(dateString)
    } else {
      SetToDate(dateString)
    }
  }
  const handleCompany = (value) => {
    setCompany(value)
    dispatch(branchNhapKhoPT(value, idChuyenKhoa))
    setIdChiNhanh(null)
  }
  const filter = useMemo(() => {
    return {
      since: moment(since, dateFormat).format('YYYY-MM-DD'),
      toDate: moment(toDate, dateFormat).format('YYYY-MM-DD'),
      idChiNhanh: idChiNhanh,
      idCongTy: company,
      nameCompany: listCompany.find((item) => item.idct === company)?.tenct,
      nameChiNhanh:
        idChiNhanh === null
          ? 'Tất cả'
          : BranchPT?.find((item) => item.idChiNhanh === idChiNhanh)?.tenChiNhanh,
    }
  }, [since, toDate, idChiNhanh, company, BranchPT, listCompany])
  // xử lí chọn chi nhánh
  const handleSelected = (value) => {
    setIdChiNhanh(value)
  }
  // xử lí lọc dưc liệu
  const handleFilter = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  const filteredData = (
    data, // lọc giá trị search
  ) =>
    data?.filter((item) =>
      Object.entries(searchValues).every(([key, value]) =>
        item[key]?.toLowerCase().includes(value.toLowerCase()),
      ),
    )
  //-------- export excel-----------//
  const exportToExcel = () => {
    try {
      const formattedData = filteredData(valueExport)?.map((item) => ({
        'Mã phiếu': item.maPhieu || '',
        'Tên phiếu': item.tenPhieu || '',
        'Người nhập': item.tenNVNhan || '',
        'Ngày nhập': item.ngayNhan ? moment(item.ngayNhan).format('DD/MM/YYYY') : '',
        'Chi nhánh': item.tenChiNhanhNhan || '',
        'Kho nhập': item.tenKhoNhap || '',
        'Số hoá đơn': item.soHoaDon || '',
        'Ngày hoá đơn': item.ngayHoaDon ? moment(item.ngayHoaDon).format('DD/MM/YYYY') : '',
        'Nhà cung cấp': item.tenDoiTac || '',
        'Tổng thực trả': disablePrice ? 0 : formatNumberVND(Math.round(item.sumThucTra)),
      }))
      const name = 'Danh sách nhâp kho'
      formattedData?.length && exportExcelformat(formattedData, name)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_DS_PHIEU_NHAP,
        true,
        MENU_CONSTANTS.NHAP_KHO,
        null,
        {
          dateFrom: filter.since,
          dateTo: filter.toDate,
          nameCompany: filter.nameCompany,
          nameChiNhanh: filter.nameChiNhanh,
        },
      )
    } catch (error) {
      console.log(error)
      const detailErrorPayload = `Có lỗi xảy ra khi xuất file excel \n ${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.NHAP_KHO,
        detailErrorPayload,
      )
    }
  }
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoas(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    dispatch(branchNhapKhoPT(id_company, ''))
    dispatch(listBranchAction())
    fetchChuyenKhoa()
  }, [])

  // useEffect(() => {
  //   if (BranchPT.length) {
  //     handleFilter()
  //   }
  // }, [BranchPT])
  const onChangSelected = (value) => {
    setIdChuyenKhoa(value)
    setIdChiNhanh(['all'])
    dispatch(branchNhapKhoPT(company, value))
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: BranchPT?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const tProps = {
    treeData,
    value: filter?.idChiNhanh,
    onChange: (value) => setIdChiNhanh(value),
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  return (
    <>
      <div className="flex justify-between mb-5">
        <div className="flex gap-5 items-center">
          <div className="w-80 ">
            <Select
              onChange={handleCompany}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              value={company}
              className="w-full"
            />
          </div>
          <div className="w-56">
            <Select
              onChange={onChangSelected}
              className="w-full"
              value={idChuyenKhoa}
              options={[
                { label: 'Tất cả chuyên khoa', value: '' },
                ...chuyenkhoas.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
            />
          </div>
          <div className="w-64 ">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          {/* <div className="w-80 ">
            <Select
              onChange={handleSelected}
              value={idChiNhanh}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { label: 'Tất cả', value: null },
                ...(BranchPT ?? []).map(({ idChiNhanh, tenChiNhanh }) => ({
                  label: tenChiNhanh,
                  value: idChiNhanh,
                })),
              ]}
              className="w-full"
            />
          </div> */}
          <div className="flex gap-3 items-center">
            <div>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleSince('since')}
                format={dateFormat}
              />
            </div>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
            </svg> */}
            <div>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                minDate={dayjs(since, dateFormat)}
                onChange={handleSince('toDate')}
                format={dateFormat}
              />
            </div>
          </div>
          <div className="font-semibold ">
            <Button
              disabled={!filteredData(valueExport)?.length}
              // onClick={exportToExcel}
              onClick={() =>
                dispatch(
                  putCheckQuyenThaoTacAction(maQuyenXuatExcelDs, null, actionIsTrue, actionIsFalse),
                )
              }
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
          <div
            className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
            onClick={handleFilter}
          >
            <CachedIcon sx={{ fontSize: 25 }} />
          </div>
        </div>
      </div>
      <div className=" h-full">
        <TableList
          disablePrice={disablePrice}
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          setValueExport={setValueExport}
          filteredData={filteredData}
          filter={filter}
          showModal={showModal}
        />
      </div>
      <ModalDetail
        disablePrice={disablePrice}
        handleCancel={handleCancel}
        show={show}
        setShow={setShow}
        isModalOpen={isModalOpen}
      />
    </>
  )
}

export default ListKho
