import React, { useEffect, useState } from 'react'
import FromCreateTemplate from './FormCreateTemplate/FromCreateTemplate'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { List, Modal } from 'antd'
import InfoTemplate from './InfoTemplate/InfoTemplate'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteTemplateSMS,
  fetchAccountSMS,
  getInfoTemplateSMS,
  getList_Template_SMS,
} from '../../../../store/actions/smsAction'
const { confirm } = Modal
const TemplateSMS = () => {
  const { listTemplate } = useSelector((state) => state.smsReducer)
  const [activeIndex, setActiveIndex] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getList_Template_SMS())
    dispatch(fetchAccountSMS())
  }, [])
  const showDeleteConfirm = (item) => {
    confirm({
      title: 'Bạn có chắc muốn xoá template sms?',
      icon: <ExclamationCircleFilled />,
      content: 'Template sms sẽ bị xoá vĩnh viễn: ' + item.tieude || '',
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        dispatch(deleteTemplateSMS(item.idtp))
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
  }
  return (
    <div className="p-2 bg-[#EFEFEF] h-full">
      <div className="p-2 border rounded-lg h-full bg-white">
        <FromCreateTemplate />
        <div className="border rounded-lg mt-2 xl:h-[50vh] h-[590px] flex">
          <div className="xl:w-1/3 w-1/2 border-r h-full p-2  overflow-auto">
            <List
              className="demo-loadmore-list"
              itemLayout="horizontal"
              dataSource={listTemplate}
              renderItem={(item) => (
                <List.Item
                  onClick={() => {
                    setActiveIndex(item.idtp)
                    dispatch(getInfoTemplateSMS(item.idtp))
                  }}
                  className={`${item.idtp === activeIndex && 'bg-[#e6f7ff] border-l-4 border-[#1890ff] border-solid'} cursor-pointer `}
                  actions={[
                    <a
                      key="list-loadmore-more "
                      onClick={() => showDeleteConfirm(item)}
                      className="text-red-500"
                    >
                      Xoá
                    </a>,
                  ]}
                >
                  <List.Item.Meta
                    title={<a href="#">{item.tieude}</a>}
                    description={item.templatE_KHONGDAU}
                  />
                </List.Item>
              )}
            />
          </div>
          <div className="p-5 xl:w-2/3 w-1/2">
            <InfoTemplate />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateSMS
