import { call, put, select, takeLatest } from 'redux-saga/effects'
import moment from 'moment'
import Swal from 'sweetalert2'
import * as typeAction from '../constants/constants'
import { tiemChungService } from '../../services/tiemChung/tiemChungService'
import { drugstoreService } from '../../services/drugstore/drugstoreService'
import { generatePhieuKiemSauTiem } from '../../utils/report/phieuKiemSauTiem'
import { phanQuyenService } from '../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../actions/phanQuyenAction'
import ToastCus from '../../utils/Toast'
import 'moment/locale/vi'
import { addLog } from '../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../utils/log/logConstant'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export function* tiemChungSaga() {
  //LẤY DANH SÁCH BỆNH NHÂN CHỜ TIÊM CHỦNG
  yield takeLatest(
    typeAction.GET_LIST_BN_CHO_TIEMCHUNG,
    function* getListBNChoTCSaga({ type, idcn }) {
      try {
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT565'),
        )

        const result = yield call(() => tiemChungService.getListBNChoTC(idcn))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_CHO_TIEMCHUNG,
          payload: result.data,
        })
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
      }
    },
  )
  //LẤY DANH SÁCH BỆNH NHÂN ĐÃ TIÊM CHỦNG
  yield takeLatest(
    typeAction.GET_LIST_BN_DA_TIEMCHUNG,
    function* getListBNDaTCSaga({ type, idcn, tungay, denngay, nameChiNhanh }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const { infoUser } = yield select((state) => state.userReducer)
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        yield call(() =>
          phanQuyenService.putCheckQuyenThaoTac(infoUser?.taiKhoan ?? userInfo?.taiKhoan, 'QHT566'),
        )

        const result = yield call(() => tiemChungService.getListBNDaTC(idcn, tungay, denngay))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DA_TIEMCHUNG,
          payload: result.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_BN_DA_TIEM_CHUNG,
          true,
          MENU_CONSTANTS.TIEM_CHUNG,
          null,
          {
            dateFrom: tungay,
            dateTo: denngay,
            nameChiNhanh,
          },
        )
      } catch (error) {
        console.error('Error : ', error)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
          yield put(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
          return
        }
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message ?? 'Thao tác thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TIEM_CHUNG,
          detailErrorPayload,
        )
      }
    },
  )
  //LẤY THÔNG TIN BỆNH NHÂN TIÊM CHỦNG
  yield takeLatest(
    typeAction.GET_INFO_BN_TIEMCHUNG,
    function* getInfoBNTiemChungSaga({ type, idbnttc, idcn }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => tiemChungService.getInfoBNTiemChung(idbnttc))
        const infoDetailToaThuoc = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
        const infoTheoDoiSauTiem = yield call(() =>
          tiemChungService.getInfoBNTheoDoiSauTiem(idbnttc),
        )
        const listVtthBn = infoTheoDoiSauTiem?.data?.DanhSachVatTuTieuHao?.filter(
          (item) => item?.idDichVu,
        )
        // const listVT = yield call(() => thuThuatService.getListVTThuThuat(idcn))
        yield put({
          type: typeAction.DISPATCH_INFO_BN_TIEMCHUNG,
          payload: result.data,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM_TC,
          payload: infoDetailToaThuoc.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_THEO_DOI_SAU_TIEM,
          payload: { ...infoTheoDoiSauTiem.data, DanhSachVatTuTieuHao: listVtthBn },
        })
        // yield put({
        //     type: typeAction.DISPATCH_LIST_VT_THUTHUAT,
        //     payload: listVT?.data.filter((items) => items.tonKho > 0).map((items) => ({ ...items, iD: ('' + items.idThuoc + items.soLo + items.hanDung + items.soHoaDon + items.maSoDangKy + items.tonKho) })),
        // })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  //LƯU THÔNG TIN THEO DÕI SAU TIÊM CHỦNG
  yield takeLatest(
    typeAction.POST_INFO_BN_THEO_DOI_SAU_TIEM,
    function* postInfoBNTheoDoiSauTiemSaga({
      type,
      form,
      idcn,
      tungay,
      denngay,
      formPrint,
      resetForm,
      infoBN,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => tiemChungService.postInfoBNTheoDoiSauTiem(form))
        yield call(() => resetForm())
        yield put({
          type: typeAction.DISPATCH_INFO_BN_TIEMCHUNG,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM_TC,
          payload: [],
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_THEO_DOI_SAU_TIEM,
          payload: null,
        })
        yield call(() => generatePhieuKiemSauTiem(formPrint))
        Toast.fire({
          icon: 'success',
          title: 'Lưu thông tin thành công!',
        })
        const resultCho = yield call(() => tiemChungService.getListBNChoTC(idcn))
        const resultDa = yield call(() => tiemChungService.getListBNDaTC(idcn, tungay, denngay))
        yield put({
          type: typeAction.DISPATCH_LIST_BN_CHO_TIEMCHUNG,
          payload: resultCho.data,
        })
        yield put({
          type: typeAction.DISPATCH_LIST_BN_DA_TIEMCHUNG,
          payload: resultDa.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.LUU_BN_THEO_DOI_SAU_TIEM,
          true,
          MENU_CONSTANTS.TIEM_CHUNG,
          null,
          {
            mabn: infoBN.MABENHNHAN,
            tenbn: infoBN.TENBENHNHAN,
          },
        )
      } catch (error) {
        yield console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Lưu thông tin thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.TIEM_CHUNG,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
