import moment from 'moment'
import { logAction } from './logConstant'
import { formatPayloadDetails } from '../algorithm'

/*
// Example usage:
const log = logActionHandler(logAction.CREATE_BENH_NHAN, { mabenhnhan: '001', tenbenhnhan: 'Nguyen Van A', ngaysinh: '01/01/1990' });
Output: Tạo bệnh nhân 001 - Nguyen Van A - 01/01/1990
*/

const logMessages = {
  [logAction.DANG_NHAP]: ({ username }) => `Đăng nhập thành công: tài khoản: ${username}`,
  [logAction.DOI_MAT_KHAU]: ({ id, taikhoan }) =>
    `Đổi mật khẩu idnv: ${id} - tài khoản: ${taikhoan}`,
  [logAction.DANG_KY_KHAM_ONLINE]: ({ dateFrom, dateTo, keyword, listChiNhanh, trangthai }) => {
    return [
      `Xem danh sách đăng ký khám online: từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}", trạng thái: ${trangthai}`,
      `Danh sách chi nhánh: ${listChiNhanh}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },

  [logAction.HUY_KICH_HOAT_THE_THANH_VIEN]: () => 'Hủy kích hoạt thẻ thành viên',
  [logAction.HOAN_PHI_KHAM]: () => 'Hoàn phí phiếu thu',
  [logAction.TIM_KIEM_VOUCHER]: ({ mavoucher }) => `Xem thông tin voucher: ${mavoucher}`,
  [logAction.PHAT_HANH_MA_VOUCHER]: ({ mavoucher, thoigianhieuluc }) =>
    `Phát hành voucher: ${mavoucher}, thời gian hiệu lực: ${thoigianhieuluc} tháng`,
  [logAction.APPLY_VOUCHER]: ({ mavoucher }) => `Kích hoạt mã voucher: ${mavoucher}`,
  [logAction.UNAPPLY_VOUCHER]: ({ listMa }) => `Bỏ kích hoạt mã voucher: ${listMa}`,
  [logAction.APPLY_VOUCHER_DOI_TAC]: ({ maVoucher, giaVoucher }) =>
    `Kích hoạt mã voucher đối tác: ${maVoucher} - giá voucher: ${giaVoucher}`,
  [logAction.UNAPPLY_VOUCHER_DOI_TAC]: ({ maVoucher, giaVoucher }) =>
    `Bỏ kích hoạt mã voucher đối tác: ${maVoucher} - giá voucher: ${giaVoucher}`,
  [logAction.UPDATE_XHD]: ({ tenBN, hoaDon, ghiChuHoaDon }) =>
    `Cập nhật xuất hoá đơn cho bệnh nhân ${tenBN}: Xuất hoá đơn: ${hoaDon ? 'có' : 'không'} - ghi chú xuất hoá đơn: ${ghiChuHoaDon}`,
  [logAction.TIEP_NHAN_BENH_NHAN]: ({ mabenhnhan, tenbenhnhan, ngaysinh }) =>
    `Tiếp nhận bệnh nhân: mã BN:${mabenhnhan} - Tên BN:${tenbenhnhan} - Ngày sinh:${ngaysinh}`,
  [logAction.APPLY_TTV_CHO_CHI_DINH_KHAM_BENH]: ({ tenChiDinh }) =>
    `Apply thẻ thành viên cho chỉ định: ${tenChiDinh}`,
  [logAction.LICH_SU_KHAM_BENH]: ({ mabenhnhan, tenbenhnhan, ngaysinh }) =>
    `Xem lịch sử khám bệnh bệnh nhân: mã BN:${mabenhnhan} - Tên BN:${tenbenhnhan} - Ngày sinh:${ngaysinh}`,
  [logAction.THONG_TIN_TTV_BN]: ({ mabenhnhan, tenbenhnhan, ngaysinh }) =>
    `Lấy thông tin thẻ thành viên: mã BN:${mabenhnhan} - Tên BN:${tenbenhnhan} - Ngày sinh:${ngaysinh}`,
  [logAction.THONG_TIN_TTT_BN]: ({ mabenhnhan, tenbenhnhan, ngaysinh }) =>
    `Lấy thông tin thẻ thanh toán: mã BN:${mabenhnhan} - Tên BN:${tenbenhnhan} - Ngày sinh:${ngaysinh}`,
  [logAction.LICH_SU_KHAM_BENH]: ({ mabenhnhan, tenbenhnhan, ngaysinh }) =>
    `Xem lịch sử khám bệnh bệnh nhân: mã BN:${mabenhnhan} - Tên BN:${tenbenhnhan} - Ngày sinh:${ngaysinh}`,
  [logAction.UU_DAI_TAI_KHAM_BN]: ({ mabenhnhan, tenbenhnhan, ngaysinh }) =>
    `Lấy thông tin ưu đãi tái khám cho bệnh nhân: mã BN:${mabenhnhan} - Tên BN:${tenbenhnhan} - Ngày sinh:${ngaysinh}`,
  [logAction.CREATE_BENH_NHAN]: ({ mabenhnhan, tenbenhnhan, ngaysinh }) =>
    `Tạo bệnh nhân: mã BN:${mabenhnhan} - Tên BN:${tenbenhnhan} - Ngày sinh:${ngaysinh}`,
  [logAction.UPDATE_BENH_NHAN]: ({ mabenhnhan, tenbenhnhan, ngaysinh }) =>
    `Cập nhật thông tin bệnh nhân: mã BN:${mabenhnhan} - Tên BN:${tenbenhnhan} - Ngày sinh:${ngaysinh}`,
  [logAction.UPDATE_BENH_AN]: ({ mabenhnhan, tenbenhnhan, ngaysinh }) =>
    `Cập nhật thông tin bệnh án mã BN:${mabenhnhan} - Tên BN:${tenbenhnhan} - Ngày sinh:${ngaysinh}`,
  [logAction.DOI_CHI_DINH_THU_NGAN]: ({ tencd }) =>
    `Đổi chỉ định thu ngân - tên chỉ định đổi: ${tencd}`,
  [logAction.CAP_NHAT_PHIEU_THU_XOA_CHI_DINH]: ({ maphieuthu }) =>
    `Cập nhật phiếu thu xoá chỉ định thu ngân - phiếu thu: ${maphieuthu}`,
  [logAction.THANH_TOAN_TIEP_NHAN]: (detail) => {
    return [
      `Thanh toán tiếp nhận: mã bn: ${detail.mabn ?? ''}, tên bn: ${detail.tenbn ?? ''}, ngày sinh: ${detail.ngaysinh ?? ''}`,
      `Chỉ định: \n \t${detail.listchidinh.join('\n \t')}`,
      `Phiếu thu: ${detail.phieuthu ?? ''}`,
      `Tiền thanh toán: ${detail.tienthanhtoan ?? ''}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },
  [logAction.THANH_TOAN_TAB_THU_NGAN]: ({ mabn, tenbn }) =>
    `Thanh toán cho bệnh nhân :${mabn} - Tên BN:${tenbn}`,

  [logAction.XUAT_EXCEL_DS_PHIEU_THU]: ({ dateFrom, dateTo, keyword, listChiNhanh }) => {
    return [
      `Xuất excel danh sách phiếu thu: từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
      `Danh sách chi nhánh: ${listChiNhanh}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },
  [logAction.GET_DS_PHIEU_THU]: ({ dateFrom, dateTo, keyword, listChiNhanh }) => {
    return [
      `Xem danh sách phiếu thu: từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
      `Danh sách chi nhánh: ${listChiNhanh}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },
  [logAction.XEM_CHI_TIET_PHIEU_THU]: ({ maphieuthu }) => `Xem chi tiết phiếu thu: ${maphieuthu}`,
  [logAction.APPLY_VOUCHER_CHI_TIET_PHIEU_THU]: ({ maphieuthu, mavoucher }) =>
    `Apply voucher cho chi tiết phiếu thu: ${maphieuthu} - mã voucher: ${mavoucher}`,
  [logAction.UNAPPLY_VOUCHER_CHI_TIET_PHIEU_THU]: ({ maphieuthu, mavoucher }) =>
    `Huỷ apply voucher cho chi tiết phiếu thu: ${maphieuthu} - mã voucher: ${mavoucher}`,
  [logAction.CAP_NHAP_CHI_TIET_THANH_TOAN_PHIEU_THU]: ({ maphieuthu }) =>
    `Cập nhật chi tiết thanh toán cho phiếu thu: ${maphieuthu}`,
  [logAction.CAP_NHAP_CHI_TIET_DICH_VU_PHIEU_THU]: ({ maphieuthu }) =>
    `Cập nhật chi tiết dịch vụ cho phiếu thu: ${maphieuthu}`,
  [logAction.HOAN_PHI_KHAM]: ({ tenbn, mabn }) =>
    `Hoàn phí khám cho bệnh nhân: mã BN: ${mabn} - Tên BN:${tenbn}`,
  [logAction.UPDATE_SINH_HIEU]: ({ tenbn, mabn }) =>
    `Cập nhật sinh hiệu cho bệnh nhân: mã BN: ${mabn} - Tên BN:${tenbn}`,
  [logAction.TINH_LAI_TIEN_THU_NGAN]: ({ tenbn, mabn }) =>
    `Tính lại tiền cho bệnh nhân: mã BN: ${mabn} - Tên BN:${tenbn}`,

  [logAction.XEM_BENH_AN]: () => `Xem bệnh án`,
  [logAction.CAP_NHAT_BENH_AN]: () => `Cập nhật thông tin hành chính`,

  [logAction.SUA_MA_BN]: () => `Sửa mã bệnh nhân`,
  [logAction.SUA_STT]: () => `Sửa số thứ tự`,
  [logAction.GOP_BENH_AN]: () => `Gộp mã bệnh nhân từ '012400518 sang 012401288'`,
  [logAction.DONG_CA_LAM_VIEC]: () => `Đóng ca làm việc vào lúc 13/11/2022 12:00`,
  [logAction.MO_CA_LAM_VIEC]: () => `Mở ca làm việc`,
  [logAction.BAO_CAO_EMAIL]: () => `Gửi báo cáo (thanh.12312312@gmail.com)`,

  [logAction.DIEU_CHINH_KHO]: () => `Lập phiếu điều chỉnh`,
  [logAction.XOA_PHIEU_DIEU_CHINH]: () => `Xoá phiếu điều chỉnh`,
  [logAction.SUA_PHIEU_DIEU_CHINH]: () => `Sửa phiếu điều chỉnh`,
  [logAction.TAO_MA_VOUCHER]: () => `Lập phiếu mã voucher`,
  [logAction.THEM_MA_VOUCHER]: () => `Thêm mã voucher`,
  [logAction.XOA_PHIEU_NHAP_KHO]: ({ maphieunhap }) =>
    `Xoá phiếu nhập kho: mã phiếu: ${maphieunhap}`,
  [logAction.TAO_PHIEU_NHAP_KHO]: ({ maphieu, tenphieu }) =>
    `Tạo phiếu nhập kho: mã phiếu: ${maphieu} - tên phiếu ${tenphieu}`,
  [logAction.TAO_PHIEU_NHAP_KHO_VVP]: ({ maphieunhap, tenphieu }) =>
    `Tạo phiếu nhập kho văn phòng phẩm: mã phiếu: ${maphieunhap} - tên phiếu ${tenphieu}`,
  [logAction.SEARCH_THUOC_VAT_TU]: ({ keyword }) =>
    `Tìm kiếm thuốc vật tư theo keyword:  "${keyword}"`,
  [logAction.COPY_HANG_HOA]: ({ maphieu }) => `Lấy hàng hoá từ mã phiếu:  "${maphieu}"`,
  [logAction.GET_LIST_CHI_TIET_HANG_NHAP_KHO]: ({
    dateFrom,
    dateTo,
    keyword,
    nameCty,
    nameChiNhanh,
  }) =>
    `Xem danh sách chi tiết hàng hoá nhập kho:  ${nameCty}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" `,
  [logAction.XUAT_EXCEL_LIST_CHI_TIET_HANG_NHAP_KHO]: ({
    dateFrom,
    dateTo,
    keyword,
    nameCty,
    nameChiNhanh,
  }) =>
    `Xuất excel danh sách chi tiết hàng hoá nhập kho:  ${nameCty}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" `,
  [logAction.GET_LIST_CHI_TIET_HANG_NHAP_KHO_VPP]: ({
    dateFrom,
    dateTo,
    keyword,
    nameCty,
    nameChiNhanh,
  }) =>
    `Xem danh sách chi tiết hàng hoá nhập kho văn phòng phẩm:  ${nameCty}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" `,
  [logAction.XUAT_EXCEL_LIST_CHI_TIET_HANG_NHAP_KHO_VPP]: ({
    dateFrom,
    dateTo,
    keyword,
    nameCty,
    nameChiNhanh,
  }) =>
    `Xuất excel danh sách chi tiết hàng hoá nhập kho văn phòng phẩm:  ${nameCty}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" `,

  [logAction.GET_DS_PHIEU_NHAP]: ({ dateFrom, dateTo, keyword, nameCompany, nameChiNhanh }) =>
    `Xem danh sách phiếu nhập kho:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
  [logAction.XUAT_EXCEL_DS_PHIEU_NHAP]: ({
    dateFrom,
    dateTo,
    keyword,
    nameCompany,
    nameChiNhanh,
  }) =>
    `Xuất excel danh sách phiếu nhập kho:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
  [logAction.CHI_TIET_PHIEU_NHAP]: ({ maphieunhap }) =>
    `Xem chi tiết phiếu nhập kho: mã phiếu nhập: ${maphieunhap}`,
  [logAction.XUAT_EXCEL_CHI_TIET_PHIEU_NHAP]: ({ maphieunhap }) =>
    `Xuất excel chi tiết phiếu nhập kho: mã phiếu nhập: ${maphieunhap}`,
  [logAction.XOA_HANG_PHIEU_NHAP]: ({ maphieunhap, tenhang }) =>
    `Xoá hàng phiếu nhập kho: mã phiếu nhập: ${maphieunhap} - tên hàng: ${tenhang}`,
  [logAction.SUA_HANG_PHIEU_NHAP]: ({ maphieunhap, tenhang }) =>
    `Sửa hàng phiếu nhập kho: mã phiếu nhập: ${maphieunhap} - tên hàng: ${tenhang}`,
  [logAction.THEM_HANG_PHIEU_NHAP]: ({ maphieunhap, tenhang }) =>
    `Thêm hàng phiếu nhập kho: mã phiếu nhập: ${maphieunhap} - tên hàng: ${tenhang}`,
  [logAction.SUA_THONG_TIN_PHIEU_NHAP]: ({ maphieunhap }) =>
    `Sửa thông tin phiếu nhập kho: mã phiếu nhập: ${maphieunhap} `,
  [logAction.CAP_NHAT_FILE_PHIEU_NHAP]: ({ maphieunhap }) =>
    `Cập nhật file cho phiếu nhập kho: mã phiếu nhập: ${maphieunhap} `,
  [logAction.DELETE_FILE_PHIEU_NHAP]: ({ maphieunhap }) =>
    `Xoá file phiếu nhập kho: mã phiếu nhập: ${maphieunhap} `,
  [logAction.XAC_NHAN_NHAP_KHO_PHIEU_NHAP]: ({ maphieunhap }) =>
    `Xác nhận nhập kho: mã phiếu nhập: ${maphieunhap} `,
  [logAction.GET_LIST_HANG_CHO_NHAP_KHO_VPP]: ({
    dateFrom,
    dateTo,
    keyword,
    nameCty,
    nameChiNhanh,
  }) =>
    `Xem danh sách hàng hoá chờ nhập kho văn phòng phẩm:  ${nameCty}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" `,
  [logAction.XUAT_EXCEL_LIST_HANG_CHO_NHAP_KHO_VPP]: ({
    dateFrom,
    dateTo,
    keyword,
    nameCty,
    nameChiNhanh,
  }) =>
    `Xuất excel danh sách hàng hoá chờ nhập kho văn phòng phẩm:  ${nameCty}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" `,

  [logAction.TAO_PHIEU_CHUYEN_KHO]: ({ maphieuchuyen, tenphieu }) =>
    `Tạo phiếu chuyển kho: mã phiếu: ${maphieuchuyen} - tên phiếu ${tenphieu}`,
  [logAction.COPY_HANG_HOA_CHUYEN_KHO]: ({ maphieu }) =>
    `Lấy hàng hoá từ mã phiếu chuyển kho:  "${maphieu}"`,
  [logAction.GET_LIST_PHIEU_CHUYEN]: ({ dateFrom, dateTo, nameChiNhanh, nameStatus }) =>
    `Xem danh sách phiếu chuyển: trạng thái phiếu: ${nameStatus}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
  [logAction.XUAT_EXCEL_LIST_PHIEU_CHUYEN]: ({ dateFrom, dateTo, nameChiNhanh, nameStatus }) =>
    `Xuất excel danh sách phiếu chuyển: trạng thái phiếu: ${nameStatus}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
  [logAction.XAC_NHAN_PHIEU_CHUYEN_KHO]: ({ maphieuchuyen }) =>
    `Xác nhận chuyển kho: mã phiếu chuyển: ${maphieuchuyen} `,
  [logAction.CHI_TIET_PHIEU_CHUYEN_KHO]: ({ maphieuchuyen }) =>
    `Xem chi tiết phiếu chuyển kho: mã phiếu chuyển: ${maphieuchuyen}`,
  [logAction.XUAT_EXCEL_CHI_TIET_PHIEU_CHUYEN_KHO]: ({ maphieuchuyen }) =>
    `Xuất excel chi tiết phiếu chuyển kho: mã phiếu chuyển: ${maphieuchuyen}`,
  [logAction.CAP_NHAT_THONG_TIN_PHIEU_CHUYEN]: ({ maphieuchuyen }) =>
    `Cập nhật thông tin phiếu chuyển kho: mã phiếu chuyển: ${maphieuchuyen}`,
  [logAction.CAP_NHAT_FILE_PHIEU_CHUYEN]: ({ maphieuchuyen }) =>
    `Cập nhật file cho phiếu chuyển kho: mã phiếu chuyển: ${maphieuchuyen} `,
  [logAction.DELETE_FILE_PHIEU_CHUYEN]: ({ maphieuchuyen }) =>
    `Xoá file phiếu chuyển kho: mã phiếu chuyển: ${maphieuchuyen} `,
  [logAction.GET_LIST_CHI_TIET_HANG_CHUYEN_KHO]: ({
    dateFrom,
    dateTo,
    keyword,
    nameCompany,
    nameChiNhanh,
  }) =>
    `Xem danh sách chi tiết hàng hoá chuyển kho:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" `,
  [logAction.XUAT_EXCEL_LIST_CHI_TIET_HANG_CHUYEN_KHO]: ({
    dateFrom,
    dateTo,
    keyword,
    nameCompany,
    nameChiNhanh,
  }) =>
    `Xuất excel danh sách chi tiết hàng hoá chuyển kho:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" `,
  [logAction.GET_LIST_SO_PHIEU_CHUYEN_KHO]: ({ dateFrom, dateTo, nameCompany, nameChiNhanh }) =>
    `Xem danh sách số phiếu chuyển kho:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
  [logAction.XUAT_EXCEL_LIST_SO_PHIEU_CHUYEN_KHO]: ({
    dateFrom,
    dateTo,
    nameCompany,
    nameChiNhanh,
  }) =>
    `Xuất excel danh sách số phiếu chuyển kho:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
  [logAction.THEM_HANG_PHIEU_CHUYEN]: ({ maphieuchuyen, listHang }) =>
    `Thêm hàng hoá cho phiếu chuyển kho: mã phiếu chuyển: ${maphieuchuyen} \n Hàng hoá: ${listHang}`,
  [logAction.XOA_HANG_PHIEU_CHUYEN]: ({ maphieuchuyen, tenhang }) =>
    `Xoá hàng hoá phiếu chuyển kho: mã phiếu chuyển: ${maphieuchuyen} \n Hàng hoá xoá: ${tenhang}`,
  [logAction.XOA_PHIEU_CHUYEN_KHO]: ({ maphieuchuyen }) =>
    `Xoá phiếu chuyển kho: mã phiếu: ${maphieuchuyen}`,

  [logAction.GET_DS_THUOC_VAT_TU]: ({ nameCompany, nameLoaiThuocVT, nameNhomThuocVT, page }) =>
    `Xem danh sách thuốc vật tư:  ${nameCompany}, loại thuốc vật tư: ${nameLoaiThuocVT}, trang: ${page} \nnhóm thuốc vật tư: ${nameNhomThuocVT}, `,
  [logAction.XUAT_EXCEL_DS_THUOC_VAT_TU]: ({ nameCompany }) =>
    `Xuất excel danh sách thuốc vật tư theo công ty : ${nameCompany}`,
  [logAction.THEM_THUOC_VAT_TU]: ({ mathuoc, tenthuoc }) =>
    `Tạo thuốc vật tư : mã thuốc: ${mathuoc}, tên thuốc: ${tenthuoc}`,
  [logAction.EDIT_THUOC_VAT_TU]: ({ mathuoc, tenthuoc }) =>
    `Chỉnh sửa thuốc vật tư : mã thuốc: ${mathuoc}, tên thuốc: ${tenthuoc}`,
  [logAction.GET_DS_THUOC_VAT_TU_GIA_BAN]: ({
    dateFrom,
    dateTo,
    nameCompany,
    nameLoaiThuocVT,
    nameNhomThuocVT,
    page,
    keyword,
  }) =>
    `Xem danh sách thuốc vật tư giá bán:  ${nameCompany}, loại thuốc vật tư: ${nameLoaiThuocVT}, nhóm thuốc vật tư: ${nameNhomThuocVT}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}", trang: ${page} , `,
  [logAction.XUAT_EXCEL_DS_THUOC_VAT_TU_GIA_BAN]: ({
    dateFrom,
    dateTo,
    nameCompany,
    nameLoaiThuocVT,
    nameNhomThuocVT,
    page,
    keyword,
  }) =>
    `Xuất excel danh sách thuốc vật tư giá bán:  ${nameCompany}, loại thuốc vật tư: ${nameLoaiThuocVT}, nhóm thuốc vật tư: ${nameNhomThuocVT}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}"`,
  [logAction.GET_DS_CHI_NHANH_BAN]: ({
    nameCompany,
    nameLoaiThuoc,
    nameNhomThuoc,
    keyword,
    page,
  }) =>
    `Xem danh sách chi nhánh bán:  ${nameCompany}, loại thuốc vật tư: ${nameLoaiThuoc}, nhóm thuốc vật tư: ${nameNhomThuoc}, tìm kiếm theo keyword: "${keyword}", trang: ${page}`,
  [logAction.GET_DS_CHI_NHANH_BAN_BY_THUOC]: ({ tenThuoc }) =>
    `Xem danh sách chi nhánh bán theo thuốc vật tư: ${tenThuoc}`,
  [logAction.SUA_GIA_CHI_NHANH_BAN]: ({ giaBan, nameVT }) =>
    `Sửa giá thuốc vật tư chi nhánh bán: giá sửa: ${giaBan} - tên: ${nameVT}`,
  [logAction.CAP_NHAT_TRANG_THAI_BAN]: ({ tenThuoc }) =>
    `Cập nhật trạng thái sử dụng giá chi nhánh bán: tên thuốc vật tư: ${tenThuoc}`,

  [logAction.GET_TON_KHO_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    nameLoaiThuocVT,
    nameNhomThuocVT,
    nameKho,
    keyword,
  }) =>
    `Xem danh sách tồn kho tổng hợp:  ${nameCompany}, kho: ${nameKho}, loại thuốc vật tư: ${nameLoaiThuocVT}, nhóm thuốc vật tư: ${nameNhomThuocVT}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh: ${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_TON_KHO_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    nameLoaiThuocVT,
    nameNhomThuocVT,
    nameKho,
    keyword,
  }) =>
    `Xuất excel danh sách tồn kho tổng hợp:   ${nameCompany}, kho: ${nameKho}, loại thuốc vật tư: ${nameLoaiThuocVT}, nhóm thuốc vật tư: ${nameNhomThuocVT}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh: ${nameChiNhanh}`,
  [logAction.GET_TON_KHO_CHI_TIET]: ({
    nameCompany,
    nameChiNhanh,
    nameLoaiThuocVT,
    nameNhomThuocVT,
    nameKho,
    keyword,
    dateTo,
  }) =>
    `Xem danh sách tồn kho chi tiết:  ${nameCompany}, kho: ${nameKho}, loại thuốc vật tư: ${nameLoaiThuocVT}, nhóm thuốc vật tư: ${nameNhomThuocVT}, ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh: ${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_TON_KHO_CHI_TIET]: ({
    nameCompany,
    nameChiNhanh,
    nameLoaiThuocVT,
    nameNhomThuocVT,
    nameKho,
    keyword,
    dateTo,
  }) =>
    `Xuất excel danh sách tồn kho chi tiết:   ${nameCompany}, kho: ${nameKho}, loại thuốc vật tư: ${nameLoaiThuocVT}, nhóm thuốc vật tư: ${nameNhomThuocVT}, ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh: ${nameChiNhanh}`,

  [logAction.GET_TON_KHO_NGAY]: ({
    nameCompany,
    nameChiNhanh,
    nameChuyenKhoa,
    nameKho,
    keyword,
    dateTo,
    dateFrom,
  }) =>
    `Xem danh sách tồn kho tồn ngày:  ${nameCompany}, kho: ${nameKho}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} đến ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh: ${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_TON_KHO_NGAY]: ({
    nameCompany,
    nameChiNhanh,
    nameChuyenKhoa,
    nameKho,
    keyword,
    dateTo,
    dateFrom,
  }) =>
    `Xuất excel danh sách tồn kho tồn ngày:   ${nameCompany}, kho: ${nameKho}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} đến ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh: ${nameChiNhanh}`,
  [logAction.GET_THE_KHO_BY_THUOC]: ({ nameCompany, nameChiNhanh, nameThuoc, dateTo, dateFrom }) =>
    `Xem thẻ kho của thuốc: thuốc vật tư: ${nameThuoc},  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''} đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}\nChi nhánh: ${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_THE_KHO_BY_THUOC]: ({
    nameCompany,
    nameChiNhanh,
    nameThuoc,
    dateTo,
    dateFrom,
  }) =>
    `Xuất excel thẻ kho của thuốc: thuốc vật tư: ${nameThuoc},  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''} đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}\nChi nhánh: ${nameChiNhanh}`,
  [logAction.GET_MSDK_BY_THUOC]: ({ tenThuoc }) =>
    `Xem lịch sử mã số đăng ký của thuốc vật tư: ${tenThuoc}`,
  [logAction.GET_GPP_BY_THUOC]: ({ tenThuoc }) => `Xem lịch sử GPP của thuốc vật tư: ${tenThuoc}`,

  [logAction.GET_DS_GIA_NHAP_TRUNG_BINH]: ({
    nameLoaiHang,
    nameNhomHang,
    keyword,
    dateTo,
    dateFrom,
  }) =>
    `Xem danh sách giá nhập trung bình: loại hàng: ${nameLoaiHang}, nhóm hàng: ${nameNhomHang}, từ ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} đến ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}"`,
  [logAction.XUAT_EXCEL_DS_GIA_NHAP_TRUNG_BINH]: ({
    nameLoaiHang,
    nameNhomHang,
    keyword,
    dateTo,
    dateFrom,
  }) =>
    `Xuất excel danh sách giá nhập trung bình: loại hàng: ${nameLoaiHang}, nhóm hàng: ${nameNhomHang}, từ ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} đến ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}"`,

  [logAction.GET_DS_GIA_NHAP_TUNG_LAN]: ({
    nameLoaiHang,
    nameNhomHang,
    keyword,
    dateTo,
    dateFrom,
  }) =>
    `Xem danh sách giá nhập từng lần: loại hàng: ${nameLoaiHang}, nhóm hàng: ${nameNhomHang}, từ ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} đến ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}"`,
  [logAction.XUAT_EXCEL_DS_GIA_NHAP_TUNG_LAN]: ({
    nameLoaiHang,
    nameNhomHang,
    keyword,
    dateTo,
    dateFrom,
  }) =>
    `Xuất excel danh sách giá nhập từng lần: loại hàng: ${nameLoaiHang}, nhóm hàng: ${nameNhomHang}, từ ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} đến ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}"`,

  [logAction.GET_DS_CANH_BAO_DO_TUOI]: ({ keyword, nameNhomVX }) =>
    `Xem danh sách cảnh báo độ tuổi: tìm kiếm theo keyword: "${keyword}", nhóm vắc xin: ${nameNhomVX}`,
  [logAction.ADD_CANH_BAO_DO_TUOI]: ({
    mathuoc,
    nhomthuoc,
    tenthuoc,
    tungaytuoi,
    denngaytuoi,
    tuoitiem,
  }) =>
    `Thêm cảnh báo độ tuổi: mã thuốc: ${mathuoc}, nhóm thuốc: ${nhomthuoc}, tên thuốc: ${tenthuoc} \ntừ ngày tuổi: ${tungaytuoi} đến ngày tuổi: ${denngaytuoi} - tuổi tiêm: ${tuoitiem}`,
  [logAction.UPDATE_CANH_BAO_DO_TUOI]: ({
    mathuoc,
    nhomthuoc,
    tenthuoc,
    tungaytuoi,
    denngaytuoi,
    tuoitiem,
  }) =>
    `Sửa cảnh báo độ tuổi: mã thuốc: ${mathuoc}, nhóm thuốc: ${nhomthuoc}, tên thuốc: ${tenthuoc} \ntừ ngày tuổi: ${tungaytuoi} đến ngày tuổi: ${denngaytuoi} - tuổi tiêm: ${tuoitiem}`,

  [logAction.GET_DS_CANH_BAO_MUI_TIEM]: ({ keyword, nameNhomVX }) =>
    `Xem danh sách cảnh báo mũi tiêm: tìm kiếm theo keyword: "${keyword}", nhóm vắc xin: ${nameNhomVX}`,
  [logAction.ADD_CANH_BAO_MUI_TIEM]: ({ mathuoc, nhomthuoc, tenthuoc, ngaycachnhau }) =>
    `Thêm cảnh báo mũi tiêm: mã thuốc: ${mathuoc}, nhóm thuốc: ${nhomthuoc}, tên thuốc: ${tenthuoc} \nngày cách nhau: ${ngaycachnhau} ngày`,
  [logAction.UPDATE_CANH_BAO_MUI_TIEM]: ({ mathuoc, nhomthuoc, tenthuoc, ngaycachnhau }) =>
    `Sửa cảnh báo mũi tiêm: mã thuốc: ${mathuoc}, nhóm thuốc: ${nhomthuoc}, tên thuốc: ${tenthuoc} \nngày cách nhau: ${ngaycachnhau} ngày`,

  [logAction.GET_DS_CANH_BAO_HOAT_CHAT]: ({ keyword, nameNhomVX }) =>
    `Xem danh sách cảnh báo hoạt chất: tìm kiếm theo keyword: "${keyword}", nhóm vắc xin: ${nameNhomVX}`,
  [logAction.ADD_CANH_BAO_HOAT_CHAT]: ({ mathuoc, nhomthuoc, tenthuoc, vacxintuongtac }) =>
    `Thêm cảnh báo hoạt chất: mã thuốc: ${mathuoc}, nhóm thuốc: ${nhomthuoc}, tên thuốc: ${tenthuoc} \nVắc xin tương tác: \n${vacxintuongtac}`,
  [logAction.UPDATE_CANH_BAO_HOAT_CHAT]: ({ mathuoc, nhomthuoc, tenthuoc, vacxintuongtac }) =>
    `Sửa cảnh báo hoạt chất: mã thuốc: ${mathuoc}, nhóm thuốc: ${nhomthuoc}, tên thuốc: ${tenthuoc} \nVắc xin tương tác: \n${vacxintuongtac}`,

  [logAction.GET_DS_CANH_BAO_KHOANG_CACH]: ({ keyword, nameNhomVX }) =>
    `Xem danh sách cảnh báo khoảng cách: tìm kiếm theo keyword: "${keyword}", nhóm vắc xin: ${nameNhomVX}`,
  [logAction.ADD_CANH_BAO_KHOANG_CACH]: ({ mathuoc, nhomthuoc, tenthuoc, vacxintuongtac }) =>
    `Thêm cảnh báo khoảng cách: mã thuốc: ${mathuoc}, nhóm thuốc: ${nhomthuoc}, tên thuốc: ${tenthuoc} \nVắc xin tương tác: \n${vacxintuongtac}`,
  [logAction.UPDATE_CANH_BAO_KHOANG_CACH]: ({ mathuoc, nhomthuoc, tenthuoc, vacxintuongtac }) =>
    `Sửa cảnh báo khoảng cách: mã thuốc: ${mathuoc}, nhóm thuốc: ${nhomthuoc}, tên thuốc: ${tenthuoc} \nVắc xin tương tác: \n${vacxintuongtac}`,

  [logAction.GET_DS_BN_DA_THUC_HIEN_THU_THUAT]: ({ nameChiNhanh, dateFrom, dateTo }) =>
    `Xem danh sách bệnh nhân đã thực hiện thủ thuật: chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''} đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,

  [logAction.GET_DETAIL_BN_THUC_HIEN_THU_THUAT]: ({ mabn, tenbn, ngaykham }) =>
    `Xem chi tiết bệnh nhân thủ thuật: mã bệnh nhân: ${mabn}, tên bệnh nhân: ${tenbn}, ngày khám: ${ngaykham ? moment(ngaykham).format('DD/MM/YYYY') : ''}`,

  [logAction.UPDATE_VTTH_BN_THUC_HIEN_THU_THUAT]: ({ mabn, tenbn, ngaykham, listVatTu }) =>
    `Cập nhật vật tư tiêu hao bệnh nhân thủ thuật: mã bệnh nhân: ${mabn}, tên bệnh nhân: ${tenbn}, ngày khám: ${ngaykham ? moment(ngaykham).format('DD/MM/YYYY') : ''} \nVật tư tiêu hao: \n${listVatTu}`,

  [logAction.SUA_HANG_HOA]: () => `Sửa hàng hoá`,
  [logAction.SUA_HANG_HOA_EXCEL]: () => `Sửa hàng hoá từ excel`,
  [logAction.THEM_HANG_HOA]: () => `Thêm hàng hoá`,
  [logAction.THEM_HANG_HOA_EXCEL]: () => `Thêm hàng hoá từ excel`,
  [logAction.SUA_PHIEU_NHAP_KHO]: () => `Sửa phiếu nhập kho`,

  [logAction.TAO_PHIEU_THU]: () => `Lập phiếu thu`,
  [logAction.SUA_PHIEU_THU]: () => `Sửa phiếu thu`,
  [logAction.XOA_PHIEU_THU]: ({ maphieuthu }) => `Xoá phiếu thu: ${maphieuthu}`,
  [logAction.LUU_CHI_DINH]: () => `Lưu chỉ định khám bệnh`,

  [logAction.LUU_PHIEU_BAN_TOA]: () => `Lưu phiếu bán toa`,
  [logAction.TRA_TOA]: () => `Trả toa`,

  [logAction.LUU_BENH_AN_NGOAI_TRU]: () => `Lưu bệnh án ngoại trú`,
  [logAction.LUU_PHIEU_TIEP_NHAN]: () => `Lưu phiếu nhận bệnh`,
  [logAction.LUU_PHIEU_THU_THUAT]: () => `Lưu phiếu thủ thuật`,

  [logAction.LUU_PHIEU_XUAT_VACXIN]: () => `Lưu phiếu xuất vắc xin`,
  [logAction.LUU_THEO_DOI_SAU_TIEM_CHUNG]: () => `Lưu theo dõi sau tiêm`,
  [logAction.XEM_BANG_KE_PHAN_UNG_NANG_VACXIN]: () => `Xem bảng kê phản ứng nặng vắc xin`,
  [logAction.XEM_BANG_KE_PHAN_UNG_THONG_TIN_VACXIN]: () =>
    `Xem bảng kê phản ứng thông thường vắc xin`,
  [logAction.XEM_BANG_KE_TINH_HINH_SU_DUNG_VACXIN]: () => `Xem bảng kê tình hình sử dụng vắc xin`,

  [logAction.LUU_TOA_PHIEU_KHAM_BENH]: () => `Lưu toa phiếu khám bệnh`,
  [logAction.LUU_TOA_PHIEU_KHAM_PHU_SAN]: () => `Lưu toa phiếu khám phụ sản`,
  [logAction.LUU_PHIEU_KHAM_SANG_LOC]: () => `Lưu toa phiếu khám sàng lọc`,
  [logAction.LUU_PHIEU_KHAM_TONG_QUAT]: () => `Lưu toa phiếu khám tổng quát`,
  [logAction.LUU_PHIEU_DO_KHUC_XA]: () => `Lưu toa phiếu đo khúc xạ`,

  [logAction.SUA_BIEU_MAU]: () => `Sửa biểu mẫu`,

  [logAction.SUA_DICH_VU]: () => `Sửa dịch vụ`,
  [logAction.THEM_DICH_VU]: () => `Thêm dịch vụ`,
  [logAction.XOA_DICH_VU]: () => `Xoá dịch vụ`,
  [logAction.SUA_DINH_MUC_TIEU_HAO]: () => `Sửa định mức tiêu hao dịch vụ`,

  [logAction.SUA_PHIEU_BAN_LE]: () => `Sửa phiếu bán lẻ`,
  [logAction.THEM_PHIEU_BAN_LE]: () => `Thêm phiếu bán lẻ`,
  [logAction.XOA_PHIEU_BAN_LE]: () => `Xoá phiếu bán lẻ`,

  [logAction.XEM_PHAN_QUYEN]: () => `Xem phân quyền`,
  [logAction.THEM_MENU]: ({ menuCha, menuCon }) => `Thêm menu: ${menuCha}/${menuCon}`,
  [logAction.XOA_MENU]: ({ tenMenu }) => `Xoá menu: ${tenMenu}`,
  [logAction.GET_ALL_MENU]: () => `Xem danh sách menu`,
  [logAction.GET_ALL_NHOM_NGUOI_DUNG]: () => `Xem danh sách nhóm người dùng`,
  [logAction.GET_ALL_DS_NGUOI_DUNG]: ({ tenNhom, keyword, page }) =>
    `Xem danh sách người dùng: nhóm người dùng: ${tenNhom}, tìm kiếm theo keyword: "${keyword}", trang: ${page}`,
  [logAction.GET_DS_NV_CHUA_TAO_TK]: ({ keyword }) =>
    `Lấy danh sách nhân viên chưa tạo tài khoản: tìm kiếm theo keyword: "${keyword}"`,
  [logAction.GET_INFO_NGUOI_DUNG]: ({ id }) =>
    `Xem chi tiết thông tin người dùng: id người dùng: ${id}`,
  [logAction.UPDATE_LOCK_NGUOI_DUNG]: ({ username, islock }) =>
    `Cập nhật lock người dùng: tài khoản: ${username}, lock: ${islock}`,
  [logAction.UPDATE_INFO_NGUOI_DUNG]: (detail) => {
    return [
      `Cập nhật thông tin người dùng:`,
      `Id người dùng: ${detail.id ?? ''}`,
      `Tài khoản: ${detail.taikhoan ?? ''}`,
      `Chi nhánh: ${detail.chinhanh ?? ''}`,
      `Vai trò: ${detail.vaitro ?? ''}`,
      `Nhóm quyền: ${detail.nhomquyen ?? ''}`,
      `Loại: ${detail.loai ?? ''}`,
      `Hiệu lực: ${detail.hieuluc ?? ''}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },
  [logAction.RESET_PASSWORD_NGUOI_DUNG]: ({ username, nguoiSua }) =>
    `Reset password người dùng: tài khoản: ${username}, ID người sửa: ${nguoiSua}`,

  [logAction.SUA_MENU]: (detail) => {
    return [
      `Sửa thông tin menu:`,
      `Tên menu: ${detail.tenMenu ?? ''}`,
      `Mã menu: ${detail.maMenu ?? ''}`,
      `Tên viết tắt: ${detail.tenVietTat ?? ''}`,
      `Link: ${detail.links ?? ''}`,
      `Ghi chú: ${detail.ghiChu ?? ''}`,
      `Menu cha: ${detail.menuCha ?? ''}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },

  [logAction.CAP_NHAT_QUYEN_MENU]: ({ tenNhom }) =>
    `Cập nhật menu cho vai trò ${tenNhom} thành công`,
  [logAction.SUA_TAI_KHOAN]: () => `Sửa tài khoản`,
  [logAction.THEM_TAI_KHOAN]: () => `Thêm tài khoản`,
  [logAction.THEM_NGUOI_DUNG]: ({ id, taikhoan, nhanvien }) =>
    `Thêm tài khoản người dùng cho nhân viên ${nhanvien} : id tài khoản: ${id} - tài khoản: ${taikhoan}`,
  [logAction.THEM_VAI_TRO]: ({ tenNhom, maNhom }) =>
    `Thêm vai trò người dùng: ${maNhom} - ${tenNhom}`,
  [logAction.XOA_VAI_TRO]: ({ tenNhom, maNhom }) =>
    `Xoá vai trò người dùng: ${maNhom} - ${tenNhom}`,
  [logAction.SUA_VAI_TRO]: (detail) => {
    return [
      `Sửa thông tin vai trò:`,
      `Tên vai trò: ${detail.tenNhom ?? ''}`,
      `Mã vai trò: ${detail.maNhom ?? ''}`,
      `Tên viết tắt: ${detail.tenVietTat ?? ''}`,
      `Ghi chú: ${detail.ghiChu ?? ''}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },
  [logAction.GET_VAI_TRO_BY_NHOM_NGUOI_DUNG]: ({ idNhom, maNhom, tenNhom }) =>
    `Xem menu nhóm người dùng bằng vai trò: ${idNhom} - ${maNhom} - ${tenNhom}`,

  [logAction.SUA_THE_THANH_VIEN]: ({
    idlt,
    malt,
    tenloaithe,
    tiensudung,
    thoigianhethan,
    idnhom,
    idchuyenkhoa,
    ghichu,
    nguoisua,
  }) => {
    const details = [
      `Sửa thẻ thành viên:`,
      `ID loại thẻ: ${idlt ?? ''}`,
      `Mã loại thẻ: ${malt ?? ''}`,
      `Tên loại thẻ: ${tenloaithe ?? ''}`,
      `Tiền sử dụng: ${tiensudung ?? 0}`,
      `Thời gian hết hạn: ${thoigianhethan ? moment(thoigianhethan).format('DD/MM/YYYY') : ''}`,
      `ID nhóm: ${idnhom ?? ''}`,
      `ID chuyên khoa: ${idchuyenkhoa ?? ''}`,
      `Ghi chú: ${ghichu ?? ''}`,
      `Người sửa: ${nguoisua ?? ''}`,
    ].filter(Boolean)

    return details.join('\n \t')
  },
  [logAction.TAO_THE_THANH_VIEN]: ({ mabn, tenbn }) =>
    `Tạo thẻ thành viên cho bệnh nhân: mã bn: ${mabn} - tên bn: ${tenbn}`,
  [logAction.XEM_DS_THE_THANH_VIEN]: () => `Xem danh sách thẻ thành viên`,
  [logAction.XOA_THE_THANH_VIEN]: () => `Xoá thẻ thành viên`,
  [logAction.GET_LIST_TTV]: ({ dateFrom, dateTo, keyword, listChiNhanh, congty }) => {
    return [
      `Danh sách thông tin phiếu thu thẻ thành viên: từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
      `Tìm kiếm theo:  ${congty}, keyword: "${keyword}"`,
      `Danh sách chi nhánh: ${listChiNhanh}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },
  [logAction.XUAT_EXCEL_LIST_TTV]: ({ dateFrom, dateTo, keyword, listChiNhanh, congty }) => {
    return [
      `Xuất excel danh sách phiếu thu thẻ thành viên: từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
      `Tìm kiếm theo:  ${congty}, keyword: "${keyword}"`,
      `Danh sách chi nhánh: ${listChiNhanh}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },
  [logAction.GET_CHI_TIET_PHIEU_THU_TTV]: ({ mabn, tenbn, maphieuthu, tenthe }) =>
    `Xem chi tiết phiếu thu thẻ thành viên của bệnh nhân: mã bn: ${mabn} - tên bn: ${tenbn} - phiếu thu: ${maphieuthu} - tên thẻ: ${tenthe}`,
  [logAction.GET_DS_TTV]: ({ dateFrom, dateTo, listChiNhanh, loaithe, nhomthe }) => {
    return [
      `Danh sách thông tin thẻ thành viên: từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
      `Tìm kiếm theo: loại thẻ: ${loaithe}, nhóm thẻ: ${nhomthe}`,
      `Danh sách chi nhánh: ${listChiNhanh}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },
  [logAction.XUAT_EXCEL_DS_TTV]: ({ dateFrom, dateTo, listChiNhanh, loaithe, nhomthe }) => {
    return [
      `Xuất excel danh sách thông tin thẻ thành viên: từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
      `Tìm kiếm theo: loại thẻ: ${loaithe}, nhóm thẻ: ${nhomthe}`,
      `Danh sách chi nhánh: ${listChiNhanh}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },
  [logAction.CHI_TIET_TTV]: ({ mathe, tenthe }) =>
    `Xem chi tiết thẻ thành viên: mã thẻ: ${mathe} - tên thẻ: ${tenthe}`,

  [logAction.UPDATE_MA_TTV]: ({ mathecu, mathemoi }) =>
    `Thay đổi mã thẻ thành viên: mã thẻ cũ: ${mathecu} - mã thẻ mới: ${mathemoi}`,
  [logAction.TANG_THE_THANH_VIEN]: ({ nguoimua, tenthe, maphieuthu }) =>
    `Tặng thẻ thành viên: người tặng: ${nguoimua} - mã phiếu thu: ${maphieuthu} - tên thẻ: ${tenthe}`,
  [logAction.GIA_HAN_TTV]: ({ mathe, maphieuthu }) =>
    `Gia hạn thẻ thành viên: mã phiếu thu: ${maphieuthu} - mã thẻ: ${mathe}`,
  [logAction.NANG_CAP_TTV]: ({ mathe, maphieuthu }) =>
    `Nâng cấp thẻ thành viên: mã phiếu thu: ${maphieuthu} - mã thẻ: ${mathe}`,
  [logAction.VO_HIEU_HOA_TTV]: ({ mathe }) => `Vô hiệu hoá thẻ thành viên: mã thẻ: ${mathe}`,
  [logAction.HUY_TTV]: ({ mathe }) => `Huỷ thẻ thành viên: mã thẻ: ${mathe}`,
  [logAction.GET_LIST_HUY_TTV]: ({ dateFrom, dateTo, listChiNhanh, keyword }) => {
    return [
      `Danh sách huỷ thẻ thành viên: từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
      `Tìm kiếm theo keyword: "${keyword}"`,
      `Danh sách chi nhánh: ${listChiNhanh}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },
  [logAction.XUAT_EXCEL_LIST_HUY_TTV]: ({ dateFrom, dateTo, listChiNhanh, keyword }) => {
    return [
      `Xuất excel danh sách huỷ thẻ thành viên: từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
      `Tìm kiếm theo keyword: "${keyword}"`,
      `Danh sách chi nhánh: ${listChiNhanh}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },
  [logAction.SUA_TUONG_TAC_VACXIN_DO_TUOI]: () => `Sửa tương tác độ tuổi`,
  [logAction.SUA_TUONG_TAC_VACXIN_HOAT_CHAT]: () => `Sửa tương tác hoạt chất`,
  [logAction.SUA_TUONG_TAC_VACXIN_KHOANG_CACH]: () => `Sửa tương tác khoảng cách`,

  [logAction.GET_DS_BENH_AN]: () => `Tải danh sách bệnh án`,
  [logAction.XEM_SDT_BN]: () => `Xem số điện thoại`,

  [logAction.GET_DS_KHACH_HANG]: ({ keyword, nameDoiTac, page }) =>
    `Tải danh sách khách hàng của chi nhánh, tìm kiếm theo keyword: "${keyword}", tên đối tác: "${nameDoiTac}", trang: ${page}`, // eslint-disable-line
  [logAction.THEM_KHACH_HANG]: (list) =>
    `Thêm khách hàng: ${list.map((kh) => kh.tenKH).join(', ')}`,
  [logAction.XOA_KHACH_HANG]: () => `Xoá khách hàng`,
  [logAction.SUA_KHACH_HANG]: ({ khDetail }) => {
    return [
      `Sửa thông tin khách hàng:`,
      `Mã khách hàng: ${khDetail.idKH ?? ''}`,
      `Tên khách hàng: ${khDetail.tenKH ?? ''}`,
      `Giới tính: ${khDetail.gioiTinh ?? ''}`,
      `Ngày sinh: ${khDetail.ngaySinh ? moment(khDetail.ngaySinh).format('DD/MM/YYYY') : ''}`,
      `Số điện thoại: ${khDetail.dienThoai ?? ''}`,
      `Địa chỉ: ${khDetail.diaChi ?? ''}`,
      `ID Tỉnh/Thành phố: ${khDetail.idTinh ?? ''}`,
      `ID Quận/huyện: ${khDetail.idQuan ?? ''}`,
      `ID Phường xã: ${khDetail.idPhuong ?? ''}`,
      `ID Nghề nghiệp: ${khDetail.idNgheNghiep ?? ''}`,
      `ID Đối tượng: ${khDetail.idDoiTuong ?? ''}`,
      `CCCD: ${khDetail.cccd ?? ''}`,
      `Ghi chú: ${khDetail.ghiChu ?? ''}`,
      `ID Đối tác: ${khDetail.idDoiTac ?? ''}`,
      `Người sửa: ${khDetail.nguoiSua ?? ''}`,
    ]
      .filter(Boolean)
      .join('\n \t')
  },

  [logAction.THEM_DOI_TAC]: (form) => `Thêm đối tác
    Mã đối tác: ${form.maDoiTac}
    Tên đối tác: ${form.tenDoiTac}
    Số điện thoại: ${form.dienThoai}
    Email: ${form.website}
    Địa chỉ: ${form.diaChi}
    Ghi chú: ${form.ghiChu}`,

  [logAction.SUA_DOI_TAC]: (form) => `Sửa đối tác
    Mã đối tác: ${form.maDoiTac}
    Tên đối tác: ${form.tenDoiTac}
    Số điện thoại: ${form.dienThoai}
    Email: ${form.email}
    Địa chỉ: ${form.diaChi}
    Ghi chú: ${form.ghiChu}`,

  [logAction.THEM_DOI_TUONG]: () => `Thêm đối tượng`,
  [logAction.XOA_DOI_TUONG]: () => `Xoá mã đối tượng`,

  [logAction.THEM_NHAN_VIEN]: ({ id, maNhanVien, tenNhanVien }) =>
    `Thêm nhân viên IDNV: ${id} - Mã nhân viên: ${maNhanVien} - Tên: ${tenNhanVien} `,
  [logAction.XOA_NHAN_VIEN]: ({ idnv, manv, tennv }) =>
    `Xoá nhân viên IDNV: ${idnv} - Mã nhân viên: ${manv} - Tên: ${tennv} `,
  [logAction.SUA_NHAN_VIEN]: (nvDetail) => {
    return [
      `Sửa thông tin nhân viên:`,
      `Mã nhân viên: ${nvDetail.maNhanVien ?? ''}`,
      `Tên tắt chức danh: ${nvDetail.chucDanhVietTat ?? ''}`,
      `Tên nhân viên: ${nvDetail.tenNhanVien ?? ''}`,
      `Số điện thoại: ${nvDetail.dienThoai ?? ''}`,
      `Ngày sinh: ${nvDetail.ngaySinh ? moment(nvDetail.ngaySinh).format('DD/MM/YYYY') : ''}`,
      `Giới tính: ${nvDetail.gioiTinh ?? ''}`,
      `Bằng cấp: ${nvDetail.tenBangCap ?? ''}`,
      `Chức danh: ${nvDetail.tenChucDanh ?? ''}`,
      `Chi nhánh: ${nvDetail.tenChiNhanh ?? ''}`,
      `Tên máy chấm công: ${nvDetail.tenmaychamcong ?? ''}`,
      `Tỉnh/Thành phố: ${nvDetail.tenTinh ?? ''}`,
      `Quận/huyện: ${nvDetail.idquatenQuan ?? ''}`,
      `Phường xã: ${nvDetail.tenPhuong ?? ''}`,
      `Địa chỉ: ${nvDetail.diachi ?? ''}`,
      `Đã nghỉ việc: ${nvDetail.thoiviec ?? ''}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },

  [logAction.SUA_THE_THANH_VIEN]: ({
    idlt,
    malt,
    tenloaithe,
    tiensudung,
    thoigianhethan,
    idnhom,
    idchuyenkhoa,
    ghichu,
    nguoisua,
  }) => {
    return [
      `Sửa thẻ thành viên:`,
      `ID loại thẻ: ${idlt ?? ''}`,
      `Mã loại thẻ: ${malt ?? ''}`,
      `Tên loại thẻ: ${tenloaithe ?? ''}`,
      `Tiền sử dụng: ${tiensudung ?? 0}`,
      `Thời gian hết hạn: ${thoigianhethan ? moment(thoigianhethan).format('DD/MM/YYYY') : ''}`,
      `ID nhóm: ${idnhom ?? ''}`,
      `ID chuyên khoa: ${idchuyenkhoa ?? ''}`,
      `Ghi chú: ${ghichu ?? ''}`,
      `Người sửa: ${nguoisua ?? ''}`,
    ]
      .filter(Boolean)
      .join('\n\t')
  },

  [logAction.GET_DS_NHAN_VIEN]: ({ companyName, branchName, keyword, page }) =>
    `Xem danh sách nhân viên  ${companyName} ,chi nhánh: ${branchName},tìm kiếm theo keyword: "${keyword}" , trang: ${page}`,
  [logAction.XUAT_EXCEL_DS_NHAN_VIEN]: ({ companyName, branchName, keyword }) =>
    `Xuất excel danh sách nhân viên  ${companyName} ,chi nhánh: ${branchName},tìm kiếm theo keyword: "${keyword}"`,

  [logAction.THEM_PHIEU_XUAT_SU_DUNG]: () => `Thêm phiếu xuất sử dụng`,
  [logAction.XAC_NHAN_PHIEU_XUAT_SU_DUNG]: () => `Xác nhận phiếu xuất sử dụng`,
  [logAction.XOA_PHIEU_XUAT_SU_DUNG]: () => `Xoá phiếu xuất sử dụng`,

  [logAction.TIM_KIEM_BENH_NHAN]: ({ keyword }) =>
    `Tìm kiếm thông tin bệnh nhân với từ khoá: "${keyword}"`,

  [logAction.GET_DS_BN_GUI_SMS]: ({ nameChuyenKhoa, nameTinhThanh, nameTTV, nameDoTuoi }) =>
    `Xem danh sách bệnh nhân gửi sms theo: chuyên khoa: ${nameChuyenKhoa}, tỉnh thành: ${nameTinhThanh}, thẻ thành viên: ${nameTTV}, độ tuổi: ${nameDoTuoi}`,
  [logAction.XAC_NHAN_GUI_SMS]: ({ maOTP, nguoiGui }) =>
    `Xác nhận gửi sms với mã OTP: ${maOTP} - người gửi: ${nguoiGui}`,
  [logAction.GET_OTP_SMS]: ({ nguoiYeuCau }) =>
    `Gửi yêu cầu lấy mã otp - người yêu cầu: ${nguoiYeuCau}`,

  [logAction.CHINH_SUA_TRANG_THAI_KHAM_BENH]: ({ maphieuthu, trangThai }) =>
    `Chỉnh sửa trạng thái khám bệnh: phiếu thu: ${maphieuthu} - trạng thái: ${trangThai}`,

  [logAction.CHINH_SUA_THOI_GIAN_THANH_TOAN]: ({ maphieuthu, ngaythanhtoan, option }) =>
    `Cập nhật ngày thanh toán: phiếu thu: ${maphieuthu} - ngày thanh toán: ${ngaythanhtoan ? moment(ngaythanhtoan).format('DD/MM/YYYY') : ''} - ${option}`,

  [logAction.CHINH_NGAY_TAI_KHAM]: ({ maphieuthu, ngaytaikham }) =>
    `Chỉnh sửa ngày tái khám bệnh: phiếu thu: ${maphieuthu} - ngày tái khám: ${ngaytaikham ? moment(ngaytaikham).format('DD/MM/YYYY') : ''}`,

  [logAction.CAP_NHAT_TTV]: ({
    maphieuthu,
    ngaythanhtoan,
    tienmat,
    chuyenkhoan,
    momo,
    vnpay,
    zalopay,
    pos,
    tiengiamgia,
    tienthanhtoan,
    thett,
    ghichu,
  }) =>
    `Chỉnh sửa giá thẻ thành viên: phiếu thu: ${maphieuthu} - ngày thanh toán: ${ngaythanhtoan ? moment(ngaythanhtoan).format('DD/MM/YYYY') : ''} \n Tiền mặt: ${tienmat} \n Chuyển khoản: ${chuyenkhoan} \n MoMo: ${momo} \n VNPay: ${vnpay} \n ZaloPay: ${zalopay} \n POS: ${pos} \n Tiền giảm giá: ${tiengiamgia} \n Tiền thanh toán: ${tienthanhtoan} \n Thẻ thành viên: ${thett} \n Ghi chú: ${ghichu}`,
  [logAction.GET_DS_CHUONG_TRINH_TAI_KHAM]: () => `Xem danh sách chương trình tái khám`,
  [logAction.THEM_CHUONG_TRINH_TAI_KHAM]: ({ tendichvu }) =>
    `Thêm chương trình tái khám: ${tendichvu}`,
  [logAction.XOA_CHUONG_TRINH_TAI_KHAM]: ({ tendichvu }) =>
    `Xoá chương trình tái khám: ${tendichvu}`,
  [logAction.CAP_NHAT_CHUONG_TRINH_TAI_KHAM]: ({ tendichvu, hieuluc }) =>
    `Cập nhật hiệu lực chương trình tái khám: ${tendichvu} - Hiệu lực: ${hieuluc ? 'Có' : 'Không'}`,
  [logAction.CHINH_SUA_CA_LAM_VIEC]: ({ maphieuthu, loaithanhtoan, calamviec }) =>
    `Cập nhật ca làm việc của phiếu thu: ${maphieuthu} - loại thanh toán: ${loaithanhtoan} - ca làm việc: ${calamviec}`,
  [logAction.CHINH_SUA_LOAI_KHAM]: ({ maphieuthu, tenloaikham }) =>
    `Cập nhật loại khám của phiếu thu: ${maphieuthu} - loại khám : ${tenloaikham}`,
  [logAction.CHINH_SUA_NGUOI_BAN_TTV]: ({ maphieuthu, tennguoiban, tennguoitao }) =>
    `Cập nhật người bán thẻ thành viên: ${maphieuthu} - người bán : ${tennguoiban} - người tạo: ${tennguoitao}`,

  [logAction.TAO_PHIEU_CHUYEN_KHO_CTY]: ({ maphieuchuyen, tenphieu }) =>
    `Tạo phiếu chuyển kho  mã phiếu: ${maphieuchuyen} - tên phiếu: ${tenphieu}`,
  [logAction.GET_DS_PHIEU_CHUYEN_KHO_CTY]: ({ nameCompany, nameStatus }) =>
    `Xem danh sách phiếu chuyển kho: ${nameStatus} -  ${nameCompany}`,
  [logAction.XUAT_EXCEL_DS_PHIEU_CHUYEN_KHO_CTY]: ({ nameCompany, nameStatus }) =>
    `Xuất excel danh sách phiếu chuyển kho: ${nameStatus} -  ${nameCompany}`,

  [logAction.GET_DS_PHIEU_CHUYEN_KHO_CTY_DA_NHAN]: ({
    nameCompany,
    dateFrom,
    dateTo,
    nameChiNhanh,
    keyword,
  }) =>
    `Xem danh sách phiếu chuyển kho   ${nameCompany}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo từ khoá: "${keyword}"`,
  [logAction.XUAT_EXCEL_DS_PHIEU_CHUYEN_KHO_CTY_DA_NHAN]: ({
    nameCompany,
    dateFrom,
    dateTo,
    nameChiNhanh,
    keyword,
  }) =>
    `Xuất excel danh sách phiếu chuyển kho   ${nameCompany}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo từ khoá: "${keyword}"`,

  [logAction.TAO_PHIEU_XUAT_SU_DUNG]: ({ maphieu, tenphieu }) =>
    `Tạo phiếu xuất sử dụng: mã phiếu: ${maphieu} - tên phiếu: ${tenphieu}`,
  [logAction.GET_DS_PHIEU_XUAT_SU_DUNG]: ({
    nameCompany,
    dateFrom,
    dateTo,
    nameChiNhanh,
    keyword,
  }) =>
    `Xem danh sách phiếu xuất sử dụng:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo từ khoá: "${keyword}"`,
  [logAction.GET_CHI_TIET_PHIEU_XUAT_SU_DUNG]: ({ maphieu, tenphieu }) =>
    `Xem chi tiết phiếu xuất sử dụng: mã phiếu: ${maphieu} - tên phiếu: ${tenphieu} `,
  [logAction.GET_DS_HANG_HOA_XUAT_SU_DUNG]: ({
    nameCompany,
    dateFrom,
    dateTo,
    nameChiNhanh,
    keyword,
  }) =>
    `Xem danh sách hàng hoá phiếu xuất sử dụng:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo từ khoá: "${keyword}"`,
  [logAction.XUAT_EXCEL_DS_HANG_HOA_XUAT_SU_DUNG]: ({
    nameCompany,
    dateFrom,
    dateTo,
    nameChiNhanh,
    keyword,
  }) =>
    `Xuất excel danh sách hàng hoá phiếu xuất sử dụng:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo từ khoá: "${keyword}"`,

  [logAction.TAO_PHIEU_DIEU_CHINH_TON_KHO]: ({ maphieu }) =>
    `Tạo phiếu điều chỉnh tồn kho: mã phiếu: ${maphieu}`,
  [logAction.GET_DS_PHIEU_DIEU_CHINH_TON_KHO]: ({
    nameCompany,
    dateFrom,
    dateTo,
    nameChiNhanh,
    keyword,
  }) =>
    `Xem danh sách phiếu điều chỉnh tồn kho:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo từ khoá: "${keyword}"`,
  [logAction.GET_CHI_TIET_PHIEU_DIEU_CHINH_TON_KHO]: ({ maphieu }) =>
    `Xem chi tiết phiếu điều chỉnh tồn kho: mã phiếu: ${maphieu}`,
  [logAction.GET_DS_HANG_HOA_DIEU_CHINH_TON_KHO]: ({
    nameCompany,
    dateFrom,
    dateTo,
    nameChiNhanh,
    keyword,
  }) =>
    `Xem danh sách hàng hoá phiếu điều chỉnh tồn kho:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo từ khoá: "${keyword}"`,

  [logAction.GET_HANG_HOA_KHO_KIEM_KE]: ({ nameCompany, nameChiNhanh, keyword, nameKho, nameKy }) =>
    `Xem danh sách hàng hoá kho kiểm kê:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, kho: ${nameKho}, kỳ hàng: ${nameKy}, tìm kiếm theo keyword: "${keyword}"`,
  [logAction.XUAT_EXCEL_HANG_HOA_KHO_KIEM_KE]: ({
    nameCompany,
    nameChiNhanh,
    keyword,
    nameKho,
    nameKy,
  }) =>
    `Xuất excel danh sách hàng hoá kho kiểm kê:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, kho: ${nameKho}, kỳ hàng: ${nameKy}, tìm kiếm theo keyword: "${keyword}"`,
  [logAction.CHOT_TON_HANG_KHO_KIEM_KE]: ({ tenky }) => `Chốt tồn hàng: kỳ hàng: ${tenky}`,
  [logAction.GET_DS_HANG_HOA_DIEU_CHINH_KHO_KIEM_KE]: ({
    nameCompany,
    nameChiNhanh,
    nameKho,
    nameKy,
  }) =>
    `Xem danh sách hàng hoá điều chỉnh kho kiểm kê:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, kho: ${nameKho}, kỳ hàng: ${nameKy}`,
  [logAction.XUAT_EXCEL_HANG_HOA_DIEU_CHINH_KHO_KIEM_KE]: ({
    nameCompany,
    nameChiNhanh,
    nameKho,
    nameKy,
  }) =>
    `Xuất excel danh sách hàng hoá điều chỉnh kho kiểm kê:  ${nameCompany}, chi nhánh: ${nameChiNhanh}, kho: ${nameKho}, kỳ hàng: ${nameKy}`,
  [logAction.CHOT_DIEU_CHINH_KHO_KIEM_KE]: ({ tenky }) =>
    `Chốt điều chỉnh kho kiểm kê: kỳ hàng: ${tenky}`,

  [logAction.GET_DS_BN_DA_TIEM_CHUNG]: ({ nameChiNhanh, dateFrom, dateTo }) =>
    `Xem danh sách bệnh nhân đã tiêm chủng: chi nhánh: ${nameChiNhanh}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}`,
  [logAction.GET_DETAIL_BN_TIEM_CHUNG]: ({ mabn, tenbn, ngaykham }) =>
    `Xem chi tiết bệnh nhân đã tiêm chủng: mã bệnh nhân: ${mabn}, tên bệnh nhân: ${tenbn}, ngày khám: ${ngaykham ? moment(ngaykham).format('DD/MM/YYYY') : ''}`,
  [logAction.ADD_VTTH_BN_TIEM_CHUNG]: ({ mabn, tenbn, listVatTu }) =>
    `Thêm vật tư tiêu hao cho bệnh nhân tiêm chủng: mã bệnh nhân: ${mabn}, tên bệnh nhân: ${tenbn} \nDanh sách vật tư:\n ${listVatTu}`,
  [logAction.LUU_BN_THEO_DOI_SAU_TIEM]: ({ mabn, tenbn }) =>
    `Lưu thông tin bệnh nhân theo dõi sau tiêm: mã bệnh nhân: ${mabn}, tên bệnh nhân: ${tenbn}`,

  [logAction.BAO_CAO_DOANH_THU_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo doanh thu tổng hợp:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DOANH_THU_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo doanh thu tổng hợp:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_DOANH_THU_CHI_TIET]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo doanh thu chi tiết:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DOANH_THU_CHI_TIET]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo doanh thu chi tiết:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_DOANH_THU_CHI_TIET_THANG]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo doanh thu chi tiết tháng:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DOANH_THU_CHI_TIET_THANG]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo doanh thu chi tiết tháng:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_LOI_NHUAN_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo lợi nhuận tổng hợp:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_LOI_NHUAN_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo lợi nhuận tổng hợp:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_LOI_NHUAN_THUOC]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo lợi nhuận thuốc:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_LOI_NHUAN_THUOC]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo lợi nhuận thuốc:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_LOI_NHUAN_VACXIN]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo lợi nhuận vacxin:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_LOI_NHUAN_VACXIN]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo lợi nhuận vacxin:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_VTTH_TONG_HOP]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xem báo cáo vật tư tiêu hao tổng hợp:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_VTTH_TONG_HOP]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xuất excel báo cáo vật tư tiêu hao tổng hợp:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_VTTH_CHI_TIET]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xem báo cáo vật tư tiêu hao chi tiết:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_VTTH_CHI_TIET]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xuất excel báo cáo vật tư tiêu hao chi tiết:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_PHIEU_THU]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xem báo cáo phiếu thu:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_PHIEU_THU]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xuất excel báo cáo phiếu thu:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_LUOT_KHAM_TONG]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
    page,
  }) =>
    `Xem báo cáo lượt khám tổng hợp:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, trang: ${page} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_LUOT_KHAM_TONG]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo lượt khám tổng hợp:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_LUOT_KHAM_DOANH_THU]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo lượt khám doanh thu:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_LUOT_KHAM_DOANH_THU]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo lượt khám doanh thu:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_LUOT_KHAM_CHI_TIET]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
    page,
  }) =>
    `Xem báo cáo lượt khám chi tiết:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, trang: ${page} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_LUOT_KHAM_CHI_TIET]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo lượt khám chi tiết:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_LUOT_KHAM_THEO_DOI_CA_KHAM]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo lượt khám theo dõi ca khám:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_LUOT_KHAM_THEO_DOI_CA_KHAM]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo lượt khám theo dõi ca khám:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_LUOT_KHAM_THEO_DOI_NGUON_KHACH]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo lượt khám theo dõi nguồn khách:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_LUOT_KHAM_THEO_DOI_NGUON_KHACH]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo lượt khám theo dõi nguồn khách:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_LUOT_KHAM_LOAI_KHAM]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
    page,
  }) =>
    `Xem báo cáo lượt khám loại khám:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, trang: ${page} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_LUOT_KHAM_LOAI_KHAM]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo lượt khám loại khám:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_LUOT_KHAM_THEO_DOI_KHACH_MOI]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo lượt khám theo dõi khách mới:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_LUOT_KHAM_THEO_DOI_KHACH_MOI]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo lượt khám theo dõi khách mới:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_LUOT_KHAM_THEO_DOI_DOI_TUONG]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo lượt khám theo dõi đối tượng:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_LUOT_KHAM_THEO_DOI_DOI_TUONG]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo lượt khám theo dõi đối tượng:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_THONG_KE_CHUYEN_KHOA_NHI]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xem báo cáo thống kê chuyên khoa nhi:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_THONG_KE_CHUYEN_KHOA_NHI]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
  }) =>
    `Xuất excel báo cáo thống kê chuyên khoa nhi:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_THONG_KE_CHUYEN_KHOA_SAN]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xem báo cáo thống kê chuyên khoa sản:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_THONG_KE_CHUYEN_KHOA_SAN]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
  }) =>
    `Xuất excel báo cáo thống kê chuyên khoa sản:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_THONG_KE_CHUYEN_KHOA_TMTD]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
  }) =>
    `Xem báo cáo thống kê chuyên khoa TMTD:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_THONG_KE_CHUYEN_KHOA_TMTD]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
  }) =>
    `Xuất excel báo cáo thống kê chuyên khoa TMTD:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_THONG_KE_CHUYEN_KHOA_MAT]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xem báo cáo thống kê chuyên khoa mắt:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_THONG_KE_CHUYEN_KHOA_MAT]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
  }) =>
    `Xuất excel báo cáo thống kê chuyên khoa mắt:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_THONG_KE_CHUYEN_KHOA_MAT]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xem báo cáo thống kê chuyên khoa mắt:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_THONG_KE_CHUYEN_KHOA_MAT]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
  }) =>
    `Xuất excel báo cáo thống kê chuyên khoa mắt:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_THONG_KE_CHUYEN_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo thống kê tổng hợp:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_THONG_KE_CHUYEN_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo thống kê tổng hợp:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_DICH_VU_KHOA_PHONG]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xem báo cáo dịch vụ khoa phòng:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DICH_VU_KHOA_PHONG]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
  }) =>
    `Xuất excel báo cáo dịch vụ khoa phòng:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_DICH_VU_KHOA_PHONG]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xem báo cáo dịch vụ khoa phòng:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DICH_VU_KHOA_PHONG]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
  }) =>
    `Xuất excel báo cáo dịch vụ khoa phòng:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_DICH_VU_NHOM_DICH_VU]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xem báo cáo dịch vụ nhóm dịch vụ:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DICH_VU_NHOM_DICH_VU]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
  }) =>
    `Xuất excel báo cáo dịch vụ nhóm dịch vụ:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_DICH_VU_CHI_TIET_DICH_VU]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xem báo cáo dịch vụ chi tiết dịch vụ:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DICH_VU_CHI_TIET_DICH_VU]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
  }) =>
    `Xuất excel báo cáo dịch vụ chi tiết dịch vụ:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_HOA_HONG_THU_THUAT_SAN]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
  }) =>
    `Xem báo cáo hoa hồng thủ thuật sản:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_HOA_HONG_THU_THUAT_SAN]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
  }) =>
    `Xuất excel báo cáo hoa hồng thủ thuật sản:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_HOA_HONG_THU_THUAT_MAT]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
  }) =>
    `Xem báo cáo hoa hồng thủ thuật mắt:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_HOA_HONG_THU_THUAT_MAT]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
  }) =>
    `Xuất excel báo cáo hoa hồng thủ thuật mắt:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_TTV]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xem báo cáo thẻ thành viên:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_TTV]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xuất excel báo cáo thẻ thành viên:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_DOANH_SO_TTV_NHI]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
    nameCa,
  }) =>
    `Xem báo cáo doanh số TTV nhi:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},ca làm việc: ${nameCa}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DOANH_SO_TTV_NHI]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
    nameCa,
  }) =>
    `Xuất excel báo cáo doanh số TTV nhi:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},ca làm việc: ${nameCa}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_DOANH_SO_TTV_SAN]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
    nameCa,
  }) =>
    `Xem báo cáo doanh số TTV sản:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},ca làm việc: ${nameCa}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DOANH_SO_TTV_SAN]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
    nameCa,
  }) =>
    `Xuất excel báo cáo doanh số TTV sản:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},ca làm việc: ${nameCa}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_DOANH_SO_TTV_LAO]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
    nameCa,
  }) =>
    `Xem báo cáo doanh số TTV lão:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},ca làm việc: ${nameCa}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DOANH_SO_TTV_LAO]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
    nameCa,
  }) =>
    `Xuất excel báo cáo doanh số TTV lão:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},ca làm việc: ${nameCa}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_DOANH_SO_TTV_MAT]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
    nameCa,
  }) =>
    `Xem báo cáo doanh số TTV mắt:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},ca làm việc: ${nameCa}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DOANH_SO_TTV_MAT]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
    nameCa,
  }) =>
    `Xuất excel báo cáo doanh số TTV mắt:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},ca làm việc: ${nameCa}, tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_TONG_QUAN_TTV]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo tổng quan TTV:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_TONG_QUAN_TTV]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo tổng quan TTV:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_XET_NGHIEM]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xem báo cáo xét nghiệm:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_XET_NGHIEM]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo }) =>
    `Xuất excel báo cáo xét nghiệm:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_XUAT_VACXIN]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo xuất vắc xin:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_XUAT_VACXIN]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo xuất vắc xin:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_XUAT_NHA_THUOC_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
  }) =>
    `Xem báo cáo nhà thuốc tổng hợp:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_XUAT_NHA_THUOC_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
  }) =>
    `Xuất excel báo cáo nhà thuốc tổng hợp:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_XUAT_NHA_THUOC_CHI_TIET]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
    loaitoa,
  }) =>
    `Xem báo cáo nhà thuốc tổng hợp:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},tìm kiếm theo keyword: "${keyword}", loại toa: ${loaitoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_XUAT_NHA_THUOC_CHI_TIET]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
    loaitoa,
  }) =>
    `Xuất excel báo cáo nhà thuốc tổng hợp:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},tìm kiếm theo keyword: "${keyword}", loại toa: ${loaitoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_GONG_KINH]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo, keyword }) =>
    `Xem báo cáo gọng kính:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_GONG_KINH]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
  }) =>
    `Xuất excel báo cáo gọng kính:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_CONG_MAI]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo, keyword }) =>
    `Xem báo cáo công mài:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_CONG_MAI]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    keyword,
  }) =>
    `Xuất excel báo cáo công mài:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''},tìm kiếm theo keyword: "${keyword}" \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_TI_LE_TOA]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo tỉ lệ toa:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_TI_LE_TOA]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo tỉ lệ toa:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_DO_LECH]: ({ nameCompany, nameChiNhanh, dateFrom, dateTo, nameChuyenKhoa }) =>
    `Xem báo cáo độ lệch:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DO_LECH]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo độ lệch:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_DOANH_THU_THUOC_THEO_TUAN]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo doanh thu thuốc theo tuần:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DOANH_THU_THUOC_THEO_TUAN]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo doanh thu thuốc theo tuần:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_DOANH_THU_THUOC_TUY_CHON]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo doanh thu thuốc tuỳ chọn:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_DOANH_THU_THUOC_TUY_CHON]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo doanh thu thuốc tuỳ chọn:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_THOI_GIAN_KHAM_THEO_CHI_NHANH]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo thời gian khám theo chi nhánh:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_THOI_GIAN_KHAM_THEO_CHI_NHANH]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo thời gian khám theo chi nhánh:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_THOI_GIAN_KHAM_THEO_BAC_SI]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo thời gian khám theo bác sĩ:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_THOI_GIAN_KHAM_THEO_BAC_SI]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo thời gian khám theo bác sĩ:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_THOI_GIAN_KHAM_CHI_TIET_PHIEU]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo thời gian khám theo chi tiết phiếu:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_THOI_GIAN_KHAM_CHI_TIET_PHIEU]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo thời gian khám theo chi tiết phiếu:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_TIEM_CHUNG]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo tiêm chủng:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_TIEM_CHUNG]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo tiêm chủng:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_HANG_HOA]: ({ nameCompany, date, nameNhomThuoc, keyword, page }) =>
    `Xem báo cáo hàng hoá:  ${nameCompany}, tháng: ${date ? moment(date).format('MM/YYYY') : ''}, nhóm thuốc: ${nameNhomThuoc}, tìm kiếm theo keyword: "${keyword}", trang: ${page}`,
  [logAction.XUAT_EXCEL_BAO_CAO_HANG_HOA]: ({ nameCompany, date, nameNhomThuoc, keyword }) =>
    `Xuất excel báo cáo hàng hoá:  ${nameCompany}, tháng: ${date ? moment(date).format('MM/YYYY') : ''}, nhóm thuốc: ${nameNhomThuoc}, tìm kiếm theo keyword: "${keyword}"`,
  [logAction.BAO_CAO_PHIEU_XUAT_DOANH_THU_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo phiếu xuất doanh thu tổng hợp:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_PHIEU_XUAT_DOANH_THU_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo phiếu xuất doanh thu tổng hợp:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_PHIEU_XUAT_DOANH_THU_CHI_TIET]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo phiếu xuất doanh thu chi tiết:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_PHIEU_XUAT_DOANH_THU_CHI_TIET]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo phiếu xuất doanh thu chi tiết:  ${nameCompany}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, chuyên khoa: ${nameChuyenKhoa} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_THEO_DOI_PHU_SAN_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo theo dõi phụ sản tổng hợp:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_THEO_DOI_PHU_SAN_TONG_HOP]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo theo dõi phụ sản tổng hợp:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.BAO_CAO_THEO_DOI_PHU_SAN_CHI_TIET]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo theo dõi phụ sản chi tiết:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_THEO_DOI_PHU_SAN_CHI_TIET]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo theo dõi phụ sản chi tiết:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_THEO_DOI_PHU_SAN_SAY_THAI]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xem báo cáo theo dõi phụ sản sảy thai:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,
  [logAction.XUAT_EXCEL_BAO_CAO_THEO_DOI_PHU_SAN_SAY_THAI]: ({
    nameCompany,
    nameChiNhanh,
    dateFrom,
    dateTo,
    nameChuyenKhoa,
  }) =>
    `Xuất excel báo cáo theo dõi phụ sản sảy thai:  ${nameCompany}, chuyên khoa: ${nameChuyenKhoa}, từ ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''}, đến ngày: ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''} \nChi nhánh:\n${nameChiNhanh}`,

  [logAction.BAO_CAO_BANG_KE_PHIEU_THU]: () => `Xem bảng kê phiếu thu`,
  [logAction.BAO_CAO_BIEU_MAU_PHONG_KHAM]: () => `Xem báo cáo biểu mẫu phòng khám`,
  [logAction.BAO_CAO_CHI_TIET_XUAT]: () => `Xem báo cáo chi tiết xuất`,
  [logAction.BAO_CAO_CHUNG_TU_NHAP_KHO]: () => `Xem báo cáo chứng từ nhập kho`,
  [logAction.BAO_CAO_DICH_VU]: () => `Xem báo cáo dịch vụ`,
  [logAction.BAO_CAO_GUI_SMS]: () => `Xem báo cáo gửi sms`,
  [logAction.BAO_CAO_HANG_HOA_NHAP_KHO]: () => `Xem báo cáo hàng hoá nhập kho`,
  [logAction.BAO_CAO_LOI_NHUAN]: () => `Xem báo cáo lợi nhuận`,
  [logAction.BAO_CAO_NHAN_VIEN]: () => `Xem báo cáo nhân viên`,
  [logAction.BAO_CAO_PHIEU_BAN_HANG]: () => `Xem báo cáo phiếu bán hàng`,
  [logAction.BAO_CAO_PHIEU_BAN_THEO_TOA]: () => `Xem báo cáo phiếu bán theo toa`,
  [logAction.BAO_CAO_PHIEU_XUAT]: () => `Xem báo cáo phiếu xuất`,
  [logAction.BAO_CAO_THEO_DOI_CHUYEN_KHO]: () => `Xem báo cáo theo dõi chuyển kho`,
  [logAction.BAO_CAO_THEO_DOI_DOANH_SO]: () => `Xem báo cáo theo dõi doanh số`,
  [logAction.BAO_CAO_THEO_DOI_DOI_TUONG]: () => `Xem báo cáo theo dõi đối tượng`,
  [logAction.BAO_CAO_THEO_DOI_KHACH_MOI]: () => `Xem báo cáo theo dõi khách mới`,
  [logAction.BAO_CAO_THEO_DOI_LUOT_KHAM]: () => `Xem báo cáo theo dõi lượt khám`,
  [logAction.BAO_CAO_THEO_DOI_NGUON_KHACH]: () => `Xem báo cáo theo dõi nguồn khách`,
  [logAction.BAO_CAO_TONG_HOP]: () => `Xem báo cáo tổng hợp`,
  [logAction.BIEU_MAU_MISA_PHIEU_BAN_HANG]: () => `Xem biểu mẫu misa - phiếu bán hàng`,

  [logAction.GET_DS_CONG_TY_CRM]: ({ keyword, page }) =>
    `Xem danh sách công ty CRM: tìm kiếm theo keyword: "${keyword}", trang: ${page}`,
  [logAction.THEM_MOI_CONG_TY_CRM]: ({
    diachi,
    dienthoai,
    email,
    fax,
    ghichu,
    masothue,
    tenct,
    website,
  }) => `Tạo công ty CRM:
  \n Địa chỉ: ${diachi}
  \n Điện thoại: ${dienthoai}
  \n Email: ${email}
  \n Fax: ${fax}
  \n Mã số thuế: ${masothue}
  \n Ghi chú: ${ghichu}
  \n Tên công ty: ${tenct}
  \n Website: ${website}
  `,

  [logAction.CAP_NHAT_CONG_TY_CRM]: ({
    diachi,
    dienthoai,
    email,
    fax,
    ghichu,
    masothue,
    tenct,
    website,
  }) => `Cập nhật công ty CRM:
  \n Địa chỉ: ${diachi}
  \n Điện thoại: ${dienthoai}
  \n Email: ${email}
  \n Fax: ${fax}
  \n Mã số thuế: ${masothue}
  \n Ghi chú: ${ghichu}
  \n Tên công ty: ${tenct}
  \n Website: ${website}
  `,
  [logAction.GET_THONG_TIN_LIEN_HE]: ({ tenct }) => `Xem thông tin liên hệ của công ty: ${tenct}`,

  [logAction.ADD_THONG_TIN_LIEN_HE]: ({
    emaillienhe,
    dienthoai,
    nguoilienhe,
    ghichu,
    tencongty,
  }) => `Tạo thông tin liên hệ của công ty ${tencongty}:
  \n Điện thoại: ${dienthoai}
  \n Email liên hệ: ${emaillienhe},
  \n Người liên hệ: ${nguoilienhe},
  \n Ghi chú: ${ghichu}
  `,

  [logAction.EDIT_THONG_TIN_LIEN_HE]: ({
    emaillienhe,
    dienthoai,
    nguoilienhe,
    ghichu,
    tencongty,
  }) => `Cập nhật thông tin liên hệ của công ty ${tencongty}:
  \n Điện thoại: ${dienthoai}
  \n Email liên hệ: ${emaillienhe},
  \n Người liên hệ: ${nguoilienhe},
  \n Ghi chú: ${ghichu}
  `,

  [logAction.GET_DS_GOI_KHAM]: () => `Xem danh sách gói khám`,

  [logAction.ADD_GOI_KHAM]: ({ tengoikham, tungay, denngay, ghichu, listdv }) => `Tạo gói khám:
  \n Tên gói khám: ${tengoikham}
  \n Từ ngày: ${tungay ? moment(tungay).format('DD/MM/YYYY') : ''} đến ngày: ${denngay ? moment(denngay).format('DD/MM/YYYY') : ''},
  \n Ghi chú: ${ghichu}
  \n Danh sách dịch vụ: \n${listdv}
  `,

  [logAction.EDIT_GOI_KHAM]: ({
    tengoikham,
    tungay,
    denngay,
    ghichu,
    listdv,
  }) => `Cập nhật gói khám:
  \n Tên gói khám: ${tengoikham}
  \n Từ ngày: ${tungay ? moment(tungay).format('DD/MM/YYYY') : ''} đến ngày: ${denngay ? moment(denngay).format('DD/MM/YYYY') : ''},
  \n Ghi chú: ${ghichu}
  \n Danh sách dịch vụ: \n${listdv}
  `,

  [logAction.DELETE_GOI_KHAM]: ({ tengoikham }) => `Xoá gói khám: ${tengoikham}`,
  [logAction.GET_DETAIL_GOI_KHAM]: ({ tengoikham }) => `Xem chi tiết gói khám: ${tengoikham}`,

  [logAction.GET_DS_KHACH_HANG_CRM]: () => `Xem danh sách khách hàng CRM`,
  [logAction.GET_DS_KH_BY_IDNV]: ({ idnv }) =>
    `Xem danh sách khách hàng CRM by ID nhân viên: ID ${idnv}`,
  [logAction.ADD_KHACH_HANG]: ({ form }) => `Thêm mới khách hàng:\n${formatPayloadDetails(form)}`,
  [logAction.EDIT_KHACH_HANG]: ({ form }) => `Chỉnh sửa khách hàng:\n${formatPayloadDetails(form)}`,

  [logAction.GET_DS_GOI_KHAM_KHACH_HANG]: ({ id }) =>
    `Xem danh sách gói khám khách hàng CRM: ID Khách hàng: ${id}`,
  [logAction.ADD_GOI_KHAM_KHACH_HANG]: ({ form }) =>
    `Thêm mới gói khám khách hàng:\n${formatPayloadDetails(form)}`,
  [logAction.EDIT_GOI_KHAM_KHACH_HANG]: ({ form }) =>
    `Chỉnh sửa gói khám khách hàng:\n${formatPayloadDetails(form)}`,

  [logAction.GET_DETAIL_PHAN_CONG]: ({ id }) =>
    `Xem chi tiết phân công liên hệ: ID phân công liên hệ: ${id}`,
  [logAction.ADD_PHAN_CONG]: ({ form }) =>
    `Thêm mới phân công liên hệ:\n${formatPayloadDetails(form)}`,
  [logAction.EDIT_PHAN_CONG]: ({ form }) =>
    `Chỉnh sửa phân công liên hệ:\n${formatPayloadDetails(form)}`,

  [logAction.GET_DS_CHI_TIET_LIEN_HE]: ({ idnv, idct }) =>
    `Xem danh sách chi tiết liên hệ của nhân viên: ID nhân viên: ${idnv} - ID khách hàng: ${idct}`,
  [logAction.GET_DETAIL_CHI_TIET_LIEN_HE]: ({ id }) =>
    `Xem chi tiết liên hệ: ID chi tiết liên hệ: ${id}`,
  [logAction.ADD_CHI_TIET_LIEN_HE]: ({ form }) =>
    `Thêm mới chi tiết liên hệ:\n${formatPayloadDetails(form)}`,
  [logAction.DOWNLOAD_FILE_CHI_TIET_LIEN_HE]: ({ name }) =>
    `Tải xuống file chi tiết liên hệ: ${name}`,

  [logAction.GET_DS_BENH_NHAN_CRM]: ({ page }) =>
    `Xem danh sách bệnh nhân CRM theo điều kiện, trang: ${page}`,

  [logAction.GUI_SMS_DS_BENH_NHAN]: ({ name, listID }) =>
    `Gửi sms danh sách bệnh nhân: người gửi: ${name} \nDanh sách ID bệnh nhân: \n ${listID}`,
  [logAction.GUI_SMS_ALL_BENH_NHAN]: ({ name }) =>
    `Gửi sms danh sách tất cả bệnh nhân theo điều kiện: người gửi: ${name} `,
  [logAction.XUAT_EXCEL_ALL_BENH_NHAN_CRM]: () => `Xuất excel danh sách tất cả bệnh nhân CRM`,
  [logAction.IMPORT_DS_BENH_NHAN_CRM]: ({ listBN }) =>
    `Import excel danh sách bệnh nhân CRM:\n${listBN}`,
  [logAction.ADD_BENH_NHAN_CRM]: ({ form }) =>
    `Thêm mới bệnh nhân CRM:\n${formatPayloadDetails(form)}`,
  [logAction.EDIT_BENH_NHAN_CRM]: ({ form }) =>
    `Chỉnh sửa bệnh nhân CRM:\n${formatPayloadDetails(form)}`,
  [logAction.DELETE_BENH_NHAN_CRM]: ({ id }) => `Xoá bệnh nhân CRM: ID bệnh nhân CRM: ${id}`,

  [logAction.XEM_NGAY_KHAM]: () => `Xem ngày khám từ`,
  [logAction.XEM_TAI_KHAM]: ({ Branchname, tuNgay, denNgay }) => `Xem tái khám từ
    Chi nhánh: ${Branchname}
    Từ ngày : ${tuNgay}
    Đến ngày : ${denNgay}
    `,
  [logAction.XUAT_EXCEL_TAI_KHAM]: ({
    Branchname,
    tuNgay,
    denNgay,
  }) => `Xuất excel ngày tái khám từ 
    Chi nhánh: ${Branchname}
    Từ ngày : ${tuNgay}
    Đến ngày : ${denNgay}`,

  [logAction.UPDATE_LIEN_HE_TAI_KHAM]: ({ nameBenhNhan, noidung }) =>
    `Cập nhật liên hệ tái khám 
    Bệnh nhân: ${nameBenhNhan}
    Nội dung: ${noidung}`,

  [logAction.XEM_BENH_NHAN_TAI_KHAM]: ({ nameBenhNhan }) =>
    `Xem thông tin ${nameBenhNhan} tái khám`,

  [logAction.XEM_TAI_KHAM_TIEM_CHUNG]: ({
    Branchname,
    tuNgay,
    denNgay,
  }) => `Xem ngày tái khám tiêm chủng từ 
    Chi nhánh: ${Branchname}
    Từ ngày : ${tuNgay}
    Đến ngày : ${denNgay}`,

  [logAction.XUAT_EXCEL_TAI_KHAM_TIEM_CHUNG]: ({
    Branchname,
    tuNgay,
    denNgay,
  }) => `Xuất excel ngày tái khám tiêm chủng từ
    Chi nhánh: ${Branchname}
    Từ ngày : ${tuNgay}
    Đến ngày : ${denNgay}`,

  [logAction.UPDATE_LIEN_HE_TAI_KHAM_TIEM_CHUNG]: ({ nameBenhNhan, noidung }) =>
    `Cập nhật liên hệ tái khám tiêm chủng
    Bệnh nhân: ${nameBenhNhan}
    Nội dung: ${noidung}`,

  [logAction.XEM_HEN_TIEM_VX]: ({ Branchname, tuNgay, denNgay }) => `Xem hẹn tiêm vắc xin từ 
    Chi nhánh: ${Branchname}
    Từ ngày : ${tuNgay}
    Đến ngày : ${denNgay}`,

  [logAction.XEM_HEN_TIEM_VX]: ({ Branchname, tuNgay, denNgay }) => `Xem hẹn tiêm vắc xin từ 
    Chi nhánh: ${Branchname}
    Từ ngày : ${tuNgay}
    Đến ngày : ${denNgay}`,

  [logAction.UPDATE_LIEN_HE_HEN_TIEM_VX]: ({
    nameBenhNhan,
    noidung,
  }) => `Cập nhật liên hệ hẹn tiêm vắc xin
    Bệnh nhân: ${nameBenhNhan}
    Nội dung: ${noidung}`,

  [logAction.XEM_LICH_SU_NGUOI_DUNG]: ({ branchName, keyword, dateFrom, dateTo, page }) =>
    `Xem lịch sử người dùng: chi nhánh: ${branchName}, tìm kiếm theo keyword: "${keyword}", từ ngày: ${dateFrom} đến ngày: ${dateTo}, trang: ${page}`,

  [logAction.SUA_PHIEU_SIEU_AM]: () => `Sửa phiếu siêu âm`,

  [logAction.GET_DS_PHIEN_BAN]: () => `Xem danh sách phiên bản`,
  [logAction.THEM_PHIEN_BAN]: (detail) => {
    return [
      `Thêm phiên bản:`,
      `Tiêu đề: ${detail.tenphienban ?? ''}`,
      `Phiên bản: ${detail.phienban ?? ''}`,
      `Ngày nâng cấp: ${detail.ngaynangcap ? moment(detail.ngaynangcap).format('DD/MM/YYYY') : ''}`,
      `Nội dung: ${detail.tinhnangcapnhat ?? ''}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },
  [logAction.SUA_PHIEN_BAN]: (detail) => {
    return [
      `Sửa phiên bản:`,
      `Id phiên bản: ${detail.idpb ?? ''}`,
      `Tiêu đề: ${detail.tenphienban ?? ''}`,
      `Phiên bản: ${detail.phienban ?? ''}`,
      `Ngày nâng cấp: ${detail.ngaynangcap ? moment(detail.ngaynangcap).format('DD/MM/YYYY') : ''}`,
      `Nội dung: ${detail.tinhnangcapnhat ?? ''}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a single line break
  },

  [logAction.GET_DETAIL_PHIEN_BAN]: ({ id }) => `Xem chi tiết phiên bản: id phiên bản ${id}`,
  [logAction.XOA_PHIEN_BAN]: ({ id }) => `Xoá phiên bản: id phiên bản ${id}`,

  [logAction.XEM_LIST_MAC]: () => `Xem danh sách địa chỉ MAC cấp key`,
  [logAction.UPDATE_SU_DUNG_MAC]: ({ id, sudung }) =>
    `Cập nhật sử dụng cho địa chỉ MAC: idip: ${id}, sudung: ${sudung}`,

  [logAction.TAO_MA_VOUCHER]: ({ soLuongMa, doDaiMa, kyTuDau, kyTuCuoi }) =>
    `Tạo mã voucher:
     Số lượng mã: ${soLuongMa}
     Độ dài mã: ${doDaiMa}
     Ký tự đầu: ${kyTuDau}
     Ký tự cuối: ${kyTuCuoi}`,
  [logAction.THEM_MA_VOUCHER]: ({
    maCT,
    tenCT,
    batDau,
    ketThuc,
    giaTriGiam,
    loaiGiaTri,
    services,
    doiTuong,
    chiNhanh,
    khungGio,
    hangHoa,
    maVoucher,
  }) =>
    [
      `Thêm mã voucher:`,
      `Mã CT: ${maCT ?? ''}`,
      `Tên CT: ${tenCT ?? ''}`,
      `Bắt đầu: ${batDau ? moment(batDau).format('DD/MM/YYYY') : ''}`,
      `Kết thúc: ${ketThuc ? moment(ketThuc).format('DD/MM/YYYY') : ''}`,
      `Giá trị giảm: ${giaTriGiam ?? 0}`,
      `Loại giá trị: ${loaiGiaTri === 1 ? 'Tiền' : 'Phần trăm'}`,
      services?.length > 0 &&
        `Dịch vụ áp dụng:${services.map((sv) => `\n\t- ${sv.maDichVu} - ${sv.tenDichVu}`)}`,
      doiTuong?.length > 0 &&
        `Đối tượng áp dụng:${doiTuong.map((dt) => `\n\t- ${dt.idDT} - ${dt.tenDoiTuong}`)}`,
      chiNhanh?.length > 0 &&
        `Chi nhánh áp dụng:${chiNhanh.map((cn) => `\n\t- ${cn.idCN} - ${cn.tenChiNhanh}`)}`,
      khungGio?.length > 0 &&
        `Khung giờ áp dụng:${khungGio.map((kg) => `\n\t- ${kg.tuGio} - ${kg.denGio}`)}`,
      hangHoa?.length > 0 &&
        `Hàng hóa áp dụng:${hangHoa.map((hh) => `\n\t- ${hh.maThuoc} - ${hh.tenThuoc}`)}`,
      maVoucher?.length > 0 && `Mã voucher:${maVoucher.map((mv) => `\n\t- ${mv}`)}`,
    ]
      .filter(Boolean)
      .join('\n\t'),

  [logAction.UPDATE_VOUCHER]: ({
    idct,
    mact,
    tenct,
    batdau,
    ketthuc,
    idttdt,
    idttcn,
    idtttg,
    idttgt,
    idcombo,
    giatri,
    soluongma,
    loaigiatri,
    dodaima,
    kytudau,
    kytucuoi,
    ghichu,
    nguoitao,
    ngaytao,
  }) =>
    [
      `Cập nhật voucher:`,
      `ID chương trình: ${idct ?? ''}`,
      `Mã CT: ${mact ?? ''}`,
      `Tên CT: ${tenct ?? ''}`,
      `Thời gian áp dụng: ${batdau ? moment(batdau).format('DD/MM/YYYY') : ''} - ${ketthuc ? moment(ketthuc).format('DD/MM/YYYY') : ''}`,
      `Trạng thái đối tượng: ${idttdt === 1 ? 'Có' : 'Không'}`,
      `Trạng thái chi nhánh: ${idttcn === 1 ? 'Có' : 'Không'}`,
      `Trạng thái thời gian: ${idtttg === 1 ? 'Có' : 'Không'}`,
      `Trạng thái giới tính: ${idttgt === 1 ? 'Nam' : idttgt === 2 ? 'Nữ' : 'Tất cả'}`,
      `Loại chương trình: ${idcombo === 3 ? 'Giảm giá' : idcombo === 2 ? 'Mã đối tác' : idcombo === 1 ? 'Combo' : 'Voucher'}`,
      `Giá trị: ${giatri ?? 0}`,
      `Số lượng mã: ${soluongma ?? 0}`,
      `Loại giá trị: ${loaigiatri === 1 ? 'Tiền' : 'Phần trăm'}`,
      `Độ dài mã: ${dodaima ?? ''}`,
      `Ký tự đầu: ${kytudau ?? ''}`,
      `Ký tự cuối: ${kytucuoi ?? ''}`,
      `Ghi chú: ${ghichu ?? ''}`,
      `Người tạo: ${nguoitao ?? ''}`,
      `Ngày tạo: ${ngaytao ? moment(ngaytao).format('DD/MM/YYYY HH:mm:ss') : ''}`,
    ]
      .filter(Boolean)
      .join('\n\t'),
  [logAction.SUA_THE_DICH_VU]: (detail = {}) => {
    return [
      `Sửa thông tin thẻ dịch vụ:`,
      `Tên dịch vụ: ${detail.tenDichVu ?? ''}`,
      `Số lượng: ${detail.soLanSuDung ?? 0}`,
      `Giảm giá: ${detail.ptGiamGia ?? 0}`,
    ]
      .filter(Boolean) // Remove any undefined or empty lines
      .join('\n \t') // Join all parts with a line break and tab
  },
  [logAction.THEM_THE_LOAI_CHI_NHANH]: ({ dongia, idchinhanh, idlt } = {}) => {
    return [
      `Thêm giá thẻ loại chi nhánh:`,
      `ID chi nhánh: ${idchinhanh ?? ''}`,
      `ID loại thẻ" ${idlt ?? ''}`,
      `Đơn giá: ${dongia ?? 0}`,
    ]
      .filter(Boolean)
      .join('\n\t')
  },
  [logAction.UPDATE_THE_LOAI_CHI_NHANH]: ({ dongia, idchinhanh, idlt, idltcn } = {}) => {
    return [
      `Cập nhật giá thẻ loại chi nhánh:`,
      `ID chi nhánh: ${idchinhanh ?? ''}`,
      `ID loại thẻ: ${idlt ?? ''}`,

      `Đơn giá: ${dongia ?? 0}`,
    ]
      .filter(Boolean)
      .join('\n\t')
  },

  [logAction.GET_ALL_NHOM_QUYEN]: ({ tenNhom, keyword, page }) =>
    `Xem danh sách nhóm quyền: tên nhóm: ${tenNhom}, tìm kiếm theo keyword: "${keyword}", trang: ${page}`,
  [logAction.GET_QUYEN_BY_ID_NGUOI_DUNG]: ({ taikhoan, manhanvien, tennhanvien }) =>
    `Xem danh sách nhóm quyền của người dùng: tài khoản: ${taikhoan}, mã nhân viên: ${manhanvien}, tên nhân viên: ${tennhanvien}`,
  [logAction.UPDATE_QUYEN_BY_ID_NGUOI_DUNG]: ({ taikhoan, manhanvien, tennhanvien }) =>
    `Cập nhật quyền của người dùng: tài khoản: ${taikhoan}, mã nhân viên: ${manhanvien}, tên nhân viên: ${tennhanvien}`,
  [logAction.THEM_NHOM_QUUYEN]: ({ tennhomquyen }) => `Thêm nhóm quyền mới: ${tennhomquyen}`,
  [logAction.UPDATE_SU_DUNG_NHOM_QUYEN]: ({ tennhomquyen, sudung }) =>
    `Cập nhật sử dụng cho nhóm quyền: ${tennhomquyen}, sudung: ${sudung} `,
  [logAction.GET_QUYEN_HE_THONG_BY_ID]: ({ tenNhom }) =>
    `Get quyền hệ thống theo nhóm quyền: ${tenNhom}`,
  [logAction.UPDATE_SU_DUNG_QUYEN_HE_THONG]: ({ tenmenu, tenquyen, sudung }) =>
    `Cập nhật quyền sử dụng cho menu: ${tenmenu} , tên quyền: ${tenquyen}, sudung: ${sudung} `,
  [logAction.UPDATE_SU_DUNG_LIST_PHAN_QUYEN_HE_THONG]: ({ tennhomquyen, sudung }) =>
    `Cập nhật list quyền sử dụng cho nhóm quyền: ${tennhomquyen}, sudung: ${sudung} `,
  [logAction.XEM_NHOM_QUYEN_HE_THONG]: ({ keyword }) =>
    `Tìm kiếm nhóm quyền hệ thống: tìm kiếm theo keyword: "${keyword}"`,
  [logAction.XEM_QUYEN_THEO_MENU_KEYWORD]: ({ menu, keyword }) =>
    `Tìm kiếm quyền hệ thống theo menu: ${menu}, tìm kiếm theo keyword: "${keyword}"`,
  // kHÁM NHI
  [logAction.XEM_DETAIL_BENH_NHAN_KHAM_NHI]: ({ tenbenhnhan }) =>
    `Xem chi tiết bệnh nhân: ${tenbenhnhan}`,
  [logAction.XEM_DANH_SACH_BENH_NHAN_CHO_KHAM_THEO_PHONGKHAM]: ({ phongkham }) =>
    `Xem danh sách bệnh nhân chờ khám theo phòng khám: ${phongkham}`,
  [logAction.XEM_DANH_SACH_BENH_NHAN_DANG_KHAM_VA_DA_KHAM_THEO_PHONGKHAM]: ({ tuNgay, denNgay }) =>
    `Xem danh sách bệnh nhân đang khám và đã khám theo phòng khám từ ${tuNgay} đến ${denNgay}`,
  [logAction.THEM_CHI_DINH_DICH_VU]: ({ tenBenhNhan, tenDinhVu }) =>
    `Thêm chỉ định dịch vụ cho bệnh nhân: ${tenBenhNhan}, dịch vụ: ${tenDinhVu}`,
  [logAction.XOA_CHI_DINH_DICH_VU]: ({ tenBenhNhan, tenDinhVu }) =>
    `Xoá chỉ định dịch vụ cho bệnh nhân: ${tenBenhNhan}, dịch vụ: ${tenDinhVu}`,
  [logAction.THEM_CHI_DINH_DICH_VU_XET_NGHIEM]: ({ tenBenhNhan, tenDinhVu }) =>
    `Thêm chỉ định dịch vụ xét nghiệm cho bệnh nhân: ${tenBenhNhan}, dịch vụ: ${tenDinhVu}`,

  [logAction.GET_CHI_NHANH_DICH_VU_XET_NGHIEM]: ({ nameXN, nameCompany }) =>
    `Xem danh sách chi nhánh dịch vụ xét nghiệm: tên xét nghiệm: ${nameXN}, công ty: ${nameCompany}`,
  [logAction.XUAT_EXCEL_CHI_NHANH_DICH_VU_XET_NGHIEM]: () =>
    `Xuất excel danh sách danh mục xét nghiệm`,
  [logAction.THEM_MOI_DICH_VU_XET_NGHIEM]: ({ form }) =>
    `Thêm dịch vụ xét nghiệm: \n${formatPayloadDetails(form)}`,
  [logAction.CHINH_SUA_DICH_VU_XET_NGHIEM]: ({ form }) =>
    `Chỉnh sửa dịch vụ xét nghiệm: \n${formatPayloadDetails(form)}`,
  [logAction.XOA_DICH_VU_XET_NGHIEM]: ({ id }) => `Xoá dịch vụ xét nghiệm: ID dịch vụ: ${id}`,
  [logAction.CAP_NHAT_CHI_NHANH_DICH_VU_XET_NGHIEM]: ({ listCN }) =>
    `Cập nhật chi nhánh dịch vụ xét nghiệm: ${listCN}`,
  [logAction.CAP_NHAT_CHI_TIET_DICH_VU_XET_NGHIEM_CHI_NHANH]: ({ form }) =>
    `Cập nhật chi tiết dịch vụ xét nghiệm chi nhánh: \n${formatPayloadDetails(form)}`,

  [logAction.THEM_MOI_CDHA]: ({ form }) => `Thêm CĐHA: \n${formatPayloadDetails(form)}`,
  [logAction.CHINH_SUA_CDHA]: ({ form }) => `Chỉnh sửa CĐHA: \n${formatPayloadDetails(form)}`,
  [logAction.XOA_CDHA]: ({ id }) => `Xoá CĐHA: ID CĐHA: ${id}`,

  [logAction.GET_CHI_NHANH_DICH_VU_DANH_MUC]: ({ nameDV, nameCompany }) =>
    `Xem danh sách chi nhánh dịch vụ: tên dịch vụ: ${nameDV}, công ty: ${nameCompany}`,
  [logAction.XUAT_EXCEL_DANH_MUC_DICH_VU_DANH_MUC]: () => `Xuất excel danh sách danh mục`,
  [logAction.THEM_MOI_DICH_VU_DANH_MUC]: ({ form }) =>
    `Thêm dịch vụ: \n${formatPayloadDetails(form)}`,
  [logAction.CHINH_SUA_DICH_VU_DANH_MUC]: ({ form }) =>
    `Chỉnh sửa dịch vụ: \n${formatPayloadDetails(form)}`,
  [logAction.XOA_DICH_VU_DANH_MUC]: ({ id }) => `Xoá dịch vụ: ID dịch vụ: ${id}`,
  [logAction.CAP_NHAT_CHI_NHANH_DICH_VU]: ({ listCN }) => `Cập nhật chi nhánh dịch vụ: ${listCN}`,
  [logAction.CAP_NHAT_CHI_TIET_DICH_VU_CHI_NHANH]: ({ form }) =>
    `Cập nhật chi tiết dịch vụ chi nhánh: \n${formatPayloadDetails(form)}`,

  [logAction.GET_DANH_SACH_BIEU_MAU]: ({ nameLoaiBieuMau, keyword }) =>
    `Xem danh sách biểu mẫu: loại biểu mẫu: ${nameLoaiBieuMau}, tìm kiếm theo keyword: "${keyword}"`,
  [logAction.THEM_MOI_BIEU_MAU]: ({ form }) => `Thêm biểu mẫu: \n${formatPayloadDetails(form)}`,
  [logAction.CHINH_SUA_BIEU_MAU]: ({ form }) =>
    `Chỉnh sửa biểu mẫu: \n${formatPayloadDetails(form)}`,
  [logAction.XOA_BIEU_MAU]: ({ id }) => `Xoá biểu mẫu: ID biểu mẫu: ${id}`,

  [logAction.GET_DANH_SACH_PHONG_KHAM]: () => `Xem danh sách phòng khám`,
  [logAction.GET_DANH_SACH_CHI_NHANH_PHONG_KHAM]: ({ namePK, nameCompany }) =>
    `Xem danh sách chi nhánh phòng khám: tên phòng khám: ${namePK} - ${nameCompany}`,
  [logAction.THEM_MOI_PHONG_KHAM]: ({ form }) =>
    `Thêm mới phòng khám: \n${formatPayloadDetails(form)}`,
  [logAction.CHINH_SUA_PHONG_KHAM]: ({ form }) =>
    `Cập nhật phòng khám: \n${formatPayloadDetails(form)}`,
  [logAction.THEM_MOI_CHI_NHANH_PHONG_KHAM]: ({ form }) =>
    `Thêm mới chi nhánh phòng khám: \n${formatPayloadDetails(form)}`,
  [logAction.XOA_CHI_NHANH_PHONG_KHAM]: ({ id }) =>
    `Xoá chi nhánh phòng khám: ID chi nhánh phòng khám: ${id}`,

  [logAction.XOA_CHI_DINH_DICH_VU_XET_NGHIEM]: ({ tenBenhNhan, tenDinhVu }) =>
    `Xoá chỉ định dịch vụ xét nghiệm cho bệnh nhân: ${tenBenhNhan}, dịch vụ: ${tenDinhVu}`,
  [logAction.THEM_CHI_DINH_DICH_VU_CDHA]: ({ tenBenhNhan, tenDinhVu }) =>
    `Thêm chỉ định dịch vụ chuẩn đoán hình ảnh cho bệnh nhân: ${tenBenhNhan}, dịch vụ: ${tenDinhVu}`, // eslint-disable-line
  [logAction.XOA_CHI_DINH_DICH_VU_CDHA]: ({ tenBenhNhan, tenDinhVu }) =>
    `Xoá chỉ định dịch vụ chuẩn đoán hình ảnh cho bệnh nhân: ${tenBenhNhan}, dịch vụ: ${tenDinhVu}`, // eslint-disable-line
  [logAction.CAP_NHAT_BN_KHAM]: ({ tenBenhNhan, diUng, dienThoai, tienSuBanThan }) =>
    `Cập nhật thông tin bệnh nhân khám: tên: ${tenBenhNhan}, dị ứng: ${diUng}, điện thoại: ${dienThoai}, tiền sử bản thân: ${tienSuBanThan}`,
  [logAction.CAP_NHAT_BN_KHAM_TTV]: ({
    tenBenhNhan,
    benhkemtheo,
    benhsu,
    bskham,
    cacbophan,
    cannang,
    chandoan,
    chandoanbandau,
    chandoanravien,
    chieucao,
    daxuly,
    dienbienlamsang,
    dieutringoaitru,
    diung,
    ghichu,
    giorapk,
    huongxuly,
    huyetap,
    idbnttc,
    idbskham,
    idchandoan,
    idicD10BANDAU,
    idicD10BENHKEMTHEO,
    idicD10RAVIEN,
    idloidan,
    loidan,
    lydokham,
    lydokolaythuoc,
    mach,
    ngaytaikham,
    nhietdo,
    nhiptho,
    ppdieutri,
    quatrinhbenhly,
    spO2,
    tiensubanthan,
    tieusugiadinh,
    toanthan,
    trangthaikhambenh,
    trieuchung,
    ttlucravien,
  }) =>
    `Cập nhật thông tin bệnh nhân khám: 
    Tên: ${tenBenhNhan},
    Bệnh kèm theo: ${benhkemtheo},
    Bệnh sử: ${benhsu},
    Bác sĩ khám: ${bskham},
    Các bộ phận: ${cacbophan},
    Cân nặng: ${cannang},
    Chẩn đoán: ${chandoan},
    Chẩn đoán ban đầu: ${chandoanbandau},
    Chẩn đoán ra viện: ${chandoanravien},
    Chiều cao: ${chieucao},
    Đã xử lý: ${daxuly},
    Diễn biến lâm sàng: ${dienbienlamsang},
    Điều trị ngoại trú: ${dieutringoaitru},
    Dị ứng: ${diung},
    Ghi chú: ${ghichu},
    Giờ ra PK: ${giorapk},
    Hướng xử lý: ${huongxuly},
    Huyết áp: ${huyetap},
    ID bệnh nhân TTV: ${idbnttc},
    ID bác sĩ khám: ${idbskham},
    ID chẩn đoán: ${idchandoan},
    ID ICD10 ban đầu: ${idicD10BANDAU},
    ID ICD10 bệnh kèm theo: ${idicD10BENHKEMTHEO},
    ID ICD10 ra viện: ${idicD10RAVIEN},
    ID lời dặn: ${idloidan},
    Lời dặn: ${loidan},
    Lý do khám: ${lydokham},
    Lý do kê thuốc: ${lydokolaythuoc},
    Mạch: ${mach},
    Ngày tái khám: ${ngaytaikham},
    Nhiệt độ: ${nhietdo},
    Nhịp thở: ${nhiptho},
    Phương pháp điều trị: ${ppdieutri},
    Quá trình bệnh lý: ${quatrinhbenhly},
    SpO2: ${spO2},
    Tiền sử bản thân: ${tiensubanthan},
    Tiểu sử gia đình: ${tieusugiadinh},
    Toàn thân: ${toanthan},
    Trạng thái khám bệnh: ${trangthaikhambenh},
    Triệu chứng: ${trieuchung},
    Tình trạng lúc ra viện: ${ttlucravien}`,
  // In templateLogMota.js
  [logAction.CAP_NHAT_TOA_THUOC_BS_TU_LAY_SO_LO]: ({ tenBenhNhan, danhSachThuoc }) =>
    `Cập nhật toa thuốc bác sĩ tự lấy số lô cho bệnh nhân: ${tenBenhNhan}
  Danh sách thuốc: ${danhSachThuoc
    .map((item) => `\n- ${item.tenThuoc} (${item.soLuong} ${item.donViTinh})`)
    .join('')}`,
  //Kham san
  [logAction.XEM_DETAIL_BENH_NHAN_KHAM_SAN]: ({ tenbenhnhan }) =>
    `Xem chi tiết bệnh nhân khám sản: ${tenbenhnhan}`,
  [logAction.CAP_NHAT_BN_KHAM_TTC]: ({
    benhkemtheo,
    benhsu,
    bskham,
    bung,
    cacbophan,
    cannang,
    chandoan,
    chandoanbandau,
    chandoanravien,
    chieucao,
    chukikinh,
    daniem,
    daxuly,
    dienbienlamsang,
    dieutringoaitru,
    diung,
    dusanh,
    dusanhkc,
    ghichu,
    giorapk,
    huongxuly,
    huyetap,
    idbnttc,
    idbskham,
    idchandoan,
    idicD10BANDAU,
    idicD10BENHKEMTHEO,
    idicD10RAVIEN,
    idloidan,
    kinhapchot,
    kinhchot,
    loidan,
    lydokham,
    lydokolaythuoc,
    mach,
    ngaysanh,
    ngaytaikham,
    ngaythai,
    nguathai,
    nhietdo,
    nhiptho,
    para,
    phu,
    ppdieutri,
    quatrinhbenhly,
    spO2,
    tiensubanthan,
    tieusugiadinh,
    timphoi,
    toanthan,
    tongtrang,
    trangthaikhambenh,
    trieuchung,
    ttlucravien,
    tuanthai,
    tuoithai,
  }) => `Cập nhật thông tin bệnh nhân thông tin chung:
      Bệnh kèm theo: ${benhkemtheo ?? ''},
      Bệnh sử: ${benhsu ?? ''}, 
      Bác sĩ khám: ${bskham ?? ''},
      Bụng: ${bung ?? ''},
      Các bộ phận: ${cacbophan ?? ''},
      Cân nặng: ${cannang ?? ''},
      Chẩn đoán: ${chandoan ?? ''},
      Chẩn đoán ban đầu: ${chandoanbandau ?? ''},
      Chẩn đoán ra viện: ${chandoanravien ?? ''},
      Chiều cao: ${chieucao ?? ''},
      Chu kỳ kinh: ${chukikinh ?? ''},
      Da niêm: ${daniem ?? ''},
      Đã xử lý: ${daxuly ?? ''},
      Diễn biến lâm sàng: ${dienbienlamsang ?? ''},
      Điều trị ngoại trú: ${dieutringoaitru ?? ''},
      Dị ứng: ${diung ?? ''},
      Dự sinh: ${dusanh ?? ''},
      Dự sinh KC: ${dusanhkc ?? ''},
      Ghi chú: ${ghichu ?? ''},
      Giờ ra phòng khám: ${giorapk ?? ''},
      Hướng xử lý: ${huongxuly ?? ''},
      Huyết áp: ${huyetap ?? ''},
      ID TTC: ${idbnttc ?? ''},
      ID bác sĩ khám: ${idbskham ?? ''},
      ID chẩn đoán: ${idchandoan ?? ''},
      ICD10 ban đầu: ${idicD10BANDAU ?? ''},
      ICD10 bệnh kèm theo: ${idicD10BENHKEMTHEO ?? ''},
      ICD10 ra viện: ${idicD10RAVIEN ?? ''},
      ID lời dặn: ${idloidan ?? ''},
      Kinh áp chót: ${kinhapchot ?? ''},
      Kinh chót: ${kinhchot ?? ''},
      Lời dặn: ${loidan ?? ''},
      Lý do khám: ${lydokham ?? ''},
      Lý do kê thuốc: ${lydokolaythuoc ?? ''},
      Mạch: ${mach ?? ''},
      Ngày sinh: ${ngaysanh ? moment(ngaysanh).format('DD/MM/YYYY') : ''},
      Ngày tái khám: ${ngaytaikham ? moment(ngaytaikham).format('DD/MM/YYYY') : ''},
      Ngày thai: ${ngaythai ?? ''},
      Ngừa thai: ${nguathai ?? ''},
      Nhiệt độ: ${nhietdo ?? ''},
      Nhịp thở: ${nhiptho ?? ''},
      Para: ${para ?? ''},
      Phù: ${phu ?? ''},
      Phương pháp điều trị: ${ppdieutri ?? ''},
      Quá trình bệnh lý: ${quatrinhbenhly ?? ''},
      SpO2: ${spO2 ?? ''},
      Tiền sử bản thân: ${tiensubanthan ?? ''},
      Tiểu sử gia đình: ${tieusugiadinh ?? ''},
      Tim phổi: ${timphoi ?? ''},
      Toàn thân: ${toanthan ?? ''},
      Tổng trạng: ${tongtrang ?? ''},
      Trạng thái khám bệnh: ${trangthaikhambenh ?? ''},
      Triệu chứng: ${trieuchung ?? ''},
      Tình trạng lúc ra viện: ${ttlucravien ?? ''},
      Tuần thai: ${tuanthai ?? ''},
      Tuổi thai: ${tuoithai ?? ''}`,
  [logAction.CAP_NHAT_BENH_NHAN_KHAM_SAN]: ({
    tenBenhNhan,
    amdao,
    amho,
    bctc,
    congoi,
    ctc,
    cungdoan,
    gnkhac,
    hachnach,
    idbnttc,
    idloaisan,
    kchau,
    khamtrong,
    ngoithai,
    nhiN2VU,
    nhinphai,
    nhintrai,
    oi,
    phanphup,
    phanphut,
    sonanphai,
    sonantrai,
    timthai,
    tsm,
    tucung,
  }) => `Cập nhật bệnh nhân khám sản: ${tenBenhNhan}
          Âm đạo: ${amdao ?? ''}
          Âm hộ: ${amho ?? ''}
          BCTC: ${bctc ?? ''} 
          Cơn gò: ${congoi ?? ''}
          CTC: ${ctc ?? ''}
          Cùng đồ: ${cungdoan ?? ''}
          Ghi nhận khác: ${gnkhac ?? ''}
          Hạch nách: ${hachnach ?? ''}
          ID BNTTC: ${idbnttc ?? ''}
          Loại sản: ${idloaisan ?? ''}
          Khung chậu: ${kchau ?? ''}
          Khám trong: ${khamtrong ?? ''}
          Ngôi thai: ${ngoithai ?? ''}
          Nhìn 2 vú: ${nhiN2VU ?? ''}
          Nhìn phải: ${nhinphai ?? ''}
          Nhìn trái: ${nhintrai ?? ''}
          Ối: ${oi ?? ''}
          Phần phụ phải: ${phanphup ?? ''}
          Phần phụ trái: ${phanphut ?? ''}
          Sờ nắn phải: ${sonanphai ?? ''}
          Sờ nắn trái: ${sonantrai ?? ''}
          Tim thai: ${timthai ?? ''}
          Tầng sinh môn: ${tsm ?? ''}
          Tử cung: ${tucung ?? ''}`,
  [logAction.XOA_TOA_THUOC_BS_KHONG_KE_TOA]: ({ tenBenhNhan }) =>
    `Xoá toa thuốc bác sĩ không kê toa cho bệnh nhân: ${tenBenhNhan}`,
  [logAction.CHINH_SUA_BENH_NHAN_DO_KHUC_XA]: (
    tenBenhNhan,
    thiluckhongkinhxA_MP,
    thiluckhongkinhxA_MT,
    thiluckhongkinhxA_2MP,
    thiluckhongkinhgaN_MP,
    thiluckhongkinhgaN_MT,
    thiluckhongkinhgaN_2MP,
    thiluckinhloxA_MP,
    thiluckinhloxA_MT,
    thiluckinhloxA_2M,
    thiluckinhcuxA_MP,
    thiluckinhcuxA_MT,
    thiluckinhcuxA_2M,
    thiluckinhcugaN_MP,
    thiluckinhcugaN_MT,
    thiluckinhcugaN_2M,
    nhanaP_MP,
    nhanaP_MT,
    khachquankhonglietdT_MP,
    khachquankhonglietdT_MT,
    khachquanlietdT_MP,
    khachquanlietdT_MT,
    chuquaN_MP,
    chuquaN_MT,
    kinhcU_MP_CAU,
    kinhcU_MP_LOAN,
    kinhcU_MP_TRUC,
    kinhcU_MP_ADD,
    kinhcU_MT_CAU,
    kinhcU_MT_LOAN,
    kinhcU_MT_TRUC,
    kinhcU_MT_ADD,
    kinhcU_PD,
    kinhdieuchinH_MP_CAU,
    kinhdieuchinH_MP_LOAN,
    kinhdieuchinH_MP_TRUC,
    kinhdieuchinH_MP_TL,
    kinhdieuchinH_MP_ADD,
    kinhdieuchinH_MT_CAU,
    kinhdieuchinH_MT_LOAN,
    kinhdieuchinH_MT_TRUC,
    kinhdieuchinH_MT_TL,
    kinhdieuchinH_MT_ADD,
    kinhdieuchinH_PD,
    kinhtiepxuC_MP_CONGSUAT,
    kinhtiepxuC_MP_BC,
    kinhtiepxuC_MP_DIA,
    kinhtiepxuC_MP_HIEUBRAND,
    kinhtiepxuC_MT_CONGSUAT,
    kinhtiepxuC_MT_BC,
    kinhtiepxuC_MT_DIA,
    kinhtiepxuC_MT_HIEUBRAND,
    khamtienchaN_MP_SACGIAC,
    khamtienchaN_MT_SACGIAC,
    khamtienchaN_2M_SACGIAC,
    khamtienchaN_MP_DONGTU,
    khamtienchaN_MT_DONGTU,
    khamtienchaN_2M_DONGTU,
    khamtienchaN_MP_VANNHAN,
    khamtienchaN_MT_VANNHAN,
    khamtienchaN_2M_VANNHAN,
    khamtienchaN_MP_THITRUONG,
    khamtienchaN_MT_THITRUONG,
    khamtienchaN_2M_THITRUONG,
    khamtienchaN_MP_TESTCHEMAT_XA,
    khamtienchaN_MT_TESTCHEMAT_XA,
    khamtienchaN_2M_TESTCHEMAT_XA,
    khamtienchaN_MP_TESTCHEMAT_GAN,
    khamtienchaN_MT_TESTCHEMAT_GAN,
    khamtienchaN_2M_TESTCHEMAT_GAN,
    khamtienchaN_MP_BODIEUTIETAA,
    khamtienchaN_MT_BODIEUTIETAA,
    khamtienchaN_2M_BODIEUTIETA,
    khamtienchaN_CANDIEMQUYTUNPC,
    khamtienchaN_THIGIACLAPTHESTEREO,
    nguoikham,
    ngaykham,
    nguoisua,
    ngaysua,
    tiensutaimat,
    chandoanravieN_MT,
    chandoanravieN_MP,
    cacxetnghiemcanlam,
    tomtatbenhan,
    tienluonggan,
    tienluongxa,
    tuvangiaoducsuckhoe,
    noikhoa,
    phauthuat,
    thuthuatthuchien,
    tinhtrangravieN_TOANTRANG,
    tinhtrangravieN_MP,
    tinhtrangravieN_MT,
    khongkinH_MT,
    khongkinH_MP,
    cokinH_MT,
    cokinH_MP,
    hocmaT_MT,
    hocmaT_MP,
    tinhtrangnhancaU_MT,
    tinhtrangnhancaU_MP,
    vannhaN_MT,
    vannhaN_MP,
    ledaO_MT,
    ledaO_MP,
    mimaT_MT,
    mimaT_MP,
    ketmaC_MT,
    ketmaC_MP,
    giacmaC_MT,
    giacmaC_MP,
    cungmaC_MT,
    cungmaC_MP,
    hinhvemotA_MT,
    hinhvemotA_MP,
    tienphonG_MT,
    tienphonG_MP,
    mongmaT_MT,
    mongmaT_MP,
    dongtU_MT,
    dongtU_MP,
    thethuytinH_MT,
    thethuytinH_MP,
    dichkinH_MT,
    dichkinH_MP,
    daymaT_MT,
    daymaT_MP,
    thilucravieN_MT,
    thilucravieN_MP,
    thilucchinhkinH_MT,
    thilucchinhkinH_MP,
    nhanapravieN_MT,
    nhanapravieN_MP,
  ) => `
  Chỉnh sửa bệnh nhân khám khúc xạ:
  tên bệnh nhân: ${tenBenhNhan},
  
`,
  //  Tra cứu bênh nhân
  [logAction.XEM_TRA_CUU_BENH_NHAN]: ({ branchName, keyword, page }) =>
    `Xem tra cứu bệnh nhân: chi nhánh: ${branchName}, keyword: ${keyword}, trang: ${page}`,
  [logAction.XEM_TRA_CUU_BENH_NHAN_ALL_CTY]: ({ keyword, page }) =>
    `Xem tra cứu bệnh nhân: Tất cả công ty, keyword: ${keyword}, trang: ${page}`,
  [logAction.XEM_TRA_CUU_BENH_NHAN_ALL_CHINHANH]: ({ idCongTY, keyword, page }) =>
    `Xem tra cứu bệnh nhân theo ID công ty :${idCongTY}, keyword: ${keyword}, trang: ${page}`,
  [logAction.TAO_BENH_NHAN_MOI]: ({
    tenBenhNhan,
    Matcqg,
    NgayTao,
    cccd,
    diaChi,
    dienThoai,
    gioiTinh,
    idChiNhanh,
    idDanToc,
    idDoiTuong,
    idNgheNghiep,
    idPhuong,
    idQuan,
    idQuocTich,
    idTinh,
    idTrangThai,
    ngayCap,
    ngaySinh,
    tuoi,
  }) =>
    `Tạo bệnh nhân mới: ${tenBenhNhan},
    Mã TCQG: ${Matcqg},
    Ngày tạo: ${NgayTao},
    CMT/CCCD: ${cccd},
    Địa chỉ: ${diaChi},
    Điện thoại: ${dienThoai},
    Giới tính: ${gioiTinh},
    ID chi nhánh: ${idChiNhanh},
    ID dân tộc: ${idDanToc},
    ID đối tượng: ${idDoiTuong},
    ID nghề nghiệp: ${idNgheNghiep},
    ID phường: ${idPhuong},
    ID quận: ${idQuan},
    ID quốc tịch: ${idQuocTich},
    ID tỉnh: ${idTinh},
    ID trạng thái: ${idTrangThai},
    Ngày cấp: ${ngayCap},
    Ngày sinh: ${ngaySinh},
    Tuổi: ${tuoi}`,
  [logAction.XEM_THONG_TIN_BENH_NHAN_TRA_CUU]: ({ tenBenhNhan }) =>
    `Xem thông tin bệnh nhân tra cứu: ${tenBenhNhan}`,
  [logAction.CAP_NHAT_THONG_TIN_BENH_NHAN]: ({
    tenBenhNhan,
    cccd,
    diaChi,
    dienThoai,
    ghiChuHoaDon,
    gioiTinh,
    hoaDon,
    idDanToc,
    idDoiTuong,
    idNgheNghiep,
    idPhuong,
    idQuan,
    idQuocTich,
    idTinh,
    idbn,
    maTCQG,
    ngayCap,
    ngaySinh,
    nguoiSua,
    tuoi,
  }) => `Cập nhật thông tin bệnh nhân: ${tenBenhNhan}
    CMT/CCCD: ${cccd},
    Địa chỉ: ${diaChi},
    Điện thoại: ${dienThoai},
    Ghi chú hóa đơn: ${ghiChuHoaDon},
    Giới tính: ${gioiTinh},
    Hóa đơn: ${hoaDon},
    ID dân tộc: ${idDanToc},
    ID đối tượng: ${idDoiTuong},
    ID nghề nghiệp: ${idNgheNghiep},
    ID phường: ${idPhuong},
    ID quận: ${idQuan},
    ID quốc tịch: ${idQuocTich},
    ID tỉnh: ${idTinh},
    ID bệnh nhân: ${idbn},
    Mã TCQG: ${maTCQG},
    Ngày cấp: ${ngayCap},
    Ngày sinh: ${ngaySinh},
    Người sửa: ${nguoiSua},
    Tuổi: ${tuoi}`,
  [logAction.TRA_CUU_LICH_SU_KHAM_BENH]: ({ tenBenhNhan }) =>
    `Tra cứu lịch sử khám bệnh: ${tenBenhNhan} `,
  [logAction.CAP_NHAT_THONG_TIN_BENH_NHAN_THONG_TIN_CHUNG]: () =>
    `Cập nhật thông tin bệnh nhân thông tin chung`,
  [logAction.XEM_KET_QUA_CAN_LAM_SANG]: ({ tenBenhNhan }) =>
    `Xem kết quả cận lâm sàng: ${tenBenhNhan}`,
  [logAction.XEM_LICH_SU_BENH_NHAN_XET_NGHIEM]: ({ tenBenhNhan }) =>
    `Xem lịch sử bệnh nhân xét nghiệm: ${tenBenhNhan}`,
  [logAction.TRA_CUU_KET_QUA_SIEU_AM]: ({ tenBenhNhan }) =>
    `Tra cứu kết quả siêu âm: ${tenBenhNhan}`,
  [logAction.XEM_SO_KHAM_BENH]: ({ tenCongTy, tenChiNhanh, keyword, tuNgay, denNgay }) =>
    `Xem danh sách sổ khám bệnh của công ty ${tenCongTy}, chi nhánh ${tenChiNhanh}, keyword: ${keyword}, từ ngày ${tuNgay} đến ngày ${denNgay}`,
  [logAction.XEM_SO_KHAM_THAI]: ({ tenCongTy, tenChiNhanh, keyword, tuNgay, denNgay }) =>
    `Xem danh sách sổ khám thai của công ty ${tenCongTy}, chi nhánh ${tenChiNhanh}, keyword: ${keyword}, từ ngày ${tuNgay} đến ngày ${denNgay}`,
  [logAction.XEM_BENH_AN_MAT]: ({ companyName, idChinhanh, keyword, pageIndex }) =>
    `Xem bệnh án mắt: tên công ty ${companyName},
   tên chi nhánh: ${idChinhanh}, keyword: ${keyword}, trang: ${pageIndex}`,
  [logAction.XEM_LICH_SU_XET_NGHIEM]: ({ tenBenhNhan }) => `Xem lịch sử xét nghiệm: ${tenBenhNhan}`,
  [logAction.CAP_NHAT_KET_QUA_XET_NGHIEM]: () => `Cập nhật kết quả xét nghiệm`,
  [logAction.IN_PHIEU_CHI_DINH_XET_NGHIEM]: ({ tenBenhNhan }) =>
    `In phiếu chỉ định xét nghiệm: ${tenBenhNhan}`,
  [logAction.GUI_MAU_VA_LUU_VTTH]: ({ tenBenhNhan }) => `Gửi mẫu và lưu VTTH: ${tenBenhNhan}`,
  [logAction.XEM_CHI_TIET_XET_NGHIEM]: ({ tenBenhNhan }) =>
    `Xem chi tiêt xét nghiệm: ${tenBenhNhan}`,
  [logAction.CAP_NHAT_BENH_NHAN_VAT_TU_TIEU_HAO_BINH_THUONG]: ({ tenBenhNhan }) => `
      Cập nhật bệnh nhân vật tư tiêu hao bình thường : ${tenBenhNhan}  
      `,
  [logAction.XEM_LICH_SU_BENH_NHAN_TRONG_KINH]: ({ tenBenhNhan }) =>
    `Xem lịch sử bệnh nhân tròng kính: ${tenBenhNhan}`,
  [logAction.XEM_LICH_SU_BENH_NHAN_SIEU_AM]: ({ tenBenhNhan }) =>
    `Xem lịch sử bệnh nhân siêu âm :${tenBenhNhan}`,
  [logAction.THEM_MOI_BENH_NHAN_SIEU_AM]: ({ tenChiNhanh }) =>
    `Thêm bệnh nhân siêu âm: tên chi nhánh: ${tenChiNhanh}`,
  [logAction.CAP_NHAT_BENH_NHAN_SIEU_AM]: ({ tenbenhnhan }) =>
    `Cập nhật thông tin bệnh nhân siêu âm ${tenbenhnhan}`,
  [logAction.XEM_LICH_SU_BENH_NHAN_MONITOR]: ({ tenBenhNhan }) =>
    `Xem lịch sử bênh nhân MONITOR: ${tenBenhNhan}`,
  [logAction.XEM_LICH_SU_BENH_NHAN_VAT_LY_TRI_LIEU]: ({ tenBenhNhan }) =>
    `Xem lịch sử bệnh nhân vật lý trị liệu: ${tenBenhNhan}`,
  [logAction.XEM_LICH_SU_BENH_NHAN_DIEN_TIM]: ({ tenBenhNhan }) =>
    `Xem lịch sử bệnh nhân điện tim: ${tenBenhNhan}`,

  // Bán thuốc
  [logAction.XEM_THONG_TIN_BENH_NHAN_NHA_THUOC]: ({ tenBenhNhan }) =>
    `Xem thông tin bệnh nhân nhà thuốc: ${tenBenhNhan}`,

  [logAction.THANH_TOAN_THUOC]: ({ mabn, tenbn }) =>
    `Thanh toán cho bệnh nhân :${mabn} - Tên BN:${tenbn}`,
  [logAction.TRA_TOA_CHO_BS_KE_LAI]: ({ mabenhnhan, tenbenhnhan, noidungtratoa }) =>
    `Trả toa cho bác sĩ kê lại cho bệnh nhân :${mabenhnhan} - Tên BN:${tenbenhnhan} - Nội dung trả toa: ${noidungtratoa}`,
  [logAction.XEM_CHI_TIET_PHIEU_THU_TOA_THUOC]: ({ mabenhnhan, tenbenhnhan }) =>
    `Xem chi tiết phiếu thu toa thuốc cho bệnh nhân :${tenbenhnhan} - Mã BN:${mabenhnhan}`,
  [logAction.XOA_PHIEU_THU_TOA_THUOC]: ({ maphieuthu, lydo }) =>
    `Xoá phiếu thu toa thuốc: ${maphieuthu} - Lý do: ${lydo}`,
  [logAction.CHINH_SUA_CHI_TIET_THANH_TOAN_TOA_THUOC]: ({ maphieuthu }) =>
    `Chỉnh sửa chi tiết thanh toán toa thuốc: ${maphieuthu}`,
  [logAction.THANH_TOAN_PHIEU_BAN_LE]: ({ tenBenhNhan }) =>
    `Tạo thành công phiếu bán lẻ cho bênh nhân ${tenBenhNhan}`,
  [logAction.BAO_CAO_THUOC_HET_HAN]: ({ tuNgay, denNgay, tenChiNhanh, tenKho }) =>
    `Xem báo cáo thuốc hết hạn từ ${tuNgay} đến ${denNgay} - Chi nhánh: ${tenChiNhanh} - Kho: ${tenKho}`,
  [logAction.BAO_CAO_THUOC_KHONG_LAY]: ({ tuNgay, denNgay, tenChiNhanh, tenChuyenKhoa }) =>
    `Xem báo cáo thuốc không lấy từ ${tuNgay} đến ${denNgay} - Chi nhánh: ${tenChiNhanh} - Chuyên khoa: ${tenChuyenKhoa}`,
  [logAction.BAO_CAO_BANG_GIA]: ({ search, tenNhom, tenLoai }) =>
    `Xem báo cáo bảng giá: Tìm kiếm: ${search} - Nhóm: ${tenNhom} - Loại: ${tenLoai}`,
  [logAction.TAO_PHIEU_BAN_NOI_BO]: ({ tenBenhNhan, maBenhNhan }) =>
    `Tạo phiếu bán nội bộ cho bệnh nhân: ${tenBenhNhan} - Mã bệnh nhân: ${maBenhNhan}`,
  [logAction.GET_DS_PHIEU_BAN_NOI_BO]: ({ dateFrom, dateTo, keyword, nameChiNhanh }) => {
    return `Lấy danh sách phiếu bán nội bộ từ ${dateFrom} đến ${dateTo} - Từ khóa: ${keyword} - Chi nhánh: ${nameChiNhanh}`
  },
  [logAction.GET_CHI_TIET_PHIEU_BAN_NOI_BO]: ({ maphieu }) =>
    `Xem chi tiết phiếu bán nội bộ: ${maphieu}`,
  [logAction.GET_DS_HANG_HOA_BAN_NOI_BO]: ({
    nameCompany,
    dateFrom,
    dateTo,
    nameChiNhanh,
    keyword,
  }) => {
    return `Lấy danh sách hàng hóa bán nội bộ từ ${dateFrom} đến ${dateTo} - Từ khóa: ${keyword} - Chi nhánh: ${nameChiNhanh}`
  },
  [logAction.TAO_PHIEU_XUAT_TRA_NCC]: ({ tenDoiTac, maDoiTac }) =>
    `Tạo phiếu xuất trả NCC cho đối tác: ${tenDoiTac} - Mã đối tác: ${maDoiTac}`,
  [logAction.GET_DS_PHIEU_XUAT_TRA_NCC]: ({ dateFrom, dateTo, keyword, nameChiNhanh }) => {
    return `Lấy danh sách phiếu xuất trả NCC từ ${dateFrom} đến ${dateTo} - Từ khóa: ${keyword} - Chi nhánh: ${nameChiNhanh}`
  },
  [logAction.GET_CHI_TIET_PHIEU_XUAT_TRA_NCC]: ({ maphieu }) =>
    `Xem chi tiết phiếu xuất trả NCC: ${maphieu}`,
  [logAction.GET_DS_HANG_HOA_XUAT_TRA_NCC]: ({
    nameCompany,
    dateFrom,
    dateTo,
    nameChiNhanh,
    keyword,
  }) => {
    return `Lấy danh sách hàng hóa xuất trả NCC từ ${dateFrom} đến ${dateTo} - Từ khóa: ${keyword} - Chi nhánh: ${nameChiNhanh}`
  },
  [logAction.XEM_DS_PHIEU_XUAT_VACXIN]: ({ dateFrom, dateTo, keyword, nameChiNhanh }) => {
    return `Xem danh sách phiếu xuất vắc xin từ ${dateFrom} đến ${dateTo} - Từ khóa: ${keyword} - Chi nhánh: ${nameChiNhanh}`
  },
  [logAction.XEM_CHI_TIET_PHIEU_XUAT_VACXIN]: ({ maphieuthu }) =>
    `Xem chi tiết phiếu xuất vắc xin: ${maphieuthu}`,
  [logAction.GET_DS_HANG_HOA_XUAT_VACXIN]: ({
    nameCompany,
    dateFrom,
    dateTo,
    nameChiNhanh,
    keyword,
  }) => {
    return `Lấy danh sách hàng hóa xuất vắc xin từ ${dateFrom} đến ${dateTo} - Từ khóa: ${keyword} - Chi nhánh: ${nameChiNhanh}`
  },
  //DANH MỤC

  [logAction.XEM_DANH_SACH_CHI_NHANH]: ({ IDCompany, keyword, from, to }) =>
    `Xem danh sách chi nhánh theo công ty: ${IDCompany} - Từ khóa: ${keyword} - Từ: ${from} - Đến: ${to}`,
  [logAction.THEM_MOI_CHI_NHANH]: ({ tenChiNhanh }) =>
    `Thêm mới chi nhánh : Tên chi nhánh: ${tenChiNhanh}`,
  [logAction.CAP_NHAT_CHI_NHANH]: ({ tenChiNhanh }) =>
    `Cập nhật chi nhánh : Tên chi nhánh: ${tenChiNhanh}`,
  [logAction.TIM_KIEM_DOI_TAC]: ({ keyword }) => `Tìm kiếm đối tác: ${keyword}`,
  [logAction.THEM_DOI_TACC]: ({ maDoiTac, tenDoiTac, soDienThoai, email, diaChi, ghiChu }) =>
    `Thêm mới đối tác: Mã đối tác: ${maDoiTac} - Tên đối tác: ${tenDoiTac} - Số điện thoại: ${soDienThoai} - Email: ${email} - Địa chỉ: ${diaChi} - Ghi chú: ${ghiChu}`,
  [logAction.CAP_NHAT_DOI_TAC]: ({ maDoiTac, tenDoiTac, soDienThoai, email, diaChi, ghiChu }) =>
    `Cập nhật đối tác: Mã đối tác: ${maDoiTac} - Tên đối tác: ${tenDoiTac} - Số điện thoai: ${soDienThoai} - Email: ${email} - Địa chỉ: ${diaChi} - Ghi chú: ${ghiChu}`,
  [logAction.XOA_DOI_TAC]: ({ id }) => `Xoá id đối tác: ${id}`,

  [logAction.GET_DS_QUOC_GIA]: () => `Xem danh sách quốc gia`,
  [logAction.THEM_MOI_QUOC_GIA]: ({ ten }) => `Thêm quốc gia: ${ten}`,
  [logAction.XOA_QUOC_GIA]: ({ ten }) => `Xoá quốc gia: ${ten}`,

  [logAction.GET_DS_TINH_TP]: () => `Xem danh sách tỉnh/thành phố`,
  [logAction.THEM_MOI_TINH_TP]: ({ ten }) => `Thêm tỉnh/thành phố: ${ten}`,
  [logAction.XOA_TINH_TP]: ({ ten }) => `Xoá tỉnh/thành phố: ${ten}`,

  [logAction.GET_DS_QUAN_HUYEN]: ({ tenTinhTP }) =>
    `Xem danh sách quận huyện theo tỉnh/thành phố: ${tenTinhTP}`,
  [logAction.THEM_MOI_QUAN_HUYEN]: ({ tenTinhTP, ten }) =>
    `Thêm quận/huyện: ${ten} cho tỉnh/thành phố: ${tenTinhTP}`,
  [logAction.XOA_QUAN_HUYEN]: ({ ten, tenTinhTP }) =>
    `Xoá quận/huyện: ${ten} của tỉnh/thành phố: ${tenTinhTP}`,

  [logAction.GET_DS_PHUONG_XA]: ({ tenTinhTP, tenQuanHuyen }) =>
    `Xem danh sách phường xã theo tỉnh/thành phố: ${tenTinhTP}, quận/huyện: ${tenQuanHuyen}`,
  [logAction.THEM_MOI_PHUONG_XA]: ({ ten, tenQuanHuyen }) =>
    `Thêm phường/xã: ${ten} của quận/huyện: ${tenQuanHuyen}`,
  [logAction.XOA_PHUONG_XA]: ({ ten, tenTinhTP, tenQuanHuyen }) =>
    `Xoá phường/xã: ${ten} của tỉnh/thành phố: ${tenTinhTP}, quận/huyện: ${tenQuanHuyen}`,

  [logAction.GET_DS_LOI_DAN]: ({ keyword }) =>
    `Xem danh sách lời dặn: tìm kiếm theo keyword: ${keyword}`,
  [logAction.XUAT_EXCEL_DS_LOI_DAN]: ({ keyword }) =>
    `Xuất excel danh sách lời dặn: tìm kiếm theo keyword: ${keyword}`,
  [logAction.GET_CHI_TIET_LOI_DAN]: ({ loiDan, maLoiDan }) =>
    `Xem chi tiết lời dặn: \n${maLoiDan} - ${loiDan}`,
  [logAction.CHINH_SUA_LOI_DAN]: ({ loiDan, maLoiDan, ghiChu, suDung }) =>
    `Cập nhật lời dặn: 
  \n Mã lời dặn: ${maLoiDan}
  \n Lời dặn: ${loiDan}
  \n Ghi chú: ${ghiChu}
  \n Sử dụng: ${suDung}`,
  [logAction.THEM_MOI_LOI_DAN]: ({ loiDan, maLoiDan, ghiChu }) =>
    `Thêm lời dặn: 
  \n Mã lời dặn: ${maLoiDan}
  \n Lời dặn: ${loiDan}
  \n Ghi chú: ${ghiChu}`,
  [logAction.XOA_LOI_DAN]: ({ loiDan, maLoiDan }) =>
    `Xoá lời dặn lời dặn: \n${maLoiDan} - ${loiDan}`,

  //SU_CO
  [logAction.THEM_SU_CO]: ({ tenbaohong }) => `Thêm sự cố: ${tenbaohong}`,
  [logAction.CAP_NHAT_SU_CO]: ({ tenbaohong }) => `Cập nhật sự cố: ${tenbaohong}`,
  [logAction.XOA_SU_CO]: ({ idbaohong }) => `Xoá sự cố có id: ${idbaohong}`,
  [logAction.THEM_NHAN_VIEN_SU_CO]: ({ tenbaohong, tennv }) =>
    `Thêm nhân viên: ${tennv} vào sự cố: ${tenbaohong}`,
  [logAction.THEM_NHAN_SU_CO]: ({ tennhan }) => `Thêm nhãn sự cố: ${tennhan}`,
  [logAction.CAP_NHAT_NHAN_SU_CO]: ({ tennhan }) => `Cập nhật nhãn sự cố: ${tennhan}`,
  [logAction.XOA_NHAN_SU_CO]: ({ tennhan }) => `Xoá nhãn sự cố có: ${tennhan}`,
  [logAction.THEM_DANH_MUC_SU_CO]: ({ idnhan, idnhandm }) =>
    `Thêm danh mục sự cố: ${idnhan} tại id nhãn : ${idnhandm}`,
  [logAction.CAP_NHAT_DANH_MUC_SU_CO]: ({ tendanhmuc, idnhandm }) =>
    `Cập nhật danh mục sự cố: ${tendanhmuc} tại id nhãn : ${idnhandm}`,
  [logAction.DELETE_DANH_MUC_SU_CO]: ({ tendanhmuc, idnhandm }) =>
    `Xoá danh mục sự cố: ${tendanhmuc} tại id nhãn : ${idnhandm}`,
  [logAction.TAO_VUNG_SU_CO]: ({ tenvung }) => `Tạo vùng sự cố: ${tenvung}`,
  [logAction.THEM_NHAN_VIEN_VUNG_SU_CO]: ({ idvung, tennv }) =>
    `Thêm nhân viên: ${tennv} vào vùng sự cố: ${idvung}`,
  [logAction.XOA_NHAN_VIEN_VUNG_SU_CO]: ({ id }) => `Xoá nhân viên: ${id} khỏi vùng sự cố`,
  [logAction.CAP_NHAT_KHU_VUC_SU_CO]: ({ tenkhuvuc }) => `Cập nhật khu vực sự cố: ${tenkhuvuc}`,
  [logAction.XOA_KHU_VUC_SU_CO]: ({ idvung }) => `Xoá khu vực sự cố: ${idvung}`,
  [logAction.THEM_CHI_NHANH_VUNG_SU_CO]: ({ idcn, idvung }) =>
    `Thêm chi nhánh: ${idcn} vào vùng sự cố: ${idvung}`,
  [logAction.XOA_CHI_NHANH_VUNG_SU_CO]: ({ id }) => `Xoá chi nhánh: ${id} khỏi vùng sự cố`,
  [logAction.XOA_NHAN_VIEN_SU_CO]: ({ idnvbh, lydo }) =>
    `Xoá nhân viên: ${idnvbh} khỏi sự cố với lý do: ${lydo}`,
  [logAction.THEM_DON_DAT_HANG]: ({ tendonhang }) => `Thêm đơn đặt hàng: ${tendonhang}`,
  [logAction.CAP_NHAT_TRANG_THAI_DON_DAT_HANG]: ({ iddathang }) =>
    `Cập nhật trạng thái đơn đặt hàng id : ${iddathang}`,
  [logAction.XOA_DON_DAT_HANG]: ({ iddathang }) => `Xoá đơn đặt hàng id : ${iddathang}`,
  [logAction.SUA_DON_DAT_HANG]: ({ tendonhang }) => `Sửa đơn đặt hàng: ${tendonhang}`,
  [logAction.DUYET_DON_DAT_HANG]: ({ listDonDatHang }) =>
    `Duyệt đơn đặt hàng với id: ${listDonDatHang}`,
  [logAction.CAP_NHAT_DON_HANG_THANH_CONG]: ({ listDonDatHang }) =>
    `Cập nhật đơn hàng thành công với id: ${listDonDatHang}`,
  [logAction.THEM_VVP_DAT_HANG_TONG_HOP]: ({ tendonhang }) =>
    `Thêm văn phòng đặt hàng tổng hợp: ${tendonhang}`,
  [logAction.GET_DS_ICD10]: ({ keyword, nameChuong, page }) =>
    `Xem danh sách ICD10: tìm kiếm theo keyword: ${keyword}, chương: ${nameChuong}, trang: ${page}`,
  [logAction.XUAT_EXCEL_DS_ICD10]: ({ keyword, nameChuong }) =>
    `Xuất excel danh sách ICD10: tìm kiếm theo keyword: ${keyword}, chương: ${nameChuong}`,
  [logAction.GET_CHI_TIET_ICD10]: ({ maICD }) => `Xem chi tiết ICD10: mã ICD: \n${maICD}`,
  [logAction.XOA_ICD10]: ({ maICD }) => `Xoá ICD10: mã ICD: \n${maICD}`,
  [logAction.THEM_MOI_ICD10]: ({ nameChuong, mabenh, mabenhkodau, icD10TV, icD10TA, ghichu }) =>
    `Thêm ICD-10: 
  \n Chương: ${nameChuong}
  \n Mã bệnh: ${mabenh}
  \n Mã bệnh không dấu: ${mabenhkodau},
  \n Tên tiếng việt: ${icD10TV},
  \n Tên tiếng anh: ${icD10TA},
  \n Ghi chú: ${ghichu}`,

  [logAction.CHINH_SUA_ICD10]: ({ nameChuong, mabenh, mabenhkodau, icD10TV, icD10TA, ghichu }) =>
    `Chỉnh sửa ICD-10: 
  \n Chương: ${nameChuong}
  \n Mã bệnh: ${mabenh}
  \n Mã bệnh không dấu: ${mabenhkodau},
  \n Tên tiếng việt: ${icD10TV},
  \n Tên tiếng anh: ${icD10TA},
  \n Ghi chú: ${ghichu}`,

  [logAction.GET_DS_DVT]: () => `Xem danh sách DVT`,
  [logAction.XUAT_EXCEL_DS_DVT]: () => `Xuất excel danh sách DVT`,
  [logAction.GET_CHI_TIET_DVT]: ({ maDVT }) => `Xem chi tiết DVT: mã DVT: \n${maDVT}`,
  [logAction.XOA_DVT]: ({ maDVT }) => `Xoá DVT: mã DVT: \n${maDVT}`,
  [logAction.CHINH_SUA_DVT]: ({ form }) => `Cập nhật DVT: \n${formatPayloadDetails(form)}`,
  [logAction.THEM_MOI_DVT]: ({ form }) => `Thêm mới DVT: \n${formatPayloadDetails(form)}`,

  [logAction.GET_DS_KHO]: ({ nameCompany }) => `Xem danh sách kho của ${nameCompany}`,
  [logAction.GET_DS_KHO_CHI_NHANH]: ({ nameKho }) => `Xem danh sách kho chi nhánh: ${nameKho}`,
  [logAction.XUAT_EXCEL_DS_KHO]: () => `Xuất excel danh mục kho`,
  [logAction.GET_CHI_TIET_KHO]: ({ nameKho, maKho }) => `Xem chi tiết kho: ${maKho} - ${nameKho}`,
  [logAction.THEM_MOI_KHO]: ({ form }) => `Thêm kho: \n${formatPayloadDetails(form)}`,
  [logAction.CHINH_SUA_KHO]: ({ form }) => `Cập nhật kho: \n${formatPayloadDetails(form)}`,
  [logAction.XOA_KHO]: ({ nameKho, nameCompany }) =>
    `Xem chi tiết kho: ${nameCompany} - tên kho: ${nameKho}`,
  [logAction.CAP_NHAT_KHO_CHI_NHANH]: ({ nameKho, nameCompany, listNameCN }) =>
    `Xem chi tiết kho chi nhánh: ${nameCompany} - tên kho: ${nameKho} \nDanh sách kho chi nhánh:\n${listNameCN}`,

  [logAction.GET_DS_HOA_HONG]: ({ nameCompany, keyword }) =>
    `Xem danh sách danh mục hoa hồng thủ thuật: ${nameCompany}, tìm kiếm theo keyword: "${keyword}"`,
  [logAction.XUAT_EXCEL_DS_HOA_HONG]: () => `Xuất excel danh sách danh mục hoa hồng thủ thuật`,
  [logAction.GET_CHI_TIET_HOA_HONG]: ({ form }) =>
    `Xem chi tiết hoa hồng thử thuật: \n${formatPayloadDetails(form)}`,
  [logAction.XOA_HOA_HONG]: ({ ma }) => `Xoá hoa hồng thủ thuật: idhh: ${ma}`,
  [logAction.CHINH_SUA_HOA_HONG]: ({ form }) =>
    `Cập nhật hoa hồng: \n${formatPayloadDetails(form)}`,
  [logAction.THEM_MOI_HOA_HONG]: ({ form }) => `Thêm mới hoa hồng:\n${formatPayloadDetails(form)}`,

  [logAction.GET_DS_HOA_HONG]: ({ nameCompany, keyword }) =>
    `Xem danh sách danh mục hoa hồng thủ thuật: ${nameCompany}, tìm kiếm theo keyword: "${keyword}"`,
  [logAction.GET_CHI_TIET_HOA_HONG]: ({ form }) =>
    `Xem chi tiết hoa hồng thử thuật: \n${formatPayloadDetails(form)}`,
  [logAction.XOA_HOA_HONG]: ({ ma }) => `Xoá hoa hồng thủ thuật: idhh: ${ma}`,
  [logAction.CHINH_SUA_HOA_HONG]: ({ form }) =>
    `Cập nhật hoa hồng: \n${formatPayloadDetails(form)}`,
  [logAction.THEM_MOI_HOA_HONG]: ({ form }) => `Thêm mới hoa hồng:\n${formatPayloadDetails(form)}`,

  [logAction.GET_DS_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI]: ({ keyword, idnhom }) =>
    `Xem danh sách loại hàng hóa thuốc vật tư phân loại: ID nhóm: ${idnhom}, tìm kiếm theo keyword: "${keyword}"`,
  [logAction.XUAT_EXCEL_DS_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI]: () =>
    `Xuất excel danh sách loại hàng hóa thuốc vật tư phân loại`,
  [logAction.GET_DETAIL_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI]: ({ id, keyword }) =>
    `Xem chi tiết loại hàng hóa thuốc vật tư phân loại: id: ${id}, tìm kiếm theo keyword: "${keyword}"`,
  [logAction.THEM_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI]: ({ form }) =>
    `Thêm mới loại hàng hóa thuốc vật tư phân loại:\n${formatPayloadDetails(form)}`,
  [logAction.CHINH_SUA_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI]: ({ form }) =>
    `Chỉnh sửa mới loại hàng hóa thuốc vật tư phân loại:\n${formatPayloadDetails(form)}`,
  [logAction.XOA_LOAI_HANG_HOA_THUOC_VAT_TU_PHAN_LOAI]: ({ id }) =>
    `Xoá loại hàng hóa thuốc vật tư phân loại: id: ${id}`,

  [logAction.XUAT_EXCEL_DS_LOAI_HANG_HOA_THUOC_VAT_TU_NHOM_TAT_CA]: () =>
    `Xuất excel danh sách loại hàng hóa thuốc vật tư nhóm tất cả`,
  [logAction.XUAT_EXCEL_DS_LOAI_HANG_HOA_THUOC_VAT_TU_NHOM_THEO_LOAI]: () =>
    `Xuất excel danh sách loại hàng hóa thuốc vật tư nhóm theo loại`,
  [logAction.THEM_LOAI_HANG_HOA_THUOC_VAT_TU_NHOM]: ({ form }) =>
    `Thêm mới loại hàng hóa thuốc vật tư nhóm:\n${formatPayloadDetails(form)}`,
  [logAction.CHINH_SUA_LOAI_HANG_HOA_THUOC_VAT_TU_NHOM]: ({ form }) =>
    `Chỉnh sửa loại hàng hóa thuốc vật tư nhóm:\n${formatPayloadDetails(form)}`,
  [logAction.UPDATE_TPCN_LOAI_HANG_HOA_THUOC_VAT_TU_NHOM]: ({ active, listIdNhom }) =>
    `Cập nhật thực phẩm chức năng: \nactive: ${active} \nID nhóm: ${listIdNhom} `,

  [logAction.GET_DS_BO_PHAN]: ({ keyword }) =>
    `Xem danh sách danh bộ phận: tìm kiếm theo keyword: "${keyword}"`,
  [logAction.CHINH_SUA_BO_PHAN]: ({ form }) => `Cập nhật bộ phận:\n${formatPayloadDetails(form)}`,
  [logAction.THEM_MOI_BO_PHAN]: ({ form }) => `Thêm mới bộ phận:\n${formatPayloadDetails(form)}`,

  [logAction.GET_DS_DINH_MUC_TIEU_HAO_DICH_VU]: ({ idnhomdv, idct, keyword }) =>
    `Xem danh sách dịch vụ định mức tiêu hao: ID công ty: ${idct} - ID nhóm dịch vụ: ${idnhomdv}, tìm kiếm theo keyword: "${keyword}"`,
  [logAction.GET_DETAIL_DINH_MUC_TIEU_HAO_DICH_VU]: ({ id }) =>
    `Xem chi tiết dịch vụ định mức tiêu hao của dịch vụ: ID ${id}`,
  [logAction.THEM_DINH_MUC_TIEU_HAO_DICH_VU]: ({ form }) =>
    `Thêm mới định mức vật tư tiêu hao:\n${formatPayloadDetails(form)}`,
  [logAction.CHINH_SUA_DINH_MUC_TIEU_HAO_DICH_VU]: ({ form }) =>
    `Chỉnh sửa định mức vật tư tiêu hao:\n${formatPayloadDetails(form)}`,
  [logAction.XOA_DINH_MUC_TIEU_HAO_DICH_VU]: ({ id }) => `Xoá định mức vật tư tiêu hao: ID: ${id}`,

  [logAction.GET_DS_DINH_MUC_TIEU_HAO_XET_NGHIEM]: () =>
    `Xem danh sách danh mục xét nghiệm định mức tiêu hao`,
  [logAction.GET_DETAIL_DINH_MUC_TIEU_HAO_XET_NGHIEM]: ({ id }) =>
    `Xem chi tiết xét nghệm định mức tiêu hao của dịch vụ: ID ${id}`,
  [logAction.THEM_DINH_MUC_TIEU_HAO_XET_NGHIEM]: ({ form }) =>
    `Thêm mới định mức vật tư tiêu hao xét nghiệm:\n${formatPayloadDetails(form)}`,
  [logAction.CHINH_SUA_DINH_MUC_TIEU_HAO_XET_NGHIEM]: ({ form }) =>
    `Chỉnh sửa định mức vật tư tiêu hao xét nghiệm:\n${formatPayloadDetails(form)}`,
  [logAction.XOA_DINH_MUC_TIEU_HAO_XET_NGHIEM]: ({ id }) =>
    `Xoá định mức vật tư tiêu hao xét nghiệm: ID: ${id}`,

  [logAction.GET_DS_DINH_MUC_TIEU_HAO_CDHA]: () => `Xem danh sách danh mục CĐHA định mức tiêu hao`,
  [logAction.GET_DETAIL_DINH_MUC_TIEU_HAO_CDHA]: ({ id }) =>
    `Xem chi tiết CĐHA định mức tiêu hao của dịch vụ: ID ${id}`,
  [logAction.THEM_DINH_MUC_TIEU_HAO_CDHA]: ({ form }) =>
    `Thêm mới định mức vật tư tiêu hao CĐHA:\n${formatPayloadDetails(form)}`,
  [logAction.CHINH_SUA_DINH_MUC_TIEU_HAO_CDHA]: ({ form }) =>
    `Chỉnh sửa định mức vật tư tiêu hao CĐHA:\n${formatPayloadDetails(form)}`,
  [logAction.XOA_DINH_MUC_TIEU_HAO_CDHA]: ({ id }) =>
    `Xoá định mức vật tư tiêu hao CĐHA: ID: ${id}`,
  [logAction.TRA_CUU_BENH_NHAN]: ({ nameBenhNhan }) => `Xem thông tin bệnh nhân: ${nameBenhNhan}`,

  [logAction.GET_DS_GPP]: ({ idct, dateFrom, dateTo, keyword, idPhanLoai, idNhom, page }) => `Xem danh sách lịch sử thay đổi giá GPP thuốc vật tư: ID công ty: ${idct}, tìm kiếm theo ngày: ${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''} - ${dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''}, keyword: ${keyword}, ID phân loại: ${idPhanLoai}, ID nhóm: ${idNhom}, trang: ${page}`,
  [logAction.XUAT_EXCEL_DS_GPP]: () => `Xuất excel danh sách lịch sử thay đổi giá GPP thuốc vật tư`,


}

export const logActionHandler = (action, info = '') => {
  const logMessage = logMessages[action]
  return logMessage ? logMessage(info) : 'Unknown action'
}
