import { call, put, takeLatest, select } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { khachhangCRMService } from '../../services/khachhangCRM/khachhangCRMService'
import ToastCus from '../../utils/Toast'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { ICD10Service } from '../../services/ICD10/ICD10Service'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* IDC10Saga() {
  yield takeLatest(typeAction.GET_ALL_CHUONG_ICD10, function* getAllChuongs({ type }) {
    try {
      const { data } = yield call(() => ICD10Service.getAllChuongs())
      yield put({
        type: typeAction.DISPATCH_GET_ALL_CHUONG,
        payload: data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  yield takeLatest(
    typeAction.GET_ICD10_BY_CHUONG,
    function* getICD10ByChuongs({
      type,
      keyword,
      idchuong,
      page,
      pageSize,
      reset,
      setTotalPages,
      nameChuong,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const { IDC10s } = yield select((state) => state.IDC10Reducer)
      yield put({
        type: typeAction.OPEN_LOADING_TABLE,
      })
      try {
        const { data } = yield call(() =>
          ICD10Service.getIC10ByIdChuong(keyword, idchuong, page, pageSize),
        )
        setTotalPages(data?.totalPages === 0 ? 1 : data.totalPages)
        yield put({
          type: typeAction.DISPATCH_ICD10_BY_IDCHUONG,
          payload: reset ? data : { ...data, data: [...IDC10s?.data, ...data.data] },
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_ICD10,
          true,
          MENU_CONSTANTS.ICD_10,
          null,
          {
            keyword: keyword ?? '',
            nameChuong,
            page,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.ICD_10,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_TABLE,
        })
      }
    },
  )
}
