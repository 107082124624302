import React, { useState, useRef, useEffect } from 'react'
import { Button } from 'antd'
import axios from 'axios'
const Test = () => {
  const [file1, setFile1] = useState(null)
  const URL = ''
  const videoRef = useRef(null)
  const handleInput_1 = (e) => {
    const file = e.target.files[0]
    setFile1(file)
  }
  const handleInput_2 = (e) => {
    const file = e.target.files[0]
    let render = new FileReader()
    render.readAsDataURL(file)
    render.onload = (e) => {
      setFile1(e.target.result)
    }
  }
  const handleInput_3 = (e) => {
    const file = e.target.files[0]
    let formData = new FormData()
    formData.append('file', file)
    setFile1(formData)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    axios.post(URL, file1)
  }
  useEffect(() => {
    const getVideo = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true })
        if (videoRef.current) {
          videoRef.current.srcObject = stream
        }
      } catch (err) {
        console.error('Lỗi khi truy cập camera: ', err)
      }
    }

    // getVideo();
  }, [videoRef])
  ;(function () {
    var run = function () {
      if (
        typeof window.LadiPageScript == 'undefined' ||
        typeof window.ladi == 'undefined' ||
        window.ladi == undefined
      ) {
        setTimeout(run, 100)
        return
      }
      window.LadiPageApp = window.LadiPageApp || new window.LadiPageAppV2()
      window.LadiPageScript.runtime.ladipage_id = '66b9ad461454130012fdb990'
      window.LadiPageScript.runtime.publish_platform = 'LADIPAGEDNS'
      window.LadiPageScript.runtime.version = '1723196011965'
      window.LadiPageScript.runtime.cdn_url = 'https://w.ladicdn.com/v4/source/'
      window.LadiPageScript.runtime.DOMAIN_SET_COOKIE = ['315medical.com']
      window.LadiPageScript.runtime.DOMAIN_FREE = ['preview.ldpdemo.com', 'ldp.page']
      window.LadiPageScript.runtime.bodyFontSize = 12
      window.LadiPageScript.runtime.store_id = '5aa6273ea81f66ca2eacc40b'
      window.LadiPageScript.runtime.time_zone = 7
      window.LadiPageScript.runtime.currency = 'VND'
      window.LadiPageScript.runtime.convert_replace_str = true
      window.LadiPageScript.runtime.desktop_width = 960
      window.LadiPageScript.runtime.mobile_width = 420
      window.LadiPageScript.runtime.formdata = true
      window.LadiPageScript.runtime.tracking_button_click = true
      window.LadiPageScript.runtime.publish_time = 1723706846397
      window.LadiPageScript.runtime.lang = 'vi'
        window.LadiPageScript.runtime.lang = 'vi'
      window.LadiPageScript.run(true)
      window.LadiPageScript.runEventScroll()
      if (document.cookie.includes('_total_turn_SPINLUCKY4=1')) {
        // Đặt lại giá trị cookie về 0 để tiếp tục quay
        document.cookie = '_total_turn_SPINLUCKY4=0; path=/'
      }

      setTimeout(run, 0)
    }
    run()
  })()
  return (
    <div className="flex flex-col w-full h-500 items-center justify-between">
      {/* <div className="h-20">
        <h2>test 1</h2>
        <Button className="my-2 p-0 bg-blue-500 text-white">
          <label className="cursor-pointer px-4 py-1" for="file-upload">
            Chọn file danh sách test
          </label>
          <input id="file-upload" type="file" onChange={handleInput_1} />
        </Button>
      </div>
      <div>
        <h2>test 2</h2>
        <Button className="my-2 p-0 bg-blue-500 text-white">
          <label className="cursor-pointer px-4 py-1" for="file-upload-1">
            Chọn file danh sách
          </label>
          <input id="file-upload-1" type="file" onChange={handleInput_2} />
        </Button>
      </div>
      <div>
        <h2>test 3</h2>
        <Button className="my-2 p-0 bg-blue-500 text-white">
          <label className="cursor-pointer px-4 py-1" for="file-upload-2">
            Chọn file danh sách
          </label>
          <input id="file-upload-2" type="file" onChange={handleInput_3} />
        </Button>
      </div>
      <h1>Camera Access</h1>
      <video ref={videoRef} width="640" height="480" autoPlay /> */}
      {/* <button className='border' onClick={()=>{handleSubmit()}}>Nộp file</button> */}
    </div>
  )
}

export default Test
