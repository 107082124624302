import { formatNumberVND } from '../../../../../utils/formatNumberVND'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')

export const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    align: 'center',
    width: 50,
    fixed: 'left',
    render: (text, record, index) => ++index,
  },
  {
    title: 'Ngày',
    dataIndex: 'ngaythanhtoan',
    key: 'ngaythanhtoan',
    align: 'center',
    width: 100,
    fixed: 'left',
    render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
  },
  {
    title: 'Chi nhánh',
    dataIndex: 'tenchinhanh',
    key: 'tenchinhanh',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Mã phiếu thu',
    dataIndex: 'maphieuthu',
    key: 'maphieuthu',
    width: 150,
  },
  {
    title: 'Mã bệnh nhân',
    dataIndex: 'mabenhnhan',
    key: 'mabenhnhan',
    width: 150,
  },
  {
    title: 'Tên bệnh nhân',
    dataIndex: 'tenbenhnhan',
    key: 'tenbenhnhan',
    width: 250,
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'diachi',
    key: 'diachi',
    width: 250,
  },
  {
    title: 'Tiếp nhận lúc',
    dataIndex: 'ngaykham',
    key: 'ngaykham',
    align: 'center',
    width: 150,
    render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
  },
  {
    title: 'Bắt đầu khám',
    dataIndex: 'giovaopk',
    key: 'giovaopk',
    align: 'center',
    width: 150,
    render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
  },
  {
    title: 'Kết thúc khám',
    dataIndex: 'giorapk',
    key: 'giorapk',
    align: 'center',
    width: 150,
    render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
  },
  {
    title: 'Thời gian chờ khám',
    dataIndex: 'thoigianchokham',
    key: 'thoigianchokham',
    align: 'center',
    width: 150,
    render: (text) => (text !== '::' ? text : '-'),
  },
  {
    title: 'Thời gian khám',
    dataIndex: 'thoigiankham',
    key: 'thoigiankham',
    align: 'center',
    width: 120,
    render: (text) => (text !== '::' ? text : '-'),
  },
  {
    title: 'Phát thuốc lúc',
    dataIndex: 'gioketthuc',
    key: 'gioketthuc',
    align: 'center',
    width: 150,
    render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
  },
  {
    title: 'Bác sĩ khám',
    dataIndex: 'bskham',
    key: 'bskham',
    width: 250,
  },
]
