import React, { useContext, useEffect, useRef, useState } from 'react'
import { Switch, ConfigProvider, Form, Popconfirm, Table, notification, InputNumber } from 'antd'
import './styleTable.css'
import moment from 'moment'
import { toolService } from '../../../../services/tool/toolService'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUuDai } from '../../../../store/actions/toolAction'
import 'moment/locale/vi'
import { addLog } from '../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../utils/log/logConstant'
import { formatPayloadDetails } from '../../../../utils/algorithm'
moment.locale('vi')
const EditableContext = React.createContext(null)

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
    }
  }, [editing])
  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async () => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({
        ...record,
        ...values,
      })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }
  let childNode = children
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     message: `${title} is required.`,
        //   },
        // ]}
      >
        <InputNumber ref={inputRef} min={0} max={100} onPressEnter={save} onBlur={save} />
        {/* <Input ref={inputRef} onPressEnter={save} onBlur={save} /> */}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingInlineEnd: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }
  return <td {...restProps}>{childNode}</td>
}
const TableTaiKham = ({ data, setData, fetchData }) => {
  const [api, contextHolder] = notification.useNotification()
  const { infoUser } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const onChangeChecked = async (checked, record) => {
    const value = checked ? 1 : 0
    const index = data.findIndex((item) => item.idudtk === record.idudtk)
    if (index !== -1) {
      setData((prevState) => {
        const newData = [...prevState] // Sao chép mảng hiện tại
        newData[index] = { ...newData[index], sudung: value } // Cập nhật phần tử cụ thể
        return newData // Trả về mảng đã được cập nhật
      })
    }
    const form = {
      idudtk: record.idudtk,
      sudung: value,
      nguoisua: infoUser?.idnv,
    }
    try {
      await toolService.putUuDaiTK(form)
      fetchData()
      openNotificationWithIcon('Cập nhật hiệu lực thành công')
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        logAction.CAP_NHAT_CHUONG_TRINH_TAI_KHAM,
        true,
        MENU_CONSTANTS.CONG_CU,
        null,
        {
          tendichvu: record.tendv,
          hieuluc: value,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.TIEP_NHAN,
        detailErrorPayload,
      )
    }
  }

  const openNotificationWithIcon = (description) => {
    api.success({
      message: 'Cập nhật tái khám',
      description,
      showProgress: true,
    })
  }
  const handleDelete = (record) => {
    dispatch(deleteUuDai(record, fetchData, openNotificationWithIcon))
  }
  const defaultColumns = [
    {
      title: 'STT',
      //   dataIndex: 'name',
      width: 60,
      align: 'center',
      render: (text, _, index) => ++index,
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'tendv',
      width: 300,
    },
    {
      title: 'Mã dịch vụ',
      dataIndex: 'madv',
      align: 'center',
      width: 150,
    },
    {
      title: '% giảm',
      dataIndex: 'ptgiamgia',
      align: 'center',
      width: 100,
      editable: true,
    },
    {
      title: 'Chuyên khoa',
      dataIndex: 'tenchuyenkhoa',
      width: 120,
    },
    {
      title: 'Ngày Tạo',
      dataIndex: 'ngaytao',
      width: 150,
      render: (text) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Người tạo',
      dataIndex: 'tennguoitao',
      width: 200,
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaysua',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
      width: 150,
    },
    {
      title: 'Người sửa',
      dataIndex: 'tennguoisua',
      width: 200,
    },
    {
      title: 'Hiệu lực',
      align: 'center',
      dataIndex: 'sudung',
      render: (text, record) => (
        <Switch
          size="small"
          value={text === 1}
          onChange={(checked) => onChangeChecked(checked, record)}
        />
      ),
      fixed: 'right',
      width: 70,
    },
    {
      title: '',
      align: 'center',
      dataIndex: 'Xoá',
      fixed: 'right',
      width: 30,
      render: (_, record) =>
        data.length >= 1 ? (
          <Popconfirm
            title="Bạn có chắc muốn xoá?"
            okText="Xoá"
            cancelText="Huỷ"
            onConfirm={() => handleDelete(record)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5 text-red-500 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </Popconfirm>
        ) : null,
    },
  ]
  const handleSave = async (row) => {
    const newData = [...data]
    const index = newData.findIndex((item) => row.idudtk === item.idudtk)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    setData(newData)
    const form = {
      idudtk: row.idudtk,
      ptgiamgia: row.ptgiamgia,
      nguoisua: infoUser?.idnv,
    }
    try {
      await await toolService.putUuDaiTK(form)
      fetchData()
      openNotificationWithIcon('Đã cập nhật % giảm giá dịch vụ ')
    } catch (error) {
      console.log(error)
    }
  }
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    }
  })
  return (
    <div className="mt-2">
      {contextHolder}
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          scroll={{
            x: 1500,
          }}
          pagination={{
            pageSize: 5,
          }}
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={data}
          columns={columns}
        />
      </ConfigProvider>
    </div>
  )
}

export default TableTaiKham
