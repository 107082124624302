import {
  Button,
  DatePicker,
  Checkbox,
  Input,
  InputNumber,
  Select,
  Tabs,
  Divider,
  Radio,
  Popover,
} from 'antd'
import dayjs from 'dayjs'
import { SearchOutlined, UpOutlined, DownOutlined, PrinterOutlined } from '@ant-design/icons'
import { https } from '../../../../../../services/apiService'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ToaThuoc from '../../../../ChildrenClinic/General/ToaThuoc/ToaThuoc'

import { childrenClinicService } from '../../../../../../services/childrenClinic/childrenClinicService'
import {
  deleteBNThuocBSKhongKeToaAction,
  updateBNThuocBSKeToaLaiAction,
} from '../../../../../../store/actions/childrenClinicAction'

import { maternityClinicService } from '../../../../../../services/maternityClinic/maternityClinicService'
// import {
//   deleteBNThuocBSKhongKeToaSanAction,
//   updateBNThuocBSKeToaLaiSanAction,
// } from '../../../../../../store/actions/maternityClinicAction'
import FormSanLon from './FormSanLon'
import FormSanNhoPhuKhoa from './FormSanNhoPhuKhoa'
import FormNhuHoa from './FormNhuHoa'
import Swal from 'sweetalert2'
import * as typeAction from '../../../../../../store/constants/constants'
import { generatePhieuToaThuoc } from '../../../../../../utils/report/phieuToaThuoc'
import 'moment/locale/vi'
import { CalculateDuSanh } from '../../../../../../utils/CalculateDuSanh'
import { generatePhieuKhamBenh } from '../../../../../../utils/report/phieuKhamBenh'
import { fetchNgayNghi } from '../../../../../../store/actions/ngayNghiAction'
import CustomInput from './CustomInput'
moment.locale('vi')
const { TextArea } = Input
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
const FormCD = ({
  date,
  count,
  checkBox,
  setCheckBox,
  formik,
  setDate,
  formikPhuSan,
  setCount,
  formBenhNhan,
  isPrintToaThuocKhiLuu,
  setIsPrintToaThuocKhiLuu,
}) => {
  const dispatch = useDispatch()
  // const {  DetailBN, ThuocBN, infoBN, DetailToaThuocBN } = useSelector((state) => state.maternityClinicReducer,)
  const { loidan, DetailBN, ThuocBN, infoBN, DetailToaThuocBN } = useSelector(
    (state) => state.childrenClinicReducer,
  )
  const { NgayNghis } = useSelector((state) => state.NgayNghiReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [ChanDoan, setChanDoan] = useState([])
  const [listLyDoKhongKeToa, setListLyDoKhongKeToa] = useState([])
  const [isStatus, setIsStatus] = useState(true)
  // const [loaiKham, setLoaiKham] = useState(formikPhuSan.values.idloaisan)
  const [tenLoaiKham, setTenLoaiKham] = useState('Sản lớn')
  const [listLoaiKham, setListLoaiKham] = useState([])
  const [isFormKham, setIsFormKham] = useState(true)
  const disabledDate = (current) => {
    const today = dayjs() // Ngày hiện tại

    // Kiểm tra nếu ngày bị chặn
    return (
      (current && current.isBefore(today, 'day')) || // Chặn các ngày trước hôm nay
      NgayNghis.filter((holiday) => holiday.sudung === 1).some((holiday) => {
        const rangeStart = dayjs(holiday.tungay)
        const rangeEnd = dayjs(holiday.denngay)
        return current && current >= rangeStart && current <= rangeEnd
      })
    )
    // Can not select days before today and today
    // return current && current < dayjs().startOf('day')
  }
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }
  const handleSlected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  const onChangeDatePicker = (date, dateString) => {
    setDate(moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
    formik.setFieldValue('ngaytaikham', moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      fechChanDoan(keyword)
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const fechChanDoan = async (keyword) => {
    try {
      const result = await childrenClinicService.getChanDoan(keyword)
      setChanDoan(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchMissingOption = async (idIcd) => {
    try {
      const response = await https.get(`ChanDoan/GetICD10ByID?idICD=${idIcd}`)
      const newOption = response.data
      console.log(ChanDoan, newOption)
      setChanDoan((prevOptions) => [...prevOptions, newOption])
    } catch (error) {
      console.log(error)
    }
  }
  const fetchListLyDoKhongKeToa = async () => {
    try {
      const result = await childrenClinicService.getListLyDoKhongKeToa()
      setListLyDoKhongKeToa(result?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const onClickChanDoan = (value, options, key, keyIDC) => {
    const newValue = options.mabenh + '-' + options.icD10TV + '/'
    formik.setFieldValue(key, newValue)
    formik.setFieldValue(keyIDC, value)
    //reset lai chan doan
    fechChanDoan('')
  }

  const onClick = (e) => {
    if (
      DetailBN?.trangthaikhambenh === 2 &&
      (DetailToaThuocBN?.BSKEDON || DetailToaThuocBN?.NGAYKEDON) &&
      DetailToaThuocBN?.DANHANTOA === 1
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    if (DetailBN?.trangthaikhambenh === 2 || DetailBN?.trangthaikhambenh === 1) {
      const { checked } = e.target
      formik.setFieldValue('lydokolaythuoc', null)
      // formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      // formik.setFieldError('ngaytaikham', null)
      // setDate(dayjs(new Date()))
      // setCount(0)
      setCheckBox(checked)

      // formik.setFieldValue('lydokolaythuoc', null)
      // setCheckBox(checked)
      // formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      // setCount(0)
      // formik.setFieldError('ngaytaikham', null)
    }
  }
  const checkUpdate =
    DetailBN && DetailBN?.trangthaikhambenh !== 1 && DetailToaThuocBN?.DANHANTOA !== 0
  const handleLuuVaIn = async (isPrint) => {
    setIsPrintToaThuocKhiLuu(isPrint)
    // if (ThuocBN?.length > 0) formik.setFieldValue('lydokolaythuoc', 0)
    if (
      (isPrint || DetailBN?.trangthaikhambenh !== 1) &&
      ((checkBox === false && ThuocBN?.length <= 0) || (checkBox && !formik.values.lydokolaythuoc))
    ) {
      formik.setFieldValue('lydokolaythuoc', null)
      formik.setFieldError('lydokolaythuoc', true)
      // formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      // formik.setFieldError('ngaytaikham', null)
      // setDate(dayjs(new Date()))
      // setCount(0)
      setCheckBox(true)
      Toast.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })

      // formik.setFieldValue('lydokolaythuoc', null)
      // setCheckBox(true)
      // formik.setFieldValue('ngaytaikham', dayjs(new Date()))
      // setCount(0)
      return
    }
    if (isStatus && checkRequiredFields()) {
      showErrorToast('Dự sanh hoặc lúc thai không được bỏ trống!')
      return
    } 
    if(!isStatus){
      formik.values.dusanh = null
      formik.values.tuanthai = null
      formik.values.ngaythai = null
    }

    if (
      checkBox === false &&
      ThuocBN?.find((item) => item?.soLuong <= 0 || item?.ton < 0 || !item?.soLuong || !item?.ton)
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại số lượng thuốc trong toa!',
      })
      return
    }
    // if (!formik.values.para || formik.values.para?.trim()?.length === 0) {
    //   formik.setFieldValue('para', null)
    //   formik.setErrors({ para: 'require' })
    //   Toast.fire({
    //     icon: 'error',
    //     title: 'Vui lòng nhập chỉ số Para!',
    //   })
    //   return
    // }
    if (DetailBN?.trangthaikhambenh === 1) {
      if (_.isEmpty(formik.errors)) {
        //nếu mà không có lỗi mới lưu
        //formik.setFieldValue('formPhuSan', formikPhuSan.values)
        formik.handleSubmit()
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Vui lòng kiểm tra lại!',
        })
      }
      return
    }
    if (DetailBN?.trangthaikhambenh === 2) {
      if (
        (DetailToaThuocBN?.BSKEDON || DetailToaThuocBN?.NGAYKEDON) &&
        DetailToaThuocBN?.DANHANTOA === 1
      ) {
        Toast.fire({
          icon: 'error',
          title: 'Dược sĩ đang thao tác!',
        })
        return
      }
      if (checkBox && !formik.values.lydokolaythuoc) {
        Toast.fire({
          icon: 'error',
          title: 'Vui lòng kiểm tra lại!',
        })
        return
      }
      // kê lại toa khi dược chưa lưu
      if (checkBox || ThuocBN?.length < 1) {
        // lưu khi tích chọn không kê toa hoặc toa thuốc rỗng
        dispatch(
          deleteBNThuocBSKhongKeToaAction(
            formik.values,
            formBenhNhan.values,
            DetailBN?.idbnttc,
            formik.values.lydokolaythuoc,
          ),
        )
        setCheckBox(false)
      } else {
        // lưu khi không chỉnh sửa hoặc chỉnh số lượng thuốc trong toa hoặc cho thêm thuốc vào toa
        dispatch(
          updateBNThuocBSKeToaLaiAction(
            formik.values,
            formBenhNhan.values,
            DetailBN?.idbnttc,
            ThuocBN?.map((item, index) => ({
              ...item,
              lieuDung: item?.lieuDung ?? ``,
              soLanNgay: item?.soLanNgay ?? ``,
              stt: `${++index}`,
            })),
            infoBN,
            DetailBN,
            PkDangNhap,
            isPrint,
          ),
        )
      }
      return
    }
  }
  const showErrorToast = (message) => {
    Toast.fire({
      icon: 'error',
      title: message,
    })
  }

  const handleChangeLucThai = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  const handleChangeLyDoKhongLayThuoc = (value) => {
    formik.setFieldValue('lydokolaythuoc', value)
  }
  const checkRequiredFields = () => {
    const requiredFields = [formik.values.dusanh, formik.values.tuanthai, formik.values.ngaythai]
    return requiredFields.some((field) => field === null)
  }

  // -------- chọn ngày dự sanh và tính ngày sanh---------//
  const handleDatePickerDuSanh = (date, dateString) => {
    const formatDate = moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD')
    const Calculate = CalculateDuSanh(DetailBN?.ngaykham, formatDate)
    formik.setFieldValue('dusanh', formatDate)
    formik.setFieldValue('ngaysanh', Calculate.ngaySanh)
    formik.setFieldValue('tuoithai', Calculate.tuoiThai)
  }
  //----------- lấy danh sách loại khám -----------
  const fetchListLoaiKham = async () => {
    try {
      const result = await maternityClinicService.getListLoaiKham()
      setListLoaiKham(result.data)
    } catch (error) {
      console.log(error)
    }
    // console.log(result.data)
  }
  useEffect(() => {
    fechChanDoan('')
    fetchListLyDoKhongKeToa()
    dispatch(fetchNgayNghi())
    if (listLoaiKham?.length <= 0) fetchListLoaiKham()
    // console.log(formik.values.ngaytaikham)
  }, [])
  useEffect(() => {
    if (
      formik.values.idchandoan &&
      !ChanDoan?.find((item) => item?.idicD10 === formik.values.idchandoan)
    ) {
      fetchMissingOption(formik.values.idchandoan)
    }
  }, [formik.values.idchandoan])
  useEffect(() => {
    if (!checkBox && ThuocBN?.length > 0) formik.setFieldValue('lydokolaythuoc', 0)
  }, [ThuocBN?.length])
  const handlePrint = () => {
    const infoBn = {
      diaChi: DetailBN.benhNhan.diaChi,
      tenPhuongXa: DetailBN.benhNhan.tenPhuongXa,
      tenQuanHuyen: DetailBN.benhNhan.tenQuanHuyen,
      tenTinhTP: DetailBN.benhNhan.tenTinhTP,
    }
    // const formatToaThuoc = DetailToaThuocBN.DanhSachThuoc.map((item) => ({
    //   ...item,
    //   soLuong: item.soluong,
    //   tenhoatchat: item.tenhoatchat,
    //   duongDung: item.duongdung,
    //   soLanNgay: item.solanngay,
    //   lieuDung: item.lieudung,
    //   ghiChu: item.ghichu,
    // }))
    let listVT = []
    // gộp các dòng khác số lô hạn dùng lại thành 1 dòng
    for (const item of DetailToaThuocBN?.DanhSachThuoc) {
      if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
        listVT?.map(
          (itemVT) =>
            itemVT?.idthuoc === item?.idthuoc &&
            (itemVT.soLuong += item.soluong) &&
            (itemVT.soluong += item.soluong),
        )
      } else {
        listVT.push({
          ...item,
          soLuong: item.soluong,
          tenhoatchat: item.tenhoatchat,
          duongDung: item.duongdung,
          soLanNgay: item.solanngay,
          lieuDung: item.lieudung,
          ghiChu: item.ghichu,
        })
      }
    }
    generatePhieuToaThuoc(infoBn, DetailBN, listVT, PkDangNhap)
  }
  // const handleSoNgayTK = (e) => {
  //   const value = e.target.value >= 0 && e.target.value < 10000 ? e.target.value : 0
  //   const formattedDate = moment()?.add(value, 'days')?.format('YYYY-MM-DD')

  //   // setCount(value)
  //   formik.setFieldValue('ngaytaikham', formattedDate)
  //   setDate(formattedDate)
  // }

  const handleSoNgayTK = (e) => {
    const value = e.target.value >= 0 && e.target.value < 10000 ? e.target.value : 0
    const formattedDate = moment()?.add(value, 'days')?.format('YYYY-MM-DD')
    const isBlockedDate = (date) => {
      const range1Start = dayjs('2025-01-01')
      const range1End = dayjs('2025-01-01')
      const range2Start = dayjs('2025-01-27')
      const range2End = dayjs('2025-02-02')
      const today = dayjs() // Ngày hiện tại

      return (
        dayjs(date).isBefore(today, 'day') || // Nếu ngày trước hôm nay
        (dayjs(date) >= range1Start && dayjs(date) <= range1End) || // Nếu trong khoảng 1
        (dayjs(date) >= range2Start && dayjs(date) <= range2End) // Nếu trong khoảng 2
      )
    }
    if (isBlockedDate(formattedDate)) return // vi phạm ngày khám thì k cho
    // setCount(value)
    formik.setFieldValue('ngaytaikham', formattedDate)
    setDate(formattedDate)
    // dispatch({
    //   type: typeAction.DISPATCH_SO_LAN_THUOC_NHI,
    //   payload: value,
    // })
  }
  const content = (
    <div className="w-32">
      <Button
        onClick={handlePrint}
        type="primary"
        disabled={!infoBN || !DetailBN || checkBox}
        style={{ marginBottom: '8px', width: '100%' }}
      >
        Toa thuốc
      </Button>
      <Button
        type="primary"
        onClick={() => {
          generatePhieuKhamBenh(DetailBN, PkDangNhap)
        }}
        style={{ width: '100%' }}
      >
        Phiếu khám bệnh
      </Button>
    </div>
  )
  const renderFormComponent = (idloaisan, formikPhuSan) => {
    const formMapping = {
      1: <FormSanLon formikPhuSan={formikPhuSan} />,
      2: <FormSanNhoPhuKhoa formikPhuSan={formikPhuSan} />,
      3: <FormSanNhoPhuKhoa formikPhuSan={formikPhuSan} />,
      4: <FormNhuHoa formikPhuSan={formikPhuSan} />,
    }

    return formMapping[idloaisan] || null
  }
  useEffect(() => {
    if (DetailBN?.trangthaikhambenh !== 1) {
      setIsStatus(DetailBN?.dusanh ? true : false) // lấy lại trạng thái dự sanh sau ca khám
    }
  }, [DetailBN])
  const hasError =
  formik.errors.lydokolaythuoc ||
  formik.errors.lydokham ||
  formik.errors.benhsu ||
  formik.errors.trieuchung ||
  formik.errors.loidan

  return (
    <div>
      <form>
        <div className="flex gap-2 justify-end">
          {/* <div className="flex w-56 items-center justify-start mr-5">
            <label className="font-semibold w-1/3 text-end mr-2">Loại khám:</label>
            <Select
              size="small"
              className="w-2/3"
              onChange={(value, option) => {
                formikPhuSan.setFieldValue('idloaisan', value)
                setTenLoaiKham(option.label)
              }}
              options={listLoaiKham?.map((item) => ({
                value: item.idbnsanloai,
                label: item.tensanloai,
              }))}
              value={formikPhuSan.values.idloaisan}
            />
          </div> */}
          <div className="flex w-40 items-center">
            <label className="font-semibold w-1/2">Số ngày TK:</label>
            {/* <Input size="small" variant="filled" readOnly className="w-1/2" value={count ?? 0} /> */}
            <Input
              disabled={checkUpdate}
              style={{ color: 'black' }}
              type="number"
              min={0}
              step={1}
              size="small"
              variant="filled"
              // readOnly
              className="w-1/2"
              value={count ?? 0}
              onChange={handleSoNgayTK}
              // onChange={(e) => {
              //   if (e.target.value >= 0) {
              //     setCount(e.target.value)
              //     formik.setFieldValue(
              //       'ngaytaikham',
              //       moment()?.add(e.target.value, 'days')?.format('YYYY-MM-DD'),
              //     )
              //   }
              // }}
            />
          </div>
          <div className="flex w-56 items-center">
            <label className="font-semibold w-1/2 text-end mr-2">Hẹn tái khám:</label>
            <DatePicker
              minDate={dayjs(moment())}
              placeholder=" "
              disabled={checkUpdate}
              style={{ color: 'black' }}
              status={
                formik.errors.ngaytaikham && formik.values.ngaytaikham === null ? 'error' : ''
              }
              allowClear={false}
              onChange={onChangeDatePicker}
              value={formik.values.ngaytaikham === null ? '' : dayjs(formik.values.ngaytaikham)}
              size="small"
              className="w-1/2"
              format={'DD/MM/YYYY'}
              disabledDate={disabledDate}
              // disabled={checkBox}
            />
          </div>
          <Checkbox onChange={onClick} checked={checkBox} className="flex items-center">
            Không kê toa
          </Checkbox>
          {checkUpdate && (
            <Popover content={content} trigger="click" placement="bottom">
              <Button type="primary" disabled={!infoBN || !DetailBN}>
                <PrinterOutlined /> In
              </Button>
            </Popover>
          )}
          <Button
            disabled={!infoBN || DetailBN?.trangthaikhambenh === 4}
            onClick={() => handleLuuVaIn(false)}
            type="primary"
          >
            {DetailBN?.trangthaikhambenh === 1 ? 'Cập nhật' : 'Lưu'}
          </Button>
          <Button
            disabled={!infoBN || DetailBN?.trangthaikhambenh === 4}
            onClick={() => handleLuuVaIn(true)}
            type="primary"
          >
            Lưu & in
          </Button>
        </div>
        <div className="h-[719px]  overflow-x-hidden">
          <div className="mb-1 w-full pl-[12.15rem]">
            <label className="font-semibold text-end mr-2">
              <span className="text-red-500">(*)</span>&nbsp;Loại khám:
            </label>
            <Radio.Group
              onChange={(e) => {
                if ((DetailBN && DetailBN?.trangthaikhambenh === 1) || !DetailBN) {
                  formikPhuSan.setFieldValue('idloaisan', e.target.value)
                  setTenLoaiKham(e.target.data?.tensanloai)
                  setIsStatus(e.target.value !== 4 ? isStatus : false) // nếu là nhũ hoa thì không cần nhập lần thai
                }
              }}
              value={formikPhuSan.values.idloaisan}
              // name="benhcaptinhmantinh"
            >
              {listLoaiKham?.map((item) => (
                <Radio value={item?.idbnsanloai} data={item}>
                  {item?.tensanloai}
                </Radio>
              ))}
              {/* <Radio value={0}>Không</Radio>
              <Radio value={1}>Có</Radio> */}
            </Radio.Group>
          </div>
          <Tabs
            tabPosition={'left'}
            type="card"
            items={[
              {
                label: (
                  <>
                    Chung
                    {hasError && <span className="text-red-500"> (*)</span>}
                  </>
                ),

                key: 1,
                children: (
                  <>
                    {checkBox ? (
                      <div className="flex gap-1 mt-1">
                        <label className="font-semibold w-44 text-end">
                          <span className="text-red-500">(*)</span>&nbsp;Lý do không kê toa:
                        </label>
                        <Select
                          // disabled={checkUpdate}
                          style={{ color: 'black' }}
                          //showSearch
                          //filterOption={false}
                          className="w-full"
                          size="small"
                          status={formik.errors.lydokolaythuoc ? 'error' : ''}
                          onChange={handleChangeLyDoKhongLayThuoc}
                          value={formik.values.lydokolaythuoc}
                          // onSearch={debounceDropDown}
                          // suffixIcon={<SearchOutlined className=" " />}
                          // notFoundContent={null}
                          options={listLyDoKhongKeToa.map((item) => ({
                            key: item.idld,
                            value: item.idld,
                            label: item.lydo,
                          }))}
                          // options={{
                          //   key: item.idld,
                          //   value: item.idld,
                          //   label: item.lydo,},
                          //   ...(listLyDoKhongKeToa?listLyDoKhongKeToa?.map((item) => ({
                          //     key: item.idld,
                          //     value: item.idld,
                          //     label: item.lydo,
                          //   })):[])}
                          // optionRender={(options) => (
                          //   <ul className="flex">
                          //     <li className="w-1/6">{options.data.mabenh}</li>
                          //     <li className="w-5/6 border-l px-5">{options.data.label}</li>
                          //   </ul>
                          // )}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">
                        <span className="text-red-500">(*)</span>&nbsp;Lý do khám:
                      </label>
                      <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        status={formik.errors.lydokham ? 'error' : ''}
                        onChange={formik.handleChange}
                        value={formik.values.lydokham}
                        name="lydokham"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">
                        <span className="text-red-500">(*)</span>&nbsp;Bệnh sử:
                      </label>
                      <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        status={formik.errors.benhsu ? 'error' : ''}
                        onChange={formik.handleChange}
                        value={formik.values.benhsu}
                        name="benhsu"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">
                        <span className="text-red-500">(*)</span>&nbsp;Triệu chứng:
                      </label>
                      <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        status={formik.errors.trieuchung ? 'error' : ''}
                        onChange={formik.handleChange}
                        value={formik.values.trieuchung}
                        name="trieuchung"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Chẩn đoán:</label>
                      <div className="flex gap-3 w-full">
                        <Select
                          disabled={checkUpdate}
                          style={{ color: 'black' }}
                          showSearch
                          placeholder="ICD-10"
                          filterOption={false}
                          dropdownStyle={{ width: 550 }}
                          className="w-28"
                          size="small"
                          onChange={(value, options) =>
                            onClickChanDoan(value, options, 'chandoan', 'idchandoan')
                          }
                          onSearch={debounceDropDown}
                          suffixIcon={<SearchOutlined className=" " />}
                          notFoundContent={null}
                          value={formik.values.idchandoan}
                          options={ChanDoan?.map((items) => ({
                            label: items.mabenh,
                            value: items.idicD10,
                            mabenh: items.mabenh,
                            icD10TV: items.icD10TV,
                            ...items,
                          }))}
                          optionRender={(options) => (
                            <ul className="flex">
                              <li className="w-10 mr-2">{options.data.mabenh}</li>
                              <li className="w-full border-l px-5 truncate">
                                {options.data.icD10TV}
                              </li>
                            </ul>
                          )}
                        />
                        <Input
                          disabled={checkUpdate}
                          style={{ color: 'black' }}
                          onChange={formik.handleChange}
                          value={formik.values.chandoan}
                          name="chandoan"
                          size="small"
                        />
                      </div>
                    </div>

                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Chẩn đoán bệnh phụ:</label>
                      <div className="flex gap-3 w-full">
                        <Select
                          placeholder="ICD-10"
                          dropdownStyle={{ width: 550 }}
                          disabled={checkUpdate}
                          style={{ color: 'black' }}
                          showSearch
                          filterOption={false}
                          className="w-28"
                          size="small"
                          onChange={(value, options) =>
                            onClickChanDoan(value, options, 'benhkemtheo', 'idicD10BENHKEMTHEO')
                          }
                          onSearch={debounceDropDown}
                          suffixIcon={<SearchOutlined className=" " />}
                          notFoundContent={null}
                          value={formik.values.idicD10BENHKEMTHEO}
                          options={ChanDoan?.map((items) => ({
                            label: items.mabenh,
                            value: items.idicD10,
                            mabenh: items.mabenh,
                            icD10TV: items.icD10TV,
                            ...items,
                          }))}
                          optionRender={(options) => (
                            <ul className="flex">
                              <li className="w-10 mr-2">{options.data.mabenh}</li>
                              <li className="w-full border-l px-2 truncate">
                                {options.data.icD10TV}
                              </li>
                            </ul>
                          )}
                        />
                        <Input
                          disabled={checkUpdate}
                          style={{ color: 'black' }}
                          onChange={formik.handleChange}
                          value={formik.values.benhkemtheo}
                          name="benhkemtheo"
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">
                        {' '}
                        <span className="text-red-500">(*)</span> Lời dặn:
                      </label>
                      <div className="flex gap-3 w-full">
                        <Select
                          dropdownStyle={{ width: 750 }}
                          disabled={checkUpdate}
                          style={{ color: 'black' }}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '')
                              .toLowerCase()
                              .localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          optionRender={(options) => (
                            <h2 className="text-pretty border-b">{options.data.label}</h2>
                          )}
                          options={loidan?.map((items) => ({
                            label: items.loiDan,
                            value: items.loiDan,
                          }))}
                          placeholder="Gợi ý"
                          showSearch
                          status={formik.errors.idloidan ? 'error' : ''}
                          onChange={handleSlected('loidan')}
                          value={null}
                          name="loidan"
                          size="small"
                          className="w-28"
                        />
                        <Input
                          disabled={checkUpdate}
                          style={{ color: 'black' }}
                          status={formik.errors.loidan ? 'error' : ''}
                          onChange={formik.handleChange}
                          value={formik.values.loidan}
                          name="loidan"
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Chẩn đoán ban đầu:</label>
                      <div className="flex gap-3 w-full">
                        <Select
                          placeholder="ICD-10"
                          dropdownStyle={{ width: 550 }}
                          disabled={checkUpdate}
                          style={{ color: 'black' }}
                          showSearch
                          filterOption={false}
                          className="w-28"
                          size="small"
                          onChange={(value, options) =>
                            onClickChanDoan(value, options, 'chandoanbandau', 'idicD10BANDAU')
                          }
                          onSearch={debounceDropDown}
                          suffixIcon={<SearchOutlined className=" " />}
                          notFoundContent={null}
                          value={formik.values.idicD10BANDAU}
                          options={ChanDoan?.map((items) => ({
                            label: items.mabenh,
                            value: items.idicD10,
                            mabenh: items.mabenh,
                            icD10TV: items.icD10TV,
                            ...items,
                          }))}
                          optionRender={(options) => (
                            <ul className="flex">
                              <li className="w-10 mr-2">{options.data.mabenh}</li>
                              <li className="w-full border-l px-2 truncate">
                                {options.data.icD10TV}
                              </li>
                            </ul>
                          )}
                        />
                        <Input
                          disabled={checkUpdate}
                          style={{ color: 'black' }}
                          onChange={formik.handleChange}
                          value={formik.values.chandoanbandau}
                          name="chandoanbandau"
                          size="small"
                        />
                      </div>
                    </div>
                    <CustomInput
                      label="Ghi chú:"
                      name="ghichu"
                      formik={formik}
                      checkUpdate={checkUpdate}
                    />
                    {/* ########################################################################## */}
                    {isExpanded && (
                      <>
                        <CustomInput
                          label="Quá trình bệnh lý:"
                          name="quatrinhbenhly"
                          formik={formik}
                          checkUpdate={checkUpdate}
                        />
                        <CustomInput
                          label="Tiền sử gia đình:"
                          name="tieusugiadinh"
                          formik={formik}
                          checkUpdate={checkUpdate}
                        />
                        <CustomInput
                          label="Toàn thân:"
                          name="toanthan"
                          formik={formik}
                          checkUpdate={checkUpdate}
                        />
                        <CustomInput
                          label="Các bộ phận:"
                          name="cacbophan"
                          formik={formik}
                          checkUpdate={checkUpdate}
                        />
                        <CustomInput
                          label="Đã xử lý:"
                          name="daxuly"
                          formik={formik}
                          checkUpdate={checkUpdate}
                        />
                        <CustomInput
                          label="ICD-10 CĐ ra viện:"
                          name="idicD10RAVIEN"
                          formik={formik}
                          checkUpdate={checkUpdate}
                          type="select"
                          options={ChanDoan?.map((items) => ({
                            label: `${items.mabenh} - ${items.icD10TV}`,
                            value: items.idicD10,
                            mabenh: items.mabenh,
                            ...items,
                          }))}
                          onSelectChange={(value, options) =>
                            onClickChanDoan(value, options, 'chandoanravien', 'idicD10RAVIEN')
                          }
                          debounceSearch={debounceDropDown}
                        />
                        <CustomInput
                          label="Chẩn đoán ra viện:"
                          name="chandoanravien"
                          formik={formik}
                          checkUpdate={checkUpdate}
                        />
                        <CustomInput
                          label="Điều trị ngoại trú:"
                          name="dieutringoaitru"
                          formik={formik}
                          checkUpdate={checkUpdate}
                        />
                        <CustomInput
                          label="Diễn biến lâm sàng:"
                          name="dienbienlamsang"
                          formik={formik}
                          checkUpdate={checkUpdate}
                        />
                        <CustomInput
                          label="Phương pháp điều trị:"
                          name="ppdieutri"
                          formik={formik}
                          checkUpdate={checkUpdate}
                        />
                        <CustomInput
                          label="Tình trạng lúc ra viện:"
                          name="ttlucravien"
                          formik={formik}
                          checkUpdate={checkUpdate}
                        />
                        <CustomInput
                          label="Hướng xử lý:"
                          name="huongxuly"
                          formik={formik}
                          checkUpdate={checkUpdate}
                        />
                      </>
                    )}
                    <div className="flex justify-center mt-1">
                      <button
                        type="button"
                        onClick={toggleExpand}
                        className="cursor-pointer flex justify-between text-blue-500 px-1 py-1 rounded-md  tracking-wider    duration-500  w-[100px]"
                      >
                        {isExpanded ? (
                          <>
                            Thu gọn
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-6 animate-bounce"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m4.5 15.75 7.5-7.5 7.5 7.5"
                              />
                            </svg>
                          </>
                        ) : (
                          <>
                            Mở rộng
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-6 animate-bounce"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m19.5 8.25-7.5 7.5-7.5-7.5"
                              />
                            </svg>
                          </>
                        )}
                      </button>
                    </div>
                  </>
                ),
              },
              {
                label: (
                  <>
                    <p
                      className={`w-[4.5rem] text-start  ${!formik.errors.para ? '' : '  text-red-500 font-semibold'}`}
                    >
                      {['Sản lớn', 'Sản nhỏ', 'Phụ khoa', 'Nhũ hoa'][
                        formikPhuSan.values.idloaisan - 1
                      ] || ''}

                      <span className={`text-red-500  ${!formik.errors.para ? ' hidden ' : ''}`}>
                        (*)
                      </span>
                    </p>
                  </>
                ),
                key: 2,
                children: (
                  <>
                    <CustomInput
                      label="Para:"
                      name="para"
                      formik={formik}
                      checkUpdate={checkUpdate}
                    />
                    <div
                      className={
                        formikPhuSan.values.idloaisan === 1 || formikPhuSan.values.idloaisan === 2
                          ? 'hidden'
                          : 'flex gap-1 mt-1'
                      }
                    >
                      <label className="font-semibold w-44 text-end">Chu kỳ kinh:</label>
                      <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.chukikinh}
                        name="chukikinh"
                        size="small"
                      />
                    </div>
                    <div
                      className={
                        formikPhuSan.values.idloaisan === 1 || formikPhuSan.values.idloaisan === 2
                          ? 'hidden'
                          : 'flex gap-1 mt-1'
                      }
                    >
                      <label className="font-semibold w-44 text-end">Ngừa thai:</label>
                      <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.nguathai}
                        name="nguathai"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Kinh chót:</label>
                      <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.kinhchot}
                        name="kinhchot"
                        size="small"
                      />
                    </div>
                    <div
                      className={
                        formikPhuSan.values.idloaisan === 1 || formikPhuSan.values.idloaisan === 2
                          ? 'hidden'
                          : 'flex gap-1 mt-1'
                      }
                    >
                      <label className="font-semibold w-44 text-end">Kinh áp chót:</label>
                      <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.kinhapchot}
                        name="kinhapchot"
                        size="small"
                      />
                    </div>
                    {![4].includes(formikPhuSan.values.idloaisan) && ( // nếu là phụ khoa thì hiện
                      <div className="flex gap-1 mt-1">
                        <label className="font-semibold w-[155px] text-end">Tình trạng:</label>
                        <Radio.Group value={isStatus} onChange={(e) => setIsStatus(e.target.value)}>
                          <Radio value={true}>Có thai</Radio>
                          <Radio value={false}>không có thai</Radio>
                        </Radio.Group>
                      </div>
                    )}

                    <div
                     className={formikPhuSan.values.idloaisan === 4 || !isStatus? 'hidden' : 'flex gap-1 mt-1'}
                    >
                      <label className="font-semibold w-44 text-end">Dự sanh lúc đầu:</label>
                      <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.dusanhkc}
                        name="dusanhkc"
                        size="small"
                      />
                    </div>
                    <div
                     className={formikPhuSan.values.idloaisan === 4 || !isStatus ? 'hidden' : 'flex gap-1 mt-1'}
                    >
                      <div className="w-1/2 flex gap-2">
                        <label className="font-semibold w-52 text-end">Dự sanh:</label>
                        <DatePicker
                          placeholder=" "
                          onChange={handleDatePickerDuSanh}
                          allowClear={false}
                          value={formik.values.dusanh && dayjs(formik.values.dusanh)}
                          format={'DD/MM/YYYY'}
                          disabled={checkUpdate}
                          size="small"
                          className="w-full"
                        />
                      </div>
                      <Input
                        onChange={formik.handleChange}
                        name="ngaysanh"
                        value={formik.values.ngaysanh}
                        disabled={checkUpdate}
                        className="w-1/2 text-red-500 font-semibold"
                        style={checkUpdate && { color: 'black' }}
                        size="small"
                      />

                      {/* <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.dusanh}
                        name="dusanh"
                        size="small"
                      /> */}
                    </div>
                    <div
                     className={formikPhuSan.values.idloaisan === 4 || !isStatus ? 'hidden' : 'flex gap-1 mt-1'}
                    >
                      <label className="font-semibold w-44 text-end">Tuổi thai:</label>
                      <Input
                        disabled={checkUpdate}
                        className="text-red-500 font-semibold"
                        onChange={formik.handleChange}
                        value={formik.values.tuoithai}
                        name="tuoithai"
                        size="small"
                      />
                    </div>
                    <div
                      className={formikPhuSan.values.idloaisan === 4 || !isStatus ? 'hidden' : 'flex gap-1 mt-1'}
                    >
                      <label className="font-semibold w-[12.5%] text-end"><span className="text-red-500">(*)</span>Lúc thai:</label>
                      <InputNumber
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        min={0}
                        max={100}
                        step={1}
                        onChange={handleChangeLucThai('tuanthai')}
                        value={formik.values.tuanthai}
                        name="tuanthai"
                        size="small"
                        className="w-1/5"
                      />
                      <span className="mr-16">Tuần</span>
                      <InputNumber
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        min={0}
                        max={100}
                        step={1}
                        onChange={handleChangeLucThai('ngaythai')}
                        value={formik.values.ngaythai}
                        name="ngaythai"
                        size="small"
                        className="w-1/5"
                      />
                      <span>Ngày</span>
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Tổng trạng:</label>
                      <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.tongtrang}
                        name="tongtrang"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Tim phổi:</label>
                      <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.timphoi}
                        name="timphoi"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Da niêm:</label>
                      <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.daniem}
                        name="daniem"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Bụng:</label>
                      <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.bung}
                        name="bung"
                        size="small"
                      />
                    </div>
                    <div className="flex gap-1 mt-1">
                      <label className="font-semibold w-44 text-end">Phù:</label>
                      <Input
                        disabled={checkUpdate}
                        style={{ color: 'black' }}
                        onChange={formik.handleChange}
                        value={formik.values.phu}
                        name="phu"
                        size="small"
                      />
                    </div>
                    <Divider
                      plain
                      style={{ margin: 5, marginTop: 15 }}
                      className="mt-10 cursor-pointer"
                      onClick={() => {
                        if (isFormKham) setIsFormKham(false)
                        else setIsFormKham(true)
                      }}
                    >
                      <p className="font-semibold">
                        {formikPhuSan.values.idloaisan === 1
                          ? 'Khám sản lớn '
                          : formikPhuSan.values.idloaisan === 2
                            ? 'Khám sản nhỏ '
                            : formikPhuSan.values.idloaisan === 3
                              ? 'Khám phụ khoa '
                              : formikPhuSan.values.idloaisan === 4
                                ? 'Khám nhũ hoa '
                                : ''}
                        {isFormKham ? <UpOutlined /> : <DownOutlined />}
                      </p>
                    </Divider>
                    {isFormKham ? (
                      formikPhuSan.values.idloaisan === 1 ? (
                        <>
                          <FormSanLon formikPhuSan={formikPhuSan} />
                        </>
                      ) : formikPhuSan.values.idloaisan === 2 ||
                        formikPhuSan.values.idloaisan === 3 ? (
                        <>
                          <FormSanNhoPhuKhoa formikPhuSan={formikPhuSan} />
                        </>
                      ) : formikPhuSan.values.idloaisan === 4 ? (
                        <>
                          <FormNhuHoa formikPhuSan={formikPhuSan} />
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                  </>
                ),
              },
            ]}
          />
          {!checkBox && (
            <div className="mb-2">
              <Divider plain style={{ margin: 5 }}>
                <p className="font-semibold">Kê toa thuốc</p>
              </Divider>

              <ToaThuoc />
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

export default FormCD
