import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Table, ConfigProvider, Button, DatePicker, Select, TreeSelect, Modal } from 'antd'
import { SyncOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
import { khoDieuChinhService } from '../../../../../services/khodieuchinh/khoDieuChinhService'
import { branchService } from '../../../../../services/branch/branchService'
import { companyService } from '../../../../../services/Company/companyService'
import { putCheckQuyenThaoTacAction } from '../../../../../store/actions/phanQuyenAction'
import { getListNameChiNhanh } from '../../../../../utils/algorithm'
import { addLog } from '../../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../../utils/log/logConstant'
import DrawerInfoProducs from './DrawerInfoProducs'
import { deletePhieuDieuChinhTon } from '../../../../../store/actions/khodieuchinhAction'
import { includes } from 'lodash'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect
const { confirm } = Modal
function ListPhieu({ keyTabs }) {
  const dispatch = useDispatch()
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [keyword, setKeyword] = useState(null)
  const [isModal, setIsModal] = useState(false)
  const [listPhieuXuat, setListPhieuXuat] = useState([])
  const [infoPhieuXuat, setInfoPhieuXuat] = useState(null)
  const [listVTHHCuaPhieuXuat, setListVTHHCuaPhieuXuat] = useState([])
  const [listCongTy, setListCongTy] = useState([])
  const [idCongTy, setIdCongTy] = useState(ID_COMPANY)
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [value, setValue] = useState([ID_BRANCH])

  const maQuyenXemDs = 'QHT226'
  const maQuyenXemCt = 'QHT227'

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Bạn có chắc muốn xoá phiếu điều chỉnh ?',
      icon: <ExclamationCircleFilled />,
      content: `Phiếu điều chỉnh muốn xoá là: ${record.maphieu}`,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        dispatch(deletePhieuDieuChinhTon(record.idnhapxuat, onLoad))
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  }
  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        getListPhieuXuat()
        break
      case maQuyenXemCt:
        setIsModal(true)
        getInfoPhieuXuatById(infoNew?.idnhapxuat)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXemCt:
        setIsModal(false)
        break
      default:
        return false
    }
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: listChiNhanh
        // ?.filter((item) => !idCongTy || item?.idct === idCongTy)
        ?.map((item, index) => ({
          title: item?.tenChiNhanh,
          value: item?.idChiNhanh,
          key: `${item?.idChiNhanh}`,
        })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  useEffect(() => {
    ;(async () => {
      try {
        const { data: arrCongTy } = await companyService.getAllListCompany()
        setListCongTy(arrCongTy)
        getListChiNhanhByIdCty(idCongTy)
        // getListKhoCnByIdCn(idChiNhanh)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  const onLoad = () => {
    setKeyword((prev) => (prev?.length ? prev?.trim() : prev))
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  const getListPhieuXuat = async () => {
    try {
      setIsLoading(true)
      const { data } = await khoDieuChinhService.getListPhieuXuat(
        keyword?.trim()?.length ? keyword?.trim() : null,
        idCongTy,
        value?.includes('all')
          ? listChiNhanh
              ?.map((item) => `idcn=${item?.idChiNhanh}`)
              ?.join('&')
              ?.toString()
          : value
              ?.map((item) => `idcn=${item}`)
              ?.join('&')
              ?.toString(),
        dateForm,
        dateTo,
      )
      setListPhieuXuat(data)
      const nameCompany = listCongTy?.find((item) => item?.idct === idCongTy)?.tenct
      const nameChiNhanh = getListNameChiNhanh(value, listChiNhanh)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_DS_PHIEU_DIEU_CHINH_TON_KHO,
        true,
        MENU_CONSTANTS.DIEU_CHINH_TON_KHO,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          keyword: keyword ?? '',
          nameCompany,
          nameChiNhanh,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DIEU_CHINH_TON_KHO,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (listCongTy.length && listChiNhanh.length) {
      onLoad()
    }
  }, [dateForm, dateTo, keyword, idCongTy, value, listCongTy, listChiNhanh])

  const getInfoPhieuXuatById = async (idPhieuXuat) => {
    try {
      setIsLoading(true)

      const { data: info } = await khoDieuChinhService.getInfoPhieuXuat(idPhieuXuat)
      const { data: list } = await khoDieuChinhService.getListVTHHCuaPhieuXuat(idPhieuXuat)
      setInfoPhieuXuat(info)
      setListVTHHCuaPhieuXuat(
        list?.map((item) => ({
          ...item,
          ton: item?.soLuong - item?.chenhlech,
        })),
      )
      // setListPhieuXuat(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_CHI_TIET_PHIEU_DIEU_CHINH_TON_KHO,
        true,
        MENU_CONSTANTS.DIEU_CHINH_TON_KHO,
        null,
        {
          maphieu: info.maphieu,
        },
      )
    } catch (error) {
      console.log(error)
      setInfoPhieuXuat(null)
      setListVTHHCuaPhieuXuat([])
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DIEU_CHINH_TON_KHO,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }
  const getListChiNhanhByIdCty = async (idct) => {
    try {
      setIsLoading(true)
      const { data: arrChiNhanh } = await branchService.getListBranch(idct)
      setListChiNhanh(arrChiNhanh)
    } catch (error) {
      console.log(error)
      setListChiNhanh([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (keyTabs === 2 && listCongTy.length && listChiNhanh.length) {
      onLoad()
    }
  }, [keyTabs, listCongTy, listChiNhanh])

  return (
    <>
      <div className="flex justify-start items-center mb-2 gap-2">
        <div className="flex justify-start items-center w-[17rem] gap-1">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-2xl flex items-center">-</span>
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        <Select
          disabled={isLoading}
          className="w-[17rem]"
          options={listCongTy?.map((item) => ({
            key: `${item?.mact}${item?.idct}`,
            value: item?.idct,
            label: item?.tenct,
          }))}
          value={idCongTy}
          onChange={(value) => {
            setIdCongTy(value)
            setValue(['all'])
            // setIdChiNhanh(0)
            getListChiNhanhByIdCty(value)
          }}
        />
        <TreeSelect
          {...tProps}
          style={{ flexWrap: 'unset' }}
          className="w-[17rem] h-8 overflow-clip"
          size="medium"
        />
        <Input
          placeholder="Tìm kiếm theo mã phiếu"
          className="w-[17rem]"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Button
          onClick={onLoad}
          loading={isLoading}
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
        ></Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          scroll={{ y: 720, x: 1000 }}
          pagination={false}
          loading={isLoading}
          bordered
          dataSource={listPhieuXuat}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 'stt',
              width: 60,
              align: 'center',
              fixed: 'left',
              render: (text, record, index) => ++index,
            },
            {
              title: 'Mã phiếu',
              dataIndex: 'maphieu',
              key: 'maphieu',
              width: 130,
              fixed: 'left',
            },
            {
              title: 'Ngày',
              dataIndex: 'ngaytao',
              key: 'ngaytao',
              width: 140,
              fixed: 'left',
              render: (text, record, index) =>
                text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-',
            },
            {
              title: 'Tên chi nhánh',
              dataIndex: 'tenchinhanh',
              key: 'tenchinhanh',
              width: 300,
            },
            {
              title: 'Kho hàng',
              dataIndex: 'tenkho',
              key: 'tenkho',
              width: 200,
            },
            {
              title: 'Người tạo',
              dataIndex: 'tennguoitao',
              key: 'tennguoitao',
              width: 200,
            },
            {
              title: 'Ghi chú',
              dataIndex: 'ghichu',
              key: 'ghichu',
            },
            {
              title: 'Thao tác',
              dataIndex: 'action',
              key: 'action',
              width: 90,
              align: 'center',
              fixed: 'right',
              render: (text, record, index) => (
                <>
                  <ul className="flex items-center justify-around">
                    <li
                      className="text-green-700 bg-green-100 rounded cursor-pointer hover:bg-green-700 p-[2px] duration-200 hover:text-white"
                      onClick={() => {
                        dispatch(
                          putCheckQuyenThaoTacAction(
                            maQuyenXemCt,
                            { idnhapxuat: record?.idnhapxuat },
                            actionIsTrue,
                            actionIsFalse,
                          ),
                        )
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                    </li>
                    <li // chỉ nhóm IT và admin và những người tạo phiếu mới có quyền xoá
                      className={`${![28, 27, 1].includes(infoUser?.idNhom) && infoUser?.idnv !== record.nguoitao ? 'text-gray-500' : 'text-red-700 hover:bg-red-700 hover:text-white'}  bg-red-100 rounded cursor-pointer  duration-200 `}
                    >
                      <button
                        disabled={
                          infoUser?.idnv !== record.nguoitao &&
                          ![28, 27, 1].includes(infoUser?.idNhom)
                        }
                        onClick={() => showDeleteConfirm(record)}
                        className="p-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </button>
                    </li>
                  </ul>
                </>
              ),
            },
          ]}
        />
      </ConfigProvider>
      <i className="text-red-500 bg-red-100 p-1 rounded-md">
        * Chỉ người tạo mới có thể xoá phiếu và IT hệ thống
      </i>
      <DrawerInfoProducs
        getInfoPhieuXuatById={getInfoPhieuXuatById}
        infoPhieuXuat={infoPhieuXuat}
        isModal={isModal}
        setIsModal={setIsModal}
        listVTHHCuaPhieuXuat={listVTHHCuaPhieuXuat}
        isLoading={isLoading}
      />
    </>
  )
}

export default ListPhieu
