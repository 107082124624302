import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Input,
  Table,
  ConfigProvider,
  Button,
  DatePicker,
  Checkbox,
  Drawer,
  Select,
  TreeSelect,
} from 'antd'
import { FileTextOutlined, SyncOutlined } from '@ant-design/icons'
// import { xuatSuDungService } from '../../../../services/xuatSuDung/xuatSuDungService'
import { xuatTraNCCService } from '../../../../services/xuatTraNCC/xuatTraNCCService'
import { companyService } from '../../../../services/Company/companyService'
import { branchService } from '../../../../services/branch/branchService'
import { chuyenKhoService } from '../../../../services/ChuyenKho/chuyenKhoService'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/vi'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
import { getListNameChiNhanh } from '../../../../utils/algorithm'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect
function ListPhieu({ keyTabs }) {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [keyword, setKeyword] = useState(null)
  const [isModal, setIsModal] = useState(false)
  const [listPhieuXuat, setListPhieuXuat] = useState([])
  const [infoPhieuXuat, setInfoPhieuXuat] = useState(null)
  const [listVTHHCuaPhieuXuat, setListVTHHCuaPhieuXuat] = useState([])
  const [listCongTy, setListCongTy] = useState([])
  const [idCongTy, setIdCongTy] = useState(ID_COMPANY)
  const [listChiNhanh, setListChiNhanh] = useState([])
  const [value, setValue] = useState([ID_BRANCH])
  const [listKhoCn, setListKhoCn] = useState([])
  const [idKhoCn, setIdKhoCn] = useState(0)

  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: listChiNhanh
        // ?.filter((item) => !idCongTy || item?.idct === idCongTy)
        ?.map((item, index) => ({
          title: item?.tenChiNhanh,
          value: item?.idChiNhanh,
          key: `${item?.idChiNhanh}`,
        })),

      // ?.filter((item) => item?.loaiChiNhanh === 'CHINHANH')
      // ?.filter((item) => (company ? item?.idct === company : item)) // dư
      // ?.filter((item) => (filterCK ? item?.idChuyenKhoa === filterCK : item))
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const handleOpenChiTiet = async (record) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT295',
      )

      setIsModal(true)
      getInfoPhieuXuatById(record?.idxtncc)
    } catch (error) {
      setIsModal(false)
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const getListPhieuXuat = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT294',
      )
      const { data } = await xuatTraNCCService.getListPhieuXuat(
        keyword?.trim()?.length ? keyword?.trim() : null,
        idCongTy,
        value?.includes('all')
          ? listChiNhanh
              ?.map((item) => `idcn=${item?.idChiNhanh}`)
              ?.join('&')
              ?.toString()
          : value
              ?.map((item) => `idcn=${item}`)
              ?.join('&')
              ?.toString(),
        dateForm,
        dateTo,
      )
      setListPhieuXuat(data)
      const nameCompany = listCongTy?.find((item) => item?.idct === idCongTy)?.tenct
      const nameChiNhanh = getListNameChiNhanh(value, listChiNhanh)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_DS_PHIEU_XUAT_TRA_NCC,
        true,
        MENU_CONSTANTS.XUATTRANCC,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          keyword: keyword ?? '',
          nameCompany,
          nameChiNhanh,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.XUATTRANCC,
        detailErrorPayload,
      )
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setIsLoading(false)
    }
  }
  const getInfoPhieuXuatById = async (idPhieuXuat) => {
    try {
      setIsLoading(true)
      const { data: info } = await xuatTraNCCService.getInfoPhieuXuat(idPhieuXuat)
      const { data: list } = await xuatTraNCCService.getListVTHHCuaPhieuXuat(idPhieuXuat)
      setInfoPhieuXuat(info)
      setListVTHHCuaPhieuXuat(list)
      // setListPhieuXuat(data)
      console.log('info', info)
      const detailForLog = {
        maphieu: info?.maphieutra,
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_CHI_TIET_PHIEU_XUAT_TRA_NCC,
        true,
        MENU_CONSTANTS.XUATTRANCC,
        null,
        detailForLog,
      )
    } catch (error) {
      console.log(error)
      setInfoPhieuXuat(null)
      setListVTHHCuaPhieuXuat([])
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.XUATTRANCC,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }
  const getListChiNhanhByIdCty = async (idct) => {
    try {
      setIsLoading(true)
      const { data: arrChiNhanh } = await branchService.getListBranch(idct)
      setListChiNhanh(arrChiNhanh)
    } catch (error) {
      console.log(error)
      setListChiNhanh([])
    } finally {
      setIsLoading(false)
    }
  }
  const getListKhoCnByIdCn = async (idcn) => {
    try {
      setIsLoading(true)
      const { data: arrKhoCn } = await chuyenKhoService.getlistKhoNhan(idcn)
      setListKhoCn(arrKhoCn)
    } catch (error) {
      console.log(error)
      setListKhoCn([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        const { data: arrCongTy } = await companyService.getAllListCompany()
        setListCongTy(arrCongTy)
        getListChiNhanhByIdCty(idCongTy)
        // getListKhoCnByIdCn(idChiNhanh)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  useEffect(() => {
    if (keyTabs === 2) {
      getListPhieuXuat()
    }
  }, [keyTabs])
  const formatDataNull = (text) => {
    return `${text ?? ''}`?.replaceAll('null', '')
  }
  return (
    <div>
      <Drawer
        placement="right"
        width={1366}
        open={isModal}
        onClose={() => setIsModal(false)}
        title={'Chi tiết phiếu xuất trả nhà cung cấp'}
      >
        <div className="grid grid-flow-row items-center gap-2">
          <div className="grid grid-cols-2 items-center gap-1">
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="font-semibold text-end">Đối tác:</label>
              <Input
                className="col-span-5"
                size="small"
                readOnly
                variant="filled"
                value={formatDataNull(infoPhieuXuat?.tendoitac)}
              />
            </div>
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Mã đối tác:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={formatDataNull(infoPhieuXuat?.madoitac)}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Mã phiếu:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={infoPhieuXuat?.maphieutra}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 items-center gap-1">
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Tên viết tắt:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={formatDataNull(infoPhieuXuat?.tenviettat)}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Số điện thoại:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={formatDataNull(infoPhieuXuat?.dienthoai)}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Mã Số thuế:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={formatDataNull(infoPhieuXuat?.masothue)}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Ngày xuất:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={
                    infoPhieuXuat?.ngaytra &&
                    moment(infoPhieuXuat?.ngaytra).format('DD/MM/YYYY HH:mm:ss')
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 items-center gap-1">
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="font-semibold text-end">Địa chỉ:</label>
              <Input
                className="col-span-5"
                size="small"
                readOnly
                variant="filled"
                value={formatDataNull(infoPhieuXuat?.diachi)}
              />
            </div>
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Phương thức:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={infoPhieuXuat?.phuongthuc}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Hình thức:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={infoPhieuXuat?.hinhthucthanhtoan}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 items-center gap-1">
            <div className="grid grid-cols-6 items-center gap-1">
              <label className="font-semibold text-end">Ghi chú:</label>
              <Input
                className="col-span-5"
                size="small"
                readOnly
                variant="filled"
                value={formatDataNull(infoPhieuXuat?.ghichu)}
              />
            </div>
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Số hóa đơn:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={infoPhieuXuat?.hoadon}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Ngày hóa đơn:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={
                    infoPhieuXuat?.ngayhoadon &&
                    moment(infoPhieuXuat?.ngayhoadon).format('DD/MM/YYYY')
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 items-center gap-1">
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Công ty:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={infoPhieuXuat?.tenct}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Chi nhánh:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={infoPhieuXuat?.tenchinhanh}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 items-center gap-1">
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Kho xuất:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={infoPhieuXuat?.tenkho}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-1">
                <label className="font-semibold text-end">Người xuất:</label>
                <Input
                  className="col-span-2"
                  size="small"
                  readOnly
                  variant="filled"
                  value={infoPhieuXuat?.tennguoitra}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-1">
            <div className="col-span-3"></div>
            <div className="grid grid-cols-3 items-center gap-1">
              <label className="font-semibold text-end">Có hóa đơn:</label>
              <Checkbox checked={infoPhieuXuat?.hoadondientu} />
            </div>
          </div>

          <div>
            <ul className="flex flex-wrap gap-2">
              {infoPhieuXuat?.fileketqua?.split(';')?.map((url, index) => {
                return (
                  url?.length && (
                    <li
                      key={`hinh${index}:${url}`}
                      className="w-[8.1rem] h-[8.1rem] overflow-hidden border rounded-md relative"
                    >
                      {/* <DeleteOutlined
                    // onClick={() => deleteFileById(items.id)}
                    className="absolute right-2 top-2 hover:bg-red-500 z-20 hover:text-white text-red-500 bg-red-200 rounded-md p-1"
                    style={{ fontSize: 18 }}
                  /> */}
                      <a href={url} target="_blank">
                        <div className="bg-black hover:bg-opacity-50 duration-200 bg-opacity-0  absolute w-full h-full">
                          <div className="relative "></div>
                          {/* <p className="absolute truncate text-black bottom-0 w-full p-1 bg-white left-0 z-10">
                            {`${infoPhieuXuat?.maphieu}-Hinh${++index}`}
                          </p> */}
                        </div>
                        <img src={url} className="w-full" alt={url} />
                      </a>
                    </li>
                  )
                )
              })}
            </ul>
          </div>
          <div className="w-[82.4rem]">
            <ConfigProvider
              theme={{
                token: { padding: 2, fontSize: 12 },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                scroll={{ x: 1400, y: 350 }}
                pagination={false}
                loading={isLoading}
                bordered
                dataSource={listVTHHCuaPhieuXuat}
                columns={[
                  {
                    key: 1,
                    title: 'Thông tin hàng',
                    children: [
                      {
                        key: 1.1,
                        title: 'STT',
                        dataIndex: 'stt',
                        width: 35,
                        fixed: true,
                        align: 'center',
                      },
                      {
                        key: 1.2,
                        title: 'Tên hàng',
                        dataIndex: 'tenthuoc',
                        fixed: true,
                      },
                      {
                        key: 1.3,
                        title: 'Mã hàng',
                        dataIndex: 'mathuoc',
                        width: 90,
                        fixed: true,
                      },
                    ],
                  },
                  {
                    key: 2,
                    title: 'Đơn vị chẵn',
                    children: [
                      {
                        key: 2.1,
                        title: 'SL',
                        dataIndex: 'soluongchan',
                        width: 40,
                        align: 'center',
                        editable: true,
                      },
                      {
                        key: 2.2,
                        title: 'Đơn vị',
                        dataIndex: 'donvichan',
                        align: 'center',
                        width: 50,
                      },
                      {
                        key: 2.3,
                        title: 'Đơn giá',
                        dataIndex: 'dongiachan',
                        align: 'right',
                        width: 75,
                        render: (text, record, index) => formattedNumber(text ?? 0),
                      },
                    ],
                  },
                  {
                    key: 3,
                    title: 'Đơn vị lẻ',
                    children: [
                      {
                        key: 3.1,
                        title: 'Quy cách',
                        dataIndex: 'quycachdonggoi',
                        width: 60,
                        align: 'center',
                      },
                      {
                        key: 3.2,
                        title: 'SL',
                        dataIndex: 'soluongle',
                        width: 40,
                        align: 'center',
                      },
                      {
                        key: 3.3,
                        title: 'Đơn vị',
                        dataIndex: 'donvile',
                        align: 'center',
                        width: 50,
                      },
                      {
                        key: 3.4,
                        title: 'Đơn giá',
                        dataIndex: 'dongiale',
                        align: 'right',
                        width: 75,
                        render: (text, record, index) => formattedNumber(text ?? 0),
                      },
                    ],
                  },
                  {
                    key: 4,
                    title: <p className="text-center">Thành tiền</p>,
                    children: [
                      {
                        key: 4.1,
                        title: <p className="text-center">Thành tiền</p>,
                        dataIndex: 'thanhtien',
                        width: 75,
                        align: 'right',
                        render: (text, record, index) => formattedNumber(text ?? 0),
                      },
                    ],
                  },
                  {
                    key: 5,
                    title: 'Chi tiết',
                    children: [
                      {
                        key: 5.1,
                        title: 'Số lô',
                        dataIndex: 'solo',
                        width: 85,
                        align: 'center',
                      },
                      {
                        key: 5.2,
                        title: 'Hạn dùng',
                        dataIndex: 'handung',
                        align: 'center',
                        width: 75,
                        render: (text, record, index) =>
                          text ? moment(text).format('DD/MM/YYYY') : '-',
                      },
                      {
                        key: 5.3,
                        title: 'Số hóa đơn',
                        dataIndex: 'sohoadon',
                        align: 'center',
                        width: 85,
                      },
                      {
                        key: 5.4,
                        title: 'Mã Số DK',
                        dataIndex: 'masodangky',
                        align: 'center',
                        width: 85,
                      },
                      {
                        key: 5.5,
                        title: 'Ghi chú',
                        dataIndex: 'ghichu',
                        align: 'left',
                        width: 150,
                      },
                    ],
                  },
                  // {
                  //   key: 6,
                  //   title: '',
                  //   dataIndex: 'ACTION',
                  //   width: 40,
                  //   align: 'center',
                  //   fixed: 'right',
                  //   render: (text, record, index) => (
                  //     <>
                  //       <CloseOutlined
                  //         className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
                  //         onClick={() =>
                  //           setListXuatVTHH((prev) =>
                  //             prev?.filter((item) => item?.key !== record?.key),
                  //           )
                  //         }
                  //       />
                  //     </>
                  //   ),
                  // },
                ]}
                footer={() => (
                  <div className="w-full  flex justify-end text-sm">
                    <ul className="flex flex-col gap-2 mt-2 mr-5">
                      <li className="flex w-60 justify-between">
                        <label>Tổng tiền:</label>
                        <p>
                          {formattedNumber(infoPhieuXuat?.thanhtien ?? 0)}
                          <span> VNĐ</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                )}
              />
            </ConfigProvider>
          </div>
        </div>
      </Drawer>
      <div className="flex justify-start items-center mb-2 gap-2">
        <div className="flex justify-start items-center w-[17rem] gap-1">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('form')}
            format={dateFormat}
          />
          <span className="font-semibold text-2xl flex items-center">-</span>
          <DatePicker
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={handleDatePicker('to')}
            format={dateFormat}
          />
        </div>
        {infoUser?.idNhom !== 3 && (
          <>
            <Select
              disabled={isLoading}
              className="w-[17rem]"
              options={listCongTy?.map((item) => ({
                key: `${item?.mact}${item?.idct}`,
                value: item?.idct,
                label: item?.tenct,
              }))}
              value={idCongTy}
              onChange={(value) => {
                setIdCongTy(value)
                setValue(['all'])
                // setIdChiNhanh(0)
                getListChiNhanhByIdCty(value)
              }}
            />
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-[17rem] h-8 overflow-clip"
              size="medium"
            />
            {/* <Select
              disabled={isLoading}
              className="w-[17rem]"
              options={[
                {
                  key: `chinhanh0`,
                  value: 0,
                  label: 'Tất cả chi nhánh',
                },
                ...listChiNhanh?.map((item) => ({
                  key: `chinhanh${item?.idChiNhanh}`,
                  value: item?.idChiNhanh,
                  label: item?.tenChiNhanh,
                })),
              ]}
              value={idChiNhanh}
              onChange={(value) => {
                setIdChiNhanh(value)
                setIdKhoCn(0)
                getListKhoCnByIdCn(value)
              }}
            /> */}
          </>
        )}
        {/* <Select
          disabled={isLoading}
          className="w-[17rem]"
          options={[
            {
              key: `khocn0`,
              value: 0,
              label: 'Tất cả các kho',
            },
            ...listKhoCn?.map((item) => ({
              key: `khocn${item?.idKhoCN}`,
              value: item?.idKhoCN,
              label: item?.tenKho,
            })),
          ]}
          value={idKhoCn}
          onChange={(value) => setIdKhoCn(value)}
        /> */}
        <Input
          placeholder="Tìm mã phiếu, thông tin đối tác..."
          className="w-[17rem]"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Button
          onClick={() => {
            setKeyword((prev) => (prev?.length ? prev?.trim() : prev))
            getListPhieuXuat()
          }}
          loading={isLoading}
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
        ></Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          scroll={{ x: 2000, y: 720 }}
          pagination={false}
          loading={isLoading}
          bordered
          dataSource={listPhieuXuat}
          columns={[
            {
              title: 'STT',
              dataIndex: 'stt',
              key: 'stt',
              width: 45,
              align: 'center',
              fixed: 'left',
              render: (text, record, index) => ++index,
            },
            {
              title: 'Ngày xuất',
              dataIndex: 'ngaytra',
              key: 'ngaytra',
              width: 140,
              align: 'center',
              fixed: 'left',
              render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
            },
            {
              title: 'Mã phiếu',
              dataIndex: 'maphieutra',
              key: 'maphieutra',
              width: 140,
              fixed: 'left',
            },
            {
              title: 'Chi nhánh xuất',
              dataIndex: 'tenchinhanh',
              key: 'tenchinhanh',
              width: 250,
            },
            {
              title: 'Kho xuất',
              dataIndex: 'tenkho',
              key: 'tenkho',
              width: 220,
            },
            {
              title: 'Người xuất',
              dataIndex: 'tennguoitra',
              key: 'tennguoitra',
              width: 220,
            },
            {
              title: 'Mã đối tác',
              dataIndex: 'madoitac',
              key: 'madoitac',
              width: 140,
            },
            {
              title: 'Tên đối tác',
              dataIndex: 'tendoitac',
              key: 'tendoitac',
            },
            // {
            //   title: 'Người sửa',
            //   dataIndex: 'tennguoisua',
            //   key: 'tennguoisua',
            //   width: 220,
            // },
            // {
            //   title: 'Ngày sửa',
            //   dataIndex: 'ngaysua',
            //   key: 'ngaysua',
            //   width: 140,
            //   align: 'center',
            //   render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
            // },
            {
              title: 'Thành tiền',
              dataIndex: 'thanhtien',
              key: 'thanhtien',
              width: 100,
              align: 'right',
              fixed: 'right',
              render: (text) => formattedNumber(text ?? 0),
            },
            {
              title: '',
              dataIndex: 'action',
              key: 'action',
              width: 40,
              align: 'center',
              fixed: 'right',
              render: (text, record, index) => (
                <>
                  <FileTextOutlined
                    className="text-lg text-blue-500 cursor-pointer flex justify-center items-center"
                    onClick={() => handleOpenChiTiet(record)}
                  />
                </>
              ),
            },
          ]}
        />
      </ConfigProvider>
    </div>
  )
}

export default ListPhieu
