import { SearchOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Divider, Input, Space, Table } from 'antd'
import { EditorState, convertFromRaw } from 'draft-js'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Highlighter from 'react-highlight-words'
import { useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import Images from './Images'
import style from './style.module.css'
import 'moment/locale/vi'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
moment.locale('vi')

function SieuAmBN(props) {
  const { PkDangNhap } = useSelector((state) => state.branchReducer)

  const { infoUser } = useSelector((state) => state.userReducer)
  const { infoBN } = useSelector((state) => state.traCuuReducer)
  const [loading, setLoading] = useState(false)
  const [lichSuSA, setLichSuSA] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [activeRow, setActiveRow] = useState(null)
  const [ketluan, setKetLuan] = useState('')
  const [listImage, setListImage] = useState('')

  useEffect(() => {
    ;(async () => {
      if (infoBN?.idbn) {
        setLoading(true)
        //reset
        setEditorState(EditorState.createEmpty())
        setKetLuan('')
        setListImage('')
        try {
          const { data } = await https.get(
            `BenhNhanSieuAm/GetBenhNhanSieuAmByIDBNCheckBacSi?idbn=${infoBN.idbn}&idChiNhanh=${PkDangNhap?.idChiNhanh}&idNV=${infoUser.idnv}`,
          )

          const detailForLog = {
            tenBenhNhan: infoBN.benhNhan.tenBenhNhan,
            tenChiNhanh: PkDangNhap.tenChiNhanh,
          }
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.GET,
            logAction.TRA_CUU_KET_QUA_SIEU_AM,
            true,
            MENU_CONSTANTS.TRACUU_BENH_NHAN,
            null,
            detailForLog,
          )
          if (data.length === 0) {
            //reset state
            setEditorState(EditorState.createEmpty())
            setKetLuan('')
            setListImage('')
          }
          setLichSuSA(data)
        } catch (error) {
          console.log(error)
          const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
          const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.GET,
            logAction.TRA_CUU_KET_QUA_SIEU_AM,
            false,
            MENU_CONSTANTS.TRACUU_BENH_NHAN,
            detailErrorPayload,
          )
        } finally {
          setLoading(false)
        }
      }
    })()
  }, [infoBN, PkDangNhap, infoUser])

  const columnsSieuAm = [
    {
      title: 'Ngày SA',
      dataIndex: 'ngaysieuam',
      key: 'ngaysieuam',
      align: 'center',
      className: 'text-sm',
      width: 150,
      // ...getColumnSearchProps('ngaysieuam'),
      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm') : ''),
    },
    {
      title: 'Loại siêu âm',
      dataIndex: 'loaisieuam',
      key: 'loaisieuam',
      align: 'left',
      className: 'text-sm',
      width: 300,
    },
    {
      title: 'BS chỉ định',
      dataIndex: 'bschidinh',
      key: 'bschidinh',
      align: 'left',
      className: 'text-sm',
      width: 250,
    },
    {
      title: 'BS siêu âm',
      dataIndex: 'bssieuam',
      key: 'bssieuam',
      align: 'left',
      className: 'text-sm',
      width: 250,
    },
    {
      title: 'Kết luận',
      dataIndex: 'ketluan',
      key: 'ketluan',
      align: 'left',
      className: 'text-sm',
    },
  ]
  const handleShowData = (record) => {
    setActiveRow(record)
    const convertJson = JSON.parse(record?.bieumau)
    const contentState = convertFromRaw(convertJson)
    setEditorState(EditorState.createWithContent(contentState))
    setKetLuan(record.ketluan)
    setListImage(record.linkhinhanh)
  }

  const rowClassName = (record) => {
    return record.idbnttc === activeRow?.idbnttc && record.idbnsa === activeRow?.idbnsa
      ? 'bg-cyan-300 bg-opacity-50'
      : 'cursor-pointer'
  }
  return (
    <div className="w-full h-full flex">
      <div className="w-1/2">
        <div className="mt-2">
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              scroll={{
                y: 300,
                x: 1500,
              }}
              bordered
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => {
                    handleShowData(record)
                  },
                }
              }}
              rowClassName={rowClassName}
              columns={columnsSieuAm}
              dataSource={lichSuSA}
              loading={loading}
            />
          </ConfigProvider>
        </div>
        <Divider />
        <div className="mt-2">
          <p className="text-sm ml-4 font-semibold">Hình ảnh: </p>
          <Images listImage={listImage} />
        </div>
      </div>
      <div className="w-1/2">
        <div className="flex flex-col justify-between h-full">
          <div>
            <p className="text-sm ml-4 font-semibold">Biểu mẫu: </p>
            <div className="p-4">
              <Editor
                toolbar={{
                  options: ['inline', 'blockType', 'fontFamily', 'textAlign', 'colorPicker'],
                  inline: {
                    options: ['bold', 'italic'],
                  },
                  blockType: {
                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                  },
                  fontFamily: {
                    options: ['Times New Roman'],
                  },
                }}
                editorState={editorState}
                toolbarClassName={style.toolbarClassName}
                wrapperClassName={style.wrapperClassName}
                editorClassName={style.editorClassName}
                readOnly={true} // Disable editing
                toolbarHidden={true} // Hide the toolbar
              />
            </div>
          </div>

          <div className="p-2 border-t">
            <h2 className="font-semibold">KẾT LUẬN</h2>
            <Input.TextArea
              style={{
                height: 80,
                resize: 'none',
              }}
              name="KETLUAN"
              value={ketluan}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

SieuAmBN.propTypes = {}

export default SieuAmBN
