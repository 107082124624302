import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { maternityClinicService } from '../../services/maternityClinic/maternityClinicService'
import { childrenClinicService } from '../../services/childrenClinic/childrenClinicService'
import { drugstoreService } from '../../services/drugstore/drugstoreService'
import { recieveService } from '../../services/receive/recieveService'
import { BNChoKhamSan } from '../actions/maternityClinicAction'
import { thuocKeToa } from '../actions/childrenClinicAction'
import moment from 'moment'
import { generatePhieuToaThuoc } from '../../utils/report/phieuToaThuoc'
import { generatePhieuChiDinh } from '../../utils/report/phieuChiDinh'
import { generatePhieuSanLon } from '../../utils/report/san/sanLon'
import { generatePhieuSanNho } from '../../utils/report/san/sanNho'
import { generatePhieuPhuKhoa } from '../../utils/report/san/phuKhoa'
import { generateNhuHoa } from '../../utils/report/san/nhuHoa'
// import { generatePhieuToaThuocTA } from '../../utils/report/phieuToaThuocTA'
import { cardService } from '../../services/card/cardService'
import 'moment/locale/vi'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
moment.locale('vi')

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* maternityClinicSaga() {
  // LẤY DANH SÁCH BỆNH NHÂN CHỜ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(typeAction.GET_BN_CHO_KHAM_BY_PK_SAN, function* BNPending({ type, idcnPk }) {
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    const { infoUser } = yield select((state) => state.userReducer)
    const { phongKham } = yield select((state) => state.receiveReducer)
    try {
      const tenPhongKham = phongKham?.find((pk) => pk.idcnpk === idcnPk)?.tenPhongKham || ''
      const result = yield call(() => maternityClinicService.getBNCho(idcnPk))
      yield put({
        type: typeAction.DISPATCH_BN_CHO_KHAM_SAN,
        payload: result.data,
      })
      yield call(
        // lưu tên bs khám của chi nhánh phòng khám hiện tại để hiện thị lên ds bn chờ khám của màn hình gọi số
        () =>
          infoUser?.tenNhom?.toLowerCase()?.includes('bác sĩ') &&
          childrenClinicService.putBsKhamCnPk({
            idcnpk: idcnPk,
            idbskham: infoUser?.idnv,
          }),
      )
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.XEM_DANH_SACH_BENH_NHAN_CHO_KHAM_THEO_PHONGKHAM,
        true,
        MENU_CONSTANTS.KHAMSAN,
        null,
        {
          phongkham: tenPhongKham,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.XEM_DANH_SACH_BENH_NHAN_CHO_KHAM_THEO_PHONGKHAM,
        true,
        MENU_CONSTANTS.KHAMNHI,
        null,
        detailErrorPayload,
      )
    }
  })
  // LẤY THÔNG TIN CHI TIẾT DANH SÁCH BỆNH NHÂN KHÁM BỆNH
  yield takeLatest(
    typeAction.GET_INFO_DETAIL_BN_KHAM_SAN,
    function* BNPending({ type, idbn, idbnttc }) {
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        // yield put({
        //   type: typeAction.OPEN_LOADING_PAGE,
        // })

        const infoDetailKhamSan = yield call(() =>
          maternityClinicService.getDetailBNKhamSan(idbnttc),
        )

        const result = yield call(() => recieveService.getInfoBN(idbn))
        yield put({
          type: typeAction.DISPATCH_DETAIL_LOAIKHAM_BN_KHAM_SAN,
          payload: infoDetailKhamSan.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DETAIL_BENH_NHAN_KHAM_SAN,
          true,
          MENU_CONSTANTS.KHAMSAN,
          null,
          {
            tenbenhnhan: result.data.tenBenhNhan,
          },
        )
        // const dataChiNhanh = yield select((state) => state.branchReducer)
        // const { PkDangNhap } = dataChiNhanh

        // const result = yield call(() => recieveService.getInfoBN(idbn))
        // const infoDetail = yield call(() => maternityClinicService.getDetailBNKham(idbnttc))
        // const infoDetailToaThuoc = yield call(() => drugstoreService.getServiceNhaThuoc(idbnttc))
        // const history = yield call(() => recieveService.getHitoryBN(idbn))

        // const infoTTV = yield call(() => cardService.getInfoMemberCard(idbn, PkDangNhap?.idChuyenKhoa))

        // yield put({
        //   type: typeAction.DISPATCH_MEMBER_CARD_KHAM,
        //   payload: infoTTV.data,
        // })

        // yield put({
        //   type: typeAction.DISPATCH_INFO_BN_KHAM_SAN,
        //   payload: result.data,
        // })
        // yield put({
        //   type: typeAction.DISPATCH_DETAIL_BN_KHAM_SAN,
        //   payload: infoDetail.data,
        // })
        // yield put({
        //   type: typeAction.DISPATCH_DETAIL_TOATHUOC_BN_KHAM_SAN,
        //   payload: infoDetailToaThuoc.data,
        // })
        // yield put({
        //   type: typeAction.DISPATCH_HISTORY_KHAM_PK_SAN,
        //   payload: history.data,
        // })
        // yield put({
        //   type: typeAction.CLOSE_LOADING_PAGE,
        // })
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DANH_SACH_BENH_NHAN_CHO_KHAM_THEO_PHONGKHAM,
          true,
          MENU_CONSTANTS.KHAMNHI,
          null,
          detailErrorPayload,
        )
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // LẤY THÔNG TIN GỢI Ý KHÁM BỆNH BÁCH SĨ
  yield takeLatest(typeAction.GET_SUGGEST_DOCTOR_SAN, function* suggestDoctor({ type }) {
    try {
      // const chanDoan = yield call(() => maternityClinicService.getChanDoans());
      // yield put({
      //   type: typeAction.DISPATCH_CHAN_DOAN,
      //   payload: chanDoan.data,
      // });
      const loiDan = yield call(() => maternityClinicService.getLoiDan())
      yield put({
        type: typeAction.DISPATCH_LOI_DAN_SAN,
        payload: loiDan.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  // LẤY DANH SÁCH THUỐC KÊ TOA TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_THUOC_KE_TOA_DOCTOR_SAN,
    function* thuocKeToaBS({ type, idChiNhanh }) {
      try {
        const result = yield call(() => maternityClinicService.getKetoaThuoc(idChiNhanh))
        const newData = result?.data
          .filter(
            (items) =>
              items.tonKho > 0 &&
              // items?.thuocVatTu?.idNhom !== 169 &&
              items?.thuocVatTu?.idNhom !== 179 &&
              items?.thuocVatTu?.idNhom !== 205,
          ) //không lấy gọng kính trong kho dược)
          .map((items, index) => ({ ...items, ID: index }))
        yield put({
          type: typeAction.DISPATCH_THUOC_KE_TOA_DOCTOR_SAN,
          payload: newData,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
  //---------------- Cập nhật THÔNG TIN KHÁM BỆNH-------------//
  yield takeLatest(
    typeAction.UPDATE_DETAIL_BN_KHAM_SAN,
    function* BNPending({ type, infoBN, formBenhNhan, form, formPhuSan, idcnPk, newArr, isPrint }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        let toaThuoc = []
        const ID_BRANCH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK

        if (form?.lydokolaythuoc) {
          yield call(() => childrenClinicService.deleteBNThuocBSKhongKeToa(form?.idbnttc))

          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.DELETE,
            logAction.XOA_TOA_THUOC_BS_KHONG_KE_TOA,
            true,
            MENU_CONSTANTS.KHAMSAN,
            null,
            {
              tenBenhNhan: infoBN.tenBenhNhan,
            },
          )
        }
        const tenBenhNhan = infoBN.tenBenhNhan

        const detailForLogBN = {
          benhkemtheo: form?.benhkemtheo,
          benhsu: form?.benhsu,
          bskham: form?.bskham,
          bung: form?.bung,
          cacbophan: form?.cacbophan,
          cannang: form?.cannang,
          chandoan: form?.chandoan,
          chandoanbandau: form?.chandoanbandau,
          chandoanravien: form?.chandoanravien,
          chieucao: form?.chieucao,
          chukikinh: form?.chukikinh,
          daniem: form?.daniem,
          daxuly: form?.daxuly,
          dienbienlamsang: form?.dienbienlamsang,
          dieutringoaitru: form?.dieutringoaitru,
          diung: form?.diung,
          dusanh: form?.dusanh,
          dusanhkc: form?.dusanhkc,
          ghichu: form?.ghichu,
          giorapk: form?.giorapk,
          huongxuly: form?.huongxuly,
          huyetap: form?.huyetap,
          idbnttc: form?.idbnttc,
          idbskham: form?.idbskham,
          idchandoan: form?.idchandoan,
          idicD10BANDAU: form?.idicD10BANDAU,
          idicD10BENHKEMTHEO: form?.idicD10BENHKEMTHEO,
          idicD10RAVIEN: form?.ididicD10RAVIEN,
          idloidan: form?.idloidan,
          kinhapchot: form?.kinhapchot,
          kinhchot: form?.kinhchot,
          loidan: form?.loidan,
          lydokham: form?.lydokham,
          lydokolaythuoc: form?.lydokolaythuoc,
          mach: form?.mach,
          ngaysanh: form?.ngaysanh,
          ngaytaikham: form?.ngaytaikham,
          ngaythai: form?.ngaythai,
          nguathai: form?.nguathai,
          nhietdo: form?.nhietdo,
          nhiptho: form?.nhiptho,
          para: form?.para,
          phu: form?.phu,
          ppdieutri: form?.ppdieutri,
          quatrinhbenhly: form?.quatrinhbenhly,
          spO2: form?.spO2,
          tiensubanthan: form?.tiensubanthan,
          tieusugiadinh: form?.tieusugiadinh,
          timphoi: form?.timphoi,
          toanthan: form?.toanthan,
          tongtrang: form?.tongtrang,
          trangthaikhambenh: form?.trangthaikhambenh,
          trieuchung: form?.trieuchung,
          ttlucravien: form?.ttlucravien,
          tuanthai: form?.tuanthai,
          tuoithai: form?.tuoithai,
        }

        const result = yield call(
          () =>
            childrenClinicService.updateDetailBN({
              ...form,
              trangthaikhambenh: isPrint ? (newArr?.length > 0 ? 2 : 4) : 1,
            }), // ấn lưu & in đổi ttkb nếu có thuốc = 2, không có thuốc = 4, ấn nút khác thì 1
        )

        const detailForLog = {
          tenBenhNhan: tenBenhNhan,
          diUng: formBenhNhan?.diUng,
          dienThoai: formBenhNhan?.dienThoai,
          tienSuBanThan: formBenhNhan?.tienSuBanThan,
        }

        const detailForLogBNPhuSan = {
          tenBenhNhan: tenBenhNhan,
          amdao: formPhuSan?.amdao,
          amho: formPhuSan?.amho,
          bctc: formPhuSan?.bctc,
          congoi: formPhuSan?.congoi,
          ctc: formPhuSan?.ctc,
          cungdoan: formPhuSan?.cungdoan,
          gnkhac: formPhuSan?.gnkhac,
          hachnach: formPhuSan?.hachnach,
          idbnttc: formPhuSan?.idbnttc,
          idloaisan: formPhuSan?.idloaisan,
          kchau: formPhuSan?.kchau,
          khamtrong: formPhuSan?.khamtrong,
          ngoithai: formPhuSan?.ngoithai,
          nhiN2VU: formPhuSan?.nhiN2VU,
          nhinphai: formPhuSan?.nhinphai,
          nhintrai: formPhuSan?.nhintrai,
          oi: formPhuSan?.oi,
          phanphup: formPhuSan?.phanphup,
          phanphut: formPhuSan?.phanphut,
          sonanphai: formPhuSan?.sonanphai,
          sonantrai: formPhuSan?.sonantrai,
          timthai: formPhuSan?.timthai,
          tsm: formPhuSan?.tsm,
          tucung: formPhuSan?.tucung,
        }

        yield call(() => childrenClinicService.putInfoBN(formBenhNhan))
        yield call(() =>
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.CAP_NHAT_BN_KHAM,
            true,
            MENU_CONSTANTS.KHAMSAN,
            null,
            detailForLog,
          ),
        )

        yield call(() =>
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.CAP_NHAT_BN_KHAM_TTC,
            true,
            MENU_CONSTANTS.KHAMSAN,
            null,
            detailForLogBN,
          ),
        )
        const resultFormPhuSan = yield call(() =>
          maternityClinicService.putDetailBNKhamSan(formPhuSan),
        )
        yield call(() =>
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.CAP_NHAT_BENH_NHAN_KHAM_SAN,
            true,
            MENU_CONSTANTS.KHAMSAN,
            null,
            detailForLogBNPhuSan,
          ),
        )

        if (newArr?.length > 0 && !form?.lydokolaythuoc) {
          // const formatData = newArr.map((item) => ({
          //   ...item,
          //   soLanNgay: item.soLanNgay + '',
          //   lieuDung: item.lieuDung + '',
          // }))
          // const toathuoc = yield call(() =>
          //   childrenClinicService.putBNThuocBSKeToaLai(form?.idbnttc, newArr),
          // )

          const detailForLogToaThuoc = {
            tenBenhNhan: tenBenhNhan,
            danhSachThuoc: newArr.map((item) => ({
              tenThuoc: item.tenBietDuoc,
              soLuong: item.soLuong,
              donViTinh: item.dvt,
            })),
          }

          const toathuoc = yield call(() =>
            childrenClinicService.putToaThuocBsTuLaySoLo(
              form?.idbnttc,
              PkDangNhap?.idChiNhanh,
              newArr,
            ),
          )

          yield call(() =>
            addLog(
              infoUser?.dangNhap.idNguoiDung,
              PkDangNhap.idChiNhanh,
              actionStatus.PUT,
              logAction.CAP_NHAT_TOA_THUOC_BS_TU_LAY_SO_LO,
              true,
              MENU_CONSTANTS.KHAMSAN,
              null,
              detailForLogToaThuoc,
            ),
          )
          if (isPrint && toathuoc?.data?.length > 0) {
            yield call(() => {
              let listVT = []
              // gộp các dòng khác số lô hạn dùng lại thành 1 dòng
              toathuoc?.data
                ?.sort((a, b) => (a?.stt ?? '').localeCompare(b?.stt ?? ''))
                ?.filter((item) => item?.idnhomthuoc !== 179 && item?.idnhomthuoc !== 205)
                ?.map((item) => {
                  if (listVT?.find((itemVT) => itemVT?.idthuoc === item?.idthuoc)) {
                    listVT?.map(
                      (itemVT) =>
                        itemVT?.idthuoc === item?.idthuoc && (itemVT.soLuong += item.soLuong),
                    )
                  } else {
                    listVT.push({
                      ...item,
                    })
                  }
                })
              // for (const item of toaPrint) {
              //   console.log(item)

              // }
              generatePhieuToaThuoc(infoBN, result.data, listVT, PkDangNhap)
            })
          }
          //const toathuoc = yield call(() => childrenClinicService.postToaThuoc(formatData))
          // toaThuoc = [...toathuoc.data]
          // if (isPrint) {
          //   yield call(() => generatePhieuToaThuoc(infoBN, result.data, toaThuoc, PkDangNhap))
          // }
        }
        yield put(BNChoKhamSan(idcnPk))
        yield put(thuocKeToa(ID_BRANCH_LOGIN))
        yield put({
          type: typeAction.RESET_BS_KHAM_CAPNHAT,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.RESET_TOA_THUOC_BN_DOCTOR_SAN,
          payload: [],
        })
        //generate
        if (isPrint) {
          switch (formPhuSan.idloaisan) {
            case 1:
              yield call(() =>
                generatePhieuSanLon(
                  { ...result.data, tienSuBanThan: form.tiensubanthan },
                  resultFormPhuSan.data,
                  PkDangNhap,
                ),
              )
              break
            case 2:
              yield call(() =>
                generatePhieuSanNho(
                  { ...result.data, tienSuBanThan: form.tiensubanthan },
                  resultFormPhuSan.data,
                  PkDangNhap,
                ),
              )
              break
            case 3:
              yield call(() =>
                generatePhieuPhuKhoa(
                  { ...result.data, tienSuBanThan: form.tiensubanthan },
                  resultFormPhuSan.data,
                  PkDangNhap,
                ),
              )
              break
            case 4:
              yield call(() =>
                generateNhuHoa(
                  { ...result.data, tienSuBanThan: form.tiensubanthan },
                  resultFormPhuSan.data,
                  PkDangNhap,
                ),
              )
              break
            default:
              break
          }
        }
        Toast.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.CAP_NHAT_BN_KHAM,
          false,
          MENU_CONSTANTS.KHAMSAN,
          detailErrorPayload,
        )
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      }
    },
  )
  // ------------- thêm chỉ định khám bệnh cho bệnh nhân ------------//
  yield takeLatest(
    typeAction.ADD_NEW_CHI_DINH_KHAM_SAN,
    function* thuocKeToaBS({ type, form, idbnttc, tenNV, handleReset, chandoanbandau }) {
      try {
        const result = []
        const data = yield select((state) => state.childrenClinicReducer)
        const dataChiNhanh = yield select((state) => state.branchReducer)
        const dataUser = yield select((state) => state.userReducer)
        const { infoUser } = dataUser
        const { DetailBN } = data
        const { PkDangNhap } = dataChiNhanh
        const tenBenhNhan = DetailBN.benhNhan?.tenBenhNhan
        const today = moment()
        // Lọc form thành ba mảng riêng dựa trên type
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const dvArray = form
          .filter((item) => item.type === 'dv' && !item.old)
          .map((item) => ({
            idbnttc: idbnttc,
            iddichvu: item.idchiDinh,
            dongia: item.dongia,
            ptgiamgia: item?.ptgiamgia ?? 0, /////////////////////////////////////////
            tiengiamgia: item?.tiengiamgia ?? 0, /////////////////////////////////////////
            tienthanhtoan: item?.tienthanhtoan ?? item.dongia, /////////////////////////////////////////

            // tienthanhtoan: item.dongia,
            ngaysudung: today.format('YYYY-MM-DDTHH:mm:ss'),
            trangthaikb: 1,
            trangthaitt: 3,
            idnguoichidinh: infoUser?.idnv,
          }))
        const xnArray = form
          .filter((item) => item.type === 'xn' && !item.old)
          .map((item) => ({
            idbnttc: idbnttc,
            idxn: item.idchiDinh,
            ngaychidinh: today.format('YYYY-MM-DDTHH:mm:ss'),
            bschidinh: tenNV,
            trangthaitt: 3,
            dongia: item.dongia,
            ptgiamgia: item?.ptgiamgia ?? 0, /////////////////////////////////////////
            tiengiamgia: item?.tiengiamgia ?? 0, /////////////////////////////////////////
            tienthanhtoan: item?.tienthanhtoan ?? item.dongia, /////////////////////////////////////////

            //tienthanhtoan: item.dongia,
          }))
        const cdhaArray = form
          .filter((item) => item.type === 'cdha' && !item.old)
          .map((item) => ({
            idbnttc: idbnttc,
            idcdha: item.idchiDinh,
            ngaychidinh: today.format('YYYY-MM-DDTHH:mm:ss'),
            idtrangthaitt: 3,
            dongia: item.dongia,
            ptgiamgia: item?.ptgiamgia ?? 0, /////////////////////////////////////////
            tiengiamgia: item?.tiengiamgia ?? 0, /////////////////////////////////////////
            tienthanhtoan: item?.tienthanhtoan ?? item.dongia, /////////////////////////////////////////

            //tienthanhtoan: item.dongia,
          }))
        if (dvArray.length > 0) {
          const { data } = yield call(() => recieveService.postBNDV(dvArray))
          result.push(...data)

          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.THEM_CHI_DINH_DICH_VU,
            true,
            MENU_CONSTANTS.KHAMSAN,
            null,
            {
              tenBenhNhan: tenBenhNhan,
              tenDinhVu: dvArray.map((item) => item.tenDinhVu),
            },
          )
        }
        if (xnArray.length > 0) {
          const { data } = yield call(() => recieveService.postBNXetNghiem(xnArray))
          result.push(...data)
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.THEM_CHI_DINH_DICH_VU_XET_NGHIEM,
            true,
            MENU_CONSTANTS.KHAMSAN,
            null,
            {
              tenBenhNhan: tenBenhNhan,
              tenDinhVu: xnArray.map((item) => item.tenDinhVu),
            },
          )
        }
        if (cdhaArray.length > 0) {
          const { data } = yield call(() => recieveService.postBNCDHA(cdhaArray))
          result.push(...data)
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.POST,
            logAction.THEM_CHI_DINH_DICH_VU_CDHA,
            true,
            MENU_CONSTANTS.KHAMSAN,
            null,
            {
              tenBenhNhan: tenBenhNhan,
              tenDinhVu: cdhaArray.map((item) => item.tenDinhVu),
            },
          )
        }
        handleReset()
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        yield call(() =>
          generatePhieuChiDinh(infoUser, DetailBN, result, PkDangNhap, chandoanbandau),
        )
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
      } catch (error) {
        console.log(error)
        const { PkDangNhap } = yield select((state) => state.branchReducer)
        const { infoUser } = yield select((state) => state.userReducer)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.THEM_CHI_DINH_DICH_VU,
          false,
          MENU_CONSTANTS.KHAMSAN,
          null,
          detailErrorPayload,
        )
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
      }
    },
  )
  // LẤY DANH SÁCH BỆNH NHÂN ĐANG KHÁM VÀ ĐÃ KHÁM BỆNH TẠI CHI NHÁNH
  yield takeLatest(
    typeAction.GET_BN_DA_KHAM_BY_PK_SAN,
    function* BNDaKham({ type, idnv, idcn, tuNgay, denNgay }) {
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const listDangKham = yield call(() =>
          maternityClinicService.getBNDangKham(idnv, idcn, tuNgay, denNgay),
        )
        const listDaKham = yield call(() =>
          maternityClinicService.getBNDaKham(idnv, idcn, tuNgay, denNgay),
        )

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.XEM_DANH_SACH_BENH_NHAN_DANG_KHAM_VA_DA_KHAM_THEO_PHONGKHAM,
          true,
          MENU_CONSTANTS.KHAMSAN,
          null,
          {
            tuNgay: tuNgay,
            denNgay: denNgay,
          },
        )
        yield put({
          type: typeAction.DISPATCH_BN_DANG_KHAM_SAN,
          payload: listDangKham.data,
        })
        yield put({
          type: typeAction.DISPATCH_BN_DA_KHAM_SAN,
          payload: listDaKham.data,
        })
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.XEM_DANH_SACH_BENH_NHAN_DANG_KHAM_VA_DA_KHAM_THEO_PHONGKHAM,
          false,
          MENU_CONSTANTS.KHAMSAN,
          null,
          detailErrorPayload,
        )
      }
    },
  )

  // bác sĩ sửa toa đã kê, chọn không kê toa hoặc xóa hết thuốc trong toa đã kê
  yield takeLatest(
    typeAction.DELETE_BN_THUOC_BS_KHONG_KE_TOA_SAN,
    function* deleteBNThuocBSKhongKeToa({ type, idbnttc, idlydokhonglaythuoc }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => maternityClinicService.deleteBNThuocBSKhongKeToa(idbnttc))
        yield call(() =>
          maternityClinicService.putLyDoKhongKeToaBNTTC(idbnttc, idlydokhonglaythuoc),
        )
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.RESET_TOA_THUOC_BN_DOCTOR_SAN,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
      } catch (error) {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
        console.log(error)
      }
    },
  )
  // bác sĩ sửa toa đã kê, kê lại toa đã lưu
  yield takeLatest(
    typeAction.PUT_BN_THUOC_BS_KE_TOA_LAI_SAN,
    function* updateBNThuocBSKeToaLai({ type, idbnttc, list, infobn, bnttc, pkdangnhap }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => maternityClinicService.putBNThuocBSKeToaLai(idbnttc, list))
        yield call(() => generatePhieuToaThuoc(infobn, bnttc, list, pkdangnhap))
        yield put({
          type: typeAction.DISPATCH_DETAIL_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_BN_KHAM_SAN,
          payload: null,
        })
        yield put({
          type: typeAction.RESET_TOA_THUOC_BN_DOCTOR_SAN,
          payload: [],
        })
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'success',
          title: 'Lưu thành công!',
        })
      } catch (error) {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Lưu thất bại!',
        })
        console.log(error)
      }
    },
  )
}
