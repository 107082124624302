import React, { useEffect, useMemo, useState } from 'react'
import { ConfigProvider, Table, Button, Switch, notification } from 'antd'
import { SyncOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import moment from 'moment'

import _ from 'lodash'
import 'moment/locale/vi'
import { authService } from '../../../services/auth/authService'
import { configSearchTableAnt } from '../../../utils/configSearchTableAntd'
import { addLog } from '../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../utils/log/logConstant'
moment.locale('vi')
const CapKey = () => {
  const { stafs, PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [data, setData] = useState([])
  const [searchValues, setSearchValues] = useState({})
  const [switchStates, setSwitchStates] = useState({})
  const [api, contextHolder] = notification.useNotification()
  // State modal
  const [loading, setLoading] = useState(false)
  // const filteredData = (
  //   data, // lọc giá trị search
  // ) =>
  //   data?.filter((item) =>
  //     Object.entries(searchValues).every(([key, value]) =>
  //       item[key]?.toLowerCase().includes(value.toLowerCase()),
  //     ),
  //   )
  const filteredData = useMemo(() => {
    // console.log('render 1');
    return data?.filter((item) =>
      Object.entries(searchValues).every(([key, value]) =>
        item[key]?.toLowerCase().includes(value.toLowerCase()),
      ),
    )
  }, [data, searchValues])
  //----------- lấy danh sách ip mac---------
  const fetchIpMacs = async () => {
    setLoading(true)
    try {
      const { data } = await authService.getIpMacAddress()
      // const initialStates = {}
      // data.forEach((record) => {
      //   initialStates[record.idip] = record.sudung === 1 // Initialize with 'sudung' field
      // })
      // setSwitchStates(initialStates)
      const sortedData = _.orderBy(data, 'ngaytao', 'desc')
      setData(sortedData)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XEM_LIST_MAC,
        true,
        MENU_CONSTANTS.CAP_KEY,
        null,
      )
    } catch (err) {
      console.log(err)
      const apiUrl = err?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${err?.response?.data?.message || err?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.CAP_KEY,
        detailErrorPayload,
      )
    } finally {
      setLoading(false)
    }
  }
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    })
  }
  useEffect(() => {
    fetchIpMacs()
  }, [])

  //--------- update Status ip mac --------//
  const updateActiveIpMac = async (form) => {
    try {
      await authService.putActiveIPMac(form)
      openNotificationWithIcon('success', 'Cập nhật trạng thái sử dụng', 'Cập nhập thái thành công')
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.UPDATE_SU_DUNG_MAC,
        true,
        MENU_CONSTANTS.CAP_KEY,
        null,
        { id: form.idip, sudung: form.sudung },
      )
    } catch (err) {
      console.log(err)
      openNotificationWithIcon('error', 'Cập nhật trạng thái sử dụng', 'Cập nhập thái thất bại')
      const apiUrl = err?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${err?.response?.data?.message || err?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.CAP_KEY,
        detailErrorPayload,
      )
    }
  }
  // update sử dụng
  const handleSwitchChange = (recordId, checked) => {
    // setSwitchStates((prevState) => ({
    //   ...prevState,
    //   [recordId]: checked,
    // }))
    setData((prevData) =>
      prevData.map((item) =>
        item.idip === recordId ? { ...item, sudung: checked ? 1 : 0 } : item,
      ),
    )
    const form = {
      idip: recordId,
      sudung: checked ? 1 : 0,
      nguoisua: infoUser?.idnv,
    }
    updateActiveIpMac(form)
  }
  // useEffect(() => {
  //   const initialStates = {}
  //   data.forEach((record) => {
  //     initialStates[record.idip] = record.sudung === 1 // Initialize with 'sudung' field
  //   })
  //   setSwitchStates(initialStates)
  //   // const sortedData = _.orderBy(data, 'ngaytao', 'desc')
  //   // setData(sortedData)
  // }, [data])
  const columns = useMemo(
    () => [
      {
        title: 'STT',
        dataIndex: 'STT',
        key: 'STT',
        align: 'center',
        width: 60,
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Chi nhánh',
        dataIndex: 'tenchinhanh',
        key: 'tenchinhanh',
        ...configSearchTableAnt(setSearchValues, searchValues, 'tenchinhanh', data, 100, false),
        fixed: 'left',
      },
      {
        title: 'IP Mac',
        dataIndex: 'maipmac',
        key: 'maipmac',
        ...configSearchTableAnt(setSearchValues, searchValues, 'maipmac', data, 100, false),
      },
      {
        title: 'OS',
        dataIndex: 'os',
        key: 'os',
        ...configSearchTableAnt(setSearchValues, searchValues, 'os', data, 100, false),
      },
      {
        title: 'RAM',
        dataIndex: 'ram',
        key: 'ram',
        align: 'center',
        ...configSearchTableAnt(setSearchValues, searchValues, 'ram', data, 100, false, {
          align: 'center',
        }),
      },
      {
        title: 'CPU',
        dataIndex: 'cpu',
        key: 'cpu',
        ...configSearchTableAnt(setSearchValues, searchValues, 'cpu', data, 100, false),
      },
      {
        title: 'IPWAN',
        dataIndex: 'ipwan',
        key: 'ipwan',
        ...configSearchTableAnt(setSearchValues, searchValues, 'ipwan', data, 100, false),
      },
      {
        title: 'IPLOCAL',
        dataIndex: 'iplocal',
        key: 'iplocal',
        ...configSearchTableAnt(setSearchValues, searchValues, 'iplocal', data, 100, false),
      },
      {
        title: 'Ngày tạo',
        dataIndex: 'ngaytao',
        key: 'ngaytao',
        align: 'center',
        ...configSearchTableAnt(setSearchValues, searchValues, 'ngaytao', data, 100, true, {
          render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
        }),
      },
      {
        title: 'Người sửa',
        dataIndex: 'tennguoisua',
        key: 'tennguoisua',
        ...configSearchTableAnt(setSearchValues, searchValues, 'tennguoisua', data, 100, false),
      },
      {
        title: 'Ngày sửa',
        dataIndex: 'ngaysua',
        key: 'ngaysua',
        ...configSearchTableAnt(setSearchValues, searchValues, 'ngaysua', data, 100, true, {
          render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
        }),
      },
      {
        title: 'Sử dụng',
        dataIndex: 'sudung',
        key: 'sudung',
        align: 'center',
        fixed: 'right',
        width: 80,
        render: (text, record) => (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            // checked={switchStates[record.idip]}
            checked={record.sudung === 1}
            onChange={(checked) => handleSwitchChange(record.idip, checked)}
          />
        ),
      },
    ],
    [data, searchValues],
  )

  return (
    <>
      {contextHolder}
      <div className="h-full p-3 bg-[#EFEFEF]">
        <div className="bg-white rounded-xl p-2">
          <div className="flex flex-col md:flex-row justify-between mb-4 gap-4">
            <div className="flex flex-col md:flex-row gap-2" style={{ flex: 2 }}>
              <Button type="primary" icon={<SyncOutlined />} onClick={fetchIpMacs}>
                Tải lại
              </Button>
            </div>
          </div>

          <ConfigProvider
            renderEmpty={() => <div>Không tìm thấy IP</div>}
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              bordered
              rowKey="key"
              scroll={{
                x: filteredData?.length !== 0 ? 'max-content' : 1000,
                y: '74vh',
              }}
              pagination={false}
              columns={columns}
              loading={loading}
              dataSource={filteredData?.map((items, index) => ({
                ...items,
                key: items.idip,
              }))}
            />
          </ConfigProvider>
        </div>
      </div>
    </>
  )
}

export default CapKey
