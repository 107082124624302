import * as typeAction from '../constants/constants'
export const fetchNgayNghi = () => ({
  type: typeAction.GET_DANH_SACH_NGAY_NGHI,
})
export const deleteNgayNghi = (record, reload) => ({
  type: typeAction.DELETE_NGAY_NGHI,
  record,
  reload,
})
export const addNewNgayNghi = (form, reload) => ({
  type: typeAction.POST_NGAY_NGHI,
  form,
  reload,
})
export const getInfoNgayNghiById = (id) => ({
  type: typeAction.GET_NGAY_NGHI_BY_ID,
  id,
})
export const updateNgayNghiById = (form, reload) => ({
  type: typeAction.UPDATE_NGAY_NGHI,
  form,
  reload,
})
