import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, Input, Table, Modal, Checkbox, Popconfirm } from 'antd'
import { SyncOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import { nhanvienService } from '../../../services/nhanvien/nhanvienService'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import {
  postDanhMucBoPhanAction,
  putDanhMucBoPhanAction,
  putDanhMucBoPhanSuDungAction,
} from '../../../store/actions/userAction'
import ToastCus from '../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
import { addLog } from '../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../utils/log/logConstant'
moment.locale('vi')
const { confirm } = Modal

function CanhBao() {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const [formAdd, setFormAdd] = useState(null)
  const [isModalAdd, setIsModalAdd] = useState(false)
  const [isModalEdit, setIsModalEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [keyword, setKeyword] = useState(null)
  const [listData, setListData] = useState([])
  const [infoBoPhan, setInfoBoPhan] = useState(null)

  const maQuyenXemDs = 'QHT415'
  const maQuyenXemCt = 'QHT416'
  const maQuyenThem = 'QHT414'
  const maQuyenCapNhatInfo = 'QHT417'
  const maQuyenCapNhatSd = 'QHT418'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        getListDanhMucBoPhan()
        break
      case maQuyenXemCt:
        setIsModalEdit(true)
        setInfoBoPhan(infoNew)
        break
      case maQuyenThem:
        setIsModalAdd(true)
        break
      case maQuyenCapNhatInfo:
        handleSubmitEdit()
        break
      case maQuyenCapNhatSd:
        handleEditSudung(infoNew)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXemCt:
        setIsModalEdit(false)
        break
      case maQuyenThem:
        setIsModalAdd(false)
        break
      case maQuyenCapNhatInfo:
        //
        break
      case maQuyenCapNhatSd:
        //
        break
      default:
        return false
    }
  }

  const resetForm = () => {
    setIsModalAdd(false)
    setIsModalEdit(false)
    setFormAdd(null)
    setInfoBoPhan(null)
    onLoad()
  }
  const handleSubmit = () => {
    if (
      !formAdd ||
      !formAdd?.mabp ||
      !formAdd?.tenbp ||
      !formAdd?.mabp?.trim()?.length ||
      !formAdd?.tenbp?.trim()?.length
    ) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
    }
    setIsModalAdd(false)
    dispatch(postDanhMucBoPhanAction(formAdd, resetForm))
  }
  const handleSubmitEdit = () => {
    if (
      !infoBoPhan ||
      !infoBoPhan?.idbp ||
      !infoBoPhan?.mabp ||
      !infoBoPhan?.tenbp ||
      !infoBoPhan?.mabp?.trim()?.length ||
      !infoBoPhan?.tenbp?.trim()?.length
    ) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng kiểm tra lại!',
      })
    }
    setIsModalEdit(false)
    dispatch(putDanhMucBoPhanAction(infoBoPhan, resetForm))
  }
  const handleEditSudung = (infoBp) => {
    dispatch(putDanhMucBoPhanSuDungAction(infoBp, resetForm))
  }
  const onLoad = () => {
    setKeyword((prev) => (prev?.length ? prev?.trim() : prev))
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  const getListDanhMucBoPhan = async () => {
    try {
      setIsLoading(true)
      const { data } = await nhanvienService.getListBoPhan(keyword?.length ? keyword?.trim() : null)
      //   console.log(data)
      setListData(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_DS_BO_PHAN,
        true,
        MENU_CONSTANTS.BO_PHAN,
        null,
        {
          keyword: keyword ?? '',
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.BO_PHAN,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (infoUser) {
      onLoad()
    }
  }, [infoUser])
  return (
    <div className="p-3 h-full bg-[#EFEFEF]">
      <Modal
        width={500}
        open={isModalAdd}
        onCancel={() => setIsModalAdd(false)}
        onOk={handleSubmit}
        okText={'Lưu'}
        cancelText="Huỷ bỏ"
        title={<p className="text-center">Tạo bộ phận</p>}
        className="flex flex-col gap-2"
      >
        <div className="grid grid-flow-row gap-1">
          <div className="">
            <div className="font-semibold">
              <span className="text-red-500">(*)</span>Mã bộ phận:
            </div>
            <Input
              className="w-full"
              status={formAdd && !formAdd?.mabp?.trim()?.length ? 'error' : ''}
              value={formAdd?.mabp}
              onChange={(e) => setFormAdd((prev) => ({ ...prev, mabp: e.target.value }))}
            />
          </div>
          <div className="">
            <div className="font-semibold">
              <span className="text-red-500">(*)</span>Tên bộ phận:
            </div>
            <Input
              className="w-full"
              status={formAdd && !formAdd?.tenbp?.trim()?.length ? 'error' : ''}
              value={formAdd?.tenbp}
              onChange={(e) => setFormAdd((prev) => ({ ...prev, tenbp: e.target.value }))}
            />
          </div>
          <div className="">
            <div className="font-semibold">Ghi chú:</div>
            <Input
              className="w-full"
              value={formAdd?.ghichu}
              onChange={(e) => setFormAdd((prev) => ({ ...prev, ghichu: e.target.value }))}
            />
          </div>
        </div>
      </Modal>
      <Modal
        width={500}
        open={isModalEdit}
        onCancel={() => setIsModalEdit(false)}
        onOk={() =>
          dispatch(
            putCheckQuyenThaoTacAction(maQuyenCapNhatInfo, null, actionIsTrue, actionIsFalse),
          )
        }
        okText={'Lưu'}
        cancelText="Huỷ bỏ"
        title={<p className="text-center">Chỉnh sửa bộ phận</p>}
        className="flex flex-col gap-2"
      >
        <div className="grid grid-flow-row gap-2">
          <div className="">
            <div className="font-semibold">
              <span className="text-red-500">(*)</span>Mã bộ phận:
            </div>
            <Input
              className="w-full"
              status={infoBoPhan && !infoBoPhan?.mabp?.trim()?.length ? 'error' : ''}
              value={infoBoPhan?.mabp}
              onChange={(e) => setInfoBoPhan((prev) => ({ ...prev, mabp: e.target.value }))}
            />
          </div>
          <div className="">
            <div className="font-semibold">
              <span className="text-red-500">(*)</span>Tên bộ phận:
            </div>
            <Input
              className="w-full"
              status={infoBoPhan && !infoBoPhan?.tenbp?.trim()?.length ? 'error' : ''}
              value={infoBoPhan?.tenbp}
              onChange={(e) => setInfoBoPhan((prev) => ({ ...prev, tenbp: e.target.value }))}
            />
          </div>
          <div className="">
            <div className="font-semibold">Ghi chú:</div>
            <Input
              className="w-full"
              value={infoBoPhan?.ghichu}
              onChange={(e) => setInfoBoPhan((prev) => ({ ...prev, ghichu: e.target.value }))}
            />
          </div>
          <div className="">
            <div className="font-semibold">Sử dụng:</div>
            <Checkbox
              checked={infoBoPhan?.sudung}
              onChange={(e) =>
                setInfoBoPhan((prev) => ({ ...prev, sudung: e.target.checked ? 1 : 0 }))
              }
            />
          </div>
        </div>
      </Modal>
      <div
        className="h-full p-2 bg-white border rounded-xl"
        style={{
          boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        }}
      >
        <div className="flex justify-between items-center mb-2">
          <div className="flex justify-start items-center gap-2">
            <Input
              className="w-60"
              placeholder="Tìm mã bộ phận, tên bộ phận..."
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <Button
              onClick={onLoad}
              loading={isLoading}
              type="primary"
              shape="circle"
              icon={<SyncOutlined />}
            />
          </div>
          <div className="flex justify-end items-center">
            <Button
              onClick={() =>
                dispatch(putCheckQuyenThaoTacAction(maQuyenThem, null, actionIsTrue, actionIsFalse))
              }
              type="primary"
              icon={<PlusOutlined />}
            >
              Tạo bộ phận
            </Button>
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            //   scroll={{ x: 1000 }}
            //   pagination={{
            //     pageSize: 20,
            //     showSizeChanger: false,
            //   }}
            loading={isLoading}
            scroll={{ y: 779 }}
            pagination={false}
            bordered
            dataSource={listData}
            // dataSource={[
            //   1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2,
            //   3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9,
            // ]}
            columns={[
              {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: 50,
                align: 'center',
                render: (text, record, index) => ++index,
              },
              {
                title: 'Mã bộ phận',
                dataIndex: 'mabophan',
                key: 'mabophan',
                width: 150,
              },
              {
                title: 'Tên bộ phận',
                dataIndex: 'tenbophan',
                key: 'tenbophan',
              },
              {
                title: 'Người tạo',
                dataIndex: 'tennguoitao',
                key: 'tennguoitao',
                width: 220,
              },
              {
                title: 'Ngày tạo',
                dataIndex: 'ngaytao',
                key: 'ngaytao',
                width: 140,
                align: 'center',
                render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
              },
              {
                title: 'Người sửa',
                dataIndex: 'tennguoisua',
                key: 'tennguoisua',
                width: 220,
              },
              {
                title: 'Ngày sửa',
                dataIndex: 'ngaysua',
                key: 'ngaysua',
                width: 140,
                align: 'center',
                render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
              },
              {
                title: 'Ghi chú',
                dataIndex: 'ghichu',
                key: 'ghichu',
                width: 200,
              },
              {
                title: 'Sử dụng',
                dataIndex: 'sudung',
                key: 'sudung',
                width: 75,
                align: 'center',
                render: (text, record, index) => (
                  <Popconfirm
                    placement="topRight"
                    title="Thay đổi sử dụng bộ phận"
                    description={
                      <>
                        <p>Bạn có chắc chắn muốn thay đổi sử dụng?</p>
                        <p className="font-semibold">
                          {record?.mabophan} - {record?.tenbophan}
                        </p>
                      </>
                    }
                    onConfirm={() =>
                      dispatch(
                        putCheckQuyenThaoTacAction(
                          maQuyenCapNhatSd,
                          record,
                          actionIsTrue,
                          actionIsFalse,
                        ),
                      )
                    }
                    okText="Xác nhận"
                    cancelText="Hủy bỏ"
                  >
                    <Checkbox checked={text} />
                  </Popconfirm>
                ),
              },
              {
                title: '',
                dataIndex: 'action',
                key: 'action',
                width: 50,
                align: 'center',
                render: (text, record, index) => (
                  <>
                    <EditOutlined
                      className="cursor-pointer text-green-500 text-lg"
                      onClick={() =>
                        dispatch(
                          putCheckQuyenThaoTacAction(
                            maQuyenXemCt,
                            {
                              idbp: record?.idbp,
                              mabp: record?.mabophan,
                              tenbp: record?.tenbophan,
                              ghichu: record?.ghichu,
                              sudung: record?.sudung,
                            },
                            actionIsTrue,
                            actionIsFalse,
                          ),
                        )
                      }
                    />
                  </>
                ),
              },
            ]}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default CanhBao
