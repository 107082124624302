import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, Table, Input, Select, message, Modal, Form } from 'antd'
import { https } from '../../../../services/apiService'
import {
  SyncOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
import { formatPayloadDetails } from '../../../../utils/algorithm'
const { Option } = Select

const PhuongXa = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [tinhData, setTinhData] = useState([])
  const [quanHuyenData, setQuanHuyenData] = useState([])
  const [selectedTinh, setSelectedTinh] = useState(null)
  const [selectedQuanHuyen, setSelectedQuanHuyen] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [cityData, setCityData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [newPhuongXa, setNewPhuongXa] = useState({ tenPhuong: '', idQuan: null }) // Add newPhuongXa state
  const [form] = Form.useForm()
  const { infoUser } = useSelector((state) => state.userReducer)

  const maQuyenXemDs = 'QHT451'
  const maQuyenThem = 'QHT452'
  const maQuyenXoa = 'QHT453'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        fetchPhuongXaData()
        break
      case maQuyenThem:
        setIsModalVisible(true)
        break
      case maQuyenXoa:
        showDeleteConfirm(infoNew)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenThem:
        setIsModalVisible(false)
        break
      case maQuyenXoa:
        //
        break
      default:
        return false
    }
  }
  useEffect(() => {
    const fetchTinhData = async () => {
      try {
        const response = await https.get('/tinh')
        setTinhData(response.data)
      } catch (error) {
        console.error('Error fetching Tinh data:', error)
      }
    }
    fetchTinhData()
  }, [])

  useEffect(() => {
    if (selectedTinh) {
      const fetchQuanHuyenData = async () => {
        try {
          const response = await https.get(`/QuanHuyen/${selectedTinh}`)
          setQuanHuyenData(response.data)
        } catch (error) {
          console.error('Error fetching QuanHuyen data:', error)
        }
      }
      fetchQuanHuyenData()
    }
  }, [selectedTinh])

  const handleAddPhuongXa = async (values) => {
    try {
      await https.post('/PhuongXa/CreatenewPhuongXa', newPhuongXa)
      message.success('Thêm phường/xã thành công')
      setIsModalVisible(false)
      setNewPhuongXa({ tenPhuong: '', idQuan: null })
      if (selectedQuanHuyen) {
        const response = await https.get(`/PhuongXa/${selectedQuanHuyen}`)
        setData(response.data)
        setFilteredData(response.data)
      }

      form.resetFields()

      const fetchPhuongXaData = async () => {
        try {
          const response = await https.get(`/PhuongXa/${selectedQuanHuyen}`)
          setData(response.data)
          setFilteredData(response.data)
        } catch (error) {
          console.error('Error fetching PhuongXa data:', error)
        }
      }
      fetchPhuongXaData()
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        logAction.THEM_MOI_PHUONG_XA,
        true,
        MENU_CONSTANTS.DIA_CHI,
        null,
        {
          tenQuanHuyen: quanHuyenData?.find((quan) => quan?.idQuan === newPhuongXa.idQuan)?.tenQuan,
          ten: newPhuongXa.tenPhuong,
        },
      )
    } catch (error) {
      console.error('Error adding PhuongXa:', error)
      message.error('Thêm phường/xã thất bại')
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(newPhuongXa)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.DIA_CHI,
        detailErrorPayload,
      )
    }
  }

  const tenTinhTP = useMemo(() => {
    return tinhData?.find((tinh) => tinh?.idTinh === selectedTinh)?.tenTinh
  }, [tinhData, selectedTinh])

  const tenQuanHuyen = useMemo(() => {
    return quanHuyenData?.find((quan) => quan?.idQuan === selectedQuanHuyen)?.tenQuan
  }, [quanHuyenData, selectedQuanHuyen])

  const fetchPhuongXaData = async () => {
    if (!selectedTinh || !selectedQuanHuyen) return
    try {
      const response = await https.get(`/PhuongXa/${selectedQuanHuyen}`)
      setData(response.data)
      setFilteredData(
        response?.data?.filter((item) => item?.tenPhuong?.toLowerCase()?.includes(searchText)),
      )
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_DS_PHUONG_XA,
        true,
        MENU_CONSTANTS.DIA_CHI,
        null,
        {
          tenTinhTP,
          tenQuanHuyen,
        },
      )
    } catch (error) {
      console.error('Error fetching PhuongXa data:', error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DIA_CHI,
        detailErrorPayload,
      )
    }
  }
  const onLoad = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  useEffect(() => {
    if (selectedQuanHuyen && infoUser) {
      onLoad()
    }
  }, [selectedQuanHuyen, infoUser])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchText(value)
    const filtered = data.filter((item) => item.tenPhuong.toLowerCase().includes(value))
    setFilteredData(filtered)
  }

  const handleTinhChange = (value) => {
    setSelectedTinh(value)
    setSelectedQuanHuyen(null)
    setQuanHuyenData([])
    setData([])
    setFilteredData([])
  }

  const handleQuanHuyenChange = (value) => {
    setSelectedQuanHuyen(value)
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên Phường/Xã',
      dataIndex: 'tenPhuong',
      key: 'tenPhuong',
      sorter: (a, b) => (a.tenPhuong || '').localeCompare(b.tenPhuong || ''),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Hành động',
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          {/* <Button type="primary" size="small" icon={<EditOutlined />}>
            Sửa
          </Button> */}
          <Button
            type="primary"
            danger
            size="small"
            icon={<DeleteOutlined />}
            onClick={() =>
              dispatch(putCheckQuyenThaoTacAction(maQuyenXoa, record, actionIsTrue, actionIsFalse))
            }
          >
            Xóa
          </Button>
        </div>
      ),
    },
  ]

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await https.get('/tinh')
        setCityData(response.data)
      } catch (err) {
        console.error(err)
      }
    }
    if (isModalVisible) {
      fetchCities()
    }
  }, [isModalVisible])

  const handleCityChange = async (idTinh) => {
    try {
      const response = await https.get(`/QuanHuyen/${idTinh}`)
      setDistrictData(response.data)
    } catch (err) {
      console.error(err)
    }
  }

  const handleDeletePhuongXa = async (record) => {
    try {
      await https.delete(`/PhuongXa/DeleteByIdPhuong?IdPhuong=${record.idPhuong}`)
      message.success('Đã xóa thành công')
      const response = await https.get(`/PhuongXa/${selectedQuanHuyen}`)
      setData(response.data)
      setFilteredData(response.data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.DELETE,
        logAction.XOA_PHUONG_XA,
        true,
        MENU_CONSTANTS.DIA_CHI,
        null,
        {
          tenTinhTP,
          tenQuanHuyen,
          ten: record.tenPhuong,
        },
      )
    } catch (error) {
      console.error('Error deleting PhuongXa:', error)
      message.error('Xóa phường/xã thất bại')
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.DELETE,
        null,
        false,
        MENU_CONSTANTS.DIA_CHI,
        detailErrorPayload,
      )
    }
  }
  const showDeleteConfirm = (record) => {
    Modal.confirm({
      title: 'Xác nhận xóa phường/xã?',
      icon: <ExclamationCircleOutlined />,
      content: 'Hành động này không thể hoàn tác',
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk() {
        handleDeletePhuongXa(record)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  return (
    <>
      <div className="flex justify-between items-center mb-4 gap-4">
        <Select
          status={!selectedTinh ? 'error' : ''}
          placeholder="Chọn Tỉnh/Thành phố"
          onChange={handleTinhChange}
          style={{ width: 250 }}
        >
          {tinhData.map((tinh) => (
            <Option key={tinh.idTinh} value={tinh.idTinh}>
              {tinh.tenTinh}
            </Option>
          ))}
        </Select>
        <Select
          status={selectedTinh && !selectedQuanHuyen ? 'error' : ''}
          placeholder="Chọn Quận/Huyện"
          onChange={handleQuanHuyenChange}
          style={{ width: 250 }}
          disabled={!selectedTinh}
          value={selectedQuanHuyen} // Add value prop to control the selected value
        >
          {quanHuyenData.map((quan) => (
            <Option key={quan.idQuan} value={quan.idQuan}>
              {quan.tenQuan}
            </Option>
          ))}
        </Select>
        <Input
          placeholder="Tìm kiếm Phường/Xã"
          value={searchText}
          onChange={handleSearch}
          style={{ width: 250 }}
          prefix={<SearchOutlined />}
        />
        <Button
          onClick={onLoad}
          className="mr-auto"
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() =>
            dispatch(putCheckQuyenThaoTacAction(maQuyenThem, null, actionIsTrue, actionIsFalse))
          }
        >
          Thêm Phường/Xã
        </Button>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table columns={columns} dataSource={filteredData} rowKey="idPhuong" />
      </ConfigProvider>
      <Modal
        title="Thêm Phường/Xã"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        okText="Lưu"
        cancelText="Hủy"
        onOk={handleAddPhuongXa}
      >
        <Form
          form={form} // Add form prop
          layout="vertical"
          initialValues={{ idTinh: null, idQuan: null }}
        >
          <Form.Item
            label="Tỉnh/Thành phố"
            name="idTinh"
            rules={[{ required: true, message: 'Vui lòng chọn tỉnh/thành phố' }]}
          >
            <Select placeholder="Chọn Tỉnh/Thành phố" onChange={handleCityChange}>
              {cityData?.map((tinh) => (
                <Option key={tinh.idTinh} value={tinh.idTinh}>
                  {tinh.tenTinh}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Quận/Huyện"
            name="idQuan"
            rules={[{ required: true, message: 'Vui lòng chọn quận/huyện' }]}
          >
            <Select
              value={newPhuongXa.idQuan}
              placeholder="Chọn Quận/Huyện"
              disabled={!form.getFieldValue('idTinh')}
              onChange={(value) => setNewPhuongXa({ ...newPhuongXa, idQuan: value })}
            >
              {districtData?.map((quan) => (
                <Option key={quan.idQuan} value={quan.idQuan}>
                  {quan.tenQuan}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Tên Phường/Xã"
            name="tenPhuong"
            rules={[{ required: true, message: 'Vui lòng nhập tên phường/xã' }]}
          >
            <Input
              value={newPhuongXa.tenPhuong}
              onChange={(e) => setNewPhuongXa({ ...newPhuongXa, tenPhuong: e.target.value })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default PhuongXa
