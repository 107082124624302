import { call, put, select, takeLatest } from 'redux-saga/effects'
import { dichVuService } from '../../services/dichVu/dichVuService'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import { getVTHHByService } from '../actions/dichVuAction'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
export function* dichVuSaga() {
  //GET ALL LIST DICH VU
  yield takeLatest(typeAction.GET_ALL_LIST_DICH_VU, function* listDichVu({ type }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => dichVuService.getAllListDichVu())
      yield put({
        type: typeAction.DISPATCH_LIST_DICH_VU,
        payload: result.data.data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  // GET LIST DICH VU THEO NHOM DV
  yield takeLatest(typeAction.GET_LIST_DICH_VU, function* listDichVu({ type, idNhomDv, idct }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => dichVuService.getListDichVu(idNhomDv, idct))
      yield put({
        type: typeAction.DISPATCH_LIST_DICH_VU,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  // GET LIST GROUPS DICH VU
  yield takeLatest(typeAction.GET_LIST_GROUPS_DICH_VU, function* listGroupsDichVu({ type }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => dichVuService.getListGroupsDichVu())
      yield put({
        type: typeAction.DISPATCH_GROUPS_DICH_VU,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  //CHI TIET DICH VU
  yield takeLatest(typeAction.GET_INFO_DICH_VU, function* infoXetNgiem({ type, iddv }) {
    try {
      const result = yield call(() => dichVuService.fetchInfoDV(iddv))
      yield put({
        type: typeAction.DISPATCH_INFO_DICH_VU,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })

  // SEARCH DV BY KEYWORD
  yield takeLatest(
    typeAction.SEARCH_DV_BY_KEYWORD,
    function* listDVByKeyword({ type, idnhomdv, idct, keyword }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const result = yield call(() => dichVuService.searchByKeyword(idnhomdv, idct, keyword))
        yield put({
          type: typeAction.DISPATCH_LIST_DICH_VU,
          payload: result.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_DINH_MUC_TIEU_HAO_DICH_VU,
          true,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          null,
          {
            idnhomdv,
            idct,
            keyword,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          detailErrorPayload,
        )
      }
    },
  )

  // ADD NEW DICH VU
  yield takeLatest(typeAction.POST_NEW_DICH_VU, function* addNewDichVu({ type, form, onLoad }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      yield call(() => dichVuService.addDichVu(form))
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm dịch vụ thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.THEM_MOI_DICH_VU_DANH_MUC,
        true,
        MENU_CONSTANTS.DANH_MUC_DV,
        null,
        {
          form,
        },
      )
      onLoad()
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Thêm dịch vụ thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.DANH_MUC_DV,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  //------------- ADD NEW VTTH BY SERVICE--------
  yield takeLatest(typeAction.POST_DICH_VU_VTTH, function* postVTTHByService({ type, form }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield call(() => dichVuService.postVTTH(form))
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm VTTH Thành công!',
      })
      yield put(getVTHHByService(form.iddv))
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.THEM_DINH_MUC_TIEU_HAO_DICH_VU,
        true,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        null,
        {
          form,
        },
      )
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: 'Thêm VTTH thất bại!',
      })
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        detailErrorPayload,
      )
    }
  })
  //------------- DELETE VTTH BY SERVICE--------
  yield takeLatest(
    typeAction.DELETE_VTTH_BY_SERVICE,
    function* DeleteVTTHByService({ type, form }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => dichVuService.deleteVTTHByService(form.iddvvtth))
        ToastCus.fire({
          icon: 'success',
          title: 'Xoá VTTH Thành công!',
        })
        yield put(getVTHHByService(form.iddv))
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          logAction.XOA_DINH_MUC_TIEU_HAO_DICH_VU,
          true,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          null,
          {
            id: form.iddvvtth,
          },
        )
      } catch (error) {
        ToastCus.fire({
          icon: 'error',
          title: 'Xoá VTTH thất bại!',
        })
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          detailErrorPayload,
        )
      }
    },
  )
  //------------- UPDATE VTTH BY SERVICE--------
  yield takeLatest(
    typeAction.UPDATE_VTTH_BY_SERVICE,
    function* updateVTTHByService({ type, form }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => dichVuService.putVTTHByService(form))
        ToastCus.fire({
          icon: 'success',
          title: 'Cập VTTH Thành công!',
        })
        yield put(getVTHHByService(form.iddv))
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CHINH_SUA_DINH_MUC_TIEU_HAO_DICH_VU,
          true,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          null,
          {
            form,
          },
        )
      } catch (error) {
        ToastCus.fire({
          icon: 'error',
          title: 'Cập VTTH thất bại!',
        })
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          detailErrorPayload,
        )
      }
    },
  )
  //------------- GET VTTH BY SERVICE--------
  yield takeLatest(typeAction.GET_VTTH_BY_ID_SERVICE, function* getVTHHByService({ type, id }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      const result = yield call(() => dichVuService.getVTTHByService(id))
      console.log(result.data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_DETAIL_DINH_MUC_TIEU_HAO_DICH_VU,
        true,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        null,
        {
          id,
        },
      )
      yield put({
        type: typeAction.DISPATCH_VTTH_SERVICE,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        detailErrorPayload,
      )
    }
  })
  // DELETE DANH MỤC XÉT NGHIÊM
  yield takeLatest(typeAction.DELETE_DICH_VU, function* deleteDVXetNghiem({ type, iddv, onLoad }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield call(() => dichVuService.deleteDichVu(iddv))
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Xóa thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.DELETE,
        logAction.XOA_DICH_VU_DANH_MUC,
        true,
        MENU_CONSTANTS.DANH_MUC_DV,
        null,
        {
          id: iddv,
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Xóa thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.DELETE,
        null,
        false,
        MENU_CONSTANTS.DANH_MUC_DV,
        detailErrorPayload,
      )
    }
  })
  // UPDATE DANH MỤC XÉT NGHIÊM
  yield takeLatest(typeAction.UPDATE_DICH_VU, function* updateDVXetNghiem({ type, form, onLoad }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield call(() => dichVuService.editDichVu(form))
      yield onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.CHINH_SUA_DICH_VU_DANH_MUC,
        true,
        MENU_CONSTANTS.DANH_MUC_DV,
        null,
        {
          form,
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Cập nhật thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.DANH_MUC_DV,
        detailErrorPayload,
      )
    }
  })
}
