// src/components/layout/ChildrenClinic/General/Tab/TabDiagnostic.jsx
import React, { useEffect, useState } from 'react'
import { Segmented, Alert } from 'antd'
import FormCD from './FormCD/FormCD'
import FormCLS from './FormCLS/FormCLS'
import FormKQXN from './FormKQXN/FormKQXN'
import FormKQCDHA from '../../../MaternityClinic/General/Tab/FormKQCDHA/FormKQCDHA'
import { useFormik } from 'formik'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateBnDetail } from '../../../../../store/actions/childrenClinicAction'
import { ChildrenClinicSchema } from '../../../../../schemas/ChilrenClinicSchema'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../../utils/Toast'
import * as typeAction from '../../../../../store/constants/constants'
import HistoryTongQuat from './HistoryTongQuat/HistoryTongQuat'
import Index from '../Index/Index'
import moment from 'moment'
import 'moment/locale/vi'
import dayjs from 'dayjs'
moment.locale('vi')

const tab_CD = 'Chẩn đoán'
const tab_CLS = 'Chỉ định cận lâm sàng'
const tab_KQXN = 'Kết quả xét nghiệm'
const tab_KQCDHA = 'Kết quả CĐHA'
const tab_LSTQ = 'Lịch sử tổng quát'

const TabDiagnostic = ({ defaultValuePK, formBenhNhan }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const now = moment()
  const SO_NGAY_TAI_KHAM = 2
  const initialStartDate = now.add(SO_NGAY_TAI_KHAM, 'days').format('YYYY-MM-DD')
  const { DetailBN, ThuocBN, DetailToaThuocBN, infoBN, historyKham, NgayThuoc, listTonDuoc } =
    useSelector((state) => state.childrenClinicReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [date, setDate] = useState(initialStartDate)
  const [count, setCount] = useState(SO_NGAY_TAI_KHAM)
  const [checkBox, setCheckBox] = useState(false)
  const [segmentedValue, setSegmentedValue] = useState(tab_CD)
  const [isPrintToaThuocKhiLuu, setIsPrintToaThuocKhiLuu] = useState(false)
  const [listQuyenTab, setlistQuyenTab] = useState([])
  const [isLoadQuyen, setisLoadQuyen] = useState(false)
  const quyenFormCD =
    (`${location.pathname ?? ''}`.includes('khamnhi') && 'QHT065') ||
    (`${location.pathname ?? ''}`.includes('tmtd') && 'QHT098') ||
    (`${location.pathname ?? ''}`.includes('phcn') && 'QHT109')
  const quyenHistoryTongQuat =
    (`${location.pathname ?? ''}`.includes('khamnhi') && 'QHT070') ||
    (`${location.pathname ?? ''}`.includes('tmtd') && 'QHT103') ||
    (`${location.pathname ?? ''}`.includes('phcn') && 'QHT114')
  const quyenFormKQXN =
    (`${location.pathname ?? ''}`.includes('khamnhi') && 'QHT071') ||
    (`${location.pathname ?? ''}`.includes('tmtd') && 'QHT104') ||
    (`${location.pathname ?? ''}`.includes('phcn') && 'QHT115')
  const quyenFormKQCDHA =
    (`${location.pathname ?? ''}`.includes('khamnhi') && 'QHT072') ||
    (`${location.pathname ?? ''}`.includes('tmtd') && 'QHT105') ||
    (`${location.pathname ?? ''}`.includes('phcn') && 'QHT116')

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idbnttc: DetailBN?.idbnttc,
      ngaytaikham: DetailBN?.ngaytaikham,
      trangthaikhambenh: 2,
      bskham: DetailBN?.bskham,
      lydokham: DetailBN?.lydokham,
      benhsu: DetailBN?.benhsu,
      trieuchung: DetailBN?.trieuchung,
      idchandoan: DetailBN?.idchandoan,
      chandoan: DetailBN?.chandoan,
      idloidan: DetailBN?.idloidan,
      loidan: DetailBN?.loidan,
      tiensubanthan: DetailBN?.tiensubanthan,
      diung: DetailBN?.diung,
      quatrinhbenhly: DetailBN?.quatrinhbenhly,
      tieusugiadinh: DetailBN?.tieusugiadinh,
      toanthan: DetailBN?.toanthan,
      cacbophan: DetailBN?.cacbophan,
      chandoanbandau: DetailBN?.chandoanbandau,
      idicD10BANDAU: DetailBN?.idicD10BANDAU,
      daxuly: DetailBN?.daxuly,
      chandoanravien: DetailBN?.chandoanravien,
      idicD10RAVIEN: DetailBN?.idicD10RAVIEN,
      dieutringoaitru: DetailBN?.dieutringoaitru,
      dienbienlamsang: DetailBN?.dienbienlamsang,
      benhkemtheo: DetailBN?.benhkemtheo,
      idicD10BENHKEMTHEO: DetailBN?.idicD10BENHKEMTHEO,
      ppdieutri: DetailBN?.ppdieutri,
      ttlucravien: DetailBN?.ttlucravien,
      huongxuly: DetailBN?.huongxuly,
      lydokolaythuoc: DetailBN?.lydokolaythuoc ?? 0,
      chieucao: DetailBN?.chieucao,
      cannang: DetailBN?.cannang,
      mach: DetailBN?.mach,
      huyetap: DetailBN?.huyetap,
      nhietdo: DetailBN?.nhietdo,
      nhiptho: DetailBN?.nhiptho,
      spO2: DetailBN?.spO2,
      ghichu: DetailBN?.ghichu,
    },
    onSubmit: (values) => handleSubmit(values),
    validationSchema: ChildrenClinicSchema,
  })

  const handleSegmentedChange = (value) => {
    setSegmentedValue(value)
  }
  const handleSubmit = (value) => {
    value.idbskham = infoUser?.idnv
    value.bskham = infoUser?.tenNV
    value.ngaytaikham = moment(date).format('YYYY-MM-DD')
    // value.chieucao = DetailBN?.chieucao;
    // value.cannang = DetailBN?.cannang;
    // value.mach = DetailBN?.mach
    // value.huyetap = DetailBN?.huyetap
    // value.nhietdo = DetailBN?.nhietdo
    // value.nhiptho = DetailBN?.nhiptho
    // value.spO2 = DetailBN?.spO2
    value.giorapk = moment().format('YYYY-MM-DDTHH:mm:ss')
    // value.bmi = DetailBN?.cannang ? formatNumberVND(DetailBN?.cannang / Math.pow(DetailBN?.chieucao / 100, 2)) : 0;
    const newArr = []
    if (!checkBox && ThuocBN?.length > 0) {
      // lấy thuốc
      // ThuocBN.forEach((item, index) => {
      //   newArr.push({
      //     idbnttc: DetailBN?.idbnttc,
      //     idthuoc: item?.idthuoc,
      //     tenBietDuoc: item?.tenBietDuoc,
      //     tenHoatChat: item?.tenhoatchat,
      //     duongDung: item?.duongDung,
      //     dvt: item?.dvt,
      //     hamLuong: item?.hamLuong,
      //     ghiChu: item?.ghiChu,
      //     lieuDung: `${item?.lieuDung ?? 0}`,
      //     soLanNgay: `${item?.soLanNgay ?? 0}`,
      //     bsKeDon: infoUser?.tenNV,
      //     donViDung: item?.donViDung,
      //     donGia: item?.giaBan,
      //     isGongKinh: item?.isGongKinh,
      //     soLuong: item?.soLuong,
      //     idkhocn: item?.idkhocn,
      //     stt: ++index,
      //   })
      // })
      let sttThuoc = 1
      for (let item of ThuocBN) {
        newArr.push({
          idbnttc: DetailBN?.idbnttc,
          idthuoc: item?.idthuoc,
          tenBietDuoc: item?.tenBietDuoc,
          tenHoatChat: item?.tenhoatchat,
          duongDung: item?.duongDung,
          dvt: item?.dvt,
          hamLuong: item?.hamLuong,
          ghiChu: item?.ghiChu,
          lieuDung: `${item?.lieuDung ?? 0}`,
          soLanNgay: `${item?.soLanNgay ?? 0}`,
          bsKeDon: infoUser?.tenNV,
          donViDung: item?.donViDung,
          donGia: item?.giaBan,
          isGongKinh: item?.isGongKinh,
          soLuong: item?.soLuong,
          idkhocn: item?.idkhocn,
          stt: `${sttThuoc++}`, // chuỗi, để còn tách lô 1.1 1.2 1.3
        })
      }
    } else value.trangthaikhambenh = 4 // không lấy thuốc
    // console.log(
    //   ['1.1', '1.4', '1.2', '1.3', '3.1', '3.2', '2.1'],
    // )
    // return
    dispatch(
      updateBnDetail(
        infoBN,
        formBenhNhan.values,
        value,
        defaultValuePK,
        newArr,
        isPrintToaThuocKhiLuu,
      ),
    )
  }
  const calculateDiffDays = (start, end) => {
    const startDate = moment(start).startOf('day')
    const endDate = moment(end).startOf('day')
    return endDate.diff(startDate, 'days')
  }
  const checkQuyenCacTabs = async (maquyen) => {
    try {
      setisLoadQuyen(true)
      maquyen &&
        (await phanQuyenService.putCheckQuyenThaoTac(
          infoUser?.taiKhoan ?? userInfo?.taiKhoan,
          maquyen,
        ))
      setlistQuyenTab((prev) =>
        prev?.find((item) => item === maquyen) ? prev : [...prev, maquyen],
      )
    } catch (error) {
      console.error('Error : ', error)
      setlistQuyenTab((prev) => prev?.filter((item) => item !== maquyen))
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setisLoadQuyen(false)
    }
  }
  useEffect(() => {
    const today = moment().startOf('day')
    const diffDays = formik.values.ngaytaikham
      ? calculateDiffDays(today, formik.values.ngaytaikham)
      : 0
    setCount(diffDays)
    dispatch({
      type: typeAction.DISPATCH_SO_LAN_THUOC_NHI,
      payload: diffDays,
    })
  }, [date, formik.values.ngaytaikham])
  //--------- set ngày tái khám mặt định-------------//
  useEffect(() => {
     // Kiểm tra nếu initialStartDate nằm trong các ngày bị chặn
     const isBlockedDate = (date) => {
      const range1Start = dayjs('2025-01-01');
      const range1End = dayjs('2025-01-01');
      const range2Start = dayjs('2025-01-27');
      const range2End = dayjs('2025-02-02');
      const today = dayjs(); // Ngày hiện tại
  
      return (
        dayjs(date).isBefore(today, 'day') || // Nếu ngày trước hôm nay
        (dayjs(date) >= range1Start && dayjs(date) <= range1End) || // Nếu trong khoảng 1
        (dayjs(date) >= range2Start && dayjs(date) <= range2End)    // Nếu trong khoảng 2
      );
    };
  
    // Xử lý ngày mặc định
    const validStartDate = isBlockedDate(initialStartDate) ? dayjs() : initialStartDate;
    if (!DetailBN?.ngaytaikham) {
      formik.setFieldValue('ngaytaikham', validStartDate)
    } else {
      setDate(DetailBN?.ngaytaikham)
    }
    const diffDays = DetailBN?.ngaytaikham
      ? calculateDiffDays(DetailBN?.ngaykham, DetailBN?.ngaytaikham)
      : SO_NGAY_TAI_KHAM
    setCount( isBlockedDate(initialStartDate) ? 0 : diffDays)
    // if (DetailBN?.idbnttc) {
    //   setlistQuyenTab([])
    //   checkQuyenCacTabs('QHT065') //chẩn đoán
    //   checkQuyenCacTabs('QHT070') //lskb tq
    //   checkQuyenCacTabs('QHT071') //kq xn
    //   checkQuyenCacTabs('QHT072') //kq cdha
    // }
  }, [DetailBN])

  //   const selectedDate = moment(date).startOf('day')
  //   const diffDays = selectedDate.diff(today, 'days')
  //   setCount(diffDays)
  // }, [date])
  // useEffect(() => {
  //   if (NgayThuoc !== count) {
  //     dispatch({
  //       type: typeAction.NGAY_THUOC_NHI,
  //       payload: count,
  //     })
  //   }
  // }, [count])
  useEffect(() => {
    setIsPrintToaThuocKhiLuu(false)
    setCheckBox(
      infoBN &&
        !DetailToaThuocBN?.DanhSachThuoc?.filter(
          (item) => item?.idnhomthuoc !== 179 && item?.idnhomthuoc !== 205,
        )?.length &&
        DetailBN?.lydokolaythuoc
        ? true
        : false,
    )
  }, [DetailToaThuocBN?.DanhSachThuoc, DetailToaThuocBN?.DanhSachThuoc?.length])
  useEffect(() => {
    segmentedValue === tab_CD && checkQuyenCacTabs(quyenFormCD) //chẩn đoán
    segmentedValue === tab_LSTQ && checkQuyenCacTabs(quyenHistoryTongQuat) //lskb tq
    segmentedValue === tab_KQXN && checkQuyenCacTabs(quyenFormKQXN) //kq xn
    segmentedValue === tab_KQCDHA && checkQuyenCacTabs(quyenFormKQCDHA) //kq cdha
  }, [segmentedValue])
  // console.log(listQuyenTab)
  return (
    <div>
      <div>
        <Index />
      </div>
      <div className="flex gap-2 mt-3">
        <div
          className="w-full bg-white rounded-md"
          style={{
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
          }}
        >
          <div className="mt-3 px-2">
            <div className="flex justify-between">
              <Segmented
                options={[tab_CD, tab_LSTQ, tab_CLS, tab_KQXN, tab_KQCDHA]}
                value={segmentedValue}
                onChange={handleSegmentedChange}
              />
            </div>
            <div>
              {segmentedValue === tab_CD && listQuyenTab?.includes(quyenFormCD) ? (
                <FormCD
                  formBenhNhan={formBenhNhan}
                  checkBox={checkBox}
                  setCheckBox={setCheckBox}
                  formik={formik}
                  setDate={setDate}
                  date={date}
                  count={count}
                  setCount={setCount}
                  isPrintToaThuocKhiLuu={isPrintToaThuocKhiLuu}
                  setIsPrintToaThuocKhiLuu={setIsPrintToaThuocKhiLuu}
                  infoDKX={null}
                />
              ) : segmentedValue === tab_LSTQ && listQuyenTab?.includes(quyenHistoryTongQuat) ? (
                <HistoryTongQuat historyKham={historyKham} />
              ) : segmentedValue === tab_KQXN && listQuyenTab?.includes(quyenFormKQXN) ? (
                <FormKQXN />
              ) : segmentedValue === tab_CLS ? (
                <FormCLS formik={formik} />
              ) : segmentedValue === tab_KQCDHA && listQuyenTab?.includes(quyenFormKQCDHA) ? (
                <FormKQCDHA />
              ) : !isLoadQuyen ? (
                <div className="my-2">
                  <Alert message="Tài khoản không có quyền thao tác!" type="error" showIcon />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabDiagnostic
