import { all } from 'redux-saga/effects'
import { branchSaga } from './branhSaga'
import { authSaga } from './authSaga'
import { receiveSaga } from './receiveSaga'
import { NhapKhoSaga } from './KhoNhapSaga'
import { ChuyenKhoSaga } from './ChuyenKhoSaga'
import { khamDoanSaga } from './khamDoanSaga'
import { ThuocVTSaga } from './ThuocVTSaga'
import { xetNghiemSaga } from './xetNghiemSaga'
import { companySaga } from './companySaga'
import { CĐHASage } from './CĐHASage'
import { cardSaga } from './cardSaga'
import { partnerSaga } from './partnerSaga'
import { childrenClinicSaga } from './childrenClinicSaga'
import { maternityClinicSaga } from './maternityClinicSaga'
import { tmtdClinicSaga } from './tmtdClinicSaga'
import { phcnClinicSaga } from './phcnClinicSaga'
import { cashierSaga } from './cashierSaga'
import { drugstoreSaga } from './drugstoreSaga'
import { dichVuSaga } from './dichVuSaga'
import { detailCardSaga } from './detailCardSaga'
import { customerSaga } from './customerSaga'
import { traCuuSaga } from './traCuuSaga'
import { banLeSaga } from './banLeSaga'
import { thuThuatSaga } from './thuThuatSaga'
import { versionSaga } from './versionSaga'
import { sieuAmSaga } from './sieuAmSaga'
import { vltlSaga } from './vltlSaga'
import { monitorSaga } from './monitorSaga'
import { dienTimSaga } from './dienTimSaga'
import { soKhamBenhSaga } from './soKhamBenhSaga'
import { doKhucXaSaga } from './doKhucXaSaga'
import { trongKinhSaga } from './trongKinhSaga'
import { khamSangLocSaga } from './khamSangLocSaga'
import { xuatVacXinSaga } from './xuatVacXinSaga'
import { tiemChungSaga } from './tiemChungSaga'
import { congtyCRMSaga } from './congtyCRMSaga'
import { lienheCRMSaga } from './lienheCRMSaga'
import { phanconglienheCRMSaga } from './phanconglienheSaga'
import { khachhangCRMSaga } from './khachhangCRMSaga'
import { goikhamCRMSaga } from './goikhamCRMSaga'
import { goikhamkhachhangCRMSaga } from './goikhamkhachhangCRMSaga'
import { benhnhanCRMSaga } from './benhnhanCRMSaga'
import { khamSucKhoeSaga } from './khamSucKhoeSaga'
import { vanPhongPhamSaga } from './vanPhongPhamSaga'
import { toolSaga } from './toolSaga'
import { phanAnhSaga } from './phanAnhSaga'
import { donViTinhSaga } from './donViTinhSaga' // Add this line
import { suCoSaga } from './suCoSaga'
import { smsSaga } from './smsSaga'
import { doiTacKHSaga } from './doiTacKHSaga'
import { khachHangDTSaga } from './khachHangDTSaga'
import { xuatSuDungSaga } from './xuatSuDungSaga'
import { xuatNoiBoSaga } from './xuatNoiBoSaga'
import { ThuocGPPSaga } from './ThuocGPPsaga'
import { traCuuBnMatSaga } from './traCuuBnMatSaga'
import { xuatTraNCCSaga } from './xuatTraNCCSaga'
import { hoaHongSaga } from './hoaHongSaga'
import { dangKyKhamSaga } from './dangKyKhamSaga'
import { userSaga } from './userSaga'
import { kiemKeSaga } from './kiemKeSaga'
import { loaiHangHoaSaga } from './loaiHangHoaSaga'
import { phanQuyenSaga } from './phanQuyenSaga'
import { khoDieuChinhSaga } from './khoDieuChinhSaga'
import { LoiDanBsSaga } from './LoiDanBsSaga'
import { IDC10Saga } from './IDC10Saga'
import { ngayNghiSaga } from './ngayNghiSaga'
export function* rootSaga() {
  yield all([
    branchSaga(),
    authSaga(),
    receiveSaga(),
    NhapKhoSaga(),
    ChuyenKhoSaga(),
    khamDoanSaga(),
    ThuocVTSaga(),
    xetNghiemSaga(),
    companySaga(),
    CĐHASage(),
    cardSaga(),
    partnerSaga(),
    childrenClinicSaga(),
    maternityClinicSaga(),
    tmtdClinicSaga(),
    phcnClinicSaga(),
    cashierSaga(),
    drugstoreSaga(),
    dichVuSaga(),
    detailCardSaga(),
    customerSaga(),
    traCuuSaga(),
    banLeSaga(),
    thuThuatSaga(),
    versionSaga(),
    sieuAmSaga(),
    vltlSaga(),
    monitorSaga(),
    dienTimSaga(),
    soKhamBenhSaga(),
    doKhucXaSaga(),
    trongKinhSaga(),
    khamSangLocSaga(),
    xuatVacXinSaga(),
    tiemChungSaga(),
    congtyCRMSaga(),
    lienheCRMSaga(),
    phanconglienheCRMSaga(),
    khachhangCRMSaga(),
    goikhamCRMSaga(),
    goikhamkhachhangCRMSaga(),
    benhnhanCRMSaga(),
    khamSucKhoeSaga(),
    vanPhongPhamSaga(),
    toolSaga(),
    phanAnhSaga(),
    donViTinhSaga(),
    suCoSaga(),
    smsSaga(),
    doiTacKHSaga(),
    khachHangDTSaga(),
    xuatSuDungSaga(),
    xuatNoiBoSaga(),
    ThuocGPPSaga(),
    traCuuBnMatSaga(),
    hoaHongSaga(),
    xuatTraNCCSaga(),
    dangKyKhamSaga(),
    userSaga(),
    kiemKeSaga(),
    loaiHangHoaSaga(),
    phanQuyenSaga(),
    khoDieuChinhSaga(),
    LoiDanBsSaga(),
    IDC10Saga(),
    ngayNghiSaga(),
  ])
}
