import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { NhapKhoService } from '../../services/NhapKho/NhapKhoService'
import Swal from 'sweetalert2'
import { chuyenKhoService } from '../../services/ChuyenKho/chuyenKhoService'
import moment from 'moment'
import { filterPT_recevieCK, listInfoThuocVTChyenKho_Action } from '../actions/chuyenKhoAction'
import { branchService } from '../../services/branch/branchService'
import { AutoPhieuXuatKho } from '../../utils/report/AutoPhieuXuatKho'
import { jsPDFCus } from '../../utils/jsPdfAddFont'
import 'moment/locale/vi'
import { addLog } from '../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
moment.locale('vi')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* ChuyenKhoSaga() {
  // get chi nhanh
  yield takeLatest(typeAction.GET_LIST_Ck_KHO_NHAN, function* branch({ type, payload }) {
    try {
      const result = yield call(() => chuyenKhoService.getlistKhoNhan(payload))
      yield put({
        type: typeAction.DISPATCH_Ck_KHO_NHAN,
        payload: result.data,
      })
    } catch (err) {
      console.log(err)
    }
  })
  // LẤY DANH SÁCH CHI NHÁNH KHO CHUYỂN THEO CÔNG TY
  yield takeLatest(
    typeAction.GET_BRANCH_CHUYEN_KHO_BT_COMPANY,
    function* branchByCompany({ type, IDCompany }) {
      try {
        const result = yield call(() => branchService.getListBranch(IDCompany))
        yield put({
          type: typeAction.DISPATCH_BRANCH_CHUYEN_KHO_BY_COMPANY,
          payload: result.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  yield takeLatest(
    typeAction.GET_PHIEU_CHUYEN_KHO,
    function* getphieuChuyen({ type, filter, setData, setIsLoading }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        setIsLoading(true)
        const result = yield call(() => chuyenKhoService.getPhieuChuyenKho(filter))
        setData(result.data)
        setIsLoading(false)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_LIST_SO_PHIEU_CHUYEN_KHO,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            dateFrom: filter.since,
            dateTo: filter.toDate,
            nameCompany: filter.nameCompany,
            nameChiNhanh: filter.nameChiNhanh,
          },
        )
      } catch (error) {
        console.log(error)
        setIsLoading(false)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  // LẤY DANH SÁCH CHI NHÁNH KHO CHUYỂN PT
  yield takeLatest(
    typeAction.GET_BRANCH_PT_CHUYEN_KHO,
    function* branchPTByCompany({ type, IDCompany }) {
      try {
        const result = yield call(() => branchService.getListBranch(IDCompany))
        yield put({
          type: typeAction.DISPATCH_BRANCH_PT_CHUYEN_KHO,
          payload: result.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  )
  // LƯU PHIẾU CHUYỂN KHO
  yield takeLatest(typeAction.POST_PHIEU_CK, function* branch({ type, payload, khoVT }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    const jsonData = {}

    for (const [key, value] of payload) {
      jsonData[key] = value
    }
    try {
      const result = yield call(() => chuyenKhoService.postChuyenKho(payload))
      const id = yield result.data.data.idNhapXuat
      const listKhoChiTiet = []
      for (let items of khoVT) {
        listKhoChiTiet.push({
          idThuoc: items.idThuoc,
          soLuong: items.soLuong,
          trangThaiChuyenKho: 1,
          soLuongLe: items.soLuongLe,
          soLuongDongGoi: items.thuocVatTu.quyCachDongGoi,
          quyCachDongGoi: items.thuocVatTu.quyCach,
          hanDung: items.hanDung,
          soLo: items.soLo,
          idNhapXuat: id,
          soHoaDon: items.soHoaDon,
          ghiChu: items.ghiChu,
          stt: items.stt,
        })
      }
      // console.log(listKhoChiTiet);
      yield call(() => NhapKhoService.postkhoChiTiet(listKhoChiTiet))
      Toast.fire({
        icon: 'success',
        title: 'Thêm Phiếu thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.TAO_PHIEU_CHUYEN_KHO,
        true,
        MENU_CONSTANTS.CHUYEN_KHO,
        null,
        {
          maphieuchuyen: result.data.data.maPhieu,
          tenphieu: result.data.data.tenPhieu,
        },
      )
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Thêm Phiếu thất bại!',
      })
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(jsonData)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.CHUYEN_KHO,
        detailErrorPayload,
      )
    }
  })
  // ADD THÊM HÀNG HOÁ CHUYỂN KHO
  yield takeLatest(
    typeAction.ADD_HANG_HOA_CHUYEN_KHO,
    function* branch({ type, idNhapXuat, production, handleCancel, maphieuchuyen }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        const result = yield call(() => NhapKhoService.postkhoChiTiet(production))
        yield put(listInfoThuocVTChyenKho_Action(idNhapXuat))
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.THEM_HANG_PHIEU_CHUYEN,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            maphieuchuyen,
            listHang: result.data.map((item) => item.thuocVatTu.tenBietDuoc).join(', '),
          },
        )
        handleCancel()
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  // LƯU PHIẾU CHUYỂN KHO
  yield takeLatest(
    typeAction.CHUYEN_KHO_COMPANY,
    function* addChuyenKhoCompany({ type, payload, khoVT }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const jsonData = {}

      for (const [key, value] of payload) {
        jsonData[key] = value
      }
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => chuyenKhoService.postChuyenKhoCompany(payload))
        const id = yield result.data.data.idNhapXuat
        const listKhoChiTiet = []
        for (let items of khoVT) {
          listKhoChiTiet.push({
            idThuoc: items.idThuoc,
            soLuong: items.soLuong,
            trangThaiChuyenKho: 1,
            soLuongLe: items.soLuongLe,
            soLuongDongGoi: items.thuocVatTu.quyCachDongGoi,
            quyCachDongGoi: items.thuocVatTu.quyCach,
            hanDung: items.hanDung,
            soLo: items.soLo,
            idNhapXuat: id,
            soHoaDon: items.soHoaDon,
            ghiChu: items.ghiChu,
          })
        }
        // console.log(listKhoChiTiet);
        yield call(() => NhapKhoService.postkhoChiTiet(listKhoChiTiet))
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'success',
          title: 'Thêm Phiếu thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.TAO_PHIEU_CHUYEN_KHO_CTY,
          true,
          MENU_CONSTANTS.CHUYEN_KHO_CONG_TY,
          null,
          {
            maphieuchuyen: result.data.data.maPhieu,
            tenphieu: result.data.data.tenPhieu,
          },
        )
      } catch (error) {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'Thêm Phiếu thất bại!',
        })
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(jsonData)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO_CONG_TY,
          detailErrorPayload,
        )
      }
    },
  )
  // GET FILTER CHUYỂN KHO TRẠNG THÁI PHIẾU TẠO
  yield takeLatest(
    typeAction.GET_FILTER_CREATE_CHUYEN_KHO,
    function* getPTCreateCK({ type, filter, setIsLoading }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        // console.log(filter);
        setIsLoading(true)
        const result = yield call(() => chuyenKhoService.getChuyenKhoFilter(filter)) //
        yield put({
          type: typeAction.DISPATCH_LIST_CREATE_CHUYEN_KHO,
          payload: result.data,
        })
        setIsLoading(false)

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_LIST_PHIEU_CHUYEN,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            dateFrom: filter.since,
            dateTo: filter.toDate,
            nameStatus: filter.nameStatus,
            nameChiNhanh: filter.nameChiNhanh,
          },
        )
      } catch (error) {
        console.log(error)
        setIsLoading(false)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  // GET FILTER CHUYỂN KHO TRẠNG THÁI PHIẾU CHỜ
  yield takeLatest(
    typeAction.GET_FILTER_PENDING_CHUYEN_KHO,
    function* getPTPendingCK({ type, filter, setIsLoading }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        setIsLoading(true)
        const result = yield call(() => chuyenKhoService.getPTAccess(filter)) //
        yield put({
          type: typeAction.DISPATCH_LIST_PENDING_CHUYEN_KHO,
          payload: result.data,
        })
        setIsLoading(false)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_LIST_PHIEU_CHUYEN,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            nameStatus: filter.nameStatus,
            nameChiNhanh: filter.nameChiNhanh,
          },
        )
      } catch (error) {
        setIsLoading(false)
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  // GET FILTER CHUYỂN KHO TRẠNG THÁI PHIẾU CHUYỂN ĐI
  yield takeLatest(
    typeAction.GET_FILTER_TRANFER_CHUYEN_KHO,
    function* getPTTranferCK({ type, filter, setIsLoading }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        setIsLoading(true)
        const result = yield call(() => chuyenKhoService.getChuyenKhoFilter(filter)) //
        yield put({
          type: typeAction.DISPATCH_LIST_TRANSFER_CHUYEN_KHO,
          payload: result.data,
        })
        setIsLoading(false)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_LIST_PHIEU_CHUYEN,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            nameStatus: filter.nameStatus,
            nameChiNhanh: filter.nameChiNhanh,
          },
        )
      } catch (error) {
        console.log(error)
        setIsLoading(false)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  // GET FILTER CHUYỂN KHO TRẠNG THÁI XÁC NHẬN
  yield takeLatest(
    typeAction.GET_FILTER_RECEVIE_CHUYEN_KHO,
    function* getPTRecevieCK({ type, filter, setIsLoading }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        setIsLoading(true)
        const result = yield call(() => chuyenKhoService.getPTAccess(filter)) //
        yield put({
          type: typeAction.DISPATCH_LIST_RECEIVE_CHUYEN_KHO,
          payload: result.data,
        })
        setIsLoading(false)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_LIST_PHIEU_CHUYEN,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            dateFrom: filter.since,
            dateTo: filter.toDate,
            nameStatus: filter.nameStatus,
            nameChiNhanh: filter.nameChiNhanh,
          },
        )
      } catch (error) {
        setIsLoading(false)
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  //LẤY DANH SÁCH TỒN KHO THEO KHO CHI NHANH
  yield takeLatest(typeAction.GET_TON_KHO_CHI_NHANH, function* listTonKho({ type, idkhoCn }) {
    try {
      yield put({
        type: typeAction.RESET_KHOVT_CK,
      })
      const result = yield call(() => chuyenKhoService.getTonKhoCN(idkhoCn)) //
      const data = result.data.result
      const newData = []
      for (let index in data) {
        data[index].tonKho !== 0 && newData.push({ ...data[index], ID: index }) // chỉ lấy lên những những hàng còn tồn trong kho chi nhánh
      }
      yield put({
        type: typeAction.DISPATCH_TON_KHO_CHI_NHANH,
        payload: newData,
      })
    } catch (err) {
      console.log(err)
    }
  })
  //----------COPY DANH SÁCH HÀNG HOÁ CHUYỂN KHO -------
  yield takeLatest(
    typeAction.COPY_PRODUCTS_CHUYEN_KHO,
    function* copyChuyenKho({ type, maHang, ListTonKho, setMaHang }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const prefix = maHang.substring(0, 3)
        const isVPP = prefix !== 'VPP' // nếu mã hàng không phải là VPP => true
        let productList = []
        if (isVPP) {
          // nếu khong phải VPP lấy hàng ở kho
          const result = yield call(() => NhapKhoService.getPhieuHangByMaHang(maHang)) //
          const { data } = yield call(() =>
            NhapKhoService.getKhoChitietByNhapXuat(result.data.idnhapxuat),
          )
          productList = data
        } else {
          // lấy hàng VPP
          const { data } = yield call(() => NhapKhoService.getHangVPPByMaFromKho(maHang)) //
          productList = data.map((item) => ({
            // fomat lại data
            idThuoc: item.idthuoc,
            soLuongLe: item.soluong,
          }))
        }
        if (!productList.length) return // Nếu không có hàng hoá nào thì dừng lại
        // Gộp các sản phẩm có cùng idThuoc
        const groupedProducts = productList.reduce((acc, product) => {
          const existingProduct = acc.find((p) => p.idThuoc === product.idThuoc)
          if (existingProduct) {
            // Nếu đã tồn tại sản phẩm với idThuoc này, cộng dồn số lượng
            existingProduct.soLuongLe += product.soLuongLe
          } else {
            // Nếu chưa tồn tại, thêm sản phẩm mới vào danh sách
            acc.push({ ...product })
          }
          return acc
        }, [])
        for (const product of groupedProducts) {
          let quantityRequired = product.soLuongLe // Số lượng cần lấy
          let matchedItems = ListTonKho.filter((item) => item.idThuoc === product.idThuoc) // Tìm các item trong TonKho có cùng idthuoc
          if (!matchedItems.length) continue // chuyển qua sm tiếp theo nếu không có hàng trong kho
          for (const item of matchedItems) {
            if (quantityRequired === 0) continue // không chạy sản phẩm này nữa nếu đã lấy đủ số lượng skip >>;
            const quantityToTake = Math.min(item.tonKho, quantityRequired)
            if (item.tonKho > 0) {
              yield put({
                type: typeAction.PUSH_PRODUCTS_COPY_CHUYEN_KHO,
                payload: {
                  ...item,
                  soLuongLe:
                    quantityToTake -
                    Math.floor(quantityToTake / item.thuocVatTu.quyCachDongGoi) *
                      item.thuocVatTu.quyCachDongGoi,
                  soLuong: Math.floor(quantityToTake / item.thuocVatTu.quyCachDongGoi),
                  ghiChu: '',
                },
              })
              quantityRequired -= quantityToTake
            }
          }
        }
        setMaHang(null)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.COPY_HANG_HOA_CHUYEN_KHO,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            maphieu: maHang,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  //update trạng thái phiếu chuyển kho công ty
  yield takeLatest(
    typeAction.UPDATE_CHUYEN_KHO_CTY,
    function* UpdateStatusCTy({ type, form, idNhapXuat, fetchData, status }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const jsonData = {}

      for (const [key, value] of form) {
        jsonData[key] = value
      }
      try {
        if (status === 'create') {
          // kiển tra xem phiếu chuyển kho có hàng hoá hay không
          // * nếu không có hàng hoá k chuyển phiếu
          // * nếu có thì chuyển tiếp
          const VT = yield call(() => NhapKhoService.getInfoThuocVTByIdNhapXuat(idNhapXuat))
          if (VT.data.length === 0) {
            Toast.fire({
              icon: 'error',
              title: 'Phiếu chuyển rỗng!',
            })
            return
          }
        }
        const resultXacNhan = yield call(() => chuyenKhoService.updateStatusCk(form, idNhapXuat))
        fetchData()
        Toast.fire({
          icon: 'success',
          title: 'Chuyển kho thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.XAC_NHAN_PHIEU_CHUYEN_KHO,
          true,
          MENU_CONSTANTS.CHUYEN_KHO_CONG_TY,
          null,
          {
            maphieuchuyen: resultXacNhan.data.maPhieu,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Chuyển kho thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(jsonData)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO_CONG_TY,
          detailErrorPayload,
        )
      }
    },
  )
  //update trạng thái phiếu chuyển kho
  yield takeLatest(
    typeAction.UPDATE_STATUS_CK,
    function* UpdateStatusCK({ type, form, idNhapXuat, filter, status }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const jsonData = {}

      for (const [key, value] of form) {
        jsonData[key] = value
      }
      try {
        if (status === 'create') {
          // kiển tra xem phiếu chuyển kho có hàng hoá hay không
          // * nếu không có hàng hoá k chuyển phiếu
          // * nếu có thì chuyển tiếp
          const VT = yield call(() => NhapKhoService.getInfoThuocVTByIdNhapXuat(idNhapXuat))
          if (VT.data.length === 0) {
            Toast.fire({
              icon: 'error',
              title: 'Phiếu chuyển rỗng!',
            })
            return
          }
        }
        const resultXacNhan = yield call(() => chuyenKhoService.updateStatusCk(form, idNhapXuat)) //
        const result = yield call(() => chuyenKhoService.getChuyenKhoFilter(filter))
        switch (
          status // kiểm tra trạng thái cập nhật lại kho chuyển
        ) {
          case 'create':
            yield put({
              type: typeAction.DISPATCH_LIST_CREATE_CHUYEN_KHO,
              payload: result.data,
            })
            break
          case 'pending':
            yield put({
              type: typeAction.DISPATCH_LIST_PENDING_CHUYEN_KHO,
              payload: result.data,
            })
            break
          default:
            break
        }
        Toast.fire({
          icon: 'success',
          title: 'Chuyển kho thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.XAC_NHAN_PHIEU_CHUYEN_KHO,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            maphieuchuyen: resultXacNhan.data.maPhieu,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Chuyển kho thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(jsonData)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  // LẤY THÔNG TIN PHIẾU CHUYỂN KHO THEO ID
  yield takeLatest(
    typeAction.GET_INFO_PT_CHUYEN_KHO,
    function* infoPTChuyenkho({ type, idNhapXuat }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        const result = yield call(() => NhapKhoService.getInfoPTNhapKho(idNhapXuat)) //
        const thuocVT = yield call(() => NhapKhoService.getInfoThuocVTByIdNhapXuat(idNhapXuat))
        yield put({
          type: typeAction.DISPATCH_INFO_THUOC_VAT_CK,
          payload: thuocVT.data,
        })
        yield put({
          type: typeAction.DISPATCH_INFO_PT_CHUYEN_KHO,
          payload: result.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.CHI_TIET_PHIEU_CHUYEN_KHO,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            maphieuchuyen: result.data.maPhieu,
          },
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  // UPDATE PHIẾU THU KHO NHẬP HÀNG HOÁ
  yield takeLatest(
    typeAction.UPDATE_INFO_PT_CHUYEN_KHO,
    function* UpdateInfoPTChuyenKho({ type, form, idNhapXuat }) {
      const jsonData = {}

      for (const [key, value] of form) {
        jsonData[key] = value
      }
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        const result = yield call(() => NhapKhoService.putInfoPTNhapKho(form, idNhapXuat)) //
        yield put(listInfoThuocVTChyenKho_Action(idNhapXuat)) // lấy lại thông tin phiếu sau khi đã cập nhật thành công
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CAP_NHAT_THONG_TIN_PHIEU_CHUYEN,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            maphieuchuyen: result.data.maPhieu,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(jsonData)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  // DELETE KHO CHI TIẾT CHUYỂN KHO
  yield takeLatest(
    typeAction.DELETE_KHO_CHI_TIET_CHUEN_KHO,
    function* deleteChiTiet({ type, record, idNhapXuat, maphieuchuyen }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      try {
        yield call(() => NhapKhoService.deleteKhoChiTiet(record.idChiTietNX)) //
        const thuocVT = yield call(() => NhapKhoService.getInfoThuocVTByIdNhapXuat(idNhapXuat))
        yield put({
          type: typeAction.DISPATCH_INFO_THUOC_VAT_CK,
          payload: thuocVT.data,
        })
        Toast.fire({
          icon: 'success',
          title: 'Xoá thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.XOA_HANG_PHIEU_CHUYEN,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            maphieuchuyen,
            tenhang: record.tenBietDuoc,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Xoá thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  // DELETE PHIẾU THU CHUYỂN KHO
  yield takeLatest(
    typeAction.DELETE_PHIEU_CHUYEN_KHO,
    function* deleteChiTiet({ type, id, handleFilter, maphieuchuyen }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => NhapKhoService.deletePhieu(id)) //
        handleFilter()
        Toast.fire({
          icon: 'success',
          title: 'Xoá thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          logAction.XOA_PHIEU_CHUYEN_KHO,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            maphieuchuyen,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Xoá thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  // UPLOAD FILE CHUYEN KHO
  yield takeLatest(
    typeAction.UPLOAD_FILE_CHUYEN_KHO,
    function* uploadFile({ type, id, form, maphieuchuyen }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => NhapKhoService.uploadFile(id, form))
        const result = yield call(() => NhapKhoService.getInfoPTNhapKho(id)) //
        yield put({
          type: typeAction.DISPATCH_INFO_PT_CHUYEN_KHO,
          payload: result.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.CAP_NHAT_FILE_PHIEU_CHUYEN,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            maphieuchuyen,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'upload thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  //------------- DELTE FILE NHAP KHO------------
  yield takeLatest(
    typeAction.DELETE_FILE_CHUYEN_KHO,
    function* deleteFile({ type, id, form, maphieuchuyen }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield call(() => NhapKhoService.deleteFile(form))
        const result = yield call(() => NhapKhoService.getInfoPTNhapKho(id)) //
        yield put({
          type: typeAction.DISPATCH_INFO_PT_CHUYEN_KHO,
          payload: result.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          logAction.DELETE_FILE_PHIEU_CHUYEN,
          true,
          MENU_CONSTANTS.CHUYEN_KHO,
          null,
          {
            maphieuchuyen,
          },
        )
      } catch (error) {
        console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'upload thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.CHUYEN_KHO,
          detailErrorPayload,
        )
      }
    },
  )
  //------------- AUTO PRINTER NHAP KHO------------
  yield takeLatest(
    typeAction.PRINTER_CHUYEN_KHO,
    function* printer({ type, arr, setSelectedRowKeys, isA5 }) {
      try {
        const dataPK = yield select((state) => state.branchReducer)
        const { PkDangNhap } = dataPK
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const dataPrin = []
        for (const item of arr) {
          const { data } = yield call(() => NhapKhoService.getInfoPTNhapKho(item))
          const thuocVT = yield call(() => NhapKhoService.getInfoThuocVTByIdNhapXuat(item))
          const ListThuoc = thuocVT.data
          dataPrin.push({ data, ListThuoc })
        }
        const doc = isA5 ? jsPDFCus('l', 'mm', 'a5') : jsPDFCus('p', 'mm', 'a4')
        dataPrin.forEach((details, index) => {
          if (index > 0) {
            doc.addPage()
          }
          AutoPhieuXuatKho(doc, details.data, details.ListThuoc, PkDangNhap, isA5)
        })
        const blob = doc.output('blob')
        const url = URL.createObjectURL(blob)
        const printWindow = window.open(url)
        if (printWindow) {
          printWindow.addEventListener(
            'load',
            function () {
              printWindow.print()
            },
            true,
          )
        }
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        setSelectedRowKeys([])
      } catch (err) {
        console.log(err)
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
        Toast.fire({
          icon: 'error',
          title: 'in thất bại thất bại!',
        })
      }
    },
  )
}
