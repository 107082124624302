import { https } from '../../services/apiService'
import { logActionHandler } from './templateLogMota'
import Cookies from 'js-cookie'


/**
 * Logs user actions and API calls to the system history
 * @param {Object} infoUser - User information object containing taiKhoan (username), tenNV (employee name), idnv (employee ID)
 * @param {string} chiNhanhDangNhap - Current branch ID where user is logged in
 * @param {string} actionAPI - The API action/endpoint being called
 * @param {string} actionLog - Description of the action for logging
 * @param {boolean} isSuccess - Whether the API call was successful
 * @param {string} menuThaoTac - The menu/module being accessed
 * @param {string} response - API response message (used when isSuccess is false)
 * @param {object} detailForLog - Additional details for the log entry (optional)
 * @returns {Promise<void>}
 */
export const addLog = async (
  idnguoidung,
  chiNhanhDangNhap,
  actionAPI,
  actionLog,
  isSuccess,
  menuThaoTac,
  response = '',
  detailForLog = ''
) => {
  try {
    //this data will be get from cookie
    // const loginInfo = JSON.parse(Cookies.get('login-info'))
    const formatData = {
      idnguoidung: idnguoidung ?? null,
      idchinhanh: chiNhanhDangNhap || null,
      ipmac: loginInfo?.macAddresses[0],
      ip: loginInfo && `${loginInfo?.ipLocal}/${loginInfo?.ipWan}`,
      hanhdong: actionAPI,
      doituong: menuThaoTac,
      mota: isSuccess ? logActionHandler(actionLog, detailForLog) : response,
      trangthai: isSuccess ? 1 : 0,
    }
    await https.post('LogLichSu/AddLogLichSu', formatData)
  } catch (error) {
    console.log(error)
  }
}
const loginInfo = (() => {
  try {
    return JSON.parse(Cookies.get('login-info')) || null;
  } catch (e) {
    return null;
  }
})();