import { Input, InputNumber, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addNewDMXetNghiemAction } from '../../../../store/actions/xetNghiemAction'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'
import { khoSchema } from '../../../../schemas/khoSchema'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'

const loaiKho = [
  {
    label: 'Kho thường',
    value: 0,
  },
  {
    label: 'Kho tổng',
    value: 1,
  },
  {
    label: 'Kho vắc xin',
    value: 2,
  },
]
const ModalAdd = ({ isModalOpen, setIsModalOpen, onLoad }) => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)

  const addKho = async (values) => {
    try {
      await https.post('Kho/AddKho', values)
      ToastCus.fire({
        icon: 'success',
        title: 'Tạo kho thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        logAction.THEM_MOI_KHO,
        true,
        MENU_CONSTANTS.KHO,
        null,
        {
          form: values,
        },
      )
      onLoad()
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Có lỗi xảy ra khi tạo kho',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.KHO,
        detailErrorPayload,
      )
    }
  }
  const handleSubmit = (value) => {
    addKho(value)
    formik.resetForm()
    setIsModalOpen(false)
  }
  const formik = useFormik({
    initialValues: {
      maKho: '',
      tenKho: '',
      dienThoai: '',
      nguoiTao: infoUser.idnv,
      ghiChu: '',
      idCT: '',
      thuocKhoTong: '',
    },
    onSubmit: (value) => handleSubmit(value),
    validationSchema: khoSchema,
  })

  return (
    <>
      <Modal
        okText="Thêm"
        cancelText="Huỷ"
        className="text-center"
        title={<h2 className="text-blue-500 text-xl">Tạo kho</h2>}
        onOk={formik.handleSubmit}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      >
        <form className="text-start flex flex-col gap-2">
          <div>
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)</span>
              Mã kho{' '}
            </label>
            <Input
              value={formik.values.maKho}
              name="maKho"
              onChange={formik.handleChange}
              placeholder="Nhập mã kho"
              status={formik.errors.maKho ? 'error' : ''}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)</span>
              Tên kho{' '}
            </label>
            <Input
              value={formik.values.tenKho}
              name="tenKho"
              onChange={formik.handleChange}
              placeholder="Nhập tên kho"
              status={formik.errors.tenKho ? 'error' : ''}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Số điện thoại</label>
            <Input
              value={formik.values.dienThoai}
              name="dienThoai"
              onChange={formik.handleChange}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)</span>
              Công ty
            </label>
            <Select
              value={formik.values.idCT}
              options={listCompany?.map((items) => ({
                label: items.tenct,
                value: items.idct,
              }))}
              onChange={(value) => formik.setFieldValue('idCT', value)}
              status={formik.errors.idCT ? 'error' : ''}
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold  text-base">
              <span className="text-sx text-red-500">(*)</span>
              Loại kho
            </label>
            <Select
              value={formik.values.thuocKhoTong}
              options={loaiKho?.map(({ label, value }) => ({
                label,
                value,
              }))}
              onChange={(value) => formik.setFieldValue('thuocKhoTong', value)}
              status={formik.errors.thuocKhoTong ? 'error' : ''}
            />
          </div>
          <div>
            <label className="font-semibold  text-base">Ghi chú</label>
            <Input value={formik.values.ghiChu} name="ghiChu" onChange={formik.handleChange} />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ModalAdd
