import moment from 'moment'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { vanPhongPhamService } from '../../services/vanPhongPham/vanphongphamService'
import ToastCus from '../../utils/Toast'
import { trangThaiDatHang } from '../../utils/trangThaiDatHang'
import { formatPayloadDetails } from '../../utils/algorithm'
import 'moment/locale/vi'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { addLog } from '../../utils/log/apiLog'
moment.locale('vi')

export function* vanPhongPhamSaga() {
  yield takeLatest(typeAction.GET_ALL_HANG_HOA, function* getAllHangHoa({ type, idct }) {
    yield put({
      type: typeAction.OPEN_LOADING_PAGE,
    })
    try {
      const result = yield call(() => vanPhongPhamService.getAllHangHoa(idct))
      yield put({
        type: typeAction.DISPATCH_LIST_HANGHOA,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  yield takeLatest(
    typeAction.GET_ALL_DON_DAT_HANG_BY_TRANG_THAI,
    function* getAllDonHangByTrangThai({
      type,
      keyword,
      fromDate,
      toDate,
      pageIndex,
      idTrangThai,
      idct,
      idcn,
      nguoiduyet,
    }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const result = yield call(() =>
          vanPhongPhamService.getAllDonHangByTrangThai(
            keyword,
            fromDate,
            toDate,
            pageIndex,
            idTrangThai,
            idct,
            idcn,
            nguoiduyet,
          ),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_DATHANG,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.GET_ALL_DON_DAT_HANG_THU_MUA_DUYET,
    function* getAllDonHangByTrangThai({ type, keyword, idct, pageIndex }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const { data } = yield call(() =>
          vanPhongPhamService.getAllDonHangThuMuaDuyet(keyword, idct, pageIndex),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_DATHANG,
          payload: data,
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.GET_ALL_DON_DAT_HANG_BY_IDCT_IDCN,
    function* getAllDonHangByIdCtyIdCn({ type, keyword, fromDate, toDate, pageIndex, idct, idcn }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const result = yield call(() =>
          vanPhongPhamService.getAllDonHangByIdCtyIdCn(
            keyword,
            fromDate,
            toDate,
            pageIndex,
            idct,
            idcn,
          ),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_DATHANG,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(typeAction.GET_ALL_NGUOI_DUYET, function* getAllNguoiDuyet({ type }) {
    try {
      const result = yield call(() => vanPhongPhamService.getAllNguoiDuyet())
      yield put({
        type: typeAction.DISPATCH_LIST_NGUOI_DUYET,
        payload: result.data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })

  yield takeLatest(
    typeAction.GET_ALL_DAT_HANG,
    function* getAllDatHang({ type, keyword, fromDate, toDate, pageIndex, idnv }) {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        const result = yield call(() =>
          vanPhongPhamService.getAllDatHang(keyword, fromDate, toDate, pageIndex, idnv),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_DATHANG,
          payload: result.data,
        })
      } catch (err) {
        yield console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.ADD_DON_DAT_HANG,
    function* addDonDatHang({ type, form, onLoad, setLoadingBtn, handleCloseModal }) {
      const { infoUser } = yield select((state) => state.userReducer)

      setLoadingBtn(true)
      try {
        yield call(() => vanPhongPhamService.addDonDatHang(form))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Tạo đơn đặt hàng thành công',
        })
        handleCloseModal()

        const defaultForLog = {
          tendonhang: form.tendonhang,
        }

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          logAction.THEM_DON_DAT_HANG,
          true,
          MENU_CONSTANTS.VAN_PHONG_PHAM,
          null,
          defaultForLog,
        )
      } catch (error) {
        yield console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message || 'Tạo đơn đặt hàng thất bại',
        })

        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.VAN_PHONG_PHAM,
          detailErrorPayload,
        )
      } finally {
        setLoadingBtn(false)
      }
    },
  )

  yield takeLatest(
    typeAction.EDIT_DON_DAT_HANG,
    function* editDonDatHang({
      type,
      form,
      onLoad,
      setLoadingBtn,
      handleCloseModal,
      updateStatus,
    }) {
      const { infoUser } = yield select((state) => state.userReducer)
      setLoadingBtn(true)
      try {
        //update hàng
        yield call(() => vanPhongPhamService.editDonDatHang(form))
        // update status
        if (updateStatus) {
          yield call(() =>
            vanPhongPhamService.updateTrangThaiDonHang(
              form.iddathang,
              trangThaiDatHang.vanChuyen,
              form.nguoisua,
            ),
          )
        }
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Duyệt đơn đặt hàng thành công',
        })
        handleCloseModal()

        const defaultForLog = {
          tendonhang: form.tendonhang,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          logAction.SUA_DON_DAT_HANG,
          true,
          MENU_CONSTANTS.VAN_PHONG_PHAM,
          null,
          defaultForLog,
        )
      } catch (error) {
        yield console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message || 'Duyệt đơn đặt hàng thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.VAN_PHONG_PHAM,
          detailErrorPayload,
        )
      } finally {
        setLoadingBtn(false)
      }
    },
  )

  yield takeLatest(typeAction.GET_INFO_DON_HANG, function* getInfoDonHang({ type, id }) {
    try {
      const { data } = yield call(() => vanPhongPhamService.getInfoDonHang(id))
      yield put({
        type: typeAction.DISPATCH_INFO_DONHANG,
        payload: data[0],
      })
    } catch (err) {
      yield console.log(err)
    }
  })

  yield takeLatest(
    typeAction.GET_INFO_DON_HANG_INCLUDE_MONEY,
    function* getInfoDonHangIncludeMoney({ type, id }) {
      try {
        const { data } = yield call(() => vanPhongPhamService.getInfoDonHangIncludeMoney(id))
        yield put({
          type: typeAction.DISPATCH_INFO_DONHANG,
          payload: data[0],
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )

  yield takeLatest(
    typeAction.UPDATE_TRANG_THAI_DON_DAT_HANG,
    function* updateTrangThaiDonHang({ type, id, idTrangThai, idnv, onLoad }) {
      const { infoUser } = yield select((state) => state.userReducer)
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        yield call(() => vanPhongPhamService.updateTrangThaiDonHang(id, idTrangThai, idnv))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Gửi đơn đặt hàng thành công',
        })
        const defaultForLog = {
          iddathang: id,
          trangthai: idTrangThai,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          logAction.CAP_NHAT_TRANG_THAI_DON_DAT_HANG,
          true,
          MENU_CONSTANTS.VAN_PHONG_PHAM,
          null,
          defaultForLog,
        )
      } catch (error) {
        yield console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message || 'Gửi đơn đặt hàng thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(id)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.VAN_PHONG_PHAM,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.UPDATE_TRANG_THAI_LIST_DON_DAT_HANG,
    function* updateTrangThaiListDonHang({ type, listDonDatHang, onLoad }) {
      const { infoUser } = yield select((state) => state.userReducer)
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      try {
        yield call(() => vanPhongPhamService.updateTrangThaiListDonHang(listDonDatHang))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Duyệt đơn đặt hàng thành công',
        })
        const listDonDatHangIds = listDonDatHang.map((item) => item.iddathang).join(', ')

        const defaultForLog = {
          listDonDatHang: listDonDatHangIds,
        }

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          logAction.DUYET_DON_DAT_HANG,
          true,
          MENU_CONSTANTS.VAN_PHONG_PHAM,
          null,
          defaultForLog,
        )
      } catch (error) {
        yield console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message || 'Duyệt đơn đặt hàng thất bại',
        })

        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(listDonDatHang)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.VAN_PHONG_PHAM,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(
    typeAction.NHAN_DON_DAT_HANG,
    function* updateSoLuongGiaoNhan({ type, form, setLoadingBtn, handleCancel }) {
      const { infoUser } = yield select((state) => state.userReducer)
      setLoadingBtn(true)
      try {
        yield call(() => vanPhongPhamService.updateSoLuongGiaoNhan(form))
        ToastCus.fire({
          icon: 'success',
          title: 'Cập nhật đơn đặt hàng thành công',
        })
        handleCancel()

        const listDonDatHangIds = form.map((item) => item.iddathang).join(', ')

        const defaultForLog = {
          listDonDatHang: listDonDatHangIds,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          logAction.CAP_NHAT_DON_HANG_THANH_CONG,
          true,
          MENU_CONSTANTS.VAN_PHONG_PHAM,
          null,
          defaultForLog,
        )
      } catch (error) {
        yield console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message || 'Cập nhật đơn đặt hàng thất bại',
        })

        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.VAN_PHONG_PHAM,
          detailErrorPayload,
        )
      } finally {
        setLoadingBtn(false)
      }
    },
  )

  yield takeLatest(typeAction.DELETE_DON_HANG, function* deleteDonHang({ type, id, onLoad }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
    } catch (err) {
      yield console.log(err)
      ToastCus.fire({
        icon: 'error',
        title: err?.response?.data?.message || 'Cập nhật đơn đặt hàng thất bại',
      })
    }
  })

  yield takeLatest(typeAction.DELETE_DON_HANG, function* deleteDonHang({ type, id, onLoad }) {
    const { infoUser } = yield select((state) => state.userReducer)
    yield put({
      type: typeAction.OPEN_LOADING_PAGE,
    })
    try {
      yield call(() => vanPhongPhamService.deleteDonHang(id))
      onLoad()
      ToastCus.fire({
        icon: 'success',
        title: 'Xóa đơn đặt hàng thành công',
      })

      const defaultForLog = {
        iddathang: id,
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.DELETE,
        logAction.XOA_DON_DAT_HANG,
        true,
        MENU_CONSTANTS.VAN_PHONG_PHAM,
        null,
        defaultForLog,
      )
    } catch (error) {
      yield console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message || 'Xóa đơn đặt hàng thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(id)}
       \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.DELETE,
        null,
        false,
        MENU_CONSTANTS.VAN_PHONG_PHAM,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}
