import React from 'react'
import { Button, Form, Input, Col, Row } from 'antd'
const styleLabel = {
  style: {
    fontWeight: 600,
  },
}
const FromCreateTemplate = () => {
  return (
    <div className="border rounded-md p-2 xl:p-5">
      <h2 className="text-xl font-semibold mb-2">Tạo ZALO template</h2>
      <Form layout="vertical">
        <Row
          gutter={{
            xl: 16,
          }}
        >
          <Col lg={24} xl={12}>
            {' '}
            <Form.Item labelCol={styleLabel} style={{ marginBottom: 5 }} label="Tiêu đề" required>
              <Input placeholder="Nhập tiêu đề" />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item labelCol={styleLabel} label="Chuyên khoa" required>
              <Input placeholder="Chọn chuyển khoa" />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item labelCol={styleLabel} label="Loại tài khoản" required>
              <Input placeholder="Nhập loại tài khoản" />
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={{
            xl: 16,
          }}
        >
          <Col lg={24} xl={12}>
            <Form.Item labelCol={styleLabel} required label="Nội dung có dấu">
              <Input.TextArea
                style={{
                  height: 80,
                  resize: 'none',
                }}
                showCount
                placeholder="Nội dung có dấu"
              />
            </Form.Item>
          </Col>
          <Col lg={24} xl={12}>
            <Form.Item labelCol={styleLabel} required label="Nội dung Không dấu">
              <Input.TextArea
                style={{
                  height: 80,
                  resize: 'none',
                }}
                showCount
                placeholder="Nội dung không dấu"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary">Tạo template</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default FromCreateTemplate
