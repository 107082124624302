import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Input, ConfigProvider, Table, Spin } from 'antd'
import { CloseOutlined, SaveOutlined, CheckOutlined } from '@ant-design/icons'
import { formattedNumber } from '../../../../../../utils/formattedNumber'
import ToastCus from '../../../../../../utils/Toast'
import {
  postApllyVoucherChoChiDinhDaThuAction,
  putPhieuThuAddVoucherAction,
} from '../../../../../../store/actions/cashierAction'
import _ from 'lodash'
import { addLog } from '../../../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../../../utils/log/logConstant'

const AddVoucher = ({ isVoucher, setisVoucher }) => {
  const dispatch = useDispatch()
  const { DetailTT, infoBNcuaPT, InfoBN, BNTTC, ArrXN, ArrCĐHA, ArrKB } = useSelector(
    (state) => state.cashierReducer,
  )
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const [maVoucher, setmaVoucher] = useState(null)
  const [isApply, setisApply] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [infoListCD, setinfoListDV] = useState([])
  const [infoBNTT, setinfoBNTT] = useState(null)
  const tongDonGia = infoListCD?.reduce((tong, item) => (tong += item?.dongia ?? 0), 0)
  const tongTienGiam = infoListCD?.reduce((tong, item) => (tong += item?.tiengiam ?? 0), 0)
  const tongThanToan = infoListCD?.reduce((tong, item) => (tong += item?.thanhtoan ?? 0), 0)
  const initListCD = ArrKB?.concat(ArrXN, ArrCĐHA)?.map((item) => ({
    idcd: item?.iddichvu ?? item?.idxn ?? item?.idcdha,
    tencd: item?.tendichvu ?? item?.tenxn ?? item?.tencdha,
    dongia: item?.dongia,
    ptgiam: item?.ptgiamgia,
    tiengiam: item?.tiengiamgia,
    thanhtoan: item?.tienthanhtoan,
    loaicd: (item?.iddichvu && 1) || (item?.idxn && 2) || (item?.idcdha && 3),
    info: item,
  }))
  const initBNTT = {
    tiengiam: DetailTT?.tiengiamgia,
    tiengiamdoitac: DetailTT?.tiengiamdoitac,
    thanhtoan: DetailTT?.tienthanhtoan,

    tienmat: DetailTT?.tienmat,
    chuyenkhoan: DetailTT?.chuyenkhoan,
    thett: DetailTT?.thett,
    momo: DetailTT?.momo,
    vnpay: DetailTT?.vnpay,
    zalopay: DetailTT?.zalopay,
    maypos: DetailTT?.pos,
  }
  const handleSubmit = () => {
    if (!isApply) {
      ToastCus.fire({
        icon: 'error',
        title: `Vui lòng áp dụng voucher!`,
      })
      return
    }
    setisLoading(true)
    dispatch(
      putPhieuThuAddVoucherAction(
        {
          listCD: infoListCD,
          infoTT: infoBNTT,
          voucer: maVoucher,
        },
        resetForm,
      ),
    )
  }
  const handleApplyVoucher = async () => {
    if (!maVoucher || !maVoucher?.trim()?.length) {
      ToastCus.fire({
        icon: 'error',
        title: `Vui lòng kiểm tra lại!`,
      })
      return
    }
    const formatVc = maVoucher
      ?.trim()
      ?.replaceAll(' ', '')
      ?.replaceAll(';', '')
      ?.replaceAll('-', '')
      ?.replaceAll('/', '')
    setmaVoucher(formatVc)
    setisLoading(true)
    dispatch(
      postApllyVoucherChoChiDinhDaThuAction(
        {
          listCD: infoListCD,
          voucer: formatVc,
          maphieuthu: infoBNcuaPT?.phieuthu,
        },
        setisLoading,
        setisApply,
        setinfoListDV,
        setinfoBNTT,
      ),
    )
  }
  const handleUnApplyVoucher = () => {
    onLoad()
    ToastCus.fire({
      icon: 'success',
      title: `Hủy áp dụng voucher thành công!`,
    })
    const formatVc = maVoucher
      ?.trim()
      ?.replaceAll(' ', '')
      ?.replaceAll(';', '')
      ?.replaceAll('-', '')
      ?.replaceAll('/', '')
    addLog(
      infoUser?.dangNhap.idNguoiDung,
      PkDangNhap.idChiNhanh,
      actionStatus.POST,
      logAction.UNAPPLY_VOUCHER_CHI_TIET_PHIEU_THU,
      true,
      MENU_CONSTANTS.TIEP_NHAN,
      null,
      {
        voucer: formatVc,
        maphieuthu: infoBNcuaPT?.phieuthu,
      },
    )
  }
  const onLoad = () => {
    setisApply(false)
    setisLoading(false)
    setinfoListDV(initListCD)
    setinfoBNTT(initBNTT)
  }
  const resetForm = () => {
    onLoad()
    setisVoucher(false)
  }
  useEffect(() => {
    onLoad()
  }, [isVoucher, DetailTT, ArrXN, ArrCĐHA, ArrKB])
  const divChiTietThanhToan = (name, value) => {
    return (
      <div className="grid grid-cols-2 items-center gap-1">
        <div className="font-semibold">{name}:</div>
        <Input
          size="small"
          variant="filled"
          readOnly
          className="w-full"
          value={formattedNumber(value ?? 0)}
        />
      </div>
    )
  }
  const divThanhToan = (name, value, end) => {
    return (
      <div className={`grid grid-cols-5 ${end ? '' : 'pb-1 border-b border-[#BABABA]'}`}>
        <div className="col-span-2">{name}</div>
        <div className="col-span-3 text-end">{formattedNumber(value ?? 0)}&nbsp;VNĐ</div>
      </div>
    )
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 35,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Tên chỉ định',
      dataIndex: 'tencd',
      key: 'tencd',
    },
    {
      title: 'Đơn giá',
      dataIndex: 'dongia',
      key: 'dongia',
      width: 80,
      align: 'right',
      render: (text, record, index) => formattedNumber(text ?? 0),
    },
    {
      title: '% Giảm',
      dataIndex: 'ptgiam',
      key: 'ptgiam',
      width: 60,
      align: 'center',
      render: (text, record, index) => text,
    },
    {
      title: 'Tiền giảm',
      dataIndex: 'tiengiam',
      key: 'tiengiam',
      width: 80,
      align: 'right',
      render: (text, record, index) => formattedNumber(text ?? 0),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'thanhtoan',
      key: 'thanhtoan',
      width: 80,
      align: 'right',
      render: (text, record, index) => formattedNumber(text ?? 0),
    },
  ]
  return (
    <div>
      <Spin spinning={isLoading}>
        <div className="flex justify-end items-center gap-2">
          <Button type="primary" icon={<SaveOutlined />} onClick={handleSubmit} />
          <Button
            danger
            type="primary"
            icon={<CloseOutlined />}
            onClick={() => setisVoucher(false)}
          />
        </div>
        <div className="grid grid-flow-row items-center gap-2 my-2">
          <div className="grid grid-cols-3 items-center gap-2">
            {divChiTietThanhToan('Tiền mặt', infoBNTT?.tienmat)}
            {divChiTietThanhToan('Chuyển khoản', infoBNTT?.chuyenkhoan)}
            {divChiTietThanhToan('Thẻ thanh toán', infoBNTT?.thett)}
          </div>
          <div className="grid grid-cols-3 items-center gap-2">
            {divChiTietThanhToan('Momo', infoBNTT?.momo)}
            {divChiTietThanhToan('VNPay', infoBNTT?.vnpay)}
            {divChiTietThanhToan('ZaloPay', infoBNTT?.zalopay)}
          </div>
          <div className="grid grid-cols-3 items-center gap-2">
            {divChiTietThanhToan('Máy POS', infoBNTT?.maypos)}
            <div></div>
            <div></div>
          </div>
          <div className="grid grid-cols-5 items-center">
            <div className="font-semibold">Voucher đã áp:</div>
            <Input
              size="small"
              variant="filled"
              readOnly
              className="col-span-4 w-full"
              value={infoBNcuaPT?.mavoucher}
            />
          </div>
          <div className="grid grid-cols-5 items-center">
            <div className="font-semibold">Voucher cần thêm:</div>
            <Input
              variant={isApply ? 'filled' : 'outlined'}
              readOnly={isApply}
              size="small"
              className="col-span-4 w-full"
              value={maVoucher}
              status={!maVoucher || !maVoucher?.trim()?.length ? 'error' : ''}
              onChange={(e) => setmaVoucher(e.target.value)}
              addonAfter={
                isApply ? (
                  <CloseOutlined
                    onClick={handleUnApplyVoucher}
                    className="text-lg text-red-500 cursor-pointer flex justify-center items-center"
                  />
                ) : (
                  <CheckOutlined
                    onClick={handleApplyVoucher}
                    className="text-lg text-green-500 cursor-pointer flex justify-center items-center"
                  />
                )
              }
            />
          </div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              padding: 3,
              fontSize: 13,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            bordered
            scroll={{ y: 180 }}
            pagination={false}
            dataSource={infoListCD}
            columns={columns}
            summary={() => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    {columns?.map((item, index) => (
                      <Table.Summary.Cell index={index} className="text-end">
                        {item?.key === 'dongia' && formattedNumber(tongDonGia ?? 0)}
                        {item?.key === 'tiengiam' && formattedNumber(tongTienGiam ?? 0)}
                        {item?.key === 'thanhtoan' && (
                          <div className="pr-[1.05rem]">{formattedNumber(tongThanToan ?? 0)}</div>
                        )}
                      </Table.Summary.Cell>
                    ))}
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
            footer={() => {
              return (
                <div className="grid grid-cols-2">
                  <div></div>
                  <div className="grid grid-flow-row items-center pl-16 pr-5">
                    {divThanhToan('Tổng tiền', tongDonGia)}
                    {divThanhToan('Tiền giảm', infoBNTT?.tiengiam)}
                    {divThanhToan('Tiền giảm đối tác', infoBNTT?.tiengiamdoitac)}
                    {divThanhToan(
                      'Tổng giảm',
                      (infoBNTT?.tiengiam ?? 0) + (infoBNTT?.tiengiamdoitac ?? 0),
                    )}
                    {divThanhToan('Thanh toán', infoBNTT?.thanhtoan, true)}
                  </div>
                </div>
              )
            }}
          />
        </ConfigProvider>
      </Spin>
    </div>
  )
}

export default AddVoucher
