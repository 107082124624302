import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Button, ConfigProvider, Empty, Input, Popconfirm, Table, Tabs, Tooltip, Tree } from 'antd'
import _, { isNil, uniq } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../services/apiService'
import * as typeAction from '../../../store/constants/constants'
import ToastCus from '../../../utils/Toast'
import { formatDataTreeMenu, formatPayloadDetails, getListMenu } from '../../../utils/algorithm'
import MenuVaiTro from './Menu'
import ModalCreateVaiTro from './ModalAddVaiTro/ModalAddVaiTro'
import ModalEditVaiTro from './ModalEditVaiTro/ModalEditVaiTro'
import { addLog } from '../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../utils/log/logConstant'

const columns = [
  {
    title: 'STT',
    dataIndex: 'STT',
    key: 'STT',
    width: 40,
    align: 'center',
  },
  {
    title: 'Mã vai trò',
    dataIndex: 'maNhom',
    key: 'maNhom',
    align: 'center',
    width: 140,
  },
  {
    title: 'Tên vai trò',
    dataIndex: 'tenNhom',
    key: 'tenNhom',
  },
  {
    title: 'Tên viết tắt',
    dataIndex: 'tenVietTat',
    key: 'tenVietTat',
  },
  {
    title: 'Hành động',
    dataIndex: 'action',
    key: 'action',
    width: 100,
  },
]

const VaiTro = () => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [dataShow, setDataShow] = useState([])
  const [search, setSearch] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenEdit, setIsModalOpenEdit] = useState({
    show: false,
    data: null,
  })
  const [selected, setSelected] = useState({})
  const [treeData, setTreeData] = useState([])
  const [checkedKeys, setCheckedKeys] = useState([])

  const getDataAllNhomNguoiDung = async () => {
    try {
      dispatch({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const { data } = await https.get('NhomNguoiDung/GetAll')
      const newData = data.filter((item) =>
        item.tenNhom.toLowerCase().includes(search.toLowerCase()),
      )
      setDataShow(newData)
      setData(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap?.idChiNhanh,
        actionStatus.GET,
        logAction.GET_ALL_NHOM_NGUOI_DUNG,
        true,
        MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
        null,
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap?.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
        detailErrorPayload,
      )
    } finally {
      dispatch({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  }

  const getMenuByIdNhomNguoiDung = async (record) => {
    try {
      const { data } = await https.get(`NhomQuyen/GetQuyenByIDNhom?idNhomNd=${record.idNhom}`)
      const defaultData = data.map((item) => {
        return { ...item.menu }
      })
      const listMenu = getListMenu(defaultData)
      const arrChecked = []
      listMenu.forEach((item) => {
        if (item.listChildMenu.length === 0) {
          arrChecked.push(item.idMenu)
        } else {
          arrChecked.push(...item.listChildMenu.map((value) => value.idMenu))
        }
      })
      setCheckedKeys(arrChecked)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_VAI_TRO_BY_NHOM_NGUOI_DUNG,
        true,
        MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
        null,
        {
          idNhom: record.idNhom,
          maNhom: record.maNhom,
          tenNhom: record.tenNhom,
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
        detailErrorPayload,
      )
    }
  }

  useEffect(() => {
    getDataAllNhomNguoiDung()
  }, [])

  const debounceSearch = useCallback(
    _.debounce((keyword, data) => {
      const result = data.filter((item) =>
        item.tenNhom.toLowerCase().includes(keyword.toLowerCase()),
      )
      setDataShow(result)
    }, 500),
    [],
  )

  const handleSearchInput = (e) => {
    const { value } = e.target
    debounceSearch(value, data)
    setSearch(value)
  }

  const handleClearSearch = () => {
    setSearch('')
    //get lai data
    setDataShow(data)
  }

  const handleChooseRow = (record) => {
    setSelected(record)
    getMenuByIdNhomNguoiDung(record)
  }

  const handleDeleteById = (e, item) => {
    e.stopPropagation()
    ;(async () => {
      try {
        await https.delete(`NhomNguoiDung/DeleteNhom/${item.idNhom}`)
        ToastCus.fire({
          icon: 'success',
          title: 'Xóa vai trò thành công',
        })

        if (selected.idNhom === item.idNhom) {
          setSelected({})
          setTreeData([])
          setCheckedKeys([])
        }
        getDataAllNhomNguoiDung(search)
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          logAction.XOA_VAI_TRO,
          true,
          MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
          null,
          {
            maNhom: item.maNhom,
            tenNhom: item.tenNhom,
          },
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Xóa vai trò thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
          detailErrorPayload,
        )
      }
    })()
  }

  const handleInfoVaiTro = (e, item) => {
    e.stopPropagation()
    setIsModalOpenEdit({
      show: true,
      data: item,
    })
  }

  const getAllMenuData = async () => {
    try {
      const { data } = await https.get('Menu/GetAll')
      const listMenu = getListMenu(data)
      setTreeData(() => formatDataTreeMenu(listMenu))
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap?.idChiNhanh,
        actionStatus.GET,
        logAction.GET_ALL_MENU,
        true,
        MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
        null,
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
        detailErrorPayload,
      )
    }
  }

  useEffect(() => {
    getAllMenuData()
  }, [])

  const addChildParentKey = (data, childKeys) => {
    const childParentMap = {}
    // Build a mapping of child keys to parent keys
    data.forEach((parentNode) => {
      if (parentNode.children) {
        parentNode.children.forEach((childNode) => {
          childParentMap[childNode.key] = parentNode.key
        })
      }
    })
    // Add parent key to each child object
    const childrenWithParent = childKeys.map((childKey) => {
      const parentKey = childParentMap[childKey]
      return [parentKey, childKey]
    })

    return childrenWithParent
  }

  const handleUpdateVaiTroMenu = async () => {
    if (checkedKeys.length === 0) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn menu',
      })
      return
    }
    const childrenWithParentKey = addChildParentKey(treeData, checkedKeys)
    const arr = []
    childrenWithParentKey.forEach((item) => {
      arr.push(...item)
    })
    // Create a Set from the array
    const arrUniqueMenu = uniq(arr.filter((item) => !isNil(item)))
    try {
      await https.put(`NhomQuyen/CapNhatQuyen`, {
        idnhom: selected.idNhom,
        idmenu: arrUniqueMenu,
      })
      ToastCus.fire({
        icon: 'success',
        title: 'Cập nhật vai trò thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap?.idChiNhanh,
        actionStatus.PUT,
        logAction.CAP_NHAT_QUYEN_MENU,
        true,
        MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
        null,
        { tenNhom: selected.tenNhom },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Cập nhật vai trò thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails({
        idnhom: selected.idNhom,
        idmenu: arrUniqueMenu,
      })} \nAPI request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
        detailErrorPayload,
      )
    }
  }

  const onCheck = (checkedKeysValue) => {
    if (selected?.idNhom) setCheckedKeys(checkedKeysValue)
  }

  const handleChangeTab = (key) => {
    if (key === 1) {
      getAllMenuData()
      handleClearSearch()
      setSelected({})
      setCheckedKeys([])
    }
  }

  return (
    <>
      <div className="w-full p-4">
        <Tabs
          type="card"
          onChange={handleChangeTab}
          defaultActiveKey="1"
          items={[
            {
              key: 1,
              label: 'Vai trò',
              children: (
                <>
                  <div className="flex gap-2 h-full p-4 bg-[#efefef]">
                    <div className="w-2/3 bg-white rounded-xl">
                      <div className="p-2 min-h-16 flex justify-between items-center">
                        <ul className="flex gap-2 items-center">
                          <li className="w-60">
                            <Input
                              value={search}
                              placeholder="Tìm kiếm"
                              onChange={handleSearchInput}
                              prefix={<SearchOutlined />}
                              suffix={
                                search?.length > 0 && <CloseOutlined onClick={handleClearSearch} />
                              }
                            />
                          </li>
                        </ul>
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={() => setIsModalOpen(true)}
                        >
                          Tạo vai trò
                        </Button>
                      </div>
                      <div className="mt-2 p-2">
                        <ConfigProvider
                          theme={{
                            token: {
                              padding: 5,
                            },
                            components: {
                              Table: {
                                rowHoverBg: '#ecf0f1',
                                headerBg: '#e6e6e6',
                                footerBg: '#e6e6e6',
                                borderColor: '#BABABA',
                              },
                            },
                          }}
                        >
                          <Table
                            bordered
                            scroll={{
                              y: 650,
                            }}
                            pagination={false}
                            onRow={(record, rowIndex) => {
                              return {
                                onClick: (event) => {
                                  handleChooseRow(record)
                                }, // click row
                              }
                            }}
                            rowClassName="vaitro-row cursor-pointer"
                            columns={columns}
                            dataSource={dataShow?.map((item, index) => ({
                              ...item,
                              STT: ++index,
                              action: (
                                <ul className="flex justify-around">
                                  <li>
                                    <Tooltip title="Sửa thông tin" color="#52C41A">
                                      <EditOutlined
                                        onClick={(e) => {
                                          handleInfoVaiTro(e, item)
                                        }}
                                        className="text-xl text-[#52C41A]  cursor-pointer"
                                      />
                                    </Tooltip>
                                  </li>
                                  <li>
                                    <Tooltip title="Xoá" color="red">
                                      <Popconfirm
                                        title="Xác nhận xóa vai trò"
                                        onConfirm={(e) => handleDeleteById(e, item)}
                                        onCancel={(e) => e.stopPropagation()}
                                        okText="Xoá"
                                        cancelText="Hủy"
                                        icon={<DeleteOutlined style={{ color: 'red' }} />}
                                      >
                                        <DeleteOutlined
                                          className="text-xl text-red-500  cursor-pointer"
                                          onClick={(e) => e.stopPropagation()} // Stop event propagation
                                        />
                                      </Popconfirm>
                                    </Tooltip>
                                  </li>
                                </ul>
                              ),
                            }))}
                          />
                        </ConfigProvider>
                      </div>
                    </div>
                    <div className="w-1/3 bg-white rounded-xl">
                      <div className="px-2 pt-2">
                        <div className="border-b p-2">
                          <h2 className="text-xl font-semibold text-gray-600">
                            Vai trò: <span className="text-black">{selected?.tenNhom}</span>
                          </h2>
                        </div>

                        {treeData.length > 0 ? (
                          <>
                            <div className="flex gap-5 justify-between items-center mt-2 p-2">
                              <div className=" w-3/5 h-full">
                                <p className="font-semibold text-lg">Menu</p>
                              </div>
                              <div className=" w-2/5 h-full">
                                <Button
                                  type="primary"
                                  className="w-full"
                                  onClick={handleUpdateVaiTroMenu}
                                  disabled={!selected?.idNhom}
                                >
                                  Lưu
                                </Button>
                              </div>
                            </div>
                            <div className="p-4">
                              <Tree
                                checkable
                                checkedKeys={checkedKeys}
                                treeData={treeData}
                                onCheck={onCheck}
                              />
                            </div>
                          </>
                        ) : (
                          <Empty description="Chưa phân quyền" />
                        )}
                      </div>
                    </div>
                  </div>
                  {isModalOpen && (
                    <ModalCreateVaiTro
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      getDataRefresh={() => getDataAllNhomNguoiDung()}
                    />
                  )}
                  {isModalOpenEdit.show && (
                    <ModalEditVaiTro
                      isModalOpenEdit={isModalOpenEdit}
                      setIsModalOpenEdit={setIsModalOpenEdit}
                      getDataRefresh={() => getDataAllNhomNguoiDung()}
                      setSelected={setSelected}
                      setTreeDataParent={setTreeData}
                    />
                  )}
                </>
              ),
            },
            {
              key: 2,
              label: 'Menu',
              children: <MenuVaiTro />,
            },
          ]}
        />
      </div>
    </>
  )
}

export default VaiTro
