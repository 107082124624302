import { Input, Modal, Select } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { addMenuSchema } from '../../../../schemas/addMenuSchema'
import { https } from '../../../../services/apiService'
import ToastCus from '../../../../utils/Toast'
import { addLog } from '../../../../utils/log/apiLog'
import { useSelector } from 'react-redux'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../utils/log/logConstant'
import { formatPayloadDetails } from '../../../../utils/algorithm'

function ModalEditMenu({ isModalOpenEdit, setIsModalOpenEdit, getAllMenu }) {
  const [loading, setLoading] = useState(false)
  const [listMenu, setListMenu] = useState([])
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      maMenu: isModalOpenEdit.data?.maMenu,
      tenMenu: isModalOpenEdit.data?.tenMenu,
      tenVietTat: isModalOpenEdit.data?.tenVietTat,
      ghiChu: isModalOpenEdit.data?.ghiChu ?? '',
      links: isModalOpenEdit.data?.links,
      idMenuCha: isModalOpenEdit.data?.idMenuCha,
    },
    onSubmit: (values, actions) => handleSubmit(values, actions),
    validationSchema: addMenuSchema,
  })
  const handleCloseModal = () => {
    setIsModalOpenEdit(false)
    formik.resetForm()
  }

  const handleSubmit = async (values, actions) => {
    setLoading(true)
    try {
      await https.put(`Menu/UpdateMenu/${isModalOpenEdit.data.idMenu}`, values)
      handleCloseModal()
      getAllMenu()
      ToastCus.fire({
        icon: 'success',
        title: 'Sửa menu thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.SUA_MENU,
        true,
        MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
        null,
        {
          ...values,
          menuCha: listMenu.find((item) => item.idMenu === values.idMenuCha)?.tenMenu ?? '',
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: 'Có lỗi xảy ra khi sửa menu',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(values)} \nAPI request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
        detailErrorPayload,
      )
    } finally {
      setLoading(false)
    }
  }

  const handleMenuParentChange = (value) => {
    formik.setFieldValue('idMenuCha', value)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('Menu/GetAll')
        setListMenu([
          ...data,
          {
            idMenu: 0,
            tenMenu: 'Mặc định',
          },
        ])
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_ALL_MENU,
          true,
          MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
          null,
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Lấy dữ liệu thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.VAI_TRO_NGUOI_DUNG,
          detailErrorPayload,
        )
      }
    })()
  }, [])

  return (
    <Modal
      width={650}
      className="text-center"
      title="Thông tin menu"
      okText="Cập nhật"
      cancelText="Huỷ bỏ"
      open={isModalOpenEdit.show}
      onOk={formik.handleSubmit}
      onCancel={handleCloseModal}
      maskClosable={false}
      confirmLoading={loading}
    >
      <form className="text-start mt-5">
        <div className="flex flex-col gap-3">
          <div className="flex w-full gap-2">
            <label className="w-32 text-end">
              <span className="text-red-500 text-xs">(*)</span>Tên Menu:
            </label>
            <Input
              status={formik.errors.tenMenu ? 'error' : ''}
              name="tenMenu"
              value={formik.values.tenMenu}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex w-full gap-2">
            <label className="w-32 text-end">
              <span className="text-red-500 text-xs">(*)</span>Mã Menu:
            </label>
            <Input
              status={formik.errors.maMenu ? 'error' : ''}
              name="maMenu"
              value={formik.values.maMenu}
              onChange={formik.handleChange}
              size="small"
            />
          </div>

          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Tên viết tắt:</label>
            <Input
              status={formik.errors.tenVietTat ? 'error' : ''}
              name="tenVietTat"
              value={formik.values.tenVietTat}
              onChange={formik.handleChange}
              size="small"
            />
          </div>

          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Link:</label>
            <Input
              status={formik.errors.links ? 'error' : ''}
              name="links"
              value={formik.values.links}
              onChange={formik.handleChange}
              size="small"
            />
          </div>

          <div className="flex w-full gap-2">
            <label className="w-32 text-end">Ghi chú:</label>
            <Input
              name="ghiChu"
              value={formik.values.ghiChu}
              onChange={formik.handleChange}
              size="small"
            />
          </div>
          <div className="flex w-full gap-2">
            <label className="w-32 text-end ">Menu cha:</label>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onChange={handleMenuParentChange}
              options={listMenu?.map((value) => ({
                label: value.tenMenu,
                value: value.idMenu,
              }))}
              value={formik.values.idMenuCha}
              className="w-full truncate"
              placeholder="Chọn menu cha"
              size="small"
              name="idMenuCha"
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}

ModalEditMenu.propTypes = {}

export default ModalEditMenu
