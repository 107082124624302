import React, { useEffect, useState } from 'react'
import { Checkbox, Select, Input } from 'antd'

import moment from 'moment'
import { useSelector } from 'react-redux'
import { recieveService } from '../../../../../services/receive/recieveService'

const CardQuaTang = ({
  isQuaTang,
  handleChangeQuaTang,
  valueChuongTrinhQuaTang,
  setValueChuongTrinhQuaTang,
  valueQuaTang,
  valueSoLuongQuaTang,
  setValueSoLuongQuaTang,
  setFormQuaTang,
}) => {
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [khoQuaTang, setKhoQuaTang] = useState([])

  // Fetch gift warehouse data
  useEffect(() => {
    const fetchKhoQuaTang = async () => {
      try {
        const response = await recieveService.getKhoQuaTang(PkDangNhap.idChiNhanh)
        setKhoQuaTang(response.data)
      } catch (error) {
        console.error('Error fetching kho qua tang:', error)
      }
    }

    if (isQuaTang) {
      fetchKhoQuaTang()
    }
  }, [isQuaTang, PkDangNhap.idChiNhanh])

  // Handle gift selection
  const handleGiftChange = (value) => {
    setValueChuongTrinhQuaTang(value)
    setValueSoLuongQuaTang(1) // Reset quantity when changing gift

    const [idThuoc, soLo, hanDung, soHoaDon] = value.split('_')

    // Find the selected gift using all criteria
    const selectedGift = valueQuaTang.find(
      (item) =>
        item.idThuoc === Number(idThuoc) &&
        item.soLo === soLo &&
        item.hanDung === hanDung &&
        item.soHoaDon === soHoaDon,
    )

    if (selectedGift) {
      setFormQuaTang({
        idThuoc: selectedGift.idThuoc,
        tenBietDuoc: selectedGift?.thuocVatTu?.tenBietDuoc,
        soLuong: 0, // Reset to 0 when changing gift
        donGia: selectedGift.dongia,
        thanhTien: 0, // Reset to 0 when changing gift
        tongTien: 0, // Reset to 0 when changing gift
        nguoiThucHien: infoUser?.idnv,
        idKhoCN: selectedGift.idKhoCN,
        soLo: selectedGift.soLo,
        hanDung: selectedGift.hanDung,
        soHoaDon: selectedGift.soHoaDon || '',
      })
    }
  }

  // Handle quantity change
  const handleQuantityChange = (e) => {
    if (valueChuongTrinhQuaTang) {
      const [idThuoc, soLo, hanDung, soHoaDon] = valueChuongTrinhQuaTang.split('_')

      // Find the selected gift using all criteria
      const selectedGift = khoQuaTang.find(
        (item) =>
          item.idThuoc === Number(idThuoc) &&
          item.soLo === soLo &&
          item.hanDung === hanDung &&
          item.soHoaDon === soHoaDon,
      )

      if (selectedGift) {
        // Limit input value to available stock
        const inputValue = Number(e.target.value ?? 0)
        const newValue = Math.min(inputValue, selectedGift.tonKho || 0)

        // Ensure minimum value is 0
        const finalValue = Math.max(newValue, 0)

        setValueSoLuongQuaTang(finalValue)

        setFormQuaTang({
          idThuoc: selectedGift.idThuoc,
          tenBietDuoc: selectedGift?.thuocVatTu?.tenBietDuoc,
          donGia: selectedGift.dongia,
          soLuong: finalValue,
          thanhTien: finalValue * selectedGift.dongia,
          tongTien: finalValue * selectedGift.dongia,
          nguoiThucHien: infoUser?.idnv,
          idKhoCN: selectedGift.idKhoCN,
          soLo: selectedGift.soLo,
          hanDung: selectedGift.hanDung,
          soHoaDon: selectedGift.soHoaDon || '',
        })
      }
    }
  }

  return (
    <div className="flex justify-start items-start">
      <div className="flex w-full justify-start pl-[6.5%]">
        <label className="text-sm mr-2 gap-1 text-end">Quà tặng:</label>
        <Checkbox checked={isQuaTang} onChange={handleChangeQuaTang} />
        {isQuaTang ? (
          <Select
            style={{ color: 'black' }}
            className="w-[15.1rem] ml-2 text-start"
            size="small"
            value={valueChuongTrinhQuaTang}
            options={khoQuaTang?.map((item) => ({
              label: (
                <span>
                  <strong>{item?.thuocVatTu?.tenBietDuoc}</strong>{' '}
                  <span style={{ color: 'red' }}>({item.tonKho})</span>
                </span>
              ),
              value: `${item.idThuoc}_${item.soLo}_${item.hanDung}_${item.soHoaDon}`,
            }))}
            onChange={handleGiftChange}
          />
        ) : null}
      </div>
      <div className="flex w-full gap-1">
        {isQuaTang ? (
          <>
            <label className="text-sm font-semibold text-end">Số lượng:</label>
            <Input
              style={{ color: 'black' }}
              size="small"
              type="number"
              step={1}
              min={0}
              value={valueSoLuongQuaTang}
              onChange={handleQuantityChange}
              className="w-[120px] text-right"
            />
          </>
        ) : null}
      </div>
    </div>
  )
}

export default CardQuaTang
