import CachedIcon from '@mui/icons-material/Cached'
import {
  Button,
  ConfigProvider,
  DatePicker,
  Input,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  TreeSelect,
} from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CheckCircleOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { listBranchAction } from '../../../../store/actions/BranchAction'
import { branchNhapKhoPT } from '../../../../store/actions/NhapKhoAction'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { NhapKhoService } from '../../../../services/NhapKho/NhapKhoService'
import { branchService } from '../../../../services/branch/branchService'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { configSearchTableAnt } from '../../../../utils/configSearchTableAntd'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import 'moment/locale/vi'
import { addLog } from '../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../utils/log/logConstant'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
const { SHOW_PARENT } = TreeSelect
moment.locale('vi')
const DetaiKho = () => {
  const today = moment()
  const dateFormat = 'DD/MM/YYYY'
  const formatDateDB = 'YYYY-MM-DD'
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const dispatch = useDispatch()
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { infoUser } = useSelector((state) => state.userReducer)

  const [since, setSince] = useState(today.format(formatDateDB))
  const [toDate, SetToDate] = useState(today.format(formatDateDB))
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [idChiNhanh, setIdChiNhanh] = useState([+BranchLogin])
  const [branch, setBranch] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState(-1)
  const [data, setData] = useState([])
  const [loadingChiTiet, setLoadingChiTiet] = useState(false)
  const [valueExport, setValueExport] = useState([])
  const [searchText, setSearchText] = useState('')
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [search, setSearch] = useState(null)
  const [searchValues, setSearchValues] = useState({})
  const [filteredData, setFilteredData] = useState([])

  const maQuyenXemDs = 'QHT183'
  const maQuyenXuatExcel = 'QHT184'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        handleFilter(search, company, idChiNhanh, since, toDate)
        break
      case maQuyenXuatExcel:
        exportToExcel()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXuatExcel:
        //
        break
      default:
        return false
    }
  }
  const toloSLChan = data.reduce(
    (sum, record) => sum + (Math.floor(record?.soluongle / record?.soluongdonggoi) || 0),
    0,
  )
  const totalSoLuongLe = data.reduce((sum, record) => sum + (record.soluongle || 0), 0)
  const totalPrice = data.reduce((sum, record) => sum + (record.giaban || 0), 0)
  const handleDatePicker = (data, dateString) => {
    setSince(moment(dateString[0], dateFormat).format(formatDateDB))
    SetToDate(moment(dateString[1], dateFormat).format(formatDateDB))
  }
  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (idct, value) => {
    try {
      const { data } = await branchService.getBranchsByIdCtyAndIdChuyenKhoa(idct, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleCompany = (value) => {
    setCompany(value)
    // dispatch(branchNhapKhoPT(value))
    fetchBranchByChuyenKhoa(value, '')
    setIdChuyenKhoa(-1)
    fetchBranch(value)
    setIdChiNhanh(null)
  }

  // xử lí chọn chi nhánh
  const handleSelected = (value) => {
    setIdChiNhanh(value)
  }
  // xử lí lọc dưc liệu
  const handleFilter = async (keyword, idct, idChinhanh, tuNgay, denNgay) => {
    try {
      setLoadingChiTiet(true)
      let idcn = ''
      if (idChinhanh[0] !== 'all') {
        idcn = idChinhanh?.map((id) => `idcn=${id}`).join('&')
      } else {
        idChinhanh = branch?.map((item) => item.idChiNhanh)
        idcn = idChinhanh?.map((id) => `idcn=${id}`).join('&')
      }
      const { data } = await NhapKhoService.getDetailkhoXuat(keyword, idct, idcn, tuNgay, denNgay)
      const newData = data.map((item) => ({
        ...item,
        soLuongChan: Math.floor(item?.soluongle / item?.soluongdonggoi) ?? 0,
      }))
      setData(newData)
      const nameCompany = listCompany.find((item) => item.idct === idct)?.tenct
      const nameChiNhanh =
        idcn === '' ? 'Tất cả' : branch.find((item) => item.idChiNhanh === idcn)?.tenChiNhanh
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_LIST_CHI_TIET_HANG_CHUYEN_KHO,
        true,
        MENU_CONSTANTS.CHUYEN_KHO,
        null,
        {
          dateFrom: tuNgay,
          dateTo: denNgay,
          keyword,
          nameCompany,
          nameChiNhanh,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.CHUYEN_KHO,
        detailErrorPayload,
      )
    } finally {
      setLoadingChiTiet(false)
    }
  }
  const onSearch = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  const getListChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setListChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  // search
  const debounceDropDown = useCallback(
    _.debounce((e) => {
      setSearch(e.target.value)
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const fetchBranch = async (id) => {
    const { data } = await branchService.getListBranch(id)
    setBranch(data)
  }
  const onChange = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
      })),
    },
  ]
  const tProps = {
    treeData,
    value: idChiNhanh,
    onChange: setIdChiNhanh,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  useEffect(() => {
    if (branch.length) {
      onSearch()
    }
  }, [search, branch])
  useEffect(() => {
    dispatch(branchNhapKhoPT(company))
    dispatch(listBranchAction())
    getListChuyenKhoa()
    fetchBranchByChuyenKhoa(company, '')
  }, [])

  const exportToExcel = () => {
    try {
      const formattedData = valueExport?.map((item) => ({
        'Mã phiếu': item?.maphieu,
        'Tên phiếu': item?.tenphieu,
        'Số hóa đơn': item?.sohoadon,
        'Ngày HĐ': item?.ngayhoadon,
        'Ngày xuất': item?.ngayxuat ? moment(item.ngayxuat).format('DD/MM/YYYY HH:mm:ss') : '',
        'Chi nhánh chuyển': item?.tenchinhanh,
        'Kho chuyển': item?.tenkho,
        'Chi nhánh nhập': item?.tenchinhanhnhap,
        'Ngày nhập': item?.ngaynhan ? moment(item.ngaynhan).format('DD/MM/YYYY HH:mm:ss') : '',
        'Kho nhập': item?.tenkhonhap,
        'Mã hàng': item?.mathuoc,
        'Tên hoạt chất': item?.tenhoatchat,
        'Tên biệt dược': item?.tenbietduoc,
        'SL chẵn': Math.floor(item?.soluongle / item?.soluongdonggoi) ?? 0,
        'Đơn vị chẵn': item?.donvichan,
        'Giá bán': item?.giaban,
        'Quy cách': item?.quycach,
        'SL lẻ': item?.soluongle,
        'Mã số ĐK': item?.masodangky,
        'Số lô': item?.solo,
        'Hạn dùng': item?.handung ? moment(item?.handung).format('DD/MM/YYYY') : '',
        'Ghi chú': item?.ghichu,
        'Trạng thái': item?.trangthai,
      }))
      const name = `Chi tiết hàng - từ ${moment(since).format('DD-MM-YYYY')} đến ${moment(toDate).format('DD-MM-YYYY')}`
      formattedData?.length && exportExcelformat(formattedData, name)
      const nameCompany = listCompany.find((item) => item.idct === company)?.tenct
      const nameChiNhanh =
        idChiNhanh === ''
          ? 'Tất cả'
          : branch.find((item) => item.idChiNhanh === idChiNhanh)?.tenChiNhanh
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_LIST_CHI_TIET_HANG_CHUYEN_KHO,
        true,
        MENU_CONSTANTS.CHUYEN_KHO,
        null,
        {
          dateFrom: since,
          dateTo: toDate,
          keyword: search ?? '',
          nameCompany,
          nameChiNhanh,
        },
      )
    } catch (error) {
      console.log(error)
      const detailErrorPayload = `Có lỗi xảy ra khi xuất file excel \n ${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.CHUYEN_KHO,
        detailErrorPayload,
      )
    }
  }
  useEffect(() => {
    setValueExport(data)
  }, [data])
  const columnsDetails = [
    {
      title: 'STT',
      dataIndex: 'STT',
      align: 'center',
      key: 'STT',
      width: 40,
    },
    {
      title: 'Thông tin phiếu chuyển',
      dataIndex: 'STT',
      align: 'center',
      key: 'STT',
      children: [
        {
          title: 'Mã phiếu',
          dataIndex: 'maphieu',
          align: 'center',
          key: 'maphieu',
          width: 150,
          ...configSearchTableAnt(setSearchValues, searchValues, 'maphieu', data, 120, false),
        },

        {
          title: 'Tên phiếu',
          dataIndex: 'tenphieu',
          key: 'tenphieu',
          width: 350,
          ...configSearchTableAnt(setSearchValues, searchValues, 'tenphieu', data, 120, false), // config search table
        },
        {
          title: 'Số hoá đơn',
          dataIndex: 'sohoadon',
          align: 'center',
          key: 'sohoadon',
          width: 150,
          // onFilter: (value, record) => record.sohoadon.startsWith(value),
          // filters: getUniqueFilters(data, 'sohoadon'),
          // filterSearch: true,
          // ...getColumnSearchProps('sohoadon')
          ...configSearchTableAnt(setSearchValues, searchValues, 'sohoadon', data, 150, false),
        },
        {
          title: 'Ngày HĐ',
          dataIndex: 'ngayhoadon',
          align: 'center',
          key: 'ngayhoadon',
          width: 100,
          ...configSearchTableAnt(setSearchValues, searchValues, 'ngayhoadon', data, 100, true, {
            render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
          }),
        },
        {
          title: 'Ngày xuất',
          dataIndex: 'ngayxuat',
          key: 'ngayxuat',
          // fixed: 'left',
          width: 150,
          ...configSearchTableAnt(setSearchValues, searchValues, 'ngayxuat', data, 150, true, {
            render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
          }),
        },
        {
          title: 'Chi nhánh chuyển',
          dataIndex: 'tenchinhanh',
          width: 200,
          key: 'tenchinhanh',
          ...configSearchTableAnt(setSearchValues, searchValues, 'tenchinhanh', data, 170, false),
        },
        {
          title: 'Kho chuyển',
          dataIndex: 'tenkho',
          key: 'tenkho',
          width: 200,
          ...configSearchTableAnt(setSearchValues, searchValues, 'tenkho', data, 170, false),
        },
        {
          title: 'Chi nhánh nhập',
          dataIndex: 'tenchinhanhnhap',
          width: 250,
          key: 'tenchinhanhnhap',
          ...configSearchTableAnt(
            setSearchValues,
            searchValues,
            'tenchinhanhnhap',
            data,
            170,
            false,
          ),
        },
        {
          title: 'Ngày nhập',
          dataIndex: 'ngaynhan',
          key: 'ngaynhan',
          width: 150,
          // render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
          ...configSearchTableAnt(setSearchValues, searchValues, 'ngaynhan', data, 150, true, {
            render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
          }),
        },
        {
          title: 'Kho nhập',
          dataIndex: 'tenkhonhap',
          key: 'tenkhonhap',
          width: 200,
          ...configSearchTableAnt(setSearchValues, searchValues, 'tenkhonhap', data, 200, false),
        },
      ],
    },
    {
      title: 'Thông tin hàng hoá',
      dataIndex: 'tenThuocVatTu',
      key: 'tenThuocVatTu',
      align: 'center',
      children: [
        {
          title: 'Mã hàng',
          dataIndex: 'mathuoc',
          key: 'mathuoc',
          width: 120,
          ...configSearchTableAnt(setSearchValues, searchValues, 'mathuoc', data, 120, false),
        },
        {
          title: 'Tên hoạt chất ',
          dataIndex: 'tenhoatchat',
          key: 'tenThuocVatTu',
          width: 250,
          ...configSearchTableAnt(setSearchValues, searchValues, 'tenhoatchat', data, 200, false),
        },
        {
          title: 'Tên biệt dược',
          dataIndex: 'tenbietduoc',
          key: 'tenbietduoc',
          width: 250,
          ...configSearchTableAnt(setSearchValues, searchValues, 'tenbietduoc', data, 200, false),
        },

        {
          title: 'SL chẵn',
          dataIndex: 'soLuongChan',
          key: 'soLuongChan',
          align: 'center',
          width: 90,
          ...configSearchTableAnt(setSearchValues, searchValues, 'soLuongChan', data, 100, false),
        },
        {
          title: 'Đơn vị chẵn',
          dataIndex: 'donvichan',
          align: 'center',
          key: 'donvichan',
          width: 120,
          ...configSearchTableAnt(setSearchValues, searchValues, 'donvichan', data, 130, false),
        },
        {
          title: 'Giá bán',
          dataIndex: 'giaban',
          align: 'right',
          key: 'giaban',
          width: 120,
          render: (text) => (text ? formatNumberVND(text) : 0),
          ...configSearchTableAnt(setSearchValues, searchValues, 'giaban', data, 120, false),
        },
        {
          title: 'Quy cách',
          dataIndex: 'quycach',
          key: 'quycach',
          align: 'center',
          width: 120,
          ...configSearchTableAnt(setSearchValues, searchValues, 'quycach', data, 120, false),
        },
        {
          title: 'SL lẻ',
          dataIndex: 'soluongle',
          key: 'soluongle',
          align: 'center',
          width: 90,
          ...configSearchTableAnt(setSearchValues, searchValues, 'soluongle', data, 90, false),
        },
        {
          title: 'Mã số ĐK',
          dataIndex: 'masodangky',
          key: 'masodangky',
          align: 'left',
          width: 120,
          ...configSearchTableAnt(setSearchValues, searchValues, 'masodangky', data, 120, false),
        },
        {
          title: 'Số lô',
          dataIndex: 'solo',
          key: 'solo',
          align: 'center',
          width: 120,
          ...configSearchTableAnt(setSearchValues, searchValues, 'solo', data, 120, false),
        },
        {
          title: 'Hạn dùng',
          dataIndex: 'handung',
          key: 'handung',
          width: 120,
          align: 'center',
          ...configSearchTableAnt(setSearchValues, searchValues, 'handung', data, 120, true, {
            render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
          }),
          // ...getColumnSearchProps('hanDung')
        },
        {
          title: 'Ghi chú',
          dataIndex: 'ghichu',
          key: 'ghichu',
          width: 200,
          ...configSearchTableAnt(setSearchValues, searchValues, 'ghichu', data, 200, false),
        },
        {
          title: 'Trạng thái',
          dataIndex: 'trangthai',
          key: 'trangthai',
          width: 120,
          align: 'center',
          fixed: 'right',
          render: (text, record) => (
            <Tag
              className="font-semibold"
              icon={text === 3 ? <CheckCircleOutlined /> : text === 2 ? <SyncOutlined /> : null}
              color={text === 3 ? '#87d068' : text === 1 ? 'error' : '#108ee9'}
            >
              {record.tenTrangThai}
            </Tag>
          ),
          filters: [
            {
              text: 'Mới chuyển',
              value: 1,
            },
            {
              text: 'Đã rời kho',
              value: 2,
            },
            {
              text: 'Đã nhận',
              value: 3,
            },
          ],
          onFilter: (value, record) => record.trangthai === value,
        },
      ],
    },
  ]

  useEffect(() => {
    const filterData = () => {
      let filtered = data
      Object.keys(searchValues).forEach((key) => {
        if (searchValues[key]) {
          filtered = filtered.filter((item) =>
            item[key]?.toString().toLowerCase().includes(searchValues[key].toLowerCase()),
          )
        }
      })
      setFilteredData(filtered)
    }
    filterData()
  }, [searchValues, data])
  return (
    <>
      <div className="flex justify-between mb-5">
        <div className="flex gap-3 items-center w-full">
          <Input
            onChange={debounceDropDown}
            placeholder="Tìm mã phiếu, tên phiếu nhập, số hóa đơn"
            allowClear
            prefix={
              <SearchOutlined
                style={{
                  color: 'rgba(0,0,0,.25)',
                }}
              />
            }
          />
          <Select
            onChange={handleCompany}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            value={company}
            className="min-w-64"
          />
          <Select
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={idChuyenKhoa}
            showSearch
            onChange={(value) => {
              fetchBranchByChuyenKhoa(company, value)
              setIdChiNhanh(['all'])
              setIdChuyenKhoa(value)
            }}
            defaultValue={-1}
            options={[
              { label: 'Tất cả', value: -1 },
              ...listChuyenKhoa?.map((item) => ({
                label: item.tenChuyenKhoa,
                value: item.idChuyenKhoa,
              })),
            ]}
            className="min-w-64"
          />
          <div className="min-w-64">
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <DatePicker.RangePicker
            className="min-w-60"
            allowClear={false}
            value={[dayjs(since, formatDateDB), dayjs(toDate, formatDateDB)]}
            format={dateFormat}
            onChange={handleDatePicker}
          />
          <Button type="primary" onClick={onSearch} icon={<CachedIcon sx={{ fontSize: 25 }} />}>
            Tìm
          </Button>
          <div className="flex gap-3 ml-auto">
            <Button
              onClick={() =>
                dispatch(
                  putCheckQuyenThaoTacAction(maQuyenXuatExcel, null, actionIsTrue, actionIsFalse),
                )
              }
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
              disabled={data?.length === 0}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>
      <div className=" h-full">
        <ConfigProvider
          theme={{
            token: {
              padding: 4,
              fontSize: 12,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            onChange={onChange}
            loading={loadingChiTiet}
            scroll={{
              x: filteredData?.length > 0 ? 'max-content' : 3800, // Đảm bảo bảng có thể cuộn ngang khi nội dung vượt quá chiều rộng
              y: '60vh', // Sử dụng y nếu filteredData > 0, ngược lại thì không sử dụng y
            }}
            bordered
            pagination={false}
            columns={columnsDetails}
            dataSource={filteredData?.map((item, index) => ({
              STT: index + 1,
              ...item,
              trangthai: item.trangthaichuyenkho,
              tenTrangThai: item.trangthai,
              status: item.trangthaichuyenkho === 5 ? 'Chưa nhập kho' : 'Đã chuyển kho',
            }))}
            summary={(pageData) => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    {Array.from({ length: 25 }, (_, index) => (
                      <Table.Summary.Cell key={index} index={index} align="center">
                        {index === 1 && (
                          <Table.Summary.Cell colSpan={2} align="center">
                            <Typography.Text strong>Số lượng: {pageData.length}</Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {index === 14 && (
                          <Typography.Text type="danger">
                            {formatNumberVND(toloSLChan)}
                          </Typography.Text>
                        )}
                        {index === 16 && (
                          <Typography.Text type="danger">
                            {formatNumberVND(totalPrice)}
                          </Typography.Text>
                        )}
                        {index === 18 && (
                          <Typography.Text type="danger">
                            {formatNumberVND(totalSoLuongLe)}
                          </Typography.Text>
                        )}
                      </Table.Summary.Cell>
                    ))}
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default DetaiKho
