import { call, put, select, takeLatest } from 'redux-saga/effects'
import { goikhamkhachhangCRMService } from '../../services/goikhamkhachhangCRM/goikhamkhachhangCRMService'
import ToastCus from '../../utils/Toast'
import * as typeAction from '../constants/constants'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'

export function* goikhamkhachhangCRMSaga() {
  yield takeLatest(
    typeAction.GET_ALL_GOIKHAM_KHACHHANG_CRM,
    function* allGoiKhamKhachHangCRM({ type, payload }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const result = yield call(() => goikhamkhachhangCRMService.getAllGoiKhamKhachHangCRM())
        yield put({
          type: typeAction.DISPATCH_ALL_GOIKHAM_KHACHHANG_CRM,
          payload: result.data,
        })
      } catch (err) {
        console.log(err)
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  //add
  yield takeLatest(
    typeAction.ADD_GOIKHAM_KHACHHANG_CRM,
    function* addGoiKhamKhachHangCRM({ type, form }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => goikhamkhachhangCRMService.addGoiKhamKhachHangCRM(form))
        ToastCus.fire({
          icon: 'success',
          title: 'Thêm mới thành công',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.ADD_GOI_KHAM_KHACH_HANG,
          true,
          MENU_CONSTANTS.GOI_KHAM_KHACH_HANG,
          null,
          {
            form,
          },
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thêm mới thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.GOI_KHAM_KHACH_HANG,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  //edit
  yield takeLatest(
    typeAction.EDIT_GOIKHAM_KHACHHANG_CRM,
    function* editGoiKhamKhachHangCRM({ type, form }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        yield call(() => goikhamkhachhangCRMService.editGoiKhamKhachHangCRM(form))
        const result = yield call(() =>
          goikhamkhachhangCRMService.getGoiKhamKhachHangCRMById(form[0].idkh),
        )
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          logAction.EDIT_GOI_KHAM_KHACH_HANG,
          true,
          MENU_CONSTANTS.GOI_KHAM_KHACH_HANG,
          null,
          {
            form,
          },
        )
        yield put({
          type: typeAction.DISPATCH_INFO_GOIKHAM_KHACHHANG_CRM,
          payload: result.data.result,
        })
        ToastCus.fire({
          icon: 'success',
          title: 'Sửa thông tin thành công',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Sửa thông tin thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.GOI_KHAM_KHACH_HANG,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )

  yield takeLatest(typeAction.GET_GOIKHAM_KHACHHANG_CRM_BY_ID, function* getById({ type, id }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const result = yield call(() => goikhamkhachhangCRMService.getGoiKhamKhachHangCRMById(id))
      yield put({
        type: typeAction.DISPATCH_INFO_GOIKHAM_KHACHHANG_CRM,
        payload: result.data.result,
      })
    } catch (err) {
      console.log(err)
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
}
