import axios from 'axios'
import { https } from '../apiService'

export const khoVTservice = {
  getAllVT: () => https.get('/ThuocVatTu/AllThuoc'),
  addThuocVT: (form) => https.post('ThuocVatTu/ThemThuocVatTu', form),
  deleteThuocVT: (id) => https.delete(`ThuocVatTu/${id}`),
  getInfoVT: (id) => https.get(`ThuocVatTu/find/${id}`),
  getListCountry: () => https.get('QuocTich'),
  getListTonkho: (id) => https.get(`TonKho/ChiNhanh/${id}`),
  getTonKhoByConditionForData: (
    IDCongTy,
    IDChiNhanh,
    IDKho,
    IDPhanLoai,
    IDNhom,
    toDate,
    //  pageNumber,
    keyword,
    IDChuyenKhoa,
  ) =>
    https.get(`TonKho/GetTonKhoDenNgay?IDCongTy=${IDCongTy}${IDChiNhanh}`, {
      params: {
        IDKho,
        IDPhanLoai,
        IDNhom,
        toDate,
        //  pageNumber,
        keyword,
        IDChuyenKhoa,
      },
    }),
  getTonKhoByConditionForDataHangHoa: (
    IDCongTy,
    IDChiNhanh,
    IDKho,
    IDPhanLoai,
    IDNhom,
    toDate,
    //  pageNumber,
    keyword,
    IDChuyenKhoa,
  ) =>
    https.get(`TonKho/GetTonKhoHangHoaDenNgay?IDCongTy=${IDCongTy}${IDChiNhanh}`, {
      params: {
        IDKho,
        IDPhanLoai,
        IDNhom,
        toDate,
        //  pageNumber,
        keyword,
        IDChuyenKhoa,
      },
    }),
  getTonFromToDay: (IDCongTy, IDChiNhanh, IDKho, toDate, fromDate, keyword, IDChuyenKhoa) =>
    https.get(`TonKho/TonKhoTuNgayDenNgay?IDCongTy=${IDCongTy}${IDChiNhanh}`, {
      params: {
        IDKho,
        toDate,
        fromDate,
        keyword,
        IDChuyenKhoa,
      },
    }),
  getTonFromToDayHangHoa: (IDCongTy, IDChiNhanh, IDKho, toDate, fromDate, keyword, IDChuyenKhoa) =>
    https.get(`TonKho/TonKhoHangHoaTuNgayDenNgay?IDCongTy=${IDCongTy}${IDChiNhanh}`, {
      params: {
        IDKho,
        toDate,
        fromDate,
        keyword,
        IDChuyenKhoa,
      },
    }),
  getTonKhoCondition: (IDCongTy, IDChiNhanh, IDKhoCN) =>
    https.get('TonKho/GetTonKhoByCondition', {
      params: {
        IDCongTy,
        IDChiNhanh,
        IDKhoCN,
      },
    }),
  getListTonkhoByKhoCN: (idKhoCN) => https.get(`TonKho/${idKhoCN}`),
  getThuocVT: (page) => https.get(`ThuocVatTu/GetPage?pageIndex=${page}`),
  getListTypesThuocVT: () => https.get('ThuocVatTuPhanLoai'),
  getListGroupsThuocVT: (idTypes) =>
    https.get(`ThuocVatTuNhom/SearchNhomThuoc?idPhanLoai=${idTypes}`),
  getAllGroupThuocVT: () => https.get('ThuocVatTuNhom'),
  updateThuocVTById: (form) => https.put(`ThuocVatTu/${form.idThuoc}`, form),
  SearchByCondition: (keyword, idCT, idPhanLoai, idNhom, pageIndex) =>
    https.get('ThuocVatTu/SearchByCondition', {
      params: {
        keyword,
        idCT,
        idPhanLoai,
        idNhom,
        pageIndex,
      },
    }),
  SearchByConditionMsdk: (keyword, idCT, idPhanLoai, idNhom, pageIndex) =>
    https.get('ThuocVatTu/SearchThuocVatTuTruTaiSan', {
      params: {
        keyword,
        idCT,
        idPhanLoai,
        idNhom,
        pageIndex,
      },
    }),
  SearchByConditionChoGiaBan: (keyword, idCT, idPhanLoai, idNhom, tuNgay, denNgay, pageIndex) =>
    https.get('ThuocVatTu/SearchThuocVatTuGiaBan', {
      params: {
        keyword,
        idCT,
        idPhanLoai,
        idNhom,
        tuNgay,
        denNgay,
        pageIndex,
      },
    }),
    SearchByConditionHistoryGPP: (keyword, idCT, idPhanLoai, idNhom, tuNgay, denNgay, pageIndex) =>
      https.get('ThuocVatTu/SearchThuocVatTuGiaGPP', {
        params: {
          keyword,
          idCT,
          idPhanLoai,
          idNhom,
          tuNgay,
          denNgay,
          pageIndex,
        },
      }),
  SearchByConditionTheoListIdNhom: (keyword, idCT, idPhanLoai, idNhom, pageIndex) =>
    https.get(
      `ThuocVatTu/SearchThuocVatTu?${keyword?.length ? `keyword=${keyword}&` : ''}idCT=${idCT}${idPhanLoai ? `&idPhanLoai=${idPhanLoai}` : ''}${idNhom}&pageIndex=${pageIndex}`,
    ),
  SearchByConditionHangThuoc: (keyword, idCT, idPhanLoai, idNhom, pageIndex) =>
    https.get(
      `ThuocVatTu/GetDanhSachLoaiThuoc?${keyword?.length ? `keyword=${keyword}&` : ''}idCT=${idCT}${idPhanLoai ? `&idPhanLoai=${idPhanLoai}` : ''}${idNhom}&pageIndex=${pageIndex}`,
    ),
  SearchByConditionHangvt: (keyword, idCT, idPhanLoai, idNhom, pageIndex) =>
    https.get(
      `ThuocVatTu/GetDanhSachLoaiHangHoa?${keyword?.length ? `keyword=${keyword}&` : ''}idCT=${idCT}${idPhanLoai ? `&idPhanLoai=${idPhanLoai}` : ''}${idNhom}&pageIndex=${pageIndex}`,
    ),
  getListBranchBuy: (id, idct, idChuyenKhoa) =>
    https.get(
      `ThuocVatTuChiNhanhBan/ByIDThuoc?idThuoc=${id}&idCT=${idct}&idChuyenKhoa=${idChuyenKhoa}`,
    ),
  putBranchBuy: (form) => https.put('ThuocVatTuChiNhanhBan/UpdateTrangThaiBan', form),
  getAllChuyenKhoa: () => https.get('ChuyenKhoa/AllChuyenKhoa'),
  getMSDKThuoc: (idThuoc) =>
    https.get(`ThuocVatTuMSDK/GetThuocVatTuMSDKByIDTHUOC?idThuoc=${idThuoc}`),
  fetchDuongDung: () => https.get('DuongDung/GetAllDuongDung'),
  getTheKho: (IDCT, IDChiNhanh, IDKhoCN, fromDate, toDate, IDThuoc, IDChuyenKhoa) =>
    https.get(`TheKho/ByCondition?IDCT=${IDCT}${IDChiNhanh}`, {
      params: {
        IDKhoCN,
        fromDate,
        toDate,
        IDThuoc,
        IDChuyenKhoa,
      },
    }),
  checkMaHangHoa: (id, data) => https.post(`ThuocVatTu/CheckMaThuoc/${id}`, data),
  getTonKhoTongHop: (IDCongTy, IDChiNhanh, IDKho, IDPhanLoai, IDNhom, keyword, IDChuyenKhoa) =>
    https.get(`TonKho/GetTonKhoTongHop?IDCongTy=${IDCongTy}${IDChiNhanh}`, {
      params: {
        IDKho,
        IDPhanLoai,
        IDNhom,
        keyword,
        IDChuyenKhoa,
      },
    }),
  getTonKhoTongHopHangHoa: (
    IDCongTy,
    IDChiNhanh,
    IDKho,
    IDPhanLoai,
    IDNhom,
    keyword,
    IDChuyenKhoa,
  ) =>
    https.get(`TonKho/GetTonKhoHangHoaTongHop?IDCongTy=${IDCongTy}${IDChiNhanh}`, {
      params: {
        IDKho,
        IDPhanLoai,
        IDNhom,
        keyword,
        IDChuyenKhoa,
      },
    }),
  getThuocVatTuGiaBan: (keyword, idct, idnhom, idType) =>
    https.get(
      `ThuocVatTu/GetThuocVatTuGiaBan?keyword=${keyword}&idct=${idct}${idnhom}&idloai=${idType}`,
    ),
  SearchThuocCanhBao: (keyword, idCT, idPhanLoai, idNhom) =>
    https.get('ThuocVatTu/SearchThuocCanhBao', {
      params: {
        keyword,
        idCT,
        idPhanLoai,
        idNhom,
      },
    }),
  updateGiaChiNhanhBan: (giaBan, idNV, idThuoc, idCT, idChuyenKhoa) =>
    https.put(
      `ThuocVatTuChiNhanhBan/UpdateGiaThuocChiNhanhBan?giaBan=${giaBan}&idNV=${idNV}&idCT=${idCT}&idThuoc=${idThuoc}&idChuyenKhoa=${idChuyenKhoa}`,
    ),
  getListKhoByIdCty: (IDCT) => https.get(`Kho/GetByCongTy`, { params: { IDCT } }),
  getGiaNhapTungLan: (keyword, idnhom, tuNgay, denNgay) =>
    https.get(
      `ThuocVatTu/GetThuocVatTuGiaNhapTungLan?${keyword?.length ? `keyword=${keyword}&` : ''}${idnhom}tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
  getGiaNhapTrungBinh: (keyword, idnhom, tuNgay, denNgay) =>
    https.get(
      `ThuocVatTu/GetThuocVatTuGiaNhapTrungBinh?${keyword?.length ? `keyword=${keyword}&` : ''}${idnhom}tuNgay=${tuNgay}&denNgay=${denNgay}`,
    ),
}
