import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  SaveOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import {
  Typography,
  ConfigProvider,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import ShowNotification from '../../../common/ShowNotification/ShowNotification'
import {
  ListDichVuAction,
  addVTTHService,
  deleteVTHHByService,
  getVTHHByService,
  listGroupsDichVuAction,
  searchDanhMucDVAction,
  updateVTHHByService,
} from '../../../../store/actions/dichVuAction'
import { formatNumberVND } from '../../../../utils/formatNumberVND'
import { searchThuocVT } from '../../../../store/actions/NhapKhoAction'
import { render } from 'bwip-js'
const { confirm } = Modal
const { Text } = Typography
const DichVu = () => {
  const dispatch = useDispatch()
  const { ListDichVu, groupsDichVu } = useSelector((state) => state.dichVuReducer)
  const { VTTHService } = useSelector((state) => state.dichVuReducer)
  const { contextHolder, openNotificationWithIcon } = ShowNotification()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const { thuocVT } = useSelector((state) => state.NhapKhoReducer)
  const [loadingTable, setLoadingTable] = useState(false)
  const [company, setCompany] = useState(+localStorage.getItem('id_company'))
  const [searchGroup, setSearchGroup] = useState(1)
  const [rowActive, setRowActive] = useState(null)
  const [loadingCN, setLoadingCN] = useState(false)
  const [show, setShow] = useState(false)
  const [data, setData] = useState(VTTHService)
  const [editingKey, setEditingKey] = useState('')
  const [editTable, setEditTable] = useState(false)
  const isEditing = (record) => record.key === editingKey
  const edit = (record) => {
    setEditTable(true)
    setEditingKey(record.key)
  }
  // handle delete danh mục xét nghiệm
  const showDeleteConfirm = (item) => {
    confirm({
      title: 'Xoá hàng vật tư tiêu hao ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <p>
          Bạn có chắc muốn xóa hàng này không:{' '}
          <span className="font-semibold">
            {item.mathuoc} - {item.tenbietduoc}
          </span>{' '}
        </p>
      ),
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ bỏ',
      onOk() {
        dispatch(deleteVTHHByService(item))
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  }
  useEffect(() => {
    dispatch(ListDichVuAction(searchGroup, company))
    dispatch(listGroupsDichVuAction())
    dispatch(listAllCompanyAction())
  }, [])

  const handleCompany = (value) => {
    setRowActive(null)
    setCompany(value)
    dispatch(ListDichVuAction(searchGroup, value))
  }

  const handleSearch = useCallback(
    debounce((keyword, company, searchGroup) => {
      //search theo tất cả
      dispatch(searchDanhMucDVAction(searchGroup, company, keyword))
    }, 300),
    [],
  )
  const debounceDropDown = useCallback(
    _.debounce((keyword, company) => {
      dispatch(searchThuocVT(keyword, company))
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const handleSearchVT = (keyword) => {
    debounceDropDown(keyword, company)
  }

  const columns = useMemo(
    () => [
      {
        title: 'STT',
        dataIndex: 'STT',
        width: 40,
        align: 'center',
      },
      {
        title: 'Mã dịch vụ',
        dataIndex: 'maDichVu',
        width: 200,
      },
      {
        title: 'Tên dịch vụ',
        dataIndex: 'tenDichVu',
      },
      {
        title: 'Đơn giá',
        dataIndex: 'donGia',
        width: 100,
        align: 'center',
        render: (text) => formatNumberVND(text),
      },
      {
        title: 'Tiền gửi mẫu',
        dataIndex: 'tienGuiMau',
        width: 100,
        align: 'center',
      },
      {
        title: 'Đơn vị tính',
        dataIndex: 'donVi',
        align: 'center',
        width: 150,
      },
      {
        title: 'Ghi chú',
        dataIndex: 'ghiChu',
        align: 'center',
        width: 250,
      },
    ],
    [],
  )
  const save = (key) => {
    const index = data.findIndex((item) => key === item.iddvvtth)
    dispatch(updateVTHHByService(data[index]))
  }
  const onChange = (value) => {
    setSearchGroup(value)
    // setIdChuyenKhoa(groupsDichVu.find((item) => item.idNhom === value).idChuyenKhoa)s
    dispatch(ListDichVuAction(value, company))
  }
  const reloadVTTHByService = (id) => {
    dispatch(getVTHHByService(id))
  }
  const handleClickRow = (row) => {
    setRowActive(row)
    reloadVTTHByService(row.iddv)
  }
  const handleSelected = (value) => {
    const form = {
      iddv: rowActive?.iddv,
      idthuoc: value,
      nguoitao: infoUser?.idnv,
      soluong: 1,
    }
    if (!rowActive)
      return openNotificationWithIcon('warning', 'Thêm vật tư tiêu hao', 'Vui lòng chọn dịch vụ')
    dispatch(addVTTHService(form))
  }
  const onChangeInput = (key, name, value) => {
    const index = data.findIndex((item) => key === item.iddvvtth)
    if (index !== -1) {
      const item = { ...data[index], [name]: Number(value) }
      const newData = [...data]
      newData[index] = item
      setData(newData)
    }
  }
  const columnsCN = [
    {
      title: 'STT',
      dataIndex: 'stt',
      render: (text, _, index) => ++index,
      width: 60,
      key: 1,
    },
    {
      title: 'Mã hàng',
      dataIndex: 'mathuoc',
      key: 2,
      width: 100,
    },
    {
      title: 'Tên Hàng',
      dataIndex: 'tenbietduoc',
      key: 3,
    },
    {
      title: 'SL',
      dataIndex: 'soluong',
      key: 4,
      width: 80,
      align: 'center',
      render: (text, record, index) => (
        <>
          <InputNumber
            variant={isEditing(record) ? 'outlined' : 'borderless'}
            readOnly={!isEditing(record)}
            onChange={(value) => onChangeInput(record.key, 'soluong', value)}
            value={text}
            min={0}
            formatter={(value) => ` ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            size="small"
            className="w-16"
          />
        </>
      ),
    },
    {
      title: 'Đơn vị',
      dataIndex: 'dvt',
      key: 5,
      width: 80,
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      width: 90,
      key: 6,
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span className="flex justify-around">
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              <Tooltip title="Lưu" color="blue">
                <SaveOutlined style={{ fontSize: 18 }} />
              </Tooltip>
            </Typography.Link>
            <Typography.Link
              onClick={() => {
                setEditingKey('')
                setEditTable(false)
                setData(VTTHService)
              }}
            >
              <Tooltip title="Huỷ">
                <CloseOutlined />
              </Tooltip>
            </Typography.Link>
          </span>
        ) : (
          <ul className="flex justify-around">
            <li>
              <Tooltip title="Chỉnh sửa" color="green">
                <EditOutlined
                  onClick={() => {
                    if (editingKey !== '') return
                    edit(record)
                  }}
                  className={`text-xl ${editingKey !== '' ? 'text-gray-500' : 'text-green-500'}  cursor-pointer`}
                />
              </Tooltip>
            </li>
            <li>
              <Tooltip title="Xoá" color="red">
                <DeleteOutlined
                  onClick={(e) => {
                    if (editingKey !== '') return
                    e.stopPropagation()
                    showDeleteConfirm(record)
                  }}
                  className={`text-xl ${editingKey !== '' ? 'text-gray-500' : 'text-red-500'}  cursor-pointer`}
                />
              </Tooltip>
            </li>
          </ul>
        )
      },
    },
  ]
  useEffect(() => {
    setData(VTTHService)
    setEditingKey('')
  }, [VTTHService])
  return (
    <>
      <div className="flex w-full items-center gap-5  bg-white rounded-md">
        <div className="flex w-full gap-3">
          <Select
            showSearch
            className="w-80"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={handleCompany}
            value={company}
            options={listCompany?.map((items) => ({
              label: items.tenct,
              value: items.idct,
            }))}
          />
          <Select
            onChange={onChange}
            value={searchGroup}
            defaultValue={-1}
            className="w-80"
            options={[
              ...(groupsDichVu
                ? groupsDichVu.map((items) => ({
                    label: items.tenNhom,
                    value: items.idNhom,
                  }))
                : []),
            ]}
          />
          <Input.Search
            onChange={(e) => {
              handleSearch(e.target.value, company, searchGroup)
            }}
            className="w-80"
            placeholder="Nhập từ khoá tìm kiếm"
          />
        </div>
      </div>
      <div className="flex pr-2 h-full border mt-2 rounded">
        <div className="w-1/2 h-full bg-white  border-r">
          <div className="p-2 h-full">
            <div>
              <ConfigProvider
                theme={{
                  token: {
                    padding: 5,
                    fontSize: 14,
                  },
                  components: {
                    Table: {
                      rowHoverBg: '#ecf0f1',
                      headerBg: '#e6e6e6',
                      footerBg: '#e6e6e6',
                      borderColor: '#BABABA',
                    },
                  },
                }}
              >
                <Table
                  loading={loadingTable}
                  pagination={false}
                  bordered
                  scroll={{
                    x: 1500,
                    y: '72vh',
                  }}
                  onRow={(row) => {
                    return {
                      onClick: () => {
                        handleClickRow(row)
                      }, // click row
                    }
                  }}
                  columns={columns}
                  dataSource={ListDichVu?.map((item, index) => ({
                    STT: ++index,
                    ...item,
                  }))}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
        <div className="w-1/2 h-full  p-2">
          <div className="flex items-center gap-2 mb-1">
            <span className="font-semibold w-24">Tên dịch vụ:</span>
            <Input value={rowActive?.tenDichVu} readOnly variant="filled" />
          </div>
          <Select
            className="w-full mb-1"
            // size="small"
            showSearch
            allowClear
            onChange={handleSelected}
            placeholder="Nhập tên vật tư hàng hoá "
            value={null}
            defaultActiveFirstOption={false}
            prefix={null}
            filterOption={false}
            onSearch={handleSearchVT}
            notFoundContent={null}
            options={(thuocVT || []).map((d, index) => ({
              key: index,
              value: d.idThuoc,
              label: (
                <u className="flex no-underline">
                  <li className="flex w-[20%]">
                    <p>{d.maThuoc}</p>
                  </li>
                  <li className="flex w-[80%]">
                    <p className=" w-full ">{d.tenBietDuoc}</p>
                  </li>
                </u>
              ),
            }))}
          />
          <div>
            <ConfigProvider
              theme={{
                token: {
                  padding: 5,
                },
                components: {
                  Table: {
                    rowHoverBg: '#ecf0f1',
                    headerBg: '#e6e6e6',
                    footerBg: '#e6e6e6',
                    borderColor: '#BABABA',
                  },
                },
              }}
            >
              <Table
                rowKey="key"
                loading={loadingCN}
                scroll={{ y: 700 }}
                // loading={isLoadingBranch}
                pagination={false}
                columns={columnsCN}
                dataSource={data?.map((item) => ({
                  ...item,
                  key: item.iddvvtth,
                }))}
                bordered
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
      {contextHolder}
    </>
  )
}

export default DichVu
