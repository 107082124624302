import React, { useEffect, useState } from 'react'
import { ConfigProvider, Input, Select, Table, notification, DatePicker, Alert } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'
import { khamSangLocService } from '../../../../../services/khamSangLoc/khamSangLocService'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import * as typeAction from '../../../../../store/constants/constants'
import ToastCus from '../../../../../utils/Toast'
import _ from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const now = moment()
const ToaThuoc = ({disabledDate}) => {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listTonVacXin } = useSelector((state) => state.khamSangLocReducer)
  const { ThuocBN, DetailToaThuocBN, DetailBN } = useSelector(
    (state) => state.childrenClinicReducer,
  )
  const [api, contextHolder] = notification.useNotification()
  const checkDuocSiChuaTraToa =
    DetailBN?.trangthaikhambenh !== 1 &&
    (DetailToaThuocBN?.BSKEDON || DetailToaThuocBN?.NGAYKEDON) &&
    DetailToaThuocBN?.DANHANTOA === 1

  const [isQuyenKeToa, setisQuyenKeToa] = useState(false)
  const [isLoadQuyen, setisLoadQuyen] = useState(false)

  const openNotificationWithIcon = (message, description) => {
    api.warning({
      message,
      description,
    })
  }
  const handleValue = (ID) => (e) => {
    if (checkDuocSiChuaTraToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    const { name, value } = e.target
    const newValue = Number(value)
    const index = ThuocBN?.findIndex((items) => items.ID === ID)
    if (newValue > ThuocBN[index].ton) {
      openNotificationWithIcon('Kê số lượng thuốc', 'Số lượng tồn  kho không đủ!')
      return
    }
    dispatch({
      type: typeAction.DISPATCH_SL_THUOC_BN,
      payload: {
        index,
        value: newValue,
      },
    })
  }
  const handleLieuDung = (ID) => (e) => {
    if (checkDuocSiChuaTraToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    const { name, value } = e.target
    const newValue = Number(value)
    const index = ThuocBN?.findIndex((items) => items.ID === ID)
    dispatch({
      type: typeAction.DISPATCH_LIEU_DUNG,
      payload: {
        index,
        value: newValue,
      },
    })
  }
  const handleMui = (ID) => (e) => {
    if (checkDuocSiChuaTraToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    const { name, value } = e.target
    const index = ThuocBN?.findIndex((items) => items.ID === ID)
    dispatch({
      type: typeAction.DISPATCH_MUI_TOA_THUOC_BN,
      payload: {
        index,
        value: value,
      },
    })
  }
  const handleghichu = (ID) => (e) => {
    if (checkDuocSiChuaTraToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    const { name, value } = e.target
    const index = ThuocBN?.findIndex((items) => items.ID === ID)
    dispatch({
      type: typeAction.DISPATCH_GHI_CHU_TOA_THUOC_BN,
      payload: {
        index,
        value,
      },
    })
  }
  const handlengayhentiem = (ID) => (date) => {
    if (checkDuocSiChuaTraToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    const index = ThuocBN?.findIndex((items) => items.ID === ID)
    dispatch({
      type: typeAction.DISPATCH_NGAYHENTIEM_TOA_THUOC_BN,
      payload: {
        index: index,
        value: date ? dayjs(date).format('YYYY-MM-DD') : null,
      },
    })
  }
  const handleVacXin = async (id) => {
    if (checkDuocSiChuaTraToa) {
      ToastCus.fire({
        icon: 'error',
        title: 'Dược sĩ đang thao tác!',
      })
      return
    }
    if (!DetailBN) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn bệnh nhân!',
      })
      return
    }
    const index = listTonVacXin?.findIndex((items) => items.ID === id)
    for (const items of ThuocBN) {
      if (items.ID === index) {
        openNotificationWithIcon('Chọn thuốc kê toa', 'Thuốc đã được chọn!')
        return
      }
    }
    const infoThuoc = {
      ID: listTonVacXin[index].ID,
      ton: listTonVacXin[index].tonKho,
      idthuoc: listTonVacXin[index].idThuoc,
      tenBietDuoc: listTonVacXin[index].thuocVatTu.tenBietDuoc,
      tenhoatchat: listTonVacXin[index].thuocVatTu.tenHoatChat,
      duongDung: listTonVacXin[index].thuocVatTu.duongDung,
      dvt: listTonVacXin[index].thuocVatTu.dvt,
      hamLuong: listTonVacXin[index].thuocVatTu.hamLuong,
      soLuong: 1,
      muitiem: 1,
      taikhamtiemchung: null,
      ngayhentiem: null,
      bsKeDon: infoUser?.tenNV,
      //   ngayKeDon: "2024-05-23T16:39:02.286Z",
      ghiChu: '',
      lieuDung: 0.5,
      soLanNgay: null,
      soLo: listTonVacXin[index].soLo,
      idkhocn: listTonVacXin[index].idKhoCN,
      idbnttc: DetailBN?.idbnttc,
      hanDung: listTonVacXin[index].hanDung,
      soHoaDon: `${listTonVacXin[index].soHoaDon}`,
      maSoDangKy: listTonVacXin[index].maSoDangKy,
    }
    try {
      const birthDate = moment(DetailBN?.benhNhan?.ngaySinh, 'YYYY-MM-DD')
      const today = moment()
      const ageDays = today.diff(birthDate, 'days')
      await khamSangLocService.checkCanhBaoDoTuoi(infoThuoc?.idthuoc, ageDays)
      await khamSangLocService.checkCanhBaoMuiTiem(infoThuoc?.idthuoc, DetailBN?.idbn)
      await khamSangLocService.checkCanhBaoHoatChat({
        idthuocchinh: infoThuoc?.idthuoc,
        idthuocphus: ThuocBN?.map((item) => item.idthuoc) ?? [],
      })
      await khamSangLocService.checkCanhBaoKhoangCach(infoThuoc?.idthuoc, DetailBN?.idbn)
      dispatch({
        type: typeAction.DISPATCH_THUOC_BN_DOCTOR,
        payload: infoThuoc,
      })
    } catch (error) {
      console.log(error)
      openNotificationWithIcon('Cảnh báo vắc xin', `${error?.response?.data?.message}!`)
    }
  }
  const checkQuyenKeToa = async () => {
    try {
      setisLoadQuyen(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT134',
      )

      setisQuyenKeToa(true)
    } catch (error) {
      console.error('Error : ', error)
      setisQuyenKeToa(false)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    } finally {
      setisLoadQuyen(false)
    }
  }
  useEffect(() => {
    checkQuyenKeToa()
  }, [DetailBN])
  useEffect(() => {
    dispatch({
      type: typeAction.RESET_TOA_THUOC_BN_DOCTOR,
      payload: [],
    })
    if (DetailToaThuocBN?.DanhSachThuoc?.length > 0) {
      for (const item of DetailToaThuocBN?.DanhSachThuoc) {
        const index = listTonVacXin?.findIndex(
          (itemVT) =>
            itemVT?.idThuoc === item?.idthuoc &&
            itemVT?.soLo === item?.solo &&
            itemVT?.hanDung === item?.handung &&
            itemVT?.soHoaDon === item?.sohoadon,
        )
        if (item.idnhomthuoc !== 179 && item.idnhomthuoc !== 205) {
          // không lấy gọng kính trong toa cũ của khách
          dispatch({
            type: typeAction.DISPATCH_THUOC_BN_DOCTOR,
            payload: {
              ID:
                index !== -1
                  ? index
                  : `${item?.idthuoc}${item?.solo}${item?.handung}${item?.sohoadon}`,
              ton:
                DetailBN?.trangthaikhambenh === 1 ||
                (DetailBN?.trangthaikhambenh === 2 && !DetailToaThuocBN?.DANHANTOA)
                  ? (index === -1 || !listTonVacXin?.length ? 0 : listTonVacXin[index]?.tonKho) +
                    item?.soluong
                  : index === -1 || !listTonVacXin?.length
                    ? 0
                    : listTonVacXin[index]?.tonKho,
              idthuoc: item.idthuoc,
              tenBietDuoc: item.tenbietduoc,
              tenhoatchat: item.tenhoatchat,
              duongDung: item.duongdung,
              dvt: item.dvt,
              hamLuong: item.hamluong,
              soLo: item.solo,
              idkhocn: item.idkhocn,
              bsKeDon: infoUser?.tenNV,
              soLuong: item.soluong,
              ghiChu: item.ghichu,
              lieuDung: item.lieudung ?? `0`,
              soLanNgay: item.solanngay ?? `0`,
              idbnttc: DetailBN?.idbnttc,
              hanDung: item.handung,
              soHoaDon: `${item.sohoadon}`,
              // maSoDangKy: item.masodangky,
              muitiem: item.muitiem,
              ngayhentiem: item.ngayhentiem,
              taikhamtiemchung: item.taikhamtiemchung,
            },
          })
        }
      }
    }
    // DetailToaThuocBN?.DanhSachThuoc?.map((item) => {})
  }, [DetailToaThuocBN])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Tên vắc xin',
      dataIndex: 'tenBietDuoc',
      key: 'tenBietDuoc',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'dvt',
      key: 'dvt',
      align: 'center',
      width: 60,
    },
    {
      title: 'Mũi',
      dataIndex: 'muitiem',
      key: 'muitiem',
      align: 'center',
      width: 60,
      render: (text, record, index) => (
        <Input
          onChange={handleMui(record.ID)}
          value={text}
          min={0}
          type="number"
          size="small"
        />
      ),
    },
    {
      title: 'Liều dùng (ml)',
      dataIndex: 'lieuDung',
      key: 'lieuDung',
      width: 100,
      align: 'center',
      render: (text, record, index) => (
        <Input
          className="w-14"
          min={0}
          step={0.1}
          type="number"
          onChange={handleLieuDung(record.ID)}
          value={text}
          size="small"
        />
      ),
    },
    {
      title: 'Đường dùng',
      dataIndex: 'duongDung',
      key: 'duongDung',
      width: 90,
      align: 'center',
    },
    {
      title: 'SL',
      dataIndex: 'soLuong',
      key: 'soLuong',
      width: 60,
      align: 'center',
      render: (text, record, index) => (
        <Input
          status={!text && 'error'}
          onChange={handleValue(record.ID)}
          value={text}
          min={0}
          type="number"
          size="small"
        />
      ),
    },
    {
      title: 'Tồn',
      dataIndex: 'ton',
      key: 'ton',
      width: 60,
      align: 'center',
    },
    {
      title: 'Ngày hẹn tiêm',
      dataIndex: 'ngayhentiem',
      key: 'ngayhentiem',
      align: 'center',
      width: 110,
      render: (text, record, index) => (
        <DatePicker
          placeholder=" "
          size="small"
          className="w-full"
          format="DD/MM/YYYY"
          minDate={dayjs(now)}
          onChange={handlengayhentiem(record.ID)}
          value={text ? dayjs(text) : null}
          allowClear={true}
          disabledDate={disabledDate}
        />
      ),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'ghiChu',
      key: 'ghiChu',
      width: 150,
      render: (text, record, index) => (
        <Input onChange={handleghichu(record.ID)} value={text} size="small" />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 30,
      fixed: 'right',
      render: (text, record, index) => (
        <>
          <CloseOutlined
            onClick={() => {
              if (checkDuocSiChuaTraToa) {
                ToastCus.fire({
                  icon: 'error',
                  title: 'Dược sĩ đang thao tác!',
                })
                return
              }
              dispatch({
                type: typeAction.DELETE_THUOC_BN,
                payload: record.ID,
              })
            }}
            className="text-red-500 cursor-pointer flex justify-center items-center"
          />
        </>
      ),
    },
  ]
  return (
    <>
      {!isLoadQuyen && !isQuyenKeToa && (
        <Alert message="Tài khoản không có quyền thao tác!" type="error" showIcon />
      )}
      <div className={`mb-2 ${isLoadQuyen || !isQuyenKeToa ? 'hidden' : ''}`}>
        {contextHolder}
        <Select
          placeholder="Nhập tên vắc xin..."
          showSearch
          allowClear
          onChange={handleVacXin}
          filterOption={(input, option) => {
            const label = option?.label ?? ''
            const tenBietDuoc = option?.tenBietDuoc ?? ''
            const searchText = input.toLowerCase()
            return (
              label.toLowerCase().includes(searchText) ||
              tenBietDuoc.toLowerCase().includes(searchText)
            )
          }}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          value={null}
          className="w-full"
          options={listTonVacXin
            ?.filter((item) => item?.tonKho > 0)
            ?.map((items) => ({
              label: items?.thuocVatTu?.tenHoatChat,
              tenBietDuoc: items?.thuocVatTu?.tenBietDuoc,
              value: items?.ID,
              ton: items?.tonKho,
              dvt: items?.thuocVatTu?.dvt,
              hanDung: items?.hanDung,
            }))}
          optionRender={(options) => (
            <ul className="flex">
              <li className="w-3/6 ">
                <p className="font-semibold w-full text-pretty">{options.data.label}</p>
                <span className="text-xs w-full text-gray-500"> ({options.data.tenBietDuoc})</span>
              </li>
              <li className="w-1/6 border-x flex items-center justify-center text-center">
                {moment(options.data.hanDung).format('DD/MM/YYYY')}
              </li>
              <li className="w-1/6 border-x text-center flex items-center justify-center">
                {options.data.ton}
              </li>
              <li className="w-1/6 text-center flex items-center justify-center">
                {options.data.dvt}
              </li>
            </ul>
          )}
        />
        <div className="mt-2 h-[10.9rem]">
          <ConfigProvider
            theme={{
              token: {
                padding: 4,
                fontSize: 13,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              bordered
              columns={columns}
              pagination={false}
              dataSource={ThuocBN}
              scroll={{ x: 1100, y: 120 }}
            />
          </ConfigProvider>
        </div>
      </div>
    </>
  )
}

export default ToaThuoc
