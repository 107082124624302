import { produce } from 'immer'
import * as typeAction from '../constants/constants'
const initialState = {
  ListDichVu: null,
  groupsDichVu: null,
  infoDichVu: null,
  history: [],
  detailDV: null,
  VTTHService: [],
}

const dichVuReducer = (state = initialState, { type, payload }) => {
  return produce(state, (draft) => {
    switch (type) {
      case typeAction.RESET_STORE:
        return initialState // reset store khi logOut
      case typeAction.DISPATCH_LIST_DICH_VU:
        draft.ListDichVu = payload
        break
      case typeAction.DISPATCH_VTTH_SERVICE:
        draft.VTTHService = payload
        break
      case typeAction.DISPATCH_GROUPS_DICH_VU:
        draft.groupsDichVu = payload
        break
      case typeAction.DISPATCH_INFO_DICH_VU:
        draft.infoDichVu = payload
        break
      case typeAction.DISPATCH_HISTORY_DICH_VU:
        draft.history = payload
        break
      case typeAction.DISPATCH_DETAIL_DICH_VU:
        draft.detailDV = payload
        break
      default:
        return state
    }
  })
}

export default dichVuReducer
