import React, { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import {
  Input,
  Menu,
  ConfigProvider,
  Table,
  Tooltip,
  Modal,
  Checkbox,
  Button,
  DatePicker,
} from 'antd'
import { ExclamationCircleFilled, EyeOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { listAllCompanyAction } from '../../../store/actions/companyAction'
import {
  DetailBranch,
  deleteBranchByID,
  listBranchDetail_Action,
} from '../../../store/actions/BranchAction'
import CachedIcon from '@mui/icons-material/Cached'
import IconButton from '@mui/material/IconButton'
import ModalInfoBranch from './Modal/ModalInfoBranch'
import ModalAddBranch from './Modal/ModalAddBranch'
import { listAllChuyeKhoa_Action } from '../../../store/actions/thuocVTAction'
import { putCheckQuyenThaoTacAction } from '../../../store/actions/phanQuyenAction'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import moment from 'moment'
import 'moment/locale/vi'
import dayjs from 'dayjs'
moment.locale('vi')
const dateFormat = 'DD/MM/YYYY'
const formDateDB = 'YYYY-MM-DD'
const { confirm } = Modal
const Branch = () => {
  const ID_COMPANY_CHAN_VAN = 1
  const { listBranhDetail } = useSelector((state) => state.branchReducer)
  const [idCompany, setIdCompany] = useState(ID_COMPANY_CHAN_VAN)
  const [isLoading, setIsLoading] = useState(false)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [isModal, setIsModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [date, setDate] = useState({ from: '', to: '' })
  const [keyword, setKeyword] = useState('')
  const dispatch = useDispatch()

  const { infoUser } = useSelector((state) => state.userReducer)

  const maQuyenXemDs = 'QHT351'
  const maQuyenXemCt = 'QHT353'
  const maQuyenXuatExcel = 'QHT639'
  const maQuyenThem = 'QHT352'

  useEffect(() => {
    dispatch(listBranchDetail_Action(keyword, idCompany, date.from, date.to, setIsLoading))
  }, [maQuyenXemDs, keyword, idCompany, date.from, date.to, dispatch, infoUser])

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        dispatch(listBranchDetail_Action(keyword, idCompany, date.from, date.to, setIsLoading))
        break
      case maQuyenXemCt:
        showEdit(infoNew?.idchinhanh)
        break
      case maQuyenXuatExcel:
        exportToExcel()
        break
      case maQuyenThem:
        showModal()
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenXemCt:
        setIsEdit(false)
        break
      case maQuyenXuatExcel:
        //
        break
      case maQuyenThem:
        setIsModal(false)
        break
      default:
        return false
    }
  }
  const debounceDropDown = useCallback(
    _.debounce((keyWord, id, from, to) => {
      const newKeyword = keyWord?.length ? keyWord?.trim() : keyWord
      setKeyword(newKeyword)
      // onReload(newKeyword,idCompany)
      // dispatch(listBranchDetail_Action(newKeyword, id, from, to, setIsLoading))
    }, 300),
    [],
  ) // sử dụng debounce để tối tiểu thánh server perfoman
  const showModal = () => {
    setIsModal(true)
  }
  const onSearchInput = (e) => {
    const { value } = e.target
    debounceDropDown(value, idCompany, date.from, date.to)
  }
  const onReload = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  const handleClickCompany = (e) => {
    setIdCompany(e.key)
    // dispatch(listBranchDetail_Action('', e.key, date.from, date.to, setIsLoading))
  }
  //---------- show modal edit ----------//
  const showEdit = (id) => {
    setIsEdit(true)
    dispatch(DetailBranch(id))
  }
  const exportToExcel = () => {
    const formatData = listBranhDetail?.map((item) => ({
      'Mã chi nhánh': item?.machinhanh,
      'Tên chi nhánh': item.tenchinhanh,
      'Địa chỉ': item.diachi,
      'Tỉnh/TP ': item.tentinh,
      'Ngày khai trương': item.ngaykhaitruong
        ? moment(item.ngaykhaitruong).format('DD/MM/YYYY')
        : '-',
      'Loại ':item.loaichinhanh === 'VANPHONG' ? 'Văn phòng' : item.loaichinhanh === 'CHINHANH' ? 'Chi nhánh' : '' ,
      'Chuyên khoa': item.tenchuyenkhoa,
      'SĐT ': item.dienthoai,
      'Công ty': item.tenct,
      'Linkmap ': item.linkmap,
    }))
    const name = `Danh sách chi nhánh - ${listCompany?.find((item) => item.idct == idCompany)?.tenct}`
    formatData?.length && exportExcelformat(formatData, name)
  }
  const showDeleteConfirm = (id, name) => {
    confirm({
      title: 'Bạn có muốn xoá chi nhánh này không',
      icon: <ExclamationCircleFilled />,
      content: `Chi nhánh: ${name}`,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        dispatch(deleteBranchByID(id, onReload))
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  useEffect(() => {
    onReload()
  }, [idCompany, keyword])
  useEffect(() => {
    // onReload()
    // dispatch(listBranchDetail_Action('', ID_COMPANY_CHAN_VAN, date.from, date.to, setIsLoading))
    dispatch(listAllCompanyAction())
    dispatch(listAllChuyeKhoa_Action())
  }, [])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã',
      dataIndex: 'machinhanh',
      key: 'machinhanh',
      align: 'center',
      width: 80,
    },
    {
      title: 'Tên chi nhánh ',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      sorter: {
        compare: (a, b) => {
          return (a?.tenchinhanh ?? '').localeCompare(b?.tenchinhanh ?? '')
        },
      },
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diachi',
      key: 'diachi',
      sorter: {
        compare: (a, b) => {
          return (a?.diachi ?? '').localeCompare(b?.diachi ?? '')
        },
      },
    },
    {
      title: 'Tỉnh/TP',
      dataIndex: 'tentinh',
      key: 'tentinh',
      align: 'center',
      width: 120,
      // sorter: (a, b) => a.idtinh - b.idtinh,
      sorter: {
        compare: (a, b) => {
          return (a?.tentinh ?? '').localeCompare(b?.tentinh ?? '')
        },
      },
    },
    {
      title: 'Ngày khai trương',
      dataIndex: 'ngaykhaitruong',
      key: 'ngaykhaitruong',
      align: 'center',
      width: 140,
      render: (text, record, index) => text && moment(text).format('DD/MM/YYYY'),
      sorter: {
        compare: (a, b) => {
          return (a?.ngaykhaitruong ?? '01/01/2000').localeCompare(
            b?.ngaykhaitruong ?? '01/01/2000',
          )
        },
      },
    },
    {
      title: 'Loại',
      dataIndex: 'loaichinhanh',
      key: 'loaichinhanh',
      align: 'center',
      width: 85,
      render: (text, record, index) =>
        text === 'VANPHONG' ? 'Văn phòng' : text === 'CHINHANH' ? 'Chi nhánh' : '',
      sorter: {
        compare: (a, b) => {
          return a?.loaichinhanh.localeCompare(b?.loaichinhanh)
        },
      },
    },
    {
      title: 'Chuyên khoa',
      dataIndex: 'tenchuyenkhoa',
      key: 'tenchuyenkhoa',
      align: 'center',
      width: 110,
      sorter: {
        compare: (a, b) => {
          return (a?.tenchuyenkhoa?.replace('Chuyên khoa', '')?.trim() ?? '')?.localeCompare(
            b?.tenchuyenkhoa?.replace('Chuyên khoa', '')?.trim() ?? '',
          )
        },
      },
      render: (text, record, index) => text?.replace('Chuyên khoa', '')?.trim(),
    },
    {
      title: 'Sử dụng',
      dataIndex: 'sudung',
      key: 'sudung',
      align: 'center',
      width: 85,
      render: (text, record, index) => (text === 1 ? <Checkbox checked /> : <Checkbox disabled />),
      sorter: (a, b) => a.sudung - b.sudung,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 45,
      align: 'center',
      render: (text, record, index) => (
        <>
          <ul className="flex justify-around">
            <li>
              <Tooltip title="Xem" color="#108ee9">
                <EyeOutlined
                  onClick={() =>
                    dispatch(
                      putCheckQuyenThaoTacAction(
                        maQuyenXemCt,
                        { idchinhanh: record?.idchinhanh },
                        actionIsTrue,
                        actionIsFalse,
                      ),
                    )
                  }
                  className="text-xl text-[#108ee9]  cursor-pointer"
                />
              </Tooltip>
            </li>
            {/* <li>
          <Tooltip title="Xoá" color="red">
            <DeleteOutlined
                onClick={()=>showDeleteConfirm(idchinhanh,tenchinhanh)}
              className="text-xl text-red-500  cursor-pointer"
            />
          </Tooltip>
        </li> */}
          </ul>
        </>
      ),
    },
  ]

  return (
    <>
      <div className="w-full h-full p-5 bg-[#E7E7E7]">
        <div className="bg-white h-full p-2 rounded-2xl">
          <div className="flex h-full border rounded-xl">
            <div className="w-1/5 p-2 border-r">
              <div className="">
                <div className="flex justify-between">
                  <h2 className="text-xl font-semibold text-gray-400">Công ty</h2>
                  {/* <button className="flex items-center mr-2 bg-blue-500 gap-1 px-4 py-2 cursor-pointer text-white font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 hover:gap-1 hover:translate-x-2">
                    Tạo
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </button> */}
                </div>

                <div className="mt-2">
                  <ConfigProvider
                    theme={{
                      components: {
                        Menu: {
                          itemHeight: 60,
                        },
                      },
                    }}
                  >
                    <Menu
                      onClick={handleClickCompany}
                      style={{ border: 'unset' }}
                      defaultSelectedKeys={['1']}
                    >
                      {listCompany?.map((items) => (
                        <Menu.Item key={items.idct} style={{ lineHeight: '30px' }}>
                          <h2 className="font-semibold text-base mt-1">{items.tenct}</h2>

                          <span className="flex items-center text-gray-400">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                              />
                            </svg>
                            {items.diachi}
                          </span>
                        </Menu.Item>
                      ))}
                    </Menu>
                  </ConfigProvider>
                </div>
              </div>
            </div>
            <div className="w-4/5 ">
              <div className="p-2">
                <div className="flex justify-between mb-4">
                  <div className="flex items-center gap-2">
                    <Input.Search
                      allowClear
                      onChange={onSearchInput}
                      placeholder="Nhập từ khoá"
                      className="w-96"
                    />
                    <div className="flex items-center gap-1">
                      <DatePicker.RangePicker
                        onChange={(dates, dateStrings) => {
                          setDate({
                            from:
                              dateStrings[0] &&
                              moment(dateStrings[0], dateFormat).format(formDateDB),
                            to:
                              dateStrings[1] &&
                              moment(dateStrings[1], dateFormat).format(formDateDB),
                          })
                        }}
                        defaultValue={
                          date.from && date.to
                            ? [dayjs(date.from, formDateDB), dayjs(date.from, dateFormat)]
                            : ''
                        }
                        format={dateFormat}
                      />
                      <i className="w-32">(Khai trương)</i>
                    </div>

                    <IconButton onClick={onReload} color="primary" aria-label="Tải lại">
                      <CachedIcon />
                    </IconButton>
                    <Button
                      disabled={!listBranhDetail?.length}
                      // onClick={exportToExcel}
                      onClick={() =>
                        dispatch(
                          putCheckQuyenThaoTacAction(
                            maQuyenXuatExcel,
                            null,
                            actionIsTrue,
                            actionIsFalse,
                          ),
                        )
                      }
                      type="text"
                      size="middle"
                      className="text-green-700 p-1"
                      icon={<ImportExportIcon />}
                    >
                      Xuất Excel
                    </Button>
                  </div>
                  <div className="w-full flex justify-end items-center pr-2">
                    <div className="text-md font-semibold text-gray-500">
                      Tổng: <span className="text-gray-700">{listBranhDetail?.length ?? 0} CN</span>
                    </div>
                  </div>
                  <div className="w-2/5 flex justify-end items-center pr-1">
                    <button
                      // onClick={showModal}
                      onClick={() =>
                        dispatch(
                          putCheckQuyenThaoTacAction(
                            maQuyenThem,
                            null,
                            actionIsTrue,
                            actionIsFalse,
                          ),
                        )
                      }
                      class="relative px-6 py-1 rounded-md bg-white isolation-auto z-10 border-2 border-blue-500        before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full duration-200 hover:text-white before:-right-full before:hover:right-0 before:rounded-full  before:bg-blue-500 before:-z-10  before:aspect-square before:hover:scale-150 overflow-hidden before:hover:duration-700"
                    >
                      Thêm chi nhánh
                    </button>
                  </div>
                </div>
                <ConfigProvider
                  theme={{
                    token: {
                      padding: 5,
                    },
                    components: {
                      Table: {
                        rowHoverBg: '#ecf0f1',
                        headerBg: '#e6e6e6',
                        footerBg: '#e6e6e6',
                        borderColor: '#BABABA',
                      },
                    },
                  }}
                >
                  <Table
                    locale={{
                      triggerDesc: 'Sắp xếp giảm dần',
                      triggerAsc: 'Sắp xếp tăng dần',
                      cancelSort: 'Hủy sắp xếp',
                    }}
                    loading={isLoading}
                    pagination={false}
                    scroll={{
                      y: 700,
                    }}
                    columns={columns}
                    dataSource={listBranhDetail}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalInfoBranch />
      <ModalAddBranch isModal={isModal} setIsModal={setIsModal} onReload={onReload} />
      <ModalInfoBranch isEdit={isEdit} setIsEdit={setIsEdit} />
    </>
  )
}

export default Branch
