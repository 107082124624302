import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { DatePicker, Select, TreeSelect, Button } from 'antd'
import IconButton from '@mui/material/IconButton'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import TableDetail from './Table/TableDetail'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { branchService } from '../../../../services/branch/branchService'
import { reportService } from '../../../../services/Report/reportService'
import { formattedNumber } from '../../../../utils/formattedNumber'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
import { getListNameChiNhanh } from '../../../../utils/algorithm'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
moment.locale('vi')
const { SHOW_PARENT } = TreeSelect
const DetailBranch = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const now = moment()
  const dateFormat = 'DD-MM-YYYY'
  const momentDate = 'YYYY-MM-DD'
  const { id } = useParams()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [value, setValue] = useState([BranchLogin])
  const [idChiNhanh, setIdChiNhanh] = useState(Number(BranchLogin))
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [data, setData] = useState([])
  const [branch, setBranch] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))

  const nameChiNhanh = useMemo(() => getListNameChiNhanh(value, branch), [value, branch])
  const nameCompany = useMemo(
    () => listCompany?.find((item) => item?.idct === company)?.tenct,
    [company, listCompany],
  )
  const nameChuyenKhoa = useMemo(
    () =>
      !idChuyenKhoa
        ? 'Tất cả'
        : ChuyenKhoa?.find((item) => item?.idChuyenKhoa === idChuyenKhoa)?.tenChuyenKhoa,
    [idChuyenKhoa, ChuyenKhoa],
  )

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }
  //-------- export excel-----------//
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        location.pathname?.includes('chitiet') ? 'QHT590' : 'QHT468',
      )

      const formattedData = data?.map((item) => ({
        'Ngày thu': item.ngayThu ? moment(item.ngayThu).format('DD/MM/YYYY HH:mm:ss') : '-',
        'Chi nhánh': item.tenChiNhanh,
        'Ngày khai trương': item.ngayKhaiTruong
          ? moment(item.ngayKhaiTruong).format('DD/MM/YYYY')
          : '-',
        'Loại phiếu': item.loai || '',
        'Hình thức': item.tenHinhThuc || '',
        'Phương thức': item.tenPhuongThucTT || '',
        'Mã bệnh nhân': item.maBenhNhan || '',
        'Tên bệnh nhân': item.tenBenhNhan || '',
        'Đối tượng': item.doiTuong || '',
        'Nguồn khách': item.nguonKhach || '',
        'Địa chỉ': item.diaChi || '',
        'Người thu': item.nguoiThu || '',
        'Mã phiếu thu': item.maPhieuThu || '',
        'Số chứng từ': item.soChungTu || '',
        'Số phiếu xuất': item.soPhieuXuat || '',
        'Loại khám ': item.tenLoaiKham || '',
        'loại thẻ': item.loaiThe || '',
        'Mã dịch vụ': item.ma || '',
        'Tên dịch vụ': item.ten || '',
        'Đơn vị': item.dvt || '',
        'Đơn giá': item.donGia ? item.donGia : 0,
        'Đơn giá sau thuế': item.donGiaSauThue ? item.donGiaSauThue : 0,
        'Số lượng': item.soLuong ? item.soLuong : 0,
        'Thành tiền': item.thanhTien ? item.thanhTien : 0,
        'Tiền giảm': item.tienGiamGia ? item.tienGiamGia : 0,
        'Thanh toán': item.thanhToan ? item.thanhToan : 0,
        'Mã Thanh toán': item.mathanhtoan,
      }))
      const name = 'Doanh thu chi tiết'
      formattedData?.length && exportExcelformat(formattedData, name)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_BAO_CAO_DOANH_THU_CHI_TIET,
        true,
        MENU_CONSTANTS.DOANH_THU,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          nameChiNhanh: nameChiNhanh.join('\n'),
          nameChuyenKhoa,
          nameCompany,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const detailErrorPayload = `Có lỗi xảy ra khi xuất excel báo cáo doanh thu chi tiết. \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DOANH_THU,
        detailErrorPayload,
      )
    }
  }
  //-------------- lấy danh sách chuyên kho ------------//
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  //---------- lấy danh sách chi nhánh theo công ty -----------//
  const fetchBranch = async (id) => {
    try {
      const { data } = await branchService.getListBranch(id)
      setBranch(data)
    } catch (error) {
      console.log('fetchBranch : ', error)
    }
  }
  const fetchData = async (id, dateForm, dateTo) => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        location.pathname?.includes('chitiet') ? 'QHT589' : 'QHT467',
      )

      let idChiNhanhs = null
      // Kiểm tra giá trị của `value` để xử lý các trường hợp rỗng hoặc "all"
      if (value?.length > 0 && value[0] !== 'all') {
        // Giả sử `value` là mảng các ID. Chuyển đổi mảng thành chuỗi truy vấn
        idChiNhanhs = value?.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await reportService.getDetailReportBranch(
          idChuyenKhoa,
          company,
          idChiNhanhs,
          dateForm,
          dateTo,
        )
        setData(data)
      } else {
        const { data } = await reportService.getDetailReportBranch(
          idChuyenKhoa,
          company,
          '',
          dateForm,
          dateTo,
        )
        setData(data)
        setValue(['all'])
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.BAO_CAO_DOANH_THU_CHI_TIET,
        true,
        MENU_CONSTANTS.DOANH_THU,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          nameChiNhanh: nameChiNhanh.join('\n'),
          nameChuyenKhoa,
          nameCompany,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DOANH_THU,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }
  //---------- reload dữ liệu --------//
  const onLoad = () => {
    fetchData(idChiNhanh, dateForm, dateTo)
  }
  const count = data.reduce((total, items) => total + items.soLuong, 0)
  const thanhTien = data.reduce((total, items) => total + items.thanhTien, 0)
  const tienGiam = data.reduce((total, items) => total + items.tienGiamGia, 0)
  const thanhToan = data.reduce((total, items) => total + items.thanhToan, 0)
  const dongia = data.reduce((total, items) => total + items.donGia, 0)

  const statis = [
    {
      title: 'TỔNG ĐƠN GIÁ',
      number: formattedNumber(dongia),
    },
    {
      title: 'TỔNG SỐ LƯỢNG',
      number: formattedNumber(count),
    },
    {
      title: 'TỔNG THÀNH TIỀN',
      number: formattedNumber(thanhTien),
    },
    {
      title: 'TỔNG TIỀN GIẢM',
      number: formattedNumber(tienGiam),
    },
    {
      title: 'TỔNG THANH TOÁN',
      number: formattedNumber(thanhToan),
    },
  ]
  const treeData = [
    {
      title: 'Tất cả chuyên khoa',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(company, value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  //------------ lấy danh sách chi nhánh theo chuyên khoa ----------//
  const fetchBranchByChuyenKhoa = async (idct, value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(idct, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    // fetchBranch(id_company)
    fetchBranchByChuyenKhoa(company, null)
    fetchChuyenKhoa()
  }, [])

  useEffect(() => {
    if (branch.length) {
      fetchData(idChiNhanh, dateForm, dateTo)
    }
  }, [branch])
  return (
    <>
      <div>
        <div className="py-2">
          <ul className="flex border rounded-md">
            {statis.map((items) => (
              <li className="flex flex-col gap-2 w-1/5 border-r p-2">
                <div className="flex justify-between">
                  <h2 className="font-semibold text-gray-500 xl:text-base text-xs">
                    {items.title}
                  </h2>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      className="size-5 text-green-700 border-2 border-green-500  rounded-full "
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
                <span className="text-lg font-semibold">{formattedNumber(items.number)}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex gap-5 justify-between border-dotted border-b-0">
          <div className="flex gap-5">
            <div className="w-80">
              <Select
                showSearch
                value={company}
                onChange={(value) => {
                  setCompany(value)
                  fetchBranch(value)
                  setIdChiNhanh(null)
                  setValue(['all'])
                  setIdChuyenKhoa('')
                  fetchBranchByChuyenKhoa(value, '')
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={listCompany?.map(({ idct, tenct }) => ({
                  label: tenct,
                  value: idct,
                }))}
                className="w-full"
                disabled={infoUser && infoUser.idNhom === 3}
              />
            </div>
            <div className="w-56">
              <Select
                onChange={onChangeChuyenKhoa}
                className="w-full"
                value={idChuyenKhoa}
                options={[
                  { label: 'Tất cả chuyên khoa', value: '' },
                  ...ChuyenKhoa.map((items) => ({
                    label: items.tenChuyenKhoa,
                    value: items.idChuyenKhoa,
                  })),
                ]}
                disabled={infoUser && infoUser.idNhom === 3}
              />
            </div>
            <div className="w-80">
              <TreeSelect
                {...tProps}
                style={{ flexWrap: 'unset' }}
                className="w-full h-8 overflow-clip"
                disabled={infoUser && infoUser.idNhom === 3}
              />
            </div>

            <div className="flex items-center gap-3">
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('form')}
                format={dateFormat}
              />
              <span className="font-semibold text-lg">-</span>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs(now)}
                maxDate={dayjs(now)}
                onChange={handleDatePicker('to')}
                format={dateFormat}
              />
            </div>
            <div className="flex gap-3">
              <IconButton onClick={onLoad} color="primary" aria-label="delete" size="small">
                <SyncIcon />
              </IconButton>
            </div>
            <div className="font-semibold ">
              <Button
                disabled={!data?.length}
                onClick={exportToExcel}
                type="text"
                size="middle"
                className="text-green-700 p-1"
                icon={<ImportExportIcon />}
              >
                Xuất Excel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-1">
        <TableDetail data={data} isLoading={isLoading} />
      </div>
    </>
  )
}

export default DetailBranch
