import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'

import ToastCus from '../../utils/Toast'
import { khoDieuChinhService } from '../../services/khodieuchinh/khoDieuChinhService'
import { check } from 'prettier'
import { addLog } from '../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
import { NhapKhoService } from '../../services/NhapKho/NhapKhoService'

export function* khoDieuChinhSaga() {
  // POST PHIẾU XUẤT SỬ DỤNG
  yield takeLatest(
    typeAction.POST_PHIEU_DIEU_CHINH_KHO,
    function* postPhieuKhoDieuChinhSaga({ type, form, list, resetForm }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      const jsonData = {}

      for (const [key, value] of form) {
        jsonData[key] = value
      }
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })
        const { data: infoPhieu } = yield call(() =>
          khoDieuChinhService.postPhieuDieuChinhKho(form),
        )
        // console.log('infoPhieu', infoPhieu)
        const formatData = list?.map((item) => ({
          idthuoc: item?.idthuoc,
          // tenbietduoc: item?.tenbietduoc,
          // tenhoatchat: item?.tenhoatchat,
          solo: item?.solo,
          handung: item?.handung,
          sohoadon: item?.sohoadon,
          soluong: item?.soluong,
          // masodangky: item?.masodangky,
          ghichu: item?.ghichu,
          stt: item?.stt,
          // mathuoc: item?.mathuoc,
          // dvt: item?.dvt,
          idNhapXuat: infoPhieu?.data?.idNhapXuat,
          chenhlech: item?.chenhlech,
        }))
        yield call(() => khoDieuChinhService.postListVTHH(formatData))
        yield call(() => resetForm())
        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.TAO_PHIEU_DIEU_CHINH_TON_KHO,
          true,
          MENU_CONSTANTS.DIEU_CHINH_TON_KHO,
          null,
          {
            maphieu: infoPhieu.data.maPhieu,
          },
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.DIEU_CHINH_TON_KHO,
          detailErrorPayload,
        )
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
  // ------------ DELETE PHIEU DIEU CHINH TON ------------//
  yield takeLatest(
    typeAction.DELETE_PHIEU_DIEU_CHINH_TON,
    function* deletePhieu({ type, idNhapXuat, onLoad }) {
      try {
        yield call(() => NhapKhoService.deletePhieu(idNhapXuat))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Xoá  thành công!',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Xoá thất bại!',
        })
      }
    },
  )
  // ------------ DELETE  DIEU CHINH TON CHI TIET ------------//
  yield takeLatest(
    typeAction.DELETE_CHI_TIET_DIEU_CHINH,
    function* deletePhieu({ type, idChiTietNX, onLoad }) {
      try {
        yield call(() => NhapKhoService.deleteKhoChiTiet(idChiTietNX))
        onLoad()
        ToastCus.fire({
          icon: 'success',
          title: 'Xoá  thành công!',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Xoá thất bại!',
        })
      }
    },
  )
}
