import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import { useFormik } from 'formik'
import { notification } from 'antd'
import { sieuAmService } from '../../../services/SieuAm/sieuAmService'
import { getAllBieuMau, saveBnSieuAm } from '../../../store/actions/sieuAmAction'
import { phanQuyenService } from '../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../store/actions/phanQuyenAction'
import * as typeAction from '../../../store/constants/constants'
import ToastCus from '../../../utils/Toast'
import Customer from './Customer/Customer'
import ImageSA from './ImageSA/ImageSA'
import InfoBN from './InfoBN/InfoBN'
import InfoPhieu from './InfoPhieu/InfoPhieu'
import Template from './Template/Template'
import moment from 'moment'
import 'moment/locale/vi'
moment.locale('vi')
const formatDB = 'YYYY-MM-DDTHH:mm:ss'
const today = moment()
const SieuAm = () => {
  const dispatch = useDispatch()
  const [api, contextHolder] = notification.useNotification()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const ID_BRANCH = Number(localStorage.getItem('BRANH_LOGIN'))
  const { infoBNSieuAm } = useSelector((state) => state.sieuAmReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const [image, setImage] = useState([])
  const [BNPending, setBNPending] = useState([])
  const [BNdone, setBnDone] = useState([])
  const [isStatus, setIsStatus] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [listImagePrint, setListImagePrint] = useState([])
  //----------- lấy danh sách chờ BN --------//
  const fetchBNPending = async (id) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT322',
      )
      const { data } = await sieuAmService.getBNPendingByBranch(id)
      setBNPending(data)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    })
  }
  // ----------- lấy danh bệnh nhân đã thực hiên ------------//
  const fetchBnDone = async (idcn, dateForm, dataTo) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT323',
      )
      const { data } = await sieuAmService.getBNDoneBtBranch(idcn, dateForm, dataTo)
      setBnDone(data)
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      IDBNTTC: infoBNSieuAm?.idbnttc,
      // IDBM: 1,
      NGAYSIEUAM: null,
      BSCHIDINH: infoBNSieuAm?.bschidinh,
      BSSIEUAM: infoUser?.tenNV,
      NGUOISUA: infoUser?.idnv, // người tạo lần đầu tiên (có id người sửa, không có ngày sửa) để cập nhật dựa trên đúng idnv mới dc cập nhật
      CHANDOANSOBO: infoBNSieuAm?.chandoan,
      LOAISIEUAM: infoBNSieuAm?.loaisieuam,
      IDBNCDHA: infoBNSieuAm?.idbncdha,
      BIEUMAU: null,
      PARA: infoBNSieuAm?.para,
      files: null,
      KETLUAN: infoBNSieuAm?.ketluan,
      tieude: infoBNSieuAm?.tieude,
      dusanh: infoBNSieuAm?.dusanh,
      tuanthai: infoBNSieuAm?.tuanthai,
      ngaythai: infoBNSieuAm?.ngaythai,
    },
    onSubmit: (values) => handleSubmit(values),
  })
  const reload = () => {
    formik.resetForm()
    fetchBNPending(ID_BRANCH)
    setEditorState(EditorState.createEmpty())
    setImage([])
    setListImagePrint([])
  }
  const handleSubmit = (values) => {
    if (isStatus && checkRequiredFields()) {
      openNotificationWithIcon(
        'warning',
        'Lưu kết quả siêu âm',
        'Vui lòng nhập dự sanh và lúc thai',
      )
      return
    }
    if (!isStatus) {
      formik.values.dusanh = null
      formik.values.tuanthai = null
      formik.values.ngaythai = null
    }
    // return
    // if (!values.PARA || values.PARA?.trim().length === 0) {
    //   formik.setFieldValue('PARA', null)
    //   // formik.setErrors({ para: 'require' })
    //   ToastCus.fire({
    //     icon: 'error',
    //     title: 'Vui lòng nhập chỉ số Para!',
    //   })
    //   return
    // }
    if (image.length !== 0) {
      // * Nếu  như có hình ảnh thì gán hình ảnh vào !
      const data = image.map((item) => item.file)
      values.files = data
    }
    const contentState = editorState.getCurrentContent()
    const row = convertToRaw(contentState)
    const data = JSON.stringify(row)
    values.BIEUMAU = data
    values.NGAYSIEUAM = today.format(formatDB) // * Lấy lại chính xát ngày siêu âm

    const formData = new FormData()
    for (let key in values) {
      if (values[key] !== null && values[key] !== undefined) {
        // Skip null or undefined values
        if (key === 'files' && values[key].length > 0) {
          values[key].forEach((file) => {
            formData.append(key, file)
          })
        } else {
          formData.append(key, values[key])
        }
      }
    }

    const formBNTTC = {
      ngaythai: values.ngaythai,
      tuanthai: values.tuanthai,
      dusanh: values.dusanh,
      idbnttc: values.IDBNTTC,
    }
    dispatch(saveBnSieuAm(formData, formBNTTC, ID_BRANCH, reload, listImagePrint))
  }
  const checkRequiredFields = () => {
    const requiredFields = [formik.values.dusanh, formik.values.tuanthai, formik.values.ngaythai]
    return requiredFields.some((field) => field === null)
  }
  useEffect(() => {
    dispatch({
      type: typeAction.RESET_STORE_SIEUAM,
    })
    dispatch(getAllBieuMau(1)) // lấy biểu mẫu loại siêu âm = 1
    fetchBNPending(ID_BRANCH)
    fetchBnDone(ID_BRANCH, today.format('YYYY-MM-DD'), today.format('YYYY-MM-DD'))
  }, [])
  useEffect(() => {
    if (infoBNSieuAm?.linkhinhanh) {
      const images = infoBNSieuAm.linkhinhanh
        .split(';')
        .map((item) => ({ url: item, checked: false }))
      setImage(images)
    } else {
      setImage([])
    }
  }, [infoBNSieuAm, infoBNSieuAm?.linkhinhanh])
  useEffect(() => {
    if (infoBNSieuAm?.bieumau) {
      const convertJson = JSON.parse(infoBNSieuAm?.bieumau)
      const contentState = convertFromRaw(convertJson)
      setEditorState(EditorState.createWithContent(contentState))
    } else {
      setEditorState(null)
    }

    setIsStatus(infoBNSieuAm?.dusanh ? true : false)
  }, [infoBNSieuAm, infoBNSieuAm?.bieumau])

  return (
    <>
      {contextHolder}
      <div className="h-full bg-[#EFEFEF]">
        <div>
          <div className="p-2">
            <div className="bg-white flex border rounded-lg">
              <div className="w-1/2 border-r">
                <div className="flex">
                  <div className="w-1/2 border-r">
                    <Customer
                      fetchBnDone={fetchBnDone}
                      fetchBNPending={fetchBNPending}
                      BNdone={BNdone}
                      BNPending={BNPending}
                      setListImagePrint={setListImagePrint}
                    />
                  </div>
                  <div className="w-1/2">
                    <div>
                      <InfoBN />
                    </div>
                    <div>
                      <InfoPhieu formik={formik} isStatus={isStatus} setIsStatus={setIsStatus} />
                    </div>
                  </div>
                </div>
                <div className="border-t">
                  <ImageSA
                    image={image}
                    setImage={setImage}
                    listImagePrint={listImagePrint}
                    setListImagePrint={setListImagePrint}
                  />
                </div>
              </div>
              <div className="w-1/2">
                <Template
                  isStatus={isStatus}
                  formik={formik}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  listImagePrint={listImagePrint}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SieuAm
