import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import ToastCus from '../../utils/Toast'
import { kiemKeService } from '../../services/KiemKe/kiemkeService'
import { addLog } from '../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'

export function* kiemKeSaga() {
  // ---------- TẠO KỲ HÀNG HOÁ -------------
  yield takeLatest(
    typeAction.CREATE_KY_HANG_HOA,
    function* closingPeriod({ type, form, resetForm }) {
      try {
        console.log(form)

        yield call(() => kiemKeService.postNewKyHang(form))
        resetForm()
        ToastCus.fire({
          icon: 'success',
          title: 'Thêm mới thành công',
        })
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: error?.response?.data?.message || 'Thêm mới thất bại',
        })
      }
    },
  )
  // ------------ LẤY DANH SÁCH KỲ HÀNG ----------------
  yield takeLatest(typeAction.GET_KY_HANG_HOA, function* kyHang({ type }) {
    try {
      const result = yield call(() => kiemKeService.getKyHangs())
      yield put({
        type: typeAction.DISPATCH_KY_HANG_HOA,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    }
  })
  //----------- CHỐT KỲ HÀNG --------------
  yield takeLatest(typeAction.ADD_KY_HANG_HOA, function* addKyHang({ type, form }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })
      const { IDCT, idChiNhanh, nguoiTao, idKy } = form
      yield call(() => kiemKeService.postTonKiemKe(IDCT, idChiNhanh, nguoiTao, idKy))
      ToastCus.fire({
        icon: 'success',
        title: 'Chốt kỳ hàng thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.CHOT_TON_HANG_KHO_KIEM_KE,
        true,
        MENU_CONSTANTS.KHO_KIEM_KE,
        null,
        {
          tenky: form.nameKy,
        },
      )
    } catch (error) {
      console.log(error)
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message || 'Chốt kỳ hàng thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.KHO_KIEM_KE,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })
  //--------------GET TON HANG KIỂM KÊ----------------
  yield takeLatest(typeAction.GET_TON_HANG_KIEM_KE, function* tonKiemKe({ type, form }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({
        type: typeAction.OPEN_LOADING_TABLE,
      })
      const { idKy, idCT, idChiNhanh, idKhoCN, keyword } = form
      const result = yield call(() =>
        kiemKeService.getTonKiemKe(idKy, idCT, idChiNhanh, idKhoCN, keyword),
      )
      yield put({
        type: typeAction.DISPATCH_TON_KIEM_KE,
        payload: result.data,
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_HANG_HOA_KHO_KIEM_KE,
        true,
        MENU_CONSTANTS.KHO_KIEM_KE,
        null,
        {
          nameCompany: form.nameCompany,
          nameChiNhanh: form.nameChiNhanh,
          keyword: form.keyword ?? '',
          nameKho: form.nameKho,
          nameKy: form.nameKy,
        },
      )
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.KHO_KIEM_KE,
        detailErrorPayload,
      )
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_TABLE,
      })
    }
  })
  //---------------- UPDATE PRODUCT KIÊM KÊ ----------------
  yield takeLatest(typeAction.UPDATE_PRODUCT_KIEM_KE, function* updateProduct({ type, form }) {
    try {
      yield call(() => kiemKeService.updateHangKiemKe(form))
    } catch (error) {
      console.log(error)
    }
  })
  //--------------POST ĐIỀU CHỈNH HÀNG KIỂM KÊ --------------
  yield takeLatest(
    typeAction.POST_DIEU_CHI_HANG_KIEM_KE,
    function* postDieuChinh({ type, idKy, idCN, idNV, handleCancel, tenky }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      try {
        yield call(() => kiemKeService.postDieuChinhHangNhap(idKy, idCN, idNV))
        yield call(() => kiemKeService.postDieuChinhHangXuat(idKy, idCN, idNV))
        ToastCus.fire({
          icon: 'success',
          title: 'Chốt hàng điều chỉnh thành công',
        })
        handleCancel()
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.CHOT_DIEU_CHINH_KHO_KIEM_KE,
          true,
          MENU_CONSTANTS.KHO_KIEM_KE,
          null,
          {
            tenky,
          },
        )
      } catch (error) {
        console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Chốt hàng điều chỉnh thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.KHO_KIEM_KE,
          detailErrorPayload,
        )
      } finally {
        yield put({ type: typeAction.CLOSE_LOADING_PAGE })
      }
    },
  )
}
