import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, DatePicker, Select, Table, TreeSelect } from 'antd'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { listAllCompanyAction } from '../../../../../store/actions/companyAction'
import { reportService } from '../../../../../services/Report/reportService'
import { https } from '../../../../../services/apiService'
import { phanQuyenService } from '../../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../../utils/Toast'
import * as XLSX from 'xlsx'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/vi'
import { getListNameChiNhanh } from '../../../../../utils/algorithm'
import { addLog } from '../../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../../utils/log/logConstant'
moment.locale('vi')

const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

function TheoDoiKhachMoi({ ChuyenKhoa, isBlockView }) {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const [branch, setBranch] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [value, setValue] = useState([BranchLogin])
  const [data, setData] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))

  const nameChiNhanh = useMemo(() => getListNameChiNhanh(value, branch), [value, branch])
  const nameCompany = useMemo(
    () => listCompany?.find((item) => item?.idct === company)?.tenct,
    [company, listCompany],
  )
  const nameChuyenKhoa = useMemo(
    () =>
      !idChuyenKhoa
        ? 'Tất cả'
        : ChuyenKhoa?.find((item) => item?.idChuyenKhoa === idChuyenKhoa)?.tenChuyenKhoa,
    [idChuyenKhoa, ChuyenKhoa],
  )

  const fetchReport = async (company, dateForm, dateTo, value, idChuyenKhoa) => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT495',
      )

      let idChiNhanhs = null
      if (value?.length > 0 && value[0] !== 'all') {
        // Giả sử `value` là mảng các ID. Chuyển đổi mảng thành chuỗi truy vấn
        idChiNhanhs = value?.map((id) => `&idChiNhanhs=${id}`).join('&')
        const { data } = await https.get(
          `ThongKeLuotKham/ThongKeTheoDoiKhachMoi?idCongTy=${company}${idChiNhanhs}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}`,
        )
        setData(data)
      } else {
        const { data } = await https.get(
          `ThongKeLuotKham/ThongKeTheoDoiKhachMoi?idCongTy=${company}&idChuyenKhoa=${idChuyenKhoa}&fromDate=${dateForm}&toDate=${dateTo}`,
        )
        setData(data)
        setValue(['all'])
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.BAO_CAO_LUOT_KHAM_THEO_DOI_KHACH_MOI,
        true,
        MENU_CONSTANTS.LUOT_KHAM,
        null,
        {
          nameCompany,
          nameChiNhanh,
          dateFrom: dateForm,
          dateTo,
          nameChuyenKhoa,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.LUOT_KHAM,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  useEffect(() => {
    dispatch(listAllCompanyAction())
    fetchBranchByChuyenKhoa('')
  }, [])

  useEffect(() => {
    if (infoUser && branch.length) {
      onLoad(company, dateForm, dateTo, value, idChuyenKhoa)
    }
  }, [infoUser, branch])

  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT496',
      )
      const headers = [
        'Chi nhánh',
        'Khám lần đầu',
        'Khám bệnh mới',
        'Tái khám',
        'Nam',
        'Nữ',
        'Dưới 1 tuổi',
        'Từ 1 - 3 tuổi',
        'Từ 3 - 6 tuổi',
        'Từ 6 - 10 tuổi',
        'Trên 10 tuổi',
        'Tổng cộng',
        'Ghi chú',
      ]
      const formattedData = data?.map((item) => ({
        'Chi nhánh': item.tenChiNhanh,
        'Khám lần đầu': item.loaiKhamLanDau,
        'Khám bệnh mới': item.loaiKhamBenhMoi,
        'Tái khám': item.loaiKhamTaiKham,
        'Nam ': item.gioiTinhNam,
        'Nữ ': item.gioiTinhNu,
        'Dưới 1 tuổi': item.tuoi_Duoi_1,
        'Từ 1 - 3 tuổi': item.tuoi_1_3,
        'Từ 3 - 6 tuổi': item.tuoi_3_6,
        'Từ 6 - 10 tuổi': item.tuoi_6_10,
        'Trên 10 tuổi': item.tuoi_Tren_10,
        'Tổng cộng': item.tongCong,
        'Ghi chú': item.ghiChu,
      }))
      const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers })
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1')
      XLSX.writeFile(wb, 'Theo dõi khách mới.xlsx')
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_BAO_CAO_LUOT_KHAM_THEO_DOI_KHACH_MOI,
        true,
        MENU_CONSTANTS.LUOT_KHAM,
        null,
        {
          nameCompany,
          nameChiNhanh,
          dateFrom: dateForm,
          dateTo,
          nameChuyenKhoa,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const detailErrorPayload = `Có lỗi xảy ra khi xuất file báo cáo lượt khám theo dõi khách mới \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.LUOT_KHAM,
        detailErrorPayload,
      )
    }
  }

  const onLoad = (company, dateForm, dateTo, value, idChuyenKhoa) => {
    fetchReport(company, dateForm, dateTo, value, idChuyenKhoa)
  }

  const renderFooter = (total, index) => {
    switch (index) {
      case 2:
        return total.khamLanDau
      case 3:
        return total.khamBenhMoi
      case 4:
        return total.taiKham
      case 5:
        return total.nam
      case 6:
        return total.nu
      case 7:
        return total.duoi1Tuoi
      case 8:
        return total.tu1Den3Tuoi
      case 9:
        return total.tu3Den6Tuoi
      case 10:
        return total.tu6Den10Tuoi
      case 11:
        return total.tren10Tuoi
      case 12:
        return total.total
      default:
        return ''
    }
  }

  return (
    <>
      <div className="flex gap-5 justify-between border-dotted border-b-0 py-2 ">
        <div className="flex gap-5">
          <div className={`w-80  ${isBlockView ? 'hidden' : ''}`}>
            <Select
              showSearch
              value={company}
              onChange={(value) => {
                setCompany(value)
                fetchBranchByChuyenKhoa('')
                setIdChuyenKhoa('')
                setValue(['all'])
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={listCompany?.map(({ idct, tenct }) => ({
                label: tenct,
                value: idct,
              }))}
              className="w-full"
            />
          </div>
          <div className={`w-64 flex gap-1 ${isBlockView ? 'hidden' : ''}`}>
            <Select
              showSearch
              value={idChuyenKhoa}
              onChange={onChangeChuyenKhoa}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { label: 'Tất cả chuyên khoa', value: '' },
                ...ChuyenKhoa.map((items) => ({
                  label: items.tenChuyenKhoa,
                  value: items.idChuyenKhoa,
                })),
              ]}
              className="w-80"
            />
          </div>
          <div className={`w-64 flex gap-1 ${isBlockView ? 'hidden' : ''}`}>
            <TreeSelect
              {...tProps}
              style={{ flexWrap: 'unset' }}
              className="w-full h-8 overflow-clip"
            />
          </div>
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          <div className="flex gap-3">
            <IconButton
              onClick={() => onLoad(company, dateForm, dateTo, value, idChuyenKhoa)}
              color="primary"
              aria-label="delete"
              size="small"
            >
              <SyncIcon />
            </IconButton>
          </div>
          <div className="font-semibold ">
            <Button
              disabled={!data?.length}
              onClick={exportToExcel}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
      </div>

      <div>
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <div style={{ height: 'calc(100vh - 200px)', overflow: 'auto' }}>
            <Table
              scroll={{
                x: 1500,
                y: '66vh',
              }}
              pagination={false}
              loading={isLoading}
              bordered
              dataSource={data}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'stt',
                  key: 'stt',
                  width: 50,
                  align: 'center',
                  render: (text, record, index) => ++index,
                },
                {
                  title: 'Chi nhánh',
                  dataIndex: 'tenChiNhanh',
                  key: 'tenChiNhanh',
                  width: 200,
                },
                {
                  title: 'Loại khám',
                  key: 1,
                  children: [
                    {
                      title: 'Lần đầu',
                      key: 1.1,
                      dataIndex: 'loaiKhamLanDau',
                      width: 80,
                      align: 'center',
                    },
                    {
                      title: 'Bệnh mới',
                      key: 1.1,
                      dataIndex: 'loaiKhamBenhMoi',
                      width: 80,
                      align: 'center',
                    },
                    {
                      title: 'Tái khám',
                      key: 1.1,
                      dataIndex: 'loaiKhamTaiKham',
                      width: 80,
                      align: 'center',
                    },
                  ],
                },
                {
                  title: 'Giới tính',
                  key: 2,
                  children: [
                    {
                      title: 'Nam',
                      key: 2.1,
                      dataIndex: 'gioiTinhNam',
                      width: 80,
                      align: 'center',
                    },
                    {
                      title: 'Nữ',
                      key: 2.1,
                      dataIndex: 'gioiTinhNu',
                      width: 80,
                      align: 'center',
                    },
                  ],
                },
                {
                  title: 'Độ tuổi',
                  key: 1,
                  children: [
                    {
                      title: 'Dưới 1 tuổi',
                      key: 1.1,
                      dataIndex: 'tuoi_Duoi_1',
                      width: 80,
                      align: 'center',
                    },
                    {
                      title: 'Từ 1 - 3 tuổi',
                      key: 1.1,
                      dataIndex: 'tuoi_1_3',
                      width: 80,
                      align: 'center',
                    },
                    {
                      title: 'Từ 3 - 6 tuổi',
                      key: 1.1,
                      dataIndex: 'tuoi_3_6',
                      width: 80,
                      align: 'center',
                    },
                    {
                      title: 'Từ 6 - 10 tuổi',
                      key: 1.1,
                      dataIndex: 'tuoi_6_10',
                      width: 80,
                      align: 'center',
                    },
                    {
                      title: 'Trên 10 tuổi',
                      key: 1.1,
                      dataIndex: 'tuoi_Tren_10',
                      width: 80,
                      align: 'center',
                    },
                  ],
                },
                {
                  title: 'Tổng cộng',
                  dataIndex: 'tongCong',
                  align: 'center',
                  key: 'tongCong',
                  width: 100,
                },
                {
                  title: 'Ghi chú',
                  dataIndex: 'ghiChu',
                  key: 'ghiChu',
                  width: 100,
                },
              ]}
              summary={(pageData) => {
                const total = {
                  khamLanDau: data.reduce((total, item) => total + item.loaiKhamLanDau, 0),
                  khamBenhMoi: data.reduce((total, item) => total + item.loaiKhamBenhMoi, 0),
                  taiKham: data.reduce((total, item) => total + item.loaiKhamTaiKham, 0),
                  nam: data.reduce((total, item) => total + item.gioiTinhNam, 0),
                  nu: data.reduce((total, item) => total + item.gioiTinhNu, 0),
                  duoi1Tuoi: data.reduce((total, item) => total + item.tuoi_Duoi_1, 0),
                  tu1Den3Tuoi: data.reduce((total, item) => total + item.tuoi_1_3, 0),
                  tu3Den6Tuoi: data.reduce((total, item) => total + item.tuoi_3_6, 0),
                  tu6Den10Tuoi: data.reduce((total, item) => total + item.tuoi_6_10, 0),
                  tren10Tuoi: data.reduce((total, item) => total + item.tuoi_Tren_10, 0),
                  total: data.reduce((total, item) => total + item.tongCong, 0),
                }
                const cells = Array.from({ length: 14 }, (_, index) => (
                  <Table.Summary.Cell key={index} align="center" index={index}>
                    <p className="font-semibold">{renderFooter(total, index)}</p>
                  </Table.Summary.Cell>
                ))
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>{cells}</Table.Summary.Row>
                  </Table.Summary>
                )
              }}
            />
          </div>
        </ConfigProvider>
      </div>
    </>
  )
}

TheoDoiKhachMoi.propTypes = {}

export default TheoDoiKhachMoi
