import { Drawer, Input, Table, ConfigProvider, Image, Badge, Modal, Button } from 'antd'
import moment from 'moment'
import React from 'react'
import { calculateSum } from '../../../../../utils/calculateSum'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { deleteDieuChinhTonChiTiet } from '../../../../../store/actions/khodieuchinhAction'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import * as XLSX from 'xlsx'
import { message } from 'antd'
const { confirm } = Modal
const DrawerInfoProducs = ({
  infoPhieuXuat,
  isModal,
  setIsModal,
  listVTHHCuaPhieuXuat,
  isLoading,
  getInfoPhieuXuatById,
}) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Bạn có chắc muốn xoá hàng hoá này ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <p>
          Hàng hoá muốn xoá là:{' '}
          <span className="font-semibold">{record.thuocVatTu.tenBietDuoc}</span>
        </p>
      ),
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        const reload = () => getInfoPhieuXuatById(record.idNhapXuat)
        dispatch(deleteDieuChinhTonChiTiet(record.idChiTietNX, reload))
      },
      onCancel() {
        // console.log('Cancel');
      },
    })
  }

  console.log('listVTHHCuaPhieuXuat', listVTHHCuaPhieuXuat)
  const exportToExcel = async () => {
    try {
      const headers = [
        'STT',
        'Mã hàng',
        'Tên biệt dược',
        'Tồn',
        'Thực tế',
        'Chênh lệch',
        'Số lô',
        'Hạn dùng',
        'Số hóa đơn',
        'Ghi chú',
      ]

      const formattedData = listVTHHCuaPhieuXuat?.map((item, index) => ({
        STT: index + 1,
        'Mã hàng': item.thuocVatTu?.maThuoc || '-',
        'Tên biệt dược': item.thuocVatTu?.tenBietDuoc || '-',
        Tồn: item.ton || 0,
        'Thực tế': item.soLuong || 0,
        'Chênh lệch': item.chenhlech || 0,
        'Số lô': item.soLo || '-',
        'Hạn dùng': item.hanDung ? moment(item.hanDung).format('DD/MM/YYYY') : '-',
        'Số hóa đơn': item.soHoaDon || '-',
        'Ghi chú': item.ghiChu || '-',
      }))

      // Calculate totals
      const totals = {
        STT: 'Tổng cộng',
        'Mã hàng': '',
        'Tên biệt dược': '',
        Tồn: formattedData.reduce((sum, item) => sum + (item['Tồn'] || 0), 0),
        'Thực tế': formattedData.reduce((sum, item) => sum + (item['Thực tế'] || 0), 0),
        'Chênh lệch': formattedData.reduce((sum, item) => sum + (item['Chênh lệch'] || 0), 0),
        'Số lô': '',
        'Hạn dùng': '',
        'Số hóa đơn': '',
        'Ghi chú': '',
      }

      // Add totals row to formatted data
      formattedData.push(totals)

      // Create workbook and worksheet
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(formattedData, {
        header: headers,
      })

      // Auto-size columns
      const colWidths = [
        { wch: 5 }, // STT
        { wch: 15 }, // Mã hàng
        { wch: 40 }, // Tên biệt dược
        { wch: 10 }, // Tồn
        { wch: 10 }, // Thực tế
        { wch: 12 }, // Chênh lệch
        { wch: 15 }, // Số lô
        { wch: 12 }, // Hạn dùng
        { wch: 15 }, // Số hóa đơn
        { wch: 20 }, // Ghi chú
      ]
      ws['!cols'] = colWidths

      // Style for totals row (bold)
      const lastRow = formattedData.length
      const range = XLSX.utils.decode_range(ws['!ref'])
      for (let C = range.s.c; C <= range.e.c; C++) {
        const cell = XLSX.utils.encode_cell({ r: lastRow - 1, c: C })
        if (!ws[cell]) ws[cell] = {}
        ws[cell].s = { font: { bold: true } }
      }

      // Add worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Thống kê kiểm kê')

      // Generate Excel file
      XLSX.writeFile(wb, `Thong_ke_kiem_ke_${moment().format('DD-MM-YYYY')}.xlsx`)
    } catch (error) {
      console.error('Export error:', error)
      message.error('Có lỗi xảy ra khi xuất file Excel')
    }
  }
  return (
    <Drawer
      placement="right"
      width={'50%'}
      open={isModal}
      onClose={() => setIsModal(false)}
      title={`CHI TIẾT PHIẾU - ${infoPhieuXuat?.maphieu}`}
    >
      <div className="flex flex-col gap-2 border-b border-double pb-2 mb-2">
        <h2 className="flex gap-1 text-gray-500 font-semibold">
          Thông tin phiếu
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
            />
          </svg>
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex gap-2">
            <label className="font-semibold text-end w-1/3 items-center">Mã phiếu:</label>
            <Input
              className="col-span-2"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.maphieu}
            />
          </div>
          <div className="flex gap-2">
            <label className="font-semibold text-end w-1/3 items-center">Người tạo:</label>
            <Input
              className="col-span-2"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.tennguoitao}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex gap-2">
            <label className="font-semibold text-end w-1/3 items-center">Chi nhánh:</label>
            <Input
              className="col-span-2"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.tenchinhanh}
            />
          </div>
          <div className="flex gap-2">
            <label className="font-semibold text-end w-1/3 items-center">Kho:</label>
            <Input
              className="col-span-2"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.tenkho}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex gap-2">
            <label className="font-semibold text-end w-1/3 items-center">Ghi chú:</label>
            <Input
              className="col-span-2"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.ghichu}
            />
          </div>
          <div className="flex gap-2">
            <label className="font-semibold text-end w-1/3 items-center">Ngày:</label>
            <Input
              className="col-span-2"
              size="small"
              readOnly
              variant="filled"
              value={infoPhieuXuat?.ngaytao && moment(infoPhieuXuat?.ngaytao).format('DD/MM/YYYY')}
            />
          </div>
        </div>
      </div>
      <ConfigProvider
        theme={{
          token: { padding: 2, fontSize: 12 },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <div className="flex justify-between items-center mb-2">
          <h2 className="flex gap-1 text-gray-500 font-semibold">
            Chi tiết hàng
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-5"
            >
              <path d="M12.378 1.602a.75.75 0 0 0-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03ZM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 0 0 .372-.648V7.93ZM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 0 0 .372.648l8.628 5.033Z" />
            </svg>
          </h2>

          <Button
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
        <Table
          scroll={{ x: 800, y: 350 }}
          pagination={false}
          loading={isLoading}
          bordered
          dataSource={listVTHHCuaPhieuXuat}
          columns={[
            {
              key: 1,
              title: 'Thông tin hàng',
              children: [
                {
                  key: 1.1,
                  title: 'STT',
                  dataIndex: 'stt',
                  width: 35,
                  fixed: true,
                  align: 'center',
                },
                {
                  key: 1.4,
                  title: 'Mã hàng',
                  dataIndex: ['thuocVatTu', 'maThuoc'],
                  width: 90,
                  fixed: true,
                },
                {
                  key: 1.2,
                  title: 'Tên biệt dược',
                  dataIndex: ['thuocVatTu', 'tenBietDuoc'],
                  fixed: true,
                  width: 300,
                },
              ],
            },

            {
              key: 5,
              title: 'Chi tiết',
              children: [
                {
                  key: 5.1,
                  title: 'Tồn',
                  dataIndex: 'ton',
                  width: 80,
                  align: 'center',
                },
                {
                  key: 5.0,
                  title: 'Chênh lệch',
                  dataIndex: 'chenhlech',
                  width: 80,
                  align: 'center',
                  render: (text) => (
                    <p className={`font-semibold ${text >= 0 ? 'text-green-700' : 'text-red-500'}`}>
                      {text}
                    </p>
                  ),
                },
                {
                  key: 5.5,
                  title: 'Thực tế',
                  dataIndex: 'thucte',
                  width: 80,
                  align: 'center',
                  render: (_, record) => record?.soLuong,
                },
                {
                  key: 5.2,
                  title: 'Số lô',
                  dataIndex: 'soLo',
                  width: 85,
                  align: 'center',
                },
                {
                  key: 5.3,
                  title: 'Hạn dùng',
                  dataIndex: 'hanDung',
                  align: 'center',
                  width: 75,
                  render: (text, record, index) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
                },
                {
                  key: 5.4,
                  title: 'Số hóa đơn',
                  dataIndex: 'soHoaDon',
                  align: 'center',
                  width: 85,
                },
                {
                  key: 5.6,
                  title: 'Ghi chú',
                  dataIndex: 'ghiChu',
                  align: 'left',
                  width: 150,
                },
                {
                  key: 5.7,
                  title: '',
                  dataIndex: 'action',
                  align: 'center',
                  width: 40,
                  fixed: 'right',
                  render: (_, record) => (
                    <button
                      disabled={
                        infoUser?.idnv !== infoPhieuXuat.nguoitao &&
                        ![28, 27, 1].includes(infoUser?.idNhom)
                      }
                      onClick={() => showDeleteConfirm(record)}
                      className={`${![28, 27, 1].includes(infoUser?.idNhom) && infoUser?.idnv !== infoPhieuXuat.nguoitao ? 'text-gray-500' : 'text-red-700 hover:bg-red-700 hover:text-white'} p-1 bg-red-100 rounded cursor-pointer  duration-200 `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    </button>
                  ),
                },
              ],
            },
          ]}
          summary={(pageData) => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="center">
                  {calculateSum(pageData, 'ton')}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="center">
                  {calculateSum(pageData, 'chenhlech')}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="center">
                  {calculateSum(pageData, 'soLuong')}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
                <Table.Summary.Cell index={10}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </ConfigProvider>
      <div className="border-t border-double mt-2 pt-2">
        <h2 className="flex gap-1 text-gray-500 font-semibold mb-2 items-center">
          Đính kèm
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-5"
          >
            <path
              fillRule="evenodd"
              d="M18.97 3.659a2.25 2.25 0 0 0-3.182 0l-10.94 10.94a3.75 3.75 0 1 0 5.304 5.303l7.693-7.693a.75.75 0 0 1 1.06 1.06l-7.693 7.693a5.25 5.25 0 1 1-7.424-7.424l10.939-10.94a3.75 3.75 0 1 1 5.303 5.304L9.097 18.835l-.008.008-.007.007-.002.002-.003.002A2.25 2.25 0 0 1 5.91 15.66l7.81-7.81a.75.75 0 0 1 1.061 1.06l-7.81 7.81a.75.75 0 0 0 1.054 1.068L18.97 6.84a2.25 2.25 0 0 0 0-3.182Z"
              clipRule="evenodd"
            />
          </svg>
          <Badge
            className="site-badge-count-109"
            count={infoPhieuXuat?.filehoadon?.split(';').length}
            style={{
              backgroundColor: '#1677FF',
            }}
          />
        </h2>
        <div>
          <ul className="flex flex-wrap gap-2">
            {infoPhieuXuat?.filehoadon?.split(';')?.map((url, index) => {
              const isImage =
                url.endsWith('.jpg') ||
                url.endsWith('.jpeg') ||
                url.endsWith('.png') ||
                url.endsWith('.gif') ||
                url.endsWith('.bmp')
              return (
                url?.length && (
                  <li
                    key={`hinh${index}:${url}`}
                    className="w-[100px] h-[100px] bg-red-200 border rounded shadow-sm"
                  >
                    {isImage ? (
                      <Image src={url} className="bg-auto" width={100} height={100} />
                    ) : (
                      <a href={url} target="_blank">
                        <img
                          width={100}
                          height={100}
                          src="https://www.svgrepo.com/download/144578/pdf.svg"
                          alt={url}
                        />
                      </a>
                    )}
                  </li>
                )
              )
            })}
          </ul>
        </div>
      </div>
    </Drawer>
  )
}

export default DrawerInfoProducs
