import * as typeAction from '../.../../constants/constants'

export const postBieuMau = (form, reset) => ({
  type: typeAction.POST_BIEU_MAU_SIEU_AM,
  form,
  reset,
})

export const getAllBieuMau = (idLoaiBM) => ({
  type: typeAction.GET_ALL_BIEU_MAU,
  idLoaiBM,
})

export const infoBNSieuAm = (id, idcdha, tenbenhnhan) => ({
  type: typeAction.GET_INFO_BN_SIEU_AM,
  id,
  idcdha,
  tenbenhnhan,
})
export const saveBnSieuAm = (form, formBNTTC, idcn, reload, listImagePrint) => ({
  type: typeAction.POST_BENH_NHAN_SIEU_AM,
  form,
  formBNTTC,
  idcn,
  reload,
  listImagePrint,
})
export const infoBnPending = (id, idcdha, tenbenhnhan) => ({
  type: typeAction.GET_INFO_BN_PENDING_SIEU_AM,
  id,
  idcdha,
  tenbenhnhan,
})
