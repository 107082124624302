import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Button, ConfigProvider, Table, Checkbox, Popconfirm, Spin, Select } from 'antd'
import { SyncOutlined, PlusOutlined } from '@ant-design/icons'
import {
  putSuDungNhomQuyenAction,
  putSuDungQuyenHeThongAction,
  putSuDungListCTNQAction,
} from '../../../../store/actions/phanQuyenAction'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { debounce } from 'lodash'
import ModalNhom from './Modal/ModalNhom'
import ModalQuyen from './Modal/ModalQuyen'
import ToastCus from '../../../../utils/Toast'
import moment from 'moment'
import 'moment/locale/vi'
import { addLog } from '../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../utils/log/logConstant'
moment.locale('vi')
const NhomQuyen = ({ tabQuyen }) => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)

  const [isModalNhom, setisModalNhom] = useState(false)
  const [infoEditNhom, setinfoEditNhom] = useState(null)
  const [listNhom, setlistNhom] = useState([])
  const [isLoadingNhom, setisLoadingNhom] = useState(false)
  const [keywordNhom, setkeywordNhom] = useState(null)

  const [isModalQuyen, setisModalQuyen] = useState(false)
  const [infoEditQuyen, setinfoEditQuyen] = useState(null)
  const [listQuyen, setlistQuyen] = useState([])
  const [isLoadingQuyen, setisLoadingQuyen] = useState(false)
  const [keywordQuyen, setkeywordQuyen] = useState(null)
  const [listMenu, setlistMenu] = useState([])
  const [idMenu, setidMenu] = useState('all')

  const [listChiTietNhomQuyen, setlistChiTietNhomQuyen] = useState([])
  const [isLoadingCTNQ, setisLoadingCTNQ] = useState(false)

  const [filterChecked, setfilterChecked] = useState('all')
  const listFilterChecked = [
    {
      key: 'all',
      value: 'all',
      label: 'Tất cả',
    },
    {
      key: 1,
      value: 1,
      label: 'Được gán',
    },
    {
      key: 0,
      value: 0,
      label: 'Không được gán',
    },
  ]

  const handleSuDungCTNQ = (info, sudung) => {
    if (!infoEditNhom || !infoEditNhom?.idnqht) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn nhóm quyền!',
      })
      return
    }
    dispatch(
      putSuDungListCTNQAction(
        {
          idnqht: infoEditNhom?.idnqht,
          sudung: sudung ? 0 : 1, // update sử dụng ngược lại
          listIdQuyen: [info?.idqht], //nếu update sử dụng 1 quyền thì truyền 1 trong list
          tennhomquyen: infoEditNhom?.tennhomquyen,
        },
        getListChiTietNhomQuyen,
      ),
    )
  }
  const handleSuDungListCTNQ = (isChecked) => {
    if (!infoEditNhom || !infoEditNhom?.idnqht) {
      ToastCus.fire({
        icon: 'error',
        title: 'Vui lòng chọn nhóm quyền!',
      })
      return
    }
    dispatch(
      putSuDungListCTNQAction(
        {
          idnqht: infoEditNhom?.idnqht,
          sudung: isChecked ? 0 : 1, // update sử dụng ngược lại
          listIdQuyen: listQuyen?.map((item) => item?.idqht),
          tennhomquyen: infoEditNhom?.tennhomquyen,
        },
        getListChiTietNhomQuyen,
      ),
    )
  }
  const getListChiTietNhomQuyen = async (idnqht) => {
    try {
      setisLoadingCTNQ(true)
      const { data } = await phanQuyenService.getListChiTietNhomQuyen(idnqht)
      setlistChiTietNhomQuyen(data)
      setlistQuyen((prev) =>
        prev?.map((item) => ({
          ...item,
          checked: data?.length
            ? data?.find((itemCtnq) => itemCtnq?.idqht === item?.idqht)?.sudung ?? 0
            : 0,
        })),
      )
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_QUYEN_HE_THONG_BY_ID,
        true,
        MENU_CONSTANTS.PHAN_QUYEN,
        null,
        {
          tenNhom: listNhom.find((item) => item?.idnqht === idnqht)?.tennhomquyen,
        },
      )
    } catch (error) {
      console.log('getListChiTietNhomQuyen : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_QUYEN_HE_THONG_BY_ID,
        false,
        MENU_CONSTANTS.PHAN_QUYEN,
        detailErrorPayload,
      )
    } finally {
      setisLoadingCTNQ(false)
    }
  }
  const getListNhom = async () => {
    try {
      setisLoadingNhom(true)
      const { data } = await phanQuyenService.getListNhomQuyen(
        keywordNhom?.length ? keywordNhom?.trim() : null,
      )
      setlistNhom(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.XEM_NHOM_QUYEN_HE_THONG,
        true,
        MENU_CONSTANTS.PHAN_QUYEN,
        null,
        {
          keyword: keywordNhom ?? '',
        },
      )
    } catch (error) {
      console.log('getListNhom : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.XEM_NHOM_QUYEN_HE_THONG,
        false,
        MENU_CONSTANTS.PHAN_QUYEN,
        detailErrorPayload,
      )
    } finally {
      setisLoadingNhom(false)
    }
  }
  const getListQuyen = async () => {
    try {
      setisLoadingQuyen(true)
      const { data } = await phanQuyenService.getListQuyenHeThong(
        keywordQuyen?.length ? keywordQuyen?.trim() : null,
        `${idMenu ?? ''}`?.includes('all') ? null : idMenu,
      )
      setlistQuyen(
        data
          ?.map((item) => ({
            ...item,
            checked:
              listChiTietNhomQuyen?.length && infoEditNhom
                ? listChiTietNhomQuyen?.find((itemCtnq) => itemCtnq?.idqht === item?.idqht)
                    ?.sudung ?? 0
                : 0,
            tenMenuSort: `${item?.tenmenucha ? `${item?.tenmenucha} / ` : ''}${item?.tenmenu}`,
          }))
          ?.sort((a, b) => (a?.tenMenuSort ?? '')?.localeCompare(b?.tenMenuSort ?? '')),
      )
      const nameMenu =
        idMenu === 'all' ? 'Tất cả' : listMenu.find((item) => item?.value === idMenu)?.label
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.XEM_QUYEN_THEO_MENU_KEYWORD,
        true,
        MENU_CONSTANTS.PHAN_QUYEN,
        null,
        {
          keyword: keywordQuyen ?? '',
          menu: nameMenu,
        },
      )
    } catch (error) {
      console.log('getListQuyen : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.XEM_QUYEN_THEO_MENU_KEYWORD,
        false,
        MENU_CONSTANTS.PHAN_QUYEN,
        detailErrorPayload,
      )
    } finally {
      setisLoadingQuyen(false)
    }
  }
  const getListMenu = async () => {
    try {
      const { data } = await phanQuyenService.getListQuyenHeThong(null, null)
      const menuOptions = Array.from(
        new Set(
          data?.map((item) =>
            JSON.stringify({
              idmenu: item?.idmenu,
              tenmenu: `${item?.tenmenucha ? `${item?.tenmenucha} / ` : ''}${item?.tenmenu}`,
            }),
          ),
        ),
      ).map((str) => {
        const item = JSON.parse(str)
        return {
          key: item.idmenu,
          value: item.idmenu,
          label: item.tenmenu,
        }
      })
      setlistMenu(menuOptions)
    } catch (error) {
      console.log('getListMenu : ', error)
      ToastCus.fire({
        icon: 'error',
        title: 'Lấy dữ liệu thất bại!',
      })
    }
  }
  useEffect(() => {
    if (tabQuyen === 2) {
      getListQuyen()
      getListNhom()
      getListMenu()
      setinfoEditNhom(null)
    }
  }, [tabQuyen])
  const debounceKeywordNhom = useCallback(debounce((e) => setkeywordNhom(e.target.value), 300))
  const debounceKeywordQuyen = useCallback(debounce((e) => setkeywordQuyen(e.target.value), 300))
  return (
    <>
      <ModalNhom
        isModalNhom={isModalNhom}
        setisModalNhom={setisModalNhom}
        infoEditNhom={infoEditNhom}
        getListNhom={getListNhom}
      />
      <ModalQuyen
        isModalQuyen={isModalQuyen}
        setisModalQuyen={setisModalQuyen}
        infoEditQuyen={infoEditQuyen}
        getListQuyen={getListQuyen}
      />
      <div className="grid grid-cols-5 h-[49.4rem]">
        <div className="col-span-2 border-e pr-2">
          <div className="flex justify-start items-center gap-2 mb-2">
            <Input
              allowClear
              placeholder="Tìm tên nhóm quyền..."
              className="w-60"
              // value={keywordNhom}
              // onChange={(e) => setkeywordNhom(e.target.value)}
              onChange={debounceKeywordNhom}
            />
            <Button
              loading={isLoadingNhom}
              type="primary"
              shape="circle"
              icon={<SyncOutlined />}
              onClick={() => {
                setkeywordNhom((prev) => (prev?.length ? prev?.trim() : prev))
                getListNhom()
              }}
            />
            <div className="mx-auto font-semibold">
              {infoEditNhom?.tennhomquyen ? (
                `Đang chọn: ${infoEditNhom?.tennhomquyen}`
              ) : (
                <span className="text-red-500">Chưa chọn nhóm</span>
              )}
            </div>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setisModalNhom(true)
                setinfoEditNhom(null)
              }}
            >
              Thêm nhóm
            </Button>
          </div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              onRow={(record) => {
                return {
                  onClick: () => {
                    setinfoEditNhom(record)
                    getListChiTietNhomQuyen(record?.idnqht)
                  },
                }
              }}
              rowClassName={
                (record) =>
                  infoEditNhom && record?.idnqht === infoEditNhom?.idnqht ? 'bg-sky-100' : '' //bg-[#ecf0f1]
              }
              loading={isLoadingNhom}
              scroll={{ y: 715 }}
              pagination={false}
              bordered
              dataSource={listNhom}
              columns={[
                {
                  title: 'STT',
                  dataIndex: 'stt',
                  key: 'stt',
                  width: 35,
                  align: 'center',
                  render: (text, record, index) => ++index,
                },
                {
                  title: 'Tên nhóm',
                  dataIndex: 'tennhomquyen',
                  key: 'tennhomquyen',
                },
                {
                  title: 'Ghi chú',
                  dataIndex: 'ghichu',
                  key: 'ghichu',
                  width: 200,
                },
                {
                  title: 'Sử dụng',
                  dataIndex: 'sudung',
                  key: 'sudung',
                  width: 70,
                  align: 'center',
                  // fixed: 'right',
                  render: (text, record, index) => (
                    <Popconfirm
                      placement="topRight"
                      okText="Xác nhận"
                      cancelText="Hủy bỏ"
                      title={'Thay đổi sử dụng nhóm?'}
                      onConfirm={() => dispatch(putSuDungNhomQuyenAction(record, getListNhom))}
                      description={
                        <>
                          <p>
                            Bạn xác nhận muốn&nbsp;
                            <span className="font-semibold">
                              {text ? 'NGỪNG SỬ DỤNG' : 'SỬ DỤNG'}
                            </span>
                            &nbsp;nhóm này?
                          </p>
                          {text ? (
                            <p>
                              Có&nbsp;
                              <span className="font-semibold">{record?.tongsonguoidung}</span>
                              &nbsp;tài khoản thuộc nhóm&nbsp;
                              <span className="font-semibold">{record?.tennhomquyen}</span>
                              &nbsp;sẽ chuyển thành nhóm&nbsp;
                              <span className="font-semibold">Tùy chỉnh</span>
                            </p>
                          ) : (
                            ''
                          )}
                        </>
                      }
                    >
                      <Checkbox checked={text} />
                    </Popconfirm>
                  ),
                },
              ]}
            />
          </ConfigProvider>
        </div>
        <div className="col-span-3 pl-2">
          <div className="flex justify-start items-center gap-2 mb-2">
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes((input ?? '').toLowerCase())
              }
              className="w-[17rem]"
              value={idMenu}
              onChange={setidMenu}
              options={[
                {
                  label: 'Tất cả menu',
                  value: 'all',
                  key: 'all',
                },
                ...listMenu?.sort((a, b) => a?.label?.localeCompare(b?.label)),
              ]}
            />
            <Input
              allowClear
              placeholder="Tìm mã quyền, tên quyền..."
              className="w-[17rem]"
              // value={keywordQuyen}
              // onChange={(e) => setkeywordQuyen(e.target.value)}
              onChange={debounceKeywordQuyen}
            />
            <Button
              loading={isLoadingQuyen}
              type="primary"
              shape="circle"
              icon={<SyncOutlined />}
              onClick={() => {
                setkeywordQuyen((prev) => (prev?.length ? prev?.trim() : prev))
                getListQuyen()
              }}
            />
            {/* <Select
              className="ml-auto w-[10.3rem]"
              value={filterChecked}
              onChange={setfilterChecked}
              options={listFilterChecked}
            /> */}
          </div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
              },
              components: {
                Table: {
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <style>
              {`
                .custom-scroll-bar .ant-table-tbody-virtual-scrollbar {                
                  background: #f0f0f0 !important;
                }
                .custom-scroll-bar .ant-table-tbody-virtual-scrollbar-vertical {
                  width: 0.85rem !important;
                  height: 100% !important;
                  z-index: 1;
                }           
                .custom-scroll-bar .ant-table-tbody-virtual-scrollbar-horizontal {
                  width: 100% !important;
                  height: 0.85rem !important;
                }                  
                .custom-scroll-bar .ant-table-tbody-virtual-scrollbar .ant-table-tbody-virtual-scrollbar-thumb{
                  background: #b4b4b4 !important;
                  border-radius: 0px !important;
                }
              `}
            </style>
            <Table
              virtual
              loading={isLoadingQuyen}
              className="custom-scroll-bar"
              scroll={{ x: 1260, y: 715 }}
              pagination={false}
              bordered
              dataSource={
                listQuyen
                // infoEditNhom && filterChecked !== 'all'
                //   ? listQuyen?.filter((item) => item?.checked === filterChecked)
                //   : listQuyen
              }
              columns={[
                {
                  title: () => {
                    let isIndeterminate =
                      listQuyen?.some((itemQ) => itemQ?.checked) &&
                      listQuyen?.some((itemQ) => !itemQ?.checked)
                    // listQuyen?.some((itemQ) =>
                    //   listChiTietNhomQuyen?.find(
                    //     (itemCtnq) => itemCtnq?.idqht === itemQ?.idqht && itemCtnq?.sudung === 1,
                    //   ),
                    // ) &&
                    // listQuyen?.some((itemQ) =>
                    //   listChiTietNhomQuyen?.find(
                    //     (itemCtnq) => itemCtnq?.idqht === itemQ?.idqht && !itemCtnq?.sudung,
                    //   ),
                    // )
                    let isChecked = listQuyen?.every((itemQ) => itemQ?.checked)
                    // listQuyen?.every((itemQ) =>
                    //   listChiTietNhomQuyen
                    //     ?.filter((itemCtnq) => itemCtnq?.sudung === 1)
                    //     ?.find((itemCtnq) => itemCtnq?.idqht === itemQ?.idqht),
                    // )
                    return !infoEditNhom || !listQuyen?.length ? (
                      <Checkbox
                        checked={false}
                        onChange={() => {
                          ToastCus.fire({
                            icon: 'error',
                            title: !infoEditNhom
                              ? 'Vui lòng chọn nhóm quyền!'
                              : 'Vui lòng kiểm tra lại!',
                          })
                        }}
                      />
                    ) : isLoadingCTNQ ? (
                      <Spin spinning={isLoadingCTNQ} size="small"></Spin>
                    ) : (
                      <>
                        <Popconfirm
                          // placement="topLeft"
                          title={'Gán quyền vào nhóm'}
                          description={
                            <div>
                              <div>
                                Bạn xác nhận muốn&nbsp;
                                <span className="font-semibold">
                                  {isChecked ? 'BỎ GÁN' : 'GÁN'}
                                </span>
                                &nbsp;cho&nbsp;
                                <span className="font-semibold">Tất cả quyền đang xem</span>?
                              </div>
                            </div>
                          }
                          onConfirm={() => handleSuDungListCTNQ(isChecked)}
                          okText="Xác nhận"
                          cancelText="Hủy bỏ"
                          okButtonProps={{
                            className: 'custom-ok-button',
                          }}
                        >
                          <Checkbox indeterminate={isIndeterminate} checked={isChecked} />
                        </Popconfirm>
                      </>
                    )
                  },
                  dataIndex: 'checked',
                  key: 'checked',
                  width: 50,
                  align: 'center',
                  fixed: 'left',
                  filters: [
                    {
                      key: 1,
                      value: 1,
                      text: 'Được chọn',
                    },
                    {
                      key: 0,
                      value: 0,
                      text: 'Không được chọn',
                    },
                  ],
                  onFilter: (value, record) => {
                    return value === record?.checked || (value === 0 && !record?.checked)
                  },
                  render: (text, record, index) => {
                    let checked = text
                    //  listChiTietNhomQuyen?.find(
                    //   (itemCtnq) => itemCtnq?.idqht === record?.idqht,
                    // )?.sudung
                    return !infoEditNhom ? (
                      <Checkbox
                        disabled={!record?.sudung}
                        checked={false}
                        onChange={() => {
                          ToastCus.fire({
                            icon: 'error',
                            title: 'Vui lòng chọn nhóm quyền!',
                          })
                        }}
                      />
                    ) : isLoadingCTNQ ? (
                      <Spin spinning={isLoadingCTNQ} size="small"></Spin>
                    ) : (
                      <>
                        <Popconfirm
                          // placement="topLeft"
                          title={'Gán quyền vào nhóm'}
                          description={
                            <div>
                              <div>
                                Bạn xác nhận muốn&nbsp;
                                <span className="font-semibold">{text ? 'BỎ GÁN' : 'GÁN'}</span>?
                              </div>
                            </div>
                          }
                          onConfirm={() => handleSuDungCTNQ(record, text)}
                          okText="Xác nhận"
                          cancelText="Hủy bỏ"
                          okButtonProps={{
                            className: 'custom-ok-button',
                          }}
                        >
                          <Checkbox disabled={!record?.sudung} checked={text} />
                        </Popconfirm>
                      </>
                    )
                  },
                },
                {
                  title: 'STT',
                  dataIndex: 'stt',
                  key: 'stt',
                  width: 35,
                  align: 'center',
                  fixed: 'left',
                  render: (text, record, index) => ++index,
                },
                {
                  title: 'Tên quyền',
                  dataIndex: 'tenquyen',
                  key: 'tenquyen',
                  fixed: 'left',
                },
                {
                  title: 'Menu',
                  dataIndex: 'tenmenu',
                  key: 'tenmenu',
                  width: 250,
                  render: (text, record, index) =>
                    `${record?.tenmenucha ? `${record?.tenmenucha} / ` : ''}${record?.tenmenu}`,
                  // filters: [...new Set(listQuyen?.map((item) => item?.tenmenu))]?.map(
                  //   (item, index) => ({
                  //     key: index,
                  //     value: item,
                  //     text: item,
                  //   }),
                  // ),
                  // onFilter: (value, record) => {
                  //   return record?.tenmenu.indexOf(value) === 0
                  // },
                },
                {
                  title: 'Ghi chú',
                  dataIndex: 'ghichu',
                  key: 'ghichu',
                  width: 200,
                },
                {
                  title: 'Mã quyền',
                  dataIndex: 'maquyen',
                  key: 'maquyen',
                  width: 80,
                },
                {
                  title: 'Sử dụng',
                  dataIndex: 'sudung',
                  key: 'sudung',
                  width: 70,
                  align: 'center',
                  fixed: 'right',
                  render: (text, record, index) => (
                    <Popconfirm
                      placement="topRight"
                      okText="Xác nhận"
                      cancelText="Hủy bỏ"
                      title={'Thay đổi sử dụng quyền?'}
                      onConfirm={() => dispatch(putSuDungQuyenHeThongAction(record, getListQuyen))}
                      description={
                        <>
                          <p>
                            Bạn xác nhận muốn&nbsp;
                            <span className="font-semibold">
                              {text ? 'NGỪNG SỬ DỤNG' : 'SỬ DỤNG'}
                            </span>
                            &nbsp;quyền:
                          </p>
                          <p className="font-semibold">
                            {record?.maquyen ? `${record?.maquyen} - ` : ''}
                            {record?.tenquyen}
                          </p>
                        </>
                      }
                    >
                      <Checkbox checked={text} />
                    </Popconfirm>
                  ),
                },
              ]}
            />
          </ConfigProvider>
        </div>
      </div>
    </>
  )
}

export default NhomQuyen
