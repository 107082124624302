import { Button, Col, Input, Row } from 'antd'
import React from 'react'
const styleLabel = {
  fontWeight: 600,
}
const InfoTemplate = () => {
  return (
    <form className="flex flex-col xl:gap-5 gap-3">
      <Row
        gutter={16}
      >
        <Col lg={24} xl={12} className='xl:mb-0 mb-2' >
          <label style={styleLabel}>Mã template:</label>
          <Input variant="filled" />
        </Col>
        <Col lg={24} xl={12}>
          <label style={styleLabel}>Tiêu đề:</label>
          <Input />
        </Col>
      </Row>
      <Row
        gutter={{
          lg: 16,
        }}
      >
        <Col lg={24} xl={12} className='xl:mb-0 mb-2'>
          <label style={styleLabel}>Chuyên khoa:</label>
          <Input />
        </Col>
        <Col lg={24} xl={12}>
          <label style={styleLabel}>Loại tài khoản:</label>
          <Input />
        </Col>
      </Row>
      <Row
        gutter={{
          lg: 16,
        }}
      >
        <Col lg={24} xl={12} className='xl:mb-0 mb-2'>
          <label style={styleLabel}>Nội dung không dấu:</label>
          <Input.TextArea
            style={{
              height: 80,
              resize: 'none',
            }}
            showCount
            placeholder="Nội dung không dấu"
          />
        </Col>
        <Col lg={24} xl={12}>
          <label style={styleLabel}>Nội dung có dấu:</label>
          <Input.TextArea
            style={{
              height: 80,
              resize: 'none',
            }}
            showCount
            placeholder="Nội dung không dấu"
          />
        </Col>
      </Row>
      <label className="text-gray-500">
        Ngày tạo: 2024/01/12 13:55:23 / Người tạo: Nguyễn Quốc Tài
      </label>
      <div>
        <Button type="primary">
          Cập nhập
        </Button>
      </div>
    </form>
  )
}

export default InfoTemplate
