import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Table, ConfigProvider } from 'antd'
import { HistoryOutlined } from '@ant-design/icons'
import style from './style.module.css'
import moment from 'moment'
const columns = [
  {
    title: 'Ngày khám',
    dataIndex: 'ngaykham',
    width: 130,
    align: 'center',
  },
  {
    title: 'Tái khám',
    dataIndex: 'TaiKham',
    width: 80,
    align: 'center',
  },
  {
    title: 'Loại khám',
    dataIndex: 'LoaiKham',
    width: 140,
  },
  {
    title: 'Chẩn đoán',
    dataIndex: 'ChuanDoan',
  },
  {
    title: 'Bác sĩ khám',
    dataIndex: 'BSKham',
    width: 250,
  },
]

const TableHistory = ({ history }) => {
  return (
    <div className="w-1/3 border rounded-lg">
      <h2 className="font-semibold text-base text-gray-500 p-2 border-b ">
        Lịch sử khám <HistoryOutlined />
      </h2>
      <ul className="flex gap-2 flex-col p-1 overflow-auto h-[460px]">
        {history?.map((item) => (
          <li className="border p-2 rounded">
            <h2 className="font-semibold flex justify-between">
              {' '}
              {item.ngaykham && moment(item.ngaykham).format('DD/MM/YYYY HH:mm:ss')}{' '}
              <span className={item.idloaikham === 2 ? 'text-green-700':'text-blue-500'}>{item?.tenLoaiKham}</span>
            </h2>
            <p className='text-gray-500'>Tái khám:{item.ngaytaikham && moment(item.ngaytaikham).format('DD/MM/YYYY')}</p>
            <p className='text-gray-500 truncate'>Chẩn đoán:{item.chandoan}</p>
            <p className='text-gray-500'>Dự sanh:{item.dusanh && moment(item.dusanh).format('DD/MM/YYYY')}</p>
            <p className='text-gray-500'>BS khám:{item.bskham}</p>
          </li>
        ))}
      </ul>
   
    </div>
  )
}

export default TableHistory
