import { call, put, select, takeLatest } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import * as typeAction from '../constants/constants'
import { suCoService } from '../../services/suCoService/suCoServvice'
import {
  getAllNhanSuCo,
  getImgBaoHong,
  getInfoXuLy,
  getKTThucHienBaoHong,
} from '../actions/suCoAction'
import { nhanvienService } from '../../services/nhanvien/nhanvienService'
import ToastCus from '../../utils/Toast'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* suCoSaga() {
  //getNhanSuCo
  yield takeLatest(typeAction.GET_NHAN_SU_CO, function* getNhanSuCo({ type, idnhom, keyword }) {
    try {
      const { data } = yield call(() => suCoService.getAllNhanSuCo(idnhom, keyword))
      yield put({
        type: typeAction.DISPATCH_NHAN_SU_CO,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //getNhanSuCo
  yield takeLatest(typeAction.GET_NHAN_DANH_MUC, function* getNhanDanhMuc({ type, setNhan }) {
    try {
      const { data } = yield call(() => suCoService.getAllNhanSuCo('', ''))
      setNhan(data)
    } catch (err) {
      yield console.log(err)
    }
  })
  //lấy info nhãn sự cố
  yield takeLatest(typeAction.GET_INFO_NHAN_SU_CO, function* ({ type, id }) {
    try {
      const { data } = yield call(() => suCoService.getDetailNhan(id))
      yield put({
        type: typeAction.DISPATCH_INFO_NHAN,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // GET DANH MỤC NHÃN
  yield takeLatest(
    typeAction.GET_DANH_MUC_BY_NHAN,
    function* getDanhMucByNhan({ type, id, keyword }) {
      try {
        const { data } = yield call(() => suCoService.getDanhMucByNhan(id, keyword))
        yield put({
          type: typeAction.DISPATCH_DANH_MUC_NHAN,
          payload: data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // GET DANH SACH LỊCH SỬ NHÂN VIÊN SỰ CỐ
  yield takeLatest(
    typeAction.GET_HISTORY_STAFF_SU_CO,
    function* getHistorySuCoStaff({ type, idbh }) {
      try {
        const { data } = yield call(() => suCoService.getHistoryStaff(idbh))
        yield put({
          type: typeAction.DISPACTH_HISTORY_STAFF_SU_CO,
          payload: data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //ADD NEW NHAN
  yield takeLatest(typeAction.ADD_NEW_NHAN, function* ADDNhanSuCo({ type, form, onReset }) {
    const { infoUser } = yield select((state) => state.userReducer)
    try {
      const { data } = yield call(() => suCoService.postNhanSuCo(form))
      yield put(getAllNhanSuCo())
      onReset()
      Toast.fire({
        icon: 'success',
        title: 'Tạo nhãn thành công!',
      })
      const defaultForLog = {
        tennhan: form.tennhan,
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        logAction.THEM_NHAN_SU_CO,
        true,
        MENU_CONSTANTS.SU_CO,
        null,
        defaultForLog,
      )
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Tạo nhãn thất bại!',
      })
      yield console.log(error)

      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.SU_CO,
        detailErrorPayload,
      )
    }
  })
  //update báo hổng
  yield takeLatest(typeAction.UPDATE_SU_CO_BAO_HONG, function* updateBaoHong({ type, id, status }) {
    try {
      const { data } = yield call(() => suCoService.updateStatusBaoHong(id, status))
      Toast.fire({
        icon: 'success',
        title: 'Cập nhật thành công!',
      })
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: 'cập nhật thất bại!',
      })
      yield console.log(err)
    }
  })
  //TẠO BÁO HỎNG
  yield takeLatest(
    typeAction.CREATE_BAO_HONG,
    function* createBaoHong({ type, form, nhanVien, nguoitao, fetchData, onReset }) {
      const formData = new FormData()
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const { data } = yield call(() => suCoService.postKTBaoHong(form))
        formData.append('IDBAOHONG', data.idbaohong)
        yield call(() => suCoService.postXuLyBaoHong(formData))
        const NewNhanVienData = nhanVien.map((id) => ({
          idnv: id,
          nguoitao: nguoitao,
          idbaohong: data.idbaohong,
        }))
        for (let staff of NewNhanVienData) {
          yield call(() => suCoService.postNhanVienBaoHong(staff))
        }
        fetchData()
        onReset()
        Toast.fire({
          icon: 'success',
          title: 'Tạo thành công!',
        })

        const defaultForLog = {
          tenbaohong: data.tenbaohong,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          logAction.THEM_SU_CO,
          true,
          MENU_CONSTANTS.SU_CO,
          null,
          defaultForLog,
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Tạo  thất bại!',
        })
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.SU_CO,
          detailErrorPayload,
        )
      }
    },
  )
  //POST DANH MỤC NHÃN SỰ CỐ
  yield takeLatest(
    typeAction.POST_DANH_MUC_NHAN_SU_CO,
    function* postDanhMuc({ type, form, onReset }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const { data } = yield call(() => suCoService.postDanhMucNhan(form))
        onReset()
        Toast.fire({
          icon: 'success',
          title: 'Tạo nhãn thành công!',
        })

        const defaultForLog = {
          idnhan: form.idnhan,
          tendanhmuc: form.tendanhmuc,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          logAction.THEM_DANH_MUC_SU_CO,
          true,
          MENU_CONSTANTS.SU_CO,
          null,
          defaultForLog,
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Tạo nhãn thất bại!',
        })
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.SU_CO,
          detailErrorPayload,
        )
      }
    },
  )
  //DETELE NHÃN SỰ CỐ
  yield takeLatest(
    typeAction.DELETE_NHAN_SU_CO,
    function* deleteNhanSuCo({ type, id, tennhan, handleCancel }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const { data } = yield call(() => suCoService.deleteNhanSuCo(id))
        handleCancel()
        yield put(getAllNhanSuCo())
        Toast.fire({
          icon: 'success',
          title: 'Xoá nhãn thành công!',
        })

        const defaultForLog = {
          tennhan: tennhan,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.DELETE,
          logAction.XOA_NHAN_SU_CO,
          true,
          MENU_CONSTANTS.SU_CO,
          null,
          defaultForLog,
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Xoá nhãn thất bại!',
        })
        yield console.log(error)

        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(id)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.SU_CO,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(
    typeAction.DELETE_DANH_MUC_NHAN,
    function* deleteDanhMucNhan({ type, id, name, fetchDanhMucNhan }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const { data } = yield call(() => suCoService.deleteDanhMucNhan(id))
        fetchDanhMucNhan()
        Toast.fire({
          icon: 'success',
          title: 'Xoá danh mục thành công!',
        })

        const defaultForLog = {
          idnhandm: id,
          tendanhmuc: name,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.DELETE,
          logAction.DELETE_DANH_MUC_SU_CO,
          true,
          MENU_CONSTANTS.SU_CO,
          null,
          defaultForLog,
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Xoá danh mục thất bại!',
        })
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(id)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.SU_CO,
          detailErrorPayload,
        )
      }
    },
  )
  yield takeLatest(
    typeAction.UPDATE_DANH_MUC_NHAN,
    function* updateDanhMuc({ type, form, handleCancel, fetchDanhMucNhan }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const { data } = yield call(() => suCoService.updateDanhMuc(form))
        handleCancel()
        fetchDanhMucNhan()
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật danh mục thành công!',
        })
        const defaultForLog = {
          idnhandm: form.idnhandm,
          tendanhmuc: form.tendanhmuc,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          logAction.CAP_NHAT_DANH_MUC_SU_CO,
          true,
          MENU_CONSTANTS.SU_CO,
          null,
          defaultForLog,
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật danh mục thất bại!',
        })
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
           \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.SU_CO,
          detailErrorPayload,
        )
      }
    },
  )
  //UPDATE NHÃN SỰ CỐ
  yield takeLatest(typeAction.UPDATE_NHAN_SU_CO, function* updateNhanSuCo({ type, form }) {
    const { infoUser } = yield select((state) => state.userReducer)
    try {
      const { data } = yield call(() => suCoService.putNhanSuCo(form))
      yield put(getAllNhanSuCo())
      Toast.fire({
        icon: 'success',
        title: 'Cập nhật thành công!',
      })

      const defaultForLog = {
        tennhan: form.tennhan,
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        logAction.CAP_NHAT_NHAN_SU_CO,
        true,
        MENU_CONSTANTS.SU_CO,
        null,
        defaultForLog,
      )
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Cập nhật thất bại!',
      })
      yield console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.SU_CO,
        detailErrorPayload,
      )
    }
  })
  //Tạo vung
  yield takeLatest(typeAction.ADD_VUNG_BAO_HONG, function* createVung({ type, form, onReset }) {
    const { infoUser } = yield select((state) => state.userReducer)
    try {
      const { data } = yield call(() => suCoService.postVungBaoHong(form))
      onReset()
      Toast.fire({
        icon: 'success',
        title: 'Tạo thành công!',
      })

      const defaultForLog = {
        tenvung: form.tenvung,
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        logAction.TAO_VUNG_SU_CO,
        true,
        MENU_CONSTANTS.KHU_VUC_QUAN_LY,
        null,
        defaultForLog,
      )
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'tạo thất bại!',
      })
      yield console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.SU_CO,
        detailErrorPayload,
      )
    }
  })
  //Tạo vung
  yield takeLatest(
    typeAction.POST_NHAN_VIEN_BAO_HONG,
    function* postNhanVien({ type, form, fetchData }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const { data } = yield call(() => suCoService.postNhanVienBaoHong(form))
        fetchData()
        Toast.fire({
          icon: 'success',
          title: 'Thêm  thành công!',
        })

        const defaultForLog = {
          tenbaohong: form.tenbaohong,
          tennv: form.tennv,
        }

        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          logAction.THEM_NHAN_VIEN_SU_CO,
          true,
          MENU_CONSTANTS.SU_CO,
          null,
          defaultForLog,
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Thêm thất bại!',
        })
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.SU_CO,
          detailErrorPayload,
        )
      }
    },
  )
  //lấy danh sách vùng báo hổng
  yield takeLatest(typeAction.GET_VUNG_BAO_HONG, function* getVung({ type, keyword }) {
    try {
      const { data } = yield call(() => suCoService.getVungBaoHong(keyword))
      yield put({
        type: typeAction.DISPATCH_GET_VUNG_BAO_HONG,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //lấy danh sách vùng báo hổng
  yield takeLatest(
    typeAction.DELETE_NHAN_VIEN_BAO_HONG,
    function* deleteNhanVienBaoHong({ type, form, reset }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const { data } = yield call(() => suCoService.deleteNhanVienBaoHong(form))
        reset()
        const defaultForLog = {
          idnvbh: form.idnvbh,
          lydo: form.lydo,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.DELETE,
          logAction.XOA_NHAN_VIEN_SU_CO,
          true,
          MENU_CONSTANTS.SU_CO,
          null,
          defaultForLog,
        )
      } catch (error) {
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.QUAN_LY_NHAN_SU,
          detailErrorPayload,
        )
      }
    },
  )
  //lấy danh sách nhân viên sự số
  yield takeLatest(
    typeAction.GET_DANH_SACH_NHAN_VIEN_SU_CO,
    function* getStaff({ type, keyword, idnhom }) {
      try {
        const { data } = yield call(() => suCoService.getSearchNV(keyword, idnhom))
        yield put({
          type: typeAction.DISPATCH_NHAN_VIEN_SU_CO,
          payload: data,
        })
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  //UPDATE NHÃN SỰ CỐ
  yield takeLatest(typeAction.GET_NHAN_VIEN_SU_CO_BY_VUNG, function* getNhanVienByVung({ type }) {
    try {
      const IDCN = Number(localStorage.getItem('BRANH_LOGIN'))
      const { data } = yield call(() => suCoService.getNhanVienByVung(IDCN))
      yield put({
        type: typeAction.DISPATCH_THUC_HIEN_BY_VUNG,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //GET DANH SÁCH BÁO HỔNG
  yield takeLatest(
    typeAction.GET_DANH_SACH_BAO_HONG,
    function* getDanhSachBaoHong({ type, keyword, idcn }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_TABLE,
        })
        const { data } = yield call(() => suCoService.getDanhSachBaoHong(keyword, idcn))
        yield put({
          type: typeAction.DISPATCH_DANH_SACH_BAO_HONG,
          payload: data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_TABLE,
        })
      } catch (err) {
        yield console.log(err)
        yield put({
          type: typeAction.CLOSE_LOADING_TABLE,
        })
      }
    },
  )
  //UPDATE NHÃN SỰ CỐ
  yield takeLatest(typeAction.GET_INFO_BAO_HONG, function* getInfoBaoHong({ type, idbh }) {
    try {
      const { data } = yield call(() => suCoService.getDetailBaoHong(idbh))
      yield put({
        type: typeAction.DISPATCH_INFO_BAO_HONG,
        payload: data,
      })
      yield put(getInfoXuLy(idbh))
      yield put(getKTThucHienBaoHong(idbh))
      yield put(getImgBaoHong(idbh))
    } catch (err) {
      yield console.log(err)
    }
  })
  //update báo hổng
  yield takeLatest(typeAction.UPDATE_BAO_HONG, function* updateBaoHong({ type, form }) {
    const { infoUser } = yield select((state) => state.userReducer)
    try {
      const { data } = yield call(() => suCoService.putKTBaoHong(form))
      Toast.fire({
        icon: 'success',
        title: 'cập nhật  thành công!',
      })
      const defaultForLog = {
        tenbaohong: form.tenbaohong,
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        logAction.CAP_NHAT_SU_CO,
        true,
        MENU_CONSTANTS.SU_CO,
        null,
        defaultForLog,
      )
    } catch (error) {
      yield console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Cập nhật  thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
       \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.SU_CO,
        detailErrorPayload,
      )
    }
  })

  //LÂY DANH SÁCH KT THỰC HIỆN BÁO HỔNG
  yield takeLatest(typeAction.GET_KT_THUC_HIEN_BAO_HONG, function* getDanhSachKT({ type, id }) {
    try {
      const { data } = yield call(() => suCoService.getDanhDachKTThucHien(id))
      yield put({
        type: typeAction.DISPATCH_KT_THU_THIEN_BAO_HONG,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //get all hạng mục
  yield takeLatest(typeAction.GET_ALL_HANG_MUC, function* getAllHangMuc({ type }) {
    try {
      const { data } = yield call(() => suCoService.getAllHangMuc())
      yield put({
        type: typeAction.DISPATCH_ALL_HANG_MUC_SU_CO,
        payload: data,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //get all hạng mục
  yield takeLatest(typeAction.GET_IMGAE_BAO_HONG, function* getImgBaoHong({ type, idbh }) {
    try {
      const { data } = yield call(() => suCoService.getImageBaoHong(idbh))
      yield put({
        type: typeAction.GET_INMAGE_BAO_HONG,
        payload: data.hinhanh,
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //xoá nhân viên trong chi tiết báo hổng
  yield takeLatest(
    typeAction.DELETE_NHAN_VIEN_THUC_HIEN_DETAIL,
    function* deleteKTdetail({ type, form, reset }) {
      try {
        yield call(() => suCoService.deleteNhanVienBaoHong(form))
        yield put(getKTThucHienBaoHong(form.idbaohong))
        reset()
        Toast.fire({
          icon: 'success',
          title: 'Xoá thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Xoá thất bại!',
        })
      }
    },
  )
  //xoá nhân viên trong chi tiết báo hổng
  yield takeLatest(
    typeAction.CHUYEN_NHAN_VIEN_THUC_HIEN_BAO_HONG,
    function* deleteKTdetail({ type, form, reset }) {
      try {
        yield call(() => suCoService.ChuyenNhanVien(form))
        yield put(getKTThucHienBaoHong(form.idbaohong))
        reset()
        Toast.fire({
          icon: 'success',
          title: 'Chuyển thành công!',
        })
      } catch (err) {
        yield console.log(err)
        Toast.fire({
          icon: 'error',
          title: 'Chuyển thất bại!',
        })
      }
    },
  )
  //get all hạng mục
  yield takeLatest(
    typeAction.GET_INFO_NHAN_BY_HANG_MUC,
    function* handleHangMuc({ type, id, setInfoNhan, formik }) {
      try {
        const { data } = yield call(() => suCoService.getinfoNhanByHangMuc(id))
        formik.setFieldValue('IDNHAN', data.idnhan)
        formik.setFieldValue('DONVINHAN', data.idnhom)
        setInfoNhan(data)
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // delete file báo hổng
  yield takeLatest(typeAction.DELETE_FILE_BAO_HONG, function* delteFileBoaHong({ type, id, url }) {
    try {
      const { data } = yield call(() => suCoService.deleteFileBaoHong(id, url))
      yield put(getImgBaoHong(id))
    } catch (err) {
      yield console.log(err)
    }
  })
  // thêm chi nhánh vùng
  yield takeLatest(
    typeAction.POST_CHI_NHANH_VUNG,
    function* addChiNhanhVung({ type, form, fetchChiNhanhByVung, onReset }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const { data } = yield call(() => suCoService.postChiNhanhVung(form))
        fetchChiNhanhByVung()
        onReset()
        Toast.fire({
          icon: 'success',
          title: 'Thêm thành công!',
        })

        const defaultForLog = {
          idcn: form.idcn,
          idvung: form.idvung,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          logAction.THEM_CHI_NHANH_VUNG_SU_CO,
          true,
          MENU_CONSTANTS.KHU_VUC_QUAN_LY,
          null,
          defaultForLog,
        )
      } catch (error) {
        yield console.log(error)
        Toast.fire({
          icon: 'error',
          title: error?.response?.data?.message || 'Thêm  thất bại!',
        })

        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.KHU_VUC_QUAN_LY,
          detailErrorPayload,
        )
      }
    },
  )
  // thêm chi nhánh vùng
  yield takeLatest(
    typeAction.GET_CHI_NHANH_BY_VUNG,
    function* getChiNhanh({ type, idVung, keyword }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_TABLE,
        })
        const { data } = yield call(() => suCoService.getChiNhanhByVung(idVung, keyword))
        yield put({
          type: typeAction.DISPATCH_CHI_NHANH_VUNG,
          payload: data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_TABLE,
        })
      } catch (err) {
        yield console.log(err)
        yield put({
          type: typeAction.CLOSE_LOADING_TABLE,
        })
      }
    },
  )
  //lấy thông tin chi tiết xử lý báo hổng
  yield takeLatest(typeAction.GET_INFO_XU_LY_BAO_HONG, function* getInfoXuLy({ type, id }) {
    try {
      const { data } = yield call(() => suCoService.getInfoXuLyBaoHong(id))
      yield put({
        type: typeAction.DISPATCH_INFO_XU_LY_BAO_HONG,
        payload: data[0],
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  //lấy thông tin chi tiết xử lý báo hổng
  yield takeLatest(typeAction.POST_IMAGE_BAO_HONG, function* addImageBaoHong({ type, id }) {
    try {
      const { data } = yield call(() => suCoService.getInfoXuLyBaoHong(id))
      yield put({
        type: typeAction.DISPATCH_INFO_XU_LY_BAO_HONG,
        payload: data[0],
      })
    } catch (err) {
      yield console.log(err)
    }
  })
  // upload file xử lý báo hổng
  yield takeLatest(typeAction.UPLOAD_FILE_XU_LY, function* uploadfileXuLy({ type, form, idbh }) {
    try {
      const { data } = yield call(() => suCoService.updateLoadFileXuLy(form))
      yield put(getInfoXuLy(idbh))
    } catch (err) {
      yield console.log(err)
    }
  })
  // delete file xử lý báo hổng
  yield takeLatest(typeAction.DELETE_FILE_XULY, function* deletFileXuLy({ type, idxl, url, idbh }) {
    try {
      const { data } = yield call(() => suCoService.deleteFileXuLy(idxl, url))
      yield put(getInfoXuLy(idbh))
    } catch (err) {
      yield console.log(err)
    }
  })
  // updaloadFileBaoHOong
  yield takeLatest(
    typeAction.UPLOAD_FILE_BAO_HONG,
    function* uploadFileBaoHong({ type, form, idbh }) {
      try {
        const { data } = yield call(() => suCoService.uploadFileBaoHong(form))
        yield put(getImgBaoHong(idbh))
      } catch (err) {
        yield console.log(err)
      }
    },
  )
  // add nhân viên vùng
  yield takeLatest(
    typeAction.POST_NHAN_VIEN_VUNG,
    function* addNewNhanVienVung({ type, form, fetchVung, setPopoverVisible }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const { data } = yield call(() => suCoService.postNhanVienVung(form))
        fetchVung()
        setPopoverVisible(false)
        ToastCus.fire({
          icon: 'success',
          title: 'Thêm thành công!',
        })
        const defaultForLog = {
          tennv: form.tennv,
          idvung: form.idvung,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          logAction.THEM_NHAN_VIEN_VUNG_SU_CO,
          true,
          MENU_CONSTANTS.KHU_VUC_QUAN_LY,
          null,
          defaultForLog,
        )
      } catch (error) {
        yield console.log(error)
        ToastCus.fire({
          icon: 'error',
          title: 'Thêm thất bại',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          null,
          false,
          MENU_CONSTANTS.KHU_VUC_QUAN_LY,
          detailErrorPayload,
        )
      }
    },
  )
  // delete Báo hổng
  yield takeLatest(
    typeAction.DELETE_BAO_HONG,
    function* deleteBaohong({ type, id, handleCancel, fetchData }) {
      const { infoUser } = yield select((state) => state.userReducer)

      try {
        const { data } = yield call(() => suCoService.deleteBaoHong(id))
        handleCancel()
        fetchData()
        Toast.fire({
          icon: 'success',
          title: 'Xoá thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.DELETE,
          logAction.XOA_SU_CO,
          true,
          MENU_CONSTANTS.SU_CO,
          null,
          {
            idbaohong: id,
          },
        )
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Xoá thất bại!',
        })
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(id)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.SU_CO,
          detailErrorPayload,
        )
      }
    },
  )
  // get danh Sách hoàn thành
  yield takeLatest(
    typeAction.GET_DANH_SACH_BAO_HONG_HOANG_THANH,
    function* getDoanhSachKhongHoanThanh({ type, keyword, idcn, tuNgay, denNgay }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_TABLE,
        })
        const { data } = yield call(() =>
          suCoService.getDanhSachHoangThanh(keyword, idcn, tuNgay, denNgay),
        )
        yield put({
          type: typeAction.DISPATCH_DANH_SACH_BAO_HONG_HOANG_THANH,
          payload: data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_TABLE,
        })
      } catch (err) {
        yield console.log(err)
        yield put({
          type: typeAction.CLOSE_LOADING_TABLE,
        })
      }
    },
  )
  //get danh Sách không hoàn thành
  yield takeLatest(
    typeAction.GET_DANH_SACH_BAO_HONG_KHONG_HOAN_THANH,
    function* getDoanhSachHoangThanh({ type, keyword, idcn, tuNgay, denNgay }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_TABLE,
        })
        const { data } = yield call(() =>
          suCoService.getDanhSachKhongHoanThanh(keyword, idcn, tuNgay, denNgay),
        )
        yield put({
          type: typeAction.DISPATCH_DANH_SACH_BAO_HONG_KHONG_HOAN_THANH,
          payload: data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_TABLE,
        })
      } catch (err) {
        yield console.log(err)
        yield put({
          type: typeAction.CLOSE_LOADING_TABLE,
        })
      }
    },
  )

  // get danh Sách hoàn thành
  yield takeLatest(
    typeAction.GET_DANH_SACH_THONG_KE_BAO_HONG,
    function* getDoanhSachThongke({ type, keyword, idcn, tuNgay, denNgay }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_TABLE,
        })
        const { data } = yield call(() =>
          suCoService.getDanhSachThongKe(keyword, idcn, tuNgay, denNgay),
        )
        yield put({
          type: typeAction.DISPATCH_THONG_KE_BAO_HONG,
          payload: data,
        })
        yield put({
          type: typeAction.CLOSE_LOADING_TABLE,
        })
      } catch (err) {
        yield console.log(err)
        yield put({
          type: typeAction.CLOSE_LOADING_TABLE,
        })
      }
    },
  )
  //lấy thông tin chi tiết xử lý báo hổng
  yield takeLatest(
    typeAction.UPDATE_XU_LY_SU_CO,
    function* updateXuLyBaoHOng({ type, formBaoHong, idxuly, noidung }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const { data } = yield call(() => suCoService.putKTBaoHong(formBaoHong))
        yield call(() => suCoService.updateNoiDungXuLy(idxuly, noidung))
        Toast.fire({
          icon: 'success',
          title: 'Cập nhật thành công!',
        })
        const defaultForLog = {
          tenbaohong: formBaoHong.tenbaohong,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          logAction.CAP_NHAT_SU_CO,
          true,
          MENU_CONSTANTS.SU_CO,
          null,
          defaultForLog,
        )
      } catch (error) {
        yield console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Cập nhật thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(formBaoHong)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.SU_CO,
          detailErrorPayload,
        )
      }
    },
  )
  //XOÁ NHÂN VIÊN VÙNG BÁO HỔNG
  yield takeLatest(
    typeAction.DELETE_NHAN_VIEN_VUNG,
    function* deleteNhanvienVung({ type, id, fetchVung }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const { data } = yield call(() => suCoService.deleteNhanVienVung(id))
        fetchVung()

        const defaultForLog = {
          id: id,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.DELETE,
          logAction.XOA_NHAN_VIEN_VUNG_SU_CO,
          true,
          MENU_CONSTANTS.KHU_VUC_QUAN_LY,
          null,
          defaultForLog,
        )
      } catch (error) {
        yield console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(id)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.KHU_VUC_QUAN_LY,
          detailErrorPayload,
        )
      }
    },
  )
  // thêm chi nhánh vùng
  yield takeLatest(
    typeAction.DELETE_CHI_NHANH_VUNG_BAO_HONG,

    function* deleteChiNhanhVung({ type, id, fetchChiNhanhByVung }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const { data } = yield call(() => suCoService.deleteChiNhanhVung(id))
        fetchChiNhanhByVung()
        Toast.fire({
          icon: 'success',
          title: 'Xoá thành công!',
        })
        const defaultForLog = {
          id: id,
        }
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.POST,
          logAction.XOA_CHI_NHANH_VUNG_SU_CO,
          true,
          MENU_CONSTANTS.KHU_VUC_QUAN_LY,
          null,
          defaultForLog,
        )
      } catch (error) {
        yield console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Xoá thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(id)}
          \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.DELETE,
          null,
          false,
          MENU_CONSTANTS.KHU_VUC_QUAN_LY,
          detailErrorPayload,
        )
      }
    },
  )
  //update khu vuc
  yield takeLatest(typeAction.UPDATE_KHU_VUC, function* updateKhuVuc({ type, form, onReset }) {
    const { infoUser } = yield select((state) => state.userReducer)
    try {
      const { data } = yield call(() => suCoService.updateKhuVuc(form))
      onReset()
      Toast.fire({
        icon: 'success',
        title: 'Cập nhật thành công!',
      })

      const defaultForLog = {
        tenkhuvuc: form.tenvung,
      }
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        logAction.CAP_NHAT_KHU_VUC_SU_CO,
        true,
        MENU_CONSTANTS.KHU_VUC_QUAN_LY,
        null,
        defaultForLog,
      )
    } catch (error) {
      yield console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Cập nhật thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)}
       \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.KHU_VUC_QUAN_LY,
        detailErrorPayload,
      )
    }
  })
  //update khu vuc
  yield takeLatest(
    typeAction.DELETE_VUNG_BAO_HONG,
    function* deleteVungBaoHong({ type, id, fetchVung }) {
      const { infoUser } = yield select((state) => state.userReducer)
      try {
        const { data } = yield call(() => suCoService.deleteVungBaoHong(id))
        fetchVung()
        Toast.fire({
          icon: 'success',
          title: 'Xoá thành công!',
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.DELETE,
          logAction.XOA_KHU_VUC_SU_CO,
          true,
          MENU_CONSTANTS.KHU_VUC_QUAN_LY,
          null,
          {
            idvung: id,
          },
        )
      } catch (error) {
        yield console.log(error)
        Toast.fire({
          icon: 'error',
          title: 'Xoá thất bại!',
        })
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(id)}
         \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          +localStorage.getItem('BRANH_LOGIN'),
          actionStatus.PUT,
          null,
          false,
          MENU_CONSTANTS.KHU_VUC_QUAN_LY,
          detailErrorPayload,
        )
      }
    },
  )
}
