import React, { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import {
  Button,
  Input,
  Avatar,
  Popover,
  Tooltip,
  AutoComplete,
  Popconfirm,
  notification,
  Modal,
  Skeleton,
  Result,
} from 'antd'
import { DeleteOutlined, ExclamationCircleFilled, HomeOutlined } from '@ant-design/icons'
import CreateVung from './CreateVung'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  addNhanVienVung,
  deleteChiNhanhVung,
  deleteNhanVienVung,
  deleteVungBaoHong,
  getAllNhanVienBaoHong,
  getChiNhanhByVung,
  getVungBaoHong,
} from '../../../store/actions/suCoAction'
import CreateChiNhanhVung from './CreateChiNhanhVung'
import { suCoService } from '../../../services/suCoService/suCoServvice'
import UpdateKhuVuc from './UpdateKhuVuc'
import 'moment/locale/vi'
import InputAdd from './InputAdd'
moment.locale('vi')
const { confirm } = Modal
const KhuVuSuCo = () => {
  const [api, contextHolder] = notification.useNotification()
  const dispatch = useDispatch()

  const { vungBaoHong, ChiNhanhVung, nhanVien } = useSelector((state) => state.suCoReducer)
  const { infoUser } = useSelector((state) => state.userReducer)
  const { isLoadingTable } = useSelector((state) => state.modalReducer)
  const [openAdd, setOpenAdd] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [openVung, setOpenVung] = useState(false)
  const [idVung, setIdVung] = useState(null)
  const [active, setActive] = useState(null)
  const [infoVung, setInfoVung] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [keywordBranch, setKeywordBranch] = useState('')
  const [Khu, setKhu] = useState([])
  const fetchVung = () => {
    dispatch(getVungBaoHong(keyword))
  }
  const debounceDropDownVung = useCallback(
    _.debounce((e) => {
      dispatch(getVungBaoHong(e.target.value))
    }, 300),
    [],
  )
  const debounceDropDownBranchByVung = useCallback(
    _.debounce((id, e) => {
      dispatch(getChiNhanhByVung(id, e.target.value))
    }, 300),
    [],
  )
  //----- lấy danh sách khu ------//
  const fetchKhu = async () => {
    try {
      const { data } = await suCoService.getVungBaoHong('')
      setKhu(data)
    } catch (error) {
      console.log(error)
    }
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword) => {
      dispatch(getAllNhanVienBaoHong(keyword))
    }, 300),
    [],
  )
  const fetchChiNhanhByVung = () => {
    dispatch(getChiNhanhByVung(idVung, keywordBranch))
  }
  const showDeleteConfirm = (name, id) => {
    confirm({
      title: 'Xoá chi nhánh?',
      icon: <ExclamationCircleFilled />,
      content: `Bạn có muốn xoá chi nhánh ${name}`,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ ',
      onOk() {
        dispatch(deleteChiNhanhVung(id, fetchChiNhanhByVung))
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  const showDeleteVung = (name, id) => {
    confirm({
      title: 'Xoá Vùng ',
      icon: <ExclamationCircleFilled />,
      content: `Bạn có muốn xoá vùng ${name}`,
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ ',
      onOk() {
        dispatch(deleteVungBaoHong(id, fetchVung))
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    })
  }
  const addNhanVien = (option, DanhSachNhanVien, idVung, setPopoverVisible) => {
    for (let i of DanhSachNhanVien) {
      // kiểm tra nhân viên đã add hay chưa
      if (i.idnv === option.id) {
        openNotificationWithIcon('error', 'Thêm nhân viên', 'Nhân viên đã được thêm!')
        return
      }
    }

    const payload = {
      idvung: idVung,
      idnv: option?.id,
      nguoitao: infoUser?.idnv,
      tennv: option?.value,
    }
    dispatch(addNhanVienVung(payload, fetchVung, setPopoverVisible))
  }

  useEffect(() => {
    fetchVung()
    fetchKhu()
  }, [])
  return (
    <>
      {contextHolder}
      <div className="p-3 bg-[#EFEFEF] h-full">
        <div className="p-3 bg-white h-[calc(100vh-100px)] rounded-xl border">
          <div className="border h-full rounded-lg flex ">
            <div className="w-1/3 border-r p-2 h-full">
              <div className="flex gap-2">
                <Input.Search
                  placeholder="Nhập từ khoá"
                  onChange={(e) => {
                    setKeyword(e.target.value)
                    debounceDropDownVung(e)
                  }}
                />
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    fetchKhu()
                    fetchVung()
                  }}
                >
                  Tải lại
                </Button>
                <Button type="primary" onClick={() => setOpenAdd(true)}>
                  {' '}
                  Tạo khu
                </Button>
              </div>
              <div className="bg-[#EFEFEF] p-2 mt-2  rounded-md h-[95%] overflow-hidden">
                <ul className="flex flex-col gap-3 h-full overflow-y-auto">
                  {vungBaoHong.map((item) => (
                    <li
                      onClick={() => {
                        dispatch(getChiNhanhByVung(item.IDVUNG, keywordBranch))
                        setIdVung(item.IDVUNG)
                        setActive(item.IDVUNG)
                      }}
                      key={item.IDVUNG}
                      className={`h-20 ${item.IDVUNG === active ? 'bg-blue-50 border-blue-400' : 'bg-white'}  rounded-md border flex flex-col gap-1 cursor-pointer hover:-translate-y-1 duration-150 p-2`}
                    >
                      <div className="flex justify-between">
                        <h2 className="font-semibold ">{item.TENVUNG} </h2>
                        <span className="text-gray-400 font-normal">
                          Ngày tạo: {moment(item.NGAYTAO).format('DD/MM/YYYY')}
                        </span>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-0.5">
                          {item.DanhSachNhanVien?.slice(0, 6).map((i) => {
                            // take the final of full name
                            const words = i?.tennhanvien?.split(' ')
                            const lastWord = words ? words[words?.length - 1] : ''
                            return (
                              <Popover
                                placement="bottom"
                                title="Nhân viên"
                                key={i.idnv}
                                content={() => {
                                  return (
                                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                                      <table class="w-full text-sm text-gray-500  text-center">
                                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-secondary-dark dark:text-white">
                                          <tr>
                                            <th scope="col" class="px-6 py-3">
                                              Mã nhân viên
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                              Tên nhân viên
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                              Hành động
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {item.DanhSachNhanVien?.map((i) => (
                                            <tr
                                              class="bg-white border-b  dark:bg-gray-800 dark:text-gray-300"
                                              key={i.idnv}
                                            >
                                              <th
                                                scope="row"
                                                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-gray-300"
                                              >
                                                {i.manhanvien}
                                              </th>
                                              <td class="px-6 py-4">{i.tennhanvien}</td>
                                              <td class="px-6 py-4 ">
                                                <Popconfirm
                                                  title="Xoá nhân viên"
                                                  description="Bạn có chắc muốn xoá không?"
                                                  onConfirm={() => {
                                                    dispatch(
                                                      deleteNhanVienVung(i.idnvvbh, fetchVung),
                                                    )
                                                  }}
                                                  okText="Yes"
                                                  cancelText="No"
                                                >
                                                  <Tooltip title="Xoá" color="red">
                                                    <button className="text-red-500 hover:text-gray-600 text-lg leading-3 mx-auto block">
                                                      <DeleteOutlined />
                                                    </button>
                                                  </Tooltip>
                                                </Popconfirm>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  )
                                }}
                              >
                                <Avatar className="bg-blue-600" size="default">
                                  {lastWord}
                                </Avatar>
                              </Popover>
                            )
                          })}
                          <div>{item?.DanhSachNhanVien.length > 6 && '...'}</div>
                          {item.DanhSachNhanVien && (
                            // <Popover
                            //   trigger="click"
                            //   content={() => {
                            //     return (
                            //       <AutoComplete
                            //         placeholder="Nhập tên"
                            //         style={{ width: '100%' }}
                            //         options={nhanVien?.map((item) => ({
                            //           label: item.tennv,
                            //           value: item.tennv,
                            //           id: item.id,
                            //         }))}
                            //         onSearch={debounceDropDown}
                            //         onSelect={(value, option) => {
                            //           addNhanVien(option, item.DanhSachNhanVien, item.IDVUNG)
                            //         }}
                            //       />
                            //     )
                            //   }}
                            //   title="Thêm người"
                            // >
                            //   <button className="w-6 h-6 rounded bg-gray-300 dark:text-black">
                            //     +
                            //   </button>
                            // </Popover>
                            <InputAdd
                              item={item}
                              nhanVien={nhanVien}
                              addNhanVien={addNhanVien}
                              debounceDropDown={debounceDropDown}
                            />
                          )}
                        </div>
                        <div className="flex gap-2">
                          <div
                            onClick={() => showDeleteVung(item.TENVUNG, item.IDVUNG)}
                            className="text-red-700 bg-red-200 hover:text-white hover:bg-red-700 duration-200  p-1 flex items-center rounded-md"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </div>
                          <div
                            onClick={() => {
                              setIsUpdate(true)
                              setInfoVung(item)
                            }}
                            className="text-green-700 bg-green-200 hover:text-white hover:bg-green-700 duration-200 p-1 rounded-md"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="w-2/3 p-2">
              <div className="flex justify-between items-center">
                <h2 className="text-green-700 font-semibold">
                  {' '}
                  Số chi nhánh: {ChiNhanhVung.length}
                </h2>
                <div className="flex gap-2 justify-end">
                  <Input.Search
                    className="w-72"
                    onChange={(e) => {
                      debounceDropDownBranchByVung(idVung, e)
                      setKeywordBranch(e.target.value)
                    }}
                    placeholder="Nhập từ khoá"
                  />
                  <Button type="primary" ghost onClick={fetchChiNhanhByVung}>
                    Tải lại
                  </Button>
                  <Button onClick={() => setOpenVung(true)} type="primary">
                    Thêm chi nhánh
                  </Button>
                </div>
              </div>
              <div className="bg-[#EFEFEF] p-2 mt-2 rounded-md h-[95%] overflow-hidden">
                {isLoadingTable ? (
                  <Skeleton active />
                ) : ChiNhanhVung.length !== 0 ? (
                  <ul className="flex flex-col gap-3 h-full overflow-y-auto">
                    {ChiNhanhVung.map((item) => (
                      <li
                        key={item.idchinhanh}
                        className="h-16 bg-white rounded-md cursor-pointer hover:-translate-y-1 duration-150 p-2"
                      >
                        <div className="flex justify-between">
                          <h2 className="font-semibold ">{item.tenchinhanh}</h2>
                          <span className="text-gray-400 font-normal">
                            Mã CN: {item.machinhanh}
                          </span>
                        </div>
                        <div className="flex justify-end">
                          <div
                            onClick={() => showDeleteConfirm(item.tenchinhanh, item.idcnvbh)}
                            className="text-red-700 bg-red-200 hover:text-white hover:bg-red-700 duration-200 size-6 p-1 flex items-center rounded-md"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Result
                    icon={<HomeOutlined />}
                    title="Chưa có chi nhánh nào trong khu vực!"
                    extra={
                      <Button
                        onClick={() => {
                          setOpenVung(true)
                        }}
                        type="primary"
                      >
                        Thêm chi nhánh
                      </Button>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateVung
        fetchKhu={fetchKhu}
        fetchVung={fetchVung}
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
      />
      <CreateChiNhanhVung
        fetchChiNhanhByVung={fetchChiNhanhByVung}
        Khu={Khu}
        setKhu={setKhu}
        openVung={openVung}
        setOpenVung={setOpenVung}
        idVung={idVung}
      />
      <UpdateKhuVuc
        fetchVung={fetchVung}
        infoVung={infoVung}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
      />
    </>
  )
}

export default KhuVuSuCo
