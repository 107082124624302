import { Modal, Input, Select, DatePicker, Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'moment/locale/vi'

import {
  createBN_Action,
  getQuanAction,
  getXaAction,
} from '../../../../store/actions/receiveAction'
import { useFormik } from 'formik'
import dayjs from 'dayjs'
import moment from 'moment'
import { createCustomerSchema } from '../../../../schemas/createCustomerSchema'
import { MENU_CONSTANTS } from '../../../../utils/log/logConstant'
moment.locale('vi')
const dateFormat = 'DD-MM-YYYY'
const ModalCreateCustomer = ({ openModaCreateCustomer, cancelCreateCustomer }) => {
  const { ngheNghiep, danToc, doiTuong, tinhTP, quocTich, phuongXa, quanHuyen } = useSelector(
    (state) => state.receiveReducer,
  )

  const dispatch = useDispatch()
  const BranchLogin = Number(JSON.parse(localStorage.getItem('BRANH_LOGIN')))
  const handleTinh = (value) => {
    dispatch(getQuanAction(value))
    formik.setFieldValue('idTinh', value)
    formik.setFieldValue('idQuan', '')
    formik.setFieldValue('idPhuong', '')
  }

  const now = new Date()
  const { infoUser } = useSelector((state) => state.userReducer)
  // tính tuổi của bệnh nhân
  function calculateAge(date, dateString) {
    // Chuyển đổi chuỗi ngày sinh thành đối tượng moment
    const birthDate = moment(dateString, 'DD-MM-YYYY')
    // Lấy ngày hiện tại
    const today = moment()
    // Tính toán tuổi
    const ageYears = today.diff(birthDate, 'years')
    birthDate.add(ageYears, 'years')
    const ageMonths = today.diff(birthDate, 'months')
    birthDate.add(ageMonths, 'months')
    const ageDays = today.diff(birthDate, 'days')
    // Cập nhật trường tuổi
    formik.setFieldValue('tuoi', `${ageYears} tuổi, ${ageMonths} tháng, ${ageDays} ngày`)
    formik.setFieldValue('ngaySinh', moment(dateString, dateFormat).format('YYYY-MM-DD'))
  }
  const handleXaPhuong = (value) => {
    formik.setFieldValue('idQuan', value)
    formik.setFieldValue('idPhuong', '')
    dispatch(getXaAction(value))
  }
  const setXaPhuongFormik = (value) => {
    formik.setFieldValue('idPhuong', value)
  }
  const handleRadio = (e) => {
    const { value } = e.target
    formik.setFieldValue('gioiTinh', value)
  }
  // submit from
  const handleSubmit = (values, action) => {
    dispatch(createBN_Action(values))
    formik.resetForm()
    cancelCreateCustomer()
    action.resetForm()
  }
  // xử klys ngày cấp cccd
  const dateCCCD = (date, dateString) => {
    formik.setFieldValue('ngayCap', moment(dateString, dateFormat).format())
  }
  // xử lí chọn
  const handleSelected = (name) => (value) => {
    formik.setFieldValue(name, value)
  }
  // formik
  const formik = useFormik({
    initialValues: {
      tenBenhNhan: null,
      gioiTinh: 'Nam',
      ngaySinh: null,
      tuoi: '',
      diaChi: null,
      idTinh: 46, // mặc định 46 là thành phố HCM
      idQuan: null,
      idPhuong: null,
      dienThoai: '',
      cccd: '',
      ngayCap: null,
      idDoiTuong: 1, // mặc định 1 là đối tượng vãng lai
      idDanToc: 1, // mặc định 1 là dân tộc kinh
      idNgheNghiep: null,
      idQuocTich: 1, // mắc định 1 là nước VN
      Matcqg: '',
      nguoiTao: infoUser?.idnv,
      NgayTao: '',
      idChiNhanh: BranchLogin,
      idTrangThai: 1, // tạo mới bệnh nhậnh trạng thái lần đầu
      menu: MENU_CONSTANTS.TIEP_NHAN,
    },
    validationSchema: createCustomerSchema,
    onSubmit: (value, action) => {
      handleSubmit(value, action)
    },
  })
  useEffect(() => {
    // lấy quậm mặc định của thành phố hồ chí minh ID HCM  = 46
    dispatch(getQuanAction(46))
  }, [])
  return (
    <Modal
      className="text-center"
      title="Tạo bệnh nhân mới"
      open={openModaCreateCustomer}
      onCancel={cancelCreateCustomer}
      onOk={formik.handleSubmit}
      okText="Tạo bệnh nhân"
      cancelText="Huỷ bỏ"
      width={800}
    >
      <form className="text-start">
        <div className="flex flex-col gap-2">
          <div className="flex gap-5 text-end">
            <div className="flex w-1/2 gap-1 items-center">
              <label className="w-1/3">
                <span className="text-sx text-red-500">(*)</span>
                Họ và tên:
              </label>
              <Input
                status={formik.errors.tenBenhNhan ? 'error' : ''}
                name="tenBenhNhan"
                value={formik.values.tenBenhNhan}
                onChange={formik.handleChange}
                size="small"
              />
            </div>
            <div className="flex w-1/2 gap-1 items-center">
              <label className="w-[38%]">Mã TCQG:</label>
              <Input
                name="Matcqg"
                value={formik.values.Matcqg}
                onChange={formik.handleChange}
                size="small"
              />
            </div>
          </div>
          <div className="flex gap-5 text-end">
            <div className="flex w-1/2 gap-1 items-center">
              <label className="w-1/3">Đối tượng:</label>
              <Select
                value={formik.values.idDoiTuong}
                options={doiTuong?.map((items) => ({
                  label: items.tenDoiTuong,
                  value: items.idDoiTuong,
                }))}
                onChange={handleSelected('idDoiTuong')}
                className="w-full text-center"
                size="small"
              />
            </div>
            <div className="flex w-1/2 gap-1 ">
              <label className="w-[38%]">
                {' '}
                <span className="text-sx text-red-500">(*)</span>Nghề nghiệp:
              </label>
              <Select
                status={formik.errors.idNgheNghiep ? 'error' : ''}
                options={ngheNghiep?.map((items) => ({
                  value: items.idNN,
                  label: items.tenNN,
                }))}
                value={formik.values.idNgheNghiep}
                onChange={handleSelected('idNgheNghiep')}
                className="w-full text-start truncate"
                size="small"
              />
            </div>
          </div>
          <div className="flex gap-5 text-end">
            <div className="flex w-1/2 gap-1 items-center">
              <label className="w-1/3">CCCD:</label>
              <Input
                size="small"
                name="cccd"
                value={formik.values.cccd}
                onChange={formik.handleChange}
              />
            </div>
            <div className="flex w-1/2 gap-1">
              <label className="w-[38%]">Ngày cấp:</label>
              <DatePicker
                allowClear={false}
                onChange={dateCCCD}
                value={formik.values.ngayCap ? dayjs(formik.values.ngayCap, 'YYYY-MM-DD') : ''}
                format={dateFormat}
                className="w-full"
                size="small"
              />
            </div>
          </div>
          <div className="flex gap-5 text-end">
            <div className="flex w-1/2 gap-1 items-center">
              <label className="w-1/3">Điện thoại:</label>
              <Input
                name="dienThoai"
                value={formik.values.dienThoai}
                onChange={formik.handleChange}
                size="small"
              />
            </div>
            <div className="flex gap-1 w-1/2">
              <label className="w-1/3 ">Giới tính:</label>
              <Radio.Group
                onChange={handleRadio}
                className="w-full text-center"
                options={[
                  {
                    label: 'Nam',
                    value: 'Nam',
                  },
                  {
                    label: 'Nữ',
                    value: 'Nữ',
                  },
                  {
                    label: 'Khác',
                    value: 'Khác',
                  },
                ]}
                // onChange={onChange1}
                value={formik.values.gioiTinh}
              />
            </div>
          </div>
          <div className="flex gap-5 text-end ">
            <div className="flex gap-1 w-1/2">
              <label className="w-1/3">
                <span className="text-sx text-red-500">(*)</span>Ngày sinh:
              </label>
              <DatePicker
                status={formik.errors.ngaySinh ? 'error' : ''}
                allowClear={false}
                maxDate={dayjs(now)}
                locale={locale}
                className="w-full"
                value={formik.values.ngaySinh ? dayjs(formik.values.ngaySinh, 'YYYY-MM-DD') : ''}
                format={dateFormat}
                onChange={calculateAge}
                size="small"
              />
            </div>
            <div className="flex w-1/2">
              <label className="w-1/3">Tuổi:</label>
              <p className="w-full text-center font-semibold text-red-500">{formik.values.tuoi}</p>
            </div>
          </div>
          <div className="flex gap-5 text-end ">
            <div className="flex gap-1 w-1/2">
              <label className="w-1/3">Dân tộc:</label>
              <Select
                options={danToc?.map((items) => ({
                  label: items.tenDanToc,
                  value: items.idDanToc,
                }))}
                onChange={handleSelected('idDanToc')}
                value={formik.values.idDanToc}
                className="w-full text-center"
                size="small"
              />
            </div>
            <div className="flex gap-1 w-1/2">
              <label className="w-[38%]">Quốc tịch:</label>
              <Select
                value={formik.values.idQuocTich}
                options={quocTich?.map((items) => ({
                  label: items.tenQuocTich,
                  value: items.idQuocTich,
                }))}
                onChange={handleSelected('idQuocTich')}
                className="w-full text-center"
                size="small"
              />
            </div>
          </div>
          <div className="flex gap-5 text-end ">
            <div className="flex w-1/3 gap-1">
              <label className="w-3/5">
                <span className="text-sx text-red-500">(*)</span>Tỉnh/TP:
              </label>
              <Select
                value={formik.values.idTinh}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={handleTinh}
                options={tinhTP?.map((item) => ({
                  label: item.tenTinh,
                  value: item.idTinh,
                }))}
                className="w-full text-center"
                size="small"
              />
            </div>
            <div className="flex w-1/3 gap-1">
              <label className="w-1/2">
                {' '}
                <span className="text-sx text-red-500">(*)</span>Q.Huyện:
              </label>
              <Select
                status={formik.errors.idQuan ? 'error' : ''}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={handleXaPhuong}
                value={formik.values.idQuan}
                options={quanHuyen?.map((item) => ({
                  label: item.tenQuan,
                  value: item.idQuan,
                }))}
                className="w-full text-center"
                size="small"
              />
            </div>
            <div className="flex w-1/3 gap-1">
              <label className="w-1/2 ">
                {' '}
                <span className="text-sx text-red-500">(*)</span>X.Phường:
              </label>
              <Select
                status={formik.errors.idPhuong ? 'error' : ''}
                showSearch
                onChange={setXaPhuongFormik}
                value={formik.values.idPhuong}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={phuongXa?.map((item) => ({
                  label: item.tenPhuong,
                  value: item.idPhuong,
                }))}
                className="w-full text-center"
                size="small"
              />
            </div>
          </div>
          <div className="flex gap-1">
            <label className="w-[100px] text-end">
              {' '}
              <span className="text-sx text-red-500">(*)</span>Địa chỉ:
            </label>
            <Input
              status={formik.errors.diaChi ? 'error' : ''}
              size="small"
              name="diaChi"
              value={formik.values.diaChi}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default ModalCreateCustomer
