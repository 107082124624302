import React, { useState, useEffect } from 'react'
import { Table, ConfigProvider } from 'antd'
import moment from 'moment'

import 'moment/locale/vi'
import { https } from '../../../../services/apiService'
moment.locale('vi')

export default function HistoryTiemChung({ idbn }) {
  // Add idbn prop
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState(false)

  // Add useEffect to fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const { data } = await https.get(`BenhNhanPhieuThu/GetDanhSachTiemChung/${idbn}`)
        const formattedData = data.map((item, index) => ({
          key: index,
          muiTiem: item.muiTiem,
          tenVacxin: item.tenVacxin,
          trangThai: item.trangThai,
          ngayTiem: item.ngayTiem,
          tenChiNhanh: item.tenChiNhanh,
          diaChi: item.diaChi,
        }))
        setDataSource(formattedData)
      } catch (error) {
        console.error('Error fetching vaccination history:', error)
      } finally {
        setLoading(false)
      }
    }

    if (idbn) {
      fetchData()
    }
  }, [idbn])

  const columns = [
    {
      title: 'Số mũi tiêm',
      dataIndex: 'muiTiem',
      key: 'muiTiem',
      width: 100,
      align: 'center',
    },
    {
      title: 'Vắc xin',
      dataIndex: 'tenVacxin',
      key: 'tenVacxin',
      width: 200,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'trangThai',
      key: 'trangThai',
      width: 120,
      align: 'center',
    },
    {
      title: 'Ngày tiêm',
      dataIndex: 'ngayTiem',
      key: 'ngayTiem',
      width: 120,
      align: 'center',
    },
    {
      title: 'Chi nhánh tiêm',
      dataIndex: 'tenChiNhanh',
      key: 'tenChiNhanh',
      width: 200,
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'diaChi',
      key: 'diaChi',
      width: 300,
    },
  ]

  return (
    <ConfigProvider>
      <div className="p-4">
        <div className="mb-4">
          <h2 className="text-xl font-bold">Lịch sử tiêm chủng</h2>
        </div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          bordered
          size="middle"
          scroll={{ x: 1000, y: 400 }}
        />
      </div>
    </ConfigProvider>
  )
}
