import { call, put, takeLatest, select } from 'redux-saga/effects'
import * as typeAction from '../constants/constants'
import { dangKyKhamOnlineService } from '../../services/dangKyKhamOnline/dangKyKhamOnlineService'
import ToastCus from '../../utils/Toast'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'

export function* khachHangDTSaga() {
  // ADD LIST KHÁCH HÀNG ĐỐI TÁC
  yield takeLatest(typeAction.POST_KHACH_HANG_DT, function* postKhachHangDTSaga({ type, list }) {
    try {
      yield put({
        type: typeAction.OPEN_LOADING_PAGE,
      })

      // Get user info and branch from state
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      yield call(() => dangKyKhamOnlineService.postKhachHangDT(list))

      yield call(() =>
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.POST,
          logAction.THEM_KHACH_HANG,
          true,
          MENU_CONSTANTS.KHACH_HANG,
          null,
          list,
        ),
      )

      ToastCus.fire({
        icon: 'success',
        title: 'Thao tác thành công!',
      })
    } catch (err) {
      console.log(err)

      // Add error log
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)

      yield call(() =>
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.ADD,
          null,
          false,
          MENU_CONSTANTS.KHACH_HANG,
          list,
        ),
      )

      ToastCus.fire({
        icon: 'error',
        title: 'Thao tác thất bại!',
      })
    } finally {
      yield put({
        type: typeAction.CLOSE_LOADING_PAGE,
      })
    }
  })

  // UPDATE KHÁCH HÀNG ĐỐI TÁC
  yield takeLatest(
    typeAction.PUT_KHACH_HANG_DT,
    function* putKhachHangDTSaga({ type, form, resetForm }) {
      try {
        yield put({
          type: typeAction.OPEN_LOADING_PAGE,
        })

        const formatData = {
          idKH: form?.idKH,
          tenKH: form?.tenKH,
          gioiTinh: form?.gioiTinh,
          ngaySinh: form?.ngaySinh,
          dienThoai: form?.dienThoai,
          diaChi: form?.diaChi,
          idTinh: form?.idTinh,
          idQuan: form?.idQuan,
          idPhuong: form?.idPhuong,
          idNgheNghiep: form?.idNgheNghiep,
          idDoiTuong: form?.idDoiTuong,
          cccd: form?.cccd,
          ghiChu: form?.ghiChu,
          idDoiTac: form?.idDoiTac,
          nguoiSua: form?.nguoiSua,
        }

        yield call(() => dangKyKhamOnlineService.putKhachHangDT(formatData))
        yield call(() => resetForm())

        // Add success log
        const { infoUser } = yield select((state) => state.userReducer)
        const { PkDangNhap } = yield select((state) => state.branchReducer)

        yield call(() =>
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.PUT,
            logAction.SUA_KHACH_HANG,
            true,
            MENU_CONSTANTS.KHACH_HANG,
            null,
            { khDetail: form },
          ),
        )

        ToastCus.fire({
          icon: 'success',
          title: 'Thao tác thành công!',
        })
      } catch (err) {
        console.log(err)

        // Add error log
        const { infoUser } = yield select((state) => state.userReducer)
        const { PkDangNhap } = yield select((state) => state.branchReducer)

        yield call(() =>
          addLog(
            infoUser?.dangNhap.idNguoiDung,
            PkDangNhap.idChiNhanh,
            actionStatus.PUT,
            null,
            false,
            MENU_CONSTANTS.KHACH_HANG,
            { khDetail: form },
          ),
        )

        ToastCus.fire({
          icon: 'error',
          title: 'Thao tác thất bại!',
        })
      } finally {
        yield put({
          type: typeAction.CLOSE_LOADING_PAGE,
        })
      }
    },
  )
}
