import {
  ArrowDownOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  SearchOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SyncIcon from '@mui/icons-material/Sync'
import IconButton from '@mui/material/IconButton'
import {
  Button,
  Card,
  ConfigProvider,
  DatePicker,
  Input,
  Select,
  Space,
  Statistic,
  Table,
  Checkbox,
  TreeSelect,
  Modal,
  message,
  Form,
} from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { https } from '../../../../services/apiService'
import { listBranchAction } from '../../../../store/actions/BranchAction'
import { detailBNCashier, phieuThuCashier } from '../../../../store/actions/cashierAction'
import * as TypeAction from '../../../../store/constants/constants'
import { formattedNumber } from '../../../../utils/formattedNumber'
import DrawerPT from './DrawerPT/DrawerPT'
import { formatNumber } from 'devextreme/localization'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import 'moment/locale/vi'
import { reportService } from '../../../../services/Report/reportService'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import ToastCus from '../../../../utils/Toast'
import { addLog } from '../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../utils/log/logConstant'
import { getListNameChiNhanh } from '../../../../utils/algorithm'
moment.locale('vi')

const loaiKham = [
  {
    id: 1,
    value: 'Khám bệnh mới', //1,
    text: 'Khám bệnh mới',
  },
  {
    id: 2,
    value: 'Tái khám', //2,
    text: 'Tái khám',
  },
  {
    id: 3,
    value: 'Khám bệnh quá ngày tái khám', //3,
    text: 'Khám bệnh quá ngày tái khám',
  },
  {
    id: 4,
    value: 'Khám lần đầu', //4,
    text: 'Khám lần đầu',
  },
  {
    id: 5,
    value: 'Tiêm Chủng', //5,
    text: 'Tiêm Chủng',
  },
]

const { SHOW_PARENT } = TreeSelect
const ListPT = () => {
  const dispatch = useDispatch()
  const { infoUser } = useSelector((state) => state.userReducer)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const now = moment()
  const dateFormat = 'DD-MM-YYYY'
  const momentDate = 'YYYY-MM-DD'
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { listBranch, PkDangNhap } = useSelector((state) => state.branchReducer)
  const { phieuThu, DetailTT, InfoBN, service } = useSelector((state) => state.cashierReducer)
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForm, setDateForm] = useState(now.format(momentDate))
  const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [nguonKH, setNguonKH] = useState([])
  const [idChuyenKhoa, setIdChuyenKhoa] = useState('')
  const [company, setCompany] = useState(id_company)
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState([BranchLogin])
  const [ChuyenKhoa, setChuyenKhoa] = useState([])
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [form] = Form.useForm()
  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await https.get('NguonKH')
        setNguonKH(
          data.map((item) => ({
            ...item,
            value: item.nguon,
            text: item.nguon,
          })),
        )
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  const CountLanDau =
    phieuThu?.reduce((count, items) => {
      if (items.tenLoaiKham === 'Khám lần đầu') {
        count += 1
      }
      return count
    }, 0) ?? 0
  const countBenhmoi =
    phieuThu?.reduce((count, items) => {
      if (items.tenLoaiKham === 'Khám bệnh mới') {
        count += 1
      }
      return count
    }, 0) ?? 0
  const taikham =
    phieuThu?.reduce((count, items) => {
      if (items.tenLoaiKham === 'Tái khám') {
        count += 1
      }
      return count
    }, 0) ?? 0
  const quaNgay =
    phieuThu?.reduce((count, items) => {
      if (items.tenLoaiKham === 'Khám bệnh quá ngày tái khám') {
        count += 1
      }
      return count
    }, 0) ?? 0
  const TotalPriceGiam = phieuThu?.reduce((total, item) => total + item.tienGiamGia, 0) ?? 0
  const TotalPricePay = phieuThu?.reduce((total, item) => total + item.tienThanhToan, 0) ?? 0
  const handleLoadPhieu = (branch) => {
    dispatch(phieuThuCashier(dateForm, dateTo, value, setIsLoading, branch))
  }
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'form'
      ? setDateForm(moment(dateString, dateFormat).format(momentDate))
      : setDateTo(moment(dateString, dateFormat).format(momentDate))
  }

  const showDrawer = async (idbn, idbntt, idbnttc) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT038',
      )
      setOpen(true)
      dispatch(detailBNCashier(idbn, idbntt, idbnttc, PkDangNhap?.idChuyenKhoa))
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }

  const onClose = () => {
    setOpen(false)
    dispatch({
      type: TypeAction.RESET_PHIEUTHU_CASHIER,
    })
  }
  //------------ quyền người dùng ----------
  const ruleUser = ['Lễ tân '].includes(infoUser?.tenNhom)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleDelete = async (record) => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT061',
      )
      Modal.confirm({
        title: <div className="text-lg font-bold text-red-600">Xác nhận xóa phiếu</div>,
        icon: <ExclamationCircleOutlined className="text-red-500 text-xl" />,
        content: (
          <div className="py-2">
            <p className="mb-3 text-gray-600">Vui lòng nhập lý do xóa phiếu này:</p>
            <Form form={form} className="delete-form">
              <Form.Item
                name="lydo"
                rules={[
                  { required: true, message: 'Vui lòng nhập lý do xóa!' },
                  { min: 100, message: 'Lý do xóa phải có ít nhất 100 ký tự!' },
                  { whitespace: true, message: 'Lý do không được chỉ chứa khoảng trắng!' },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Nhập lý do xóa (tối thiểu 100 ký tự)"
                  className="w-full border rounded-md p-2 hover:border-blue-400 focus:border-blue-500"
                  maxLength={500}
                  showCount
                />
              </Form.Item>
            </Form>
          </div>
        ),
        okText: 'Xác nhận xóa',
        okType: 'danger',
        cancelText: 'Hủy',
        width: 500,
        className: 'delete-modal',
        okButtonProps: {
          className: 'bg-red-100 hover:bg-red-600',
          onClick: async () => {
            let check = false
            try {
              // Validate form before proceeding
              const values = await form.validateFields()

              // Trim whitespace from reason
              const lydo = values.lydo.trim()

              const response = await https.delete(
                `/BenhNhanPhieuThu/DeletePhieuLeTan?idbnttc=${record.idbnttc}&idnv=${infoUser?.idnv}&LyDo=${lydo}`,
              )

              if (response.status === 200) {
                message.success({
                  content: 'Xóa phiếu thành công',
                  className: 'custom-message',
                })
                handleLoadPhieu(branch)
                form.resetFields() // Clear the form
                Modal.destroyAll() // Close all modals
                check = true
              } else {
                message.error({
                  content: 'Xóa phiếu thất bại',
                  className: 'custom-message',
                })
                check = false
              }
              addLog(
                infoUser?.dangNhap.idNguoiDung,
                PkDangNhap.idChiNhanh,
                actionStatus.DELETE,
                logAction.XOA_PHIEU_THU,
                true,
                MENU_CONSTANTS.TIEP_NHAN,
                null,
                {
                  maphieuthu: record.maPhieuThu,
                },
              )
            } catch (error) {
              if (error.errorFields) {
                check = false // Keep modal open if validation fails
              } else {
                message.error({
                  content: 'Có lỗi xảy ra khi xóa phiếu',
                  className: 'custom-message',
                })
                console.error('Error:', error)
                check = false
                const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
                const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
                addLog(
                  infoUser?.dangNhap.idNguoiDung,
                  PkDangNhap.idChiNhanh,
                  actionStatus.DELETE,
                  null,
                  false,
                  MENU_CONSTANTS.TIEP_NHAN,
                  detailErrorPayload,
                )
              }
            }
            return check
          },
        },
      })
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
    }
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: 40,
      align: 'center',
      key: 'stt',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Mã PT',
      dataIndex: 'maPhieuThu',
      width: 140,
      // align: "center",
      fixed: 'left',
      key: 'maPhieuThu',
      ...getColumnSearchProps('maPhieuThu'),
      render: (text, record) => (
        <span
          className="text-blue-500 cursor-pointer"
          onClick={() => showDrawer(record.idbn, record.idbntt, record.idbnttc)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Ngày thu',
      dataIndex: 'ngayThu',
      width: 140,
      align: 'center',
      key: 'ngayThu',
      fixed: 'left',
      render: (text) => (text ? moment(text)?.format('DD/MM/YYYY HH:mm:ss') : '-'),
      onFilter: (value, record) => {
        const formattedRecordDate = record.ngayThu
          ? moment(record.ngayThu).format('DD/MM/YYYY HH:mm:ss')
          : ''
        return formattedRecordDate.includes(value)
      },
      sorter: (a, b) => moment(a.ngayThu).unix() - moment(b.ngayThu).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenChiNhanh',
      key: 'tenChiNhanh',
      fixed: 'left',
      ...getColumnSearchProps('tenChiNhanh'),
      // onFilter: (value, record) => record.tenChiNhanh.indexOf(value) === 0,
      sorter: (a, b) => a.tenChiNhanh.length - b.tenChiNhanh.length,
      sortDirections: ['descend'],
      width: 250,
    },
    {
      title: 'Tên BN',
      dataIndex: 'tenBenhNhan',
      key: 'tenBenhNhan',
      fixed: 'left',
      ...getColumnSearchProps('tenBenhNhan'),
      // onFilter: (value, record) => record.tenBenhNhan.indexOf(value) === 0,
      sorter: (a, b) => a.tenBenhNhan.length - b.tenBenhNhan.length,
      sortDirections: ['descend'],
      width: 250,
    },
    {
      title: 'Mã BN',
      dataIndex: 'maBenhNhan',
      width: 140,
      align: 'left',
      key: 'maBenhNhan',
      ...getColumnSearchProps('maBenhNhan'),
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaySinh',
      width: 100,
      align: 'center',
      key: 'ngaySinh',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
      onFilter: (value, record) => {
        const formattedRecordDate = record.ngaySinh
          ? moment(record.ngaySinh).format('DD/MM/YYYY HH:mm:ss')
          : ''
        return formattedRecordDate.includes(value)
      },
      sorter: (a, b) => moment(a.ngaySinh).unix() - moment(b.ngaySinh).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Loại khám',
      dataIndex: 'tenLoaiKham',
      width: 120,
      align: 'center',
      key: 'tenLoaiKham',
      filters: [...new Set(phieuThu?.map((item) => item?.tenLoaiKham))]?.map((item, index) => ({
        key: index,
        value: item,
        text: item,
      })),
      onFilter: (value, record) => {
        return record.tenLoaiKham.indexOf(value) === 0
      },
      // render: (text, record) =>
      //   `${record?.tenLoaiKham}${record?.tenNhomDichVu ? ` (${record?.tenNhomDichVu})` : ''}`,
    },
    {
      title: 'Nguồn khách',
      dataIndex: 'nguon',
      align: 'center',
      width: 120,
      key: 'nguon',
      filters: [...new Set(phieuThu?.map((item) => item?.nguon))]?.map((item, index) => ({
        key: index,
        value: item,
        text: item,
      })),
      onFilter: (value, record) => record.nguon.indexOf(value) === 0,
    },
    {
      title: 'Tiền mặt',
      dataIndex: 'tienMat',
      key: 'tienMat',
      align: 'right',
      width: 120,
      render: (text) => formattedNumber(text),
      sorter: {
        compare: (a, b) => {
          return (a?.tienMat ?? '').localeCompare(b?.tienMat ?? '')
        },
      },
    },
    {
      title: 'Chuyển khoản',
      dataIndex: 'chuyenKhoan',
      key: 'chuyenKhoan',
      width: 120,
      align: 'right',
      render: (text) => formattedNumber(text),
      sorter: {
        compare: (a, b) => {
          return (a?.chuyenKhoan ?? '').localeCompare(b?.chuyenKhoan ?? '')
        },
      },
    },
    {
      title: 'Máy Pos',
      dataIndex: 'pos',
      key: 'pos',
      width: 120,
      align: 'right',
      render: (text) => formattedNumber(text),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.pos ?? '').localeCompare(b?.pos ?? '')
      //   },
      // },
    },
    {
      title: 'Momo',
      dataIndex: 'moMo',
      key: 'moMo',
      align: 'right',
      width: 120,
      render: (text) => formattedNumber(text),
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.moMo ?? '').localeCompare(b?.moMo ?? '')
      //   },
      // },
    },
    {
      title: 'VNPay',
      dataIndex: 'vnPay',
      key: 'vnPay',
      align: 'right',
      render: (text) => formattedNumber(text),
      width: 120,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.vnPay ?? '').localeCompare(b?.vnPay ?? '')
      //   },
      // },
    },
    {
      title: 'ZaloPay',
      dataIndex: 'zaloPay',
      key: 'zaloPay',
      align: 'right',
      render: (text) => formattedNumber(text),
      width: 120,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.zaloPay ?? '').localeCompare(b?.zaloPay ?? '')
      //   },
      // },
    },
    {
      title: 'Thẻ thanh toán',
      dataIndex: 'theTT',
      key: 'theTT',
      align: 'right',
      render: (text) => formattedNumber(text),
      width: 140,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.theTT ?? '').localeCompare(b?.theTT ?? '')
      //   },
      // },
    },
    {
      title: 'Mã TTV',
      dataIndex: 'maThe',
      key: 'maThe',
      width: 100,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.tenThe ?? '').localeCompare(b?.tenThe ?? '')
      //   },
      // },
    },
    {
      title: 'Thẻ thành viên',
      dataIndex: 'tenThe',
      key: 'tenThe',
      width: 250,
      // sorter: {
      //   compare: (a, b) => {
      //     return (a?.tenThe ?? '').localeCompare(b?.tenThe ?? '')
      //   },
      // },
    },
    {
      title: 'Voucher',
      dataIndex: 'maVoucher',
      width: 100,
      key: 'maVoucher',
      sorter: {
        compare: (a, b) => {
          return (a?.maVoucher ?? '').localeCompare(b?.maVoucher ?? '')
        },
      },
    },
    {
      title: 'Voucher đối tác',
      dataIndex: 'maVoucherDoiTac',
      width: 130,
      key: 'maVoucherDoiTac',
      sorter: {
        compare: (a, b) => {
          return (a?.maVoucherDoiTac ?? '').localeCompare(b?.maVoucherDoiTac ?? '')
        },
      },
    },
    {
      title: 'Combo',
      dataIndex: 'chuongTrinhCombo',
      width: 110,
      key: 'chuongTrinhCombo',
      sorter: {
        compare: (a, b) => {
          return (a?.chuongTrinhCombo ?? '').localeCompare(b?.chuongTrinhCombo ?? '')
        },
      },
    },
    {
      title: 'CT giảm giá',
      dataIndex: 'chuongTrinhGiamGia',
      width: 110,
      key: 'chuongTrinhGiamGia',
      sorter: {
        compare: (a, b) => {
          return (a?.chuongTrinhGiamGia ?? '').localeCompare(b?.chuongTrinhGiamGia ?? '')
        },
      },
    },
    {
      title: 'Người thu',
      dataIndex: 'thuNgan',
      width: 200,
      key: 'thuNgan',
      ...getColumnSearchProps('thuNgan'),
    },
    {
      title: 'Hóa đơn',
      dataIndex: 'hoaDonDienTu',
      width: 90,
      align: 'center',
      key: 'hoaDonDienTu',
      render: (text) => <Checkbox checked={text === 1} />,
      filters: [
        {
          text: 'Xuất HĐ',
          value: 1,
        },
        {
          text: 'Không xuất HĐ',
          value: 0,
        },
      ],
      onFilter: (value, record) => record.hoaDonDienTu === value,
    },
    {
      title: 'Ghi chú xuất hóa đơn',
      dataIndex: 'ghiChuXuatHoaDon',
      width: 300,
      key: 'ghiChuXuatHoaDon',
      ...getColumnSearchProps('ghiChuXuatHoaDon'),
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'tongTien',
      key: 'tongTien',
      align: 'right',
      width: 100,
      render: (text) => (text ? formattedNumber(text) : 0),
      onFilter: (value, record) => record.tongTien === Number(value),
      sorter: (a, b) => a.tongTien - b.tongTien,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Tiền giảm',
      dataIndex: 'tienGiamGia',
      key: 'tienGiamGia',
      align: 'right',
      width: 100,
      render: (text) => (text ? formattedNumber(text) : 0),
      onFilter: (value, record) => record.tienGiamGia === Number(value),
      sorter: (a, b) => a.tienGiamGia - b.tienGiamGia,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Tiền giảm đối tác',
      dataIndex: 'tienGiamDoiTac',
      key: 'tienGiamDoiTac',
      align: 'right',
      width: 140,
      render: (text) => (text ? formattedNumber(text) : 0),
      onFilter: (value, record) => record.tienGiamDoiTac === Number(value),
      sorter: (a, b) => a.tienGiamDoiTac - b.tienGiamDoiTac,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Tổng tiền giảm',
      dataIndex: 'tongTienGiam',
      key: 'tongTienGiam',
      align: 'right',
      width: 125,
      render: (text) => (text ? formattedNumber(text) : 0),
      onFilter: (value, record) => record.tongTienGiam === Number(value),
      sorter: (a, b) => a.tongTienGiam - b.tongTienGiam,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Người sửa',
      dataIndex: 'nguoiSuaPhieu',
      key: 'nguoiSuaPhieu',
      width: 200,
      render: (text) => <p className="text-red-500">{text}</p>,
      onFilter: (value, record) => record.nguoiSuaPhieu === value,
      sorter: (a, b) => a.nguoiSuaPhieu - b.nguoiSuaPhieu,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'ngaySuaPhieu',
      key: 'ngaySuaPhieu',
      align: 'right',
      width: 150,
      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
      sorter: (a, b) => a.ngaySuaPhieu - b.ngaySuaPhieu,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Thanh toán',
      dataIndex: 'tienThanhToan',
      key: 'tienThanhToan',
      width: 100,
      fixed: 'right',
      align: 'right',
      render: (text) => (text ? formattedNumber(text) : 0),
      onFilter: (value, record) => record.tienThanhToan === Number(value),
      sorter: (a, b) => a.tienThanhToan - b.tienThanhToan,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '',
      dataIndex: 'action',
      width: 70,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => (
        <ul className="flex justify-center gap-2">
          <li
            onClick={() => showDrawer(record.idbn, record.idbntt, record.idbnttc)}
            className="text-green-700 bg-green-200 cursor-pointer w-6 hover:text-white hover:bg-green-700 duration-200 rounded"
          >
            <FormOutlined style={{ fontSize: 16, fontWeight: 700 }} />
          </li>
          {(infoUser?.idNhom === 27 || infoUser?.idNhom === 28) && (
            <li
              onClick={() => handleDelete(record)}
              className="text-red-700 bg-red-200 cursor-pointer w-6 hover:text-white hover:bg-red-700 duration-200 rounded"
            >
              <DeleteOutlined style={{ fontSize: 16, fontWeight: 700 }} />
            </li>
          )}
        </ul>
      ),
    },
  ]
  useEffect(() => {
    dispatch(listAllCompanyAction())
    dispatch(listBranchAction(id_company))
    dispatch(
      phieuThuCashier(
        now.format(momentDate),
        now.format(momentDate),
        BranchLogin,
        setIsLoading,
        branch,
      ),
    )
  }, [])

  const exportToExcel = async (value, branch) => {
    const listNameChiNhanh = getListNameChiNhanh(value, branch)
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT054',
      )
      const formattedData = phieuThu?.map((item) => ({
        'Mã phiếu thu': item.maPhieuThu,
        'Ngày thu': moment(item.ngayThu).format('DD/MM/YYYY HH:mm'),
        'Chi nhánh': item.tenChiNhanh,
        'Tên bệnh nhân': item.tenBenhNhan,
        'Mã bệnh nhân': item.maBenhNhan,
        'Ngày sinh': moment(item.ngaySinh).format('DD/MM/YYYY HH:mm'),
        'Loại khám': item.tenLoaiKham,
        'Nguồn khách': item.nguon,
        'Tiền mặt': formatNumber(item.tienMat),
        'Chuyển khoản': formatNumber(item.chuyenKhoan),
        'Máy Pos': formatNumber(item.pos),
        'Momo ': formatNumber(item.moMo),
        'VNPay ': formatNumber(item.vnPay),
        'ZaloPay ': formatNumber(item.zaloPay),
        'Thẻ thanh toán': formatNumber(item.theTT),
        'Thẻ TV': item.maThe,
        'Tên Thẻ thành viên': item.tenThe,
        'Voucher ': item.maVoucher,
        'Voucher đối tác': item.maVoucherDoiTac,
        'Người thu': item.thuNgan,
        'Xuất hoá đơn': item.hoaDonDienTu === 1 ? 'Có' : 'Không',
        'Ghi chú xuất hoá đơn': item.ghiChuXuatHoaDon,
        'Tổng tiền': formatNumber(item.tongTien),
        'Tiền giảm': formatNumber(item.tienGiamGia),
        'Thanh toán': formatNumber(item.tienThanhToan),
      }))
      const name = 'Phiếu thu'
      phieuThu?.length && exportExcelformat(formattedData, name)

      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.get,
        logAction.XUAT_EXCEL_DS_PHIEU_THU,
        true,
        MENU_CONSTANTS.TIEP_NHAN,
        null,
        {
          dateFrom: dateForm,
          dateTo,
          listChiNhanh: listNameChiNhanh.join('\n'),
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const detailErrorPayload = `Error xuất excel ${error?.response?.data?.message || error} \nFilter: từ ngày ${moment(dateForm).format('DD/MM/YYYY')} đến ngày ${moment(dateTo).format('DD/MM/YYYY')} \nChi nhánh: ${listNameChiNhanh}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.TIEP_NHAN,
        detailErrorPayload,
      )
    }
  }

  useEffect(() => {
    fetchChuyenKhoa()
    fetchBranchByChuyenKhoa(null)
  }, [company])
  const fetchChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranchByChuyenKhoa = async (value) => {
    try {
      const { data } = await reportService.getBranchByChuyenKhoa(company, value)
      setBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  const onChangeChuyenKhoa = (value) => {
    fetchBranchByChuyenKhoa(value)
    setIdChuyenKhoa(value)
    setValue(['all'])
  }

  const onChange = (newValue) => {
    setValue(newValue || BranchLogin)
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch, index) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }

  useEffect(() => {
    if (branch.length > 0) {
      dispatch(phieuThuCashier(dateForm, dateTo, value, setIsLoading, branch))
    }
  }, [dispatch, branch])
  return (
    <>
      <div className="flex justify-between">
        <div className="w-3/5 flex gap-2 justify-between">
          <ConfigProvider>
            <Card size="small" className="w-1/3 ">
              <Statistic
                title="Tổng tiền giảm"
                value={TotalPriceGiam}
                valueStyle={{
                  color: 'red',
                }}
                prefix={<ArrowDownOutlined />}
                suffix="VNĐ"
              />
            </Card>
            <Card size="small" className="w-1/3 ">
              <Statistic
                title="Tổng doanh thu"
                value={TotalPricePay}
                valueStyle={{
                  color: '#3f8600',
                }}
                prefix={<WalletOutlined />}
                suffix="VNĐ"
              />
            </Card>
            <Card size="small" className="w-1/3">
              <Statistic
                title="Tổng lượt khách"
                value={phieuThu ? phieuThu.length : 0}
                valueStyle={{
                  color: '#3f8600',
                }}
                prefix={<UserOutlined />}
              />
            </Card>
          </ConfigProvider>
        </div>
        <div className="w-2/5 flex items-center justify-around">
          <Statistic title="Lần đầu" value={CountLanDau} suffix={`/ ${phieuThu?.length ?? 0}`} />
          <Statistic title="Bệnh mới" value={countBenhmoi} suffix={`/ ${phieuThu?.length ?? 0}`} />
          <Statistic title="Tái khám" value={taikham} suffix={`/ ${phieuThu?.length ?? 0}`} />
          {/* <Statistic title="Quá ngày tái khám" value={quaNgay} suffix={`/ ${phieuThu?.length}`} /> */}
        </div>
      </div>
      <div className="border mt-5 rounded-md ">
        <div className="flex gap-5 border-dotted border-b-0  p-2 ">
          <div className="flex items-center gap-3">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('form')}
              format={dateFormat}
            />
            <span className="font-semibold text-lg">-</span>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('to')}
              format={dateFormat}
            />
          </div>
          {ruleUser ? (
            <h2>{PkDangNhap?.tenChiNhanh}</h2>
          ) : (
            <>
              {' '}
              <div className="w-80">
                <Select
                  showSearch
                  value={company}
                  onChange={(value) => {
                    // fetchBranch(value)
                    setValue(['all'])
                    setCompany(value)
                    setIdChuyenKhoa('')
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={listCompany?.map(({ idct, tenct }) => ({
                    label: tenct,
                    value: idct,
                  }))}
                  className="w-full"
                />
              </div>
              <div className="w-56">
                <Select
                  onChange={onChangeChuyenKhoa}
                  className="w-full"
                  value={idChuyenKhoa}
                  options={[
                    { label: 'Tất cả chuyên khoa', value: '' },
                    ...ChuyenKhoa.map((items) => ({
                      label: items.tenChuyenKhoa,
                      value: items.idChuyenKhoa,
                    })),
                  ]}
                />
              </div>
              <div className="w-80">
                <TreeSelect
                  {...tProps}
                  style={{ flexWrap: 'unset' }}
                  className="w-full h-8 overflow-clip"
                />
              </div>
            </>
          )}
          <div className="flex gap-3">
            <IconButton
              onClick={() => handleLoadPhieu(branch)}
              color="primary"
              aria-label="delete"
              size="small"
            >
              <SyncIcon />
            </IconButton>
          </div>
          <div className="flex gap-3 ml-auto">
            <Button
              onClick={() => exportToExcel(value, branch)}
              type="text"
              size="middle"
              className="text-green-700 p-1"
              icon={<ImportExportIcon />}
            >
              Xuất Excel
            </Button>
          </div>
        </div>
        <div>
          <ConfigProvider
            theme={{
              token: {
                padding: 5,
                fontSize: 14,
              },
              components: {
                Table: {
                  headerBorderRadius: 0,
                  rowHoverBg: '#ecf0f1',
                  headerBg: '#e6e6e6',
                  footerBg: '#e6e6e6',
                  borderColor: '#BABABA',
                },
              },
            }}
          >
            <Table
              locale={{
                triggerDesc: 'Sắp xếp giảm dần',
                triggerAsc: 'Sắp xếp tăng dần',
                cancelSort: 'Hủy sắp xếp',
              }}
              pagination={false}
              loading={isLoading}
              bordered
              dataSource={phieuThu}
              columns={columns}
              scroll={{
                //x: 2100,
                y: '59vh',
              }}
              summary={(pageData) => {
                let tienmat = 0,
                  chuyenkhoa = 0,
                  maypos = 0,
                  momo = 0,
                  vnpay = 0,
                  zalopay = 0,
                  thethanhtoan = 0,
                  tiengiam = 0,
                  tiengiamdt = 0,
                  tongtiengiam = 0,
                  thanhtoan = 0,
                  tongtien = 0
                pageData.forEach(
                  ({
                    tienMat,
                    chuyenKhoan,
                    pos,
                    moMo,
                    zaloPay,
                    vnPay,
                    theTT,
                    tongTien,
                    tienGiamGia,
                    tienGiamDoiTac,
                    tongTienGiam,
                    tienThanhToan,
                  }) => {
                    tienmat += tienMat
                    chuyenkhoa += chuyenKhoan
                    maypos += pos
                    momo += moMo
                    vnpay += vnPay
                    zalopay += zaloPay
                    thethanhtoan += theTT
                    tongtien += tongTien
                    tiengiam += tienGiamGia
                    tiengiamdt += tienGiamDoiTac
                    tongtiengiam += tongTienGiam
                    thanhtoan += tienThanhToan
                  },
                )
                // console.log;
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}></Table.Summary.Cell>
                      <Table.Summary.Cell index={6}></Table.Summary.Cell>
                      <Table.Summary.Cell index={7}></Table.Summary.Cell>
                      <Table.Summary.Cell index={8}></Table.Summary.Cell>
                      <Table.Summary.Cell index={9}>
                        <p className="font-semibold text-right">{formattedNumber(tienmat)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={10}>
                        {' '}
                        <p className="font-semibold text-right">{formattedNumber(chuyenkhoa)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={11}>
                        <p className="font-semibold text-right">{formattedNumber(maypos)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={12}>
                        <p className="font-semibold text-right">{formattedNumber(momo)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={13}>
                        <p className="font-semibold text-right">{formattedNumber(vnpay)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={14}>
                        <p className="font-semibold text-right">{formattedNumber(zalopay)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={15}>
                        <p className="font-semibold text-right">{formattedNumber(thethanhtoan)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={16}></Table.Summary.Cell>
                      <Table.Summary.Cell index={17}></Table.Summary.Cell>
                      <Table.Summary.Cell index={18}></Table.Summary.Cell>
                      <Table.Summary.Cell index={19}></Table.Summary.Cell>
                      <Table.Summary.Cell index={20}></Table.Summary.Cell>
                      <Table.Summary.Cell index={21}></Table.Summary.Cell>
                      <Table.Summary.Cell index={22}></Table.Summary.Cell>
                      <Table.Summary.Cell index={23}></Table.Summary.Cell>
                      <Table.Summary.Cell index={24}></Table.Summary.Cell>
                      <Table.Summary.Cell index={25}>
                        {' '}
                        <p className="font-semibold text-right">{formattedNumber(tongtien)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={26}>
                        {' '}
                        <p className="font-semibold text-right">{formattedNumber(tiengiam)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={27}>
                        {' '}
                        <p className="font-semibold text-right">{formattedNumber(tiengiamdt)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={28}>
                        {' '}
                        <p className="font-semibold text-right">{formattedNumber(tongtiengiam)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={29}> </Table.Summary.Cell>
                      <Table.Summary.Cell index={30}></Table.Summary.Cell>
                      <Table.Summary.Cell index={31}>
                        <p className="font-semibold text-right">{formattedNumber(thanhtoan)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={32}></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )
              }}
            />
          </ConfigProvider>
        </div>
      </div>
      <DrawerPT open={open} onClose={onClose} />
    </>
  )
}

export default ListPT
