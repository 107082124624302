import { call, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { xetNghiemService } from '../../services/xetNghiem/xetNghiemService'
import * as typeAction from '../constants/constants'
import Swal from 'sweetalert2'
import { CĐHAService } from '../../services/CĐHA/CĐHAService'
import ToastCus from '../../utils/Toast'
import { getVTHHByCDHA } from '../actions/CĐHAAction'
import { addLog } from '../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../utils/log/logConstant'
import { formatPayloadDetails } from '../../utils/algorithm'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2600,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
export function* CĐHASage() {
  yield takeLatest(typeAction.GET_LIST_ALL_CĐHA, function* ListAllCĐHA({ type }) {
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      const result = yield call(() => CĐHAService.getListAllCĐHA())
      yield put({
        type: typeAction.DISPATCH_LIST_ALL_CĐHA,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
  //add new CĐHA
  yield takeLatest(typeAction.ADD_CĐHA, function* AddCĐHA({ type, form, handleReloadData }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      yield call(() => CĐHAService.addCĐHA(form))
      yield call(() => handleReloadData())
      // yield put({ type: typeAction.SEARCH_CĐHA })
      Toast.fire({
        icon: 'success',
        title: 'Thêm chẩn đoán hình ảnh thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.THEM_MOI_CDHA,
        true,
        MENU_CONSTANTS.DANH_MUC_CDHA,
        null,
        {
          form,
        },
      )
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Thêm chẩn đoán hình ảnh thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.DANH_MUC_CDHA,
        detailErrorPayload,
      )
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })

  //edit CĐHA
  yield takeLatest(typeAction.EDIT_CĐHA, function* editCĐHA({ type, form, handleReloadData }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      yield call(() => CĐHAService.editCĐHA(form))
      yield call(() => handleReloadData())
      // yield put({ type: typeAction.SEARCH_CĐHA })
      Toast.fire({
        icon: 'success',
        title: 'Sửa chẩn đoán hình ảnh thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.CHINH_SUA_CDHA,
        true,
        MENU_CONSTANTS.DANH_MUC_CDHA,
        null,
        {
          form,
        },
      )
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Sửa chẩn đoán hình ảnh thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.DANH_MUC_CDHA,
        detailErrorPayload,
      )
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })

  //delete CĐHA
  yield takeLatest(typeAction.DELETE_CĐHA, function* deleteCĐHA({ type, id, handleReloadData }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield put({ type: typeAction.OPEN_LOADING_PAGE })
      yield call(() => CĐHAService.deleteCĐHA(id))
      yield call(() => handleReloadData())
      // yield put({ type: typeAction.SEARCH_CĐHA })
      Toast.fire({
        icon: 'success',
        title: 'Xóa chẩn đoán hình ảnh thành công',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.DELETE,
        logAction.XOA_CDHA,
        true,
        MENU_CONSTANTS.DANH_MUC_CDHA,
        null,
        {
          id,
        },
      )
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: 'Xóa chẩn đoán hình ảnh thất bại',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.DELETE,
        null,
        false,
        MENU_CONSTANTS.DANH_MUC_CDHA,
        detailErrorPayload,
      )
    } finally {
      yield put({ type: typeAction.CLOSE_LOADING_PAGE })
    }
  })
  //search CĐHA
  yield takeLatest(
    typeAction.SEARCH_CĐHA,
    function* searchCĐHA({ type, keyword, idct, idnhomchha, idChuyenKhoa }) {
      const { infoUser } = yield select((state) => state.userReducer)
      const { PkDangNhap } = yield select((state) => state.branchReducer)
      try {
        yield put({ type: typeAction.OPEN_LOADING_PAGE })
        const result = yield call(() =>
          CĐHAService.searchCĐHA(keyword, idct, idnhomchha, idChuyenKhoa),
        )
        yield put({
          type: typeAction.DISPATCH_LIST_ALL_CĐHA,
          payload: result.data,
        })
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          logAction.GET_DS_DINH_MUC_TIEU_HAO_CDHA,
          true,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          null,
          {},
        )
      } catch (error) {
        console.log(error)
        const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
        const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
        addLog(
          infoUser?.dangNhap.idNguoiDung,
          PkDangNhap.idChiNhanh,
          actionStatus.GET,
          null,
          false,
          MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
          detailErrorPayload,
        )
      } finally {
        yield put({ type: typeAction.CLOSE_LOADING_PAGE })
      }
    },
  )
  //------------- ADD NEW VTTH BY CDHA--------
  yield takeLatest(typeAction.POST_CDHA_VTTH, function* postVTTHByCDHA({ type, form }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield call(() => CĐHAService.postVTTH(form))
      ToastCus.fire({
        icon: 'success',
        title: 'Thêm VTTH Thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        logAction.THEM_DINH_MUC_TIEU_HAO_CDHA,
        true,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        null,
        {
          form,
        },
      )
      yield put(getVTHHByCDHA(form.idcdha))
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: 'Thêm VTTH thất bại!',
      })
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        detailErrorPayload,
      )
    }
  })
  //------------- DELETE VTTH BY CDHA--------
  yield takeLatest(typeAction.DELETE_VTTH_BY_CDHA, function* DeleteVTTHByCDHA({ type, form }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield call(() => CĐHAService.deleteVTTHByCDHA(form.idcdhavtth))
      ToastCus.fire({
        icon: 'success',
        title: 'Xoá VTTH Thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.DELETE,
        logAction.XOA_DINH_MUC_TIEU_HAO_CDHA,
        true,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        null,
        {
          form,
        },
      )
      yield put(getVTHHByCDHA(form.idcdha))
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: 'Xoá VTTH thất bại!',
      })
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.DELETE,
        null,
        false,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        detailErrorPayload,
      )
    }
  })
  //------------- UPDATE VTTH BY CDHA--------
  yield takeLatest(typeAction.UPDATE_VTTH_BY_CDHA, function* updateVTTHByCDHA({ type, form }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)
    try {
      yield call(() => CĐHAService.putVTTHByCDHA(form))
      ToastCus.fire({
        icon: 'success',
        title: 'Cập VTTH Thành công!',
      })
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.CHINH_SUA_DINH_MUC_TIEU_HAO_CDHA,
        true,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        null,
        {
          form,
        },
      )
      yield put(getVTHHByCDHA(form.idcdha))
    } catch (error) {
      ToastCus.fire({
        icon: 'error',
        title: 'Cập VTTH thất bại!',
      })
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(form)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        false,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        detailErrorPayload,
      )
    }
  })
  //------------- GET VTTH BY CDHA--------
  yield takeLatest(typeAction.GET_VTTH_BY_ID_CDHA, function* getVTHHByCDHA({ type, id }) {
    const { infoUser } = yield select((state) => state.userReducer)
    const { PkDangNhap } = yield select((state) => state.branchReducer)

    try {
      const result = yield call(() => CĐHAService.getVTTHByCDHA(id))
      console.log(result.data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.GET_DETAIL_DINH_MUC_TIEU_HAO_CDHA,
        true,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        null,
        {
          id,
        },
      )

      yield put({
        type: typeAction.DISPATCH_VTTH_CHANDOAN,
        payload: result.data,
      })
    } catch (error) {
      console.log(error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DINH_MUC_TIEU_HAO,
        detailErrorPayload,
      )
    }
  })
}
