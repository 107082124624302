import {
  ContainerOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import CachedIcon from '@mui/icons-material/Cached'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import PrintIcon from '@mui/icons-material/Print'
import { Button } from '@mui/material'
import {
  Button as AntButton,
  ConfigProvider,
  DatePicker,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import * as XLSX from 'xlsx'
// import { chuyenKhoService } from '../../../../../services/ChuyenKho/chuyenKhoService'
import { branchService } from '../../../../../services/branch/branchService'
import {
  deleteChuyenKho,
  filterPT_createCK,
  listInfoThuocVTChyenKho_Action,
  printerAutoChuyenKho,
  updateStatusCK_action,
} from '../../../../../store/actions/chuyenKhoAction'
// import { buildDateFilterCondition } from '../../../../../utils/buildDateFilterCondition'
import 'moment/locale/vi'
import { exportExcelformat } from '../../../../../utils/export/exportExcelFormat'
import { configSearchTableAnt } from '../../../../../utils/configSearchTableAntd'
import { putCheckQuyenThaoTacAction } from '../../../../../store/actions/phanQuyenAction'
import { addLog } from '../../../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../../../utils/log/logConstant'
moment.locale('vi')
const now = new Date()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { confirm } = Modal

const Create = ({ showModal, cancelModal, setStatusUpdate }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { PTCreate } = useSelector((state) => state.chuyenKhoReducer)
  const BranchLogin = localStorage.getItem('BRANH_LOGIN')
  const ID_COMPANY = localStorage.getItem('id_company')
  // const [filters, setFilters] = useState({})
  const [searchValues, setSearchValues] = useState({})
  const [data2, setData2] = useState([])
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const { infoUser } = useSelector((state) => state.userReducer)
  // const [searchKho, setSearchKho] = useState('')
  const [idBranh, setIdBranch] = useState(Number(BranchLogin))
  // const [searchName, setSearchName] = useState('')
  // const [searchMa, setSearchMa] = useState('')
  // const [searchNguoiXuat, setSearchNguoiXuat] = useState('')
  // const [searchDate, setSearchDate] = useState('')
  // const [filterDate, setFilterDate] = useState('=')
  // const [branhNhan, setBranchNhan] = useState('')
  // const [branchChuyen, setBranchChuyen] = useState('')
  const [ListBranch, setListBranch] = useState([])
  // const [ListKho, setListKho] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [since, setSince] = useState(now)
  const [toDate, SetToDate] = useState(now)
  const [valueExport, setValueExport] = useState([])
  const hasSelected = selectedRowKeys.length > 0
  const today = moment()
  // const handleSearch = (name) => (e) => {
  //   const { value } = e.target
  //   switch (name) {
  //     case 'ma':
  //       setSearchMa(value || '')
  //       break
  //     case 'tenPhieu':
  //       setSearchName(value || '')
  //       break
  //     case 'tenNVXuat':
  //       setSearchNguoiXuat(value || '')
  //       break
  //     case 'tenChiNhanhXuat':
  //       setBranchChuyen(value || '')
  //       break
  //     default:
  //       break
  //   }
  // }

  const maQuyenXemDs = 'QHT174'
  const maQuyenXuatExcel = 'QHT176'
  const maQuyenInDs = 'QHT175'
  const maQuyenChuyenTT = 'QHT601'
  const maQuyenXemCt = 'QHT186'
  const maQuyenXoaPhieu = 'QHT598'
  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        dispatch(filterPT_createCK(filter, setIsLoading))
        break
      case maQuyenXuatExcel:
        exportToExcel()
        break
      case maQuyenInDs:
        dispatch(printerAutoChuyenKho(selectedRowKeys, setSelectedRowKeys, infoNew?.isA5))
        break
      case maQuyenChuyenTT:
        showConfirm(infoNew)
        break
      case maQuyenXemCt:
        handleShowModalDetail(infoNew?.idNhapXuat)
        break
      case maQuyenXoaPhieu:
        handleDelete_CK(infoNew?.idNhapXuat)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        break
      case maQuyenXuatExcel:
        break
      case maQuyenInDs:
        break
      case maQuyenChuyenTT:
        break
      case maQuyenXemCt:
        break
      case maQuyenXoaPhieu:
        break
      default:
        return false
    }
  }

  // show confirm xuất kho
  const showConfirm = (items) => {
    // items.trangThai= 3 // thay đổi trạng thái nhận kho
    // items['tenNVNhan'] = infoUser.tenNV
    confirm({
      title: 'Xác nhận chuyển kho ',
      width: 450,
      icon: <ExclamationCircleFilled />,
      content: (
        <p className="text-start">
          bạn có muốn xác nhận chuyển phiếu <span className="font-semibold">{items.maPhieu}</span>{' '}
          đến chi nhánh
          <span className="font-semibold"> {items.tenChiNhanhNhan}</span>
        </p>
      ),
      okText: 'Xác nhận',
      cancelText: 'Hủy',
      onOk() {
        const fd = new FormData()
        fd.append('IdNhapXuat', items.idNhapXuat)
        fd.append('TenPhieu', items.tenPhieu)
        fd.append('NgayXuat', today.format('YYYY-MM-DDTHH:mm:ss'))
        fd.append('TrangThai', 2)
        dispatch(updateStatusCK_action(fd, items.idNhapXuat, filter, 'create'))
      },
      onCancel() {
        // console.log("Cancel");
      },
    })
  }
  // const handleSelectedBranhNhan = (value, option) => {
  //   setBranchNhan(value || '')
  //   if (value) {
  //     fetchKho(option.id)
  //   }
  //   setSearchKho('')
  // }
  // const handleSelectedKhoNhan = (value) => {
  //   setSearchKho(value || '')
  // }
  const handleShowModalDetail = (idNhapXuat) => {
    showModal()
    dispatch(listInfoThuocVTChyenKho_Action(idNhapXuat))
    setStatusUpdate(true) // bật trạng thái cho phép update
  }
  // xoá phiếu chuyển kho
  const handleDelete_CK = (idPhieu) => {
    const maphieuchuyen = filteredData2(data2).find((item) => item.idNhapXuat === idPhieu)?.maPhieu
    dispatch(deleteChuyenKho(idPhieu, handleFilter, maphieuchuyen))
  }
  const statusChuyenKho = 1 // trạng thái chuyển kho

  const filter = useMemo(() => {
    return {
      status: statusChuyenKho, //
      idChiNhanh: idBranh,
      since: moment(since).format('YYYY-MM-DD'),
      toDate: moment(toDate).format('YYYY-MM-DD'),
      nameChiNhanh:
        idBranh === ''
          ? 'Tất cả'
          : ListBranch?.find((item) => item.idChiNhanh === idBranh)?.tenChiNhanh,
      nameStatus: 'Phiếu tạo',
    }
  }, [since, toDate, idBranh, statusChuyenKho, ListBranch])

  // const filteredData = data?.filter((items) => {
  //   const findKho = searchKho.toLowerCase()
  //   const findMa = searchMa.trim().toLowerCase()
  //   const nameLower = searchName.trim().toLowerCase()
  //   const nguoiXuatLower = searchNguoiXuat.trim().toLowerCase()
  //   const branchNhanLower = branhNhan.trim().toLowerCase()
  //   const branchChuyenLower = branchChuyen.trim().toLowerCase()
  //   return (
  //     (items?.tenKhoNhap?.toLowerCase().includes(findKho) || !items?.tenKhoNhap) &&
  //     (items?.maPhieu?.toLowerCase().includes(findMa) || !items?.maPhieu) &&
  //     (items?.tenPhieu?.toLowerCase().includes(nameLower) || !items?.tenPhieu) &&
  //     (items?.tenNVXuat?.toLowerCase().includes(nguoiXuatLower) || !items?.tenNVXuat) &&
  //     buildDateFilterCondition(filterDate, searchDate, items?.ngayXuat) &&
  //     (items?.tenChiNhanhXuat?.toLowerCase().includes(branchChuyenLower) ||
  //       !items?.tenChiNhanhXuat) &&
  //     (items?.tenChiNhanhNhan.toLowerCase().includes(branchNhanLower) || !items?.tenChiNhanhNhan)
  //   )
  // })
  //----------- lấy danh sách chi nhánh theo công ty -------//
  const fetchBranch = async () => {
    const { data } = await branchService.getListBranch(ID_COMPANY)
    setListBranch(data)
  }
  //---------- lấy danh sách kho chi nhánh-------------//
  // const fetchKho = async (id) => {
  //   const { data } = await chuyenKhoService.getlistKhoNhan(id)
  //   setListKho(data)
  // }
  ///---- in phiếu hàng loạt -------
  const autoPrin = (isA5) => {
    if (hasSelected) {
      dispatch(putCheckQuyenThaoTacAction(maQuyenInDs, { isA5: isA5 }, actionIsTrue, actionIsFalse))
    }
  }
  // xử lí lọc dữ liệu
  const handleFilter = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  const onSelectChange = (newSelectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  useEffect(() => {
    const sortedData = _.orderBy(PTCreate, 'ngayXuat', 'desc')
    setData(sortedData)
  }, [PTCreate])
  useEffect(() => {
    fetchBranch()
  }, [])

  useEffect(() => {
    if (ListBranch.length) {
      handleFilter()
    }
  }, [ListBranch])
  // Hàm để lấy danh sách các bộ lọc không trùng lặp từ dữ liệu
  // const getUniqueFilters = (data, dataIndex) => {
  //   const uniqueValues = Array.from(new Set(data?.map((item) => item[dataIndex]))) // Lấy các giá trị không trùng
  //   return uniqueValues?.map((value) => ({
  //     text: value,
  //     value: value,
  //   }))
  // }
  // const compare = (a, b, dataIndex) => {
  //   if (a[dataIndex] < b[dataIndex]) {
  //     return -1
  //   }
  //   if (a[dataIndex] > b[dataIndex]) {
  //     return 1
  //   }
  //   return 0
  // }

  // const PhepTinh = [
  //   { label: '>', value: '>' },
  //   { label: '<', value: '<' },
  //   { label: '=', value: '=' },
  // ]
  // const handleSearchChange = (column, value) => {
  //   setSearchValues((prevValues) => ({
  //     ...prevValues,
  //     [column]: value,
  //   }))
  // }

  const filteredData2 = (
    data, // lọc giá trị search
  ) =>
    data?.filter((item) =>
      Object.entries(searchValues).every(([key, value]) =>
        item[key]?.toLowerCase().includes(value.toLowerCase()),
      ),
    )
  const onChangeTable = (pagination, filters, sorter, extra) => {
    setValueExport(extra.currentDataSource)
  }
  useEffect(() => {
    setData2(PTCreate)
    setValueExport(PTCreate)
  }, [PTCreate])
  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
    },
    {
      title: 'Mã phiếu',
      dataIndex: 'maPhieu',
      key: 'maPhieu',
      ...configSearchTableAnt(setSearchValues, searchValues, 'maPhieu', PTCreate, 100, false),
      // sorter: (a, b) => compare(a, b, 'maPhieu'),
      // filters: getUniqueFilters(PTCreate, 'maPhieu'),
      // filterSearch: true,
      // onFilter: (value, record) => record['maPhieu'] === value,
      // showSorterTooltip: false,
      // children: [
      //   {
      //     title: (
      //       <Input
      //         value={searchValues['maPhieu']}
      //         size="small"
      //         placeholder="Tìm mã phiếu"
      //         onChange={(e) => handleSearchChange('maPhieu', e.target.value)}
      //       />
      //       // <>
      //       //   <Input size="small" onChange={handleSearch('ma')} value={searchMa} />
      //       // </>
      //     ),

      //     onCell: () => ({
      //       style: {
      //         minWidth: 100,
      //       },
      //     }),
      //     dataIndex: 'maPhieu',
      //     key: 1,
      //   },
      // ],
    },
    {
      title: 'Tên phiếu',
      dataIndex: 'tenPhieu',
      key: 'tenPhieu',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenPhieu', PTCreate, 120, false),
      // sorter: (a, b) => compare(a, b, 'tenPhieu'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Input size="small" onChange={handleSearch('tenPhieu')} value={searchName} />
      //       </>
      //     ),
      //     onCell: () => ({
      //       style: {
      //         minWidth: 120,
      //       },
      //     }),
      //     dataIndex: 'tenPhieu',
      //     key: 1,
      //   },
      // ],
    },
    {
      title: 'Người chuyển',
      dataIndex: 'tenNVXuat',
      key: 'tenNVXuat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenNVXuat', PTCreate, 120, false),
      // sorter: (a, b) => compare(a, b, 'tenNVXuat'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Input size="small" onChange={handleSearch('tenNVXuat')} value={searchNguoiXuat} />
      //       </>
      //     ),
      //     dataIndex: 'tenNVXuat',
      //     key: 1,
      //     onCell: () => ({
      //       style: {
      //         minWidth: 120,
      //       },
      //     }),
      //   },
      // ],
    },
    {
      title: 'Ngày chuyển',
      dataIndex: 'ngayXuat',
      key: 'ngayXuat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'ngayXuat', PTCreate, 120, true, {
        render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : ''),
      }),
      // sorter: (a, b) => compare(a, b, 'NGAYNHAN'),
      // children: [
      //   {
      //     title: (
      //       <div className="flex gap-1">
      //         <Select
      //           onChange={(value) => setFilterDate(value)}
      //           value={filterDate}
      //           size="small"
      //           className="w-16"
      //           options={PhepTinh}
      //         />
      //         <DatePicker
      //           value={searchDate !== '' ? dayjs(searchDate, 'YYYY-MM-DD') : ''}
      //           size="small"
      //           format={'DD/MM/YYYY'}
      //           onChange={(date, DateString) => {
      //             setSearchDate(
      //               DateString ? moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      //             )
      //           }}
      //         />
      //       </div>
      //     ),
      //     dataIndex: 'NGAYNHAN',
      //     key: 2,
      //     onCell: () => ({
      //       style: {
      //         minWidth: 150,
      //       },
      //     }),
      //   },
      // ],
    },
    {
      title: 'Chi nhánh chuyển',
      dataIndex: 'tenChiNhanhXuat',
      key: 'tenChiNhanhXuat',
      ...configSearchTableAnt(
        setSearchValues,
        searchValues,
        'tenChiNhanhXuat',
        PTCreate,
        120,
        false,
      ),
      // sorter: (a, b) => compare(a, b, 'tenChiNhanhXuat'),
      // // onFilter: (value, record) => record.sohoadon.startsWith(value),
      // // filters: getUniqueFilters(data, 'tenChiNhanhXuat'),
      // // filterSearch: true,
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Input size="small" onChange={handleSearch('tenChiNhanhXuat')} value={branchChuyen} />
      //       </>
      //     ),
      //     dataIndex: 'tenChiNhanhXuat',
      //     key: 1,
      //     onCell: () => ({
      //       style: {
      //         minWidth: 120,
      //       },
      //     }),
      //   },
      // ],
    },
    {
      title: 'Kho chuyển',
      dataIndex: 'tenKhoXuat',
      key: 'tenKhoXuat',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenKhoXuat', PTCreate, 120, false),
      // sorter: (a, b) => compare(a, b, 'tenKhoXuat'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Select
      //           filterOption={(input, option) =>
      //             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      //           }
      //           size="small"
      //           value={searchKho}
      //           showSearch
      //           onChange={handleSelectedKhoNhan}
      //           options={[
      //             { label: 'Tất cả', value: '' },
      //             ...ListKho.map((item) => ({
      //               label: item.tenKho,
      //               value: item.tenKho,
      //               key: item.tenKho, // Thêm key cho mỗi tùy chọn
      //             })),
      //           ]}
      //           className="w-full"
      //         />
      //       </>
      //     ),
      //     dataIndex: 'tenKhoXuat',
      //     key: 2,
      //     onCell: () => ({
      //       style: {
      //         minWidth: 120,
      //       },
      //     }),
      //   },
      // ],
    },
    {
      title: 'Chi nhánh nhận',
      dataIndex: 'tenChiNhanhNhan',
      key: 'tenChiNhanhNhan',
      ...configSearchTableAnt(
        setSearchValues,
        searchValues,
        'tenChiNhanhNhan',
        PTCreate,
        120,
        false,
      ),
      // sorter: (a, b) => compare(a, b, 'tenChiNhanhNhan'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Select
      //           size="small"
      //           className="w-full"
      //           filterOption={(input, option) =>
      //             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      //           }
      //           showSearch
      //           onChange={handleSelectedBranhNhan}
      //           value={branhNhan}
      //           options={[
      //             { label: 'Tất cả', value: '' },
      //             ...ListBranch?.map((item) => ({
      //               label: item.tenChiNhanh,
      //               value: item.tenChiNhanh,
      //               id: item.idChiNhanh,
      //             })),
      //           ]}
      //         />
      //       </>
      //     ),
      //     dataIndex: 'tenChiNhanhNhan',
      //     key: 2,
      //     onCell: () => ({
      //       style: {
      //         minWidth: 120,
      //       },
      //     }),
      //   },
      // ],
    },
    {
      title: 'Kho nhận',
      dataIndex: 'tenKhoNhap',
      key: 'tenKhoNhap',
      ...configSearchTableAnt(setSearchValues, searchValues, 'tenKhoNhap', PTCreate, 120, false),
      // sorter: (a, b) => compare(a, b, 'tenKhoNhap'),
      // children: [
      //   {
      //     title: (
      //       <>
      //         <Select
      //           filterOption={(input, option) =>
      //             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      //           }
      //           size="small"
      //           value={searchKho}
      //           showSearch
      //           onChange={handleSelectedKhoNhan}
      //           options={[
      //             { label: 'Tất cả', value: '' },
      //             ...ListKho.map((item) => ({
      //               label: item.tenKho,
      //               value: item.tenKho,
      //               key: item.tenKho, // Thêm key cho mỗi tùy chọn
      //             })),
      //           ]}
      //           className="w-full"
      //         />
      //       </>
      //     ),
      //     dataIndex: 'tenKhoNhap',
      //     key: 2,
      //     onCell: () => ({
      //       style: {
      //         minWidth: 120,
      //       },
      //     }),
      //   },
      // ],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'XACNHAN',
      key: 'XACNHAN',
      width: 90,
      align: 'center',
      fixed: 'right',
    },
    {
      title: 'Hành động',
      dataIndex: 'ACTION',
      key: 'unit',
      width: 120,
      align: 'center',
      fixed: 'right',
    },
  ]

  const exportToExcel = () => {
    try {
      const formattedData = filteredData2(valueExport).map((item) => ({
        'Mã phiếu': item.maPhieu,
        'Tên phiếu': item.tenPhieu,
        'Người chuyển': item.tenNVXuat,
        'Ngày chuyển': moment(item.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
        'Chi nhánh chuyển': item.tenChiNhanhXuat,
        'Kho chuyển': item.tenKhoXuat,
        'Chi nhánh nhận': item.tenChiNhanhNhan,
        'Kho nhận': item.tenKhoNhap,
      }))
      const name = 'Phiếu chuyển kho - Phiếu tạo.xlsx'
      formattedData?.length && exportExcelformat(formattedData, name)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_LIST_PHIEU_CHUYEN,
        true,
        MENU_CONSTANTS.CHUYEN_KHO,
        null,
        {
          dateFrom: filter.since,
          dateTo: filter.toDate,
          nameStatus: filter.nameStatus,
          nameChiNhanh: filter.nameChiNhanh,
        },
      )
    } catch (error) {
      console.log(error)
      const detailErrorPayload = `Có lỗi xảy ra khi xuất file excel \n ${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.CHUYEN_KHO,
        detailErrorPayload,
      )
    }
  }
  const handleDatePicker = (name) => (date, dateString) => {
    name === 'since'
      ? setSince(moment(dateString, dateFormat).format(momentDate))
      : SetToDate(moment(dateString, dateFormat).format(momentDate))
  }

  return (
    <div className="h-full">
      <div className="flex gap-5">
        <div className="w-96 gap-2 items-center flex">
          <label className="font-semibold w-48">Chi nhánh chuyển:</label>
          <Select
            className="w-full"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            onChange={(value) => setIdBranch(value)}
            value={idBranh}
            options={[
              { label: 'Tất cả', value: '' },
              ...ListBranch?.map((item) => ({
                label: item.tenChiNhanh,
                value: item.idChiNhanh,
              })),
            ]}
          />
        </div>
        <div className="flex gap-3">
          <div>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              onChange={handleDatePicker('since')}
              format={dateFormat}
            />
          </div>
          <div>
            <DatePicker
              allowClear={false}
              defaultValue={dayjs(now)}
              maxDate={dayjs(now)}
              minDate={dayjs(since, dateFormat)}
              onChange={handleDatePicker('toDate')}
              format={dateFormat}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div
            className="cursor-pointer rounded-sm text-blue-500  hover:bg-slate-200"
            onClick={handleFilter}
          >
            <CachedIcon sx={{ fontSize: 25 }} />
          </div>
        </div>
        <div className="flex gap-3">
          <Popconfirm
            title="In phiếu chuyển kho"
            onConfirm={() => autoPrin(false)}
            onCancel={() => autoPrin(true)}
            okText="A4"
            cancelText="A5"
            icon={<PrintIcon color="primary" />}
            cancelButtonProps={{ type: 'primary', className: 'w-1/3 ml-0' }}
            okButtonProps={{ type: 'primary', className: 'w-1/3 ml-0' }}
            className={`${hasSelected ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            disabled={!hasSelected}
          >
            <PrintIcon
              className={`cursor-pointer rounded-sm ${hasSelected ? 'text-blue-500' : 'text-gray-500'}  hover:bg-slate-200`}
            />
          </Popconfirm>
        </div>
        <div className="flex gap-3 ml-auto">
          <AntButton
            disabled={!filteredData2(data2)?.length}
            // onClick={exportToExcel}
            onClick={() =>
              dispatch(
                putCheckQuyenThaoTacAction(maQuyenXuatExcel, null, actionIsTrue, actionIsFalse),
              )
            }
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </AntButton>
        </div>
      </div>
      <div
        className=" mt-2 "
        // style={{
        //     boxShadow: '0 1px 2px 0 rgba(60,64,67,.1),0 2px 6px 2px rgba(60,64,67,.15)',
        // }}
      >
        <ConfigProvider
          theme={{
            token: {
              padding: 4,
              fontSize: 12,
            },
            components: {
              Table: {
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            onChange={onChangeTable}
            rowSelection={rowSelection}
            loading={isLoading}
            bordered
            pagination={false}
            scroll={{
              x: filteredData2(data2)?.length !== 0 ? 'max-content' : 1000,
              y: '70vh',
            }}
            columns={columns}
            dataSource={filteredData2(data2)?.map((items, index) => ({
              key: items.idNhapXuat,
              STT: ++index,
              ...items,
              // maPhieu: items.maPhieu,
              // tenNVXuat: items.tenNVXuat,
              // tenPhieu: items.tenPhieu,
              // ngayXuat: moment(items.ngayXuat).format('DD/MM/YYYY HH:mm:ss'),
              // tenChiNhanhXuat: items.tenChiNhanhXuat,
              // tenChiNhanhNhan: items.tenChiNhanhNhan,
              // tenKhoXuat: items.tenKhoXuat,
              // tenKhoNhap: items.tenKhoNhap,
              XACNHAN: (
                <>
                  <Button
                    disabled={Number(BranchLogin) !== items?.idChiNhanhXuat}
                    onClick={() => {
                      dispatch(
                        putCheckQuyenThaoTacAction(
                          maQuyenChuyenTT,
                          items,
                          actionIsTrue,
                          actionIsFalse,
                        ),
                      )
                    }}
                    size="small"
                    variant="contained"
                    style={{
                      fontSize: 12,
                      padding: 2,
                    }}
                  >
                    xuất kho
                  </Button>
                </>
              ),
              ACTION: (
                <div className="flex gap-4 justify-center ">
                  <Tooltip title="Xem chi tiết" color="#108ee9">
                    <ContainerOutlined
                      onClick={() => {
                        dispatch(
                          putCheckQuyenThaoTacAction(
                            maQuyenXemCt,
                            { idNhapXuat: items?.idNhapXuat },
                            actionIsTrue,
                            actionIsFalse,
                          ),
                        )
                      }}
                      className="text-xl text-[#108ee9]  cursor-pointer"
                    />
                  </Tooltip>
                  <Tooltip title="Xoá phiếu" color="red">
                    <Popconfirm
                      placement="left"
                      title="Xoá phiếu chuyển kho"
                      description={`Bạn có chắc muốn xoá phiếu ${items.maPhieu}?`}
                      onConfirm={() => {
                        dispatch(
                          putCheckQuyenThaoTacAction(
                            maQuyenXoaPhieu,
                            { idNhapXuat: items?.idNhapXuat },
                            actionIsTrue,
                            actionIsFalse,
                          ),
                        )
                      }}
                      disabled={items.nhanVienXuat !== infoUser?.idnv}
                      okText="Xoá"
                      okType="danger"
                      cancelText="Huỷ"
                      icon={
                        <QuestionCircleOutlined
                          style={{
                            color: 'red',
                          }}
                        />
                      }
                    >
                      <DeleteOutlined
                        className={`text-xl ${items.nhanVienXuat !== infoUser?.idnv ? 'text-gray-500' : 'text-red-500'}   cursor-pointer`}
                      />
                    </Popconfirm>
                  </Tooltip>
                </div>
              ),
            }))}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}

export default Create
