import { ConfigProvider, Table, Input, Space, Button, Typography } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { formatNumber } from 'devextreme/localization'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import React, { useState, useRef } from 'react'
import 'moment/locale/vi'
import { calculateAge } from '../../../../../utils/calculateAge'
moment.locale('vi')
const { Text } = Typography
const TableDetail = ({ data, isLoading }) => {
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [activeRow, setActiveRow] = useState(null)
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex] ?? '' // Kiểm tra nếu record[dataIndex] là null/undefined, gán chuỗi rỗng
      return recordValue.toString().toLowerCase().includes(value.toLowerCase())
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const handleClickRow = (record) => {
    if (activeRow && activeRow.mabenhnhan === record.mabenhnhan) {
      setActiveRow(null)
    } else {
      setActiveRow(record)
    }
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          padding: 5,
        },
        components: {
          Table: {
            rowHoverBg: '#ecf0f1',
            headerBg: '#e6e6e6',
            footerBg: '#e6e6e6',
            borderColor: '#BABABA',
          },
        },
      }}
    >
      <Table
        pagination={false}
        loading={isLoading}
        bordered
        onRow={(record) => ({
          onClick: () => handleClickRow(record),
        })}
        rowKey={(record) => record.mabenhnhan}
        dataSource={data}
        scroll={{ x: data?.length > 0 ? 'max-content' : 2800, y: '70vh' }}
        columns={[
          {
            title: 'STT',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 40,
            fixed: 'left',
            render: (text, record, index) => ++index,
          },
          {
            title: 'Mã BN',
            dataIndex: 'mabenhnhan',
            key: 'mabenhnhan',
            fixed: 'left',
            ...getColumnSearchProps('mabenhnhan'),
            width: 100,
          },
          {
            title: 'Tên BN',
            dataIndex: 'tenbenhnhan',
            key: 'tenbenhnhan',
            ...getColumnSearchProps('tenbenhnhan'),
          },
          {
            title: 'Giới tính',
            dataIndex: 'gioitinh',
            align: 'center',
            width: 100,
            key: 'gioitinh',
            ...getColumnSearchProps('gioitinh'),
          },
          {
            title: 'Ngày sinh',
            dataIndex: 'ngaysinh',
            key: 'ngaysinh',
            render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
            width: 90,
          },
          {
            title: 'Tuổi',
            dataIndex: 'tenPhuongThucTT',
            key: 'tenPhuongThucTT',
            width: 200,
            render: (text, record) => calculateAge(record.ngaysinh),
          },
          {
            title: 'SĐT',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            width: 120,
            render: (text, record) => {
              if (record.mabenhnhan === activeRow?.mabenhnhan) {
                return <p>{text}</p>
              }
              const maskedNumber = text?.replace(/./g, '*')
              return <p>{maskedNumber}</p>
            },
          },
        ]}
        summary={(pageData) => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={7}>
                <p className="font-semibold">Tổng bệnh nhân: {pageData.length}</p>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </ConfigProvider>
  )
}

export default TableDetail
