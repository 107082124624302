import React, { useEffect, useState } from 'react'
import { Table, DatePicker, Card, Typography } from 'antd'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import { DownOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/vi'
import locale from 'antd/locale/vi_VN'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { traCuuService } from '../../../services/traCuu/traCuuService'
const { Title, Text } = Typography

dayjs.locale('vi')
const today = moment().format('YYYY-MM-DD')

const columns = [
  {
    title: 'Ngày',
    dataIndex: 'ngaykedon',
    key: 'ngaykedon',
    width: 100,
    render: (text) => text && moment(text).format('DD/MM/YYYY'),
  },
  { title: 'Mã BN', dataIndex: 'mabenhnhan', key: 'mabenhnhan', width: 150 },
  { title: 'Tên BN', dataIndex: 'tenbenhnhan', key: 'tenbenhnhan', width: 150 },
  {
    title: 'Chẩn đoán',
    dataIndex: 'chandoan',
    key: 'chandoan',
    ellipsis: true,
  },
  {
    title: 'Tổng tiền',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    align: 'right',
    render: (value, record) => {
      // Calculate the totalAmount for each record
      const totalAmount = record.thuocs.reduce((total, thuoc) => {
        return total + (thuoc.soluongban * thuoc.dongia);  // Calculate total for each medication
      }, 0);
      
      // Format and return the total amount in Vietnamese format
      return `${totalAmount.toLocaleString('vi-VN')}đ`;
    },
  },
]
const HistoryPrescription = () => {
  const [data, setdata] = useState([])
  const [date, setDate] = useState({ from: today, to: today })
  const [isLoading, setIsLoading] = useState(false)
  const { infoUser } = useSelector((state) => state.userReducer)
  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Thuốc',
        dataIndex: 'tenbietduoc',
        key: 'tenbietduoc',
        render: (text, record) => (
          <span>
            {text}{' '}
            <Text type="secondary" className="text-xs ml-1">
            Liều: {record.lieudung} 
            </Text>
          </span>
        ),
      },
      {
        title: 'Số lần ngày',
        dataIndex: 'solanngay',
        key: 'solanngay',
        align: 'center',
      },
      {
        title: 'Đơn vị dùng',
        dataIndex: 'donvidung',
        key: 'donvidung',
        align: 'center',
      },
      { title: 'SL Kê', dataIndex: 'soluongke', key: 'soluongke', align: 'right' },
      { title: 'SL Bán', dataIndex: 'soluongban', key: 'soluongban', align: 'right' },
      {
        title: 'Đơn giá',
        dataIndex: 'dongia',
        key: 'dongia',
        align: 'right',
        render: (value) => `${value.toLocaleString('vi-VN')}đ`,
      },
      
      {
        title: 'Thành tiền',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        align: 'right',
        render: (value,record) => `${(record.dongia * record.soluongban).toLocaleString('vi-VN')}đ`,
      },
    ]

    return (
      <Table
        columns={columns}
        dataSource={record.thuocs}
        pagination={false}
        className="bg-gray-50"
      />
    )
  }

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const { data } = await traCuuService.getToaByUserID(infoUser?.idnv, date.from, date.to)
      setdata(data.prescriptionHistory)
    } catch (error) {
      console.log(error)
    }finally{
      setIsLoading(false)
    } 
  }
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="bg-[#EFEFEF] mx-auto p-4 h-full">
      <Card className="w-full shadow-md h-full">
        <div className="flex justify-between items-center mb-4">
          <div>
            <Title level={5} className="!mb-0">
              Kê toa: {infoUser?.tenNV}
            </Title>
            <Text type="secondary" className="text-sm">
              Xem lại các đơn thuốc đã kê cho bệnh nhân
            </Text>
          </div>
          <div className="flex gap-2">
            <IconButton onClick={fetchData} color="primary" aria-label="delete" size="small">
              <SyncIcon />
            </IconButton>
            <DatePicker
              value={dayjs(date.from)}
              onChange={(date, dateString) =>
                setDate((prevState) => ({
                  ...prevState,
                  from: moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                }))
              }
              allowClear={false}
              placeholder="Chọn ngày"
              format="DD/MM/YYYY"
              locale={locale.DatePicker}
              className="w-40"
            />
            <DatePicker
              allowClear={false}
              value={dayjs(date.to)}
              placeholder="Chọn ngày"
              format="DD/MM/YYYY"
              locale={locale.DatePicker}
              onChange={(date, dateString) => {
                console.log(moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))

                setDate((prevState) => ({
                  ...prevState,
                  to: moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                }))
              }}
              className="w-40"
            />
          </div>
        </div>
        <Table
        loading={isLoading}
          pagination={false}
          scroll={{ y:'70vh',x:1000 }}
          columns={columns}
          expandable={{
            expandedRowRender,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownOutlined
                  className="rotate-180 transition-transform"
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <DownOutlined
                  className="transition-transform"
                  onClick={(e) => onExpand(record, e)}
                />
              ),
          }}
          dataSource={data.map((items) => ({ ...items, key: items.bnttc }))}
          className="[&_.ant-table-thead_.ant-table-cell]:bg-gray-100 [&_.ant-table-thead_.ant-table-cell]:font-semibold"
          size="middle"
        />
      </Card>
    </div>
  )
}

export default HistoryPrescription
