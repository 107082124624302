import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, DatePicker, Select, Table, TreeSelect } from 'antd'
import { IconButton } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { MinusOutlined, CaretUpFilled, CaretDownFilled } from '@ant-design/icons'
import { branchService } from '../../../../services/branch/branchService'
import { khoVTservice } from '../../../../services/khoVT/khoVTservice'
import { reportService } from '../../../../services/Report/reportService'
import { listAllCompanyAction } from '../../../../store/actions/companyAction'
import { phanQuyenService } from '../../../../services/phanQuyen/phanQuyenService'
import { logoutTaiKhoanBiKhoaAction } from '../../../../store/actions/phanQuyenAction'
import { exportExcelformat } from '../../../../utils/export/exportExcelFormat'
import ToastCus from '../../../../utils/Toast'
import _ from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/vi_VN'
import 'moment/locale/vi'
import 'dayjs/locale/vi'
import { getListNameChiNhanh } from '../../../../utils/algorithm'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
moment.locale('vi')
const now = moment()
const dateFormat = 'DD-MM-YYYY'
const momentDate = 'YYYY-MM-DD'
const { SHOW_PARENT } = TreeSelect

function DoLech() {
  const dispatch = useDispatch()
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const BranchLogin = Number(localStorage.getItem('BRANH_LOGIN'))
  const id_company = Number(localStorage.getItem('id_company'))
  const { infoUser } = useSelector((state) => state.userReducer)
  const { listCompany } = useSelector((state) => state.companyReducer)
  const [branch, setBranch] = useState([])
  const [value, setValue] = useState(['all'])
  const [listChuyenKhoa, setListChuyenKhoa] = useState([])
  const [chuyenKhoa, setChuyenKhoa] = useState('all')
  const [dataReport, setDataReport] = useState([])
  const [company, setCompany] = useState(id_company)
  const [isLoading, setIsLoading] = useState(false)
  // const [dateForm, setDateForm] = useState(now.format(momentDate))
  // const [dateTo, setDateTo] = useState(now.format(momentDate))
  const [dateEnd, setDateEnd] = useState(now.format(momentDate))
  // const [search, setSearch] = useState('')

  const nameChiNhanh = useMemo(() => getListNameChiNhanh(value, branch), [value, branch])
  const nameCompany = useMemo(
    () => listCompany?.find((item) => item?.idct === company)?.tenct,
    [company, listCompany],
  )

  const nameChuyenKhoa = useMemo(
    () =>
      chuyenKhoa === 'all'
        ? 'Tất cả'
        : listChuyenKhoa?.find((item) => item?.idChuyenKhoa === chuyenKhoa)?.tenChuyenKhoa,
    [chuyenKhoa, listChuyenKhoa],
  )

  const tongST = dataReport?.reduce((tong, item) => (tong += item?.tongsotoa), 0)
  const tongTCLT = dataReport?.reduce((tong, item) => (tong += item?.tongsotoalaythuoc), 0)
  const tongDCL = tongTCLT - tongST
  const tongTLDCL = Math.round((tongTCLT / tongST - 1) * 100 * 10) / 10
  const tongTTCLTtrenTT = Math.round((tongTCLT / tongST) * 100 * 10) / 10

  const tinhThu2CuaNgayBatKy = (date) => {
    const inputDate = moment(date)
    const dayOfWeek = inputDate.day()
    const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1
    return inputDate.subtract(daysToSubtract, 'days').format('YYYY-MM-DD')
  }
  const fetchReport = async () => {
    try {
      setIsLoading(true)
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT540',
      )
      const { data } = await reportService.getBaoCaoDoLechTiLeToa(
        (value?.includes('all') || !value?.length ? branch : value)
          ?.map(
            (item) => `idcn=${value?.includes('all') || !value?.length ? item?.idChiNhanh : item}`,
          )
          ?.join('&')
          ?.toString(),
        tinhThu2CuaNgayBatKy(dateEnd), // dateStart thứ 2
        dateEnd,
      )
      setDataReport(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.BAO_CAO_DO_LECH,
        true,
        MENU_CONSTANTS.TI_LE_TOA,
        null,
        {
          dateFrom: tinhThu2CuaNgayBatKy(dateEnd),
          dateTo: dateEnd,
          nameCompany,
          nameChiNhanh,
          nameChuyenKhoa,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.TI_LE_TOA,
        detailErrorPayload,
      )
    } finally {
      setIsLoading(false)
    }
  }
  const getListChuyenKhoa = async () => {
    try {
      const { data } = await khoVTservice.getAllChuyenKhoa()
      setListChuyenKhoa(data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchBranch = async (idct, idck) => {
    try {
      setIsLoading(true)
      const { data } = await branchService.getBranchNotVP(idct)
      if (!idck || idck === 'all') setBranch(data)
      else if (idck) setBranch(data?.filter((item) => item?.idChuyenKhoa === idck))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const treeData = [
    {
      title: 'Tất cả chi nhánh',
      value: 'all',
      key: 'all',
      children: branch?.map((branch) => ({
        title: branch?.tenChiNhanh,
        value: branch?.idChiNhanh,
        key: `${branch?.idChiNhanh}`,
      })),
    },
  ]
  const onChange = (newValue) => {
    setValue(newValue)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Chọn chi nhánh...',
    filterTreeNode: (input, treeNode) =>
      treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    showSearch: true,
    style: {
      width: '100%',
    },
  }
  const exportToExcel = async () => {
    try {
      await phanQuyenService.putCheckQuyenThaoTac(
        infoUser?.taiKhoan ?? userInfo?.taiKhoan,
        'QHT541',
      )

      const formatData = dataReport?.map((item, index) => ({
        'STT ': ++index,
        'Tên chi nhánh': item?.tenchinhanh,
        'Tên viết tắt': item?.tenviettat,
        'Tổng số toa': item?.tongsotoa,
        'Tổng toa có lấy thuốc': item?.tongsotoalaythuoc,
        'Độ chênh lệch': item?.dochenhlech,
        'Tỉ lệ % độ chênh lệch': `${item?.tylechenhlech}%`,
        '% Tổng toa có lấy thuốc/Tổng toa': `${item?.tylechenhlechcolaythuoc}%`,
      }))
      const name = 'BẢNG SO SÁNH TỈ LỆ % ĐỘ CHÊNH LỆCH TỔNG TOA  CÓ LẤY THUỐC SO VỚI TỔNG SỐ TOA'
      if (formatData?.length) exportExcelformat(formatData, name)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.XUAT_EXCEL_BAO_CAO_DO_LECH,
        true,
        MENU_CONSTANTS.TI_LE_TOA,
        null,
        {
          dateFrom: tinhThu2CuaNgayBatKy(dateEnd),
          dateTo: dateEnd,
          nameCompany,
          nameChiNhanh,
          nameChuyenKhoa,
        },
      )
    } catch (error) {
      console.error('Error : ', error)
      if (error?.response?.data?.message === 'Tài khoản đang bị khóa!') {
        dispatch(logoutTaiKhoanBiKhoaAction(error?.response?.data?.message))
        return
      }
      ToastCus.fire({
        icon: 'error',
        title: error?.response?.data?.message ?? 'Thao tác thất bại!',
      })
      const detailErrorPayload = `Có lỗi xảy ra khi xuất file excel báo cao độ lệch \n${JSON.stringify(error)}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.TI_LE_TOA,
        detailErrorPayload,
      )
    }
  }
  const onLoad = () => {
    // setSearch((prev) => (prev && prev?.length ? prev?.trim() : prev))
    if (!value || !value?.length) setValue(['all'])
    fetchReport()
  }
  useEffect(() => {
    dispatch(listAllCompanyAction())
    getListChuyenKhoa()
    fetchBranch(id_company, chuyenKhoa)
  }, [])
  useEffect(() => {
    if (branch?.length && infoUser) fetchReport()
  }, [branch, infoUser])
  const iconTiLe = (num) => {
    return num < 0 ? (
      <>
        <CaretDownFilled className="text-3xl leading-1 max-h-0 p-0 flex items-center justify-center text-red-500" />
      </>
    ) : num > 0 ? (
      <>
        <CaretUpFilled className="text-3xl leading-1 max-h-0 p-0 flex items-center justify-center text-green-500" />
      </>
    ) : (
      <>
        <MinusOutlined className="text-3xl leading-1 max-h-0 p-0 flex items-center justify-center text-amber-500" />
      </>
    )
  }
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Tên chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      fixed: 'left',
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'tenviettat',
      key: 'tenviettat',
      align: 'center',
      fixed: 'left',
      width: 90,
    },
    {
      title: 'Tổng số toa',
      dataIndex: 'tongsotoa',
      key: 'tongsotoa',
      align: 'center',
      width: 105,
    },
    {
      title: 'Tổng toa có lấy thuốc',
      dataIndex: 'tongsotoalaythuoc',
      key: 'tongsotoalaythuoc',
      align: 'center',
      width: 160,
    },
    {
      title: 'Độ chênh lệch',
      dataIndex: 'dochenhlech',
      key: 'dochenhlech',
      align: 'center',
      width: 115,
      render: (text) => (
        <p className={text < 0 ? 'text-red-500' : text > 0 ? 'text-green-500' : ''}>{text}</p>
      ),
    },
    {
      title: 'Tỉ lệ % độ chênh lệch',
      dataIndex: 'tylechenhlech',
      key: 'tylechenhlech',
      align: 'center',
      width: 155,
      render: (text) => (
        <p className={text < 0 ? 'text-red-500' : text > 0 ? 'text-green-500' : ''}>{text}%</p>
      ),
    },
    {
      title: 'Tỉ lệ % độ chênh lệch',
      dataIndex: 'tylechenhlech',
      key: 'icontylechenhlech',
      align: 'center',
      width: 155,
      render: (text) => iconTiLe(text),
    },
    {
      title: '% Tổng toa có lấy thuốc/Tổng toa',
      dataIndex: 'tylechenhlechcolaythuoc',
      key: 'tylechenhlechcolaythuoc',
      align: 'center',
      width: 235,
      render: (text) => (
        <p className={text < 0 ? 'text-red-500' : text > 0 ? 'text-green-500' : ''}>{text}%</p>
      ),
    },
  ]

  return (
    <>
      <div className="flex gap-2">
        <div className="w-[17rem]">
          <Select
            showSearch
            value={company}
            onChange={(value) => {
              setCompany(value)
              fetchBranch(value, chuyenKhoa)
              setValue(['all'])
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={listCompany?.map(({ idct, tenct }) => ({
              label: tenct,
              value: idct,
            }))}
            className="w-full"
          />
        </div>
        <div className="w-[17rem]">
          <Select
            showSearch
            value={chuyenKhoa}
            onChange={(value) => {
              setChuyenKhoa(value)
              fetchBranch(company, value)
              setValue(['all'])
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                label: 'Tất cả chuyên khoa',
                value: 'all',
              },
              ...listChuyenKhoa?.map((item) => ({
                label: item?.tenChuyenKhoa,
                value: item?.idChuyenKhoa,
              })),
            ]}
            className="w-full"
          />
        </div>
        <div className="w-[17rem] flex gap-2">
          <TreeSelect
            {...tProps}
            style={{ flexWrap: 'unset' }}
            className="w-full h-8 overflow-clip"
          />
        </div>
        <div className="w-[8rem] flex items-center gap-2">
          <DatePicker
            placeholder="Ngày cuối..."
            allowClear={false}
            defaultValue={dayjs(now)}
            maxDate={dayjs(now)}
            onChange={(date, dateString) =>
              setDateEnd(moment(dateString, dateFormat).format(momentDate))
            }
            format={dateFormat}
            locale={{ ...locale, week: { start: 1 } }}
          />
        </div>
        {/* <div className="flex w-64">
          <Input
            value={search}
            placeholder="Nhập mã hàng, tên hàng..."
            onChange={(e) => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </div> */}
        <div className="flex gap-2">
          <IconButton
            disabled={isLoading}
            onClick={onLoad}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <SyncIcon />
          </IconButton>
        </div>
        <div className="font-semibold ">
          <Button
            disabled={isLoading || !dataReport?.length}
            onClick={exportToExcel}
            type="text"
            size="middle"
            className="text-green-700 p-1"
            icon={<ImportExportIcon />}
          >
            Xuất Excel
          </Button>
        </div>
      </div>
      <div className="mt-2">
        <ConfigProvider
          theme={{
            token: {
              padding: 5,
              fontSize: 14,
            },
            components: {
              Table: {
                headerBorderRadius: 0,
                rowHoverBg: '#ecf0f1',
                headerBg: '#e6e6e6',
                footerBg: '#e6e6e6',
                borderColor: '#BABABA',
              },
            },
          }}
        >
          <Table
            scroll={{
              // x: 2000,
              y: '71.1vh',
            }}
            pagination={false}
            loading={isLoading}
            bordered
            // dataSource={dataReport.concat(dataReport)}
            dataSource={dataReport}
            columns={columns}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row className="text-center font-semibold">
                  <Table.Summary.Cell index={0} colSpan={3}>
                    Tổng
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>{tongST}</Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>{tongTCLT}</Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={5}
                    className={tongDCL < 0 ? 'text-red-500' : tongDCL > 0 ? 'text-green-500' : ''}
                  >
                    {tongDCL}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={6}
                    className={
                      tongTLDCL < 0 ? 'text-red-500' : tongTLDCL > 0 ? 'text-green-500' : ''
                    }
                  >
                    {tongTLDCL}%
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    {iconTiLe(tongTLDCL)}
                    {/* {tongTLDCL < 0 ? (
                      <>
                        <CaretDownFilled className="text-3xl leading-1 max-h-0 p-0 flex items-center justify-center text-red-500" />
                      </>
                    ) : tongTLDCL > 0 ? (
                      <>
                        <CaretUpFilled className="text-3xl leading-1 max-h-0 p-0 flex items-center justify-center text-green-500" />
                      </>
                    ) : (
                      <>
                        <MinusOutlined className="text-3xl leading-1 max-h-0 p-0 flex items-center justify-center text-amber-500" />
                      </>
                    )} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    <p
                      className={`${
                        tongTTCLTtrenTT < 0
                          ? 'text-red-500'
                          : tongTTCLTtrenTT > 0
                            ? 'text-green-500'
                            : ''
                      } pr-[1rem]`}
                    >
                      {tongTTCLTtrenTT}%
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default DoLech
