import React, { useCallback, useEffect, useState, useRef } from 'react'
import { ConfigProvider, DatePicker, Input, Select, Table, Space, Button } from 'antd'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import IconButton from '@mui/material/IconButton'
import CachedIcon from '@mui/icons-material/Cached'
import moment from 'moment'
import dayjs from 'dayjs'
import _ from 'lodash'
import { branchService } from '../../../services/branch/branchService'
import { recieveService } from '../../../services/receive/recieveService'
import 'moment/locale/vi'
import { exportExcelformat } from '../../../utils/export/exportExcelFormat'
import { addLog } from '../../../utils/log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../../../utils/log/logConstant'
import { useSelector } from 'react-redux'
moment.locale('vi')
const now = moment()
const TabTaiKhamTC = () => {
  const { infoUser } = useSelector((state) => state.userReducer)
  const { PkDangNhap } = useSelector((state) => state.branchReducer)
  const ID_BRANCH_LOGIN = Number(localStorage.getItem('BRANH_LOGIN'))
  const ID_COMPANY = Number(localStorage.getItem('id_company'))
  const [date, setDate] = useState(now.format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState(now.format('YYYY-MM-DD'))
  const [idBranch, setIdBranch] = useState(ID_BRANCH_LOGIN)
  const [valueExport, setValueExport] = useState([])
  const [branch, SetBranch] = useState([])
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [rowActive, setRowActive] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  // Khởi tạo trạng thái cho phản hồi
  const [feedback, setFeedback] = useState({})
  //---------- datePicker-----------//
  const onChangeDatePicker = (date, DateString) => {
    setDate(moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
  }
  const onChangeDatePickerToDate = (date, DateString) => {
    setToDate(moment(DateString, 'DD/MM/YYYY').format('YYYY-MM-DD'))
  }
  // --------- lấy danh sách chi nhánh theo công ty  ------------//
  const fetchbranchs = async () => {
    try {
      const { data } = await branchService.getListBranch(ID_COMPANY)
      SetBranch(data)
    } catch (error) {
      console.log(error)
    }
  }
  //--------------- lấy danh sách tái khám --------//
  const fetchData = async () => {
    const nameBranch = branch.find((item) => item?.idChiNhanh === idBranch)?.tenChiNhanh
    const detailForLog = {
      Branchname: nameBranch,
      tuNgay: date,
      denNgay: toDate,
    }
    try {
      setIsLoading(true)
      const { data } = await recieveService.getDanhSachTaiKhamTiemChung(idBranch, date, toDate)
      setData(data)
      setIsLoading(false)

      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        logAction.XEM_TAI_KHAM_TIEM_CHUNG,
        true,
        MENU_CONSTANTS.TAIKHAM,
        null,
        detailForLog,
      )
    } catch (error) {
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.TAIKHAM,
        detailForLog,
      )
      console.log(error)
    }
  }
  //--------- update noi dung --------//
  const updateData = async (form, idBranch, date, toDate) => {
    const nameBenhNhan = data.find((item) => item.idbnlhtk === form.idbnlhtk)?.tenbenhnhan || ''
    const detailForLog = {
      nameBenhNhan: nameBenhNhan,
      noidung: form.noidung,
    }

    try {
      await recieveService.updateBNTaiKham(form)
      const { data } = await recieveService.getDanhSachTaiKhamTiemChung(idBranch, date, toDate)
      setData(data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        logAction.UPDATE_LIEN_HE_TAI_KHAM_TIEM_CHUNG,
        true,
        MENU_CONSTANTS.TAIKHAM,
        null,
        detailForLog,
      )
    } catch (error) {
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        PkDangNhap.idChiNhanh,
        actionStatus.PUT,
        null,
        true,
        MENU_CONSTANTS.TAIKHAM,
        form,
      )
      console.log(error)
    }
  }
  // Hàm xử lý thay đổi phản hồi
  const handleFeedbackChange = (e, key) => {
    const { value } = e.target
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [key]: value,
    }))
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const exportToExcel = () => {
    const formattedData = valueExport?.map((item) => ({
      'Chi nhánh': item.tenchinhanh,
      'Ngày khám': item.ngaykhamcu ? moment(item.ngaykhamcu).format('DD/MM/YYYY') : '',
      'Tái khám TC': item.ngaytaikham ? moment(item.ngaytaikham).format('DD/MM/YYYY') : '',
      'Tái khám TC mới': item.ngaydentaikham
        ? moment(item.ngaydentaikham).format('DD/MM/YYYY')
        : '',
      'Mã bệnh nhân': item.mabenhnhan,
      'Tên bệnh nhân': item.tenbenhnhan,
      'Mã TTV': item.mathe,
      'Tên TTV': item.tenthe,
      'Ngày sinh': item.ngaysinh ? moment(item.ngaysinh).format('DD/MM/YYYY') : '',
      'BS khám': item.bskham,
      'Chẩn đoán': item.chandoan,
      'Phản hồi': item.noidung,
    }))
    const nameBranch = branch.find((item) => item?.idChiNhanh === idBranch)?.tenChiNhanh
    const detailForLog = {
      Branchname: nameBranch,
      tuNgay: date,
      denNgay: toDate,
    }
    addLog(
      infoUser?.dangNhap.idNguoiDung,
      PkDangNhap.idChiNhanh,
      actionStatus.EXPORT,
      logAction.XUAT_EXCEL_TAI_KHAM_TIEM_CHUNG,
      true,
      MENU_CONSTANTS.TAIKHAM,
      null,
      detailForLog,
    )
    const name = `Danh sách tái khám tiêm chủng từ ${moment(date).format('DD/MM/YYYY')} đến ${moment(toDate).format('DD/MM/YYYY')} `
    if (formattedData?.length) exportExcelformat(formattedData, name)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Nhập tìm kiếm'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Xoá
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            Đóng
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const save = async (e, record, idBranch, date, toDate) => {
    const { value } = e.target
    debounceDropDown(value, record.idbnlhtk, idBranch, date, toDate)
  }
  const debounceDropDown = useCallback(
    _.debounce((keyword, id, idBranch, date, toDate) => {
      updateData({ noidung: keyword, idbnlhtk: id }, idBranch, date, toDate)
    }, 300),
    [],
  )
  useEffect(() => {
    fetchbranchs()
    fetchData()
  }, [])
  useEffect(() => {
    const initialFeedback = data.reduce((acc, item) => {
      acc[item.idbnlhtk] = item.noidung || '' // Mỗi hàng sẽ có một giá trị phản hồi ban đầu
      return acc
    }, {})
    setFeedback(initialFeedback)
  }, [data]) // Chỉ chạy khi data thay đổi

  const handleClickRow = (record) => {
    setRowActive(record)
  }
  useEffect(() => {
    setValueExport(data)
  }, [data])
  const columnsTaiKham = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      width: 40,
      align: 'center',
      render: (text, record, index) => ++index,
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'tenchinhanh',
      key: 'tenchinhanh',
      width: 200,
    },
    {
      title: 'Ngày khám',
      dataIndex: 'ngaykhamcu',
      key: 'ngaykhamcu',
      width: 140,
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
    },
    {
      title: 'Tái khám TC',
      dataIndex: 'ngaytaikham',
      key: 'ngaytaikham',
      width: 100,
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
    },
    {
      title: 'Tái khám TC mới',
      dataIndex: 'ngaydentaikham',
      key: 'ngaydentaikham',
      align: 'center',
      width: 140,
      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
    },
    // {
    //   title: 'Ngày hẹn tiêm',
    //   dataIndex: 'ngaytaikham',
    //   key: 'ngaytaikham',
    //   width: 100,
    //   align: 'center',
    //   render: (text) => (text ? moment(text).format('DD/MM/YYYY') : '-'),
    // },
    {
      title: 'Mã bệnh nhân',
      dataIndex: 'mabenhnhan',
      key: 'mabenhnhan',
      width: 130,
      ...getColumnSearchProps('mabenhnhan'),
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'tenbenhnhan',
      key: 'tenbenhnhan',
      width: 200,
      ...getColumnSearchProps('tenbenhnhan'),
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaysinh',
      key: 'ngaysinh',
      width: 90,
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY ') : '-'),
    },
    {
      title: 'Mã TTV',
      dataIndex: 'mathe',
      key: 'mathe',
      width: 90,
    },
    {
      title: 'Tên TTV',
      dataIndex: 'tenthe',
      key: 'tenthe',
      width: 200,
    },
    {
      title: 'SĐT',
      dataIndex: 'dienthoai',
      key: 'dienthoai',
      width: 100,
      align: 'center',
      render: (text, record) => (record.idbnlhtk === rowActive?.idbnlhtk ? <p>{text}</p> : ''),
    },
    {
      title: 'SĐT người nhà',
      dataIndex: 'dienthoainguoinha',
      key: 'dienthoainguoinha',
      width: 120,
      align: 'center',
      render: (text, record) => (record.idbnlhtk === rowActive?.idbnlhtk ? <p>{text}</p> : ''),
    },
    {
      title: 'BS khám',
      dataIndex: 'bskham',
      key: 'bskham',
      onCell: () => ({
        style: {
          minWidth: 90,
        },
      }),
      ...getColumnSearchProps('bskham'),
    },
    {
      title: 'Chẩn đoán',
      dataIndex: 'chandoan',
      key: 'chandoan',
      onCell: () => ({
        style: {
          minWidth: 90,
        },
      }),
    },
    {
      title: 'Phản hồi',
      dataIndex: 'noidung',
      width: 250,
      key: 'noidung',
      fixed: 'right',
      render: (text, record, index) => (
        <Input
          className="text-red-500"
          value={feedback[record.idbnlhtk]} // Sử dụng trạng thái feedback
          size="small"
          placeholder="Phản hồi khách hàng"
          onChange={(e) => handleFeedbackChange(e, record.idbnlhtk)}
          onPressEnter={(e) => save(e, record, idBranch, date, toDate)}
          onBlur={(e) => save(e, record, idBranch, date, toDate)}
          variant="borderless"
        />
      ),
    },
    {
      title: 'Liên hệ',
      dataIndex: 'dienthoai',
      key: 'dienthoai',
      align: 'center',
      width: 80,
      fixed: 'right',
      render: (text) => (
        <a
          href={`https://zalo.me/${text}`}
          target="_blank"
          className="cursor-pointer flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            className="size-8"
            viewBox="0 0 48 48"
          >
            <path
              fill="#2962ff"
              d="M15,36V6.827l-1.211-0.811C8.64,8.083,5,13.112,5,19v10c0,7.732,6.268,14,14,14h10	c4.722,0,8.883-2.348,11.417-5.931V36H15z"
            ></path>
            <path
              fill="#eee"
              d="M29,5H19c-1.845,0-3.601,0.366-5.214,1.014C10.453,9.25,8,14.528,8,19	c0,6.771,0.936,10.735,3.712,14.607c0.216,0.301,0.357,0.653,0.376,1.022c0.043,0.835-0.129,2.365-1.634,3.742	c-0.162,0.148-0.059,0.419,0.16,0.428c0.942,0.041,2.843-0.014,4.797-0.877c0.557-0.246,1.191-0.203,1.729,0.083	C20.453,39.764,24.333,40,28,40c4.676,0,9.339-1.04,12.417-2.916C42.038,34.799,43,32.014,43,29V19C43,11.268,36.732,5,29,5z"
            ></path>
            <path
              fill="#2962ff"
              d="M36.75,27C34.683,27,33,25.317,33,23.25s1.683-3.75,3.75-3.75s3.75,1.683,3.75,3.75	S38.817,27,36.75,27z M36.75,21c-1.24,0-2.25,1.01-2.25,2.25s1.01,2.25,2.25,2.25S39,24.49,39,23.25S37.99,21,36.75,21z"
            ></path>
            <path fill="#2962ff" d="M31.5,27h-1c-0.276,0-0.5-0.224-0.5-0.5V18h1.5V27z"></path>
            <path
              fill="#2962ff"
              d="M27,19.75v0.519c-0.629-0.476-1.403-0.769-2.25-0.769c-2.067,0-3.75,1.683-3.75,3.75	S22.683,27,24.75,27c0.847,0,1.621-0.293,2.25-0.769V26.5c0,0.276,0.224,0.5,0.5,0.5h1v-7.25H27z M24.75,25.5	c-1.24,0-2.25-1.01-2.25-2.25S23.51,21,24.75,21S27,22.01,27,23.25S25.99,25.5,24.75,25.5z"
            ></path>
            <path
              fill="#2962ff"
              d="M21.25,18h-8v1.5h5.321L13,26h0.026c-0.163,0.211-0.276,0.463-0.276,0.75V27h7.5	c0.276,0,0.5-0.224,0.5-0.5v-1h-5.321L21,19h-0.026c0.163-0.211,0.276-0.463,0.276-0.75V18z"
            ></path>
          </svg>
        </a>
      ),
    },
  ]

  return (
    <>
      <div className="mb-2 flex gap-2 items-center">
        <Select
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          showSearch
          onChange={(value) => setIdBranch(value)}
          value={idBranch}
          options={[
            { label: 'Tất cả', value: null }, // Mục "Tất cả" với value = null
            ...branch.map((items) => ({
              label: items.tenChiNhanh,
              value: items.idChiNhanh,
            })),
          ]}
          className="w-64 mr-2"
        />
        <div className="flex items-center gap-1">
          <DatePicker
            allowClear={false}
            onChange={onChangeDatePicker}
            value={dayjs(date)}
            format={'DD-MM-YYYY'}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
          </svg>
          <DatePicker
            allowClear={false}
            onChange={onChangeDatePickerToDate}
            value={dayjs(toDate)}
            format={'DD-MM-YYYY'}
          />
        </div>

        <IconButton color="primary" aria-label="add to shopping cart" onClick={fetchData}>
          <CachedIcon />
        </IconButton>
        <Button
          onClick={exportToExcel}
          disabled={!data?.length || !valueExport?.length}
          type="text"
          size="middle"
          className="text-green-700 p-1"
          icon={<ImportExportIcon />}
        >
          Xuất Excel
        </Button>
      </div>
      <ConfigProvider
        theme={{
          token: {
            padding: 5,
            borderRadius: 0,
          },
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table
          rowClassName={(record) => (record.ngaydentaikham ? 'bg-orange-200' : '')}
          loading={isLoading}
          bordered
          dataSource={data}
          pagination={false}
          onRow={(record) => {
            return {
              onClick: () => {
                handleClickRow(record)
              }, // click row
            }
          }}
          scroll={{
            x: data?.length ? 'max-content' : 2200,
            y: 685,
          }}
          columns={columnsTaiKham}
        />
      </ConfigProvider>
      <span className="text-red-500 bg-red-50 p-1 ">
        (*) Lưu ý: Những dòng highlight là khách đã đến tái khám tiêm chủng
      </span>
    </>
  )
}

export default TabTaiKhamTC
