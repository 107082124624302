import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, Table, Input, Select, Modal, Form, message } from 'antd'
import { https } from '../../../../services/apiService'
import {
  SyncOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { putCheckQuyenThaoTacAction } from '../../../../store/actions/phanQuyenAction'
import { addLog } from '../../../../utils/log/apiLog'
import { actionStatus, logAction, MENU_CONSTANTS } from '../../../../utils/log/logConstant'
import { formatPayloadDetails } from '../../../../utils/algorithm'
const { Option } = Select

const QuanHuyen = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [tinhData, setTinhData] = useState([])
  const [selectedTinh, setSelectedTinh] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [newDistrict, setNewDistrict] = useState({ tenQuan: '', idTinh: null })
  const { infoUser } = useSelector((state) => state.userReducer)

  const maQuyenXemDs = 'QHT448'
  const maQuyenThem = 'QHT449'
  const maQuyenXoa = 'QHT450'

  const actionIsTrue = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        fetchQuanHuyenData()
        break
      case maQuyenThem:
        setIsModalVisible(true)
        break
      case maQuyenXoa:
        showDeleteConfirm(infoNew)
        break
      default:
        return true
    }
  }
  const actionIsFalse = (info) => {
    const { maquyen, ...infoNew } = info
    switch (maquyen) {
      case maQuyenXemDs:
        //
        break
      case maQuyenThem:
        setIsModalVisible(false)
        break
      case maQuyenXoa:
        //
        break
      default:
        return false
    }
  }
  useEffect(() => {
    const fetchTinhData = async () => {
      try {
        const response = await https.get('/tinh')
        setTinhData(response.data)
      } catch (error) {
        console.error('Error fetching Tinh data:', error)
      }
    }

    fetchTinhData()
  }, [])

  const tenTinhTP = useMemo(() => {
    return tinhData?.find((tinh) => tinh?.idTinh === selectedTinh)?.tenTinh
  }, [tinhData, selectedTinh])

  const fetchQuanHuyenData = async () => {
    if (!selectedTinh) return
    try {
      const response = await https.get(`/QuanHuyen/${selectedTinh}`)
      setData(response.data)
      setFilteredData(
        response?.data?.filter((item) => item?.tenQuan?.toLowerCase().includes(searchText)),
      )
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        logAction.GET_DS_QUAN_HUYEN,
        true,
        MENU_CONSTANTS.DIA_CHI,
        null,
        {
          tenTinhTP,
        },
      )
    } catch (error) {
      console.error('Error fetching QuanHuyen data:', error)
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.GET,
        null,
        false,
        MENU_CONSTANTS.DIA_CHI,
        detailErrorPayload,
      )
    }
  }
  const onLoad = () => {
    dispatch(putCheckQuyenThaoTacAction(maQuyenXemDs, null, actionIsTrue, actionIsFalse))
  }
  useEffect(() => {
    if ((selectedTinh, infoUser)) {
      onLoad()
    }
  }, [selectedTinh, infoUser])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchText(value)
    const filtered = data.filter((item) => item.tenQuan?.toLowerCase().includes(value))
    setFilteredData(filtered)
  }

  const handleTinhChange = (value) => {
    setSelectedTinh(value)
  }

  const handleAddDistrict = async () => {
    try {
      const response = await https.post('/QuanHuyen/CreateQuanHuyen', newDistrict)
      onLoad()
      message.success('Đã thêm quận/huyện thành công')
      setIsModalVisible(false)
      setNewDistrict({ tenQuan: '', idTinh: null })
      // Refetch data after addition
      // const fetchQuanHuyenData = async () => {
      //   try {
      //     const response = await https.get(`/QuanHuyen/${selectedTinh}`)
      //     setData(response.data)
      //     setFilteredData(response.data)
      //   } catch (error) {
      //     console.error('Error fetching QuanHuyen data:', error)
      //   }
      // }
      // fetchQuanHuyenData()
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        logAction.THEM_MOI_QUAN_HUYEN,
        true,
        MENU_CONSTANTS.DIA_CHI,
        null,
        {
          tenTinhTP: tinhData?.find((tinh) => tinh?.idTinh === newDistrict.idTinh)?.tenTinh,
          ten: newDistrict.tenQuan,
        },
      )
    } catch (error) {
      console.error('Error adding district:', error)
      message.error('Thêm quận/huyện thất bại')
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `Payload: \n \t${formatPayloadDetails(newDistrict)} \n API request: ${apiUrl} \n RESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.POST,
        null,
        false,
        MENU_CONSTANTS.DIA_CHI,
        detailErrorPayload,
      )
    }
  }

  const showDeleteConfirm = (record) => {
    Modal.confirm({
      title: 'Bạn có chắc chắn muốn xóa quận/huyện này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Hủy',
      onOk() {
        handleDeleteDistrict(record)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const handleDeleteDistrict = async (record) => {
    try {
      await https.delete(`/QuanHuyen/DeleteQuanHuyen?idQuan=${record.idQuan}`)
      onLoad()
      message.success('Đã xóa thành công')
      // Refetch data after deletion
      // const response = await https.get(`/QuanHuyen/${selectedTinh}`)
      // setData(response.data)
      // setFilteredData(response.data)
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.DELETE,
        logAction.XOA_QUAN_HUYEN,
        true,
        MENU_CONSTANTS.DIA_CHI,
        null,
        {
          ten: record.tenQuan,
          tenTinhTP,
        },
      )
    } catch (error) {
      console.error('Error deleting district:', error)
      message.error('Xóa quận/huyện thất bại')
      const apiUrl = error?.config?.url ?? 'Unknown API URL' // Extract the URL from the Axios error, if available
      const detailErrorPayload = `API request: ${apiUrl} \nRESPONSE: ${error?.response?.data || error?.response?.data?.message || error?.message}`
      addLog(
        infoUser?.dangNhap.idNguoiDung,
        +localStorage.getItem('BRANH_LOGIN'),
        actionStatus.DELETE,
        null,
        false,
        MENU_CONSTANTS.DIA_CHI,
        detailErrorPayload,
      )
    }
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên Quận/Huyện',
      dataIndex: 'tenQuan',
      key: 'tenQuan',
      sorter: (a, b) => (a.tenQuan || '').localeCompare(b.tenQuan || ''),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Hành động',
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          {/* <Button type="primary" size="small" icon={<EditOutlined />}>
            Sửa
          </Button> */}
          <Button
            type="primary"
            danger
            size="small"
            icon={<DeleteOutlined />}
            onClick={() =>
              dispatch(putCheckQuyenThaoTacAction(maQuyenXoa, record, actionIsTrue, actionIsFalse))
            }
          >
            Xóa
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="flex justify-between items-center mb-4 gap-4">
        <Select
          status={!selectedTinh ? 'error' : ''}
          placeholder="Chọn Tỉnh/Thành phố"
          onChange={handleTinhChange}
          style={{ width: 250 }}
        >
          {tinhData.map((tinh) => (
            <Option key={tinh.idTinh} value={tinh.idTinh}>
              {tinh.tenTinh}
            </Option>
          ))}
        </Select>
        <Input
          placeholder="Tìm kiếm Quận/Huyện"
          value={searchText}
          onChange={handleSearch}
          style={{ width: 250 }}
          prefix={<SearchOutlined />}
        />
        <Button
          onClick={onLoad}
          className="mr-auto"
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() =>
            dispatch(putCheckQuyenThaoTacAction(maQuyenThem, null, actionIsTrue, actionIsFalse))
          }
        >
          Thêm quận/huyện
        </Button>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowHoverBg: '#ecf0f1',
              headerBg: '#e6e6e6',
              footerBg: '#e6e6e6',
              borderColor: '#BABABA',
            },
          },
        }}
      >
        <Table columns={columns} dataSource={filteredData} rowKey="idQuanHuyen" />
      </ConfigProvider>

      <Modal
        title="Thêm Quận/Huyện"
        visible={isModalVisible}
        onOk={handleAddDistrict}
        onCancel={() => setIsModalVisible(false)}
        okText="Lưu"
        cancelText="Hủy"
      >
        <Form layout="vertical">
          <Form.Item label="Tên Quận/Huyện">
            <Input
              value={newDistrict.tenQuan}
              onChange={(e) => setNewDistrict({ ...newDistrict, tenQuan: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Tỉnh/Thành phố">
            <Select
              value={newDistrict.idTinh}
              onChange={(value) => setNewDistrict({ ...newDistrict, idTinh: value })}
              placeholder="Chọn Tỉnh/Thành phố"
            >
              {tinhData.map((tinh) => (
                <Option key={tinh.idTinh} value={tinh.idTinh}>
                  {tinh.tenTinh}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default QuanHuyen
