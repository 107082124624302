import moment from 'moment'
import { Tag } from 'antd'
import 'moment/locale/vi'
import { formattedNumber } from '../../../utils/formattedNumber'
moment.locale('vi')

export const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    align: 'center',
    width: 40,
    fixed: 'left',
    render: (text, record, index) => ++index,
  },
  {
    title: 'Chi nhánh',
    dataIndex: 'tenChiNhanh',
    key: 'tenChiNhanh',
    width: 200,
    fixed: 'left',
    // ...getColumnSearchProps('tenchinhanh'),
  },
  {
    title: 'Mã bệnh nhân',
    dataIndex: 'maBenhNhan',
    key: 'maBenhNhan',
    width: 150,
  },
  {
    title: 'Tên bệnh nhân',
    dataIndex: 'tenBenhNhan',
    key: 'tenBenhNhan',
    width: 200,
  },
  {
    title: 'Loại khám',
    dataIndex: 'loaiKham',
    key: 'loaiKham',
    width: 200,
  },
  {
    title: 'Tên dịch vụ',
    dataIndex: 'dichVu',
    key: 'dichVu',
    width: 250,
    render: (text) => {
      if (!text) return '-'
      return text.split('\\n').map((line, index) => (
        <div key={index}>
          {line}
          {index < text.split('\\n').length - 1 && <br />}
        </div>
      ))
    },
  },
  {
    title: 'Quà tặng',
    dataIndex: 'tenQuaTang',
    key: 'maxn',
    width: 150,
  },
  {
    title: 'Số lượng',
    dataIndex: 'soLuong',
    key: 'soLuong',
    width: 75,
    align: 'center',
  },
  {
    title: 'Ngày tặng',
    dataIndex: 'ngayThucHien',
    key: 'ngayThucHien',
    width: 140,
    align: 'center',
    render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : '-'),
  },

  {
    title: 'Người thực hiện',
    dataIndex: 'nguoiThucHien',
    key: 'nguoiThucHien',
    width: 140,
  },
]
