import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { convertImageToBase64 } from '../convertImageToBase64'
import logo from '../../assets/images/logoKiemKe/imageKiemKe.jpg'
import moment from 'moment'
import { addLog } from '../log/apiLog'
import { MENU_CONSTANTS, actionStatus, logAction } from '../log/logConstant'
const exportExcelKiemKe = async (data, form, idNguoiDung, action) => {
  try {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Thông tin hàng hóa')
    const imageId = workbook.addImage({
      base64: await convertImageToBase64(logo), // Chuyển đổi hình ảnh sang base64
      extension: 'png',
    })
    // Đặt hình ảnh vào vị trí tiêu đề
    worksheet.addImage(imageId, {
      tl: { col: 0.5, row: 0.5 }, // Góc trên bên trái của hình ảnh
      ext: { width: 650, height: 60 }, // Kích thước của hình ảnh
    })
    worksheet.mergeCells('A5', 'G5')
    worksheet.getCell('A5').value = 'BIÊN BẢN KIỂM KÊ'
    worksheet.getCell('A5').font = { bold: true, size: 16 }
    worksheet.getCell('A5').alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell('B7').value = `Ngày:`
    worksheet.getCell('B8').value = `Chi nhánh: ${data[0]?.tenCN}`
    worksheet.addRow([])
    const headerCells = [
      'A10',
      'B10',
      'C10',
      'D10',
      'E10',
      'F10',
      'G10',
      'H10',
      'I10',
      'J10',
      'K10',
      'L10',
      'M10',
      'N10',
      'A11',
      'B11',
      'C11',
      'D11',
      'E11',
      'F11',
      'G11',
      'H11',
      'I11',
      'J11',
      'K11',
      'L11',
      'M11',
      'N11',
    ]
    // Thêm tiêu đề lớn "Thông tin hàng" và gộp ô
    worksheet.mergeCells('A10', 'C10')
    worksheet.getCell('A10').value = 'Thông tin hàng'
    worksheet.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center' }
    // Thiết lập tiêu đề cho các cột con
    worksheet.getCell('A11').value = 'Mã hàng'
    worksheet.getCell('B11').value = 'Tên hàng'
    worksheet.getCell('C11').value = 'hoạt chất'
    worksheet.mergeCells('D10', 'N10')
    worksheet.getCell('D10').value = 'Số lượng'
    worksheet.getCell('D10').alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell('D11').value = 'Số lượng lẻ'
    worksheet.getCell('E11').value = 'Đơn vị lẻ'
    worksheet.getCell('F11').value = 'Số lượng chẳn'
    worksheet.getCell('G11').value = 'Quy cách'
    worksheet.getCell('H11').value = 'Số lô'
    worksheet.getCell('I11').value = 'Hạn dùng'
    worksheet.getCell('J11').value = 'Số hoá đơn'
    worksheet.getCell('K11').value = 'Chi nhánh'
    worksheet.getCell('L11').value = 'Kho hàng'
    worksheet.getCell('M11').value = 'SL thực tế'
    worksheet.getCell('N11').value = 'Chênh lệch'
    // Đặt chiều rộng cho các cột
    worksheet.columns = [
      { key: 'maThuoc', width: 15 },
      { key: 'tenBietDuoc', width: 50 },
      { key: 'tenHoatChat', width: 50 },
      { key: 'tonKho', width: 10 },
      { key: 'dvt', width: 15 },
      { key: 'slChan', width: 15 },
      { key: 'quyCach', width: 15 },
      { key: 'soLo', width: 15 },
      { key: 'hanDung', width: 15 },
      { key: 'soHoaDon', width: 15 },
      { key: 'tenCN', width: 25 },
      { key: 'tenKhoCn', width: 25 },
      { key: 'tonkhothucte', width: 10 },
      { key: 'chenhLech', width: 15 },
    ]
    //   const data = [
    //     { maHang: 'MH001', tenHang: 'Sản phẩm A', soLuong: 50, donVi: 'Chiếc', chenhLech: 5 },
    //     { maHang: 'MH002', tenHang: 'Sản phẩm B', soLuong: 30, donVi: 'Hộp', chenhLech: -2 },
    //     { maHang: 'MH003', tenHang: 'Sản phẩm C', soLuong: 20, donVi: 'Bộ', chenhLech: 0 },
    //   ]
    headerCells.forEach((cellAddress) => {
      const cell = worksheet.getCell(cellAddress)
      // Kiểm tra xem ô có dữ liệu không
      if (cell.value) {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF99' }, // Màu nền vàng (mã hex: FFFF99)
        }
        cell.font = {
          bold: true,
        }
      }
    })
    // Thêm dữ liệu vào worksheet
    data.forEach((item) => {
      const tonTT = item?.tonkhothucte || item?.tonKho
      const count = tonTT - item?.tonKho
      const row = worksheet.addRow({
        maThuoc: item.maThuoc,
        tenBietDuoc: item.tenBietDuoc,
        tenHoatChat: item.tenHoatChat,
        tonKho: item.tonKho,
        dvt: item.dvt,
        slChan: item.slChan,
        quyCach: item.quyCach,
        soLo: item.soLo,
        hanDung: moment(item.hanDung).format('DD/MM/YYYY'),
        soHoaDon: item.soHoaDon,
        tenCN: item.tenCN,
        tenKhoCn: item.tenKhoCn,
        tonkhothucte: item.tonkhothucte,
        chenhLech: count,
      })
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
      })
    })

    // Định dạng tiêu đề
    worksheet.getRow(3).font = { bold: true }
    worksheet.getRow(3).alignment = { vertical: 'middle', horizontal: 'center' }
    //   worksheet.eachRow((row) => {
    //     row.eachCell((cell) => {
    //       cell.font = { name: 'Times New Roman', size: 12 }
    //     })
    //   })
    // Tạo và lưu file Excel
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, 'Hàng kiểm kê.xlsx')
    addLog(
      idNguoiDung,
      +localStorage.getItem('BRANH_LOGIN'),
      actionStatus.GET,
      action,
      true,
      MENU_CONSTANTS.KHO_KIEM_KE,
      null,
      {
        nameCompany: form.nameCompany,
        nameChiNhanh: form.nameChiNhanh,
        keyword: form.keyword ?? '',
        nameKho: form.nameKho,
        nameKy: form.nameKy,
      },
    )
  } catch (error) {
    console.log(error)
    const detailErrorPayload = `Có lỗi xảy ra khi xuất file excel kiểm kê \n${JSON.stringify(error)}`
    addLog(
      idNguoiDung,
      +localStorage.getItem('BRANH_LOGIN'),
      actionStatus.GET,
      null,
      false,
      MENU_CONSTANTS.KHO_KIEM_KE,
      detailErrorPayload,
    )
  }
}
export default exportExcelKiemKe
